import React from 'react';

import TradingBannerIMG from '../../../assets/images/lawn.jpg'

import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF'
  },
  image: {
    width:'100%', 
    height: '35vh', 
    objectFit: 'cover', 
    zIndex: 0,
  },
  imageDark: {
    width:'100%', 
    height: '35vh', 
    objectFit: 'cover', 
    zIndex: 0,
    background: 'linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%)'
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width:'40%', 
  }
});

const StyledText = withStyles({
    root: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign:'center',
        color:'#fff',
        fontSize:'30px',
        lineHeight:'1.19',
        zIndex: 5,
    },
  })(Typography);

const TradingBanner = props => {  
    const {
      classes,      
    } = props;
    return (
      <div className={classes.root}>        
            <StyledText variant="h2">
            Search to find your perfect trade or service
            </StyledText>
            <img
              className={classes.imageDark} 
              src={TradingBannerIMG} />         
      </div>
    );
}

export default withStyles(styles)(TradingBanner);