import React, { useState, useEffect } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import SUBURBS from '../../../assets/data/suburbs-all-new.json'
import _ from 'lodash'
import { matchSorter } from 'match-sorter'
import { TextField } from '@material-ui/core'

const SuburbSearchField = (props) => {
  const { autoCompleteValue, open, setOpen, defaultValue, style = {} } = props

  const sortedSuburbs = SUBURBS.sort((a, b) => {
    const x = a.locality
    const y = b.locality
    if (x < y) {
      return -1
    }

    if (x > y) {
      return 1
    }

    return 0
  })

  // const finalSuburbsList = [
  //   ...new Map(sortedSuburbs.map((item) => [item['locality'], item])).values(),
  // ]

  const _filterOptions = createFilterOptions({ limit: 50 })
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state)
    return matchSorter(results, state.inputValue, {
      keys: [
        {
          threshold: matchSorter.rankings.STARTS_WITH,
          key: 'locality',
        },
      ],
    })
  }

  const defaultProps = {
    options: sortedSuburbs,
    getOptionLabel: (option) => option.locality ?? '',
    renderOption: (option) => {
      return (
        option.locality +
        (option.state ? ', ' + option.state : '') +
        (option.postcode ? ', ' + option.postcode : '')
      )
    },
    filterOptions: filterOptions,
  }

  const [selectorError, setSelectorError] = useState(false)

  return (
    <div>
      <Autocomplete
        {...defaultProps}
        defaultValue={defaultValue}
        onInputChange={props.onInputChange && props.onInputChange}
        open={open}
        onOpen={() => {
          if (props.inputValue) {
            setOpen(true)
          }
        }}
        forcePopupIcon={false}
        id="auto-complete"
        autoComplete
        includeInputInList
        onChange={(e, value) => {
          if (value) {
            setSelectorError(false)
          } else {
            setSelectorError(true)
            setOpen(false)
          }

          if (props.setValueCallback) {
            props.setValueCallback(value)
          }
        }}
        onClose={(e, reason) => {
          const value = e.target.value
          if (reason === 'blur' || reason === 'select-option') {
            if (value !== '') {
              setSelectorError(false)
            } else {
              setSelectorError(true)
            }

            setOpen(false)
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={selectorError}
              helperText={
                selectorError
                  ? props.errorMsg
                    ? props.errorMsg
                    : 'Please select a suburb'
                  : null
              }
              variant={'outlined'}
              label={props.placeholder}
              margin="normal"
              style={{ ...style }}
              inputProps={{
                ...params.inputProps,
                value: autoCompleteValue ? autoCompleteValue : params.inputProps.value,
              }}
            />
          )
        }}
      />
    </div>
  )
}

export default SuburbSearchField
