export function getErrors(state) {
  return state.ippReferral.error
}

export function getStatus(state) {
  return state.ippReferral.status
}

export function getAnalytics(state) {
  return state.ippReferral.ippAnalyticsData
}
