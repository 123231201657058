import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authenticationReducer from './authentication.reducers'
import filtersReducer from './filters.reducers'
import settingsReducer from './settings.reducers'
import userReducer from './user.reducers'
import validationReducer from './validation.reducers'
import propertyReducer from './property.reducers'
import agentReducers from './agent.reducers'
import agencyReducers from './agency.reducers'
import rateAgentReducers from './rate.agent.reducers'
import skipTakeReducers from './skiptake.reducers'
import uploadImageReducers from './uploadImage.reducers'
import statisticsReducers from './statistics.reducers'
import contactUsReducer from './contactUs.reducer'
import emailHistoryReducer from './emailHistory.reducer'
import { getSingleEmailReducer } from './emailHistory.reducer'
import superadminReducers from './superadmin.reducers'
import agencyv2Reducers from './agencyv2.reducers'
import propertyv2Reducers from './propertyv2.reducers'
import billingReducers from './billing.reducers'

import referenceFiltersReducers from './referencefilters.reducers'
import searchReducers from './search.reducers'
import authReducers from './auth.reducers'
import mppReducers from './mpp.reducers'
import FilterReducers from './globalfilters.reducers'
import StorageReducers from './storage.reducers'
import ippReferralReducers from './ippReferral.reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'authentication',
    'filters',
    // 'validations',
    //'property',
    'settings',
    'users',
    //'agents',
    'referenceFilters',
    'superadmin',
    'agencyv2',
    'propertyv2',
    'mpp',
    'globalFilters',
    'search',
    'storage',
  ],
}

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  property: propertyReducer,
  filters: filtersReducer,
  settings: settingsReducer,
  users: userReducer,
  validations: validationReducer,
  agents: agentReducers,
  agency: agencyReducers,
  rateAgent: rateAgentReducers,
  skipTakeData: skipTakeReducers,
  uploadImage: uploadImageReducers,
  statistics: statisticsReducers,
  referenceFilters: referenceFiltersReducers,
  contactUsReducer: contactUsReducer,
  emailHistoryReducer: emailHistoryReducer,
  getSingleEmailReducer: getSingleEmailReducer,
  superadmin: superadminReducers,
  agencyv2: agencyv2Reducers,
  propertyv2: propertyv2Reducers,
  search: searchReducers,
  billing: billingReducers,
  auth: authReducers,
  mpp: mppReducers,
  globalFilters: FilterReducers,
  storage: StorageReducers,
  ippReferral: ippReferralReducers,
})

export default persistReducer(persistConfig, rootReducer)
