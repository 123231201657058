import React, { Component } from 'react'
import PropTypes from 'prop-types'
import shouldPureComponentUpdate from 'react-pure-render/function'
import DynamicIcons from '../DynamicIcons'

import GoogleMap from 'google-map-react'
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'

const Marker = ({ content }) => <div>{content}</div>

const getMapOptions = (maps, type) => {
  // console.log(maps, 'MAPS')
  return {
    panControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    zoomControl: true,
    clickableIcons: false,
  }
}

export default class GoogleMapView extends Component {
  static propTypes = {
    data: PropTypes.object,
    options: PropTypes.object,
  }

  static defaultProps = {
    data: {
      map: {
        coordinates: [-37.7963, 144.92512],
        markers: [{ lat: -37.7963, lng: 144.92512 }],
        nearby: [],
        zoom: 15,
      },
      address: '108/62 Altona Street, Kensington',
    },
  }

  shouldComponentUpdate = shouldPureComponentUpdate

  constructor(props) {
    super(props)
  }

  render() {
    console.log(this.props.data, 'DATA')
    return (
      <GoogleMap
        apiKey={GOOGLE_MAP_API_KEY}
        center={this.props.data.map.coordinates}
        zoom={this.props.data.map.zoom}
        options={({ map, maps }) => getMapOptions(maps)}
      >
        {this.props.data.map.markers.length > 0 &&
          this.props.data.map.markers.map((marker, idx) => {
            return (
              <Marker
                {...marker}
                key={idx}
                content={
                  <DynamicIcons
                    viewBox="0 0 24 28"
                    style={{
                      transform: 'translate(-50%, -70%)',
                      width: '1.5em',
                      height: '1.5em',
                    }}
                    icon="2DCSaleDropInIcon"
                  />
                }
              />
            )
          })}
        {/* { this.props.data.map.nearby.length > 0 && this.props.data.map.nearby.map((nearby, idx) => { 
            return (
              <Marker {...nearby} key={idx}
                content={<DynamicIcons viewBox="0 0 24 28"
                  style={{transform: 'translate(-50%, -70%)', width: '1.5em', height:'1.5em'}}
                  icon="2DCSaleDropInIcon" />
                }
              />
            )
          })
        }  */}
      </GoogleMap>
    )
  }
}
