import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, List, ListItem, ListItemText, makeStyles, Paper, TextField } from '@material-ui/core';
import _ from 'lodash';
import { addressSearch } from '../../../store/api/property.api';

const useStyles = makeStyles(() => ({
  listContainer: {
    position: 'relative',
  },
  list: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '11.250em',
    cursor: 'pointer',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24)',
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EF6631',
    },
    'li ': {
      cursor: 'pointer',
    },
  },
}));

function AddressSearchBar() {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const history = useHistory();
  const typingTimeoutRef = useRef(null);

  const fetchSuggestions = async (value) => {
    const data = await addressSearch(value);
    setSuggestions(data.result);
  };

  useEffect(() => {
    if (inputValue.length > 3) {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }

      typingTimeoutRef.current = setTimeout(() => {
        fetchSuggestions(inputValue);
      }, 300);
    } else {
      setSuggestions([]);
    }
    return () => {
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    };
  }, [inputValue]);

  const handleClick = (id) => {
    history.push(`/buy/${id}`);
  };

  return (
    <Box display="flex" flexDirection="column">
      <TextField
        placeholder="Search by address"
        InputProps={{
          style: {
            textDecoration: 'none',
            padding: '15px',
            display: 'block',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            boxShadow: 'rgb(0 0 0 / 30%) inset 0px 3.5px 5px',
            border: '1px solid #000000',
          },
          onChange: (e) => {
            setInputValue(e.target.value);
          },
        }}
      />
      <Paper style={{ position: 'relative', width: '100%' }}>
        {!_.isEmpty(suggestions) && (
          <List dense={true} className={classes.list}>
            {suggestions.map((suggestion) => {
              return (
                <ListItem key={suggestion._id} onClick={() => handleClick(suggestion._id)}>
                  <ListItemText primary={suggestion.addressText} />
                </ListItem>
              );
            })}
          </List>
        )}
      </Paper>
    </Box>
  );
}

export default AddressSearchBar;
