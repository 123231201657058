import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SuperBreadCrumbs } from 'components/Web/Superadmin'
import AlertNotification from 'components/Web/Form/AlertNotification'
import {
    Paper,
    Typography,
    Grid,
    Button,
    TextField,
    useMediaQuery,
    Avatar,
    Container,
    withStyles,
    useTheme,
} from '@material-ui/core';

import DashboardBanner from 'components/Web/Dashboard/DashboardBanner'
import dashBannerIMG from 'assets/images/agentbanner.png'
import InputMask from 'react-input-mask'
/** redux */
import { agencyv2Actions } from 'store/actions'

import { requestGETAPIAgency, requestPUTAPIAgency, requestPOSTAPIAgency } from 'store/api'
import {
    getAdminStatus,
    getAdminErrors,
} from 'store/selectors/agencyv2.selectors'
import Loader from 'components/Web/Loader'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useRouterChange } from 'utils/router.utils';


const styles = (theme) => ({
    root: {
    },
    title: {
        fontWeight: 300,
        fontSize: '21px',
        letterSpacing: '-0.525px',
        lineHeight: '32px',
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'left',
    },
    subtitle: {
        fontWeight: 300,
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'left',
    },
    formContainer: {
        width: '100%',
        height: 'auto',
        marginRight: 20,
        padding: '2%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
        marginBottom: 60,
        marginRight: 20,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    button: {
        textTransform: 'capitalize',
        borderRadius: 20,
        marginLeft: 10,
        minWidth: 120,
    },
    icon: {
        fontSize: 25,
    },
    fieldGroup: {
        marginTop: 20,
        borderTop: '1px solid #d3d3d3',
        paddingTop: 20,
        paddingBottom: 20,
    },
})

const FormFields = {
    firstName: { name: 'firstName', label: 'First Name' },
    lastName: { name: 'lastName', label: 'Last Name' },
    email: { name: 'email', label: 'Email Address' },
    phone: { name: 'phone', label: 'Phone' },
    password: { name: 'password', label: 'Password' }
}

const validationSchema = yup.object({
    email: yup
        .string('Enter admin email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone: yup
        .string("Enter phone number")
        .required("Admin phone is required")
        .min(12, "Invalid number")
        .max(12, "Invalid number"),
    password: yup
        .string('Enter admin password')
        .required('Password required')
        .min(8, "Password must be more 8 or more characters")
})

const FormInput = (props) => (
    <div style={{ marginTop: 10 }}>
        <Typography variant={'caption'} style={{ color: 'gray' }}>
            {props.label}
        </Typography>
        <TextField
            style={{ backgroundColor: '#FFF' }}
            {...props}
            label={null}
            fullWidth
            id="outlined-basic"
            variant="outlined"
        />
    </div>
)




const AddEditAdminForm = (props) => {
    const { classes, agency, data, action, type, match, successCallback } = props
    const [routerChange] = useRouterChange()

    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            // row 1
            firstName: data?.firstName ?? '',
            lastName: data?.lastName ?? '',
            email: data?.email ?? '',
            phone: data?.phone ?? '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => { },
    })

    const confirmSubmit = () => {
        let params
        params = formik.values
        params.phone = formik.values?.phone?.match(/\d/g)?.join("")
        if (action === "edit") {
            delete params.password
        }

        if (data?.email === params.email) {
            delete params.email
        }

        handleSubmit(params)
    }


    /**
     * ? Maintain selector variable format to UPPERCASE to know distinction
     */
    const STATUS = useSelector(getAdminStatus)
    const ERROR = useSelector(getAdminErrors)

    const AGENCY = agency


    const [isLoading, setIsLoading] = useState(false)
    const defaultNotification = { status: false, options: null }
    const defaultConfirmation = { status: false, options: null }
    const [isNotification, setIsNotification] = useState(defaultNotification)


    const handleNotificationClose = () => {
        setIsNotification(defaultNotification)
    }

    window.onbeforeunload = (e) => {
        handleClearData()
    }

    useEffect(() => {
        // handleClearData()


        return () => {
            handleClearData()
        }
    }, [])


    useEffect(() => {
        if (STATUS === "agency/AGENCY_CREATE_ADMIN_SUCCESS") {
            setIsLoading(false)
            const options = {
                status: true,
                options: {
                    severity: 'success',
                    message: 'Admin has been added successfully.',
                    autoHideDuration: 4000,
                    onClose: () => {
                        handleClearData()
                        if (type === "superadmin" && match) {
                            routerChange('/superadmin/manage-agencies/' + match?.params?.id + "/admins")
                        } else {
                            routerChange('/agency/manage-admins')
                        }
                    }
                },
            }
            setIsNotification(options)
            if (successCallback) {
                successCallback()
            }
        }
        if (STATUS === "agency/AGENCY_CREATE_ADMIN_FAILED" && ERROR) {
            setIsLoading(false)
            const options = {
                status: true,
                options: {
                    severity: 'error',
                    message: 'Error adding admin. ' + ERROR?.message,
                    autoHideDuration: 4000,
                },
            }
            setIsNotification(options)
        }
        if (STATUS === "agency/AGENCY_EDIT_ADMIN_FAILED" && ERROR) {
            setIsLoading(false)
            const options = {
                status: true,
                options: {
                    severity: 'error',
                    message: 'Error updating admin. ' + ERROR?.message,
                    autoHideDuration: 4000,
                },
            }
            setIsNotification(options)
        }

        if (STATUS === "agency/AGENCY_EDIT_ADMIN_SUCCESS") {
            setIsLoading(false)
            const options = {
                status: true,
                options: {
                    severity: 'success',
                    message: 'Admin has been updated.',
                    autoHideDuration: 4000,
                    onClose: () => {
                        handleClearData()
                        if (type === "superadmin" && match) {
                            routerChange('/superadmin/manage-agencies/' + match?.params?.id + "/admins")
                        } else {
                            routerChange('/agency/manage-admins')
                        }
                    }
                },
            }
            setIsNotification(options)
            if (successCallback) {
                successCallback()
            }
        }
    }, [STATUS])

    /**
     *? API Request */

    /**
     * Sample API Request via Redux Dispatcher
     * @param query leave null if no inline url query is required
     * @param data leave as empty obj as default
     * ? Always use dispatch for api calls
     */
    /*   const handlePostSample = (data) => {
      let request = {
        query: null,
        data: { ...data },
      }
      dispatch(requestPOSTAPIAgency(request, 'AGENCY_LOGIN'))
    } */

    const handleSubmit = (params) => {

        if (AGENCY) {
            setIsLoading(true)
            let request;
            if (action !== "edit") {
                request = {
                    query: `/${AGENCY?._id}`,
                    data: params
                }

                dispatch(requestPOSTAPIAgency(request, "AGENCY_CREATE_ADMIN"))
            }

            if (data?._id) {
                request = {
                    query: `/${data?._id}/update`,
                    data: params
                }

                dispatch(requestPUTAPIAgency(request, "AGENCY_EDIT_ADMIN"))
            }
        }
    }



    const handleClearData = () => {
        // dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
    }

    return (
        <div className={classes.root}>
            <Loader isLoading={isLoading} message={action === "edit" ? "Updating admin account..." : 'Creating admin account...'} />

            {isNotification.status && (
                <AlertNotification
                    status={isNotification.status}
                    options={isNotification.options}
                    closeNotification={handleNotificationClose}
                />
            )}

            <Grid
                className={classes.fieldGroup}
                container
                spacing={2}
                alignItems={'center'}
                justifyContent={'flex-start'}
            >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.title} variant={'h1'}>
                        {'Details'}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormInput
                        id={FormFields['email'].name}
                        name={FormFields['email'].name}
                        label={FormFields['email'].label}
                        variant="outlined"
                        value={formik.values[FormFields['email'].name]}
                        onChange={formik.handleChange}
                        error={formik.errors && Boolean(formik.errors['email'])}
                        helperText={formik.errors && formik.errors['firsemailtName']}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormInput
                        id={FormFields['firstName'].name}
                        name={FormFields['firstName'].name}
                        label={FormFields['firstName'].label}
                        variant="outlined"
                        value={formik.values[FormFields['firstName'].name]}
                        onChange={formik.handleChange}
                        error={formik.errors && Boolean(formik.errors['firstName'])}
                        helperText={formik.errors && formik.errors['firstName']}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormInput
                        id={FormFields['lastName'].name}
                        name={FormFields['lastName'].name}
                        label={FormFields['lastName'].label}
                        variant="outlined"
                        value={formik.values[FormFields['lastName'].name]}
                        onChange={formik.handleChange}
                        error={formik.errors && Boolean(formik.errors['lastName'])}
                        helperText={formik.errors && formik.errors['lastName']}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <div style={{ marginTop: 10 }}>
                        <Typography variant={'caption'} style={{ color: 'gray' }}>
                            {FormFields['phone'].label}
                        </Typography>
                        <InputMask
                            mask="9999 999 999"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values[FormFields['phone'].name]}
                            maskPlaceholder={''}
                        >
                            <TextField
                                type="text"
                                fullWidth
                                variant="outlined"
                                id={FormFields['phone'].name}
                                name={FormFields['phone'].name}
                                // label={FormFields["phone"].label}
                                error={formik.errors && Boolean(formik.errors['phone'])}
                                helperText={formik.errors && formik.errors['phone']}
                            />
                        </InputMask>
                    </div>
                </Grid>
                {action !== "edit" && <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormInput
                        id={FormFields['password'].name}
                        name={FormFields['password'].name}
                        label={FormFields['password'].label}
                        variant="outlined"
                        value={formik.values[FormFields['password'].name]}
                        onChange={formik.handleChange}
                        error={formik.errors && Boolean(formik.errors['password'])}
                        helperText={formik.errors && formik.errors['password']}
                    />
                </Grid>}
                <Grid item xs={12}>
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        className={classes.button}
                        disabled={isLoading || !formik.dirty || !formik.isValid}
                        onClick={confirmSubmit}>{action === "edit" ? "Save" : "Submit"}</Button>
                </Grid>
            </Grid>

        </div>
    )
}
export default withStyles(styles)(AddEditAdminForm)
