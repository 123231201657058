import CustomCheckbox from 'components/Web/Filter/CustomCheckbox'
import React from 'react'
import useFilters from 'utils/customHooks/useFilters'

const UnderOffer = () => {
  const [getFilter, setFilter] = useFilters()

  const UNDER_OFFER = getFilter('exclude_under_offers')

  return (
    <CustomCheckbox
      checked={UNDER_OFFER}
      label="Exclude under offer"
      onChange={(e) => {
        setFilter({ key: 'exclude_under_offers', value: !UNDER_OFFER })
      }}
    />
  )
}

export default UnderOffer
