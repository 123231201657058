import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  SuperBreadCrumbs,
  AgentsHeader,
  AgentsLists,
  Snackybar,
} from 'components/Web/Superadmin'

import { Loader } from 'components/Common'
import AddAgentForm from 'components/Web/ManageAgents/AddAgentForm'
import BulkAddAgentForm from 'components/Web/ManageAgents/BulkAddAgentForm'
import AlertNotification from "components/Web/Form/AlertNotification";
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog'
/** redux */
import { superadminActions } from 'store/actions'

import { requestGETAPISuperadmin, requestPUTAPISuperadmin } from 'store/api'
import { getStatus, getErrors, getAgents } from 'store/selectors/superadmin.selectors'
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
    overflow: 'scroll',
  },
})

const SuperadminAgents = (props) => {
  const { classes, history, match } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()

  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)
  const AGENTS = useSelector(getAgents)

  const [isAddingAgent, setIsAddingAgent] = useState(false)
  const [isBulkAddingAgent, setIsBulkAddingAgent] = useState(false)
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    type: '', // * delete_agent
    title: '',
    message: '',
  })


  const defaultNotification = { status: false, options: null }
  const defaultConfirmation = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)
  const [confirmation, setConfirmation] = useState(defaultConfirmation)

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  const [currentRequest, setCurrentRequest] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  })

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
    {
      type: 'secondary',
      label: 'Manage Agency Information',
      path: '/superadmin/manage-agencies/' + match.params.id,
    },
    {
      type: 'primary',
      label: 'Manage Agents',
      path: '',
    },
  ]

  useEffect(() => {
    handleGetAgents()
    /**
     * * Set Default Values
     */
    handleClearError()
    handleClearStatus()
  }, [])

  useEffect(() => {
    if (STATUS === 'superadmin/SUPERADMIN_GET_ALL_AGENTS_REQUEST') {
      setLoading(true)
    }
    if (STATUS === 'superadmin/SUPERADMIN_GET_ALL_AGENTS_FAILED') {
      setLoading(false)
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR?.message : ERROR,
      })
      handleClearError()
    }

    if (STATUS === 'superadmin/SUPERADMIN_DELETE_AGENT_SUCCESS') {
      setLoading(false)
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: "Successfully deleted agent.",
          autoHideDuration: 4000,
        }
      }
      setIsNotification(options)
      handleCloseConfirmation()
      handleGetAgents()
    } else if (STATUS === "superadmin/SUPERADMIN_DELETE_AGENT_FAILED") {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: "Error deleting agent. Please try again in a few minutes.",
          autoHideDuration: 4000,
        }
      }
      setIsNotification(options)
    }

    handleClearStatus()
  }, [STATUS, ERROR])


  const handleConfirmDialog = (data) => {
    setConfirmation(data)
  }

  const handleCloseConfirmation = () => {
    setConfirmation(defaultConfirmation)
  }


  const submitAgents = (data) => {
    setIsBulkAddingAgent(false)
    routerChange(
      '/superadmin/manage-agencies/' + match.params.id + '/bulk-upload-agents',
      data
    )
  }

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    })
  }

  /** API Request */
  const handleGetAgents = (data) => {
    let request = {
      query: '/' + match.params.id + '/agent/list',
      data: { ...data },
    }
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_ALL_AGENTS'))
  }

  const handleDeleteAgent = () => {
    if (confirmation.options?.data?._id && AGENTS) {
      let request = {
        query: confirmation.options.data._id,
        data: {
          agencyReference: AGENTS._id
        }
      }
      dispatch(requestPUTAPISuperadmin(request, 'SUPERADMIN_DELETE_AGENT'))
    }
  }

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  }

  return (
    <div className={classes.root}>
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <SuperBreadCrumbs
        section={'Manage Agents'}
        crumbsLinks={CRUMBS}

      />
      <AddAgentForm
        isAddingAgent={isAddingAgent}
        success={success}
        failed={failed}
        status={STATUS}
        error={ERROR}
        setSuccess={setSuccess}
        setFailed={setFailed}
        submitAgents={submitAgents}
        setIsAddingAgent={setIsAddingAgent}
      />
      <BulkAddAgentForm
        isBulkAddingAgent={isBulkAddingAgent}
        success={success}
        failed={failed}
        status={STATUS}
        error={ERROR}
        setSuccess={setSuccess}
        setFailed={setFailed}
        submitAgents={submitAgents}
        setIsBulkAddingAgent={setIsBulkAddingAgent}
      />
      <AgentsHeader
        type={'superadmin'}
        setIsAddingAgent={setIsAddingAgent}
        setIsBulkAddingAgent={setIsBulkAddingAgent}

        match={match}
      />
      {AGENTS && AGENTS?.agentList &&
        <AgentsLists
          type={'superadmin'}
          agentsData={AGENTS.agentList}

          match={match}
          handleConfirmDialogOpen={handleConfirmDialog}
        />
      }
      {isNotification.status &&
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      }
      {confirmation.status && <ConfirmDialog
        isOpen={confirmation.status}
        title={confirmation.options.title}
        text={confirmation.options.message}
        closeCallback={handleCloseConfirmation}
        confirmCallback={handleDeleteAgent}

      />}
    </div>
  )
}
export default withStyles(styles)(SuperadminAgents)
