import React, { useState } from 'react';
// import ImageCarousel from '../ImageCarousel';
// import Image from '../Image'
import ImageBannerUI from '../ImageBannerUI';
// import IMGComingSoon from '../../../assets/images/coming_soon.svg';
// import IMGOffMarket from '../../../assets/images/off_market.svg';
import LightBoxGallery from '../GridCarousel/LightBoxGallery';
import PhotoSwipeGallery from '../GridCarousel/PhotoSwipeGallery';

const PropertyCarousel = props => {
    const { 
        classes,
        authentication,
        settings,
        properties,
        currentProperty,
        toggleSignUp,
        executeScroll,
        apiRequestSetStatView,
        floorPlan,
        virtualTours
    } = props;

    // console.log(properties, 'Prop Carousel')

    const [images, setImages] = useState(properties);
    const [selectImageIndex, setSelectImageIndex] = useState(null);
    

    React.useEffect(()=>{        
        setImages(properties)
    },[properties])

    return (
        <ImageBannerUI
            data={{images: images}}
            floorPlan={floorPlan}
            selectImageIndex={selectImageIndex}
            setSelectImageIndex={setSelectImageIndex}
            toggleSignUp={toggleSignUp}
            currentProperty={currentProperty}
            apiRequestSaveProperty={props.apiRequestSaveProperty}            
            executeScroll={executeScroll}
            virtualTours={virtualTours}
            {...props}
            >                
            {/* <ImageCarousel data={ images } floorPlan={floorPlan} selectImageIndex={selectImageIndex} setSelectImageIndex={setSelectImageIndex}/> */}
            
            <LightBoxGallery
                data={images}
                selectImageIndex={selectImageIndex}
                setSelectImageIndex={setSelectImageIndex}
            />
                
           {/* <PhotoSwipeGallery
                data={images}
                selectImageIndex={selectImageIndex}
                setSelectImageIndex={setSelectImageIndex}
           /> */}
        </ImageBannerUI>
    )
}

export default PropertyCarousel;
