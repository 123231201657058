import React, { useEffect, useState } from 'react'
import UploadPropertyCard from '../../Web/Property/UploadPropertyCard'
import { propertyConfigSetter } from '../../../utils/property.utils'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { DEFAULT_DOOR_URLS } from 'constants/constants'

const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
  },
  labelMb: {
    marginBottom: 25,
  },
  image: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
  },
  imageDark: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
    background:
      'linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%)',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width: '40%',
  },
  pListContainer: {
    height: '220px',
  },
  activeCover: {
    '& img': {
      boxShadow: '0 0 0 2px ' + theme.palette.secondary.main,
    },
  },
  coverImageWrap: {
    paddingBottom: '20px',
    '& img': {
      cursor: 'pointer',
    },
  },
})

const AddPropertyStep3 = (props) => {
  const {
    classes,
    authentication,
    settings,
    filters,
    addPropertyFields,
    handleFieldChange,
    users,
    setIsFormInvalid,
  } = props

  const [property, setProperty] = useState(null)
  const [imageGallery, setImageGallery] = useState(
    addPropertyFields.propertyInfo.images.value[0] === 'default'
      ? ['default']
      : addPropertyFields.propertyInfo.images.value
  )
  const defaultStructure = {
    shardId: 1,
    close_to_parks: 0,
    close_to_shops: 0,
    foody_area: 0,
    close_to_universities: 0,
    close_to_primary_schools: 0,
    close_to_secondary_schools: 0,
    inner_city_vibe: 0,
    close_to_public_transports: 0,
    close_to_cafes: 0,
    gas: false,
    balcony: false,
    study: false,
    airConditioning: false,
    wardrobe: false,
    courtyard: false,
    laundry: false,
    swimmingPool: false,
    established: false,
    images: ['default'],
    views: 0,
    subscriptionTypeWeight: 2,
    hasCoefficients: true,
    forRent: false,
    furnished: false,
    _id: '',
    property: '',
    bedrooms: 1,
    bathrooms: 1,
    subscriptionType: 'Premium',
    __v: 0,
    addressLotNumber: '',
    addressMunicipality: '',
    addressPostcode: '',
    addressSite: null,
    addressState: '',
    addressStreetNumber: '',
    addressSubNumber: '',
    addressSuburb: '',
    agencyName: '',
    agent: {
      agentId: '',
      name: '',
      firstName: '',
      lastName: '',
      telephone: '',
      email: '',
      twitterUrl: null,
      image: 'http://lorempixel.com/200/200/people/9/',
    },
    auctionDate: '',
    carports: 0,
    description: '',
    garages: 1,
    landSize: 5,
    price: 1270000,
    priceView: '',
    propertyStatus: 'comingsoon',
    propertyType: '',
    publishDate: '',
    totalParking: 1,
    underOffer: false,
    createdAt: '',
    updatedAt: '',
    expiresAt: '',
    coefficients: {
      close_to_parks: 0,
      close_to_shops: 0,
      close_to_universities: 0,
      close_to_primary_schools: 0,
      close_to_secondary_schools: 0,
      close_to_public_transports: 0,
      close_to_cafes: 0,
      foody_area: 1,
      inner_city_vibe: 1,
      bedrooms: 0.4,
      bathrooms: 0.2,
      parking: 0.2,
      has_gas: 0,
      has_balcony: 0,
      has_study: 0,
      has_airconditioning: 0,
      has_wardrobe: 0,
      has_courtyard: 0,
      has_laundry: 0,
      has_pool: 0,
      is_established: 0,
      is_subscription_platinum: 0,
      is_subscription_premium: 1,
      is_subscription_standard: 0,
      is_type_house: 0,
      is_type_apartment: 0,
      is_type_townhouse: 0,
      is_type_retirement_living: 0,
      is_type_villa: 0,
      is_type_block_of_units: 0,
      is_type_land: 0,
      is_type_acreage: 0,
      is_type_rural: 0,
      price_bargain: 0,
      price_cheap: 0,
      price_normal: 0.8650004325002163,
      price_expensive: 0,
      price_luxury: 0,
    },
    latitude: '',
    longitude: '',
  }
  const placeholderimages = DEFAULT_DOOR_URLS
  const [defaultImages, setDefaultImages] = useState()

  function formatUSD(num) {
    if (num && typeof num !== 'object') {
      return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return null
    }
  }

  useEffect(() => {
    if (addPropertyFields.propertyInfo.images.value[0] !== 'default') {
      setIsFormInvalid(false)
    } else {
      setIsFormInvalid(true)
    }
  }, [addPropertyFields.propertyInfo.images])

  useEffect(() => {
    setProperty(defaultStructure)
  }, [])

  useEffect(() => {
    if (addPropertyFields) {
      const details = addPropertyFields.propertyInfo
      const agent = addPropertyFields.agentInfo
      const propDetails = {
        ...defaultStructure,
        propertyStatus: details.propertyStatus.value,
        propertyType: details.propertyType.value,
        bedrooms: details.propertyDetails.value.bedrooms,
        bathrooms: details.propertyDetails.value.bathrooms,
        bathrooms: details.propertyDetails.value.bathrooms,
        garages: details.propertyDetails.value.carparking,
        totalParking: details.propertyDetails.value.carparking,
        price: details.propertyDetails.value.price,
        priceView: addPropertyFields.propertyInfo.propertyDetails.value.priceView,
        addressText: addPropertyFields.propertyInfo.address.value.full,
        addressSuburb:
          addPropertyFields.propertyInfo.address.value.suburb +
          ' ' +
          addPropertyFields.propertyInfo.address.value.state,
        images: imageGallery,
        agent: {
          firstName: agent.firstName.value,
          lastName: agent.lastName.value,
          telephone: agent.phone.value,
          email: agent.email.value,
          name: agent.firstName.value + ' ' + agent.lastName.value,
        },
        agency: users && users.agency ? users.agency : null,
      }
      setProperty(propDetails)
    }
  }, [addPropertyFields])

  useEffect(() => {
    if (property) {
      const getTransformations =
        property.propertyStatus === 'comingsoon'
          ? 'e_grayscale'
          : 'e_colorize:60,co_rgb:35C0CA'
      const imgBucket = []
      placeholderimages.map((item, i) => {
        imgBucket.push(item.replace('XTRANSFORMX', getTransformations))
      })
      setDefaultImages(imgBucket)
    }
  }, [property])

  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'images', imageGallery)
  }, [imageGallery])

  const handleImageSelect = (src) => {
    setImageGallery([src])
  }

  const handleMatchCount = (property) => {
    let count = 0
    if (filters && property) {
      filters.filters.advanceProperty.map((item, index) => {
        if (item.active && property.coefficients) {
          if (property.coefficients[item.key] >= 0.7) {
            count++
          }
        }
      })
    }
    return count
  }

  return (
    <div className={classes.root}>
      {property && (
        <Grid container spacing={6}>
          <Grid item md={4}>
            <div className={classes.labelMb}>
              <Typography>Property Listing Preview </Typography>
            </div>
            <UploadPropertyCard
              cardType={'list'}
              properties={{
                ...property,
                agent: {
                  ...property.agent,
                  image: addPropertyFields.agentInfo.image.value,
                },
              }}
              {...propertyConfigSetter(
                'card-type',
                property
                  ? property.subscriptionType
                    ? property.subscriptionType
                    : ''
                  : ''
              )}
              authentication={authentication}
              settings={settings}
              listingType={'properties'}
              handleMatchCount={handleMatchCount}
              setCoverCallback={() => false}
              enableCoverSelector={false}
            />
          </Grid>
          <Grid item md={8}>
            <div className={classes.labelMb}>
              <Typography>Please choose the door image below</Typography>
            </div>
            <Grid container spacing={4} className={classes.coverImageWrap}>
              {defaultImages &&
                defaultImages.map((src, idx) => (
                  <Grid
                    key={idx}
                    item
                    md={6}
                    className={imageGallery.includes(src) ? classes.activeCover : ''}
                  >
                    <div
                      className={classes.pListContainer}
                      onClick={() => handleImageSelect(src)}
                    >
                      <img
                        src={src}
                        height="100%"
                        width="100%"
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    filters: state.filters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddPropertyStep3))
