import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function ListIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <polygon style={{fillRule:"evenodd", clipRule:"evenodd"}} points="0,25.4 21.4,25.4 21.4,23.2 0,23.2 0,25.4 "/>

      <polygon style={{fillRule:"evenodd", clipRule:"evenodd"}} points="1.5,24 22.5,24 22.5,21.8 1.5,21.8 1.5,24 "/>
      <rect x="1.5" y="16.4" style={{fillRule:"evenodd", clipRule:"evenodd"}} width="21" height="2.2"/>
      <polygon style={{fillRule:"evenodd", clipRule:"evenodd"}} points="20.9,0 22.5,1.6 9,14.6 2.6,8.5 4.2,7 8.9,11.6 "/>
    </SvgIcon>
  );
}

export function ListIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ListIcon />
      <ListIcon color="primary" />
      <ListIcon color="secondary" />
      <ListIcon color="action" />
      <ListIcon color="disabled" />
      <ListIcon style={{ color: green[500] }} />
    </div>
  );
}
