import { FETCH } from "../../utils/api";
import {
  getUserRequest,
  getUserRequestSuccess,
  getUserRequestFail,
} from "../actions";

import { errorhandler } from ".";

import { apiEndpoints } from "../../utils/api/endpoints";

export function getUserDetails(id) {
  return (dispatch) => {
    dispatch(getUserRequest(id));
    FETCH(apiEndpoints.GET_USER + ':' + id)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(getUserRequestSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(getUserRequestFail(errorhandler(error)));
      });
  };
}
