/** API REQUEST */
export const REQUEST_SEARCH_AGENT = 'agent/REQUEST_SEARCH_AGENT'
export const REQUEST_SEARCH_AGENT_SUCCESS = 'agent/REQUEST_SEARCH_AGENT_SUCCESS'
export const REQUEST_SEARCH_AGENT_FAIL = 'agent/REQUEST_SEARCH_AGENT_FAIL'
export const REQUEST_AGENT_AGENCY_DATA = 'agent/REQUEST_AGENT_AGENCY_DATA'
export const REQUEST_AGENT_AGENCY_DATA_SUCCESS =
  'agent/REQUEST_AGENT_AGENCY_DATA_SUCCESS'
export const REQUEST_AGENT_AGENCY_DATA_FAIL = 'agent/REQUEST_AGENT_AGENCY_DATA_FAIL'

/** GET AGENT DETAILS */
export const REQUEST_AGENT_DETAILS = 'agent/REQUEST_AGENT_DETAILS'
export const REQUEST_AGENT_DETAILS_SUCCESS = 'agent/REQUEST_AGENT_DETAILS_SUCCESS'
export const REQUEST_AGENT_DETAILS_FAIL = 'agent/REQUEST_AGENT_DETAILS_FAIL'
export const REQUEST_AGENT_LIST = 'agent/REQUEST_AGENT_LIST'
export const REQUEST_AGENT_LIST_SUCCESS = 'agent/REQUEST_AGENT_LIST_SUCCESS'
export const REQUEST_AGENT_LIST_FAIL = 'agent/REQUEST_AGENT_LIST_FAIL'
export const REQUEST_AGENCY_LIST = 'agent/REQUEST_AGENCY_LIST'
export const REQUEST_AGENCY_LIST_SUCCESS = 'agent/REQUEST_AGENCY_LIST_SUCCESS'
export const REQUEST_AGENCY_LIST_FAIL = 'agent/REQUEST_AGENCY_LIST_FAIL'

export const SET_SEARCH_AGENT_PARAMS = 'agent/SET_SEARCH_AGENT_PARAMS'
export const CLEAR_SEARCH_AGENT_PARAMS = 'agent/CLEAR_SEARCH_AGENT_PARAMS'

/* RATE AGENT */
export const REQUEST_SET_AGENT_RATING = 'agent/rate/REQUEST_SET_AGENT_RATING'
export const REQUEST_SET_AGENT_RATING_SUCCESS =
  'agent/rate/REQUEST_SET_AGENT_RATING_SUCCESS'
export const REQUEST_SET_AGENT_RATING_FAIL = 'agent/rate/REQUEST_SET_AGENT_RATING_FAIL'

export const REQUEST_GET_AGENT_PROPERTIES = 'agent/REQUEST_GET_AGENT_PROPERTIES'
export const REQUEST_GET_AGENT_PROPERTIES_SUCCESS =
  'agent/REQUEST_GET_AGENT_PROPERTIES_SUCCESS'
export const REQUEST_GET_AGENT_PROPERTIES_FAIL =
  'agent/REQUEST_GET_AGENT_PROPERTIES_FAIL'

/* AGENT PAUSE/UNPAUSE PROPERTY */
export const REQUEST_AGENT_SET_PAUSE_PROPERTY = 'agent/REQUEST_AGENT_SET_PAUSE_PROPERTY'
export const REQUEST_AGENT_SET_PAUSE_PROPERTY_SUCCESS =
  'agent/REQUEST_AGENT_SET_PAUSE_PROPERTY_SUCCESS'
export const REQUEST_AGENT_SET_PAUSE_PROPERTY_FAIL =
  'agent/REQUEST_AGENT_SET_PAUSE_PROPERTY_FAIL'
export const REQUEST_AGENT_CLEAR_PAUSE_PROPERTY_STATUS =
  'agent/REQUEST_AGENT_CLEAR_PAUSE_PROPERTY_STATUS'

/* AGENT CREATE PROPERTY */
export const REQUEST_CREATE_PROPERTY = 'agent/REQUEST_CREATE_PROPERTY'
export const REQUEST_CREATE_PROPERTY_SUCCESS = 'agent/REQUEST_CREATE_PROPERTY_SUCCESS'
export const REQUEST_CREATE_PROPERTY_FAIL = 'agent/REQUEST_CREATE_PROPERTY_FAIL'

/* AGENT Upload Statement of Intent*/
export const REQUEST_UPLOAD_SO_INTENT = 'agent/REQUEST_UPLOAD_SO_INTENT'
export const REQUEST_UPLOAD_SO_INTENT_SUCCESS = 'agent/REQUEST_UPLOAD_SO_INTENT_SUCCESS'
export const REQUEST_UPLOAD_SO_INTENT_FAIL = 'agent/REQUEST_UPLOAD_SO_INTENT_FAIL'

export const REQUEST_STATISTICS = 'agency/REQUEST_STATISTICS'
export const REQUEST_STATISTICS_SUCCESS = 'agency/REQUEST_STATISTICS_SUCCESS'
export const REQUEST_STATISTICS_FAIL = 'agency/REQUEST_STATISTICS_FAIL'

/* REQUEST TO CONFIRM PROPERTY*/
export const REQUEST_GET_TO_CONFIRM_PROPERTY = 'agent/REQUEST_GET_TO_CONFIRM_PROPERTY'
export const REQUEST_GET_TO_CONFIRM_PROPERTY_SUCCESS =
  'agent/REQUEST_GET_TO_CONFIRM_PROPERTY_SUCCESS'
export const REQUEST_GET_TO_CONFIRM_PROPERTY_FAIL =
  'agent/REQUEST_GET_TO_CONFIRM_PROPERTY_FAIL'

/* REQUEST TO APPROVE PROPERTY*/
export const REQUEST_APPROVE_PROPERTY = 'agent/REQUEST_APPROVE_PROPERTY'
export const REQUEST_APPROVE_PROPERTY_SUCCESS = 'agent/REQUEST_APPROVE_PROPERTY_SUCCESS'
export const REQUEST_APPROVE_PROPERTY_FAIL = 'agent/REQUEST_APPROVE_PROPERTY_FAIL'

/* REQUEST TO EDIT PROPERTY*/
export const REQUEST_EDIT_PROPERTY = 'agent/REQUEST_EDIT_PROPERTY'
export const REQUEST_EDIT_PROPERTY_SUCCESS = 'agent/REQUEST_EDIT_PROPERTY_SUCCESS'
export const REQUEST_EDIT_PROPERTY_FAIL = 'agent/REQUEST_EDIT_PROPERTY_FAIL'

/* REQUEST TO EDIT PROPERTY*/
export const REQUEST_DELETE_PROPERTY = 'agent/REQUEST_DELETE_PROPERTY'
export const REQUEST_DELETE_PROPERTY_SUCCESS = 'agent/REQUEST_DELETE_PROPERTY_SUCCESS'
export const REQUEST_DELETE_PROPERTY_FAIL = 'agent/REQUEST_DELETE_PROPERTY_FAIL'

/* REQUEST AGENT STATE*/
export const REQUEST_AGENT_STATE = 'agent/REQUEST_AGENT_STATE'
export const REQUEST_AGENT_STATE_SUCCESS = 'agent/REQUEST_AGENT_STATE_SUCCESS'
export const REQUEST_AGENT_STATE_FAIL = 'agent/REQUEST_AGENT_STATE_FAIL'

/* REQUEST AGENT ACTIVATE*/
export const REQUEST_AGENT_ACTIVATE = 'agent/REQUEST_AGENT_ACTIVATE'
export const REQUEST_AGENT_ACTIVATE_SUCCESS = 'agent/REQUEST_AGENT_ACTIVATE_SUCCESS'
export const REQUEST_AGENT_ACTIVATE_FAIL = 'agent/REQUEST_AGENT_ACTIVATE_FAIL'

export const CLEAR_STATUS = 'agent/CLEAR_STATUS'

export const CLEAR_AGENT_STATE = 'agent/CLEAR_AGENT_STATE'
