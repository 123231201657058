import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { set } from 'lodash';
import { SuperBreadCrumbs, ManageAgenciesTable, Snackybar } from 'components/Web/Superadmin';

import { Loader } from 'components/Common';
/** redux */
import { superadminActions } from 'store/actions';

import { requestGETAPISuperadmin } from 'store/api';
import { getStatus, getErrors, getAgencies } from 'store/selectors/superadmin.selectors';
import useHandleManageAgencyRowCount from 'utils/customHooks/useHandleManageAgencyRowCount';
import useHandleManageAgencyPageCount from 'utils/customHooks/useHandleManageAgencyPageCount';
import useHandleManageAgencyButtons from 'utils/customHooks/useHandleManageAgencyButtons';
import useHandleManageAgencyFilters from 'utils/customHooks/useHandleManageAgencyFilters';
import useHandleManageAgencySort from 'utils/customHooks/useHandleManageAgencySort';
import useHandleManageAgencySearchField from 'utils/customHooks/useHandleManageAgencySearchField';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
});

const SuperadminAgency = (props) => {
  const { classes, history } = props;

  const dispatch = useDispatch();

  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getErrors);
  const AGENCIES = useSelector(getAgencies);

  const manageAgenciesButton = [
    {
      id: 0,
      label: 'Paid',
      active: true,
    },
    {
      id: 1,
      label: 'Free',
      active: false,
    },
  ];

  const initStatus = {
    subscription: 'ALL',
    docusign: 'ALL',
    payment: 'ALL',
    adminEmail: 'ALL',
    active: 'ALL',
  };

  const initSort = [
    {
      id: 0,
      key: 'registeredTradeName',
      sort: false,
      sortOrder: null,
    },
    {
      id: 1,
      key: 'address',
      sort: false,
      sortOrder: null,
    },
    {
      id: 2,
      key: 'adminEmail',
      sort: false,
      sortOrder: null,
    },
    {
      id: 3,
      key: 'subscription.billingDate',
      sort: false,
      sortOrder: null,
    },
  ];

  const [rowCount, setRowCount] = useHandleManageAgencyRowCount();
  const [pageCount, setPageCount] = useHandleManageAgencyPageCount();
  const [manageButtons, setManageButtons] = useHandleManageAgencyButtons();
  const [manageFilters, setManageFilters] = useHandleManageAgencyFilters();
  const [manageSort, setManageSort] = useHandleManageAgencySort();
  const [manageSearchField, setManageSearchField] = useHandleManageAgencySearchField();

  const [agencyNameFilter, setAgencyNameFilter] = useState(manageSearchField ?? '');
  const [loading, setLoading] = useState(false);
  const [statuses, setStatuses] = useState(manageFilters ?? initStatus);
  const [sort, setSort] = useState(manageSort ?? initSort);
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  });

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
  ];

  useEffect(() => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    handleGetAgencyListFilter(id, agencyNameFilter);
    /**
     * * Set Default Values
     */
    handleClearError();
    handleClearStatus();
  }, []);

  useEffect(() => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    handleGetAgencyListFilter(id, agencyNameFilter);
  }, [pageCount, rowCount]);

  window.onbeforeunload = (e) => {
    return () => {
      const id = manageButtons && manageButtons[0].active ? 0 : 1;
      handleGetAgencyListFilter(id, agencyNameFilter);
      /**
       * * Set Default Values
       */
      handleClearError();
      handleClearStatus();
    };
  };

  useEffect(() => {
    if (STATUS === 'superadmin/SUPERADMIN_GET_ALL_AGENCY_REQUEST') {
      setLoading(true);
    }
    if (STATUS === 'superadmin/SUPERADMIN_GET_ALL_AGENCY_SUCCESS') {
      setLoading(false);
    }
    if (STATUS === 'superadmin/SUPERADMIN_GET_ALL_AGENCY_FAILED') {
      setLoading(false);
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR?.message : ERROR,
      });
      handleClearError();
    }
    handleClearStatus();
  }, [STATUS, ERROR]);

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    });
  };

  /** API Request */
  const handleGetAgencyListFilter = (id, name) => {
    let queryParams = {
      page: pageCount + 1,
      paying: id === 0 ? true : false,
      limit: rowCount,
    };

    if (name && name.length > 3) {
      queryParams.agencyName = agencyNameFilter;
    }

    if (id === 0 && statuses.subscription && statuses.subscription !== 'ALL') {
      queryParams['subscriptionStatus[]'] = statuses.subscription;
    }

    if (id === 0 && statuses.docusign && statuses.docusign !== 'ALL') {
      queryParams['docusignStatus[]'] = statuses.docusign;
    }

    if (id === 0 && statuses.payment && statuses.payment !== 'ALL') {
      queryParams['subscriptionPaymentStatus[]'] = statuses.payment;
    }

    if (id === 1 && statuses.adminEmail && statuses.adminEmail !== 'ALL') {
      queryParams['emailUpdated'] = statuses.adminEmail;
    }

    if (id === 1 && statuses.active && statuses.active !== 'ALL') {
      queryParams['activated'] = statuses.active;
    }

    if (sort.length > 0) {
      for (let index = 0; index < sort.length; index++) {
        if (sort[index].sort) {
          if (!queryParams['sort[]']) {
            queryParams['sort[]'] = [];
          }

          queryParams['sort[]'].push(sort[index].sortOrder === 'asc' ? `${sort[index].key}` : `-${sort[index].key}`);
        }
      }
    }

    let queryString = Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    let request = {
      query: `?${queryString}`,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_ALL_AGENCY'));
  };

  const handleGetAgencyListClear = (id, name) => {
    let queryParams = {
      page: pageCount + 1,
      paying: id === 0 ? true : false,
      limit: rowCount,
    };

    if (name && name.length > 3) {
      queryParams.agencyName = agencyNameFilter;
    }

    let queryString = Object.keys(queryParams)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    let request = {
      query: `?${queryString}`,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_ALL_AGENCY'));
  };

  const handleGetAgencyTAB = (id, name, count, row) => {
    let queryParams = {
      page: count,
      paying: id === 0 ? true : false,
      limit: row,
    };

    if (name && name.length > 3) {
      queryParams.agencyName = agencyNameFilter;
    }

    if (id === 0 && statuses.subscription && statuses.subscription !== 'ALL') {
      queryParams['subscriptionStatus[]'] = statuses.subscription;
    }

    if (id === 0 && statuses.docusign && statuses.docusign !== 'ALL') {
      queryParams['docusignStatus[]'] = statuses.docusign;
    }

    if (id === 0 && statuses.payment && statuses.payment !== 'ALL') {
      queryParams['subscriptionPaymentStatus[]'] = statuses.payment;
    }

    if (id === 1 && statuses.adminEmail && statuses.adminEmail !== 'ALL') {
      queryParams['emailUpdated'] = statuses.adminEmail;
    }

    if (id === 1 && statuses.active && statuses.active !== 'ALL') {
      queryParams['activated'] = statuses.active;
    }

    if (sort.length > 0) {
      for (let index = 0; index < sort.length; index++) {
        if (sort[index].sort) {
          if (!queryParams['sort[]']) {
            queryParams['sort[]'] = [];
          }

          queryParams['sort[]'].push(sort[index].sortOrder === 'asc' ? `${sort[index].key}` : `-${sort[index].key}`);
        }
      }
    }

    let queryString = Object.keys(queryParams)
      .map((key) => {
        if (Array.isArray(queryParams[key])) {
          return queryParams[key].map((value) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
      })
      .join('&');

    let request = {
      query: `?${queryString}`,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_ALL_AGENCY'));
  };

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'));
  };

  const handleChangeStatus = (event, label) => {
    setStatuses({
      ...statuses,
      [label]: event.target.value,
    });
  };

  const clearFilter = () => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    setStatuses(initStatus);
    setManageFilters(initStatus);
    setSort(initSort);
    setManageSort(initSort);
    handleGetAgencyListClear(id, agencyNameFilter);
  };

  const applyFilter = () => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    setManageFilters(statuses);
    setSort(initSort);
    setManageSort(initSort);
    handleGetAgencyListFilter(id, agencyNameFilter);
  };

  const clearChangeNameFilter = () => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    setAgencyNameFilter('');
    setManageSearchField('');
    handleGetAgencyListFilter(id, '');
  };

  const handleChangeNameFilter = (event) => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    setAgencyNameFilter(event.target.value);

    if (event.target.value.length === 0) {
      setManageSearchField('');
      handleGetAgencyListFilter(id, event.target.value);
    }
  };

  const handleChangeNameFilterSearch = () => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    setManageSearchField(agencyNameFilter);
    handleGetAgencyListFilter(id, agencyNameFilter);
  };

  const handleSortRequest = (key) => {
    const id = manageButtons && manageButtons[0].active ? 0 : 1;
    let newSort = sort;

    let findData = newSort.find((x) => x.key === key);
    for (let i = 0; i < newSort.length; i++) {
      if (newSort[i].key == findData.key) {
        if (newSort[i].sortOrder === null) {
          newSort[i].sortOrder = 'asc';
          newSort[i].sort = true;
        } else if (newSort[i].sortOrder === 'asc') {
          newSort[i].sortOrder = 'desc';
          newSort[i].sort = true;
        } else {
          newSort[i].sortOrder = null;
          newSort[i].sort = false;
        }
      }
    }

    setRowCount(rowCount);
    setPageCount(0);
    setSort(newSort);
    setManageSort(newSort);
    handleGetAgencyTAB(id, agencyNameFilter, 1, rowCount);
  };

  const handleChangeNameFilterPress = (event) => {
    if (event.keyCode == 13) {
      const id = manageButtons && manageButtons[0].active ? 0 : 1;
      setAgencyNameFilter(event.target.value);

      if (event.target.value.length > 3) {
        handleGetAgencyListFilter(id, event.target.value);
      }
    }
  };

  const changeManageTable = (event, id) => {
    if (id === 0) {
      setManageButtons([
        {
          id: 0,
          label: 'Paid',
          active: true,
        },
        {
          id: 1,
          label: 'Free',
          active: false,
        },
      ]);
    } else {
      setManageButtons([
        {
          id: 0,
          label: 'Paid',
          active: false,
        },
        {
          id: 1,
          label: 'Free',
          active: true,
        },
      ]);
    }
    setRowCount(30);
    setPageCount(0);
    setStatuses(initStatus);
    setManageFilters(initStatus);
    setSort(initSort);
    setManageSort(initSort);
    setAgencyNameFilter('');
    setManageSearchField('');
    handleGetAgencyTAB(id, '', 1, 30);
  };

  return (
    <div className={classes.root}>
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <SuperBreadCrumbs section={'Manage Agencies'} crumbsLinks={CRUMBS} />
      <ManageAgenciesTable
        section={'Manage Agencies'}
        // tableData={AGENCIES?.data?.entities ? _.orderBy(AGENCIES?.data?.entities, ['createdAt'], ['desc']) : null}
        tableData={AGENCIES?.data?.entities ? AGENCIES?.data?.entities : null}
        tableDataPageCount={pageCount}
        tableDataPageRow={rowCount}
        pagination={{ totalItemCount: AGENCIES?.data?.totalResults }}
        pageSize={10}
        rowsPerPageOptions={[10, 30, 50, 80, 100, { value: AGENCIES?.data?.totalResults, label: 'All' }]}
        statuses={statuses}
        sort={sort}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
        handleChangeStatus={handleChangeStatus}
        agencyNameFilter={agencyNameFilter}
        handleChangeNameFilter={handleChangeNameFilter}
        handleChangeNameFilterPress={handleChangeNameFilterPress}
        handleChangeNameFilterSearch={handleChangeNameFilterSearch}
        handleSortRequest={handleSortRequest}
        manageButtons={manageButtons ?? manageAgenciesButton}
        changeManageTable={changeManageTable}
        clearChangeNameFilter={clearChangeNameFilter}
      />
    </div>
  );
};
export default withStyles(styles)(SuperadminAgency);
