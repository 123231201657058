import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '@material-ui/icons/Close'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  toggleThemeMode,
  toggleSignUp,
  navigateReset,
  logoutRequest,
  toggleForgotPassword,
} from '../../../store/actions'

import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Register from './Register'
import HeadingContent from './HeadingContent'

import {
  Paper,
  AppBar,
  Tabs,
  Tab,
  Container,
  Button,
  Grid,
  IconButton,
  withStyles,
} from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    // padding: 0,
    // paddingTop: theme.spacing(2),
    position: 'relative',
    borderRadius: 38,
    '&:hover': {
      outline: 'none',
    },
    overflow: 'hidden',
    maxWidth: 500,
    margin: '30px auto 0',
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
    boxShadow: 'none',
    padding: 0,
    zIndex: 100,
  },
  container: {
    padding: 0,
  },
  profileContainer: {
    padding: '30px 24px 50px',
  },
  avatar: {},
  formWrapper: {
    paddingTop: 50,
  },
  closeicon: {
    position: 'absolute',
    top: 6,
    right: 5,
  },
})

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
  },
})(Button)

const MainHeader = withStyles({
  root: {
    boxShadow: 'none',
    padding: '10px 0px',
    position: 'relative',
  },
})(AppBar)

const TabHeader = withStyles({
  root: {
    justifyContent: 'space-evenly',
    padding: '0',
  },
})(Tabs)

const StyledTab = withStyles({
  root: {
    width: 'calc(50% - 10px)',
    margin: '0 auto',
    backgroundColor: '#F5F5F5',
  },
  selected: {
    color: '#000 !important',
    backgroundColor: '#fff',
  },
})(Tab)

const BackButton = withStyles({
  root: {
    width: '100px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const ScrollContainer = withStyles({
  root: {
    // overflowY:'auto', overflowX:'hidden', maxHeight:'calc(90vh - 104px)', width:'100%'
  },
})(Container)

const Authentication = (props) => {
  const { classes, type } = props

  /** redux props */
  const {
    settings,
    user,
    authentication,
    toggleForgotPassword,
    goBack,

    location,
  } = props
  const [routerChange] = useRouterChange()
  /** redux api actions */
  const { toggleSignUp, navigateResetRedux, logoutUser } = props

  const [value, setValue] = React.useState(0)
  const [isForgotPasswordView, setIsForgotPasswordView] = React.useState(false)
  const [isLogged, setIsLogged] = React.useState(false)
  const [referenceOrigin, setReferenceOrigin] = React.useState(null)

  React.useEffect(() => {
    /** Check if Token Exist */
    if (authentication.token) {
      setIsLogged(true)
      toggleSignUp(false)
    } else {
      setIsLogged(false)
    }
    checkAuth()
  }, [authentication])

  React.useEffect(() => {
    checkAuth()
    return () => {
      /**
       * Reset Redux Values (API Error Return)
       * */
      navigateResetRedux()
    }
  }, [])

  const checkAuth = () => {
    if (!referenceOrigin) {
      setReferenceOrigin(props?.history?.location?.state?.origin)
    }
    if (
      authentication?.token &&
      referenceOrigin &&
      (referenceOrigin === '/login' || referenceOrigin === '/signup')
    ) {
      routerChange('/')
    } else if (authentication.token && referenceOrigin) {
      // routerChange(props.history.location.state.origin)

      if (
        authentication?.token?.user?.userType === 'Agent' ||
        authentication?.token?.user?.userTypeList?.some((i) => ['Agent'].includes(i))
      ) {
        if (routerChange) {
          routerChange('/agent/dashboard')
        }
      } else if (
        authentication?.token?.user?.userType === 'Agency' ||
        authentication?.token?.user?.userTypeList?.some((i) =>
          ['Agency', 'AgencyAdmin'].includes(i)
        )
      ) {
        if (routerChange) {
          routerChange('/agency/dashboard')
        }
      } else {
        if (referenceOrigin) {
          routerChange(referenceOrigin)
        } else {
          routerChange('/')
        }
      }
    }
  }

  useEffect(() => {
    if (location && location.path === '/login') {
      setValue(0)
    } else if (location && location.path === '/signup') {
      setValue(1)
    }
  }, [location])

  const handleChange = (event, newValue) => {
    setValue(newValue)

    if (newValue === 0) {
      routerChange('/login')
    } else {
      routerChange('/signup')
    }

    /**
     * Reset Redux Values (API Error Return)
     * */
    navigateResetRedux()
  }

  const handleForgotPasswordView = (value) => {
    setIsForgotPasswordView(value)
    toggleForgotPassword(value)

    /**
     * Reset Redux Values (API Error Return)
     * */
    navigateResetRedux()
  }

  function renderProfile() {
    return (
      <ScrollContainer className={classes.profileContainer}>
        <Grid item xs={12}>
          <HeadingContent
            title={'Profile'}
            // content={`User Profile`}
          />
          <StyledButton
            color="primary"
            variant="contained"
            disabled={false}
            onClick={() => {
              logoutUser()
              //toggleSignUp()
            }}
          >
            Log out
          </StyledButton>
        </Grid>
      </ScrollContainer>
    )
  }

  function renderForms() {
    return (
      <div className={classes.formWrapper}>
        <AppBar className={classes.appBar} position="static">
          <TabHeader
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
            variant="fullWidth"
          >
            {location && location.path === '/login' ? (
              <StyledTab label="LOG IN" />
            ) : (
              <StyledTab
                label="LOG IN"
                onClick={() => {
                  toggleSignUp(false, 0)
                  routerChange('/login', {
                    origin:
                      props?.history?.location?.pathname ?? window.location.pathname,
                  })
                }}
              />
            )}
            {location && location.path === '/signup' ? (
              <StyledTab label="SIGN UP" />
            ) : (
              <StyledTab
                label="SIGN UP"
                onClick={() => {
                  toggleSignUp(false, 1)
                  routerChange('/signup', {
                    origin:
                      props?.history?.location?.pathname ?? window.location.pathname,
                  })
                }}
              />
            )}
          </TabHeader>
        </AppBar>
        <ScrollContainer className={classes.container}>
          {value === 0 ? (
            settings && settings.showForgotPassword ? (
              <ForgotPassword
                setIsForgotPasswordView={handleForgotPasswordView}
                tabChange={handleChange}
              />
            ) : (
              <Login
                setIsForgotPasswordView={handleForgotPasswordView}
                tabChange={handleChange}
                changeRoute={routerChange}
                goBack={goBack}
              />
            )
          ) : value === 1 ? (
            <Register tabChange={handleChange} />
          ) : null}
        </ScrollContainer>
      </div>
    )
  }

  return (
    <Paper className={classes.root} elevation={3}>
      <MainHeader className={classes.appBar} position="static">
        <IconButton aria-label="close" className={classes.closeicon} onClick={goBack}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </MainHeader>
      {!isLogged && renderForms()}
    </Paper>
  )
}

Authentication.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
    authentication: state.authentication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleForgotPassword: (checked) => toggleForgotPassword(checked),
      navigateResetRedux: () => navigateReset(),
      logoutUser: () => logoutRequest(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Authentication))
