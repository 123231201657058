import React, { useRef, useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import styled from 'styled-components'

import Searchbar from '../InlineSearchbar'
import PropertySearchFilter from '../../Web/Searchbar/PropertySearchFilter'
import Loader from '../Loader'
import AlertNotification from '../../Mobile/Form/AlertNotification'

import { scrollToRef } from '../../Mobile/ScrollEvents'
import DynamicIcons from '../DynamicIcons'

import _ from 'lodash'

/** api redux request */
import {
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
} from '../../../store/api'

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  toggleFilterWeb,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters,
} from '../../../store/actions'

/** Object Filters */
import { formatRequest } from '../../../utils/search.utils'

import { Typography, Button, withStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { requestPUTAPIMpp } from 'store/api/mpp.api'
import { useRouterChange } from 'utils/router.utils'

const BackButton = withStyles({
  root: {
    //width: '100%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const styles = (theme) => ({
  root: {
    //height: window.innerHeight,
    backgroundColor: '#f5f5f5',
    position: 'relative',
    zIndex: 1,
    '& .MuiBackdrop-root': {
      width: '100%',
      maxWidth: '100%',
      left: 0,
    },
  },
  content: {
    //height: '100%',
    backgroundColor: '#f5f5f5',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    // transform: 'translateY(-56px)'
  },
})

const ValidationSearch = styled.div`
  position: absolute;
  bottom: -5px;
  left: 90px;
  border-radius: 3px;
  opacity: 0;
  background-color: rgb(17, 192, 201);
  padding: 10px;
  z-index: 9;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 0.3s ease;
  &.active {
    transform: translateY(0px);
    opacity: 1;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent rgb(17, 192, 201) transparent;
  }
  p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
`

const SearchResultsFilter = (props) => {
  const {
    classes,
    authentication,
    settings,
    property,
    filters,
    toggleSignUp,
    toggleFilter,
    toggleFilterWeb,
    savePropertySearch,
    searchProperty,
    clearCurrentProperty,
    setPropertySearch,
    setIncludeNearbyFilter,
    setSearchFilters,
    setSearchSortingFilters,
    getRoutePath,
    showFilters,
    showSearchBar,
    invalidSearch,
    setInvalidSearch,
  } = props
  const [routerChange] = useRouterChange()
  const topContainer = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d) //add `false` as second parameter to scroll without transition.

  const [isFiltered, setIsFiltered] = useState({ isActive: false, suburbs: null })
  const [isLoading, setIsLoading] = useState(true)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [searchResultProperties, setSearchResultProperties] = useState([])
  const dispatch = useDispatch()
  const saveProperty = (params) =>
    dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'))

  React.useEffect(() => {
    /** Initial Scroll to Top */
    executeTopScroll(true)

    /** Clear Last Selected Property */
    clearCurrentProperty()

    /** Clear Redux Property Search */
    //clearPropertySuburb();

    /** Request Property Call */
    apiRequestSearchProperty()
  }, [])

  React.useEffect(() => { }, [isFiltered])
  /* handle properties callback */
  React.useEffect(() => {
    /** success handler save search property  */
    if (
      property &&
      property.payload &&
      property.payload.data &&
      property.payload.data &&
      property.payload.data.message === 'POST request successful'
    ) {
      //handleNotification(true, {message: 'Successful', severity: 'success'})
      //setIsLoading(false);
      /** refresh data */
      apiRequestSearchProperty()
    }
    /** property data setter */
    if (property) {
      if (
        property.payload &&
        property.payload.data &&
        property.payload.data.ok &&
        isLoading
      ) {
        setIsLoading(false)
        if (
          property &&
          property.properties &&
          property.properties.data &&
          property.properties.data.data
        ) {
          setSearchResultProperties(property.properties.data.data)

          if (filters) {
            const filterData = Object.assign([], filters.suburbs)
            if (filterData && filterData.length > 0) {
              setIsFiltered({ isActive: true, suburbs: filterData })
            } else {
              setIsFiltered({ isActive: false, suburbs: null })
            }
            if (filters.sorting) {
              handleSortBy(filters.sorting)
            }
          } else {
            setIsFiltered({ isActive: false, suburbs: null })
          }
        }
      }
    }
    /** error hanlder property search */
    if (property && property.property_search_error && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }
    /** error hanlder save property */
    if (property && property.propert_save_property_error && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }

    /** error hanlder save search property */
    if (property && property.propert_save_search_error && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }
  }, [property])

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const handleSortBy = (data, propertiesData) => {
    setSearchSortingFilters(data)
    const propertyData = Object.assign({}, property.properties.data.data)
    const propertiesFilters = Object.assign([], propertyData.propertiesFilters)
    let type = _.filter(data, function (o) {
      return o.active
    })
    let sortedData = propertiesFilters

    if (data && type && type.length > 0) {
      switch (type[0].value) {
        case 'Relevance':
          sortedData = propertiesFilters
          break
        case 'Newest':
          sortedData =
            getRoutePath() === 'sold'
              ? sortData(propertiesFilters, ['soldDate'], 'desc')
              : sortData(propertiesFilters, ['publishDate'], 'desc')
          break
        case 'Price (High to Low)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                propertiesFilters,
                ['rentalPrice', 'publishDate'],
                ['desc', 'desc']
              )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['desc', 'desc'])
          break
        case 'Price (Low to High)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                propertiesFilters,
                ['rentalPrice', 'publishDate'],
                ['asc', 'asc']
              )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['asc', 'asc'])
          break
        case 'Bedrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bedrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Bathrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bathrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Sqm':
          sortedData = sortData(
            propertiesFilters,
            ['landSize', 'price'],
            ['desc', 'desc']
          )
          break
        default:
          sortedData = propertiesFilters
      }

      propertyData.propertiesFilters = sortedData
      setSearchResultProperties(propertyData)
    }
  }

  const sortData = (data, type, order) => {
    const sortBy = type
    return _.orderBy(
      data,
      sortBy.map((s) => {
        return (r) => {
          return r[s] ? r[s] : ''
        }
      }),
      order
    )
  }

  /** Search Property */
  async function apiRequestSearchProperty() {
    setIsLoading(true)

    let params = await formatRequest('buy', filters)
    searchProperty(params)
  }

  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    }

    saveProperty(params)
  }

  /** Save Property Search API */
  function apiRequestSaveSearch(params) {
    setIsLoading(true)

    savePropertySearch(filters.suburbs)
  }

  function saveFilters(params) {
    if (filters && filters.filters) {
      let obj = Object.assign({}, filters.filters)
      if (params) {
        obj.propertyDetails.bedrooms = params[0].selected
        obj.propertyDetails.bathrooms = params[2].selected
        obj.propertyDetails.price.min = params[1].values[0].value.min
        obj.propertyDetails.price.max = params[1].values[0].value.max
        if (params[3].values) {
          obj.propertyType = params[3].values
          obj.propertyType.map((item, index) => {
            item.active = params[3].values[index].active
          })
        }
        setSearchFilters(obj)
      }
    }
  }

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <div ref={topContainer} className={classes.topAnchor}></div>

      {/* <div className={classes.backButtonContainer}>
        <BackButton 
          variant="text" 
          disableRipple={true} 
          fullWidth={false} 
          onClick={()=> routerChange('/')}>
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
      </div> */}

      <Searchbar
        type={'search'}
        sortType={'search'}
        showSearchBar={showSearchBar ? showSearchBar : true}
        showFilters={showFilters ? showFilters : true}
        navigateFilterButtonTo={'/buy/filters'}

        authentication={authentication}
        settings={settings}
        toggleSignUp={toggleSignUp}
        toggleFilter={toggleFilter}
        toggleFilterWeb={toggleFilterWeb}
        apiRequestSaveSearch={apiRequestSaveSearch}
        apiRequestSearchProperty={apiRequestSearchProperty}
        setPropertySearch={setPropertySearch}
        setIncludeNearbyFilter={setIncludeNearbyFilter}
        setSearchSortingFilters={handleSortBy}
        filters={filters}
        saveFilters={saveFilters}
        properties={searchResultProperties}
        isFiltered={isFiltered}
        path={getRoutePath()}
        onFocus={() => setInvalidSearch(false)}
      />

      {/* <ValidationSearch className={invalidSearch ? 'active' : ''}>
        <p>Please add the location you are looking for</p>
      </ValidationSearch> */}

      {/* <div className={classes.content}>
        <PropertySearchFilter />        
      </div> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      toggleFilterWeb: (checked) => toggleFilterWeb(checked),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchProperty: (params) => requestPropertySearch(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setSearchSortingFilters: (params) => setSortingFilters(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchResultsFilter))
