import FiltersSearch from '../../containers/Mobile/FiltersSearch'
import Property from '../../containers/Mobile/Property'
import PropertyMapView from '../../containers/Mobile/PropertyMapView'
import PropertyStreetView from '../../containers/Mobile/PropertyStreetView'
import MapView from '../../containers/Mobile/MapView'
import SearchResultsV3 from 'containers/Mobile/SearchResultsV3'

let routes = [
  {
    path: '/coming-soon/map-view',
    component: MapView,
  },
  {
    path: '/hush-hush/map-view',
    component: MapView,
  },
  {
    path: '/buy/map-view',
    component: MapView,
  },
  {
    path: '/off-market/map-view',
    component: MapView,
  },
  {
    path: '/rent/map-view',
    component: MapView,
  },
  {
    path: '/sold/map-view',
    component: MapView,
  },
  {
    path: '/buy',
    component: SearchResultsV3,
  },
  {
    path: '/buy/filters',
    component: FiltersSearch,
  },
  {
    path: '/buy/:id',
    component: Property,
  },
  {
    path: '/buy/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/buy/:id/location/street',
    component: PropertyStreetView,
  },
  {
    path: '/off-market',
    component: SearchResultsV3,
  },
  {
    path: '/off-market/filters',
    component: FiltersSearch,
  },
  {
    path: '/off-market/:id',
    component: Property,
  },
  {
    path: '/off-market/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/off-market/:id/location/street',
    component: PropertyStreetView,
  },
  {
    path: '/rent',
    component: SearchResultsV3,
  },
  {
    path: '/rent/filters',
    component: FiltersSearch,
  },
  {
    path: '/rent/:id',
    component: Property,
  },
  {
    path: '/rent/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/rent/:id/location/street',
    component: PropertyStreetView,
  },
  {
    path: '/sold',
    component: SearchResultsV3,
  },
  {
    path: '/sold/filters',
    component: FiltersSearch,
  },
  {
    path: '/sold/:id',
    component: Property,
  },
  {
    path: '/sold/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/sold/:id/location/street',
    component: PropertyStreetView,
  },
  {
    path: '/hush-hush',
    component: SearchResultsV3,
  },
  {
    path: '/hush-hush/filters',
    component: FiltersSearch,
  },
  {
    path: '/hush-hush/:id',
    component: Property,
  },
  {
    path: '/hush-hush/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/hush-hush/:id/location/street',
    component: PropertyStreetView,
  },
  {
    path: '/coming-soon',
    component: SearchResultsV3,
  },
  {
    path: '/coming-soon/filters',
    component: FiltersSearch,
  },
  {
    path: '/coming-soon/:id',
    component: Property,
  },
  {
    path: '/coming-soon/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/coming-soon/:id/location/street',
    component: PropertyStreetView,
  },
]

export default routes
