import AgentSearch from 'containers/Web/AgentSearch';
import AgentResults from 'containers/Web/AgentResults';
import AgentDetails from 'containers/Web/AgentDetails';
import MyProperties from 'components/Web/Agent/AgentMyProperties';
import AgentDashboard from 'containers/Web/AgentDashboardv2';
import AccountSettings from 'containers/Web/AccountSettings';
import AgentAddProperty from 'containers/Web/AgentProperties';
import AgentLogin from 'containers/Web/Agent/AgentLogin';
// import AgentUpdateProperty from 'containers/Web/Agent/AgentUpdateProperty'
// import AgentEditProperty from 'containers/Web/Agent/AgentEditProperty'

let routes = [
  {
    path: '/agent',
    component: AgentSearch,
  },
  {
    path: '/agent/results',
    component: AgentResults,
  },
  {
    path: '/agent/results/:id',
    component: AgentDetails,
  },
  {
    path: '/agent/my-properties',
    component: MyProperties,
  },
  {
    path: '/agent/dashboard',
    component: AgentDashboard,
  },
  {
    path: '/agent/account-settings',
    component: AccountSettings,
  },
  {
    path: '/agent/add-property',
    component: AgentAddProperty,
  },
  // {
  //   path: '/agent/update-property/:id',
  //   component: AgentUpdateProperty,
  // },
  // {
  //   path: '/agent/edit-property/:id',
  //   component: AgentEditProperty,
  // },
  {
    path: '/agent/login',
    component: AgentLogin,
  },
];

export default routes;
