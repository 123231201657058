import React, { useEffect, useState } from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import TuneIcon from '@material-ui/icons/Tune'
import _ from 'lodash'

import ButtonSelection from './ButtonSelection'
import OptionModalTop from './OptionModalTop'
import SliderContainer from './SliderContainer'

import ListOptions from './ListOptions'

import { BUTTON_FILTERS, PROPERTY_TYPE } from './constants'
import {
  PRICE_RANGE,
  PRICE_MARKS,
  PRICE_RANGE_RENT,
  RENT_PRICE_MARKS,
} from '../../../constants/constants'

import {
  withStyles,
  IconButton,
  Paper,
  Button,
  Badge,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Box,
  makeStyles,
} from '@material-ui/core'
import { PROPERTY_DETAILS } from '../Filter/DefaultValues'
import useFilters from 'utils/customHooks/useFilters'
import { numberFormatter } from 'utils/formatter/numberFormatter'
import PriceSlider from 'components/Common/GlobalFilters/PriceSlider'
import { priceRangeLabelFormatter } from 'utils/formatter/priceRangeLabelFormatter'

import {
  PRICE_RANGE as GLOBAL_PRICE_RANGE,
  PRICE_RANGE_RENT as GLOBAL_PRICE_RANGE_RENT,
} from '../../../constants/globalfilters'

const useStyles = makeStyles((theme) => ({
  filterMainContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  /** Search Filter */
  searchFilterContainer: {
    display: ' flex',
    marginTop: '-5px',
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: 6,
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
    '& .Filter-Button': {
      paddingRight: 4,
      paddingLeft: 8,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '@media (max-width: 768px)': {
      paddingRight: 0,
    },
  },
  /** Top Option Modal */
  optionContainer: {
    padding: '1.250em',
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
  },
  optionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '0.625em',
  },
  optionButton: {
    textTransform: 'none',
    borderRadius: 20,
    margin: '0.625em',
  },
  filterContainer: {
    paddingTop: '3.125em',
  },
  filterContainerList: {
    paddingTop: '4.125em',
  },
  iconTune: {
    color: '#000',
  },
  checkBoxText: {
    fontSize: 10,
    fontWeight: 'normal',
    color: '#6A6A6A',
  },
  optionBottomContainer: {
    '&:hover': {
      outline: 'none',
    },
  },
  optionList: {
    // height: "40vh",
    // overflowY: "scroll",
  },

  // Modal filter button
  modalFilterButton: {
    height: '2.250em',
    textTransform: 'capitalize',
    marginLeft: '0.313em',
    marginRight: '0.313em',
    borderRadius: '1.250em',
    whiteSpace: 'noWrap',
    fontWeight: 'normal',
    padding: '12px 15px',
    lineHeight: '2.250em',
    '@media (max-width: 1024px)': {
      padding: '0px 10px',
    },
    '@media (max-width: 890px)': {
      padding: '12px 8px',
      fontSize: '13px',
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    '@media (max-width: 768px)': {
      fontSize: '11px',
    },
  },
}))

const priceMarks = PRICE_MARKS
const SCALE_PRICE = PRICE_MARKS[PRICE_MARKS.length - 1].value
const RENT_SCALE_PRICE = RENT_PRICE_MARKS[RENT_PRICE_MARKS.length - 1].value

const priceRange = PRICE_RANGE

const handleScale = (valueArray) => {
  return [scale(valueArray[0]), scale(valueArray[1])]
}

const scale = (value) => {
  if (value === undefined) {
    return 0
  }
  try {
    const previousMarkIndex = Math.floor(value / 25)
    const previousMark = priceMarks[previousMarkIndex]
    const remainder = value % 25
    if (remainder === 0 && previousMark) {
      return previousMark.scaledValue
    }

    const nextMark = priceMarks[previousMarkIndex + 1]
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25
    return remainder * increment + previousMark.scaledValue
  } catch (err) {
    return 0
  }
}

const getPropertyTypeButtonText = (propertyTypes) => {
  const activePropertyTypeCount = propertyTypes.filter(
    (propertyType) => propertyType.active
  ).length

  let addPropertyTypeSuffix = activePropertyTypeCount > 1

  if (activePropertyTypeCount === 0) {
    return 'Property Type'
  }

  return `${activePropertyTypeCount} Property Type${addPropertyTypeSuffix ? 's' : ''}`
}

const PropertyBedBathroomFilter = ({ filter, handleFilterButtonClick }) => {
  const classes = useStyles()

  const isExactFilter = filter.exact
  const isPluralFilter = filter.value > 1

  let filterButtonMessage = ''

  if (filter.value === 0) {
    filterButtonMessage = `${filter.label}s`
  } else {
    filterButtonMessage = `${filter.value}${isExactFilter ? '' : '+'} ${filter.label}${isPluralFilter ? 's' : ''
      }`
  }

  return (
    <Button
      className={classes.modalFilterButton}
      variant="outlined"
      endIcon={<KeyboardArrowDownIcon />}
      onClick={(event) =>
        handleFilterButtonClick(filter.label === 'Bedroom' ? 'bed' : 'bath')
      }
    >
      <Box>{filterButtonMessage}</Box>
    </Button>
  )
}

const ModalFilterButtons = ({ handleFilterButtonClick, path }) => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()

  const PROPERTY_TYPE = getFilter('property_type')
  const PROPERTY_BEDROOMS = getFilter('bedroom')
  const PROPERTY_BATHROOMS = getFilter('bathroom')
  const PRICE = getFilter('price')
  const RENT_PRICE = getFilter('price_rent')

  const priceButtonText =
    path === 'rent'
      ? priceRangeLabelFormatter(
        RENT_PRICE.min,
        RENT_PRICE.max,
        GLOBAL_PRICE_RANGE_RENT[0],
        GLOBAL_PRICE_RANGE_RENT[1]
      )
      : priceRangeLabelFormatter(
        PRICE.min,
        PRICE.max,
        GLOBAL_PRICE_RANGE[0],
        GLOBAL_PRICE_RANGE[1]
      )

  const propertyTypeButtonText = getPropertyTypeButtonText(PROPERTY_TYPE)

  return (
    <Box>
      <PropertyBedBathroomFilter
        handleFilterButtonClick={handleFilterButtonClick}
        filter={PROPERTY_BEDROOMS}
      />
      <Button
        className={classes.modalFilterButton}
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event) => handleFilterButtonClick('cost')}
      >
        <Box>{priceButtonText}</Box>
      </Button>
      <PropertyBedBathroomFilter
        handleFilterButtonClick={handleFilterButtonClick}
        filter={PROPERTY_BATHROOMS}
      />
      <Button
        className={classes.modalFilterButton}
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event) => handleFilterButtonClick('propertyType')}
      >
        <Box>{propertyTypeButtonText}</Box>
      </Button>
    </Box>
  )
}

const FilterOptions = (props) => {
  const {
    type,
    showModalOptions,
    showOptionModal,
    selectedFilterType,
    setIsInputFocused,
    setIncludeNearbyFilter,
    apiRequestSearchProperty,
    filters,
    toggleFilterWeb,
    saveFilters,
  } = props

  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const PROPERTY_TYPE = getFilter('property_type')

  const [buttonFilters, setButtonFilters] = useState(BUTTON_FILTERS)
  const [filterData, setFilterData] = useState({
    bedrooms: { label: 'Bedrooms', selected: { value: 0, exactMatch: false } },
    price: {
      label: 'Price',
      selected: '',
      min: PRICE_RANGE[0] ?? 0,
      max: PRICE_RANGE[1] ?? SCALE_PRICE,
    },
    rangedPrice: {
      label: 'Price',
      selected: '',
      min: PRICE_RANGE[0] ?? 0,
      max: PRICE_RANGE[1] ?? SCALE_PRICE,
    },
    priceRent: { label: 'Price Per Week', selected: '', min: 250, max: 5000 },
    bathrooms: {
      label: 'Bathrooms',
      selected: { value: 0, exactMatch: false },
    },
  })
  const [filterDataProperty, setFilterDataProperty] = useState(null)
  const [isNearby, setIsNearby] = useState(
    filters ? (filters.includeNearby ? filters.includeNearby : false) : true
  )
  const [filterBed, setFilterBed] = useState(null)
  const [filterBath, setFilterBath] = useState(null)
  const [filterPrice, setFilterPrice] = useState(null)
  const [filterPriceRent, setFilterPriceRent] = useState(null)

  const [updatedPropertyFilters, setUpdatedPropertyFilters] = useState(null)

  const [propertyDetails, setPropertyDetails] = useState(
    props.filters ? props.filters.filters.propertyDetails : PROPERTY_DETAILS
  )

  const formatPriceLabel = (valueObj) => {
    let label = ''
    if (valueObj.min < SCALE_PRICE && valueObj.max <= SCALE_PRICE) {
      const scaledVals = handleScale([valueObj.min, valueObj.max])
      label =
        '$' +
        numberFormatter(scaledVals[0], 2, true) +
        ' - $' +
        numberFormatter(scaledVals[1], 2, true)
    } else {
      label =
        '$' +
        numberFormatter(valueObj.min, 2, true) +
        ' - $' +
        numberFormatter(valueObj.max, 2, true)
    }

    return label
  }

  useEffect(() => {
    if (filters) {
      if (filters.filters.propertyDetails) {
        const propertyDetails = filters.filters.propertyDetails
        const isAny =
          propertyDetails.price.min === 0 && propertyDetails.price.max === SCALE_PRICE
        const isAnyRent =
          propertyDetails.priceRent.min === 250 &&
          propertyDetails.priceRent.max === 5000
        let sign = ''
        if (propertyDetails.price.max === PRICE_RANGE[1]) {
          sign = '+'
        }
        let newData = {
          bedrooms: {
            label:
              propertyDetails.bedrooms && propertyDetails.bedrooms.value > 1
                ? 'Bedrooms'
                : 'Bedroom',
            selected: propertyDetails.bedrooms || '',
          },
          price: {
            label: isAny ? 'Any' : 'Price',
            selected: isAny
              ? ''
              : formatPriceLabel(propertyDetails.price ?? [0, SCALE_PRICE]) + sign,
            min: propertyDetails.price && propertyDetails.price.min,
            max: propertyDetails.price && propertyDetails.price.max,
          },
          priceRent: {
            label: isAnyRent ? 'Any' : 'Price Per Week',
            selected: isAnyRent
              ? ''
              : '$' +
              numberFormatter(propertyDetails.priceRent.min) +
              ' to $' +
              numberFormatter(propertyDetails.priceRent.max) +
              '',
            min: propertyDetails.priceRent.min,
            max: propertyDetails.priceRent.max,
          },
          bathrooms: {
            label:
              propertyDetails.bathrooms && propertyDetails.bathrooms.value > 1
                ? 'Bathrooms'
                : 'Bathroom',
            selected: propertyDetails.bathrooms || '',
          },
          scaledPrice: propertyDetails.scaledPrice ?? [0, SCALE_PRICE],
        }
        setFilterData(newData)
        handleData(newData)
      }
      setIsNearby(filters.includeNearby || false)
    }
  }, [filters])

  useEffect(() => {
    if (
      !_.isEqual(filters.filters.propertyType, filterDataProperty) ||
      !filterDataProperty
    ) {
      setFilterDataProperty(filters.filters.propertyType)
    }
  }, [filters.filters.propertyType])

  useEffect(() => {
    if (props.isClearFilter) {
      setIsNearby(false)
    }
  }, [props.isClearFilter])

  useEffect(() => {
    if (filterDataProperty) {
      handleData(null, 'preset')
    }
  }, [filterDataProperty])

  useEffect(() => {
    handleData()
  }, [filterData])

  function handleNearbyCheck(value) {
    setIncludeNearbyFilter(value)
    setIsNearby(value)
  }

  const handlePropertyDetails = (data) => {
    setPropertyDetails(data)
  }

  function formatLabel(val1, val2) {
    if (
      (val1 === 0 && val2 === SCALE_PRICE) ||
      (val1 < PRICE_RANGE[0] && val2 === PRICE_RANGE[1])
    ) {
      return 'Any'
    } else if (
      (val1 === 0 && val2 === SCALE_PRICE) ||
      (val1 < PRICE_RANGE[0] && val2 < PRICE_RANGE[1] && val2 > PRICE_RANGE[0])
    ) {
      return 'Below $' + numberFormatter(val2, 2, true)
    } else if (
      (val1 === 0 && val2 === SCALE_PRICE) ||
      (val1 < PRICE_RANGE[0] && val2 <= PRICE_RANGE[0])
    ) {
      return 'Below $' + numberFormatter(PRICE_RANGE[0], 2, true)
    } else {
      let sign = ''
      if (val2 === PRICE_RANGE[1]) {
        sign = '+'
      }
      return (
        '$' +
        numberFormatter(val1, 2, true) +
        ' - $' +
        numberFormatter(val2, 2, true) +
        sign
      )
    }
  }

  // const handleSlider = (type, data) => {
  //   const sliderFilter = filterData

  //   if (type === 'bedrooms') {
  //     sliderFilter.bedrooms.label = data.value > 1 ? 'Bedrooms' : 'Bedroom'
  //     sliderFilter.bedrooms.selected = data
  //     setFilterBed(sliderFilter.bedrooms)
  //   }
  //   if (type === 'price') {
  //     const isAny = data.price[0] === PRICE_RANGE[0] && data.price[1] === PRICE_RANGE[1]
  //     sliderFilter.price.label = isAny ? 'Any' : ''
  //     sliderFilter.price.selected = isAny
  //       ? ''
  //       : formatLabel(data.price[0], data.price[1])
  //     sliderFilter.price.min = data.price[0]
  //     sliderFilter.price.max = data.price[1]

  //     sliderFilter.scaledPrice = data.scaledPrice

  //     setFilterPrice(sliderFilter.price)
  //   }

  //   if (type === 'scaledPrice') {
  //     sliderFilter.scaledPrice = data
  //   }

  //   if (type === 'priceRent') {
  //     const isAny = data[0] === PRICE_RANGE_RENT[0] && data[1] === PRICE_RANGE_RENT[1]
  //     sliderFilter.priceRent.label = isAny ? 'Any' : ''
  //     sliderFilter.priceRent.selected = isAny
  //       ? ''
  //       : '$' + numberFormatter(data[0]) + ' - $' + numberFormatter(data[1])
  //     sliderFilter.priceRent.min = data[0]
  //     sliderFilter.priceRent.max = data[1]

  //     setFilterPriceRent(sliderFilter.priceRent)
  //   }
  //   if (type === 'bathrooms') {
  //     sliderFilter.bathrooms.label = data.value > 1 ? 'Bathrooms' : 'Bathroom'
  //     sliderFilter.bathrooms.selected = data

  //     setFilterBath(sliderFilter.bathrooms)
  //   }
  //   setFilterData({ ...sliderFilter, sliderFilter })

  //   const propertyData = {
  //     price: { min: sliderFilter.price.min, max: sliderFilter.price.max },
  //     rangedPrice: { min: sliderFilter.price.min, max: sliderFilter.price.max },
  //     priceRent: {
  //       min: sliderFilter.priceRent.min,
  //       max: sliderFilter.priceRent.max,
  //     },
  //     bedrooms: sliderFilter.bedrooms.selected,
  //     bathrooms: sliderFilter.bathrooms.selected,
  //     scaledPrice: sliderFilter.scaledPrice,
  //   }

  //   if (
  //     propertyData.rangedPrice.min <= SCALE_PRICE &&
  //     propertyData.rangedPrice.max <= SCALE_PRICE
  //   ) {
  //     const scaled = handleScale(propertyData.scaledPrice)
  //     propertyData.rangedPrice = { min: scaled[0], max: scaled[1] }
  //   }

  //   const baseDetails = filters.filters.propertyDetails
  //   const mergedDetails = Object.assign(baseDetails, propertyData)
  //   setUpdatedPropertyFilters({
  //     ...filters.filters,
  //     propertyDetails: mergedDetails,
  //   })
  // }

  const handlePropertyType = (data) => {
    if (updatedPropertyFilters) {
      setUpdatedPropertyFilters({
        ...updatedPropertyFilters,
        propertyType: data,
      })
    } else {
      setUpdatedPropertyFilters({ ...filters.filters, propertyType: data })
    }
    setFilterDataProperty(data)
  }

  const handleData = (data, isSave) => {
    let newData = data || filterData

    const isRent = props.path && props.path === 'rent'

    if (newData) {
      let data = [
        {
          type: 'bed',
          label: newData.bedrooms.label,
          selected: newData.bedrooms.selected !== '' ? newData.bedrooms.selected : '',
          values: [
            {
              name: '',
              value: newData.bedrooms.selected,
            },
          ],
        },
        {
          type: 'cost',
          label: isRent ? newData.priceRent.label : newData.price.label,
          selected: isRent
            ? newData.priceRent.selected !== ''
              ? newData.priceRent.selected
              : ''
            : newData.price.selected !== ''
              ? newData.price.selected
              : '',
          values: [
            {
              name: '',
              value: {
                min: isRent ? propertyDetails.priceRent.min : propertyDetails.price.min,
                max: isRent ? propertyDetails.priceRent.max : propertyDetails.price.max,
              },
            },
          ],
        },
        {
          type: 'bath',
          label: newData.bathrooms.label,
          selected: newData.bathrooms.selected !== '' ? newData.bathrooms.selected : '',
          values: [
            {
              name: '',
              value: newData.bathrooms.selected,
            },
          ],
        },
        {
          type: 'propertyType',
          label: 'Property Type',
          selected: '',
          values: null,
        },
      ]

      if (filterDataProperty) {
        data[3].values = filterDataProperty
      }
      setButtonFilters(data)
      if (saveFilters && isSave === 'save' && updatedPropertyFilters) {
        saveFilters(updatedPropertyFilters)
        showOptionModal(null, true)
        setIsInputFocused(false)
        apiRequestSearchProperty()
      }

      if (isSave === 'preset' && updatedPropertyFilters) {
        saveFilters({
          ...updatedPropertyFilters,
          propertyType: filterDataProperty,
        })
      }
    }
  }

  function renderListContent() {
    return (
      <Paper className={classes.optionContainer} elevation={5}>
        <div className={classes.filterContainerList}>
          <div className={classes.optionList}>
            <ListOptions
              type={'property'}
              options={filterDataProperty}
              handleType={handlePropertyType}
            />
          </div>
        </div>
        <div className={classes.optionButtonContainer}>
          <Button
            size={'small'}
            className={classes.optionButton}
            onClick={() => {
              showOptionModal(null, true)
              setIsInputFocused(false)
            }}
          >
            Cancel
          </Button>
          <Button
            size={'small'}
            className={classes.optionButton}
            color={'primary'}
            variant="contained"
            onClick={() => {
              handleData(null, 'save')
            }}
          >
            Apply
          </Button>
        </div>
      </Paper>
    )
  }

  function renderSearchModalOptions() {
    return (
      <Paper className={classes.optionContainer} elevation={5}>
        <div className={classes.filterContainer}>
          {/* <SliderContainer
            path={props.path}
            type={selectedFilterType}
            handleSlider={handleSlider}
            filterData={filterData}
            currentFilters={filters.filters.propertyDetails}
            propertyDetails={propertyDetails}
            handlePropertyDetails={handlePropertyDetails}
          /> */}
          <PriceSlider path={props.path} />
        </div>
        <div className={classes.optionButtonContainer}>
          <Button
            size={'small'}
            className={classes.optionButton}
            onClick={() => {
              showOptionModal(null, true)
              setIsInputFocused(false)
            }}
          >
            Cancel
          </Button>
          <Button
            size={'small'}
            className={classes.optionButton}
            color={'primary'}
            variant="contained"
            onClick={() => {
              handleData(null, 'save')
            }}
          >
            Apply
          </Button>
        </div>
      </Paper>
    )
  }

  /** filter view */
  function renderFilterOptions() {
    return (
      <>
        <div className={classes.filterMainContainer}>
          <FormControlLabel
            className={classes.checkBoxText}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
                name="checkedI"
                checked={isNearby}
                onChange={(e) => {
                  handleNearbyCheck(!isNearby)
                }}
              />
            }
            label="Include nearby suburbs"
          />
        </div>
      </>
    )
  }

  /** result view */
  function renderSearchOptions() {
    const handleFilterButtonClick = (filterType) => {
      showOptionModal(filterType)
      setIsInputFocused(false)
    }

    return (
      <>
        <div className={classes.searchFilterContainer}>
          <Tooltip title="Filters">
            <IconButton
              className={'Filter-Button'}
              onClick={() => toggleFilterWeb({ type: props.path, status: true })}
            >
              <Badge
                badgeContent={null}
                color="primary"
                style={{ pointerEvents: 'none' }}
              >
                <TuneIcon className={classes.iconTune} />
              </Badge>
            </IconButton>
          </Tooltip>
          <ModalFilterButtons
            handleFilterButtonClick={handleFilterButtonClick}
            path={props.path}
          />
          {/* {buttonFilters.map((value, index) => {
            return (
              <ButtonSelection
                key={index}
                data={value}
                setShowModalOptions={() => {
                  showOptionModal(value.type)
                  setIsInputFocused(false)
                }}
              />
            )
          })} */}
        </div>
      </>
    )
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <OptionModalTop
        isOpen={showModalOptions}
        showOptionModal={showOptionModal}
        content={
          selectedFilterType === 'propertyType'
            ? renderListContent()
            : renderSearchModalOptions()
        }
      />
      {type === 'search' ? renderSearchOptions() : renderFilterOptions()}
    </div>
  )
}

export default FilterOptions
