import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function ErrorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path style={{fillRule:"evenodd", clipRule:"evenodd", fill:"#EB4335"}} d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"/>
    </SvgIcon>
  );
}

export function ErrorIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ErrorIcon />
      <ErrorIcon color="primary" />
      <ErrorIcon color="secondary" />
      <ErrorIcon color="action" />
      <ErrorIcon color="disabled" />
      <ErrorIcon style={{ color: green[500] }} />
    </div>
  );
}
