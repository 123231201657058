import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  useMediaQuery,
  Avatar,
  InputAdornment,
  IconButton,
  withStyles,
  useTheme,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { SuperStatusChip, AgentsImageUpload, AgentsForm } from "components/Web/Superadmin";
import { Cancel, CheckCircleRounded, Error } from "@material-ui/icons";

const FormInput = (props) => (
  <div style={{ marginTop: 10 }}>
    <Typography variant={"caption"} style={{ color: "gray" }}>
      {props.label}
    </Typography>
    <TextField
      style={{ backgroundColor: "#FFF" }}
      {...props}
      label={null}
      fullWidth
      id="outlined-basic"
      variant="outlined"
    />
  </div>
);

const styles = (theme) => ({
  root: {
    marginTop: 20,
    marginRight: 20,
    paddingBottom: 60,
    width: "89vw",
    '& p.MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-24px'
    }
  },
  title: {
    fontWeight: 300,
    fontSize: "21px",
    letterSpacing: "-0.525px",
    lineHeight: "32px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  subtitle: {
    fontWeight: 300,
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  formContainer: {
    width: "100%",
    height: "auto",
    marginRight: 20,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    marginRight: 20,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 20,
    marginLeft: 10,
    minWidth: 120,
  },
  buttonReset: {
    textTransform: "capitalize",
    borderRadius: 20,
    marginLeft: 10,
  },
  icon: {
    fontSize: 25,
  },
  fieldGroup: {
    borderTop: "1px solid #d3d3d3",
    paddingBottom: 20,
  },
  biofield: {
    display: "flex",
    flexDirection: "column",
  },
  biocounter: {
    marginTop: 10,
    alignSelf: "flex-end",
  },
  avatarfield: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  avatar: {
    border: "1px solid #FFF",
    width: 150,
    height: 150,
  },
});

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const FormFields = {
  email: { name: "email", label: "Email Address" },
};

const AgentsAddForm = (props) => {
  const { classes, goBack, match, handleEmailCheck, isLoading, error, result, clearPayload, handleSubmit, noReset } = props;
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      // row 1
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      checkEmail()
    },
  });

  useEffect(() => {
    clearPayload()
    return () => {
      clearPayload()
    }
  }, [])



  const [step, setStep] = useState(1)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const checkEmail = () => {
    if (formik.isValid && formik.values['email'] !== '') {
      handleEmailCheck({ email: encodeURIComponent(formik.values['email']) })
    }
  }

  useEffect(() => {
    if (result?.data?.agent || result?.message === "Agent not found") {
      setStep(2)
    }
  }, [result])

  const _renderHeader = () => {
    return (
      <div className={classes.header}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.title} variant={"h1"}>
            {"Add an Agent"}
          </Typography>
        </div>
      </div>
    );
  };

  const _renderInfo = () => {
    return (
      <div className={classes.formContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <FormInput
                id={FormFields["email"].name}
                name={FormFields["email"].name}
                label={FormFields["email"].label}
                variant="outlined"
                value={formik.values[FormFields["email"].name]}
                onChange={(e) => {
                  formik.handleChange(e)
                }}
                error={
                  formik.errors &&
                  Boolean(formik.errors["email"]) || (result?.data?.agent ? true : false)
                }
                helperText={
                  formik.errors &&
                  formik.errors["email"]
                }
                disabled={Boolean(isLoading) || (((result?.message && result?.message !== "FETCH request successful") || result?.data?.agent) ? true : false)}
                InputProps={
                  {
                    autoComplete: 'off',
                    form: {
                      autoComplete: 'off',
                    },
                    endAdornment:
                      <InputAdornment position={'end'}>
                        {result?.message === 'Agent not found' &&
                          <CheckCircleRounded
                            color={'primary'}
                          />
                        }{
                          result?.data?.agent &&
                          <Error
                            color={'primary'}
                          />
                        }
                      </InputAdornment>

                  }
                }
              />

            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  className={classes.button}
                  disableElevation
                  variant="outlined"
                  color="default"
                  onClick={() => goBack()}
                >
                  Cancel
                </Button>
                {/* {step === 2 && <Button
                  className={classes.button}
                  disableElevation
                  variant="outlined"
                  color="secondary"
                  onClick={() => setStep(1)}
                >
                  Discard Form
                </Button>} */}
                {((result?.message && result?.message !== "FETCH request successful") || result?.data?.agent) && !noReset && step === 1 &&
                  <Button
                    className={classes.buttonReset}
                    disableElevation
                    variant="outlined"
                    color="secondary"
                    onClick={clearPayload}
                  >
                    Reset
                  </Button>
                }
                <Button
                  className={classes.button}
                  disableElevation
                  variant="contained"
                  color={!error ? 'primary' : 'secondary'}
                  onClick={() => {
                    if (error) {
                      checkEmail()
                    } else if (result?.message === 'Agent not found' || result?.data?.agent) {
                      setStep(2)
                    } else {
                      checkEmail()
                    }
                  }}
                  disabled={isLoading || !formik.dirty || !formik.isValid}
                >
                  {isLoading ? 'Checking' : error ? 'Retry' : (result?.message === "Agent not found" || result?.data?.agent) ? 'Continue' : 'Check'}
                </Button>

              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {_renderHeader()}
      {_renderInfo()}

      {step === 2 && (result?.data?.agent || result?.message === "Agent not found") &&

        <AgentsForm
          match={match}
          goBack={goBack}
          agent={result?.data?.agent ?? { email: formik.values['email'] }}
          handleSubmit={handleSubmit}
          submitStatus={submitSuccess}
          heading={result?.data?.agent ? 'Edit Agent' : 'Add Agent'}
          hideCancel={true}
        />
      }

    </div>
  );
};

export default withStyles(styles)(AgentsAddForm);
