import React, { useState } from "react";
import PropTypes from "prop-types";
import { Paper, Typography, withStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import clsx from "clsx";
import Dropzone from "react-dropzone";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "65vh",
    overflowY: "scroll",
    overflowX: "hidden",
    marginRight: 20,
    marginTop: 10,
  },
  title: {
    fontWeight: 300,
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.87)",
    textAlign: "left",
  },
  photodrop: {
    minWidth: 300,
    height: 150,
    padding: 20,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  addIconContainer: {
    width: 40,
    height: 40,
    backgroundColor: "#e0e0e0",
    borderRadius: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  addIcon: {
    width: 30,
    height: 30,
    color: "#706053",
  },
});

const AgentsImageUpload = (props) => {
  const { classes } = props;

  return (
    <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
      {({ getRootProps, getInputProps }) => (
        <Paper className={classes.photodrop} {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={classes.addIconContainer}>
            <Add className={classes.addIcon} />
          </div>
          <Typography className={classes.title} variant={"body2"}>
            {"Click to upload or drag and drop"}
          </Typography>
          <Typography className={classes.title} variant={"body2"}>
            {"PNG, JPG (Max 400px x 400px)"}
          </Typography>
        </Paper>
      )}
    </Dropzone>
  );
};

AgentsImageUpload.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AgentsImageUpload);
