import React, {useState} from "react";
import styled, { css } from 'styled-components'

import SliderHeader from '../Filter/SliderHeader';
import { PRICE_RANGE, PRICE_MARKS } from "../../../constants/constants";

import { Typography, withStyles, Slider } from '@material-ui/core';

const StyledSlider = withStyles({
  root: {
  },
  rail:{
    height:'6px',
    borderRadius:'4px'
  },
  track:{
    height:'6px',
    borderRadius:'4px'
  },
  thumb: {
    width:'20px',
    height:'20px',
    borderRadius:'20px',
    backgroundColor:'#fff',
    border:'1px solid #35C0CA',
    marginTop:'-8px',
    marginLeft:'-8px'
  },
  mark:{
    height:'6px',
    backgroundColor:'#fff'
  }
})(Slider);

const Header = styled.div`
  padding:0 0;
  display:flex;
  align-items:center;
  position:relative;
  width:100%;
`

function valuetext(value) {
  return `${value}°C`;
}

const multiplier = 15;
const SCALE_PRICE = PRICE_MARKS[PRICE_MARKS.length - 1].value

const RangeSlider = props => {
  // const [value, setValue] = useState(props.value);

  const handleChange = (event, newValue) => {
    props.handleChange(event, newValue)
  };

  const handleChangeCommitted = (event, newValue) => {
    if(props.handleChangeCommitted) {
      props.handleChangeCommitted(event, newValue)
    }
  }


  function nFormatter(num, digits, isMin) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "B" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;

    // if(num >= 5000000 && isMin) {
    //   return Math.floor((num / si[i].value)).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    // } else {
    //   return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    // }
  }

  // function formatLabel(val1, val2) {
  //   if(props.path && props.path === 'rent') {
  //     return '$'+nFormatter(val1, 1, true)+' pw to $'+ nFormatter(val2, 1, true) + ' pw'
  //   } else if(props.isPrecise){
  //     return '$'+nFormatter(props.value, 1)
  //   } else {
  //     let sign = '';
  //     if(val2 === PRICE_RANGE[1]) {
  //       sign = '+';
  //     }
  //     return '$'+nFormatter(val1, 2, true)+' to $'+ nFormatter(val2, 2, true) + sign
  //   }
  // }


  function formatLabel(val1, val2) {
    if(props.path && props.path === 'rent') {
      return '$'+nFormatter(val1, 1, true)+' pw to $'+ nFormatter(val2, 1, true) + ' pw'
    } 
    else if((val1 === 0 && val2 === SCALE_PRICE) || (val1 < PRICE_RANGE[0] && val2 === PRICE_RANGE[1])) {      
      return 'Any'
    }
    else if((val1 === 0 && val2 === SCALE_PRICE) || (val1 < PRICE_RANGE[0] && val2 < PRICE_RANGE[1] && val2 > PRICE_RANGE[0])) {      
      return 'Below $'+ nFormatter(val2, 2, true);
    }
    else if((val1 === 0 && val2 === SCALE_PRICE) || (val1 < PRICE_RANGE[0] && val2 <= PRICE_RANGE[0])) {      
      return 'Below $'+ nFormatter(PRICE_RANGE[0], 2, true);
    }
    else {
      let sign = '';
       if(val2 === PRICE_RANGE[1]) {
         sign = '+';
       }
       return '$'+nFormatter(val1, 2, true)+' to $'+ nFormatter(val2, 2, true) + sign
     }
  }

  return (
    <div className={'slider-'+props.label}>
      <Header>
        <SliderHeader label={props.label} icon={props.icon} />
        {
          (props.valueLabelDisplay === 'off' || props.isSingle) && props.value ?
            <Typography style={{fontSize:'13px', color:'rgba(0, 0, 0, 0.87)', lineHeight:'0.76', fontWeight:'400', letterSpacing:'0.073125px', position:'absolute', left:0, right:0, textAlign: (props.path && props.path === 'rent') || props.isSingle ? 'right' : 'center'}}>
              {
                props.path && props.path === 'rent' ?
                    props.value[0] === 250 && props.value[1] === 5000 ?
                    'Any'
                  : formatLabel(props.value[0], props.value[1])
                : props.value[0] === PRICE_RANGE[0] && props.value[1] === PRICE_RANGE[1] ?
                  'Any'
                : props.isSingle ?
                    props.value+' km'
                : !Array.isArray(props.value) ?
                    formatLabel(0, props.value)
                : formatLabel(props.value[0], props.value[1])
              }
            </Typography>
          : null
        }
      </Header>

      {
        !props.range ?
          <StyledSlider
            value={props.value}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            // valueLabelDisplay="auto" - Duplicate props
            aria-labelledby="discrete-slider"
            getAriaValueText={valuetext}
            valueLabelDisplay={props.valueLabelDisplay || 'off'}
            step={props.step || 1}
            marks={props.marks || false}
            min={props.min}
            max={props.max}
          />
        : <StyledSlider
            value={props.value}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            // valueLabelDisplay="auto" - Duplicate props
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
            min={props.range[0]}
            max={props.range[1]}
            valueLabelDisplay={props.valueLabelDisplay || 'off'}
            step={props.step || 1}
          />
        
      }
    </div>
  );
};

export default RangeSlider;