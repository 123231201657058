export const handleSearchPropertyReference = (searchReferences) => {
  if (searchReferences) {
    const offmarketReference = searchReferences.find(
      (reference) => reference.id === 'offMarket'
    )
    const comingsoonReference = searchReferences.find(
      (reference) => reference.id === 'comingSoon'
    )

    if (offmarketReference.active && comingsoonReference.active) {
      return '/hush-hush'
    }

    const activeReference = searchReferences.filter((reference) => reference.active)

    return activeReference[0]?.path
  }
}
