import { PRICE_RANGE, PRICE_RANGE_RENT } from 'constants/globalfilters'

const isPriceDefaultLimit = ({ price, limitType, priceType }) => {
  const priceRange = priceType === 'rent' ? PRICE_RANGE_RENT : PRICE_RANGE

  return limitType === 'min' ? price <= priceRange[0] : price >= priceRange[1]
}

const handlePropertyTypeCategories = (propertyType) => {
  switch (propertyType) {
    case 'House':
      return [
        'House',
        'Townhouse',
        'Villa',
        'Terrace',
        'DuplexSemi-detached',
        'Lifestyle',
      ]

    case 'Townhouse':
      return ['Townhouse', 'Terrace', 'DuplexSemi-detached', 'Lifestyle']

    case 'Apartment & Unit':
      return ['Apartment & Unit', 'Apartment', 'Studio', 'Unit', 'Flat', 'BlockOfUnits']

    case 'Land':
      return ['Land', 'Land/Development']

    case 'Rural':
      return [
        'AcreageSemi-rural',
        'DuplexSemi-detached',
        'MixedFarming',
        'Rural',
        'LifeStyle',
        'Viticulture',
      ]

    case 'Retirement':
      return ['Retirement']

    case 'Villa':
      return ['Villa']

    default:
      return []
  }
}

// 'House': ["House","Townhouse", "Villa", "Terrace","DuplexSemi-detached", "Lifestyle" ],
//   'Townhouse': ["Townhouse", "Terrace", "DuplexSemi-detached", "Lifestyle"],
//   'Apartment & Unit': ['Apartment & Unit', 'Apartment', 'Studio', 'Unit', 'Flat', 'BlockOfUnits'],
//   'Land': ['Land', 'Land/Development'],
//   'Rural': ['AcreageSemi-rural', 'DuplexSemi-detached', 'MixedFarming', 'Rural', 'LifeStyle', 'Viticulture'],
//   'Retirement': ['Retirement'],
//   'Villa': ['Villa'],

export const funnelDown = (filters, data, changedFilters) => {
  let filteredData = data
  const getReference = filters?.search_reference
    ?.filter((item) => item.active)[0]
    ?.path.split('/')[1]
  const bedroomsfilter = (object) =>
    filters.bedroom.exact && object.bedrooms === filters.bedroom.value
      ? true
      : filters.bedroom.exact === false && object.bedrooms >= filters.bedroom.value
      ? true
      : false

  const bathroomsfilter = (object) =>
    filters.bedroom.exact && object.bathrooms === filters.bathroom.value
      ? true
      : filters.bathroom.exact === false && object.bathrooms >= filters.bathroom.value
      ? true
      : false

  const carparkingfilter = (object) =>
    filters.bedroom.exact && object.totalParking === filters.carparking.value
      ? true
      : filters.bedroom.exact === false &&
        object.totalParking >= filters.carparking.value
      ? true
      : false

  const establishedFilter = (object, key) =>
    object[key] ===
    (filters?.is_established === null || filters?.is_established === 'any')
      ? 'any'
      : filters.is_established === 'new'
      ? false
      : true

  const underOfferFilter = (object) =>
    object.underOffer === filters?.exclude_under_offers

  const landSizeFilter = (object) =>
    object.landSize >= filters?.land_size?.min &&
    object.landSize <= filters?.land_size?.max

  const priceFilter = (object) => {
    // if min price of filters is less than or equal to min default price return all properties that are less than max price
    if (
      isPriceDefaultLimit({
        price: filters?.price?.min,
        limitType: 'min',
        priceType: 'normal',
      })
    ) {
      return object.price <= filters?.price?.max
    }
    // if max price of filters is greater than or equal to max default price return all properties that are greater than min price
    if (
      isPriceDefaultLimit({
        price: filters?.price?.max,
        limitType: 'max',
        priceType: 'normal',
      })
    ) {
      return object.price >= filters?.price?.min
    }

    return object.price >= filters?.price?.min && object.price <= filters?.price?.max
  }

  const rentalPriceFilter = (object) => {
    // if min price of filters is less than or equal to min default price return all properties that are less than max price
    if (
      isPriceDefaultLimit({
        price: filters?.price_rent?.min,
        limitType: 'min',
        priceType: 'rent',
      })
    ) {
      console.debug('here 1')

      return object.rentalPrice <= filters?.price_rent?.max
    }
    // if max price of filters is greater than or equal to max default price return all properties that are greater than min price
    if (
      isPriceDefaultLimit({
        price: filters?.price?.max,
        limitType: 'max',
        priceType: 'rent',
      })
    ) {
      console.debug('here 2')
      return object.rentalPrice >= filters?.price_rent?.min
    }

    return (
      object.rentalPrice >= filters?.price_rent?.min &&
      object.rentalPrice <= filters?.price_rent?.max
    )
  }

  const filterFactory = (baseData, filterKey, filteringFunction) => {
    const obj = baseData?.filter((item) => {
      if (filters[filterKey]?.value >= 0) {
        return filteringFunction(item)
      } else {
        return baseData
      }
    })

    return obj
  }

  let state
  if (filters?.state) {
    const s = filters?.state.filter((f) => {
      if (f.active) {
        state = f.title
        return f
      }
    })
  }

  const nearbyKeys = []
  const f = filters?.nearby_filters?.filter((f) => {
    if (f.active) {
      nearbyKeys.push(f.key)
      return f
    }
  })

  const stateFilter = (item) => item.addressState === state
  let filteredPerfect

  if (getReference === 'perfect-property') {
    const filteredState = changedFilters?.includes('state')
      ? filteredData?.filter(function (item) {
          return stateFilter(item)
        })
      : filteredData

    const filteredNearby = changedFilters?.includes('nearby_filters')
      ? filteredState?.filter(function (item) {
          return nearbyKeys.every(function (key) {
            return item?.closeToFilters[key] === true
          })
        })
      : filteredState

    filteredPerfect = filteredState
  }

  const filteredBed = changedFilters?.includes('bedroom')
    ? filterFactory(filteredPerfect ?? filteredData, 'bedroom', bedroomsfilter)
    : filteredPerfect ?? filteredData
  const filteredBaths = changedFilters?.includes('bathroom')
    ? filterFactory(filteredBed, 'bathroom', bathroomsfilter)
    : filteredBed
  const filteredCarparking = changedFilters?.includes('carparking')
    ? filterFactory(filteredBaths, 'carparking', carparkingfilter)
    : filteredBaths

  const propertyFeaturesMapping = [
    { key: 'gas', active: filters?.features?.filter((f) => f.id === 'gas')?.active },
    {
      key: 'balcony',
      active: filters?.features?.filter((f) => f.id === 'balcony')?.active,
    },
    {
      key: 'study',
      active: filters?.features?.filter((f) => f.id === 'study')?.active,
    },
    {
      key: 'airConditioning',
      active: filters?.features?.filter((f) => f.id === 'aircon')?.active,
    },
    {
      key: 'builtInRobes',
      active: filters?.features?.filter((f) => f.id === 'builtinwardrobes')?.active,
    },
    {
      key: 'courtyard',
      active: filters?.features?.filter((f) => f.id === 'garden')?.active,
    },
    {
      key: 'poolInGround',
      active: filters?.features?.filter((f) => f.id === 'pool')?.active,
    },
    {
      key: 'poolAboveGround',
      active: filters?.features?.filter((f) => f.id === 'pool')?.active,
    },
  ]
  const featuresKeys = []
  propertyFeaturesMapping.filter((f) => {
    if (f.active) {
      featuresKeys.push(f.key)
      return f
    }
  })
  const filteredFeatures = changedFilters?.includes('features')
    ? filteredCarparking?.filter(function (item) {
        return featuresKeys.every(function (key) {
          return item.features[key] === true
        })
      })
    : filteredCarparking

  const propertyTypesMapping = [
    {
      key: 'House',
      active: filters?.property_type?.filter((f) => f.id === 'house')?.active,
    },
    {
      key: 'Townhouse',
      active: filters?.property_type?.filter((f) => f.id === 'townhouse')?.active,
    },
    {
      key: 'Apartment & Unit',
      active: filters?.property_type?.filter((f) => f.id === 'apartment')?.active,
    },
    {
      key: 'Land',
      active: filters?.property_type?.filter((f) => f.id === 'land')?.active,
    },
    {
      key: 'Rural',
      active: filters?.property_type?.filter((f) => f.id === 'rural')?.active,
    },
    {
      key: 'Retirement',
      active: filters?.property_type?.filter((f) => f.id === 'retirement')?.active,
    },
    {
      key: 'Villa',
      active: filters?.property_type?.filter((f) => f.id === 'villa')?.active,
    },
  ]

  const propertyTypes =
    filters?.property_type?.filter((f) => f?.active)?.map((i) => i.title) ?? []

  const filterPropertyType = changedFilters?.includes('property_type')
    ? filteredFeatures?.filter(function (property) {
        if (propertyTypes.length > 0) {
          return propertyTypes.some(function (key) {
            return handlePropertyTypeCategories(key).includes(property.propertyType)
          })
        } else {
          return filteredFeatures
        }
      })
    : filteredFeatures

  const filteredIsEstablished = changedFilters?.includes('is_established')
    ? filterPropertyType?.filter(function (item) {
        return establishedFilter(item)
      })
    : filterPropertyType

  const filteredLandSize = changedFilters?.includes('land_size')
    ? filteredIsEstablished?.filter(function (item) {
        if (filters?.land_size?.min !== '' && filters?.land_size?.max !== '') {
          return landSizeFilter(item)
        } else {
          return filteredIsEstablished
        }
      })
    : filteredIsEstablished

  var filteredKeywords = changedFilters?.includes('keyword')
    ? filteredLandSize?.filter(function (item) {
        var arrKeywords = filters?.keyword?.split(' ')
        return arrKeywords.every(function (key) {
          var searchString = item?.description?.search(key) ?? ''
          if (searchString > -1) {
            return item
          }
        })
      })
    : filteredLandSize

  let filteredPrice

  if (getReference === 'rent') {
    const filteredRentaPrice = changedFilters?.includes('price_rent')
      ? filteredKeywords?.filter((item) => {
          if (
            filters?.price_rent?.min !== '' &&
            filters?.price_rent?.max !== '' &&
            filters?.price_rent?.max !== 0 &&
            rentalPriceFilter(item)
          ) {
            return item
          }
        })
      : filteredKeywords

    filteredPrice = filteredRentaPrice
    console.debug(filteredPrice?.length)
  } else {
    const filteredOtherPrice = changedFilters?.includes('price')
      ? filteredKeywords?.filter((item) => {
          if (
            filters?.price?.min !== '' &&
            filters?.price?.max !== '' &&
            filters?.price?.max !== 0 &&
            priceFilter(item)
          ) {
            return item
          }
        })
      : filteredKeywords

    filteredPrice = filteredOtherPrice
  }

  return filteredPrice
}
