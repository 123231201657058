import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import RangeSlider from '../Filter/RangeSlider';
import { PRICE_RANGE } from '../../../constants/constants';
import { Typography, withStyles } from '@material-ui/core';
const Container = styled.div`
  padding: 20px 0;
`;

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
  .slider-Bedrooms {
    .MuiSlider-markLabel[data-index='0'] {
    }
  }
`;

const marksBedroom = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: 'Studio',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
];

const marksDefault = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
];

const priceRange = PRICE_RANGE;
const priceRangeRent = [250, 5000];

const SliderContainer = (props) => {
  const { type, filterData } = props;
  const [price, setPrice] = useState(
    filterData && filterData.price ? [filterData.price.min, filterData.price.max] : priceRange,
  );
  const [priceRent, setPriceRent] = useState(
    filterData && filterData.priceRent ? [filterData.priceRent.min, filterData.priceRent.max] : priceRangeRent,
  );
  const [bedrooms, setBedrooms] = useState(
    filterData && filterData.bedrooms && filterData.bedrooms.selected !== '' ? filterData.bedrooms.selected : 0,
  );
  const [bathrooms, setBathrooms] = useState(
    filterData && filterData.bathrooms && filterData.bathrooms.selected !== '' ? filterData.bathrooms.selected : 0,
  );
  const [carparking, setCarparking] = useState(
    filterData && filterData.carparking && filterData.carparking.selected !== '' ? filterData.carparking.selected : 0,
  );
  const [priceStep, setPriceStep] = useState(priceRange[0]);

  const handlePriceChange = (event, newValue) => {
    let minVal = newValue[0];
    if (minVal > 5000000 && minVal < 10000000) {
      setPriceStep(500000);
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000);
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000);
    } else {
      setPriceStep(50000);
    }

    if (
      (newValue[0] > 5000000 && newValue[0] < priceRange[1]) ||
      (newValue[1] > 5000000 && newValue[1] < priceRange[1])
    ) {
      if (newValue[0].toFixed(2).search('50000.00') > -1) {
        let nMin = String(newValue[0]).replace('50000', '00000');
        newValue[0] = parseInt(nMin);
      }
      if (newValue[1].toFixed(2).search('50000.00') > -1) {
        let nMax = String(newValue[1]).replace('50000', '00000');
        newValue[1] = parseInt(nMax);
      }
      setPrice(newValue);
    } else {
      setPrice(newValue);
    }
  };

  const handlePriceRentChange = (event, newValue) => {
    setPriceRent(newValue);
  };

  const handleBedroomsChange = (event, newValue) => {
    setBedrooms(newValue);
  };

  const handleBathroomsChange = (event, newValue) => {
    setBathrooms(newValue);
  };

  const handleCarparkingChange = (event, newValue) => {
    setCarparking(newValue);
  };

  const handleChangeCommitted = (type) => {
    if (type === 'price') {
      props.handleSlider('price', {
        price: {
          min: price[0],
          max: price[1],
        },
      });
    }
    if (type === 'priceRent') {
      props.handleSlider('priceRent', {
        priceRent: {
          min: priceRent[0],
          max: priceRent[1],
        },
      });
    }
    if (type === 'bedrooms') {
      props.handleSlider('bedrooms', { bedrooms });
    }
    if (type === 'bathrooms') {
      props.handleSlider('bathrooms', { bathrooms });
    }
    if (type === 'carparking') {
      props.handleSlider('carparking', { carparking });
    }
    // handleData();
  };

  const handleData = () => {
    const propertyData = {
      price: {
        min: price[0],
        max: price[1],
      },
      bedrooms,
      bathrooms,
      carparking,
    };
    if (props.handleSlider) {
      // props.handleSlider(propertyData)
    }
  };

  function renderPrice() {
    return (
      <SliderItem>
        {props.path === 'rent' ? (
          <RangeSlider
            path={props.path}
            label="Price Per Week"
            icon="MonetizationOn"
            value={priceRent}
            range={priceRangeRent}
            step={50}
            handleChange={handlePriceRentChange}
            handleChangeCommitted={() => {
              handleChangeCommitted('priceRent');
            }}
            valueLabelDisplay={'off'}
          />
        ) : (
          // <RangeSlider path={props.path} label="Price Per Week" icon="MonetizationOn" value={priceRent} range={priceRangeRent} step={50} handleChange={handlePriceRentChange} handleChangeCommitted={handleChangeCommitted} valueLabelDisplay={'off'} />
          <RangeSlider
            label="Price"
            icon="MonetizationOn"
            value={price}
            range={priceRange}
            step={priceStep}
            handleChange={handlePriceChange}
            handleChangeCommitted={() => {
              handleChangeCommitted('price');
            }}
            valueLabelDisplay={'off'}
          />
        )}
      </SliderItem>
    );
  }

  function renderBedroom() {
    return (
      <SliderItem>
        <RangeSlider
          label="Bedrooms"
          icon="Hotel"
          value={bedrooms}
          handleChange={handleBedroomsChange}
          step={1}
          marks={marksBedroom}
          min={0}
          max={5}
          handleChangeCommitted={() => {
            handleChangeCommitted('bedrooms');
          }}
        />
      </SliderItem>
    );
  }

  function renderBathroom() {
    return (
      <SliderItem>
        <RangeSlider
          label="Bathrooms"
          icon="2DCBathIcon"
          value={bathrooms}
          handleChange={handleBathroomsChange}
          step={1}
          marks={marksDefault}
          min={0}
          max={5}
          handleChangeCommitted={() => {
            handleChangeCommitted('bathrooms');
          }}
        />
      </SliderItem>
    );
  }

  function renderParking() {
    return (
      <SliderItem>
        <RangeSlider
          label="Carparking"
          icon="DirectionsCar"
          value={carparking}
          handleChange={handleCarparkingChange}
          step={1}
          marks={marksDefault}
          min={0}
          max={5}
          handleChangeCommitted={() => {
            handleChangeCommitted('carparking');
          }}
        />
      </SliderItem>
    );
  }

  return (
    <Container>
      {type === 'cost' && renderPrice()}
      {type === 'bed' && renderBedroom()}
      {type === 'bath' && renderBathroom()}
      {type === 'parking' && renderParking()}
    </Container>
  );
};

export default SliderContainer;
