import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function ApartmentIcon(props: SvgIconProps) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="none"
    viewBox="0 0 60 60"
  >
    {/* <rect
      width="59.5"
      height="59.5"
      x="0.25"
      y="0.25"
      stroke="#000"
      strokeWidth="0.5"
      rx="4.75"
    ></rect> */}
    <mask
      id="mask0"
      width="35"
      height="2"
      x="13"
      y="19"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.102 19.156H47.75v1.667H13.102v-1.667z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M47.56 20.183H13.294a.193.193 0 010-.387h34.268c.105 0 .19.087.19.194a.192.192 0 01-.19.193"
        clipRule="evenodd"
      ></path>
    </g>
    <mask
      id="mask1"
      width="35"
      height="3"
      x="13"
      y="20"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.102 20.9H47.75v1.666H13.102V20.9z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M47.56 21.926H13.294a.193.193 0 010-.387h34.268c.105 0 .19.087.19.194a.192.192 0 01-.19.193"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.292 20.183a.194.194 0 01-.18-.255l1.712-5.225a.19.19 0 01.241-.123c.1.035.155.145.122.245l-1.715 5.225a.19.19 0 01-.18.133M47.558 20.184a.19.19 0 01-.18-.134l-1.713-5.225c-.035-.1.02-.21.12-.245a.186.186 0 01.24.124l1.713 5.225a.193.193 0 01-.18.255"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M27.775 14.958H15.007a.192.192 0 01-.19-.193c0-.107.085-.193.19-.193h12.768c.105 0 .19.086.19.193a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask2"
      width="9"
      height="10"
      x="22"
      y="11"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M22.81 11.667h7.808v8.516H22.81v-8.516z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M23.001 20.183a.186.186 0 01-.13-.052.195.195 0 01-.01-.273l7.427-8.13a.187.187 0 01.269-.01.195.195 0 01.01.273l-7.427 8.13a.192.192 0 01-.139.062"
        clipRule="evenodd"
      ></path>
    </g>
    <mask
      id="mask3"
      width="9"
      height="10"
      x="30"
      y="11"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M30.236 11.667h7.804v8.516h-7.804v-8.516z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M37.85 20.183a.193.193 0 01-.14-.062l-7.422-8.13a.195.195 0 01.01-.273.188.188 0 01.268.01l7.423 8.13c.072.078.067.2-.01.273a.186.186 0 01-.13.052"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M45.846 14.958h-12.77a.19.19 0 01-.19-.193.19.19 0 01.19-.193h12.77c.105 0 .19.086.19.193a.192.192 0 01-.19.193M15.005 48.054a.192.192 0 01-.19-.193V21.732c0-.106.085-.193.19-.193s.19.087.19.193v26.129a.192.192 0 01-.19.193M45.847 48.054a.192.192 0 01-.19-.193V21.732c0-.106.085-.193.19-.193s.19.087.19.193v26.129a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask4"
      width="35"
      height="2"
      x="13"
      y="47"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.102 47.027H47.75v1.027H13.102v-1.027z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M47.56 48.054H13.294a.193.193 0 010-.387h34.268a.19.19 0 01.19.194.192.192 0 01-.19.193"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24.142 32.958a.192.192 0 01-.19-.194V25.41H18.62v7.355a.192.192 0 01-.19.194.192.192 0 01-.19-.194v-7.548c0-.107.085-.194.19-.194h5.71c.106 0 .191.087.191.194v7.548a.192.192 0 01-.19.194"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24.714 32.957H17.86a.192.192 0 01-.19-.193.19.19 0 01.19-.193h6.854a.19.19 0 01.19.193.192.192 0 01-.19.193M33.28 32.958a.192.192 0 01-.19-.194V25.41h-5.33v7.355a.192.192 0 01-.19.194.192.192 0 01-.191-.194v-7.548c0-.107.085-.194.19-.194h5.712c.105 0 .19.087.19.194v7.548a.192.192 0 01-.19.194M33.28 48.054a.192.192 0 01-.19-.194V36.44h-5.33v11.42a.192.192 0 01-.19.194.192.192 0 01-.191-.194V36.247c0-.107.085-.193.19-.193h5.712c.105 0 .19.086.19.193V47.86a.192.192 0 01-.19.194"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M33.853 32.957h-6.854a.192.192 0 01-.19-.193.19.19 0 01.19-.193h6.854a.19.19 0 01.19.193.192.192 0 01-.19.193M42.42 32.958a.192.192 0 01-.19-.194V25.41H36.9v7.355a.192.192 0 01-.19.194.192.192 0 01-.192-.194v-7.548c0-.107.085-.194.191-.194h5.71c.105 0 .19.087.19.194v7.548a.192.192 0 01-.19.194"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M42.99 32.957h-6.855a.192.192 0 01-.19-.193.19.19 0 01.19-.193h6.855a.19.19 0 01.19.193.192.192 0 01-.19.193M24.142 43.99a.192.192 0 01-.19-.194v-7.355H18.62v7.355a.192.192 0 01-.19.193.192.192 0 01-.19-.193v-7.55c0-.107.085-.193.19-.193h5.71c.106 0 .191.086.191.193v7.55a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24.714 43.99H17.86a.192.192 0 01-.19-.194.19.19 0 01.19-.193h6.854a.19.19 0 01.19.193.192.192 0 01-.19.194M42.42 43.99a.192.192 0 01-.19-.194v-7.355H36.9v7.355a.192.192 0 01-.19.193.192.192 0 01-.192-.193v-7.55c0-.107.085-.193.191-.193h5.71c.105 0 .19.086.19.193v7.55a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M42.99 43.99h-6.855a.192.192 0 01-.19-.194.19.19 0 01.19-.193h6.855a.19.19 0 01.19.193.192.192 0 01-.19.194"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask5"
      width="2"
      height="3"
      x="13"
      y="19"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.102 19.796h1.01v2.13h-1.01v-2.13z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M13.292 21.926a.192.192 0 01-.19-.193v-1.744c0-.106.085-.193.19-.193s.19.087.19.193v1.744a.192.192 0 01-.19.193"
        clipRule="evenodd"
      ></path>
    </g>
    <mask
      id="mask6"
      width="2"
      height="3"
      x="46"
      y="19"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M46.74 19.796h1.01v2.13h-1.01v-2.13z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M47.56 21.926a.192.192 0 01-.19-.193v-1.744c0-.106.085-.193.19-.193s.19.087.19.193v1.744a.192.192 0 01-.19.193"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M44.13 16.7h-6.608a.19.19 0 01-.19-.194.19.19 0 01.19-.193h6.608c.105 0 .19.087.19.193a.192.192 0 01-.19.194M44.705 18.442h-5.59a.19.19 0 01-.19-.194.19.19 0 01.19-.193h5.59c.104 0 .19.087.19.193a.192.192 0 01-.19.194M23.327 16.7h-6.61a.192.192 0 01-.19-.194c0-.107.086-.193.19-.193h6.61c.105 0 .19.087.19.193a.192.192 0 01-.19.194M21.737 18.442h-5.59a.192.192 0 01-.19-.194c0-.106.085-.193.19-.193h5.59c.105 0 .19.087.19.193a.192.192 0 01-.19.194M21.287 28.892a.192.192 0 01-.19-.193v-3.483c0-.107.085-.194.19-.194.104 0 .19.087.19.194v3.483a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M19.575 30.635a.192.192 0 01-.19-.194c0-.106.085-.193.19-.193.84 0 1.523-.695 1.523-1.55 0-.107.085-.193.19-.193s.192.087.192.193c0 1.069-.854 1.937-1.905 1.937"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M19.575 30.635h-1.143a.192.192 0 01-.19-.193c0-.107.085-.194.19-.194h1.143c.105 0 .19.087.19.194a.192.192 0 01-.19.193M20.146 32.957a.192.192 0 01-.19-.193V30.34c0-.106.085-.195.19-.195s.19.089.19.195v2.424a.192.192 0 01-.19.193M23 30.635c-1.048 0-1.902-.869-1.902-1.937 0-.107.085-.193.19-.193s.19.087.19.193c0 .855.684 1.55 1.523 1.55.105 0 .19.087.19.194a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24.143 30.635H23a.192.192 0 01-.19-.193c0-.107.085-.194.19-.194h1.143c.105 0 .19.087.19.194a.192.192 0 01-.19.193M22.431 32.957a.192.192 0 01-.19-.193V30.34c0-.106.085-.195.19-.195s.19.089.19.195v2.424a.192.192 0 01-.19.193M30.425 28.892a.192.192 0 01-.19-.193v-3.483c0-.107.085-.194.19-.194s.19.087.19.194v3.483a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M28.714 30.635a.192.192 0 01-.19-.194c0-.106.085-.193.19-.193.839 0 1.523-.695 1.523-1.55 0-.107.085-.193.19-.193s.192.087.192.193c0 1.069-.855 1.937-1.905 1.937"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M28.713 30.635h-1.142a.192.192 0 01-.19-.193c0-.107.085-.194.19-.194h1.142c.105 0 .19.087.19.194a.192.192 0 01-.19.193M29.285 32.957a.192.192 0 01-.19-.193V30.34c0-.106.085-.195.19-.195.104 0 .19.089.19.195v2.424a.192.192 0 01-.19.193M32.14 30.635c-1.05 0-1.904-.869-1.904-1.937 0-.107.086-.193.19-.193.105 0 .19.087.19.193 0 .855.684 1.55 1.524 1.55.105 0 .19.087.19.194a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M33.284 30.635H32.14a.19.19 0 01-.19-.193.19.19 0 01.19-.194h1.143c.105 0 .19.087.19.194a.192.192 0 01-.19.193M31.57 32.957a.192.192 0 01-.19-.193V30.34c0-.106.085-.195.19-.195s.19.089.19.195v2.424a.192.192 0 01-.19.193M39.564 28.892a.192.192 0 01-.19-.193v-3.483c0-.107.085-.194.19-.194s.19.087.19.194v3.483a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M37.852 30.635a.192.192 0 01-.19-.194c0-.106.085-.193.19-.193.84 0 1.523-.695 1.523-1.55a.19.19 0 01.19-.193c.105 0 .19.087.19.193 0 1.069-.852 1.937-1.903 1.937"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M37.852 30.635h-1.143a.19.19 0 01-.19-.193.19.19 0 01.19-.194h1.143c.105 0 .19.087.19.194a.192.192 0 01-.19.193M38.423 32.957a.192.192 0 01-.19-.193V30.34c0-.106.085-.195.19-.195s.19.089.19.195v2.424a.192.192 0 01-.19.193M41.278 30.635c-1.05 0-1.903-.869-1.903-1.937 0-.107.085-.193.19-.193a.19.19 0 01.19.193c0 .855.684 1.55 1.523 1.55.105 0 .19.087.19.194a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M42.42 30.635h-1.142a.19.19 0 01-.19-.193.19.19 0 01.19-.194h1.142c.105 0 .19.087.19.194a.192.192 0 01-.19.193M40.708 32.957a.192.192 0 01-.19-.193V30.34c0-.106.085-.195.19-.195s.19.089.19.195v2.424a.192.192 0 01-.19.193M21.287 39.925a.192.192 0 01-.19-.193v-3.485c0-.107.085-.193.19-.193.104 0 .19.086.19.193v3.485a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M19.575 41.667a.192.192 0 01-.19-.193.19.19 0 01.19-.193c.84 0 1.523-.695 1.523-1.549a.19.19 0 11.382 0c0 1.067-.854 1.935-1.905 1.935"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M19.575 41.667h-1.143a.192.192 0 01-.19-.193.19.19 0 01.19-.193h1.143a.19.19 0 01.19.193.192.192 0 01-.19.193M20.146 43.99a.192.192 0 01-.19-.194v-2.423c0-.109.085-.195.19-.195s.19.086.19.195v2.423a.192.192 0 01-.19.193M23 41.667c-1.048 0-1.902-.868-1.902-1.935a.19.19 0 01.19-.193.19.19 0 01.19.193c0 .854.684 1.549 1.523 1.549a.19.19 0 01.19.193.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M24.143 41.667H23a.192.192 0 01-.19-.193.19.19 0 01.19-.193h1.143a.19.19 0 01.19.193.192.192 0 01-.19.193M22.431 43.99a.192.192 0 01-.19-.194v-2.423c0-.109.085-.195.19-.195s.19.086.19.195v2.423a.192.192 0 01-.19.193M39.564 39.925a.192.192 0 01-.19-.193v-3.485c0-.107.085-.193.19-.193s.19.086.19.193v3.485a.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M37.852 41.667a.192.192 0 01-.19-.193.19.19 0 01.19-.193c.84 0 1.523-.695 1.523-1.549a.19.19 0 11.38 0c0 1.067-.852 1.935-1.903 1.935"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M37.852 41.667h-1.143a.19.19 0 01-.19-.193.19.19 0 01.19-.193h1.143a.19.19 0 01.19.193.192.192 0 01-.19.193M38.423 43.99a.192.192 0 01-.19-.194v-2.423c0-.109.085-.195.19-.195s.19.086.19.195v2.423a.192.192 0 01-.19.193M41.278 41.667c-1.05 0-1.903-.868-1.903-1.935a.19.19 0 11.38 0c0 .854.684 1.549 1.523 1.549a.19.19 0 01.19.193.192.192 0 01-.19.193"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M42.42 41.667h-1.142a.19.19 0 01-.19-.193.19.19 0 01.19-.193h1.142a.19.19 0 01.19.193.192.192 0 01-.19.193M40.708 43.99a.192.192 0 01-.19-.194v-2.423c0-.109.085-.195.19-.195s.19.086.19.195v2.423a.192.192 0 01-.19.193M29.283 42.829a.192.192 0 01-.19-.193v-1.162c0-.108.085-.195.19-.195.104 0 .19.087.19.195v1.162a.192.192 0 01-.19.193M26.118 20.183a.186.186 0 01-.13-.052.195.195 0 01-.01-.273l4.31-4.715a.186.186 0 01.268-.01.195.195 0 01.01.273l-4.308 4.715a.192.192 0 01-.14.062"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M34.734 20.183a.192.192 0 01-.14-.062l-4.306-4.715a.197.197 0 01.008-.273.188.188 0 01.27.01l4.307 4.715c.072.078.067.2-.01.273a.186.186 0 01-.13.052"
      clipRule="evenodd"
    ></path>
  </svg>
  );
}

export function ApartmentIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ApartmentIcon />
      <ApartmentIcon color="primary" />
      <ApartmentIcon color="secondary" />
      <ApartmentIcon color="action" />
      <ApartmentIcon color="disabled" />
      <ApartmentIcon style={{ color: green[500] }} />
    </div>
  );
}
