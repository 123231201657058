import React from 'react'
import styled from 'styled-components'
import HeadingContentSmall from '../Form/HeadingContentSmall'
import FormField from '../Form/FormField'
import { withStyles, Button } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    padding: '20px 21px 0 24px',
  },
})

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
  },
})(Button)

const LogoWrap = styled.div`
  background: black;
  padding: 20px;
  img {
    object-fit: cover;
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
  }
`
const ContentWrap = styled.div`
  padding: 20px 0;
`
const FormWrap = styled.div`
  padding: 0 0 0;
`
const ButtonWrap = styled.div`
  padding: 20px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DirectUploadStep1 = ({
  classes,
  setActiveStep,
  activeStep,
  addPropertyFields,
  handleFieldChange,
  handleCheckField,
  errors,
  logo,
  hex,
  backClick,
}) => {
  const [vendorEmail, setVendorEmail] = React.useState(null)
  const [vendorPhone, setVendorPhone] = React.useState(null)
  const [isFormInvalid, setIsFormInvalid] = React.useState(true)

  React.useEffect(() => {
    const errorBucket = []
    if (errors) {
      const errorsArr = Object.keys(errors)

      errorsArr.map((keys) => {
        if (errors[keys].status !== false || !vendorEmail || !vendorPhone) {
          errorBucket.push(true)
        }
      })
    }

    if (errorBucket.length > 0) {
      setIsFormInvalid(true)
    } else {
      setIsFormInvalid(false)
    }
  }, [addPropertyFields])

  React.useEffect(
    () => (backClick ? setIsFormInvalid(false) : setIsFormInvalid(true)),
    []
  )

  return (
    <div className={classes.root}>
      <LogoWrap style={{ backgroundColor: hex }}>
        <img src={logo} />
      </LogoWrap>

      <ContentWrap>
        <HeadingContentSmall
          title={`Property Direct Upload Step 1 of ${
            addPropertyFields &&
            addPropertyFields.propertyInfo.address.value.state !== 'VIC'
              ? '3'
              : '4'
          }`}
          content={`Your Information`}
        />
      </ContentWrap>

      <FormWrap>
        <HeadingContentSmall title={'Agent Information'} />

        <FormField
          type={'text'}
          placeholder={'First name *'}
          errorMsg={''}
          showError={false}
          value={addPropertyFields.agentInfo.firstName.value}
          disabled
        />

        <FormField
          type={'text'}
          placeholder={'Last name *'}
          errorMsg={''}
          showError={false}
          value={addPropertyFields.agentInfo.lastName.value}
          disabled
        />

        <FormField
          type={'email'}
          placeholder={'Your Email *'}
          errorMsg={''}
          showError={false}
          value={addPropertyFields.agentInfo.email.value}
          disabled
        />

        <FormField
          type={'tel'}
          placeholder={'Your Mobile Number *'}
          errorMsg={''}
          showError={false}
          value={addPropertyFields.agentInfo.phone.value}
          telformat={'9999999999'}
          disabled
        />

        <FormField
          type={'email'}
          placeholder={'Agency email address *'}
          errorMsg={''}
          showError={false}
          value={addPropertyFields.agentInfo.agencyEmail.value}
          disabled
        />

        <FormField
          type={'email'}
          placeholder={'Vendor email address *'}
          errorMsg={errors.vendorsEmail.message}
          showError={errors.vendorsEmail.status}
          value={addPropertyFields.agentInfo.vendorsEmail.value}
          onChange={(e) => {
            const value = e.target.value
            setVendorEmail(value)
            handleFieldChange('agentInfo', null, 'vendorsEmail', value)
            handleCheckField(value, value, 'vendorsEmail')
          }}
        />

        <FormField
          type={'tel'}
          placeholder={'Vendor Phone Number'}
          errorMsg={errors.vendorsPhone.message}
          showError={errors.vendorsPhone.status}
          value={addPropertyFields.agentInfo.vendorsPhone.value}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, '')
            setVendorPhone(value)
            handleFieldChange('agentInfo', null, 'vendorsPhone', value)
            handleCheckField(value, value, 'vendorsPhone')
          }}
          maxlength={14}
          telformat={'9999999999'}
        />
      </FormWrap>

      <ButtonWrap>
        <StyledButton
          color="primary"
          variant="contained"
          disabled={isFormInvalid}
          onClick={() => {
            setActiveStep(activeStep + 1)
          }}
        >
          Next
        </StyledButton>
      </ButtonWrap>
    </div>
  );
}

export default withStyles(styles)(DirectUploadStep1)
