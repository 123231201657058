import React from "react";
import PropTypes from 'prop-types';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DynamicIcons from '../DynamicIcons'

import { 
  toggleThemeMode, 
  toggleSignUp,
  navigateReset,
  logoutRequest
} from "../../../store/actions";

import Login from "./Login";
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import HeadingContent from './HeadingContent'

import ButtonGroupsCustom from "../ButtonGroupsCustom";

import { Paper, AppBar, Tabs, Tab, Container, TextField, Button, Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    padding: 0,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      outline: 'none',
    },
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
    boxShadow:'none'
  },
  container: {
    padding:0
  },
  profileContainer: {
    padding: '30px 24px 50px',
  },
  avatar: {
    
  }
});

const StyledButton = withStyles({
  root: {
    borderRadius:30,
    textTransform:'initial',
    fontWeight:'400',
    letterSpacing:'0.073125px',
    padding: '9px 15px',
    minWidth:'118px'
  }
})(Button);

const MainHeader = withStyles({
  root: {
    boxShadow:'none',
    padding:'10px 0px'
  }
})(AppBar);

const TabHeader = withStyles({
  root: {
    justifyContent:'space-evenly',
    padding:'0 21px'
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    width:'calc(50% - 10px)',
    margin:'0 5px',
    backgroundColor:'#F5F5F5',
  },
  selected: {
    color:'#000 !important',
    backgroundColor:'#fff'
  }
})(Tab);

const BackButton = withStyles({
  root: {
    width:'100px'
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const ScrollContainer = withStyles({
  root: {
    overflowY:'auto', overflowX:'hidden', maxHeight:'calc(90vh - 104px)', width:'100%'
  }
})(Container);

const Authentication = props => {
  const { 
    classes, 
    type, 
  } = props;

  /** redux props */
  const {
    settings,
    user,
    authentication
  } = props;

  /** redux api actions */
  const {
    toggleSignUp,
    navigateResetRedux,
    logoutUser,
  } = props;

  const [value, setValue] = React.useState(0);
  const [isForgotPasswordView, setIsForgotPasswordView] = React.useState(false);
  const [isLogged, setIsLogged] = React.useState(false);

  React.useEffect(() => {
    /** Check if Token Exist */
    if(authentication.token){
      setIsLogged(true);
      toggleSignUp(false);
    } else {
      setIsLogged(false);
    }
  },[authentication])

  React.useEffect(() => {
    return () => {
      /** 
       * Reset Redux Values (API Error Return) 
       * */
      navigateResetRedux();
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    
    /** 
     * Reset Redux Values (API Error Return) 
     * */
    navigateResetRedux();
  };

  const handleForgotPasswordView = (value) => {
    setIsForgotPasswordView(value)
    
    /** 
     * Reset Redux Values (API Error Return) 
     * */
    navigateResetRedux();
  };

  function renderProfile(){
    return(
      <ScrollContainer className={classes.profileContainer}>
        <Grid item xs={12} >
          <HeadingContent 
            title={'Profile'}
            // content={`User Profile`}
          />
          <StyledButton 
            color="primary"
            variant="contained"
            disabled={false}
            onClick={()=> {
              logoutUser()
              //toggleSignUp()
            }}
          >
            Log out
          </StyledButton>
        </Grid>
      </ScrollContainer>
    )
  }

  function renderForms(){
    return(
      <div>
        <AppBar className={classes.appBar} position="static">
          <TabHeader
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
          >
            <StyledTab label="LOGIN" />
            <StyledTab label="SIGN UP" />
          </TabHeader>
        </AppBar>
        <ScrollContainer className={classes.container}>
        {
          value === 0 ?
            isForgotPasswordView ? 
              <ForgotPassword setIsForgotPasswordView={handleForgotPasswordView} tabChange={handleChange}/> : 
              <Login setIsForgotPasswordView={handleForgotPasswordView} tabChange={handleChange}/>
          : value === 1 ?
            <Register tabChange={handleChange} />
          : null
        }
        </ScrollContainer>
      </div>
    )
  }

  return (
    <Paper className={classes.root} elevation={5}>
      <MainHeader className={classes.appBar} position="static">
          <BackButton 
            variant="text" 
            disableRipple={true} 
            fullWidth={false} 
            onClick={()=> toggleSignUp(!settings.showSignUp)}><DynamicIcons icon="ChevronLeft" /> BACK</BackButton>
      </MainHeader>
      {
        !isLogged &&
        renderForms()
      }
    </Paper>
  )
};

Authentication.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    settings: state.settings,
    user: state.user,
    authentication: state.authentication,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleThemeMode: checked => toggleThemeMode(checked),
      toggleSignUp: checked => toggleSignUp(checked),
      navigateResetRedux: () => navigateReset(),      
      logoutUser: () => logoutRequest(),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Authentication));