import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Button,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons'

import clsx from 'clsx'
import { toDateFormat } from 'utils/formatter/dateFormatter'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
  table: {
    height: '70vh',
  },
})

const TABLE_HEADER = [
  {
    key: 'date',
    label: 'Date',
  },
  {
    key: 'sender',
    label: 'Sender',
  },
  {
    key: 'subject',
    label: 'Subject',
  },
  {
    key: 'mailType',
    label: 'Mail Type',
  },
  {
    key: 'content',
    label: 'Content',
  },
]

const EmailLogsTable = (props) => {
  const { classes, history, tableData, pagination, showEmailDetails } = props

  const [currentPage, setCurrentPage] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleChangePage = (event, newPage) => {
    // handlePageUpdate(newPage + 1);
    setCurrentPage(newPage)
  }

  const _renderRows = () => {
    return (
      <TableBody>
        {tableData &&
          tableData.map((value, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" className={classes.mainText}>
                {toDateFormat(value.createdAt, 'momentCompare')}
              </TableCell>
              <TableCell align="left">{value.mailSenderAddress}</TableCell>
              <TableCell align="left">{value.mailSubject}</TableCell>
              <TableCell align="left">{value.mailType}</TableCell>
              <TableCell>
                <Button
                  variant={'text'}
                  color={'primary'}
                  onClick={(e) => {
                    showEmailDetails(value)
                  }}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    )
  }

  const _renderEmpty = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell align={'center'} colSpan={TABLE_HEADER.length + 1}>
            No Data to View
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  return (
    <div className={classes.root}>
      <TableContainer className={classes.table} component={Paper}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              {TABLE_HEADER.map((value, index) => {
                return <TableCell key={index}>{value.label}</TableCell>
              })}
            </TableRow>
          </TableHead>
          {tableData && tableData.length > 0 && _renderRows()}
          {tableData && tableData.length === 0 && _renderEmpty()}
        </Table>
      </TableContainer>
      {tableData && tableData.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          count={
            pagination && pagination.totalItemCount ? pagination.totalItemCount : 0
          }
          rowsPerPage={10}
          page={currentPage}
          onPageChange={handleChangePage}
        />
      )}
    </div>
  )
}

EmailLogsTable.propTypes = {
  classes: PropTypes.object,
  tableData: PropTypes.array,
  pagination: PropTypes.object,
  showEmailDetails: PropTypes.func,
}

export default withStyles(styles)(EmailLogsTable)
