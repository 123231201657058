import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {    
        display: 'flex',
        width: 'auto',
        overflowX: 'scroll',
        paddingRight: '0.625em',
        paddingLeft: '0.625em',
        paddingBottom: '0.625em'
    }
  });

const HorizontalContainer = props => {
    const { 
        classes,
        children 
    } = props;

    return (
        <div className={classes.root}>                    
           {children}
        </div>
    )
}

export default withStyles(styles)(HorizontalContainer);
