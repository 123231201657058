import React from 'react'
import { useHistory } from 'react-router-dom'

import MenuIcon from '@material-ui/icons/Menu'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'
import { connect } from 'react-redux'

import { toggleThemeMode, toggleSignUp, logoutRequest } from '../../../store/actions'

import DynamicIcons from '../../../components/Mobile/DynamicIcons'
import PMLogo from '../../../assets/images/pm-logo-inverted.png'

import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  appBar: {
    boxShadow: 'none',
    paddingTop: '10px',
  },
  toolbarRoot: {
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
  },
})

const ButtonLogo = withStyles({
  root: {
    width: '24px',
    minWidth: '39px',
    padding: '0',
  },
})(Button)

const LeftWrap = styled.a``

const RightWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const LogoWrap = styled.div`
  max-width: 147px;
  width: 100%;
  margin: 0 auto;
  img {
    width: 100%;
  }
`
const NotificationBell = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  span {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #35c0ca;
  }
`

const Header = ({
  classes,
  handleToggleDrawer,
  settings,
  authentication,
  logoutUser,
  toggleSignUp,
  type,
  currentRoute,

  auth,
}) => {
  const history = useHistory()
  const [routerChange] = useRouterChange()

  function checkTokenStatus() {
    if (authentication.token) {
      logoutUser()
      routerChange('/')
    } else {
      // toggleSignUp(!settings.showSignUp);
      history.push('/login', {
        origin: window.location.pathname,
      })
    }
  }

  return (
    <AppBar
      position="fixed"
      className={classes.appBar}
      style={{ background: type === 'LIGHT' ? 'transparent' : '#FFF' }}
    >
      <Toolbar classes={{ root: classes.toolbarRoot }}>
        <LeftWrap>
          <Tooltip title="Menu">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              onClick={handleToggleDrawer}
              className={classes.menuButton}
            >
              <MenuIcon style={{ color: type === 'LIGHT' ? '#FFF' : '#000' }} />
            </IconButton>
          </Tooltip>
          {currentRoute !== '/' && (
            <ButtonLogo
              disableRipple={true}
              onClick={(e) => {
                routerChange('/')
              }}
            >
              <DynamicIcons
                icon="2DCPIconColor"
                style={{
                  width: '24px',
                  height: '35px',
                  position: 'relative',
                  top: '-3px',
                }}
              />
            </ButtonLogo>
          )}
        </LeftWrap>
        {currentRoute === '/' && (
          <LogoWrap>
            <img src={PMLogo} alt="PropertyMate" />
          </LogoWrap>
        )}
        <RightWrap>
          {/*
            !NOTE: Temporarily disabled until Notification server is enabled and re-integration is done
          {auth === "logged" && (
            <Tooltip title="Notifications">
              <NotificationBell
                onClick={(e) => {
                  e.preventDefault();
                  routerChange("/notification-list");
                }}
              >
                <span></span>
                <DynamicIcons
                  icon="Notifications"
                  style={{
                    fill: type === "LIGHT" ? "#fff" : "#000",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </NotificationBell>
            </Tooltip>
          )} */}
          <Button
            color="inherit"
            style={{ color: type === 'LIGHT' ? '#FFF' : '#000' }}
            onClick={() => checkTokenStatus()}
          >
            {authentication.token ? 'SIGN OUT' : 'LOG IN'}
          </Button>
        </RightWrap>
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    authentication: state.authentication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      toggleSignUp: (checked) => toggleSignUp(checked),
      logoutUser: () => logoutRequest(),
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header))
