import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import DynamicIcons from '../DynamicIcons';

import { withStyles, List, ListItem, ListItemText, ListItemIcon, Button, Typography } from '@material-ui/core';
import useGoogleEvents from 'utils/customHooks/useGoogleEvents';

const styles = (theme) => ({
  root: {},
  listitem: {
    paddingLeft: 30,
    paddingTop: 0,
    paddingBottom: 0,
  },
  listText: {
    '& svg': {
      fill: 'rgba(0, 0, 0, 0.54)',
      marginRight: 28,
    },
    '& button': {
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
    },
    '& .MuiListItemText-primary > span > span': {
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      paddingTop: '6px !important',
      paddingBottom: '6px !important',
    },
  },
});

const ListOptions = (props) => {
  const { classes, options, data, properties } = props;

  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents();

  const handleListItemClick = (event, index, type, url) => {
    setSelectedIndex(index);
  };

  const canSendSms = () => {
    const platform = navigator.platform;
    const isMobile = platform.indexOf('iPhone') !== -1 || platform.indexOf('Android') !== -1;
    return isMobile;
  };

  const showAlert = () => {
    alert('Sharing via SMS is not supported on this device.');
  };

  return (
    <>
      <List component="nav">
        {options &&
          options.value.map((value, index) => {
            switch (value.type) {
              case 'WhatsApp':
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => {
                      handleListItemClick(event, index);
                      triggerAnalyticsEvent({
                        event: 'property_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'property_share',
                        actionName: 'Shared property through Whatsapp',
                        actionTarget: 'Whatsapp',
                        actionReference: 'property',
                        ...getPropertyData(properties),
                      });
                    }}
                  >
                    <ListItemText
                      primary={
                        <WhatsappShareButton url={window.location.href}>
                          <ListItemIcon>
                            <DynamicIcons icon={value.icon} />
                          </ListItemIcon>
                          {value.name}
                        </WhatsappShareButton>
                      }
                      className={classes.listText}
                    />

                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );

              case 'Email':
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => {
                      handleListItemClick(event, index);
                      triggerAnalyticsEvent({
                        event: 'property_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'property_share',
                        actionName: 'Shared property through Email',
                        actionTarget: 'Email',
                        actionReference: 'property',
                        ...getPropertyData(properties),
                      });
                    }}
                  >
                    <ListItemText
                      primary={
                        <EmailShareButton
                          subject={`PropertyMate Property | ${data?.priceView}`}
                          url={window.location.href}
                          body={''}
                        >
                          <ListItemIcon>
                            <DynamicIcons icon={value.icon} />
                          </ListItemIcon>
                          {value.name}
                        </EmailShareButton>
                      }
                      className={classes.listText}
                    />

                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );

              case 'Facebook':
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => {
                      handleListItemClick(event, index);
                      triggerAnalyticsEvent({
                        event: 'property_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'property_share',
                        actionName: 'Shared property through Facebook',
                        actionTarget: 'Facebook',
                        actionReference: 'property',
                        ...getPropertyData(properties),
                      });
                    }}
                  >
                    <ListItemText
                      primary={
                        <FacebookShareButton url={window.location.href} quote={data?.description ?? ''}>
                          <ListItemIcon>
                            <DynamicIcons icon={value.icon} />
                          </ListItemIcon>
                          {value.name}
                        </FacebookShareButton>
                      }
                      className={classes.listText}
                    />

                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );

              case 'LinkedIn':
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => {
                      handleListItemClick(event, index);
                      triggerAnalyticsEvent({
                        event: 'property_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'property_share',
                        actionName: 'Shared property through LinkedIn',
                        actionTarget: 'LinkedIn',
                        actionReference: 'property',
                        ...getPropertyData(properties),
                      });
                    }}
                  >
                    <ListItemText
                      primary={
                        <LinkedinShareButton url={window.location.href}>
                          <ListItemIcon>
                            <DynamicIcons icon={value.icon} />
                          </ListItemIcon>
                          {value.name}
                        </LinkedinShareButton>
                      }
                      className={classes.listText}
                    />

                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );

              case 'Twitter':
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => {
                      handleListItemClick(event, index);
                      triggerAnalyticsEvent({
                        event: 'property_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'property_share',
                        actionName: 'Shared property through Twitter',
                        actionTarget: 'Twitter',
                        actionReference: 'property',
                        ...getPropertyData(properties),
                      });
                    }}
                  >
                    <ListItemText
                      primary={
                        <TwitterShareButton url={window.location.href}>
                          <ListItemIcon>
                            <DynamicIcons icon={value.icon} />
                          </ListItemIcon>
                          {value.name}
                        </TwitterShareButton>
                      }
                      className={classes.listText}
                    />

                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );

              case 'SMS':
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => {
                      if (canSendSms()) {
                        handleListItemClick(event, index, value.type, window.location.href);
                        triggerAnalyticsEvent({
                          event: 'property_actions',
                          eventFrom: 'Mobile',
                          actionScope: 'property_share',
                          actionName: 'Shared property through SMS',
                          actionTarget: 'SMS',
                          actionReference: 'property',
                          ...getPropertyData(properties),
                        });
                      }
                    }}
                  >
                    <ListItemIcon>
                      <DynamicIcons icon={value.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <span className={classes.pseudoBtn}>
                          <Button
                            onClick={canSendSms() ? null : showAlert}
                            href={
                              canSendSms()
                                ? `sms:?&body=Hey, check out this property I found on PropertyMate: ${window.location.href}`
                                : null
                            }
                            style={{
                              padding: '6px 0px',
                              textTransform: 'none',
                              justifyContent: 'flex-start',
                            }}
                          >
                            <Typography variant="body1">{value.name}</Typography>
                          </Button>
                        </span>
                      }
                      className={classes.listText}
                    />

                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );

              case 'Clipboard':
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => {
                      handleListItemClick(event, index, value.type, window.location.href);
                      triggerAnalyticsEvent({
                        event: 'property_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'property_share',
                        actionName: 'Shared property through Copy Link',
                        actionTarget: 'Copy Link',
                        actionReference: 'property',
                        ...getPropertyData(properties),
                      });
                    }}
                  >
                    <ListItemIcon>
                      <DynamicIcons icon={value.icon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <span className={classes.pseudoBtn}>
                          <CopyToClipboard text={window.location.href}>
                            <span>{selectedIndex === index ? 'Copied' : value.name}</span>
                          </CopyToClipboard>
                        </span>
                      }
                      className={classes.listText}
                    />

                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );

              default:
                return (
                  <ListItem
                    key={index}
                    button
                    selected={selectedIndex === index}
                    className={classes.listitem}
                    onClick={(event) => handleListItemClick(event, index)}
                  >
                    <ListItemIcon>
                      <DynamicIcons icon={value.icon} />
                    </ListItemIcon>
                    <ListItemText primary={value.name} className={classes.listText} />
                    {selectedIndex === index && <CheckCircle style={{ color: '#27AE60' }} />}
                  </ListItem>
                );
            }
          })}
      </List>
    </>
  );
};

export default withStyles(styles)(ListOptions);
