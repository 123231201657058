import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import SnackbarProvider from 'react-simple-snackbar';

import styled from 'styled-components';
import { connect } from 'react-redux';

import AgencyLayout from './layouts/Web/AgencyLayout';
import AgentLayout from './layouts/Web/AgentLayout';
import AdminLayout from './layouts/Web/AdminLayout';
import AuthLayout from './layouts/Web/AuthLayout';
import MainLayout from './layouts/Web/MainLayout';
import EmptyLayout from './layouts/Web/EmptyLayout';

/** Import All Routes */
import {
  AgencyRoutes,
  AgentRoutes,
  CommonRoutes,
  EmailRoutes as EmailLogs,
  HomeRoutes,
  SuperadminRoutes,
  TradesRoutes,
} from './routes/Web';

/** Import Themes */
import DARK_THEME from './theme/dark';
import LIGHT_THEME from './theme/light';

import { createBrowserHistory } from 'history';

import { APP_MODE, APP_VERSION } from '../src/utils/constants.utils';

import { SET_API_TOKEN } from '../src/utils/api';

export const history = createBrowserHistory();

const NotFound = () => {
  return <div>NotFound</div>;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AuthLayout>
          <Component {...matchProps} />
        </AuthLayout>
      )}
    />
  );
};

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AdminLayout>
          <Component {...matchProps} />
        </AdminLayout>
      )}
    />
  );
};

const AgencyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AgencyLayout>
          <Component {...matchProps} />
        </AgencyLayout>
      )}
    />
  );
};

const AgentRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AgentLayout>
          <Component {...matchProps} />
        </AgentLayout>
      )}
    />
  );
};

const ScrollContainer = styled.div`
  height: ${(props) => (props.vh ? props.vh + 'px' : 'auto')};
`;

const WebApp = (props) => {
  const { settings } = props;

  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    /** prevent body scroll */
    // document.body.style.overflow = "hidden";

    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
      setInnerWidth(window.innerWidth);
    });

    /**
     * * CUSTOMER SET API TOKEN
     * ? IMPORTANT KEY PART OF AUTHENTICATION
     * */
    if (
      localStorage.getItem('persist:root') !== null &&
      JSON.parse(JSON.parse(localStorage.getItem('persist:root')).authentication) &&
      JSON.parse(JSON.parse(localStorage.getItem('persist:root')).authentication).token
    ) {
      let cTokenCustomer = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).authentication).token.token;
      SET_API_TOKEN(cTokenCustomer);
    }
    /**
     * * SUPERADMIN SET API TOKEN
     * ? IMPORTANT KEY PART OF AUTHENTICATION
     */
    if (
      localStorage.getItem('persist:root') !== null &&
      JSON.parse(JSON.parse(localStorage.getItem('persist:root')).superadmin) &&
      JSON.parse(JSON.parse(localStorage.getItem('persist:root')).superadmin).superadmintoken
    ) {
      let cTokenSuperadmin = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).superadmin).superadmintoken
        .token;
      SET_API_TOKEN(cTokenSuperadmin);
    }
    document.querySelector('#scroll-container').scrollTo(0, 0);
  }, []);

  function checkIfFilterOpen() {
    const { settings } = props;
    return settings.showFilter === true ? 'scroll' : 'scroll';
  }

  return (
    <MuiThemeProvider theme={settings.darkMode ? DARK_THEME : LIGHT_THEME}>
      <CssBaseline />
      <SnackbarProvider>
        <ScrollContainer id="scroll-container" style={{ overflowX: 'hidden' }}>
          <Router history={history}>
            <Switch>
              {HomeRoutes.map((value, index) => {
                return <MainRoute key={index} path={value.path} component={value.component} exact />;
              })}

              {TradesRoutes.map((value, index) => {
                return <MainRoute key={index} path={value.path} component={value.component} exact />;
              })}

              {CommonRoutes.map((value, index) => {
                return <AuthRoute key={index} path={value.path} component={value.component} exact />;
              })}

              {AgentRoutes.map((value, index) => {
                return <AgentRoute key={index} path={value.path} component={value.component} exact />;
              })}

              {EmailLogs.map((value, index) => {
                return <MainRoute key={index} path={value.path} component={value.component} exact />;
              })}

              {SuperadminRoutes.map((value, index) => {
                return <AdminRoute key={index} path={value.path} component={value.component} exact />;
              })}

              {AgencyRoutes.map((value, index) => {
                return <AgencyRoute key={index} path={value.path} component={value.component} exact />;
              })}
              {/* <EmptyRoute component={NotFound} /> */}
              <Redirect to={'/'} />
            </Switch>
          </Router>
        </ScrollContainer>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps, null)(WebApp);
