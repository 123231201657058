import React, { useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import LottieAnimation from '../../components/Mobile/LottieAnimation';

/** actions */
import {
  clearSession
} from '../../store/actions';

import { Typography, Container } from '@material-ui/core';

const Splash = props => {

  useEffect(() => {
    if (!props.users.user) {
      localStorage.clear();
      /** CLEAR ALL SESSION */
      props.clearAllSession();
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0f171d',
        width: '100vw',
        height: '100vh',
        pointerEvents: 'none'
      }}>
      <LottieAnimation />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    users: state.users
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearAllSession: checked => clearSession(checked),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Splash);
