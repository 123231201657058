import React from 'react';
import { grey } from "@material-ui/core/colors";
import { withStyles, Button } from '@material-ui/core';

const StyledButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText(grey[100]),
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      lineHeight: '1.0625rem',
      letterSpacing: '-0.2px',
      display: 'block',
      width: '100%',
      textTransform: 'uppercase',
      backgroundColor: grey[100],
      '&:hover': {
        backgroundColor: grey[200],
      },
    },
  }))(Button);

const BlockButton = props => {    
    return (
        <StyledButton { ...props }>{props.children}</StyledButton>
    );
}

export default (BlockButton);