import React, { Fragment } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { scrollToRef } from 'components/Mobile/ScrollEvents';

import { Button, Grid, Typography, withStyles } from '@material-ui/core';

import Image from 'material-ui-image';
import ContactWrap from 'components/Web/Agency/ContactWrap';
import PropertyCardFull from 'components/Web/Property/PropertyCardFull';
import AgentCards from 'components/Web/Agent/AgentResults';
import _ from 'lodash';

import styled from 'styled-components';

/** api redux request */
import { requestAgencyDetails } from 'store/api';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 90,
    minHeight: 'calc(100vh - 231px)',
  },
  media: {
    maxHeight: 352 + 'px !important',
    objectFit: 'cover',
  },
  infoSection: {
    //backgroundColor: "#F5F5F5"
  },
  infoWrap: {
    margin: '0 auto',
    backgroundColor: '#F5F5F5',
    paddingLeft: '114px',
  },
  detailsGrid: {
    background: '#ffffff',
    paddingRight: 114 + 'px !important',
  },
});

const HeaderImage = styled.div`
  position: relative;
  max-height: 352px;
  overflow: hidden;
  .bg-overlay {
    position: absolute;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
  }
`;

const AgencyLogo = styled.img`
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 30%;
  left: 50%;
  text-align: center;
`;

const Content = styled.div`
  padding: 20px 25px;

  h2 {
    font-size: 20px;
    margin-bottom: 13px;
  }

  h6 {
    font-family: Roboto;
    font-weight: 500;
    margin-bottom: 13px;
  }
`;

const PropertyWrap = styled.div``;

const PropertyFeeds = styled.div`
  padding-top: 15px;

  h2 {
    font-weight: 400;
    padding-bottom: 10px;
  }
`;

const AgencyDetails = (props) => {
  const { classes, currentAgency, getAgencyDetails } = props;

  const [agencyData, setAgencyData] = React.useState(null);
  const [sold, setSold] = React.useState(null);
  const [sale, setSale] = React.useState(null);
  const addressRegex = / *AU *$|AUS *$/gm;

  const topContainer = React.useRef(null);
  const executeTopScroll = (d) => scrollToRef(topContainer, d); //add `false` as second parameter to scroll without transition.

  /**
   * Save Current Agency API */
  async function apiRequestSetCurrentAgency() {
    let params = props.match.params.id + '/profile';
    if (params) {
      getAgencyDetails(params);
    }
  }

  function iterateItems(obj) {
    const items = [];
    for (const [details] of Object.entries(obj)) {
      items.push({ ...details });
    }
    return items;
  }

  React.useEffect(() => {
    apiRequestSetCurrentAgency();
  }, []);

  React.useEffect(() => {
    if (currentAgency && currentAgency.data && currentAgency.data.agency) {
      setAgencyData(currentAgency.data.agency);
      if (currentAgency.data.agency.properties) {
        const ALL_SOLD = _.pickBy(currentAgency.data.agency.properties, (e) => _.includes(['sold'], e.propertyStatus));
        const ALL_CURRENT = _.pickBy(currentAgency.data.agency.properties, (e) =>
          _.includes(['current', 'On-Sale', 'rental'], e.propertyStatus),
        );
        setSold(iterateItems(ALL_SOLD));
        setSale(iterateItems(ALL_CURRENT));
      }
    }
  }, [currentAgency]);

  return (
    <div className={classes.root}>
      <div ref={topContainer}></div>
      <HeaderImage>
        <div className="bg-overlay"></div>
        {agencyData && agencyData.coverImageUrl && (
          <Image
            className={classes.media}
            src={agencyData.coverImageUrl}
            onClick={() => ''}
            aspectRatio={16 / 9}
            disableSpinner
            style={{ objectFit: 'cover' }}
          />
        )}
        {agencyData && agencyData.logoUrl && <AgencyLogo src={agencyData.logoUrl} />}
      </HeaderImage>
      <div className={classes.infoSection}>
        <Grid container spacing={2} className={classes.infoWrap}>
          <Grid item xs={12} lg={4} xl={3} className={classes.infoGrid}>
            {agencyData && <ContactWrap data={agencyData} />}
          </Grid>
          <Grid item xs={12} lg={8} xl={9} className={classes.detailsGrid}>
            {agencyData && (
              <Content>
                <Typography variant="h6">
                  {agencyData.registeredTradeName ? agencyData.registeredTradeName : agencyData.companyName}
                </Typography>

                <Typography variant="body1">
                  {agencyData.address && agencyData.address.replace(addressRegex, '')}
                </Typography>

                <Typography variant="body1">{agencyData.profilePropertiesIntro}</Typography>

                <PropertyWrap></PropertyWrap>
              </Content>
            )}
          </Grid>
        </Grid>
      </div>
      {agencyData && (
        <Fragment>
          {agencyData.properties.length > 0 && sold.length > 0 && (
            <Content>
              <PropertyFeeds>
                <Typography variant="h2">Properties Sold</Typography>
                <Grid container spacing={3}>
                  {sold &&
                    _.chunk(sold, 6)[0] &&
                    _.chunk(sold, 6)[0].map((value, index) => {
                      return (
                        <Grid item xs={6} lg={4} key={index}>
                          <PropertyCardFull properties={value} type={'properties'} />
                        </Grid>
                      );
                    })}
                </Grid>
              </PropertyFeeds>
            </Content>
          )}
          <Content>
            <PropertyFeeds>
              <Typography variant="h2">
                {agencyData.registeredTradeName ? agencyData.registeredTradeName : agencyData.companyName} Team
              </Typography>
              <Grid container spacing={3}>
                <AgentCards agentsData={agencyData.agents} executeTopScroll={executeTopScroll} />
              </Grid>
            </PropertyFeeds>
          </Content>
          {agencyData.properties.length > 0 && sale.length > 0 && (
            <Content>
              <PropertyFeeds>
                <Typography variant="h2">Our Properties</Typography>
                <Grid container spacing={3}>
                  {sale &&
                    _.chunk(sale, 6)[0] &&
                    _.chunk(sale, 6)[0].map((value, index) => {
                      return (
                        <Grid item xs={6} lg={4} key={index}>
                          <PropertyCardFull properties={value} type={'properties'} />
                        </Grid>
                      );
                    })}
                </Grid>
              </PropertyFeeds>
            </Content>
          )}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepCounter: null,
    currentAgency: state.agency.current_agency,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAgencyDetails: (params) => requestAgencyDetails(params),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AgencyDetails));
