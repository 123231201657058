export function getToConfirmProperty(state) {
  return state.propertyv2.to_confirm_property
}

export function getStatus(state) {
  return state.propertyv2.status
}

export function getError(state) {
  return state.propertyv2.error
}

export function getSavedSearchRequestStatus(state) {
  return state.property.property_save_search_status
}

export function getSavedSearchRequestError(state) {
  return state.property.property_save_search_error
}

export function getPropertyDetails(state) {
  return state.property.properties_details?.data?.data?.property
}
