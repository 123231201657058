import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { requestPOSTAPIAgency } from 'store/api'
import { AuthImages } from 'components/Web/Superadmin'
import { AgencyForgotForm } from 'components/Web/Agency'
import { getStatus, getErrors } from 'store/selectors/agencyv2.selectors'
import { agencyv2Actions } from 'store/actions'
import NotificationDialog from 'components/Web/CustomDialogs/NotificationDialog'
import AlertNotification from 'components/Web/Form/AlertNotification'
import { useRouterChange } from 'utils/router.utils';

//const STATIC_PASSWORD = "2dcsuperadmin";

const styles = (theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    height: '100vh',
    padding: 40,
    paddingLeft: '10vw',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const AgencyForgot = (props) => {
  const { classes } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()
  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [dialogContents, setDialogContents] = useState({
    title: '',
    text: '',
  })

  const [loading, setLoading] = useState(false)
  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)

  function goBack(path) {
    routerChange('back')
  }

  window.onbeforeunload = (e) => {
    return handleClearStates()
  }

  useEffect(() => {
    /**
     * * Default state refresher
     */
    handleClearStates()
    setConfirmDialog(false)
    return () => {
      handleClearStates()
    }
  }, [])

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'))
  }

  const handleClearStates = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
  }

  const handleConfirmDialog = () => {
    setConfirmDialog(false)
    if (STATUS === 'agency/AGENCY_RESET_PASSWORD_SUCCESS' || STATUS === null) {
      handleClearStatus()
      handleClearError()
    }
    setDialogContents({ title: '', text: '' })
  }

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  useEffect(() => {
    if (STATUS === 'agency/AGENCY_FORGOT_PASSWORD_REQUEST') {
      setLoading(true)
    }

    if (STATUS === 'agency/AGENCY_FORGOT_PASSWORD_SUCCESS') {
      setLoading(false)
      const options = {
        status: true,
        options: { severity: 'success', message: 'Reset code sent to your email.' },
      }
      setIsNotification(options)
    }

    if (STATUS === 'agency/AGENCY_FORGOT_PASSWORD_FAILED') {
      setLoading(false)
      const options = {
        status: true,
        options: {
          severity: 'error',
          message:
            ERROR?.message === 'User does not exist.'
              ? 'Sorry, this email is not yet assigned to an account'
              : ERROR?.message ?? 'An error occured. Please try again.',
        },
      }
      setIsNotification(options)
    }

    if (STATUS === 'agency/AGENCY_RESET_PASSWORD_REQUEST') {
      setLoading(true)
    }
    if (STATUS === 'agency/AGENCY_RESET_PASSWORD_FAILED') {
      setLoading(false)
      setConfirmDialog(true)
      setDialogContents({
        title: 'Error',
        text: ERROR.message,
      })
    }
    if (STATUS === 'agency/AGENCY_RESET_PASSWORD_SUCCESS') {
      setConfirmDialog(true)
      setDialogContents({
        title: 'Success',
        text: 'Password updated successfuly.',
      })
    }
  }, [STATUS, ERROR])

  const handleForgotPassword = (data) => {
    let request = {
      query: null,
      data: { email: data.email },
    }
    dispatch(requestPOSTAPIAgency(request, 'AGENCY_FORGOT_PASSWORD'))
  }

  const handleResetPassword = (data) => {
    let request = {
      query: null,
      data: { email: data.email, password: data.password, code: data.code },
    }
    dispatch(requestPOSTAPIAgency(request, 'AGENCY_RESET_PASSWORD'))
  }

  return (
    <div className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={5} className={classes.leftColumn}>
          <AgencyForgotForm

            goBack={goBack}
            handleFormSubmit={handleForgotPassword}
            handleFormSubmitReset={handleResetPassword}
            forgotStatus={
              STATUS === 'agency/AGENCY_FORGOT_PASSWORD_SUCCESS' ||
              STATUS === 'agency/AGENCY_RESET_PASSWORD_FAILED' ||
              STATUS === 'agency/AGENCY_RESET_PASSWORD_REQUEST'
            }
            resetStatus={STATUS === 'agency/AGENCY_RESET_PASSWORD_SUCCESS'}
          />

          {confirmDialog && STATUS !== null && (
            <NotificationDialog
              isOpen={confirmDialog}
              closeCallback={handleConfirmDialog}
              title={dialogContents.title}
              text={dialogContents.text}
              align={'center'}
            />
          )}
          {isNotification.status && ERROR && (
            <AlertNotification
              status={isNotification.status}
              options={isNotification.options}
              closeNotification={handleNotificationClose}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={7} className={classes.rightColumn}>
          <AuthImages type={'forgot-password'} />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(AgencyForgot)
