import React, { useState, useEffect, Fragment } from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';

import { connect, useSelector, useDispatch } from 'react-redux';
import Sidebar from 'components/Web/Sidebar';

import Footer from 'components/Web/Footer';
import { SuperHeader } from 'components/Web/Superadmin';

/** selectors */
import { getAuthentication, getSettings, getUser } from 'store/selectors/superadmin.selectors';

import { getUser as getUserAuthentication } from 'store/selectors/authentication.selectors';

import { toggleFilterWeb } from 'store/actions';

import AlertNotification from 'components/Web/Form/AlertNotification';
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
});

const AgentLayout = (props) => {
  const { classes, children } = props;
  const [routerChange] = useRouterChange();
  const agentRoutes = ['/agent/my-properties', '/agent/dashboard', '/agent/account-settings', '/agent/add-property'];

  const dispatch = useDispatch();

  const AUTH = useSelector(getAuthentication);
  const SETTINGS = useSelector(getSettings);
  const USER = useSelector(getUser);
  const USER_AUTH = useSelector(getUserAuthentication);

  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [isRestricted, setIsRestricted] = useState(true);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const [open, setOpen] = useState(false);

  const handleToggleDrawer = (value) => {
    setOpen(value);
  };

  const routeChange = (path) => {
    routerChange(path);
    setOpen(false);
    /** reset redux state */
    dispatch(toggleFilterWeb({ type: null, status: false }));
  };

  const handleNavigation = (type) => {
    routeChange(type.path);
  };

  useEffect(() => {
    if (USER_AUTH) {
      // If user is not login
      if (USER_AUTH?.user === null) {
        routeChange('/agent/login');
      }
      // If user is login and not an Agent
      if (
        agentRoutes.includes(children.props.history.location.pathname) &&
        USER_AUTH?.user?.userType === 'Agency' &&
        !USER_AUTH?.user?.userTypeList.some((i) => ['Agency', 'AgencyAdmin'].includes(i))
      ) {
        setIsRestricted(true);
        routeChange('/agency/dashboard');
      } else if (
        agentRoutes.includes(children.props.history.location.pathname) &&
        USER_AUTH?.user?.userType !== 'Agent' &&
        !USER_AUTH?.user?.userTypeList.some((i) => ['Agent'].includes(i))
      ) {
        setIsRestricted(true);

        setIsNotification({
          status: true,
          options: {
            severity: 'warning',
            message:
              'Access restricted! You tried to login as Agent with ' +
              USER_AUTH?.user?.userType +
              " account. You'll be redirected to homepage shortly.",
            duration: 5000,
            onClose: () => {
              routeChange('/');
            },
          },
        });
      } else {
        setIsRestricted(false);
      }
    }
  }, []);

  return (
    <Fragment>
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}

      {!isRestricted && (
        <>
          <Sidebar
            open={open}
            isAgent={true}
            isAgency={false}
            isSuperadmin={false}
            auth={AUTH && AUTH?.user_status}
            page={SETTINGS && SETTINGS?.showFilterWeb?.type}
            filterWebActive={SETTINGS && SETTINGS?.showFilterWeb?.status}
            handleToggleDrawer={handleToggleDrawer}
            handleNavigation={handleNavigation}
            routerChange={routeChange}
            {...children.props}
          />
          <main className={classes.root}>
            <SuperHeader {...children.props} user={USER_AUTH} />
            {children}
          </main>
        </>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(AgentLayout);
