import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import FormField from '../../../components/Mobile/Form/FormField';
import AlertNotification from '../../../components/Mobile/Form/AlertNotification'

import checkField from 'components/Common/Validator/validationService'

/** actions */
import {
  setPropertySuburbSearch,
  clearSuburbSearch,
} from '../../../store/actions';

/** api redux request */
import {
  requestAgentSetRating
} from "../../../store/api";

import { withStyles, Button, Typography, Badge, IconButton } from '@material-ui/core';

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px'
  },
})(Typography);

const BackButton = withStyles({
  root: {
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative'
  },
  headerImageContainer: {
    position: 'relative',
    width: '100%',
    height: 250
  },
  overlayColor: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,0.5)', // 'rgba(255,255,255,0.5)'
    width: '100%',
    height: 250
  },
  media: {
    width: '100%',
  },
  agentLogo: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    height: '3.857em',
    width: 'auto',
    imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    // imageRendering: 'pixelated',
    imageRendering: '-webkit-optimize-contrast'
  },
  profileImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: '#FFF',
  },
  avatarImage: {
    position: 'absolute',
    zIndex: 6,
    bottom: -50,
    width: '5.625em',
    height: '5.625em',
    borderRadius: '2.813em',
    border: '1px solid #FFF',
    objectFit: 'cover',
    margin: theme.spacing(2)
  },
  optionButton: {
    position: 'absolute',
    textTransform: 'none',
    width: 100,
    height: 35,
    marginRight: theme.spacing(2),
    bottom: -80,
    borderRadius: '1.250em',
    color: '#000',
    fontWeight: 'normal',
    float: 'right'
  },

  submitButton: {
    margin: '0 auto',
    borderRadius: '1.250em',
    textTransform: 'none',
    width: 124,
    display: 'inline-flex',
  },

  submitWrap: {
    display: 'flex',
    justifyContent: 'center'
  },

  content: {
    margin: theme.spacing(2)
  },

  agentName: {
    textAlign: 'left',
    fontSize: 21,
    fontWeight: 300,
    marginBottom: theme.spacing(2)
  },

  ratingsWrap: {
    display: "flex",
    alignItems: 'center',
  },
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  agentMetadata: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  contentText: {
    marginBottom: theme.spacing(3)
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: theme.spacing(1)
    }
  },
  messageField: {
    marginTop: theme.spacing(2)
  }
});

const RatingBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#6FCF97",
    color: '#ffffff',
    top: theme.spacing(-1),
    right: theme.spacing(-1),
  },
}))(Badge);

const RatingIconButton = withStyles((theme) => ({
  root: {
    boxShadow: "inset 0 0 0 0.25px #000000",
    marginRight: theme.spacing(1.5)
  },
}))(IconButton);

const AgentReview = props => {

  const {
    classes,
    setAgentRating,
    ratingInfo,
    agentData,
    setIsReviewing,
    isReviewing,
    match,
    authentication,
  } = props;


  const [message, setMessage] = React.useState('');
  const [isNotification, setIsNotification] = React.useState(false);
  const [notificationOptions, setNotificationOptions] = React.useState(null);
  const [errors, setErrors] = React.useState(
    {
      message: { status: false, message: '' },
    }
  )

  const REVIEW_VALIDATION_CONFIG = fields => ({
    fields: {
      message: {
        isRequired: { message: 'Message is required' }
      }
    },
    onSubmit: data => {
      if (data.isFormValid) {
        console.log('Submitted', data);
      } else {
        console.log('Please fix the errors of the form.');
      }
    },
    showErrors: 'change'
  })


  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }


  /** API Error Response Handler */
  function handleAPIErrorResponse() {

    /** Contact Admin */
    if (ratingInfo && ratingInfo.request_set_agent_rating_status === 'success') {
      handleNotification(true, { severity: 'success', message: "Review has been submitted." });
      setIsReviewing(false)
      return;
    }
    if (ratingInfo && ratingInfo.request_set_agent_rating_status) {
      if (ratingInfo.request_set_agent_rating_status === 'failed') {
        handleNotification(true, { severity: 'error', message: "Error submitting your review." })
        setIsReviewing(true)
        return;
      }
    }
  }

  /** 
   * Set Agent Rating API */
  async function apiRequestSetAgentRating() {

    if (authentication.user_status && authentication.user_status === 'logged') {
      let params = {
        id: match.params.id, request: {
          like: true,
          comment: message
        }
      }
      if (params) {
        setAgentRating(params);
      }
    } else {
      handleNotification(true, { severity: 'error', message: "Please login before you can submit your Agent Review" })
    }
  }

  const handleCheckField = (state, value, fieldName) => {
    if (checkField(state, REVIEW_VALIDATION_CONFIG(fieldName).fields[fieldName]) === null) {
      setErrors(prevState => ({ ...prevState, [fieldName]: { ...prevState[fieldName], status: false } }))
    } else {
      setErrors(prevState => ({ ...prevState, [fieldName]: { ...prevState[fieldName], status: true, message: checkField(value, REVIEW_VALIDATION_CONFIG(fieldName).fields[fieldName]) } }))
    }
  }

  React.useEffect(() => {

  }, [])

  React.useEffect(() => {
    handleAPIErrorResponse()
  }, [ratingInfo])



  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Typography variant="body1" component="p" className={classes.heading}>How would you rate {agentData.firstName}’s work overall? <br /> Give him a thumbs up</Typography>

      <RatingIconButton aria-label="rating" className={classes.marginBottom}>
        <ThumbUpAltIcon color="primary" />
      </RatingIconButton>

      <Typography variant="body2" component="p" className={classes.contentText}>Your review of {agentData.firstName}'s work <br /> Some things to consider</Typography>
      <Typography variant="body2" component="p">Make sure it's unique. We can't use copied reviews. <br />
        Please don't mention your name or financial details. <br />
        Keep it clean or we won't publish it. <br />
        Please check for errors before you submit it!</Typography>
      <span></span>
      <div className={classes.messageField}>
        <FormField
          type={'textarea'}
          value={message}
          placeholder={'Enter your review...'}
          errorMsg={errors.message.message}
          showError={errors.message.status}
          onChange={(e) => {
            var value = e.target.value;
            setMessage(value)
            if (message) {
              handleCheckField(message, value, 'message')
            }
          }
          }
          onBlur={(e) => {
            var value = e.target.value;
            setMessage(value)
            handleCheckField(message, value, 'message')
          }}
          rowsMin={5}

        />
      </div>

      <div className={classes.submitWrap}>
        <Button
          className={classes.submitButton}
          size={'small'}
          color={'primary'}
          variant={'contained'}
          disabled={message ? false : true}
          onClick={() => {
            apiRequestSetAgentRating();
          }}>
          {'Submit'}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  console.log(state.users, "STATE")
  return {
    stepCounter: null,
    ratingInfo: state.rateAgent,
    authentication: state.authentication,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setAgentRating: params => requestAgentSetRating(params)
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentReview));
