import React from "react";

import Image from "material-ui-image";

import guyOnChair from "../../../assets/images/guy-on-chair.png";

import { withStyles, Grid, Typography, Button } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  mainContainer: {
    "@media (max-width: 1024px)": {
      flexDirection: "column-reverse",
      flexWrap: "nowrap",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  heading: {
    fontFamily: "PT Serif, serif",
    fontWeight: 400,
    fontSize: 30,
    lineHeight: "29px",
    color: theme.palette.gray.main,
    marginBottom: theme.spacing(2),
    letterSpacing: "-0.78px",
    "& strong": {
      fontWeight: 700,
      fontFamily: "Montserrat, sans",
      display: "block",
    },
  },
  leadText: {
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(4),
    color: "#828282",
    "@media (max-width: 1140px)": {
      marginBottom: theme.spacing(3),
    },
  },
  button: {
    width: 148,
    backgroundColor: "#35C0CA",
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: 400,
    borderRadius: 20,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#21a9b3",
    },
  },
});

const NoResultsCard = ({ classes, toggleFilterWeb, pathName }) => (
  <Grid container spacing={3} className={classes.mainContainer}>
    <Grid item md={12} lg={5} xl={4} className={classes.textContainer}>
      <Typography component={"h1"} className={classes.heading}>
        <strong>the hunt</strong> continues.
      </Typography>
      <Typography component={"h6"} className={classes.leadText}>
        Fine-tune your search criteria and roll the dice again.
      </Typography>
      <Button
        className={classes.button}
        onClick={() =>
          toggleFilterWeb({ type: pathName.substr(1), status: true })
        }
      >
        Search Again
      </Button>
    </Grid>
    <Grid item md={12} lg={7} xl={8}>
      <Image
        src={guyOnChair}
        aspectRatio={16 / 9}
        imageStyle={{ objectFit: "cover" }}
        style={{ backgroundColor: "transparent" }}
      />
    </Grid>
  </Grid>
);

export default withStyles(styles)(NoResultsCard);
