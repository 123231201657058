import React, { useState, useEffect } from 'react'
import Image from 'material-ui-image'
import HeartFullIcon from '@material-ui/icons/Favorite'
import HeartIcon from '@material-ui/icons/FavoriteBorder'
import { connect } from 'react-redux'

import MapPropertyCardInfo from './MapPropertyCardInfo'

import IMGComingSoon from '../../../assets/images/coming_soon.svg'
import IMGOffMarket from '../../../assets/images/off_market.svg'

import { withStyles, Card, IconButton } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { useRouterChange } from 'utils/router.utils'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  wrap: {
    width: '100%',
  },
  root: {
    display: 'flex',
    position: 'relative',
  },
  rootActive: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  card: {
    minWidth: '10.500em',
    height: '13.438em',
    margin: '0.325em',
    marginBottom: '0.625em',
    position: 'relative',
  },
  cardActive: {
    minWidth: '10.500em',
    margin: '0.325em',
    marginBottom: '0.625em',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      width: '50%',
    },
    '& > div:last-child': {
      paddingTop: theme.spacing(1),
      paddingbottom: theme.spacing(1),
    },
    '& .MuiIconButton-label .MuiTypography-caption': {
      fontSize: '9px',
      color: '#000000',
    },
    '& .MuiTypography-body2': {
      fontSize: '1rem',
    },
    '& .MapPropertyCardInfo-main': {
      padding: theme.spacing(2),
    },
  },
  media: {
    width: '100%',
    height: '6.250em',
    objectFit: 'cover',
  },
  favoriteContainer: {
    width: '0.625em',
    height: '0.625em',
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
    width: '0.625em',
    height: '0.625em',
  },
  favoriteIconActive: {
    color: '#57bdc7',
    width: '0.625em',
    height: '0.625em',
  },
  relativeWrap: {
    position: 'relative',
  },
  offmarketApprox: {
    padding: theme.spacing(0.5, 1),
    width: '100%',
    display: 'inline-block',
    fontSize: 10,
  },
})

const MapPropertyCard = ({
  classes,
  type,
  properties,
  active,

  currentScreen,
  setCurrentProperty,
  pathType,
  currentPath,
  style,
  authentication,
  toggleSignUp,
  settings,
  apiRequestSaveProperty,
}) => {
  const [routerChange] = useRouterChange()
  const MPP = useSelector(getMpp)
  let likes = MPP?.favourites?.data?.likes

  const [heartClicked, setHeartClicked] = useState(null)

  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents()

  useEffect(() => {
    if (likes && properties?.property) {
      setHeartClicked(likes.includes(properties.property))
    }
  }, [likes, properties?.property])

  function imageType(type, image) {
    switch (type) {
      case 'auction':
        return image
      case 'comingsoon':
        return image ? image : IMGComingSoon
      case 'offmarket':
        return image ? image : IMGOffMarket
      default:
        return image
    }
  }
  function listingType(type) {
    switch (type) {
      case 'rental':
        return 'rent'
      case 'sold':
        return 'sold'
      default:
        return 'buy'
    }
  }

  function checkLoginStatus(e) {
    e.stopPropagation()
    if (authentication && authentication.user_status !== 'logged') {
      // toggleSignUp(!settings.showSignUp)
      routerChange('/login', { origin: window?.location?.pathname })
    } else {
      /** User Logged */

      // setHeartClicked((prev) => !prev)

      apiRequestSaveProperty(properties.property)
    }
  }

  function renderProperties() {
    return (
      <div
        className={active ? classes.wrap : ''}
        onClick={() => {
          setCurrentProperty(properties)
          if (properties.propertyStatus === 'offmarket') {
            routerChange('/off-market/' + properties.property, {
              previous_screen: currentScreen,
            })
          } else {
            if (pathType === 'property') {
              routerChange('/' + currentPath + '/' + properties.property, {
                previous_screen: currentScreen,
              })
            }

            if (pathType === 'liked') {
              routerChange('/liked-properties/' + properties.property, {
                previous_screen: currentScreen,
              })
            }
          }

          triggerAnalyticsEvent({
            event: 'results_actions',
            eventFrom: 'Mobile',
            actionScope: 'map_card_click',
            actionName: 'Property Card',
            actionTarget: 'Viewed Property',
            actionReference: 'results',
            ...getPropertyData(properties),
          })
        }}
        style={style}
      >
        {!active && (
          <div className={classes.root}>
            <Card className={classes.card}>
              <Image
                className={classes.media}
                src={imageType(properties.propertyStatus, properties.images[0])}
                aspectRatio={16 / 10}
                disableSpinner
              />
              <IconButton
                className={classes.favoriteContainer}
                onClick={(e) => {
                  checkLoginStatus(e)
                }}
              >
                {authentication &&
                authentication.user_status === 'logged' &&
                likes &&
                likes?.includes(properties?.property) ? (
                  <HeartFullIcon className={classes.favoriteIconActive} />
                ) : (
                  <HeartIcon className={classes.favoriteIcon} />
                )}
              </IconButton>
              <MapPropertyCardInfo type={type} properties={properties} />
            </Card>
          </div>
        )}
        {active && (
          <div className={classes.rootActive}>
            <Card className={classes.cardActive}>
              <div className={classes.relativeWrap}>
                <Image
                  className={classes.media}
                  src={imageType(properties.propertyStatus, properties.images[0])}
                  aspectRatio={16 / 10}
                  disableSpinner
                />
                {authentication &&
                authentication.user_status === 'logged' &&
                likes &&
                heartClicked ? (
                  <IconButton
                    className={classes.favoriteContainer}
                    onClick={(e) => {
                      checkLoginStatus(e)
                      triggerAnalyticsEvent({
                        event: 'results_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'map_card',
                        actionName: 'Property Card',
                        actionTarget: 'Remove from Likes',
                        actionReference: 'results',
                        ...getPropertyData(properties),
                      })
                    }}
                  >
                    <HeartFullIcon className={classes.favoriteIconActive} />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.favoriteContainer}
                    onClick={(e) => {
                      checkLoginStatus(e)
                      triggerAnalyticsEvent({
                        event: 'results_actions',
                        eventFrom: 'Mobile',
                        actionScope: 'map_card',
                        actionName: 'Property Card',
                        actionTarget: 'Add to Likes',
                        actionReference: 'results',
                        ...getPropertyData(properties),
                      })
                    }}
                  >
                    <HeartIcon className={classes.favoriteIcon} />
                  </IconButton>
                )}
              </div>
              <div>
                <MapPropertyCardInfo type={type} properties={properties} />
                {properties && properties.propertyStatus === 'offmarket' && (
                  <small className={classes.offmarketApprox}>
                    This position is approximate location within 200 meters.
                  </small>
                )}
              </div>
            </Card>
          </div>
        )}
      </div>
    )
  }

  return <>{renderProperties()}</>
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(MapPropertyCard))
