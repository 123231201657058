import { PURGE } from 'redux-persist';
import {
  SAVE_SKIPTAKEDATA,
  CLEAR_SKIPTAKEDATA
} from '../constants';

const defaultData = {
  propertiesFilters: [],
  skip: null,
  take: null,
  totalResults: 0,
  reference: null
}

const INITIAL_STATE = {
  buy: defaultData,
  rent: defaultData,
  "off-market": defaultData,
  "liked-properties": defaultData,
  "hush-hush": defaultData,
  "coming-soon": defaultData,
  favourites: defaultData,
  sold: defaultData,
};

export default function skipTakeReducers(state = INITIAL_STATE, action) {

  switch (action.type) {
    case SAVE_SKIPTAKEDATA:
      var ref = action.payload.reference;
      var properties = action.payload.data && action.payload.data.propertiesFilters ? action.payload.data.propertiesFilters : []
      state[ref] = {
        propertiesFilters: [...state[ref].propertiesFilters, ...properties],
        skip: action.payload.data.skip,
        take: action.payload.data.take,
        totalResults: action.payload.data.totalResults ? action.payload.data.totalResults : state[ref].totalResults,
        reference: ref
      }

      return {
        ...state,
      }
    case CLEAR_SKIPTAKEDATA:
      var ref = action.payload;
      state[action.payload] = { ...defaultData }
      return {
        ...INITIAL_STATE,
      }

    case PURGE:
      return INITIAL_STATE

    default:
      return state;
  }
};
