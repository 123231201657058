const DEFAULT_NAVIGATION = [
  {
    name: 'Find your perfect <br />property',
    cls: 'perfect-property',
    icon: 'ThumbUpAlt',
    path: '/perfect-property',
    active: false,
    filter: true,
  },
  {
    name: 'Find Hush-Hush <br />properties',
    cls: 'hush-hush',
    icon: 'WatchLater',
    path: '/hush-hush',
    active: false,
    filter: true,
  },
  {
    name: 'Buy',
    cls: 'buy',
    icon: 'AttachMoney',
    path: '/buy',
    active: false,
    filter: true,
  },
  {
    name: 'Rent',
    cls: 'rent',
    icon: 'VpnKey',
    path: '/rent',
    active: false,
    filter: true,
  },
  {
    name: 'Sold',
    cls: 'sold',
    icon: 'Gavel',
    path: '/sold',
    active: false,
    filter: true,
  },

  /* {
    name: 'Find a trade <br />or service',
    cls: 'trade',
    icon: 'Build',
    path: '/trades',
    active: false,
    filter: false
  }, 
  {
    name: 'Find an agent',
    cls: 'agent',
    icon: '2DCHousePin',
    path: '/agent',
    active: false,
    filter: false,
  },*/
]

const LOGGED_IN_NAVIGATION = [
  {
    name: 'Find your perfect <br />property',
    cls: 'perfect-property',
    icon: 'ThumbUpAlt',
    path: '/perfect-property',
    active: false,
    filter: true,
  },
  {
    name: 'Find Hush-Hush <br />properties',
    cls: 'hush-hush',
    icon: 'WatchLater',
    path: '/hush-hush',
    active: false,
    filter: true,
  },
  {
    name: 'Buy',
    cls: 'buy',
    icon: 'AttachMoney',
    path: '/buy',
    active: false,
    filter: true,
  },
  {
    name: 'Rent',
    cls: 'rent',
    icon: 'VpnKey',
    path: '/rent',
    active: false,
    filter: true,
  },
  {
    name: 'Sold',
    cls: 'sold',
    icon: 'Gavel',
    path: '/sold',
    active: false,
    filter: true,
  },
  /* {
    name: 'Find a trade <br />or service',
    cls: 'trade',
    icon: 'Build',
    path: '/trades',
    active: false,
    filter: false
  }, 
  {
    name: 'Find an agent',
    cls: 'agent',
    icon: '2DCHousePin',
    path: '/agent',
    active: false,
    filter: false,
  },*/
  {
    name: 'My favourite <br />properties',
    cls: 'favorite',
    icon: 'Favorite',
    path: '/favourites',
    active: false,
    filter: false,
  },
  /*   {
      name: 'My liked <br />properties',
      cls: 'liked',
      icon: 'ThumbUpAlt',
      path: '/liked-properties',
      active: false,
      filter: false,
    }, */
  // {
  //   name: "What's my <br />house worth",
  //   cls: 'house-worth',
  //   icon: 'MonetizationOn',
  //   path: '/house-worth',
  //   active: false,
  //   filter: false
  // },
  {
    name: 'Saved <br />searches',
    cls: 'saved',
    icon: '2DCSavedSearchIcon',
    path: '/saved-searches',
    active: false,
    filter: false,
  },
  {
    name: 'Account <br />Settings',
    cls: 'account-settings',
    icon: 'Settings',
    path: '/account-settings',
    active: false,
    filter: false,
  },
  /* {
    name: 'Privacy <br />Policy',
    cls: 'privacy',
    icon: 'Bookmark',
    path: '/privacy-policy',
    active: false,
    filter: false,
  }, */
]

const AGENT_NAVIGATION = [
  {
    name: 'Dashboard',
    cls: 'agent-dashboard',
    icon: '2DCListIcon',
    path: '/agent/dashboard',
    active: false,
    filter: false,
  },
  {
    name: 'Manage <br />Properties',
    cls: 'my-properties',
    icon: '2DCSearchIcon',
    path: '/agent/my-properties',
    active: false,
    filter: false,
  },
  /*  {
     name: 'Billing & <br />Payment',
     cls: 'billing',
     icon: 'AttachMoney',
     path: '/billing',
     active: false,
     filter: false,
   }, */
  {
    name: 'Account <br />Settings',
    cls: 'account-settings',
    icon: 'Settings',
    path: '/agent/account-settings',
    active: false,
    filter: false,
  },
]

const AGENCY_NAVIGATION = [
  {
    name: 'Dashboard',
    cls: 'agent-dashboard',
    icon: '2DCListIcon',
    path: '/agency/dashboard',
    active: false,
    filter: false,
  },
  {
    name: 'Manage <br />Agents',
    cls: 'agency-manage-agents',
    icon: 'AccountCircle',
    path: '/agency/manage-agents',
    active: false,
    filter: false,
  },
  {
    name: 'Billing & <br />Payment',
    cls: 'billing',
    icon: 'AttachMoney',
    path: '/agency/billing',
    active: false,
    filter: false,
  },
  {
    name: 'Manage <br />Properties',
    cls: 'agency-manage-properties',
    icon: '2DCSearchIcon',
    path: '/agency/manage-properties',
    active: false,
    filter: false,
  },
  {
    name: 'Change <br />Request',
    cls: 'change-request',
    icon: 'Autorenew',
    path: '/agency/change-request',
    active: false,
    filter: false,
  },
  {
    name: 'Account <br />Settings',
    cls: 'account-settings',
    icon: 'Settings',
    path: '/agency/account-settings',
    active: false,
    filter: false,
  },
]

const SUPERADMIN_NAVIGATION = [
  {
    name: 'Dashboard',
    cls: 'superadmin-dashboard',
    icon: '2DCListIcon',
    path: '/superadmin/dashboard',
    active: false,
    filter: false,
  },
  {
    name: 'Manage <br />Agencies',
    cls: 'superadmin-manage-agents',
    icon: 'AccountCircle',
    path: '/superadmin/manage-agencies',
    active: false,
    filter: false,
  },
  {
    name: 'Email <br />Logs',
    cls: 'email-logs',
    icon: 'Email',
    path: '/superadmin/email-logs',
    active: false,
    filter: false,
  },
  {
    name: 'Change <br />Requests',
    cls: 'change-requests',
    icon: 'Edit',
    path: '/superadmin/change-requests',
    active: false,
    filter: false,
  },
  {
    name: 'Add <br />Agency',
    cls: 'add-agency',
    icon: 'Add',
    path: '/superadmin/add-agency',
    active: false,
    filter: false,
  },
  // {
  //   name: 'Notification <br />List',
  //   cls: 'notification-list',
  //   icon: 'Notifications',
  //   path: '/superadmin/notification-list',
  //   active: false,
  //   filter: false,
  // },
  // {
  //   name: "Manage <br />Properties",
  //   cls: "superadmin-manage-properties",
  //   icon: "HomeWork",
  //   path: "/superadmin/manage-properties",
  //   active: false,
  //   filter: false,
  // },
  // {
  //   name: 'Account <br />Settings',
  //   cls: 'account-settings',
  //   icon: 'Settings',
  //   path: '/superadmin/account-settings',
  //   active: false,
  //   filter: false,
  // },
]

export {
  DEFAULT_NAVIGATION,
  LOGGED_IN_NAVIGATION,
  AGENT_NAVIGATION,
  AGENCY_NAVIGATION,
  SUPERADMIN_NAVIGATION,
}
