import React, { useEffect } from 'react'
import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  getAgencyHexByAgencyId,
  DEFAULT_AGENCIES,
  HEX_OBJECT,
} from '../../../constants/constants'
import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {},
  agencyBanner: {
    width: '100%',
    height: 60,
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  agentLogo: {
    // height: '1.8em',
    width: '85%',
    // maxWidth: '60%',
    maxHeight: '72%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    // imageRendering: 'pixelated',
    imageRendering: '-webkit-optimize-contrast',
    objectFit: 'contain',
  },
})

const checkIfLight = (color) => {
  const hex = color.replace('#', '')
  const c_r = parseInt(hex.substr(0, 2), 16)
  const c_g = parseInt(hex.substr(2, 2), 16)
  const c_b = parseInt(hex.substr(4, 2), 16)
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness > 155
}

const logoObject = LOGO_OBJECT,
  hexObject = HEX_OBJECT

const getAgencyLogo = (agencyName) => {
  if (
    logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
    logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
  ) {
    return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
  } else {
    return LogoPlaceholder
  }
}

const getAgencyHex = (agencyName) => {
  if (
    hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
    hexObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
  ) {
    return hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)]
  } else {
    return '#000000'
  }
}

const AgencyBar = (props) => {
  const { classes, property, agency } = props

  const brandColor =
    property.agency && property.agency.brandColor
      ? property.agency.brandColor
      : property.reaAgencyId
        ? getAgencyHex(property.reaAgencyId || property.agency.reaAgencyId)
        : '#000000'

  return (
    <div className={classes.root}>
      <div
        className={classes.agencyBanner}
        style={{
          backgroundColor: brandColor,
          boxShadow: checkIfLight(brandColor)
            ? '0 0 0 1px #000000'
            : 'none',
        }}
      >
        <img
          className={classes.agentLogo}
          src={
            property.agency && property.agency.logoUrl
              ? property.agency.logoUrl
              : getAgencyLogo(property.reaAgencyId)
          }
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(AgencyBar)
