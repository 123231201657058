import { compareArrays } from "./compareArrays"
import { compareMinMax } from "./compareMinMax"

export const compareRadiusDistance = (current, reference) => {

    if (current && reference) {
        const checkOptions = compareArrays(current.options, reference.options, "active")
        const checkMinMax = compareMinMax(current, reference)

        if (checkOptions === "widened" || checkMinMax === "widened") {
            return "widened"
        } else if (checkOptions === "narrowed" || checkMinMax === "narrowed") {
            return "narrowed"
        } else {
            return null
        }
    } else {
        return null
    }

}