import { POST, FETCH } from '../../utils/api';

import {
  agencyRegister,
  agencyRegisterSuccess,
  agencyRegisterFail,
  agencyContactAdmin,
  agencyContactAdminSuccess,
  agencyContactAdminFail,
  agencyDetails,
  agencyDetailsSuccess,
  agencyDetailsFail,
  agentsRegister,
  agentsRegisterSuccess,
  agentsRegisterFail,
  getAgencyAgents,
  getAgencyAgentsSuccess,
  getAgencyAgentsFail,
  resendAgentInvitation,
  resendAgentInvitationSuccess,
  resendAgentInvitationFail,
  disableAgent,
  disableAgentSuccess,
  disableAgentFail,
  verifyAgency,
  verifyAgencySuccess,
  verifyAgencyFail,
  agencyMe,
  agencyMeSuccess,
  agencyMeFail,
  upgradeProperty,
  upgradePropertySuccess,
  upgradePropertyFail,
  statistics,
  statisticsSuccess,
  statisticsFail,
  updateAgency,
  updateAgencySuccess,
  updateAgencyFail,
  applyPromoCode,
  applyPromoCodeSuccess,
  applyPromoCodeFail,
} from '../actions';

import { errorhandler } from '../api';

import { apiEndpoints } from '../../utils/api/endpoints';

export function requestAgencyRegister(params) {
  return (dispatch) => {
    dispatch(agencyRegister(params));
    POST(apiEndpoints.AGENCY_REGISTER, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyRegisterSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(agencyRegisterFail(errorhandler(error)));
      });
  };
}

export function requestAgencyContactAdmin(params) {
  return (dispatch) => {
    dispatch(agencyContactAdmin(params));
    POST(apiEndpoints.AGENCY_CONTACT_ADMIN, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyContactAdminSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(agencyContactAdminFail(errorhandler(error)));
      });
  };
}

export function requestAgencyDetails(params) {
  return (dispatch) => {
    dispatch(agencyDetails(params));
    FETCH(apiEndpoints.AGENCY_DETAILS + params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyDetailsSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(agencyDetailsFail(errorhandler(error)));
      });
  };
}

export function requestAgentsRegister(params) {
  return (dispatch) => {
    dispatch(agentsRegister(params));
    POST(apiEndpoints.AGENCY_AGENTS, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agentsRegisterSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(agentsRegisterFail(errorhandler(error)));
      });
  };
}

export function requestAgencyAgents(params) {
  return (dispatch) => {
    dispatch(getAgencyAgents(params));
    FETCH(apiEndpoints.AGENCY_AGENTS, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(getAgencyAgentsSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(getAgencyAgentsFail(errorhandler(error)));
      });
  };
}

export function requestResendAgentInvitation(params) {
  return (dispatch) => {
    dispatch(resendAgentInvitation(params));
    POST(apiEndpoints.AGENT_ACTIONS + params + '/resend') //param id
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(resendAgentInvitationSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(resendAgentInvitationFail(errorhandler(error)));
      });
  };
}

export function requestDisableAgent(params) {
  return (dispatch) => {
    dispatch(disableAgent(params));
    POST(apiEndpoints.AGENT_ACTIONS + params + '/disable') //param id
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(disableAgentSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(disableAgentFail(errorhandler(error)));
      });
  };
}

export function requestVerifyAgency(params) {
  return (dispatch) => {
    dispatch(verifyAgency(params));
    POST(apiEndpoints.AGENCY_VERIFY, params) //param object
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(verifyAgencySuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(verifyAgencyFail(errorhandler(error)));
      });
  };
}

export function requestAgencyMe(params) {
  return (dispatch) => {
    dispatch(agencyMe(params));
    FETCH(apiEndpoints.AGENCY_ME) //param object
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyMeSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(agencyMeFail(errorhandler(error)));
      });
  };
}

export function requestUpgradeProperty(params) {
  return (dispatch) => {
    dispatch(upgradeProperty(params));
    POST(apiEndpoints.UPGRADE_PROPERTY, params) //param object
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(upgradePropertySuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(upgradePropertyFail(errorhandler(error)));
      });
  };
}

export function requestStatistics() {
  return (dispatch) => {
    dispatch(statistics());
    FETCH(apiEndpoints.STATISTICS)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(statisticsSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(statisticsFail(errorhandler(error)));
      });
  };
}

export function requestUpdateAgency(params) {
  return (dispatch) => {
    dispatch(updateAgency(params));
    POST(apiEndpoints.AGENCY_ACCOUNT, params) //param object
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(updateAgencySuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(updateAgencyFail(errorhandler(error)));
      });
  };
}

export function requestApplyPromoCode(params) {
  return (dispatch) => {
    dispatch(applyPromoCode(params));
    POST(apiEndpoints.AGENCY_APPLY_PROMO_CODE, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(applyPromoCodeSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(applyPromoCodeFail(errorhandler(error)));
      });
  };
}
