import React from "react";
import PMLogo from '../../../assets/images/pm-logo-inverted.png'
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {    
      textAlign: 'center',
      marginBottom: theme.spacing(2),      
      marginTop: theme.spacing(3),      
  }
});



const LogoOnlyHeader = props => {
  const {
    classes,
  } = props;

  return (
    <div className={classes.root}>
        <img src={PMLogo} width="240"/>
    </div>
  );
};

export default withStyles(styles)(LogoOnlyHeader);
