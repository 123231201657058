import { PURGE } from 'redux-persist';
import { SUPERADMIN_ACTION_TYPES } from 'store/constants/superadmin.constants';

const INITIAL_STATE = {
  // temporary values
  payload_request: null,
  payload_success: null,
  payload_failed: null,
  status: null,
  error: null,

  // stored values
  superadminuser: null,
  superadmintoken: null,
  agencies: null,
  agency: null,
  agents: null,
  emailLogs: null,
  emailContent: null,
  office: null,
  officeLists: null,
  singleAgent: null,
  agentImage: null,
  superAdminAccount: null,
  currentEditedAgent: null,
  currentAddedAgency: null,
  changeRequests: null,
  notifications: null,
  token_validator: {
    data: null,
    status: null,
    error: null,
    reference: null,
  },
  reportsData: null,
};

const superAdminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_LOGIN']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_FORGOT_PASSWORD']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESET_PASSWORD']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_AGENCY']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENCY_DATA']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_ACCOUNT_STATUS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_PAUSE_ACCOUNT']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_DELETE_ACCOUNT']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_AGENTS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SAVE_DRAFT_AGENCY']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_DOCUSIGN_AGENCY']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESEND_DOCUSIGN_AGENCY']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_LISTS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_DATA']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_OFFICE_REFERENCE']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_EMAIL_LOGS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_EMAIL_CONTENT']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_BULK_UPLOAD']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_ADD_SINGLE_AGENT']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_DELETE_AGENT']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_AGENT']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESEND_INVITATION']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPLOAD_AGENT_IMAGE']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_ACCOUNT_SETTINGS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_CHECK_AGENT_EMAIL']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENT_DETAILS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_CHANGE_REQUESTS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_CHANGE_REQUESTS_DATA']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_ADD_AGENCY']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_NOTIFICATION_LIST']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_CHANGE_REQUESTS']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_ACTIVATION_EMAIL']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL']['REQUEST']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_REPORTS_DATA']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_VALIDATE_TOKEN']['REQUEST']:
      return {
        ...state,
        token_validator: {
          ...state.token_validator,
          status: 'pending',
          reference: action.params,
        },
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_LOGIN']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_FORGOT_PASSWORD']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESET_PASSWORD']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_AGENCY']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENCY_DATA']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_ACCOUNT_STATUS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_PAUSE_ACCOUNT']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_DELETE_ACCOUNT']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_AGENTS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SAVE_DRAFT_AGENCY']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_DOCUSIGN_AGENCY']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESEND_DOCUSIGN_AGENCY']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_LISTS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_DATA']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_OFFICE_REFERENCE']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_EMAIL_LOGS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_EMAIL_CONTENT']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_BULK_UPLOAD']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_ADD_SINGLE_AGENT']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_DELETE_AGENT']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_AGENT']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESEND_INVITATION']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPLOAD_AGENT_IMAGE']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_ACCOUNT_SETTINGS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_CHECK_AGENT_EMAIL']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENT_DETAILS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_CHANGE_REQUESTS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_CHANGE_REQUESTS_DATA']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_CHANGE_REQUESTS']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_ACTIVATION_EMAIL']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL']['FAILED']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_REPORTS_DATA']['FAILED']:
      return {
        ...state,
        status: action?.type,
        payload_failed: action?.params,
        error: action.params?.data,
      };
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_NOTIFICATION_LIST']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_ADD_AGENCY']['FAILED']:
      return {
        ...state,
        status: action?.type,
        payload_failed: action?.params,
        error: action.params?.data,
      };
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_VALIDATE_TOKEN']['FAILED']:
      return {
        ...state,
        token_validator: {
          ...state.token_validator,
          data: null,
          status: 'error',
          error: action.params.data,
        },
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_VALIDATE_TOKEN']['SUCCESS']:
      return {
        ...state,
        token_validator: {
          ...state.token_validator,
          data: action.params?.data?.data,
          status: 'success',
          error: null,
        },
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_NOTIFICATION_LIST']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        notifications: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_ADD_AGENCY']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        currentAddedAgency: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_LOGIN']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        superadminuser: action.params.data,
        superadmintoken: action.params.data.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_AGENCY']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        agencies: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENT_DETAILS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        currentEditedAgent: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENCY_DATA']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        agency: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_LISTS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        officeLists: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_DATA']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        office: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_CHANGE_REQUESTS_DATA']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        changeRequests: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_AGENTS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        agents: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_EMAIL_LOGS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        emailLogs: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_EMAIL_CONTENT']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        emailContent: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_CHANGE_REQUESTS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        changeRequests: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_ADD_SINGLE_AGENT']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        singleAgent: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPLOAD_AGENT_IMAGE']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        agentImage: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_ACCOUNT_SETTINGS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        superAdminAccount: action.params.data,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_REPORTS_DATA']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        reportsData: action.params.data,
      };

    /** One Time Actions */
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_FORGOT_PASSWORD']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESET_PASSWORD']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_ACCOUNT_STATUS']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_PAUSE_ACCOUNT']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_DELETE_ACCOUNT']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SAVE_DRAFT_AGENCY']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_DOCUSIGN_AGENCY']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESEND_DOCUSIGN_AGENCY']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_OFFICE_REFERENCE']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_BULK_UPLOAD']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_DELETE_AGENT']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_AGENT']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_RESEND_INVITATION']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_CHECK_AGENT_EMAIL']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_UPDATE_CHANGE_REQUESTS']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_ACTIVATION_EMAIL']['SUCCESS']:
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_ALL_AGENCY']['CLEAR']:
      return {
        ...state,
        agencies: null,
      };
    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_ADD_AGENCY']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_success: null,
        currentAddedAgency: null,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENT_DETAILS']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_success: null,
        currentEditedAgent: null,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_AGENCY_DATA']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_success: null,
        agency: null,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_LISTS']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_success: null,
        officeLists: null,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_OFFICE_DATA']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_success: null,
        office: null,
      };

    case SUPERADMIN_ACTION_TYPES['SUPERADMIN_GET_CHANGE_REQUESTS_DATA']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_success: null,
        changeRequests: null,
      };

    /** General Actions */
    case SUPERADMIN_ACTION_TYPES['GENERAL_SUPERADMIN']['CLEAR_ERROR']:
      return {
        ...state,
        status: null,
        payload_request: null,
        payload_success: null,
        payload_failed: null,
        error: null,
      };

    case SUPERADMIN_ACTION_TYPES['GENERAL_SUPERADMIN']['CLEAR_STATUS']:
      return {
        ...state,
        status: null,
      };

    case SUPERADMIN_ACTION_TYPES['GENERAL_SUPERADMIN']['LOG_OUT']:
      return INITIAL_STATE;

    case SUPERADMIN_ACTION_TYPES['GENERAL_SUPERADMIN']['RESET_STATES']:
      return {
        ...state,
        payload_request: null,
        payload_success: null,
        payload_failed: null,
        status: null,
        error: null,
        agencies: null,
        agency: null,
        agents: null,
        emailLogs: null,
        emailContent: null,
        office: null,
        officeLists: null,
        singleAgent: null,
        agentImage: null,
        token_validator: {
          data: null,
          status: null,
          error: null,
          reference: null,
        },
        reportsData: null,
      };
    case PURGE:
      return INITIAL_STATE;

    default: {
      return state;
    }
  }
};

export default superAdminReducer;
