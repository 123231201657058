import React, { useState, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'react-simple-snackbar';
import styled from 'styled-components';

import Hero from '../../components/Mobile/Hero';
import HomePageSearchSection from '../../components/Mobile/Home/HomePageSearchSection';
import Footer from '../../components/Mobile/Footer';
import _ from 'lodash';

/** api redux request */
import {
  getDreamProperties,
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
  requestPropertySuggestion,
  requestSearchPerfectProperty,
} from '../../store/api';

import {
  clearFilters,
  setPropertySuburbSearch,
  setPerfectPropertyFilters,
  setCurrentProperty,
  clearCurrentProperty,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  toggleFilterWeb,
  toggleSignUp,
  mppActions,
} from '../../store/actions';

/** Object Filters */
import { formatRequest } from '../../utils/search.utils';
import TextImageSection from '../../components/Mobile/TextImageSection';
import TextImage01 from '../../assets/images/content/textimage01.svg';
import TextImage02 from '../../assets/images/content/textimage02.svg';
import findagentsectionbg from '../../assets/images/home-card-bg.png';
import DreamCarousel from '../../components/Mobile/DreamCarousel';
import { Container } from '@material-ui/core';
import { SnackbarNotification, BrandNewPlatform } from '../../components/Common';
import { useDispatch, useSelector } from 'react-redux';
import { requestGETAPIMpp, requestPUTAPIMpp } from 'store/api/mpp.api';
import { getMpp } from 'store/selectors/mpp.selectors';
import { getPopupStatus } from 'store/selectors/storage.selectors';
import { StorageActions } from 'store/actions/storage.actions';
import DialogContainer from 'components/Mobile/CustomDialogs/DialogContainer';
import useFilters from 'utils/customHooks/useFilters';
import { useRouterChange } from 'utils/router.utils';

const ValidationSearch = styled.div`
  position: absolute;
  bottom: -30px;
  left: 30px;
  border-radius: 3px;
  opacity: 0;
  background-color: rgb(17, 192, 201);
  padding: 10px;
  z-index: 9;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 0.3s ease;
  &.is-mpp {
    background-color: rgb(234, 223, 116);
    &:before {
      border-color: transparent transparent rgb(234, 223, 116) transparent;
    }
    p {
      color: #000;
    }
  }
  &.active {
    transform: translateY(0px);
    opacity: 1;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent rgb(17, 192, 201) transparent;
  }
  p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
`;

const Home = (props) => {
  const {
    setPropertySearch,
    savePropertySearch,
    searchPerfectProperty,
    setPerfectPropertyFilter,
    toggleFilterWeb,
    filters,
    getDreamProperties,
    dreamPropertiesData,
    settings,
    authentication,
    dreamPropertiesStatus,
    dreamPropertiesSuccess,
    dreamPropertiesError,
  } = props;
  const [routerChange] = useRouterChange();
  const { openSnackbar, closeSnackbar, clearAllFilters } = props;
  const [page, setPage] = React.useState('/buy');
  const [isLoading, setIsLoading] = useState(true);
  const [invalidSearch, setInvalidSearch] = useState(false);
  const [dreamProperties, setDreamProperties] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const MPP = useSelector(getMpp);
  const POPUP_STATUS = useSelector(getPopupStatus);
  const [getFilter, setFilter] = useFilters();
  const SEARCH_BUTTONS = getFilter('search_reference');
  const dispatch = useDispatch();

  const saveProperty = (params) => dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'));

  React.useEffect(() => {
    if (page.indexOf('superadmin') > -1) {
      routerChange('/superadmin/login');
    } else {
      routerChange('/agency/login');
    }

    // show default snackbar
    setShowSnackbar(true);

    /** show alert to relogin */
    if (localStorage.getItem('showSnackBar') !== null) {
      openSnackbar('Session Expired.');
      localStorage.removeItem('showSnackBar');
    }
    localStorage.removeItem('isOffMarket');
    localStorage.removeItem('hideState');
    /** CLEAR FILTERS ON HOME */
    apiRequestDreamProperties(filters);
    const homeButtons = SEARCH_BUTTONS.map((item) =>
      item.path.toLowerCase().replace('/', '') === 'buy' ? { ...item, active: true } : { ...item, active: false },
    );
    setFilter({ key: 'search_reference', value: homeButtons });
  }, []);

  React.useEffect(() => {
    if (MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      apiRequestGetSavedProperties();
    }
  }, [MPP]);

  /** GET Save Property API */
  async function apiRequestGetSavedProperties() {
    //getSavedProperties()
    apiRequestFavouritesLists();
  }

  /** Get List of Favourite and Dislike Properties */
  function apiRequestFavouritesLists() {
    handleClearMPPFavouritesList();
    dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'));
  }

  const handleClearMPPFavouritesList = () => {
    dispatch(mppActions(null, 'GET_MPP_FAVOURITES_IDS', 'CLEAR'));
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const getRoutePath = () => {
    return page ? page.replace('/', '') : '';
  };
  let st = null;

  async function apiRequestSearchProperty(type) {
    let path = getRoutePath();

    if (validateFilter(filters)) {
      setInvalidSearch(false);
      if (type === 'mpp') {
        toggleFilterWeb({ type: 'perfect-property', status: true });
      } else {
        routerChange('/' + path);
      }
    } else {
      setInvalidSearch(true);
      st = setTimeout(() => {
        setInvalidSearch(false);
        clearTimeout(st);
        st = null;
      }, 2000);
    }
  }

  function apiRequestSaveSearch(params) {
    setIsLoading(true);

    savePropertySearch(filters.suburbs);
  }

  const validateFilter = (params) => {
    if (page === '/perfect-property') {
      if (_.findIndex(params.perfectProperty.state, { active: true }) > -1) {
        return true;
      } else {
        return false;
      }
    } else if (params && params.suburbs.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const validationElement = () => {
    return page !== '/perfect-property' ? (
      <>
        {/* <ValidationSearch className={`${invalidSearch ? 'active' : ''}`}>
        <p>Please add the location you are looking for</p>
      </ValidationSearch> */}
      </>
    ) : (
      <ValidationSearch className={`${invalidSearch ? 'active' : ''} is-mpp`}>
        <p>Please add the state you are looking for</p>
      </ValidationSearch>
    );
  };

  useEffect(() => {
    if (
      dreamPropertiesData &&
      dreamPropertiesData.data &&
      dreamPropertiesData.data.data &&
      dreamPropertiesData.data.data.propertiesFilters
    ) {
      setDreamProperties(dreamPropertiesData.data.data.propertiesFilters);
    }
  }, [dreamPropertiesData]);

  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    };

    saveProperty(params);
  }

  /**
   * Get Dream Properties API */
  async function apiRequestDreamProperties(filters) {
    let path = getRoutePath();
    let params = await formatRequest(path, filters);
    params.skip = 0;
    params.take = 9;
    if (filters.suburbs.length > 0) {
      getDreamProperties(params);
    } else {
      let suburbs = [{ postcode: '3121', suburb: 'RICHMOND', state: 'VIC' }];
      params.suburb = suburbs;
      getDreamProperties(params);
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflowX: 'hidden',
      }}
    ></div>
  );
};

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
    settings: state.settings,
    authentication: state.authentication,
    dreamPropertiesData: state.property.dream_properties.data,
    dreamPropertiesStatus: state.property.dream_properties.status,
    dreamPropertiesSuccess: state.property.dream_properties.success,
    dreamPropertiesError: state.property.dream_properties.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearAllFilters: (params) => clearFilters(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      setPerfectPropertyFilter: (params) => setPerfectPropertyFilters(params),
      toggleFilterWeb: (checked) => toggleFilterWeb(checked),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      toggleSignUp: (checked) => toggleSignUp(checked),
      getDreamProperties: (params) => getDreamProperties(params),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Home));
