import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  TextField,
  Grid,
  IconButton,
  Avatar,
  Button,
  withStyles,
  Box,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { validateEmail, validateURL } from 'utils/validators/validators'
import FormField from 'components/Web/Form/FormField'
import _ from 'lodash'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
  listMain: {
    height: '65vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
    marginRight: 20,
  },
  button: {
    textTransform: 'capitalize',
    borderRadius: 20,
    marginLeft: 20,
    minWidth: 120,
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',
  },
  avatar: {
    width: '13vw',
    height: '13vw',
  },
  item: {
    borderTop: '0.5px solid #c3c3c3',
    marginTop: 20,
  },
})

const FormInput = (props) => (
  <div>
    <TextField
      style={{ backgroundColor: '#FFF' }}
      {...props}
      label={props.label}
      placeholder={props.label}
      fullWidth
      id="outlined-basic"
      variant="outlined"
      disabled={props.disabled}
    />
  </div>
)

const FormInputTel = (props) => (
  <div>
    <FormField {...props} />
  </div>
)

const BulkResults = (props) => {
  const { classes, goBack, agents, submitCallback } = props

  const [localAgents, setLocalAgents] = useState(agents)
  const [isFormValid, setIsFormValid] = useState(true)

  useEffect(() => {
    if (agents) {
      setLocalAgents(agents)
    }
  }, [agents])

  const updateDataArr = (index, key, value) => {
    let arr = _.map(localAgents, function (obj, idx) {
      return idx === index ? { ...obj, [key]: value } : obj
    })
    setLocalAgents(arr)
  }

  const removeDataFromArr = (index) => {
    if (window.confirm('Proceed with this action?')) {
      let arr = _.remove(localAgents, function (n, idx) {
        return idx !== index
      })
      setLocalAgents(arr)
    }
  }

  const handleSubmit = () => {
    if (submitCallback) {
      const params = localAgents.map((item, index) => {
        const theItem = {
          firstName: item.first_name,
          lastName: item.last_name,
          email: item.email,
          phone: item.phone,
          telephone: item.phone,
          profileText: item.bio,
          image: item.profile_image_url,
          title: item.job_title,
        }
        Object.keys(theItem).forEach((key) => {
          if (theItem[key] === null || theItem[key] === undefined) {
            delete theItem[key]
          }
        })
        return theItem
      })

      submitCallback(params)
    }
  }

  const _renderListItem = (data) => {
    useEffect(() => {
      if (data) {
        let formValid = true

        for (let index = 0; index < data.length; index++) {
          if (!validateEmail(data[index].email)) {
            formValid = false
            break
          }
        }
        setIsFormValid(formValid)
      }
    }, [data])

    return (
      <>
        {data && data.length > 0 ? (
          data.map((value, index) => {
            return (
              <Grid
                key={index}
                className={classes.item}
                container
                spacing={2}
                justifyContent={'space-around'}
                alignContent={'center'}
                alignItems={'center'}
              >
                <Grid item xs={12} md={2} lg={2} xl={2}>
                  <Grid
                    container
                    justifyContent={'space-around'}
                    alignContent={'flex-start'}
                    alignItems={'flex-start'}
                  >
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <Avatar
                        className={classes.avatar}
                        alt={value.first_name}
                        src={value.profile_image_url && value.profile_image_url}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={9} lg={9} xl={9}>
                  <Grid
                    container
                    spacing={2}
                    justifyContent={'space-around'}
                    alignContent={'center'}
                    alignItems={'center'}
                  >
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                      <FormInput
                        id={'firstname'}
                        name={'firstname'}
                        label={'First Name'}
                        variant="outlined"
                        value={value.first_name && value.first_name}
                        error={value.first_name && value.first_name.length < 2}
                        onChange={(e) =>
                          updateDataArr(index, 'first_name', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                      <FormInput
                        id={'lastname'}
                        name={'lastname'}
                        label={'Last Name'}
                        variant="outlined"
                        value={value.last_name && value.last_name}
                        error={value.last_name && value.last_name.length < 2}
                        onChange={(e) =>
                          updateDataArr(index, 'last_name', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                      <FormInput
                        id={'email'}
                        name={'email'}
                        label={'Email Address'}
                        variant="outlined"
                        value={value.email && value.email}
                        error={value.email && validateEmail(value.email) === false}
                        onChange={(e) => {
                          updateDataArr(index, 'email', e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3}>
                      <FormInputTel
                        type={'tel'}
                        label={'Phone'}
                        placeholder={'Phone'}
                        errorMsg={null}
                        value={value.phone && value.phone}
                        showError={value.phone && value.phone.length < 10}
                        onChange={(e) =>
                          updateDataArr(
                            index,
                            'phone',
                            e.target.value.replace(/\D/g, '').replace(/ /g, '')
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormInput
                        id={'job_title'}
                        name={'job_title'}
                        label={'Job Title (optional)'}
                        variant="outlined"
                        value={value.job_title && value.job_title}
                        onChange={(e) =>
                          updateDataArr(index, 'job_title', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormInput
                        id={'profile_image_url'}
                        name={'profile_image_url'}
                        label={'Profile Image URL (optional)'}
                        variant="outlined"
                        value={value.profile_image_url && value.profile_image_url}
                        error={
                          value.profile_image_url &&
                          !validateURL(value.profile_image_url)
                        }
                        onChange={(e) =>
                          updateDataArr(index, 'profile_image_url', e.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormInput
                        id={'bio'}
                        name={'bio'}
                        label={'Bio (optional)'}
                        variant="outlined"
                        multiline
                        rows={5}
                        value={value.bio && value.bio}
                        onChange={(e) => updateDataArr(index, 'bio', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        endIcon={<DeleteIcon />}
                        onClick={() => removeDataFromArr(index)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          })
        ) : (
          <div className={classes.emptyContainer}>
            <Typography variant={'body2'}>{'No Data to View'}</Typography>
          </div>
        )}
      </>
    )
  }

  const _renderHeader = () => {
    return (
      <div className={classes.header}>
        <Button
          className={classes.button}
          disableElevation
          variant="outlined"
          color="default"
          onClick={() => goBack()}
        >
          Cancel
        </Button>
        <Button
          disabled={!isFormValid}
          className={classes.button}
          disableElevation
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {_renderHeader()}
      {!isFormValid && (
        <Box paddingY="10px" color="red">
          There is an error on one of the entries. Cannot submit bulk upload, please
          make sure all of the entries are valid!
        </Box>
      )}
      <div className={classes.listMain}>
        {_renderListItem(localAgents)}
        {/* <ListItem classes={classes} data={agents} /> */}
      </div>
    </div>
  )
}

BulkResults.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
}

export default withStyles(styles)(BulkResults)
