import { ADVANCED_PROPERTY_CONSTANTS, PRICE_RANGE } from '../../../constants/constants'
export const PROPERTY_TYPE = [
  {
    id: '0',
    title: 'House',
    icon: '2DCHouseIcon',
    active: false,
    count: 0,
  },
  {
    id: '1',
    title: 'Townhouse',
    icon: '2DCTownHouseIcon',
    active: false,
    count: 0,
  },
  {
    id: '2',
    title: 'Apartment & Unit',
    icon: '2DCApartmentIcon',
    active: false,
    count: 0,
  },
  {
    id: '3',
    title: 'Land',
    icon: '2DCLandIcon',
    active: false,
    count: 0,
  },
  {
    id: '4',
    title: 'Rural',
    icon: '2DCRuralIcon',
    active: false,
    count: 0,
  },
  {
    id: '5',
    title: 'Retirement',
    icon: '2DCRetirementVillageIcon',
    active: false,
    count: 0,
  },
  {
    id: '6',
    title: 'Villa',
    icon: '2DCVillaIcon',
    active: false,
    count: 0,
  },
]

export const PROPERTY_DETAILS = {
  price: {
    min: PRICE_RANGE[0],
    max: PRICE_RANGE[1],
  },
  rangedPrice: {
    min: PRICE_RANGE[0],
    max: PRICE_RANGE[1],
  },
  scaledPrice: [0, 275],
  rentScaledPrice: [0, 225],
  priceRent: {
    min: 250,
    max: 5000,
  },
  bedrooms: null,
  bathrooms: null,
  carparking: null,
}

export const PROPERTY_LAND_SIZE = {
  min: null,
  max: null,
}

export const PROPERTY_FEATURES = [
  {
    id: '0',
    label: 'Gas',
    active: false,
  },
  {
    id: '1',
    label: 'Balcony',
    active: false,
  },
  {
    id: '2',
    label: 'Study',
    active: false,
  },
  {
    id: '3',
    label: 'Air Conditioning',
    active: false,
  },
  {
    id: '4',
    label: 'Built-In Wardrobes',
    active: false,
  },
  {
    id: '5',
    label: 'Garden/Courtyard',
    active: false,
  },
  {
    id: '6',
    label: 'Swimming Pool',
    active: false,
  },
]

export const PROPERTY_ADVANCE = ADVANCED_PROPERTY_CONSTANTS

export const PROPERTY_KEYWORD = {
  keyword: '',
  offer: false,
}

export const PERFECT_PROPERTY = {
  state: [
    { id: 0, title: 'ACT', active: false },
    { id: 1, title: 'NSW', active: false },
    { id: 2, title: 'NT', active: false },
    { id: 3, title: 'QLD', active: false },
    { id: 4, title: 'SA', active: false },
    { id: 5, title: 'TAS', active: false },
    { id: 6, title: 'VIC', active: true },
    { id: 7, title: 'WA', active: false },
  ],
  near: [
    {
      id: '0',
      label: 'Capital city',
      active: false,
    },
    {
      id: '1',
      label: 'Suburbs',
      active: false,
    },
    {
      id: '2',
      label: 'Rural setting',
      active: false,
    },
    {
      id: '3',
      label: 'Country Town',
      active: false,
    },
  ],
  radius: 0,
}

export const PROPERTY_DETAILS_UPLOAD = {
  showPrice: false,
  price: {
    min: PRICE_RANGE[0],
    max: PRICE_RANGE[1],
  },
  priceRent: {
    min: 250,
    max: 5000,
  },
  bedrooms: 1,
  bathrooms: 1,
  carparking: 1,
  priceOption: 'Single Price',
  priceRangeMin: 0,
  priceRangeMax: 0,
}
