import React from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '@material-ui/icons/Close'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  toggleThemeMode,
  toggleSignUp,
  navigateReset,
  logoutRequest,
  toggleForgotPassword,
} from '../../../store/actions'

import Login from './Login'
import ForgotPassword from './ForgotPassword'
import Register from './Register'

import { Paper, AppBar, Tabs, Tab, Container, IconButton, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    // padding: 0,
    // paddingTop: theme.spacing(2),
    position: 'relative',
    margin: theme.spacing(7),
    marginRight: '25%',
    marginLeft: '25%',
    borderRadius: 38,
    '&:hover': {
      outline: 'none',
    },
    overflow: 'hidden',
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
    boxShadow: 'none',
  },
  container: {
    padding: 0,
  },
  profileContainer: {
    padding: '30px 24px 50px',
  },
  avatar: {},
  formWrapper: {
    padding: theme.spacing(0, 6.25),
  },
  closeicon: {
    position: 'absolute',
    top: 20,
    right: theme.spacing(2.5),
  },
})

/* const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
  },
})(Button) */

const MainHeader = withStyles({
  root: {
    boxShadow: 'none',
    padding: '10px 0px',
    position: 'relative',
  },
})(AppBar)

const TabHeader = withStyles({
  root: {
    justifyContent: 'space-evenly',
    padding: '0 21px',
  },
})(Tabs)

const StyledTab = withStyles({
  root: {
    width: 'calc(50% - 10px)',
    margin: '0 5px',
    backgroundColor: '#F5F5F5',
  },
  selected: {
    color: '#000 !important',
    backgroundColor: '#fff',
  },
})(Tab)

/* const BackButton = withStyles({
  root: {
    width: '100px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button) */

const ScrollContainer = withStyles({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 'calc(90vh - 104px)',
    width: '100%',
  },
})(Container)

const Authentication = (props) => {
  const { classes } = props

  /** redux props */
  const { settings, authentication, toggleForgotPassword } = props

  /** redux api actions */
  const { toggleSignUp, navigateResetRedux } = props

  const [value, setValue] = React.useState(0)
  const [isForgotPasswordView, setIsForgotPasswordView] = React.useState(false)
  const [isLogged, setIsLogged] = React.useState(false)

  React.useEffect(() => {
    /** Check if Token Exist */
    if (authentication.token) {
      setIsLogged(true)
      toggleSignUp(false)
    } else {
      setIsLogged(false)
    }
  }, [authentication])

  React.useEffect(() => {
    if (settings && settings.tab !== 0) {
      setValue(settings.tab)
    }
  }, [settings])

  React.useEffect(() => {
    return () => {
      /**
       * Reset Redux Values (API Error Return)
       * */
      navigateResetRedux()
    }
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)

    /**
     * Reset Redux Values (API Error Return)
     * */
    navigateResetRedux()
  }

  const handleForgotPasswordView = (value) => {
    setIsForgotPasswordView(value)
    toggleForgotPassword(value)

    /**
     * Reset Redux Values (API Error Return)
     * */
    navigateResetRedux()
  }

  /*   function renderProfile() {
    return (
      <ScrollContainer className={classes.profileContainer}>
        <Grid item xs={12}>
          <HeadingContent
            title={'Profile'}
            // content={`User Profile`}
          />
          <StyledButton
            color="primary"
            variant="contained"
            disabled={false}
            onClick={() => {
              logoutUser()
              //toggleSignUp()
            }}
          >
            Log out
          </StyledButton>
        </Grid>
      </ScrollContainer>
    )
  } */

  function renderForms() {
    return (
      <div className={classes.formWrapper}>
        <AppBar className={classes.appBar} position="static">
          <TabHeader
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
            variant="fullWidth"
          >
            <StyledTab label="LOGIN" />
            <StyledTab label="SIGN UP" />
          </TabHeader>
        </AppBar>
        <ScrollContainer className={classes.container}>
          {value === 0 ? (
            settings && settings.showForgotPassword ? (
              <ForgotPassword
                setIsForgotPasswordView={handleForgotPasswordView}
                tabChange={handleChange}
              />
            ) : (
              <Login
                setIsForgotPasswordView={handleForgotPasswordView}
                tabChange={handleChange}
                changeRoute={props.changeRoute}
              />
            )
          ) : value === 1 ? (
            <Register tabChange={handleChange} />
          ) : null}
        </ScrollContainer>
      </div>
    )
  }

  return (
    <Paper className={classes.root} elevation={5}>
      <MainHeader className={classes.appBar} position="static">
        <IconButton
          aria-label="close"
          className={classes.closeicon}
          onClick={() => {
            toggleSignUp(false)
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </MainHeader>
      {!isLogged && renderForms()}
    </Paper>
  )
}

Authentication.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
    authentication: state.authentication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleForgotPassword: (checked) => toggleForgotPassword(checked),
      navigateResetRedux: () => navigateReset(),
      logoutUser: () => logoutRequest(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Authentication))
