/** API REQUEST */
export const REQUEST_AGENCY_REGISTER = 'agency/REQUEST_AGENCY_REGISTER';
export const REQUEST_AGENCY_REGISTER_SUCCESS = 'agency/REQUEST_AGENCY_REGISTER_SUCCESS';
export const REQUEST_AGENCY_REGISTER_FAIL = 'agency/REQUEST_AGENCY_REGISTER_FAIL';

export const REQUEST_AGENTS_REGISTER = 'agency/REQUEST_AGENTS_REGISTER';
export const REQUEST_AGENTS_REGISTER_SUCCESS = 'agency/REQUEST_AGENTS_REGISTER_SUCCESS';
export const REQUEST_AGENTS_REGISTER_FAIL = 'agency/REQUEST_AGENTS_REGISTER_FAIL';

export const REQUEST_AGENCY_CONTACT_ADMIN = 'agency/REQUEST_AGENCY_CONTACT_ADMIN';
export const REQUEST_AGENCY_CONTACT_ADMIN_SUCCESS = 'agency/REQUEST_AGENCY_CONTACT_ADMIN_SUCCESS';
export const REQUEST_AGENCY_CONTACT_ADMIN_FAIL = 'agency/REQUEST_AGENCY_CONTACT_ADMIN_FAIL';

export const REQUEST_AGENCY_DETAILS = 'agency/REQUEST_AGENCY_DETAILS';
export const REQUEST_AGENCY_DETAILS_SUCCESS = 'agency/REQUEST_AGENCY_DETAILS_SUCCESS';
export const REQUEST_AGENCY_DETAILS_FAIL = 'agency/REQUEST_AGENCY_DETAILS_FAIL';

export const REQUEST_AGENCY_AGENTS = 'agency/REQUEST_AGENCY_AGENTS';
export const REQUEST_AGENCY_AGENTS_SUCCESS = 'agency/REQUEST_AGENCY_AGENTS_SUCCESS';
export const REQUEST_AGENCY_AGENTS_FAIL = 'agency/REQUEST_AGENCY_AGENTS_FAIL';

export const REQUEST_RESEND_AGENT_INVITATION = 'agency/REQUEST_RESEND_AGENT_INVITATION';
export const REQUEST_RESEND_AGENT_INVITATION_SUCCESS = 'agency/REQUEST_RESEND_AGENT_INVITATION_SUCCESS';
export const REQUEST_RESEND_AGENT_INVITATION_FAIL = 'agency/REQUEST_RESEND_AGENT_INVITATION_FAIL';

export const REQUEST_DISABLE_AGENT = 'agency/REQUEST_DISABLE_AGENT';
export const REQUEST_DISABLE_AGENT_SUCCESS = 'agency/REQUEST_DISABLE_AGENT_SUCCESS';
export const REQUEST_DISABLE_AGENT_FAIL = 'agency/REQUEST_DISABLE_AGENT_FAIL';

export const REQUEST_VERIFY_AGENCY = 'agency/REQUEST_VERIFY_AGENCY';
export const REQUEST_VERIFY_AGENCY_SUCCESS = 'agency/REQUEST_VERIFY_AGENCY_SUCCESS';
export const REQUEST_VERIFY_AGENCY_FAIL = 'agency/REQUEST_VERIFY_AGENCY_FAIL';

export const REQUEST_AGENCY_ME = 'agency/REQUEST_AGENCY_ME';
export const REQUEST_AGENCY_ME_SUCCESS = 'agency/REQUEST_AGENCY_ME_SUCCESS';
export const REQUEST_AGENCY_ME_FAIL = 'agency/REQUEST_AGENCY_ME_FAIL';

export const REQUEST_UPGRADE_PROPERTY = 'agency/REQUEST_UPGRADE_PROPERTY';
export const REQUEST_UPGRADE_PROPERTY_SUCCESS = 'agency/REQUEST_UPGRADE_PROPERTY_SUCCESS';
export const REQUEST_UPGRADE_PROPERTY_FAIL = 'agency/REQUEST_UPGRADE_PROPERTY_FAIL';

export const REQUEST_STATISTICS = 'agency/REQUEST_STATISTICS';
export const REQUEST_STATISTICS_SUCCESS = 'agency/REQUEST_STATISTICS_SUCCESS';
export const REQUEST_STATISTICS_FAIL = 'agency/REQUEST_STATISTICS_FAIL';

export const REQUEST_UPDATE_AGENCY = 'agency/REQUEST_UPDATE_AGENCY';
export const REQUEST_UPDATE_AGENCY_SUCCESS = 'agency/REQUEST_UPDATE_AGENCY_SUCCESS';
export const REQUEST_UPDATE_AGENCY_FAIL = 'agency/REQUEST_UPDATE_AGENCY_FAIL';

export const REQUEST_APPLY_PROMO_CODE = 'agency/REQUEST_APPLY_PROMO_CODE';
export const REQUEST_APPLY_PROMO_CODE_SUCCESS = 'agency/REQUEST_APPLY_PROMO_CODE_SUCCESS';
export const REQUEST_APPLY_PROMO_CODE_FAIL = 'agency/REQUEST_APPLY_PROMO_CODE_FAIL';
export const REQUEST_APPLY_PROMO_CODE_RESET = 'agency/REQUEST_APPLY_PROMO_CODE_RESET';
