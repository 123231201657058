import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'

import { useSelector, useDispatch } from 'react-redux'

import Searchbar from '../../components/Web/Searchbar'
import Filter from '../../components/Web/Filter'
import FilterV2 from '../../components/Web/Filter/FilterV2'
import Heading from '../../components/Web/Filter/Heading'
import Loader from '../../components/Web/Loader'
import AlertNotification from '../../components/Web/Form/AlertNotification'
import DynamicIcons from '../../components/Web/DynamicIcons'

/** Object Filters */
import { reform } from '../../utils/filters.utils'
import { formatRequest } from '../../utils/search.utils'

import { Typography, Button, withStyles, Box } from '@material-ui/core'
import { useRef } from 'react'
import { scrollToRef } from 'components/Web/ScrollEvents'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 45,
    paddingBottom: 56,
  },
  content: {
    height: '100%',
    paddingBottom: '30px',
  },
})

const StyledButton = withStyles({
  root: {
    borderRadius: '30px',
  },
})(Button)

const StyledBox = styled(Box)``

const Header = styled.div`
  position: relative;
  padding: 10px 23px 15px;
`
const HeaderNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
`

const BackButton = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.073125px;
  display: flex;
  align-items: center;
  margin-left: -10px;
`

const ClearButton = styled.a`
  text-decoration: underline;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.073125px;
  cursor: pointer;
`

const ButtonWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 90px;
  max-width: calc(573px);
  width: 100%;
  background-color: #fff;
  padding: 10px 15px;
  box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-center: center;
  justify-content: center;
  z-index: 9;
  &.filter-button {
    left: -573px;
    transition: left 0.7s ease;
  }
`

const ValidationSearch = styled.div`
  position: absolute;
  bottom: -4px;
  left: 50px;
  border-radius: 3px;
  opacity: 0;
  background-color: rgb(17, 192, 201);
  padding: 10px;
  z-index: 9;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 0.3s ease;
  &.active {
    transform: translateY(0px);
    opacity: 1;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent rgb(17, 192, 201) transparent;
  }
  p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
`

const FiltersSearchV2 = (props) => {
  const { classes } = props
  const [routerChange] = useRouterChange()
  const [filter, setFilter] = useState(null)
  const [isClearFilter, setIsClearFilter] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [propertyTypesCount, setPropertyTypesCount] = useState(null)
  const [invalidSearch, setInvalidSearch] = useState(false)

  const dispatch = useDispatch()

  const topContainerRef = useRef()

  const executeTopScroll = (noTransition) => scrollToRef(topContainerRef, noTransition)

  function routeGoBack() {
    routerChange('back')
  }

  function resetFilters() {
    //clearFiltersState()
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  // scroll to top of searchbar if no suburbs were inputted
  useEffect(() => {
    if (invalidSearch) {
      executeTopScroll(false)
    }
  }, [invalidSearch])

  /** Search Property */

  async function apiRequestSearchProperty(clear) {
    let path = props.page
    /*     if (FILTERS?.suburbs.length) {
      setInvalidSearch(false)
      routerChange('/' + path)
    } else {
      setInvalidSearch(true)
    } */
  }

  const handleFilter = (obj) => {
    if (obj) {
      setFilter(obj)
      if (JSON.stringify(obj) !== JSON.stringify(filter)) {
        //saveFilters(obj)
      }
    }
  }

  const applyFilter = (status) => {
    if (invalidSearch) {
      executeTopScroll(false)
      return
    }

    let c = status === 'clear' ? true : null
    apiRequestSearchProperty(c)
  }

  const handleClearFilter = () => {
    setIsClearFilter(true)
    let to = setTimeout(() => {
      setIsClearFilter(false)
      resetFilters()
      // applyFilter('clear');
      clearTimeout(to)
    }, 50)
  }

  const validateFilter = (params) => {
    if (params.suburb.length > 0) {
      return true
    } else {
      return false
    }
  }

  const clearSelectedFilters = (e) => {
    e.preventDefault()
    handleClearFilter()
  }

  return (
    <StyledBox ref={topContainerRef}>
      <div className={classes.root}>
        <AlertNotification
          status={isNotification}
          options={notificationOptions}
          closeNotification={closeNotification}
        />

        <Loader isLoading={isLoading} />
        <div style={{ position: 'relative' }}>
          <HeaderNav>
            <ClearButton onClick={clearSelectedFilters}>Clear Selections</ClearButton>
          </HeaderNav>
          <Box
            onFocus={() => {
              setInvalidSearch(false)
            }}
            marginBottom="20px"
          >
            {/*             <Searchbar
              type={'filter'}
              showFilters={true}
              isClearFilter={isClearFilter}
              setPropertySearch={setPropertySearch}
              setIncludeNearbyFilter={setIncludeNearbyFilter}
              apiRequestSearchProperty={apiRequestSearchProperty}
              filters={FILTERS}
              isFiltered={null}
              showSearchBar={true}
              path={props.page}
            /> */}
          </Box>

          <ValidationSearch className={invalidSearch ? 'active' : ''}>
            <p>Please add the location you are looking for</p>
          </ValidationSearch>
        </div>
        <div className={classes.content}>
          {/*           <Filter
            handleFilter={handleFilter}
            isClearFilter={isClearFilter}
            //saveFilters={saveFilters}
            resetFilters={resetFilters}
            //filters={FILTERS}
            path={props.page}
            propertyTypesCount={propertyTypesCount}
          /> */}
        </div>

        <ButtonWrap className="filter-button">
          <StyledButton
            variant="contained"
            color="primary"
            onClick={applyFilter}
            style={{ textTransform: 'none' }}
          >
            Apply Filters
          </StyledButton>
        </ButtonWrap>
      </div>
    </StyledBox>
  )
}

export default withStyles(styles)(FiltersSearchV2)
