import React from 'react'
import moment from 'moment'

import MailIcon from '@material-ui/icons/Mail'
import EmailIcon from '@material-ui/icons/Email'

import BedIcon from '@material-ui/icons/Hotel'
import ParkingIcon from '@material-ui/icons/DriveEta'
import ViewIcon from '@material-ui/icons/Visibility'
import SaveIcon from '@material-ui/icons/SaveAlt'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import CloseIcon from '@material-ui/icons/Close'
import MessageIcon from '@material-ui/icons/Message'

import DynamicIcons from '../DynamicIcons'
import ReadMore from './ReadMore'
import OptionModalBottom from './OptionModalBottom'
import ListOptions from './ListOptions'

import { OPTION_MODAL } from './constants'
import {
  Box,
  withStyles,
  Typography,
  IconButton,
  Button,
  Badge,
  Paper,
  Tooltip,
  Link,
} from '@material-ui/core'
import DOMPurify from 'dompurify'
import { useRouterChange } from 'utils/router.utils'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  rootSearch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rootProperty: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoContainerSearch: {
    //paddingBottom: theme.spacing(1)
  },
  infoContainer: {
    padding: theme.spacing(3),
  },
  icon: {
    color: '#000',
  },
  iconSave: {
    color: '#000',
    transform: 'rotate(180deg)',
    maxWidth: '14px',
    marginLeft: theme.spacing(1),
    height: 'auto',
  },
  customIcon: {
    color: '#000',
    width: 20,
    height: 20,
  },
  emailContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emailIcon: {
    width: '0.625em',
    height: '0.625em',
  },
  emailText: {
    marginLeft: '0.625em',
  },
  infoDescription: {
    paddingTop: theme.spacing(2),
    whiteSpace: 'pre-line',
  },
  /** Bottomn Option Modal */
  optionBottomContainer: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    '&:hover': {
      outline: 'none',
    },
  },
  optionBottomHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  optionBottomHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionBottomHeaderText: {
    fontWeight: '300',
  },
  optionBottonList: {
    height: '25vh',
    overflowY: 'scroll',
  },
  arrow: {
    backgroundColor: '#FFF',
  },
  filterMain: {
    minHeight: 40,
    width: 150,
    borderRadius: 4,
    backgroundColor: '#FFF',
    position: 'absolute',
    zIndex: 1500,
    border: '1px solid #000000',
    top: 50,
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
  },
  filterText: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#000',
  },
  triangle: {
    position: 'absolute',
    top: -11,
    zIndex: 1000,
    left: '45%',
    width: 0,
    borderBottom: 'solid 10px black',
    borderRight: 'solid 10px transparent',
    borderLeft: 'solid 10px transparent',
  },
  empty: {
    position: 'absolute',
    top: -10,
    zIndex: 1200,
    left: '45%',
    width: 0,
    borderBottom: 'solid 10px white',
    borderRight: 'solid 10px transparent',
    borderLeft: 'solid 10px transparent',
  },
  priceText: {
    fontSize: '1.250em',
  },
  priceTextInner: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '23.44px',
    letterSpacing: '-0.5px',
    color: theme.palette.common.black,
  },
  dateText: {
    fontSize: '0.750em',
  },
  otherInfoText: {
    fontSize: '0.750em',
    paddingBottom: theme.spacing(1),
  },
  addressText: {
    fontSize: '0.875em',
  },
  priceGuideCTA: {
    textAlign: 'right',
    '& > button:last-child': {
      marginRight: 0,
    },
    padding: theme.spacing(2, 3.12, 1, 2),
    width: '100%',
  },
  cta: {
    color: theme.palette.common.black,
    borderRadius: '16px',
    textTransform: 'initial',
    padding: theme.spacing(1, 2),
    minWidth: 94,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.light.main,
    fontSize: 13,
    lineHeight: '15.6px',
    letterSpacing: '-0.25px',
  },
  inspectionDateText: {
    color: '#70757A',
    fontSize: 12,
  },
  emailButton: {
    minWidth: '0px',

    [theme.breakpoints.down(1450)]: {
      padding: '5px 10px',
    },

    '& .MuiButton-startIcon': {
      [theme.breakpoints.down(1450)]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },

    [theme.breakpoints.down(1250)]: {
      padding: '5px 5px',
    },
  },
  emailButtonText: {
    [theme.breakpoints.down(1450)]: {
      display: 'none',
    },
  },
})

function formatUSD(num) {
  if (num && typeof num !== 'object') {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  } else {
    return null
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function auctionTimeCalculator(time) {
  const formattedTime = time.split(':')

  let meridiem
  let hour

  const hourData = formattedTime[0]
  const minutesData = formattedTime[1]

  if (hourData === '0') {
    hour = '12'
    meridiem = 'am'

    return `${hour}:${minutesData}${meridiem} `
  }

  if (hourData < 12 && hourData !== '0') {
    hour = hourData
    meridiem = 'am'

    return `${hour}:${minutesData}${meridiem} `
  }

  if (hourData > 12 && hourData !== '0') {
    hour = hourData % 12
    meridiem = 'pm'

    return `${hour}:${minutesData}${meridiem} `
  }
}

function inspectionDateHandler(properties, inspectionDates, dateNow) {
  for (let index = 0; index < inspectionDates.length; index++) {
    const inspectionDate = new Date(inspectionDates[index].toString())
    inspectionDate.setDate(inspectionDate.getDate() + 1)

    if (inspectionDate > dateNow) {
      const inspectionTimeArray = properties.inspectionTimes[index].split(' ')
      inspectionTimeArray.shift()
      const inspectionTime = inspectionTimeArray.join(' ')

      const inspectionFullDate = properties.inspectionTimes[index].split(' ', 1)
      const fullFormattedDate = moment(inspectionFullDate.toString()).format(
        'ddd D MMM'
      )

      const formattedInspectionDate = `${fullFormattedDate}, ${inspectionTime}`

      return {
        type: 'Inspection',
        date: formattedInspectionDate,
        showDate: true,
      }
    }
  }
}

function getAllPendingAuctionInspections(properties) {
  const pendingAuctionInspections = {
    auction: '',
    inspections: [],
  }
  const dateNow = new Date()

  if (properties.auctionDate) {
    const formattedAuctionDate = new Date(properties.auctionDate)
    formattedAuctionDate.setDate(formattedAuctionDate.getDate() + 1)

    if (formattedAuctionDate > dateNow) {
      formattedAuctionDate.setDate(formattedAuctionDate.getDate() - 1)
      const utc = formattedAuctionDate.toUTCString()

      const formattedUTCTime = auctionTimeCalculator(utc.split(' ')[4])
      const formattedUTCDate = utc.split(' ')
      formattedUTCDate.splice(4, 2)

      pendingAuctionInspections.auction = `${formattedUTCDate.join(
        ' '
      )} ${formattedUTCTime}`
    }
  }

  properties.inspectionTimes.forEach((inspectionDate) => {
    const fullInspectionDate = inspectionDate.split(' ', 1).toString()
    const formattedInspectionDate = new Date(fullInspectionDate.toString())
    formattedInspectionDate.setDate(formattedInspectionDate.getDate() + 1)

    if (formattedInspectionDate > dateNow) {
      const inspectionDateformatted = moment(fullInspectionDate).format('ddd D MMMM Y')

      const inspectionTimeArray = inspectionDate.split(' ')
      inspectionTimeArray.shift()
      const inspectionTime = inspectionTimeArray.join(' ')

      pendingAuctionInspections.inspections.push(
        `${inspectionDateformatted} ${inspectionTime}`
      )
    }
  })

  return pendingAuctionInspections
}

const htmlDecode = (text) => {
  let e = document.createElement('div')
  e.innerHTML = text
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
}

const PropertyInfo = (props) => {
  const {
    classes,
    properties,
    type,

    selectProperty,
    matchCount,
    listingType,
    executePriceGuideScroll,
    addIconOnCard,
  } = props

  const [showModalBottomOptions, setShowModalBottomOptions] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const addressRegex = / *AU *$|AUS *$/gm
  const type_exception = ['sold', 'rental']

  const [routerChange] = useRouterChange()

  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents()

  function showOptionBottomModal() {
    setShowModalBottomOptions(!showModalBottomOptions)
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltip = () => {
    setOpen(!open)
  }

  function renderInfoSearchScreen() {
    const dateNow = new Date()

    const inspectionDates = properties.inspectionTimes?.map((inspectionDate) =>
      inspectionDate.split(' ', 1)
    )
    let inspectionDateData = {
      date: '',
      showDate: false,
    }

    if (inspectionDates?.length > 0) {
      let inspectionData = inspectionDateHandler(properties, inspectionDates, dateNow)
      inspectionDateData = {
        ...inspectionData,
      }
    }

    return (
      <div>
        {properties &&
          properties.inspectionTimes &&
          properties.inspectionTimes.length > 0 &&
          inspectionDateData.showDate && (
            <div>
              <Typography
                component="p"
                variant="body2"
                className={classes.inspectionDateText}
              >
                {`Inspection ${inspectionDateData.date}`}
              </Typography>
            </div>
          )}
        {properties.propertyType?.toLowerCase() === 'land' ||
        properties.reaPropertyType?.toLowerCase() === 'land' ? null : (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flexDirection="row">
              <Tooltip title="Bedrooms">
                <IconButton onClick={() => ''} disableRipple>
                  <BedIcon className={classes.icon} />
                  <Typography variant="caption" color="textSecondary" component="p">
                    {properties.bedrooms ? properties.bedrooms : 0}
                  </Typography>
                </IconButton>
              </Tooltip>

              <Tooltip title="Bathrooms">
                <IconButton onClick={() => ''} disableRipple>
                  <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
                  <Typography variant="caption" color="textSecondary" component="p">
                    {properties.bathrooms ? properties.bathrooms : 0}
                  </Typography>
                </IconButton>
              </Tooltip>

              <Tooltip title="Parking">
                <IconButton onClick={() => ''} disableRipple>
                  <ParkingIcon className={classes.icon} />
                  <Typography variant="caption" color="textSecondary" component="p">
                    {properties.totalParking ? properties.totalParking : 0}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Box>
            {addIconOnCard && (
              <Box marginLeft="auto">
                {properties.agent?.telephone &&
                  properties?.propertyStatus !== 'offmarket' && (
                    <Button
                      href={`sms:${properties.agent.telephone};?&body=${properties.addressText} ${window.location.href} (Please enter your name and enquiry here)`}
                      className="btn-tel"
                      startIcon={<MessageIcon />}
                      // style={{ marginTop: agent.telephone ? 5 : 0 }}
                      style={{ marginLeft: 'auto' }}
                      fullWidth
                      variant="outlined"
                      onClick={() => ''}
                    >
                      SMS
                    </Button>
                  )}

                {properties?.propertyStatus === 'offmarket' && (
                  <Button
                    className={classes.emailButton}
                    startIcon={<EmailIcon />}
                    color={'primary'}
                    variant="outlined"
                    onClick={() => {
                      routerChange('/' + listingType + '/' + properties.property, {
                        previous_screen: listingType,
                        from: 'cardEmailIcon',
                      })
                    }}
                  ></Button>
                )}
              </Box>
            )}
          </Box>
        )}
        {/*    <div>
                        <Tooltip title="List">
                            <IconButton onClick={()=> ''}>
                                <Badge badgeContent={matchCount > 0 ? matchCount : null} color="primary">
                                    <DynamicIcons className={classes.customIcon} icon="2DCListIcon" />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        
                        <Tooltip title="Number of viewers">
                            <IconButton onClick={()=> ''}>
                                <Badge badgeContent={properties.views} color="primary">
                                    <ViewIcon className={classes.icon}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </div> */}
        <div className={classes.infoContainerSearch}>
          {properties &&
            properties.priceView &&
            properties.propertyStatus !== 'rental' &&
            properties.propertyStatus !== 'leased' && (
              <Typography
                className={classes.priceText}
                align={'left'}
                variant="h6"
                component="p"
              >
                {properties.priceView?.toLowerCase()?.includes('contact agent') ? (
                  <>
                    <Link
                      onClick={() => {
                        selectProperty(true)
                        triggerAnalyticsEvent({
                          event: 'property_actions',
                          eventFrom: 'Mobile',
                          actionScope: 'property_card',
                          actionName: 'Property Card',
                          actionTarget: 'Clicked Contact Agent',
                          actionReference: 'property',
                          ...getPropertyData(properties),
                        })
                      }}
                      underline="none"
                      style={{ cursor: 'pointer' }}
                    >
                      <Typography
                        className={classes.priceText}
                        align={'left'}
                        variant="h6"
                        component="span"
                      >
                        {'Contact Agent'}
                      </Typography>
                    </Link>
                  </>
                ) : (
                  properties.priceView || formatUSD(properties.price)
                )}
              </Typography>
            )}
          {properties &&
            (properties.propertyStatus === 'rental' ||
              properties.propertyStatus === 'leased') &&
            (properties?.priceView || properties?.rentalPrice ? (
              <Typography
                className={classes.priceText}
                align={'left'}
                variant="h6"
                component="p"
              >
                {properties?.priceView
                  ? `${properties.priceView}`
                  : `$${properties?.rentalPrice} weekly`}
              </Typography>
            ) : (
              <Link
                onClick={() => {
                  selectProperty(true)
                }}
                underline="none"
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  className={classes.priceText}
                  align={'left'}
                  variant="h6"
                  component="span"
                >
                  {'Contact Agent'}
                </Typography>
              </Link>
            ))}

          {/* {properties.propertyStatus === 'rental' ||
            (properties.propertyStatus === 'leased' && properties.inspectionDate && (
              <Typography
                className={classes.dateText}
                align={'left'}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Inspection Date ' +
                  moment(properties.inspectionDate).format('ddd Do MMMM YYYY')}
              </Typography>
            ))} */}
          {properties.propertyStatus === 'sold' && properties.soldDate && (
            <Typography
              className={classes.dateText}
              align={'left'}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {'Sold on ' + moment(properties.soldDate).format('ddd Do MMMM YYYY')}
            </Typography>
          )}
          {/* {properties.propertyStatus !== 'sold' &&
            properties.propertyStatus !== 'rental' &&
            properties.propertyStatus !== 'leased' &&
            properties.propertyStatus !== 'comingsoon' &&
            properties.propertyStatus !== 'offmarket' &&
            properties.auctionDate && (
              <Typography
                className={classes.dateText}
                align={'left'}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Auction ' + moment(properties.auctionDate).format('ddd Do MMMM YYYY')}
              </Typography>
            )} */}

          {properties.propertyStatus !== 'comingsoon' &&
          properties.propertyStatus !== 'offmarket' &&
          properties.addressText ? (
            <Typography
              className={classes.addressText}
              align={'left'}
              variant="body2"
              component="p"
            >
              {properties.addressText &&
                properties.addressText.replace(addressRegex, '')}
            </Typography>
          ) : (
            <div>
              {/* <Typography
                className={classes.priceText}
                align={"left"}
                variant="h6"
                component="p"
              >
                {properties.price ? formatUSD(properties.price) : ""}
              </Typography> */}
              <Typography
                className={classes.addressText}
                align={'left'}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {properties.propertyStatus === 'comingsoon'
                  ? properties?.addressText
                  : properties.addressSuburb}
              </Typography>
              <div
                className={classes.emailContainer}
                onClick={() => {
                  selectProperty(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                <MailIcon className={classes.emailIcon} />
                <Typography
                  align={'left'}
                  className={classes.emailText}
                  variant="caption"
                  component="p"
                >
                  {'Email Agent for more details'}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const getListCoefficients = (data) => {
    let arr = []
    if (data) {
      if (data.coefficients) {
        const selectedProperty = data.coefficients
        if (props.filters && selectedProperty) {
          props.filters.filters.advanceProperty.map((item, index) => {
            if (item.active) {
              if (selectedProperty[item.key] >= 0.7) {
                arr.push(item.label)
              }
            }
          })
        }
      }
    }
    return arr
  }

  function renderInfoPropertyScreen() {
    const rentalStatusArr = ['leased', 'rental']

    const auctionInspectionDates = getAllPendingAuctionInspections(properties)
    return (
      <div>
        {/* <div className={classes.priceGuideCTA}> */}
        {/*           <Button
            variant={'outlined'}
            color={'primary'}
            className={classes.cta}
            onClick={() => setShowModalBottomOptions(!showModalBottomOptions)}
          >
            Share <SaveIcon className={classes.iconSave} />{' '}
          </Button> */}
        {/* <Button
            variant={"outlined"}
            color={"primary"}
            className={classes.cta}
            onClick={executePriceGuideScroll}
          >
            View Agent Price Guide
          </Button> */}
        {/* </div> */}
        <div className={classes.rootProperty} style={{ flexDirection: 'column' }}>
          {properties.propertyType?.toLowerCase() === 'land' ||
          properties.reaPropertyType?.toLowerCase() === 'land' ? null : (
            <div>
              <Tooltip title="Bedrooms">
                <IconButton disableRipple onClick={() => ''}>
                  <BedIcon className={classes.icon} />
                  <Typography variant="caption" color="textSecondary" component="p">
                    {properties.bedrooms ? properties.bedrooms : 0}
                  </Typography>
                </IconButton>
              </Tooltip>
              <Tooltip title="Bathrooms">
                <IconButton disableRipple onClick={() => ''}>
                  <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
                  <Typography variant="caption" color="textSecondary" component="p">
                    {properties.bathrooms ? properties.bathrooms : 0}
                  </Typography>
                </IconButton>
              </Tooltip>
              <Tooltip title="Parking">
                <IconButton disableRipple onClick={() => ''}>
                  <ParkingIcon className={classes.icon} />
                  <Typography variant="caption" color="textSecondary" component="p">
                    {properties.totalParking ? properties.totalParking : 0}
                  </Typography>
                </IconButton>
              </Tooltip>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.cta}
                onClick={() => {
                  setShowModalBottomOptions(!showModalBottomOptions)
                  triggerAnalyticsEvent({
                    event: 'property_actions',
                    eventFrom: 'Mobile',
                    actionScope: 'property_share',
                    actionName: 'Property Share',
                    actionTarget: 'Expanded Options',
                    actionReference: 'property',
                    ...getPropertyData(properties),
                  })
                }}
                style={{ marginLeft: 75 }}
              >
                Share <SaveIcon className={classes.iconSave} />{' '}
              </Button>
            </div>
          )}
          {!type_exception.includes(properties.propertyStatus) &&
            (properties?.statementOfIntent || properties?.statementOfInformation) && (
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.cta}
                  href={
                    properties?.statementOfIntent
                      ? properties.statementOfIntent
                      : properties?.statementOfInformation
                      ? properties.statementOfInformation
                      : null
                  }
                  target="_blank"
                  style={{ marginTop: 10 }}
                >
                  View Agent Price Guide
                </Button>
              </div>
            )}
          {/*<Box marginLeft="10px" color="#9B9B9B">
            {auctionInspectionDates.inspections?.length > 0 && (
              <>
                {auctionInspectionDates.inspections.length === 1 ? (
                  <Typography component="p" variant="body2" fontSize="10px">
                    Open for inspection {auctionInspectionDates.inspections[0]}
                  </Typography>
                ) : (
                  <Typography component="p" variant="body2" fontSize="10px">
                    Open for inspection:
                  </Typography>
                )}
              </>
            )}
            {auctionInspectionDates.inspections.length > 1 &&
              auctionInspectionDates.inspections.map((auctionInspectionDate, index) => (
                <Typography
                  key={'auction-inspection-dates' + index}
                  component="p"
                  variant="body2"
                  fontSize="10px"
                >
                  {auctionInspectionDate}
                </Typography>
              ))}
            {auctionInspectionDates.auction && (
              <Typography
                component="p"
                variant="body2"
                fontSize="12px"
                style={{ marginTop: '5px' }}
              >
                Auction {auctionInspectionDates.auction}
              </Typography>
            )}
          </Box> */}
          {/* <div>
                        <Tooltip title="Save">
                            <IconButton onClick={()=> setShowModalBottomOptions(!showModalBottomOptions)}>
                                <SaveIcon className={classes.iconSave}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="List">
                            <IconButton onClick={handleTooltip} style={{position: 'relative'}}>
                                <Badge badgeContent={matchCount > 0 ? matchCount : null} color="primary">
                                    <DynamicIcons className={classes.icon} icon="2DCListIcon" />
                                </Badge>
                                {
                                    open &&
                                    <div className={classes.filterMain}>
                                        <div className={classes.triangle}></div>
                                        <div className={classes.empty}></div>
                                        <Typography variant="body2" className={classes.filterText} >
                                        {
                                            (getListCoefficients(properties).length > 0) ?
                                                getListCoefficients(properties).map((item, keyIndex) => {

                                                    if(keyIndex === 0) {
                                                        return item
                                                    } else {
                                                        return ', ' + item.replace('Close to', '').toLowerCase();
                                                    }
                                                })
                                            : 'No selected filter'
                                        }
                                        </Typography>
                                    </div>
                                }
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Number of viewers">
                            <IconButton onClick={()=> ''}>
                                <Badge badgeContent={properties.views} color="primary">
                                    <ViewIcon className={classes.icon}/>
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    </div> */}
        </div>
        <div className={classes.infoContainer}>
          {properties && !rentalStatusArr.includes(properties.propertyStatus) && (
            <Typography
              className={classes.priceTextInner}
              align={'left'}
              variant="h6"
              component="p"
              dangerouslySetInnerHTML={{
                __html: properties.priceView
                  ? DOMPurify.sanitize(
                      htmlDecode(
                        properties.priceView !== 'Contact Agent'
                          ? properties.priceView.replace(/ *AU *$|AUS *$/gm, '')
                          : 'Contact Agent'
                      )
                    )
                  : 'Contact Agent',
              }}
            />
          )}
          {properties &&
            properties.rentalPrice &&
            rentalStatusArr.includes(properties.propertyStatus) && (
              <Typography
                className={classes.priceTextInner}
                align={'left'}
                variant="h6"
                component="p"
              >
                {properties.priceView
                  ? properties.priceView
                  : '$' +
                    numberWithCommas(properties.rentalPrice) +
                    ' ' +
                    (properties.rentalPaymentFrequency
                      ? properties.rentalPaymentFrequency
                      : '')}
              </Typography>
            )}
          {/* {listingType !== 'rent' && properties && properties.auctionDate && (
            <Typography
              className={classes.dateText}
              align={'left'}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {'Auction ' + moment(properties.auctionDate).format('ddd Do MMMM YYYY')}
            </Typography>
          )}

          {listingType === 'rent' && properties.inspectionDate && (
            <Typography
              className={classes.dateText}
              align={'left'}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {'Inspection Date ' +
                moment(properties.inspectionDate).format('ddd Do MMMM YYYY')}
            </Typography>
          )} */}
          {properties && properties.headline && (
            <Box marginY="6px" style={{ marginTop: 10 }}>
              <Typography
                className={classes.priceTextInner}
                align={'left'}
                variant="h6"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    htmlDecode(properties.headline.replace(/ *AU *$|AUS *$/gm, ''))
                  ),
                }}
              />
            </Box>
          )}
          {properties &&
          properties.addressText &&
          properties.propertyStatus !== 'offmarket' ? (
            <Box color="#010101">
              <Typography
                align={'left'}
                component="p"
                variant="body2"
                fontSize="12px"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    htmlDecode(properties.addressText.replace(/ *AU *$|AUS *$/gm, ''))
                  ),
                }}
              />
            </Box>
          ) : (
            <Box color="#010101">
              <Typography
                align={'left'}
                component="p"
                variant="body2"
                fontSize="12px"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(htmlDecode(properties.addressSuburb)),
                }}
              />
            </Box>
          )}
          {properties?.description && (
            <div className={classes.infoDescription}>
              <ReadMore limit={true} description={properties.description} />
            </div>
          )}
        </div>
      </div>
    )
  }

  function renderShareContent() {
    return (
      <Paper className={classes.optionBottomContainer} elevation={5}>
        <div className={classes.optionBottomHeaderContainer}>
          <div className={classes.optionBottomHeader}>
            <UnfoldMoreIcon />
            <Typography
              className={classes.optionBottomHeaderText}
              variant="h6"
              component="h2"
            >
              {'Share Listing'}
            </Typography>
          </div>
          <IconButton
            onClick={() => {
              showOptionBottomModal()
              triggerAnalyticsEvent({
                event: 'property_actions',
                eventFrom: 'Mobile',
                actionScope: 'property_share',
                actionName: 'Property Share',
                actionTarget: 'Expanded Options',
                actionReference: 'property',
                ...getPropertyData(properties),
              })
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.optionBottonList}>
          <ListOptions type={'share'} options={OPTION_MODAL} properties={properties} />
        </div>
      </Paper>
    )
  }

  return (
    <>
      {type === 'search' && renderInfoSearchScreen()}
      {type === 'property' && renderInfoPropertyScreen()}
      <OptionModalBottom
        isOpen={showModalBottomOptions}
        showOptionBottomModal={showOptionBottomModal}
        content={renderShareContent()}
      />
    </>
  )
}

export default withStyles(styles)(PropertyInfo)
