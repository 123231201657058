import React, { useState } from 'react';
import { Button, Typography, Grid, withStyles } from '@material-ui/core';
import styled from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';

import Step1 from './AddPropertyStep1';
import Step2 from './AddPropertyStep2';
import Step3 from './AddPropertyStep3';
import Step4 from './AddPropertyStep4';

import { requestAgentCreateProperty, requestUploadStatementOfIntent } from '../../../store/api';
import { clearAgentState, logoutRequest, toggleSignUp } from '../../../store/actions';
import LogoPlaceholder from '../../../assets/images/biggin-scott.jpg';
import { AGENT_CREATE_PROPERTY_FIELDS_CONFIG } from '../../../constants/constants';
import { LOGO_OBJECT, getLogoByAgencyId, DEFAULT_AGENCIES } from '../../../constants/constants';
import checkField from 'components/Common/Validator/validationService';
import NotificationDialog from '../../Web/CustomDialogs/NotificationDialog';
import { useRouterChange } from 'utils/router.utils';

const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
  },
  image: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
  },
  imageDark: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
    background:
      'linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%)',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width: '40%',
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '35px 0',
  },
});

const Container = styled.div`
  margin: 0 auto;
  padding: 0;
  box-shadow: none;
  font-family: Montserrat, sans-serif;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StepCounter = withStyles({
  root: {
    fontSize: 21,
    fontWeight: 300,
    lineHeight: '32px',
    marginBottom: 10,
    borderBottom: '1px solid #cdcdcd',
    paddingBottom: 10,
  },
})(Typography);

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
  },
})(Button);

const AddProperty = (props) => {
  const {
    classes,
    users,
    requestCreateProperty,
    create_property_status,
    create_property_error,
    create_property,
    authentication,
    toggleSignUp,
    uploaded_intent,
    upload_status,
    upload_error,
    requestUploadIntent,
    logoutUser,
  } = props;
  const [routerChange] = useRouterChange();
  const [step, setStep] = useState(1);
  const [maxStep, setMaxStep] = useState(4);
  const [enabledSoi, setEnabledSoi] = useState(false);
  const [successNotice, setSuccessNotice] = React.useState(false);
  const [errorNotice, setErrorNotice] = React.useState(false);
  const [isFormInvalid, setIsFormInvalid] = React.useState(true);
  const [errors, setErrors] = React.useState({
    vendorsEmail: { status: false, message: '' },
    vendorsPhone: { status: false, message: '' },
    propertyStatus: { status: false, message: '' },
  });

  const [addPropertyFields, setAddPropertyFields] = useState(null);

  const defaultAddPropertyFields = {
    agentInfo: {
      firstName: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      lastName: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      email: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      image: {
        value: '',
        error: { status: false, message: '' },
      },
      phone: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      agencyEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      vendorsEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      vendorsPhone: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      confirmVendorsEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
    },
    propertyInfo: {
      images: {
        value: ['default'],
        error: {
          status: false,
          message: '',
        },
      },
      customCover: {
        value: false,
        error: {
          status: false,
          message: '',
        },
      },
      propertyType: '',
      propertyStatus: {
        value: 'comingsoon',
        error: {
          status: false,
          message: '',
        },
      },
      description: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      propertyDetails: {},
      address: {
        value: {},
        error: {
          status: false,
          message: '',
        },
      },
      statementOfIntent: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
    },
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(clearAgentState());

    return () => dispatch(clearAgentState());
  }, []);

  React.useEffect(() => {
    let successTimeout;
    let errorTimeout;

    if (create_property_status && create_property_status === 'success' && !create_property_error) {
      setSuccessNotice(true);
      successTimeout = setTimeout(() => {
        setSuccessNotice(false);
        routerChange('/agent/dashboard');
      }, 3000);
    } else {
      setErrorNotice(true);
      errorTimeout = setTimeout(() => {
        setErrorNotice(false);
      }, 3000);
    }

    return () => {
      if (errorTimeout) clearTimeout(errorTimeout);
      if (successTimeout) clearTimeout(successTimeout);
    };
  }, [create_property_status, create_property_error]);

  const updateStep = (updatedStep) => {
    if (updatedStep >= 1 && updatedStep <= maxStep) {
      setStep(updatedStep);
    } else if (updatedStep > maxStep) {
      apiCreateProperty();
    }
  };

  React.useEffect(() => {
    if (authentication && !authentication.token && users && !users.user) {
      toggleSignUp(true);
    }

    setAddPropertyFields({
      ...defaultAddPropertyFields,
      agentInfo: {
        ...defaultAddPropertyFields.agentInfo,
        firstName: {
          ...defaultAddPropertyFields.agentInfo.firstName,
          value: users && users.agent && users.agent.firstName ? users.agent.firstName : '',
        },
        lastName: {
          ...defaultAddPropertyFields.agentInfo.lastName,
          value: users && users.agent && users.agent.lastName ? users.agent.lastName : '',
        },
        email: {
          ...defaultAddPropertyFields.agentInfo.email,
          value: users && users.agent && users.agent.email ? users.agent.email : '',
        },
        phone: {
          ...defaultAddPropertyFields.agentInfo.phone,
          value: users && users.agent && users.agent.telephone ? users.agent.telephone : '',
        },
        image: {
          ...defaultAddPropertyFields.agentInfo.image,
          value: users?.agent?.image ? users?.agent?.image : '',
        },
        agencyEmail: {
          ...defaultAddPropertyFields.agentInfo.agencyEmail,
          value: users && users.agency && users.agency.adminEmail ? users.agency.adminEmail : '',
        },
      },
    });
  }, []);

  React.useEffect(() => {
    if (addPropertyFields && addPropertyFields.propertyInfo) {
      if (
        addPropertyFields.propertyInfo.address.value.state?.toUpperCase() === 'VIC' &&
        addPropertyFields.propertyInfo.propertyStatus.value === 'comingsoon'
      ) {
        setEnabledSoi(true);
        setMaxStep(4);
      } else {
        setEnabledSoi(false);
        setMaxStep(3);
      }
    }
  }, [addPropertyFields]);

  const handleFieldChange = (fieldGroup, subGroup, fieldName, value) => {
    if (subGroup) {
      setAddPropertyFields({
        ...addPropertyFields,
        [fieldGroup]: {
          ...addPropertyFields[fieldGroup],
          [subGroup]: {
            ...addPropertyFields[fieldGroup][subGroup],
            [fieldName]: {
              ...addPropertyFields[fieldGroup][subGroup][fieldName],
              value: value,
            },
          },
        },
      });
    } else if (fieldGroup) {
      setAddPropertyFields({
        ...addPropertyFields,
        [fieldGroup]: {
          ...addPropertyFields[fieldGroup],
          [fieldName]: {
            ...addPropertyFields[fieldGroup][fieldName],
            value: value,
          },
        },
      });
    }
  };

  function formatUSD(num) {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  async function apiCreateProperty() {
    const params = {};

    params.property_status = addPropertyFields.propertyInfo.propertyStatus.value ?? 'comingsoon';
    params.property_type = addPropertyFields.propertyInfo.propertyType.value ?? '';
    params.address = {
      full: addPropertyFields?.propertyInfo?.address?.value?.full ?? '',
      lot_number: addPropertyFields?.propertyInfo?.address?.value?.lotNumber?.trim() ?? '',
      sub_number: addPropertyFields?.propertyInfo?.address?.value?.subNumber?.trim() ?? '',
      street_number: addPropertyFields?.propertyInfo?.address?.value?.streetNumber?.trim() ?? '',
      street_name: addPropertyFields?.propertyInfo?.address?.value.streetName?.trim() ?? '',
      municipality: addPropertyFields?.propertyInfo?.address?.value?.municipality ?? '',
      state: addPropertyFields?.propertyInfo?.address?.value?.state ?? '',
      suburb: addPropertyFields?.propertyInfo?.address?.value?.suburb?.toUpperCase() ?? '',
      postcode: addPropertyFields?.propertyInfo?.address?.value?.postcode ?? '',
    };
    params.bedrooms = addPropertyFields.propertyInfo.propertyDetails.value.bedrooms ?? 1;
    params.bathrooms = addPropertyFields.propertyInfo.propertyDetails.value.bathrooms ?? 1;
    params.carparkings = addPropertyFields.propertyInfo.propertyDetails.value.carparking ?? 1;
    params.agency_email = addPropertyFields.agentInfo.agencyEmail.value;
    params.vendor_email = addPropertyFields.agentInfo.vendorsEmail.value ?? '';
    params.image = addPropertyFields.propertyInfo.images.value;
    params.price = addPropertyFields.propertyInfo.propertyDetails.value.price;
    params.customCover = addPropertyFields.propertyInfo.customCover.value;
    params.statement_of_intent = addPropertyFields.propertyInfo.statementOfIntent.value;
    params.vendor_phone = addPropertyFields.agentInfo.vendorsPhone.value
      ? addPropertyFields.agentInfo.vendorsPhone.value.match(/\d/g).join('')
      : '';
    params.description = addPropertyFields.propertyInfo.description.value;

    params.priceView = addPropertyFields.propertyInfo.propertyDetails.value.priceView;
    params.importSource = 'added_manually_agent';
    await requestCreateProperty(params);
  }

  const handleCheckField = (state, value, fieldName) => {
    if (
      checkField(state, AGENT_CREATE_PROPERTY_FIELDS_CONFIG(fieldName).fields[fieldName]) === null ||
      ((fieldName === 'vendorsPhone' || fieldName === 'vendorsEmail') && value === '')
    ) {
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: { ...prevState[fieldName], status: false },
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          status: true,
          message: checkField(value, AGENT_CREATE_PROPERTY_FIELDS_CONFIG(fieldName).fields[fieldName]),
        },
      }));
    }
  };

  const logoObject = LOGO_OBJECT;

  const getAgencyLogo = (agencyName) => {
    if (
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)];
    } else {
      return LogoPlaceholder;
    }
  };
  return (
    <div className={classes.root}>
      <Container>
        {step <= maxStep && (
          <StepCounter variant="h5" className={classes.subtitle2}>
            Property Direct Upload Step {step} of {maxStep}
          </StepCounter>
        )}
        {addPropertyFields && (
          <div>
            {step === 1 && (
              <Step1
                addPropertyFields={addPropertyFields}
                setAddPropertyFields={setAddPropertyFields}
                handleFieldChange={handleFieldChange}
                handleCheckField={handleCheckField}
                errors={errors}
                setIsFormInvalid={setIsFormInvalid}
              />
            )}
            {step === 2 && (
              <Step2
                addPropertyFields={addPropertyFields}
                setAddPropertyFields={setAddPropertyFields}
                handleFieldChange={handleFieldChange}
                handleCheckField={handleCheckField}
                errors={errors}
                setIsFormInvalid={setIsFormInvalid}
              />
            )}
            {step === 3 && (
              <Step3
                addPropertyFields={addPropertyFields}
                setAddPropertyFields={setAddPropertyFields}
                handleFieldChange={handleFieldChange}
                handleCheckField={handleCheckField}
                errors={errors}
                setIsFormInvalid={setIsFormInvalid}
                logo={users.agency && users.agency.logoUrl ? users.agency.logoUrl : getAgencyLogo(users.reaAgencyId)}
                users={users}
              />
            )}
            {step === 4 && enabledSoi && (
              <Step4
                addPropertyFields={addPropertyFields}
                setAddPropertyFields={setAddPropertyFields}
                handleFieldChange={handleFieldChange}
                requestUploadIntent={requestUploadIntent}
                uploaded_intent={uploaded_intent}
                upload_status={upload_status}
                upload_error={upload_error}
                setIsFormInvalid={setIsFormInvalid}
                logoutUser={logoutUser}
              />
            )}
          </div>
        )}
        {create_property && create_property_status === 'success' && !create_property_error && (
          <NotificationDialog
            isOpen={successNotice}
            closeCallback={() => {
              setSuccessNotice(false);
            }}
            title={'Success'}
            text={'Property submitted for vendor and agency approval.'}
            showLogo={true}
            align={'center'}
          />
        )}
        {!create_property && create_property_status === 'failed' && create_property_error && (
          <NotificationDialog
            isOpen={errorNotice}
            closeCallback={() => {
              setErrorNotice(false);
            }}
            title={'Error'}
            text={'An error encountered while submitting your property.'}
            showLogo={true}
            align={'center'}
          />
        )}
        <Grid container spacing={0} className={classes.navContainer}>
          <Grid item xs={12}>
            <NavigationContainer>
              <div>
                {step !== 1 && (
                  <StyledButton variant="outlined" onClick={() => updateStep(step - 1)}>
                    Back
                  </StyledButton>
                )}
              </div>
              <div>
                <StyledButton
                  color="primary"
                  variant="contained"
                  disabled={isFormInvalid}
                  onClick={() => updateStep(step + 1)}
                >
                  {step === maxStep ? 'Done' : 'Next'}
                </StyledButton>
              </div>
            </NavigationContainer>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    users: state.users,
    create_property_status: state.agents.request_create_property_status,
    create_property_error: state.agents.request_create_property_error,
    create_property: state.agents.request_create_property,
    upload_status: state.agents.request_upload_property_intent_status,
    upload_error: state.agents.request_upload_property_intent_error,
    uploaded_intent: state.agents.request_upload_property_intent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestCreateProperty: (params) => requestAgentCreateProperty(params),
      toggleSignUp: (params) => toggleSignUp(params),
      requestUploadIntent: (params) => requestUploadStatementOfIntent(params),
      logoutUser: (params) => logoutRequest(params),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddProperty));
