import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const StyledHeading = withStyles((theme) => ({
  root: {
    fontSize: '1.25rem', 
    lineHeight: '2rem',
    fontFamily: 'Montserrat',
    letterSpacing: '-0.525px',
    fontWeight: 'normal',
    marginBottom: theme.spacing(2) 
  },
}))(Typography);

const SectionHeading = props => {    
    return (
        <StyledHeading { ...props }>{props.children}</StyledHeading>
    );
}

export default (SectionHeading);