import React, { useEffect, useState } from 'react'

import { Button, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { getAllFilters } from '../../../utils/search/selectors/selectors.search'
import { GLOBAL_FILTER_DEFAULT } from '../../../constants/globalfilters'
import _ from 'lodash'
import compareGlobalFilters from 'utils/compareGlobalFilters'

const useStyles = makeStyles((theme) => ({
  clearButton: {
    maxHeight: '35px',
    borderRadius: '30px',
    textTransform: 'none',
    backgroundColor: '#EF6631',

    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      backgroundColor: '#ed5419',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },

    '&:active': {
      backgroundColor: '#ed5419',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
  },

  smallMobileButton: {
    [theme.breakpoints.down(300)]: {
      padding: '5px 8px',
      fontSize: '11px',
    },
  },

  buttonDisabled: {
    pointerEvents: 'none',
    backgroundColor: '#f07545',
  },
}))

//////////// COMPONENT /////////////////////
//////////// COMPONENT /////////////////////
const ClearFiltersButton = ({ type, isSmallMobile, onClick }) => {
  const classes = useStyles()
  const [changedFilterCount, setChangedFilterCount] = useState(0)

  const GLOBAL_FILTERS = useSelector(getAllFilters)

  useEffect(() => {
    if (GLOBAL_FILTERS) {
      setChangedFilterCount(
        compareGlobalFilters(type, GLOBAL_FILTERS, GLOBAL_FILTER_DEFAULT, true).length
      )
    }
  }, [GLOBAL_FILTERS])

  return (
    <Button
      className={`${classes.clearButton} ${
        isSmallMobile ? classes.smallMobileButton : ''
      } ${changedFilterCount === 0 ? classes.buttonDisabled : ''}`}
      style={{
        color: 'white',
      }}
      onClick={(event) => onClick(event)}
      variant="contained"
    >
      {`Clear ${changedFilterCount} Filter${changedFilterCount <= 1 ? '' : 's'}`}
    </Button>
  )
}

export default ClearFiltersButton
