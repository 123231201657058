import { useSelector } from 'react-redux'
import { getAllFilters } from 'utils/search/selectors/selectors.search'
import { formatRequest } from 'utils/search/query/formatRequest'
import { useSearch } from 'utils/search/useSearch'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

export default function useGoogleEvents() {
  const [initiateSearch] = useSearch()
  const CURRENT_FILTERS = useSelector(getAllFilters)
  const triggerGTMEvent = useGTMDispatch()

  function getSearchPayload(trimmed) {
    let payload = formatRequest(CURRENT_FILTERS)
    if (trimmed) {
      delete payload?.countTotal
      delete payload?.excludeUnderOffer
      delete payload?.skip
      delete payload?.take

      if (Object.keys(payload?.range_filters)?.length === 0)
        delete payload?.range_filters

      Object.keys(payload).forEach((key) => (!payload[key] ? delete payload[key] : {}))
    }
    return payload
  }

  function getSearchData() {
    const searchData = getSearchPayload(true)
    const searchData_extracted = {}
    const searchData_suburbs = searchData?.suburb?.map(
      (suburb, index) =>
        (searchData_extracted['search_suburb_' + index] =
          suburb?.suburb + ', ' + suburb?.state + ' ' + suburb?.postcode)
    )

    searchData_extracted.search_reference = searchData?.reference
    searchData_extracted.search_includeNearby = searchData?.nearby_suburbs
      ? 'Yes'
      : 'No'
    searchData_extracted.search_bathrooms = searchData?.property_filters?.bathrooms
      ? searchData?.property_filters?.bathrooms?.value +
        (!searchData?.property_filters?.bathrooms?.exactMatch ? '+' : '')
      : undefined
    searchData_extracted.search_bedrooms = searchData?.property_filters?.bedrooms
      ? searchData?.property_filters?.bedrooms?.value +
        (!searchData?.property_filters?.bedrooms?.exactMatch ? '+' : '')
      : undefined
    searchData_extracted.search_parking = searchData?.property_filters?.totalParking
      ? searchData?.property_filters?.totalParking?.value +
        (!searchData?.property_filters?.totalParking?.exactMatch ? '+' : '')
      : undefined

    searchData_extracted.search_propertyType_house =
      searchData?.property_filters?.propertyType?.includes('House')
    searchData_extracted.search_propertyType_townhouse =
      searchData?.property_filters?.propertyType?.includes('Townhouse')
    searchData_extracted.search_propertyType_apartment =
      searchData?.property_filters?.propertyType?.includes('Apartment & Unit')
    searchData_extracted.search_propertyType_land =
      searchData?.property_filters?.propertyType?.includes('Land')
    searchData_extracted.search_propertyType_rural =
      searchData?.property_filters?.propertyType?.includes('Rural')
    searchData_extracted.search_propertyType_retirement =
      searchData?.property_filters?.propertyType?.includes('Retirement')
    searchData_extracted.search_propertyType_villa =
      searchData?.property_filters?.propertyType?.includes('Villa')

    searchData_extracted.search_price_min = searchData?.range_filters?.price?.min
    searchData_extracted.search_price_max = searchData?.range_filters?.price?.max
    searchData_extracted.search_rentalPrice_min =
      searchData?.rental_filters?.rentalPrice?.min
    searchData_extracted.search_rentalPrice_max =
      searchData?.rental_filters?.rentalPrice?.max

    if (searchData_extracted?.search_reference === 'rent') {
      delete searchData_extracted.search_price_min
      delete searchData_extracted.search_price_max
    } else {
      delete searchData_extracted.search_rentalPrice_min
      delete searchData_extracted.search_rentalPrice_max
    }

    Object.keys(searchData_extracted).forEach((key) =>
      !searchData_extracted[key] ? delete searchData_extracted[key] : {}
    )

    return searchData_extracted
  }

  function getPropertyData(property) {
    let propertyData = {}
    const propertyData_agents = property?.agents?.map((agent, index) => {
      propertyData['property_agents_' + index + '_name'] = agent?.name
      propertyData['property_agents_' + index + '_id'] = agent?.agentId
    })
    propertyData.property_agencyName =
      property?.agency?.registeredTradeName ?? property?.agency?.companyName
    propertyData.property_agencyId = property?.agency?.reaAgencyId
    propertyData.property_agencyOffice = property?.agency?.office
    propertyData.property_agentId = property?.agent?.agentId
    propertyData.property_agentName = property?.agent?.name
    propertyData.property_suburb = property?.addressSuburb
    propertyData.property_state = property?.addressState
    propertyData.property_postcode = property?.addressPostcode
    propertyData.property_bathrooms = property?.bathrooms
    propertyData.property_bedrooms = property?.bedrooms
    propertyData.property_totalParking = property?.totalParking
    propertyData.property_type = property?.propertyType
    propertyData.property_status = property?.propertyStatus
    propertyData.property_subscriptionType = property?.subscriptionType

    return propertyData
  }

  function triggerAnalyticsEvent(payload) {
    const pageURL = window?.location?.origin + window?.location?.pathname
    triggerGTMEvent({
      ...payload,
      pageURL: pageURL
    })
  }

  return { getSearchData, getPropertyData, triggerAnalyticsEvent }
}
