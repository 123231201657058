import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllFilters, getReferenceFilters } from './selectors/selectors.search'
import { compareArrays } from './utils/compareArrays'
import { compareMinMax } from './utils/compareMinMax'
import { compareStaticValues } from './utils/compareStaticValues'
import { compareValues } from './utils/compareValues'
import { compareRadiusDistance } from './utils/compareRadiusDistance'

export function useSearchType() {
  const REFERENCE_FILTERS = useSelector(getReferenceFilters)
  const CURRENT_FILTERS = useSelector(getAllFilters)

  const handleArrays = useMemo(() => {
    return (direction) => {
      let bucket = []
      let reference = []

      Object.keys(CURRENT_FILTERS).map((filter) => {
        const isArr =
          Object.prototype.toString.call(CURRENT_FILTERS[filter]) ===
            '[object Array]' ?? false
        if (
          isArr &&
          filter !== 'reference_filters' &&
          filter !== 'suburbs' &&
          filter !== 'search_reference' &&
          filter !== 'listing_search_sort'
        ) {
          const value = compareArrays(
            CURRENT_FILTERS?.[filter],
            REFERENCE_FILTERS?.[filter],
            'active'
          )
          bucket.push(value)
          if (value) {
            reference.push({ [filter]: value })
          }
        } else if (isArr && filter === 'listing_search_sort') {
          const value = compareArrays(
            CURRENT_FILTERS?.[filter],
            REFERENCE_FILTERS?.[filter],
            null,
            'listing_search_sort'
          )
          bucket.push(value)
          if (value) {
            reference.push({ [filter]: value })
          }
        } else if (isArr && filter === 'search_reference') {
          const value = compareArrays(
            CURRENT_FILTERS?.[filter],
            REFERENCE_FILTERS?.[filter],
            null,
            'search_reference'
          )
          bucket.push(value)
          if (value) {
            reference.push({ [filter]: value })
          }
        } else if (isArr && filter === 'suburbs') {
          const value = compareArrays(
            CURRENT_FILTERS?.[filter],
            REFERENCE_FILTERS?.[filter],
            null,
            'suburbs'
          )
          bucket.push(value)
          if (value) {
            reference.push({ [filter]: value })
          }
        }
      })
      return { value: bucket.includes(direction), reference: reference }
    }
  }, [CURRENT_FILTERS])

  const handleMinMax = useMemo(() => {
    return (direction) => {
      let bucket = []
      let reference = []
      Object.keys(CURRENT_FILTERS).map((filter) => {
        const isMinMax =
          (CURRENT_FILTERS[filter]?.hasOwnProperty('min') &&
            CURRENT_FILTERS[filter]?.hasOwnProperty('max')) ??
          false
        if (isMinMax) {
          const value = compareMinMax(
            CURRENT_FILTERS?.[filter],
            REFERENCE_FILTERS?.[filter]
          )
          bucket.push(value)
          if (value) {
            reference.push({ [filter]: value })
          }
        }
      })

      return { value: bucket.includes(direction), reference: reference }
    }
  }, [CURRENT_FILTERS])

  const handleValues = useMemo(() => {
    return (direction) => {
      let bucket = []
      let reference = []
      Object.keys(CURRENT_FILTERS).map((filter) => {
        const isValues =
          (CURRENT_FILTERS[filter]?.hasOwnProperty('value') &&
            filter !== 'radius_distance') ??
          false
        if (isValues) {
          const value = compareValues(
            CURRENT_FILTERS?.[filter],
            REFERENCE_FILTERS?.[filter]
          )
          bucket.push(value)
          if (value) {
            reference.push({ [filter]: value })
          }
        }
      })

      return { value: bucket.includes(direction), reference: reference }
    }
  }, [CURRENT_FILTERS])

  const handleStaticValues = useMemo(() => {
    return (direction) => {
      let bucket = []
      let reference = []
      Object.keys(CURRENT_FILTERS).map((filter) => {
        const isStatic =
          Object.prototype.toString.call(CURRENT_FILTERS[filter]) ===
            '[object String]' ||
          Object.prototype.toString.call(CURRENT_FILTERS[filter]) === '[object Null]' ||
          Object.prototype.toString.call(CURRENT_FILTERS[filter]) === '[object Boolean]'
        if (isStatic) {
          const value = compareStaticValues(
            CURRENT_FILTERS?.[filter],
            REFERENCE_FILTERS?.[filter]
          )
          bucket.push(value)
          if (value) {
            reference.push({ [filter]: value })
          }
        }
      })

      return { value: bucket.includes(direction), reference: reference }
    }
  }, [CURRENT_FILTERS])

  const handleRadiusDistance = useMemo(() => {
    return (direction) => {
      let bucket = []
      let reference = []
      const value = compareRadiusDistance(
        CURRENT_FILTERS?.['radius_distance'],
        REFERENCE_FILTERS?.['radius_distance']
      )
      bucket.push(value)
      if (value) {
        reference.push({ radius_distance: value })
      }
      return { value: bucket.includes(direction), reference: reference }
    }
  }, [CURRENT_FILTERS])

  function isWidened() {
    const reference = {
      ...handleArrays('widened')?.reference,
      ...handleMinMax('widened')?.reference,
      ...handleValues('widened')?.reference,
      ...handleStaticValues('widened')?.reference,
      ...handleRadiusDistance('widened')?.reference,
    }

    const value =
      handleArrays('widened')?.value ||
      handleMinMax('widened')?.value ||
      handleValues('widened')?.value ||
      handleStaticValues('widened')?.value ||
      handleRadiusDistance('widened')?.value

    return { value, reference }
  }

  function isNarrowed() {
    const reference = {
      ...handleArrays('narrowed')?.reference,
      ...handleMinMax('narrowed')?.reference,
      ...handleValues('narrowed')?.reference,
      ...handleStaticValues('narrowed')?.reference,
      ...handleRadiusDistance('narrowed')?.reference,
    }

    const value =
      handleArrays('narrowed')?.value ||
      handleMinMax('narrowed')?.value ||
      handleValues('narrowed')?.value ||
      handleStaticValues('narrowed')?.value ||
      handleRadiusDistance('narrowed')?.value

    return { value, reference }
  }

  return [isWidened, isNarrowed]
}
