import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'

/** selectors */
import {
  getAuthentication,
  getSettings,
  getUser,
  getSuperAdminAccount,
} from 'store/selectors/superadmin.selectors'
import { SuperBreadCrumbs, SuperAccountSettings } from 'components/Web/Superadmin'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
    '@media (max-width: 1024px)': {
      paddingLeft: 102,
    },
  },
})

const SuperadminSettings = (props) => {
  const { classes, history } = props
  const USER = useSelector(getUser)
  const ADMIN_ACCOUNT = useSelector(getSuperAdminAccount)

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Account Settings',
      path: '/superadmin/account-settings',
    },
  ]

  const [accountData, setAccountData] = useState(
    ADMIN_ACCOUNT?.data?.entity ?? USER?.superadminuser?.data?.superadmin
  )

  useEffect(() => {
    if (ADMIN_ACCOUNT?.data?.entity) {
      setAccountData(ADMIN_ACCOUNT?.data?.entity)
    }
  }, [ADMIN_ACCOUNT])

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs
        section={'Account Settings'}
        crumbsLinks={CRUMBS}

      />
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <SuperAccountSettings user={accountData} auth={USER} />
        </Grid>
      </Grid>
    </div>
  )
}
export default withStyles(styles)(SuperadminSettings)
