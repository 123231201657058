import React from 'react'
import styled, { css } from 'styled-components'

import CallIcon from '@material-ui/icons/Call'
import ThumbsUp from '@material-ui/icons/ThumbUp'
import { Link } from 'react-router-dom'

import { withStyles, Typography, IconButton } from '@material-ui/core';

const styles = (theme) => ({
  root: {},
  main: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  iconContainer: {
    marginRight: '0.313em',
    width: '0.625em',
    height: '0.625em',
  },
  icon: {
    color: '#000',
    width: '0.625em',
    height: '0.625em',
    marginRight: '0.213em',
  },
  customIconContainer: {
    marginRight: '0.313em',
    width: '0.525em',
    height: '0.525em',
  },
  customIcon: {
    color: '#000',
    width: '0.525em',
    height: '0.525em',
    marginRight: '0.213em',
  },
  pricing: {
    fontSize: 12,
    fontWeight: 500,
  },

  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.625em',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '0.625em',
    //marginBottom: '0.625em'
  },
  callIcon: {
    color: '#000',
    width: '1.2em',
    height: '1.2em',
  },
  thumbsup: {
    color: '#000',
    width: '0.5em',
    height: '0.5em',
  },
  agentName: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 500,
  },
  website: {
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 500,
    color: '#007CDF',
  },
  footerContainer: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    height: 50,
    backgroundColor: '#F5F5F5',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowContainerFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bottomRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  countLabel: {
    textAlign: 'left',
    fontSize: 10,
    fontWeight: 500,
    color: '#9B9B9B',
  },
  emailText: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const AgentInfo = (props) => {
  const { classes, type, agent } = props

  function renderAgentCard() {
    return (
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.rowContainer}>
            <div className={classes.headerContainer}>
              <Typography
                dangerouslySetInnerHTML={{ __html: agent.agent }}
                className={classes.agentName}
                variant="body2"
                component="p"
              ></Typography>
              <Typography className={classes.website} variant="body2" component="p">
                {agent.website}
              </Typography>
            </div>
            <IconButton className={classes.iconContainer} href={`tel:${agent.phone}`}>
              <CallIcon className={classes.callIcon} />
            </IconButton>
          </div>
          <Typography variant="caption" component="p" className={classes.emailText}>
            <Link href={`mailto:${agent.email}`}>{agent.email}</Link>
          </Typography>
        </div>
        <div className={classes.footerContainer}>
          {/*  <div className={classes.rowContainerFooter}>
                        <IconButton className={classes.iconContainer}>
                            <ThumbsUp className={classes.thumbsup} />
                        </IconButton>
                        <Typography variant="caption" component="p" onClick={()=> window.open("https://www.google.com")}>
                            {agent.rating + '% Positive Rating'}
                        </Typography>
                    </div> 
          <div className={classes.bottomRowContainer}>
            <>
              <Typography
                className={classes.countLabel}
                variant="caption"
                component="p"
              >
                {agent.propertiesSold}
              </Typography>
              <Typography
                className={classes.countLabel}
                variant="caption"
                component="p"
              >
                {'Sold'}
              </Typography>
            </>
            <>
              <Typography
                className={classes.countLabel}
                variant="caption"
                component="p"
              >
                {agent.propertiesForRent}
              </Typography>
              <Typography
                className={classes.countLabel}
                variant="caption"
                component="p"
              >
                {'For Rent'}
              </Typography>
            </>
            <>
              <Typography
                className={classes.countLabel}
                variant="caption"
                component="p"
              >
                {agent.propertiesForSale}
              </Typography>
              <Typography
                className={classes.countLabel}
                variant="caption"
                component="p"
              >
                {'For Sale'}
              </Typography>
            </>
          </div>*/}
        </div>
      </div>
    )
  }

  return <>{type === 'agent-card' && renderAgentCard()}</>
}

export default withStyles(styles)(AgentInfo)
