export const PACKAGE_NAMES = ['Entry', 'Extended', 'Executive', 'Elite'];

export const FEATURE_LABELS = [
  'Listing Order',
  'Standard Listing',
  'Coming Soon Listing',
  'Off-Market Listing',
  'Dashboard',
  'Agency Branded',
  'Agenct Name',
  'Agent Photo',
  'Social Media Boost',
  'New Property Icon',
  'Updated Property Icon',
];

export const PACKAGE_FEATURES = {
  entry: [
    { label: 'Listing Order', isBoolean: false, value: '4th' },
    { label: 'Standard Listing', isBoolean: true, value: true },
    { label: 'Coming Soon Listing', isBoolean: true, value: true },
    { label: 'Off-Market Listing', isBoolean: true, value: true },
    { label: 'Dashboard', isBoolean: true, value: true },
    { label: 'Agency Branded', isBoolean: true, value: true },
    { label: 'Agenct Name', isBoolean: true, value: false },
    { label: 'Agent Photo', isBoolean: true, value: false },
    { label: 'Social Media Boost', isBoolean: true, value: false },
    { label: 'New Property Icon', isBoolean: false, value: '1 day' },
    { label: 'Updated Property Icon', isBoolean: false, value: '1 day' },
  ],
  extended: [
    { label: 'Listing Order', isBoolean: false, value: '3rd' },
    { label: 'Standard Listing', isBoolean: true, value: true },
    { label: 'Coming Soon Listing', isBoolean: true, value: true },
    { label: 'Off-Market Listing', isBoolean: true, value: true },
    { label: 'Dashboard', isBoolean: true, value: true },
    { label: 'Agency Branded', isBoolean: true, value: true },
    { label: 'Agenct Name', isBoolean: true, value: true },
    { label: 'Agent Photo', isBoolean: true, value: false },
    { label: 'Social Media Boost', isBoolean: true, value: false },
    { label: 'New Property Icon', isBoolean: false, value: '3 days' },
    { label: 'Updated Property Icon', isBoolean: false, value: '2 days' },
  ],
  executive: [
    { label: 'Listing Order', isBoolean: false, value: '2nd' },
    { label: 'Standard Listing', isBoolean: true, value: true },
    { label: 'Coming Soon Listing', isBoolean: true, value: true },
    { label: 'Off-Market Listing', isBoolean: true, value: true },
    { label: 'Dashboard', isBoolean: true, value: true },
    { label: 'Agency Branded', isBoolean: true, value: true },
    { label: 'Agenct Name', isBoolean: true, value: true },
    { label: 'Agent Photo', isBoolean: true, value: true },
    { label: 'Social Media Boost', isBoolean: true, value: false },
    { label: 'New Property Icon', isBoolean: false, value: '5 days' },
    { label: 'Updated Property Icon', isBoolean: false, value: '3 days' },
  ],
  elite: [
    { label: 'Listing Order', isBoolean: false, value: '1st' },
    { label: 'Standard Listing', isBoolean: true, value: true },
    { label: 'Coming Soon Listing', isBoolean: true, value: true },
    { label: 'Off-Market Listing', isBoolean: true, value: true },
    { label: 'Dashboard', isBoolean: true, value: true },
    { label: 'Agency Branded', isBoolean: true, value: true },
    { label: 'Agenct Name', isBoolean: true, value: true },
    { label: 'Agent Photo', isBoolean: true, value: true },
    { label: 'Social Media Boost', isBoolean: true, value: true },
    { label: 'New Property Icon', isBoolean: false, value: '7 days' },
    { label: 'Updated Property Icon', isBoolean: false, value: '4 days' },
  ],
};
