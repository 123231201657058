import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { StreetView } from 'react-google-map-street-view'
import DynamicIcons from '../DynamicIcons'
import Image from '../Image'

import GoogleMapView from './GoogleMapView'
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'

import { withStyles, Card, CardContent, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    height: '100%',
    borderRadius: 0,
  },
  mapviewport: {
    position: 'relative',
  },
  cardcontent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  maps: {
    height: '100%',
    width: '100%',
  },
})

const streetViewCSS = {
  height: '100%',
  width: '100%',
}
const Marker = ({ content }) => <div>{content}</div>
const handleApiLoaded = (map, maps) => {
  // use map and maps objects
}

const MapCard = (props) => {
  const { classes, data } = props
  const STATIC_MAP_SRC =
    'https://maps.googleapis.com/maps/api/staticmap?center=' +
    data.map.coordinates.toString() +
    '&zoom=15&size=640x270&scale=2&maptype=roadmap&key=' +
    GOOGLE_MAP_API_KEY +
    ''
  const STATIC_STREETVIEW_SRC =
    'https://maps.googleapis.com/maps/api/streetview?size=640x270&location=' +
    data.map.coordinates.toString() +
    '&fov=120&heading=70&pitch=0&key=' +
    GOOGLE_MAP_API_KEY +
    ''

  // console.log(data, 'DATA ON MAPCARD')

  const Map = (props) => {
    const isInteractive = props.isInteractive
    if (isInteractive) {
      return <GoogleMapView data={data} />
    }
    return (
      <div>
        <Image src={STATIC_MAP_SRC} aspectRatio={2.35 / 1} />
        <DynamicIcons
          viewBox="0 0 24 28"
          style={{
            position: 'absolute',
            left: '48.2%',
            top: '48.2%',
            width: '1.5em',
            height: '1.5em',
          }}
          icon="2DCSaleDropInIcon"
        />
      </div>
    )
  }
  const Street = (props) => {
    const isInteractive = props.isInteractive
    if (isInteractive) {
      return (
        <StreetView
          mapStyle={streetViewCSS}
          address={data.address}
          APIkey={GOOGLE_MAP_API_KEY}
          streetView
          zoomLevel={data.map.zoom}
        />
      )
    }
    return <Image src={STATIC_STREETVIEW_SRC} aspectRatio={2.35 / 1} />
  }

  return (
    <Card className={classes.root}>
      <div className={classes.mapviewport}>
        {data.mode === 'map' && <Map isInteractive={data.interactive} />}
        {data.mode === 'street' && <Street isInteractive={data.interactive} />}
      </div>
    </Card>
  )
}

export default withStyles(styles)(MapCard)
