import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components'
import _ from 'lodash'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import HeadingContentSmall from '../Form/HeadingContentSmall';
import FormField from '../Form/FormField'
import LogoPlaceholder from '../../../assets/images/logo_placeholder.png';

import PropertyType from '../Form/PropertyTypeEdit';
import SliderContainer from '../Form/SliderContainerDesktop';
import CustomSwitch from '../Filter/CustomSwitch'

import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'

import {
  PROPERTY_TYPE,
  PROPERTY_DETAILS_UPLOAD,
} from '../Filter/DefaultValues';


import { withStyles, Button, Typography } from '@material-ui/core';


const styles = () => ({
  root: {
    backgroundColor: '#FFF',
    overflow: 'hidden'
  },
});


const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px'
  }
})(Button);

const StyledText = withStyles(() => ({
  root: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
}))(Typography);

const FormWrap = styled.div`
  padding:0 0 0;

  .slider-Price, .slider-Bedrooms, .slider-Bathrooms, .slider-Carparking {
    margin-left: 12px;
    margin-right: 12px;
  }
`
const CheckWrap = styled.div`
  padding:30px 0;
  display:flex;
  flex-direction:row; 
  width:232px;
  margin:0 auto;
  justify-content:space-between;
  align-items:center;
`
const GoogleWrap = styled.div`
 
  .css-1hb7zxy-IndicatorsContainer {
    display:none;
  }
  .css-yk16xz-control {
    border: 1px solid #ced4da !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    height:56px;
    outline:none !important;
    transition:border-color 0.3s ease;
  }
  .css-g1d714-ValueContainer {
    height:56px;
    outline:none !important;
  }
  .css-1wa3eu0-placeholder {
    font-family:Roboto, sans-serif;
    font-size:16px;
    color:rgba(0, 0, 0, 0.35);
  }
  .css-1uccc91-singleValue {
    font-family:Roboto, sans-serif;
    font-size:16px;
  }
  input, .css-b8ldur-Input, .css-b8ldur-Input div {
    font-family:Roboto, sans-serif !important;
    font-size:16px !important;
  }

  .css-26l3qy-menu, .css-26l3qy-menu * {
    font-family:Roboto, sans-serif !important;
  }

  .css-1pahdxg-control {
    box-shadow:none !important;
    border-radius: 4px !important;
    border-color: #35C0CA !important;
  }

`


const EditPropertyDetails = props => {

  const {
    classes,
    property,
    editPropertyFields,
    handleFieldChange
  } = props
  const [propertyType, setPropertyType] = useState(PROPERTY_TYPE);
  const [propertyDetails, setPropertyDetails] = useState(PROPERTY_DETAILS_UPLOAD);
  const [propertyStatus, setPropertyStatus] = useState(property.propertyStatus === 'offmarket' ? true : false)
  const [propertyAddress, setPropertyAddress] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [geoCodedDetails, setGeoCodedDetails] = useState(null)
  const [defaultPropertyDetails, setDefaultPropertyDetails] = useState(null)

  useEffect(() => {

    if (property) {
      const details = {
        ...PROPERTY_DETAILS_UPLOAD,
        price: property.price,
        bedrooms: property.bedrooms,
        bathrooms: property.bathrooms,
        carparking: property.totalParking
      }

      setDefaultPropertyDetails(details)
    }

  }, [property])

  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'propertyDetails', propertyDetails)
  }, [propertyDetails])

  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'propertyStatus', handlePropertyStatus(propertyStatus))
  }, [propertyStatus])

  useEffect(() => {
    if (propertyAddress && propertyAddress.value) {
      setIsDisabled(false)
      geocodeByPlaceId(propertyAddress.value.place_id)
        .then(results => setGeoCodedDetails(results[0]))
        .catch(() => setGeoCodedDetails(null));

    } else {
      setIsDisabled(true)
    }


  }, [propertyAddress])

  useEffect(() => {
    if (geoCodedDetails) {

      let details = {}
      details.postcode = _.find(geoCodedDetails.address_components, { types: ["postal_code"] }).short_name ?? ""
      details.state = _.find(geoCodedDetails.address_components, { types: ["administrative_area_level_1", "political"] }).short_name ?? ''
      details.municipality = _.find(geoCodedDetails.address_components, { types: ["administrative_area_level_2", "political"] }) ? _.find(geoCodedDetails.address_components, { types: ["administrative_area_level_2", "political"] }).short_name : ''
      details.suburb = _.find(geoCodedDetails.address_components, { types: ["locality", "political"] }) ? _.find(geoCodedDetails.address_components, { types: ["locality", "political"] }).short_name : ''
      details.streetName = _.find(geoCodedDetails.address_components, { types: ["route"] }) ? _.find(geoCodedDetails.address_components, { types: ["route"] }).short_name : ''
      details.streetNumber = _.find(geoCodedDetails.address_components, { types: ["street_number"] }) ? _.find(geoCodedDetails.address_components, { types: ["street_number"] }).short_name : ''

      if (details) {
        var lotNumber = details.lotNumber ?? '',
          streetName = details.streetName,
          streetNumber = details.streetNumber,
          subNumber = details.subNumber ?? '',
          suburb = details.suburb,
          municipality = details.municipality,
          state = details.state,
          postcode = details.postcode


        const fullAddressText = '' + lotNumber + ' ' + streetNumber + ' ' + streetName + ' ' + subNumber + ' ' + suburb + ', ' + municipality + ' ' + state + ' ' + postcode;
        details.full = fullAddressText.replace(/ {1,}/g, " ").trim()
        details.rawAddress = propertyAddress

        handleFieldChange('propertyInfo', null, 'address', details)

      }

    }

  }, [geoCodedDetails])

  const handlePropertyType = (data) => {
    if (property) {
      setType(property.propertyType)
      if (_.filter(data, { active: true }).length > 0) {
        setPropertyType(data)
        const propTypeText = _.filter(data, { active: true })[0].title
        handleFieldChange('propertyInfo', null, 'propertyType', propTypeText)
      } else {
        setPropertyType(setType(property.propertyType))
        handleFieldChange('propertyInfo', null, 'propertyType', property.propertyType)
      }
    }
  }

  const handlePropertyDetails = (data) => {
    setPropertyDetails(data)
  }

  const handlePropertyStatus = (status) => {
    return status ? 'offmarket' : 'comingsoon';
  }

  const handleGooglePlaces = (data) => {
    setPropertyAddress(data)
  }


  const setType = (type) => {
    let ptype = []

    PROPERTY_TYPE.map((item) => {
      if (item.title === type) {
        ptype.push({ ...item, active: true })
      } else {
        ptype.push(item)
      }
    })

    return ptype;
  }

  const handleData = () => {

    let ptype = [];
    propertyType.map((item, index) => {
      if (item.active) {
        ptype.push(item.title);
      }
    })
    const data = {
      location: propertyAddress && propertyAddress.label || "",
      propertyType: ptype,
      propertyDetails: propertyDetails,
      propertyStatus: handlePropertyStatus(propertyStatus)
    }
  }



  return (
    <div className={classes.root}>

      <FormWrap>
        <GoogleWrap>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_MAP_API_KEY}
            selectProps={{
              placeholder: editPropertyFields.propertyInfo.address.value.full || property.addressText,
              value: editPropertyFields.propertyInfo.address.value.rawAddress || propertyAddress,
              onChange: handleGooglePlaces,
            }}
            autocompletionRequest={{
              componentRestrictions: { country: 'au' }
            }}
          />
        </GoogleWrap>


        <CheckWrap>
          <CustomSwitch label={'Coming soon'} checked={propertyStatus} onChange={(e) => {
            setPropertyStatus(!propertyStatus)
          }} />
          <StyledText>Off Market</StyledText>
        </CheckWrap>

        <HeadingContentSmall
          title={'Select Property Type'}
        />
        <PropertyType
          handlePropertyType={handlePropertyType}
          propertyType={propertyType}
        />
        {defaultPropertyDetails &&
          <SliderContainer
            handlePropertyDetails={handlePropertyDetails}
            propertyDetails={defaultPropertyDetails ? defaultPropertyDetails : propertyDetails}
            showPrice={true}
            price={property.price}
          />
        }

      </FormWrap>
    </div>
  );
}

export default withStyles(styles)(EditPropertyDetails);