import Favorites from '../../containers/Mobile/Favorites'
import Property from '../../containers/Mobile/Property'
import PropertyMapView from '../../containers/Mobile/PropertyMapView'
import PropertyStreetView from '../../containers/Mobile/PropertyStreetView'
import FavoritesMap from '../../containers/Mobile/FavoritesMap'
import FavoritesV2 from '../../containers/Mobile/FavoritesV2'

let routes = [
  {
    path: '/favourites',
    component: FavoritesV2,
  },
  {
    path: '/favourites/map-view',
    component: FavoritesMap,
  },
  {
    path: '/favourites/:id',
    component: Property,
  },
  {
    path: '/favourites/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/favourites/:id/location/street',
    component: PropertyStreetView,
  },
]

export default routes
