import { POST, FETCH, PUT, DELETE } from 'utils/api'
import { authActions } from 'store/actions/auth.actions'
import { apiEndpoints } from 'utils/api/endpoints'

const urlSetter = (params, actionMain) => {
    let URL = apiEndpoints[actionMain]
    if (params.query && params.query !== '') {
        URL = apiEndpoints[actionMain] + params.query
    }
    return URL
}

export function requestPOSTAPIAuth(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(authActions(params, actionMain, 'REQUEST'))
        POST(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(authActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(authActions(error.response, actionMain, 'FAILED'))
            })
    }
}

export function requestGETAPIAuth(params, actionMain) {
    let URL = urlSetter(params, actionMain)

    return (dispatch) => {
        dispatch(authActions(params, actionMain, 'REQUEST'))
        FETCH(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(authActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(authActions(error.response, actionMain, 'FAILED'))
            })
    }
}

export function requestPUTAPIAuth(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(authActions(params, actionMain, 'REQUEST'))
        PUT(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(authActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(authActions(error.response, actionMain, 'FAILED'))
            })
    }
}

export function requestDELETEAPIAgency(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(authActions(params, actionMain, 'REQUEST'))
        DELETE(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(authActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(authActions(error.response, actionMain, 'FAILED'))
            })
    }
}
