import React from 'react';
import styled from 'styled-components'

import FormField from '../Form/FormField'
import { Grid, withStyles, Button } from '@material-ui/core';

const styles = () => ({
  root: {
    backgroundColor: '#FFF',
  },
});


const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px'
  }
})(Button);


const EditPropertyPersonalInfo = props => {

  const {
    classes,
    property,
    editPropertyFields,
    handleFieldChange
  } = props


  const [firstName, setFirstName] = React.useState(property.agent.firstName)
  const [lastName, setLastName] = React.useState(property.agent.lastName)
  const [email, setEmail] = React.useState(property.agent.email)
  const [mobile, setMobile] = React.useState(property.agent.telephone)
  const [agencyEmail, setAgencyEmail] = React.useState(null)
  const [vendorEmail, setVendorEmail] = React.useState(property.vendor.email)
  const [isFormValid, setIsFormValid] = React.useState(false)



  React.useEffect(() => {
    if (firstName, lastName, email, mobile, agencyEmail, vendorEmail) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [firstName, lastName, email, mobile, agencyEmail, vendorEmail])

  React.useEffect(() => {
    if (editPropertyFields && editPropertyFields.agentInfo && editPropertyFields.agentInfo.agencyEmail && editPropertyFields.agentInfo.agencyEmail.value) {
      setAgencyEmail(editPropertyFields.agentInfo.agencyEmail.value)
    }
  }, [editPropertyFields])

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item md={6}>
          <FormField
            type={'text'}
            placeholder={'First name *'}
            errorMsg={''}
            showError={''}
            value={firstName ? firstName : ''}
            disabled
            onChange={(e) => {
              const value = e.target.value;
              setFirstName(value)
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            type={'text'}
            placeholder={'Last name *'}
            errorMsg={''}
            showError={''}
            value={lastName ? lastName : ''}
            disabled
            onChange={(e) => {
              const value = e.target.value;
              setLastName(value)
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            type={'email'}
            placeholder={'Your Email *'}
            errorMsg={''}
            showError={''}
            value={email ? email : ''}
            disabled
            onChange={(e) => {
              const value = e.target.value;
              setEmail(value)
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            type={'tel'}
            placeholder={'Your Mobile Number *'}
            errorMsg={''}
            showError={''}
            value={mobile ? mobile : ''}
            disabled
            onChange={(e) => {
              const value = e.target.value;
              setMobile(value)
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            type={'email'}
            placeholder={'Agency email address *'}
            errorMsg={''}
            showError={''}
            value={agencyEmail}
            disabled
            onChange={(e) => {
              const value = e.target.value;
              setAgencyEmail(value)
              handleFieldChange('agentInfo', null, 'agencyEmail', value)
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            type={'email'}
            placeholder={'Vendor email address *'}
            errorMsg={''}
            showError={''}
            disabled
            value={vendorEmail ? vendorEmail : ''}
            onChange={(e) => {
              const value = e.target.value;
              setVendorEmail(value)
              handleFieldChange('agentInfo', null, 'vendorsEmail', value)
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EditPropertyPersonalInfo);