import { AUTH_ACTION_TYPES } from 'store/constants/auth.constants'
import { PURGE } from 'redux-persist'

const INITIAL_STATE = {
    // temporary values
    payload_request: null,
    payload_success: null,
    payload_failed: null,
    status: null,
    error: null,
}

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case AUTH_ACTION_TYPES['AUTH_FORGOT_PASSWORD']['REQUEST']:
        case AUTH_ACTION_TYPES['AUTH_RESET_PASSWORD']['REQUEST']:

            return {
                ...state,
                status: action.type,
                payload_request: action.params,
                error: null,
            }

        case AUTH_ACTION_TYPES['AUTH_FORGOT_PASSWORD']['FAILED']:
        case AUTH_ACTION_TYPES['AUTH_RESET_PASSWORD']['FAILED']:
            return {
                ...state,
                status: action.type,
                payload_failed: action.params,
                error: action.params.data,
            }

        case AUTH_ACTION_TYPES['AUTH_FORGOT_PASSWORD']['SUCCESS']:
        case AUTH_ACTION_TYPES['AUTH_RESET_PASSWORD']['SUCCESS']:
            return {
                ...state,
                status: action.type,
                payload_success: action.params.data,
            }

        case AUTH_ACTION_TYPES['GENERAL_AUTH']['CLEAR_ERROR']:
            return {
                ...state,
                status: null,
                payload_request: null,
                payload_success: null,
                payload_failed: null,
                error: null,
            }

        case AUTH_ACTION_TYPES['GENERAL_AUTH']['CLEAR_STATUS']:
            return {
                ...state,
                status: null,
            }

        case AUTH_ACTION_TYPES['GENERAL_AUTH']['RESET_STATES']:
            return INITIAL_STATE

        case PURGE:
            return INITIAL_STATE

        default: {
            return state
        }
    }
}

export default authReducer
