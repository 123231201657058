import {
  REQUEST_AGENCY_REGISTER,
  REQUEST_AGENCY_REGISTER_SUCCESS,
  REQUEST_AGENCY_REGISTER_FAIL,
  REQUEST_AGENCY_CONTACT_ADMIN,
  REQUEST_AGENCY_CONTACT_ADMIN_SUCCESS,
  REQUEST_AGENCY_CONTACT_ADMIN_FAIL,
  REQUEST_AGENCY_DETAILS,
  REQUEST_AGENCY_DETAILS_SUCCESS,
  REQUEST_AGENCY_DETAILS_FAIL,
  REQUEST_AGENTS_REGISTER,
  REQUEST_AGENTS_REGISTER_SUCCESS,
  REQUEST_AGENTS_REGISTER_FAIL,
  REQUEST_AGENCY_AGENTS,
  REQUEST_AGENCY_AGENTS_SUCCESS,
  REQUEST_AGENCY_AGENTS_FAIL,
  REQUEST_RESEND_AGENT_INVITATION,
  REQUEST_RESEND_AGENT_INVITATION_SUCCESS,
  REQUEST_RESEND_AGENT_INVITATION_FAIL,
  REQUEST_DISABLE_AGENT,
  REQUEST_DISABLE_AGENT_SUCCESS,
  REQUEST_DISABLE_AGENT_FAIL,
  REQUEST_VERIFY_AGENCY,
  REQUEST_VERIFY_AGENCY_SUCCESS,
  REQUEST_VERIFY_AGENCY_FAIL,
  REQUEST_AGENCY_ME,
  REQUEST_AGENCY_ME_SUCCESS,
  REQUEST_AGENCY_ME_FAIL,
  REQUEST_UPGRADE_PROPERTY,
  REQUEST_UPGRADE_PROPERTY_SUCCESS,
  REQUEST_UPGRADE_PROPERTY_FAIL,
  REQUEST_STATISTICS,
  REQUEST_STATISTICS_SUCCESS,
  REQUEST_STATISTICS_FAIL,
  REQUEST_UPDATE_AGENCY,
  REQUEST_UPDATE_AGENCY_SUCCESS,
  REQUEST_UPDATE_AGENCY_FAIL,
  REQUEST_APPLY_PROMO_CODE,
  REQUEST_APPLY_PROMO_CODE_SUCCESS,
  REQUEST_APPLY_PROMO_CODE_FAIL,
  REQUEST_APPLY_PROMO_CODE_RESET,
} from '../constants/agency.constants';

export const agencyRegister = (params) => {
  return {
    type: REQUEST_AGENCY_REGISTER,
    params,
  };
};

export const agencyRegisterSuccess = (payload) => {
  return {
    type: REQUEST_AGENCY_REGISTER_SUCCESS,
    payload,
  };
};

export const agencyRegisterFail = (error) => {
  return {
    type: REQUEST_AGENCY_REGISTER_FAIL,
    error,
  };
};

export const agencyContactAdmin = (params) => {
  return {
    type: REQUEST_AGENCY_CONTACT_ADMIN,
    params,
  };
};

export const agencyContactAdminSuccess = (payload) => {
  return {
    type: REQUEST_AGENCY_CONTACT_ADMIN_SUCCESS,
    payload,
  };
};

export const agencyContactAdminFail = (error) => {
  return {
    type: REQUEST_AGENCY_CONTACT_ADMIN_FAIL,
    error,
  };
};

export const agencyDetails = (params) => {
  return {
    type: REQUEST_AGENCY_DETAILS,
    params,
  };
};

export const agencyDetailsSuccess = (payload) => {
  return {
    type: REQUEST_AGENCY_DETAILS_SUCCESS,
    payload,
  };
};

export const agencyDetailsFail = (error) => {
  return {
    type: REQUEST_AGENCY_DETAILS_FAIL,
    error,
  };
};

export const agentsRegister = (params) => {
  return {
    type: REQUEST_AGENTS_REGISTER,
    params,
  };
};

export const agentsRegisterSuccess = (payload) => {
  return {
    type: REQUEST_AGENTS_REGISTER_SUCCESS,
    payload,
  };
};

export const agentsRegisterFail = (error) => {
  return {
    type: REQUEST_AGENTS_REGISTER_FAIL,
    error,
  };
};

export const getAgencyAgents = (params) => {
  return {
    type: REQUEST_AGENCY_AGENTS,
    params,
  };
};

export const getAgencyAgentsSuccess = (payload) => {
  return {
    type: REQUEST_AGENCY_AGENTS_SUCCESS,
    payload,
  };
};

export const getAgencyAgentsFail = (error) => {
  return {
    type: REQUEST_AGENCY_AGENTS_FAIL,
    error,
  };
};

export const resendAgentInvitation = (params) => {
  return {
    type: REQUEST_RESEND_AGENT_INVITATION,
    params,
  };
};

export const resendAgentInvitationSuccess = (payload) => {
  return {
    type: REQUEST_RESEND_AGENT_INVITATION_SUCCESS,
    payload,
  };
};

export const resendAgentInvitationFail = (error) => {
  return {
    type: REQUEST_RESEND_AGENT_INVITATION_FAIL,
    error,
  };
};

export const disableAgent = (params) => {
  return {
    type: REQUEST_DISABLE_AGENT,
    params,
  };
};

export const disableAgentSuccess = (payload) => {
  return {
    type: REQUEST_DISABLE_AGENT_SUCCESS,
    payload,
  };
};

export const disableAgentFail = (error) => {
  return {
    type: REQUEST_DISABLE_AGENT_FAIL,
    error,
  };
};
export const verifyAgency = (params) => {
  return {
    type: REQUEST_VERIFY_AGENCY,
    params,
  };
};

export const verifyAgencySuccess = (payload) => {
  return {
    type: REQUEST_VERIFY_AGENCY_SUCCESS,
    payload,
  };
};

export const verifyAgencyFail = (error) => {
  return {
    type: REQUEST_VERIFY_AGENCY_FAIL,
    error,
  };
};
export const agencyMe = (params) => {
  return {
    type: REQUEST_AGENCY_ME,
    params,
  };
};

export const agencyMeSuccess = (payload) => {
  return {
    type: REQUEST_AGENCY_ME_SUCCESS,
    payload,
  };
};

export const agencyMeFail = (error) => {
  return {
    type: REQUEST_AGENCY_ME_FAIL,
    error,
  };
};
export const upgradeProperty = (params) => {
  return {
    type: REQUEST_UPGRADE_PROPERTY,
    params,
  };
};

export const upgradePropertySuccess = (payload) => {
  return {
    type: REQUEST_UPGRADE_PROPERTY_SUCCESS,
    payload,
  };
};

export const upgradePropertyFail = (error) => {
  return {
    type: REQUEST_UPGRADE_PROPERTY_FAIL,
    error,
  };
};

export const statistics = (params) => {
  return {
    type: REQUEST_STATISTICS,
    params,
  };
};

export const statisticsSuccess = (payload) => {
  return {
    type: REQUEST_STATISTICS_SUCCESS,
    payload,
  };
};

export const statisticsFail = (error) => {
  return {
    type: REQUEST_STATISTICS_FAIL,
    error,
  };
};

export const updateAgency = (params) => {
  return {
    type: REQUEST_UPDATE_AGENCY,
    params,
  };
};

export const updateAgencySuccess = (payload) => {
  return {
    type: REQUEST_UPDATE_AGENCY_SUCCESS,
    payload,
  };
};

export const updateAgencyFail = (error) => {
  return {
    type: REQUEST_UPDATE_AGENCY_FAIL,
    error,
  };
};

export const applyPromoCode = (params) => {
  return {
    type: REQUEST_APPLY_PROMO_CODE,
    params,
  };
};

export const applyPromoCodeSuccess = (payload) => {
  return {
    type: REQUEST_APPLY_PROMO_CODE_SUCCESS,
    payload,
  };
};

export const applyPromoCodeFail = (error) => {
  return {
    type: REQUEST_APPLY_PROMO_CODE_FAIL,
    error,
  };
};

export const applyPromoCodeReset = () => {
  return {
    type: REQUEST_APPLY_PROMO_CODE_RESET,
  };
};
