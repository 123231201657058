import React from 'react';
import styled, { css } from 'styled-components'

import CustomSelect from './CustomSelect';
import Searchbar from '../Searchbar';

import { withStyles, Typography } from '@material-ui/core';

const TradeContainer = styled.div`
    height:100vh;
    width:100%;
`

const TradeContainerOverlay = styled.div`
    height:100vh;
    width:100%;
    background: linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%);
    position: fixed;
    z-index: 1;
`

const ContentContainer = styled.div`
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const StyledText = withStyles({
    root: {
        textAlign:'center',
        color:'#fff',
        fontSize:'20px',
        lineHeight:'1.19',
        padding:'10px',
        zIndex: 10,
        position: 'relative'
    },
  })(Typography);

const StyledSubText = withStyles({
    root: {
        textAlign:'center',
        color:'#fff',
        fontSize:'15px',
        lineHeight:'1.19',
        padding:'15px',
        zIndex: 10,
        position: 'relative'
    },
  })(Typography);

const Wrap = styled.div`
  padding-top: 20px;
`

const categoryOptions = [{
    id:0,
    label:'Select a category',
    value:'select'
  },{
    id:1,
    label:'All',
    value:'all'
  },{
    id:2,
    label:'Landscaper',
    value:'landscaper'
  },{
    id:3,
    label:'Plumber',
    value:'plumber'
  },{
    id:4,
    label:'Gardener',
    value:'gardener'
  }]

const TradesLanding = props => {  

    const [category, setCategory] = React.useState(null);

    const handleCategory = (data) => {
        setCategory(data)
    }

    return (
        <TradeContainer style={{
            backgroundImage: `url(${props.imageBG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'right center'
        }}>
            <TradeContainerOverlay />
            <ContentContainer>
                <StyledText variant="h2">
                    Search to find your perfect trade or service
                </StyledText>
                <StyledSubText variant="h2">
                    Lorem ipsum dolor sit amet, consectetur <br/> adipiscing elit, sed do eiusmod tempor incididunt
                </StyledSubText>
                <Wrap>
                    <CustomSelect 
                        options={categoryOptions} 
                        handleCategory={handleCategory} 
                        isClearFilter={false}
                        value={category}
                    />
                    <Wrap>
                        <Searchbar
                            type={'search'}
                            sortType={'search'}
                            isFullWidth={true}
                            showSearchBar={true}
                            showFilters={false}
                            setPropertySearch={props.setPropertySearch}
                            clearPropertySuburb={props.clearPropertySuburb}
                            apiRequestSearchProperty={props.apiRequestSearchProperty}
                        />
                    </Wrap>
                </Wrap>
            </ContentContainer>
        </TradeContainer>
    );
}

export default (TradesLanding);