import React from 'react'
import { Typography, withStyles } from '@material-ui/core';
import pmateLogo from 'assets/images/pmate-icon-white.png'

const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#fff',
  },
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 262,
  },
  card: {
    maxWidth: 353,
    height: 286,
    background: 'rgba(20, 35, 50, 0.8)',
    borderRadius: '0  0 15px 15px',
    padding: '36px 24px 25px 30px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '31px',
    marginBottom: 12,
    color: '#fff',
  },
  content: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '23px',
    color: '#fff',
  },
  imageDark: {
    width: '100%',
    height: 380,
    objectFit: 'cover',
    zIndex: 0,
    background:
      'linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%)',
  },
  logo: {
    display: 'block',
    maxWidth: 35,
    height: 'auto',
    marginBottom: 56,
    objectFit: 'cover',
  },
})

const AgentDashboardBanner = ({ classes, title, content, image }) => {
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.card}>
          <img className={classes.logo} src={pmateLogo} alt="Propertymate" />
          <Typography className={classes.title} variant="h1">
            {title}
          </Typography>
          <Typography className={classes.content} variant="body1">
            {content}
          </Typography>
        </div>
      </div>
      <img
        className={classes.imageDark}
        src={image}
        alt="Agent Dashboard Banner Image"
      />
    </div>
  )
}

export default withStyles(styles)(AgentDashboardBanner)
