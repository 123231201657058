import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function RetirementVillageIcon(props: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      {/* <rect
        width="59.5"
        height="59.5"
        x="0.25"
        y="0.25"
        stroke="#000"
        strokeWidth="0.5"
        rx="4.75"
      ></rect> */}
      <mask
        id="mask0"
        width="48"
        height="2"
        x="7"
        y="37"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7 37.578h47.198v1.268H7v-1.268z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M53.93 38.846H7.268a.268.268 0 010-.536H53.93a.268.268 0 010 .536"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M30.598 38.846a.268.268 0 01-.268-.268v-6.022c0-.148.12-.266.268-.266.148 0 .268.118.268.266v6.022c0 .148-.12.268-.268.268M11.03 38.846a.268.268 0 01-.269-.268v-9.326a.268.268 0 01.536 0v9.326c0 .148-.12.268-.268.268M25.33 38.846a.268.268 0 01-.268-.268v-9.326a.268.268 0 01.536 0v9.326c0 .148-.12.268-.268.268"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9.526 30.567a.266.266 0 01-.22-.116.266.266 0 01.068-.372l8.654-6.022a.268.268 0 11.304.442l-8.654 6.022a.277.277 0 01-.152.046"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M26.836 30.567a.277.277 0 01-.152-.046L18.028 24.5a.268.268 0 11.304-.442l8.656 6.022a.266.266 0 01.068.372.266.266 0 01-.22.116M9.526 27.557a.266.266 0 01-.22-.116.266.266 0 01.068-.372l8.654-6.022a.268.268 0 11.304.442L9.678 27.51a.277.277 0 01-.152.046"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M26.836 27.557a.277.277 0 01-.152-.046l-8.656-6.022a.268.268 0 11.304-.442l8.656 6.022a.266.266 0 01.068.372.266.266 0 01-.22.116M9.525 30.567a.268.268 0 01-.268-.268v-3.01c0-.149.12-.267.268-.267.148 0 .268.119.268.267v3.01c0 .148-.12.268-.268.268"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M26.836 30.567a.268.268 0 01-.268-.268v-3.01c0-.149.12-.267.268-.267.148 0 .268.119.268.267v3.01c0 .148-.12.268-.268.268"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask1"
        width="48"
        height="18"
        x="7"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7 38.846h47.198V21H7v17.846z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g fill="#000" fillRule="evenodd" clipRule="evenodd" >
        <path d="M16.565 38.31h3.228v-6.238h-3.228v6.238zm3.496.536h-3.764a.266.266 0 01-.266-.268v-6.774c0-.148.118-.266.266-.266h3.764c.148 0 .268.118.268.266v6.774c0 .148-.12.268-.268.268zM35.867 38.846a.268.268 0 01-.268-.268v-9.326a.268.268 0 01.536 0v9.326c0 .148-.12.268-.268.268M50.166 38.846a.268.268 0 01-.268-.268v-9.326a.268.268 0 01.536 0v9.326c0 .148-.12.268-.268.268"></path>
        <path d="M34.362 30.567a.266.266 0 01-.22-.116.266.266 0 01.068-.372l8.656-6.022a.268.268 0 11.304.442l-8.656 6.022a.277.277 0 01-.152.046"></path>
        <path d="M51.672 30.567a.277.277 0 01-.152-.046L42.866 24.5a.268.268 0 11.304-.442l8.654 6.022a.266.266 0 01.068.372.266.266 0 01-.22.116M34.362 27.557a.266.266 0 01-.22-.116.266.266 0 01.068-.372l8.656-6.022a.268.268 0 11.304.442l-8.656 6.022a.277.277 0 01-.152.046"></path>
        <path d="M51.672 27.557a.277.277 0 01-.152-.046l-8.654-6.022a.268.268 0 11.304-.442l8.654 6.022a.266.266 0 01.068.372.266.266 0 01-.22.116M34.361 30.567a.268.268 0 01-.268-.268v-3.01c0-.149.12-.267.268-.267.148 0 .268.119.268.267v3.01c0 .148-.12.268-.268.268"></path>
        <path d="M51.672 30.567a.268.268 0 01-.268-.268v-3.01c0-.149.12-.267.268-.267.148 0 .268.119.268.267v3.01c0 .148-.12.268-.268.268M41.401 38.31h3.228v-6.238h-3.228v6.238zm3.496.536h-3.764a.266.266 0 01-.266-.268v-6.774c0-.148.118-.266.266-.266h3.764c.148 0 .268.118.268.266v6.774c0 .148-.12.268-.268.268z"></path>
      </g>
    </svg>
  );
}

export function RetirementVillageIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RetirementVillageIcon />
      <RetirementVillageIcon color="primary" />
      <RetirementVillageIcon color="secondary" />
      <RetirementVillageIcon color="action" />
      <RetirementVillageIcon color="disabled" />
      <RetirementVillageIcon style={{ color: green[500] }} />
    </div>
  );
}
