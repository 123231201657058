import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { SuperLoginForm, AuthImages, Snackybar } from 'components/Web/Superadmin'
import { Loader } from 'components/Common'

/** redux */
import { superadminActions } from 'store/actions'
import { requestPOSTAPISuperadmin } from 'store/api'
import {
  getStatus,
  getErrors,

} from 'store/selectors/superadmin.selectors'

import { SET_API_TOKEN } from 'utils/api'
import { useRouterChange } from 'utils/router.utils';

const STATIC_PASSWORD = '2dcsuperadmin'

const styles = () => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    height: '100vh',
    padding: 40,
    paddingLeft: '10vw',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const SuperadminLogin = (props) => {
  const { classes } = props
  const [routerChange] = useRouterChange()

  const dispatch = useDispatch()

  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)
  const SUPERADMIN_TOKEN = useSelector(getErrors)

  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  })

  useEffect(() => {
    /**
     * * Show Admin Password query when accessing superadmin containers,
     * ? defalt password: STATIC_PASSWORD
     */

    /**
     * * Default state refresher
     */
    handleClearStatus()
    handleClearError()
    return () => {
      handleClearError()
    }
  }, [])

  useEffect(() => {
    if (STATUS === 'superadmin/SUPERADMIN_LOGIN_REQUEST') {
      setLoading(true)
    }
    if (STATUS === 'superadmin/SUPERADMIN_LOGIN_FAILED') {
      setLoading(false)
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR?.message : ERROR,
      })
      handleClearError()
    }
    if (STATUS === 'superadmin/SUPERADMIN_LOGIN_SUCCESS') {
      setLoading(false)
      routerChange('/superadmin/dashboard')
      if (SUPERADMIN_TOKEN) {
        SET_API_TOKEN(SUPERADMIN_TOKEN.token)
      }
    }
    handleClearStatus()
  }, [STATUS, ERROR])

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    })
  }

  const requestAdminPassword = () => {
    let password = prompt('Enter Superadmin Password to access', '')
    if (password !== STATIC_PASSWORD) {
      routerChange('/')
    }
  }

  /** API Request */
  const handleSuperadminLogin = (data) => {
    let request = {
      query: "?userRole=Superadmin",
      data: { email: data.email, password: data.password },
    }
    dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_LOGIN'))
  }

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  }

  return (
    <>
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5} className={classes.leftColumn}>
            <SuperLoginForm

              error={ERROR}
              handleSuperadminLogin={handleSuperadminLogin}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={7} className={classes.rightColumn}>
            <AuthImages type={'login'} />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default withStyles(styles)(SuperadminLogin)
