export function getStorageVersion(state) {
  return state.storage.version;
}
export function getPopupStatus(state) {
  return state.storage.popup_status;
}
export function getScrollPosition(state) {
  return state.storage.search_scroll_position;
}
export function getPropertySearchedRef(state) {
  return state.storage.property_search_ref;
}
export function getSuperadminManageAgencyRowCount(state) {
  return state.storage.superadminManageAgencyRow;
}
export function getSuperadminManageAgencyPageCount(state) {
  return state.storage.superadminManageAgencyPage;
}
export function getSuperadminManageAgencyButtons(state) {
  return state.storage.superadminManageAgencyButtons;
}
export function getSuperadminManageAgencyFilters(state) {
  return state.storage.superadminManageAgencyFilters;
}
export function getSuperadminManageAgencySort(state) {
  return state.storage.superadminManageAgencySort;
}
export function getSuperadminManageAgencyField(state) {
  return state.storage.superadminManageAgencyField;
}
export function getFromInnerPage(state) {
  return state.storage.from_innerpage;
}
