export const BILLING_ACTION_TYPES = {
  BILLING_GET_ALL_INVOICES: {
    REQUEST: 'billing/BILLING_GET_ALL_INVOICES_REQUEST',
    SUCCESS: 'billing/BILLING_GET_ALL_INVOICES_SUCCESS',
    FAILED: 'billing/BILLING_GET_ALL_INVOICES_FAILED',
    CLEAR: 'billing/BILLING_GET_ALL_INVOICES_CLEAR',
  },
  BILLING_GET_INVOICE_XERO: {
    REQUEST: 'billing/BILLING_GET_INVOICE_XERO_REQUEST',
    SUCCESS: 'billing/BILLING_GET_INVOICE_XERO_SUCCESS',
    FAILED: 'billing/BILLING_GET_INVOICE_XERO_FAILED',
    CLEAR: 'billing/BILLING_GET_INVOICE_XERO_CLEAR',
  },
  STRIPE_GET_PRICES: {
    REQUEST: 'billing/STRIPE_GET_PRICES_REQUEST',
    SUCCESS: 'billing/STRIPE_GET_PRICES_SUCCESS',
    FAILED: 'billing/STRIPE_GET_PRICES_FAILED',
    CLEAR: 'billing/STRIPE_GET_PRICES_CLEAR',
  },
  STRIPE_POST_CHECKOUT: {
    REQUEST: 'billing/STRIPE_POST_CHECKOUT_REQUEST',
    SUCCESS: 'billing/STRIPE_POST_CHECKOUT_SUCCESS',
    FAILED: 'billing/STRIPE_POST_CHECKOUT_FAILED',
    CLEAR: 'billing/STRIPE_POST_CHECKOUT_CLEAR',
  },
  STRIPE_POST_CLIENTSECRET: {
    REQUEST: 'billing/STRIPE_POST_CLIENTSECRET_REQUEST',
    SUCCESS: 'billing/STRIPE_POST_CLIENTSECRET_SUCCESS',
    FAILED: 'billing/STRIPE_POST_CLIENTSECRET_FAILED',
    CLEAR: 'billing/STRIPE_POST_CLIENTSECRET_CLEAR',
  },
  STRIPE_POST_PAYMENTMETHODINTENT: {
    REQUEST: 'billing/STRIPE_POST_PAYMENTMETHODINTENT_REQUEST',
    SUCCESS: 'billing/STRIPE_POST_PAYMENTMETHODINTENT_SUCCESS',
    FAILED: 'billing/STRIPE_POST_PAYMENTMETHODINTENT_FAILED',
    CLEAR: 'billing/STRIPE_POST_PAYMENTMETHODINTENT_CLEAR',
  },
  STRIPE_GET_PAYMENTMETHODS: {
    REQUEST: 'billing/STRIPE_GET_PAYMENTMETHODS_REQUEST',
    SUCCESS: 'billing/STRIPE_GET_PAYMENTMETHODS_SUCCESS',
    FAILED: 'billing/STRIPE_GET_PAYMENTMETHODS_FAILED',
    CLEAR: 'billing/STRIPE_GET_PAYMENTMETHODS_CLEAR',
  },
  STRIPE_REMOVE_PAYMENTMETHOD: {
    REQUEST: 'billing/STRIPE_REMOVE_PAYMENTMETHOD_REQUEST',
    SUCCESS: 'billing/STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS',
    FAILED: 'billing/STRIPE_REMOVE_PAYMENTMETHOD_FAILED',
    CLEAR: 'billing/STRIPE_REMOVE_PAYMENTMETHOD_CLEAR',
  },
  STRIPE_DEFAULT_PAYMENTMETHOD: {
    REQUEST: 'billing/STRIPE_DEFAULT_PAYMENTMETHOD_REQUEST',
    SUCCESS: 'billing/STRIPE_DEFAULT_PAYMENTMETHOD_SUCCESS',
    FAILED: 'billing/STRIPE_DEFAULT_PAYMENTMETHOD_FAILED',
    CLEAR: 'billing/STRIPE_DEFAULT_PAYMENTMETHOD_CLEAR',
  },
  STRIPE_GET_SUBSCRIPTION_DETAILS: {
    REQUEST: 'billing/STRIPE_GET_SUBSCRIPTION_DETAILS_REQUEST',
    SUCCESS: 'billing/STRIPE_GET_SUBSCRIPTION_DETAILS_SUCCESS',
    FAILED: 'billing/STRIPE_GET_SUBSCRIPTION_DETAILS_FAILED',
    CLEAR: 'billing/STRIPE_GET_SUBSCRIPTION_DETAILS_CLEAR',
  },
  STANDALONE_PAYMENT_GET_AGENCY_DETAILS: {
    REQUEST: 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_REQUEST',
    SUCCESS: 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_SUCCESS',
    FAILED: 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_FAILED',
    CLEAR: 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_CLEAR',
  },

  GENERAL_BILLING: {
    CLEAR_ERROR: 'billing/CLEAR_ERROR',
    CLEAR_STATUS: 'billing/CLEAR_STATUS',
    RESET_STATES: 'billing/RESET_STATES',
  },
}
