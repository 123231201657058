import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import Close from '@material-ui/icons/Close';

import { withStyles, Typography, Button } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(1)
    },
    optionButton: {
        textTransform: 'none',
        width: 120,
        height: 35,
        marginTop: theme.spacing(1),
        borderRadius: '1.250em',
        color: '#000',
        fontWeight: 'normal',
        boxShadow: 'none'
    },
    cancelButton: {
        backgroundColor: 'transparent',
        border: '0.25px solid #000000'
    },
    submitButton: {

    }
});

const StyledText = withStyles({
  root: {
      textAlign:'left',
      color:'#4D4D4D',
      fontSize:'14px',
      lineHeight:'1.19',
      paddingBottom:'10px',
  },
})(Typography);

const SavedButton = props => {  
    const { 
        classes,
        handleClose,
        handleSubmitSaveSearch,
        isError
    } = props;

    return (
        <div className={classes.root}>
            <Button 
                className={clsx(classes.optionButton, classes.cancelButton)}
                size={'small'}
                disableElevation
                disableRipple
                onClick={handleClose}>
                {'Cancel'}
            </Button>
            <Button 
                className={clsx(classes.optionButton, classes.submitButton)}
                size={'small'}
                color={'primary'}
                disableElevation
                disableRipple
                variant={'contained'}
                onClick={()=> handleSubmitSaveSearch() }>
                {'Save Search'}
            </Button>
        </div>
    );
}

export default withStyles(styles)(SavedButton);