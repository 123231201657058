import React, { useEffect } from 'react'
import { Paper, Typography, Grid, Button, TextField, withStyles } from '@material-ui/core';
import { useFormik } from 'formik'
import * as yup from 'yup'
import clsx from 'clsx'

const FormInput = (props) => (
  <TextField {...props} fullWidth id="outlined-basic" variant="outlined" />
)

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    width: 450,
    minHeight: 450,
    borderRadius: 20,
    paddingTop: 30,
  },
  content: {
    paddingTop: 20,
    paddingRight: 40,
    paddingLeft: 40,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
    },
    marginTop: 20,
    // marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
    // borderBottom: "0.3px solid #BDBDBD",
  },
  button: {
    borderRadius: 20,
    textTransform: 'capitalize',
    minWidth: 150,
    marginBottom: 10,
  },
  forgotButton: {
    borderRadius: 20,
    textTransform: 'capitalize',
    fontSize: 12,
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subtitle: {
    fontWeight: 300,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px',
  },
  passwordConfigMain: {
    marginTop: 20,
    marginBottom: 20,
  },
  passwordConfigContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  caption: {
    fontWeight: 300,
    fontSize: '10px',
    letterSpacing: '0.09px',
    lineHeight: '18px',
    color: 'gray',
    marginLeft: 5,
  },
  colorActive: {
    backgroundColor: '#27AE60',
    width: 6,
    height: 6,
    borderRadius: 6,
  },
  colorInactive: {
    backgroundColor: '#C4C4C4',
    width: 6,
    height: 6,
    borderRadius: 6,
  },
})

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  /*  .matches(
     /^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/,
     'Password is Invalid'
   ), */
  confirmpassword: yup
    .string('Enter your password')
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  /* .matches(
    /^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/,
    'Password is Invalid'
  ), */
})

const AgencyActivateForm = (props) => {
  const { classes, type, handleSubmit } = props

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmpassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit({ password: formik.values.confirmpassword })
    },
  })

  const checkPassword = (type) => {
    if (type === 'lowercase') {
      let hasLowercase = /[a-z]/
      return hasLowercase.test(formik.values.password)
    }
    if (type === 'uppercase') {
      let hasUppercase = /[A-Z]/
      return hasUppercase.test(formik.values.password)
    }
    if (type === 'number') {
      let hasNumber = /[1234567890]/
      return hasNumber.test(formik.values.password)
    }
    if (type === 'special') {
      let hasSpecial = /[$&+,:;=?@#|'<>.^*()%!-]/
      return hasSpecial.test(formik.values.password)
    }
    if (type === 'length') {
      let isLength = /^.{8,}$/
      return isLength.test(formik.values.password)
    }
  }

  const _renderPasswordConfig = (state, label) => {
    return (
      <div className={classes.passwordConfigContainer}>
        <div
          className={clsx({
            [classes.colorActive]: state,
            [classes.colorInactive]: !state,
          })}
        />
        <Typography className={classes.caption} variant={'caption'}>
          {label}
        </Typography>
      </div>
    )
  }

  const _renderPasswordChecker = () => {
    return (
      <Grid container className={classes.passwordConfigMain}>
        <Grid item lg={6}>
          {_renderPasswordConfig(checkPassword('length'), 'Minimum 8 characters')}
          {/* {_renderPasswordConfig(
            checkPassword('special'),
            'At least one special character'
          )} */}
          {/* {_renderPasswordConfig(
            checkPassword('lowercase'),
            'At least one lower case character'
          )}
          {_renderPasswordConfig(
            checkPassword('uppercase'),
            'At least one upper case character'
          )}
          {_renderPasswordConfig(checkPassword('number'), 'At least one number')} */}
        </Grid>
        <Grid item lg={6}></Grid>
      </Grid>
    )
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.content}>
        <Typography className={classes.title} variant={'h1'}>
          We’re passionate about passwords.
        </Typography>
        <Typography className={classes.subtitle} variant={'body2'}>
          For top notch security, pick the best password in your armoury.
        </Typography>
        <div className={classes.form}>
          <form onSubmit={formik.handleSubmit}>
            <FormInput
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <FormInput
              fullWidth
              id="confirmpassword"
              name="confirmpassword"
              label="Repeat Password"
              type="password"
              variant="outlined"
              value={formik.values.confirmpassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)
              }
              helperText={
                formik.touched.confirmpassword && formik.errors.confirmpassword
              }
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                color={'primary'}
                variant="contained"
                type="submit"
                disabled={
                  !formik.dirty ||
                  formik.values.password !== formik.values.confirmpassword
                }
              >
                Activate
              </Button>
            </div>
            {_renderPasswordChecker()}
          </form>
        </div>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(AgencyActivateForm)
