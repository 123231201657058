import React, { useState, useEffect } from 'react';

import Close from '@material-ui/icons/Close';

import BedIcon from '@material-ui/icons/Hotel';
import ParkingIcon from '@material-ui/icons/DriveEta';

import { PRICE_RANGE } from '../../../constants/constants';
import DynamicIcons from "../DynamicIcons";

import { withStyles, Modal, Typography, IconButton } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: theme.spacing(2)
    },
    propertyInfo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    customIcon: {
        color: '#000',
        width: 18,
        height: 18,
        marginRight: 3
    },
    icon: {
        color: '#000',
        width: 18,
        height: 18,
        marginRight: 3
    },
    caption: {
        width: '100%',
    },
    addressContainer: {
        marginLeft: 10
    }
});

const StyledText = withStyles({
    root: {
        textAlign: 'left',
        color: '#4D4D4D',
        fontSize: '14px',
        lineHeight: '1.19',
    },
})(Typography);

const StyledTextCaption = withStyles({
    root: {
        textAlign: 'left',
        color: '#4D4D4D',
        fontSize: '12px',
        lineHeight: '1.19',
    },
})(Typography);

const SavedPropertyInfo = props => {
    const {
        classes,
        filters,
        suburbs,
        path,
    } = props;

    function numberWithCommas(num) {
        if (num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return 0;
        }
    }

    function uppercase(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const priceString = (price, rangedPrice) => {
        switch (true) {
            case (price && price.min === 0 && price.max === 200) || (price && price.min === PRICE_RANGE[0] && price.max === PRICE_RANGE[1]):
                return "Any"
                break;

            case (price && price.min <= 200 && price.min !== 0 && price.max <= 200 && rangedPrice):
                return "Within $" + numberWithCommas(rangedPrice.min) + '- $' + numberWithCommas(rangedPrice.max)
                break;

            case price && price.min > 200 && price.min === PRICE_RANGE[0] && price.max > 200 && price.max !== PRICE_RANGE[1]:
                return "Below $" + numberWithCommas(price.max)
                break;
            case price && price.min === 0 && price.max < 200 && rangedPrice:
                return "Below $" + numberWithCommas(rangedPrice.max)
                break;
            default:
                return "Above $" + numberWithCommas(price.max)
        }
    }

    const rentalPriceString = (price) => {

        switch (true) {
            case price && price.min === 250 && price.max === 5000:
                return "Any"
                break;

            case (price && price.min <= 5000 && price.min !== 250 && price.max <= 5000):
                return "Within $" + numberWithCommas(price.min) + '- $' + numberWithCommas(price.max)
                break;
            case price && price.min === 250 && price.max < 5000:
                return "Below $" + numberWithCommas(price.max)
                break;
            default:
                return "Above $" + numberWithCommas(price.max)
        }
    }

    const formatValues = (data) => {
        if (data) {
            return data.value + (data.exactMatch === false && data.value !== 0 ? "+" : "")
        } else {
            return "∞"
        }
    }

    return (
        <>
            <div className={classes.root}>
                <div>
                    <div className={classes.caption}>
                        <StyledText variant="body2">
                            {
                                path === 'buy' &&
                                <>
                                    {uppercase(path) + ' | ' + priceString(filters.filters.propertyDetails.price, filters.filters.propertyDetails.rangedPrice)}
                                </>
                            }
                            {
                                path === 'rent' &&
                                <>
                                    {uppercase(path) + ' | ' + rentalPriceString(filters.filters.propertyDetails.priceRent, filters.filters.propertyDetails.priceRent)}
                                </>
                            }
                        </StyledText>
                    </div>
                    <div className={classes.propertyInfo}>
                        <IconButton onClick={() => ''} disableRipple>
                            <BedIcon className={classes.icon} />
                            <Typography variant="caption" color="textSecondary">
                                {formatValues(filters?.filters?.propertyDetails?.bedrooms)}
                            </Typography>
                        </IconButton>
                        <IconButton onClick={() => ''} disableRipple>
                            <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
                            <Typography variant="caption" color="textSecondary">
                                {formatValues(filters?.filters?.propertyDetails?.bathrooms)}
                            </Typography>
                        </IconButton>
                        <IconButton onClick={() => ''} disableRipple>
                            <ParkingIcon className={classes.icon} />
                            <Typography variant="caption" color="textSecondary">
                                {formatValues(filters?.filters?.propertyDetails?.carparking)}
                            </Typography>
                        </IconButton>
                    </div>
                </div>
                <div className={classes.addressContainer}>
                    <StyledTextCaption variant="caption">
                        {
                            filters.includeNearby ?
                                suburbs + ' including surrounding suburbs.'
                                :
                                suburbs
                        }
                    </StyledTextCaption>
                </div>
            </div>
        </>
    );
}

export default withStyles(styles)(SavedPropertyInfo);