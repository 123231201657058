import React, { forwardRef, useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import getDayOfYear from 'date-fns/getDayOfYear';

import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import Image from 'material-ui-image';

import AgentBanner from 'components/Web/Property/AgentBanner';
import PropertyInfo from 'components/Web/Property/PropertyInfo';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import styled from 'styled-components';
import _ from 'lodash';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LogoPlaceholder from 'assets/images/logo-placeholder.png';
import ReloadIcon from 'assets/images/reload.svg';

import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  DEFAULT_AGENCIES,
  cloudinaryCardTransforms,
  cloudinaryImageHeightSmall,
} from '../../../constants/constants';

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils';
import { Box, Button, Chip, withStyles, Card, CardContent, Typography, IconButton, Grid } from '@material-ui/core';
import { requestAgencySetPauseProperty, requestDELETEAPIAgency } from 'store/api';
import ConfirmDialog from '../CustomDialogs/ConfirmDialog';

const CarouselWrapper = styled.div`
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  position: relative;
`;

const OverlayReload = styled.div`
  position: absolute;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    display: block;
    text-align: center;
    text-decoration: none;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    pointer-events: none;
    width: 2em !important;
  }
  p {
    color: #fff;
    pointer-events: none;
    margin-top: 5px;
  }
`;

const styles = (theme) => ({
  paddedRoot: {
    padding: 40,
  },
  cardFull: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    // borderRadius: 0,
    minHeight: 364,
    // minHeight:435,
    width: '100%',
    backgroundColor: '#FFF',
  },
  cardPadded: {
    width: '100%',
    maxHeight: '364',
    backgroundColor: '#FFF',
  },
  grid: {
    // borderRadius: 0,
    // minHeight:435,
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    transition: 'all 0.03s ease',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    },
  },
  cardPaddedSwipe: {
    margin: 40,
    // width: window.innerWidth - (theme.spacing(3) * 2),
    // height: '35.125em',
    width: 350,
    // minHeight: 425,
    backgroundColor: '#FFF',
    fontSize: '12px',
    paddingBottom: 10,
    '@media (max-width: 1024px)': {
      minHeight: 400,
    },
  },
  media: {
    width: '100%',
    height: '210px',
    objectFit: 'cover !important',
  },
  favoriteContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
  tagContainer: {
    position: 'relative',
    cursor: 'pointer',
    pointerEvents: 'auto',
    '& > button + div': {
      paddingTop: 'calc(50%) !important',
    },
  },
  tagTypeText: {
    color: '#FFF',
    fontSize: 13, // previously 1.25em
  },
  tagTypeAuction: {
    minWidth: '13.929em',
    // minHeight: '1.786em',
    backgroundColor: 'rgba(6, 18, 82, 0.8)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.375, 2),
  },
  tagTypeComingSoon: {
    minWidth: 125,
    maxWidth: 125,
    backgroundColor: 'rgba(253, 189, 38, 0.9)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 0',
    textAlign: 'center',
  },
  tagTypeOffMarket: {
    minWidth: 125,
    maxWidth: 125,
    backgroundColor: '#F50057',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 0',
    textAlign: 'center',
  },
  textLabelCloseTo: {
    fontSize: '0.875em',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  chipText: {
    fontSize: 10,
  },
  chipIcon: {
    width: 10,
    height: 10,
  },
  dots: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    '.BrainhubCarousel__dot': {
      padding: '20px 5px',
    },
  },
  rowContainerCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  rowContainerEven: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  chipContainer: {
    minWidth: '3.750em',
    height: '1.300em',
    paddingRight: '0.425em',
    paddingLeft: '0.425em',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    border: ' 0.5px solid #35C0CA',
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    marginBottom: '0.425em',
    marginRight: '0.125em',
  },
  infoIconContainer: {
    zIndex: 100,
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 0,
    cursor: 'pointer',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    backgroundColor: theme.palette.primary.main,
    transition: 'opacity 0.3s ease',
    borderRadius: 20,
    fontSize: 12,
    padding: '3px 5px',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#FFF',
      opacity: 0.8,
      color: '#000000',
    },
  },
  infoIcon: {
    width: 30,
    height: 30,
    color: '#57bdc7',
  },
  agencyLogoOverlay: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
    minWidth: 90,
    maxWidth: 150,
    '& > img': {
      maxWidth: 135,
    },
  },
  cardContentBase: {
    padding: theme.spacing(1.2),
  },
  newListingLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.375, 2),
    // maxWidth: 112,
    // minWidth: 112,
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: '#ef6631',
    textAlign: 'center',
    color: '#fff',
    padding: '1px 16px',
    '& > p': {
      fontWeight: 300,
      lineHeight: '1.55',
    },
  },
});

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 30px)',
  width: 60,
  height: 60,
  cursor: 'pointer',
};

const iconStyles = {
  width: '100%',
  height: '100%',
};

const logoObject = LOGO_OBJECT;

function handlePropertyStatus(type, data) {
  if (type === 'agency') {
    if (data.isApprovedByAgency) return 'isAgencyApproved';
    if (!data.isApprovedByAgency) return 'isAgencyPending';
  }
  if (type === 'vendor') {
    if (data.isApprovedByVendor) return 'isVendorApproved';
    if (!data.isApprovedByVendor && data.vendor.remarks) return 'isVendorRejected';
    if (!data.isApprovedByVendor) return 'isVendorPending';
  }
  if (type === 'soi') {
    if (data.statementOfIntent === null || data.statementOfIntent === '') return 'soiPending';
  }
}

const getAgencyLogo = (agencyName) => {
  if (
    logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
    logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
  ) {
    return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)];
  } else {
    return LogoPlaceholder;
  }
};

const PropertyStatusChip = ({ type }) => {
  let ChipContent;

  switch (type) {
    case 'isVendorPending':
      ChipContent = (
        <Chip
          style={{
            backgroundColor: '#e0e0e',
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Montserrat, sans',
          }}
          size="small"
          label="Vendor Pending"
        />
      );
      break;

    case 'isVendorApproved':
      ChipContent = (
        <Chip
          style={{
            backgroundColor: '#4ce2a4',
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Montserrat, sans',
          }}
          size="small"
          label="Vendor Approved"
        />
      );
      break;

    case 'isVendorRejected':
      ChipContent = (
        <Chip
          style={{
            backgroundColor: '#ef7070',
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Montserrat, sans',
          }}
          size="small"
          label="Vendor Rejected"
        />
      );
      break;

    case 'isAgencyApproved':
      ChipContent = (
        <Chip
          style={{
            backgroundColor: '#38bfc9',
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Montserrat, sans',
          }}
          size="small"
          label="Published"
        />
      );
      break;

    case 'isAgencyPending':
      ChipContent = (
        <Chip
          style={{
            backgroundColor: '#e0e0e',
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Montserrat, sans',
          }}
          size="small"
          label="Agency Review Pending"
        />
      );
      break;

    // case 'soiPending':
    //   ChipContent = (
    //     <Chip
    //       style={{
    //         backgroundColor: '#ffffff',
    //         color: '#EC0000',
    //         fontWeight: 'bold',
    //         fontFamily: 'Montserrat, sans',
    //       }}
    //       size="small"
    //       label="SOI Pending"
    //     />
    //   );
    //   break;

    default:
      ChipContent = null;
  }

  return <Box margin="4px">{ChipContent}</Box>;
};

const PropertyCard = forwardRef((props, ref) => {
  const { classes, isFullWidth, property, setCurrentProperty, cardType, listingType, key, isAgencyPropertiesGridCard } =
    props;

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [value, setValue] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  const [imgError, setImgError] = useState(false);
  const [carouselOffset, setCarouselOffset] = useState(3);

  const [heartActionError, setHeartActionError] = useState(false);
  const handleCarouselChange = (e) => {
    setCarouselOffset(e + 3);
  };

  const [galleryImages, setGalleryImages] = useState(null);

  const showNewListingLabel = () => {
    const publishedDate = new Date(property.createdAt);
    const publishedDayOfYear = getDayOfYear(publishedDate);
    const publishedYear = publishedDate.getFullYear();
    const currentDayOfYear = getDayOfYear(new Date());
    const currentYear = new Date().getFullYear();
    if (publishedYear >= currentYear) {
      if (publishedDayOfYear + 3 >= currentDayOfYear) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (property && property.images && property.images.length > 0) {
      let gallery_array = [];
      if (property.cloudinaryImages && property.cloudinaryImages.length > 0) {
        property.cloudinaryImages.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageHeightSmall + '/' + item.file;
          gallery_array.push(file);
        });
      } else {
        gallery_array = _.compact(property.images);
      }

      setGalleryImages(gallery_array);
    }
  }, [property]);

  function renderTypeTag(type, property) {
    let propertyStatus = type.toLowerCase().replace(/[^\w\s]/gi, '');
    let isForRent = property.forRent ? true : false;
    let conditionCheck = propertyStatus + ':' + isForRent;
    const auctionDate = property.auctionDate ? moment(property.auctionDate).format('Do MMMM YYYY') : '';
    const soldDate = property.soldDate ? moment(property.soldDate).format('Do MMMM YYYY') : '';
    switch (conditionCheck) {
      case 'onsale:false':
        return (
          auctionDate && (
            <div className={classes.tagTypeAuction}>
              <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
                {'Auction ' + auctionDate}
              </Typography>
            </div>
          )
        );
      case 'current:false':
        return (
          auctionDate && (
            <div className={classes.tagTypeAuction}>
              <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
                {'Auction ' + auctionDate}
              </Typography>
            </div>
          )
        );
      case 'sold:false':
        return (
          soldDate && (
            <div className={classes.tagTypeAuction}>
              <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
                {'Sold ' + soldDate}
              </Typography>
            </div>
          )
        );
      case 'comingsoon:false':
        return (
          <div className={classes.tagTypeComingSoon}>
            <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
              Coming Soon
            </Typography>
          </div>
        );
      case 'offmarket:false':
        return (
          <div className={classes.tagTypeOffMarket}>
            <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
              Off Market
            </Typography>
          </div>
        );
    }
  }

  const reloadImages = () => {
    let pi = Object.assign([], galleryImages);
    let newPi = [];
    _.each(pi, (item, index) => {
      if (item.split('?').length) {
        newPi.push(item.split('?')[0] + '?t=' + Date.now());
      } else {
        newPi.push(item + '?t=' + Date.now());
      }
    });
    setGalleryImages(newPi);
    setImgError(false);
  };

  const showUpdatedListingLabel = () => {
    const publishedDate = new Date(property.publishDate);
    const publishedDayOfYear = getDayOfYear(publishedDate);
    const publishedYear = publishedDate.getFullYear();
    const currentDayOfYear = getDayOfYear(new Date());
    const currentYear = new Date().getFullYear();
    if (publishedYear >= currentYear) {
      if (publishedDayOfYear + 3 >= currentDayOfYear) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  function renderForListCard() {
    return (
      <div className={isFullWidth ? '' : classes.paddedRoot} style={props.style ?? {}}>
        <Card elevation={1} className={isFullWidth ? classes.cardFull : classes.cardPadded}>
          <div className={classes.tagContainer}>
            {property && property.propertyStatus !== 'offmarket' && galleryImages && galleryImages.length > 1 ? (
              <CarouselWrapper>
                {imgError && (
                  <OverlayReload>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        reloadImages();
                      }}
                    >
                      <img src={ReloadIcon} />
                      <p>Load image</p>
                    </a>
                  </OverlayReload>
                )}
                <Carousel
                  showArrows={true}
                  showIndicators={false}
                  showStatus={false}
                  infiniteLoop={false}
                  showThumbs={false}
                  useKeyboardArrows={false}
                  autoPlay={false}
                  swipeable={true}
                  emulateTouch={true}
                  onChange={handleCarouselChange}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 5, color: '#fff' }}
                      >
                        <ChevronLeftIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 5, color: '#fff' }}
                      >
                        <ChevronRightIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  onSwipeMove={() => {
                    if (!isSwiping) {
                      setIsSwiping(true);
                    }
                  }}
                  onSwipeEnd={() => {
                    if (isSwiping) {
                      let st = setTimeout(() => {
                        setIsSwiping(false);
                        clearTimeout(st);
                      }, 50);
                    }
                  }}
                >
                  {!imgError ? (
                    galleryImages.map((img, index) => {
                      const indexRef = index + 1;
                      if (img) {
                        return (
                          <Fragment key={index}>
                            <Image
                              style={{ width: '100%' }}
                              key={index}
                              className={classes.media}
                              src={
                                indexRef <= carouselOffset
                                  ? propertyConfigSetter('image-banner-card', {
                                      type: property.propertyStatus,
                                      image: img,
                                    })
                                  : null
                              }
                              // onClick={() => selectProperty()}
                              aspectRatio={16 / 9}
                              disableSpinner
                              onLoad={() => {
                                setImgError(false);
                              }}
                              onError={() => {
                                setImgError(true);
                              }}
                            />
                          </Fragment>
                        );
                      }
                    })
                  ) : (
                    <>
                      <Image
                        style={{ width: '100%' }}
                        className={classes.media}
                        src={propertyConfigSetter('image-banner-card', {
                          type: property.propertyStatus,
                          image: null,
                        })}
                        // onClick={() => selectProperty()}
                        aspectRatio={16 / 9}
                        disableSpinner
                      />
                    </>
                  )}
                </Carousel>
              </CarouselWrapper>
            ) : property && property.propertyStatus === 'offmarket' && galleryImages && galleryImages.length > 0 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: property.propertyStatus,
                  image: galleryImages[0],
                })}
                // onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : property && galleryImages && galleryImages.length === 1 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: property.propertyStatus,
                  image: galleryImages[0],
                })}
                // onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : (
              <div></div>
            )}
            {property.propertyStatus && renderTypeTag(property.propertyStatus, property)}
          </div>
          {
            <AgentBanner
              type={'search'}
              properties={property}
              {...propertyConfigSetter('agent', property?.subscriptionType)}
            />
          }
          <CardContent className={classes.cardContentBase}>
            <PropertyInfo
              type={'search'}
              properties={property}
              matchCount={() => null}
              listingType={listingType}
              filters={props.filters}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  function renderForGridCard() {
    return (
      <Grid item sm={6} md={4} lg={3} onClick={props.onClick} style={props.style ?? {}}>
        <Card elevation={1} className={classes.grid}>
          <div className={classes.tagContainer}>
            {property && property.propertyStatus !== 'offmarket' && galleryImages && galleryImages.length > 1 ? (
              <CarouselWrapper>
                {imgError && (
                  <OverlayReload>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        reloadImages();
                      }}
                    >
                      <img src={ReloadIcon} />
                      <p>Load image</p>
                    </a>
                  </OverlayReload>
                )}
                <Carousel
                  showArrows={true}
                  showIndicators={false}
                  showStatus={false}
                  infiniteLoop={false}
                  showThumbs={false}
                  useKeyboardArrows={false}
                  autoPlay={false}
                  swipeable={true}
                  emulateTouch={true}
                  onChange={handleCarouselChange}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 5, color: '#fff' }}
                      >
                        <ChevronLeftIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 5, color: '#fff' }}
                      >
                        <ChevronRightIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  onSwipeMove={() => {
                    if (!isSwiping) {
                      setIsSwiping(true);
                    }
                  }}
                  onSwipeEnd={() => {
                    if (isSwiping) {
                      let st = setTimeout(() => {
                        setIsSwiping(false);
                        clearTimeout(st);
                      }, 50);
                    }
                  }}
                >
                  {!imgError ? (
                    galleryImages.map((img, index) => {
                      const indexRef = index + 1;
                      if (img) {
                        return (
                          <Fragment key={index}>
                            <Image
                              style={{ width: '100%' }}
                              key={index}
                              className={classes.media}
                              src={
                                indexRef <= carouselOffset
                                  ? propertyConfigSetter('image-banner-card', {
                                      type: property.propertyStatus,
                                      image: img,
                                    })
                                  : null
                              }
                              // onClick={() => selectProperty()}
                              aspectRatio={16 / 9}
                              disableSpinner
                              onLoad={() => {
                                setImgError(false);
                              }}
                              onError={() => {
                                setImgError(true);
                              }}
                            />
                          </Fragment>
                        );
                      }
                    })
                  ) : (
                    <>
                      <Image
                        style={{ width: '100%' }}
                        className={classes.media}
                        src={propertyConfigSetter('image-banner-card', {
                          type: property.propertyStatus,
                          image: null,
                        })}
                        // onClick={() => selectProperty()}
                        aspectRatio={16 / 9}
                        disableSpinner
                      />
                    </>
                  )}
                </Carousel>
              </CarouselWrapper>
            ) : property && property.propertyStatus === 'offmarket' && galleryImages && galleryImages.length > 0 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: property?.propertyStatus,
                  image: galleryImages[0],
                })}
                // onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : property && galleryImages && galleryImages.length === 1 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: property?.propertyStatus,
                  image: galleryImages[0],
                })}
                // onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : (
              <div></div>
            )}

            {property.propertyStatus && renderTypeTag(property.propertyStatus, property)}

            {property && showNewListingLabel() && (
              <div className={classes.newListingLabel}>
                <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
                  New
                </Typography>
              </div>
            )}

            {property && showUpdatedListingLabel() && !showNewListingLabel() && (
              <div className={classes.newListingLabel}>
                <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
                  Updated
                </Typography>
              </div>
            )}
          </div>
          {
            <AgentBanner
              type={'search'}
              properties={property}
              {...propertyConfigSetter('agent', property?.subscriptionType)}
            />
          }
          <CardContent className={classes.cardContentBase}>
            <PropertyInfo
              type={'search'}
              properties={property}
              matchCount={() => null}
              listingType={listingType}
              filters={props.filters}
            />
          </CardContent>
        </Card>
      </Grid>
    );
  }

  const RenderForAgencyPropertyGridCard = () => {
    const [isPaused, setIsPaused] = useState(property.isPaused);

    const dispatch = useDispatch();

    const pausePropertyAPI = () => {
      let request = {
        query: null,
        data: { property: property._id, is_paused: !property.isPaused },
      };
      dispatch(requestAgencySetPauseProperty(request, 'AGENCY_PAUSE_PROPERTY'));
    };

    const handlePauseButtonOnClick = () => {
      setIsPaused((prev) => !prev);
      pausePropertyAPI();
    };

    const deletePropertyAPI = () => {
      let request = {
        query: `/${property._id}`,
        data: null,
      };
      dispatch(requestDELETEAPIAgency(request, 'AGENCY_DELETE_PROPERTY'));
    };

    const handleDeleteProperty = (data) => {
      setConfirmDelete(false);
      deletePropertyAPI();
    };

    return (
      <Grid item xs={12} sm={12} md={6} lg={4} xl={3} style={props.style ?? {}}>
        <Card onClick={props.onClick} elevation={1} className={classes.grid}>
          <Box className={classes.tagContainer}>
            {property.addressState === 'VIC' && property.propertyStatus === 'comingsoon' && (
              <Box position="absolute" zIndex={2} top={10} right={10}>
                <PropertyStatusChip classes={classes} type={handlePropertyStatus('soi', property)} />
              </Box>
            )}
            {/* 
            {property &&
            property.propertyStatus !== 'offmarket' &&
            galleryImages &&
            galleryImages.length > 1 ? (
              <CarouselWrapper>
                {imgError && (
                  <OverlayReload>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        reloadImages()
                      }}
                    >
                      <img src={ReloadIcon} />
                      <p>Load image</p>
                    </a>
                  </OverlayReload>
                )}
                <Carousel
                  showArrows={true}
                  showIndicators={false}
                  showStatus={false}
                  infiniteLoop={false}
                  showThumbs={false}
                  useKeyboardArrows={false}
                  autoPlay={false}
                  swipeable={true}
                  emulateTouch={true}
                  onChange={handleCarouselChange}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 5, color: '#fff' }}
                      >
                        <ChevronLeftIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 5, color: '#fff' }}
                      >
                        <ChevronRightIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  onSwipeMove={() => {
                    if (!isSwiping) {
                      setIsSwiping(true)
                    }
                  }}
                  onSwipeEnd={() => {
                    if (isSwiping) {
                      let st = setTimeout(() => {
                        setIsSwiping(false)
                        clearTimeout(st)
                      }, 50)
                    }
                  }}
                >
                  {!imgError ? (
                    galleryImages.map((img, index) => {
                      const indexRef = index + 1
                      if (img) {
                        return (
                          <Fragment key={index}>
                            <Image
                              style={{ width: '100%' }}
                              key={index}
                              className={classes.media}
                              src={
                                indexRef <= carouselOffset
                                  ? propertyConfigSetter('image-banner-card', {
                                      type: property.propertyStatus,
                                      image: img,
                                    })
                                  : null
                              }
                              // onClick={() => selectProperty()}
                              aspectRatio={16 / 9}
                              disableSpinner
                              onLoad={() => {
                                setImgError(false)
                              }}
                              onError={() => {
                                setImgError(true)
                              }}
                            />
                          </Fragment>
                        )
                      }
                    })
                  ) : (
                    <>
                      <Image
                        style={{ width: '100%' }}
                        className={classes.media}
                        src={propertyConfigSetter('image-banner-card', {
                          type: property.propertyStatus,
                          image: null,
                        })}
                        // onClick={() => selectProperty()}
                        aspectRatio={16 / 9}
                        disableSpinner
                      />
                    </>
                  )}
                </Carousel>
              </CarouselWrapper>
            ) : property &&
              property.propertyStatus === 'offmarket' &&
              galleryImages &&
              galleryImages.length > 0 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: property?.propertyStatus,
                  image: galleryImages[0],
                })}
                // onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : property && galleryImages && galleryImages.length === 1 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: property?.propertyStatus,
                  image: galleryImages[0],
                })}
                // onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : (
              <div></div>
            )} */}

            {property && galleryImages && galleryImages.length >= 1 && (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: property?.propertyStatus,
                  image: galleryImages[0],
                })}
                // onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            )}

            {property.propertyStatus && renderTypeTag(property.propertyStatus, property)}

            {property && showNewListingLabel() && (
              <div className={classes.newListingLabel}>
                <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
                  New
                </Typography>
              </div>
            )}

            {property && showUpdatedListingLabel() && !showNewListingLabel() && (
              <div className={classes.newListingLabel}>
                <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
                  Updated
                </Typography>
              </div>
            )}
          </Box>
          <AgentBanner
            type={'search'}
            properties={property}
            {...propertyConfigSetter('agent', property?.subscriptionType)}
          />
          <CardContent className={classes.cardContentBase}>
            <PropertyInfo
              type={'search'}
              properties={property}
              matchCount={() => null}
              listingType={listingType}
              filters={props.filters}
            />
          </CardContent>
        </Card>
        <Box marginTop="10px" display="flex" flexDirection="row" alignItems="center">
          <PropertyStatusChip type={handlePropertyStatus('agency', property)} />
          {/*property?.importSource !== 'added_manually_agency' ? (
            <PropertyStatusChip type={handlePropertyStatus('agency', property)} />
          ) : null*/}
        </Box>
        <Box>
          {property?.isApprovedByAgency && property?.isApprovedByVendor && (
            <Button
              onClick={handlePauseButtonOnClick}
              variant="contained"
              style={{
                backgroundColor: isPaused ? '#4ce2a4' : '#ef7070',
                color: 'rgba(0, 0, 0, 0.87)',
                fontFamily: 'Montserrat, sans',
                width: '100%',
                marginTop: '5px',
              }}
            >
              {isPaused ? 'Unpause' : 'Pause'}
            </Button>
          )}
          <Button
            onClick={() => setConfirmDelete(true)}
            variant="contained"
            style={{
              backgroundColor: '#ef7070',
              color: 'rgba(0, 0, 0, 0.87)',
              fontFamily: 'Montserrat, sans',
              width: '100%',
              marginTop: '5px',
            }}
          >
            {'Delete'}
          </Button>
        </Box>
        <ConfirmDialog
          isOpen={confirmDelete}
          confirmCallback={() => handleDeleteProperty(property._id)}
          closeCallback={() => setConfirmDelete(false)}
          showLogo={true}
          align={'center'}
          title={'Delete Property'}
          text={<>Are you sure you want to delete this property?</>}
        />
      </Grid>
    );
  };

  return (
    <Fragment key={key}>
      {cardType === 'list' && renderForListCard()}

      {cardType === 'grid' && !isAgencyPropertiesGridCard && renderForGridCard()}

      {cardType === 'grid' && isAgencyPropertiesGridCard && <RenderForAgencyPropertyGridCard />}
    </Fragment>
  );
});

export default withStyles(styles)(PropertyCard);
