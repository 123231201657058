export function getStatus(state) {
  return state.billing.status
}

export function getErrors(state) {
  return state.billing.error
}

export function getSuccess(state) {
  return state.billing.payload_success
}

export function getAllInvoices(state) {
  return state.billing.billing_invoices
}

export function getAllInvoicesXero(state) {
  return state.billing.invoice_xero
}

export const getPricePackages = (state) => {
  return state.billing?.packages
}

export const getCheckoutDetails = (state) => {
  return state.billing?.checkout
}

export const getClientSecret = (state) => {
  return state.billing?.client_secret
}

export const getPaymentMethodIntent = (state) => {
  return state.billing?.paymentMethodIntent
}

export const getSubscriptionDetails = (state) => {
  return state.billing?.subscription_details
}

export const getPaymentMethods = (state) => {
  return state.billing?.payment_methods
}
