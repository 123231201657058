import { reform } from './filters.utils'
import _ from 'lodash'
import moment from 'moment'
import { PRICE_RANGE } from '../constants/constants'

const sha1 = require('sha1');

export async function formatRequest(type, filters, skipTakeData, requestIndex, cursor) {
  switch (type) {
    case 'buy':
      return await buySoldParameters(filters, skipTakeData, requestIndex, type, cursor)
    case 'off-market':
      return await offMarketParameters(
        filters,
        skipTakeData,
        requestIndex,
        type,
        cursor
      )
    case 'hush-hush':
      return await hushHushParameters(filters, skipTakeData, requestIndex, type, cursor)
    case 'coming-soon':
      return await comingSoonParameters(
        filters,
        skipTakeData,
        requestIndex,
        type,
        cursor
      )
    case 'sold':
      return await buySoldParameters(filters, skipTakeData, requestIndex, type, cursor)
    case 'rent':
      return await rentParameters(filters, skipTakeData, requestIndex, type, cursor)
    case 'perfect-property':
      return await perfectPropertyParameters(filters, skipTakeData, type)
    case 'agent':
      return await searchAgentParameters(filters, skipTakeData)
  }
}

async function handleParams(
  filters,
  skipTakeData,
  requestIndex,
  type,
  cursor,
  customParams
) {
  let current_filters = filters.filters
  let current_suburbs = filters.suburbs
  let current_nearby = filters.includeNearby

  const ALL_NEARBY = current_suburbs
    ?.map((item) => item.nearby ?? false)
    .filter((item) => item !== false)
  const MERGED_NEARBY = [].concat.apply([], ALL_NEARBY)

  let params = {
    nearby_filters: {
      close_to_parks:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[0]
          ? current_filters.advanceProperty[0].active
          : false,
      close_to_shops:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[1]
          ? current_filters.advanceProperty[1].active
          : false,
      close_to_primary_schools:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[2]
          ? current_filters.advanceProperty[2].active
          : false,
      close_to_secondary_schools:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[3]
          ? current_filters.advanceProperty[3].active
          : false,
      close_to_universities:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[4]
          ? current_filters.advanceProperty[4].active
          : false,
      close_to_cafes:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[5]
          ? current_filters.advanceProperty[5].active
          : false,
      close_to_public_transports:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[6]
          ? current_filters.advanceProperty[6].active
          : false,
      foody_area:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[7]
          ? current_filters.advanceProperty[7].active
          : false,
      inner_city_vibe:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[8]
          ? current_filters.advanceProperty[8].active
          : false,
    },
    property_filters: {
      propertyType: await reform('Property_Type', current_filters.propertyType),
      bedrooms: current_filters.propertyDetails.bedrooms,
      bathrooms: current_filters.propertyDetails.bathrooms,
      totalParking: current_filters.propertyDetails.carparking,
      gas: current_filters.features[0].active,
      balcony: current_filters.features[1].active,
      study: current_filters.features[2].active,
      airConditioning: current_filters.features[3].active,
      wardrobe: current_filters.features[4].active,
      courtyard: current_filters.features[5].active,
      swimmingPool: current_filters.features[6].active,
      established:
        current_filters.establishment === null ||
          current_filters.establishment === 'any'
          ? 'any'
          : current_filters.establishment === 'new'
            ? false
            : true,
    },
    range_filters: {
      landSize: {
        min: current_filters.landSize.min,
        max: current_filters.landSize.max,
      },
      price: {
        min:
          (current_filters.propertyDetails.rangedPrice &&
            current_filters.propertyDetails.rangedPrice.min) ??
          PRICE_RANGE[0],
        max:
          (current_filters.propertyDetails.rangedPrice &&
            current_filters.propertyDetails.rangedPrice.max) ??
          PRICE_RANGE[1],
      },
    },
    suburb: await reform('Suburbs', current_suburbs),
    nearby_suburbs: current_nearby,
    nearbyList: MERGED_NEARBY,
    keywords: await reform('Keywords', current_filters.keywords.keyword),
    excludeUnderOffer: current_filters.keywords.offer,
  }

  let newParams = {
    nearby_filters: await reform('All', params.nearby_filters),
    property_filters: await reform('All', params.property_filters),
    range_filters: await reform('All', params.range_filters),
    suburb: await reform('All', params.suburb),
    page: 1,
    perPage: 50,
    skip:
      skipTakeData && requestIndex && skipTakeData.take
        ? skipTakeData.take * requestIndex
        : 0,
    take: 40,
    reference: type,
    countTotal: true,
    cursor: cursor && skipTakeData && requestIndex && skipTakeData.take ? cursor : null,
  }
  /**
   * Other Filters
   **/
  if (newParams.cursor === null) {
    delete newParams['cursor']
  }

  if (params.keywords.length === 0) {
    delete params['keywords']
  }
  if (params.nearby_suburbs === false) {
    delete params['nearby_suburbs']
  }
  if (params.excludeUnderOffer === false) {
    delete params['excludeUnderOffer']
  }
  if (params.property_filters.propertyType.length === 0) {
    delete params.property_filters['propertyType']
  }
  if (
    (params.range_filters.landSize.min === '' &&
      params.range_filters.landSize.max === '') ||
    (params.range_filters.landSize.min === 0 &&
      params.range_filters.landSize.max === 9999999999)
  ) {
    delete params.range_filters['landSize']
  }
  if (
    (params.range_filters.price.min === PRICE_RANGE[0] &&
      params.range_filters.price.max === PRICE_RANGE[1]) ||
    params.range_filters.price.max === 0
  ) {
    delete params.range_filters['price']
  }

  if (
    (params.property_filters.bedrooms &&
      params.property_filters.bedrooms.value === 0 &&
      params.property_filters.bedrooms.exactMatch !== true) ||
    params.property_filters.bedrooms === null ||
    params.property_filters.bedrooms === ''
  ) {
    delete params.property_filters['bedrooms']
  }

  if (
    (params.property_filters.bathrooms &&
      params.property_filters.bathrooms.value === 0 &&
      params.property_filters.bathrooms.exactMatch !== true) ||
    params.property_filters.bathrooms === null ||
    params.property_filters.bathrooms === ''
  ) {
    delete params.property_filters['bathrooms']
  }

  if (
    (params.property_filters.totalParking &&
      params.property_filters.totalParking.value === 0 &&
      params.property_filters.totalParking.exactMatch !== true) ||
    params.property_filters.totalParking === null ||
    params.property_filters.totalParking === ''
  ) {
    delete params.property_filters['totalParking']
  }

  if (params.range_filters.price) {
    if (params.range_filters.price.max === PRICE_RANGE[1]) {
      params.range_filters.price.max = null
    }
  }

  let finalParams = customParams
    ? { ...params, ...newParams, ...customParams }
    : { ...params, ...newParams }
  return finalParams
}

/** BUY & SOLD REQUEST */
async function buySoldParameters(filters, skipTakeData, requestIndex, type, cursor) {
  return handleParams(filters, skipTakeData, requestIndex, type, cursor, null)
}

/* Off Market Parameters */
function offMarketParameters(filters, skipTakeData, requestIndex, type, cursor) {
  const offmarketParams = { property_status: ['offmarket'] }
  return handleParams(
    filters,
    skipTakeData,
    requestIndex,
    type,
    cursor,
    offmarketParams
  )
}

/* Hush Hush Parameters */
function hushHushParameters(filters, skipTakeData, requestIndex, type, cursor) {
  const hushHushParams = { property_status: ['offmarket', 'comingsoon'] }
  return handleParams(filters, skipTakeData, requestIndex, type, cursor, hushHushParams)
}

/* Hush Hush Parameters */
function comingSoonParameters(filters, skipTakeData, requestIndex, type, cursor) {
  const comingSoonParams = { property_status: ['comingsoon'] }
  return handleParams(
    filters,
    skipTakeData,
    requestIndex,
    type,
    cursor,
    comingSoonParams
  )
}

/** RENT REQUEST */
async function rentParameters(filters, skipTakeData, requestIndex, type, cursor) {
  let current_filters = filters.filters
  let current_suburbs = filters.suburbs
  let current_nearby = filters.includeNearby

  const ALL_NEARBY = current_suburbs
    ?.map((item) => item.nearby ?? false)
    .filter((item) => item !== false)
  const MERGED_NEARBY = [].concat.apply([], ALL_NEARBY)

  let params = {
    nearby_filters: {
      close_to_parks:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[0]
          ? current_filters.advanceProperty[0].active
          : false,
      close_to_shops:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[1]
          ? current_filters.advanceProperty[1].active
          : false,
      close_to_primary_schools:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[2]
          ? current_filters.advanceProperty[2].active
          : false,
      close_to_secondary_schools:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[3]
          ? current_filters.advanceProperty[3].active
          : false,
      close_to_universities:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[4]
          ? current_filters.advanceProperty[4].active
          : false,
      close_to_cafes:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[5]
          ? current_filters.advanceProperty[5].active
          : false,
      close_to_public_transports:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[6]
          ? current_filters.advanceProperty[6].active
          : false,
      foody_area:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[7]
          ? current_filters.advanceProperty[7].active
          : false,
      inner_city_vibe:
        current_filters &&
          current_filters.advenceProperty &&
          current_filters.advenceProperty[8]
          ? current_filters.advanceProperty[8].active
          : false,
    },
    property_filters: {
      propertyType: await reform('Property_Type', current_filters.propertyType),
      bedrooms: current_filters.propertyDetails.bedrooms,
      bathrooms: current_filters.propertyDetails.bathrooms,
      totalParking: current_filters.propertyDetails.carparking,
      gas: current_filters.features[0].active,
      balcony: current_filters.features[1].active,
      study: current_filters.features[2].active,
      airConditioning: current_filters.features[3].active,
      wardrobe: current_filters.features[4].active,
      courtyard: current_filters.features[5].active,
      // "laundry": current_filters.features[6].active,
      swimmingPool: current_filters.features[6].active,
      established:
        current_filters.establishment === null ||
          current_filters.establishment === 'any'
          ? 'any'
          : current_filters.establishment === 'new'
            ? false
            : true,
      availableDate: current_filters.availableDate,
    },
    range_filters: {
      landSize: {
        min: current_filters.landSize.min,
        max: current_filters.landSize.max,
      },
    },
    suburb: await reform('Suburbs', current_suburbs),
    nearby_suburbs: current_nearby,
    keywords: await reform('Keywords', current_filters.keywords.keyword),
    excludeUnderOffer: current_filters.keywords.offer,
  }

  let newParams = {
    nearby_filters: await reform('All', params.nearby_filters),
    property_filters: await reform('All', params.property_filters),
    /** place rental filters here */
    rental_filters: {
      rentalDateAvailable: current_filters.availableDate
        ? moment(current_filters.availableDate).format('YYYY-MM-DD')
        : null,
      rentalPrice: {
        min: current_filters.propertyDetails.priceRent.min,
        max: current_filters.propertyDetails.priceRent.max,
      },
      furnished: current_filters.furniture
        ? current_filters.furniture === 'furnished'
          ? true
          : false
        : null,
    },
    range_filters: await reform('All', params.range_filters),
    suburb: await reform('All', params.suburb),
    page: 1,
    perPage: 50,
    skip:
      skipTakeData && requestIndex && skipTakeData.take
        ? skipTakeData.take * requestIndex
        : 0,
    take: 40,
    reference: type,
    countTotal: true,
    nearbyList: MERGED_NEARBY,
  }

  /**
   * Other Filters
   **/
  if (newParams.cursor === null) {
    delete newParams['cursor']
  }

  if (params.keywords.length === 0) {
    delete params['keywords']
  }
  if (params.nearby_suburbs === false) {
    delete params['nearby_suburbs']
  }
  if (params.excludeUnderOffer === false) {
    delete params['excludeUnderOffer']
  }
  if (params.property_filters.propertyType.length === 0) {
    delete params.property_filters['propertyType']
  }
  if (
    (params.range_filters.landSize.min === '' &&
      params.range_filters.landSize.max === '') ||
    (params.range_filters.landSize.min === 0 &&
      params.range_filters.landSize.max === 9999999999)
  ) {
    delete params.range_filters['landSize']
  }

  if (
    newParams.rental_filters.rentalPrice.min === 250 &&
    newParams.rental_filters.rentalPrice.max === 5000
  ) {
    delete newParams.rental_filters['rentalPrice']
  }

  if (
    newParams.rental_filters.furnished === null ||
    current_filters?.furniture === 'any'
  ) {
    delete newParams.rental_filters['furnished']
  }

  if (newParams.rental_filters.rentalDateAvailable === null) {
    delete newParams.rental_filters['rentalDateAvailable']
  }

  if (params.availableDate === null) {
    delete params['availableDate']
  }

  if (
    (params.property_filters.bedrooms &&
      params.property_filters.bedrooms.value === 0 &&
      params.property_filters.bedrooms.exactMatch !== true) ||
    params.property_filters.bedrooms === null ||
    params.property_filters.bedrooms === ''
  ) {
    delete params.property_filters['bedrooms']
  }

  if (
    (params.property_filters.bathrooms &&
      params.property_filters.bathrooms.value === 0 &&
      params.property_filters.bathrooms.exactMatch !== true) ||
    params.property_filters.bathrooms === null ||
    params.property_filters.bathrooms === ''
  ) {
    delete params.property_filters['bathrooms']
  }

  if (
    (params.property_filters.totalParking &&
      params.property_filters.totalParking.value === 0 &&
      params.property_filters.totalParking.exactMatch !== true) ||
    params.property_filters.totalParking === null ||
    params.property_filters.totalParking === ''
  ) {
    delete params.property_filters['totalParking']
  }

  let finalParams = { ...params, ...newParams }
  return finalParams
}

/** PERFECT PROPERTY REQUEST */
async function perfectPropertyParameters(filters) {
  let current_filters = filters.filters
  let current_perfectProperty = filters.perfectProperty

  let state = ''
  if (current_perfectProperty && current_perfectProperty.state) {
    _.filter(current_perfectProperty.state, function (o) {
      if (o.active) {
        state = o.title
        return o
      }
    })
  }

  let distanceFromKmData = current_filters.distanceFromKm
  let distanceFromKmParams =
    distanceFromKmData && distanceFromKmData.near
      ? distanceFromKmData.near.filter((i) => i.active)
      : null
  const nearKms = distanceFromKmParams
    ? distanceFromKmParams.map((i) =>
      i.label
        .trim()
        .toLowerCase()
        .replace(/([^A-Z0-9]+)(.)/gi, function (match) {
          return arguments[2].toUpperCase()
        })
    )
    : null

  let params = {
    state: state,
    nearby_filters: {
      close_to_parks:
        current_filters &&
          current_filters.advanceProperty &&
          current_filters.advanceProperty[0]
          ? current_filters.advanceProperty[0].active
          : false,
      close_to_shops:
        current_filters &&
          current_filters.advanceProperty &&
          current_filters.advanceProperty[1]
          ? current_filters.advanceProperty[1].active
          : false,
      close_to_primary_schools:
        current_filters &&
          current_filters.advanceProperty &&
          current_filters.advanceProperty[2]
          ? current_filters.advanceProperty[2].active
          : false,
      close_to_secondary_schools:
        current_filters &&
          current_filters.advanceProperty &&
          current_filters.advanceProperty[3]
          ? current_filters.advanceProperty[3].active
          : false,
      close_to_cafes:
        current_filters &&
          current_filters.advanceProperty &&
          current_filters.advanceProperty[4]
          ? current_filters.advanceProperty[4].active
          : false,
      close_to_public_transports:
        current_filters &&
          current_filters.advanceProperty &&
          current_filters.advanceProperty[5]
          ? current_filters.advanceProperty[5].active
          : false,
      inner_city_vibe:
        current_filters &&
          current_filters.advanceProperty &&
          current_filters.advanceProperty[6]
          ? current_filters.advanceProperty[6].active
          : false,
    },
    property_filters: {
      propertyType: await reform('Property_Type', current_filters.propertyType),
      bedrooms: current_filters.propertyDetails.bedrooms,
      bathrooms: current_filters.propertyDetails.bathrooms,
      totalParking: current_filters.propertyDetails.carparking,
    },
    range_filters: {
      price: {
        min:
          (current_filters.propertyDetails.rangedPrice &&
            current_filters.propertyDetails.rangedPrice.min) ??
          PRICE_RANGE[0],
        max:
          (current_filters.propertyDetails.rangedPrice &&
            current_filters.propertyDetails.rangedPrice.max) ??
          PRICE_RANGE[1],
      },
    },
    countTotal: true,
    take: 50,
    skip: 0,
  }

  if (
    distanceFromKmData &&
    nearKms &&
    nearKms.length > 0 &&
    distanceFromKmData.value &&
    distanceFromKmData.value.min >= 0 &&
    distanceFromKmData.value.max <= 500
  ) {
    const kmsObj = {}
    nearKms.map((i) => {
      kmsObj[i] = distanceFromKmData.value
    })
    params.property_filters = { ...params.property_filters, distanceFromKm: kmsObj }
  }

  if (
    (params.property_filters.bedrooms &&
      params.property_filters.bedrooms.value === 0 &&
      params.property_filters.bedrooms.exactMatch !== true) ||
    params.property_filters.bedrooms === null ||
    params.property_filters.bedrooms === ''
  ) {
    delete params.property_filters['bedrooms']
  }

  if (
    (params.property_filters.bathrooms &&
      params.property_filters.bathrooms.value === 0 &&
      params.property_filters.bathrooms.exactMatch !== true) ||
    params.property_filters.bathrooms === null ||
    params.property_filters.bathrooms === ''
  ) {
    delete params.property_filters['bathrooms']
  }

  if (
    (params.property_filters.totalParking &&
      params.property_filters.totalParking.value === 0 &&
      params.property_filters.totalParking.exactMatch !== true) ||
    params.property_filters.totalParking === null ||
    params.property_filters.totalParking === ''
  ) {
    delete params.property_filters['totalParking']
  }

  let finalParams = { ...params }
  return finalParams
}
/** PERFECT PROPERTY REQUEST */
async function searchAgentParameters(filters) {
  let current_filters = filters
  let params = {
    search: current_filters.keyword,
  }

  let finalParams = { ...params }
  return finalParams
}

export function paramsToBase64(params) {
  const base64 = Buffer.from(JSON.stringify(params)).toString('base64')
  return base64
}

export function paramsToSha1(params) {
  return sha1(JSON.stringify(params))
}