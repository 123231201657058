import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Bathrooms from '../../Common/GlobalFilters/Bathrooms'
import Bedrooms from '../../Common/GlobalFilters/Bedrooms'
import Carparking from '../../Common/GlobalFilters/Carparking'
import PriceSlider from '../../Common/GlobalFilters/PriceSlider'

const Container = styled.div`
  padding: 20px 0;
`

const SliderContainerV2 = ({ path }) => {
  return (
    <Container>
      <PriceSlider path={path} />
      <Bedrooms />
      <Bathrooms />
      <Carparking />
    </Container>
  )
}

export default SliderContainerV2
