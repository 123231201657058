import React, { useEffect } from "react";
import styled from 'styled-components'

import { withStyles, FormControl, Select, MenuItem } from '@material-ui/core';

const Container = styled.div`
  position: relative;
  z-index: 5;
  margin-right: 20px;
`

const StyledFormControl = withStyles({
  root: {
    width: '30vw',
    backgroundColor: '#FFF',
    borderRadius: 4,
  }
})(FormControl);

const StyledSelect = withStyles({
  root: {
    padding: '18px',
    alignItems: 'center',
  }
})(Select);

const StyledMenuItem = withStyles({
  root: {
    textAlign: 'left',
  },
})(MenuItem);

const CustomSelect = props => {

  const [value, setValue] = React.useState(props.value || 'select');

  useEffect(() => {
    if (props.isClearFilter) {
      setValue('')
    }
  }, [props.isClearFilter])

  const handleChange = (event) => {
    setValue(event.target.value);
    props.handleCategory(event.target.value)
  };

  return (
    <Container>
      <StyledFormControl variant="filled" >
        <StyledSelect
          value={value}
          onChange={handleChange}
        >
          {
            props.options && props.options.map((item, index) => {
              return (
                <StyledMenuItem value={item.value} key={index}>
                  {item.label}
                </StyledMenuItem>
              )
            })
          }
        </StyledSelect>
      </StyledFormControl>
    </Container>
  );
};

export default CustomSelect;