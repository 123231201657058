import React, { useState } from 'react'

import BlockButton from '../BlockButton'
import TextSmall from '../TextSmall'
import SectionHeading from '../SectionHeading'
import ReadMore from './ReadMore'

import {
  withStyles,
  Link,
  Dialog,
  CardMedia,
  IconButton,
  Fade,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  description: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  buttonWrap: {
    textAlign: 'left',
  },
  cta: {
    color: theme.palette.common.black,
    borderRadius: '20px',
    textTransform: 'initial',
    padding: theme.spacing(2, 1),
    minWidth: 285,
    marginRight: theme.spacing(3),
    backgroundColor: theme.palette.light.main,
    fontSize: 16,
  },
  modalWrapper: {
    backgroundColor: '#000000',
    '& .MuiDialog-scrollPaper': {
      padding: theme.spacing(6, 3),
    },
    '& iframe': {
      width: '100%',
      border: 'none',
      height: '100%',
    },
    '& .MuiDialog-paperFullScreen': {
      height: "auto"
    }
  },
  closeicon: {
    position: 'fixed',
    top: 0,
    right: 0,
    color: 'gray',
    '&:hover': {
      background: 'transparent',
    },
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />
})

const InspectProperty = (props) => {
  const { classes, executeScroll, currentProperty, data } = props
  const [modalOpen, setModalOpen] = useState(false)

  const handleImageIndex = (i) => {
    let floorplanLength = 0
    currentProperty.floorPlan.map((item) => {
      if (item?.['@_url']) {
        floorplanLength++
        console.debug('url', item?.['@_url'])
      }
    })
  }

  const handleModalOpen = () => {
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <div className={classes.root}>
      {/*             <SectionHeading variant="h6" component="h2">
                Want to inspect the property?
            </SectionHeading> */}
      {/* <div className={classes.description}>
                <ReadMore />
            </div> */}
      {/*             <TextSmall>
                You can either request an inspection or a virtual inspection below
            </TextSmall> */}
      {/* <TextSmall>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quia, sed ut. Et non a itaque recusandae iste ratione eius. <br/>               
            </TextSmall> */}
      {/* <TextSmall>          
                <Link href="#" color="inherit" onClick={(e)=>{e.preventDefault()}}>Read more about inspection restrictions &gt;</Link>
            </TextSmall> */}
      <BlockButton
        variant="outlined"
        color="primary"
        className={classes.cta}
        onClick={() => executeScroll()}
      >
        Contact Agent
      </BlockButton>
      {currentProperty && currentProperty?.cloudinaryFloorPlans[0] && (
        <BlockButton
          variant="outlined"
          color="primary"
          className={classes.cta}
          //onClick={() => handleImageIndex('floorplan')}
          onClick={handleModalOpen}
        >
          Floorplan
        </BlockButton>
      )}
      {/*             <BlockButton variant="outlined" color="primary"  className={classes.cta} onClick={()=> executeScroll()}>Request a Virtual Tour</BlockButton>
       */}{' '}
      {currentProperty && currentProperty.cloudinaryFloorPlans && (
        <Dialog
          fullScreen
          open={modalOpen}
          onClose={handleModalOpen}
          TransitionComponent={Transition}
          className={classes.modalWrapper}
        >
          <CardMedia
            component={'img'}
            image={
              currentProperty.cloudinaryFloorPlans[0]?.path +
              'f_webp,q_auto,w_1000' +
              currentProperty.cloudinaryFloorPlans[0]?.file
            }
          />
          <IconButton
            aria-label="close"
            className={classes.closeicon}
            onClick={handleModalClose}
            disableFocusRipple
            disableRipple
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Dialog>
      )}
    </div>
  )
}

export default withStyles(styles)(InspectProperty)
