import React from 'react';
import { Modal, Slide, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
  content: {
    height: '100%'
  }
});

const OptionModalBottom = props => {
  const { classes } = props;

  const handleClose = () => {
    props.showOptionBottomModal()
  };

  return (
    <div>
      <Modal
        style={{ zIndex: 9999 }}
        open={props.isOpen}
        onClose={handleClose}
      >
        <Slide className={classes.root} direction="up" in={props.isOpen} mountOnEnter unmountOnExit>
          <div className={classes.content}>
            {props.content}
          </div>
        </Slide>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(OptionModalBottom);