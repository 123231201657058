import { PURGE } from 'redux-persist'
import { PROPERTY_ACTION_TYPES } from 'store/constants/propertyv2.constants'

const INITIAL_STATE = {
  // temporary values
  payload_request: null,
  payload_success: null,
  payload_failed: null,
  status: null,
  error: null,
  to_confirm_property: null,
  property_favourites: null,
  property_dislikes: null,
}

const propertyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROPERTY_ACTION_TYPES['GET_PROPERTY_DETAILS']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
        to_confirm_property: null,
      }

    case PROPERTY_ACTION_TYPES['POST_REQUEST_OTP_VENDOR_APPROVAL']['REQUEST']:
    case PROPERTY_ACTION_TYPES['POST_VERIFY_VENDOR_OTP']['REQUEST']:
    case PROPERTY_ACTION_TYPES['POST_RESEND_VENDOR_OTP']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      }

    case PROPERTY_ACTION_TYPES['GET_PROPERTY_DETAILS']['FAILED']:
    case PROPERTY_ACTION_TYPES['POST_REQUEST_OTP_VENDOR_APPROVAL']['FAILED']:
    case PROPERTY_ACTION_TYPES['POST_VERIFY_VENDOR_OTP']['FAILED']:
    case PROPERTY_ACTION_TYPES['POST_RESEND_VENDOR_OTP']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params.data,
      }

    case PROPERTY_ACTION_TYPES['POST_REQUEST_OTP_VENDOR_APPROVAL']['SUCCESS']:
    case PROPERTY_ACTION_TYPES['POST_VERIFY_VENDOR_OTP']['SUCCESS']:
    case PROPERTY_ACTION_TYPES['POST_RESEND_VENDOR_OTP']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params.data,
      }

    case PROPERTY_ACTION_TYPES['GET_PROPERTY_DETAILS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params.data,
        to_confirm_property: action.params.data,
      }

    case PROPERTY_ACTION_TYPES['GENERAL_PROPERTY']['CLEAR_ERROR']:
      return {
        ...state,
        status: null,
        payload_request: null,
        payload_success: null,
        payload_failed: null,
        error: null,
      }

    case PROPERTY_ACTION_TYPES['GENERAL_PROPERTY']['CLEAR_STATUS']:
      return {
        ...state,
        status: null,
      }
    case PROPERTY_ACTION_TYPES['GENERAL_PROPERTY']['RESET_STATES']:
      return INITIAL_STATE
    case PURGE:
      return INITIAL_STATE

    default: {
      return state
    }
  }
}

export default propertyReducer
