import React, { useState, useEffect } from 'react';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import CloseIcon from '@material-ui/icons/Close';

import HeartIcon from '@material-ui/icons/FavoriteBorder';
import HeartFullIcon from '@material-ui/icons/Favorite';
import videoIcon from '../../../assets/images/video-icon.svg';
import tourIcon from '../../../assets/images/360-icon.svg';

import { connect } from 'react-redux';

import { withStyles, Button, Badge, IconButton, Tooltip, Dialog, Fade, CardMedia } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { getMpp } from 'store/selectors/mpp.selectors';

const styles = (theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  favorite: {
    position: 'absolute',
    top: '47px',
    right: '33px',
    border: 0,
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    backgroundColor: 'rgba(74, 74, 74, 0.39393) !important',
  },
  icons: {
    fontSize: '18px',
  },
  iconsWrap: {
    position: 'absolute',
    left: '20px',
    bottom: '0',
  },
  favoriteContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: theme.spacing(2.5),
    right: theme.spacing(2.5),
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
  fpBtn: {
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(3),
    borderRadius: theme.spacing(2.5),

    '& svg': {
      verticalAlign: 'middle',
    },
  },
  exploreButtons: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 6,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    '& .MuiButton-outlined': {
      borderRadius: '0',
      borderColor: '#ffffff',
      color: '#ffffff',
      textTransform: 'initial',
      margin: theme.spacing(0, 1.1),
      backgroundColor: 'rgba(77,77,77,0.5)',
      '& img': {
        marginRight: theme.spacing(0.8),
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&:disabled': {
        opacity: 0.36,
      },
    },
  },
  modalWrapper: {
    backgroundColor: '#000000',
    '& .MuiDialog-scrollPaper': {
      padding: theme.spacing(6, 3),
    },
    '& iframe': {
      width: '100%',
      border: 'none',
      height: '100%',
    },
  },
  closeicon: {
    position: 'fixed',
    top: 0,
    right: 0,
    color: 'gray',
    '&:hover': {
      background: 'transparent',
    },
  },
  modalIframe: {
    height: '100%',
    width: '100%',
    border: 'none',
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: -4,
    color: '#ffffff',
    padding: '0 4px',
  },
}))(Badge);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const ImageBannerUI = (props) => {
  const {
    classes,
    authentication,
    settings,
    currentProperty,
    toggleSignUp,
    apiRequestSaveSearch,
    apiRequestSaveProperty,
    floorPlan,
  } = props;
  const itemCount = Array.isArray(props.data.images) ? props.data.images.length : 1;
  const MPP = useSelector(getMpp);
  let likes = MPP?.favourites?.data?.likes;

  const [tourOpen, setTourOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);

  const handleTourOpen = () => {
    setTourOpen(true);
  };

  const handleTourClose = () => {
    setTourOpen(false);
  };

  const handleVideoOpen = () => {
    setVideoOpen(true);
  };

  const handleVideoClose = () => {
    setVideoOpen(false);
  };

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp);
    } else {
      /** User Logged */
      apiRequestSaveProperty(currentProperty._id);
    }
  }

  return (
    <div className={classes.root}>
      {props.children}

      {/* <ToggleButton className={classes.favorite}
              value="check"
              selected={favorite}
              onChange={() => {
                //setFavorite(!favorite);
                checkLoginStatus();
              }}
            >
              {properties.favorite ? <FavoriteIcon color="primary" className={classes.icons}  /> : <FavoriteBorderOutlinedIcon style={{ color: '#ffffff' }} className={classes.icons}  />}
            </ToggleButton> */}
      <Tooltip title="Add to favorite">
        <IconButton
          className={classes.favoriteContainer}
          onClick={(e) => {
            e.preventDefault();
            checkLoginStatus();
          }}
        >
          {authentication &&
          authentication.user_status === 'logged' &&
          currentProperty &&
          currentProperty._id &&
          likes &&
          likes?.includes(currentProperty?._id) ? (
            <HeartFullIcon className={classes.favoriteIconActive} />
          ) : (
            <HeartIcon className={classes.favoriteIcon} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Number of pictures">
        <div className={classes.iconsWrap}>
          <StyledBadge badgeContent={itemCount} color="primary">
            <CameraAltIcon style={{ color: '#ffffff', fontSize: '36px' }} />
          </StyledBadge>
          {/* {floorPlan && <span className={classes.fpBtn}  onClick={()=> props.setSelectImageIndex(itemCount-1) }><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ArchitectureIcon"><path d="M6.36 18.78 6.61 21l1.62-1.54 2.77-7.6c-.68-.17-1.28-.51-1.77-.98l-2.87 7.9zm8.41-7.9c-.49.47-1.1.81-1.77.98l2.77 7.6L17.39 21l.26-2.22-2.88-7.9zM15 8c0-1.3-.84-2.4-2-2.82V3h-2v2.18C9.84 5.6 9 6.7 9 8c0 1.66 1.34 3 3 3s3-1.34 3-3zm-3 1c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"></path></svg></span>} */}
        </div>
      </Tooltip>

      <div className={classes.exploreButtons}>
        <Button
          variant="outlined"
          onClick={handleVideoOpen}
          disabled={currentProperty && currentProperty.videoLink ? false : true}
        >
          <img src={videoIcon} />
          Video
        </Button>
        <Button
          variant="outlined"
          onClick={handleTourOpen}
          disabled={props.virtualTours && props.virtualTours.length ? false : true}
        >
          <img src={tourIcon} />
          Virtual Tour
        </Button>
      </div>

      {currentProperty && currentProperty.videoLink && (
        <Dialog
          fullScreen
          open={videoOpen}
          onClose={handleVideoOpen}
          TransitionComponent={Transition}
          className={classes.modalWrapper}
        >
          <CardMedia component={'iframe'} image={currentProperty.videoLink} />
          <IconButton
            aria-label="close"
            className={classes.closeicon}
            onClick={handleVideoClose}
            disableFocusRipple
            disableRipple
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Dialog>
      )}
      {props.virtualTours && props.virtualTours.length && (
        <Dialog
          fullScreen
          open={tourOpen}
          onClose={handleTourClose}
          TransitionComponent={Transition}
          className={classes.modalWrapper}
        >
          <iframe src={props.virtualTours[0]} className={classes.modalIframe} />
          <IconButton
            aria-label="close"
            className={classes.closeicon}
            onClick={handleTourClose}
            disableFocusRipple
            disableRipple
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(withStyles(styles)(ImageBannerUI));
