import React, { useState, Fragment } from "react";
import classNames from "classnames";
import { bindActionCreators } from "redux";

import { connect, useSelector, useDispatch } from "react-redux";
import Sidebar from "components/Web/Sidebar";

import Footer from "components/Web/Footer";
import { SuperHeader } from "components/Web/Superadmin";

/** selectors */
import {
  getAuthentication,
  getSettings,
  getUser,
} from "store/selectors/superadmin.selectors";

import {
  getUser as getUserAuthentication
} from "store/selectors/authentication.selectors"

import { toggleFilterWeb } from "store/actions";

import { withStyles } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
});

const AgencyLayout = (props) => {
  const { classes, children } = props;
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch();

  const AUTH = useSelector(getAuthentication);
  const SETTINGS = useSelector(getSettings);
  const USER = useSelector(getUser);
  const USER_AUTH = useSelector(getUserAuthentication)

  const [open, setOpen] = useState(false);

  const handleToggleDrawer = (value) => {
    setOpen(value);
  };

  const routeChange = (path) => {
    routerChange(path);
    setOpen(false);
    /** reset redux state */
    dispatch(toggleFilterWeb({ type: null, status: false }));
  };

  const handleNavigation = (type) => {
    routeChange(type.path);
  };

  return (
    <Fragment>
      <Sidebar
        open={open}
        isAgent={false}
        isAgency={children?.props?.history?.location?.pathname !== '/agency/sign-up' ?? true}
        isSuperadmin={false}
        auth={AUTH && AUTH?.user_status}
        page={SETTINGS && SETTINGS?.showFilterWeb?.type}
        filterWebActive={SETTINGS && SETTINGS?.showFilterWeb?.status}
        handleToggleDrawer={handleToggleDrawer}
        handleNavigation={handleNavigation}
        routerChange={routeChange}
        {...children.props}
      />
      <main className={classes.root}>
        <SuperHeader {...children.props} user={USER_AUTH} />
        {children}
      </main>
    </Fragment>
  );
};

export default withStyles(styles)(AgencyLayout);
