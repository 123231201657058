import FBLogo from '../../../assets/images/Facebook.png'
import LinkedInLogo from '../../../assets/images/LinkedIn.png'
import TwitterLogo from '../../../assets/images/Twitter.png'

export const FOOTER_LINKS = [
  {
    row: 1,
    label: 'Buy',
    route: '/buy',
  },
  {
    row: 1,
    label: 'Rent',
    route: '/rent',
  },
  {
    row: 1,
    label: 'Sold',
    route: '/sold',
  },
  {
    row: 1,
    label: 'My Perfect Property',
    route: '/perfect-property',
  },
  {
    row: 1,
    label: 'Hush Hush',
    route: '/hush-hush',
  },
  {
    row: 2,
    label: 'About',
    route: '/about-us',
  },
  {
    row: 2,
    label: 'Contact',
    route: '/contact-us',
  },
  {
    row: 2,
    label: 'Agency Signup',
    route: '/agency/sign-up',
  },
  {
    row: 2,
    label: 'Agency Log In',
    route: '/agency/login',
  },
  {
    row: 3,
    label: 'Privacy Policy',
    route: '/privacy-policy',
  },
  {
    row: 3,
    label: 'Terms & Conditions',
    route: '/terms-and-conditions',
  },
]

export const FOOTER_AGENCY_LINKS = [
  {
    row: 1,
    label: 'Dashboard',
    route: '',
  },
  {
    row: 1,
    label: 'Manage  Agents',
    route: '',
  },
  {
    row: 1,
    label: 'Billing & Payments',
    route: '',
  },
  {
    row: 2,
    label: 'Account Settings',
    route: '',
  },
  {
    row: 2,
    label: 'Log out',
    route: '',
  },
]

export const FOOTER_AGENT_LINKS = [
  {
    row: 1,
    label: 'Dashboard',
    route: '/agent/dashboard',
  },
  {
    row: 1,
    label: 'Manage  Agents',
    route: '',
  },
  {
    row: 1,
    label: 'Billing & Payments',
    route: '',
  },
  {
    row: 2,
    label: 'Account Settings',
    route: '/agent/account-settings',
  },
  {
    row: 2,
    label: 'Log out',
    route: '',
  },
]

export const FOOTER_SOCIAL_LINKS = [
  {
    name: 'facebook',
    route: 'https://www.facebook.com/PropertyMate-101449472478215/',
    logo: FBLogo,
  },
  {
    name: 'linkedin',
    route: 'https://www.linkedin.com/company/propertymate-real-estate/',
    logo: LinkedInLogo,
  },
  {
    name: 'twitter',
    route: 'https://twitter.com/property_mate',
    logo: TwitterLogo,
  },
]
