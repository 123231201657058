import React, { useState } from 'react'
import BlockButton from '../BlockButton'
import TextSmall from '../TextSmall'
import SectionHeading from '../SectionHeading'
import ReadMore from './ReadMore'

import { withStyles, Link, Button } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& p': {
      color: '#6A6A6A',
    },
  },
  description: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cta: {
    color: '#000000',
    borderRadius: '10rem',
    textTransform: 'initial',
    padding: theme.spacing(1.5, 1),
    minWidth: 285,
    marginRight: theme.spacing(3),
    margin: '10px 0px',
  },
})

const InspectProperty = (props) => {
  const { classes, executeScroll } = props
  return (
    <div className={classes.root}>
      <SectionHeading variant="h6" component="h2">
        Want to inspect the property?
      </SectionHeading>

      {/* <div className={classes.description}>
                <ReadMore />
            </div> */}
      <TextSmall variant="body1">
        You can request an inspection below
      </TextSmall>
      {/* <TextSmall variant="body1">
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quia, sed ut. Et non a itaque recusandae iste ratione eius. <br/>               
            </TextSmall> */}
      {/* <TextSmall>          
                <Link href="#" color="inherit" onClick={(e)=>{e.preventDefault()}}>Read more about inspection restrictions &gt;</Link>
            </TextSmall> */}

      <Button
        variant="outlined"
        color="primary"
        fontSize="large"
        className={classes.cta}
        onClick={() => executeScroll()}
      >
        Request inspection
      </Button>
    </div>
  )
}

export default withStyles(styles)(InspectProperty)
