import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Breadcrumbs, Link, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  error: {
    marginLeft: 10,
    marginTop: 10,
    color: 'red',
  },
})

const ErrorHandler = (props) => {
  const { classes, error } = props

  return (
    <div className={classes.root}>
      {error && (
        <Typography className={classes.error} variant={'caption'}>
          {'Failed: ' + error?.message}
        </Typography>
      )}
    </div>
  )
}

ErrorHandler.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.object,
}

export default withStyles(styles)(ErrorHandler)
