import * as React from 'react';
import PropTypes from 'prop-types';

// import { pascal } from 'naming-style';
import * as Icons from '@material-ui/icons';
import CustomIcon from './CustomIcons';

const DynamicIcons = props => {
  const { height, icon, theme, width, ...other } = props;
  //const iconName = `${iconProp && pascal(iconProp)}${theme === 'Filled' ? '' : theme}`;

  const Icon = Object.keys(Icons).includes(icon) ? Icons[icon] : undefined;

  return Icon ? 
    <Icon {...other} /> : 
    <CustomIcon {...other} icon={icon} />;
};

// DynamicIcons.PropTypes = {
//     height: PropTypes.string,
//     width: PropTypes.string,
// }

export default DynamicIcons;
