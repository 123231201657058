const apiEndpoints = {
  REGISTRATION: '/users',
  REGISTRATION_VERIFICATION: '/users/registration-verification',
  LOGIN: '/auth/login',
  RESEND_CODE: '/auth/resend-verification-code',
  REFRESH_TOKEN: '/auth/refresh-token', //? auth/token/refresh
  VALIDATE_TOKEN: '/auth/token/check',
  SUPERADMIN_VALIDATE_TOKEN: '/auth/token/check',
  FORGOT_PASSWORD: '/auth/forgot-password',
  CHANGE_PASSWORD: '/auth/reset-password',
  FACEBOOK_AUTH: '/auth/facebook',
  GOOGLE_AUTH: '/auth/google',
  APPLE_AUTH: '/auth/apple',
  FAVORITE_PROPERTIES: '/property/favorites',
  PROPERTY_SAVE: '/users/me/favorites',
  //PROPERTY_SAVE_SEARCH: '/users/me/saved-searches',
  //PROPERTY_SAVE_SEARCH_DELETE: '/users/me/delete-searches',
  PROPERTY_SAVE_SEARCH: '/saved-search/me/saved-searches',
  PROPERTY_SAVE_SEARCH_DELETE: '/saved-search/me/delete-searches',
  PROPERTY_SAVE_SEARCH_NOTIFY: '/saved-search/me/notify-user',
  PROPERTY_ADDRESS: '/property/address',
  PROPERTY_SEARCH: '/property/search',
  PROPERTY_RENT: '/property/rental',
  PROPERTY_SOLD: '/property/sold',
  PROPERTY_SUGGESTIONS: '/suggested-properties',
  PROPERTY_DETAILS: '/property/', // +id
  PROPERTY_LIKES: '/property/likes-dislikes',
  LIKE_DISLIKE_PROPERTY: '/users/me/likes-dislikes',
  //PERFECT_PROPERTY_RECOMMENDATION: '/mpp-recommendations-cache',
  PERFECT_PROPERTY_RECOMMENDATION: '/my-perfect-property',
  PERFECT_PROPERTY_FILTERS: '/users/me/my-perfect-property-filters',
  SEARCH_TALLIES: '/users/me/swipe-tallies',
  SEARCH_AGENTS: '/agent/search',
  SEARCH_AGENCY: '/agency/search',
  UPDATE_USER: '/users/me/update',
  GET_USER: '/user/', // +id
  AGENCY_REGISTER: '/agency',
  AGENCY_DETAILS: '/agency/', //+id
  AGENCY_CONTACT_ADMIN: '/agency-contact-admin',
  AGENCY_REGISTER_CONTACT_ADMIN: '/agency-contact-admin',
  AGENT_AGENCY_LIST: '/agent-agency-list',
  AGENT_GET_DETAILS: '/agent/', //+id
  AGENT_RATING: '/agent/', //+id + /rate
  AGENCY_AGENTS: '/agency/agents', //object payload
  AGENT_ACTIONS: '/agency/agents/', //+ id +/disable or + id + /resend
  AGENCY_VERIFY: '/agency/verification',
  AGENCY_ME: '/agency/me',
  AGENCY_ACCOUNT: '/agency/account',
  UPGRADE_PROPERTY: '/agency/properties/upgrade', //{ "properties": ["5f75869be689240f68d1d033"] }
  UPLOAD_IMAGE: '/image/upload', //file payload
  AGENT_PROPERTIES: '/agents/me/property-list',
  AGENT_PROPERTIESV2: '/property/list',
  PROPERTY_PAUSE: '/property/pause',
  PROPERTY_DELETE: '/agents/me/property',
  PROPERTY_CREATE: '/agents/me/property',
  UPLOAD_SO_INTENT: '/soi/upload', //form-data file payloed
  STATISTICS: '/statistics',
  PROPERTY_EDIT: '/agents/me/property',
  UPLOAD_SO_INTENT: '/soi/upload', //form-data file payload
  CONFIRM_PROPERTY: '/confirm-property/', //+ vendor|agency + property
  APPROVE_PROPERTY: '/approve-property/', //+ vendor|agency + property
  SET_STATISTICS: '/',
  DREAM_PROPERTIES: '/property/featured-properties',
  CONTACT_US: '/contact-us',
  MAIL_HISTORY_LIST: '/mail-history/list',
  GET_SINGLE_MAIL: '/mail-history/',
  AGENCY_APPLY_PROMO_CODE: '/stripe/subscription/coupon/apply',

  /** Superadmin -> must always be aligned to CONSTANTS_NAME */
  SUPERADMIN_LOGIN: '/auth/login',
  SUPERADMIN_FORGOT_PASSWORD: '/auth/forgot-password',
  SUPERADMIN_RESET_PASSWORD: '/auth/reset-password',
  SUPERADMIN_GET_ALL_AGENCY: '/agency/list',
  SUPERADMIN_GET_AGENCY_DATA: '/agency',
  SUPERADMIN_GET_OFFICE_LISTS: '/agency-office-id-list',
  SUPERADMIN_GET_OFFICE_DATA: '/agency-property-summary',
  SUPERADMIN_UPDATE_ACCOUNT_STATUS: '/agency-activate', // + agencyId
  SUPERADMIN_PAUSE_ACCOUNT: '/agency', // :agencyReference/status/disable
  SUPERADMIN_DELETE_ACCOUNT: '/agency',
  SUPERADMIN_GET_ALL_AGENTS: '/agency',
  SUPERADMIN_SAVE_DRAFT_AGENCY: '',
  SUPERADMIN_SEND_DOCUSIGN_AGENCY: '/admin/send-docusign',
  SUPERADMIN_RESEND_DOCUSIGN_AGENCY: '/admin/resend-docusign',
  SUPERADMIN_SEND_OFFICE_REFERENCE: '/agency-match',
  SUPERADMIN_GET_EMAIL_LOGS: '/mail-history/list',
  SUPERADMIN_GET_EMAIL_CONTENT: '/mail-history',
  SUPERADMIN_BULK_UPLOAD: '',
  SUPERADMIN_ADD_SINGLE_AGENT: '/agents',
  SUPERADMIN_DELETE_AGENT: '/remove-agent/',
  SUPERADMIN_UPDATE_AGENT: '/agent/',
  SUPERADMIN_GET_AGENT_DETAILS: '/agent/',
  SUPERADMIN_RESEND_INVITATION: '',
  SUPERADMIN_UPLOAD_AGENT_IMAGE: '',
  SUPERADMIN_UPDATE_ACCOUNT_SETTINGS: '/superadmin', // + superadmin/update
  SUPERADMIN_CHECK_AGENT_EMAIL: '/agent', //_id
  SUPERADMIN_SEND_ACTIVATION_EMAIL: '/agency', // /:agencyReference/send-activation-mail
  SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL: '/agency', // /:agencyReference/add-creditcard-mail
  SUPERADMIN_GET_REPORTS_DATABASE: '/reporting-dashboard/database',
  SUPERADMIN_GET_REPORTS_GOOGLE: '/reporting-dashboard/google-analytics',
  /** Change Requests */
  SUPERADMIN_GET_ALL_CHANGE_REQUESTS: '/change-intents',
  SUPERADMIN_GET_CHANGE_REQUESTS_DATA: '/change-intents',
  SUPERADMIN_UPDATE_CHANGE_REQUESTS: '/change-intent',
  SUPERADMIN_ADD_AGENCY: '/agency',
  /** AGENCY */
  AGENCY_ACTIVATE: '/agency-activate/',
  AGENCY_LOGIN: '/auth/login',
  AGENCY_FORGOT_PASSWORD: '/auth/forgot-password',
  AGENCY_RESET_PASSWORD: '/auth/reset-password',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  AUTH_RESET_PASSWORD: '/auth/reset-password',

  AGENCY_GET_ALL_AGENCY: '',
  AGENCY_GET_ALL_AGENTS: '/agency/',
  AGENCY_DASHBOARD_GET_PROPERTIES: '/property/list', //? query params append ID string
  AGENCY_EDIT_PROPERTY: '/property/', // ? query params append ID string
  AGENCY_PUBLISH_PROPERTY: '/property/publish-property/', // ? query params append ID string
  AGENCY_NOTIFY_VENDOR: '/property/notify-vendor/', // ? query params append ID string
  AGENCY_CREATE_PROPERTY: '/agency/me/property',
  AGENCY_PAUSE_PROPERTY: '/property/pause',
  AGENCY_DELETE_PROPERTY: '/property/', // + id
  AGENCY_SEND_AGENT_ACTIVATION_EMAIL: '/agent/resend-invitation/', //? query params append ID string

  /** AGENT ACTIVATION */
  AGENT_GET_STATE: '/agent-state', // + id
  AGENT_ACTIVATE: '/agent-activate', // + id
  AGENCY_GET_AGENT_DETAILS: '/agent/', //+id
  AGENCY_PUT_AGENT_DETAILS: '/agent/', //+id
  AGENCY_CHECK_AGENT_EMAIL: '/agent', //+id
  AGENCY_ADD_AGENT: '/agents', //+id
  AGENCY_DELETE_AGENT: '/remove-agent/', //+id
  AGENCY_AGENT_PHOTO: '/agent-photo-upload/', //:email
  /** BILLING */
  BILLING_GET_ALL_INVOICES: '/invoices',
  BILLING_GET_INVOICE_XERO: '/invoice', //+invoiceReference

  /** IPP REFERRAL */
  IPP_ANALYTICS_REFERRAL_CODE: '/ipp-analytics/', //query params append referralCode string
  IPP_ANALYTICS_GENERATE_REFERRAL_CODE: '/generateReferralCode/', // query params append agencyId string
  GET_IPP_ANALYTICS: '/ipp-analytics',
  /** VENDOR OTP */
  VENDOR_VERIFY_OTP: '/property/vendor-verify-sms-code/', // + propertyId,
  GET_AGENCY_DETAILS: '/agency/', //+agencyId
  UPDATE_AGENCY_DETAILS: '/agency/', //+agencyId
  AGENCY_UPLOAD_IMAGE: '/agency-logo-upload/', //+agencyId &target
  GET_PROPERTY_DETAILS: '/confirm-property/', //+ vendor|agency + property,
  POST_VERIFY_VENDOR_OTP: '/property/vendor-verify-sms-code/', // + _id
  POST_REQUEST_OTP_VENDOR_APPROVAL: '/property/vendor-approval/', // + _id
  POST_RESEND_VENDOR_OTP: '/property/vendor-resend-sms-code/', // + _id
  GET_PROPERTY_SEARCH: '/property/search',
  POST_PROPERTY_SEARCH: '/property/search-cache',
  GET_RENTAL_PROPERTY_SEARCH: '/property/rental',
  POST_RENTAL_PROPERTY_SEARCH: '/property/search-cache',
  GET_SOLD_PROPERTY_SEARCH: '/property/sold',
  POST_SOLD_PROPERTY_SEARCH: '/property/search-cache',
  STRIPE_GET_PRICES: '/stripe/subscription/product/list',
  STRIPE_POST_CHECKOUT: '/stripe/subscription/checkout',
  STRIPE_POST_CLIENTSECRET: '/stripe/subscription/subscribe',
  STRIPE_POST_PAYMENTMETHODINTENT: '/stripe/setup',
  STRIPE_GET_PAYMENTMETHODS: '/stripe/', // + sourceActorReference/paymentmethods
  STRIPE_REMOVE_PAYMENTMETHOD: '/stripe/paymentmethods/detach',
  STRIPE_DEFAULT_PAYMENTMETHOD: '/stripe/paymentmethods/default',
  STRIPE_GET_SUBSCRIPTION_DETAILS: '/stripe/subscriptions/', // + sourceActorReference
  AGENCY_POST_CHANGE_REQUEST: '/change-intent/add',
  STANDALONE_PAYMENT_GET_AGENCY_DETAILS: '/agency/',
  /** MPP FAVOURITES and dislikes */
  POST_MPP_LIKES_DISLIKES: '/mpp/me/likes-dislikes',
  PUT_MPP_LIKES_DISLIKES: '/mpp/me/likes-dislikes',
  PUT_MPP_FAVOURITES: '/mpp/me/favorites',
  GET_MPP_FAVOURITES_IDS: '/mpp/me/favorites',
  GET_MPP_FAVOURITES: '/mpp/me/likes-dislikes', //? query params append propertyPreference like
  GET_MPP_DISLIKES: '/mpp/me/likes-dislikes', //? query params append propertyPreference dislike
  AGENCY_GET_ALL_ADMINS: '/agency-admin',
  AGENCY_GET_SINGLE_ADMIN: '/agency-admin',
  AGENCY_CREATE_ADMIN: '/agency-admin',
  AGENCY_EDIT_ADMIN: '/agency-admin',
  AGENCY_DELETE_ADMIN: '/agency-admin',
};
export { apiEndpoints };
