import React from 'react';
import _ from 'lodash';

import ChipInput from 'material-ui-chip-input';

import SearchIcon from '@material-ui/icons/Search';

import SUBURBS from '../../../assets/data/suburbs-all-new.json';

import { makeStyles, Paper, List, ListItem, ListItemText, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: '#FFF',
    position: 'relative',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    zIndex: 9,
  },
  mainContainerFullWidth: {
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 9,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 4,
    border: '1px solid #9B9B9B',
    position: 'relative',
    zIndex: 20,
  },
  chip: {
    backgroundColor: '#f5f5f5',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 0,
    height: '2.875em',
    fontSize: '0.875em',
  },
  chipContainer: {
    width: '100%',
    flexFlow: 'nowrap',
    overflowX: 'auto',
    alignSelf: 'center',
    border: 4,
  },
  chipContainerFull: {
    width: '60vw',
    flexFlow: 'nowrap',
    overflowX: 'auto',
    alignSelf: 'center',
    border: 4,
  },
  input: {
    marginLeft: theme.spacing(1),
    zIndex: 20,
    textAlign: 'left',
    width: '100%',
  },
  iconButton: {
    alignSelf: 'stretch',
    position: 'relative',
    padding: '0.625em',
    backgroundColor: '#11c0c9',
    borderRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    '&:hover': {
      backgroundColor: '#11c0c9',
    },
  },
  iconSearch: {
    color: '#FFF',
  },
  iconLoading: {
    color: '#FFF',
  },
  iconTune: {
    color: '#000',
  },
  listContainer: {
    position: 'relative',
  },
  list: {
    position: 'absolute',
    zIndex: 999,
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '11.250em',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SearchInput = (props) => {
  const [searchText, setSearchText] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [selectedChipValue, setSelectedChipValue] = React.useState([]);
  const [resultArr, setResultArr] = React.useState(null);
  const [filteredCalled, setFilterCalled] = React.useState(false);
  const [isSuburbRemoveCalled, setIsSuburbRemoveCalled] = React.useState(false);

  const classes = useStyles();

  let timeout;

  React.useEffect(() => {
    if (props.isClearFilter) {
      setSearchText('');
      setResultArr(null);
      setSelectedValue([]);
      setFilterCalled(false);
      setSelectedChipValue([]);
    }
  }, [props.isClearFilter]);

  React.useEffect(() => {
    if (props.filters) {
      if (props.filters.suburbs.length > 0) {
        setSelectedValue(_.uniq(props.filters.suburbs));
        // if(JSON.stringify(props.filters.suburbs) !== JSON.stringify(selectedValue)) {
        //     props.setPropertySearch(props.filters.suburbs);
        // }
        let suburb = [];
        props.filters.suburbs.map((item, index) => {
          suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode);
        });
        setSelectedChipValue(_.uniq(suburb));
        if (JSON.stringify(_.uniq(suburb)) !== JSON.stringify(selectedChipValue)) {
          props.setChipValue(_.uniq(suburb));
        }
      }
      if (isSuburbRemoveCalled) {
        props.apiRequestSearchProperty();
        setIsSuburbRemoveCalled(false);
      }
    }
  }, [props.filters]);

  // React.useEffect(()=> {
  //     if(searchText.length > 0 && !filteredCalled){
  //         filterResult();
  //     } else {
  //         setResultArr(null);
  //     }
  // },[searchText])

  function addSelection(value) {
    /** Set Selected Values */
    let selected = selectedValue;
    selected.push({
      ...value,
      name: value.locality + ', ' + value.state + ', ' + value.postcode,
    });
    setSelectedValue(selected);

    /** Set Property Suburb Search */
    props.setPropertySearch(selected);

    /** Set Chip Values */
    let selectedChip = selectedChipValue;
    selectedChip.push(value.locality + ', ' + value.state + ', ' + value.postcode);
    setSelectedChipValue(selectedChip);

    props.setChipValue(selectedChip);

    /** Reset Results and search text */
    setResultArr(null);
    //props.setIsInputFocused(false);
    setSearchText('');
  }

  function removeSelected(value, index) {
    /** Filter and Remove Matched Object from Selection */
    let selected = _.filter(selectedValue, function (n) {
      return n.name !== value;
    });
    setSelectedValue(selected);

    /** Set Property Suburb Search */
    props.setPropertySearch(selected);

    /** Set Chip Values */
    let selectedChip = _.filter(selectedChipValue, function (n) {
      return n !== value;
    });
    setSelectedChipValue(selectedChip);
    props.setChipValue([]);
    setIsSuburbRemoveCalled(true);
  }

  function mergeAddress(d, s) {
    let text = (d.locality + ', ' + d.state + ', ' + d.postcode)
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/ /g, '');
    let searchText = s
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/ /g, '');
    return text.toLowerCase().startsWith(searchText);
  }

  function filterResult(value) {
    setFilterCalled(true);

    if (value.length > 0) {
      /** Set filtered suburb based from results */
      let suburb = _.filter(SUBURBS, (d) => {
        return mergeAddress(d, value) || d.postcode.toLowerCase() === value.toLowerCase();
      });

      let groups = _.groupBy(suburb, 'state');
      let topSuburbs = [];
      _.forEach(groups, (item, key) => {
        _.forEach(item, (o, k) => {
          if (k < 10) {
            topSuburbs.push(o);
          }
        });
      });
      /** Filter and Remove Matched Object from Selection */
      var output = _.orderBy(
        _.filter(topSuburbs, (el) => {
          return (
            _.findIndex(selectedValue, (elem) => {
              return elem.id === el.id;
            }) === -1
          );
        }),
        ['locality'],
        ['asc'],
      );
      setResultArr(output);
    } else {
      setResultArr(null);
    }
    setFilterCalled(false);
  }

  React.useEffect(() => {
    // if(searchText) {
    //     handleFilterResult(searchText)
    // } else {
    //     setResultArr(null)
    // }
  }, [searchText]);

  function handleChange(e) {
    setSearchText(e.target.value);
    let txt = e.target.value || null;
    if (txt) {
      // handleFilterResult(txt)
      filterResult(txt);
    } else {
      setResultArr(null);
    }
  }

  const handleFilterResult = React.useCallback(
    _.debounce((value) => {
      filterResult(value);
    }, 150),
    [],
  );

  return (
    <div className={props.isFullWidth ? classes.mainContainerFullWidth : classes.mainContainer}>
      <div className={classes.root}>
        <ChipInput
          clearInputValueOnChange={true}
          fullWidth
          fullWidthInput={false}
          classes={{
            chipContainer: props.isFullWidth ? classes.chipContainerFull : classes.chipContainer,
            input: classes.input,
            chip: classes.chip,
          }}
          disableUnderline={true}
          placeholder={props.isFullWidth ? 'Suburb' : 'Search by Suburb or Postcode'}
          value={selectedChipValue}
          onDelete={(chip, index) => removeSelected(chip, index)}
          InputProps={{
            onChange: (event) => {
              handleChange(event);
            },
            onBlur: () => {
              if (!resultArr || resultArr.length === 0) {
                setSearchText('');
              }
            },
            value: searchText,
          }}
        />
        <IconButton className={classes.iconButton} onClick={() => props.apiRequestSearchProperty()}>
          <SearchIcon className={classes.iconSearch} />
        </IconButton>
      </div>
      <Paper className={classes.listContainer}>
        {resultArr && resultArr.length > 0 && (
          <List dense={true} className={classes.list}>
            {resultArr &&
              resultArr.map((value, index) => {
                let textLocation = value.locality + ', ' + value.state + ', ' + value.postcode;
                let isExist = _.findIndex(selectedChipValue, function (o) {
                  return o == textLocation;
                });
                if (isExist === -1) {
                  return (
                    <ListItem key={index} onClick={() => addSelection(value)}>
                      <ListItemText primary={textLocation} />
                    </ListItem>
                  );
                }
              })}
          </List>
        )}
      </Paper>
    </div>
  );
};

export default SearchInput;
