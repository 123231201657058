import React, { useState, useEffect } from 'react';
import { IconButton, Button, Grid, AppBar, Paper, Container, Typography, withStyles } from "@material-ui/core";

import checkField from 'components/Common/Validator/validationService'
import FormField from '../Form/FormField';

import _ from 'lodash'
import Loader from '../Loader';
import NotificationDialog from '../CustomDialogs/NotificationDialog';

const styles = theme => ({
    root: {
        padding: theme.spacing(3, 0)
    },
    confirm: {
        borderRadius: 30,
        minWidth: 118, 
        fontWeight: 400,
        padding: '9px 15px',
        textTransform: 'initial',
        letterSpacing: 0.0731
    },
    actionWrap: {
        paddingTop: theme.spacing(3)
    },
    verifyloading: {
        '& .MuiBackdrop-root' : {
            maxWidth: 'initial',
            left: 0
        }
    }
})

const VERIFY_AGENCY_FIELDS = fields => ({
        fields: {       
          email: {
            isEmail: { message: 'Email is invalid' },
            isRequired: { message: 'Email is required'}   
          },    
          password: {
            isRequired: { message: 'Password is required' },
            isStrongPassword: { message: 'Invalid password format' },
            isMinLength: { length: 8, message: 'Password must be at least 8 characters.'}   
          },    
          confirmpassword: {
            isRequired: { message: 'Confirm Password is required' },
            isEqual: { value: fields.password , message: "Password doesn't match."}      
          },    
          code: {
            isRequired: { message: 'Code is required' },
            isMinLength: { length: 6,message: 'Code must be at least 6 characters'}      
          },    
        },
        onSubmit: e => {
            console.log('EXECUTE SUBMIT', e)
        },
        showErrors: 'change'
      }
)

const AgencyVerifyForm = props => {  
    const {
      classes,
      verifyAgency,
      params,
      verifystatus,
      verifyerror
    } = props;

    const [code, setCode] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmpassword] = useState('');
    const defaultErrors = {email: {status: false, message: '' }, password: {status: false, message: '' }, confirmpassword: {status: false, message: '' }, code: {status: false, message: '' } }
    const [errors, setErrors] = useState(defaultErrors)
    const [isButtonDisabled, setIsButtonDisabled] = useState(true); 
    const [isLoading, setIsLoading] = useState(false); 
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const handleFieldErrors = (field, value) => {
        if(checkField(value, VERIFY_AGENCY_FIELDS(field).fields[field]) === null ) {             
            setErrors(prevState => ({...prevState,  [field]: {...prevState[field], status: false }}))
        } else{
            setErrors(prevState => ({...prevState, [field]: {...prevState[field], status: true, message: checkField(value, VERIFY_AGENCY_FIELDS(field).fields[field]) }}))
        }
    }

    const codePassCheck = (fields, errors)=>{ 
        const reqFields = fields.code.trim() && fields.password.trim() && fields.confirmpassword.trim() && fields.email.trim() ? true : false; 
        if (reqFields && Object.keys(errors).length > 0) {
          if(!errors.code.status && !errors.password.status && !errors.confirmpassword.status && !errors.email.status) {
            setIsButtonDisabled(false)   
          } else {
            setIsButtonDisabled(true)
          }
        } else {
          setIsButtonDisabled(true)
        } 
      }

    useEffect(() => {
        codePassCheck({code, password, confirmpassword, email}, errors)
    }, [code, password, confirmpassword, email]);
    
    useEffect(() => {
        if(params && params.code && params.email) {
            setCode(params.code)
            setEmail(params.email)
        }
    }, [params]);

    const handleVerify = () => {
        setIsLoading(true); 
        let params = {
            code: code, 
            password: password, 
            confirmPassword: confirmpassword,
            email: email, 
        }        
        verifyAgency(params)
    }

    const resetForm = () => {
        
        setEmail('')
        setPassword('')
        setCode('')
        setConfirmpassword('')
        setErrors(defaultErrors);
        setIsButtonDisabled(true)
    } 

    useEffect(()=>{

        if(verifystatus === 'success') {
            setIsLoading(false)
            setSuccess(true);
        }
        if(verifystatus === 'failed') {
            setIsLoading(false)
            setError(true);
        }

    }, [verifystatus, verifyerror])

    return (
        
            <div className={classes.root} elevation={5}> 
            <div className={classes.verifyloading}>
                <Loader isLoading={isLoading}/> 
            </div>            
                <FormField 
                    type={'number'}                        
                    placeholder={'Code'}
                    name={'code'}
                    value={params && params.code ? code : ''}
                    errorMsg={errors.code.message}
                    showError={errors.code.status}
                    onChange={(e) => {
                        var value = e.target.value;
                        setCode(value); 
                        handleFieldErrors('code', value)                   
                    }}
                    disabled={params && params.code ? true : false}                                                     
                />   
                <FormField 
                    type={'email'}                        
                    placeholder={'Email'}
                    name={'email'}
                    value={params && params.email ? email : ''}
                    errorMsg={errors.email.message}
                    showError={errors.email.status}
                    onChange={(e) => {
                        var value = e.target.value;
                        setEmail(value); 
                        handleFieldErrors('email', value)                   
                    }}
                    disabled={params && params.email ? true : false}                                                                                                             
                />   
                <FormField 
                    type={'password'}                        
                    placeholder={'Password'}
                    name={'password'}
                    // value={password}
                    errorMsg={errors.password.message}
                    showError={errors.password.status}
                    onChange={(e) => {
                        var value = e.target.value;
                        setPassword(value);
                        handleFieldErrors('password', value) 
                    }}                                   
                />   
                <FormField 
                    type={'password'}                        
                    placeholder={'Confirm Password'}
                    name={'confirmpassword'}                    
                    errorMsg={errors.confirmpassword.message}
                    showError={errors.confirmpassword.status}
                    onChange={(e) => {
                        var value = e.target.value;
                        setConfirmpassword(value);
                        if(value && value !== password) { 
                            handleFieldErrors('confirmpassword', value) 
                        }else{
                            setErrors(prevState => ({...prevState,  confirmpassword: {...prevState.confirmpassword, status: false }}))
                        }
                    }}                                   
                />
                <div className={classes.actionWrap}>
                    <Button variant={'contained'} color={'primary'} disabled={isButtonDisabled} disableElevation className={classes.confirm} onClick={handleVerify}>Verify</Button>
                </div>
                { verifystatus === 'success' && !verifyerror && <NotificationDialog
                    isOpen={success}
                    closeCallback={() => {setSuccess(false); resetForm()}}
                    title={'Success'}
                    text={'Verification successful.'}
                    showLogo={true}
                    align={'center'}
                />}
                { verifyerror && <NotificationDialog
                    isOpen={error}
                    closeCallback={() => {setError(false); setIsLoading(false)}}
                    title={'Error'}
                    text={'Please check if correct information are specified then try again.'}
                    showLogo={true}
                    align={'center'}
                />}
            </div>
    );
}

export default withStyles(styles)(AgencyVerifyForm);