import { PROPERTY_ADVANCE } from 'components/Web/Filter/DefaultValues'
import { PRICE_RANGE, PRICE_RANGE_RENT } from '../../constants/constants'

export const DEFAULT_FILTERS = {
  propertyType: [
    {
      id: '0',
      title: 'House',
      icon: '2DCHouseIcon',
      active: false,
    },
    {
      id: '1',
      title: 'Townhouse',
      icon: '2DCTownHouseIcon',
      active: false,
    },
    {
      id: '2',
      title: 'Apartment & Unit',
      icon: '2DCApartmentIcon',
      active: false,
    },
    {
      id: '3',
      title: 'Land',
      icon: '2DCLandIcon',
      active: false,
    },
    {
      id: '4',
      title: 'Rural',
      icon: '2DCRuralIcon',
      active: false,
    },
    {
      id: '5',
      title: 'Retirement',
      icon: '2DCRetirementVillageIcon',
      active: false,
    },
    {
      id: '6',
      title: 'Villa',
      icon: '2DCVillaIcon',
      active: false,
    },
  ],
  propertyDetails: {
    price: {
      min: PRICE_RANGE[0],
      max: PRICE_RANGE[1],
    },
    rangedPrice: {
      min: PRICE_RANGE[0],
      max: PRICE_RANGE[1],
    },
    scaledPrice: [0, 275],
    priceRent: {
      min: PRICE_RANGE_RENT[0],
      max: PRICE_RANGE_RENT[1],
    },
    bedrooms: null,
    bathrooms: null,
    carparking: null,
  },
  landSize: {
    min: '',
    max: '',
  },
  features: [
    {
      id: '0',
      label: 'Gas',
      active: false,
    },
    {
      id: '1',
      label: 'Balcony',
      active: false,
    },
    {
      id: '2',
      label: 'Study',
      active: false,
    },
    {
      id: '3',
      label: 'Air Conditioning',
      active: false,
    },
    {
      id: '4',
      label: 'Built-In Wardrobes',
      active: false,
    },
    {
      id: '5',
      label: 'Garden/Courtyard',
      active: false,
    },
    {
      id: '6',
      label: 'Swimming Pool',
      active: false,
    },
  ],
  establishment: null,
  furniture: null,
  availableDate: null,
  keywords: {
    keyword: '',
    offer: false,
  },
  advanceProperty: PROPERTY_ADVANCE,
  perfectFilters: {},
  distanceFromKm: {
    near: [
      {
        id: '0',
        label: 'Capital city',
        active: true,
      },
    ],
    value: 0,
  },
}

export const DEFAULT_MPP_FILTERS = {
  state: [
    { id: 0, title: 'ACT', active: false },
    { id: 1, title: 'NSW', active: false },
    { id: 2, title: 'NT', active: false },
    { id: 3, title: 'QLD', active: false },
    { id: 4, title: 'SA', active: false },
    { id: 5, title: 'TAS', active: false },
    { id: 6, title: 'VIC', active: true },
    { id: 7, title: 'WA', active: false },
  ],
  near: [
    {
      id: '0',
      label: 'Capital city',
      active: false,
    },
    {
      id: '1',
      label: 'Suburbs',
      active: false,
    },
    {
      id: '2',
      label: 'Rural setting',
      active: false,
    },
    {
      id: '3',
      label: 'Country Town',
      active: false,
    },
  ],
  radius: 0,
}
