import React from "react";
import { Typography, CircularProgress } from "@material-ui/core";

function CacheLoader(props) {
  const styles = {
    root: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    title: {
      fontFamily: "Montserrat, sans",
      textAlign: "center",
      fontWeight: "bold",
      color: "#4f4f4f",
      marginTop: 20,
    },
    spinner: {
      color: "#4f4f4f",
    },
  };
  return (
    <div style={styles.root}>
      <CircularProgress style={styles.spinner} />
      <Typography style={styles.title} variant="body1">
        Please wait..
      </Typography>
    </div>
  );
}

export default CacheLoader;
