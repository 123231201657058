import FBLogo from '../../../assets/images/Facebook.png'
import LinkedInLogo from '../../../assets/images/LinkedIn.png'
import TwitterLogo from '../../../assets/images/Twitter.png'

export const FOOTER_LINKS = [
  {
    row: 1,
    label: 'Buy',
    route: '/buy',
  },
  {
    row: 1,
    label: 'Rent',
    route: '/rent',
  },
  {
    row: 1,
    label: 'Sold',
    route: '/sold',
  },
  {
    row: 1,
    label: 'My Perfect Property',
    route: '/perfect-property',
  },
  {
    row: 1,
    label: 'Off market properties',
    route: '/off-market',
  },
  {
    row: 1,
    label: 'Coming soon properties',
    route: '/coming-soon',
  },
  {
    row: 2,
    label: 'Cookie preference',
    route: '',
  },
  {
    row: 3,
    label: 'Privacy Policy',
    route: '/privacy-policy',
  },
  {
    row: 3,
    label: 'Terms & Conditions',
    route: '/terms-and-conditions',
  },
]

export const FOOTER_AGENCY_LINKS = [
  {
    row: 1,
    label: 'Dashboard',
    route: '',
  },
  {
    row: 1,
    label: 'Manage  Agents',
    route: '',
  },
  {
    row: 1,
    label: 'Billing & Payments',
    route: '',
  },
  {
    row: 2,
    label: 'Account Settings',
    route: '',
  },
  {
    row: 2,
    label: 'Log out',
    route: '',
  },
]

export const FOOTER_SOCIAL_LINKS = [
  {
    name: 'facebook',
    route: 'https://www.facebook.com/PropertyMate-101449472478215/',
    logo: FBLogo,
  },
  {
    name: 'linkedin',
    route: 'https://www.linkedin.com/company/propertymate-real-estate/',
    logo: LinkedInLogo,
  },
  {
    name: 'twitter',
    route: 'https://twitter.com/property_mate',
    logo: TwitterLogo,
  },
]
