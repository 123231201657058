export const IPP_REFERRAL_ACTION_TYPES = {
  IPP_ANALYTICS_REFERRAL_CODE: {
    REQUEST: 'ipp-analytics/IPP_ANALYTICS_REFERRAL_CODE_REQUEST',
    SUCCESS: 'ipp-analytics/IPP_ANALYTICS_REFERRAL_CODE_SUCCESS',
    FAILED: 'ipp-analytics/IPP_ANALYTICS_REFERRAL_CODE_FAILED',
    CLEAR: 'ipp-analytics/IPP_ANALYTICS_REFERRAL_CODE_CLEAR',
  },
  IPP_ANALYTICS_GENERATE_REFERRAL_CODE: {
    REQUEST: 'ipp-analytics/IPP_ANALYTICS_GENERATE_REFERRAL_CODE_REQUEST',
    SUCCESS: 'ipp-analytics/IPP_ANALYTICS_GENERATE_REFERRAL_CODE_SUCCESS',
    FAILED: 'ipp-analytics/IPP_ANALYTICS_GENERATE_REFERRAL_CODE_FAILED',
    CLEAR: 'ipp-analytics/IPP_ANALYTICS_GENERATE_REFERRAL_CODE_CLEAR',
  },
  GET_IPP_ANALYTICS: {
    REQUEST: 'ipp-analytics/GET_IPP_ANALYTICS_REQUEST',
    SUCCESS: 'ipp-analytics/GET_IPP_ANALYTICS_SUCCESS',
    FAILED: 'ipp-analytics/GET_IPP_ANALYTICS_FAILED',
    CLEAR: 'ipp-analytics/GET_IPP_ANALYTICS_CLEAR',
  },
}
  