function mapDrawEnable(map, type) {
    map.setOptions({
        scrollwheel: true, 
        disableDoubleClickZoom: false,
        panControl: true,
        mapTypeControl: false,        
        fullscreenControl: false,
        zoomControl: false,
        clickableIcons: false,
        draggableCursor: null
    });
}

export default mapDrawEnable