import {
  GET_EMAIL_HISTORY_SUCCESS,
  GET_EMAIL_HISTORY_FAIL,
  GET_SINGLE_EMAIL_SUCCESS,
  GET_SINGLE_EMAIL_FAIL,
} from "../constants";

export function getEmailLogsSuccess(payload) {
  return {
    type: GET_EMAIL_HISTORY_SUCCESS,
    payload,
  };
}

export function getEmailLogsFail(payload) {
  return {
    type: GET_EMAIL_HISTORY_FAIL,
    payload,
  };
}

export function getSingleEmailSuccess(payload) {
  return {
    type: GET_SINGLE_EMAIL_SUCCESS,
    payload,
  };
}

export function getSingleEmailFail(payload) {
  return {
    type: GET_SINGLE_EMAIL_FAIL,
    payload,
  };
}
