import React, { useEffect, useState, Fragment } from 'react'
import { Link } from 'react-router-dom'

import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'

import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  getAgencyHexByAgencyId,
  DEFAULT_AGENCIES,
  HEX_OBJECT,
} from '../../../constants/constants'

import styled, { css } from 'styled-components'
import MessageIcon from '@material-ui/icons/Message'
import PhoneIcon from '@material-ui/icons/Phone'
import { Box, withStyles, Typography, Avatar, Button } from '@material-ui/core'
import { isNaN } from 'lodash'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: 43,
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 6,
    paddingLeft: theme.spacing(2),
  },
  rootProperty: {
    width: '100%',
    height: 43,
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarText: {
    color: '#FFF',
    textAlign: 'right',
    marginRight: '0.714em',
    marginLeft: '0.714em',
    lineHeight: 1.2,
  },
  avatarTextProperty: {
    color: '#FFF',
    textAlign: 'right',
    marginRight: '0.714em',
    marginLeft: '0.714em',
    lineHeight: 1.2,
    fontSize: 16,
  },
  avatarImage: {
    width: 61,
    height: 61,
    position: 'relative',
    bottom: 10,
    border: '1px solid #FFF',
    backgroundColor: '#bdbdbd',
    '@media (max-width: 1024px)': {
      width: 55,
      height: 55,
    },
  },
  avatarImageProperty: {
    width: 120,
    height: 120,
    position: 'relative',
    bottom: 0,
    border: '1px solid #FFF',
    marginTop: '-80px',
  },
  agentLogo: {
    // height: '2em',
    maxHeight: '72%',
    width: 'auto',
    maxWidth: '40%',
    backgroundColor: 'transparent',
    imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    // imageRendering: 'pixelated',
    imageRendering: '-webkit-optimize-contrast',
  },
  buttonOption: {
    marginTop: 5,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    padding: theme.spacing(1.25, 0.6),
    width: 150,
  },
  agentName: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21px',
    marginLeft: 0,
    marginRight: 10,
  },
})

const AgentsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

const Row = styled.div`
  padding-left: 0;
  padding-right: 0;

  &:last-child {
    padding-right: 0;
  }

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 22px 0;
  }

  .avatar {
    width: 80px;
    height: 80px;
  }

  .btn-tel {
    width: 100px !important;
    margin: 0;
    padding: 10px 4.8px;
    margin-top: 13px;
  }
`

const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 80px;
  &:last-child {
    margin-left: 20px;
    justify-content: flex-end;
    width: calc(100% - 80px);
    flex-direction: column;
    align-items: flex-start;
  }
`

const AgentBannerDisplayInfo = ({ classes, agent, getAgentImage }) => {
  const [isVisible, setIsVisible] = useState(false)
  const hasTelephone = agent.telephone ? true : agent.phone ? true : false
  const telNum = agent.telephone ? agent.telephone : agent.phone ? agent.phone : null
  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents()

  return (
    <div>
      {hasTelephone && (
        <Row>
          <div>
            <Col>
              <Avatar className="avatar" src={getAgentImage(agent.image)} />
            </Col>
            <Col>
              <Typography
                dangerouslySetInnerHTML={{ __html: agent.name ? agent.name : '' }}
                className={classes.agentName}
                variant="body2"
                component="p"
              ></Typography>
              {hasTelephone && (
                <Button
                  href={isVisible ? `tel:${telNum}` : '#'}
                  className={classes.buttonOption}
                  startIcon={<PhoneIcon />}
                  fullWidth
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault()
                    setIsVisible((prev) => !prev)
                    triggerAnalyticsEvent({
                      event: 'property_actions',
                      eventFrom: 'Web',
                      actionScope: 'property_agent_card',
                      actionName: 'Property Agent Card',
                      actionTarget: 'Clicked Agent Mobile Number',
                      actionReference: 'property',
                      ...getPropertyData(agent),
                    })
                  }}
                >
                  {isVisible ? telNum : 'Phone'}
                </Button>
              )}
            </Col>
          </div>
        </Row>
      )}
    </div>
  )
}

const AgentBanner = (props) => {
  const { classes, showBanner, showAgentAvatar, properties, type, isMultipleAgents } =
    props

  const [agents, setAgents] = useState(properties.agents)

  useEffect(() => {
    let obj = properties.agents
    if (obj && obj.length) {
      obj.map((item) => {
        return (item['visible'] = false)
      })
      setAgents(obj)
    }
  }, [])

  const AgentImage = (img) => {
    if (img === 'http://lorempixel.com/200/200/people/9/') {
      return null
    } else {
      if (img) {
        const imgsplit = img.split('image/upload', 2)

        return imgsplit.length === 2
          ? imgsplit[0] + 'image/upload/f_webp,q_auto/c_fill,g_face,h_300,w_300' + imgsplit[1]
          : img
      }
      return img
    }
  }

  const checkIfLight = (color) => {
    const hex = color.replace('#', '')
    const c_r = parseInt(hex.substr(0, 2), 16)
    const c_g = parseInt(hex.substr(2, 2), 16)
    const c_b = parseInt(hex.substr(4, 2), 16)
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
    const isLight = isNaN(brightness) ? true : brightness > 155
    return isLight
  }

  const logoObject = LOGO_OBJECT,
    hexObject = HEX_OBJECT

  const getAgencyLogo = (agencyName) => {
    if (
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return LogoPlaceholder
    }
  }

  const getAgencyHex = (agencyName) => {
    if (
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return '#000000'
    }
  }

  // const brandColor = properties.agency && properties.agency.brandColor ? properties.agency.brandColor : getAgencyHex(properties.reaAgencyId || properties.agency.reaAgencyId)
  const brandColor =
    properties.agency && properties.agency.brandColor
      ? properties.agency.brandColor
      : properties.reaAgencyId
      ? getAgencyHex(properties.reaAgencyId || properties.agency.reaAgencyId)
      : '#000000'

  function renderAgentBannerResultScreen() {
    return (
      <>
        {showBanner && (
          // <div className={classes.root} style={{backgroundColor: brandColor, boxShadow: checkIfLight(getAgencyHex(properties.reaAgencyId || properties.agency.reaAgencyId)) ? "0 0 0 1px #000000" : "none"}}>
          <div
            className={classes.root}
            style={{
              backgroundColor: brandColor,
              boxShadow: properties.reaAgencyId
                ? checkIfLight(
                    getAgencyHex(
                      properties.reaAgencyId || properties.agency.reaAgencyId
                    )
                  )
                : '0 0 0 1px #000000',
            }}
          >
            <img
              className={classes.agentLogo}
              // src={properties.agency && properties.agency.logoUrl ? properties.agency.logoUrl : getAgencyLogo(properties.reaAgencyId || properties.agency.reaAgencyId)} />
              src={
                properties.agency && properties.agency.logoUrl
                  ? properties.agency.logoUrl
                  : properties.reaAgencyId
                  ? getAgencyLogo(
                      properties.reaAgencyId || properties.agency.reaAgencyId
                    )
                  : ''
              }
            />
            {showAgentAvatar && properties.agent && (
              <div className={classes.avatarContainer}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html:
                      (properties.agent.name ? properties?.agent?.name : '') ||
                      (properties.agent.firstName ? properties?.agent?.firstName : '') +
                        ' ' +
                        (properties.agent.lastName ? properties?.agent?.lastName : ''),
                  }}
                  className={classes.avatarText}
                  variant="caption"
                  component="p"
                  style={{
                    color: checkIfLight(brandColor) ? '#000000' : '#ffffff',
                  }}
                ></Typography>
                <Avatar
                  className={classes.avatarImage}
                  src={AgentImage(properties.agent.image)}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  function renderAgentBannerPropertyScreen() {
    if (isMultipleAgents) {
      return (
        <>
          {showBanner && (
            <div
              className={classes.root}
              style={{
                backgroundColor: brandColor,
                boxShadow: checkIfLight(brandColor) ? '0 0 0 1px #000000' : 'none',
              }}
            >
              <img
                className={classes.agentLogo}
                style={{ maxWidth: '190px' }}
                src={
                  properties.agency && properties.agency.logoUrl
                    ? properties.agency.logoUrl
                    : getAgencyLogo(
                        properties.reaAgencyId || properties.agency.reaAgencyId
                      )
                }
              />
            </div>
          )}
          <AgentsWrap
            style={{ maxWidth: agents && agents.length > 2 ? '100%' : '75%' }}
          >
            {showAgentAvatar &&
              agents.map((o, index) => (
                // const hasTelephone = o.telephone ? true : o.phone ? true : false
                // const telNum = o.telephone ? o.telephone : o.phone ? o.phone : null
                // if (hasTelephone) {
                //   return (
                //     <Row key={'row-' + index}>
                //       <div>
                //         <Col>
                //           <Avatar className="avatar" src={AgentImage(o.image)} />
                //         </Col>
                //         <Col>
                //           <Typography
                //             dangerouslySetInnerHTML={{ __html: o.name ? o.name : '' }}
                //             className={classes.agentName}
                //             variant="body2"
                //             component="p"
                //           ></Typography>
                //           {hasTelephone && (
                //             <Button
                //               href={o.visible ? `tel:${telNum}` : '#'}
                //               className={classes.buttonOption}
                //               startIcon={<PhoneIcon />}
                //               fullWidth
                //               variant="outlined"
                //               onClick={(e) => {
                //                 e.preventDefault()
                //                 let obj = [...agents]
                //                 obj[index].visible = !o.visible
                //                 setAgents(obj)
                //               }}
                //             >
                //               {o.visible ? telNum : 'Phone'}
                //             </Button>
                //           )}
                //         </Col>
                //       </div>
                //     </Row>
                //   )
                <Box margin="10px" key={index}>
                  <AgentBannerDisplayInfo
                    classes={classes}
                    agent={o}
                    getAgentImage={AgentImage}
                    key={'row-' + index}
                  />
                </Box>
              ))}
          </AgentsWrap>
        </>
      )
    } else {
      return (
        <>
          {showBanner && (
            <div
              className={classes.rootProperty}
              style={{
                ...props.style,
                backgroundColor: brandColor,
                //backgroundColor: getAgencyHex(properties.reaAgencyId || properties.agency.reaAgencyId),
              }}
            >
              <img
                style={{ maxWidth: '220px' }}
                className={classes.agentLogo}
                src={
                  properties.agency && properties.agency.logoUrl
                    ? properties.agency.logoUrl
                    : getAgencyLogo(
                        properties.reaAgencyId || properties.agency.reaAgencyId
                      )
                }
              />
              {showAgentAvatar && properties.agent && (
                <div className={classes.avatarContainer}>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: properties.agent.name }}
                    className={classes.avatarTextProperty}
                    variant="caption"
                    component="p"
                  ></Typography>
                  <Avatar
                    className={classes.avatarImageProperty}
                    src={AgentImage(properties.agent.image)}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )
    }
  }

  return (
    <>
      {type === 'search' && renderAgentBannerResultScreen()}
      {type === 'property' && renderAgentBannerPropertyScreen()}
    </>
  )
}

export default withStyles(styles)(AgentBanner)
