import { Button, FormControl } from '@material-ui/core'
import React from 'react'
import useFilters from 'utils/customHooks/useFilters'
import styled, { css } from 'styled-components'
import Heading from '../../Web/Filter/Heading'
import CustomCheckbox from '../../Web/Filter/CustomCheckbox'
import _ from 'lodash'
import { Typography, withStyles } from '@material-ui/core'
const Container = styled.div`
  padding: 20px 0;
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
`

const Col = styled.div`
  width: 50%;
`
const Features = (props) => {
  const { handleFeatures, isClearFilter } = props
  const [getFilter, setFilter] = useFilters()
  const FEATURES = getFilter('features')

  //add clear here
  //CLEAR_SINGLE_FILTER

  /*   useEffect(() => {
    if (props.isClearFilter) {
      //CLEAR_SINGLE_FILTER
    }
  }, [props.isClearFilter]) */

  const handleChange = (e, obj, index) => {
    let data = FEATURES.map((item) =>
      item.id === obj.id ? { ...item, active: !obj.active } : item
    )
    console.debug('features data', data)
    setFilter({
      key: 'features',
      value: data,
    })
    if (props.handleFeatures) {
      props.handleFeatures(data)
    }
  }

  return (
    <Container>
      <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
        Features
      </Heading>
      <Row>
        <Col>
          {FEATURES &&
            FEATURES.map((item, index) => {
              if (index < 4) {
                return (
                  <CustomCheckbox
                    checked={item.active}
                    label={item.label}
                    key={index}
                    onChange={(e) => {
                      handleChange(e, item, index)
                    }}
                  />
                )
              }
            })}
        </Col>
        <Col>
          {FEATURES &&
            FEATURES.map((item, index) => {
              if (index > 3) {
                return (
                  <CustomCheckbox
                    checked={item.active}
                    label={item.label}
                    key={index}
                    onChange={(e) => {
                      handleChange(e, item, index)
                    }}
                  />
                )
              }
            })}
        </Col>
      </Row>
    </Container>
  )
}

export default Features
