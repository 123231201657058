import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';

const ReferralDataCards = (props) => {
  const { textData, textLabel, marginTop, width, height } = props;

  return (
    <Card style={{ backgroundColor: '#F5F5F5', borderRadius: 5, marginTop, width, height }}>
      <CardContent style={{ textAlign: 'center' }}>
        <Typography style={{ fontWeight: 700, fontSize: 20 }}>
          {(textLabel === 'Bounce Rate' ? `${textData}${typeof textData === 'string' ? '' : '%'}` : textData) || 0}
        </Typography>
        <Typography style={{ color: '#6A6A6A' }}>{textLabel}</Typography>
      </CardContent>
    </Card>
  );
};

export default ReferralDataCards;
