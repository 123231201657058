import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Breadcrumbs, Link, withStyles } from '@material-ui/core';
import PMLogo from 'assets/images/pm-logo-inverted.png';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
    width: '98%',
  },
  content: {
    borderBottom: '0.3px solid gray',
    width: '100%',
  },
  title: {
    fontWeight: 400,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  breadGroup: {
    marginTop: 20,
  },
  link: {
    cursor: 'pointer',
  },
});

const SuperBreadCrumbs = (props) => {
  const { classes, section, crumbsLinks } = props;
  const [routerChange] = useRouterChange();

  function handleClick(event, path) {
    event.preventDefault();
    routerChange(path);
  }

  const _renderCrumbsItem = (value, index) => {
    if (value.type === 'secondary') {
      return (
        <Link className={classes.link} key={index} color="inherit" onClick={(e) => handleClick(e, value.path)}>
          {value.label}
        </Link>
      );
    }
    if (value.type === 'primary') {
      return (
        <Typography key={index} color="textPrimary">
          {value.label}
        </Typography>
      );
    }
  };

  return (
    <div className={classes.root}>
      {section !== 'Manage Agencies' && (
        <div className={classes.content}>
          <Typography className={classes.title} variant={'h1'}>
            {section}
          </Typography>
        </div>
      )}
      <Breadcrumbs
        className={classes.breadGroup}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {crumbsLinks &&
          crumbsLinks.map((value, index) => {
            return _renderCrumbsItem(value, index);
          })}
      </Breadcrumbs>
    </div>
  );
};

SuperBreadCrumbs.propTypes = {
  classes: PropTypes.object,
  section: PropTypes.string.isRequired,
  crumbsLinks: PropTypes.array,
};

export default withStyles(styles)(SuperBreadCrumbs);
