export function reform(type, params) {
    switch (type) {
        case 'Property_Type':
            return propertyTypeFormat(params);
        case 'Keywords':
            return filterKeywords(params);
        case 'Suburbs':
            return filterSuburbs(params);
        case 'All':
            return filterAll(params);
        case 'Likes':
            return filterLikes(params);
        case 'Dislikes':
            return filterDislikes(params);
        case 'Swipe_Tallies':
            return filterSwipeTallies(params);
        case 'Drill_Down_Tallies':
            return filterDrillDownTallies(params);

    }
}

function propertyTypeFormat(object) {
    let newObject = [];
    if (object) {
        object.map((value) => {
            if (value.title && value.active) newObject.push(value.title);
        })
    }
    return newObject;
}

function filterKeywords(string) {
    if (string !== '') {
        return string.split(",");
    } else {
        return [];
    }
}

function filterSuburbs(object) {
    let newObject = [];
    object.map((value) => {
        if (value.postcode && value.locality && value.state) {
            newObject.push({
                'postcode': value.postcode,
                'suburb': value.locality,
                'state': value.state
            });
        }
    })
    return newObject;
}

function filterAll(params) {
    let modParams = params;
    Object.keys(modParams).forEach(function (key) {
        if (modParams[key] === false || modParams[key] === undefined || modParams[key] === '' || modParams[key] === null) {
            delete modParams[key];
        }
    })
    return modParams;
}

function filterLikes(object) {
    let newObject = [];
    object.map((value) => {
        if (value) {
            newObject.push(value.property);
        }
    })
    return newObject;
}

function filterDislikes(object) {
    let newObject = [];
    object.map((value) => {
        if (value) {
            newObject.push(value.property);
        }
    })
    return newObject;
}

function filterSwipeTallies(obj) {
    let params = {
        bedrooms: {
            likes: 0,
            dislikes: 0
        },
        bathrooms: {
            likes: 0,
            dislikes: 0
        },
        close_to_parks: {
            likes: 0,
            dislikes: 0
        },
        close_to_shops: {
            likes: 0,
            dislikes: 0
        },
        close_to_universities: {
            likes: 0,
            dislikes: 0
        },
        close_to_primary_schools: {
            likes: 0,
            dislikes: 0
        },
        close_to_secondary_schools: {
            likes: 0,
            dislikes: 0
        },
        close_to_public_transports: {
            likes: 0,
            dislikes: 0
        },
        close_to_cafes: {
            likes: 0,
            dislikes: 0
        },
        foody_area: {
            likes: 0,
            dislikes: 0
        },
        inner_city_vibe: {
            likes: 0,
            dislikes: 0
        },
        has_gas: {
            likes: 0,
            dislikes: 0
        },
        has_balcony: {
            likes: 0,
            dislikes: 0
        },
        has_study: {
            likes: 0,
            dislikes: 0
        },
        has_airconditioning: {
            likes: 0,
            dislikes: 0
        },
        has_wardrobe: {
            likes: 0,
            dislikes: 0
        },
        has_courtyard: {
            likes: 0,
            dislikes: 0
        },
        has_laundry: {
            likes: 0,
            dislikes: 0
        },
        has_pool: {
            likes: 0,
            dislikes: 0
        },
        is_established: {
            likes: 0,
            dislikes: 0
        },
        is_subscription_platinum: {
            likes: 0,
            dislikes: 0
        },
        is_subscription_premium: {
            likes: 0,
            dislikes: 0
        },
        is_subscription_standard: {
            likes: 0,
            dislikes: 0
        },
        is_type_house: {
            likes: 0,
            dislikes: 0
        },
        is_type_apartment: {
            likes: 0,
            dislikes: 0
        },
        is_type_townhouse: {
            likes: 0,
            dislikes: 0
        },
        is_type_retirement_living: {
            likes: 0,
            dislikes: 0
        },
        is_type_villa: {
            likes: 0,
            dislikes: 0
        },
        is_type_block_of_units: {
            likes: 0,
            dislikes: 0
        },
        is_type_land: {
            likes: 0,
            dislikes: 0
        },
        is_type_acreage: {
            likes: 0,
            dislikes: 0
        },
        is_type_rural: {
            likes: 0,
            dislikes: 0
        },
        parking: {
            likes: 0,
            dislikes: 0
        },
        price_bargain: {
            likes: 0,
            dislikes: 0
        },
        price_cheap: {
            likes: 0,
            dislikes: 0
        },
        price_normal: {
            likes: 0,
            dislikes: 0
        },
        price_expensive: {
            likes: 0,
            dislikes: 0
        },
        price_luxury: {
            likes: 0,
            dislikes: 0
        }
    }
    if (obj.likes && obj.likes.length > 0) {
        obj.likes.map((value, index) => {
            let dataCoff = obj.likes[index].coefficients;
            for (var coefficients in dataCoff) {
                if (dataCoff[coefficients] >= 0.7) {
                    params[coefficients].likes = params[coefficients].likes + 1
                }
            }
        })
    }
    if (obj.dislikes && obj.dislikes.length > 0) {
        obj.dislikes.map((value, index) => {
            let dataCoff = obj.dislikes[index].coefficients;
            for (var coefficients in dataCoff) {
                if (dataCoff[coefficients] >= 0.7) {
                    params[coefficients].dislikes = params[coefficients].dislikes + 1
                }
            }
        })
    }
    return params
}

function filterDrillDownTallies(obj) {
    let params = {
        bedrooms: {
            likes: [0],
            dislikes: [0]
        },
        bathrooms: {
            likes: [0],
            dislikes: [0]
        },
        close_to_parks: {
            likes: [0],
            dislikes: [0]
        },
        close_to_shops: {
            likes: [0],
            dislikes: [0]
        },
        close_to_universities: {
            likes: [0],
            dislikes: [0]
        },
        close_to_primary_schools: {
            likes: [0],
            dislikes: [0]
        },
        close_to_secondary_schools: {
            likes: [0],
            dislikes: [0]
        },
        close_to_public_transports: {
            likes: [0],
            dislikes: [0]
        },
        close_to_cafes: {
            likes: [0],
            dislikes: [0]
        },
        foody_area: {
            likes: [0],
            dislikes: [0]
        },
        inner_city_vibe: {
            likes: [0],
            dislikes: [0]
        },
        has_gas: {
            likes: [0],
            dislikes: [0]
        },
        has_balcony: {
            likes: [0],
            dislikes: [0]
        },
        has_study: {
            likes: [0],
            dislikes: [0]
        },
        has_airconditioning: {
            likes: [0],
            dislikes: [0]
        },
        has_wardrobe: {
            likes: [0],
            dislikes: [0]
        },
        has_courtyard: {
            likes: [0],
            dislikes: [0]
        },
        has_laundry: {
            likes: [0],
            dislikes: [0]
        },
        has_pool: {
            likes: [0],
            dislikes: [0]
        },
        is_established: {
            likes: [0],
            dislikes: [0]
        },
        is_subscription_platinum: {
            likes: [0],
            dislikes: [0]
        },
        is_subscription_premium: {
            likes: [0],
            dislikes: [0]
        },
        is_subscription_standard: {
            likes: [0],
            dislikes: [0]
        },
        is_type_house: {
            likes: [0],
            dislikes: [0]
        },
        is_type_apartment: {
            likes: [0],
            dislikes: [0]
        },
        is_type_townhouse: {
            likes: [0],
            dislikes: [0]
        },
        is_type_retirement_living: {
            likes: [0],
            dislikes: [0]
        },
        is_type_villa: {
            likes: [0],
            dislikes: [0]
        },
        is_type_block_of_units: {
            likes: [0],
            dislikes: [0]
        },
        is_type_land: {
            likes: [0],
            dislikes: [0]
        },
        is_type_acreage: {
            likes: [0],
            dislikes: [0]
        },
        is_type_rural: {
            likes: [0],
            dislikes: [0]
        },
        parking: {
            likes: [0],
            dislikes: [0]
        },
        price_bargain: {
            likes: [0],
            dislikes: [0]
        },
        price_cheap: {
            likes: [0],
            dislikes: [0]
        },
        price_normal: {
            likes: [0],
            dislikes: [0]
        },
        price_expensive: {
            likes: [0],
            dislikes: [0]
        },
        price_luxury: {
            likes: [0],
            dislikes: [0]
        }
    }
    if (obj.likes && obj.likes.length > 0) {
        obj.likes.map((value, index) => {
            let dataCoff = obj.likes[index].coefficients;
            for (var coefficients in dataCoff) {
                if (dataCoff[coefficients] >= 0.7) {
                    params[coefficients].likes[0] = params[coefficients].likes[0] = params[coefficients].likes[0] + 1;
                }
            }
        })
    }
    if (obj.dislikes && obj.dislikes.length > 0) {
        obj.dislikes.map((value, index) => {
            let dataCoff = obj.dislikes[index].coefficients;
            for (var coefficients in dataCoff) {
                if (dataCoff[coefficients] >= 0.7) {
                    params[coefficients].dislikes[0] = params[coefficients].dislikes[0] = params[coefficients].dislikes[0] + 1;
                }
            }
        })
    }
    if (obj.likes_drill_down && obj.likes_drill_down.length > 0) {
        obj.likes_drill_down.map((value, index) => {
            let dataCoff = obj.likes_drill_down[index].coefficients;
            for (var coefficients in dataCoff) {
                if (dataCoff[coefficients] >= 0.7) {
                    if (params[coefficients].likes.length === 2) {
                        params[coefficients].likes[1] = params[coefficients].likes[1] = params[coefficients].likes[1] + 1;
                    } else {
                        params[coefficients].likes.push(1);
                    }
                }
            }
        })
    }
    return params
}