import React from 'react'
import styled, { css } from 'styled-components'

import AgentInfo from './AgentInfo'

import { withStyles, Typography, Card, Avatar } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const AgentContainer = styled.div`
  height: 100vh;
  width: 100%;
`

const StyledText = withStyles({
  root: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
  },
})(Typography)

const StyledSubText = withStyles({
  root: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '15px',
    lineHeight: '1.19',
    padding: '15px',
    zIndex: 10,
    position: 'relative',
  },
})(Typography)

const Wrap = styled.div`
  width: 90vw;
  padding-top: 20px;
`

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: 'auto',
    position: 'relative',
  },
  card: {
    width: '45vw',
    maxHeight: '68vw',
    //marginLeft: theme.spacing(2),
    margin: theme.spacing(1),
  },
  profileImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: '#FFF',
  },
  media: {
    top: 15,
    width: '4.000em',
    height: '4.000em',
    borderRadius: '2.000em',
    objectFit: 'cover',
    marginRight: theme.spacing(1),
  },
  agencyBanner: {
    width: '100%',
    height: 40,
    backgroundColor: 'black',
  },
  agentLogo: {
    height: '2.857em',
    width: 'auto',
    backgroundColor: 'transparent',
    imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    // imageRendering: 'pixelated',
    imageRendering: '-webkit-optimize-contrast',
  },
  favoriteContainer: {
    width: '0.625em',
    height: '0.625em',
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
    width: '0.625em',
    height: '0.625em',
  },
})

const AgentCards = (props) => {
  const { classes, type, agent } = props
  const [routerChange] = useRouterChange()
  function renderAgentCard() {
    return (
      <div
        className={classes.root}
        onClick={() => routerChange('/agent/results/' + agent.id)}
      >
        <Card className={classes.card}>
          <div className={classes.profileImageContainer}>
            <Avatar className={classes.media} src={agent.profileImage} />
          </div>
          <div className={classes.agencyBanner} style={{ backgroundColor: agent.bg }}>
            <img
              className={classes.agentLogo}
              src={
                'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png'
              }
            />
          </div>
          <AgentInfo type={type} agent={agent} />
        </Card>
      </div>
    )
  }

  return <>{type === 'agent-card' && renderAgentCard()}</>
}

export default withStyles(styles)(AgentCards)
