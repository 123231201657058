import React, { useState, useEffect } from 'react'

import DreamPropertyCards from './DreamPropertyCards'
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import { withStyles, Typography } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(10.6),
    paddingLeft: 31,

    '@media (max-width: 1440px)': {
      paddingLeft: '50px',
    },

    '@media (max-width: 1380px)': {
      paddingLeft: '8%',
    },
  },
  headerText: {
    fontSize: '1.5em',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 400,
    fontFamily: 'Montserrat, sans',
    letterSpacing: '-0.52 px',
  },
  cardContainer: {
    display: 'flex',
    width: 'auto',
    overflow: 'hidden',
    '& .BrainhubCarousel__arrows': {
      backgroundColor: 'transparent',
      '& span': {
        borderColor: '#cdcdcd',
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },
    },
    '& .BrainhubCarousel__arrows:hover': {
      backgroundColor: 'transparent',
      '& span': {
        borderColor: theme.palette.primary.main,
      },
    },

    '@media (min-width: 1024px)': {
      '& .MuiCard-root': {
        maxWidth: '26vw',
      },
    },
  },
})

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

const DreamCarousel = (props) => {
  const {
    classes,
    properties,
    settings,
    authentication,
    apiRequestSaveProperty,
    toggleSignUp,
  } = props

  const { width } = useWindowDimensions()

  const [dreamProperties, setDreamProperties] = useState(null)

  useEffect(() => {
    setDreamProperties(properties)
  }, [properties])

  return (
    <div className={classes.root}>
      {dreamProperties && dreamProperties.length > 0 && (
        <div>
          <Typography className={classes.headerText} variant="h4" component="h4">
            {'Handpicked for your viewing pleasure...'}
          </Typography>
          <div className={classes.cardContainer}>
            <Carousel
              plugins={[
                'arrows',
                'infinite',
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: width < 1000 ? 1 : width < 1020 ? 2 : 3,
                  },
                },
              ]}
            >
              {dreamProperties.map((value, index) => {
                return (
                  <DreamPropertyCards
                    key={index}
                    properties={value}
                    type={'properties'}
                    toggleSignUp={toggleSignUp}
                    authentication={authentication}
                    settings={settings}
                    apiRequestSaveProperty={apiRequestSaveProperty}
                  />
                )
              })}
            </Carousel>
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(DreamCarousel)
