import React from 'react';
import styled, { css } from 'styled-components'

import AgentSearchInput from './AgentSearchInput';
import SortSelection from './SortSelection';

import { withStyles, Typography } from '@material-ui/core';

const Main = styled.div`
    z-index: 6;
    background-color: '#FFF';
    position: relative;
    border-radius: 4;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #c3c3c3;
`

const AgentSearchBar = props => {  

    const {
        type,
        agents,
        agencies,
        agentsData,
        category,
        agentReducer,
        clearAgentSearchParams,
    } = props;

    const [showModalBottomOptions, setShowModalBottomOptions] = React.useState(false);

    function showOptionBottomModal(){
        setShowModalBottomOptions(!showModalBottomOptions)
    }

    return (
        <Main>
            <Container>
                <AgentSearchInput
                    match={props.match}
                    clearAgentSearchParams={clearAgentSearchParams}
                    agentReducer={agentReducer}
                    agents={agents}
                    agencies={agencies}
                    category={category}
                    showInlineSearchButton={props.showInlineSearchButton}
                    apiRequestSearchAgent={props.apiRequestSearchAgent}/>
            </Container>
            <Container>
            {
                !props.showInlineSearchButton && agentsData && agentsData.length > 0 &&
                <SortSelection
                    sortType={'agent-results'} 
                    current={0}
                    total={0}
                    setSearchSortingFilters={null} 
                    filters={null}
                    showModalBottomOptions={showModalBottomOptions}
                    showOptionBottomModal={showOptionBottomModal}
                />
            }
            </Container>
        </Main>
    );
}

export default (AgentSearchBar);