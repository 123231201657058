import React from 'react';
import _ from 'lodash';

import ChipInput from 'material-ui-chip-input';

import SearchIcon from '@material-ui/icons/Search';

import SUBURBS from '../../../assets/data/suburbs-all-new.json';

import { makeStyles, Paper, List, ListItem, ListItemText, IconButton, Button, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: '#FFF',
    position: 'relative',
    zIndex: 9,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    border: '1px solid #9B9B9B',
    position: 'relative',
    zIndex: 20,
  },
  chip: {
    backgroundColor: '#f5f5f5',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 0,
    height: '2.875em',
    fontSize: '0.875em',
  },
  chipContainer: {
    width: '90%',
    height: 50,
    flexFlow: 'nowrap',
    overflowX: 'scroll',
    alignSelf: 'center',
    border: 4,
  },
  input: {
    zIndex: 20,
    textAlign: 'left',
    width: '100%',
    fontSize: '0.875em',
  },
  iconButton: {
    alignSelf: 'stretch',
    position: 'relative',
    padding: '0.625em',
    backgroundColor: '#11c0c9',
    borderRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    '&:hover': {
      backgroundColor: '#11c0c9',
    },
  },
  iconSearch: {
    color: '#FFF',
  },
  iconLoading: {
    color: '#FFF',
  },
  iconTune: {
    color: '#000',
  },
  listContainer: {
    position: 'relative',
  },
  list: {
    position: 'absolute',
    zIndex: 999,
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '11.250em',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  optionButton: {
    textTransform: 'none',
    width: 100,
    height: 35,
    marginTop: theme.spacing(2),
    borderRadius: '1.250em',
    color: '#000',
    fontWeight: 'normal',
    float: 'right',
  },
}));

const AgentSearchInput = (props) => {
  const [searchText, setSearchText] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [selectedChipValue, setSelectedChipValue] = React.useState([]);
  const [resultArr, setResultArr] = React.useState(null);
  const [filteredCalled, setFilterCalled] = React.useState(false);
  const [isSuburbRemoveCalled, setIsSuburbRemoveCalled] = React.useState(false);
  const [ogData, setOGData] = React.useState([]);

  const classes = useStyles();

  let timeout;

  React.useEffect(() => {
    if (props.isClearFilter) {
      setSearchText('');
      setResultArr(null);
      setSelectedValue([]);
      setFilterCalled(false);
      setSelectedChipValue([]);
    }
  }, [props.isClearFilter]);

  React.useEffect(() => {
    if (props.category) {
      setSelectedValue([{ category: props.category }]);
    }
  }, [props.category]);

  React.useEffect(() => {
    if (props.agentReducer) {
      if (props.agentReducer && props.agentReducer.search_params) {
        if (props.agentReducer.search_params && props.agentReducer.search_params.name) {
          addSelection(props.agentReducer.search_params);
        }
      }
    }
  }, [props.agentReducer]);

  React.useEffect(() => {
    let newData = _.union(SUBURBS, props.agencies);
    let newnewData = _.union(newData, props.agents);

    setOGData(newnewData);
  }, [props]);

  function addSelection(value) {
    /** Set Selected Values */
    let selected = selectedValue;
    selected = [{ ...value, name: value.name, category: props.category }];
    setSelectedValue(selected);

    /** Set Property Suburb Search */
    /** Set Chip Values */
    let selectedChip = selectedChipValue;
    selectedChip = [value.name];
    setSelectedChipValue(selectedChip);

    /** Reset Results and search text */
    setResultArr(null);

    setSearchText('');
  }

  function removeSelected(value, index) {
    /** Filter and Remove Matched Object from Selection */
    let selected = _.filter(selectedValue, function (n) {
      return n.name !== value;
    });
    setSelectedValue(selected);

    /** clear search params */
    props.clearAgentSearchParams();

    /** Set Chip Values */
    let selectedChip = _.filter(selectedChipValue, function (n) {
      return n !== value;
    });
    setSelectedChipValue(selectedChip);
    setIsSuburbRemoveCalled(true);
  }

  function filterResult() {
    setFilterCalled(true);
    /** Set filtered suburb based from results */
    let results = [];
    let suburb = _.filter(ogData, (d) => {
      /** result screen search */
      if (props.match && props.match.path !== '/agent/results') {
        if (d.type === 'agency' && props.match && props.match.path !== '/agent/results') {
          if (d.companyName && d.companyName.match(searchText.toLowerCase())) {
            results.push({ ...d, id: d._id, name: d.companyName, type: 'agency' });
          }
        } else if (d.type === 'agent' && props.match && props.match.path !== '/agent/results') {
          if (d.firstName && d.firstName.match(searchText.toLowerCase())) {
            results.push({
              ...d,
              id: d._id,
              name: d.firstName + ' ' + d.lastName,
              type: 'agent',
            });
          }
        } else {
          if (d.locality || d.postcode) {
            if (
              d.locality.toLowerCase().startsWith(searchText.toLowerCase()) ||
              d.postcode.toLowerCase() === searchText.toLowerCase()
            ) {
              results.push({
                ...d,
                id: d.id,
                name: d.locality + ', ' + d.state + ', ' + d.postcode,
                type: 'suburb',
              });
            }
          }
        }
      } else {
        /** landing screen search */
        if (d.type === 'agency') {
          if (d.companyName && d.companyName.match(searchText.toLowerCase())) {
            results.push({ ...d, id: d._id, name: d.companyName, type: 'agency' });
          }
        } else if (d.type === 'agent') {
          if (d.firstName && d.firstName.match(searchText.toLowerCase())) {
            results.push({
              ...d,
              id: d._id,
              name: d.firstName + ' ' + d.lastName,
              type: 'agent',
            });
          }
        } else {
          if (d.locality || d.postcode) {
            if (
              d.locality.toLowerCase().startsWith(searchText.toLowerCase()) ||
              d.postcode.toLowerCase() === searchText.toLowerCase()
            ) {
              results.push({
                ...d,
                id: d.id,
                name: d.locality + ', ' + d.state + ', ' + d.postcode,
                type: 'suburb',
              });
            }
          }
        }
      }
    });

    /** Filter and Remove Matched Object from Selection */
    var output = _.filter(results, (el) => {
      return (
        _.findIndex(selectedValue, (elem) => {
          return elem.id === el.id || elem._id === el._id;
        }) === -1
      );
    });

    setResultArr(output);
    clearTimeout(timeout);
    setFilterCalled(false);
  }

  React.useEffect(() => {
    if (searchText.length > 0 && !filteredCalled) {
      filterResult();
    } else {
      setResultArr(null);
    }
  }, [searchText]);

  function handleChange(e) {
    clearTimeout(timeout);
    setSearchText(e.target.value);
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.root}>
        <ChipInput
          clearInputValueOnChange={true}
          fullWidth
          fullWidthInput={false}
          classes={{
            chipContainer: classes.chipContainer,
            input: classes.input,
            chip: classes.chip,
          }}
          disableUnderline={true}
          placeholder={
            props.showInlineSearchButton
              ? 'Suburb / postcode / agency or agents name'
              : 'Add postcode / suburb / agency or agents name'
          }
          value={selectedChipValue}
          onDelete={(chip, index) => removeSelected(chip, index)}
          InputProps={{
            onChange: (event) => {
              handleChange(event);
            },
            onBlur: () => {
              if (!resultArr || resultArr.length === 0) {
                setSearchText('');
              }
            },
            value: searchText,
          }}
        />
        {props.showInlineSearchButton && (
          <Tooltip title="Search">
            <IconButton className={classes.iconButton} onClick={() => props.apiRequestSearchAgent(selectedValue)}>
              <SearchIcon className={classes.iconSearch} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Paper className={classes.listContainer}>
        {resultArr && resultArr.length > 0 && (
          <List dense={true} className={classes.list}>
            {resultArr &&
              resultArr.map((value, index) => {
                return (
                  <ListItem key={index} onClick={() => addSelection(value)}>
                    <ListItemText primary={value.name} />
                  </ListItem>
                );
              })}
          </List>
        )}
      </Paper>
      {!props.showInlineSearchButton && (
        <Button
          className={classes.optionButton}
          size={'small'}
          color={'primary'}
          variant={'contained'}
          onClick={() => props.apiRequestSearchAgent(selectedValue)}
        >
          {'Search'}
        </Button>
      )}
    </div>
  );
};

export default AgentSearchInput;
