import React from 'react';
import { Backdrop, CircularProgress, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
  },
  loadingText: {
    paddingTop: 10,
  }
}));

export default function Loader(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.isLoading}>
        <CircularProgress color="primary" />
        <Typography className={classes.loadingText} variant="body2" component="h2">
            {props.text ? props.text : 'Please wait while we load your properties'}
        </Typography>
      </Backdrop>
    </div>
  );
}