import _ from 'lodash'
import React from 'react'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { Box, Button, makeStyles, Typography } from '@material-ui/core'

import { toggleFilter } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthentication } from 'store/selectors/authentication.selectors'
import { getSettings } from 'store/selectors/generic.selectors'

import RouteButtons from 'components/Common/GlobalFilters/RouteButtons'
import SearchbarFilter from 'components/Common/GlobalFilters/SearchbarFilter'
import DynamicIcons from 'components/Mobile/DynamicIcons'
import SavedSearch from 'components/Web/SavedSearch'
import Property from 'components/Mobile/Property'
import { toggleSignUp } from 'store/actions'
import { setCurrentProperty } from 'store/actions'
import { getSearchResults } from 'utils/search/selectors/selectors.search'
import { useEffect } from 'react'
import { getPagination } from 'utils/search/selectors/selectors.search'
import { searchActions } from 'store/actions'
import { getProperties } from 'store/selectors/search.selectors'
import { requestPUTAPIMpp } from 'store/api/mpp.api'
import { getAllFilters } from 'utils/search/selectors/selectors.search'
import useFilters from 'utils/customHooks/useFilters'
import { useState } from 'react'
import Loader from 'components/Mobile/Loader'
import FilterOptionsV2 from 'components/Mobile/Searchbar/FilterOptionsV2'
import AlertNotification from 'components/Mobile/Form/AlertNotification'
import SortByProperties from 'components/Common/SortByProperties'
import useConvertUrlToFilters from 'utils/customHooks/useConvertUrlToFilters'

import useGoogleEvents from 'utils/customHooks/useGoogleEvents'
import { StorageActions } from 'store/actions/storage.actions'

const useStyles = makeStyles((theme) => ({
  //Back button
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },

  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
}))

const RELATED_ROUTES = ['off-market', 'hush-hush', 'coming-soon', 'buy']
const DEFAULT_PAGINATION = {
  pageNumber: 0,
  pageSize: 20,
  propertiesFilters: [],
  totalResults: null,
}

const SuburbSearchText = ({ searchPath, currentResultsLength, totalResultsLength }) => {
  const [getFilter, setFilter] = useFilters()

  const SUBURBS = getFilter('suburbs')
  const INCLUDE_NEARBY_SUBURBS = getFilter('include_nearby_suburbs')

  let pathMessage

  switch (searchPath) {
    case 'buy':
      pathMessage = 'On Sale'
      break

    case 'sold':
      pathMessage = 'Sold'
      break

    case 'rent':
      pathMessage = 'Rental'
      break

    case 'coming-soon':
      pathMessage = 'Coming Soon'
      break

    case 'off-market':
      pathMessage = 'Off Market'
      break

    case 'hush-hush':
      pathMessage = 'Hush Hush'
      break
  }

  return (
    // <Box>
    //   <Typography
    //     style={{ fontSize: '14px' }}
    //   >{`Showing ${currentResultsLength} of ${totalResultsLength} ${pathMessage} properties found in ${
    //     INCLUDE_NEARBY_SUBURBS ? 'and around' : ''
    //   } ${SUBURBS.length} suburb${SUBURBS.length > 1 ? 's' : ''} `}</Typography>
    // </Box>
    <Box>
      <Typography style={{ fontSize: '14px' }}>
        {`Showing ${currentResultsLength} of ${totalResultsLength} ${pathMessage} properties found ${
          SUBURBS.length > 0
            ? `in ${INCLUDE_NEARBY_SUBURBS ? 'and around' : ''} ${
                SUBURBS.length
              } suburb${SUBURBS.length > 1 ? 's' : ''}`
            : ''
        }`}
      </Typography>
    </Box>
  )
}

const SearchResultsV3 = () => {
  const classes = useStyles()

  const [notificationStatus, setNotificationStatus] = useState({
    status: false,
    options: null,
  })

  const [showModalOptions, setShowModalOptions] = useState(false)
  const [selectedFilterType, setSelectedFilterType] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isInputFocused, setIsInputFocused] = React.useState(false)

  const [isFiltersFromUrl, setIsFiltersFromUrl] = useConvertUrlToFilters()

  const dispatch = useDispatch()
  const property = useSelector(getProperties)

  const authentication = useSelector(getAuthentication)
  const settings = useSelector(getSettings)
  const searchProperties = useSelector(getSearchResults)
  const pagination = useSelector(getPagination)
  const filters = useSelector(getAllFilters)

  const history = useHistory()
  const path = history.location.pathname?.split('/')[1] || ''

  const topContainer = useRef(null)

  const { getSearchData, triggerAnalyticsEvent } = useGoogleEvents()

  const getRoutePath = () => {
    return history.location.pathname.split('/')[1] || ''
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      dispatch(StorageActions(null, 'GENERAL_SETTINGS', 'SET_FROM_INNERPAGE'))
      dispatch(StorageActions(0, 'GENERAL_SETTINGS', 'SET_SCROLL_POSITION'))
    }
  }, [])

  const closeNotification = () => {
    setNotificationStatus({ status: false, options: null })
  }

  const showOptionModal = (selected, closeModal) => {
    if (closeModal) {
      setShowModalOptions(false)
      setSelectedFilterType('')
      toggleFilter(false)
    } else {
      setShowModalOptions(true)
      toggleFilter(true)
      setSelectedFilterType(selected)
    }
  }
  const handleToggleSignUp = (checked) => dispatch(toggleSignUp(checked))
  const handleSetCurrentProperty = (params) => dispatch(setCurrentProperty(params))
  const saveProperty = (params) =>
    dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'))

  async function apiRequestSearchProperty() {}

  const paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size)
  }

  const handlePagination = (customData) => {
    const nextPage = customData ? pagination?.pageNumber : pagination?.pageNumber + 1
    const theSearchData = customData
      ? customData
      : searchProperties?.narrowed_results?.data ??
        searchProperties?.search_results?.data
    const filterData = pagination?.propertiesFilters.concat(
      paginate(theSearchData.propertiesFilters, pagination?.pageSize, nextPage)
    )

    const pageData = isFiltersFromUrl
      ? {
          pageNumber: nextPage,
          pageSize: pagination?.pageSize,
          propertiesFilters: _.uniqBy(
            paginate(
              searchProperties?.search_results?.data?.propertiesFilters ?? [],
              pagination?.pageSize,
              nextPage
            ),
            'property'
          ),
          totalResults: searchProperties?.search_results?.data?.totalResults,
        }
      : {
          pageNumber: nextPage,
          pageSize: pagination?.pageSize,
          propertiesFilters: _.uniqBy(filterData, 'property'),
          totalResults:
            searchProperties?.narrowed_results?.data?.totalResults > 0
              ? searchProperties?.narrowed_results?.data?.totalResults
              : pagination?.totalResults ??
                searchProperties?.search_results?.data?.totalResults,
        }

    if (isFiltersFromUrl) {
      setIsFiltersFromUrl(false)
    }

    dispatch(searchActions(pageData, 'PAGINATION_REFERENCE', 'UPDATE'))
  }

  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    // setIsLoading(true)

    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    }

    saveProperty(params)
  }

  useEffect(() => {
    if (searchProperties?.status === 'search/POST_PROPERTY_SEARCH_REQUEST') {
      setIsLoading(true)
    } else if (searchProperties?.status === 'search/POST_PROPERTY_SEARCH_SUCCESS') {
      setIsLoading(false)
      dispatch(searchActions(null, 'FUNNEL_DOWN_SEARCH', 'CLEAR'))
      dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'))
      triggerAnalyticsEvent({
        event: 'results_actions',
        eventFrom: 'Mobile',
        actionScope: 'search_results',
        actionName: 'Search Results',
        actionTarget: 'Showed search results from ' + getSearchData()?.search_reference,
        actionReference: 'results',
        ...getSearchData(),
        results_total: searchProperties?.search_results?.data?.totalResults,
      })
    }
  }, [searchProperties])

  useEffect(() => {
    if (searchProperties?.search_results?.data) {
      handlePagination(searchProperties?.search_results?.data)
    }
    return () => {}
  }, [searchProperties?.search_results?.data])

  React.useEffect(() => {
    if (searchProperties?.narrowed_results?.data) {
      handlePagination(searchProperties?.narrowed_results?.data)
      triggerAnalyticsEvent({
        event: 'results_actions',
        eventFrom: 'Web',
        actionScope: 'search_results',
        actionName: 'Search Results',
        actionTarget: 'Showed search results from ' + getSearchData()?.search_reference,
        actionReference: 'results',
        ...getSearchData(),
        results_total: searchProperties?.narrowed_results?.data?.totalResult,
        search_direction: searchProperties?.narrowed_results ? 'narrowed' : undefined,
      })
    }
    return () => {}
  }, [searchProperties?.narrowed_results?.data])

  window.addEventListener('unload', function (e) {
    dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'))
    dispatch(searchActions(null, 'GENERAL_SEARCH', 'RESET_STATES'))
  })

  return (
    <Box id="test">
      <Box display="flex" flexDirection="column" paddingX="10px">
        <AlertNotification
          status={notificationStatus?.status}
          options={notificationStatus?.options}
          closeNotification={closeNotification}
        />

        {/*   <AlertNotification
          status={isNotification}
          options={notificationOptions}
          closeNotification={closeNotification}
        />

           <Loader isLoading={isLoading} />
        <Loader
          isLoading={offmarketComingsoonLoading}
          text={'Updating offmarket/coming soon visibility...'}
        /> */}

        <Loader isLoading={isLoading} />

        <div ref={topContainer} className={classes.topAnchor}></div>

        <Box className={classes.backButtonContainer}>
          <Button
            style={{ textTransform: 'capitalize', padding: '0px' }}
            variant="text"
            disableRipple={true}
            fullWidth={false}
            onClick={() => history.goBack()}
          >
            <DynamicIcons icon="ChevronLeft" /> GO BACK
          </Button>
        </Box>

        <Box display="flex" width="100%" padding="10px">
          <SearchbarFilter />
        </Box>

        <Box zIndex={1}>
          <FilterOptionsV2
            type={'search'}
            navigateFilterButtonTo={'/' + getRoutePath() + '/filters'}
            showModalOptions={showModalOptions}
            showOptionModal={showOptionModal}
            setIsInputFocused={setIsInputFocused}
            path={path}
            toggleFilter={toggleFilter}
            selectedFilterType={selectedFilterType}
          />
        </Box>

        <Box padding="10px" style={{ backgroundColor: 'white' }}>
          {pagination.propertiesFilters.length > 0 && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="10px"
            >
              <Box marginRight="10px">
                <SuburbSearchText
                  searchPath={path}
                  currentResultsLength={pagination.propertiesFilters.length}
                  totalResultsLength={pagination.totalResults}
                />
              </Box>
              <SavedSearch path={path} />
            </Box>
          )}

          <Box
            display="flex"
            flexDirection="row"
            marginTop="15px"
            alignItems="center"
            marginBottom="10px"
          >
            {RELATED_ROUTES.includes(path) && <RouteButtons />}
            <SortByProperties />
          </Box>

          {/* {pagination.propertiesFilters.length > 1 && (
            <Box paddingX="10px">
              <Typography
                style={{ fontSize: '12px' }}
              >{`Showing ${pagination.propertiesFilters.length} of ${pagination.totalResults} properties`}</Typography>
            </Box>
          )} */}
        </Box>
      </Box>

      <Box
        id="cards-scroll-column"
        style={{ backgroundColor: '#f5f5f5' }}
        paddingBottom="20px"
      >
        <Property
          authentication={authentication}
          settings={settings}
          toggleSignUp={handleToggleSignUp}
          setCurrentProperty={handleSetCurrentProperty}
          skipTakeData={pagination ?? DEFAULT_PAGINATION}
          properties={pagination ?? DEFAULT_PAGINATION}
          property={property}
          // isFiltered={isFiltered}
          isLoading={isLoading}
          currentScreen={getRoutePath()}
          apiRequestSaveProperty={apiRequestSaveProperty}
          handlePagination={handlePagination}
          listingType={getRoutePath()}
          apiRequestSearchProperty={apiRequestSearchProperty}
          filters={filters}
          history={history}
        />
      </Box>
    </Box>
  )
}

export default SearchResultsV3
