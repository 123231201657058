import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function FacebookIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path id="Fill-1" style={{fillRule:"evenodd", clipRule:"evenodd", fill:'#4267B2'}} d="M16.6,9.6h-3.2V7.7C13.6,5.9,17,6.3,17,6.3l0-0.1l0,0V3.2h0v0h0c-0.1,0-6.7-1.4-8,3c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0.5-0.2,3-0.2,3.4H6v3.3h3V22h4.3v-9.1h3.3L17,9.6H16.6z"/>
    </SvgIcon>
  );
}

export function FacebookIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FacebookIcon />
      <FacebookIcon color="primary" />
      <FacebookIcon color="secondary" />
      <FacebookIcon color="action" />
      <FacebookIcon color="disabled" />
      <FacebookIcon style={{ color: green[500] }} />
    </div>
  );
}
