import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';

export const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100%',
    paddingLeft: '10vw',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'rgb(197,197,197)',
  },
  title: {
    marginTop: 10,
    fontWeight: 600,
    fontSize: '24px',
    letterSpacing: '-0.525px',
    lineHeight: '28px',
    textAlign: 'left',
    color: 'rgb(37, 51, 71)',
    textTransform: 'capitalize',
    paddingLeft: 20,
  },
  textContentBold: {
    fontWeight: 600,
    fontSize: '20px',
    letterSpacing: '-0.525px',
    lineHeight: '24.3px',
    color: 'rgb(37, 51, 71)',
    //textTransform: 'capitalize',
  },
  textContentNormal: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.525px',
    lineHeight: '19.5px',
    color: 'rgba(56, 56, 56, 1)',
    //textTransform: 'capitalize',
  },
  bottomSpace: {
    height: 100,
  },
  tableContainer: {
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    alignContent: 'flex-start',
    //backgroundColor: 'rgb(197,197,197)',
  },
  tableRowContainer: { display: 'flex', flexDirection: 'row', marginBottom: 20 },
  tableColumnContainer: { display: 'flex', flexDirection: 'column' },
  coloredTableColumn: {
    borderRadius: 10,
    flexDirection: 'column',
    width: 383,
    padding: 20,
    backgroundColor: 'white',
    marginTop: 20,
    marginRight: 40,
  },
});

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.525px',
    lineHeight: '19.5px',
    color: 'rgba(56, 56, 56, 1)',
  },
  body: {
    backgroundColor: 'transparent',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.525px',
    lineHeight: '19.5px',
    color: 'rgba(56, 56, 56, 1)',
    //width: 100,
  },
  root: {
    borderBottom: 'none',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
}))(TableRow);
