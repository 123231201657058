import React from 'react';
import PropTypes from 'prop-types';
import SocialButton from "./SocialButton";

const staticVar = {
    facebookAppID: process.env.REACT_APP_FACEBOOK_APP_ID,
    googleAppID: process.env.REACT_APP_GOOGLE_APP_ID,
}

const SocialAuth = props => {

    function handleSuccess(data){
        props.callback(data);
    }

    function handleFailure(data){
        props.callback(data);
    }

    function renderSocialButton(value){
        return(
            <SocialButton
                // autoCleanUri 
                icon={value.icon}
                label={value.label}
                provider={value.type}
                appId={(value.type === 'facebook') ? staticVar.facebookAppID : (value.type === 'google') ? staticVar.googleAppID : staticVar.appleAppId}
                onLoginSuccess={handleSuccess}
                onLoginFailure={handleFailure}
            />
        )
    }
    
    return renderSocialButton(props)
}

SocialAuth.propTypes={
    icon: PropTypes.object,
    label: PropTypes.string,
    type: PropTypes.string,
}

export default SocialAuth;