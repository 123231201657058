import React, { useState, useEffect } from 'react';
import ChevronRightSharpIcon from '@material-ui/icons/ChevronRightSharp';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

import {
    withStyles,
    AppBar,
    Tabs,
    Tab,
    Link,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core';

const listHeight = 330

const styles = theme => ({

    appbar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        position: 'static',
    },
    listmain: {
        maxHeight: listHeight,
        overflowY: 'hidden'
    },
    listmainempty: {
        height: 200,
        overflowY: 'hidden'
    },
    listmainfull: {
        height: '100%',
        maxHeight: 'initial',
        overflowY: 'hidden'
    },
    listitem: {
        borderBottom: '1px solid #979797',
        paddingLeft: 0,
        paddingRight: 0,
        height: 80,
    },
    subtext: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    listlink: {
        width: '100%',
        display: 'block',
        color: 'rgba(0, 0, 0, 0.87)',
        textDecoration: 'none',
        '&:active, &:hover, &:focus': {
            textDecoration: 'none'
        }
    },
    icongroup: {
        display: 'flex',
        alignItems: 'center'
    },
    viewmore: {
        display: 'flex',
        alignItems: 'center',
        color: '#4d4d4d',
        fontSize: '0.75rem'
    },
    viewmoreIcon: {
        marginLeft: theme.spacing(1)
    }
});

const NearbyTabsList = props => {
    const { classes, data } = props;
    const [filter, setFilter] = useState('all');
    const [showAll, setShowAll] = useState(false);
    const { labels } = props;

    const handleChange = (event, newValue) => {
        setFilter(newValue);
        props.selectedFilter(newValue)
    };

    const listRef = React.useRef()

    return (
        <div>
            <AppBar className={classes.appbar}>
                <Tabs
                    value={filter}
                    onChange={handleChange}
                    aria-label="Nearby List"
                    indicatorColor="primary"
                >
                    {labels.map((label, idx) => {
                        return <Tab label={label} key={idx} value={label.toLowerCase()} />
                    })}
                </Tabs>
            </AppBar>
            <List className={data && data.length > 0 ? showAll ? classes.listmainfull : classes.listmain : classes.listmainempty}>
                {data && data.length > 0 && data.map((item, idx) => {
                    return (
                        <ListItem
                            component="div"
                            className={classes.listitem}
                            key={idx}>
                            <ListItemText
                                primary={item.name}
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.subtext}>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                            >
                                                {item.vicinity}
                                            </Typography>
                                            {/* <span className={classes.icongroup}>
                                            <span>{'100m'}</span>
                                            <ChevronRightSharpIcon />                                            
                                        </span>  */}
                                        </span>
                                    </React.Fragment>
                                } />

                        </ListItem>
                    )
                })}
                {
                    data && data.length === 0 &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Typography
                            component="span"
                            variant="body2"
                            style={{ color: '#202020' }}
                        >
                            {'No nearby places to show'}
                        </Typography>
                    </div>
                }
            </List>
            <div style={{ display: 'inline-block', marginTop: 20 }}>
                {
                    data?.length > 0 && listRef?.current?.offsetHeight >= listHeight &&
                    <Link href="#" onClick={(e) => { e.preventDefault(); setShowAll(!showAll) }} className={classes.viewmore}>
                        <span>{showAll ? 'View less' : 'View more'}</span>
                        <KeyboardArrowDownSharpIcon fontSize="small" className={classes.viewmoreIcon} />
                    </Link>
                }
            </div>
        </div>
    );
}

export default withStyles(styles)(NearbyTabsList);