import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ViewIcon from '@material-ui/icons/Visibility'
import HeartIcon from '@material-ui/icons/Favorite'
import ListIcon from '@material-ui/icons/List'
import RoomIcon from '@material-ui/icons/Room'

import styled from 'styled-components'

import { withStyles, Button, Badge, Typography } from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
  },
  optionButton: {
    textTransform: 'none',
    width: '10.500em',
    height: '2.875em',
    borderRadius: '2.250em',
    color: '#000',
    fontWeight: 'normal',
    fontSize: 11,
    lineHeight: 1,
    marginRight: 10,
    padding: '11px 0',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '110px',
  },
  optionIcon: {
    marginLeft: '0.313em',
    width: '0.625em',
    height: '0.625em',
  },
})

const ButtonWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 16px;
  width: 100%;

  @media screen and (max-width: 600px) {
    justify-content: space-between;
  }

  &.map-view {
    @media screen and (max-width: 1024px) {
      justify-content: space-between;
    }
  }
`

const RightWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 1300px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
  }
`

const PropertySearchFilter = (props) => {
  const {
    classes,
    checkLoginStatus,
    history,
    setOmcsStatus,
    omcsStatus,
    omcsData,
    data,
  } = props
  const [routerChange] = useRouterChange()
  const [toggleStatus, setToggleStatus] = React.useState(omcsStatus)

  /*   React.useEffect(()=>{
    
    let isOffMarket = localStorage.getItem('isOffMarket') || (window.location.pathname.search('/off-market') > -1 ? true : null) 
    

    if(isOffMarket) {
        setToggleStatus([ { label: "offmarket", active: true}, toggleStatus[1], toggleStatus[2], toggleStatus[3]])
    }
  },[]) */

  /*   React.useEffect(()=>{
    // console.log(toggleStatus)
    setOmcsStatus(toggleStatus)
    props.handleFilter(toggleStatus)
  },[toggleStatus]) */

  const [suburb, setSuburb] = useState(null)

  React.useEffect(() => {
    if (props.propertySuburbs && props.propertySuburbs[0]) {
      setSuburb(props.propertySuburbs)
    } else {
      setSuburb(null)
    }
  }, [props.propertySuburbs])

  const handleRoute = (path) => {
    var rootPath = props.history.location.pathname.split('/')[1]
    if (path) {
      routerChange('/' + rootPath + '/' + path)
    } else {
      routerChange('/' + rootPath)
    }
  }

  const handleActiveState = (path) => {
    let p = props.history.location.pathname
    if (path) {
      return p === '/buy/map-view' || p === '/rent/map-view' || p === '/sold/map-view'
    } else {
      return p === '/buy' || p === '/rent' || p === '/sold'
    }
  }

  const handleCounters = () => {
    if (
      omcsStatus[0].active &&
      !omcsStatus[1].active &&
      omcsData &&
      omcsData.offmarket
    ) {
      return omcsData.offmarket.length + ' off market'
    } else if (
      omcsStatus[1].active &&
      !omcsStatus[0].active &&
      omcsData &&
      omcsData.comingsoon
    ) {
      return omcsData.comingsoon.length + ' coming soon'
    } else if (
      omcsStatus[0].active &&
      omcsStatus[1].active &&
      omcsData &&
      omcsData.comingsoon &&
      omcsData.offmarket
    ) {
      return omcsData.comingsoon.length + omcsData.comingsoon.length + ' hush hush'
    } else {
      return props.propertyCount
    }
  }

  const renderResultsText = () => {
    let propertyText = ' properties found'
    let suburbText = ''
    let around = props?.filters?.includeNearby ? 'and around ' : ''

    if (history.location.pathname === '/hush-hush') {
      propertyText = ' hush hush properties found'
    } else if (history.location.pathname === '/buy') {
      propertyText = ' On Sale properties found'
    } else {
      if (history.location.pathname === '/off-market') {
        propertyText = ' Off Market properties found'
      } else if (history.location.pathname === '/sold') {
        propertyText = ' Sold properties found'
      } else if (history.location.pathname === '/rent') {
        propertyText = ' Rental properties found'
      } else if (history.location.pathname === '/coming-soon') {
        propertyText = ' Coming Soon properties found'
      }
    }

    if (suburb) {
      if (suburb.length === 1) {
        suburbText += suburb[0].name + '.'
      } else {
        for (let i = 0; i < suburb.length; i++) {
          if (i === suburb.length - 1) {
            suburbText += suburb[i].name + '.'
          } else {
            suburbText += suburb[i].name + ' - '
          }
        }
      }
    }

    return (
      <Typography variant="body2" component="h2">
        {data && data.propertiesFilters && data.propertiesFilters.length > 0
          ? 'Showing ' + data.propertiesFilters.length + ' of '
          : ''}
        {handleCounters() ? handleCounters() + propertyText : 0 + propertyText}
        {/*        {suburb ? ' in and around ' + suburbText : ''} */}
        {suburb ? ' in ' + around + suburb?.length + ' suburbs ' : ''}
      </Typography>
    )
  }

  return (
    <>
      <div className={classes.root}>
        {renderResultsText()}
        {/* <Typography variant="body2" component="h2">       
                {data && data.propertiesFilters && data.propertiesFilters.length > 0 ? 'Showing ' + data.propertiesFilters.length + ' of ' : '' }{handleCounters() ? handleCounters() + ' properties found' : 0 + ' properties found' }{ suburb ? ' in and around ' + suburb : '' }
            </Typography> */}
        {/* <div className={classes.buttonContainer}>
                <div style={(localStorage.getItem('isOffMarket') || (window.location.pathname.search('/off-market') > -1 ? true : null)) ? {opacity:0, pointerEvents:'none', visibility:'hidden'} : {display:'flex'}}>
                    <ToggleButton
                        className={classes.optionButton}
                        style={{backgroundColor: toggleStatus[0].active ? '#e1315a' : '#FFF'}}
                        value="check"
                        selected={toggleStatus[0].active}
                        onChange={() => {
                            const o = Object.assign([], toggleStatus);
                            o[0].active = !o[0].active;
                            setToggleStatus(o);
                        }}
                        >
                        <span style={{
                            display: 'flex', 
                            justifyContent: 'space-around', 
                            alignItems: 'center',
                            color: toggleStatus[0].active ? '#FFF' : '#4d4d4d'}}>
                            {'Off Market '} <ViewIcon className={classes.optionIcon}/> 
                        </span>
                    </ToggleButton>
                    <ToggleButton
                        className={classes.optionButton}
                        style={{backgroundColor: toggleStatus[1].active ? '#4d4d4d' : '#FFF'}}
                        value="check"
                        selected={toggleStatus[1].active}
                        onChange={() => {
                            const o = Object.assign([], toggleStatus);
                            o[1].active = !o[1].active;
                            setToggleStatus(o);
                            if(!o[0].active) {
                                localStorage.removeItem('isOffMarket')
                            }
                        }}
                        >
                        <span style={{
                            display: 'flex', 
                            justifyContent: 'space-around', 
                            alignItems: 'center',
                            color: toggleStatus[1].active ? '#FFF' : '#4d4d4d'}}>
                            {'Coming Soon '} <ViewIcon className={classes.optionIcon}/> 
                        </span>
                    </ToggleButton>


                   
                </div>
                <RightWrap>
                    <ButtonWrap className={props.view ? props.view : ''}>
                        <Button 
                            className={classes.optionButton}
                            size={'small'} 
                            color={'primary'} 
                            variant={'outlined'}
                            endIcon={<ListIcon className={classes.optionIcon}/>}
                            style={{borderColor:'rgba(0, 0, 0, 0.12)', backgroundColor: toggleStatus[2].active ? '#4d4d4d' : '#fff',  color:toggleStatus[2].active ? '#fff' : '#000'}}
                            onClick={()=> {
                                setToggleStatus([toggleStatus[0], toggleStatus[1], { label: "listview", active: true}, {label: "mapview" , active: false}])
                            }}>
                            {'List View'}
                        </Button>

                        <Button 
                            className={classes.optionButton}
                            size={'small'} 
                            color={'primary'} 
                            variant={'outlined'}
                            endIcon={<RoomIcon className={classes.optionIcon}/>}
                            style={{marginRight:0, borderColor:'rgba(0, 0, 0, 0.12)', backgroundColor: toggleStatus[3].active ? '#4d4d4d' : '#fff',  color:toggleStatus[3].active ? '#fff' : '#000'}}
                            onClick={()=> {
                                setToggleStatus([toggleStatus[0], toggleStatus[1], { label: "listview", active: false}, {label: "mapview" , active: true}])
                            } }>
                            {'Map View'}
                        </Button>
                    </ButtonWrap>

                    <Button 
                        className={classes.optionButton}
                        size={'small'} 
                        color={'primary'} 
                        variant={'outlined'}
                        endIcon={<HeartIcon className={classes.optionIcon}/>}
                        style={{marginRight:'0',marginLeft:10}}
                        onClick={()=> checkLoginStatus() }>
                        {'Save Search'}
                    </Button>
                </RightWrap>
            </div> */}
      </div>
    </>
  )
}

export default withStyles(styles)(PropertySearchFilter)
