import React, { useEffect, useMemo, useReducer, useState } from 'react';
import InputMask from 'react-input-mask';

import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Paper, Typography, Grid, Button, TextField, Box, InputLabel, makeStyles } from '@material-ui/core';

import SuburbSearchField from 'components/Common/Fields/SuburbSearchField';
import CompanyInfoSearchField from 'components/Common/Fields/CompanyInfoSearchField';
import SelectField from 'components/Web/Form/SelectField';
import { STATES } from 'constants/constants';

import { CRMS } from 'constants/constants';
import { requestPOSTCreateAgencyAPI } from 'store/api';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { getUser } from 'store/selectors/superadmin.selectors';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { HOW_DID_YOU_FIND_US } from 'constants/constants';
import FormField from 'components/Web/Form/FormField';
import { superadminActions } from 'store/actions';
import defaultBanner from '../../AgencyAccountSettings/imgs/default-banner.jpg';

import isURL from 'components/Common/Validator';

const useStyles = makeStyles({
  root: {
    marginTop: 20,
    marginRight: 20,
    paddingBottom: 60,
    width: '89vw',
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left',
  },
  formContainer: {
    width: '100%',
    height: 'auto',
    borderRadius: 20,
    backgroundColor: '#f5f5f5',
    padding: 20,
  },
  optionContainer: {
    marginTop: 40,
    width: '100%',
    height: 'auto',
    borderRadius: 20,
    backgroundColor: '#faf7ef',
    padding: 20,
  },
  buttonContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },

  changeImageButton: {
    background: '#FFFFFF',
    border: '0.5px solid #35C0CA',
    borderRadius: '20px',
    padding: '10px 35px',
    cursor: 'pointer',
    outline: 'none',
    transition: '0.3s ease',
    color: '#35C0CA',
    '&:hover': {
      background: '#35C0CA',
      color: 'white',
    },
  },
  imageUrl: {
    height: '262px',
    width: '100%',
    objectFit: 'contain',
  },
  imageUrlDefault: {
    objectFit: 'cover',
  },
});

const AGENCY_INPUT_FIELD_A = {
  firstName: '',
  lastName: '',
  email: '',
  adminEmail: '',
  phone: '',
  signUpPermission: '',
  hdyfu: '',
  manualHdyfu: '',
  adminName: '',
};

const AGENCY_INPUT_FIELD_B = {
  companyName: '',
  registeredTradeName: '',
  companyInfo: '',
  companyWebsite: '',
  abn: '',
  realEstateLicenseNumber: '',
  enquiryEmailAddress: '',
  businessNumber: '',
  address: '',

  officeSuburb: '',
  officePostcode: '',
  officeState: '',
};

const AGENCY_INPUT_FIELD_C = {
  contactPrincipalName: '',
  contactPrincipalEmail: '',
  contactAccountPayableName: '',
  contactAccountPayableEmail: '',
  contactBillingAddress: '',
  contactSendListingOffice: '',

  contactSuburb: '',
  contactPostcode: '',
  contactState: '',
};

const AGENCY_PROFILE = {
  brandColor: '',
  logoUrl: '',
  coverImageUrl: '',
};

const AgencyInfoInputFieldsInitialState = {
  agencyInfoFieldA: AGENCY_INPUT_FIELD_A,
  agencyInfoFieldB: AGENCY_INPUT_FIELD_B,
  agencyInfoFieldC: AGENCY_INPUT_FIELD_C,
  agencyProfile: AGENCY_PROFILE,
};

const defaultErrors = {
  firstName: { status: false, message: '' },
  lastName: { status: false, message: '' },
  email: { status: false, message: '' },
  adminEmail: { status: false, message: '' },
  phone: { status: false, message: '' },

  companyName: { status: false, message: '' },
  companyWebsite: { status: false, message: '' },
  companyInfo: { status: false, message: '' },
  businessNumber: { status: false, message: '' },
  abn: { status: false, message: '' },
  crm: { status: false, message: '' },
  manualCrm: { status: false, message: '' },
  crmSales: { status: false, message: '' },
  manualCrmSales: { status: false, message: '' },
  crmRental: { status: false, message: '' },
  manualCrmrental: { status: false, message: '' },

  hdyfu: { status: false, message: '' },
};

// const validatePhone = (val) => {
//   return /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(
//     val
//   )
// }

const validateURL = (url) => {
  return /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/.test(url);
};

const validateEmail = (val) => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    val,
  );
};

const FormInput = ({ errorMessage, required, label, style, ...props }) => (
  <div style={{ marginTop: '18px', position: 'relative' }}>
    <Typography variant={'caption'} style={{ color: 'gray' }}>
      {required ? `${label} *` : label}
    </Typography>

    <TextField
      style={style ? { ...style, backgroundColor: '#FFF' } : { backgroundColor: '#FFF' }}
      {...props}
      label={null}
      fullWidth
      id="outlined-basic"
      variant="outlined"
    />
    {props.error && (
      <Box position="absolute" bottom={-22} left={0} fontSize="14px" color="red">
        {errorMessage}
      </Box>
    )}
  </div>
);

const agencyInputFieldsReducer = (state, action) => {
  switch (action.type) {
    case 'FIELD_A': {
      return {
        ...state,
        agencyInfoFieldA: { ...state.agencyInfoFieldA, [action.name]: action.value },
      };
    }

    case 'FIELD_B': {
      return {
        ...state,
        agencyInfoFieldB: { ...state.agencyInfoFieldB, [action.name]: action.value },
      };
    }

    case 'FIELD_C': {
      return {
        ...state,
        agencyInfoFieldC: { ...state.agencyInfoFieldC, [action.name]: action.value },
      };
    }

    default:
      return state;
  }
};

const SuburbStateContainer = ({ children, ...props }) => {
  return (
    <Box style={{ ...props }} marginTop="18px">
      {children}
    </Box>
  );
};

const PostCodeState = ({ postCode, state, required }) => {
  return (
    <Box display="flex" gridGap="15px">
      <Box width="40%" marginTop="-18px">
        <InputMask mask={'99999999999'} value={postCode.value} onChange={postCode.onChange} maskPlaceholder={null}>
          <FormInput
            fullWidth
            id={postCode.id}
            name={postCode.name}
            label={postCode.label}
            variant="outlined"
            required={required}
            // helperText={formik.touched[value.name] && formik.errors[value.name]}
          />
        </InputMask>
      </Box>
      <Box marginTop="6.5px" width="60%">
        <InputLabel
          variant="standard"
          style={{
            color: 'gray',
            fontSize: '0.75rem',
            marginBottom: '-10px',
          }}
        >
          {required ? 'State *' : 'State'}
        </InputLabel>
        <SelectField
          options={STATES}
          placeholder={'State'}
          value={state.value}
          onChange={state.onChange}
          onBlur={(e, value) => {
            //var value = e.target.value;
            state.onChange(value);
            // handleCheckField(value, value, 'state')
          }}
        />
      </Box>
    </Box>
  );
};

// const ImageInput = ({
//   labelText,
//   labelId,
//   buttonLabel,
//   photoUrl,
//   handleImageUrl,
//   imageType,
// }) => {
//   const classes = useStyles()

//   return (
//     <Box>
//       <Box>
//         <Typography style={{ color: 'gray', fontSize: '0.75rem', marginBottom: '3px' }}>
//           {labelText}
//         </Typography>
//         <img
//           className={`${classes.imageUrl} ${!photoUrl ? classes.imageUrlDefault : ''}`}
//           src={photoUrl ? photoUrl : defaultBanner}
//         />
//       </Box>
//       <Box display="flex" justifyContent="center" marginTop="5px">
//         <InputLabel className={classes.changeImageButton} htmlFor={labelId}>
//           {buttonLabel}
//         </InputLabel>
//         <input
//           id={labelId}
//           type="file"
//           onChange={(event) => {
//             handleImageUrl(event, imageType)
//           }}
//           style={{ display: 'none' }}
//         />
//       </Box>
//     </Box>
//   )
// }

const AgencyInfo = ({ agencyInfo, inputChange, errors, handleRequiredField, classes }) => {
  const [openSuburbList, setOpenSuburbList] = useState(false);
  const [suburbInputValue, setSuburbInputValue] = useState();
  const [isCompanyInfoError, setIsCompanyInfoError] = useState(false);

  const onCompanyInfoChangeHandler = (value) => {
    if (!value) {
      setIsCompanyInfoError(true);
    } else {
      setIsCompanyInfoError(false);
    }

    inputChange({
      type: 'FIELD_B',
      name: 'companyInfo',
      value: value || '',
    });

    handleRequiredField(value, 'companyInfo');
  };

  // Autocomplete Postcode and state based from suburb
  useEffect(() => {
    if (agencyInfo.agencyInfoFieldB.officeSuburb) {
      inputChange({
        type: 'FIELD_B',
        name: 'officePostcode',
        value: agencyInfo.agencyInfoFieldB.officeSuburb.postcode || '',
      });

      inputChange({
        type: 'FIELD_B',
        name: 'officeState',
        value: agencyInfo.agencyInfoFieldB.officeSuburb.state || '',
      });
    }
  }, [agencyInfo.agencyInfoFieldB.officeSuburb]);

  return (
    <>
      <Typography className={classes.title} variant={'h1'}>
        Agency Admin Information
      </Typography>

      <Paper className={classes.formContainer}>
        <Grid container spacing={2} justifyContent={'space-between'}>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormInput
              required
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldA.firstName}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_A',
                  name: 'firstName',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'firstName');
              }}
              error={errors?.firstName?.status}
              errorMessage={errors?.firstName?.message}
            />
            <FormInput
              required
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldA.lastName}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_A',
                  name: 'lastName',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'lastName');
              }}
              error={errors?.lastName?.status}
              errorMessage={errors?.lastName?.message}
            />
            <FormInput
              required
              fullWidth
              id="email"
              name="email"
              label="Signatory Email Address"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldA.email}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_A',
                  name: 'email',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'email');
              }}
              error={
                (agencyInfo.agencyInfoFieldA.email !== '' && !validateEmail(agencyInfo.agencyInfoFieldA.email)) ||
                errors.email.status
              }
              errorMessage={
                !validateEmail(agencyInfo.agencyInfoFieldA.email) ? 'Invalid email format' : errors.email.message
              }
            />
            <FormInput
              required
              fullWidth
              id="adminEmail"
              name="adminEmail"
              label="Administration Email Address"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldA.adminEmail}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_A',
                  name: 'adminEmail',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'adminEmail');
              }}
              error={
                (agencyInfo.agencyInfoFieldA.adminEmail !== '' &&
                  !validateEmail(agencyInfo.agencyInfoFieldA.adminEmail)) ||
                errors.adminEmail.status
              }
              errorMessage={
                !validateEmail(agencyInfo.agencyInfoFieldA.adminEmail)
                  ? 'Invalid email format'
                  : errors.adminEmail.message
              }
            />
            <FormInput
              required
              fullWidth
              id="adminName"
              name="adminName"
              label="Admin Name (Admin Email Owner)"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldA.adminName}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_A',
                  name: 'adminName',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'adminName');
              }}
              error={errors?.adminName?.status}
              errorMessage={errors?.adminName?.message}
            />
            <InputMask
              mask={'9999999999'}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_A',
                  name: 'phone',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'phone');
              }}
              value={agencyInfo.agencyInfoFieldA.phone}
              maskPlaceholder={null}
            >
              <FormInput
                required
                fullWidth
                id="phone"
                name="phone"
                label="Phone Number"
                variant="outlined"
                error={errors.phone.status}
                errorMessage={errors.phone.message}
                // error={formik.touched[value.name] && Boolean(formik.errors[value.name])}
                // helperText={formik.touched[value.name] && formik.errors[value.name]}
              />
            </InputMask>
            {/* <FormInput
              fullWidth
              id="signUpPermission"
              name="signUpPermission"
              label="Permission to sign-up"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldA.signUpPermission}
              onChange={(event) =>
                inputChange({
                  type: 'FIELD_A',
                  name: 'signUpPermission',
                  value: event.target.value,
                })
              }
            /> */}
            <Box marginTop="21px">
              <InputLabel
                variant="standard"
                style={{
                  color: 'gray',
                  fontSize: '0.75rem',
                  marginBottom: '-6px',
                }}
              >
                Permission to sign-up
              </InputLabel>
              <SelectField
                options={['an agency owner', 'authorised by the agency owner', 'not authorised']}
                placeholder={'I am...'}
                errorMsg={'I am... is required.'}
                value={agencyInfo.agencyInfoFieldA.signUpPermission}
                selected={agencyInfo.agencyInfoFieldA.signUpPermission}
                onChange={(e, value) => {
                  inputChange({
                    type: 'FIELD_A',
                    name: 'signUpPermission',
                    value: value,
                  });
                }}
              />
            </Box>
            <Box>
              <InputLabel
                variant="standard"
                style={{
                  color: 'gray',
                  fontSize: '0.75rem',
                  marginTop: '15px',
                  lineHeight: '2px',
                }}
              >
                How did you hear about us? *
              </InputLabel>
              <SelectField
                options={HOW_DID_YOU_FIND_US}
                placeholder={'How did you hear about us? *'}
                value={agencyInfo.agencyInfoFieldA.manualHdyfu}
                onChange={(e, value) => {
                  if (value && value !== 'Other') {
                    inputChange({
                      type: 'FIELD_A',
                      name: 'manualHdyfu',
                      value: value,
                    });
                    inputChange({
                      type: 'FIELD_A',
                      name: 'hdyfu',
                      value: value,
                    });
                    handleRequiredField(value, 'hdyfu');
                  } else {
                    inputChange({
                      type: 'FIELD_A',
                      name: 'manualHdyfu',
                      value: value,
                    });
                    inputChange({
                      type: 'FIELD_A',
                      name: 'hdyfu',
                      value: '',
                    });
                  }
                }}
                onBlur={(e, value) => {
                  //var value = e.target.value;
                  if (value && value !== 'Other') {
                    inputChange({
                      type: 'FIELD_A',
                      name: 'manualHdyfu',
                      value: value,
                    });
                    inputChange({
                      type: 'FIELD_A',
                      name: 'hdyfu',
                      value: value,
                    });
                    handleRequiredField(value, 'hdyfu');
                  } else {
                    inputChange({
                      type: 'FIELD_A',
                      name: 'manualHdyfu',
                      value: value,
                    });
                    inputChange({
                      type: 'FIELD_A',
                      name: 'hdyfu',
                      value: '',
                    });
                  }
                }}
              />
              {agencyInfo.agencyInfoFieldA.manualHdyfu &&
                agencyInfo.agencyInfoFieldA.manualHdyfu !== '' &&
                agencyInfo.agencyInfoFieldA.manualHdyfu === 'Other' && (
                  <Box marginTop="14px">
                    <FormField
                      type={'text'}
                      value={agencyInfo.agencyInfoFieldA.hdyfu}
                      placeholder={'How did you find us? *'}
                      // errorMsg={errors.hdyfu.message}
                      // showError={errors.hdyfu.status}
                      onChange={(e) => {
                        var value = e.target.value;
                        inputChange({
                          type: 'FIELD_A',
                          name: 'hdyfu',
                          value: value,
                        });
                        if (value) {
                          handleRequiredField(value, 'hdyfu');
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value;
                        inputChange({
                          type: 'FIELD_A',
                          name: 'hdyfu',
                          value: value,
                        });
                        handleRequiredField(value, 'hdyfu');
                      }}
                    />
                  </Box>
                )}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <FormInput
              required
              fullWidth
              id="companyName"
              name="companyName"
              label="Company Name"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldB.companyName}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_B',
                  name: 'companyName',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'companyName');
              }}
              error={errors.companyName.status}
              errorMessage={errors.companyName.message}
            />
            <FormInput
              required
              fullWidth
              id="registeredTradeName"
              name="registeredTradeName"
              label="Registered Trade Name"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldB.registeredTradeName}
              onChange={(event) =>
                inputChange({
                  type: 'FIELD_B',
                  name: 'registeredTradeName',
                  value: event.target.value,
                })
              }
            />
            <SuburbStateContainer>
              <CompanyInfoSearchField
                value={agencyInfo.agencyInfoFieldB.companyInfo}
                isErrorShown={isCompanyInfoError}
                onValueChange={onCompanyInfoChangeHandler}
              />
            </SuburbStateContainer>
            <FormInput
              required
              fullWidth
              id="companyWebsite"
              name="companyWebsite"
              label="Company Website"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldB.companyWebsite}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_B',
                  name: 'companyWebsite',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'companyWebsite');
              }}
              error={
                errors.companyWebsite.status || agencyInfo.agencyInfoFieldB.companyWebsite
                  ? !validateURL(agencyInfo.agencyInfoFieldB.companyWebsite)
                  : false
              }
              errorMessage={
                errors.companyWebsite.message
                  ? errors.companyWebsite.message
                  : validateURL(agencyInfo.agencyInfoFieldB.companyWebsite)
                  ? ''
                  : 'Invalid URL format of company website'
              }
            />
            <InputMask
              mask={'99999999999'}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_B',
                  name: 'abn',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'abn');
              }}
              value={agencyInfo.agencyInfoFieldB.abn}
              maskPlaceholder={null}
            >
              <FormInput
                required
                fullWidth
                id="abn"
                name="abn"
                label="ABN"
                variant="outlined"
                error={errors.abn.status}
                errorMessage={errors.abn.message}
              />
            </InputMask>
            <FormInput
              fullWidth
              id="realEstateLicenseNumber"
              name="realEstateLicenseNumber"
              label="Real Estate Licence Number"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldB.realEstateLicenseNumber}
              onChange={(event) =>
                inputChange({
                  type: 'FIELD_B',
                  name: 'realEstateLicenseNumber',
                  value: event.target.value,
                })
              }
            />
            <FormInput
              fullWidth
              id="enquiryEmailAddress"
              name="enquiryEmailAddress"
              label="Enquiry Email Address"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldB.enquiryEmailAddress}
              onChange={(event) =>
                inputChange({
                  type: 'FIELD_B',
                  name: 'enquiryEmailAddress',
                  value: event.target.value,
                })
              }
              error={
                agencyInfo.agencyInfoFieldB.enquiryEmailAddress !== '' &&
                !validateEmail(agencyInfo.agencyInfoFieldB.enquiryEmailAddress)
              }
              errorMessage={'Invalid email format'}
              // helperText={formik.touched[value.name] && formik.errors[value.name]}
            />
            <InputMask
              mask={'9999999999'}
              onChange={(event) => {
                inputChange({
                  type: 'FIELD_B',
                  name: 'businessNumber',
                  value: event.target.value,
                });
                handleRequiredField(event.target.value, 'businessNumber');
              }}
              value={agencyInfo.agencyInfoFieldB.businessNumber}
              maskPlaceholder={null}
            >
              <FormInput
                required
                fullWidth
                id="businessNumber"
                name="businessNumber"
                label="Office Phone Number"
                variant="outlined"
                error={errors.businessNumber.status}
                errorMessage={errors.businessNumber.message}
              />
            </InputMask>
            <FormInput
              fullWidth
              id="address"
              name="address"
              label="Office Address"
              variant="outlined"
              value={agencyInfo.agencyInfoFieldB.address}
              onChange={(event) =>
                inputChange({
                  type: 'FIELD_B',
                  name: 'address',
                  value: event.target.value,
                })
              }
              // error={formik.touched[value.name] && Boolean(formik.errors[value.name])}
              // helperText={formik.touched[value.name] && formik.errors[value.name]}
            />
            <SuburbStateContainer>
              <SuburbSearchField
                value={agencyInfo.agencyInfoFieldB.officeSuburb}
                inputValue={suburbInputValue}
                setValueCallback={(value) =>
                  inputChange({
                    type: 'FIELD_B',
                    name: 'officeSuburb',
                    value: value,
                  })
                }
                open={openSuburbList}
                setOpen={setOpenSuburbList}
                placeholder={'Suburb *'}
                onInputChange={(e, value) => {
                  if (value) {
                    setSuburbInputValue(value);
                    handleRequiredField(value, 'suburb');
                  } else {
                    setOpenSuburbList(false);
                  }
                }}
              />
            </SuburbStateContainer>
            <SuburbStateContainer>
              <PostCodeState
                postCode={{
                  id: 'postcode',
                  name: 'postcode',
                  label: 'Postcode',
                  value: agencyInfo.agencyInfoFieldB.officePostcode,
                  onChange: (event) =>
                    inputChange({
                      type: 'FIELD_B',
                      name: 'officePostcode',
                      value: event.target.value,
                    }),
                }}
                state={{
                  value: agencyInfo.agencyInfoFieldB.officeState,
                  onChange: (event) =>
                    inputChange({
                      type: 'FIELD_B',
                      name: 'officeState',
                      value: event.target.value,
                    }),
                }}
                required
              />
            </SuburbStateContainer>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const AgencyContacts = ({
  agencyInfo,
  inputChange,
  classes,
  saveAgency,

  errors,
  handleRequiredField,
}) => {
  const [openSuburbList, setOpenSuburbList] = useState(false);
  const [suburbInputValue, setSuburbInputValue] = useState();

  const [contactSuburbDefaultValue, setContactSuburbDefaultValue] = useState('');

  const [crm, setCrm] = useState('');
  const [manualCrm, setManualCrm] = useState('');

  const [crmSales, setCrmSales] = useState('');
  const [manualCrmSales, setManualCrmSales] = useState('');

  const [crmRental, setCrmRental] = useState('');
  const [manualCrmRental, setManualCrmRental] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);

  const dispatch = useDispatch();

  const formValidationChecker = (fields) => {
    const errorInputFields = _.pickBy(fields, (keys) => keys === '');

    if (Object.keys(errorInputFields).length === 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const apiRequestSuperAdminCreateAgency = (agencyDetails) => {
    const request = {
      query: null,
      data: {
        ...agencyDetails,
      },
    };

    dispatch(requestPOSTCreateAgencyAPI(request, 'SUPERADMIN_ADD_AGENCY'));
  };

  const handleNextStep = () => {
    const agencyDetails = {
      // INPUT_FIELD_A
      email: agencyInfo.agencyInfoFieldA.email,
      adminEmail: agencyInfo.agencyInfoFieldA.adminEmail,
      firstName: agencyInfo.agencyInfoFieldA.firstName,
      lastName: agencyInfo.agencyInfoFieldA.lastName,
      phone: agencyInfo.agencyInfoFieldA.phone.replace(/\D/g, '').replace(/ /g, ''),
      signUpPermission: agencyInfo.agencyInfoFieldA.signUpPermission
        ? agencyInfo.agencyInfoFieldA.signUpPermission
        : '',
      hdyfu: agencyInfo.agencyInfoFieldA.hdyfu,
      adminName: agencyInfo.agencyInfoFieldA.adminName,

      // INPUT_FIELD_B
      companyName: agencyInfo.agencyInfoFieldB.companyName,
      companyWebsite: agencyInfo.agencyInfoFieldB.companyWebsite,
      companyInfo: agencyInfo.agencyInfoFieldB.companyInfo,
      registeredTradeName: agencyInfo.agencyInfoFieldB.registeredTradeName
        ? agencyInfo.agencyInfoFieldB.registeredTradeName
        : '',
      abn: agencyInfo.agencyInfoFieldB.abn.replace(/\D/g, '').replace(/ /g, ''),
      businessNumber: agencyInfo.agencyInfoFieldB.businessNumber.replace(/\D/g, '').replace(/ /g, ''),
      realEstateLicenseNumber: agencyInfo.agencyInfoFieldB.realEstateLicenseNumber
        ? agencyInfo.agencyInfoFieldB.realEstateLicenseNumber
        : '',
      enquiryEmailAddress: agencyInfo.agencyInfoFieldB.enquiryEmailAddress
        ? agencyInfo.agencyInfoFieldB.enquiryEmailAddress
        : '',
      address: agencyInfo.agencyInfoFieldB.address ? agencyInfo.agencyInfoFieldB.address : '',
      postcode: agencyInfo.agencyInfoFieldB.officePostcode,
      state: agencyInfo.agencyInfoFieldB.officeState,
      suburb: agencyInfo.agencyInfoFieldB.officeSuburb.locality,

      // INPUT_FIELD_C
      contactPrincipalName: agencyInfo.agencyInfoFieldC.contactPrincipalName
        ? agencyInfo.agencyInfoFieldC.contactPrincipalName
        : '',
      contactPrincipalEmail: agencyInfo.agencyInfoFieldC.contactPrincipalEmail
        ? agencyInfo.agencyInfoFieldC.contactPrincipalEmail
        : '',
      contactAccountPayableName: agencyInfo.agencyInfoFieldC.contactAccountPayableName
        ? agencyInfo.agencyInfoFieldC.contactAccountPayableName
        : '',
      contactAccountPayableEmail: agencyInfo.agencyInfoFieldC.contactAccountPayableEmail
        ? agencyInfo.agencyInfoFieldC.contactAccountPayableEmail
        : '',
      contactBillingAddress: agencyInfo.agencyInfoFieldC.contactBillingAddress
        ? agencyInfo.agencyInfoFieldC.contactBillingAddress
        : '',
      crm: crmSales === 'Other' ? manualCrmSales : crmSales,
      crmSales: crmSales === 'Other' ? manualCrmSales : crmSales,
      crmRental: crmRental === 'Other' ? manualCrmRental : crmRental,

      contactSendListingOffice: agencyInfo.agencyInfoFieldC.contactSendListingOffice
        ? agencyInfo.agencyInfoFieldC.contactSendListingOffice
        : '',
      contactSuburb: agencyInfo.agencyInfoFieldC.contactSuburb?.locality,
      contactPostcode: agencyInfo.agencyInfoFieldC.contactPostcode,
      contactState: agencyInfo.agencyInfoFieldC.contactState,
    };

    if (isFormValid) {
      apiRequestSuperAdminCreateAgency(agencyDetails);
    }

    // make form valid false when next step button is clicked to avoid spamming while waiting for add agency success or error functionality
    setIsFormValid(false);
  };

  useEffect(() => {
    formValidationChecker({
      email: agencyInfo.agencyInfoFieldA.email,
      adminEmail: agencyInfo.agencyInfoFieldA.adminEmail,
      firstName: agencyInfo.agencyInfoFieldA.firstName,
      lastName: agencyInfo.agencyInfoFieldA.lastName,
      phone: agencyInfo.agencyInfoFieldA.phone,
      hdyfu: agencyInfo.agencyInfoFieldA.hdyfu,

      companyName: agencyInfo.agencyInfoFieldB.companyName,
      registeredTradeName: agencyInfo.agencyInfoFieldB.registeredTradeName,
      companyWebsite: agencyInfo.agencyInfoFieldB.companyWebsite,
      companyInfo: agencyInfo.agencyInfoFieldB.companyInfo,
      abn: agencyInfo.agencyInfoFieldB.abn,
      businessNumber: agencyInfo.agencyInfoFieldB.businessNumber,

      postcode: agencyInfo.agencyInfoFieldB.officePostcode,
      state: agencyInfo.agencyInfoFieldB.officeState,
      suburb: agencyInfo.agencyInfoFieldB.officeSuburb?.locality,
      //crm: crm,
      crmSales: crmSales,
      crmRental: crmRental,
      adminName: agencyInfo.agencyInfoFieldA?.adminName,
    });
  }, [
    agencyInfo.agencyInfoFieldA.email,
    agencyInfo.agencyInfoFieldA.adminEmail,
    agencyInfo.agencyInfoFieldA.firstName,
    agencyInfo.agencyInfoFieldA.lastName,
    agencyInfo.agencyInfoFieldB.companyName,
    agencyInfo.agencyInfoFieldB.companyWebsite,
    agencyInfo.agencyInfoFieldB.companyInfo,
    agencyInfo.agencyInfoFieldB.abn,
    agencyInfo.agencyInfoFieldB.businessNumber,
    agencyInfo.agencyInfoFieldB.officePostcode,
    agencyInfo.agencyInfoFieldB.officeState,
    agencyInfo.agencyInfoFieldB.officeSuburb,
    //crm,
    crmSales,
    crmRental,
    agencyInfo.agencyInfoFieldA.phone,
    agencyInfo.agencyInfoFieldA.hdyfu,
  ]);

  // Autocomplete Postcode and state based from suburb\
  useEffect(() => {
    if (agencyInfo.agencyInfoFieldC.contactSuburb) {
      inputChange({
        type: 'FIELD_C',
        name: 'contactPostcode',
        value: agencyInfo.agencyInfoFieldC.contactSuburb.postcode,
      });

      inputChange({
        type: 'FIELD_C',
        name: 'contactState',
        value: agencyInfo.agencyInfoFieldC.contactSuburb.state,
      });
    }
  }, [agencyInfo.agencyInfoFieldC.contactSuburb]);

  return (
    <Box>
      <Typography className={classes.title} variant={'h1'}>
        Agency Contacts
      </Typography>
      <Box>
        <Paper className={classes.formContainer}>
          <Grid container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={12} md={12} lg={12}>
              <FormInput
                fullWidth
                id="contactPrincipalName"
                name="contactPrincipalName"
                label="Principal Name"
                variant="outlined"
                value={agencyInfo.agencyInfoFieldC.contactPrincipalName}
                onChange={(event) =>
                  inputChange({
                    type: 'FIELD_C',
                    name: 'contactPrincipalName',
                    value: event.target.value,
                  })
                }
              />
              <FormInput
                fullWidth
                variant="outlined"
                id="contactPrincipalEmail"
                name="contactPrincipalEmail"
                label="Principal Email Address"
                onChange={(event) =>
                  inputChange({
                    type: 'FIELD_C',
                    name: 'contactPrincipalEmail',
                    value: event.target.value,
                  })
                }
                error={
                  agencyInfo.agencyInfoFieldC.contactPrincipalEmail !== '' &&
                  !validateEmail(agencyInfo.agencyInfoFieldC.contactPrincipalEmail)
                }
              />
              <FormInput
                fullWidth
                variant="outlined"
                id="contactAccountPayableName"
                name="contactAccountPayableName"
                label="Accounts Payable Name"
                value={agencyInfo.agencyInfoFieldC.contactAccountPayableName}
                onChange={(event) =>
                  inputChange({
                    type: 'FIELD_C',
                    name: 'contactAccountPayableName',
                    value: event.target.value,
                  })
                }
              />
              <FormInput
                fullWidth
                variant="outlined"
                id="contactAccountPayableEmail"
                name="contactAccountPayableEmail"
                label="Accounts Payable Email"
                onChange={(event) =>
                  inputChange({
                    type: 'FIELD_C',
                    name: 'contactAccountPayableEmail',
                    value: event.target.value,
                  })
                }
                error={
                  agencyInfo.agencyInfoFieldC.contactAccountPayableEmail !== '' &&
                  !validateEmail(agencyInfo.agencyInfoFieldC.contactAccountPayableEmail)
                }
              />
              <FormInput
                fullWidth
                variant="outlined"
                id="contactBillingAddress"
                name="contactBillingAddress"
                label="Billing Address"
                value={agencyInfo.agencyInfoFieldC.contactBillingAddress}
                onChange={(event) =>
                  inputChange({
                    type: 'FIELD_C',
                    name: 'contactBillingAddress',
                    value: event.target.value,
                  })
                }
              />
              <FormControlLabel
                style={{
                  color: 'gray',
                  fontFamily: ' "Roboto", "Helvetica", "Arial", "sans-serif"',
                }}
                control={
                  <Checkbox
                    style={{ color: '#35C0CA' }}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setContactSuburbDefaultValue(agencyInfo.agencyInfoFieldB.officeSuburb?.locality);
                        inputChange({
                          type: 'FIELD_C',
                          name: 'contactBillingAddress',
                          value: agencyInfo.agencyInfoFieldB.address,
                        });
                        inputChange({
                          type: 'FIELD_C',
                          name: 'contactSuburb',
                          value: agencyInfo.agencyInfoFieldB.officeSuburb,
                        });
                        inputChange({
                          type: 'FIELD_C',
                          name: 'contactPostcode',
                          value: agencyInfo.agencyInfoFieldB.officePostcode,
                        });
                        inputChange({
                          type: 'FIELD_C',
                          name: 'contactState',
                          value: agencyInfo.agencyInfoFieldB.officeState,
                        });
                      }
                    }}
                  />
                }
                label="Same as office address"
              />
              <SuburbStateContainer marginTop={'0px'}>
                <SuburbSearchField
                  value={agencyInfo.agencyInfoFieldC.contactSuburb || ''}
                  inputValue={suburbInputValue || ''}
                  setValueCallback={(value) =>
                    inputChange({
                      type: 'FIELD_C',
                      name: 'contactSuburb',
                      value: value,
                    })
                  }
                  autoCompleteValue={contactSuburbDefaultValue ? contactSuburbDefaultValue : ''}
                  open={openSuburbList}
                  setOpen={setOpenSuburbList}
                  placeholder={'Suburb'}
                  onInputChange={(e, value) => {
                    if (value) {
                      inputChange({
                        type: 'FIELD_C',
                        name: 'contactSuburb',
                        value: value,
                      });
                      setSuburbInputValue(value);
                    } else {
                      setOpenSuburbList(false);
                    }
                  }}
                />
              </SuburbStateContainer>
              <SuburbStateContainer>
                <PostCodeState
                  postCode={{
                    id: 'contactPostCode',
                    name: 'contactPostCode',
                    label: 'Postcode',
                    value: agencyInfo.agencyInfoFieldC.contactPostcode || '',
                    onChange: (event) =>
                      inputChange({
                        type: 'FIELD_C',
                        name: 'contactPostcode',
                        value: event.target.value,
                      }),
                  }}
                  state={{
                    value: agencyInfo.agencyInfoFieldC.contactState || '',
                    onChange: (event) =>
                      inputChange({
                        type: 'FIELD_C',
                        name: 'contactState',
                        value: event.target.value,
                      }),
                  }}
                />
              </SuburbStateContainer>

              <FormInput
                fullWidth
                variant="outlined"
                id="contactSendListingOffice"
                name="contactSendListingOffice"
                label="Which offices do you want to send listing from?"
                value={agencyInfo.agencyInfoFieldC.contactSendListingOffice}
                onChange={(event) =>
                  inputChange({
                    type: 'FIELD_C',
                    name: 'contactSendListingOffice',
                    value: event.target.value,
                  })
                }
              />
              <Box>
                <Typography
                  style={{
                    marginTop: '18px',
                    lineHeight: '0px',
                    fontSize: '0.75rem',
                    color: 'gray',
                  }}
                >
                  Sales Listing Uploader *
                </Typography>
                <SelectField
                  options={CRMS.sort()}
                  placeholder={'Your Bulk Uploader Provider - who you upload sales listings through *'}
                  value={manualCrmSales}
                  // errorMsg={errors.crm.message}
                  // showError={errors.crm.status}
                  onChange={(e, value) => {
                    //var value = e.target.value;
                    if (value && value !== 'Other') {
                      setManualCrmSales(value);
                      setCrmSales(value);
                      //handleRequiredField(value, 'crmSales')
                    } else {
                      setManualCrmSales(value);
                      setCrmSales('');
                    }
                  }}
                  onBlur={(e, value) => {
                    //var value = e.target.value;
                    if (value && value !== 'Other') {
                      setManualCrmSales(value);
                      setCrmSales(value);
                      // handleCheckField(value, value, 'crm')
                      //handleRequiredField(value, 'crmSales')
                    } else {
                      setManualCrmSales(value);
                      setCrmSales('');
                    }
                  }}
                  style={{ marginTop: 0, marginBottom: 18 }}
                />
                {manualCrmSales && manualCrmSales !== '' && manualCrmSales === 'Other' && (
                  <FormInput
                    type={'text'}
                    value={crmSales}
                    placeholder={'Sales Listing Uploader *'}
                    onChange={(e) => {
                      var value = e.target.value;
                      setCrmSales(value);
                      /*                       if (value) {
                        handleRequiredField(value, 'crmSales')
                      } */
                    }}
                    onBlur={(e) => {
                      var value = e.target.value;
                      setCrmSales(value);
                      //handleRequiredField(value, 'crmSales')
                    }}
                    error={errors?.crmSales?.status}
                    errorMessage={errors?.crmSales?.message}
                  />
                )}
                {/*                 <FormControlLabel
                  style={{
                    color: 'gray',
                    fontFamily: ' "Roboto", "Helvetica", "Arial", "sans-serif"',
                  }}
                  control={
                    <Checkbox
                      style={{ color: '#35C0CA' }}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setCrmSales(crm)
                        }
                      }}
                    />
                  }
                  label="Same as CRM"
                /> */}
              </Box>

              <Box>
                <Typography
                  style={{
                    marginTop: '18px',
                    lineHeight: '0px',
                    fontSize: '0.75rem',
                    color: 'gray',
                  }}
                >
                  Rental Listing Uploader *
                </Typography>
                <SelectField
                  options={CRMS.sort()}
                  placeholder={'Your Bulk Uploader Provider - who you upload rental listings through *'}
                  value={manualCrmRental}
                  // errorMsg={errors.crm.message}
                  // showError={errors.crm.status}
                  onChange={(e, value) => {
                    //var value = e.target.value;
                    if (value && value !== 'Other') {
                      setManualCrmRental(value);
                      setCrmRental(value);
                      //handleRequiredField(value, 'crmRental')
                    } else {
                      setManualCrmRental(value);
                      setCrmRental('');
                    }
                  }}
                  onBlur={(e, value) => {
                    //var value = e.target.value;
                    if (value && value !== 'Other') {
                      setManualCrmRental(value);
                      setCrmRental(value);
                      // handleCheckField(value, value, 'crm')
                      //handleRequiredField(value, 'crmRental')
                    } else {
                      setManualCrmRental(value);
                      setCrmRental('');
                    }
                  }}
                  style={{ marginTop: 0, marginBottom: 18 }}
                />
                {manualCrmRental && manualCrmRental !== '' && manualCrmRental === 'Other' && (
                  <FormInput
                    type={'text'}
                    value={crmRental}
                    placeholder={'Rental Listing Uploader *'}
                    onChange={(e) => {
                      var value = e.target.value;
                      setCrmRental(value);
                      /*                         if (value) {
                          handleRequiredField(value, 'crmRental')
                        } */
                    }}
                    onBlur={(e) => {
                      var value = e.target.value;
                      setCrmRental(value);
                      //handleRequiredField(value, 'crmRental')
                    }}
                    error={errors?.crmRental?.status}
                    errorMessage={errors?.crmRental?.message}
                  />
                )}
                <FormControlLabel
                  style={{
                    color: 'gray',
                    fontFamily: ' "Roboto", "Helvetica", "Arial", "sans-serif"',
                  }}
                  control={
                    <Checkbox
                      style={{ color: '#35C0CA' }}
                      onChange={(event) => {
                        if (crmSales && crmSales !== 'Other') {
                          setManualCrmRental(crmSales);
                          setCrmRental(crmSales);
                        } else {
                          setManualCrmRental('Other');
                          setCrmRental('');
                        }
                      }}
                    />
                  }
                  label="Same as CRM"
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <div className={classes.buttonContainer}>
          <Button
            fullWidth
            color={'primary'}
            variant="outlined"
            onClick={handleNextStep}
            style={{ minWidth: '200px', height: 50, marginRight: 5, borderRadius: 40 }}
            disabled={!isFormValid || !validateURL(agencyInfo.agencyInfoFieldB.companyWebsite)}
          >
            Save agency
          </Button>
        </div>
      </Box>
    </Box>
  );
};

const AddAgencyForm = ({ nextFormAction }) => {
  const classes = useStyles();

  const AGENCY_DETAILS = useSelector(getUser);

  const [agencyInputFields, dispatchAgencyInputFields] = useReducer(
    agencyInputFieldsReducer,
    AgencyInfoInputFieldsInitialState,
  );

  const [suburb, setSuburb] = useState();

  const [postCode, setPostCode] = useState('');
  const [state, setState] = useState('');
  const [errors, setErrors] = useState(defaultErrors);

  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);

  const dispatch = useDispatch();

  const handleRequiredField = (value, fieldName) => {
    if (value !== '') {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: { status: false, message: '' },
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: { status: true, message: 'This field is required' },
      }));
    }
  };

  const handleSuburbChange = (value) => {
    setSuburb(value);
  };

  const handlePostCodeChange = (value) => {
    setPostCode(value);
  };

  const handleStateChange = (value) => {
    setState(value);
  };

  const handleFormInputChange = ({ type, name, value }) => {
    dispatchAgencyInputFields({
      type: type,
      name: name,
      value: value,
    });
  };

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  useEffect(() => {
    let errorTimeOut = null;
    let successTimeOut = null;

    if (AGENCY_DETAILS?.payload_failed) {
      handleNotification(true, {
        severity:
          AGENCY_DETAILS.payload_failed.data.message ===
          'You already have pending application. You may contact our admin regarding your application.'
            ? 'warning'
            : 'error',
        message:
          AGENCY_DETAILS.payload_failed.data.message ===
          'You already have pending application. You may contact our admin regarding your application.'
            ? 'You already have a pending application linked to that email. Contact PropertyMate to enquire about your account status.'
            : AGENCY_DETAILS.payload_failed.data.message,
      });
      dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'));
    }

    if (AGENCY_DETAILS?.currentAddedAgency) {
      handleNotification(true, {
        severity: 'success',
        message: `Successfully added agency ${AGENCY_DETAILS.currentAddedAgency.data.entity.companyName}`,
      });
      successTimeOut = setTimeout(() => {
        nextFormAction('next');
      }, 3000);
    }

    return () => {
      // if (errorTimeOut) clearTimeout(errorTimeOut)
      if (successTimeOut) clearTimeout(successTimeOut);
    };
  }, [AGENCY_DETAILS]);

  return (
    <Grid className={classes.root} container spacing={2}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={() => setIsNotification(false)}
      />
      <Grid item xs={12} md={12} lg={8}>
        <AgencyInfo
          classes={classes}
          agencyInfo={agencyInputFields}
          inputChange={handleFormInputChange}
          suburb={suburb}
          postCode={postCode}
          state={state}
          setSuburb={handleSuburbChange}
          setPostCode={handlePostCodeChange}
          setState={handleStateChange}
          errors={errors}
          handleRequiredField={handleRequiredField}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <AgencyContacts
          classes={classes}
          agencyInfo={agencyInputFields}
          inputChange={handleFormInputChange}
          suburb={suburb}
          postCode={postCode}
          state={state}
          errors={errors}
          handleRequiredField={handleRequiredField}
          agencyReduxState={AGENCY_DETAILS}
        />
      </Grid>
    </Grid>
  );
};

export default AddAgencyForm;
