import { POST, FETCH, PUT, DELETE } from 'utils/api';
import { superadminActions } from 'store/actions/superadmin.actions';
import { apiEndpoints } from 'utils/api/endpoints';

const urlSetter = (params, actionMain) => {
  let URL = apiEndpoints[actionMain];
  if (params.query && params.query !== '') {
    URL = apiEndpoints[actionMain] + params.query;
  }
  return URL;
};

export function requestPOSTAPISuperadmin(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestGETAPISuperadmin(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    FETCH(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestPUTAPISuperadmin(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    PUT(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestDELETEAPISuperadmin(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    DELETE(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestPUTAPISuperadminAccount(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    PUT(`${URL}/${params.user}/update`, params.entity)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestPUTAPISuperadminAgency(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    PUT(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestDELETEAPISuperadminAgency(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    DELETE(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestPOSTCreateAgencyAPI(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestGETSuperAdminNotificationList(params, actionMain) {
  // let URL = urlSetter(params, actionMain)
  // return (dispatch) => {
  //   dispatch(superadminActions(params, actionMain, 'REQUEST'))
  //   POST(URL, params.data)
  //     .then((response) => {
  //       if (response.error) {
  //         throw response.error
  //       }
  //       dispatch(superadminActions(response, actionMain, 'SUCCESS'))
  //       return response
  //     })
  //     .catch((error) => {
  //       dispatch(superadminActions(error.response, actionMain, 'FAILED'))
  //     })
  // }
}

export function sendEmailActivation(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(superadminActions(params, actionMain, 'REQUEST'));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(superadminActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(superadminActions(error.response, actionMain, 'FAILED'));
      });
  };
}
