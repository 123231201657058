import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import PropertyCard from './PropertyCard'
import NoResultsCard from 'components/Web/Property/NoResultsCard'

import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { css } from 'styled-components'
import _ from 'lodash'

/** Redux **/
import { toggleFilterWeb } from '../../../store/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'
import { withStyles, CircularProgress, Grid } from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'
// import MyPropertiesCard from "../Agent/MyPropertiesCard";

const styles = (theme) => ({
  root: {
    '& .infinite-scroll-component': {
      // overflowX: 'hidden !important'
      padding: theme.spacing(0, 2),
      margin: '0 -16px',
    },
  },
  loader: { padding: '5px 23px 20px', textAlign: 'center', marginTop: 20 },
})

const PropertiesListing = (props) => {
  const {
    classes,
    setCurrentProperty,
    properties,
    isLoading,
    listingType,
    pagination,
    origin,
    handlePagination,
  } = props

  const [items, setItems] = useState([]) // PROPERTY_CARDS
  const [hasMore, setHasMore] = useState(true)
  const [routerChange] = useRouterChange()

  useEffect(() => {
    return () => {
      setItems([])
    }
  }, [])

  useEffect(() => {
    /**
     * Property Data
     * */
    if (properties.propertiesFilters) {
      setItems(_.uniqBy(properties?.propertiesFilters, '_id'))
      let totalresults = pagination.totalResults
      let propertyLoadedCount = properties.propertiesFilters.length
      if (totalresults > propertyLoadedCount) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }
    }
  }, [properties])

  const fetchMoreData = () => {
    if (pagination.totalResults === items.length) {
      setHasMore(false)
      return
    }
    handlePagination()
  }

  const refrestData = () => {
    setItems(_.uniqBy(properties.propertiesFilters, '_id'))
  }

  const noResultsMessage = (listing) => {
    switch (listing) {
      case 'favourites':
        return (
          <p>
            There are <strong>0</strong> properties on your favourite list. Try adding
            some to see them listed here.
          </p>
        )
      case 'disliked-properties':
        return (
          <p>
            There are <strong>0</strong> properties on your dislikes list. Try adding
            some to see them listed here.
          </p>
        )

      case 'liked-properties':
        return (
          <p>
            You haven't added any homes yet. Start searching for properties to add now.
          </p>
        )
      default:
        return <p>No results</p>
    }
  }

  const _renderLoader = () => (
    <div className={classes.loader}>
      <CircularProgress size={20} />
    </div>
  )

  const _renderEndMessage = () => (
    <p style={{ textAlign: 'center', fontSize: 14, padding: '20px 0 0' }}>
      We have loaded all properties
    </p>
  )

  const _renderPullDownToRefreshContent = () => (
    <p style={{ textAlign: 'center', padding: '10px ' }}>
      &#8595; Pull down to refresh
    </p>
  )

  const _renderReleaseToRefreshContent = () => (
    <p style={{ textAlign: 'center', padding: '10px ' }}>&#8593; Release to refresh</p>
  )

  const _renderCardContent = () => {
    return (
      <Grid container spacing={3} alignItems="stretch">
        {items.map((value, index) => (
          <PropertyCard
            style={{ height: '100%', marginTop: '10px' }}
            key={index}
            cardType={props.cardType ? props.cardType : 'list'}
            property={value}
            {...propertyConfigSetter('card-type', 'premium')}
            listingType={listingType}
            setCurrentProperty={setCurrentProperty}
            onClick={() => {
              routerChange('/agency/update-property/' + value._id)
            }}
            isAgencyPropertiesGridCard={true}
          />
        ))}
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      {items && items.length > 0 ? (
        <InfiniteScroll
          scrollableTarget={
            origin && origin === 'map-view' ? 'cards-scroll-column' : window.body
          }
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={_renderLoader()}
          endMessage={_renderEndMessage()}
          refreshFunction={refrestData}
          pullDownToRefresh={false}
          pullDownToRefreshThreshold={100}
          pullDownToRefreshContent={_renderPullDownToRefreshContent()}
          releaseToRefreshContent={_renderReleaseToRefreshContent()}
        >
          {_renderCardContent()}
        </InfiniteScroll>
      ) : !isLoading ? (
        noResultsMessage(listingType)
      ) : null}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleFilterWeb: (checked) => toggleFilterWeb(checked),
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(PropertiesListing))
