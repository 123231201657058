import React from "react";

import AgentCards from './AgentCards';
import PropertyLoader from '../Property/PropertyLoader';

import { withStyles, Typography, Grid } from '@material-ui/core';

const StyledSubText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '15px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative'
  },
})(Typography);

const styles = theme => ({
  root: {
    backgroundColor: '#F5F5F5',
  },
  content: {
    margin: theme.spacing(2)
  },
  searchbarContainer: {
    padding: theme.spacing(1)
  },
  cardContent: {
    display: 'flex',
    width: '100%',
    padding: 10
  },
  nullMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '40vh'
  },
  nullMessage: {
    textAlign: 'center',
    color: 'gray',
  },

});

const AgentResults = props => {

  const {
    classes,
    agentsData,
    executeTopScroll,
  } = props;

  return (
    <>
      <div className={classes.cardContent}>
        {
          agentsData && agentsData.length > 0 &&
          <Grid container spacing={2}>
            {
              agentsData && agentsData.map((value, index) => {
                return <AgentCards key={index} agent={value} type={'agent-card'} />
              })
            }
          </Grid>
        }
        {
          agentsData && agentsData.length === 0 &&
          <div className={classes.nullMessageContainer}>
            <StyledSubText className={classes.nullMessage} variant="h2">
              No result found
            </StyledSubText>
          </div>
        }
      </div>
      {
        agentsData && agentsData.length > 4 &&
        <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
      }
    </>
  );
};

export default (withStyles(styles)(AgentResults));
