import { GLOBAL_FILTER_DEFAULT } from 'constants/globalfilters'
import { PRICE_RANGE, PRICE_RANGE_RENT } from 'constants/globalfilters'
import { DEFAULT_PAYLOAD } from './default.payload'
import { handleSearchPropertyReference } from 'utils/handleSearchPropertyReference'

const handleSearchSort = (sortFilters) => {
  const activeSort = sortFilters.find((sortFilter) => sortFilter.active)
  let sortPayload = null

  switch (activeSort?.id) {
    case 'relevance':
      sortPayload = null
      break

    case 'createdAt':
      sortPayload = { createdAt: -1 }
      break

    case 'priceDesc':
      sortPayload = { price: -1 }
      break

    case 'priceAsc':
      sortPayload = { price: 1 }
      break

    case 'sqm':
      sortPayload = { landSize: -1 }
      break

    case 'bedrooms':
    case 'bathrooms':
      sortPayload = { [activeSort.id]: -1 }
      break

    default:
      sortPayload = null
      break
  }

  return sortPayload
}

export const formatRequest = (filters) => {
  const ALL_NEARBY = filters.suburbs
    ?.map((item) => item.nearby ?? false)
    .filter((item) => item !== false)

  const getNearbyPlaces = {}
  if (filters?.nearby_filters?.length > 0) {
    const places = filters?.nearby_filters?.forEach((place) => {
      getNearbyPlaces[place.key] = place.active
    })
  }

  const getReference = handleSearchPropertyReference(filters?.search_reference)?.split(
    '/'
  )[1]

  const getPropertyType = filters?.property_type
    ?.filter((type) => type.active)
    .map((type) => type.title)
  const getState = filters?.state
    ?.filter((state) => state.active)
    .map((state) => state.title)
  const checkIfAll = !getPropertyType.includes('All') && getPropertyType.length >= 1
  const getNearbyList = [].concat.apply([], ALL_NEARBY)
  // const getFeatures = filters?.features
  //   ?.filter((feat) => feat.active)
  //   .map((feat) => feat.id)
  const allFeatures = filters?.features?.map((feat) => feat.id)
  const getDistanceFrom = {
    capitalCity: {
      min: filters?.radius_distance?.min,
      max: filters?.radius_distance?.max,
    },
  }

  //?declare on this defaults the constant values that usually never change depending on requirements for API
  let payload = DEFAULT_PAYLOAD
  if (checkIfAll) {
    payload.property_filters.propertyType = getPropertyType
  }

  if (
    getPropertyType?.length === GLOBAL_FILTER_DEFAULT?.property_type?.length ||
    getPropertyType?.length === 0
  ) {
    delete payload.property_filters?.propertyType
  }

  payload.nearby_suburbs = filters.include_nearby_suburbs
  payload.property_filters.established = filters.is_established ?? 'any'
  if (filters.include_nearby_suburbs) {
    payload.nearbyList = getNearbyList
  } else {
    payload.nearbyList = []
  }
  payload.range_filters = {
    price: {
      min: filters.price.min === PRICE_RANGE[0] ? null : filters.price.min,
      max: filters.price.max === PRICE_RANGE[1] ? null : filters.price.max,
    },
    landSize: {
      min: filters.land_size.min,
      max: filters.land_size.max,
    },
  }
  payload.reference = getReference
  payload.suburb = filters.suburb
  payload.nearby_filters = getNearbyPlaces

  payload.excludeUnderOffer = filters.exclude_under_offers

  if (handleSearchSort(filters.listing_search_sort)) {
    payload.sort = handleSearchSort(filters.listing_search_sort)
  } else {
    payload.sort = null
  }

  const checkValues = (key) => filters[key]?.value === 0 && filters[key]?.exact !== true

  payload.property_filters.bedrooms = (({ value, exact }) => ({
    value,
    exactMatch: exact,
  }))(filters?.bedroom)
  payload.property_filters.bathrooms = (({ value, exact }) => ({
    value,
    exactMatch: exact,
  }))(filters?.bathroom)
  payload.property_filters.totalParking = (({ value, exact }) => ({
    value,
    exactMatch: exact,
  }))(filters?.carparking)
  payload.suburb = filters?.suburbs?.map((sub) =>
    (({ postcode, locality, state }) => ({ postcode, suburb: locality, state }))(sub)
  )

  if (checkValues('bedroom') && payload.property_filters?.bedrooms) {
    delete payload.property_filters.bedrooms
  }
  if (checkValues('bathroom') && payload.property_filters?.bathrooms) {
    delete payload.property_filters.bathrooms
  }
  if (checkValues('carparking') && payload.property_filters?.totalParking) {
    delete payload.property_filters.totalParking
  }

  if (
    (filters?.price?.min === PRICE_RANGE[0] &&
      filters?.price?.max === PRICE_RANGE[1]) ||
    getReference === 'rent'
  ) {
    delete payload.range_filters.price
  }
  if (!filters?.land_size?.min || !filters?.land_size?.max) {
    delete payload.range_filters.landSize
  }
  if (filters?.available_date) {
    payload.property_filters.available_date = filters.available_date
  }

  if (filters?.keyword && filters.keyword !== '' && filters.keyword !== null) {
    payload.keywords = filters.keyword?.split(',')
  } else {
    delete payload.keywords
  }

  // allFeatures.forEach((feat) => {
  //   delete payload.property_filters?.[feat]
  // })

  // if (getFeatures.length > 0) {
    // let features = {}
    // getFeatures.forEach((feat) => {
    //   features[feat] = true
    // })
    // payload.property_filters = { ...payload.property_filters, ...features }
  // }


  /**
   * Remove existing features filter
   */
  filters.features.map((feature) => {
    if (feature.id in payload.property_filters) {
      const updated_property_filters = { ...payload.property_filters };
      delete updated_property_filters[feature.id]
      payload.property_filters = { ...updated_property_filters }
    }
  });
  
  /**
   * Add features if it was checked.
   */
  const features = filters.features.filter((feat) => feat.active).map((feat) => ({ [feat.id]: true }))
  .reduce((acc, obj) => ({ ...acc, ...obj }), {});
  payload.property_filters = { ...payload.property_filters, ...features }

  switch (getReference) {
    case 'rent':
      payload.rental_filters = {
        rentalPrice: {
          min:
            filters.price_rent.min === PRICE_RANGE_RENT[0]
              ? null
              : filters.price_rent.min,
          max:
            filters.price_rent.max === PRICE_RANGE_RENT[1]
              ? null
              : filters.price_rent.max,
        },
      }
      if (filters.available_date) {
        payload.rental_filters.rentalDateAvailable = filters.available_date
      }
      if (
        filters?.price_rent?.min === PRICE_RANGE_RENT[0] &&
        filters?.price_rent?.max === PRICE_RANGE_RENT[1]
      ) {
        delete payload.rental_filters?.rentalPrice
      }

      if (filters.furnished && filters.furnished !== 'any') {
        payload.rental_filters = {
          ...payload.rental_filters,
          furnished: filters.furnished === 'furnished',
        }
      } else if (filters.furnished === 'any') {
        delete payload.rental_filters?.furnished
      }
      break

    case 'perfect-property':
      delete payload.take
      delete payload.nearbyList
      delete payload.nearby_suburbs
      delete payload?.range_filters?.landSize
      delete payload?.keywords
      delete payload?.suburb
      delete payload.rental_filters
      delete payload.property_filters.established
      payload.state = getState
      payload.property_filters.distanceFromKm = getDistanceFrom
      break

    default:
      payload.take = 1000
      delete payload.nearby_filters
      delete payload.rental_filters
      delete payload.state
      delete payload.property_filters.distanceFromKm
      break
  }

  return { ...payload }
}
