import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Grid, Typography, TextField, withStyles, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import UploadPropertyCard from 'components/Web/Property/UploadPropertyCard';
import styled from 'styled-components';

/** Property Utils */
import { propertyConfigSetter } from 'utils/property.utils';
import HeadingContentSmall from 'components/Mobile/Form/HeadingContentSmall';
import LogoOnlyHeader from 'components/Mobile/Header/LogoOnlyHeader';

import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import NotificationDialog from 'components/Web/CustomDialogs/NotificationDialog';
import Loader from 'components/Web/Loader';
import FormField from 'components/Web/Form/FormField';
import AlertNotification from 'components/Web/Form/AlertNotification';

import { useDispatch, useSelector } from 'react-redux';
import { getAuthentication } from 'store/selectors/authentication.selectors';
import { getSettings, getFilters } from 'store/selectors/generic.selectors';
import { getToConfirmProperty, getStatus, getError } from 'store/selectors/propertyv2.selectors';
import { propertyv2Actions, toggleSignUp } from 'store/actions';
import {
  requestPOSTAPIProperty,
  requestGETAPIProperty,
  requestPUTAPIProperty,
  requestDELETEAPIProperty,
} from 'store/api';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    width: '100%',
    paddingTop: '5vh',
    height: '100vh',
    '& .MuiBackdrop-root': {
      width: '100%',
      maxWidth: 'initial',
      left: 0,
    },
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  intentUploadWrap: {
    marginTop: theme.spacing(3),
  },
  otpMain: {
    width: '100%',
    marginTop: 20,
  },
  otpTextContainer: {
    paddingLeft: '20%',
    paddingRight: '20%',
  },
  otpText: {
    fontFamily: 'Montserrat, sans',
  },
  otpFormContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  otpField: {
    marginTop: 10,
  },
  otpButton: {
    width: 150,
    textTransform: 'initial',
  },
});

/** Default input */
const FormInput = (props) => <TextField {...props} fullWidth id="outlined-basic" variant="outlined" />;

const StyledButton = withStyles((theme) => ({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: theme.spacing(1, 2),
    minWidth: '118px',
  },
}))(Button);

const ButtonWrap = styled.div`
  padding: 20px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    margin: 0 10px;
  }
`;

/** Validation */
const validationSchema = yup.object({
  otp: yup.number('Invalid OTP').required('OTP is required'),
  reason: yup.string('Enter your reason').required('Reason is required'),
});

let timercountdown = null;
let resendcountdown = null;

const ConfirmProperty = (props) => {
  const { classes, match } = props;
  const [routerChange] = useRouterChange();

  const dispatch = useDispatch();
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [confirmDecline, setConfirmDecline] = useState(false);
  const [errorNotice, setErrorNotice] = useState(false);
  const [successNotice, setSuccessNotice] = useState(false);
  const [action, setAction] = useState(null);
  const [initialAction, setInitialAction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [invalidProperty, setInvalidProperty] = useState(false);

  const AUTHENTICATION = useSelector(getAuthentication);
  const PROPERTY = useSelector(getToConfirmProperty);
  const SETTINGS = useSelector(getSettings);
  const FILTERS = useSelector(getFilters);
  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getError);

  /** Form set-up, validation integration and submit handler */
  const formik = useFormik({
    initialValues: {
      code: '',
      remarks: '',
    },
    validationSchema: validationSchema,
  });

  /**
   * *Countdown states
   */
  const [countdown, setCountdown] = useState(5); // ** set seconds here
  const [countdownState, setCountdownState] = useState(false);

  const [resendCountdown, setResendCountdown] = useState(180);
  const [resendCountdownState, setResendCountdownState] = useState(false);
  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  let isVendor = match.params.target === 'vendor';

  useEffect(() => {
    handleClearError();
    handleClearData();
    handleClearStatus();
    handleRequestGetProperty();
    return () => {
      handleClearError();
      handleClearData();
      handleClearStatus();
    };
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = process?.env.REACT_APP_REDIRECT_URL;
    }
  }, [countdown]);

  useEffect(() => {
    if (PROPERTY) {
      setIsLoading(false);
    }
  }, [PROPERTY]);

  useEffect(() => {
    if (countdownState === true && countdown > -1) {
      timercountdown = setInterval(() => setCountdown(countdown - 1), 1000);
    }
    /** clear timer on unmount  */
    return () => clearInterval(timercountdown);
  });

  useEffect(() => {
    if (resendCountdownState === true && resendCountdown > 0) {
      resendcountdown = setInterval(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    /** clear timer on unmount  */
    return () => clearInterval(resendcountdown);
  });

  useEffect(() => {
    if (STATUS && STATUS === 'property/GET_PROPERTY_DETAILS_FAILED' && ERROR) {
      setIsLoading(false);
      setInvalidProperty(true);
      if (!countdownState && countdown > -1) {
        setCountdownState(true);
      }
    }

    if (STATUS && STATUS === 'property/POST_VERIFY_VENDOR_OTP_SUCCESS' && !ERROR) {
      setSuccessNotice(true);
      setTimeout(() => {
        setSuccessNotice(false);
        window.location.href = process?.env.REACT_APP_REDIRECT_URL;
      }, 6000);
    } else {
      setErrorNotice(true);
      setTimeout(() => {
        setErrorNotice(false);
      }, 6000);
    }

    if (STATUS === 'property/POST_VERIFY_VENDOR_OTP_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Processing your request...',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }

    if (STATUS === 'property/POST_REQUEST_OTP_VENDOR_APPROVAL_FAILED' && ERROR && initialAction) {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'We encountered an error sending OTP code. Please try again in a few minutes.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'property/POST_REQUEST_OTP_VENDOR_APPROVAL_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Sending OTP code...',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'property/POST_REQUEST_OTP_VENDOR_APPROVAL_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Check your inbox and confirm your OTP',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
      if (!resendCountdownState && resendCountdown > -1) {
        setResendCountdownState(true);
      }

      if (initialAction && initialAction === 'decline') {
        setConfirmDecline(true);
      } else if (initialAction && initialAction === 'approve') {
        setConfirmApprove(true);
      }
    }

    if (STATUS === 'property/POST_RESEND_VENDOR_OTP_FAILED' && ERROR) {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'We encountered an error sending new OTP code. Please try again in a few minutes.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'property/POST_RESEND_VENDOR_OTP_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Sending new OTP code...',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'property/POST_RESEND_VENDOR_OTP_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Check your inbox and confirm your new OTP',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  }, [STATUS, ERROR]);

  const handleRequestGetProperty = () => {
    if (props.match.params && props.match.params.id) {
      const params = {
        query: props.match.params.target + '/' + props.match.params.id,
        data: null,
      };
      setIsLoading(true);

      dispatch(requestGETAPIProperty(params, 'GET_PROPERTY_DETAILS'));
    }
  };

  const handleRequestApprovePropertyOtp = () => {
    const params = {
      query: PROPERTY.data.property._id,
      data: null,
    };

    dispatch(requestPOSTAPIProperty(params, 'POST_REQUEST_OTP_VENDOR_APPROVAL'));
  };

  const handleRequestResendOtp = () => {
    const params = {
      query: PROPERTY.data.property._id,
      data: null,
    };

    dispatch(requestPOSTAPIProperty(params, 'POST_RESEND_VENDOR_OTP'));
  };

  const handleVerifyOTP = (status) => {
    const payload = {
      isApproved: status,
      remarks: formik.values.remarks,
      code: formik.values.code,
    };
    if (status) {
      delete payload.remarks;
    }

    const params = {
      query: PROPERTY.data.property._id,
      data: payload,
    };

    dispatch(requestPOSTAPIProperty(params, 'POST_VERIFY_VENDOR_OTP'));
  };

  const handleApproveConfirmation = (status) => {
    // formik.submitForm()
    setConfirmApprove(false);
    handleVerifyOTP(status);
    setAction(status);
  };

  const handleDeclineConfirmation = (status) => {
    // formik.submitForm()
    setConfirmDecline(false);
    handleVerifyOTP(status);
    setAction(status);
  };

  const handleMatchCount = (property) => {
    let count = 0;
    if (FILTERS && property) {
      FILTERS.filters.advanceProperty.map((item, index) => {
        if (item.active && property.coefficients) {
          if (property.coefficients[item.key] >= 0.7) {
            count++;
          }
        }
      });
    }
    return count;
  };

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(propertyv2Actions(null, 'GENERAL_PROPERTY', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(propertyv2Actions(null, 'GENERAL_PROPERTY', 'CLEAR_ERROR'));
  };

  const handleClearData = () => {
    dispatch(propertyv2Actions(null, 'GENERAL_PROPERTY', 'RESET_STATES'));
  };

  const _renderFields = (type) => {
    return (
      <div className={classes.otpMain}>
        <div className={classes.otpTextContainer}>
          <Typography variant="caption" component={'p'} className={classes.otpText}>
            To proceed, please input the one-time pin (OTP) that is sent to your mobile device. You may resend OTP{' '}
            <strong>
              {resendCountdown ? 'after ' + resendCountdown + ' second' + (resendCountdown > 1 ? 's' : '') : 'now'}
            </strong>
          </Typography>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.otpFormContainer}>
            <FormInput
              className={classes.otpField}
              id="code"
              name="code"
              label="OTP *"
              variant="outlined"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
            <Button
              className={classes.otpButton}
              variant="text"
              color="primary"
              onClick={handleRequestResendOtp}
              disabled={!resendCountdown <= 0}
            >
              Resend OTP
            </Button>
          </div>
          {type === 'decline' && (
            <FormInput
              className={classes.otpField}
              id="remarks"
              name="remarks"
              label="Reason *"
              variant="outlined"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              error={formik.touched.remarks && Boolean(formik.errors.remarks)}
              helperText={formik.touched.remarks && formik.errors.remarks}
            />
          )}
        </form>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Loader isLoading={isLoading} />
      {!isLoading && !invalidProperty && (
        <Container>
          <LogoOnlyHeader />

          {PROPERTY && PROPERTY.data && PROPERTY.data.property && (
            <Grid container spacing={6} justifyContent={'center'}>
              <Grid item md={4}>
                <HeadingContentSmall
                  title={isVendor ? 'Vendor Confirmation required' : 'Agency Confirmation required'}
                  content={`Please confirm that you give your consent for your property at <strong>${
                    PROPERTY && PROPERTY.data && PROPERTY.data.property && PROPERTY.data.property.addressText
                  }</strong> to be published on the PropertyMate platform.`}
                  style={{ textAlign: 'center' }}
                />
                <UploadPropertyCard
                  cardType={'list'}
                  properties={PROPERTY.data.property}
                  {...propertyConfigSetter(
                    'card-type',
                    PROPERTY.data.property
                      ? PROPERTY.data.property.subscriptionType
                        ? PROPERTY.data.property.subscriptionType
                        : ''
                      : '',
                  )}
                  authentication={AUTHENTICATION}
                  settings={SETTINGS}
                  toggleSignUp={(d) => dispatch(toggleSignUp(d))}
                  listingType={'properties'}
                  handleMatchCount={handleMatchCount}
                  setCoverCallback={() => false}
                  enableCoverSelector={false}
                />
              </Grid>
            </Grid>
          )}
          <ButtonWrap>
            <StyledButton
              color="secondary"
              variant="contained"
              disabled={false}
              onClick={() => {
                setInitialAction('decline');
                handleRequestApprovePropertyOtp();
              }}
            >
              Decline
            </StyledButton>
            <StyledButton
              color="primary"
              variant="contained"
              disabled={false}
              onClick={() => {
                handleRequestApprovePropertyOtp();
                setInitialAction('approve');
              }}
            >
              Approve
            </StyledButton>
          </ButtonWrap>

          <ConfirmDialog
            isOpen={confirmApprove}
            confirmCallback={() => handleApproveConfirmation(true)}
            closeCallback={() => setConfirmApprove(false)}
            align={'center'}
            title={'Approve Property'}
            text={'Are you sure you want to approve this listing?'}
            hasRender={isVendor} // ** Only show OTP field if link is being viewed by the VENDOR
            renderComponents={_renderFields('approved')}
          />
          <ConfirmDialog
            isOpen={confirmDecline}
            confirmCallback={() => handleDeclineConfirmation(false)}
            closeCallback={() => setConfirmDecline(false)}
            align={'center'}
            title={'Decline Property'}
            text={'Are you sure you want to decline this listing?'}
            hasRender={isVendor} // ** Only show OTP field if link is being viewed by the VENDOR
            renderComponents={_renderFields('decline')}
          />
          {STATUS && STATUS === 'property/POST_VERIFY_VENDOR_OTP_SUCCESS' && !ERROR && (
            <NotificationDialog
              isOpen={successNotice}
              closeCallback={() => {
                setSuccessNotice(false);
              }}
              title={'Success'}
              text={`${action ? 'Approval' : 'Decline'} Successful. You will be redirected to homepage shortly.`}
              showLogo={true}
              align={'center'}
            />
          )}
          {STATUS && STATUS === 'property/POST_VERIFY_VENDOR_OTP_FAILED' && ERROR && (
            <NotificationDialog
              isOpen={errorNotice}
              closeCallback={() => {
                setErrorNotice(false);
              }}
              title={'Error'}
              text={`${action ? 'Approval' : 'Decline'} Error. Please retry.`}
              showLogo={true}
              align={'center'}
            />
          )}
        </Container>
      )}
      {!isLoading && invalidProperty && (
        <Container style={{ paddingTop: '10vh' }}>
          <Typography component="p" style={{ textAlign: 'center' }}>
            Sorry, this approval link is invalid. This property might have been already approved, declined, or doesn't
            exist.
          </Typography>
          <Typography component="p" style={{ textAlign: 'center' }}>
            {"You'll be redirected to homepage in "} <b> {countdown}</b> seconds.
          </Typography>
          <Typography component="p" style={{ textAlign: 'center', paddingTop: '30px' }}>
            Not redirected?{' '}
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = process?.env.REACT_APP_REDIRECT_URL)}
            >
              Go to Homepage Now
            </Button>{' '}
          </Typography>
        </Container>
      )}
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};

/* const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.agents.to_confirm_property,
    property_error: state.agents.request_to_confirm_property_error,
    property_status: state.agents.request_to_confirm_property_status,
    property_approval: state.agents.request_approve_property,
    approval_status: state.agents.request_approve_property_status,
    approval_error: state.agents.request_approve_property_error,
    filters: state.filters,
    vendor_otp_status: state.property.vendor_otp_status,
    vendor_otp_error: state.property.vendor_otp_error,
    vendor_otp: state.property.vendor_otp,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      getPropertyDetails: (params) => requestGetToConfirmProperty(params),
      setApproveProperty: (params) => requestSetConfirmProperty(params),
      setVendorOTP: (propertyId, params) =>  postVendorOTP(propertyId, params),
    },
    dispatch
  )
} */

export default withStyles(styles)(ConfirmProperty);
