import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function TownHouseIcon(props: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12.516 21.72a.2.2 0 01-.115-.363l8.902-6.319a.196.196 0 01.278.047.198.198 0 01-.047.278l-8.903 6.32a.193.193 0 01-.115.037"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M30.325 21.72a.193.193 0 01-.115-.037l-8.905-6.32a.198.198 0 01-.047-.278.196.196 0 01.279-.046l8.903 6.318a.2.2 0 01-.115.363M12.516 24.017a.2.2 0 01-.115-.363l8.902-6.318a.196.196 0 01.278.048.199.199 0 01-.047.279l-8.903 6.318a.193.193 0 01-.115.036"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M30.325 24.017a.193.193 0 01-.115-.036l-8.905-6.319a.199.199 0 01-.047-.278.196.196 0 01.279-.048l8.903 6.318a.2.2 0 01-.115.363"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0"
        width="2"
        height="4"
        x="12"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.317 21.32h1.033v2.698h-1.033v-2.699z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.517 24.018a.2.2 0 01-.2-.2v-2.299c0-.11.09-.2.2-.2.11 0 .2.09.2.2v2.299a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M30.324 24.018a.2.2 0 01-.2-.2v-2.299c0-.11.09-.2.2-.2.11 0 .2.09.2.2v2.299a.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M30.325 21.72a.2.2 0 01-.115-.363l8.901-6.319a.196.196 0 01.279.047.198.198 0 01-.047.278l-8.903 6.32a.193.193 0 01-.115.037"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask1"
        width="10"
        height="7"
        x="39"
        y="15"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M39.027 15h9.302v6.72h-9.302V15z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M48.13 21.72a.193.193 0 01-.115-.037l-8.903-6.32a.198.198 0 01-.047-.278.196.196 0 01.278-.046l8.902 6.318a.2.2 0 01-.115.363"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M30.325 24.017a.2.2 0 01-.115-.363l8.901-6.318a.197.197 0 01.279.048.199.199 0 01-.047.279L30.44 23.98a.193.193 0 01-.115.036"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2"
        width="10"
        height="8"
        x="39"
        y="17"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M39.027 17.298h9.302v6.72h-9.302v-6.72z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M48.13 24.017a.193.193 0 01-.115-.036l-8.903-6.319a.199.199 0 01-.047-.278.197.197 0 01.278-.048l8.902 6.318a.2.2 0 01-.115.363"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M30.324 48.143a.2.2 0 01-.2-.2V21.519c0-.11.09-.2.2-.2.11 0 .2.09.2.2v26.424a.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask3"
        width="2"
        height="4"
        x="47"
        y="21"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M47.297 21.32h1.033v2.698h-1.033v-2.699z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M48.13 24.018a.2.2 0 01-.2-.2v-2.299c0-.11.09-.2.2-.2.11 0 .2.09.2.2v2.299a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask4"
        width="2"
        height="26"
        x="47"
        y="23"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M47.297 23.618h1.033v24.525h-1.033V23.618z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M48.13 48.143a.2.2 0 01-.2-.2V23.818a.2.2 0 01.2-.2.2.2 0 01.2.2v24.125a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask5"
        width="2"
        height="26"
        x="12"
        y="23"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.317 23.618h1.033v24.525h-1.033V23.618z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.517 48.143a.2.2 0 01-.2-.2V23.818a.2.2 0 01.2-.2.2.2 0 01.2.2v24.125a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask6"
        width="37"
        height="34"
        x="12"
        y="15"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.316 48.143H48.33V15H12.316v33.143z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M26.504 47.743h7.642v-8.215h-7.642v8.215zm7.84.4h-8.04a.2.2 0 01-.202-.2v-8.615a.2.2 0 01.201-.2h8.042a.2.2 0 01.2.2v8.615a.2.2 0 01-.2.2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M32.057 43.548a.202.202 0 01-.202-.2c0-.112.087-.2.197-.2h.005a.2.2 0 01.2.2.2.2 0 01-.2.2M28.611 43.548a.202.202 0 01-.201-.2c0-.112.087-.2.197-.2h.005a.2.2 0 01.2.2.2.2 0 01-.2.2M48.13 34.932H12.516a.2.2 0 01-.2-.2c0-.112.089-.2.2-.2H48.13a.2.2 0 01.2.2.2.2 0 01-.2.2M25.153 26.029a.2.2 0 01-.2-.2 3.537 3.537 0 00-3.534-3.534 3.537 3.537 0 00-3.533 3.534.2.2 0 01-.402 0 3.939 3.939 0 013.935-3.934 3.937 3.937 0 013.934 3.934.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M14.816 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M17.11 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M19.984 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M22.857 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M25.728 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M28.027 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M28.027 29.763H14.815a.2.2 0 110-.4h13.212a.2.2 0 110 .4"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M17.687 29.762a.2.2 0 01-.2-.2V25.83a.2.2 0 01.2-.2.2.2 0 01.2.2v3.733a.2.2 0 01-.2.2M25.154 29.762a.2.2 0 01-.2-.2V25.83a.2.2 0 01.2-.2.2.2 0 01.2.2v3.733a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M25.153 26.316h-7.467a.2.2 0 110-.4h7.467a.2.2 0 01.2.2.2.2 0 01-.2.2M42.961 26.029a.2.2 0 01-.2-.2 3.537 3.537 0 00-3.533-3.534 3.537 3.537 0 00-3.533 3.534.2.2 0 01-.402 0 3.939 3.939 0 013.935-3.934 3.937 3.937 0 013.933 3.934.2.2 0 01-.2.2M32.62 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M34.92 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M37.792 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M40.663 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M43.536 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2M45.831 34.931a.2.2 0 01-.2-.2v-5.17c0-.11.09-.2.2-.2.11 0 .2.09.2.2v5.17a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M45.832 29.763H32.62a.2.2 0 110-.4h13.212a.2.2 0 110 .4"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M35.493 29.762a.2.2 0 01-.2-.2V25.83a.2.2 0 01.2-.2.2.2 0 01.2.2v3.733a.2.2 0 01-.2.2M42.96 29.762a.2.2 0 01-.2-.2V25.83a.2.2 0 01.2-.2.2.2 0 01.2.2v3.733a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M42.961 26.316h-7.466a.2.2 0 110-.4h7.466a.2.2 0 01.2.2.2.2 0 01-.2.2M36.844 43.148h8.216v-5.343h-8.216v5.343zm8.416.4h-8.617a.2.2 0 01-.2-.2v-5.743a.2.2 0 01.2-.202h8.617c.11 0 .2.09.2.202v5.743a.2.2 0 01-.2.2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M45.26 40.676h-8.617a.2.2 0 01-.2-.2.2.2 0 01.2-.2h8.617a.2.2 0 01.2.2.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M39.513 43.548a.2.2 0 01-.2-.2v-5.743c0-.112.09-.202.2-.202.11 0 .2.09.2.202v5.743a.2.2 0 01-.2.2M42.386 43.548a.2.2 0 01-.2-.2v-5.743c0-.112.09-.202.2-.202.11 0 .2.09.2.202v5.743a.2.2 0 01-.2.2M15.59 43.148h8.215v-5.343H15.59v5.343zm8.415.4H15.39a.2.2 0 01-.2-.2v-5.743a.2.2 0 01.2-.202h8.614c.11 0 .2.09.2.202v5.743a.2.2 0 01-.2.2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M24.005 40.676H15.39a.2.2 0 01-.2-.2.2.2 0 01.2-.2h8.614a.2.2 0 01.2.2.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M18.261 43.548a.2.2 0 01-.2-.2v-5.743c0-.112.09-.202.2-.202.11 0 .2.09.2.202v5.743a.2.2 0 01-.2.2M21.134 43.548a.2.2 0 01-.2-.2v-5.743c0-.112.09-.202.2-.202.11 0 .2.09.2.202v5.743a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.24"
          d="M12.518 47.943H48.13"
        ></path>
      </g>
    </svg>
  );
}

export function TownHouseIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TownHouseIcon />
      <TownHouseIcon color="primary" />
      <TownHouseIcon color="secondary" />
      <TownHouseIcon color="action" />
      <TownHouseIcon color="disabled" />
      <TownHouseIcon style={{ color: green[500] }} />
    </div>
  );
}
