import React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { withStyles, Button } from '@material-ui/core'

const styles = (theme) => ({
  root: {},
  button: {
    height: '2.250em',
    textTransform: 'capitalize',
    marginLeft: '0.313em',
    marginRight: '0.313em',
    borderRadius: '1.250em',
    whiteSpace: 'noWrap',
    fontWeight: 'normal',
    padding: '12px 15px',
    lineHeight: '2.250em',
    '@media (max-width: 1024px)': {
      padding: '0px 10px',
    },
    '@media (max-width: 890px)': {
      padding: '12px 8px',
      fontSize: '13px',
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    '@media (max-width: 768px)': {
      fontSize: '11px',
    },
  },
})

const ButtonSelection = (props) => {
  const { open, classes, data } = props
  const activeTypes = data.values && data.values.filter((p) => p.active)

  return (
    <>
      <Button
        className={classes.button}
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={
          props.setShowModalOptions ? props.setShowModalOptions : props.toggleFilterWeb
        }
      >
        {data.type === 'cost'
          ? data.selected === '$50k - $15M+' || data.selected === '$0 - $15M+'
            ? 'Price'
            : data.label === 'Any'
            ? 'Price'
            : data.selected
          : data.type === 'propertyType'
          ? activeTypes && activeTypes.length > 0
            ? activeTypes.length +
              ' ' +
              data.label +
              (activeTypes.length > 1 ? 's' : '')
            : data.label
          : Array.isArray(data.selected)
          ? data.selected[0] !== 0 || data.selected[1] !== 5
            ? data.selected[0] +
              '-' +
              (data.selected[1] === 5 ? data.selected[1] + '+' : data.selected[1]) +
              ' ' +
              data.label
            : data.label ?? ''
          : data.selected.value > 0
          ? data.selected.value +
            (!data.selected.exactMatch ? '+' : '') +
            ' ' +
            data.label +
            ' ' +
            (data.value ?? '')
          : data.selected.value === 0 || data.selected.value === ''
          ? data.label + 's'
          : data.label ?? ''}
      </Button>
    </>
  )
}

export default withStyles(styles)(ButtonSelection)
