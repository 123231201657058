import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SuperBreadCrumbs, AgentsForm } from 'components/Web/Superadmin'

/** redux */
import {
  requestGETAPISuperadmin,
  requestPUTAPISuperadmin,
} from 'store/api'

/** selectors */
import {
  getAuthentication,
  getSettings,
  getUser,
  getStatus,
  getErrors,
  getAgentDetails
} from "store/selectors/superadmin.selectors";


import { superadminActions } from 'store/actions'

import Loader from 'components/Web/Loader'

import AlertNotification from "components/Web/Form/AlertNotification";
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
})

const SuperadminEditAgent = (props) => {
  const { classes, history, match } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()

  const AUTH = useSelector(getAuthentication);
  const SETTINGS = useSelector(getSettings);
  const USER = useSelector(getUser);
  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getErrors);
  const AGENT = useSelector(getAgentDetails)

  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
    {
      type: 'secondary',
      label: 'Manage Agency Information',
      path: '/superadmin/manage-agencies/' + match.params.id,
    },
    {
      type: 'secondary',
      label: 'Manage Agents',
      path: '/superadmin/manage-agencies/' + match.params.id + '/agents',
    },
    {
      type: 'primary',
      label: 'Edit Agent',
      path: '',
    },
  ]

  function goBack() {
    routerChange('back')
  }

  useEffect(() => {
    handleClearAgentData()
    handleGetAgentDetails(match.params.agent)
    return () => {
      handleClearAgentData()
    }
  }, [])

  useEffect(() => {
    if (STATUS === 'superadmin/SUPERADMIN_UPDATE_AGENT_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: "Changes successfully saved.",
          autoHideDuration: 3000,
          onClose: handleClearNotices
        }
      }
      setIsNotification(options)
      setSubmitSuccess(true)

    } else if (STATUS === "superadmin/SUPERADMIN_UPDATE_AGENT_REQUEST") {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: "Saving changes...",
          autoHideDuration: 3000,
        }
      }
      setIsNotification(options)

    } else if (STATUS === "superadmin/SUPERADMIN_UPDATE_AGENT_FAILED") {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: "Error saving changes. Please try again.",
          autoHideDuration: 4000,
        }
      }
      setIsNotification(options)
    }
  }, [STATUS])

  const submitAgents = () => { };

  const handleGetAgentDetails = (id) => {
    const params = {
      query: id,
      data: null
    }
    dispatch(requestGETAPISuperadmin(params, 'SUPERADMIN_GET_AGENT_DETAILS'))
  }

  const handleSubmit = (data) => {
    const params = {
      query: match.params.agent + '/update',
      data: data,
    }
    dispatch(requestPUTAPISuperadmin(params, 'SUPERADMIN_UPDATE_AGENT'))
  }

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  }

  const handleClearAgentData = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_GET_AGENT_DETAILS', 'CLEAR'))
  }

  const handleClearData = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'RESET_STATES'))
  }

  const handleClearNotices = () => {
    handleClearStatus()
    handleClearError()
    setIsNotification(defaultNotification)
  }


  return (
    <div className={classes.root}>
      <SuperBreadCrumbs
        section={'Manage Agents'}
        crumbsLinks={CRUMBS}

      />
      {AGENT && AGENT?.data?.entity &&
        <AgentsForm
          match={match}
          goBack={goBack}
          agent={AGENT.data.entity}
          handleSubmit={handleSubmit}
          submitStatus={submitSuccess}
        />}
      {isNotification.status &&
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      }
    </div>
  )
}
export default withStyles(styles)(SuperadminEditAgent)
