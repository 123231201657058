import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { AuthImages } from 'components/Web/Superadmin'
import { AgencyActivateForm } from 'components/Web/Agency'
import AlertNotification from 'components/Web/Form/AlertNotification'

/** actions */
import { agencyv2Actions } from 'store/actions'
import { requestPUTAPIAgency } from 'store/api'
import { getStatus, getErrors } from 'store/selectors/agencyv2.selectors'
import AccountActivationDialog from 'components/Web/DialogContainer/AccountActivationDialog'
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    height: '100vh',
    padding: 40,
    paddingLeft: '10vw',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const AgencyActivate = (props) => {
  const { classes, match } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()

  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)

  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)
  const [openDialog, setOpenDialog] = useState(false)

  const closeDialog = () => {
    setOpenDialog(false)
  }

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  /** API Request */
  const handleAgencyActivate = (data) => {
    let request = {
      query: match.params.token,
      data: data,
    }

    handleClearStatus()
    dispatch(requestPUTAPIAgency(request, 'AGENCY_ACTIVATE'))
  }
  window.onbeforeunload = (e) => {
    return handleClearStatus()
  }

  useEffect(() => {
    handleClearStatus()
    return () => {
      handleClearStatus()
    }
  }, [])

  useEffect(() => {
    if (STATUS === 'agency/AGENCY_ACTIVATE_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message:
            'Password successfully set. You will now be redirected to login to your dashboard.',
          autoHideDuration: 4000,
          // onClose: () => routerChange('/agency/login')
        },
      }
      setIsNotification(options)
      setOpenDialog(true)
    } else if (STATUS === 'agency/AGENCY_ACTIVATE_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Activating account...',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    } else if (
      STATUS === 'agency/AGENCY_ACTIVATE_FAILED' &&
      ERROR?.message !== 'Agency not found'
    ) {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error activating your account, please try again in few minutes.',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    } else if (
      STATUS === 'agency/AGENCY_ACTIVATE_FAILED' &&
      ERROR?.message === 'Agency not found'
    ) {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message:
            'Cannot perform this operation. The link is either invalid or the Agency you are activating is already activated. Thank you.',
          autoHideDuration: 6000,
          onClose: () => routerChange('/'),
        },
      }
      setIsNotification(options)
    }
  }, [STATUS])

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
  }

  return (
    <div className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={5} className={classes.leftColumn}>
          <AgencyActivateForm
            type={'agency'}

            handleSubmit={handleAgencyActivate}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={7} className={classes.rightColumn}>
          <AuthImages type={'login'} />
        </Grid>
      </Grid>
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}

      {openDialog && (
        <AccountActivationDialog
          isOpen={openDialog}
          closeCallBack={closeDialog}

          redirectUrl={'/agency/login'}
          title={'Finito!'}
          message={'Activation complete'}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(AgencyActivate)
