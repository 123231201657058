import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Image from 'material-ui-image';
import DynamicIcons from "../../components/Mobile/DynamicIcons";
import AgentCards from '../../components/Mobile/Agent/AgentCards';
import HorizontalContainer from '../../components/Mobile/Property/HorizontalContainer';
import ContactWrap from '../../components/Mobile/Agency/ContactWrap';
import _ from 'lodash'

import styled, { css } from 'styled-components';

/** actions */
import {
  setPropertySuburbSearch,
  clearSuburbSearch,
} from '../../store/actions';

/** api redux request */
import {
  requestAgencyDetails
} from "../../store/api";

import { withStyles, Button, Typography } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px'
  },
})(Typography);

const BackButton = withStyles({
  root: {
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
  },
});

const HeaderImage = styled.div`
  position:relative;
  .bg-overlay {
    position:absolute;
    z-index:2;
    background-color:rgba(255,255,255,0.5);
    width:100%;
    height:100%;
  }

`

const AgencyLogo = styled.img`
  position:absolute;
  z-index:5;
  top:50%;
  transform:translate(-50%, -50%);
  height:3.857em;
  width:auto;
  left:50%;
  text-align:center;
`

const Content = styled.div`
  padding:20px 25px;

  h2 {
    font-size:20px;
    margin-bottom:13px;
  }

  h6 {
    font-family:Roboto;
    font-weight:500;
    margin-bottom:13px;
  }
  
  p {
    font-size:13px;
    line-height:1.3;
    margin-bottom:13px;
  }

`

const PropertyWrap = styled.div`

`

const TeamWrap = styled.div`
  
`
const PropertyFeeds = styled.div`
  h2 {
      font-weight:400;
  }
`

const AgencyDetails = props => {

  const {
    classes,
    currentAgency,
    getAgencyDetails
  } = props;
  const [routerChange] = useRouterChange()
  const [agencyData, setAgencyData] = React.useState(null);
  const [sold, setSold] = React.useState(null)
  const [sale, setSale] = React.useState(null)
  const addressRegex = / *AU *$|AUS *$/gm;

  /** 
   * Save Current Agency API */
  async function apiRequestSetCurrentAgency() {
    let params = props.match.params.id + '/profile';
    if (params) {
      getAgencyDetails(params);
    }
  }

  function iterateItems(obj) {
    const items = [];
    for (const [key, details] of Object.entries(obj)) {
      items.push({ ...details })
    }
    return items;
  }

  React.useEffect(() => {
    apiRequestSetCurrentAgency();
  }, [])

  React.useEffect(() => {
    if (currentAgency && currentAgency.data && currentAgency.data.agency) {
      setAgencyData(currentAgency.data.agency);
      if (currentAgency.data.agency.properties) {
        const ALL_SOLD = _.pickBy(currentAgency.data.agency.properties, e => _.includes(['sold'], e.propertyStatus))
        const ALL_CURRENT = _.pickBy(currentAgency.data.agency.properties, e => _.includes(['current', 'On-Sale', 'rental'], e.propertyStatus))
        setSold(iterateItems(ALL_SOLD))
        setSale(iterateItems(ALL_CURRENT))
        console.log(sold, 'SOLD', currentAgency)

      }
    }
  }, [currentAgency])

  function routeBack(path) {
    routerChange("back")
  }

  const DUMMY = {
    images: [
      'https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2019/8/1/1/uo2019_living-room-01-wide-blinds-up-KB2A8968_h.jpg.rend.hgtvcom.966.644.suffix/1564684055231.jpeg'
    ],
    agencyLogo: 'https://placeholder.com/wp-content/uploads/2018/10/placeholder.com-logo1.png',
    description: 'Cras et tortor ornare, mattis justo at, rutrum neque. Integer at lectus non nulla hendrerit pretium non nec ipsum. Proin lacinia ut diam vel cursus. Morbi tristique magna eget quam lacinia mollis. In faucibus vulputate libero et blandit. Donec mattis est felis, eu molestie eros consequat quis. In hac habitasse platea dictumst. Phasellus sodales venenatis congue.',
    profilePropertiesIntro: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    agents: [
      { "shardId": 1, "positiveRatings": 1, "propertiesSold": 0, "propertiesForSale": 0, "propertiesForRent": 0, "_id": "5f75a5b12d121d3ca2ee447a", "email": "propertytest", "firstName": "firstagent", "lastName": "last agent", "about": "this is me", "agencyName": "agency 1", "postcode": "3031", "createdAt": "2020-10-01T09:47:29.094Z", "updatedAt": "2020-10-01T09:47:29.094Z", "__v": 0, "reviews": [{ "timestamp": 1602561729892, "like": true, "comment": "Test", "user": "5f59a28d369ce3390a4820a1" }], "ratingPercentage": 100 },
      { "shardId": 1, "positiveRatings": 1, "propertiesSold": 0, "propertiesForSale": 0, "propertiesForRent": 0, "_id": "5f75a5b12d121d3ca2ee447a", "email": "propertytest", "firstName": "firstagent", "lastName": "last agent", "about": "this is me", "agencyName": "agency 1", "postcode": "3031", "createdAt": "2020-10-01T09:47:29.094Z", "updatedAt": "2020-10-01T09:47:29.094Z", "__v": 0, "reviews": [{ "timestamp": 1602561729892, "like": true, "comment": "Test", "user": "5f59a28d369ce3390a4820a1" }], "ratingPercentage": 100 },
    ]
  }

  return (
    <div className={classes.root}>

      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routeBack()}>
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
      </div>
      <HeaderImage>
        <div className="bg-overlay"></div>
        {agencyData && agencyData.coverImageUrl &&
          <Image
            className={classes.media}
            src={agencyData.coverImageUrl}
            onClick={() => ''}
            aspectRatio={(16 / 9)}
            disableSpinner
            style={{ objectFit: 'cover' }}
          />
        }
        {agencyData && agencyData.logoUrl && <AgencyLogo src={agencyData.logoUrl} />}
      </HeaderImage>
      <ContactWrap></ContactWrap>
      {agencyData &&
        <>
          <Content>

            <Typography variant="h6">{agencyData.registeredTradeName ? agencyData.registeredTradeName : agencyData.companyName}</Typography>

            <Typography variant="body1">{agencyData.address}</Typography>

            <Typography variant="body1">{agencyData.profilePropertiesIntro}</Typography>

            <PropertyWrap>

            </PropertyWrap>

          </Content>

          <Content><Typography variant="h2">{agencyData.registeredTradeName ? agencyData.registeredTradeName : agencyData.companyName} Team</Typography></Content>
          <HorizontalContainer>
            {agencyData && agencyData.agents && agencyData.agents.map((agent, index) => {
              return <AgentCards key={index} agent={agent} type={'agent-card'} />
            })
            }

          </HorizontalContainer>
          {agencyData.properties.length > 0 && sale.length > 0 &&
            <Content>
              <PropertyFeeds>
                <Typography variant="h2">Our Properties</Typography>
                <Typography variant="body1">{agencyData.profilePropertiesIntro}</Typography>
              </PropertyFeeds>
            </Content>
          }

        </>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stepCounter: null,
    currentAgency: state.agency.current_agency,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getAgencyDetails: params => requestAgencyDetails(params)
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgencyDetails));
