import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import RangeSlider from './RangeSlider'
import CustomSwitch from '../Filter/CustomSwitch'
import { PRICE_RANGE } from '../../../constants/constants'

import { Typography, withStyles } from '@material-ui/core'

const Container = styled.div`
  padding: 20px 0;
`

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
  .slider-Bedrooms {
    .MuiSlider-markLabel[data-index='0'] {
      left: 4% !important;
    }
  }
`
const StyledText = withStyles((theme) => ({
  root: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
}))(Typography)

const CheckWrap = styled.div`
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: -25px;
`

const marksBedroom = [
  {
    value: 1,
    label: 'Studio',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
]

const marksDefault = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
]

const priceRange = PRICE_RANGE
const priceRangeRent = [250, 5000]

const SliderContainer = (props) => {
  const [price, setPrice] = useState([
    props.propertyDetails.price.min,
    props.propertyDetails.price.max,
  ])
  const [priceRent, setPriceRent] = useState([
    props.propertyDetails.priceRent.min,
    props.propertyDetails.priceRent.max,
  ])
  const [bedrooms, setBedrooms] = useState(props.propertyDetails.bedrooms)
  const [bathrooms, setBathrooms] = useState(props.propertyDetails.bathrooms)
  const [carparking, setCarparking] = useState(props.propertyDetails.carparking)
  const [pricePrecise, setPricePrecise] = useState(props.price)
  const [priceStep, setPriceStep] = useState(priceRange[0])

  useEffect(() => {
    if (props.isClearFilter) {
      setPriceRent(priceRangeRent)
      setPrice(priceRange)
      setBedrooms(null)
      setBathrooms(null)
      setCarparking(null)
      handleData('clear')
    }
  }, [props.isClearFilter])

  useEffect(() => {
    handleData()
  }, [props.hidePrice])

  const handlePriceChange = (event, newValue) => {
    let minVal = newValue[0]
    if (minVal > 5000000 && minVal < 10000000) {
      setPriceStep(500000)
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000)
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000)
    } else {
      setPriceStep(50000)
    }

    if (
      (newValue[0] > 5000000 && newValue[0] < priceRange[1]) ||
      (newValue[1] > 5000000 && newValue[1] < priceRange[1])
    ) {
      if (newValue[0].toFixed(2).search('50000.00') > -1) {
        let nMin = String(newValue[0]).replace('50000', '00000')
        newValue[0] = parseInt(nMin)
      }
      if (newValue[1].toFixed(2).search('50000.00') > -1) {
        let nMax = String(newValue[1]).replace('50000', '00000')
        newValue[1] = parseInt(nMax)
      }
      setPrice(newValue)
    } else {
      setPrice(newValue)
    }
  }

  const handlePricePreciseChange = (event, value) => {
    let minVal = value
    if (minVal > 5000000 - 1 && minVal < 10000000) {
      setPriceStep(500000)
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000)
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000)
    } else {
      setPriceStep(50000)
    }
    setPricePrecise(value)
  }

  const handlePriceRentChange = (event, newValue) => {
    setPriceRent(newValue)
  }

  const handleBedroomsChange = (event, newValue) => {
    setBedrooms(newValue)
  }

  const handleBathroomsChange = (event, newValue) => {
    setBathrooms(newValue)
  }

  const handleCarparkingChange = (event, newValue) => {
    setCarparking(newValue)
  }

  const handleChangeCommitted = () => {
    handleData()
  }
  const handleData = (clear) => {
    const propertyData = {
      price: pricePrecise,
      priceRent: {
        min: priceRent[0],
        max: priceRent[1],
      },
      bedrooms,
      bathrooms,
      carparking,
      hidePrice: props.hidePrice,
    }
    if (props.handlePropertyDetails) {
      if (clear) {
        propertyData.price.min = priceRange[0]
        propertyData.price.max = priceRange[1]
        propertyData.priceRent.min = 250
        propertyData.priceRent.max = 5000
        propertyData.bedrooms = null
        propertyData.bathrooms = null
        propertyData.carparking = null
      }
      props.handlePropertyDetails(propertyData)
    }
  }

  return (
    <Container>
      <SliderItem>
        {
          // <RangeSlider label="Price" icon="MonetizationOn" value={price} range={priceRange} step={priceRange[0]} handleChange={handlePriceChange} handleChangeCommitted={handleChangeCommitted} valueLabelDisplay={'off'} />
          props.path === 'rent' ? (
            <RangeSlider
              path={props.path}
              label="Price Per Week"
              icon="MonetizationOn"
              value={priceRent}
              range={priceRangeRent}
              step={50}
              handleChange={handlePriceRentChange}
              handleChangeCommitted={handleChangeCommitted}
              valueLabelDisplay={'off'}
            />
          ) : (
            <RangeSlider
              label="Price"
              icon="MonetizationOn"
              value={pricePrecise}
              isPrecise={true}
              step={priceStep}
              handleChange={handlePricePreciseChange}
              handleChangeCommitted={handleChangeCommitted}
              min={priceRange[0]}
              max={priceRange[1]}
              valueLabelDisplay={'off'}
            />
          )
        }
      </SliderItem>
      {
        <CheckWrap>
          <CustomSwitch
            label={'Show Price'}
            checked={props.hidePrice}
            onChange={(e) => {
              props.setHidePrice(!props.hidePrice)
            }}
          />
          <StyledText component={'span'}>POA</StyledText>
        </CheckWrap>
      }
      <SliderItem>
        <RangeSlider
          label="Bedrooms"
          icon="Hotel"
          value={bedrooms}
          handleChange={handleBedroomsChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={marksBedroom}
          min={1}
          max={5}
        />
      </SliderItem>
      <SliderItem>
        <RangeSlider
          label="Bathrooms"
          icon="2DCBathIcon"
          value={bathrooms}
          handleChange={handleBathroomsChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={marksDefault}
          min={1}
          max={5}
        />
      </SliderItem>
      <SliderItem>
        <RangeSlider
          label="Carparking"
          icon="DirectionsCar"
          value={carparking}
          handleChange={handleCarparkingChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={marksDefault}
          min={1}
          max={5}
        />
      </SliderItem>
    </Container>
  )
}

export default SliderContainer
