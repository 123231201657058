import React, {useState, useEffect} from "react";
import styled, { css } from 'styled-components'
import Heading from './Heading'
import CustomCheckbox from './CustomCheckbox'

import { Typography, withStyles } from '@material-ui/core';

const Container = styled.div`
  padding:0 0 20px 0;
`

const Row = styled.div`
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  padding-top:10px;
`

const Col = styled.div`
  width:50%;
`


const featureListData = [{
  id:'0',
  label:'Capital city',
  active:false
},
/* {
  id:'1',
  label:'Suburbs',
  active:false
},{
  id:'2',
  label:'Rural setting',
  active:false
},{
  id:'3',
  label:'Country Town',
  active:false
} */
]

const NearProperties = props => {
  const [listData, setListData] = useState(props.listData || featureListData);
 
  useEffect(()=>{
    if(props.isClearFilter) {
      setListData(featureListData)
    }
  },[props.isClearFilter])
  

  useEffect(()=>{
    setListData(props.listData)
  },[props.listData])


  useEffect(()=>{
  },[listData])

  const handleChange = (e, obj, index) => {
    let list = listData.map(item => 
      item.id === obj.id 
      ? {...item, active : !obj.active} 
      : item 
    )
    setListData(list);

    if(props.handleDistanceFromKm) {
      let data = {near: list} 
      console.debug(data, 'Checkbox')
      props.handleDistanceFromKm(data)
    }
  };

  return (
    <Container>
      <Heading variant="h6" style={{fontWeight:400, color:'#4d4d4d'}}>Properties near</Heading>
      <Row>

        <Col>
        {
          listData && listData.map((item, index) => {
            if(index < 2) {
              return <CustomCheckbox checked={item.active} label={item.label} key={index} onChange={(e)=>{handleChange(e, item, index)}} />
            }
          })
        }
        </Col>
        <Col>
        {
          listData && listData.map((item, index) => {
            if(index > 1) {
              return <CustomCheckbox checked={item.active} label={item.label} key={index} onChange={(e)=>{handleChange(e, item, index)}} />
            }
          })
        }
        </Col>
      </Row>
    </Container>
  );
};

export default NearProperties;