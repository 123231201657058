import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import DynamicIcons from '../DynamicIcons';

import { Typography, withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const Container = styled.div`
  padding: 10px 0 20px;
  &.full {
    width: 100%;
    padding: 0;
  }
`;

const StyledFormControl = withStyles({
  root: {
    width: '100%',
  },
})(FormControl);

const StyledInputLabel = withStyles({
  root: {},
})(InputLabel);

const StyledSelect = withStyles({
  root: {},
})(Select);

const StyledMenuItem = withStyles({
  root: {},
})(MenuItem);

const CustomSelect = (props) => {
  const [value, setValue] = React.useState(props.value || '');
  const [min, setMin] = React.useState(props.min || '');
  const [max, setMax] = React.useState(props.max || '');

  useEffect(() => {
    // console.log('custom select')
    // console.log(props.isClearFilter)
    if (props.isClearFilter) {
      setValue('');
    }
  }, [props.isClearFilter]);

  const handleChange = (event) => {
    setValue(event.target.value);
    props.handleSelect(event.target.value);
  };

  useEffect(() => {
    setMin(props.min);
    setMax(props.max);
  }, [props.min, props.max]);

  return (
    <Container className={props.full ? 'full' : ''} style={props.isForm ? { padding: '0px' } : {}}>
      <StyledFormControl variant="outlined">
        {props.placeholder && (!value || value === '') && (
          <StyledInputLabel shrink={false}>{props.placeholder}</StyledInputLabel>
        )}
        {props.label && (
          <Typography variant={'caption'} style={{ color: 'gray' }}>
            {props.label}
          </Typography>
        )}
        <StyledSelect
          {...props.styles}
          value={value}
          onChange={handleChange}
          renderValue={props.renderValue}
          // IconComponent={<DynamicIcons icon="ExpandMore" />}
        >
          {props.options &&
            props.options.map((item, index) => {
              if (min) {
                if (parseInt(item.value) >= min) {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                } else if (item.value === 'any') {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                }
              } else if (max) {
                if (parseInt(item.value) <= max) {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                } else if (item.value === 'any') {
                  return (
                    <StyledMenuItem value={item.value} key={index}>
                      {item.label}
                    </StyledMenuItem>
                  );
                }
              } else {
                return (
                  <StyledMenuItem value={item.value} key={index}>
                    {item.label}
                  </StyledMenuItem>
                );
              }
            })}
          {!props.options && props.isLoading && <StyledMenuItem>Loading {props.label}...</StyledMenuItem>}
          {!props.options && !props.isLoading && <StyledMenuItem>Error loading {props.label}</StyledMenuItem>}
        </StyledSelect>
      </StyledFormControl>
    </Container>
  );
};

export default CustomSelect;
