import React from 'react';
import DynamicIcons from "../DynamicIcons";

import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import { useSelector } from 'react-redux';
import { getPropertyDetails } from 'store/selectors/propertyv2.selectors';

const Marker = ({ content }) => <div>{content}</div>;

const getMapOptions = (maps, type) => {
  return {
    panControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
    zoomControl: false,
    clickableIcons: false
  }
}

const GoogleMapView = (props) => {
  const { data } = props
  const PROPERTY = useSelector(getPropertyDetails)

  return (
    <>
      {
        <GoogleMapReact
          bootstrapURLKeys={{
            key: GOOGLE_MAP_API_KEY,
          }}
          center={PROPERTY?.location?.coordinates?.reverse() ?? [parseFloat(data?.map?.coordinates[0]), parseFloat(data?.map?.coordinates[1])]}
          zoom={data?.map.zoom}
          options={({ map, maps }) => getMapOptions(maps)}
        >
          {data?.map?.markers?.length > 0 && data?.map?.markers?.map((marker, idx) => {
            return (
              <Marker {...marker} key={idx}
                content={<DynamicIcons viewBox="0 0 24 28"
                  style={{ transform: 'translate(-50%, -70%)', width: '1.5em', height: '1.5em' }}
                  icon="2DCSaleDropInIcon" />
                }
              />
            )
          })
          }
        </GoogleMapReact>
      }
    </>
  )
}

export default GoogleMapView