import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import AgentSearchBar from './AgentSearchBar';
import AgentCards from './AgentCards';
import PropertyLoader from '../Property/PropertyLoader';

import { withStyles, Button, Typography } from '@material-ui/core';

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
  },
})(Typography);

const StyledSubText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '15px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative'
  },
})(Typography);

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
  },
  content: {
    margin: theme.spacing(2)
  },
  searchbarContainer: {
    padding: theme.spacing(1)
  },
  cardContent: {
    width: '100vw',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  nullMessageContainer: {
    paddingTop: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  nullMessage: {
    textAlign: 'center',
    color: 'gray',
  }
});

const AgentResults = props => {

  const {
    classes,
    match,
    agentsData,
    agents,
    agencies,

    executeTopScroll,
    agentReducer,
    clearAgentSearchParams
  } = props;

  React.useEffect(() => {

  }, [])

  return (
    <div>
      <div className={classes.content}>
        <StyledText variant="h2">
          Find a real estate agent
        </StyledText>
        <StyledSubText variant="h2">
          Find the perfect agent to find your home.
        </StyledSubText>

        <div className={classes.searchbarContainer}>
          <AgentSearchBar
            match={match}
            clearAgentSearchParams={clearAgentSearchParams}
            showInlineSearchButton={false}
            agentReducer={agentReducer}
            agents={agents}
            agencies={agencies}
            apiRequestSearchAgent={props.apiRequestSearchAgent}
            agentsData={agentsData} />
        </div>

      </div>

      <div className={classes.cardContent}>
        {
          agentsData && agentsData.map((value, index) => {
            return <AgentCards key={index} agent={value} type={'agent-card'} />
          })
        }
        {
          agentsData && agentsData.length === 0 &&
          <div className={classes.nullMessageContainer}>
            <StyledSubText className={classes.nullMessage} variant="h2">
              No result found
            </StyledSubText>
          </div>
        }
      </div>
      {
        agentsData && agentsData.length > 4 &&
        <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
      }
    </div>
  );
};

export default (withStyles(styles)(AgentResults));
