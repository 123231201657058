import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { searchActions } from 'store/actions'
import { mppActions } from 'store/actions'
import { FiltersActions } from 'store/actions'
import { requestPOSTAPISearch, requestPOSTAPIMpp } from 'store/api'
import { paramsToSha1 } from 'utils/search.utils'
import { formatRequest } from './query/formatRequest'
import {
  getReferenceFilters,
  getAllFilters,
  getSearchResults,
  getSearchResultsData,
  getMppSearchResults,
} from './selectors/selectors.search'
import { useSearchType } from './useSearchType'
import { funnelDown } from './utils/funnelDown'
import { getLikesDislikes, getFavourites } from 'store/selectors/mpp.selectors'
import { handleSearchPropertyReference } from 'utils/handleSearchPropertyReference'
import { StorageActions } from 'store/actions/storage.actions'
import { getSettings } from 'store/selectors/generic.selectors'
import { toggleFilterWeb } from 'store/actions'
import generateUrlQuery from './utils/generateUrlQuery'
import { getAuthentication } from 'store/selectors/authentication.selectors'

export function useSearch(pagination) {
  const dispatch = useDispatch()

  const AUTHENTICATION = useSelector(getAuthentication)
  const REFERENCE_FILTERS = useSelector(getReferenceFilters)
  const CURRENT_FILTERS = useSelector(getAllFilters)
  const MPP_SEARCH_RESULTS = useSelector(getMppSearchResults)
  const SEARCH_RESULTS = useSelector(getSearchResults)
  const SEARCH_RESULTS_DATA = useSelector(getSearchResultsData)
  const GET_LIKES_DISLIKES = useSelector(getLikesDislikes)
  const GET_USER_LIKES_DISLIKES = useSelector(getFavourites)
  const SETTINGS = useSelector(getSettings)

  const SEARCH_ROUTES = [
    '/buy',
    '/rent',
    '/off-market',
    '/hush-hush',
    '/perfect-property',
  ]

  let history = useHistory()
  const queryParameters = new URLSearchParams(history.location.search)

  const [searchData, setSearchData] = useState()

  const [isWidened, isNarrowed] = useSearchType()

  useEffect(() => {
    const getCurrentReference = handleSearchPropertyReference(
      CURRENT_FILTERS?.search_reference
    )
    const getPreviousReference = handleSearchPropertyReference(
      REFERENCE_FILTERS?.search_reference
    )

    if (SEARCH_RESULTS?.status === 'search/POST_PROPERTY_SEARCH_REQUEST') {
      dispatch(
        FiltersActions(
          { key: 'suburbs', value: CURRENT_FILTERS?.suburbs },
          'GENERAL_FILTERS',
          'SET_REFERENCE_FILTER'
        )
      )
    }

    if (
      SEARCH_RESULTS?.status === 'search/POST_PROPERTY_SEARCH_REQUEST' &&
      (getCurrentReference !== history?.location?.pathname || !getPreviousReference)
    ) {
      dispatch(searchActions(null, 'GENERAL_SEARCH', 'RESET_STATES'))
    }
  }, [
    SEARCH_RESULTS?.search_results,
    SEARCH_RESULTS?.mpp_search_results,
    SEARCH_RESULTS?.status,
  ])

  useEffect(() => {
    const getCurrentReference = handleSearchPropertyReference(
      CURRENT_FILTERS?.search_reference
    )

    const getPreviousReference = handleSearchPropertyReference(
      REFERENCE_FILTERS?.search_reference
    )

    if (
      MPP_SEARCH_RESULTS?.status === 'mpp/PERFECT_PROPERTY_RECOMMENDATION_REQUEST' &&
      (getCurrentReference !== history?.location?.pathname || !getPreviousReference)
    ) {
      history.push(getCurrentReference)
    }
  }, [MPP_SEARCH_RESULTS?.mpp_search_results, MPP_SEARCH_RESULTS?.status])

  useEffect(() => {
    if (SEARCH_RESULTS_DATA?.length) {
      setSearchData(SEARCH_RESULTS_DATA)
    }
  }, [SEARCH_RESULTS_DATA])

  const handleSearchRequest = (excludeList) => {
    dispatch(searchActions(null, 'FUNNEL_DOWN_SEARCH', 'CLEAR'))
    dispatch(StorageActions(0, 'GENERAL_SETTINGS', 'SET_SCROLL_POSITION'))
    const payload = formatRequest(CURRENT_FILTERS)

    //?This payload reference check will fallback if in some cases the search_reference did not register and a search request has been initiated, it will fallback to current screen pathname
    if (!payload?.reference) {
      payload.reference =
        history.location.pathname !== '/' &&
        SEARCH_ROUTES.includes(history.location.pathname)
          ? history.location.pathname?.split('/')[1]
          : undefined
    }

    const property_reference = payload?.reference
    if (property_reference === 'perfect-property') {
      payload.likes = excludeList?.likes ?? []
      payload.dislikes = excludeList?.dislikes ?? []
    }
    const params = {
      query: `?filters=${paramsToSha1(payload)}`,
      data: payload,
    }

    if (property_reference === 'perfect-property') {
      dispatch(mppActions(null, 'MPP_PAGINATION_REFERENCE', 'CLEAR'))
      dispatch(requestPOSTAPIMpp(params, 'PERFECT_PROPERTY_RECOMMENDATION'))
      return
    } else {
      dispatch(requestPOSTAPISearch(params, 'POST_PROPERTY_SEARCH'))
    }
  }

  async function initializeSearch(excludeList) {
    const { value: widened, reference: widenedRef } = isWidened()
    const { value: narrowed, reference: narrowedRef } = isNarrowed()

    const getCurrentReference = handleSearchPropertyReference(
      CURRENT_FILTERS?.search_reference
    )
    const getPreviousReference = handleSearchPropertyReference(
      REFERENCE_FILTERS?.search_reference
    )

    const mutateGlobalFiltersIntoUrl = () => {
      const path = handleSearchPropertyReference(CURRENT_FILTERS?.search_reference)

      const urlQuery = generateUrlQuery(path, CURRENT_FILTERS)

      history.push(
        `${path}${urlQuery ? `?${urlQuery}` : queryParameters.toString()}
          `,
        {
          previousSearchRef: getPreviousReference,
          currentSearchRef: getCurrentReference,
        }
      )
    }

    if (SETTINGS?.showFilterWeb?.status) {
      dispatch(toggleFilterWeb({ type: null, status: false }))
    }

    if (getCurrentReference !== '/perfect-property') {
      mutateGlobalFiltersIntoUrl()
    }

    if (getCurrentReference === '/perfect-property') {
      dispatch(mppActions(null, 'MPP_PAGINATION_REFERENCE', 'CLEAR'))

      let refLikesDislikes
      if (AUTHENTICATION?.user_status !== 'logged') {
        refLikesDislikes = GET_LIKES_DISLIKES
      } else {
        refLikesDislikes = {
          likes: [
            ...(GET_USER_LIKES_DISLIKES?.data?.likes?.map(
              (property) => property.property
            ) ?? []),
            ...(GET_LIKES_DISLIKES?.likes ?? []),
          ],
          dislikes: [
            ...(GET_USER_LIKES_DISLIKES?.data?.dislikes?.map(
              (property) => property.property
            ) ?? []),
            ...(GET_LIKES_DISLIKES?.dislikes ?? []),
          ],
        }
      }

      handleSearchRequest(excludeList ? excludeList : refLikesDislikes)
    } else if (REFERENCE_FILTERS) {
      if (getCurrentReference !== getPreviousReference) {
        dispatch(searchActions(null, 'GENERAL_SEARCH', 'RESET_STATES'))
      }

      if (widened) {
        dispatch(searchActions(null, 'GENERAL_SEARCH', 'RESET_STATES'))
        console.debug(
          'Search has widened and will dispatch a search query here',
          widenedRef
        )
        handleSearchRequest(excludeList)
      }

      if (narrowed && searchData?.length && !widened) {
        dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'))
        const deconstructedRefs = Object.keys(narrowedRef)
          .map((i) => narrowedRef[i])
          ?.map((i) => Object.keys(i).toString())
        console.debug(
          'Search has narrowed and will just filter the current items on the loaded data',
          deconstructedRefs,
          narrowedRef
        )
        const narrowedData = await funnelDown(
          CURRENT_FILTERS,
          searchData,
          deconstructedRefs ?? []
        )
        dispatch(searchActions(narrowedData, 'FUNNEL_DOWN_SEARCH', 'UPDATE'))
        dispatch(
          FiltersActions(
            { key: 'suburbs', value: CURRENT_FILTERS?.suburbs },
            'GENERAL_FILTERS',
            'SET_REFERENCE_FILTER'
          )
        )
      } else if (narrowed && searchData?.length === 0) {
        console.debug('Search is narrowed but data has been purged')
        dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'))
        handleSearchRequest(excludeList)
      }

      if (!narrowed && !widened && searchData?.length > 0) {
        console.debug(
          'Search is the same and will not trigger a search',
          widenedRef,
          narrowedRef
        )
        dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'))
        dispatch(searchActions(null, 'FUNNEL_DOWN_SEARCH', 'CLEAR'))
        handleSearchRequest(excludeList)
      }

      if (
        getCurrentReference !== history?.location?.pathname ||
        !getPreviousReference
      ) {
        // history.push(getCurrentReference)
      }
    } else {
      dispatch(FiltersActions(null, 'GENERAL_FILTERS', 'SET_REFERENCE_FILTER'))
      dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'))
      dispatch(mppActions(null, 'MPP_PAGINATION_REFERENCE', 'CLEAR'))
      dispatch(searchActions(null, 'FUNNEL_DOWN_SEARCH', 'CLEAR'))
      handleSearchRequest(excludeList)
    }
  }

  return [initializeSearch]
}
