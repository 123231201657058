import React, { useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'

import AgencySignUpForm from 'components/Web/Agency/AgencySignUpForm'
import Image from 'components/Web/Image'

import TextImage01 from 'assets/images/content/signup-step1.svg'
import TextImage02 from 'assets/images/content/signup-step2.svg'

import { clearFilters } from 'store/actions'
import theme from 'theme/light'
import { Container, Fade, Grid, Typography, withStyles } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    paddingLeft: 106,
    paddingRight: theme.spacing(2),
    margin: '0 auto',
    // maxWidth: '75vw'
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  sectionHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 52,
    lineHeight: '45.5px',
    color: theme.palette.gray.main,
    letterSpacing: '-0.78px',
    marginBottom: '32px',
    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
      fontSize: 56,
    },
    '@media (max-width: 960px) ': {
      textAlign: 'center',
    },
  },
  sectionSubHeading: {
    fontFamily: 'Montserrat, sans',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.52px',
  },
  sectionLeadText: {
    color: theme.palette.gray.main,
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '1.6em',
    '@media (max-width: 960px) ': {
      marginBottom: 64,
      textAlign: 'center',
    },
  },
  textContents: {
    // flexDirection: "column-reverse",
    // alignItems: "flex-end",
    display: 'flex',
    paddingRight: '20px !important',
    paddingLeft: '15px',
    '@media (max-width: 1280px) ': {
      paddingLeft: 0,
    },
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textImageWrap: {
    /* paddingLeft: 75,
      "@media (max-width: 960px) " : {
        paddingLeft: 0,
      },
      '@media (min-width: 1440px)': {
        paddingLeft: 100,
      }, */
  },
  imageWrap: {
    maxWidth: 570,
    marginBottom: 18,
    '@media (max-width: 960px) ': {
      margin: '0 auto',
      marginBottom: 60,
      display: 'none',
    },
  },

  formWrapper: {
    '@media (max-width: 960px) ': {
      margin: '0 auto',
      '& > h2': {
        textAlign: 'center',
      },
    },
  },
})

const PageHeading = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px 0',
    zIndex: 10,
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})(Typography)

const AgencySignUp = (props) => {
  const { classes, openSnackbar } = props

  const [formStep, setFormStep] = useState(1)

  React.useEffect(() => {
    /** show alert to relogin */
    if (localStorage.getItem('showSnackBar') !== null) {
      openSnackbar('Session Expired.')
      localStorage.removeItem('showSnackBar')
    }
    document.getElementById('root').style.backgroundColor = '#f5f5f5'
    /** CLEAR FILTERS */
    //clearAllFilters();

    if (props.history?.location?.search) {
      const params = new URLSearchParams(props.history?.location?.search)

      if (params.get('step')) {
        const currentStep =
          Number(params.get('step')) >= 1 && Number(params.get('step')) <= 3
            ? Number(params.get('step'))
            : 1
        setFormStep(currentStep)
      }
    }

    return () => {
      document.getElementById('root').style.backgroundColor = 'initial'
    }
  }, [])

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div className={classes.textImageWrap}>
              <div className={classes.imageWrap}>
                <Fade in={formStep === 1}>
                  <div>
                    {formStep === 1 && (
                      <Image
                        src={TextImage01}
                        style={{ width: 480 }}
                        aspectRatio={4 / 2.6}
                        color={'transparent'}
                        imageStyle={{ objectFit: 'contain' }}
                      />
                    )}
                  </div>
                </Fade>
                <Fade in={formStep >= 2}>
                  <div style={{ marginTop: 60 }}>
                    {formStep >= 2 && (
                      <Image
                        src={TextImage02}
                        style={{ width: 432 }}
                        aspectRatio={4 / 2.6}
                        color={'transparent'}
                        imageStyle={{ objectFit: 'contain' }}
                      />
                    )}
                  </div>
                </Fade>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography component={'h1'} className={classes.sectionHeading}>
                    <strong>proper</strong>
                    perks
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.textContents}>
                  <Typography component={'h6'} className={classes.sectionLeadText}>
                    Wise choice. As a PropertyMate member, you&rsquo;ll get to enjoy a
                    suite of tasty benefits. Ready to experience something different?
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={6} className={classes.formWrapper}>
            {/* <PageHeading variant="h2"><strong>Sign up</strong> <br />to see what all the fuss is about</PageHeading> */}
            <AgencySignUpForm
              formStep={formStep}
              setFormStep={setFormStep}
              location={props?.history?.location}

            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearAllFilters: () => clearFilters(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(styles)(AgencySignUp)))
