import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, withStyles } from '@material-ui/core';
import PMLogo from 'assets/images/pm-logo-inverted.png';
import clsx from 'clsx';

const styles = (theme) => ({
  chip: {
    minWidth: 150,
    textTransform: 'capitalize',
  },
  chipFull: {
    width: '100%',
    textTransform: 'capitalize',
  },

  greenChip: {
    color: '#FFF',
    backgroundColor: '#6dc5a1',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%) !important',
    fontWeight: '600 !important',
    fontSize: '13px',
    width: '80%',
    marginBottom: '1rem',
  },

  yellowChip: {
    backgroundColor: '#fdbd26',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%) !important',
    fontWeight: '600 !important',
    fontSize: '13px',
    width: '80%',
    marginBottom: '1rem',
  },

  redChip: {
    backgroundColor: '#EF7070',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%) !important',
    fontWeight: '600 !important',
    fontSize: '13px',
    width: '80%',
    marginBottom: '1rem',
  },

  orangeChip: {
    backgroundColor: '#EF6631',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%) !important',
    fontWeight: '600 !important',
    fontSize: '13px',
    width: '80%',
    marginBottom: '1rem',
  },

  greyChip: {
    color: '#FFF',
    backgroundColor: '#6A6A6A',
    boxShadow: '0px 2px 4px rgb(0 0 0 / 25%) !important',
    fontWeight: '600 !important',
    fontSize: '13px',
    width: '80%',
    marginBottom: '1rem',
  },
  noDocuSignChip: {},
});

const SuperStatusAgency = (props) => {
  const { classes, type, fullWidth } = props;

  const modifyText = (text) => {
    return text ? text.replace('_', ' ') : '';
  };

  const _renderAgencyStatusChip = () => {
    return (
      <>
        {type && (
          <Chip
            className={clsx(classes.chip, {
              [classes.chipFull]: fullWidth,
              [classes.greenChip]:
                type === 'active' ||
                type === 'approved' ||
                type === 'verified' ||
                type === 'activated' ||
                type === 'trial_active' ||
                type === 'completed' ||
                type === 'charged' ||
                type === 'Credit Card Added' ||
                type === 'docusign_signed' ||
                type === 'signed' ||
                type === 'Subscription Charged' ||
                type === 'invoice_paid' ||
                type === 'Promo Code Applied',
              [classes.greyChip]:
                type === 'pending' ||
                type === 'Credit Card Pending' ||
                type === 'payment_set' ||
                type === 'docusign_pending' ||
                type === 'payment_pending' ||
                type === 'Subscription charge Pending' ||
                type === 'invoice_issued',
              [classes.orangeChip]:
                type === 'pending_approval' ||
                type === 'pending_activation' ||
                type === 'docusign_sent' ||
                type === 'sent' ||
                type === 'invoice_due' ||
                type === 'docusign_resent',
              [classes.redChip]:
                type === 'error' ||
                type === 'deleted' ||
                type === 'archived' ||
                type === 'suspended' ||
                type === 'cancelled' ||
                type === 'stale' ||
                type === 'rejected' ||
                type === 'unpaid' ||
                type === 'Subscription payment failed' ||
                type === 'invoice_reminder_set' ||
                type === 'resend_docusign',
              [classes.noDocuSignChip]: type === 'no_docusign',
            })}
            label={modifyText(type)}
          />
        )}
      </>
    );
  };

  return <>{_renderAgencyStatusChip()}</>;
};

SuperStatusAgency.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
};

export default withStyles(styles)(SuperStatusAgency);
