import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { withStyles, FormControl } from '@material-ui/core';

const Container = styled.div`
  padding: 10px 0 20px;
`;

const StyledFormControl = withStyles({
  root: {
    width: '100%',
  },
})(FormControl);

const CustomDatePicker = (props) => {
  const [value, setValue] = React.useState(props.value || null);

  useEffect(() => {
    if (props.isClearFilter) {
      setValue(null);
    }
  }, [props.isClearFilter]);

  const handleChange = (date) => {
    setValue(date);
    if (props.handleDate) {
      props.handleDate(date);
    }
  };

  return (
    <Container>
      <StyledFormControl variant="outlined">
        <DatePicker
          autoOk
          margin="normal"
          format="dd/MM/yyyy"
          clearable
          value={value}
          onChange={handleChange}
          inputVariant="outlined"
          minDate={props.minDate ? props.minDate : null}
        />
      </StyledFormControl>
    </Container>
  );
};

export default CustomDatePicker;
