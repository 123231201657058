import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { SOCIAL_MEDIA_BUTTONS, REGISTER_VALIDATION_CONFIG } from '../../../constants/constants';

/** actions */
import { toggleThemeMode, toggleSignUp, navigateReset } from '../../../store/actions';

/** api redux request */
import {
  requestAPIUserRegistration,
  requestAPIVerifyUserRegistration,
  requestAPIFacebookAuth,
  requestAPIGoogleAuth,
  requestAPIAppleAuth,
  requestAPIResendCode,
  requestPOSTAPIAgencyReferral,
} from '../../../store/api';

import checkField from 'components/Common/Validator/validationService';

import useGoogleEvents from 'utils/customHooks/useGoogleEvents';

import HeadingContent from './HeadingContent';
import FormField from './FormField';
import FormNavigationGroup from './FormNavigationGroup';
import SocialButtonGroup from './SocialButtonGroup';
import AlertNotification from './AlertNotification';

import { Grid, Typography, Link, Checkbox, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    //padding: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      outline: 'none',
    },
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
  },
  container: {
    padding: '30px 24px 40px',
  },
  avatar: {},
});

const REGISTER_FIELDS = {
  email: {
    name: 'email',
    id: 'email',
    value: 'email@ema',
    placeholder: 'Email Address',
    label: 'Email Address *',
    type: 'text',
  },
  password: {
    name: 'password',
    id: 'password',
    value: '',
    placeholder: 'Password',
    label: 'Password *',
    type: 'password',
  },
};

const FormLabel = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px',
  },
})(Typography);

const LinkSignUp = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'underline',
  },
})(Link);

const Notice = withStyles({
  root: {
    fontWeight: 300,
    fontSize: '12px',
    letterSpacing: '0.19724px',
    lineHeight: '12px',
    color: '#5F5F60',
    fontStyle: 'normal',
    marginBottom: '16px',
  },
})(Typography);

const StyledCheckbox = withStyles({
  root: {
    paddingLeft: 0,
    color: '#4D4D4D',
  },
})(Checkbox);

const MainForm = styled.form`
  padding-top: 0;
`;

const LinkStyleWrap = styled.div`
  padding-top: 40px;
  text-align: center;
  width: 100%;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #646464;
    letter-spacing: 0.09px;
    a {
      color: #646464;
      text-decoration: underline;
    }
  }
`;

const Register = (props) => {
  /** component props */
  const { classes, type, setIsForgotPasswordView, tabChange } = props;

  /** redux props */
  const { authentication, settings, registerFields } = props;

  /** authentication redux */
  const {
    error, // error response
    payload, // data response from success
    request, // request details
    status, // status
  } = authentication;

  /** redux api actions */
  const {
    userRegistration,
    verifyRegistration,
    navigateResetRedux,
    facebookAuth,
    googleAuth,
    appleAuth,
    resendCode,
    agencyReferral,
  } = props;

  //Use `errors` object to immediately show errors on the UI on lost focus or `submittedErrors` to only show errors on form submit
  const [isLandingView, setIsLandingView] = React.useState(true);
  const [isCodeView, setIsCodeView] = React.useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSignInDisabled, setIsSignInDisabled] = useState(true);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const [errors, setErrors] = useState({
    firstName: { status: false, message: '' },
    lastName: { status: false, message: '' },
    email: { status: false, message: '' },
    password: { status: false, message: '' },
    confirmpassword: { status: false, message: '' },
    phone: { status: false, message: '' },
    code: { status: false, message: '' },
  });

  const { triggerAnalyticsEvent } = useGoogleEvents();

  const formCheck = (fields, errors) => {
    const reqFields =
      fields.firstName.trim() &&
      fields.lastName.trim() &&
      fields.email.trim() &&
      fields.password.trim() &&
      fields.confirmPassword.trim() &&
      fields.phone.trim()
        ? true
        : false;
    if (reqFields && Object.keys(errors).length > 0) {
      if (
        !errors.lastName.status &&
        !errors.firstName.status &&
        !errors.email.status &&
        !errors.password.status &&
        !errors.confirmpassword.status &&
        !errors.phone.status &&
        fields.isPrivacyChecked
      ) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(true);
    }
  };

  const codeCheck = (fields, errors) => {
    const reqFields = fields.code.trim() ? true : false;
    if (reqFields && Object.keys(errors).length > 0) {
      if (!errors.code.status) {
        setIsSignInDisabled(false);
      } else {
        setIsSignInDisabled(true);
      }
    } else {
      setIsSignInDisabled(true);
    }
  };

  /** Listeners */
  /** Reducer Changes Listeners */
  useEffect(() => {
    handleAPIErrorResponse();
  }, [authentication]);

  useEffect(() => {
    formCheck(
      {
        email,
        password,
        confirmPassword,
        phone,
        isPrivacyChecked,
        firstName,
        lastName,
      },
      errors,
    );
  }, [email, password, confirmPassword, phone, isPrivacyChecked, firstName, lastName]);

  useEffect(() => {
    codeCheck({ code }, errors);
  }, [code]);

  /** Register API */
  function apiRequestRegister() {
    let request = {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      phone: phone.replace(/\D/g, '').replace(/ /g, ''),
      firstName: firstName,
      lastName: lastName,
    };
    userRegistration(request);
    setIsLoading(true);
    triggerAnalyticsEvent({
      event: 'signup_actions',
      eventFrom: 'Mobile',
      actionScope: 'signup',
      actionName: 'Buyer Signup',
      actionTarget: 'Signed up',
      actionReference: 'signup',
    });
  }

  /** Verify API */
  function apiVerifyRegistration() {
    verifyRegistration({
      email: email,
      code: code,
    });
  }

  /** Facebook API */
  function apiRequestFacebook(token) {
    facebookAuth({
      access_token: token,
    });
  }

  /** Google API */
  function apiRequestGoogle(token) {
    googleAuth({
      access_token: token,
    });
  }

  /** Apple API */
  function apiRequestApple(token) {
    appleAuth({
      access_token: token,
    });
  }

  /** Resend Code API */
  function apiResendCode() {
    setIsLoading(true);
    let request = {
      email: email,
    };
    resendCode(request);
  }

  /** IPP Referral */
  function apiAgencyReferral(id) {
    let refCode = localStorage.getItem('referralCode') || '';
    let request = {
      query: '/' + refCode,
      data: {
        referralCode: refCode,
        userId: id,
      },
    };
    if (refCode) {
      agencyReferral(request, 'IPP_ANALYTICS_REFERRAL_CODE');
      localStorage.removeItem('referralCode');
    }
  }

  /** API Error Response Handler */
  function handleAPIErrorResponse() {
    /** Registration */
    if (authentication.registration_status === 'success') {
      handleNotification(true, {
        message: 'Verification code sent. Please verify your account.',
        severity: 'success',
      });
      apiAgencyReferral(authentication?.payload?.data?.data?.entity._id);
      setIsCodeView(true);
      navigateResetRedux();
      return;
    }
    if (authentication.registration_error) {
      if (authentication.registration_status === 'error') {
        let errMsg;
        if (
          authentication.registration_error.message === 'Email with same user role exists.' ||
          authentication.registration_error.message === 'Phone number is already used.'
        ) {
          errMsg = authentication.registration_error.message;
        }
        if (errMsg !== '' || errMsg !== null || errMsg !== undefined) {
          handleNotification(true, { message: errMsg, severity: 'error' });
          setIsLoading(false);
          return;
        }
      }
    }
    /** Verify Registration */
    if (authentication.verify_registration_status === 'success') {
      handleNotification(true, {
        message: 'Registration Successful',
        severity: 'success',
      });
      setTimeout(function () {
        tabChange('', 0);
      }, 1600);
      return;
    }
    if (authentication.verify_registration_error) {
      if (authentication.verify_registration_status === 'error') {
        handleNotification(true, {
          message: authentication.verify_registration_error.data.message,
          severity: 'error',
        });
        return;
      }
    }

    /** Social Signup */
    if (authentication.social_status === 'success') {
      apiAgencyReferral(authentication?.payload?.data?.data?.user._id);
    }

    /** Resend Code */
    if (authentication.resend_code_status === 'success' && isLoading) {
      handleNotification(true, {
        message: 'Verification code resent',
        severity: 'success',
      });
      setIsLoading(false);
      return;
    }
    if (authentication.resend_code_error) {
      if (authentication.resend_code_status === 'error') {
        handleNotification(true, {
          message: authentication.resend_code_error.data.message,
          severity: 'error',
        });
        return;
      }
    }
  }

  const handleSocialCallback = (data) => {
    if (data && data.token) {
      if (data.provider === 'facebook') {
        apiRequestFacebook(data.token.accessToken);
      }
      if (data.provider === 'google') {
        apiRequestGoogle(data.token.id_token);
      }
      if (data.provider === 'apple') {
        apiRequestApple(data.token);
      }
    }
    triggerAnalyticsEvent({
      event: 'signup_actions',
      eventFrom: 'Mobile',
      actionScope: 'signup',
      actionName: 'Buyer Signup',
      actionTarget: 'Signed up',
      actionReference: 'signup',
    });
  };

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const closeNotification = () => {
    setIsNotification(false);
  };

  // const renderSignUpLanding = () => {
  //   return (
  //     <LinkStyleWrap>
  //       <LinkSignUp href="#" onClick={(e)=>{
  //         e.preventDefault()
  //         setIsLandingView(false)
  //       }}>
  //         Sign up using your email address
  //       </LinkSignUp>
  //     </LinkStyleWrap>
  //   )
  // }

  const renderSignUpEmail = () => {
    return (
      <div>
        {/* <FormLabel>Sign up using your email address</FormLabel> */}
        <span></span>
        <FormField
          type={'text'}
          name={'input-firstname'}
          value={firstName}
          placeholder={'First Name *'}
          errorMsg={errors.firstName.message}
          showError={errors.firstName.status}
          autocomplete={'desktop-email-off'}
          onChange={(e) => {
            var value = e.target.value;
            setFirstName(value);
            if (checkField(value, REGISTER_VALIDATION_CONFIG('firstName').fields.firstName) === null) {
              setErrors((prevState) => ({
                ...prevState,
                firstName: { ...prevState.firstName, status: false },
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                firstName: {
                  ...prevState.firstName,
                  status: true,
                  message: checkField(value, REGISTER_VALIDATION_CONFIG('firstName').fields.firstName),
                },
              }));
            }
          }}
        />
        <FormField
          type={'text'}
          name={'input-lastname'}
          value={lastName}
          placeholder={'Last Name *'}
          errorMsg={errors.lastName.message}
          showError={errors.lastName.status}
          autocomplete={'desktop-email-off'}
          onChange={(e) => {
            var value = e.target.value;
            setLastName(value);
            if (checkField(value, REGISTER_VALIDATION_CONFIG('lastName').fields.lastName) === null) {
              setErrors((prevState) => ({
                ...prevState,
                lastName: { ...prevState.lastName, status: false },
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                lastName: {
                  ...prevState.lastName,
                  status: true,
                  message: checkField(value, REGISTER_VALIDATION_CONFIG('lastName').fields.lastName),
                },
              }));
            }
          }}
        />
        <FormField
          type={'email'}
          name={'input-email'}
          value={email}
          placeholder={'Email Address *'}
          errorMsg={errors.email.message}
          showError={errors.email.status}
          autocomplete={'mobile-email-off'}
          onChange={(e) => {
            var value = e.target.value;
            setEmail(value);
            if (checkField(value, REGISTER_VALIDATION_CONFIG('email').fields.email) === null) {
              setErrors((prevState) => ({
                ...prevState,
                email: { ...prevState.email, status: false },
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                email: {
                  ...prevState.email,
                  status: true,
                  message: checkField(value, REGISTER_VALIDATION_CONFIG('email').fields.email),
                },
              }));
            }
          }}
        />
        <FormField
          type={'tel'}
          value={phone}
          name={'input-phone'}
          placeholder={'Phone number *'}
          errorMsg={errors.phone.message}
          showError={errors.phone.status}
          onChange={(e) => {
            var value = e.target.value.replace(/\D/g, '');
            setPhone(value);
            if (checkField(value, REGISTER_VALIDATION_CONFIG('phone').fields.phone) === null) {
              setErrors((prevState) => ({
                ...prevState,
                phone: { ...prevState.phone, status: false },
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                phone: {
                  ...prevState.phone,
                  status: true,
                  message: checkField(value, REGISTER_VALIDATION_CONFIG('phone').fields.phone),
                },
              }));
            }
          }}
          maxlength={14}
          telformat={'9999999999'}
        />
        <Notice>This is important for 2F Authentication and to receive updates via sms</Notice>
        <FormField
          type={'password'}
          value={password}
          placeholder={'Password *'}
          name={'input-pass1'}
          errorMsg={errors.password.message}
          showError={errors.password.status}
          // onKeyUp={(e)=>setPassword(e.target.value)}
          // {...getFieldProps('password')}
          onChange={(e) => {
            var value = e.target.value;
            setPassword(value);
            if (checkField(value, REGISTER_VALIDATION_CONFIG('password').fields.password) === null) {
              setErrors((prevState) => ({
                ...prevState,
                password: { ...prevState.password, status: false },
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                password: {
                  ...prevState.password,
                  status: true,
                  message: checkField(value, REGISTER_VALIDATION_CONFIG('password').fields.password),
                },
              }));
            }
          }}
        />
        <Notice>* Your password must contain one capital letter and a number</Notice>

        <FormField
          type={'password'}
          value={confirmPassword}
          placeholder={'Re-type new password *'}
          name={'input-pass2'}
          errorMsg={errors.confirmpassword.message}
          showError={errors.confirmpassword.status}
          // onKeyUp={(e)=>setConfirmPassword(e.target.value)}
          // {...getFieldProps('confirmpassword')}
          onChange={(e) => {
            var value = e.target.value;
            setConfirmPassword(value);
            if (value) {
              if (value !== password) {
                if (checkField(value, REGISTER_VALIDATION_CONFIG('confirmpassword').fields.confirmpassword) === null) {
                  setErrors((prevState) => ({
                    ...prevState,
                    confirmpassword: { ...prevState.confirmpassword, status: false },
                  }));
                } else {
                  setErrors((prevState) => ({
                    ...prevState,
                    confirmpassword: {
                      ...prevState.confirmpassword,
                      status: true,
                      message: checkField(value, REGISTER_VALIDATION_CONFIG('confirmpassword').fields.confirmpassword),
                    },
                  }));
                }
              } else {
                setErrors((prevState) => ({
                  ...prevState,
                  confirmpassword: { ...prevState.confirmpassword, status: false },
                }));
              }
            }
          }}
        />

        <CheckBoxLabel htmlFor="privacy-checkbox">
          <StyledCheckbox
            checked={isPrivacyChecked}
            onChange={() => {
              setIsPrivacyChecked(!isPrivacyChecked);
            }}
            id="privacy-checkbox"
            name="privacy-checkbox"
            color="primary"
          />
          <p>
            I accept your{' '}
            <a href="/terms-and-conditions" target="_blank">
              terms and conditions
            </a>
          </p>
        </CheckBoxLabel>

        <FormNavigationGroup
          type={'register'}
          disabled={isButtonDisabled}
          setIsForgotPasswordView={setIsForgotPasswordView}
          tabChange={tabChange}
          onClick={() => {
            apiRequestRegister();
          }}
        />
      </div>
    );
  };

  const renderSignUpCode = () => {
    return (
      <div>
        <FormLabel>Enter code below</FormLabel>
        <FormField
          type={'text'}
          value={code}
          placeholder={'Code *'}
          errorMsg={errors.code.message}
          name={'input-code'}
          showError={errors.code.status}
          // onKeyUp={(e)=>setCode(e.target.value)}
          // {...getFieldProps('code')}
          onChange={(e) => {
            var value = e.target.value;
            setCode(value);
            if (checkField(value, REGISTER_VALIDATION_CONFIG('code').fields.code) === null) {
              setErrors((prevState) => ({
                ...prevState,
                code: { ...prevState.code, status: false },
              }));
            } else {
              setErrors((prevState) => ({
                ...prevState,
                code: {
                  ...prevState.code,
                  status: true,
                  message: checkField(value, REGISTER_VALIDATION_CONFIG('code').fields.code),
                },
              }));
            }
          }}
        />

        <FormNavigationGroup
          type={'register code'}
          disabled={isSignInDisabled}
          setIsForgotPasswordView={setIsForgotPasswordView}
          apiResendCode={apiResendCode}
          tabChange={tabChange}
          onClick={() => {
            apiVerifyRegistration();
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <AlertNotification status={isNotification} options={notificationOptions} closeNotification={closeNotification} />
      <Grid className={classes.container} container wrap="nowrap">
        <Grid item xs={12}>
          <HeadingContent
            title={'Sign up using your email address'}
            content={`Creating an account is simple<br />Enter your details below to get started`}
          />
          {
            // isLandingView ?
            //   renderSignUpLanding() :
            <MainForm>{!isCodeView ? renderSignUpEmail() : renderSignUpCode()}</MainForm>
          }

          <SocialButtonGroup
            type={'register'}
            border={!isLandingView ? 'none' : ''}
            label={!isLandingView ? '' : 'Or sign up using any of the following methods'}
            socialCallback={handleSocialCallback}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    registerFields: REGISTER_FIELDS,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      toggleSignUp: (checked) => toggleSignUp(checked),
      userRegistration: (params) => requestAPIUserRegistration(params),
      verifyRegistration: (params) => requestAPIVerifyUserRegistration(params),
      facebookAuth: (params) => requestAPIFacebookAuth(params),
      googleAuth: (params) => requestAPIGoogleAuth(params),
      appleAuth: (params) => requestAPIAppleAuth(params),
      resendCode: (params) => requestAPIResendCode(params),
      agencyReferral: (params, actionMain) => requestPOSTAPIAgencyReferral(params, actionMain),
      navigateResetRedux: () => navigateReset(),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Register));
