import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { requestValidateUserToken, requestGETAPISuperadmin } from 'store/api';
import { persistor } from 'store/store';
import { getUser, getAuthentication } from 'store/selectors/authentication.selectors';

import { getSuperadminToken as getAdminAuth, getUser as getAdminUser } from 'store/selectors/superadmin.selectors';
import { APP_VERSION } from '../src/utils/constants.utils';
import { getStorageVersion } from 'store/selectors/storage.selectors';
import { isExpired as jwtIsExpired, decodeToken } from 'react-jwt'
/** Import App JS (Mobile and Web) */
import MobileApp from './App.Mobile';
import WebApp from './App.Web';
import { clearSession } from 'store/actions';
import { superadminActions } from 'store/actions';
import getStorage from 'redux-persist/es/storage/getStorage';
import { StorageActions } from 'store/actions/storage.actions';
import { handleSearchPropertyReference } from 'utils/handleSearchPropertyReference';
import { FiltersActions } from 'store/actions';
import { getAllFilters } from 'utils/search/selectors/selectors.search';
import { convertUrlQueryToFilters } from 'utils/customHooks/useConvertUrlToFilters';

const MainApp = (props) => {
  const theme = useTheme();
  let isWebView = null;
  isWebView = useMediaQuery(theme.breakpoints.up('sm'));

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const queryParameters = new URLSearchParams(window?.location?.search);

  const dispatch = useDispatch();

  const AUTH = useSelector(getAuthentication);
  const USER = useSelector(getUser);
  const ADMIN_AUTH = useSelector(getAdminAuth);
  const ADMIN_USER = useSelector(getAdminUser);
  const STORAGE_VERSION = useSelector(getStorageVersion);

  const isExpired = (ts) => {
    const currentDate = new Date();
    const currentTimestamp = currentDate.getTime();
    const targetDate = new Date(ts);
    const targetTimestamp = targetDate ? targetDate.getTime() : null;
    return targetDate ? targetTimestamp <= currentTimestamp : false;
  };

  const handleValidation = () => {
    const request = {
      query: null,
      data: null,
    };

    if (ADMIN_AUTH) {
      const checkAdminSession = jwtIsExpired(ADMIN_AUTH.token);
      if (checkAdminSession) {
        dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'LOG_OUT'));
      }
    }

    if (!AUTH.token_validator) {
      dispatch(requestValidateUserToken());
    }

    if (
      (AUTH.token?.token || AUTH.token?.refreshToken) &&
      AUTH?.token_validator?.data?.expire &&
      USER.user &&
      isExpired(AUTH.token_validator.data.expire)
    ) {
      dispatch(clearSession());
    } else if ((AUTH.token?.token || AUTH.token?.refreshToken) && USER.user && !AUTH.token_validator.data) {
      dispatch(requestValidateUserToken());
    }

    if (!ADMIN_USER.token_validator) {
      dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_VALIDATE_TOKEN', 'REQUEST'));
    }

    if (
      (ADMIN_AUTH?.token || ADMIN_AUTH?.refreshToken) &&
      ADMIN_USER?.token_validator?.data?.expire &&
      ADMIN_AUTH.user &&
      isExpired(ADMIN_USER.token_validator.data.expire)
    ) {
      dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'LOG_OUT'));
    } else if ((ADMIN_AUTH?.token || ADMIN_AUTH?.refreshToken) && ADMIN_AUTH.user && !ADMIN_USER.token_validator.data) {
      dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_VALIDATE_TOKEN', 'REQUEST'));
    }
  };

  useEffect(() => {
    handleValidation();
  }, [AUTH?.token, ADMIN_AUTH?.token]);

  const CURRENT_FILTERS = useSelector(getAllFilters);

  useEffect(() => {
    if (STORAGE_VERSION !== APP_VERSION) {
      persistor.flush().then(() => {
        return persistor.purge().then(() => {
          // Get Filters from url
          const filtersFromUrlQuery = convertUrlQueryToFilters(queryParameters, CURRENT_FILTERS?.search_reference);

          dispatch(
            FiltersActions(
              {
                data: filtersFromUrlQuery,
              },
              'GENERAL_FILTERS',
              'SET_ALL_FILTER',
            ),
          );

          dispatch(StorageActions(APP_VERSION, 'GENERAL_STORAGE', 'SET_STORAGE_VERSION'));
        });
      });
    }
  }, [STORAGE_VERSION]);

  window.addEventListener('resize', () => {
    setScreenWidth(window.innerWidth);
  });

  return (
    <div>
      <WebApp />
    </div>
  );
};

export default MainApp;
