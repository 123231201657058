import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Chip, withStyles } from '@material-ui/core';
import PMLogo from 'assets/images/pm-logo-inverted.png'
import clsx from 'clsx'
import MailIcon from '@material-ui/icons/Mail'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AssignmentIcon from '@material-ui/icons/Assignment'

const styles = (theme) => ({
  buttonIcon: {
    width: 35,
    height: 35,
    borderRadius: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeChip: {
    color: '#FFF',
    backgroundColor: '#6dc5a1',
  },
  completedChip: {
    color: '#FFF',
    backgroundColor: '#6dc5a1',
  },
  pendingChip: {
    color: '#FFF',
    backgroundColor: '#fdbd26',
  },
  pendingApprovalChip: {
    color: '#FFF',
    backgroundColor: '#fdbd26',
  },
  pendingActivationChip: {
    color: '#FFF',
    backgroundColor: '#ef6631',
  },
  pendingDefaultChip: {
    color: '#FFF',
    backgroundColor: '#d2d2d2',
  },
  errorChip: {
    color: '#FFF',
    backgroundColor: '#EF6631',
  },
  noDocuSignChip: {},
})

const SuperStatusIcon = (props) => {
  const { classes, status, type, fullWidth } = props

  const _renderStatusIcon = () => {
    if (type == 'subscription') {
      return <MailIcon className={classes.icon} />
    } else if (type == 'payment') {
      return <AssignmentIcon className={classes.icon} />
    } else if (type == 'docusign') {
      return <FileCopyIcon className={classes.icon} />
    }
    return null
  }

  const modifyText = (text) => {
    return text ? text.replace('_', ' ') : ''
  }

  const _renderAgencyStatusChip = () => {
    return (
      <div
        className={clsx(classes.buttonIcon, {
          [classes.activeChip]:
            status === 'active' ||
            status === 'approved' ||
            status === 'verified' ||
            status === 'activated' ||
            status === 'trial_active' ||
            status === 'sent' ||
            status === 'signed',
          [classes.completedChip]: status === 'completed',
          [classes.pendingChip]:
            status === 'pending' ||
            status === 'payment_set' ||
            status === 'payment_pending' ||
            status === 'charged',
          [classes.pendingApprovalChip]: status === 'pending_approval',
          [classes.pendingActivationChip]: status === 'pending_activation',
          [classes.errorChip]:
            status === 'error' ||
            status === 'deleted' ||
            status === 'archived' ||
            status === 'suspended' ||
            type === 'stale' ||
            type === 'rejected' ||
            status === 'unpaid',
          [classes.noDocuSignChip]: status === 'no_docusign',
        })}
      >
        {_renderStatusIcon()}
      </div>
    )
  }

  return <>{_renderAgencyStatusChip()}</>
}

SuperStatusIcon.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  status: PropTypes.string,
}

export default withStyles(styles)(SuperStatusIcon)
