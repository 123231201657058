import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Box,
  Grid,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  withStyles,
} from '@material-ui/core';
import PMLogo from 'assets/images/pm-logo-inverted.png';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

/** redux */
import { superadminActions, logoutRequest } from 'store/actions';
import { clearSession } from 'store/actions';
import {
  getAuthentication,
  getUser,
} from 'store/selectors/authentication.selectors';
import { getAuthentication as getAdminAuthentication } from 'store/selectors/superadmin.selectors';
import { useCheckUserRole } from 'utils/customHooks/useCheckUserRole';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#F5F5F5',
    boxShadow: '0px 4px 8px 0px #0000001F',
    fontFamily: 'Montserrat',
  },
  selectGroup: {
    marginBottom: '2px',
  },
  border: {
    margin: '20px 0',
    borderBottom: '0.5px solid #9B9B9B',
  },
  logo: {
    width: 200,
    height: 'auto',
  },
  formControl: {
    width: '100%',
  },
  formLabel: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 600,
    color: '#383838',
    marginBottom: '5px',
  },
  formSelect: {
    backgroundColor: '#ffffff',
  },
  buttonEdge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonGroup: {
    alignItems: 'flex-end',
  },
  button: {
    marginLeft: 20,
    borderRadius: 20,
    minWidth: 180,
    textTransform: 'capitalize',
  },
  clearButton: {
    backgroundColor: '#ffffff',
    color: '#383838',
    fontWeight: 600,
    border: '1.5px solid #35C0CA',
    borderRadius: 40,
    height: 40,
    minWidth: 120,
    textTransform: 'capitalize',
  },
  applyButton: {
    color: '#ffffff',
    fontWeight: 600,
    borderRadius: 40,
    height: 40,
    minWidth: 120,
    textTransform: 'capitalize',
  },
  name: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
});

let defaultAlertOptions = {
  status: false,
  options: null,
};

const SuperFilter = (props) => {
  const {
    classes,
    data,
    clearFilter,
    applyFilter,
    status,
    paid,
    handleChange,
  } = props;
  const [routerChange] = useRouterChange();
  const dispatch = useDispatch();

  const filterButtons = [
    {
      id: 1,
      label: 'Clear Filter',
      active: false,
    },
    {
      id: 2,
      label: 'Apply Filter',
      active: true,
    },
  ];

  return (
    <div>
      <Box className={classes.root}>
        <Grid
          container
          className={classes.selectGroup}
          justifyContent='flex-start'
          alignItems='flex-end'
          spacing={2}
        >
          {paid && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormControl variant='outlined' className={classes.formControl}>
                <Typography className={classes.formLabel}>
                  Subscription Status
                </Typography>
                <Select
                  className={classes.formSelect}
                  labelId='select-subscription'
                  id='select-subscription'
                  value={status.subscription}
                  onChange={(e) => handleChange(e, 'subscription')}
                >
                  <MenuItem value={'ALL'}>Select All</MenuItem>
                  <MenuItem value={'pending_activation'}>Pending</MenuItem>
                  <MenuItem value={'canceled'}>Cancelled</MenuItem>
                  <MenuItem value={'trial_active'}>Trial Active</MenuItem>
                  <MenuItem value={'active'}>Active</MenuItem>
                  <MenuItem value={'suspended'}>Suspended</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {/*paid && (
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <FormControl variant="outlined" className={classes.formControl}>
                <Typography className={classes.formLabel}>Docusign Status</Typography>
                <Select
                  className={classes.formSelect}
                  labelId="select-docusign"
                  id="select-docusign"
                  value={status.docusign}
                  onChange={(e) => handleChange(e, 'docusign')}
                >
                  <MenuItem value={'ALL'}>Select All</MenuItem>
                  <MenuItem value={'docusign_pending'}>Docusign Pending</MenuItem>
                  <MenuItem value={'docusign_sent'}>Docusign Sent</MenuItem>
                  <MenuItem value={'docusign_resent'}>Docusign Resent</MenuItem>
                  <MenuItem value={'docusign_signed'}>Docusign Signed</MenuItem>
                  <MenuItem value={'resend_docusign'}>Resend Docusign</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )*/}
          {paid && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormControl variant='outlined' className={classes.formControl}>
                <Typography className={classes.formLabel}>
                  Payment Status
                </Typography>
                <Select
                  className={classes.formSelect}
                  labelId='select-payment'
                  id='select-payment'
                  value={status.payment}
                  onChange={(e) => handleChange(e, 'payment')}
                >
                  <MenuItem value={'ALL'}>Select All</MenuItem>
                  <MenuItem value={'Credit Card Pending'}>
                    Credit Card Pending
                  </MenuItem>
                  <MenuItem value={'Promo Code Applied'}>
                    Promo Code Applied
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {!paid && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormControl variant='outlined' className={classes.formControl}>
                <Typography className={classes.formLabel}>
                  Has Admin Email
                </Typography>
                <Select
                  className={classes.formSelect}
                  labelId='select-email'
                  id='select-email'
                  value={status.adminEmail}
                  onChange={(e) => handleChange(e, 'adminEmail')}
                >
                  <MenuItem value={'ALL'}>Select All</MenuItem>
                  <MenuItem value={'true'}>Yes</MenuItem>
                  <MenuItem value={'false'}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          {!paid && (
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <FormControl variant='outlined' className={classes.formControl}>
                <Typography className={classes.formLabel}>
                  Account Status
                </Typography>
                <Select
                  className={classes.formSelect}
                  labelId='select-active'
                  id='select-active'
                  value={status.active}
                  onChange={(e) => handleChange(e, 'active')}
                >
                  <MenuItem value={'ALL'}>Select All</MenuItem>
                  <MenuItem value={'true'}>Active</MenuItem>
                  <MenuItem value={'false'}>Not Active</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={paid ? 3 : 4}
            lg={paid ? 3 : 4}
            xl={paid ? 3 : 4}
          >
            <Grid
              container
              className={classes.buttonGroup}
              justifyContent='center'
              alignItems='flex-end'
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container justifyContent='flex-end'>
                  <Button
                    className={classes.clearButton}
                    color={'primary'}
                    variant='outlined'
                    onClick={clearFilter}
                    disableElevation
                  >
                    Clear Filter
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container justifyContent='flex-start'>
                  <Button
                    className={classes.applyButton}
                    color={'primary'}
                    variant='contained'
                    onClick={applyFilter}
                    disableElevation
                  >
                    Apply Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div className={classes.border}></div>
    </div>
  );
};

SuperFilter.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.array,
};

export default withStyles(styles)(SuperFilter);
