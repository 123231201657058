import React, { useState, useEffect, Fragment } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { List, ListItem, ListItemText, Typography, Divider, ListItemSecondaryAction, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core"

import Loader from "../Loader";
import { ChevronRightSharp } from "@material-ui/icons";


const styles = theme => ({
  root: {
    padding: theme.spacing(1, 2, 2, 2),
    position: 'relative',
    '& .MuiBackdrop-root': {
      maxWidth: 'initial',
      position: 'absolute',
      left: 0
    }
  },
  heading: {
    fontWeight: 700
  },
  inline: {
    display: 'inline',
  },
  block: {
    display: 'block'
  },
  item: {
    padding: theme.spacing(1, 0),
  },
  link: {
    color: 'rgba(0,0,0,0.54)'
  },
  content: {
    color: theme.palette.text.primary
  },
  listActions: {
    display: 'inline-flex',
    position: 'relative',
    top: 'initial',
    right: 'initial',
    transform: 'initial',
  },
  notificationList: {
    '& .MuiListItem-container': {
      display: 'flex'
    }
  }
})

const NotificationList = props => {
  const {
    classes,
    authentication,
    setHasNotification,
    response
  } = props

  const addressRegex = / *AU *$|AUS *$/gm;
  const [isLoading, setIsLoading] = useState(true)

  React.useEffect(() => {
    console.debug(response, 'response')
    setIsLoading(false)
    if (response && response.length > 0) {
      setHasNotification(true)
    }
  }, [response])

  const TEST_RESPONSE = [
    {
      sharedId: '01',
      createdAt: "2020-12-16T00:53:23.780Z",
      updatedAt: "2020-12-16T01:25:01.780Z",
      seenAt: null,
      data: {
        id: '698hg6789s789f6ghjnf6780g6789sfg67hsd89756fg',
        soldDate: "2020-12-16T01:25:01.780Z",
        addressText: '162A Spencer Street, ESSENDON VIC 3040 AU',
        title: 'Sold'
      },
      body: 'On 19th of March 2020',
      user: {},
      type: 'propertySold',
      seen: false,
    },
    {
      sharedId: '02',
      createdAt: "2020-12-16T00:53:23.780Z",
      updatedAt: "2020-12-16T01:25:01.780Z",
      seenAt: null,
      data: {
        id: '698hg6789s789f6ghjnf6780g6789sfg67hsd89756fg',
        soldDate: "2020-12-16T01:25:01.780Z",
        addressText: '162A Spencer Street, ESSENDON VIC 3040 AU',
        title: 'Auction'
      },
      body: ' day approaching',
      user: {},
      type: 'propertySold',
      seen: false,
    }
  ]

  return (
    <div className={classes.root}>
      <Loader isLoading={isLoading} />
      <Typography variant="overline" component="h2" className={classes.heading}>
        Notifications
      </Typography>
      <List className={classes.notificationList}>
        {
          response && response.map((value, index) => { //response is the actual object
            return (
              <Fragment key={index}>
                <ListItem className={`${classes.item}`} key={index + '-item'}>
                  <ListItemText
                    color="textPrimary"
                    secondary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          <strong>{value.data && value.data.title + ' '}</strong>
                        </Typography>
                        <span className={classes.content}>{value.data && value.body}</span>
                        <Typography
                          component="a"
                          variant="body2"
                          href={'#'}
                          className={`${classes.block} ${classes.link}`}
                        >
                          {value.data && value.data.addressText && value.data.addressText.replace(addressRegex, '')}
                        </Typography>
                      </>
                    }
                  />
                  <ListItemSecondaryAction className={classes.listActions}>
                    <IconButton edge="end" aria-label="read" style={{ backgroundColor: 'transparent' }} >
                      <ChevronRightSharp fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {response && response.length !== index + 1 &&
                  <Divider component="li" key={index + '-divider'} />
                }
              </Fragment>
            )
          })
        }
      </List>

      {!response &&  //response is the actual object
        <Typography variant='caption'>You have no unread notifications.</Typography>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {

    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NotificationList));
