import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import Filter from '../../components/Mobile/Filter'
import Heading from '../../components/Mobile/Filter/Heading'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'
import DynamicIcons from '../../components/Mobile/DynamicIcons'

/** api redux request */
import { requestSearchPerfectProperty } from '../../store/api'

/** actions */
import {
  setPropertySearchFilters,
  clearFilters,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPerfectPropertyFilters,
  clearPerfectPropertyFilters,
  clearLikedUnlikedProperties,
  setTypesCounters,
  clearTypesCounters,
  setReferenceFilters,
  clearReferenceFilters,
  FiltersActions,
} from '../../store/actions'

/** Object Filters */
import { formatRequest, paramsToSha1 } from '../../utils/search.utils'

import { Typography, Button, withStyles } from '@material-ui/core'
import { useSearch } from 'utils/search/useSearch'
import {
  getAllFilters,
  getSearchResults,
} from 'utils/search/selectors/selectors.search'
import { useDispatch } from 'react-redux'
import useFilters from 'utils/customHooks/useFilters'
import { useRouterChange } from 'utils/router.utils'
import ClearFiltersButton from 'components/Common/GlobalFilters/ClearFiltersButton'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  content: {
    height: '100%',
    paddingBottom: '30px',
  },
})

const StyledButton = withStyles({
  root: {
    borderRadius: '30px',

    '&:hover': {
      backgroundColor: '#d75b2c',
    },
  },
})(Button)

const Header = styled.div`
  position: relative;
  padding: 10px 23px 15px;
`
const HeaderNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

const BackButton = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.073125px;
  display: flex;
  align-items: center;
  margin-left: -10px;
  cursor: pointer;
`

const ClearButton = styled.a`
  text-decoration: underline;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.073125px;
`

const ButtonWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 15px;
  box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-center: center;
  justify-content: center;
  z-index: 9;
`

const FiltersPerfect = (props) => {
  const {
    classes,
    authentication,
    filters,
    property,
    error,
    setSearchFilters,
    clearFiltersState,
    searchPerfectProperty,
    setPropertySearch,
    clearPropertySuburb,
    setIncludeNearbyFilter,
    setPerfectPropertyFilter,
    clearPerfectProperty,
    clearLikeAndUnlike,
    setTypesCount,
    typeCounters,
    clearTypeCount,
    referenceFilters,
    saveReferenceFilters,
    removeReferenceFilters,
  } = props
  const [routerChange] = useRouterChange()

  const [filter, setFilter] = useState(null)
  const [isClearFilter, setIsClearFilter] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [propertyTypesCount, setPropertyTypesCount] = useState(null)
  const [updatedFilter, setUpdatedFilter] = useState(filters)
  const [initiateSearch] = useSearch()
  const [getFilter, setFilters] = useFilters()
  const SEARCH_BUTTONS = getFilter('search_reference')
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setIsLoading(false)
      setPropertyTypesCount(null)
    }
  }, [])

  function routeChangeBack() {
    routerChange('back')
  }

  function saveFilters(params) {
    setSearchFilters(params)
    setPerfectPropertyFilter(params.perfectProperty)
  }

  function resetFilters() {
    clearFiltersState()
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const validateFilter = (params) => {
    return _.findIndex(params.perfectProperty.state, { active: true }) > -1
  }

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  /* handle properties callback */
  useEffect(() => {
    /** success handler */
    if (property) {
      if (
        property.payload &&
        property.payload.data &&
        property.payload.data.ok &&
        isLoading
      ) {
        setIsLoading(false)
        routerChange('/perfect-property')
      }
    }
    /** error hanlder */
    if (property && property.property_search_error && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }
    /* 
        if (
          property.perfect_properties &&
          property.perfect_properties.data &&
          property.perfect_properties.data.propertyTypesCount
        ) {
          actionSetTypesCount(property.perfect_properties.data.propertyTypesCount);
          actionSaveReferenceFilters(filters, "perfect-property");
        } else if (
          property.perfect_properties &&
          property.perfect_properties.data.data &&
          property.perfect_properties.data.data &&
          property.perfect_properties.data.data.propertyTypesCount
        ) {
          actionSetTypesCount(property.perfect_properties.data.data.propertyTypesCount);
          actionSaveReferenceFilters(filters, "perfect-property");
        } */
  }, [property])

  useEffect(() => {
    if (
      filters &&
      referenceFilters &&
      JSON.stringify(filters) !== JSON.stringify(referenceFilters.filters)
    ) {
      setPropertyTypesCount(null)
    }

    if (referenceFilters && referenceFilters.scope !== getRoutePath()) {
      setPropertyTypesCount(null)
    }
  }, [filters, referenceFilters])

  useEffect(() => {
    if (typeCounters) {
      setPropertyTypesCount(typeCounters)
    } else {
      setPropertyTypesCount(null)
    }
  }, [typeCounters])

  async function actionSetTypesCount(params) {
    if (params) {
      setTypesCount(params)
    }
  }

  async function actionSaveReferenceFilters(params, reference) {
    if (params && reference) {
      saveReferenceFilters(params, reference)
    }
  }

  /** Search Perfect Property */
  async function apiRequestSearchPerfectProperty() {
    let params = await formatRequest('perfect-property', updatedFilter)

    if (!validateFilter(filters)) {
      handleNotification(true, {
        message: 'Please select state first',
        severity: 'error',
      })
    } else {
      setIsLoading(true)
      const request = {
        query: `?filters=${paramsToSha1(params)}`,
        data: params,
      }
      searchPerfectProperty(request)
    }
  }

  const handleFilter = (obj) => {
    setUpdatedFilter((prev) => ({ ...prev, filters: { ...prev.filters, ...obj } }))
    if (obj) {
      setFilter(obj)
      if (JSON.stringify(obj) !== JSON.stringify(filter)) {
        saveFilters(obj)
      }
    }
  }

  const applyFilter = () => {
    //apiRequestSearchPerfectProperty()
    if (getRoutePath() === 'perfect-property') {
      const homeButtons = SEARCH_BUTTONS.map((item) =>
        item.id === 'mpp' ? { ...item, active: true } : { ...item, active: false }
      )
      setFilters({ key: 'search_reference', value: homeButtons })
    }
    initiateSearch()
  }

  const handleClearFilter = () => {
    dispatch(
      FiltersActions(
        null,
        'GENERAL_FILTERS',
        'CLEAR_ALL_EXCEPT_SEARCH_REFERENCE_AND_SUBURBS'
      )
    )
  }

  const goBack = (e) => {
    e.preventDefault()
    routeChangeBack()
  }

  const clearSelectedFilters = (e) => {
    e.preventDefault()
    handleClearFilter()
  }

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <Header>
        <HeaderNav>
          <BackButton onClick={goBack}>
            <DynamicIcons icon="ChevronLeft" /> GO BACK
          </BackButton>
        </HeaderNav>
        <Heading variant="h6" style={{ fontWeight: 400 }}>
          Find My Perfect Property
        </Heading>
        <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
          Enter your lifestyle needs to discover perfect properties beyond where you
          know.
        </Heading>
      </Header>

      <div className={classes.content}>
        <Filter
          handleFilter={handleFilter}
          isClearFilter={isClearFilter}
          saveFilters={saveFilters}
          resetFilters={resetFilters}
          filters={filters}
          filterType={'perfect-property'}
          propertyTypesCount={propertyTypesCount}
        />
      </div>

      <ButtonWrap>
        <ClearFiltersButton type="mpp" onClick={clearSelectedFilters} />
        <StyledButton
          variant="contained"
          onClick={applyFilter}
          style={{
            backgroundColor: '#6DC5A1',
            marginLeft: '10px',
            textTransform: 'none',
            color: 'white',
          }}
        >
          Apply Filters
        </StyledButton>
      </ButtonWrap>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    filters: state.filters,
    property: state.property,
    typeCounters: state.referenceFilters.typesCounters,
    referenceFilters: state.referenceFilters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSearchFilters: (params) => setPropertySearchFilters(params),
      clearFiltersState: (params) => clearFilters(params),
      searchPerfectProperty: (params) => requestSearchPerfectProperty(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setPerfectPropertyFilter: (params) => setPerfectPropertyFilters(params),
      clearPerfectProperty: () => clearPerfectPropertyFilters(),
      clearLikeAndUnlike: () => clearLikedUnlikedProperties(),
      setTypesCount: (params) => setTypesCounters(params),
      clearTypeCount: () => clearTypesCounters(),
      saveReferenceFilters: (params, reference) =>
        setReferenceFilters(params, reference),
      removeReferenceFilters: () => clearReferenceFilters(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FiltersPerfect))
