import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Breadcrumbs, Link, withStyles } from '@material-ui/core';
import PMLogo from 'assets/images/pm-logo-inverted.png'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 15,
    marginBottom: 35,
    width: '100%',
    padding: '0 80px 0 100px',
  },
  content: {
    borderBottom: '0.3px solid gray',
    width: '100%',
  },
  title: {
    fontWeight: 400,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  link: {
    fontSize: 13,
    cursor: 'pointer',
  },
})

const AgentBreadCrumbs = (props) => {
  const { classes, crumbsLinks } = props
  const [routerChange] = useRouterChange()

  function handleClick(event, path) {
    event.preventDefault()
    routerChange(path)
  }

  const _renderCrumbsItem = (value, index) => {
    if (value.type === 'secondary') {
      return (
        <Link
          className={classes.link}
          key={index}
          color="inherit"
          onClick={(e) => handleClick(e, value.path)}
        >
          {value.label}
        </Link>
      )
    }
    if (value.type === 'primary') {
      return (
        <Typography
          className={classes.link}
          key={index}
          color="textPrimary"
          style={{ cursor: 'auto' }}
        >
          {value.label}
        </Typography>
      )
    }
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs
        className={classes.breadGroup}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {crumbsLinks &&
          crumbsLinks.map((value, index) => {
            return _renderCrumbsItem(value, index)
          })}
      </Breadcrumbs>
    </div>
  )
}

AgentBreadCrumbs.propTypes = {
  classes: PropTypes.object,
  crumbsLinks: PropTypes.array,
}

export default withStyles(styles)(AgentBreadCrumbs)
