import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Box, InputAdornment, Typography, withStyles, FormControl } from '@material-ui/core';
import { Today } from '@material-ui/icons';

const Container = styled.div`
  padding: 10px 0 20px;
`;

const StyledFormControl = withStyles({
  root: {
    width: '100%',
  },
})(FormControl);

const CustomDatePicker = (props) => {
  const [value, setValue] = React.useState(props.value || null);
  console.log(props.value);

  useEffect(() => {
    if (props.isClearFilter) {
      setValue(null);
    }
  }, [props.isClearFilter]);

  const handleChange = (date) => {
    setValue(date);
    if (props.handleDate) {
      props.handleDate(date);
    }
  };

  return (
    <Container style={{ ...props.style, padding: props.isForm ? '0px' : '' }}>
      <StyledFormControl variant="outlined">
        {props.label && (
          <Box marginBottom={props.isForm ? '-19px' : ''}>
            <Typography variant={'caption'} style={{ color: 'gray' }}>
              {props.label}
            </Typography>
          </Box>
        )}
        <DatePicker
          {...props.styles}
          autoOk
          margin="normal"
          format="dd/MM/yyyy"
          clearable
          value={value}
          onChange={handleChange}
          inputVariant="outlined"
          minDate={props.minDate ? props.minDate : undefined}
          InputProps={{
            placeholder: props.placeholder ?? '',
            endAdornment: (
              <InputAdornment position="end">
                <Today />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormControl>
    </Container>
  );
};

export default CustomDatePicker;
