import React from 'react';
import { withStyles, Tabs, Tab } from '@material-ui/core';

const styles = theme => ({
    root: {    
        display: 'flex',
        width: '60%',
        position: 'relative',
        paddingTop: theme.spacing(3),
    },    
});

const StyledTab = withStyles((theme) => ({
    root: {     
      fontWeight: 500,
      backgroundColor: "#f5f5f5", 
      '&$selected': {
        color: '#000000',    
        backgroundColor: "#ffffff",     
      },
      '&:focus': {
        color: '#000000',
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

const TabSelector = props => {
    const {
        classes,
        initialTab,
        setInitialTab
    } = props

    const handleChange = (event, value)=>{
        setInitialTab(value)
    }

    return (
        
        <Tabs
            value={initialTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="Category Selector"
            variant="fullWidth"
            className={classes.root}
        >
            <StyledTab value={'agent'} label="Agent" />                
            <StyledTab value={'agency'} label="Agency" />
        </Tabs>
        
    );
}

export default withStyles(styles)(TabSelector);