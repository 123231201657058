import React, { useState } from 'react';
import { IconButton, Paper, Container, AppBar, Typography, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DialogContainer from './DialogContainer';
import DynamicIcons from '../DynamicIcons';



const styles = theme => ({ 
    headerBar: {
        backgroundColor: theme.palette.light.main,
        boxShadow:'none'
    },
    modal: {
        margin: theme.spacing(7),
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '23vh',
        maxWidth: 600,
        borderRadius: 38,
        position: 'relative',    
        '&:hover': {
        outline: 'none',
        },
        overflow: 'hidden',
        padding: theme.spacing(3.25, 5, 3.75)
    },
    closeicon: {
        position: 'absolute',
        top: 8, 
        right: 10,
        zIndex: 2,

        '& svg' :{
            width: 28,
            height: 28,
            fill: '#000000'
        }
    },
    text: {
        fontSize: 21,
        fontWeight: 300
    },
    title: {
        fontSize: 36,
        fontWeight: 500,
        marginBottom: theme.spacing(2)
    }
});

const MainHeader = withStyles({
    root: {
      boxShadow:'none',
      padding:'10px 0px'
    }
  })(AppBar);

const ContentDialog = props => {  
    const {
      classes,      
      isOpen, 
      closeCallback,
      align,
      content,
      style,
      modalStyle
    } = props;

    const customStyle={
        textAlign: align,
        ...style
    }
    
    const customModalStyle={        
        ...modalStyle
    }

    return (                  
        <DialogContainer
                isOpen={isOpen}
                closeCallback={closeCallback}
            >
                <Paper className={classes.modal} elevation={5} style={customModalStyle}>                
                    <MainHeader className={classes.headerBar} position="static">
                        <IconButton aria-label="close" className={classes.closeicon} onClick={()=> {closeCallback(false)}}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>                         
                    </MainHeader>
                    <Container style={customStyle}>
                       { content }
                    </Container>
                </Paper>
        </DialogContainer>  
    );
}

export default withStyles(styles)(ContentDialog);