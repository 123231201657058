import React from 'react'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import TuneIcon from '@material-ui/icons/Tune'

import ButtonSelection from './ButtonSelection'
import OptionModalTop from './OptionModalTop'
import SliderContainer from './SliderContainer'

import ListOptions from './ListOptions'

import { BUTTON_FILTERS, PROPERTY_TYPE } from './constants'
import { PRICE_RANGE, PRICE_MARKS } from '../../../constants/constants'

import {
  withStyles,
  IconButton,
  Paper,
  Button,
  Badge,
  Checkbox,
  FormControlLabel,
  Box,
} from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  filterMainContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    [theme.breakpoints.down(1440)]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  /** Search Filter */
  searchFilterContainer: {
    paddingTop: '0.625em',
    paddingBottom: '0.625em',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'noWrap',
    flexDirection: 'row',
    overflowX: 'scroll',
    alignItems: 'center',
    position: 'relative',
    zIndex: 6,
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
  },
  /** Top Option Modal */
  optionContainer: {
    padding: '1.250em',
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
  },
  optionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '0.625em',
  },
  optionButton: {
    textTransform: 'none',
    borderRadius: 20,
    margin: '0.625em',
  },
  filterContainer: {
    paddingTop: '3.125em',
  },
  filterContainerList: {
    paddingTop: '4.125em',
  },
  iconTune: {
    color: '#000',
  },
  checkBoxText: {
    fontSize: 10,
    fontWeight: 'normal',
    color: '#6A6A6A',
  },
  optionBottomContainer: {
    '&:hover': {
      outline: 'none',
    },
  },
  optionList: {
    height: '40vh',
    overflowY: 'scroll',
  },
  nearbySuburbsCheckBoxLabel: {
    [theme.breakpoints.down(1440)]: {
      fontSize: '12px',
    },
  },
})

const SCALE_PRICE = PRICE_MARKS[PRICE_MARKS.length - 1].value

const FilterOptions = (props) => {
  const {
    classes,
    type,
    showModalOptions,
    showOptionModal,
    selectedFilterType,
    setIsInputFocused,
    setIncludeNearbyFilter,
    apiRequestSearchProperty,
    filters,
    navigateFilterButtonTo,
  } = props

  // const cbIncludeNearby = React.useRef('')
  const [routerChange] = useRouterChange()

  const [buttonFilters, setButtonFilters] = React.useState(BUTTON_FILTERS)
  const [filterData, setFilterData] = React.useState({
    bedrooms: { label: 'Bedrooms', selected: '' },
    price: { label: 'Price', selected: '', min: PRICE_RANGE[0], max: PRICE_RANGE[1] },
    priceRent: { label: 'Price Per Week', selected: '', min: 250, max: 5000 },
    bathrooms: { label: 'Bathrooms', selected: '' },
  })
  const [filterDataProperty, setFilterDataProperty] = React.useState(null)
  const [isNearby, setIsNearby] = React.useState(
    filters ? (filters.includeNearby ? filters.includeNearby : false) : true
  )

  React.useEffect(() => {
    if (filters) {
      if (filters.filters.propertyDetails) {
        const propertyDetails = filters.filters.propertyDetails
        const isAny =
          propertyDetails.price.min === PRICE_RANGE[0] &&
          propertyDetails.price.max === PRICE_RANGE[1]
        const isAnyRent =
          propertyDetails.priceRent.min === 250 &&
          propertyDetails.priceRent.max === 5000
        let sign = ''
        if (propertyDetails.price.max === PRICE_RANGE[1]) {
          sign = '+'
        }
        let newData = {
          bedrooms: { label: 'Bedrooms', selected: propertyDetails.bedrooms || '' },
          price: {
            label: isAny ? 'Any' : 'Price',
            selected: isAny
              ? ''
              : '$' +
              nFormatter(propertyDetails.price.min) +
              ' - $' +
              nFormatter(propertyDetails.price.max) +
              sign,
            min: propertyDetails.price.min,
            max: propertyDetails.price.max,
          },
          priceRent: {
            label: isAnyRent ? 'Any' : 'Price Per Week',
            selected: isAnyRent
              ? ''
              : '$' +
              nFormatter(propertyDetails.priceRent.min) +
              ' to $' +
              nFormatter(propertyDetails.priceRent.max) +
              '',
            min: propertyDetails.priceRent.min,
            max: propertyDetails.priceRent.max,
          },
          bathrooms: { label: 'Bathrooms', selected: propertyDetails.bathrooms || '' },
        }

        setFilterData(newData)
        handleData(newData)
      }
      if (filters.filters.propertyType) {
        setFilterDataProperty(filters.filters.propertyType)
      }
      setIsNearby(filters.includeNearby || false)
    }
  }, [filters])

  React.useEffect(() => {
    if (props.isClearFilter) {
      // cbIncludeNearby.current.checked = false;
      setIsNearby(false)
    }
  }, [props.isClearFilter])

  React.useEffect(() => { }, [selectedFilterType])

  React.useEffect(() => {
    handleData()
  }, [filterData])

  function handleNearbyCheck(value) {
    setIncludeNearbyFilter(value)
    setIsNearby(value)
  }

  function formatLabel(val1, val2) {
    if (
      (val1 === 0 && val2 === SCALE_PRICE) ||
      (val1 < PRICE_RANGE[0] && val2 === PRICE_RANGE[1])
    ) {
      return 'Any'
    } else if (
      (val1 === 0 && val2 === SCALE_PRICE) ||
      (val1 < PRICE_RANGE[0] && val2 < PRICE_RANGE[1] && val2 > PRICE_RANGE[0])
    ) {
      return 'Below $' + nFormatter(val2, 2, true)
    } else if (
      (val1 === 0 && val2 === SCALE_PRICE) ||
      (val1 < PRICE_RANGE[0] && val2 <= PRICE_RANGE[0])
    ) {
      return 'Below $' + nFormatter(PRICE_RANGE[0], 2, true)
    } else {
      let sign = ''
      if (val2 === PRICE_RANGE[1]) {
        sign = '+'
      }
      return '$' + nFormatter(val1, 2, true) + ' - $' + nFormatter(val2, 2, true) + sign
    }
  }

  const handleSlider = (type, data) => {
    const sliderFilter = filterData
    if (type === 'bedrooms') {
      sliderFilter.bedrooms.label = data.bedrooms > 1 ? 'Bedrooms' : 'Bedroom'
      sliderFilter.bedrooms.selected = data.bedrooms
    }
    if (type === 'price') {
      const isAny =
        data.price.min === PRICE_RANGE[0] && data.price.max === PRICE_RANGE[1]
      sliderFilter.price.label = isAny ? 'Any' : ''
      sliderFilter.price.selected = isAny
        ? ''
        : formatLabel(data.price.min, data.price.max)
      sliderFilter.price.min = data.price.min
      sliderFilter.price.max = data.price.max
    }

    if (type === 'priceRent') {
      const isAny = data.priceRent.min === 250 && data.priceRent.max === 5000
      sliderFilter.priceRent.label = isAny ? 'Any' : ''
      sliderFilter.priceRent.selected = isAny
        ? ''
        : '$' + nFormatter(data.priceRent.min) + ' - $' + nFormatter(data.priceRent.max)
      sliderFilter.priceRent.min = data.priceRent.min
      sliderFilter.priceRent.max = data.priceRent.max
    }
    if (type === 'bathrooms') {
      sliderFilter.bathrooms.label = data.bathrooms > 1 ? 'Bathrooms' : 'Bathroom'
      sliderFilter.bathrooms.selected = data.bathrooms
    }
    setFilterData({ ...sliderFilter, sliderFilter })
  }

  const handlePropertyType = (data) => {
    setFilterDataProperty(data)
  }

  const handleData = (data, isSave) => {
    let newData = data || filterData
    const isRent = props.path && props.path === 'rent'

    if (newData) {
      // const isAny = newData.price.min === PRICE_RANGE[0] && newData.price.max === PRICE_RANGE[1];
      let data = [
        {
          type: 'bed',
          label: newData.bedrooms.label,
          selected: newData.bedrooms.selected !== '' ? newData.bedrooms.selected : '',
          values: [
            {
              name: '',
              value: newData.bedrooms.selected,
            },
          ],
        },
        {
          type: 'cost',
          label: isRent ? newData.priceRent.label : newData.price.label,
          selected: isRent
            ? newData.priceRent.selected !== ''
              ? newData.priceRent.selected
              : ''
            : newData.price.selected !== ''
              ? newData.price.selected
              : '',
          values: [
            {
              name: '',
              value: {
                min: isRent ? newData.priceRent.min : newData.price.min,
                max: isRent ? newData.priceRent.max : newData.price.max,
              },
            },
          ],
        },
        {
          type: 'bath',
          label: newData.bathrooms.label,
          selected: newData.bathrooms.selected !== '' ? newData.bathrooms.selected : '',
          values: [
            {
              name: '',
              value: newData.bathrooms.selected,
            },
          ],
        },
        {
          type: 'propertyType',
          label: 'Property Type',
          selected: '',
          values: null,
        },
      ]

      if (filterDataProperty) {
        data[3].values = filterDataProperty
      }
      setButtonFilters(data)
      if (props.saveFilters && isSave === 'save') {
        props.saveFilters(data)
        showOptionModal(null, true)
        setIsInputFocused(false)
      }
    }
  }

  function nFormatter(num, digits) {
    var si = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ]
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/
    var i
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
  }

  function renderListContent() {
    return (
      <Paper
        className={classes.optionContainer}
        // style={{height: selectedFilterType === 'propertyType' ? '80vh' : '60vh'}}
        elevation={5}
      >
        <div className={classes.filterContainerList}>
          <div className={classes.optionList}>
            <ListOptions
              type={'property'}
              options={filterDataProperty}
              handleType={handlePropertyType}
            />
          </div>
        </div>
        <div className={classes.optionButtonContainer}>
          <Button
            size={'small'}
            className={classes.optionButton}
            onClick={() => {
              showOptionModal(null, true)
              setIsInputFocused(false)
            }}
          >
            Cancel
          </Button>
          <Button
            size={'small'}
            className={classes.optionButton}
            color={'primary'}
            variant="contained"
            onClick={() => {
              handleData(null, 'save')
              apiRequestSearchProperty()
            }}
          >
            Apply
          </Button>
        </div>
      </Paper>
    )
  }

  function renderSearchModalOptions() {
    return (
      <Paper
        className={classes.optionContainer}
        // style={{height: selectedFilterType === 'propertyType' ? '80vh' : '60vh'}}
        elevation={5}
      >
        <div className={classes.filterContainer}>
          <SliderContainer
            path={props.path}
            type={selectedFilterType}
            handleSlider={handleSlider}
            filterData={filterData}
          />
        </div>
        <div className={classes.optionButtonContainer}>
          <Button
            size={'small'}
            className={classes.optionButton}
            onClick={() => {
              showOptionModal(null, true)
              setIsInputFocused(false)
            }}
          >
            Cancel
          </Button>
          <Button
            size={'small'}
            className={classes.optionButton}
            color={'primary'}
            variant="contained"
            onClick={() => {
              handleData(null, 'save')
              apiRequestSearchProperty()
            }}
          >
            Apply
          </Button>
        </div>
      </Paper>
    )
  }

  /** filter view */
  function renderFilterOptions() {
    return (
      <>
        <div className={classes.filterMainContainer}>
          <FormControlLabel
            className={classes.checkBoxText}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
                name="checkedI"
                checked={isNearby}
                onChange={(e) => {
                  handleNearbyCheck(!isNearby)
                }}
              />
            }
            label={
              <Box className={classes.nearbySuburbsCheckBoxLabel}>
                Include nearby suburbs
              </Box>
            }
          />
        </div>
      </>
    )
  }

  /** result view */
  function renderSearchOptions() {
    return (
      <>
        <div className={classes.searchFilterContainer}>
          <IconButton onClick={() => routerChange(navigateFilterButtonTo)}>
            <Badge badgeContent={null} color="primary">
              <TuneIcon className={classes.iconTune} />
            </Badge>
          </IconButton>
          {buttonFilters.map((value, index) => {
            return (
              <ButtonSelection
                key={index}
                data={value}
                setShowModalOptions={() => {
                  showOptionModal(value.type)
                  setIsInputFocused(false)
                }}
              />
            )
          })}
        </div>
      </>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <OptionModalTop
        isOpen={showModalOptions}
        showOptionModal={showOptionModal}
        content={
          selectedFilterType === 'propertyType'
            ? renderListContent()
            : renderSearchModalOptions()
        }
      />
      {type === 'search' ? renderSearchOptions() : renderFilterOptions()}
    </div>
  )
}

export default withStyles(styles)(FilterOptions)
