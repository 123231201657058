import React, { useState, useEffect, useRef } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { scrollToRef } from '../../components/Mobile/ScrollEvents'
import NotificationDialog from '../../components/Mobile/CustomDialogs/NotificationDialog'

import LogoPlaceholder from '../../assets/images/biggin-scott.jpg'
import _ from 'lodash'

import DirectUploadStep1 from '../../components/Mobile/Agent/DirectUploadStep1'

import DirectUploadStep2 from '../../components/Mobile/Agent/DirectUploadStep2'
import DirectUploadStep3 from '../../components/Mobile/Agent/DirectUploadStep3'
import DirectUploadStep4 from '../../components/Mobile/Agent/DirectUploadStep4'
import { AGENT_CREATE_PROPERTY_FIELDS_CONFIG } from '../../constants/constants'

import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  getAgencyHexByAgencyId,
  DEFAULT_AGENCIES,
  HEX_OBJECT,
} from '../../constants/constants'

import checkField from 'components/Common/Validator/validationService'

/** api redux request */
import { requestAgentCreateProperty, requestAgentDetails } from '../../store/api'

import { toggleSignUp } from '../../store/actions'
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
})

const AgentAddProperty = ({
  classes,
  users,
  authentication,
  requestCreateProperty,
  create_property,
  create_property_status,
  create_property_error,
  toggleSignUp,
  settings,
  history,
}) => {
  const [routerChange] = useRouterChange()
  const [back, setBack] = useState(false)
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [activeStep, setActiveStep] = useState(1)
  const [successNotice, setSuccessNotice] = useState(false)
  const [errorNotice, setErrorNotice] = useState(false)
  const [addPropertyFields, setAddPropertyFields] = useState(null)
  const [errors, setErrors] = useState({
    vendorsEmail: { status: false, message: '' },
    vendorsPhone: { status: false, message: '' },
  })

  const topContainer = useRef(null)

  const executeTopScroll = (d) => scrollToRef(topContainer, d)
  const defaultAddPropertyFields = {
    agentInfo: {
      firstName: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      lastName: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      email: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      phone: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      agencyEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      vendorsEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      vendorsPhone: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      confirmVendorsEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
    },
    propertyInfo: {
      images: {
        value: ['default'],
        error: {
          status: false,
          message: '',
        },
      },
      customCover: {
        value: false,
        error: {
          status: false,
          message: '',
        },
      },
      propertyType: '',
      propertyStatus: {
        value: 'comingsoon',
        error: {
          status: false,
          message: '',
        },
      },
      description: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      propertyDetails: {},
      address: {
        value: {},
        error: {
          status: false,
          message: '',
        },
      },
      statementOfIntent: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
    },
  }
  const logoObject = LOGO_OBJECT
  const hexObject = HEX_OBJECT
  const brandColor =
    users && users.agency && users.agency.brandColor
      ? users.agency.brandColor
      : users.reaAgencyId
        ? getAgencyHex(users.reaAgencyId)
        : null

  useEffect(() => {
    if (users?.user?.userType !== 'Agent' && !users?.user?.userTypeList.some(i => ['Agent'].includes(i))) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    if (
      create_property_status &&
      create_property_status === 'success' &&
      !create_property_error
    ) {
      setSuccessNotice(true)
      setTimeout(() => {
        setSuccessNotice(false)
        window.location.reload()
      }, 3000)
    } else {
      setErrorNotice(true)
      /*  setTimeout(()=>{
        setErrorNotice(false)        
      }, 3000) */
    }
  }, [create_property_status, create_property_error])

  useEffect(() => {
    executeTopScroll(false)
  }, [activeStep])

  useEffect(() => {
    if (authentication && authentication.token && users && users.user) {
      setSelectedAgent(users.user)
      setAddPropertyFields({
        ...defaultAddPropertyFields,
        agentInfo: {
          ...defaultAddPropertyFields.agentInfo,
          firstName: {
            ...defaultAddPropertyFields.agentInfo.firstName,
            value:
              users && users.agent && users.agent.firstName
                ? users.agent.firstName
                : '',
          },
          lastName: {
            ...defaultAddPropertyFields.agentInfo.lastName,
            value:
              users && users.agent && users.agent.lastName ? users.agent.lastName : '',
          },
          email: {
            ...defaultAddPropertyFields.agentInfo.email,
            value: users && users.agent && users.agent.email ? users.agent.email : '',
          },
          phone: {
            ...defaultAddPropertyFields.agentInfo.phone,
            value:
              users && users.agent && users.agent.telephone
                ? users.agent.telephone
                : '',
          },
          agencyEmail: {
            ...defaultAddPropertyFields.agentInfo.agencyEmail,
            value:
              users && users.agency && users.agency.adminEmail
                ? users.agency.adminEmail
                : '',
          },
        },
      })
    }
  }, [authentication, users])

  useEffect(() => {
    if (addPropertyFields) {
      const fields = {}
      fields.vendorsEmail = addPropertyFields.propertyInfo.vendorsEmail
      fields.vendorsPhone = addPropertyFields.propertyInfo.vendorsPhone
      regFormCheck(fields, errors)
    }

    if (authentication && !authentication.token && users && !users.user) {
      routerChange('/login')
    }
  }, [addPropertyFields])

  const handleFieldChange = (fieldGroup, subGroup, fieldName, value) => {
    if (subGroup) {
      setAddPropertyFields({
        ...addPropertyFields,
        [fieldGroup]: {
          ...addPropertyFields[fieldGroup],
          [subGroup]: {
            ...addPropertyFields[fieldGroup][subGroup],
            [fieldName]: {
              ...addPropertyFields[fieldGroup][subGroup][fieldName],
              value: value,
            },
          },
        },
      })
    } else if (fieldGroup) {
      setAddPropertyFields({
        ...addPropertyFields,
        [fieldGroup]: {
          ...addPropertyFields[fieldGroup],
          [fieldName]: {
            ...addPropertyFields[fieldGroup][fieldName],
            value: value,
          },
        },
      })
    }
  }

  const formatUSD = (num) => {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const apiCreateProperty = async () => {
    const params = {}

    params.property_status =
      addPropertyFields.propertyInfo.propertyStatus.value ?? 'comingsoon'
    params.property_type =
      addPropertyFields.propertyInfo.propertyType.value ?? 'comingsoon'
    params.address = {
      full: addPropertyFields.propertyInfo.address.value.full ?? '',
      lot_number: addPropertyFields.propertyInfo.address.value.lotNumber ?? '',
      sub_number: addPropertyFields.propertyInfo.address.value.subNumber ?? '',
      street_number: addPropertyFields.propertyInfo.address.value.streetNumber ?? '',
      street_name: addPropertyFields.propertyInfo.address.value.streetName ?? '',
      municipality: addPropertyFields.propertyInfo.address.value.municipality ?? '',
      state: addPropertyFields.propertyInfo.address.value.state ?? '',
      suburb: addPropertyFields.propertyInfo.address.value.suburb.toUpperCase() ?? '',
      postcode: addPropertyFields.propertyInfo.address.value.postcode ?? '',
    }
    params.bedrooms = addPropertyFields.propertyInfo.propertyDetails.value.bedrooms ?? 1
    params.bathrooms =
      addPropertyFields.propertyInfo.propertyDetails.value.bathrooms ?? 1
    params.carparkings =
      addPropertyFields.propertyInfo.propertyDetails.value.carparking ?? 1
    params.agency_email = addPropertyFields.agentInfo.agencyEmail.value
    params.vendor_email = addPropertyFields.agentInfo.vendorsEmail.value
    params.image = addPropertyFields.propertyInfo.images.value
    params.price = addPropertyFields.propertyInfo.propertyDetails.value.price
    params.customCover = addPropertyFields.propertyInfo.customCover.value
    params.statement_of_intent = addPropertyFields.propertyInfo.statementOfIntent.value
    params.vendor_phone = addPropertyFields.agentInfo.vendorsPhone.value
      .match(/\d/g)
      .join('')
    params.description = addPropertyFields.propertyInfo.description.value

    if (
      addPropertyFields.propertyInfo.propertyDetails &&
      addPropertyFields.propertyInfo.propertyDetails.value.hidePrice === true
    ) {
      params.priceView = 'Contact Agent'
    } else {
      params.priceView = formatUSD(
        addPropertyFields.propertyInfo.propertyDetails.value.price
      )
    }

    await requestCreateProperty(params)
  }

  const regFormCheck = (fields, errors) => {
    var reqFields = _.pickBy(fields, (e) => (e ? true : false)),
      setfields = fields
    if (
      Object.keys(reqFields).length > 0 &&
      Object.keys(setfields).length === Object.keys(reqFields).length &&
      Object.keys(errors).length > 0
    ) {
      var statusBucket = []

      Object.keys(fields).map((e) => {
        statusBucket.push(errors[e] && errors[e] !== 'undefined' && !errors[e].status)
      })
    }
  }

  const handleCheckField = (state, value, fieldName) => {
    if (
      checkField(
        state,
        AGENT_CREATE_PROPERTY_FIELDS_CONFIG(fieldName).fields[fieldName]
      ) === null
    ) {
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: { ...prevState[fieldName], status: false },
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          status: true,
          message: checkField(
            value,
            AGENT_CREATE_PROPERTY_FIELDS_CONFIG(fieldName).fields[fieldName]
          ),
        },
      }))
    }
  }

  const getAgencyLogo = (agencyName) => {
    if (
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return LogoPlaceholder
    }
  }

  const getAgencyHex = (agencyName) => {
    if (
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return '#000000'
    }
  }

  const handleBackButton = () => setBack(true)

  return (
    <div className={classes.root}>
      <div ref={topContainer} className={classes.topAnchor}></div>
      {addPropertyFields && (
        <>
          {activeStep === 1 && (
            <DirectUploadStep1
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              addPropertyFields={addPropertyFields}
              setAddPropertyFields={setAddPropertyFields}
              handleFieldChange={handleFieldChange}
              handleCheckField={handleCheckField}
              errors={errors}
              users={users}
              logo={
                users.agency && users.agency.logoUrl && users.agency?.reaAgencyId
                  ? users.agency.logoUrl
                  : getAgencyLogo(users.agency?.reaAgencyId)
              }
              hex={brandColor}
              backClick={back}
            />
          )}

          {activeStep === 2 && (
            <DirectUploadStep2
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              addPropertyFields={addPropertyFields}
              setAddPropertyFields={setAddPropertyFields}
              handleFieldChange={handleFieldChange}
              handleCheckField={handleCheckField}
              errors={errors}
              users={users}
              logo={
                users.agency && users.agency.logoUrl && users.agency?.reaAgencyId
                  ? users.agency.logoUrl
                  : getAgencyLogo(users.agency?.reaAgencyId)
              }
              hex={brandColor}
              handleBackClick={handleBackButton}
              backClick={back}
            />
          )}

          {activeStep === 3 && (
            <DirectUploadStep3
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              addPropertyFields={addPropertyFields}
              setAddPropertyFields={setAddPropertyFields}
              handleFieldChange={handleFieldChange}
              apiCreateProperty={apiCreateProperty}

              users={users}
              authentication={authentication}
              logo={
                users.agency && users.agency.logoUrl && users.agency?.reaAgencyId
                  ? users.agency.logoUrl
                  : getAgencyLogo(users.agency?.reaAgencyId)
              }
              hex={brandColor}
              handleBackClick={handleBackButton}
            />
          )}

          {activeStep === 4 &&
            addPropertyFields.propertyInfo.address.value.state === 'VIC' &&
            addPropertyFields.propertyInfo.propertyStatus.value !== 'offmarket' && (
              <DirectUploadStep4
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                addPropertyFields={addPropertyFields}
                setAddPropertyFields={setAddPropertyFields}
                handleFieldChange={handleFieldChange}
                apiCreateProperty={apiCreateProperty}
                users={users}
                logo={
                  users.agency && users.agency.logoUrl && users.agency?.reaAgencyId
                    ? users.agency.logoUrl
                    : getAgencyLogo(users.agency?.reaAgencyId)
                }
                hex={brandColor}
                toggleSignUp={toggleSignUp}
                settings={settings}
              />
            )}
        </>
      )}

      {create_property &&
        create_property_status === 'success' &&
        !create_property_error && (
          <NotificationDialog
            isOpen={successNotice}
            closeCallback={() => {
              setSuccessNotice(false)
            }}
            title={'Success'}
            text={'Property submitted for vendor and agency approval.'}
            showLogo={true}
            align={'center'}
          />
        )}
      {!create_property &&
        create_property_status === 'failed' &&
        create_property_error && (
          <NotificationDialog
            isOpen={errorNotice}
            closeCallback={() => {
              setErrorNotice(false)
            }}
            title={'Error'}
            text={
              create_property_error.message ||
              'An error encountered while submitting your property.'
            }
            showLogo={true}
            align={'center'}
          />
        )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    users: state.users,
    create_property_status: state.agents.request_create_property_status,
    create_property_error: state.agents.request_create_property_error,
    create_property: state.agents.request_create_property,
    settings: state.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestCreateProperty: (params) => requestAgentCreateProperty(params),
      toggleSignUp: (params) => toggleSignUp(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentAddProperty))
