import React, { useCallback, useState } from 'react';
import { useDropzone, Dropzone} from 'react-dropzone';
import { IconButton, withStyles } from "@material-ui/core";
import DynamicIcons from '../DynamicIcons';
import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  root: {
    borderColor: 'red',
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
  content: {
    height: '100%'
  },
  dropzone: {
    padding:theme.spacing(2.5, 5, 2.5, 2.75),
    borderColor: "#9b9b9b",
    borderStyle: 'solid',
    borderWidth: 0.5,
    borderRadius: 4,
    position: 'relative',
    overflow: 'hidden'
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: -1,
    background: '#35C0CA',
    borderRadius: 4, 
    padding: theme.spacing(2.2, 2.8),
    '&:hover' : {
        background: '#35C0CA',
    }
  },
  activeDrop: {
    backgroundColor: grey[200]
  },
  successDrop: {
    borderColor: theme.palette.primary.main
  },
  errorDrop: {
    borderColor: '#ff0000', 
    '& > p' : {
        color: '#ff0000'
    }
  }


});

const DropField = props => {
    const {
      classes,
      onDropAccepted,
      onDropRejected,
      fileState,
      maxFiles,
      accept,
      label,
      name,
    } = props;

    const {   
        getRootProps, 
        getInputProps, 
        isDragActive,
    } = useDropzone({onDropAccepted, onDropRejected, maxFiles: maxFiles, accept: accept});

    return (
        <section className="container">
            <div {...getRootProps()} className={`${classes.dropzone} ${isDragActive ? classes.activeDrop : '' } ${fileState && !fileState.error ? classes.successDrop : '' }  ${fileState && fileState.error ? classes.errorDrop : ''}`}>            
                <input {...getInputProps()} className={classes.input} name={name || "dropfield"}/>
                {!fileState && <p>Drag and drop or select { accept } file here</p>}
                {fileState && fileState.error && fileState.errors && <p>Error. {fileState.errors[0].message}</p>}
                {fileState && !fileState.error && fileState.path && <p>{fileState.path}</p>}                
                {fileState && !fileState.error && !fileState.path && <p>{fileState.data && fileState.data.length ? fileState.data.length : 0 } file/s added</p>}                
                <IconButton color="primary" aria-label={ label|| "File Uploader"} size="medium" component="span" className={classes.icon}>
                    <DynamicIcons icon="2DCDownloadIcon" viewBox="0 0 18 18" width="18" height="18" style={{width:'18px'}}/>
                </IconButton> 
            </div>        
        </section>
    );
}

export default withStyles(styles)(DropField);