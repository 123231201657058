import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Sidebar from 'components/Web/Sidebar'

import { SuperHeader } from 'components/Web/Superadmin'
/** selectors */
import {
  getAuthentication,
  getSettings,
  getUser,
} from 'store/selectors/superadmin.selectors'

import { toggleFilterWeb } from 'store/actions'

import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
})

const AdminLayout = (props) => {
  const { classes, children } = props
  const [routerChange] = useRouterChange()

  const dispatch = useDispatch()

  const AUTH = useSelector(getAuthentication)
  const SETTINGS = useSelector(getSettings)
  const USER = useSelector(getUser)

  const [open, setOpen] = useState(false)

  const handleToggleDrawer = (value) => {
    setOpen(value)
  }

  const routeChange = (path) => {
    routerChange(path)
    setOpen(false)
    /** reset redux state */
    dispatch(toggleFilterWeb({ type: null, status: false }))
  }

  const handleNavigation = (type) => {
    routeChange(type.path)
  }

  useEffect(() => {
    /** If no token for superadmin re-route to login */
    if (!AUTH?.superadmintoken?.token) {
      routeChange('/superadmin/login')
    }
  }, [])

  return (
    <Fragment>
      <Sidebar
        open={open}
        isAgent={false}
        isSuperadmin={true}
        auth={AUTH && AUTH?.user_status}
        page={SETTINGS && SETTINGS?.showFilterWeb?.type}
        filterWebActive={SETTINGS && SETTINGS?.showFilterWeb?.status}
        handleToggleDrawer={handleToggleDrawer}
        handleNavigation={handleNavigation}
        routerChange={routeChange}
        {...children.props}
      />
      <main className={classes.root}>
        <SuperHeader
          {...children.props}
          user={AUTH?.superadminuser?.data?.superadmin}
        />
        {children}
      </main>
    </Fragment>
  )
}

export default withStyles(styles)(AdminLayout)
