import { useDispatch, useSelector } from 'react-redux'
import { StorageActions } from 'store/actions/storage.actions'
import { getSuperadminManageAgencyRowCount } from 'store/selectors/storage.selectors'

export default function useHandleManageAgencyRowCount() {
  const dispatch = useDispatch()
  const agencyRowCount = useSelector(getSuperadminManageAgencyRowCount)

  const setAgencyRowCount = (rowCount) => {
    dispatch(
      StorageActions(
        rowCount,
        'GENERAL_SETTINGS',
        'SET_SUPERADMIN_MANAGE_AGENCY_ROW_COUNT'
      )
    )
  }

  return [agencyRowCount, setAgencyRowCount]
}