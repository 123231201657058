import { FILTER_ACTION_TYPES } from 'store/constants/globalfilters.constants'
import { GLOBAL_FILTER_DEFAULT } from 'constants/globalfilters'
import { PURGE } from 'redux-persist'

const INITIAL_STATE = GLOBAL_FILTER_DEFAULT

//! if ALL params must be  {data: { //all key value pairs of InitialState  }}
//! if SINGLE params must be  {data: { key: "keyName", value: "" }}
//! if CLEARING ALL, params not required
//! if CLEARING SINGLE, params must be { key: ""}

const FilterReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FILTER_ACTION_TYPES['GENERAL_FILTERS']['SET_ALL_FILTER']:
      if (
        Object.keys(action.params.data)?.length > 0 &&
        Object.keys(INITIAL_STATE).length === Object.keys(action.params.data).length
      ) {
        return {
          ...state,
          ...action.params.data,
        }
      } else {
        return state
      }

    case FILTER_ACTION_TYPES['GENERAL_FILTERS']['CLEAR_ALL_FILTER']:
      return { ...INITIAL_STATE, suburbs: [] }

    case FILTER_ACTION_TYPES['GENERAL_FILTERS']['SET_SINGLE_FILTER']:
      return {
        ...state,
        [action.params.data.key]: action.params.data.value,
      }
    case FILTER_ACTION_TYPES['GENERAL_FILTERS']['CLEAR_SINGLE_FILTER']:
      return {
        ...state,
        [action.params.data.key]: null,
      }
    case FILTER_ACTION_TYPES['GENERAL_FILTERS']['SET_REFERENCE_FILTER']:
      const base = state
      delete base?.reference_filters
      if (action?.params) {
        return {
          ...base,
          reference_filters: {
            ...base,
            [action?.params?.key]: action?.params?.value,
          },
        }
      } else {
        return {
          ...state,
          reference_filters: state,
        }
      }
    case FILTER_ACTION_TYPES['GENERAL_FILTERS']['CLEAR_REFERENCE_FILTER']:
      return {
        ...state,
        reference_filters: null,
      }

    case FILTER_ACTION_TYPES['GENERAL_FILTERS'][
      'CLEAR_ALL_EXCEPT_SEARCH_REFERENCE_AND_SUBURBS'
    ]:
      return {
        ...INITIAL_STATE,
        suburbs: state.suburbs,
        search_reference: state.search_reference,
      }

    case PURGE:
      return INITIAL_STATE

    default: {
      return state
    }
  }
}

export default FilterReducers
