import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import useCompanyInfoSearchField from './useCompanyInfoSearchField';

const CompanyInfoSearchField = ({ value, isErrorShown, onValueChange }) => {
  const errorMessage = 'Please select brand';
  const { onTextChangeHandler, onCloseHandler, onCompanyInfoChangeHandler, options, isOpen } =
    useCompanyInfoSearchField({
      value,
      onValueChange,
    });

  return (
    <div>
      <Autocomplete
        options={options}
        defaultValue={value}
        open={isOpen}
        forcePopupIcon={false}
        id="auto-complete"
        autoComplete
        includeInputInList
        onChange={onCompanyInfoChangeHandler}
        onClose={onCloseHandler}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={isErrorShown ? errorMessage : null}
              helperText={isErrorShown ? errorMessage : null}
              variant={'outlined'}
              label={'Brand *'}
              margin="normal"
              inputProps={{
                ...params.inputProps,
                value: params.inputProps.value,
              }}
              onChange={onTextChangeHandler}
            />
          );
        }}
      />
    </div>
  );
};

export default CompanyInfoSearchField;
