import React, { Fragment, Component } from "react";
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: "flex",
    backgroundColor: '#FFF',
    height: '100vh',
    width: '100%'
  },
});

const AuthLayout = props => {
  const { classes, children } = props;

  return (
    <Fragment style={{position:'relative'}}>
      <div className={classes.root}>
        <main>
          {children}
        </main>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(AuthLayout);
