import { PURGE } from 'redux-persist'
import { IPP_REFERRAL_ACTION_TYPES } from 'store/constants'

const INITIAL_STATE = {
  // temporary values
  payload_request: null,
  payload_success: null,
  payload_failed: null,
  status: null,
  error: null,
  ippAnalyticsData: null,
}

const ippReferralReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IPP_REFERRAL_ACTION_TYPES['IPP_ANALYTICS_REFERRAL_CODE']['REQUEST']:
    case IPP_REFERRAL_ACTION_TYPES['IPP_ANALYTICS_GENERATE_REFERRAL_CODE']['REQUEST']:
    case IPP_REFERRAL_ACTION_TYPES['GET_IPP_ANALYTICS']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      }
    case IPP_REFERRAL_ACTION_TYPES['IPP_ANALYTICS_REFERRAL_CODE']['SUCCESS']:
    case IPP_REFERRAL_ACTION_TYPES['IPP_ANALYTICS_GENERATE_REFERRAL_CODE']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params.data,
        error: null,
      }

    case IPP_REFERRAL_ACTION_TYPES['IPP_ANALYTICS_REFERRAL_CODE']['FAILED']:
    case IPP_REFERRAL_ACTION_TYPES['IPP_ANALYTICS_GENERATE_REFERRAL_CODE']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params.data,
      }

    case IPP_REFERRAL_ACTION_TYPES['GET_IPP_ANALYTICS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        ippAnalyticsData: action.params.data,
      }

    case PURGE:
      return INITIAL_STATE

    default:
      return state
  }
}

export default ippReferralReducers
