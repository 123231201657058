import React, { useEffect, useState } from 'react';
import { Typography, withStyles, CircularProgress } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { useSelector } from 'react-redux';
import TableRow from '@material-ui/core/TableRow';
import { SuperBreadCrumbs } from 'components/Web/Superadmin';
import { getSuperadminToken } from 'store/selectors/superadmin.selectors';
import { useRouterChange } from 'utils/router.utils';
import { STATES_LABEL } from 'constants/constants';
import { StyledTableCell, StyledTableRow, styles } from './styles';
import { formatNumString } from 'utils/api/utils';
import { filterArray } from 'utils/api/utils';
import { capitalizeName } from 'utils/api/utils';
import _ from 'lodash';
import { createUrl } from 'utils/api';

const SuperadminDashboard = (props) => {
  const { classes } = props;
  const [routerChange] = useRouterChange();
  const SUPERADMIN_TOKEN = useSelector(getSuperadminToken);

  useEffect(() => {
    checkSuperadminToken();
  }, []);

  const checkSuperadminToken = () => {
    if (SUPERADMIN_TOKEN === null) {
      routerChange('/superadmin/login');
    }
  };

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Reports',
      path: '/superadmin/reports',
    },
  ];

  const [data, setData] = useState({
    agencyData: null,
    userData: null,
    propertyDataOverview: null,
    propertyDataTypes: null,
    propertyDataStates: null,
    googleAnalyticsData: null,
  });

  useEffect(() => {
    fetch(createUrl('/reporting-dashboard/database'))
      .then((response) => response.json())
      .then((data) => {
        setData((prevData) => ({
          ...prevData,
          agencyData: data.data.agencyData,
          userData: data.data.userData,
        }));
      })
      .catch((error) => console.error('Fetch failed', error));
  }, []);

  useEffect(() => {
    fetch(createUrl('/reporting-dashboard/database-types'))
      .then((response) => response.json())
      .then((data) => {
        setData((prevData) => ({
          ...prevData,
          propertyDataTypes: data.data.propertyData,
        }));
      })
      .catch((error) => console.error('Fetch failed', error));
  }, []);

  useEffect(() => {
    fetch(createUrl('/reporting-dashboard/database-state'))
      .then((response) => response.json())
      .then((data) => {
        setData((prevData) => ({
          ...prevData,
          propertyDataStates: data.data.propertyData,
        }));
      })
      .catch((error) => console.error('Fetch failed', error));
  }, []);

  useEffect(() => {
    fetch(createUrl('/reporting-dashboard/database-overview'))
      .then((response) => response.json())
      .then((data) => {
        setData((prevData) => ({
          ...prevData,
          propertyDataOverview: data.data.propertyData,
        }));
      })
      .catch((error) => console.error('Fetch failed', error));
  }, []);

  useEffect(() => {
    fetch(createUrl('/reporting-dashboard/google-analytics'))
      .then((response) => response.json())
      .then((data) => {
        setData((prevData) => ({
          ...prevData,
          googleAnalyticsData: data.data.googleAnalyticsData,
        }));
      })
      .catch((error) => console.error('Fetch failed', error));
  }, []);

  const renderBoldContent = (label, value) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography className={classes.textContentBold} variant={'h1'}>
          {label}
        </Typography>
        <Typography className={classes.textContentBold} variant={'h1'}>
          {value}
        </Typography>
      </div>
    );
  };

  const renderNormalContent = (label, value) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
        <Typography className={classes.textContentNormal} variant={'h1'}>
          {label}
        </Typography>
        <Typography className={classes.textContentNormal} variant={'h1'}>
          {value}
        </Typography>
      </div>
    );
  };

  const renderAnalytics = () => {
    return (
      <div className={classes.tableContainer}>
        <Typography className={classes.title} variant={'h1'}>
          {`Analytics`}
        </Typography>
        <div className={classes.tableRowContainer}>
          <div className={classes.tableColumnContainer}>
            {data?.userData ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Total Users', '')}
                {renderNormalContent('New signups today', formatNumString(data?.userData?.usersAddedToday))}
                {renderNormalContent('New signups in last 7 days', formatNumString(data?.userData?.usersAddedThisWeek))}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Total Users', '')}
                <CircularProgress color="primary" />
              </div>
            )}
            <div className={classes.tableColumnContainer}>
              {data?.googleAnalyticsData ? (
                <div className={classes.coloredTableColumn}>
                  {renderBoldContent(`Visitors' Activities`, '')}
                  {renderNormalContent(
                    'Searches on Header',
                    formatNumString(
                      filterArray(
                        data?.googleAnalyticsData?.specificEvents,
                        (item) => Object.keys(item)[0] === 'Header Search',
                        'Header Search',
                      ),
                    ),
                  )}
                  {renderNormalContent(
                    'Searches on Homepage',
                    formatNumString(
                      filterArray(
                        data?.googleAnalyticsData?.specificEvents,
                        (item) => Object.keys(item)[0] === 'Homepage Search',
                        'Homepage Search',
                      ),
                    ),
                  )}
                  {renderNormalContent(
                    'Viewed property',
                    formatNumString(
                      filterArray(
                        data?.googleAnalyticsData?.specificEvents,
                        (item) => Object.keys(item)[0] === 'Property Inner Page',
                        'Property Inner Page',
                      ),
                    ),
                  )}
                  {renderNormalContent(
                    'Emailed the agent',
                    formatNumString(
                      filterArray(
                        data?.googleAnalyticsData?.specificEvents,
                        (item) => Object.keys(item)[0] === 'Property Card Click Contact Agent',
                        'Property Card Click Contact Agent',
                      ),
                    ),
                  )}
                  {renderNormalContent(
                    'Called the agent',
                    formatNumString(
                      filterArray(
                        data?.googleAnalyticsData?.specificEvents,
                        (item) => Object.keys(item)[0] === 'Property Card Click Contact Agent',
                        'Property Card Click Contact Agent',
                      ),
                    ),
                  )}
                  {renderNormalContent(
                    'Sent SMS to the agent',
                    formatNumString(
                      filterArray(
                        data?.googleAnalyticsData?.specificEvents,
                        (item) => Object.keys(item)[0] === 'Property Card Click Contact Agent',
                        'Property Card Click Contact Agent',
                      ),
                    ),
                  )}
                  {renderNormalContent(
                    'Signed up',
                    formatNumString(
                      filterArray(
                        data?.googleAnalyticsData?.specificEvents,
                        (item) => Object.keys(item)[0] === 'Buyer Signup',
                        'Buyer Signup',
                      ),
                    ),
                  )}
                </div>
              ) : (
                <div className={classes.coloredTableColumn}>
                  {renderBoldContent(`Visitors' Activities`, '')}
                  <CircularProgress color="primary" />
                </div>
              )}
            </div>
          </div>
          <div className={classes.tableColumnContainer}>
            {data?.googleAnalyticsData ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('My Perfect Property Search', '')}
                {renderNormalContent(
                  'Likes',
                  formatNumString(
                    filterArray(
                      data?.googleAnalyticsData?.userBehavior,
                      (item) => Object.keys(item)[0] === 'Add to Likes',
                      'Add to Likes',
                    ),
                  ),
                )}
                {renderNormalContent(
                  'Dislikes',
                  formatNumString(
                    filterArray(
                      data?.googleAnalyticsData?.userBehavior,
                      (item) => Object.keys(item)[0] === 'Add to Dislikes',
                      'Add to Dislikes',
                    ),
                  ),
                )}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('My Perfect Property Search', '')}
                <CircularProgress color="primary" />
              </div>
            )}
            {data?.googleAnalyticsData ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Top 5 Traffic Sources', '')}
                {data?.googleAnalyticsData?.trafficSource
                  .sort((a, b) => b - a)
                  .slice(0, 5)
                  .map((source) =>
                    renderNormalContent(
                      capitalizeName(`${Object.keys(source)[0]}`),
                      formatNumString(Object.values(source)[0]),
                    ),
                  )}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Top 5 Traffic Sources', '')}
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAllProperties = () => {
    return (
      <div className={classes.tableContainer}>
        <Typography className={classes.title} variant={'h1'}>
          {'Properties'}
        </Typography>
        <div className={classes.tableRowContainer}>
          <div className={classes.tableColumnContainer}>
            {data?.propertyDataOverview ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Overview', '')}
                {renderNormalContent(
                  'Total no. of Properties',
                  formatNumString(data?.propertyDataOverview?.propertiesCount),
                )}
                {renderNormalContent(
                  'Properties added today',
                  formatNumString(data?.propertyDataOverview?.propertiesAddedToday),
                )}
                {renderNormalContent(
                  'Properties in last 7 days',
                  formatNumString(data?.propertyDataOverview?.propertiesAddedThisWeek),
                )}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Overview', '')}
                <CircularProgress color="primary" />
              </div>
            )}
            {data?.propertyDataTypes ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Properties by Type', '')}
                {renderNormalContent('On sale', formatNumString(data?.propertyDataTypes?.propertiesForSale))}
                {renderNormalContent('Rental', formatNumString(data?.propertyDataTypes?.propertiesForRent))}
                {renderNormalContent('Sold', formatNumString(data?.propertyDataTypes?.propertiesSold))}
                {renderNormalContent('Leased', formatNumString(data?.propertyDataTypes?.propertiesLeased))}
                {renderNormalContent('Coming Soon', formatNumString(data?.propertyDataTypes?.propertiesComingSoon))}
                {renderNormalContent('Off-Market', formatNumString(data?.propertyDataTypes?.propertiesOffMarket))}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Properties by Type', '')}
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
          <div className={classes.tableColumnContainer}>
            {data?.propertyDataTypes ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('On Sale Properties by Type', '')}
                {renderNormalContent('House', formatNumString(data?.propertyDataTypes?.housePropertiesForSale))}
                {renderNormalContent('Townhouse', formatNumString(data?.propertyDataTypes?.townHousePropertiesForSale))}
                {renderNormalContent('Apartment', formatNumString(data?.propertyDataTypes?.apartmentPropertiesForSale))}
                {renderNormalContent('Land', formatNumString(data?.propertyDataTypes?.landPropertiesForSale))}
                {renderNormalContent('Rural', formatNumString(data?.propertyDataTypes?.ruralPropertiesForSale))}
                {renderNormalContent(
                  'Retirement',
                  formatNumString(data?.propertyDataTypes?.retirementPropertiesForSale),
                )}
                {renderNormalContent('Villa', formatNumString(data?.propertyDataTypes?.villaPropertiesForSale))}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('On Sale Properties by Type', '')}
                <CircularProgress color="primary" />
              </div>
            )}
            {data?.propertyDataStates ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Total Coming Soon', '')}
                {data?.propertyDataStates?.propertiesComingSoonByState.map((item) =>
                  renderNormalContent(
                    filterArray(STATES_LABEL, (state) => state.id === item.state, 'label'),
                    formatNumString(item.count),
                  ),
                )}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Total Coming Soon', '')}
                <CircularProgress color="primary" />
              </div>
            )}
            {data?.propertyDataStates ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Total Off Market', '')}
                {data?.propertyDataStates?.propertiesOffMarketByState.map((item) =>
                  renderNormalContent(
                    filterArray(STATES_LABEL, (state) => state.id === item.state, 'label'),
                    `${item.count}`,
                  ),
                )}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Total Off Market', '')}
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
          <div className={classes.tableColumnContainer}>
            {data?.propertyDataStates ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('On Sale Properties by State', '')}
                {data?.propertyDataStates?.propertiesForSaleByState.map((item) =>
                  renderNormalContent(
                    filterArray(STATES_LABEL, (state) => state.id === item.state, 'label'),
                    formatNumString(item.count),
                  ),
                )}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('On Sale Properties by State', '')}
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderMostSearchedItems = () => {
    return (
      <div className={classes.tableContainer}>
        <Typography className={classes.title} variant={'h1'}>
          {'Most Searched Items'}
        </Typography>
        <div className={classes.tableRowContainer}>
          <div className={classes.tableColumnContainer}>
            {data?.googleAnalyticsData ? (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Top 10 Searched Suburbs', '')}
                {data?.googleAnalyticsData?.searchedSuburbs
                  .sort((a, b) => b - a)
                  .slice(0, 10)
                  .map((source) =>
                    renderNormalContent(`${Object.keys(source)[0]}`, formatNumString(Object.values(source)[0])),
                  )}
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Top 10 Searched Suburbs', '')}
                <CircularProgress color="primary" />
              </div>
            )}
            <div className={classes.coloredTableColumn}>
              {renderBoldContent('Top 10 Price Points', '')}
              {renderNormalContent('$10,000,000+', 'Not Yet Available')}
              {renderNormalContent('$8,000,000 - $10,000,000', 'Not Yet Available')}
              {renderNormalContent('$6,000,000 - $8,000,000', 'Not Yet Available')}
              {renderNormalContent('$4,000,000 - $6,000,000', 'Not Yet Available')}
              {renderNormalContent('$2,000,000 - $4,000,000', 'Not Yet Available')}
            </div>
          </div>
          <div className={classes.tableColumnContainer}>
            {data?.googleAnalyticsData ? (
              <div
                style={{
                  borderRadius: 10,
                  flexDirection: 'column',
                  padding: 20,
                  backgroundColor: 'white',
                  marginTop: 20,
                }}
              >
                {renderBoldContent('Top 50 Viewed Properties', '')}
                <div
                  style={{
                    width: 800,
                    marginTop: 20,
                    maxHeight: '700px',
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#888 transparent',
                  }}
                >
                  <TableContainer>
                    <Table className={classes.table} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Address</StyledTableCell>
                          <StyledTableCell align="right">Price</StyledTableCell>
                          <StyledTableCell align="right">No. of Views</StyledTableCell>
                          <StyledTableCell align="right">Status</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.googleAnalyticsData?.topProperties.map((item, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell component="th" scope="row">
                              {Object.keys(item)[0]}
                            </StyledTableCell>
                            <StyledTableCell align="right">{item.price || 'N/A'}</StyledTableCell>
                            <StyledTableCell align="right">{Object.values(item)[0]}</StyledTableCell>
                            <StyledTableCell align="right">{item.propertyStatus}</StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            ) : (
              <div className={classes.coloredTableColumn}>
                {renderBoldContent('Top 50 Viewed Properties', '')}
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs section={'Reports'} crumbsLinks={CRUMBS} />
      <div className={classes.buttonContainer}>
        {renderAllProperties()}
        {renderMostSearchedItems()}
        {renderAnalytics()}
      </div>
      {/* <Loader isLoading={loading} /> */}
      <div className={classes.bottomSpace} />
    </div>
  );
};
export default withStyles(styles)(SuperadminDashboard);
