export function getStatus(state) {
  return state.agencyv2.status;
}

export function getErrors(state) {
  return state.agencyv2.error;
}

export function getSuccessPayload(state) {
  return state.agencyv2.payload_success;
}

export function getProperties(state) {
  return state.agencyv2.dashboardproperties;
}

export function getUser(state) {
  return state.users.user;
}

export function getAgency(state) {
  return state.users.agency;
}

export function getAgencyAdmin(state) {
  return state.users.agency_admin;
}

export function getAuthentication(state) {
  return state.authentication;
}

export function getAgencyUser(state) {
  return state.agencyv2.agencyuser?.data;
}

export function getPropertyDetails(state) {
  return state.property.properties_details;
}

export function getAllAgents(state) {
  return state.agencyv2.agency_agents;
}

export function checkEmailAvailable(state) {
  return state.agencyv2.payload_success;
}

export function getAgentDetails(state) {
  return state.agencyv2.current_agent;
}

export function getAvatarUpload(state) {
  return state.agencyv2.avatar_upload;
}

export function getAgencyUpload(state) {
  return state.agencyv2.agency_upload_image;
}

export function getCurrentAgency(state) {
  return state.agencyv2.current_agency;
}

export function getAgencyRegistration(state) {
  return state.agencyv2.agency_register;
}
export function getAgencyRegistrationContactAdmin(state) {
  return state.agencyv2.agency_contact_admin;
}

export function getCurrentAgencyDeleteProperty(state) {
  return state.agencyv2.agency_delete_property;
}

export function getDashboardProperties(state) {
  return state.agencyv2.dashboardproperties;
}

export function getAllAdmins(state) {
  return state.agencyv2.agency_admins?.admins_list;
}

export function getSingleAdmin(state) {
  return state.agencyv2.agency_admins.current_admin?.entities?.[0];
}

export function getAdminStatus(state) {
  return state.agencyv2.agency_admins?.status;
}

export function getAdminErrors(state) {
  return state.agencyv2.agency_admins?.error;
}

export function getAdminSuccess(state) {
  return state.agencyv2.agency_admins?.payload_success;
}

export function getCreatedProperty(state) {
  return state.agencyv2.agency_create_property?.response?.data;
}

export function getCreatedPropertyStatus(state) {
  return state.agencyv2.agency_create_property?.status;
}

export function getApplyPromoCodeSuccess(state) {
  return state.agency.request_apply_promo_code;
}

export function getApplyPromoCodeStatus(state) {
  return state.agency.request_apply_promo_code_status;
}

export function getApplyPromoCodeError(state) {
  return state.agency.request_apply_promo_code_error;
}
