import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function PIconColor(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill="url(#paint0_linear)"
        d="M12 0C5.4 0 0 5.143 0 11.429V30s3.6-1 5.4-3.143A8.194 8.194 0 007.5 22c1.35.571 2.85.857 4.5.857 6.6 0 12-5.143 12-11.428C24 5.143 18.6 0 12 0zm4.5 15.143h-9V9.286L12 6.143l4.5 3.143v5.857z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        d="M16.8 15H7.65v7c1.35.571 2.85.857 4.5.857 3.45 0 6.6-1.428 8.7-3.714L16.8 15z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="17.485"
          x2="0.072"
          y1="5.036"
          y2="27.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.612" stopColor="#37BFC9"></stop>
          <stop offset="0.714" stopColor="#5DC6CA"></stop>
          <stop offset="0.809" stopColor="#7DCCCA"></stop>
          <stop offset="0.935" stopColor="#B2DED8"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="15.913"
          x2="3.013"
          y1="18.995"
          y2="18.979"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.183" stopColor="#37BFC9"></stop>
          <stop offset="0.248" stopColor="#34B4BE"></stop>
          <stop offset="0.363" stopColor="#2E96A1"></stop>
          <stop offset="0.516" stopColor="#236572"></stop>
          <stop offset="0.695" stopColor="#142332"></stop>
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}

export function PIconColorSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PIconColor />
      <PIconColor color="primary" />
      <PIconColor color="secondary" />
      <PIconColor color="action" />
      <PIconColor color="disabled" />
      <PIconColor style={{ color: green[500] }} />
    </div>
  );
}
