import Home from '../../containers/Mobile/Home';
import AgencyStandalonePayment from 'containers/Mobile/Agency/AgencyStandalonePayment';

let routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/agency/payment-details/:token',
    component: AgencyStandalonePayment,
  },
  {
    path: '/agency/payment-details/',
    component: AgencyStandalonePayment,
  },
];

export default routes;
