import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, Box, Typography, withStyles, Grid } from '@material-ui/core';
import logo from '../../../assets/images/pm-logo-inverted.png';
import moment from 'moment';

const styles = () => ({
  title: {
    fontWeight: 600,
    fontSize: '16px',
    color: 'black',
  },
  labelText: {
    fontWeight: 600,
    fontSize: '14px',
    color: 'black',
  },
  labelContent: {
    fontWeight: 400,
    fontSize: '14px',
    color: 'black',
  },
  headerText: {
    fontSize: '20px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
  detailsBox: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  separatorLine: {
    display: 'flex',
    width: '100%',
    height: '2px',
    backgroundColor: 'rgb(153,214,220)',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 30,
  },
  spacer: {
    height: 30,
  },
  imageLogo: {
    width: 'auto',
    height: 50,
  },
  docusignButton: {
    width: 200,
    height: 50,
    marginLeft: 5,
    borderRadius: 40,
    backgroundColor: '#fdbd26',
    borderColor: '#fdbd26',
    '&:hover': {
      backgroundColor: 'rgba(253, 189, 38, 0.8)',
    },
  },
});

const DocusignPreviewDialog = ({ isOpen, content, onCloseDialog, onSendDocusign, classes }) => {
  const agencyDetails = [
    { label: 'Company Name', text: content?.companyName },
    { label: 'Registered Trading Name', text: content?.registeredTradeName },
    { label: 'Principal Name', text: content?.contactPrincipalName },
    { label: 'Principal Email', text: content?.contactPrincipalEmail },
    { label: 'Accounts Payable Name', text: content?.contactAccountPayableName },
    { label: 'Accounts Payable Email', text: content?.contactAccountPayableEmail },
    { label: 'Company Real Estate Licence Number', text: content?.realEstateLicenseNumber },
    { label: 'ABN', text: content?.abn },
    { label: 'Office Address', text: content?.address },
    { label: 'Billing Address', text: content?.contactBillingAddress },
    { label: 'Office Phone Number', text: content?.businessNumber },
    { label: 'Office Email', text: content?.email },
    { label: 'Rental Listing Uploader', text: content?.crmRental },
    { label: 'Sales Listing Uploader', text: content?.crmSales },
    { label: 'Website', text: content?.companyWebsite },
  ];

  const advertisingParticulars = [
    {
      label: 'Billing and Subscription Start Date',
      text: moment(content?.subscription?.billingDateString).format('DD MMMM, YYYY'),
    },
  ];

  const term = content?.subscription?.type ? `${content?.subscription?.type || ''}`.split('-')[1].toLowerCase() : '';
  const cost = content?.subscription?.package
    ? content?.subscription?.package?.label.split(' ')[2].split('/')[0]
    : '0.00';
  const subscription = [
    { label: 'Monthly/Annual Subscription', text: term === 'monthly' ? 'Monthly' : 'Annual' },
    { label: 'Subscription Payment Term', text: term === 'monthly' ? 'Paid monthly' : 'Payable upfront in full' },
    {
      label: 'Subscription Cost',
      text: `${cost} + GST per ${term === 'monthly' ? 'month' : 'annum'}`,
    },
  ];

  const coreProducts = [
    {
      value: 'ENTRY-MONTHLY',
      items: [
        { label: 'Sales', text: 'Entry Listing - $0 + GST' },
        { label: 'Rentals', text: 'Entry Listing - No Charge' },
      ],
    },
    {
      value: 'ENTRY-YEARLY',
      items: [
        { label: 'Sales', text: 'Entry Listing - $0 + GST' },
        { label: 'Rentals', text: 'Entry Listing - No Charge' },
      ],
    },
    {
      value: 'EXTENDED-MONTHLY',
      items: [
        { label: 'Sales', text: 'Extended Listing - $100 + GST' },
        { label: 'Rentals', text: 'Extended Listing - No Charge' },
      ],
    },
    {
      value: 'EXTENDED-YEARLY',
      items: [
        { label: 'Sales', text: 'Extended Listing - $100 + GST' },
        { label: 'Rentals', text: 'Extended Listing - No Charge' },
      ],
    },
    {
      value: 'EXECUTIVE-MONTHLY',
      items: [
        { label: 'Sales', text: 'Executive Listing - $250 + GST' },
        { label: 'Rentals', text: 'Executive Listing - No Charge' },
      ],
    },
    {
      value: 'EXECUTIVE-YEARLY',
      items: [
        { label: 'Sales', text: 'Executive Listing - $250 + GST' },
        { label: 'Rentals', text: 'Executive Listing - No Charge' },
      ],
    },
    {
      value: 'ELITE-MONTHLY',
      items: [
        { label: 'Sales', text: 'Elite Listing - $450 + GST' },
        { label: 'Rentals', text: 'Elite Listing - No Charge' },
      ],
    },
    {
      value: 'ELITE-YEARLY',
      items: [
        { label: 'Sales', text: 'Elite Listing - $450 + GST' },
        { label: 'Rentals', text: 'Elite Listing - No Charge' },
      ],
    },
  ];

  const renderAgencyDetails = () => {
    return (
      <div className={classes.detailsBox}>
        <Typography variant={'subtitle1'}>Agency Detail</Typography>
        <div className={classes.separatorLine} />
        {agencyDetails.map((item) => renderText(item.label, item.text))}
      </div>
    );
  };

  const renderAdvertisingParticulars = () => {
    return (
      <div className={classes.detailsBox}>
        <Typography variant={'subtitle1'}>Advertising Particulars</Typography>
        <div className={classes.separatorLine} />
        {advertisingParticulars.map((item) => renderText(item.label, item.text))}
      </div>
    );
  };

  const renderSubscription = () => {
    return (
      <div className={classes.detailsBox}>
        <Typography variant={'subtitle1'}>Subscription</Typography>
        <div className={classes.separatorLine} />
        {subscription.map((item) => renderText(item.label, item.text))}
      </div>
    );
  };

  /*const renderCoreProducts = () => {
    return (
      <div className={classes.detailsBox}>
        <Typography variant={'subtitle1'}>Core Products</Typography>
        <div className={classes.separatorLine} />
        {coreProducts
          .filter((item) => item.value === content?.subscription?.type)[0]
          .items.map((item) => renderText(item?.label, item?.text))}
      </div>
    );
  };*/

  const renderCoreProducts = () => {
    const selectedProduct = coreProducts.find((item) => item.value === content?.subscription?.type);

    if (!selectedProduct) return null;

    const renderedItems = selectedProduct.items.map((item) => renderText(item?.label, item?.text));

    return (
      <div className={classes.detailsBox}>
        <Typography variant={'subtitle1'}>Core Products</Typography>
        <div className={classes.separatorLine} />
        {renderedItems}
      </div>
    );
  };

  const renderNote = () => {
    return (
      <div className={classes.detailsBox}>
        <Typography variant={'subtitle1'}>Note</Typography>
        <div className={classes.separatorLine} />
        {content?.docusignNote}
      </div>
    );
  };

  const renderText = (label, text) => {
    return (
      <div className={classes.textContainer}>
        <Typography className={classes.labelText} variant={'subtitle1'}>
          {label}
        </Typography>
        <Typography className={classes.labelContent} variant={'subtitle1'}>
          {text}
        </Typography>
      </div>
    );
  };

  return (
    <Dialog open={isOpen} keepMounted aria-describedby="alert-dialog-slide-description">
      <DialogActions>
        <Button onClick={() => onCloseDialog(false)}>CLOSE</Button>
      </DialogActions>
      <DialogContent>
        <Box sx={{ border: 1 }}>
          <Grid container item justify="space-between">
            <img className={classes.imageLogo} src={logo} />
            <Typography className={classes.headerText} variant={'subtitle1'}>
              Advertising Agreement Particulars
            </Typography>
          </Grid>
          <div className={classes.spacer} />
          {renderAgencyDetails()}
          {renderAdvertisingParticulars()}
          {renderSubscription()}
          {renderCoreProducts()}
          {renderNote()}
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container item justify="center" marginBottom={20}>
          <Button
            className={classes.docusignButton}
            variant="contained"
            disableElevation
            onClick={() => onCloseDialog(false)}
          >
            EDIT
          </Button>
          <Button className={classes.docusignButton} variant="contained" disableElevation onClick={onSendDocusign}>
            SEND DOCUSIGN
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(DocusignPreviewDialog);
