import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { AuthImages } from 'components/Web/Superadmin'
import AgentActivateForm from 'components/Web/Agent/AgentActivateForm'
import AlertNotification from 'components/Web/Form/AlertNotification'
import AccountActivationDialog from 'components/Web/DialogContainer/AccountActivationDialog'
import { Loader } from 'components/Common'

/** actions */
import { clearAgentStatus } from 'store/actions'
import { requestAgentState } from 'store/api'
import {
  getAgentState,
  getAgentStateStatus,
  getAgentActivateStatus,
} from 'store/selectors/agent.selectors'

const styles = () => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    height: '100vh',
    padding: 40,
    paddingLeft: '10vw',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const notificationMessages = {
  failed: {
    severity: 'error',
    message: 'Agent not found, please check with your agency for your account.',
  },
  notActivated: {
    severity: 'success',
    message: 'Agent found, please proceed on your account activation.',
  },
  failedActivate: {
    severity: 'error',
    message: 'Failed to activate agent. Please contact admin for support.',
  },
  activated: {
    severity: 'success',
    message: 'Agent already active, going to login page.',
  },
}

const AgentActivate = (props) => {
  const { classes } = props

  /** State */
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const agentId = props.location.pathname.split('/')[3]

  /** Redux */
  const dispatch = useDispatch()
  const AGENT_STATE = useSelector(getAgentState)
  const AGENT_STATE_STATUS = useSelector(getAgentStateStatus)
  const AGENT_ACTIVATE = useSelector(getAgentActivateStatus)

  const apiGetAgentState = (id) => {
    setLoading(true)
    const params = { user: id }
    dispatch(requestAgentState(params))
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const clearAgentStatusRedux = () => {
    dispatch(clearAgentStatus())
  }

  const handleNotification = (showNotification, options) => {
    setIsNotification(showNotification)
    setNotificationOptions(options)
  }

  const closeDialog = () => {
    setOpenDialog(false)
  }

  useEffect(() => {
    apiGetAgentState(agentId)
    return () => {
      clearAgentStatusRedux()
    }
  }, [])

  useEffect(() => {
    if (AGENT_STATE_STATUS === 'failed') {
      handleNotification(true, notificationMessages.failed)
      setLoading(false)
    }
    if (AGENT_STATE_STATUS === 'success') {
      setLoading(false)
    }
    if (AGENT_ACTIVATE === 'failed') {
      handleNotification(true, notificationMessages.failedActivate)
      setLoading(false)
    }
    if (AGENT_ACTIVATE === 'success') {
      setOpenDialog(true)
      setLoading(false)
    }
    if (AGENT_STATE === 'NONE') {
      handleNotification(true, notificationMessages.notActivated)
      setLoading(false)
    }
    if (AGENT_STATE === 'ACTIVATED') {
      setOpenDialog(true)
      setLoading(false)
    }
  }, [AGENT_STATE, AGENT_STATE_STATUS, AGENT_ACTIVATE])

  return (
    <div className={classes.content}>
      <Loader isLoading={loading} />
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <AccountActivationDialog
        isOpen={openDialog}
        closeCallBack={closeDialog}

        title={'Finito!'}
        message={'Activation complete'}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={5} className={classes.leftColumn}>
          <AgentActivateForm
            agentId={agentId}
            type={'agent'}

            setLoading={setLoading}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={7} className={classes.rightColumn}>
          <AuthImages type={'login'} />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(AgentActivate)
