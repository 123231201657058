import React, { useState, useEffect } from 'react'

import Close from '@material-ui/icons/Close'
import HeartIcon from '@material-ui/icons/Favorite'
import {
  withStyles,
  Modal,
  Typography,
  IconButton,
  Box,
  Button,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import BedIcon from '@material-ui/icons/Hotel'
import ParkingIcon from '@material-ui/icons/DriveEta'
import { useDispatch, useSelector } from 'react-redux'

import SavedInput from './SavedInput'
import SavedButton from './SavedButton'
import AlertNotification from '../Form/AlertNotification'
import CustomCheckbox from '../Filter/CustomCheckbox'

import {
  getSavedSearchRequestStatus,
  getSavedSearchRequestError,
} from 'store/selectors/propertyv2.selectors'
import { useCheckIsUserAuthenticated } from 'utils/customHooks/useCheckIsUserAuthenticated'
import useFilters from 'utils/customHooks/useFilters'
import { formatUSD } from 'utils/formatter/priceFormatter'
import DynamicIcons from '../DynamicIcons'
import { requestPropertySaveSearch } from 'store/api'
import { getAllFilters } from 'utils/search/selectors/selectors.search'
import { requestSavedSearchClear } from 'store/actions'
import { useRouterChange } from 'utils/router.utils'

import styled, { css } from 'styled-components'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    width: '35vw',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },

    [theme.breakpoints.down(1024)]: {
      width: '80%',
    },

    [theme.breakpoints.down(600)]: {
      width: '90%',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: '#000',
  },

  // Saved Search Button
  optionButton: {
    textTransform: 'none',
    // width: '10.500em',
    minWidth: '50px',
    borderRadius: '2.250em',
    color: '#000',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 1,
    marginRight: 10,
    padding: '9px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',

    [theme.breakpoints.down(1024)]: {
      padding: '5px 15px',
    },
  },

  optionIcon: {
    [theme.breakpoints.down(1366)]: {
      marginLeft: '-8px',
    },
  },

  // Saved Search Info
  icon: {
    color: '#000',
    width: 18,
    height: 18,
    marginRight: 3,
  },
}))

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
  },
})(Typography)

const CustomCheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  label {
    margin:0;
  }
  span {
    white-space: normal;
    top: -10px;
    padding-left: 0;
  }
`

const SavedSearchInfo = ({ notify, setNotify ,path }) => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const SUBURBS = getFilter('suburbs')
  const INCLUDE_NEARBY_SUBURBS = getFilter('include_nearby_suburbs')

  const PRICE = getFilter('price')
  const RENT_PRICE = getFilter('price_rent')

  const BEDROOM = getFilter('bedroom')
  const BATHROOM = getFilter('bathroom')
  const PARKING = getFilter('carparking')

  const priceText =
    path === 'rent'
      ? `${formatUSD(RENT_PRICE.min)} - ${formatUSD(RENT_PRICE.max)}`
      : `${formatUSD(PRICE.min)} - ${formatUSD(PRICE.max)}`

  const suburbArray = SUBURBS.map((suburb) => suburb.name)
  const suburbText = `${suburbArray.join(', ')} ${
    INCLUDE_NEARBY_SUBURBS ? 'including surrounding suburbs.' : ''
  }`

  return (
    <Box padding="16px" display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row">
        <Box fontSize="16px" marginRight="20px">
          <Box fontWeight="bold" style={{ textTransform: 'capitalize' }}>
            Suburbs:
          </Box>
          <Box 
            fontWeight={400}
            lineHeight={1.43}
            fontSize="16px"
            style={{
              textTransform: 'normal',
              color: '#0000008a'
            }}
          >
            {suburbText}
          </Box>
        </Box>
        <Box fontSize="16px" marginRight="20px">
          <Box fontWeight="bold" style={{ textTransform: 'capitalize' }}>
            Filters:
          </Box>
          <Box
            fontWeight="400"
            style={{
              textTransform: 'capitalize',
              color: '#0000008a'
            }}
          >
            {path}
          </Box>
          <Box marginTop="5px" whiteSpace="nowrap" style={{ color: '#0000008a' }}>
            {priceText}
          </Box>

          <Box whiteSpace="nowrap">
            <Tooltip title="Bedrooms">
              <IconButton disableRipple>
                <BedIcon className={classes.icon} />
                <Typography variant="caption" color="textSecondary">
                  {`${BEDROOM.value}${BEDROOM.exact ? '' : '+'}`}
                </Typography>
              </IconButton>
            </Tooltip>
            <Tooltip title="Bathrooms">
              <IconButton disableRipple>
                <DynamicIcons className={classes.icon} icon="2DCBathIcon" />
                <Typography variant="caption" color="textSecondary">
                  {`${BATHROOM.value}${BATHROOM.exact ? '' : '+'}`}
                </Typography>
              </IconButton>
            </Tooltip>
            <Tooltip title="Parking">
              <IconButton disableRipple>
                <ParkingIcon className={classes.icon} />
                <Typography variant="caption" color="textSecondary">
                  {`${PARKING.value}${PARKING.exact ? '' : '+'}`}
                </Typography>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        fontWeight="400"
        style={{ color: '#0000008a'}}
      >
        <Box fontSize="16px">
          <CustomCheckboxWrap>
            <CustomCheckbox
              label={'Email me perfect properties that match my saved search (modify notifications in Account Settings).'}
              checked={notify ?? false}
              onChange={(e) => {
                setNotify(!notify)
              }}
            />
          </CustomCheckboxWrap>
        </Box>
      </Box>
    </Box>
  )
}

const SavedSearch = ({ path }) => {
  const [routerChange] = useRouterChange()
  const classes = useStyles()

  const ALL_GLOBAL_FILTERS = useSelector(getAllFilters)
  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(false)
  const [savedTitle, setSavedTitle] = useState('')
  const [isError, setIsError] = useState(false)
  const [notify, setNotify] = useState(false)

  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)

  const SAVE_STATUS = useSelector(getSavedSearchRequestStatus)
  const SAVE_ERROR = useSelector(getSavedSearchRequestError)

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  const isAuthenticated = useCheckIsUserAuthenticated()
  const saveSearchButtonSmallVersion = window?.innerWidth < 1366

  const handleButtonClick = () => {
    if (isAuthenticated) {
      /** User Logged */
      setOpenModal(true)
    } else {
      routerChange('/login', { origin: window?.location?.pathname })
    }
  }

  useEffect(() => {
    if (isNotification.status) {
      dispatch(requestSavedSearchClear())
    }

    return () => dispatch(requestSavedSearchClear())
  }, [isNotification])

  useEffect(() => {
    if (SAVE_STATUS === 'success') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Search criteria successfully saved.',
        },
      }
      setIsNotification(options)
    }
    if (SAVE_ERROR === 'error') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error saving search criteria. Please try again.',
        },
      }
      setIsNotification(options)
    }
  }, [SAVE_STATUS, SAVE_ERROR])

  useEffect(() => {
    if (savedTitle.length > 0) {
      setIsError(false)
    }
  }, [savedTitle])

  async function apiRequestSaveSearch(params) {
    let request = {
      name: params.name,
      searchType: path,
      parameters: { ...ALL_GLOBAL_FILTERS, reference_filters: null },
      notifyUser: notify,
    }

    dispatch(requestPropertySaveSearch(request))
  }

  function handleSubmitSaveSearch() {
    let params = {
      name: savedTitle,
    }
    if (savedTitle === '') {
      setIsError(true)
    } else {
      setIsError(false)
      setSavedTitle('')
      setOpenModal(false)
      setNotify(false)
      apiRequestSaveSearch(params)
    }
  }

  return (
    <Box>
      <Tooltip
        disableHoverListener={saveSearchButtonSmallVersion ? false : true}
        title="Save Search"
      >
        <Button
          className={classes.optionButton}
          size={'small'}
          color={'primary'}
          variant={'outlined'}
          endIcon={<HeartIcon className={classes.optionIcon} />}
          style={{ marginRight: 0, marginLeft: 0 }}
          onClick={() => handleButtonClick()}
        >
          {saveSearchButtonSmallVersion ? '' : 'Save Search'}
        </Button>
      </Tooltip>

      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}

      {openModal && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.root}>
            <div className={classes.rowContainer}>
              <StyledText variant="h2">Saved Search</StyledText>
              <IconButton
                className={classes.closeButton}
                component="span"
                onClick={() => setOpenModal(false)}
              >
                <Close />
              </IconButton>
            </div>
            <SavedInput
              savedTitle={savedTitle}
              setSavedTitle={setSavedTitle}
              isError={isError}
            />
            <SavedSearchInfo
              notify={notify}
              setNotify={setNotify}
              path={path}
            />
            {/* <SavedPropertyInfo path={path} filters={filters} suburbs={suburbs} /> */}
            <SavedButton
              handleClose={() => setOpenModal(false)}
              isError={isError}
              handleSubmitSaveSearch={handleSubmitSaveSearch}
            />
          </div>
        </Modal>
      )}
    </Box>
  )
}

export default SavedSearch
