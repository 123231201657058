import React from 'react'
import { Link } from 'react-router-dom'

import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'

import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  getAgencyHexByAgencyId,
  DEFAULT_AGENCIES,
  HEX_OBJECT,
} from '../../../constants/constants'
import { Box, withStyles, Typography, Avatar } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '40px',
    maxHeight: theme.spacing(5),
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0.625, 1),
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarText: {
    color: '#FFF',
    textAlign: 'right',
    marginRight: '0.714em',
    marginLeft: '0.714em',
    lineHeight: 1.2,
  },
  avatarImage: {
    width: '3.286em',
    height: '3.286em',
    position: 'relative',
    bottom: '18px',
    border: '1px solid #FFF',
    backgroundColor: '#bdbdbd',
  },
  avatarImageProperty: {
    width: '3.286em',
    height: '3.286em',
    position: 'relative',
    bottom: 0,
    border: '1px solid #FFF',
  },
  agentLogo: {
    maxHeight: theme.spacing(3.125),
    width: 'auto',
    maxWidth: '40%',
    backgroundColor: 'transparent',
    imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    // imageRendering: 'pixelated',
    imageRendering: '-webkit-optimize-contrast',
  },
})

const DreamPropertiesAgentBanner = (props) => {
  const { classes, showBanner, showAgentAvatar, properties, type } = props

  const AgentImage = (img) => {
    if (img === 'http://lorempixel.com/200/200/people/9/') {
      return null
    } else {
      if (img) {
        const imgsplit = img.split('image/upload', 2)

        return imgsplit.length === 2
          ? imgsplit[0] + 'image/upload/f_webp,q_auto/c_fill,g_face,h_300,w_300' + imgsplit[1]
          : img
      }
      return img
    }
  }

  const checkIfLight = (color) => {
    const hex = color.replace('#', '')
    const c_r = parseInt(hex.substr(0, 2), 16)
    const c_g = parseInt(hex.substr(2, 2), 16)
    const c_b = parseInt(hex.substr(4, 2), 16)
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
    return brightness > 155
  }

  const logoObject = LOGO_OBJECT,
    hexObject = HEX_OBJECT

  const getAgencyLogo = (agencyName) => {
    if (
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return LogoPlaceholder
    }
  }

  const getAgencyHex = (agencyName) => {
    if (
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return '#000000'
    }
  }

  const brandColor =
    properties.agency && properties.agency.brandColor
      ? properties.agency.brandColor
      : getAgencyHex(properties.reaAgencyId || properties.agency.reaAgencyId)

  function renderAgentBannerResultScreen() {
    return (
      <>
        {showBanner ? (
          <div
            className={classes.root}
            style={{
              backgroundColor: brandColor,
              boxShadow: checkIfLight(brandColor) ? '0 0 0 1px #000000' : 'none',
            }}
          >
            <img
              className={classes.agentLogo}
              src={
                properties.agency && properties.agency.logoUrl
                  ? properties.agency.logoUrl
                  : getAgencyLogo(
                      properties.reaAgencyId || properties.agency.reaAgencyId
                    )
              }
            />
            {showAgentAvatar && properties.agent && (
              <div className={classes.avatarContainer}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: properties.agent.name }}
                  className={classes.avatarText}
                  variant="caption"
                  component="p"
                  style={{ color: checkIfLight(brandColor) ? '#000000' : '#ffffff' }}
                ></Typography>
                <Avatar
                  className={classes.avatarImage}
                  src={AgentImage(properties.agent.image)}
                />
              </div>
            )}
          </div>
        ) : (
          <Box height="40px" />
        )}
      </>
    )
  }

  function renderAgentBannerPropertyScreen() {
    return (
      <>
        {showBanner && (
          <div
            className={classes.root}
            style={{
              backgroundColor: getAgencyHex(
                properties.reaAgencyId || properties.agency.reaAgencyId
              ),
            }}
          >
            <img
              style={{ maxWidth: '220px' }}
              className={classes.agentLogo}
              src={
                properties.agency && properties.agency.logoUrl
                  ? properties.agency.logoUrl
                  : getAgencyLogo(
                      properties.reaAgencyId || properties.agency.reaAgencyId
                    )
              }
            />
            {showAgentAvatar && properties.agent && (
              <div className={classes.avatarContainer}>
                <Typography
                  dangerouslySetInnerHTML={{ __html: properties.agent.name }}
                  className={classes.avatarText}
                  variant="caption"
                  component="p"
                ></Typography>
                <Avatar
                  className={classes.avatarImageProperty}
                  src={AgentImage(properties.agent.image)}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {type === 'search' && renderAgentBannerResultScreen()}
      {type === 'property' && renderAgentBannerPropertyScreen()}
    </>
  )
}

export default withStyles(styles)(DreamPropertiesAgentBanner)
