import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function FilterIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M19.4,9.4h-6.8c-0.3-1.2-1.4-2.2-2.6-2.2S7.5,8.1,7.3,9.4H0.7c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h6.6c0.3,1.2,1.4,2.2,2.6,2.2s2.4-0.9,2.6-2.2h6.8c0.3,0,0.6-0.3,0.6-0.6C20,9.7,19.7,9.4,19.4,9.4z M9.9,11.5c-0.8,0-1.5-0.7-1.5-1.6s0.7-1.6,1.5-1.6c0.8,0,1.5,0.7,1.5,1.6S10.8,11.5,9.9,11.5z"/>
      <path d="M2.8,5.7c1.3,0,2.4-0.9,2.6-2.2h13.9c0.3,0,0.6-0.3,0.6-0.6s-0.3-0.6-0.6-0.6H5.5C5.2,1.1,4.1,0.1,2.8,0.1c-1.5,0-2.7,1.3-2.7,2.8S1.3,5.7,2.8,5.7z M2.8,1.4c0.8,0,1.5,0.7,1.5,1.6S3.6,4.5,2.8,4.5S1.3,3.8,1.3,2.9S2,1.4,2.8,1.4z"/>
      <path d="M17.2,14.3c-1.3,0-2.4,0.9-2.6,2.1H0.7c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6h13.9c0.3,1.2,1.4,2.2,2.6,2.2c1.5,0,2.7-1.2,2.7-2.8S18.7,14.3,17.2,14.3z M17.2,18.6c-0.8,0-1.5-0.7-1.5-1.6s0.7-1.6,1.5-1.6c0.8,0,1.5,0.7,1.5,1.6S18,18.6,17.2,18.6z"/>
      <polygon style={{fill: "none"}} points="-2,-2 22,-2 22,22 -2,22 "/>
  </SvgIcon>
  );
}

export function FilterIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FilterIcon />
      <FilterIcon color="primary" />
      <FilterIcon color="secondary" />
      <FilterIcon color="action" />
      <FilterIcon color="disabled" />
      <FilterIcon style={{ color: green[500] }} />
    </div>
  );
}
