import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function PropSearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path style={{fillRule:"evenodd", clipRule:"evenodd", fill:props.fill}} d="M20.3,5.6c1.9,0,1.7,0.8,1.7,2.1v10.4c0,1-0.9,1.9-2,1.9H4c-1.1,0-2-0.8-2-1.9L2,5.9C2,4.8,2.9,4,4,4
        h5.2c0.5,0,1.2,0,1.5,0.6c0.2,0.4,0.3,0.7,0.5,1.1H20.3z M14.9,14h-0.5l-0.2-0.1c0.6-0.6,0.9-1.4,0.9-2.3c0-1.9-1.7-3.5-3.7-3.5
        s-3.7,1.6-3.7,3.5S9.4,15,11.4,15c0.9,0,1.8-0.3,2.4-0.8l0.2,0.1v0.4l2.9,2.7l0.9-0.8L14.9,14z M11.4,14c-1.4,0-2.6-1.1-2.6-2.4
        c0-1.3,1.2-2.4,2.6-2.4c1.4,0,2.6,1.1,2.6,2.4C14,12.9,12.8,14,11.4,14z"/>
    </SvgIcon>
  );
}

export function PropSearchIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PropSearchIcon />
      <PropSearchIcon color="primary" />
      <PropSearchIcon color="secondary" />
      <PropSearchIcon color="action" />
      <PropSearchIcon color="disabled" />
      <PropSearchIcon style={{ color: green[500] }} />
    </div>
  );
}
