import { PURGE } from 'redux-persist'
import { SEARCH_ACTION_TYPES } from 'store/constants/search.constants'

const defaultPaginationObj = {
  pageNumber: 0,
  pageSize: 20,
  propertiesFilters: [],
  totalResults: null,
}

const defaultMppPaginationObj = {
  pageNumber: 0,
  pageSize: 10,
  propertiesFilters: [],
  totalResults: null,
}

const INITIAL_STATE = {
  // temporary values
  payload_request: null,
  payload_success: null,
  payload_failed: null,
  status: null,
  error: null,
  search_results: null,
  pagination: defaultPaginationObj,
  narrowed_results: null,
}

const searchReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_ACTION_TYPES['GET_PROPERTY_SEARCH']['REQUEST']:
    case SEARCH_ACTION_TYPES['POST_PROPERTY_SEARCH']['REQUEST']:
    case SEARCH_ACTION_TYPES['GET_RENTAL_PROPERTY_SEARCH']['REQUEST']:
    case SEARCH_ACTION_TYPES['POST_RENTAL_PROPERTY_SEARCH']['REQUEST']:
    case SEARCH_ACTION_TYPES['GET_SOLD_PROPERTY_SEARCH']['REQUEST']:
    case SEARCH_ACTION_TYPES['POST_SOLD_PROPERTY_SEARCH']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      }

    case SEARCH_ACTION_TYPES['GET_PROPERTY_SEARCH']['SUCCESS']:
    case SEARCH_ACTION_TYPES['POST_PROPERTY_SEARCH']['SUCCESS']:
    case SEARCH_ACTION_TYPES['GET_RENTAL_PROPERTY_SEARCH']['SUCCESS']:
    case SEARCH_ACTION_TYPES['POST_RENTAL_PROPERTY_SEARCH']['SUCCESS']:
    case SEARCH_ACTION_TYPES['GET_SOLD_PROPERTY_SEARCH']['SUCCESS']:
    case SEARCH_ACTION_TYPES['POST_SOLD_PROPERTY_SEARCH']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params.data,
        search_results: action.params.data,
        error: null,
      }

    case SEARCH_ACTION_TYPES['GET_PROPERTY_SEARCH']['FAILED']:
    case SEARCH_ACTION_TYPES['POST_PROPERTY_SEARCH']['FAILED']:
    case SEARCH_ACTION_TYPES['GET_RENTAL_PROPERTY_SEARCH']['FAILED']:
    case SEARCH_ACTION_TYPES['POST_RENTAL_PROPERTY_SEARCH']['FAILED']:
    case SEARCH_ACTION_TYPES['GET_SOLD_PROPERTY_SEARCH']['FAILED']:
    case SEARCH_ACTION_TYPES['POST_SOLD_PROPERTY_SEARCH']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params?.data,
      }

    case SEARCH_ACTION_TYPES['GENERAL_SEARCH']['CLEAR_ERROR']:
      return {
        ...state,
        status: null,
        payload_request: null,
        payload_success: null,
        payload_failed: null,
        error: null,
      }

    case SEARCH_ACTION_TYPES['FUNNEL_DOWN_SEARCH']['UPDATE']:
      return {
        ...state,
        narrowed_results: {
          data: {
            propertiesFilters: action.params,
            propertyCount: action.params?.length,
            totalResults: action.params?.length,
          },
          message: 'Search narrows down successfully.',
        },
      }
    case SEARCH_ACTION_TYPES['FUNNEL_DOWN_SEARCH']['CLEAR']:
      return {
        ...state,
        narrowed_results: null,
      }
    case SEARCH_ACTION_TYPES['PAGINATION_REFERENCE']['UPDATE']:
      return {
        ...state,
        status: 'search/PAGINATION_REFERENCE_UPDATE',
        pagination: action.params,
      }
    case SEARCH_ACTION_TYPES['PAGINATION_REFERENCE']['CLEAR']:
      return {
        ...state,
        pagination: defaultPaginationObj,
      }

    case SEARCH_ACTION_TYPES['GENERAL_SEARCH']['CLEAR_STATUS']:
      return {
        ...state,
        status: null,
      }

    case SEARCH_ACTION_TYPES['GENERAL_SEARCH']['RESET_STATES']:
      return INITIAL_STATE
    case PURGE:
      return INITIAL_STATE

    default: {
      return state
    }
  }
}

export default searchReducers
