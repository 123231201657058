import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import RangeSlider from '../Filter/RangeSlider'
import { PRICE_RANGE, PRICE_MARKS } from '../../../constants/constants'
import { Typography, withStyles } from '@material-ui/core'
const Container = styled.div`
  padding: 20px 0;
`

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
  .slider-Bedrooms {
    .MuiSlider-markLabel[data-index='0'] {
      left: 20px !important;
    }
  }
`

const marksBedroomExact = [
  {
    value: 0,
    label: 'Studio',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
]

const marksDefault = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: '1+',
  },
  {
    value: 2,
    label: '2+',
  },
  {
    value: 3,
    label: '3+',
  },
  {
    value: 4,
    label: '4+',
  },
  {
    value: 5,
    label: '5+',
  },
]

const marksExact = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
]

const marksPrice = [
  {
    value: 50000,
    label: '50k',
  },
  {
    value: 100000,
    label: '100k',
  },
  {
    value: 200000,
    label: '200k',
  },
  {
    value: 250000,
    label: '250k',
  },
  {
    value: 300000,
    label: '300k',
  },
  {
    value: 12000000,
    label: '12M',
  },
]

const priceMarks = PRICE_MARKS
const SCALE_PRICE = PRICE_MARKS[PRICE_MARKS.length - 1].value

const priceRange = PRICE_RANGE
const priceRangeRent = [250, 5000]

const SliderContainer = (props) => {
  const [price, setPrice] = useState([
    props.propertyDetails.price.min,
    props.propertyDetails.price.max,
  ])
  const [scaledPrice, setScaledPrice] = useState(
    props.propertyDetails.scaledPrice ?? [0, SCALE_PRICE]
  )
  const [priceRent, setPriceRent] = useState([
    props.propertyDetails.priceRent.min,
    props.propertyDetails.priceRent.max,
  ])
  const [bedrooms, setBedrooms] = useState(props.propertyDetails.bedrooms)
  const [bathrooms, setBathrooms] = useState(props.propertyDetails.bathrooms)
  const [carparking, setCarparking] = useState(props.propertyDetails.carparking)
  const [persistedData, setPersistedData] = useState(false)

  const [priceStep, setPriceStep] = useState(priceRange[0])
  const [priceStepScaled, setPriceStepScaled] = useState(5)

  useEffect(() => {
    if (!persistedData) {
      setPrice([
        props.propertyDetails.price.min,
        props.propertyDetails.price.max === 0
          ? SCALE_PRICE
          : props.propertyDetails.price.max,
      ])
      setPriceRent([
        props.propertyDetails.priceRent.min,
        props.propertyDetails.priceRent.max,
      ])
      setBedrooms({
        value:
          props.propertyDetails.bedrooms && props.propertyDetails.bedrooms.value
            ? props.propertyDetails.bedrooms.value
            : 0,
        exactMatch:
          props.propertyDetails.bedrooms &&
          props.propertyDetails.bedrooms.exactMatch !== undefined
            ? props.propertyDetails.bedrooms.exactMatch
            : false,
      })
      setBathrooms({
        value:
          props.propertyDetails.bathrooms && props.propertyDetails.bathrooms.value
            ? props.propertyDetails.bathrooms.value
            : 0,
        exactMatch:
          props.propertyDetails.bathrooms &&
          props.propertyDetails.bathrooms.exactMatch !== undefined
            ? props.propertyDetails.bathrooms.exactMatch
            : false,
      })
      setCarparking({
        value:
          props.propertyDetails.carparking && props.propertyDetails.carparking.value
            ? props.propertyDetails.carparking.value
            : 0,
        exactMatch:
          props.propertyDetails.carparking &&
          props.propertyDetails.carparking.exactMatch !== undefined
            ? props.propertyDetails.carparking.exactMatch
            : false,
      })
      setScaledPrice(props.propertyDetails.scaledPrice)
      setPersistedData(true)
    }
  }, [props.propertyDetails])

  useEffect(() => {
    handleData()
  }, [bedrooms, bathrooms, carparking])

  useEffect(() => {
    if (props.isClearFilter) {
      setPriceRent(priceRangeRent)
      setPrice(priceRange)
      setBedrooms(null)
      setBathrooms(null)
      setCarparking(null)
      setScaledPrice([0, SCALE_PRICE])
      handleData('clear')
    }
  }, [props.isClearFilter])

  const handlePriceChange = (event, newValue) => {
    let minVal = parseInt(newValue[0])
    if (minVal > 5000000 && minVal < 10000000) {
      setPriceStep(500000)
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000)
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000)
    } else {
      setPriceStep(50000)
    }

    if (
      (newValue[0] > 5000000 && newValue[0] < priceRange[1]) ||
      (newValue[1] > 5000000 && newValue[1] < priceRange[1])
    ) {
      if (newValue[0].toFixed(2).search('50000.00') > -1) {
        let nMin = String(newValue[0]).replace('50000', '00000')
        newValue[0] = parseInt(nMin)
      }
      if (newValue[1].toFixed(2).search('50000.00') > -1) {
        let nMax = String(newValue[1]).replace('50000', '00000')
        newValue[1] = parseInt(nMax)
      }
      setPrice(newValue)
      props.handleSlider('price', handleScale(newValue))
      props.handleSlider('scaledPrice', newValue)
    } else {
      setPrice(newValue)
      props.handleSlider('price', handleScale(newValue))
      props.handleSlider('scaledPrice', newValue)
    }

    setScaledPrice(newValue)
  }

  const handlePriceRentChange = (event, newValue) => {
    setPriceRent(newValue)
    props.handleSlider('priceRent', newValue)
  }

  const handleBedroomsChange = (event, newValue, isExact, exactChange) => {
    if ((isExact !== undefined || isExact !== null) && exactChange === true) {
      setBedrooms({ ...bedrooms, exactMatch: isExact })
      props.handleSlider('bedrooms', { ...bedrooms, exactMatch: isExact })
    } else {
      setBedrooms({ ...bedrooms, value: newValue })
      props.handleSlider('bedrooms', { ...bedrooms, value: newValue })
    }
  }

  const handleBathroomsChange = (event, newValue, isExact, exactChange) => {
    if ((isExact !== undefined || isExact !== null) && exactChange === true) {
      setBathrooms({ ...bathrooms, exactMatch: isExact })
      props.handleSlider('bathrooms', { ...bathrooms, exactMatch: isExact })
    } else {
      setBathrooms({ ...bathrooms, value: newValue })
      props.handleSlider('bathrooms', { ...bathrooms, value: newValue })
    }
  }

  const handleCarparkingChange = (event, newValue, isExact, exactChange) => {
    if ((isExact !== undefined || isExact !== null) && exactChange === true) {
      setCarparking({ ...carparking, exactMatch: isExact })
    } else {
      setCarparking({ ...carparking, value: newValue })
    }
  }

  const handleChangeCommitted = () => {
    handleData()
  }
  const handleData = (clear) => {
    const rangedPrice = handleScale(scaledPrice)
    const propertyData = {
      price: {
        min: price[0],
        max: price[1],
      },
      rangedPrice: {
        min: rangedPrice[0] !== 0 ? rangedPrice[0] : PRICE_RANGE[0],
        max: rangedPrice[1] !== 0 ? rangedPrice[1] : PRICE_RANGE[1],
      },
      priceRent: {
        min: priceRent[0],
        max: priceRent[1],
      },
      bedrooms: bedrooms,
      bathrooms: bathrooms,
      carparking: carparking,
      scaledPrice: scaledPrice,
    }
    if (props.handlePropertyDetails) {
      if (clear) {
        propertyData.price.min = priceRange[0]
        propertyData.price.max = priceRange[1]
        propertyData.rangedPrice.min = priceRange[0]
        propertyData.rangedPrice.max = priceRange[1]
        propertyData.priceRent.min = 250
        propertyData.priceRent.max = 5000
        propertyData.bedrooms = null
        propertyData.bathrooms = null
        propertyData.carparking = null
        scaledPrice.scaledPrice = [0, SCALE_PRICE]
      }
      props.handlePropertyDetails(propertyData)
    }
  }

  const handleScale = (valueArray) => {
    if (valueArray) {
      return [scale(valueArray[0]), scale(valueArray[1])]
    } else {
      return PRICE_RANGE
    }
  }

  const scale = (value) => {
    if (value === undefined) {
      return 0
    }
    try {
      const previousMarkIndex = Math.floor(value / 25)
      const previousMark = priceMarks[previousMarkIndex]
      const remainder = value % 25
      if (remainder === 0 && previousMark) {
        return previousMark.scaledValue
      }

      const nextMark = priceMarks[previousMarkIndex + 1]
      const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25
      return remainder * increment + previousMark.scaledValue
    } catch (err) {
      return 0
    }
  }

  function renderPrice() {
    return (
      <SliderItem>
        {
          // <RangeSlider label="Price" icon="MonetizationOn" value={price} range={priceRange} step={priceRange[0]} handleChange={handlePriceChange} handleChangeCommitted={handleChangeCommitted} valueLabelDisplay={'off'} />
          props.path === 'rent' ? (
            <RangeSlider
              path={props.path}
              label="Price Per Week"
              icon="MonetizationOn"
              value={priceRent}
              range={priceRangeRent}
              step={50}
              handleChange={handlePriceRentChange}
              handleChangeCommitted={handleChangeCommitted}
              valueLabelDisplay={'off'}
              enableExactControls={false}
            />
          ) : (
            // : <RangeSlider label="Price" icon="MonetizationOn" value={price} range={priceRange} step={priceStep} handleChange={handlePriceChange} handleChangeCommitted={handleChangeCommitted} valueLabelDisplay={'off'} />
            <RangeSlider
              label="Price"
              icon="MonetizationOn"
              value={scaledPrice}
              min={0}
              step={5}
              max={SCALE_PRICE}
              marks={false}
              scale={handleScale}
              scaledValues={handleScale(scaledPrice)}
              handleChange={handlePriceChange}
              handleChangeCommitted={handleChangeCommitted}
              valueLabelDisplay={'off'}
              isScaled={true}
              enableExactControls={false}
            />
          )
        }
      </SliderItem>
    )
  }

  function renderBedroom() {
    return (
      <SliderItem>
        <RangeSlider
          label="Bedrooms"
          icon="Hotel"
          value={(bedrooms && bedrooms.value) ?? 0}
          handleChange={handleBedroomsChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={bedrooms && bedrooms.exactMatch ? marksBedroomExact : marksDefault}
          min={0}
          max={5}
          isExact={bedrooms && bedrooms.exactMatch}
          enableExactControls={true}
        />
      </SliderItem>
    )
  }

  function renderBathroom() {
    return (
      <SliderItem>
        <RangeSlider
          label="Bathrooms"
          icon="2DCBathIcon"
          value={(bathrooms && bathrooms.value) ?? 0}
          handleChange={handleBathroomsChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={bathrooms && bathrooms.exactMatch ? marksExact : marksDefault}
          min={0}
          max={5}
          isExact={bathrooms && bathrooms.exactMatch}
          enableExactControls={true}
        />
      </SliderItem>
    )
  }

  function renderParking() {
    return (
      <SliderItem>
        <RangeSlider
          label="Carparking"
          icon="DirectionsCar"
          value={(carparking && carparking.value) ?? 0}
          handleChange={handleCarparkingChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={carparking && carparking.exactMatch ? marksExact : marksDefault}
          min={0}
          max={5}
          isExact={carparking && carparking.exactMatch}
          enableExactControls={true}
        />
      </SliderItem>
    )
  }

  return (
    <Container>
      {props.type === 'cost' && renderPrice()}
      {props.type === 'bed' && renderBedroom()}
      {props.type === 'bath' && renderBathroom()}
      {props.type === 'parking' && renderParking()}
    </Container>
  )
}

export default SliderContainer
