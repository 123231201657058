import React from 'react'
import { Box } from '@material-ui/core'

import CustomField from 'components/Web/Filter/CustomField'
import Heading from 'components/Web/Filter/Heading'

import useFilters from 'utils/customHooks/useFilters'

const Keywords = () => {
  const [getFilter, setFilter] = useFilters()
  const KEYWORD = getFilter('keyword')

  return (
    <Box>
      <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
        Keywords
      </Heading>
      <CustomField
        value={KEYWORD || ''}
        type="text"
        placeholder="eg. waterfront, street name etc"
        containerstyle={{ paddingBottom: 0 }}
        onChange={(e) => {
          let value = e.target.value
          setFilter({ key: 'keyword', value: value ? value : null })
        }}
      />
    </Box>
  )
}

export default Keywords
