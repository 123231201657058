import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

import Filter from "../../components/Web/Filter";
import Heading from "../../components/Web/Filter/Heading";
import Loader from "../../components/Web/Loader";
import AlertNotification from "../../components/Web/Form/AlertNotification";
import DynamicIcons from "../../components/Web/DynamicIcons";

/** api redux request */
import { requestSearchPerfectProperty } from "../../store/api";

/** actions */
import {
  setPropertySearchFilters,
  clearFilters,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPerfectPropertyFilters,
  clearPerfectPropertyFilters,
  clearLikedUnlikedProperties,
  setTypesCounters,
  clearTypesCounters,
  setReferenceFilters,
  clearReferenceFilters,
} from "../../store/actions";

/** Object Filters */
import { formatRequest, paramsToSha1 } from '../../utils/search.utils'

import { Typography, Button, withStyles } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";

const styles = (theme) => ({
  root: {
    backgroundColor: "#FFF",
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 45,
  },
  content: {
    height: "100%",
    paddingBottom: "30px",
  },
});

const StyledButton = withStyles({
  root: {
    borderRadius: "30px",
  },
})(Button);

const Header = styled.div`
  position: relative;
  padding: 10px 23px 15px;
`;

const HeaderNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
`;

const BackButton = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.073125px;
  display: flex;
  align-items: center;
  margin-left: -10px;
  cursor: pointer;
`;

const ClearButton = styled.a`
  text-decoration: underline;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.073125px;
  cursor: pointer;
`;

const ButtonWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 90px;
  max-width: calc(573px);
  width: 100%;
  background-color: #fff;
  padding: 10px 15px;
  box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-center: center;
  justify-content: center;
  z-index: 9;

  &.filter-button {
    left: -573px;
    transition: left 0.7s ease;
  }
`;

const FiltersPerfect = (props) => {
  const {
    classes,
    filters,
    property,
    setSearchFilters,
    clearFiltersState,
    searchPerfectProperty,
    setPerfectPropertyFilter,
    setTypesCount,
    typeCounters,
    clearTypeCount,
    referenceFilters,
    saveReferenceFilters,
    removeReferenceFilters,
    filterScope,
  } = props;
  const [routerChange] = useRouterChange()
  const [filter, setFilter] = useState(null);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);
  const [propertyTypesCount, setPropertyTypesCount] = useState(null);

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setPropertyTypesCount(null);
    };
  }, []);

  /* handle properties callback */
  useEffect(() => {
    /** success handler */
    if (property) {
      if (
        property.payload &&
        property.payload.data &&
        property.payload.data.ok &&
        isLoading
      ) {
        setIsLoading(false);
        routerChange("/perfect-property");
      }
    }
    /** error hanlder */
    if (property && property.property_search_error && isLoading) {
      handleNotification(true, {
        message: "Oops. An error has occured..",
        severity: "error",
      });
      setIsLoading(false);
    }

    /* if (
      property.perfect_properties &&
      property.perfect_properties.data &&
      property.perfect_properties.data.propertyTypesCount
    ) {
      actionSetTypesCount(property.perfect_properties.data.propertyTypesCount);
      actionSaveReferenceFilters(filters, "perfect-property");
    } else if (
      property.perfect_properties &&
      property.perfect_properties.data.data &&
      property.perfect_properties.data.data &&
      property.perfect_properties.data.data.propertyTypesCount
    ) {
      actionSetTypesCount(property.perfect_properties.data.data.propertyTypesCount);
      actionSaveReferenceFilters(filters, "perfect-property");
    } */
  }, [property]);

  useEffect(() => {
    if (
      filters &&
      referenceFilters &&
      JSON.stringify(filters) !== JSON.stringify(referenceFilters.filters)
    ) {
      setPropertyTypesCount(null);
    }

    if (filterScope && referenceFilters && referenceFilters.scope !== filterScope) {
      setPropertyTypesCount(null);
    }
  }, [filters, filterScope, referenceFilters]);

  useEffect(() => {
    if (typeCounters) {
      setPropertyTypesCount(typeCounters);
    } else {
      setPropertyTypesCount(null);
    }
  }, [typeCounters]);

  async function actionSetTypesCount(params) {
    if (params) {
      setTypesCount(params);
    }
  }

  async function actionSaveReferenceFilters(params, reference) {
    if (params && reference) {
      saveReferenceFilters(params, reference);
    }
  }

  /** Search Perfect Property */
  async function apiRequestSearchPerfectProperty() {
    let params = await formatRequest("perfect-property", filters);

    if (!validateFilter(filters)) {
      handleNotification(true, {
        message: "Please select state first",
        severity: "error",
      });
    } else {
      setIsLoading(true);
      const request = {
        query: `?filters=${paramsToSha1(params)}`,
        data: params,
      }
      searchPerfectProperty(request)
    }
  }

  const handleFilter = (obj) => {
    if (obj) {
      setFilter(obj);
      if (JSON.stringify(obj) !== JSON.stringify(filter)) {
        saveFilters(obj);
      }
    }
  };

  function saveFilters(params) {
    setSearchFilters(params);
    if (params && params.perfectProperty) {
      setPerfectPropertyFilter(params.perfectProperty);
    }
  }

  const validateFilter = (params) => {
    return _.findIndex(params.perfectProperty.state, { active: true }) > -1;
  };

  function resetFilters() {
    clearFiltersState();
  }

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const closeNotification = () => {
    setIsNotification(false);
  };

  const applyFilter = () => {
    apiRequestSearchPerfectProperty();
  };

  const handleClearFilter = () => {
    setIsClearFilter(true);
    let to = setTimeout(() => {
      setIsClearFilter(false);
      resetFilters();
      clearTimeout(to);
    }, 50);
  };

  const clearSelectedFilters = (e) => {
    e.preventDefault();
    handleClearFilter();
  };

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <HeaderNav>
        {/* <ClearButton onClick={clearSelectedFilters}>Clear Selections</ClearButton> */}
      </HeaderNav>
      <Header>
        <Heading variant="h6" style={{ fontWeight: 400 }}>
          Find My Perfect Property
        </Heading>
        <Heading variant="body1" style={{ fontWeight: 400, color: "#4d4d4d" }}>
          Answer as many of these questions as you want to find your dream home... get
          ready to be surprised.
        </Heading>
      </Header>

      <div className={classes.content}>
        <Filter
          handleFilter={handleFilter}
          isClearFilter={isClearFilter}
          saveFilters={saveFilters}
          resetFilters={resetFilters}
          filters={filters}
          filterType={"perfect-property"}
          propertyTypesCount={propertyTypesCount}
        />
      </div>

      <ButtonWrap className="filter-button">
        <StyledButton
          variant="contained"
          color="primary"
          onClick={applyFilter}
          style={{ textTransform: "none" }}
        >
          Apply Filters
        </StyledButton>
      </ButtonWrap>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    filters: state.filters,
    property: state.property,
    typeCounters: state.referenceFilters.typesCounters,
    referenceFilters: state.referenceFilters,
    filterScope: state.settings.showFilterWeb.type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSearchFilters: (params) => setPropertySearchFilters(params),
      clearFiltersState: (params) => clearFilters(params),
      searchPerfectProperty: (params) => requestSearchPerfectProperty(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setPerfectPropertyFilter: (params) => setPerfectPropertyFilters(params),
      clearPerfectProperty: () => clearPerfectPropertyFilters(),
      clearLikeAndUnlike: () => clearLikedUnlikedProperties(),
      setTypesCount: (params) => setTypesCounters(params),
      clearTypeCount: () => clearTypesCounters(),
      saveReferenceFilters: (params, reference) =>
        setReferenceFilters(params, reference),
      removeReferenceFilters: () => clearReferenceFilters(),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FiltersPerfect));
