export const LOGOUT_REQUEST = "authentication/LOGOUT_REQUEST_REQUEST";

export const REGISTER_USER_REQUEST = "authentication/REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "authentication/REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "authentication/REGISTER_USER_FAIL";

export const VERIFY_REGISTRATION_REQUEST = "authentication/VERIFY_REGISTRATION_REQUEST";
export const VERIFY_REGISTRATION_SUCCESS = "authentication/VERIFY_REGISTRATION_SUCCESS";
export const VERIFY_REGISTRATION_FAIL = "authentication/VERIFY_REGISTRATION_FAIL";

export const LOGIN_USER_REQUEST = "authentication/LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "authentication/LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "authentication/LOGIN_USER_FAIL";
export const CLEAR_LOGIN_STATES = "authentication/CLEAR_LOGIN_STATES";

export const RESET_PASSWORD_REQUEST = "authentication/RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "authentication/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "authentication/RESET_PASSWORD_FAIL";

export const FORGOT_PASSWORD_REQUEST = "authentication/FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "authentication/FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "authentication/FORGOT_PASSWORD_FAIL";

export const CHANGE_PASSWORD_REQUEST = "authentication/CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "authentication/CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "authentication/CHANGE_PASSWORD_FAIL";

export const RESEND_CODE_REQUEST = "authentication/RESEND_CODE_REQUEST";
export const RESEND_CODE_SUCCESS = "authentication/RESEND_CODE_SUCCESS";
export const RESEND_CODE_FAIL = "authentication/RESEND_CODE_FAIL";

export const FACEBOOK_AUTH_REQUEST = "authentication/FACEBOOK_AUTH_REQUEST";
export const FACEBOOK_AUTH_SUCCESS = "authentication/FACEBOOK_AUTH_SUCCESS";
export const FACEBOOK_AUTH_FAIL = "authentication/FACEBOOK_AUTH_FAIL";

export const GOOGLE_AUTH_REQUEST = "authentication/GOOGLE_AUTH_REQUEST";
export const GOOGLE_AUTH_SUCCESS = "authentication/GOOGLE_AUTH_SUCCESS";
export const GOOGLE_AUTH_FAIL = "authentication/GOOGLE_AUTH_FAIL";

export const APPLE_AUTH_REQUEST = "authentication/APPLE_AUTH_REQUEST";
export const APPLE_AUTH_SUCCESS = "authentication/APPLE_AUTH_SUCCESS";
export const APPLE_AUTH_FAIL = "authentication/APPLE_AUTH_FAIL";

export const VALIDATE_USER_TOKEN_REQUEST = "authentication/VALIDATE_USER_TOKEN_REQUEST";
export const VALIDATE_USER_TOKEN_SUCCESS = "authentication/VALIDATE_USER_TOKEN_SUCCESS";
export const VALIDATE_USER_TOKEN_FAIL = "authentication/VALIDATE_USER_TOKEN_FAIL";

export const CLEAR_SESSION = "authentication/CLEAR_SESSION";
