import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import LawnBG from '../../assets/images/lawn.jpg';
import WoodworkBG from '../../assets/images/woodwork.jpg';
import CarpentryBG from '../../assets/images/carpentry.jpg';
import PaintingBG from '../../assets/images/painting.jpg';
import PlumingBG from '../../assets/images/pluming.jpg';
import RepairBG from '../../assets/images/repair.jpg';

import TradesLanding from '../../components/Web/TradesLanding';

/** actions */
import {
  setPropertySuburbSearch,
  clearSuburbSearch,
} from '../../store/actions';

/** api redux request */
import {
  requestPropertySearch,
} from "../../store/api";
import { useRouterChange } from "utils/router.utils";

const Trades = props => {
  const [routerChange] = useRouterChange()
  const [currentBG, setCurrentBG] = React.useState(LawnBG)

  React.useEffect(() => {
    setCurrentBG(randomizeBackground())
    localStorage.removeItem('hideState');

  }, [])

  /** Search Property */
  async function apiRequestSearchProperty() {
    routerChange('/trades/results')
  }

  function randomizeBackground() {
    let randomBgKey = Math.floor(Math.random() * 5);
    switch (randomBgKey) {
      case 0:
        return LawnBG;
      case 1:
        return WoodworkBG;
      case 2:
        return CarpentryBG;
      case 3:
        return PaintingBG;
      case 4:
        return PlumingBG;
      case 5:
        return RepairBG;
      default:
        return LawnBG;
    }
  }

  return (
    <div style={{
      height: '75vh',
      display: "flex",
      flexDirection: 'column',
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: 'gray'
    }}>
      <TradesLanding
        imageBG={currentBG}
        setPropertySearch={props.setPropertySearch}
        clearPropertySuburb={props.clearPropertySuburb}

        apiRequestSearchProperty={apiRequestSearchProperty} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stepCounter: null
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      searchProperty: params => requestPropertySearch(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Trades);
