import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const StyledText = withStyles((theme) => ({
  root: {
    fontSize: '1rem', 
    lineHeight: '1rem',  
    letterSpacing: '-0.25px', 
    marginBottom: theme.spacing(3)
  },
}))(Typography);

const TextSmall = props => {    
    return (
        <StyledText { ...props }>{props.children}</StyledText>
    );
}

export default (TextSmall);