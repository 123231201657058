import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SuperBreadCrumbs, AgentsForm } from 'components/Web/Superadmin'

import { agencyv2Actions } from 'store/actions'
import { requestGETAPIAgency, requestPUTAPIAgency } from 'store/api'
import { getStatus, getAgentDetails, getUser } from 'store/selectors/agencyv2.selectors'

import AlertNotification from 'components/Web/Form/AlertNotification'
import { withStyles } from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
})

const AgencyEditAgent = (props) => {
  const { classes, history, match } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()

  const STATUS = useSelector(getStatus)
  const AGENT = useSelector(getAgentDetails)
  const USER = useSelector(getUser);

  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/agency/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agents',
      path: '/agency/manage-agents',
    },
    {
      type: 'primary',
      label: 'Edit Agent',
      path: '',
    },
  ]

  function goBack() {
    routerChange('back')
  }

  window.onbeforeunload = (e) => {
    return handleClearData()
  }

  useEffect(() => {
    handleClearData()
    handleGetAgentDetails(match.params.id)

    return () => {
      handleClearData()
    }
  }, [])

  useEffect(() => {
    if (STATUS === 'agency/AGENCY_PUT_AGENT_DETAILS_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Changes successfully saved.',
          autoHideDuration: 2000,
          onClose: () => {
            handleClearNotices()
            setTimeout(() => goBack(), 2000)
          },
        },
      }
      setIsNotification(options)
      setSubmitSuccess(true)
    } else if (STATUS === 'agency/AGENCY_PUT_AGENT_DETAILS_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Saving changes...',
          autoHideDuration: 3000,
        },
      }
      setIsNotification(options)
    } else if (STATUS === 'agency/AGENCY_PUT_AGENT_DETAILS_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error saving changes. Please try again.',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    }
  }, [STATUS])

  const handleGetAgentDetails = (id) => {
    const params = {
      query: id,
      data: null,
    }
    dispatch(requestGETAPIAgency(params, 'AGENCY_GET_AGENT_DETAILS'))
  }

  const handleSubmit = (data) => {
    const params = {
      query: match.params.id,
      data: data,
    }

    dispatch(requestPUTAPIAgency(params, 'AGENCY_PUT_AGENT_DETAILS'))
  }

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'))
  }

  const handleClearData = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
  }

  const handleClearNotices = () => {
    handleClearStatus()
    handleClearError()
    setIsNotification(defaultNotification)
  }

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs section={'Manage Agents'} crumbsLinks={CRUMBS} />
      {AGENT && AGENT?.data?.entity && (
        <AgentsForm
          match={{ params: { id: USER.agency } }}
          goBack={goBack}
          agent={AGENT.data.entity}
          handleSubmit={handleSubmit}
          submitStatus={submitSuccess}
        />
      )}
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
}
export default withStyles(styles)(AgencyEditAgent)
