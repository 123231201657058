import React, { useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import styled from 'styled-components'
/** components **/
import AddProperty from '../../components/Web/Agent/AddProperty'

import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: '#fff',
  },
})

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1440px) {
    padding: 0 142px 0 155px;
  }
`

const Content = styled.div`
  max-width: 1140px;
  width: 100%;
  background-color: #fff;
  margin-top: 2rem;
  padding: 0 0 140px;
`

const AgentProperties = (props) => {
  const { classes, user, history } = props

  useEffect(() => {
    if (user?.userType !== 'Agent' && !user?.userTypeList?.some(i => ['Agent'].includes(i))) {
      history.push('/')
    }
  }, [user])

  return (
    <div className={classes.root}>
      <Container>
        <Content>
          <AddProperty />
        </Content>
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    user: state.users.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentProperties))
