import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import shouldPureComponentUpdate from 'react-pure-render/function'
import DynamicIcons from '../DynamicIcons'

import GoogleMap from 'google-map-react'
import { fitBounds } from 'google-map-react'

import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import MapPropertyOverlay from '../Map/MapPropertyOverlay'
import mapDraw from './MapDraw'
import _ from 'lodash'
import mapDrawDisable from './mapDrawDisable'

function rad2degr(rad) {
  return (rad * 180) / Math.PI
}
function degr2rad(degr) {
  return (degr * Math.PI) / 180
}

/**
 * @param latLngInDeg array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...]
 *
 * @return array with the center latitude longtitude pairs in
 *   degrees.
 */
function getLatLngCenter(latLngInDegr) {
  var LATIDX = 0
  var LNGIDX = 1
  var sumX = 0
  var sumY = 0
  var sumZ = 0

  for (var i = 0; i < latLngInDegr.length; i++) {
    var lat = degr2rad(latLngInDegr[i][LATIDX])
    var lng = degr2rad(latLngInDegr[i][LNGIDX])
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng)
    sumY += Math.cos(lat) * Math.sin(lng)
    sumZ += Math.sin(lat)
  }

  var avgX = sumX / latLngInDegr.length
  var avgY = sumY / latLngInDegr.length
  var avgZ = sumZ / latLngInDegr.length

  // convert average x, y, z coordinate to latitude and longtitude
  var lng = Math.atan2(avgY, avgX)
  var hyp = Math.sqrt(avgX * avgX + avgY * avgY)
  var lat = Math.atan2(avgZ, hyp)

  return ({lat: rad2degr(lat), lng: rad2degr(lng)})
}

const Marker = (props) => {
  const { content, details, active, children } = props
  // console.log(details, 'details', active)
  return (
    <Fragment>
      <div>{content}</div>
      {children}
    </Fragment>
  )
}

const processShape = (iArr) => {
  var oArr = []
  for (var i = 0; i < iArr.length; i++) {
    oArr.push({
      lat: iArr[i][1],
      lng: iArr[i][0],
    })
    // oArr.push(new maps.LatLng(iArr[i][1],iArr[i][0]));
  }

  return oArr
}

let MapInstance
var _mapContainer = document.querySelector('.gm-style-pbc')

const checkPointPoly = (point, polygon) => {
  const coords = window.google.maps
    ? new window.google.maps.LatLng(point.lat, point.lng)
    : false
  return window.google.maps && coords
    ? window.google.maps.geometry.poly.containsLocation(coords, polygon)
    : false
}

const apiIsLoaded = (map, maps, offmarkets, instance, type, data) => {
  MapInstance = map

  const merger = _.spread(_.union)

  instance.setState((state) => {
    return {
      ...state,
      mergedProperties: merger([
        data.map.offmarket,
        data.map.current,
        data.map.comingsoon,
      ]),
    }
  })

  if (maps) {
    let getbounds = new maps.LatLngBounds()
    
    if (instance?.state?.mergedProperties?.length > 0) {
      instance.state.mergedProperties.forEach((p) => {
        getbounds.extend(new maps.LatLng(p.lat, p.lng))
      })

      map.fitBounds(getbounds)
      if (instance.state.mergedProperties.length === 1) {
        map.setZoom(17)
      }
    }

    //center map
    /* map.fitBounds(map.getBounds());
    if(map.getZoom() > 11) {
      map.setZoom(11)
    }else if(map.getZoom() < 9) {
      map.setZoom(11)
    } */

    var collectCoordinates = []

    if (instance?.state?.mergedProperties) {
      instance.state.mergedProperties.map((item, idx) => {
        collectCoordinates.push([item.lat, item.lng])
      })
    }

    if (collectCoordinates.length > 0 && getLatLngCenter(collectCoordinates)) {
      try {
        map.setCenter(getLatLngCenter(collectCoordinates), 11)
      } catch (e) {
        console.debug(e)
      }
    }
  }

  // console.log(collectCoordinates, 'Coords Collected', instance.state.mergedProperties)
}

var mapPolys = []

const getMapOptions = (type, drawing, map, maps, instance) => {
  if (drawing === true && maps && map) {
    maps.event.addDomListener(map.getDiv(), 'mousedown', function (e) {
      if (mapPolys) {
        for (var i in mapPolys) {
          mapPolys[i].setMap(null)
        }
        mapPolys.length = 0
        instance.setState((state) => {
          return { ...state, polygonFilter: null }
        })
      }
      mapDraw(maps, map, type, instance, mapPolys)
    })
  } else {
    if (mapPolys) {
      for (var i in mapPolys) {
        mapPolys[i].setMap(null)
      }
      mapPolys.length = 0
      instance.setState((state) => {
        return { ...state, polygonFilter: null }
      })
    }
  }

  const enabled = {
    // draggable: true,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    panControl: true,
    mapTypeControl: false,
    mapTypeId: type ? 'satellite' : 'roadmap',
    fullscreenControl: false,
    zoomControl: true,
    clickableIcons: false,
    draggableCursor: 'crosshair',
  }

  const disabled = {
    // draggable: false,
    panControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: false,
    panControl: false,
    mapTypeControl: false,
    mapTypeId: type ? 'satellite' : 'roadmap',
    fullscreenControl: false,
    zoomControl: true,
    clickableIcons: false,
    draggableCursor: null,
  }

  switch (drawing) {
    case false:
      return disabled
      break
    case true:
      return enabled
      break
    default:
      return disabled
  }
}

export default class PropertySearchMapView extends Component {
  static propTypes = {
    data: PropTypes.object,
    onCenterChange: PropTypes.func,
    activePropertySetter: PropTypes.func,
    offMarketToggleSetter: PropTypes.func,
    visibleOffmarketItems: PropTypes.func,
    setCurrentProperty: PropTypes.func,
    currentPath: PropTypes.string,
    mapDrawEnabled: PropTypes.bool,
    setMapCenter: PropTypes.func,
    activePreviewProperty: PropTypes.string,
    suburbs: PropTypes.any,
  }

  static defaultProps = {
    data: {
      map: {
        coordinates: [-37.7963, 144.92512],
        center: { lat: -37.7963, lng: 144.92512 },
        comingsoon: [{ lat: -37.7963, lng: 144.92512 }],
        offmarket: [],
        current: [],
        rental: [],
        sold: [],
        zoom: 17,
      },
      address: '108/62 Altona Street, Kensington',
      radius: 600,
    },
    drawing: false,
  }

  shouldComponentUpdate = shouldPureComponentUpdate

  constructor(props) {
    super(props)

    this.state = {
      center: [-37.7963, 144.92512],
      active: null,
      visibleOffmarkets: null,
      mergedProperties: null,
      polygonFilter: null,
      activeHovered: null,
      previewHovered: this.props.activePreviewProperty,
    }

    this.mapRef = React.createRef()
  }

  _checkAxis = (event) => {
    var x = event.offsetX
    var y = event.offsetY

    /* console.log("X Position: " +x);
    console.log("Y Position: " +y); */
  }

  _setVisibleOffmarkets = (items) => {
    this.setState((state, props) => {
      return { ...state, visibleOffmarkets: items }
    })

    this.props.visibleOffmarketItems(this.state.visibleOffmarkets)
  }

  _onChildClick = (key, childProps) => {
    if (key !== this.state.active) {
      // setTimeout(()=>{
      this.setState((state, props) => {
        return { ...state, active: key }
      })
      this.props.activePropertySetter(childProps.details)
      // },700)
    } else {
      this.props.activePropertySetter(null)
    }
    if (childProps.details.propertyStatus !== 'offmarket') {
      this.props.offMarketToggleSetter(false)
    }

    this.props.setMapCenter({ lat: childProps.lat, lng: childProps.lng })
  }

  _onClick = (props) => {
    this.setState((state, props) => {
      return { ...state, active: null }
    })
    this.props.activePropertySetter(null)
  }

  _onDragEnd = (props) => {
    if (this.state.activeHovered) {
      this.setState((state, props) => {
        return { ...state, activeHovered: null }
      })
    }
  }

  _onChildMouseEnter = (key, childProps) => {
    if (key !== this.state.active) {
      this.setState((state, props) => {
        return { ...state, activeHovered: key }
      })
    } else {
      this.props.activePropertySetter(null)
    }
  }

  _onChildMouseLeave = (key, childProps) => {
    this.setState((state, props) => {
      return { ...state, activeHovered: null }
    })
    this.props.activePropertySetter(null)
  }
  _toggleDrawing = () => {
    const ref = this.props.data.map.drawing

    this.setState((state, props) => {
      return { ...state, drawing: !ref }
    })
  }

  render() {
    const markerStyle = {
      cursor: 'pointer',
      transform: 'translate(-50%, -70%)',
      width: '1.5em',
      height: '1.5em',
      position: 'relative',
    }
    const markerStyleOffmarket = {
      cursor: 'pointer',
      transform: 'translate(-50%, -70%)',
      width: '4.6rem',
      height: '4.6rem',
      position: 'relative',
    }
    return (
      <GoogleMap
        apiKey={GOOGLE_MAP_API_KEY}
        ref={this.mapRef}
        options={(maps) =>
          getMapOptions(
            this.props.data.map.maptype,
            this.props.data.map.drawing === true ||
              this.props.data.map.drawing === false
              ? this.props.data.map.drawing
              : 'initial',
            MapInstance,
            window.google.maps,
            this
          )
        }
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) =>
          apiIsLoaded(
            map,
            maps,
            this.props.data.map.offmarket,
            this,
            this.props.data.map.maptype,
            this.props.data
          )
        }
        center={
          this.props.data.map.coordinates
            ? this.props.data.map.coordinates
            : this.props.suburbs && this.props.suburbs[0]
            ? [this.props.suburbs[0].lat, this.props.suburbs[0].long]
            : this.state.center
        }
        zoom={this.props.data.map.zoom}
        onClick={this._onClick}
        onDragEnd={this._onDragEnd}
        onChildClick={this._onChildClick}
        draggable={
          this.props.data.map.drawing !== undefined
            ? !this.props.data.map.drawing
            : true
        }
        onChildMouseEnter={this._onChildMouseEnter}
        onChildMouseLeave={this._onChildMouseLeave}
        hoverDistance={40}
      >
        {!this.props.data.filteroffmarket &&
          !this.state.polygonFilter &&
          this.props.data.map.comingsoon.length > 0 &&
          this.props.data.map.comingsoon.map((comingsoon, idx) => {
            if (comingsoon.details.longitude && comingsoon.details.latitude) {
              return (
                <Marker
                  {...comingsoon}
                  key={comingsoon.details.property}
                  content={
                    <DynamicIcons
                      viewBox="0 0 24 28"
                      active={this.state.active || this.props.activePreviewProperty}
                      style={{
                        ...markerStyle,
                        zIndex:
                          this.props.activePreviewProperty ===
                            comingsoon.details.property ||
                          (this.state.active === comingsoon.details.property &&
                            !this.props.data.filteroffmarket)
                            ? '1'
                            : 'auto',
                      }}
                      icon="2DCSaleDropInIcon"
                      fill={
                        this.state.activeHovered === comingsoon.details.property ||
                        this.props.activePreviewProperty ===
                          comingsoon.details.property ||
                        (this.state.active === comingsoon.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#ffffff'
                          : '#FDBD26'
                      }
                      stroke={
                        this.state.activeHovered === comingsoon.details.property ||
                        this.props.activePreviewProperty ===
                          comingsoon.details.property ||
                        (this.state.active === comingsoon.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#FDBD26'
                          : '#FDBD26'
                      }
                      secondaryfill={
                        this.state.activeHovered === comingsoon.details.property ||
                        this.props.activePreviewProperty ===
                          comingsoon.details.property ||
                        (this.state.active === comingsoon.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#FDBD26'
                          : '#ffffff'
                      }
                    />
                  }
                >
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 35,
                      left: -183,
                      zIndex: 1000,
                    }}
                  >
                    <MapPropertyOverlay
                      key={comingsoon.details.property}
                      properties={comingsoon.details}
                      active={this.state.active}
                      setCurrentProperty={this.props.setCurrentProperty}
                      pathType={'property'}
                      currentPath={this.props.currentPath}
                      authentication={this.props.authentication}
                      apiRequestSaveProperty={this.props.apiRequestSaveProperty}
                      toggleSignUp={this.props.toggleSignUp}
                      settings={this.props.settings}
                    />
                  </div>
                </Marker>
              )
            }
          })}
        {this.props.data.map.offmarket.length > 0 &&
          !this.state.polygonFilter &&
          this.props.data.map.offmarket.map((offmarket, idx) => {
            // if(offmarket.details.longitude && offmarket.details.latitude) {
            return (
              <Marker
                {...offmarket}
                key={offmarket.details.property}
                content={
                  <DynamicIcons
                    viewBox="0 0 75 75"
                    active={this.state.active || this.props.activePreviewProperty}
                    style={{
                      ...markerStyleOffmarket,
                      zIndex:
                        this.props.activePreviewProperty ===
                          offmarket.details.property ||
                        (this.state.active === offmarket.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '1'
                          : 'auto',
                    }}
                    icon="2DCMapCircleIcon"
                    stroke={
                      this.state.activeHovered === offmarket.details.property ||
                      this.props.activePreviewProperty === offmarket.details.property ||
                      this.state.active === offmarket.details.property
                        ? '#f50057'
                        : '#35C0CA'
                    }
                  />
                }
              >
                <div
                  style={{
                    position: 'absolute',
                    bottom: 35,
                    left: -183,
                    zIndex: 1000,
                  }}
                >
                  <MapPropertyOverlay
                    key={offmarket.details.property}
                    properties={offmarket.details}
                    active={this.state.active}
                    setCurrentProperty={this.props.setCurrentProperty}
                    pathType={'property'}
                    currentPath={this.props.currentPath}
                    authentication={this.props.authentication}
                    apiRequestSaveProperty={this.props.apiRequestSaveProperty}
                    toggleSignUp={this.props.toggleSignUp}
                    settings={this.props.settings}
                  />
                </div>
              </Marker>
            )
            // }
          })}
        {!this.props.data.filteroffmarket &&
          !this.state.polygonFilter &&
          this.props.data.map.current.length > 0 &&
          this.props.data.map.current.map((current, idx) => {
            if (current.details.longitude && current.details.latitude) {
              return (
                <Marker
                  {...current}
                  key={current.details.property}
                  content={
                    <DynamicIcons
                      viewBox="0 0 24 28"
                      active={this.state.active || this.props.activePreviewProperty}
                      style={{
                        ...markerStyle,
                        zIndex:
                          this.props.activePreviewProperty ===
                            current.details.property ||
                          (this.state.active === current.details.property &&
                            !this.props.data.filteroffmarket)
                            ? '1'
                            : 'auto',
                      }}
                      icon="2DCSaleDropInIcon"
                      fill={
                        this.state.activeHovered === current.details.property ||
                        this.props.activePreviewProperty === current.details.property ||
                        (this.state.active === current.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#35C0CA'
                          : '#ffffff'
                      }
                      stroke={
                        this.state.activeHovered === current.details.property ||
                        this.props.activePreviewProperty === current.details.property ||
                        (this.state.active === current.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#35C0CA'
                          : '#35C0CA'
                      }
                      secondaryfill={
                        this.state.activeHovered === current.details.property ||
                        this.props.activePreviewProperty === current.details.property ||
                        (this.state.active === current.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#ffffff'
                          : '#35C0CA'
                      }
                    />
                  }
                >
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 35,
                      left: -183,
                      zIndex: 1000,
                    }}
                  >
                    <MapPropertyOverlay
                      key={current.details.property}
                      properties={current.details}
                      active={this.state.active}
                      setCurrentProperty={this.props.setCurrentProperty}
                      pathType={'property'}
                      currentPath={this.props.currentPath}
                      authentication={this.props.authentication}
                      apiRequestSaveProperty={this.props.apiRequestSaveProperty}
                      toggleSignUp={this.props.toggleSignUp}
                      settings={this.props.settings}
                    />
                  </div>
                </Marker>
              )
            }
          })}

        {!this.props.data.filteroffmarket &&
          this.state.polygonFilter &&
          this.state.mergedProperties.length > 0 &&
          this.state.mergedProperties.map((current, idx) => {
            if (
              current.details.propertyStatus !== 'offmarket' &&
              current.details.propertyStatus !== 'comingsoon' &&
              current.details.longitude &&
              current.details.latitude &&
              checkPointPoly(
                { lat: current.details.latitude, lng: current.details.longitude },
                this.state.polygonFilter
              )
            ) {
              return (
                <Marker
                  {...current}
                  key={current.details.property}
                  content={
                    <DynamicIcons
                      viewBox="0 0 24 28"
                      active={this.state.active || this.props.activePreviewProperty}
                      style={{
                        ...markerStyle,
                        zIndex:
                          this.props.activePreviewProperty ===
                            current.details.property ||
                          (this.state.active === current.details.property &&
                            !this.props.data.filteroffmarket)
                            ? '1'
                            : 'auto',
                      }}
                      icon="2DCSaleDropInIcon"
                      fill={
                        this.state.activeHovered === current.details.property ||
                        this.props.activePreviewProperty === current.details.property ||
                        (this.state.active === current.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#35C0CA'
                          : '#ffffff'
                      }
                      stroke={
                        this.state.activeHovered === current.details.property ||
                        this.props.activePreviewProperty === current.details.property ||
                        (this.state.active === current.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#35C0CA'
                          : '#35C0CA'
                      }
                      secondaryfill={
                        this.state.activeHovered === current.details.property ||
                        this.props.activePreviewProperty === current.details.property ||
                        (this.state.active === current.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#ffffff'
                          : '#35C0CA'
                      }
                    />
                  }
                >
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 35,
                      left: -183,
                      zIndex: 1000,
                    }}
                  >
                    <MapPropertyOverlay
                      key={current.details.property}
                      properties={current.details}
                      active={this.state.active}
                      setCurrentProperty={this.props.setCurrentProperty}
                      pathType={'property'}
                      currentPath={this.props.currentPath}
                      authentication={this.props.authentication}
                      apiRequestSaveProperty={this.props.apiRequestSaveProperty}
                      toggleSignUp={this.props.toggleSignUp}
                      settings={this.props.settings}
                    />
                  </div>
                </Marker>
              )
            }
          })}

        {!this.props.data.filteroffmarket &&
          this.state.polygonFilter &&
          this.state.mergedProperties.length > 0 &&
          this.state.mergedProperties.map((comingsoon, idx) => {
            if (
              comingsoon.details.propertyStatus === 'comingsoon' &&
              comingsoon.details.longitude &&
              comingsoon.details.latitude &&
              checkPointPoly(
                { lat: comingsoon.details.latitude, lng: comingsoon.details.longitude },
                this.state.polygonFilter
              )
            ) {
              return (
                <Marker
                  {...comingsoon}
                  key={comingsoon.details.property}
                  content={
                    <DynamicIcons
                      viewBox="0 0 24 28"
                      active={this.state.active || this.props.activePreviewProperty}
                      style={{
                        ...markerStyle,
                        zIndex:
                          this.props.activePreviewProperty ===
                            comingsoon.details.property ||
                          (this.state.active === comingsoon.details.property &&
                            !this.props.data.filteroffmarket)
                            ? '1'
                            : 'auto',
                      }}
                      icon="2DCSaleDropInIcon"
                      fill={
                        this.props.activePreviewProperty ===
                          comingsoon.details.property ||
                        (this.state.active === comingsoon.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#35C0CA'
                          : '#000000'
                      }
                      stroke={
                        this.state.activeHovered === comingsoon.details.property ||
                        this.props.activePreviewProperty ===
                          comingsoon.details.property ||
                        (this.state.active === comingsoon.details.property &&
                          !this.props.data.filteroffmarket)
                          ? '#35C0CA'
                          : '#000000'
                      }
                      secondaryfill="#ffffff"
                    />
                  }
                >
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 35,
                      left: -183,
                      zIndex: 1000,
                    }}
                  >
                    <MapPropertyOverlay
                      key={comingsoon.details.property}
                      properties={comingsoon.details}
                      active={this.state.active}
                      setCurrentProperty={this.props.setCurrentProperty}
                      pathType={'property'}
                      currentPath={this.props.currentPath}
                      authentication={this.props.authentication}
                      apiRequestSaveProperty={this.props.apiRequestSaveProperty}
                      toggleSignUp={this.props.toggleSignUp}
                      settings={this.props.settings}
                    />
                  </div>
                </Marker>
              )
            }
          })}
        {this.state.polygonFilter &&
          this.state.mergedProperties.length > 0 &&
          this.state.mergedProperties.map((offmarket, idx) => {
            if (
              offmarket.details.propertyStatus === 'offmarket' &&
              offmarket.details.longitude &&
              offmarket.details.latitude &&
              checkPointPoly(
                { lat: offmarket.details.latitude, lng: offmarket.details.longitude },
                this.state.polygonFilter
              )
            ) {
              return (
                <Marker
                  {...offmarket}
                  key={offmarket.details.property}
                  content={
                    <DynamicIcons
                      viewBox="0 0 75 75"
                      active={this.state.active || this.props.activePreviewProperty}
                      style={{
                        ...markerStyleOffmarket,
                        zIndex:
                          this.props.activePreviewProperty ===
                            offmarket.details.property ||
                          (this.state.active === offmarket.details.property &&
                            !this.props.data.filteroffmarket)
                            ? '1'
                            : 'auto',
                      }}
                      icon="2DCMapCircleIcon"
                      stroke={
                        this.state.activeHovered === offmarket.details.property ||
                        this.props.activePreviewProperty ===
                          offmarket.details.property ||
                        this.state.active === offmarket.details.property
                          ? '#f50057'
                          : '#35C0CA'
                      }
                    />
                  }
                >
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 35,
                      left: -183,
                      zIndex: 1000,
                    }}
                  >
                    <MapPropertyOverlay
                      key={offmarket.details.property}
                      properties={offmarket.details}
                      active={this.state.active}
                      setCurrentProperty={this.props.setCurrentProperty}
                      pathType={'property'}
                      currentPath={this.props.currentPath}
                      authentication={this.props.authentication}
                      apiRequestSaveProperty={this.props.apiRequestSaveProperty}
                      toggleSignUp={this.props.toggleSignUp}
                      settings={this.props.settings}
                    />
                  </div>
                </Marker>
              )
            }
          })}
        {this.state.polygonFilter &&
          this.state.mergedProperties.length > 0 &&
          this.state.mergedProperties.map((offmarket, idx) => {
            if (
              offmarket.details.propertyStatus === 'offmarket' &&
              offmarket.lat &&
              offmarket.lng &&
              checkPointPoly(
                { lat: offmarket.lat, lng: offmarket.lng },
                this.state.polygonFilter
              )
            ) {
              return (
                <Marker
                  {...offmarket}
                  key={offmarket.details.property}
                  content={
                    <DynamicIcons
                      viewBox="0 0 75 75"
                      active={this.state.active || this.props.activePreviewProperty}
                      style={{
                        ...markerStyleOffmarket,
                        zIndex:
                          this.props.activePreviewProperty ===
                            offmarket.details.property ||
                          (this.state.active === offmarket.details.property &&
                            !this.props.data.filteroffmarket)
                            ? '1'
                            : 'auto',
                      }}
                      icon="2DCMapCircleIcon"
                      stroke={
                        this.props.activePreviewProperty ===
                          offmarket.details.property ||
                        this.state.active === offmarket.details.property
                          ? '#f50057'
                          : '#35C0CA'
                      }
                    />
                  }
                >
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 35,
                      left: -183,
                      zIndex: 1000,
                    }}
                  >
                    <MapPropertyOverlay
                      key={offmarket.details.property}
                      properties={offmarket.details}
                      active={this.state.active}
                      setCurrentProperty={this.props.setCurrentProperty}
                      pathType={'property'}
                      currentPath={this.props.currentPath}
                      authentication={this.props.authentication}
                      apiRequestSaveProperty={this.props.apiRequestSaveProperty}
                      toggleSignUp={this.props.toggleSignUp}
                      settings={this.props.settings}
                    />
                  </div>
                </Marker>
              )
            }
          })}
      </GoogleMap>
    )
  }
}
