import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function HouseIcon(props: SvgIconProps) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    fill="none"
    viewBox="0 0 60 60"
  >
    {/* <rect
      width="59.5"
      height="59.5"
      x="0.25"
      y="0.25"
      stroke="#000"
      strokeWidth="0.5"
      rx="4.75"
    ></rect> */}
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.545 28.283a.21.21 0 01-.165-.081.205.205 0 01.041-.286l12.955-9.874a.205.205 0 11.249.325l-12.957 9.874a.202.202 0 01-.123.042M11.545 30.75a.21.21 0 01-.165-.08.207.207 0 01.041-.288l12.955-9.872a.205.205 0 11.249.327L11.668 30.71a.211.211 0 01-.123.04"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M38.268 28.9a.201.201 0 01-.124-.042l-13.768-10.49a.207.207 0 01-.04-.287.21.21 0 01.289-.038l13.766 10.49c.09.068.108.197.041.287a.21.21 0 01-.164.08M37.458 30.75a.21.21 0 01-.124-.04l-12.958-9.873a.207.207 0 01-.04-.288.21.21 0 01.289-.039l12.956 9.872c.09.069.108.198.041.288a.21.21 0 01-.164.08"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M49.798 28.9h-11.53a.204.204 0 01-.206-.204c0-.113.09-.205.205-.205h11.53a.205.205 0 010 .41"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask0"
      width="2"
      height="4"
      x="11"
      y="27"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.339 27.873h1.087v2.878H11.34v-2.878z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11.544 30.751a.205.205 0 01-.204-.204v-2.47a.205.205 0 01.41 0v2.47a.205.205 0 01-.206.204"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M49.8 30.751H37.458a.204.204 0 01-.205-.205c0-.114.09-.204.205-.204h12.34a.205.205 0 110 .41"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask1"
      width="3"
      height="3"
      x="48"
      y="28"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M48.917 28.49h1.087v2.26h-1.087v-2.26z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M49.798 30.75a.205.205 0 01-.204-.204v-1.851a.205.205 0 01.41 0v1.851a.205.205 0 01-.206.205"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M12.777 46.793a.205.205 0 01-.205-.204V29.605c0-.115.092-.205.205-.205.113 0 .205.09.205.205V46.59a.205.205 0 01-.205.204M36.224 46.793a.205.205 0 01-.205-.204V29.605c0-.115.092-.205.205-.205.113 0 .205.09.205.205V46.59a.205.205 0 01-.205.204M48.564 46.794a.205.205 0 01-.205-.205V30.547a.205.205 0 01.41 0v16.042a.205.205 0 01-.205.205"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask2"
      width="7"
      height="9"
      x="44"
      y="20"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M44.658 20.47h5.346v8.43h-5.346v-8.43z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M49.8 28.9a.207.207 0 01-.175-.097l-4.936-8.022a.205.205 0 01.349-.215l4.936 8.022a.206.206 0 01-.067.282.216.216 0 01-.108.03"
        clipRule="evenodd"
      ></path>
    </g>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M44.863 20.88H27.74a.204.204 0 01-.205-.206c0-.113.09-.204.205-.204h17.123a.205.205 0 010 .41M24.5 27.665a2.265 2.265 0 00-2.263 2.264 2.265 2.265 0 002.263 2.263 2.265 2.265 0 002.264-2.263 2.265 2.265 0 00-2.264-2.264m0 4.936a2.676 2.676 0 01-2.674-2.672c0-1.475 1.2-2.673 2.674-2.673a2.677 2.677 0 012.675 2.673c0 1.474-1.2 2.672-2.675 2.672M22.65 46.794a.205.205 0 01-.205-.205v-7.405a.205.205 0 01.41 0v7.405a.205.205 0 01-.205.205"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M26.352 39.39h-3.704a.205.205 0 010-.41h3.704a.205.205 0 010 .41"
      clipRule="evenodd"
    ></path>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M26.353 46.794a.205.205 0 01-.205-.205v-7.405a.205.205 0 01.41 0v7.405a.205.205 0 01-.205.205"
      clipRule="evenodd"
    ></path>
    <mask
      id="mask3"
      width="40"
      height="30"
      x="11"
      y="17"
      maskUnits="userSpaceOnUse"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.338 46.794h38.665V17.999H11.338v28.795z"
        clipRule="evenodd"
      ></path>
    </mask>
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M15.45 40.831h4.525v-4.526H15.45v4.526zm4.732.411h-4.936a.204.204 0 01-.205-.206v-4.938c0-.113.09-.204.205-.204h4.936c.113 0 .204.091.204.204v4.938a.205.205 0 01-.204.206zM29.025 40.831h4.524v-4.526h-4.524v4.526zm4.73.411H28.82a.205.205 0 01-.205-.206v-4.938c0-.113.092-.204.205-.204h4.936c.114 0 .205.091.205.204v4.938c0 .115-.09.206-.205.206zM40.132 40.831h4.525v-4.526h-4.525v4.526zm4.731.411h-4.936a.205.205 0 01-.204-.206v-4.938c0-.113.091-.204.204-.204h4.936c.115 0 .205.091.205.204v4.938c0 .115-.09.206-.205.206zM24.5 32.602a.205.205 0 01-.205-.205V27.46a.205.205 0 01.41 0v4.937a.205.205 0 01-.205.205"></path>
      <path d="M26.968 30.134h-4.937a.205.205 0 010-.41h4.938a.205.205 0 010 .41M49.798 46.794H11.543a.205.205 0 010-.41h38.255a.205.205 0 010 .41M45.434 25.815h-7.512a.205.205 0 010-.41h7.512a.205.205 0 010 .41M43.914 23.347h-9.231a.205.205 0 110-.41h9.231a.205.205 0 010 .41M20.182 38.154h-4.936a.205.205 0 010-.409h4.936a.205.205 0 010 .41M44.863 38.154h-4.936a.205.205 0 010-.409h4.936a.205.205 0 110 .41M33.756 38.154H28.82a.205.205 0 010-.409h4.936a.205.205 0 110 .41"></path>
    </g>
  </svg>
  );
}

export function HouseIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HouseIcon />
      <HouseIcon color="primary" />
      <HouseIcon color="secondary" />
      <HouseIcon color="action" />
      <HouseIcon color="disabled" />
      <HouseIcon style={{ color: green[500] }} />
    </div>
  );
}
