import React, { useState } from 'react';
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import Geocode from "react-geocode";

import MapCard from '../Map';

import { withStyles, Grid } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = theme => ({
    root: {
        padding: theme.spacing(3)
    }
});

const MapsSection = props => {
    const {
        classes,

        property,
        listingType
    } = props;
    const [routerChange] = useRouterChange()

    const [mapData, setMapData] = useState({
        map: {
            coordinates: [-37.796300, 144.925120],
            markers: [{ lat: -37.796300, lng: 144.925120 }],
            nearby: [{ lat: -37.793541, lng: 144.931722 }],
            zoom: 15
        }
    })
    React.useEffect(() => {
        console.log(property, "PROPERTY")
    }, [])

    React.useEffect(() => {
        setGoogleAPI();
    }, [property]);

    function setGoogleAPI() {
        Geocode.setApiKey(GOOGLE_MAP_API_KEY);
        if (property && property.addressText) {
            Geocode.fromAddress(property.addressText).then(
                response => {
                    const GEOCODE_DATA = response.results[0];
                    setMapData((prevData) => ({
                        map: {
                            ...prevData.map,
                            coordinates: [GEOCODE_DATA.geometry.location.lat, GEOCODE_DATA.geometry.location.lng],
                            markers: [GEOCODE_DATA.geometry.location],
                            nearby: [GEOCODE_DATA.geometry.location]
                        }
                    }))
                },
                error => {

                }
            );
        }
    }

    return (
        <div className={classes.root}>
            {property &&
                <Grid container spacing={3}>
                    {mapData && <Grid item xs={6} onClick={() => routerChange('/' + listingType + '/' + property?.property + '/location/map', { type: 'map' })}>
                        <MapCard data={
                            {
                                mode: 'map',
                                title: 'Map View',
                                ...mapData,
                                address: property.addressText,
                                interactive: false
                            }
                        } />
                    </Grid>}
                    {mapData && <Grid item xs={6} onClick={() => routerChange('/' + listingType + '/' + property?.property + '/location/street', { type: 'street' })} >
                        <MapCard data={
                            {
                                mode: 'street',
                                title: 'Street View',
                                ...mapData,
                                address: property.addressText,
                                interactive: false
                            }
                        } />
                    </Grid>}
                </Grid>}
        </div>
    )
}

export default withStyles(styles)(MapsSection);
