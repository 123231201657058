import React from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';

const ReferralDatePicker = ({
  label,
  selectedDate,
  handleDateChange,
  minDate,
  disabled,
  dateFormat = 'DD/MM/yyyy',
  width,
  marginLeft,
  marginRight,
}) => {
  return (
    <KeyboardDatePicker
      style={{ width, marginLeft, marginRight }}
      autoOk
      clearable
      disabled={disabled}
      disableToolbar
      variant="inline"
      format={dateFormat}
      margin="normal"
      id="date-picker-inline"
      minDate={minDate}
      label={label}
      value={selectedDate ? selectedDate : null}
      onChange={handleDateChange}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
    />
  );
};

export default ReferralDatePicker;
