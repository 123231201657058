import { numberFormatter } from './numberFormatter'

export const distanceRangeLabelFormatter = (
  distanceMin,
  distanceMax,
  distanceMinGlobalFilters,
  distanceMaxGlobalFilters,
  labelSuffix
) => {
  let addDistancePrefix
  let addDistanceSuffix

  if (
    distanceMin === distanceMinGlobalFilters &&
    distanceMax === distanceMaxGlobalFilters
  ) {
    return 'Any'
  }

  addDistancePrefix = distanceMin === distanceMinGlobalFilters
  addDistanceSuffix = distanceMax === distanceMaxGlobalFilters

  return `${addDistancePrefix ? 'Below' : '' + numberFormatter(distanceMin)} ${
    labelSuffix ? labelSuffix : ''
  } to ${numberFormatter(distanceMax)}${addDistanceSuffix ? '+ km' : ' km'} ${
    labelSuffix ? labelSuffix : ''
  }`
}
