export const getFilters = state => {
    return state.filters
}

export const getReferenceFilters = state => {
    return state.referenceFilters
}

export const getFilterScope = state => {
    return state.settings.showFilterWeb.type
}

export const getTypesCounters = state => {
    return state.referenceFilters.typesCounters
}