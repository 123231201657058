import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'

import Heading from './Heading'
import PropertyType from './PropertyType'
import SliderContainer from './SliderContainer'
import FeatureList from './FeatureList'
import AdvancedSearch from './AdvancedSearch'
import StateSelect from './StateSelect'
import CustomSelect from './CustomSelect'
import CustomField from './CustomField'
import CustomCheckbox from './CustomCheckbox'
import NearProperties from './NearProperties'
import DistanceContainer from './DistanceContainer'
import CustomDatePicker from './CustomDatePicker'

import { DEFAULT_FILTERS, DEFAULT_MPP_FILTERS } from 'store/reducers/default'

import {
  PROPERTY_TYPE,
  PROPERTY_DETAILS,
  PROPERTY_LAND_SIZE,
  PROPERTY_FEATURES,
  PROPERTY_ADVANCE,
  PROPERTY_KEYWORD,
  PERFECT_PROPERTY,
} from './DefaultValues'

/* import AdvancedSearch from '../../Common/GlobalFilters/AdvancedSearch'
import DistanceContainer from '../../Common/GlobalFilters/DistanceContainer'
import PropertyType from '../../Common/GlobalFilters/PropertyType'
import StateSelect from '../../Common/GlobalFilters/StateSelect'
 */
import AvailableDate from '../../Common/GlobalFilters/AvailableDate'
import Bathrooms from '../../Common/GlobalFilters/Bathrooms'
import Bedrooms from '../../Common/GlobalFilters/Bedrooms'
import Carparking from '../../Common/GlobalFilters/Carparking'
import Features from '../../Common/GlobalFilters/Features'
import Furnished from '../../Common/GlobalFilters/Furnished'
import IncludeNearbySuburbs from '../../Common/GlobalFilters/IncludeNearbySuburbs'
import Keywords from '../../Common/GlobalFilters/Keywords'
import PriceSlider from '../../Common/GlobalFilters/PriceSlider'

import LandSize from '../../Common/GlobalFilters/LandSize'
import SearchbarFilter from '../../Common/GlobalFilters/SearchbarFilter'
import UnderOffer from '../../Common/GlobalFilters/UnderOffer'

const FilterContainer = styled.div`
  width: 100%;
  padding: 10px 23px 30px;
  overflow: hidden;
`

const Wrap = styled.div``
const SelectLabel = styled.div`
  margin-bottom: -10px;
  padding-top: 10px;
  color: rgb(77, 77, 77);
`

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`
const Col = styled.div`
  width: 50%;
  padding-right: 10%;
`

const establishmentOption = [
  {
    id: 0,
    label: 'Any',
    value: 'any',
  },
  {
    id: 1,
    label: 'New',
    value: 'new',
  },
  {
    id: 2,
    label: 'Established',
    value: 'established',
  },
]

const furnitureOption = [
  {
    id: 0,
    label: 'Any',
    value: 'any',
  },
  {
    id: 1,
    label: 'Furnished',
    value: 'furnished',
  },
  {
    id: 2,
    label: 'Unfurnished',
    value: 'unfurnished',
  },
]

const landSizeOptions = [
  {
    label: 'Any',
    value: 'any',
  },
  {
    label: '10 ㎡',
    value: '10',
  },
  {
    label: '20 ㎡',
    value: '20',
  },
  {
    label: '30 ㎡',
    value: '30',
  },
  {
    label: '40 ㎡',
    value: '40',
  },
  {
    label: '50 ㎡',
    value: '50',
  },
  {
    label: '60 ㎡',
    value: '60',
  },
  {
    label: '70 ㎡',
    value: '70',
  },
  {
    label: '80 ㎡',
    value: '80',
  },
  {
    label: '90 ㎡',
    value: '90',
  },
  {
    label: '100 ㎡',
    value: '100',
  },
  {
    label: '200 ㎡',
    value: '200',
  },
  {
    label: '300 ㎡',
    value: '300',
  },
  {
    label: '400 ㎡',
    value: '400',
  },
  {
    label: '500 ㎡',
    value: '500',
  },
  {
    label: '600 ㎡',
    value: '600',
  },
  {
    label: '700 ㎡',
    value: '700',
  },
  {
    label: '800 ㎡',
    value: '800',
  },
  {
    label: '900 ㎡',
    value: '900',
  },
  {
    label: '1,000 ㎡',
    value: '1000',
  },
  {
    label: '1,200 ㎡',
    value: '1200',
  },
  {
    label: '1,500 ㎡',
    value: '1500',
  },
  {
    label: '1,750 ㎡',
    value: '1750',
  },
  {
    label: '2,000 ㎡',
    value: '2000',
  },
  {
    label: '3,000 ㎡',
    value: '3000',
  },
  {
    label: '4,000 ㎡',
    value: '4000',
  },
  {
    label: '5,000 ㎡',
    value: '5000',
  },
  {
    label: '10,000 ㎡',
    value: '10000',
  },
  {
    label: '20,000 ㎡',
    value: '20000',
  },
  {
    label: '30,000 ㎡',
    value: '30000',
  },
  {
    label: '40,000 ㎡',
    value: '40000',
  },
  {
    label: '50,000 ㎡',
    value: '50000',
  },
  {
    label: '100,000 ㎡',
    value: '100000',
  },
  {
    label: '200,000 ㎡',
    value: '200000',
  },
  {
    label: '300,000 ㎡',
    value: '300000',
  },
  {
    label: '400,000 ㎡',
    value: '400000',
  },
  {
    label: '500,000 ㎡',
    value: '500000',
  },
  {
    label: '600,000 ㎡',
    value: '600000',
  },
  {
    label: '700,000 ㎡',
    value: '700000',
  },
  {
    label: '800,000 ㎡',
    value: '800000',
  },
  {
    label: '900,000 ㎡',
    value: '900000',
  },
]

const nearDefaults = [
  {
    id: '0',
    label: 'Capital city',
    active: true,
  },
  /* {
    id:'1',
    label:'Suburbs',
    active:false
  },{
    id:'2',
    label:'Rural setting',
    active:false
  },{
    id:'3',
    label:'Country Town',
    active:false
  } */
]

const FilterV2 = (props) => {
  const [propertyType, setPropertyType] = useState(
    props.filters ? props.filters.filters.propertyType : PROPERTY_TYPE
  )
  const [propertyDetails, setPropertyDetails] = useState(
    props.filters ? props.filters.filters.propertyDetails : PROPERTY_DETAILS
  )
  const [landSize, setLandSize] = useState(
    props.filters ? props.filters.filters.landSize : PROPERTY_LAND_SIZE
  )
  const [features, setFeatures] = useState(
    props.filters ? props.filters.filters.features : PROPERTY_FEATURES
  )
  const [establishment, setEstablishment] = useState(
    props.filters ? props.filters.filters.establishment : null
  )
  const [keywords, setKeywords] = useState(
    props.filters ? props.filters.filters.keywords : PROPERTY_KEYWORD
  )
  const [advanceProperty, setAdvanceProperty] = useState(
    props.filters ? props.filters.filters.advanceProperty : PROPERTY_ADVANCE
  )
  const [perfectProperty, setPerfectProperty] = useState(
    props.filters
      ? props.filters.perfectProperty
        ? props.filters.perfectProperty
        : PERFECT_PROPERTY
      : PERFECT_PROPERTY
  )
  const [furniture, setFurniture] = useState(
    props.filters ? props.filters.filters.furniture : null
  )
  const [availableDate, setAvailableDate] = useState(
    props.filters ? props.filters.filters.availableDate : null
  )
  const [distanceFromKm, setDistanceFromKm] = useState(
    props.filters && props.filters.filters.distanceFromKm
      ? props.filters.filters.distanceFromKm
      : {
          near: nearDefaults,
          value: { min: 0, max: 500 },
          scale: [0, 500],
        }
  )

  const [dataInitialized, setDataInitialized] = useState(false)
  const [detailsCleared, setDetailsCleared] = useState(false)

  const txtKeyword = useRef('')
  const txtLandMax = useRef('')
  const txtLandMin = useRef('')

  const today = new Date()

  const INITIAL_STATE = {
    filters: DEFAULT_FILTERS,
    perfectProperty: DEFAULT_MPP_FILTERS,
    suburbs: [],
    sorting: null,
    includeNearby: true,
  }

  /* Watch all data */
  useEffect(() => {
    const baseFilters = props.filters.filters
    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }
    const isReset = _.isEqual(
      JSON.stringify(baseFilters),
      JSON.stringify(INITIAL_STATE.filters)
    )
    if (props.handleFilter) {
      switch (true) {
        case props.filters &&
          !_.isEqual(baseFilters.propertyType, propertyType) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, { propertyType: propertyType }))
          break

        case props.filters &&
          !_.isEqual(baseFilters.propertyDetails, propertyDetails) &&
          !props.isClearFilter &&
          !detailsCleared:
          props.handleFilter(Object.assign(filterData, propertyDetails))

          break

        case props.filters &&
          !_.isEqual(baseFilters.propertyDetails, propertyDetails) &&
          !props.isClearFilter &&
          detailsCleared:
          props.handleFilter(Object.assign(filterData, PROPERTY_DETAILS))

          break
        case props.filters &&
          !_.isEqual(baseFilters.landSize, landSize) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, landSize))
          break

        case props.filters &&
          !_.isEqual(baseFilters.features, features) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, features))
          break

        case props.filters &&
          !_.isEqual(baseFilters.establishment, establishment) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, establishment))
          break

        case props.filters &&
          !_.isEqual(baseFilters.keywords, keywords) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, keywords))
          break

        case props.filters &&
          // !_.isEqual(baseFilters.advanceProperty, advanceProperty) &&
          !props.isClearFilter:
          props.handleFilter({ ...filterData, advanceProperty })
          break

        case props.filters &&
          !_.isEqual(baseFilters.perfectProperty, perfectProperty) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, perfectProperty))
          break

        /* case props.filters &&
          !_.isEqual(baseFilters.furniture, furniture) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, furniture))
          break

        case props.filters &&
          !_.isEqual(baseFilters.availableDate, availableDate) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, availableDate))
          break */

        case props.filters &&
          !_.isEqual(baseFilters.distanceFromKm, distanceFromKm) &&
          !props.isClearFilter:
          props.handleFilter({ ...filterData, distanceFromKm })
          break
      }
    }
  }, [
    propertyType,
    propertyDetails,
    features,
    landSize,
    establishment,
    keywords,
    advanceProperty,
    perfectProperty,
    distanceFromKm,
  ])

  useEffect(() => {
    if (props.isClearFilter) {
      setLandSize({ ...landSize, min: '', max: '' })
      setKeywords({ ...keywords, keyword: '', offer: false })
      setPerfectProperty(PERFECT_PROPERTY)
      setFurniture(null)
      setAvailableDate(null)
      // setDistanceFromKm({near: nearDefaults, value: 0})
      clearTextField()
    }
  }, [props.isClearFilter])

  useEffect(() => {
    if (propertyType && props.propertyTypesCount) {
      const types = propertyType
      const typeKeys = Object.keys(props.propertyTypesCount)
      types.map((i, id) => {
        if (typeKeys.includes(i.title)) {
          Object.assign(types[id], {
            count:
              props.propertyTypesCount[i.title] !== 0
                ? props.propertyTypesCount[i.title]
                : 0,
          })
        } else {
          Object.assign(types[id], { count: 0 })
        }
      })
      setPropertyType(types)
    } else {
      const types = propertyType
      types.map((i, id) => {
        Object.assign(types[id], { count: 0 })
      })
      setPropertyType(types)
    }
  }, [props.propertyTypesCount])

  const clearTextField = () => {
    if (txtLandMin && txtLandMin.current !== '') {
      txtLandMin.current.value = ''
    }
    if (txtLandMax && txtLandMax.current !== '') {
      txtLandMax.current.value = ''
    }
    if (txtLandMax && txtLandMax.current !== '') {
      txtLandMax.current.value = ''
    }
  }

  const handlePropertyType = (data) => {
    setPropertyType(data)
  }

  const handlePropertyDetails = (data) => {
    if (!_.isEqual(data, props.filters.filters.propertyDetails)) {
      setDetailsCleared(false)
      setPropertyDetails(data)
    } else {
      setDetailsCleared(true)
    }
  }

  const handleFeatures = (data) => {
    setFeatures(data)
  }

  const handleEstablishment = (data) => {
    setEstablishment(data)
  }

  const handleFurniture = (data) => {
    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }
    if (props.handleFilter) {
      props.handleFilter({ ...filterData, furniture: data })
    }
    setFurniture(data)
  }

  const handleAvailableDate = (data) => {
    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }
    if (props.handleFilter) {
      props.handleFilter({ ...filterData, availableDate: data })
    }
    setAvailableDate(data)
  }

  const handleAdvanceProperty = (data) => {
    setAdvanceProperty(data)
  }

  const handlePerfectProperty = (data, type) => {
    let params = Object.assign({}, perfectProperty)
    if (type === 'state') {
      params.state = data
    }
    if (type === 'near') {
      params.near = data
    }
    if (type === 'radius') {
      params.radius = data
    }
    setPerfectProperty(params)
  }

  const handleLandSizeMin = (data) => {
    setLandSize({ ...landSize, min: data !== 'any' ? parseInt(data) : '' })
  }

  const handleLandSizeMax = (data) => {
    setLandSize({ ...landSize, max: data !== 'any' ? parseInt(data) : '' })
  }

  const handleDistanceFromKm = (data) => {
    let newDistance

    if (distanceFromKm?.near) {
      newDistance = { near: distanceFromKm?.near, ...data }
    } else {
      newDistance = { near: nearDefaults, ...data }
    }

    setDistanceFromKm(newDistance)

    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }

    if (props.handleFilter && props.filters) {
      props.handleFilter({ ...filterData, distanceFromKm: newDistance })
    }
  }

  return (
    <FilterContainer>
      {props.filterType && props.filterType === 'perfect-property' && (
        <div>
          <Heading variant="h6" style={{ fontWeight: 400 }}>
            Where are you looking?
          </Heading>
          <StateSelect
            isClearFilter={props.isClearFilter}
            listData={perfectProperty.state}
            handlePerfectProperty={handlePerfectProperty}
          />

          {/* <NearProperties 
            isClearFilter={props.isClearFilter}   
            distanceFromKm={distanceFromKm}     
            listData={distanceFromKm && distanceFromKm.near ? distanceFromKm.near : nearDefaults}
            handleDistanceFromKm={handleDistanceFromKm}
          /> */}

          <DistanceContainer
            isClearFilter={props.isClearFilter}
            distanceFromKm={distanceFromKm}
            radius={distanceFromKm ? distanceFromKm.value : 0}
            handleDistanceFromKm={handleDistanceFromKm}
            scaledDistance={distanceFromKm.scale}
          />

          <AdvancedSearch
            title={'What’s most important to you?'}
            isToggle={false}
            handleAdvanceProperty={handleAdvanceProperty}
            isClearFilter={props.isClearFilter}
            data={advanceProperty}
            isMPP={true}
          />
        </div>
      )}

      <Heading variant="h6" style={{ fontWeight: 400 }}>
        Select Property Type
      </Heading>
      <PropertyType
        handlePropertyType={handlePropertyType}
        isClearFilter={props.isClearFilter}
        propertyType={propertyType}
        propertyTypesCount={props.propertyTypesCount}
      />
      <SliderContainer
        handlePropertyDetails={handlePropertyDetails}
        isClearFilter={props.isClearFilter}
        propertyDetails={propertyDetails}
        path={props.path}
        filters={props.filters}
      />

      {props.path && props.path === 'rent' && (
        <Wrap>
          <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
            Available Date
          </Heading>
          <CustomDatePicker
            value={availableDate}
            handleDate={handleAvailableDate}
            isClearFilter={props.isClearFilter}
            minDate={today}
          />
        </Wrap>
      )}

      {props.path && props.path === 'rent' && (
        <Wrap>
          <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
            Furnished / Unfurnished
          </Heading>
          <CustomSelect
            options={furnitureOption}
            handleSelect={handleFurniture}
            isClearFilter={props.isClearFilter}
            value={furniture}
          />
        </Wrap>
      )}
      <Wrap>
        <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
          Land Size
        </Heading>
        <Row>
          <Col>
            <SelectLabel>Min</SelectLabel>
            <CustomSelect
              options={landSizeOptions}
              handleSelect={handleLandSizeMin}
              isClearFilter={props.isClearFilter}
              value={landSize.min}
              max={landSize.max !== 'any' ? landSize.max : null}
              placeholder={'Min'}
            />
          </Col>
          <Col>
            <SelectLabel>Max</SelectLabel>
            <CustomSelect
              options={landSizeOptions}
              handleSelect={handleLandSizeMax}
              isClearFilter={props.isClearFilter}
              value={landSize.max}
              min={landSize.min !== 'any' ? landSize.min : null}
              max={landSize.max !== 'any' ? landSize.max : null}
              placeholder={'Max'}
            />
          </Col>
        </Row>
      </Wrap>
      <FeatureList
        handleFeatures={handleFeatures}
        isClearFilter={props.isClearFilter}
        features={features}
      />
      {/* <Wrap>
        <Heading variant="body1" style={{fontWeight:400, color:'#4d4d4d'}}>New / Established</Heading>
        <CustomSelect 
          options={establishmentOption} 
          handleSelect={handleEstablishment} 
          isClearFilter={props.isClearFilter}
          value={establishment}
        />
      </Wrap> */}
      <Wrap>
        <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
          Keywords
        </Heading>
        <CustomField
          inputRef={txtKeyword}
          // value={keywords.keyword}
          defaultValue={keywords.keyword}
          type="text"
          placeholder="eg. waterfront, street name etc"
          containerstyle={{ paddingBottom: 0 }}
          onBlur={(e) => {
            let value = e.target.value
            setKeywords({ ...keywords, keyword: value })
          }}
        />
        <br />
        <CustomCheckbox
          checked={keywords.offer}
          label="Exclude under offer"
          onChange={(e) => {
            let value = e.target.value
            setKeywords({ ...keywords, offer: !keywords.offer })
          }}
        />
      </Wrap>

      {/* {!props.filterType && props.filterType !== 'perfect-property' && (
        <AdvancedSearch
          title={'Advance Property Search'}
          subTitle={'What’s most important to you?'}
          isToggle={true}
          handleAdvanceProperty={handleAdvanceProperty}
          isClearFilter={props.isClearFilter}
          data={advanceProperty}
        />
      )} */}
      {!props.filterType && props.filterType === 'perfect-property' && (
        <div style={{ height: 30 }}></div>
      )}
    </FilterContainer>
  )
}

export default FilterV2
