import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { Button, TextField, withStyles } from '@material-ui/core';

import { requestPUTAPISuperadminAccount } from 'store/api'
import { superadminActions } from 'store/actions'

import AlertNotification from 'components/Web/Form/AlertNotification'

const styles = (theme) => ({
  root: {
    padding: '48px 0',
    '@media (max-width: 1024px)': {
      paddingRight: 12,
    },
  },
  inputs: {
    marginBottom: 20,
  },
  submitContainer: {
    textAlign: 'center',
  },
})

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup.string('Enter your first name').required('First name is required'),
  lastName: yup.string('Enter your last name').required('Last name is required'),
  // password: yup
  //   .string('Enter your password')
  //   .min(8, 'Password should be of minimum 8 characters length')
  //   .required('Password is required'),
})

const SuperAccountSettings = ({ classes, user, auth }) => {
  const dispatch = useDispatch()
  const initialValues = {
    email: user?.email ?? '',
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    // password: user?.user?.password ?? ''
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = {
        entity: {
          ...values,
        },
        user: user?._id ?? '',
      }
      dispatch(
        requestPUTAPISuperadminAccount(params, 'SUPERADMIN_UPDATE_ACCOUNT_SETTINGS')
      )
    },
  })

  const [notificationOptions, setNotificationOptions] = useState(null)
  const [isNotification, setIsNotification] = useState(false)
  const [recentStatus, setRecentStatus] = useState(false)

  function closeNotification() {
    setIsNotification(false)
  }

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  }  

  useEffect(() => {
    if (auth?.status === null) {
      setIsNotification(false)
    }
    if (auth?.status?.includes('REQUEST')) {
      setIsNotification(true);
      setNotificationOptions({
        message: "Updating account settings",
        severity: "info",
      });
    }
    if (auth?.status?.includes('SUCCESS') && recentStatus) {
      setIsNotification(true);
      setNotificationOptions({
        message: "Account updated",
        severity: "success",
      });
    }
    if (auth?.status?.includes('FAILED') && recentStatus) {
      setIsNotification(true);
      setNotificationOptions({
        message: "Account update failed",
        severity: "error",
      });
    }
    return () => {
      setRecentStatus(true)
    }
  }, [auth])

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <form onSubmit={formik.handleSubmit}>
        <TextField
          className={classes.inputs}
          variant="outlined"
          fullWidth
          id="firstName"
          name="firstName"
          label="First Name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
        <TextField
          className={classes.inputs}
          variant="outlined"
          fullWidth
          id="lastName"
          name="lastName"
          label="Last Name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
        <TextField
          className={classes.inputs}
          variant="outlined"
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        {/* <TextField
          className={classes.inputs}
          variant="outlined"
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          disabled
        /> */}
        <div className={classes.submitContainer}>
          <Button color="primary" variant="contained" type="submit" disableElevation>
            Update
          </Button>
        </div>
      </form>
    </div>
  )
}

export default withStyles(styles)(SuperAccountSettings)
