import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import Authentication from '../../components/Mobile/Form/AuthenticationV2';
import Image from '../../components/Web/Image';
import Footer from '../../components/Mobile/Footer';

import TextImage03 from '../../assets/images/content/textimage03.svg';
import TextImage02 from '../../assets/images/content/textimage02.svg';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    padding: '16px 16px 60px',
    backgroundColor: '#F5F5F5',
    minHeight: 'calc(100vh - 92px)',
    maxWidth: 1440,
    margin: '0 auto',
  },
  mainContentWrap: {
    padding: 0,
  },
  sectionHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 48,
    lineHeight: '45.5px',
    color: theme.palette.gray.main,
    // marginBottom: theme.spacing(6),
    letterSpacing: '-0.78px',
    textAlign: 'center',
    marginBottom: '25px',

    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
      fontSize: 56,
    },
  },
  innerWrap: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionSubHeading: {
    fontFamily: 'Montserrat, sans',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.52px',
    textAlign: 'center',
  },
  sectionLeadText: {
    color: theme.palette.gray.main,
    // marginBottom: theme.spacing(4),
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '1.6em',
    textAlign: 'center',
  },
  textContents: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    display: 'flex',
    paddingLeft: '15px !important',
    paddingRight: '0 !important',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 580,
  },
  imageWrap: {
    maxWidth: 580,
    margin: '0 auto 30px',
  },
});

const LoginSignUp = (props) => {
  const { classes, authentication } = props;
  const [routerChange] = useRouterChange();

  useEffect(() => {
    document.querySelector('#scroll-container').style.backgroundColor = '#f5f5f5';

    return () => {
      document.querySelector('#scroll-container').style.backgroundColor = 'initial';
    };
  }, []);

  function routeChangeBack() {
    routerChange('back');
  }

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth={false} className={classes.mainContentWrap}>
          <Grid container spacing={2} className={classes.innerWrap}>
            <Grid item xs={12} style={{ width: '100%', margin: '0 auto' }}>
              <Authentication goBack={routeChangeBack} location={props.match} history={props.history} />
            </Grid>
            <Grid item xs={7} className={classes.rightColumn}>
              {props.match && props.match.path === '/login' && (
                <div>
                  <div className={classes.imageWrap} style={{ maxWidth: 398 }}>
                    <Image
                      src={TextImage02}
                      style={{ width: 475 }}
                      aspectRatio={4 / 3.6}
                      color={'transparent'}
                      imageStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12}>
                      <Typography component={'h1'} className={classes.sectionHeading}>
                        <strong>find</strong>
                        where
                        <br />
                        you belong
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.textContents}>
                      <Typography component={'h6'} className={classes.sectionLeadText}>
                        A home and community you love. Somewhere you feel safe, grounded, nurtured, fulfilled. Home
                        feels better here.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              {props.match && props.match.path === '/signup' && (
                <div>
                  <div className={classes.imageWrap} style={{ maxWidth: 398 }}>
                    <Image
                      src={TextImage03}
                      style={{ width: 475 }}
                      aspectRatio={4 / 3.6}
                      color={'transparent'}
                      imageStyle={{ objectFit: 'contain' }}
                    />
                  </div>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12}>
                      <Typography component={'h1'} className={classes.sectionHeading}>
                        <strong>find</strong>
                        where you
                        <br />
                        belong
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.textContents}>
                      <Typography component={'h6'} className={classes.sectionLeadText}>
                        A home and community you love. Somewhere you feel safe, grounded, nurtured, fulfilled. Home
                        feels better here.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginSignUp));
