export const SET_STAT_VIEW = "statistics/SET_STAT_VIEW";
export const SET_STAT_VIEW_SUCCESS = "statistics/SET_STAT_VIEW_SUCCESS";
export const SET_STAT_VIEW_FAIL = "statistics/SET_STAT_VIEW_FAIL";

export const SET_STATISTICS_POOL = "statistics/SET_STATISTICS_POOL";
export const CLEAR_STATISTICS_POOL = "statistics/SET_STATISTICS_POOL";

export const GET_STATISTICS_DATA = "statistic/GET_STATISTICS_DATA";
export const GET_STATISTICS_DATA_SUCCESS = "statistic/GET_STATISTICS_DATA_SUCCESS";
export const GET_STATISTICS_DATA_FAIL = "statistic/GET_STATISTICS_DATA_FAIL";



export const CLEAR_STATISTICS_DATA = "statistics/CLEAR_STATISTICS_DATA";