import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import 'lightgallery.js/dist/css/lightgallery.css'
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from 'react-lightgallery'
import Image from '../Image'
import { Grid, Typography, withStyles } from '@material-ui/core'

const GalleryStyles = (theme) => ({
  root: {
    //    maxHeight: 596,
    //    overflow: 'hidden'
  },
  smallImages: {
    ' & > div': {
      marginBottom: theme.spacing(1.25),
      '& img': {
        cursor: 'pointer',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  imageCounter: {
    position: 'relative',
    '& strong': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Montserrat, sans',
      fontSize: '48px',
      letterSpacing: '-0.88px',
      lineHeight: '44px',
      fontWeight: 500,
      color: '#ffffff',
      pointerEvents: 'none',
      textShadow: '0 0 5px rgba(0,0,0,0.38)',
    },
  },
  primaryImage: {
    '& > div': {
      height: '100%',
    },
  },
})

const PhotoItem = ({ image, thumb, group }) => (
  <div style={{ maxWidth: '250px', width: '200px', padding: '5px' }}>
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: '100%' }} />
    </LightgalleryItem>
  </div>
)
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
}

const CoverGallery = withStyles(GalleryStyles)((props) => {
  const { openGallery } = useLightgallery()

  useEffect(() => {
    if (document.querySelector(".lg-on .MuiAppBar-positionFixed")) {
      document.querySelector(".lg-on .MuiAppBar-positionFixed").style.zIndex = 1
    }
    if (props.selectImageIndex !== null && props.selectImageIndex !== undefined) {
      openGallery('group2', props.selectImageIndex)
    }
  }, [props.selectImageIndex])

  return null

})
CoverGallery.propTypes = {
  className: PT.string,
}

const LightBoxGallery = (props) => {
  const { data } = props

  return (
    <div className="content">
      <div>
        <LightgalleryProvider
          onCloseAfter={(e) => {
            document.querySelector("header.MuiAppBar-positionFixed").style.zIndex = 1050
          }}
          plugins={['lg-fullscreen.js', 'lg-thumbnail.js', 'lg-video.js', 'lg-zoom.js']}
          lightgallerySettings={
            {
              autoplayFirstVideo: false,
              youtubePlayerParams: { autoplay: 0 },
            }
          }
        >
          <CoverGallery data={data} {...props} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'none',
            }}
          >
            {data.map((p, idx) => (
              <PhotoItem key={idx} image={p} group="group2" />
            ))}
          </div>
        </LightgalleryProvider>
      </div>
    </div >
  )
}

export default LightBoxGallery
