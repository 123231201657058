export const PROPERTY_ACTION_TYPES = {
  GET_PROPERTY_DETAILS: {
    REQUEST: 'property/GET_PROPERTY_DETAILS_REQUEST',
    SUCCESS: 'property/GET_PROPERTY_DETAILS_SUCCESS',
    FAILED: 'property/GET_PROPERTY_DETAILS_FAILED',
  },
  POST_REQUEST_OTP_VENDOR_APPROVAL: {
    REQUEST: 'property/POST_REQUEST_OTP_VENDOR_APPROVAL_REQUEST',
    SUCCESS: 'property/POST_REQUEST_OTP_VENDOR_APPROVAL_SUCCESS',
    FAILED: 'property/POST_REQUEST_OTP_VENDOR_APPROVAL_FAILED',
  },

  POST_VERIFY_VENDOR_OTP: {
    REQUEST: 'property/POST_VERIFY_VENDOR_OTP_REQUEST',
    SUCCESS: 'property/POST_VERIFY_VENDOR_OTP_SUCCESS',
    FAILED: 'property/POST_VERIFY_VENDOR_OTP_FAILED',
  },

  POST_RESEND_VENDOR_OTP: {
    REQUEST: 'property/POST_RESEND_VENDOR_OTP_REQUEST',
    SUCCESS: 'property/POST_RESEND_VENDOR_OTP_SUCCESS',
    FAILED: 'property/POST_RESEND_VENDOR_OTP_FAILED',
  },

  GENERAL_PROPERTY: {
    CLEAR_ERROR: 'property/CLEAR_ERROR',
    CLEAR_STATUS: 'property/CLEAR_STATUS',
    RESET_STATES: 'property/RESET_STATES',
  },
}
