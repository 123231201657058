import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ListIcon from '@material-ui/icons/List'

import { withStyles, Divider } from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    position: 'fixed',
    bottom: 20,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 4,
    whiteSpace: 'nowrap',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    borderRadius: 30,
  },
  buttonLeft: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    paddingLeft: '1.875em',
    paddingRight: '1.875em',
  },
  buttonRight: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    paddingLeft: '1.875em',
    paddingRight: '1.875em',
    textTransform: 'capitalize',
  },
  divider: {
    border: `0.5 solid #FFF`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFF',
    color: '#FFF',
    marginTop: '0.625em',
    marginBottom: '0.625em',
  },
})

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
  grouped: {
    color: '#FFF',
    border: 'none',
  },
}))(ToggleButtonGroup)

const FloatingNavigationButton = (props) => {
  const { classes, match, routeChangeBack, mapPath } = props
  const [routerChange] = useRouterChange()
  const [selectedValue, setSelectedValue] = React.useState(null)

  React.useEffect(() => {
    if (match) {
      handleValue(match.path)
    }
  }, [match])

  const handleValue = (path) => {
    const paths = [
      '/buy',
      '/sold',
      '/rent',
      '/coming-soon',
      '/off-market',
      '/hush-hush',
      '/liked-properties',
    ]
    const mapViewPaths = [
      '/buy/map-view',
      '/rent/map-view',
      '/sold/map-view',
      '/liked-properties/liked-properties-map',
      '/coming-soon/map-view',
      '/off-market/map-view',
      '/hush-hush/map-view',
    ]
    if (paths.includes(path)) setSelectedValue('List')
    if (mapViewPaths.includes(path)) setSelectedValue('Map')
  }

  function isSelected(value) {
    return selectedValue === value
  }

  const handleRoute = (path) => {
    const paths = [
      '/buy/map-view',
      '/rent/map-view',
      '/sold/map-view',
      '/off-market/map-view',
      '/hush-hush/map-view',
      '/coming-soon/map-view',
      '/liked-properties/map-view',
    ]
    if (paths.includes(path)) routerChange(path)
  }

  const handleBack = () => {
    routeChangeBack()
  }

  return (
    <div className={classes.root}>
      <StyledToggleButtonGroup
        className={classes.grouped}
        size="medium"
        exclusive
        onChange={handleValue}
      >
        <ToggleButton
          value="List"
          className={classes.buttonLeft}
          onClick={(e) => {
            handleBack()
          }}
        >
          <ListIcon style={{ color: isSelected('List') ? '#FFF' : '#269AA2' }} />
        </ToggleButton>
        <Divider className={classes.divider} orientation="vertical" flexItem />
        <ToggleButton
          value="Map"
          className={classes.buttonRight}
          onClick={(e) => {
            handleRoute(mapPath)
          }}
        >
          Map View
        </ToggleButton>
      </StyledToggleButtonGroup>
    </div>
  )
}

export default withStyles(styles)(FloatingNavigationButton)
