import React from 'react'
import { Box } from '@material-ui/core'

import CustomSelect from 'components/Web/Filter/CustomSelect'
import Heading from 'components/Web/Filter/Heading'
import useFilters from 'utils/customHooks/useFilters'

const furnitureOption = [
  {
    id: 0,
    label: 'Any',
    value: 'any',
  },
  {
    id: 1,
    label: 'Furnished',
    value: 'furnished',
  },
  {
    id: 2,
    label: 'Unfurnished',
    value: 'unfurnished',
  },
]

const Furnished = () => {
  const [getFilter, setFilter] = useFilters()
  const FURNISHED = getFilter('furnished')

  const handeFurnishedChange = (value) => {
    setFilter({ key: 'furnished', value: value })
  }

  return (
    <Box>
      <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
        Furnished / Unfurnished
      </Heading>
      <CustomSelect
        options={furnitureOption}
        handleSelect={handeFurnishedChange}
        // isClearFilter={props.isClearFilter}
        value={FURNISHED}
        renderValue={(field) => {
          console.debug('field', field)

          return (
            <Box style={{ textTransform: 'capitalize' }}>
              {FURNISHED ? FURNISHED : ''}
            </Box>
          )
        }}
      />
    </Box>
  )
}

export default Furnished
