import React from "react";
import styled, { css } from 'styled-components'

import DynamicIcons from "../DynamicIcons";


import { Typography, withStyles } from '@material-ui/core';


const Container = styled.div`
  display:flex;
  align-items:center
`

const IconWrap = styled.div`
`

const SliderHeader = props => {
  return (
    <Container>
      <DynamicIcons icon={props.icon}  />
      <Typography style={{marginLeft:'15px', fontSize:'13px', color:'rgba(126, 126, 126, 0.87)', lineHeight:'0.76', fontWeight:'400', letterSpacing:'0.073125px'}}>{props.label}</Typography>
    </Container>
  );
};

export default SliderHeader;