import React, { useState } from 'react'
import { MenuItem, TextField, Select, withStyles } from '@material-ui/core'
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp'

const styles = (theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    '& > svg': {
      right: theme.spacing(2),
      top: 18,
      opacity: 0.54,
      position: 'absolute',
    },
    '&.placeholdered > div > div': {
      color: '#b6b6b6',
    },
    margin: theme.spacing(1.5, 0),
  },
  selectField: {
    width: '100%',
    /*         '& svg' : {
            display: 'none'
        }, */
    '& fieldset': {
      borderWidth: 1 + 'px !important',
    },
  },
  normal: {},
})

const SelectField = (props) => {
  const {
    classes,
    label,
    placeholder,
    options,
    value,
    selected,
    onChange,
    onBlur,
    errorMsg,
    showError,
    type,
  } = props

  const [changed, setChanged] = useState(false)

  const handleSelect = (event, newValue) => {
    if (onChange) {
      onChange(event, newValue)
    }
  }

  return (
    <div
      className={`${classes.root} ${value === '' ? ' placeholdered' : null}`}
      style={props.style}
    >
      <Select
        IconComponent={KeyboardArrowDownSharpIcon}
        variant={'outlined'}
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={(e) => handleSelect(e, e.target.value)}
        // {...classes}
        // select
        displayEmpty
        error={showError}
        // helpertext={showError ? errorMsg : ''}
        className={classes.selectField}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options &&
          options.map((item) => {
            return (
              <MenuItem
                key={item.value ? item.value : item}
                value={item.value ? item.value : item}
              >
                {item.label ? item.label : item}
              </MenuItem>
            )
          })}
      </Select>
    </div>
  )
}

export default withStyles(styles)(SelectField)
