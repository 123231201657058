import React, { useEffect, useState } from 'react'
import PT from 'prop-types'
import 'lightgallery.js/dist/css/lightgallery.css'
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery,
} from 'react-lightgallery'
import Image from '../Image'
import { Grid, Typography, withStyles } from '@material-ui/core'

const GalleryStyles = (theme) => ({
  root: {
    //    maxHeight: 596,
    //    overflow: 'hidden'
  },
  smallImages: {
    ' & > div': {
      marginBottom: theme.spacing(1.25),
      '& img': {
        cursor: 'pointer',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  imageCounter: {
    position: 'relative',
    '& strong': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Montserrat, sans',
      fontSize: '48px',
      letterSpacing: '-0.88px',
      lineHeight: '44px',
      fontWeight: 500,
      color: '#ffffff',
      pointerEvents: 'none',
      textShadow: '0 0 5px rgba(0,0,0,0.38)',
    },
  },
  primaryImage: {
    '& > div': {
      height: '100%',
    },
  },
})

const GROUP1 = [
  [
    'https://images.unsplash.com/photo-1592549585866-486f41343aaf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
    'https://images.unsplash.com/photo-1592549585866-486f41343aaf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  ],
  [
    'https://images.unsplash.com/photo-1594614271360-0ed9a570ae15?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
    'https://images.unsplash.com/photo-1594614271360-0ed9a570ae15?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  ],
]

const GROUP2 = [
  'https://images.unsplash.com/photo-1594818898109-44704fb548f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  'https://images.unsplash.com/photo-1594818896795-35ad7bcf3c6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  'https://images.unsplash.com/photo-1594818896744-57eca4d47b07?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  'https://images.unsplash.com/photo-1594818897077-aec41f55241f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80',
]

const PhotoItem = ({ image, thumb, group }) => (
  <div style={{ maxWidth: '250px', width: '200px', padding: '5px' }}>
    <LightgalleryItem group={group} src={image} thumb={thumb}>
      <img src={image} style={{ width: '100%' }} />
    </LightgalleryItem>
  </div>
)
PhotoItem.propTypes = {
  image: PT.string.isRequired,
  thumb: PT.string,
  group: PT.string.isRequired,
}

const CoverGallery = withStyles(GalleryStyles)((props) => {
  const { data, classes } = props
  const { openGallery } = useLightgallery()
  const getLastItem = (array) => {
    const length = array.length
    if (array && array.length > 0) {
      return array[length - 1]
    }
  }

  useEffect(() => {
    if (props.selectImageIndex) {
      openGallery('group2', props.selectImageIndex)
    }
  }, [props.selectImageIndex])

  return (
    <div>
      <Grid container spacing={2}>
        {data ? (
          <>
            <Grid item sm={data.length > 1 ? 9 : 12} className={classes.primaryImage}>
              <Image
                src={data[0]}
                aspectRatio={20 / 13}
                imageStyle={{ objectFit: 'cover', cursor: 'pointer' }}
                onClick={() => openGallery('group2', 0)}
              />
            </Grid>
            {data.length > 1 && (
              <Grid item sm={3} className={classes.smallImages}>
                {data.length > 1 && (
                  <>
                    {data[3] && (
                      <Image
                        src={data[3]}
                        aspectRatio={20 / 13}
                        imageStyle={{ objectFit: 'cover' }}
                        onClick={() => openGallery('group2', 3)}
                      />
                    )}
                    {data[1] && (
                      <div className={classes.imageCounter}>
                        <Image
                          src={data[1]}
                          aspectRatio={20 / 13}
                          imageStyle={{ objectFit: 'cover' }}
                          onClick={() => openGallery('group2', 1)}
                        />
                        {data.length > 4 && (
                          <strong>
                            <span>+{data.length - 4}</span>
                          </strong>
                        )}
                      </div>
                    )}
                    {data[2] && (
                      <Image
                        src={data[2]}
                        aspectRatio={20 / 13}
                        imageStyle={{ objectFit: 'cover' }}
                        onClick={() => openGallery('group2', 2)}
                      />
                    )}
                  </>
                )}
              </Grid>
            )}
          </>
        ) : (
          <Typography component={'p'} variant={'overline'}>
            No available images
          </Typography>
        )}
      </Grid>
    </div>
  )
})
CoverGallery.propTypes = {
  className: PT.string,
}

const LightBoxGallery = (props) => {
  const { data } = props

  return (
    <div className="content">
      <div>
        <LightgalleryProvider
          // onBeforeOpen={() => console.info("onBeforeOpen")}
          // onAfterOpen={() => console.info("onAfterOpen")}
          // onSlideItemLoad={() => console.info("onSlideItemLoad")}
          // onBeforeSlide={() => console.info("onBeforeSlide")}
          // onAfterSlide={() => console.info("onAfterSlide")}
          // onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
          // onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
          // onDragstart={() => console.info("onDragstart")}
          // onDragmove={() => console.info("onDragmove")}
          // onDragend={() => console.info("onDragend")}
          // onSlideClick={() => console.info("onSlideClick")}
          onBeforeClose={() => props.setSelectImageIndex(null)}
          // onCloseAfter={() => console.info("onCloseAfter")}
          plugins={['lg-fullscreen.js', 'lg-thumbnail.js', 'lg-video.js', 'lg-zoom.js']}
          lightgallerySettings={
            {
              autoplayFirstVideo: false,
              youtubePlayerParams: { autoplay: 0 },
            }
          }
        >
          <CoverGallery data={data} {...props} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'none',
            }}
          >
            {data.filter((item) => typeof item !== 'undefined').map((p, idx) => (
              <PhotoItem key={idx} image={p} group="group2" />
            ))}
          </div>
        </LightgalleryProvider>
      </div>
    </div >
  )
}

export default LightBoxGallery
