import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function DropPinIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path style={{fill:props.fill}} d="M9.1,8.3c-1.6,0-2.8,1.3-2.8,2.8c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8C11.9,9.5,10.7,8.3,9.1,8.3z"/>
      <path style={{fill:props.fill}} d="M19.8,8.4l-9.1-8.1c-0.4-0.4-1-0.4-1.4,0l-9,8.1c-0.4,0.3-0.1,0.9,0.4,0.9H2v9.6C2,19.5,2.5,20,3.1,20h3.7c0.6,0,5.9,0,6.5,0h3.7c0.6,0,1.1-0.5,1.1-1.1V9.4h1.4C19.9,9.4,20.2,8.7,19.8,8.4z M15.1,18l-3.1-3.1v-0.5l-0.2-0.2c-0.7,0.6-1.6,1-2.7,1c-2.3,0-4.1-1.8-4.1-4.1S6.8,7,9.1,7c2.3,0,4.1,1.8,4.1,4.1c0,1-0.4,1.9-1,2.7l0.2,0.2h0.5l3.1,3.1L15.1,18z"/>
    </SvgIcon>
  );
}

export function DropPinIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DropPinIcon />
      <DropPinIcon color="primary" />
      <DropPinIcon color="secondary" />
      <DropPinIcon color="action" />
      <DropPinIcon color="disabled" />
      <DropPinIcon style={{ color: green[500] }} />
    </div>
  );
}
