import React from 'react';
import { Link } from "react-router-dom";
import HeartIcon from '@material-ui/icons/Favorite';

import { withStyles, Button, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    backgroundColor: '#FFF'
  },
  container: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionButton: {
      textTransform: 'none',
      width: 130,
      marginLeft: '0.313em',
      marginRight: '0.313em',
      borderRadius: '1.250em',
      color: '#000',
      fontWeight: 'normal'
  },
  optionIcon: {
      color: '#4d4d4d'
  }
});

const SavedSearch = props => {
  const { 
    classes,
    authentication,
    settings,
    toggleSignUp,
    apiRequestSaveSearch,
    showSaveSearchModal
  } = props;

  function checkLoginStatus(){
    if(authentication && authentication.user_status !== 'logged'){
      toggleSignUp(!settings.showSignUp);
    } else {
      /** User Logged */
      showSaveSearchModal(true)
      //apiRequestSaveSearch()
    }
  }

  return (
    <>
        <div className={classes.root}>
          <div className={classes.container}>
            <Typography variant="body2" component="h2">
                {props.propertyCount + ' properties found in and around '+ props.propertySuburbs}
            </Typography>
            <div className={classes.optionButtonContainer}>
                <Button 
                    className={classes.optionButton}
                    size={'small'} 
                    color={'primary'} 
                    variant={'outlined'}
                    endIcon={<HeartIcon className={classes.optionIcon}/>}
                    onClick={()=> checkLoginStatus() }>
                    {'Save Search'}
                </Button>
            </div>
          </div>
        </div>
    </>
  );
};

export default withStyles(styles)(SavedSearch);
