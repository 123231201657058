import React from 'react';

import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import KeyboardArrowUpSharpIcon from '@material-ui/icons/KeyboardArrowUpSharp';

import { withStyles, Typography, Button, Link } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    button: {
        height: '2.250em',
        textTransform: 'capitalize',
        marginLeft: '0.313em',
        marginRight: '0.313em',
        borderRadius: '1.250em',
        whiteSpace: 'noWrap',
        fontWeight: 'normal',
        fontSize: 12,
        color: '#636363'
    },
    toTopIconGroup : {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color:'#4d4d4d',
      fontSize: '0.75rem'        
  },
  toTopGroupIcon :{
      marginRight: theme.spacing(1)
  }
});

const PropertyLoader = props => {
  const { 
    classes,
    properties,
    executeTopScroll
  } = props;

  return (
    <>
    <div className={classes.root}>

        {
          !props.isBackTop && 
            <Button
              className={classes.button} 
              variant="outlined"
              color={'primary'}
              size={'small'}
              endIcon={<KeyboardArrowDownSharpIcon />}
              onClick={()=> ''}>
              {'Load More'}
            </Button>
        }
        
        {
          !props.isLoadMore &&
          <Link  style={{cursor:'pointer'}}
            className={classes.toTopIconGroup} 
            onClick={()=> executeTopScroll()}>
            <KeyboardArrowUpSharpIcon 
                fontSize="small" 
                className={classes.toTopGroupIcon}/>
            <span>Back to Top</span>            
          </Link>
        }
       
    </div>
    </>
  );
};

export default withStyles(styles)(PropertyLoader);
