import { PURGE } from 'redux-persist'
import {
  REQUEST_SAVE_PROPERTY,
  REQUEST_SAVE_PROPERTY_SUCCESS,
  REQUEST_SAVE_PROPERTY_FAIL,
  REQUEST_GET_SAVE_PROPERTY,
  REQUEST_GET_SAVE_PROPERTY_SUCCESS,
  REQUEST_GET_SAVE_PROPERTY_FAIL,
  REQUEST_SAVE_SEARCH,
  REQUEST_SAVE_SEARCH_SUCCESS,
  REQUEST_SAVE_SEARCH_FAIL,
  REQUEST_PROPERTY_SEARCH,
  REQUEST_PROPERTY_SEARCH_SUCCESS,
  REQUEST_PROPERTY_SEARCH_FAIL,
  REQUEST_PROPERTY_SEARCH_RENTAL,
  REQUEST_PROPERTY_SEARCH_RENTAL_FAIL,
  REQUEST_PROPERTY_SEARCH_RENTAL_SUCCESS,
  REQUEST_PROPERTY_SEARCH_SOLD,
  REQUEST_PROPERTY_SEARCH_SOLD_FAIL,
  REQUEST_PROPERTY_SEARCH_SOLD_SUCCESS,
  REQUEST_PROPERTY_DETAILS,
  REQUEST_PROPERTY_DETAILS_SUCCESS,
  REQUEST_PROPERTY_DETAILS_FAIL,
  REQUEST_LIKE_PROPERTIES,
  REQUEST_LIKE_PROPERTIES_FAIL,
  REQUEST_LIKE_PROPERTIES_SUCCESS,
  CLEAR_LIKE_PROPERTIES,
  REQUEST_DISLIKE_PROPERTIES,
  REQUEST_DISLIKE_PROPERTIES_FAIL,
  REQUEST_DISLIKE_PROPERTIES_SUCCESS,
  REQUEST_SEARCH_PERFECT_PROPERTY,
  REQUEST_SEARCH_PERFECT_PROPERTY_FAIL,
  REQUEST_SEARCH_PERFECT_PROPERTY_SUCCESS,
  REQUEST_PROPERTY_SUGGESTIONS,
  REQUEST_PROPERTY_SUGGESTIONS_FAIL,
  REQUEST_PROPERTY_SUGGESTIONS_SUCCESS,
  CLEAR_PROPERTY_SUGGESTIONS,
  SEND_PERFECT_PROPERTY_FILTERS,
  SEND_PERFECT_PROPERTY_FILTERS_FAIL,
  SEND_PERFECT_PROPERTY_FILTERS_SUCCESS,
  SEND_LIKES_DISLIKES_PROPERTIES,
  SEND_LIKES_DISLIKES_PROPERTIES_FAIL,
  SEND_LIKES_DISLIKES_PROPERTIES_SUCCESS,
  SEND_SEARCH_TALLIES,
  SEND_SEARCH_TALLIES_FAIL,
  SEND_SEARCH_TALLIES_SUCCESS,
  SEND_AGENT_CONTACT_INQUIRY,
  SEND_AGENT_CONTACT_INQUIRY_FAIL,
  SEND_AGENT_CONTACT_INQUIRY_SUCCESS,
  CLEAR_AGENT_CONTACT_INQUIRY,
  CLEAR_INQUIRY_DATA,
  SET_PERFECT_PROPERTIES,
  SET_CURRENT_SELECTED_PROPERTY,
  ADD_LIKED_PROPERTIES,
  ADD_UNLIKED_PROPERTIES,
  ADD_LIKED_DRILL_DOWN_PROPERTIES,
  ADD_UNLIKED_DRILL_DOWN_PROPERTIES,
  CLEAR_LIKED_AND_UNLIKED_PROPERTIES,
  CLEAR_PROPERTY_PAYLOAD,
  CLEAR_PERFECT_PROPERTIES,
  CLEAR_CURRENT_SELECTED_PROPERTY,
  CLEAR_SESSION,
  CLEAR_FAVORITE_STATUS,
  REQUEST_SAVED_SEARCH_DATA,
  REQUEST_SAVED_SEARCH_DATA_FAIL,
  REQUEST_SAVED_SEARCH_DATA_SUCCESS,
  REQUEST_DELETE_SAVED_SEARCH,
  REQUEST_DELETE_SAVED_SEARCH_FAIL,
  REQUEST_DELETE_SAVED_SEARCH_SUCCESS,
  REQUEST_PUT_SAVED_SEARCH,
  REQUEST_PUT_SAVED_SEARCH_FAIL,
  REQUEST_PUT_SAVED_SEARCH_SUCCESS,
  REQUEST_FAVORITE_PROPERTIES,
  REQUEST_FAVORITE_PROPERTIES_FAIL,
  REQUEST_FAVORITE_PROPERTIES_SUCCESS,
  REQUEST_DREAM_PROPERTIES,
  REQUEST_DREAM_PROPERTIES_SUCCESS,
  REQUEST_DREAM_PROPERTIES_FAIL,
  REQUEST_PROPERTY_EDIT,
  REQUEST_PROPERTY_EDIT_SUCCESS,
  REQUEST_PROPERTY_EDIT_FAIL,
  REQUEST_VENDOR_OTP,
  REQUEST_VENDOR_OTP_SUCCESS,
  REQUEST_VENDOR_OTP_FAIL,
  PROPERTY_DETAILS_CLEAR,
  REQUEST_SAVED_SEARCH_CLEAR,
} from '../constants'

const INITIAL_STATE = {
  current_selected_property: null,
  unliked_properties: [],
  unliked_drill_down_properties: [],
  send_search_tallies_error: null,
  send_perfect_properties_filters_error: null,
  send_liked_disliked_properties_error: null,
  send_agent_inquiry_error: null,
  send_agent_inquiry_status: null,
  property_search_filters: null,
  property_search_error: null,
  property_search_status: null,
  property_save_search_error: null,
  property_save_search_success: null,
  property_save_search_status: null,
  property_save_property_error: null,
  property_details_error: null,
  property_details: null,
  property_edit: null,
  property_edit_error: null,
  properties_liked_error: null,
  properties_liked: null,
  properties_disliked_error: null,
  properties_disliked: null,
  properties: null,
  properties_suggestions: null,
  properties_suggestions_error: null,
  perfect_property: null,
  perfect_properties_error: null,
  perfect_properties: null,
  payload: null,
  liked_properties: [],
  liked_drill_down_properties: [],
  saved_properties: null,
  saved_properties_error: null,
  delete_properties_error: null,
  put_properties_error: null,
  favorite_properties: [],
  favorite_properties_error: null,
  get_favorites: null,
  property_save_property_status: null,
  dream_properties: {
    data: null,
    status: null,
    success: null,
    error: null,
  },
  vendor_otp_status: null,
  vendor_otp_error: null,
  vendor_otp: null,
}

let likedProperties = []
let unlikedProperties = []

export default function propertyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    /** REQUEST SEARCH PERFECT PROPERTY */
    case REQUEST_SEARCH_PERFECT_PROPERTY:
      return {
        ...state,
        payload: null,
        perfect_properties_error: null,
        request: {
          ...state.request,
          current: 'search_perfect_property',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_SEARCH_PERFECT_PROPERTY_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        perfect_properties: action.payload,
        perfect_properties_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'search_perfect_property',
          params: action.params,
        },
      }
    case REQUEST_SEARCH_PERFECT_PROPERTY_FAIL:
      return {
        ...state,
        perfect_properties_error: action.error,
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'search_perfect_property',
          params: action.params,
        },
      }

    /** PROPERTY LIKE REQUEST */
    case REQUEST_LIKE_PROPERTIES:
      return {
        ...state,
        payload: null,
        properties_liked_error: null,
        request: {
          ...state.request,
          current: 'property_like_properties',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_LIKE_PROPERTIES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        properties_liked: action.payload,
        properties_liked_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_like_properties',
          params: action.params,
        },
      }
    case REQUEST_LIKE_PROPERTIES_FAIL:
      return {
        ...state,
        properties_liked_error: action.error,
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_like_properties',
          params: action.params,
        },
      }
    case CLEAR_LIKE_PROPERTIES:
      return {
        ...state,
        properties_liked_error: null,
        payload: null,
        request: null,
        properties_liked: null,
      }

    /** PROPERTY FAVORITE REQUEST */
    case REQUEST_FAVORITE_PROPERTIES:
      return {
        ...state,
        payload: null,
        favorite_properties_error: null,
        request: {
          ...state.request,
          current: 'favorite_properties',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_FAVORITE_PROPERTIES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        favorite_properties: action.payload,
        favorite_properties_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'favorite_properties',
          params: action.params,
        },
      }
    case REQUEST_FAVORITE_PROPERTIES_FAIL:
      return {
        ...state,
        favorite_properties_error: action.error,
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'favorite_properties',
          params: action.params,
        },
      }

    /** PROPERTY DISLIKE REQUEST */
    case REQUEST_DISLIKE_PROPERTIES:
      return {
        ...state,
        payload: null,
        properties_disliked_error: null,
        request: {
          ...state.request,
          current: 'property_dislike_properties',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_DISLIKE_PROPERTIES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        properties_disliked: action.payload,
        properties_disliked_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_dislike_properties',
          params: action.params,
        },
      }
    case REQUEST_DISLIKE_PROPERTIES_FAIL:
      return {
        ...state,
        properties_disliked_error: action.error,
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_dislike_properties',
          params: action.params,
        },
      }

    /** SEND LIKES AND DISLIKES */
    case SEND_LIKES_DISLIKES_PROPERTIES:
      return {
        ...state,
        payload: null,
        request: {
          ...state.request,
          current: 'send_likes_dislikes_properties',
          previous: null,
          params: action.params,
        },
      }

    case SEND_LIKES_DISLIKES_PROPERTIES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        request: {
          ...state.request,
          current: null,
          previous: 'send_likes_dislikes_properties',
          params: action.params,
        },
      }

    case SEND_LIKES_DISLIKES_PROPERTIES_FAIL:
      return {
        ...state,
        send_liked_disliked_properties_error: action.error,
        request: {
          ...state.request,
          current: null,
          previous: 'send_likes_dislikes_properties',
          params: action.params,
        },
      }

    /** SEND SEARCH TALLIES */
    case SEND_SEARCH_TALLIES:
      return {
        ...state,
        payload: null,
        request: {
          ...state.request,
          current: 'send_search_tallies',
          previous: null,
          params: action.params,
        },
      }

    case SEND_SEARCH_TALLIES_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        request: {
          ...state.request,
          current: null,
          previous: 'send_search_tallies',
          params: action.params,
        },
      }

    case SEND_SEARCH_TALLIES_FAIL:
      return {
        ...state,
        send_search_tallies_error: action.error,
        request: {
          ...state.request,
          current: null,
          previous: 'send_search_tallies',
          params: action.params,
        },
      }

    /** SEND SEARCH PERFECT PROPERTY FILTERS */
    case SEND_PERFECT_PROPERTY_FILTERS:
      return {
        ...state,
        payload: null,
        request: {
          ...state.request,
          current: 'send_perfect_properties_filters',
          previous: null,
          params: action.params,
        },
      }

    case SEND_PERFECT_PROPERTY_FILTERS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        request: {
          ...state.request,
          current: null,
          previous: 'send_perfect_properties_filters',
          params: action.params,
        },
      }

    case SEND_PERFECT_PROPERTY_FILTERS_FAIL:
      return {
        ...state,
        send_perfect_properties_filters_error: action.error,
        request: {
          ...state.request,
          current: null,
          previous: 'send_perfect_properties_filters',
          params: action.params,
        },
      }

    /** CLEAR PAYLOAD */
    case CLEAR_PROPERTY_PAYLOAD:
      return {
        ...state,
        payload: null,
      }

    /** CONTACT AGENT */
    case SEND_AGENT_CONTACT_INQUIRY:
      return {
        ...state,
        payload: null,
        request: {
          ...state.request,
          current: 'send_agent_inquiry',
          previous: null,
          params: action.params,
        },
        send_agent_inquiry_status: 'progress',
      }
    case SEND_AGENT_CONTACT_INQUIRY_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        send_agent_inquiry_status: 'success',
        request: {
          ...state.request,
          current: null,
          previous: 'send_agent_inquiry',
          params: action.params,
        },
      }
    case SEND_AGENT_CONTACT_INQUIRY_FAIL:
      return {
        ...state,
        send_agent_inquiry_error: action.error,
        send_agent_inquiry_status: 'fail',
        request: {
          ...state.request,
          current: null,
          previous: 'send_agent_inquiry',
          params: action.params,
        },
      }
    case CLEAR_AGENT_CONTACT_INQUIRY:
      return {
        ...state,
        payload: null,
        send_agent_inquiry_error: null,
        send_agent_inquiry_status: null,
        request: null,
      }
    case CLEAR_INQUIRY_DATA:
      return {
        ...state,
        send_agent_inquiry_error: null,
        send_agent_inquiry_status: null,
      }

    /** Clear Liked and Unliked properties */
    case CLEAR_LIKED_AND_UNLIKED_PROPERTIES:
      likedProperties = []
      unlikedProperties = []
      return {
        ...state,
        liked_properties: [],
        unliked_properties: [],
        liked_drill_down_properties: [],
        unliked_drill_down_properties: [],
      }

    /** Add Liked Properties */
    case ADD_LIKED_PROPERTIES:
      likedProperties.push(action.params)
      return {
        ...state,
        liked_properties: likedProperties,
      }

    /** Add Unliked Properties */
    case ADD_UNLIKED_PROPERTIES:
      unlikedProperties.push(action.params)
      return {
        ...state,
        unliked_properties: unlikedProperties,
      }

    /** Add Liked Drill Down Properties */
    case ADD_LIKED_DRILL_DOWN_PROPERTIES:
      return {
        ...state,
        liked_drill_down_properties: action.params,
      }

    /** Add Unliked Drill Down Properties */
    case ADD_UNLIKED_DRILL_DOWN_PROPERTIES:
      return {
        ...state,
        unliked_drill_down_properties: action.params,
      }

    /** Clear Session */
    case CLEAR_SESSION:
      return {
        ...INITIAL_STATE,
      }

    /** SAVE PROPERTY */
    case REQUEST_SAVE_PROPERTY:
      return {
        ...state,
        payload: null,
        property_save_property_status: 'pending',
        request: {
          ...state.request,
          current: 'save_property',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_SAVE_PROPERTY_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        property_save_property_status: 'success',
        property_save_property_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'save_property',
          params: action.params,
        },
      }
    case REQUEST_SAVE_PROPERTY_FAIL:
      return {
        ...state,
        property_save_property_error: action.error,
        request: {
          ...state.request,
          current: null,
          previous: 'save_property',
          params: action.params,
        },
      }

    /** GET SAVE PROPERTY */
    case REQUEST_GET_SAVE_PROPERTY:
      return {
        ...state,
        payload: null,
        get_favorites_status: 'pending',
        request: {
          ...state.request,
          current: 'get_save_property',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_GET_SAVE_PROPERTY_SUCCESS:
      return {
        ...state,
        get_favorites: action.payload,
        get_favorites_status: 'success',
        get_favorites_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'get_save_property',
          params: action.params,
        },
      }
    case REQUEST_GET_SAVE_PROPERTY_FAIL:
      return {
        ...state,
        get_favorites_error: action.error,
        get_favorites_status: 'error',
        request: {
          ...state.request,
          current: null,
          previous: 'get_save_property',
          params: action.params,
        },
      }

    /** PROPERTY SEARCH */
    case REQUEST_PROPERTY_SEARCH:
      return {
        ...state,
        payload: null,
        property_search_error: null,
        property_search_status: 'pending',
        request: {
          ...state.request,
          current: 'property_search',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_SEARCH_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        properties: action.payload,
        property_search_error: null,
        property_search_status: 'success',
        request: {
          ...state.request,
          current: null,
          previous: 'property_search',
          params: state.request.params,
        },
      }
    case REQUEST_PROPERTY_SEARCH_FAIL:
      return {
        ...state,
        property_search_error: action.error,
        property_search_status: 'error',
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_search',
          params: action.params,
        },
      }

    /** PROPERTY SEARCH RENTAL */
    case REQUEST_PROPERTY_SEARCH_RENTAL:
      return {
        ...state,
        payload: null,
        property_search_error: null,
        property_search_status: 'pending',
        request: {
          ...state.request,
          current: 'property_search_rental',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_SEARCH_RENTAL_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        properties: action.payload,
        property_search_error: null,
        property_search_status: 'success',
        request: {
          ...state.request,
          current: null,
          previous: 'property_search_rental',
          params: state.request.params,
        },
      }
    case REQUEST_PROPERTY_SEARCH_RENTAL_FAIL:
      return {
        ...state,
        property_search_error: action.error,
        property_search_status: 'error',
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_search_rental',
          params: action.params,
        },
      }

    /** PROPERTY SEARCH SOLD */
    case REQUEST_PROPERTY_SEARCH_SOLD:
      return {
        ...state,
        payload: null,
        property_search_error: null,
        property_search_status: 'pending',
        request: {
          ...state.request,
          current: 'property_search_sold',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_SEARCH_SOLD_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        properties: action.payload,
        property_search_error: null,
        property_search_status: 'success',
        request: {
          ...state.request,
          current: null,
          previous: 'property_search_sold',
          params: state.request.params,
        },
      }
    case REQUEST_PROPERTY_SEARCH_SOLD_FAIL:
      return {
        ...state,
        property_search_error: action.error,
        property_search_status: 'error',
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_search_sold',
          params: action.params,
        },
      }

    /** PROPERTY DETAILS SEARCH */
    case REQUEST_PROPERTY_DETAILS:
      return {
        ...state,
        payload: null,
        properties_details: null,
        property_details_error: null,
        request: {
          ...state.request,
          current: 'property_details',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_DETAILS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        properties_details: action.payload,
        property_details_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_details',
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_DETAILS_FAIL:
      return {
        ...state,
        property_details_error: action.error,
        payload: null,
        properties_details: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_details',
          params: action.params,
        },
      }
    case PROPERTY_DETAILS_CLEAR:
      return {
        ...state,
        payload: null,
        properties_details: null,
        property_details_error: null,
        request: {
          ...state.request,
          current: 'property_details',
          previous: null,
          params: action.params,
        },
      }

    /** PROPERTY EDIT REQUEST */
    case REQUEST_PROPERTY_EDIT:
      return {
        ...state,
        payload: null,
        property_edit: null,
        property_edit_error: null,
        request: {
          ...state.request,
          current: 'property_edit',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_EDIT_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        property_edit: action.payload,
        property_edit_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_edit',
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_EDIT_FAIL:
      return {
        ...state,
        payload: null,
        property_edit_error: action.error,
        property_edit: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_edit',
          params: action.params,
        },
      }

    /** PROPERTY SUGGESTIONS */
    case REQUEST_PROPERTY_SUGGESTIONS:
      return {
        ...state,
        payload: null,
        properties_suggestions_error: null,
        request: {
          ...state.request,
          current: 'property_suggestions',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        properties_suggestions: action.payload,
        properties_suggestions_error: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_suggestions',
          params: action.params,
        },
      }
    case REQUEST_PROPERTY_SUGGESTIONS_FAIL:
      return {
        ...state,
        properties_suggestions_error: action.error,
        payload: null,
        request: {
          ...state.request,
          current: null,
          previous: 'property_suggestions',
          params: action.params,
        },
      }

    case CLEAR_PROPERTY_SUGGESTIONS:
      return {
        ...state,
        payload: null,
        properties_suggestions: null,
        properties_suggestions_error: null,
        request: null,
      }

    /** SET CURENT SELECTED */
    case SET_CURRENT_SELECTED_PROPERTY:
      return {
        ...state,
        current_selected_property: action.params,
      }

    case CLEAR_CURRENT_SELECTED_PROPERTY:
      return {
        ...state,
        current_selected_property: null,
      }

    case CLEAR_FAVORITE_STATUS:
      return {
        ...state,
        property_save_property_status: null,
      }

    /** SET CURENT SELECTED */
    case SET_PERFECT_PROPERTIES:
      return {
        ...state,
        perfect_property: action.params,
      }

    /** Clear perfect Property */
    case CLEAR_PERFECT_PROPERTIES:
      return {
        ...state,
        perfect_property: null,
      }

    /** SAVE SEARCH PROPERTY */
    case REQUEST_SAVE_SEARCH:
      return {
        ...state,
        payload: null,
        property_save_search_status: 'pending',
        request: {
          ...state.request,
          current: 'save_search_property',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_SAVE_SEARCH_SUCCESS:
      return {
        ...state,
        property_save_search_status: 'success',
        property_save_search_success: action.payload,
        property_save_search_error: null,
        payload: action.payload,
        request: {
          ...state.request,
          current: null,
          previous: 'save_search_property',
          params: action.params,
        },
      }
    case REQUEST_SAVE_SEARCH_FAIL:
      return {
        ...state,
        property_save_search_error: action.error,
        property_save_search_status: 'failed',
        property_save_search_success: null,
        request: {
          ...state.request,
          current: null,
          previous: 'save_search_property',
          params: action.params,
        },
      }

    /** GET SAVED SEARCH PROPERTY */
    case REQUEST_SAVED_SEARCH_DATA:
      return {
        ...state,
        payload: null,
        saved_properties_error: null,
        request: {
          ...state.request,
          current: 'get_saved_searches',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_SAVED_SEARCH_DATA_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        saved_properties: action.payload,
        request: {
          ...state.request,
          current: null,
          previous: 'get_saved_searches',
          params: action.params,
        },
      }
    case REQUEST_SAVED_SEARCH_DATA_FAIL:
      return {
        ...state,
        saved_properties_error: action.error,
        request: {
          ...state.request,
          current: null,
          previous: 'get_saved_searches',
          params: action.params,
        },
      }

    /** DELETE SAVED SEARCH PROPERTY */
    case REQUEST_DELETE_SAVED_SEARCH:
      return {
        ...state,
        payload: null,
        delete_properties_error: null,
        request: {
          ...state.request,
          current: 'delete_saved_search',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_DELETE_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        request: {
          ...state.request,
          current: null,
          previous: 'delete_saved_search',
          params: action.params,
        },
      }
    case REQUEST_DELETE_SAVED_SEARCH_FAIL:
      return {
        ...state,
        delete_properties_error: action.error,
        request: {
          ...state.request,
          current: null,
          previous: 'delete_saved_search',
          params: action.params,
        },
      }

     /** PUT SAVED SEARCH PROPERTY */
     case REQUEST_PUT_SAVED_SEARCH:
      return {
        ...state,
        payload: null,
        put_properties_error: null,
        request: {
          ...state.request,
          current: 'put_saved_search',
          previous: null,
          params: action.params,
        },
      }
    case REQUEST_PUT_SAVED_SEARCH_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        request: {
          ...state.request,
          current: null,
          previous: 'put_saved_search',
          params: action.params,
        },
      }
    case REQUEST_PUT_SAVED_SEARCH_FAIL:
      return {
        ...state,
        put_properties_error: action.error,
        request: {
          ...state.request,
          current: null,
          previous: 'put_saved_search',
          params: action.params,
        },
      }
    /** PROPERTY SUGGESTIONS */
    case REQUEST_DREAM_PROPERTIES:
      return {
        ...state,
        dream_properties: {
          data: null,
          status: 'pending',
          success: null,
          error: null,
        },
      }
    case REQUEST_DREAM_PROPERTIES_SUCCESS:
      return {
        ...state,
        dream_properties: {
          data: action.payload,
          status: 'success',
          success: true,
          error: null,
        },
      }
    case REQUEST_DREAM_PROPERTIES_FAIL:
      return {
        ...state,
        dream_properties: {
          data: null,
          status: 'fail',
          success: null,
          error: action.error,
        },
      }

    /** VENDOR OTP */
    case REQUEST_VENDOR_OTP:
      return {
        ...state,
        vendor_otp_status: 'progress',
        vendor_otp_error: null,
        vendor_otp: null,
      }
    case REQUEST_VENDOR_OTP_SUCCESS:
      return {
        ...state,
        vendor_otp_status: 'success',
        vendor_otp_error: null,
        vendor_otp: action.payload,
      }
    case REQUEST_VENDOR_OTP_FAIL:
      return {
        ...state,
        vendor_otp_status: 'failed',
        vendor_otp_error: action.error,
        vendor_otp: null,
      }

    //CLEAR
    case REQUEST_SAVED_SEARCH_CLEAR:
      return {
        ...state,
        property_save_search_error: null,
        property_save_search_status: null,
        property_save_search_success: null,
      }

    case PURGE:
      return INITIAL_STATE

    default:
      return state
  }
}

export const getAllProperties = (state) => state.properties
