export const AGENCY_ACTION_TYPES = {
  AGENCY_LOGIN: {
    REQUEST: 'agency/AGENCY_LOGIN_REQUEST',
    SUCCESS: 'agency/AGENCY_LOGIN_SUCCESS',
    FAILED: 'agency/AGENCY_LOGIN_FAILED',
  },
  AGENCY_FORGOT_PASSWORD: {
    REQUEST: 'agency/AGENCY_FORGOT_PASSWORD_REQUEST',
    SUCCESS: 'agency/AGENCY_FORGOT_PASSWORD_SUCCESS',
    FAILED: 'agency/AGENCY_FORGOT_PASSWORD_FAILED',
  },
  AGENCY_RESET_PASSWORD: {
    REQUEST: 'agency/AGENCY_RESET_PASSWORD_REQUEST',
    SUCCESS: 'agency/AGENCY_RESET_PASSWORD_SUCCESS',
    FAILED: 'agency/AGENCY_RESET_PASSWORD_FAILED',
  },
  AGENCY_DASHBOARD_GET_PROPERTIES: {
    REQUEST: 'agency/AGENCY_DASHBOARD_GET_PROPERTIES_REQUEST',
    SUCCESS: 'agency/AGENCY_DASHBOARD_GET_PROPERTIES_SUCCESS',
    FAILED: 'agency/AGENCY_DASHBOARD_GET_PROPERTIES_FAILED',
    CLEAR: 'agency/AGENCY_DASHBOARD_GET_PROPERTIES_CLEAR',
  },

  AGENCY_EDIT_PROPERTY: {
    REQUEST: 'agency/AGENCY_EDIT_PROPERTY_REQUEST',
    SUCCESS: 'agency/AGENCY_EDIT_PROPERTY_SUCCESS',
    FAILED: 'agency/AGENCY_EDIT_PROPERTY_FAILED',
  },
  AGENCY_PUBLISH_PROPERTY: {
    REQUEST: 'agency/AGENCY_PUBLISH_PROPERTY_REQUEST',
    SUCCESS: 'agency/AGENCY_PUBLISH_PROPERTY_SUCCESS',
    FAILED: 'agency/AGENCY_PUBLISH_PROPERTY_FAILED',
  },
  AGENCY_NOTIFY_VENDOR: {
    REQUEST: 'agency/AGENCY_NOTIFY_VENDOR_REQUEST',
    SUCCESS: 'agency/AGENCY_NOTIFY_VENDOR_SUCCESS',
    FAILED: 'agency/AGENCY_NOTIFY_VENDOR_FAILED',
  },
  AGENCY_GET_ALL_AGENTS: {
    REQUEST: 'agency/AGENCY_GET_ALL_AGENTS_REQUEST',
    SUCCESS: 'agency/AGENCY_GET_ALL_AGENTS_SUCCESS',
    FAILED: 'agency/AGENCY_GET_ALL_AGENTS_FAILED',
  },
  AGENCY_GET_AGENT_DETAILS: {
    REQUEST: 'agency/AGENCY_GET_AGENT_DETAILS_REQUEST',
    SUCCESS: 'agency/AGENCY_GET_AGENT_DETAILS_SUCCESS',
    FAILED: 'agency/AGENCY_GET_AGENT_DETAILS_FAILED',
  },
  AGENCY_PUT_AGENT_DETAILS: {
    REQUEST: 'agency/AGENCY_PUT_AGENT_DETAILS_REQUEST',
    SUCCESS: 'agency/AGENCY_PUT_AGENT_DETAILS_SUCCESS',
    FAILED: 'agency/AGENCY_PUT_AGENT_DETAILS_FAILED',
  },

  AGENCY_CHECK_AGENT_EMAIL: {
    REQUEST: 'agency/AGENCY_CHECK_AGENT_EMAIL_REQUEST',
    SUCCESS: 'agency/AGENCY_CHECK_AGENT_EMAIL_SUCCESS',
    FAILED: 'agency/AGENCY_CHECK_AGENT_EMAIL_FAILED',
  },
  AGENCY_ADD_AGENT: {
    REQUEST: 'agency/AGENCY_ADD_AGENT_REQUEST',
    SUCCESS: 'agency/AGENCY_ADD_AGENT_SUCCESS',
    FAILED: 'agency/AGENCY_ADD_AGENT_FAILED',
  },
  AGENCY_DELETE_AGENT: {
    REQUEST: 'agency/AGENCY_DELETE_AGENT_REQUEST',
    SUCCESS: 'agency/AGENCY_DELETE_AGENT_SUCCESS',
    FAILED: 'agency/AGENCY_DELETE_AGENT_FAILED',
  },
  AGENCY_AGENT_PHOTO: {
    REQUEST: 'agency/AGENCY_AGENT_PHOTO_REQUEST',
    SUCCESS: 'agency/AGENCY_AGENT_PHOTO_SUCCESS',
    FAILED: 'agency/AGENCY_AGENT_PHOTO_FAILED',
  },
  GET_AGENCY_DETAILS: {
    REQUEST: 'agency/GET_AGENCY_DETAILS_REQUEST',
    SUCCESS: 'agency/GET_AGENCY_DETAILS_SUCCESS',
    FAILED: 'agency/GET_AGENCY_DETAILS_FAILED',
  },
  UPDATE_AGENCY_DETAILS: {
    REQUEST: 'agency/UPDATE_AGENCY_DETAILS_REQUEST',
    SUCCESS: 'agency/UPDATE_AGENCY_DETAILS_SUCCESS',
    FAILED: 'agency/UPDATE_AGENCY_DETAILS_FAILED',
  },
  AGENCY_ACTIVATE: {
    REQUEST: 'agency/AGENCY_ACTIVATE_REQUEST',
    SUCCESS: 'agency/AGENCY_ACTIVATE_SUCCESS',
    FAILED: 'agency/AGENCY_ACTIVATE_FAILED',
  },
  AGENCY_UPLOAD_IMAGE: {
    REQUEST: 'agency/AGENCY_UPLOAD_IMAGE_REQUEST',
    SUCCESS: 'agency/AGENCY_UPLOAD_IMAGE_SUCCESS',
    FAILED: 'agency/AGENCY_UPLOAD_IMAGE_FAILED',
    CLEAR: 'agency/AGENCY_UPLOAD_IMAGE_CLEAR',
    CLEAR_STATUS: 'agency/AGENCY_UPLOAD_IMAGE_CLEAR_STATUS',
    CLEAR_ERROR: 'agency/AGENCY_UPLOAD_IMAGE_CLEAR_ERROR',
  },
  AGENCY_REGISTER: {
    REQUEST: 'agency/AGENCY_REGISTER_REQUEST',
    SUCCESS: 'agency/AGENCY_REGISTER_SUCCESS',
    FAILED: 'agency/AGENCY_REGISTER_FAILED',
    CLEAR: 'agency/AGENCY_REGISTER_CLEAR',
    CLEAR_STATUS: 'agency/AGENCY_REGISTER_CLEAR_STATUS',
    CLEAR_ERROR: 'agency/AGENCY_REGISTER_CLEAR_ERROR',
  },
  AGENCY_REGISTER_CONTACT_ADMIN: {
    REQUEST: 'agency/AGENCY_REGISTER_CONTACT_ADMIN_REQUEST',
    SUCCESS: 'agency/AGENCY_REGISTER_CONTACT_ADMIN_SUCCESS',
    FAILED: 'agency/AGENCY_REGISTER_CONTACT_ADMIN_FAILED',
    CLEAR: 'agency/AGENCY_REGISTER_CONTACT_ADMIN_CLEAR',
    CLEAR_STATUS: 'agency/AGENCY_REGISTER_CONTACT_ADMIN_CLEAR_STATUS',
    CLEAR_ERROR: 'agency/AGENCY_REGISTER_CONTACT_ADMIN_CLEAR_ERROR',
  },
  GENERAL_AGENCY: {
    CLEAR_ERROR: 'agency/CLEAR_ERROR',
    CLEAR_STATUS: 'agency/CLEAR_STATUS',
    RESET_STATES: 'agency/RESET_STATES',
  },
  AGENCY_CREATE_PROPERTY: {
    REQUEST: 'agency/AGENCY_CREATE_PROPERTY_REQUEST',
    SUCCESS: 'agency/AGENCY_CREATE_PROPERTY_SUCCESS',
    FAILED: 'agency/AGENCY_CREATE_PROPERTY_FAILED',
    CLEAR: 'agency/AGENCY_CREATE_PROPERTY_CLEAR',
  },
  AGENCY_CREATE_PROPERTY_SOI: {
    REQUEST: 'agency/AGENCY_CREATE_PROPERTY_SOI_REQUEST',
    SUCCESS: 'agency/AGENCY_CREATE_PROPERTY_SOI_SUCCESS',
    FAILED: 'agency/AGENCY_CREATE_PROPERTY_SOI_FAILED',
    CLEAR: 'agency/AGENCY_CREATE_PROPERTY_SOI_CLEAR',
  },
  AGENCY_POST_CHANGE_REQUEST: {
    REQUEST: 'agency/AGENCY_POST_CHANGE_REQUEST_REQUEST',
    SUCCESS: 'agency/AGENCY_POST_CHANGE_REQUEST_SUCCESS',
    FAILED: 'agency/AGENCY_POST_CHANGE_REQUEST_FAILED',
    CLEAR: 'agency/AGENCY_POST_CHANGE_REQUEST_CLEAR',
  },
  AGENCY_PAUSE_PROPERTY: {
    REQUEST: 'agency/AGENCY_PAUSE_PROPERTY_REQUEST',
    SUCCESS: 'agency/AGENCY_PAUSE_PROPERTY_SUCCESS',
    FAILED: 'agency/AGENCY_PAUSE_PROPERTY_FAILED',
    CLEAR: 'agency/AGENCY_PAUSE_PROPERTY_CLEAR',
  },
  AGENCY_DELETE_PROPERTY: {
    REQUEST: 'agency/AGENCY_DELETE_PROPERTY_REQUEST',
    SUCCESS: 'agency/AGENCY_DELETE_PROPERTY_SUCCESS',
    FAILED: 'agency/AGENCY_DELETE_PROPERTY_FAILED',
    CLEAR: 'agency/AGENCY_DELETE_PROPERTY_CLEAR',
  },
  AGENCY_SEND_AGENT_ACTIVATION_EMAIL: {
    REQUEST: 'agency/AGENCY_SEND_AGENT_ACTIVATION_EMAIL_REQUEST',
    SUCCESS: 'agency/AGENCY_SEND_AGENT_ACTIVATION_EMAIL_SUCCESS',
    FAILED: 'agency/AGENCY_SEND_AGENT_ACTIVATION_EMAIL_FAILED',
  },
  AGENCY_GET_ALL_ADMINS: {
    REQUEST: 'agency/AGENCY_GET_ALL_ADMINS_REQUEST',
    SUCCESS: 'agency/AGENCY_GET_ALL_ADMINS_SUCCESS',
    FAILED: 'agency/AGENCY_GET_ALL_ADMINS_FAILED',
  },
  AGENCY_GET_SINGLE_ADMIN: {
    REQUEST: 'agency/AGENCY_GET_SINGLE_ADMIN_REQUEST',
    SUCCESS: 'agency/AGENCY_GET_SINGLE_ADMIN_SUCCESS',
    FAILED: 'agency/AGENCY_GET_SINGLE_ADMIN_FAILED',
  },
  AGENCY_CREATE_ADMIN: {
    REQUEST: 'agency/AGENCY_CREATE_ADMIN_REQUEST',
    SUCCESS: 'agency/AGENCY_CREATE_ADMIN_SUCCESS',
    FAILED: 'agency/AGENCY_CREATE_ADMIN_FAILED',
  },
  AGENCY_EDIT_ADMIN: {
    REQUEST: 'agency/AGENCY_EDIT_ADMIN_REQUEST',
    SUCCESS: 'agency/AGENCY_EDIT_ADMIN_SUCCESS',
    FAILED: 'agency/AGENCY_EDIT_ADMIN_FAILED',
  },
  AGENCY_DELETE_ADMIN: {
    REQUEST: 'agency/AGENCY_DELETE_ADMIN_REQUEST',
    SUCCESS: 'agency/AGENCY_DELETE_ADMIN_SUCCESS',
    FAILED: 'agency/AGENCY_DELETE_ADMIN_FAILED',
  },
}
