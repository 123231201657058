import React, { useEffect } from 'react'
import Crumbs from '../../components/Web/Header/Crumbs'

import styled from 'styled-components'

import { withStyles, Typography } from '@material-ui/core'
import OfferingsTable from '../Web/OfferingsTable'

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  crumbsWrap: {
    padding: '20px 0 16px 100px',
  },
  mainContainer: {
    zIndex: 6,
    backgroundColor: '#F5F5F5',
    position: 'relative',
    borderRadius: 4,
    padding: '0 120px 0 55px',
  },
  heading: {
    fontWeigth: '400',
    textAlign: 'start',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: '#4d4d4d',
  },
  subHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
  },
  textContent: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  textRulesContent: {
    marginLeft: theme.spacing(3),
  },
  LineSpace: {
    marginBottom: theme.spacing(3),
  },
  apendix: {
    backgroundColor: '#FFFFFF',
  },
  inlineTexts: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
})

const Container = styled.div`
  max-width: 1440px;
  padding: 20px 120px 120px 155px;
  @media (min-width: 1440px) {
    margin: 0 auto;
  }
`

const InnerText = styled.span`
  flex-direction: column;
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 12px;
`

const AdvertisingAgreement = ({ classes, history, location }) => {
  useEffect(() => {
    localStorage.removeItem('hideState')
    window.scrollTo(0, 0)
  }, [])

  const origin = window?.location?.origin

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <div className={classes.crumbsWrap}>
          <Crumbs
            crumbsOption={{
              label: 'Advertising Agreement Terms and Conditions',
              route: '/advertising-agreement',
            }}

          />
        </div>
      </div>
      <Container>
        <Typography variant="h5" className={classes.heading}>
          Advertising Agreement Terms and Conditions
        </Typography>
        <Typography variant="h6" className={classes.subHeading}>
          Parties
        </Typography>

        <Typography variant="body1" className={classes.inlineTexts}>
          PROPERTYMATE GROUP PTY LTD ACN: 642 236 039 ABN: 52 642 236 039 of 248 Burwood
          Rd, Hawthorn VIC 3122; and
        </Typography>

        <Typography variant="body1" className={classes.inlineTexts}>
          the party set out in the 'Customer Details' section of the Particulars
          (Customer),
        </Typography>

        <Typography variant="body1" className={classes.inlineTexts}>
          (each, a party and together the parties).
        </Typography>

        <Typography variant="h6" className={classes.subHeading}>
          Agreed terms
        </Typography>
        <Typography variant="h6" className={classes.subHeading}>
          1. Definitions and Interpretation
        </Typography>
        <div className={classes.textContainer}>
          <Typography>1.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            <Typography>In this Agreement</Typography>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Agreement</strong> means these terms and conditions and the
            Particulars;
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Advertising Guidelines</strong> means the advertising specifications
            or guidelines made available to the Customer by PM, from time to time;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Background IP</strong> means any Intellectual Property Rights that
            are pre-existing or created independently of this Agreement;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Business Day</strong> means a day that is not a Saturday, Sunday or
            public holiday in Melbourne, Victoria;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Coming Soon Listing </strong>means a Listing that will be advertised
            as a Standard Listing with all relevant particulars within 30 days of
            publication;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Confidential Information</strong> means the terms and existence of
            this Agreement and all information belonging or relating to a party, in any
            form, that is:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              1. &nbsp;&nbsp; or should reasonably be regarded as, confidential to the
              party to whom it belongs or relates; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              2. &nbsp;&nbsp; not generally available to the public at the time of
              disclosure other than by reason of a breach of this Agreement;
            </span>
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Consequential Loss</strong> means any and all Loss other than Loss
            that may fairly and reasonably be considered arising naturally or directly
            from the event by which it is caused;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Event of Default</strong> means a party failing to comply with any
            of its obligations under this Agreement or becoming deregistered;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Force Majeure Event</strong> means any act, event or cause, other
            than a lack of funds, which directly or indirectly results in a party being
            prevented from or delayed in performing any of its obligations under this
            Agreement and is beyond the reasonable control of that party;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>GST</strong> has the meaning given to it in A New Tax System (Goods
            and Services Tax) Act 1999 (Cth);
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Intellectual Property Rights</strong> means all present and future
            intellectual and industrial property rights conferred by law and wherever
            existing;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Listing</strong> means an advertised residential property for sale
            or rent including the material provided to PM via upload to a CRM integrated
            with PM (or the Website, for Coming Soon Listings and Off Market Listings)
            by or on behalf of the Customer, to be published on the Website and all
            related material and data including photographs, floorplans, video,
            descriptions, agent and agency details;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Listing Price</strong> means the price set out in Appendix 1,
            subject to clause 18.1, payable by the Customer to PM per Listing;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Core Product</strong> means one of the following core products
            described in Appendix 1, as set out in the Particulars or as otherwise
            agreed between the parties in writing, time to time:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              1. &nbsp;&nbsp; Elite Listing (not available as at the date of this
              Agreement);
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              2. &nbsp;&nbsp; Executive Listing;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              3. &nbsp;&nbsp; Extended Listing; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              4. &nbsp;&nbsp; Entry Listing;
            </span>
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Loss</strong> means any damage, loss, liability, cost or expense
            (including all reasonable professional costs on a full indemnity basis)
            incurred by a party or a claim, action, proceeding or demand made against a
            party, however arising and whether present or future, fixed or
            unascertained, actual or contingent;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Off Market Listing</strong> means a Listing that does not disclose
            all relevant listing information (a potential buyer would have to contact
            the agent of the Listing to obtain the relevant listing information);
            Particulars means the particulars accompanying this Agreement;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Personal Information</strong> has the same meaning given to it in
            the Privacy Act 1988 (Cth); Privacy Policy means PM's privacy policy which
            can be accessed at{' '}
            <a
              href={`${origin}/privacy-policy`}
              target="_blank"
              style={{ textDecoration: 'none', color: '#000000' }}
            >
              {`${origin}/privacy-policy`}
            </a>
            ;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Privacy Laws </strong> means any applicable law which relates to
            privacy, including without limitation the Privacy Act 1988 (Cth), the
            Australian Privacy Principles under the Privacy Act 1988 (Cth), the Spam Act
            2003 (Cth), and all other laws in Australia which relate to the protection
            of Personal Information relevant to the parties;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Services</strong> means publishing Listings on the Website, and such
            other services agreed between the parties from time to time;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Service Fees</strong> means the Subscription Price and Listing
            Price;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Sorting Rules </strong> means the sorting rules as set out in
            Appendix 2;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Standard Listing</strong> means a Listing which is not a Coming Soon
            Listing or an Off Market Listing;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Subscription Price</strong> means the price set out in the
            Particulars including a payment surcharge equal to the amount charged by the
            relevant payment service provider to PM to use its services;
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Website</strong> means the website located at the URL{' '}
            <a
              href=" https://propertymate.com.au/"
              target="_blank"
              style={{ textDecoration: 'none', color: '#000000' }}
            >
              https://propertymate.com.au/
            </a>
            ; and
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Website T&Cs </strong>
            means the terms and conditions relating to the Website which can be accessed
            at{' '}
            <a
              href={`${origin}/terms-and-conditions`}
              target="_blank"
              style={{ color: '#000000' }}
            >
              {`${origin}/terms-and-conditions`}
            </a>
            .
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>1.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The terms, <strong>Billing Start Date, Contract Start Date</strong> and{' '}
            <strong>Initial Term</strong> have the meanings set out in the Particulars.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>1.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            In this Agreement, unless expressly provided otherwise:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; includes or including means includes or including without
              limitation;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; a reference to a an entity includes its successors and
              permitted assigns; and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; a reference to $ refers to Australian dollars.
            </span>
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          2. Request for Services and Term
        </Typography>
        <div className={classes.textContainer}>
          <Typography>2.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer requests PM to provide the Services, and PM agrees to provide
            the Services, on the terms of this Agreement.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>2.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            This Agreement starts on the Contract Start Date and continues until the end
            of the Initial Term unless terminated earlier in accordance with this
            Agreement.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>2.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If the parties continue to perform their obligations under this Agreement
            after the expiry of the Initial Term, this Agreement continues on a
            month-by-month basis until a party provides 1 months' written notice to the
            other party to terminate this Agreement.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          3. Advertising conditions
        </Typography>
        <div className={classes.textContainer}>
          <Typography>3.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            All Listings uploaded by the Customer must comply with the Advertising
            Guidelines.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>3.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer is solely responsible for the content of all Listings and must
            notify PM of any errors or omissions in any Listing published.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          4. Third party and website acknowledgments
        </Typography>
        <div className={classes.textContainer}>
          <Typography>4.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer acknowledges that the Services rely on services performed or
            controlled by third parties, including internet and website hosting
            services.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer authorises PM sharing Listings with third party providers as
            required, in order for PM to perform the Services.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PM does not warrant any third party products and services and is not
            responsible for any acts, omission or delays by third party providers.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PM does not warrant that the Website will be uninterrupted, error-free, or
            completely secure. Although PM will use commercially reasonable efforts to
            take actions it deems appropriate to remedy and avoid such adverse events
            with respect to the Services, PM cannot guarantee that such events will not
            occur. Accordingly, PM excludes any and all liability resulting from, or
            related to, such events, to the extent that such events were not caused or
            contributed to by PM.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          5. Core Product
        </Typography>
        <div className={classes.textContainer}>
          <Typography>5.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            For each Listing (including any Coming Soon Listing or Off Market Listing),
            the Customer will receive the offerings relevant to the Core Product as set
            out in Appendix 1. For the avoidance of doubt, the Customer is not entitled
            to select more than 1 Core Product.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            As part of the Subscription Price, PM will bulk upload all existing Listings
            nominated by the Customer on the Contract Start Date.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PM will use best endeavours to publish the Listings on the terms of this
            Agreement.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Listings will be published in the "buy" or "rent" section (as applicable) of
            the Website in accordance with the Sorting Rules until:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; the Customer notifies PM that the Listing is sold or
              rented, following which the Listing will be:
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i)</Typography>
              &nbsp;&nbsp; in the case of sales, moved to the "sold" section of the
              Website; and
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii)</Typography>
              &nbsp;&nbsp; in the case of rentals, removed from the Website;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; the Customer requests the Listing be removed, following
              which the Listing will be archived; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; in respect of a Coming Soon Listing or an Off Market
              Listing only, the expiry of
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i)</Typography>
              &nbsp;&nbsp; 30 days for a Coming Soon Listing; or
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii)</Typography>
              &nbsp;&nbsp; 60 days for an Off Market Listing, following which Listings
              for sales will be archived and Listings for rentals will be removed from
              the Website, unless the Listing is upgraded to a Standard Listing in
              accordance with clauses 5.7 and 5.9 below.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Coming Soon Listings/Off Market Listings</strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.LineSpace}>
            A Coming Soon Listing or an Off Market Listing is an interim offering before
            the Listing is upgraded to a Standard Listing.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.LineSpace}>
            The Customer may upgrade the Listing to a Standard Listing at any time via
            the CRM.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.LineSpace}>
            The Customer will be notified 7 days before the Listing is archived pursuant
            to clause 5.5(c).
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Updates to a Listing</strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.8</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer may update a Listing via the CRM.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>5.9</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer may archive a Listing at any time.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>5.10</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Listings which are archived may only be re-activated within 60 days of
            archiving, upon written request by the Customer, and if not a Standard
            Listing, will be upgraded to a Standard Listing. Any Listing re-activated
            after this period will be charged as a new Listing.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>5.11</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If at any time the Customer uploads a Listing for the same property as a
            Coming Soon Listing or an Off Market Listing, the new Listing will supersede
            the previous Listing, and the previous Listing will be deleted.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>5.12</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer may not upload a Coming Soon Listing or Off Market Listing for
            the same property more than once.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>Refresh of an Elite or Executive Listing </strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.13</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If PM creates a functionality to "refresh" a Listing, the Customer may
            refresh an Elite Listing or Executive Listing only so that the Listing will
            be listed as Elite/Executive (as applicable) for an additional 60 days (120
            days in total) (Listing Refresh), and must pay the relevant Listing Price
            for each Listing Refresh in addition to the original Listing Price. As at
            the date of this Agreement, the Listing Refresh functionality is not
            available.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            <strong>PM's right to deal with Listings </strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.14</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Every Listing is subject to PM’s approval, and PM reserves the right to
            refuse, suspend or remove a Listing at any time, acting reasonably,
            including as necessary in order to comply with the Advertising Guidelines,
            or if the Listing breaches the terms of this Agreement. In the event that a
            Listing is refused, suspended or removed, PM will not charge the Customer
            the Listing Price for that Listing.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>5.15</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Notwithstanding clause 5.15, PM owes no duty to the Customer to review,
            approve or amend any Listing and no review, approval or amendment by PM will
            affect the Customer’s responsibility for the content of the Listing.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>5.16</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The positioning, placement or format of the Listing on the Website or any
            other digital location must be in accordance with the Sorting Rules.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          6. Customer obligations
        </Typography>
        <div className={classes.textContainer}>
          <Typography>6.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer must, and must ensure that the Customer's personnel, comply
            with:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; all applicable laws in connection with the receipt of
              Services and this Agreement including without limitation, the Competition
              and Consumer Act 2010 (including the Australian Consumer Law); real estate
              agent legislation and regulations and any other applicable advertising
              standards and regulations;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; Website T&Cs
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; Privacy Policy; and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              d. &nbsp;&nbsp; Advertising Guidelines.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>6.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer must not resell advertising space to any third party or use
            advertising space to advertise anything other than property.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          7. Payment Terms
        </Typography>
        <div className={classes.textContainer}>
          <Typography>7.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Entry Listings, Coming Soon Listings and Off Market Listings are included in
            the Subscription Price.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            In consideration of PM performing the Services to the Customer, the Customer
            must pay PM the Service Fees.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer will be charged by PM via Stripe or such other payment method
            reasonably required by PM, for the following (as applicable), in advance:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; the annual Subscription Price annually on the Billing
              Start Date and each anniversary of the Billing Start Date; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; the monthly Subscription Price on the Billing Start Date
              and the same date each calendar month.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            On and from the Billing Start Date, PM will issue an invoice for the Listing
            Price for each Listing published (except those published pursuant to clause
            5.3), on the Website during the previous calendar month, and the Customer
            must pay the invoice by direct debit or credit card within 30 days from the
            date of the invoice.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If the Customer defaults in payment, without limiting any other rights PM
            may have, PM may:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; charge the Customer interest, accruing daily at a rate of
              2% above the rate fixed from time to time under section 2 of the Penalty
              Interest Rates Act 1983 (Vic), on the unpaid amount; and/or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; remove the Listing(s) relevant to the unpaid amount;
              and/or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; refuse to publish any additional Listings until the unpaid
              amount is paid.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.6</Typography>
          <Typography variant="body1" className={classes.textContent}>
            In the event that PM exercises its rights under clause 7.5, the Customer
            will not be relieved from its liability to pay the Service Fees including in
            relation to the Listing which has been removed.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          8. GST
        </Typography>
        <div className={classes.textContainer}>
          <Typography>8.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Words or expressions used in this clause 8 which are defined in the A New
            Tax System (Goods and Services Tax) Act 1999 (Cth) have the same meaning as
            they are given in the said Act.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>8.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If GST applies to any supply made under this Agreement, then the party
            making that supply may, in addition to any other amount payable in this
            Agreement, recover from the other party an additional amount on account of
            GST, such amount to be calculated on the basis of the current GST rate and a
            valid tax invoice (or adjustment note) is received by the recipient. Unless
            otherwise specified, any amount payable under this Agreement is exclusive of
            GST.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          9. Intellectual Property
        </Typography>
        <div className={classes.textContainer}>
          <Typography>9.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Ownership of Background IP will remain with the party who contributed that
            Background IP.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>9.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer must not use PM's Background IP without PM's prior written
            consent.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>9.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer grants PM a non-exclusive, worldwide, royalty-free licence to
            use, reproduce, communicate, modify and adapt the Customer's Background IP
            solely for the purpose of performing the Services and in respect of clause
            9.4. The Customer warrants that it is authorised to grant this licence in
            relation to all Listings.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>9.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PM may use a Listing to promote its advertising and marketing services
            including to third parties. This right extends beyond the term of this
            Agreement.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>9.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer must indemnify and keep PM indemnified against all claims and
            proceedings made or brought against PM, and for all Loss PM suffers or
            incurs, arising from any third party claim that Customer's Background IP or
            PM's use of Customer's Background IP, infringes the Intellectual Property
            Rights of any third party.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          10. Confidentiality and privacy
        </Typography>
        <div className={classes.textContainer}>
          <Typography>10.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Each party receiving or acquiring Confidential Information of the other
            party (Disclosing Party) acknowledges that the Disclosing Party's
            Confidential Information is the property of, and confidential to, the
            Disclosing Party.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>10.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Subject to clause 10.3, each party must keep the other party's Confidential
            Information confidential and take all reasonable steps to secure it and keep
            it secure while in its possession or control.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>10.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The obligations of confidentiality under clause 10.2 do not apply to any
            information that is required to be disclosed by any applicable law.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>10.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer must:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; comply with all applicable Privacy Laws including in
              relation to opt-out and opt-in procedures and the collection, use and
              security of user data and data privacy;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; provide the necessary notifications and obtain the
              necessary consents to enable the Services to be performed; and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; take all necessary steps to ensure any Personal
              Information has the adequate level of data protection required under the
              applicable laws and is protected from unauthorised access, misuse,
              interference, corruption and loss.
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              d. &nbsp;&nbsp; PM may invoice for outstanding amounts owed, and Customer
              remains liable to PM in respect of outstanding invoices.
            </span>
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          11. Customer warranties
        </Typography>
        <div className={classes.textContainer}>
          <Typography className={classes.LineSpace}>
            At all times during the term of this Agreement, Customer represents and
            warrants that:
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>11.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Listing, and the publication of such, does not breach or infringe:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; any copyright, trade mark, obligation of confidentiality
              or other personal or proprietary right;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; any third party intellectual property rights; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; any applicable laws;
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>11.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            It holds all required licences or accreditations to sell or rent all the
            Listings including, but not limited to a real estate agency licence;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Customer has provided any necessary notification and obtained any
            necessary consent, authorisation, right, certificate, licence, permit,
            declaration, exemption, notarisation or waiver, however described (including
            any renewal or partial renewal), including from any individual and in
            respect of Privacy Laws, required to enable the Services to be performed;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Listing is true, accurate, complete, not misleading in any material
            respect and can be substantiated, and complies with any applicable laws,
            regulations and codes, and will not cause PM to breach any applicable law,
            regulation or code;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Listing will not contain any information or content that is unlawful,
            false, misleading or deceptive, discriminatory, defamatory, indecent,
            obscene, offensive, objectionable, or in breach of any third party rights,
            including Intellectual Property Rights;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.6</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Listing will be in the best interests of the property industry;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.7</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Listing will not reference competitors of PM;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.8</Typography>
          <Typography variant="body1" className={classes.textContent}>
            It will promptly provide all information, approvals, support and assistance
            which PM reasonably requires in performing the Services;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.9</Typography>
          <Typography variant="body1" className={classes.textContent}>
            It will ensure that all Listings uploaded are the correct versions, do not
            contain errors or omissions, and comply with any Advertising Guidelines;
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.10</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Listing does not contain any worm, virus, Trojan horse, defect or
            similar program or contain functionality that enables the Customer or any
            third party to scrape or index the Website or any content on it; and
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>11.11</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Each website represented by any URL shown or embedded in any Listing is
            controlled and operated by the Customer, will be functional and accessible
            at all times during display of the Listing and is suitable to link to and
            from the Website.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          12. No representation or warranty by PM
        </Typography>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.textContent}>
            PM makes no representation or warranty of any kind and in particular makes
            no representation or warranty:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; concerning a Listing;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; in relation to the final placement or positioning of the
              Listing;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; hat publication will occur on a specific date, by a
              specific time, to a specific number of consumers or readers or within a
              specific geographic area;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              d. &nbsp;&nbsp; in relation to the number of visitors to the Website
              including any minimum number of leads or the performance, placement,
              number of clicks or impressions.
            </span>
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          13. Indemnity
        </Typography>
        <div className={classes.textContainer}>
          <Typography>13.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Subject to clause 14, each party (Indemnifying Party) indemnifies the other
            (Indemnified Party) and all of its officers, employees and agents against
            any liability or cost which the Indemnified Party or any of its officers,
            employees and agents incur concerning any claim or action by a third party
            arising from:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; any negligent act or omission by the Indemnifying Party;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; any breach of this Agreement by the Indemnifying Party.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>13.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Indemnified Party must take reasonable action to mitigate any loss,
            liability, costs or other damage suffered as a result of any claim or action
            by a third party in accordance with clause 13.1.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          14. Liability
        </Typography>
        <div className={classes.textContainer}>
          <Typography>14.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Under no circumstances shall either party be liable to the other party or
            any third party for:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; any Loss caused by any act or omission of the other party
              or its officers, employees, agents or contractors; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; any Consequential Loss.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>14.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Nothing in this Agreement operates to limit or exclude either party's
            liability to the other in respect of Loss arising out of, or in connection
            with a party's breach of:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; clause 10 (Confidentiality);
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; any infringement of copyright, trademarks or intellectual
              property rights; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; any fraud or repudiation of this Agreement.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>14.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The maximum aggregate liability of a party in connection with this Agreement
            is limited to the greater of:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; all payments payable by the Customer to PM under this
              Agreement; and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; $1,000.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>14.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PM limits its liability for any error in a Listing caused by PM to the
            republication of the relevant Listing or payment of the cost of republishing
            the relevant Listing.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>14.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Any representation, warranty, condition, guarantee or undertaking that would
            be implied in this Agreement by legislation, common law, equity, trade,
            custom or usage is excluded to the fullest extent permitted by law.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>14.6</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Nothing in this Agreement excludes, restricts or modifies any consumer
            guarantee, right or remedy conferred on the party by the Australian Consumer
            Law in Schedule 2 of the Competition and Consumer Act 2010 (Cth) or any
            other applicable law that cannot be excluded, restricted or modified by
            agreement.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>14.7</Typography>
          <Typography variant="body1" className={classes.textContent}>
            To the fullest extent permitted by law, the liability of PM for a breach of
            a non-excludable consumer guarantee referred to in clause 14.6 is limited,
            at PM's option, to:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; the supplying of the Services again; or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; the payment of the cost of having the Services supplied
              again.
            </span>
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          15. Breach and termination
        </Typography>
        <div className={classes.textContainer}>
          <Typography>15.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If the Customer commits an Event of Default that is capable of remedy and
            fails to remedy that breach within 10 Business Days after receiving written
            notice from PM, PM may:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; remove the Listing(s) relevant to the Event of Default;
              and/or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; refuse to publish any additional Listings.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>15.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            In the event that PM exercises its rights under clause 15.1 immediately
            above, the Customer will not be relieved from its liability to pay the
            Service Fees including in relation to the Listing which has been removed.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>15.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Without limiting clause 15.1, this Agreement may be terminated by a party
            giving written notice to the other party, if the other party:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; commits an Event of Default that is capable of remedy and
              fails to remedy that breach within 10 Business Days after receiving
              written notice from the non-breaching party;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; commits an Event of Default that is not capable of remedy;
              or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; is the subject of an insolvency event.
            </span>
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          16. Consequences of termination
        </Typography>
        <div className={classes.textContainer}>
          <Typography>16.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            On termination or expiry of this Agreement:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; PM will remove all Listings from the Website and cease
              publication of any further Listings;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; each party must cease using the other party's Confidential
              Information;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; except as expressly provided in this Agreement, all
              licences and other benefits granted under this Agreement shall immediately
              terminate; and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              d. &nbsp;&nbsp; PM may invoice for outstanding amounts owed, and Customer
              remains liable to PM in respect of outstanding invoices.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>16.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Despite any other provision of this Agreement, this clause 16 and any other
            provisions which by their nature are intended to do so, survive the
            termination or expiry of this Agreement.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          17. Notices
        </Typography>
        <div className={classes.textContainer}>
          <Typography>17.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            A notice or communication given to a party under this Agreement must be in
            writing and delivered personally or sent by pre-paid post or email to that
            party's address or email address as set out in the Particulars, or as
            otherwise notified by a party to the other party pursuant to this clause 17
            not less than 5 Business Days before a notice is given.{' '}
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>17.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Any notice given is taken to be given by the sender and received by the
            recipient (whether or not the recipient actually receives it):{' '}
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              a. &nbsp;&nbsp; if delivered in person, when it is left at the recipient's
              address;
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              b. &nbsp;&nbsp; if posted to the recipient's address, at 9.00 am on the
              fifth (tenth, if sent to or from an address in another country) day after
              the date of posting; and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              c. &nbsp;&nbsp; if sent by email, 2 hours after the time the email is sent
              to the recipient's email address, as recorded on the sender's email
              system, unless the sender receives, within that time period, an automatic
              notification (other than an out of office message) indicating that the
              email has not been delivered.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>17.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If any delivery or receipt would be deemed by clause 17.2 to be on a day
            that is not a Business Day or after 4.00 pm (recipient's time), it is deemed
            to have been received at 9.00 am (recipient's time) on the next Business
            Day.{' '}
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          18. General
        </Typography>
        <div className={classes.textContainer}>
          <Typography>18.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PM may vary this Agreement including the Listing Price or Subscription Price
            by providing not less than 90 days' written notice to the Customer. Where
            the Customer does not agree to the variation, the Customer may terminate
            this Agreement by providing written notice of its intention to do so within
            60 days' from the date of receiving the notice. If the Customer does not
            provide such notice then the variation will come into effect on and from the
            date set out in PM's notice.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>18.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            This Agreement contains the entire understanding between the parties
            concerning the subject matter of this Agreement and supersedes all prior
            communications between the parties.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>18.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            No amendment or variation of this Agreement is binding unless in writing and
            signed by all parties.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>18.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If any provision of this Agreement is or becomes invalid, illegal or
            unenforceable, it shall be deemed modified to the minimum extent necessary
            to rectify it.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>18.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Where any failure or delay by a party in the performance of its obligations
            under this Agreement (other than an obligation to pay) is caused, directly
            or indirectly, by a Force Majeure Event, that party is not liable for that
            failure or delay and its obligations under this Agreement are suspended, to
            the extent to which they are affected by the relevant Force Majeure Event,
            for the duration of the Force Majeure Event.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>18.6</Typography>
          <Typography variant="body1" className={classes.textContent}>
            A party cannot assign or otherwise transfer the benefit of, this Agreement
            without the prior written consent of the other party.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>18.7</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Any failure, delay, relaxation or indulgence by a party in exercising any
            power or right conferred on the party by this Agreement does not operate as
            a waiver of the power or right and does not preclude a further exercise of
            it or any other power or right under this Agreement.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>18.8</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Nothing in this Agreement is intended to, or shall be deemed to, establish
            any partnership or joint venture between any of the parties, constitute any
            party the agent of another party, nor authorise any party to make or enter
            into any commitments for or on behalf of any other party.
          </Typography>
        </div>

        <div className={classes.textContainer}>
          <Typography>18.9</Typography>
          <Typography variant="body1" className={classes.textContent}>
            This Agreement is governed by and must be construed in accordance with the
            laws in force in Victoria and the parties submit to the exclusive
            jurisdiction of the courts of Victoria and the Commonwealth of Australia in
            respect of all matters arising out of or relating to this Agreement.
          </Typography>
        </div>
        <div className={classes.apendix}>
          <div className={classes.textContainer}>
            <Typography style={{ color: '#35C0CA' }} className={classes.LineSpace}>
              <strong>Appendix 1 - Offerings </strong>
            </Typography>
          </div>
          <div>
            <OfferingsTable />
          </div>
          <div className={classes.textContainer}>
            <Typography style={{ color: '#35C0CA' }} className={classes.LineSpace}>
              <strong>Appendix 2 - Sorting Rules</strong>
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography>1.</Typography>
            <Typography variant="body1" className={classes.textRulesContent}>
              The order of priority of Core Products is as follows: Elite, Executive,
              Extended, Entry.
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography>2.</Typography>
            <Typography variant="body1" className={classes.textRulesContent}>
              Each Listing of the same Core Product will be ordered by published date
              from the most recent Listing (at the top) to the earliest Listing (at the
              bottom).
            </Typography>
          </div>
          <div className={classes.textContainer}>
            <Typography>3.</Typography>
            <Typography variant="body1" className={classes.textRulesContent}>
              Coming Soon Listings and Off Market Listings will be treated as per rule 1
              and 2, and will be treated as the same Core Product as set out in the
              Particulars.
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default withStyles(styles)(AdvertisingAgreement)
