import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, withStyles } from '@material-ui/core';
import { AgentsCard } from 'components/Web/Superadmin'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    // overflowY: "scroll",
    // overflowX: "hidden",
    marginRight: 20,
    marginTop: 10,
    marginBottom: 20,
  },
  cardContainer: {
    padding: 20,
  },
  infoText: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10vh',
  },
})

const AgentsLists = (props) => {
  const { classes, agentsData, match, type, handleConfirmDialogOpen } =
    props

  return (
    <div className={classes.root}>
      {agentsData ? (
        <Grid
          container
          spacing={2}
          justifyContent={'flex-start'}
          alignContent={'flex-start'}
          alignItems={'center'}
          className={classes.cardContainer}
        >
          {agentsData &&
            agentsData.map((agent, index) => {
              return (
                <Grid key={index} item xs={6} md={6} lg={4} xl={3}>
                  <AgentsCard
                    type={type}

                    match={match}
                    handleConfirmDialogOpen={handleConfirmDialogOpen}
                    agent={agent}
                  />
                </Grid>
              )
            })}
        </Grid>
      ) : (
        <Typography className={classes.infoText} variant={'body2'}>
          {'No agents listed'}
        </Typography>
      )}
    </div>
  )
}

AgentsLists.propTypes = {
  classes: PropTypes.object,
  agentsData: PropTypes.array,
  pagination: PropTypes.object,
  match: PropTypes.object,
  type: PropTypes.string,
}

export default withStyles(styles)(AgentsLists)
