import React from 'react';
import styled, { css } from 'styled-components'

import { Typography, Grid, withStyles } from "@material-ui/core";
import Searchbar from '../Searchbar';
import CustomSelect from './CustomSelect';

const styles = theme => ({
    inputWrap: {
        '& > div' : {
            width: '30vw',
            padding: 0,
            '& > div': {
                width: '100%', 
                '& > div': {
                    border: 'none'
                }             
            },
            '&:first-child': {
                marginRight: theme.spacing(2.5)
            },
            '&:last-child': {
                marginLeft: theme.spacing(2.5)
            },
            '& .MuiSelect-select' : {
                padding: theme.spacing(2.25, 3)
            }
        },
        '& .MuiFormControl-fullWidth > div' : {
            width: '100%',
        }
    },
})

const AgentContainer = styled.div`
    width:100%;
    height: 100%;
`

const AgentContainerOverlay = styled.div`
    height:75vh;
    width:100%;
    background: linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%);
    position: absolute;
    z-index: 1;
`

const ContentContainer = styled.div`
    margin:25vh auto 0;
    padding-left: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const StyledText = withStyles({
    root: {
        textAlign:'center',
        color:'#fff',
        fontSize:'36px',
        lineHeight:'1.19',
        zIndex: 5,
    },
  })(Typography);

const StyledSubText = withStyles({
    root: {
        textAlign:'center',
        color:'#fff',
        fontSize:'15px',
        lineHeight:'1.19',
        padding:'15px',
        zIndex: 5,
    },
  })(Typography);

const InputWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding-top: 20px;
`

const categoryOptions = [
{
    id:0,
    label:'Select a category',
    value:'select'
},{
    id:1,
    label:'All',
    value:'all'
},
{
    id:2,
    label:'Landscaper',
    value:'landscaper'
},
{
    id:3,
    label:'Plumber',
    value:'plumber'
},
{
    id:4,
    label:'Gardener',
    value:'gardener'
},
{
    id:5,
    label:'Interior Designer',
    value:'interior-designer'
},
{
    id:6,
    label:'Landscape Designer',
    value:'landscape-designer'
},
{
    id:7,
    label:'Electrician',
    value:'electrician'
},
{
    id:8,
    label:'Carpet Cleaner',
    value:'carpet-cleaner'
}
]

const TradesLanding = props => {
    const {
        imageBG,
        clearAgentSearchParams,
        classes
    } = props;

    const [category, setCategory] = React.useState(null);

    const handleCategory = (data) => {
        setCategory(data)
    }

    return (
        <AgentContainer style={{
            backgroundImage: `url(${imageBG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }}>
            <AgentContainerOverlay />
            <ContentContainer>
                <StyledText variant="h2">
                Search to find your perfect trade or service
                </StyledText>
                <StyledSubText variant="h2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id lectus euismod 
                    <br/> ex porta facilisis. Maecenas ut ultrices odio. 
                </StyledSubText>
                <InputWrap className={classes.inputWrap}>
                    <Searchbar
                        type={'search'}
                        sortType={'search'}
                        isFullWidth={true}
                        showSearchBar={true}
                        showFilters={false}
                        setPropertySearch={props.setPropertySearch}
                        clearPropertySuburb={props.clearPropertySuburb}
                        apiRequestSearchProperty={props.apiRequestSearchProperty}
                    />

                    <CustomSelect 
                        options={categoryOptions} 
                        handleCategory={handleCategory} 
                        isClearFilter={false}
                        value={category}
                    />

                    
                </InputWrap>
            </ContentContainer>
        </AgentContainer>
    );
}

export default withStyles(styles)(TradesLanding);