import { Box, Button, Grid, makeStyles, TextField, Typography, Checkbox, Stack } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Loader } from 'components/Common';
import StripePaymentForm from 'components/Common/Billing/StripePaymentForm';
import PromoCode from 'components/Common/Billing/PromoCode';
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import CustomDatePicker from 'components/Web/Filter/CustomDatePicker';
import CustomSelect from 'components/Web/Filter/CustomSelect';
import AlertNotification from 'components/Web/Form/AlertNotification';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { billingActions } from 'store/actions/billing.actions';
import { StorageActions } from 'store/actions/storage.actions';
import { requestPOSTAPIBilling } from 'store/api/billing.api';
import { requestGETAPIBilling } from 'store/api/billing.api';
import { getClientSecret } from 'store/selectors/billing.selectors';
import { getPricePackages } from 'store/selectors/billing.selectors';
import { getUser } from 'store/selectors/superadmin.selectors';
import { cleanDateToday } from 'utils/cleanDateToday';
import { computeDateDiff } from 'utils/computeDateDiff';
import useHandleManageAgencyRowCount from 'utils/customHooks/useHandleManageAgencyRowCount';
import useHandleManageAgencyPageCount from 'utils/customHooks/useHandleManageAgencyPageCount';
import { useRouterChange } from 'utils/router.utils';

const useStyles = makeStyles({
  docusignButton: {
    width: '70%',
    height: '50px',
    borderRadius: '40px',
    backgroundColor: '#fdbd26',
    borderColor: '#fdbd26',
    '&:hover': {
      backgroundColor: 'rgba(253, 189, 38, 0.8)',
    },
  },
});

const API_KEY = process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const ACCOUNT_ID = process?.env?.REACT_APP_STRIPE_ACCOUNT_ID;
const stripePromise = loadStripe(API_KEY, { stripeAccount: ACCOUNT_ID });

const AccountInfo = ({
  packages,
  showStripeForm,
  handleShowStripeForm,
  trialDays,
  trialDaysHandler,
  routerChange,
  accountType,
  handleChangeAccountType,
  nextFormAction,
}) => {
  const today = cleanDateToday();

  const [rowCount, setRowCount] = useHandleManageAgencyRowCount();
  const [pageCount, setPageCount] = useHandleManageAgencyPageCount();
  const [startDate, setStartDate] = useState(today);

  const [accountOptions, setAccountOptions] = useState([
    { value: 'Paid', label: 'Paid' },
    { value: 'Free', label: 'Free' },
  ]);
  const [isSelectPackageDisabled, setIsSelectPackageDisabled] = useState(false);
  const [isSkipButtonDisabled, setIsSkipButtonDisabled] = useState(true);

  const [packageError, setPackageError] = useState(false);
  const [message, setMessage] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,

    title: '',
    message: '',
  });

  const dispatch = useDispatch();
  const AGENCY_DETAILS = useSelector(getUser);

  const apiRequestGetClientSecret = (params) => {
    const request = {
      query: null,
      data: params,
    };
    handleGetClientSecretClear();
    dispatch(requestPOSTAPIBilling(request, 'STRIPE_POST_CLIENTSECRET', 'REQUEST'));
  };

  const handleGetClientSecretClear = () => {
    dispatch(billingActions(null, 'STRIPE_POST_CLIENTSECRET', 'CLEAR'));
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialog((prev) => ({ ...prev, open: false }));
  };

  const handleConfirmDialogCallback = () => {
    nextFormAction('next');
    setIsSelectPackageDisabled(true);
    setIsSkipButtonDisabled(false);
    setConfirmDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const calculatedBillingDate = useMemo(() => {
    let new_date = moment(startDate, 'DD-MM-YYYY').add('days', trialDays);
    return new_date;
  }, [startDate, trialDays]);

  useEffect(() => {
    if (accountType) {
      setIsSelectPackageDisabled(false);
    }
  }, [accountType]);

  const calculatedDateValue = useMemo(() => {
    let new_date = moment(startDate, 'DD-MM-YYYY').add('days', trialDays);
    let day = new_date.format('DD');
    let month = new_date.format('MM');
    let year = new_date.format('YYYY');
    return day + '/' + month + '/' + year;
  }, [startDate, trialDays]);

  const handleManageAgencyPageReset = () => {
    setRowCount(30);
    setPageCount(0);
  };

  return (
    <Box>
      <ConfirmDialog
        isOpen={confirmDialog.open}
        confirmCallback={() => handleConfirmDialogCallback()}
        closeCallback={() => handleConfirmDialogClose()}
        showLogo={true}
        align={'center'}
        title={confirmDialog.title}
        text="Can only edit the account type on manage agencies, do you wish to continue with the chosen account type?"
      />

      <Box marginTop="10px">
        <CustomSelect
          styles={{
            disabled: showStripeForm,
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          isForm={true}
          label="Account Type"
          options={accountOptions}
          handleSelect={(e) => {
            handleChangeAccountType(e);
          }}
          isClearFilter={false}
          value={accountType}
        />
      </Box>

      <Box
        marginTop="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridGap="15px"
        flexDirection="column"
      >
        <Button
          disabled={accountType && !isSelectPackageDisabled ? false : true}
          color={'primary'}
          variant="contained"
          style={{
            width: '70%',
            height: '50px',
            borderRadius: '40px',
            color: 'white',
          }}
          onClick={() => {
            handleManageAgencyPageReset();

            setConfirmDialog((prev) => ({
              ...prev,
              title: 'Account Type',
              open: true,
            }));
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

const AgencyAccountForm = (props) => {
  const [routerChange] = useRouterChange();
  const [trialDays, setTrialDays] = useState(90);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [isUsePromoCode, setIsUsePromoCode] = useState(false);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  const dispatch = useDispatch();
  const PACKAGES = useSelector(getPricePackages);
  const CLIENTSECRET = useSelector(getClientSecret);
  const AGENCY_DETAILS = useSelector(getUser);

  const handleShowStripeForm = () => {
    setShowStripeForm((prev) => !prev);
  };

  const handleGetPackageClear = () => {
    dispatch(billingActions(null, 'STRIPE_GET_PRICES', 'CLEAR'));
  };

  const trialDaysHandler = (value) => {
    setTrialDays(value);
  };

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const apiRequestGetPricePackages = () => {
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClear();
    dispatch(requestGETAPIBilling(request, 'STRIPE_GET_PRICES', 'REQUEST'));
  };

  useEffect(() => {
    apiRequestGetPricePackages();
  }, []);

  useEffect(() => {
    if (showStripeForm) {
      if (!clientSecret) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }
  }, [showStripeForm, clientSecret]);

  useEffect(() => {
    if (CLIENTSECRET) {
      if (
        CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_SUCCESS' ||
        CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_FAILED'
      ) {
        setIsLoading(false);
      }

      if (CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_SUCCESS') {
        setClientSecret(CLIENTSECRET?.data?.clientSecret);
      }

      if (CLIENTSECRET.status === 'billing/STRIPE_POST_CLIENTSECRET_FAILED') {
        handleNotification(true, {
          severity: 'error',
          message: 'Error authenticating payment gateway. Please try again after few seconds.',
        });
      }
    }
  }, [CLIENTSECRET]);

  return (
    <Box marginTop="20px" marginRight="20px" paddingBottom="60px" width="90vw">
      <Loader isLoading={isLoading} />
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={() => setIsNotification(false)}
      />
      <Typography
        style={{
          fontSize: '21px',
          fontWeight: 300,
          marginBottom: '10px',
          letterSpacing: '-0.525px',
          lineHeight: '32px',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
        variant={'h1'}
      >
        Account
      </Typography>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center" style={{ height: '100%' }}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <AccountInfo
            packages={PACKAGES?.packages}
            showStripeForm={showStripeForm}
            handleShowStripeForm={handleShowStripeForm}
            trialDays={trialDays}
            trialDaysHandler={trialDaysHandler}
            clientSecret={clientSecret}
            routerChange={routerChange}
            accountType={props.accountType}
            handleChangeAccountType={props.handleChangeAccountType}
            nextFormAction={props.nextFormAction}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgencyAccountForm;
