import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DateTime as DT } from 'luxon'

import {
  Container,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
  Chip,
  IconButton,
  CircularProgress,
  withStyles,
} from '@material-ui/core'
import {
  getPaymentMethodIntent,
  getSuccess,
  getStatus,
  getSubscriptionDetails,
  getPaymentMethods,
} from 'store/selectors/billing.selectors'
import Crumbs from 'components/Web/Header/Crumbs'
import { billingActions } from 'store/actions/billing.actions'
import { requestGETAPIBilling, requestPOSTAPIBilling } from 'store/api'
import AlertNotification from 'components/Web/Form/AlertNotification'
import DashboardBanner from 'components/Web/Dashboard/DashboardBanner'
import { getAgency } from 'store/selectors/agencyv2.selectors'
import dashBannerIMG from 'assets/images/agentbanner.png'
import StripePaymentForm from 'components/Common/Billing/StripePaymentForm'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import {
  CheckCircle,
  WarningRounded,
  Error,
  CachedRounded,
  Add,
  Remove,
  Close,
  Delete,
} from '@material-ui/icons'
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog'

const API_KEY = process?.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY
const ACCOUNT_ID = process?.env?.REACT_APP_STRIPE_ACCOUNT_ID
const stripePromise = loadStripe(API_KEY, { stripeAccount: ACCOUNT_ID })

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    paddingLeft: 90,
    paddingRight: theme.spacing(2),
    margin: '0 auto',
    width: '100%',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  formWrapper: {
    marginTop: theme.spacing(4),
    '@media (max-width: 960px) ': {
      margin: '0 auto',
      '& > h2': {
        textAlign: 'center',
      },
    },
  },
  statusWrap: {
    textAlign: 'center',
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusCard: {
    maxWidth: 600,
    margin: '0 auto',
  },
  subHeading: {
    marginBottom: theme.spacing(2),
  },
  contentCard: {
    padding: theme.spacing(2, 0),
  },
  paymentMethodList: {
    '& div > span': {
      textTransform: 'capitalize',
    },
  },
  cardFormGrid: {
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
})

const CustomerPortal = (props) => {
  const { classes, location } = props

  const dispatch = useDispatch()
  const PAYMENTMETHOD_INTENT = useSelector(getPaymentMethodIntent)
  const AGENCY = useSelector(getAgency)
  const PAYMENT_METHODS = useSelector(getPaymentMethods)
  const SUCCESS = useSelector(getSuccess)
  const STATUS = useSelector(getStatus)
  const SUBSCRIPTION_DETAILS = useSelector(getSubscriptionDetails)

  const [isLoading, setIsLoading] = useState(false)
  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)
  const [clientSecret, setClientSecret] = useState(null)
  const [message, setMessage] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [isRemoveLoading, setIsRemoveLoading] = useState(false)
  const [isDefaultLoading, setIsDefaultLoading] = useState(false)
  const [confirmRemoveCard, setConfirmRemoveCard] = useState(false)
  const [cardIdToRemove, setCardIdToRemove] = useState(null)

  const [paymentMethods, setPaymentMethods] = useState(null)
  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  const getRemainingTrialDays = (end) => {
    if (end) {
      const endDate = DT.fromSeconds(end)
      const now = DT.fromSeconds(DT.now().toUnixInteger())
      const res = endDate.diff(now, ['days', 'hours']).toObject()
      return res
    } else {
      return ''
    }
  }

  const getFormattedDate = (date) => {
    if (date) {
      const newDate = DT.fromSeconds(date).toLocaleString(DT.DATE_FULL)
      return newDate
    } else {
      return ''
    }
  }

  const clearReduxState = () => {
    handleGetSubscriptionDetailsClear()
    handleGetSetupPaymentMethodIntentClear()
    handleGetPaymentMethodsClear()
  }

  window.onbeforeunload = (e) => {
    return clearReduxState()
  }

  useEffect(() => {
    const params = new URLSearchParams(location?.search)
    if (params.get('redirect_status') && params.get('setup_intent')) {
      switch (params.get('redirect_status')) {
        case 'succeeded':
          setMessage('Card added successfully!')
          setPaymentStatus('success')
          break
        case 'processing':
          setMessage('Add card is processing.')
          setPaymentStatus('processing')
          break
        case 'requires_payment_method':
          setMessage('Adding card was not successful, please try again.')
          setPaymentStatus('unsuccessful')
          break
        default:
          setMessage('Something went wrong.')
          setPaymentStatus('error')
          break
      }
    }
    return () => {
      clearReduxState()
      document.getElementById('root').style.backgroundColor = 'initial'
    }
  }, [])

  const handleGetSetupPaymentMethodIntentClear = () => {
    dispatch(billingActions(null, 'STRIPE_POST_PAYMENTMETHODINTENT', 'CLEAR'))
  }

  const apiRequestGetSetupPaymentMethodIntent = (params) => {
    setIsLoading(true)
    const request = {
      query: null,
      data: {
        sourceActorReference: AGENCY?.subscription?.sourceActorReference,
      },
    }
    handleGetSetupPaymentMethodIntentClear()
    dispatch(
      requestPOSTAPIBilling(request, 'STRIPE_POST_PAYMENTMETHODINTENT', 'REQUEST')
    )
  }

  const apiRequestGetPaymentMethods = (params) => {
    setIsLoading(true)
    const sourceActorReference = AGENCY?.subscription?.sourceActorReference
    const request = {
      query: sourceActorReference + '/paymentmethods',
      data: null,
    }
    handleGetPaymentMethodsClear()
    dispatch(requestGETAPIBilling(request, 'STRIPE_GET_PAYMENTMETHODS', 'REQUEST'))
  }

  const apiRequestRemovePaymentMethod = (paymentMethod) => {
    setIsRemoveLoading(true)
    setConfirmRemoveCard(false)
    const sourceActorReference = AGENCY?.subscription?.sourceActorReference
    const request = {
      query: null,
      data: {
        sourceActorReference,
        paymentMethod,
      },
    }
    handleGetPaymentMethodsClear()
    dispatch(requestPOSTAPIBilling(request, 'STRIPE_REMOVE_PAYMENTMETHOD', 'REQUEST'))
  }

  const apiRequestDefaultPaymentMethod = (paymentMethod) => {
    setIsDefaultLoading(true)

    const sourceActorReference = AGENCY?.subscription?.sourceActorReference
    const request = {
      query: null,
      data: {
        sourceActorReference,
        paymentMethod,
      },
    }
    handleGetPaymentMethodsClear()
    dispatch(requestPOSTAPIBilling(request, 'STRIPE_DEFAULT_PAYMENTMETHOD', 'REQUEST'))
  }

  const handleGetPaymentMethodsClear = () => {
    dispatch(billingActions(null, 'GENERAL_BILLING', 'CLEAR_STATUS'))
  }

  const apiRequestGetSubscriptionDetails = (params) => {
    setIsLoading(true)
    const sourceReference = AGENCY?.subscription?.sourceReference
    const request = {
      query: sourceReference,
      data: null,
    }
    handleGetSubscriptionDetailsClear()
    dispatch(
      requestGETAPIBilling(request, 'STRIPE_GET_SUBSCRIPTION_DETAILS', 'REQUEST')
    )
  }
  const handleGetSubscriptionDetailsClear = () => {
    dispatch(billingActions(null, 'STRIPE_GET_SUBSCRIPTION_DETAILS', 'CLEAR'))
  }

  const handleConfirmDeleteCard = (id) => {
    setCardIdToRemove(id)
    setConfirmRemoveCard(true)
  }

  useEffect(() => {
    if (PAYMENTMETHOD_INTENT) {
      if (
        PAYMENTMETHOD_INTENT.status ===
        'billing/STRIPE_POST_PAYMENTMETHODINTENT_SUCCESS' ||
        PAYMENTMETHOD_INTENT.status === 'billing/STRIPE_POST_PAYMENTMETHODINTENT_FAILED'
      ) {
        setIsLoading(false)
      }

      if (
        PAYMENTMETHOD_INTENT.status ===
        'billing/STRIPE_POST_PAYMENTMETHODINTENT_SUCCESS'
      ) {
        setClientSecret(PAYMENTMETHOD_INTENT?.data?.client_secret)
      }

      if (
        PAYMENTMETHOD_INTENT.status === 'billing/STRIPE_POST_PAYMENTMETHODINTENT_FAILED'
      ) {
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message:
              'Error getting payment method authentication. Please try again after few seconds.',
          },
        })
      }
    }
  }, [PAYMENTMETHOD_INTENT])

  useEffect(() => {
    if (PAYMENT_METHODS) {
      if (
        STATUS === 'billing/STRIPE_GET_PAYMENTMETHODS_SUCCESS' ||
        STATUS === 'billing/STRIPE_GET_PAYMENTMETHODS_FAILED'
      ) {
        setIsLoading(false)
      }

      if (STATUS === 'billing/STRIPE_GET_PAYMENTMETHODS_SUCCESS') {
        setPaymentMethods(PAYMENT_METHODS?.data?.data)
      }

      if (STATUS === 'billing/STRIPE_GET_PAYMENTMETHODS_FAILED') {
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message:
              'Error getting payment methods list. Please try again after few seconds.',
          },
        })
      }
    }
  }, [PAYMENT_METHODS])

  useEffect(() => {
    const loadArray = [
      'billing/STRIPE_DEFAULT_PAYMENTMETHOD_SUCCESS',
      'billing/STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS',
      'billing/STRIPE_REMOVE_PAYMENTMETHOD_FAILED',
    ]
    if (SUCCESS) {
      if (loadArray.includes(STATUS)) {
        setIsRemoveLoading(false)
        setIsDefaultLoading(false)
      }

      if (STATUS === 'billing/STRIPE_REMOVE_PAYMENTMETHOD_SUCCESS') {
        setIsNotification({
          status: true,
          options: {
            severity: 'success',
            message: 'Card removed successfully.',
          },
        })

        handleGetPaymentMethodsClear()
        apiRequestGetPaymentMethods()
      }
      if (STATUS === 'billing/STRIPE_DEFAULT_PAYMENTMETHOD_SUCCESS') {
        handleGetPaymentMethodsClear()
        apiRequestGetPaymentMethods()
      }

      if (STATUS === 'billing/STRIPE_REMOVE_PAYMENTMETHOD_FAILED') {
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message: 'Error removing card. Please try again after few seconds.',
          },
        })
      }

      if (STATUS === 'billing/STRIPE_DEFAULT_PAYMENTMETHOD_FAILED') {
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message:
              'Error setting card as default. Please try again after few seconds.',
          },
        })
      }
    }
  }, [SUCCESS])

  useEffect(() => {
    if (SUBSCRIPTION_DETAILS) {
      if (
        SUBSCRIPTION_DETAILS?.status ===
        'billing/STRIPE_GET_SUBSCRIPTION_DETAILS_FAILED'
      ) {
        setIsLoading(false)
        setIsNotification({
          status: true,
          options: {
            severity: 'error',
            message:
              'Error getting subscription details. Please try again after few seconds.',
          },
        })
      }
    }
  }, [SUBSCRIPTION_DETAILS])

  useEffect(() => {
    if (AGENCY?.subscription?.sourceActorReference) {
      apiRequestGetSetupPaymentMethodIntent()
      apiRequestGetPaymentMethods()
      apiRequestGetSubscriptionDetails()
    }
  }, [AGENCY])
  const CRUMBS = [
    { label: 'Agency Dashboard', route: '/agency/dashboard' },
    { label: 'Billing & Payment', route: '/agency/billing' },
  ]
  return (
    <div className={classes.root}>
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
      <DashboardBanner
        title={'Billing & Payment'}
        content={
          <Typography
            variant="body1"
            component="div"
            dangerouslySetInnerHTML={{
              __html:
                AGENCY?.bannerIntro && AGENCY.bannerIntro !== ''
                  ? AGENCY.bannerIntro
                  : 'Welcome!',
            }}
          ></Typography>
        }
        image={dashBannerIMG}
      />
      <Container maxWidth={'lg'} style={{ maxWidth: 1012, padding: '24px 0' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Crumbs type={'DARK'} crumbsOption={CRUMBS} />
          </Grid>
        </Grid>
        {clientSecret ? (
          <Card className={classes.formWrapper}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Typography
                    variant={'h6'}
                    component={'h2'}
                    className={classes.subHeading}
                  >
                    My Subscription
                  </Typography>
                  <Divider />
                  <div className={classes.contentCard}>
                    {SUBSCRIPTION_DETAILS?.data && (
                      <Card
                        className={classes.subscriptionCard}
                        elevation={0}
                        variant={'outlined'}
                      >
                        <CardContent>
                          <Grid container spacing={0}>
                            <Grid item md={8}>
                              <Typography variant="h6" component="h2" gutterBottom>
                                <strong>{SUBSCRIPTION_DETAILS?.data?.plan}</strong>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              md={4}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="body2" component="p" gutterBottom>
                                <Typography variant="h6" component="span">
                                  <strong>
                                    ${SUBSCRIPTION_DETAILS?.data?.propertyPlanPrice}
                                  </strong>
                                </Typography>
                                /listing
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid item md={8}>
                              {SUBSCRIPTION_DETAILS?.data?.status === 'trialing' && (
                                <>
                                  <Typography
                                    className={classes.cardLabel}
                                    color={'textSecondary'}
                                    gutterBottom
                                  >
                                    Trial started from{' '}
                                    {`${getFormattedDate(
                                      SUBSCRIPTION_DETAILS?.data?.trialStart
                                    )}`}{' '}
                                    until&nbsp;
                                    {`${getFormattedDate(
                                      SUBSCRIPTION_DETAILS?.data?.trialEnd
                                    )}`}
                                    .
                                  </Typography>
                                  <Typography variant="body2" component="p">
                                    {`${getRemainingTrialDays(
                                      SUBSCRIPTION_DETAILS?.data?.trialEnd
                                    )?.days
                                      }`}{' '}
                                    trial days remaining
                                  </Typography>
                                </>
                              )}
                            </Grid>
                            <Grid
                              item
                              md={4}
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-start',
                              }}
                            >
                              {' '}
                              {SUBSCRIPTION_DETAILS?.data?.status === 'trialing' ? (
                                <Chip
                                  variant={'outlined'}
                                  color="primary"
                                  size="small"
                                  label={'Trial'}
                                />
                              ) : (
                                <Chip
                                  color="primary"
                                  size="small"
                                  label={'Active'}
                                  style={{ color: '#ffffff' }}
                                />
                              )}
                            </Grid>
                          </Grid>

                          {SUBSCRIPTION_DETAILS?.data?.status === 'active' && (
                            <Typography variant="body2" component="p">
                              Billing Period:{' '}
                              {`${getFormattedDate(
                                SUBSCRIPTION_DETAILS?.data?.currentPeriodStart
                              )}`}{' '}
                              -{' '}
                              {`${getFormattedDate(
                                SUBSCRIPTION_DETAILS?.data?.currentPeriodEnd
                              )}`}
                            </Typography>
                          )}
                        </CardContent>
                      </Card>
                    )}
                  </div>
                  <Typography
                    variant={'h6'}
                    component={'h2'}
                    className={classes.subHeading}
                  >
                    My Payment Methods
                  </Typography>
                  <Divider />

                  <div className={classes.contentCard}>
                    <List className={classes.paymentMethodList}>
                      {paymentMethods?.map((item, idx) => {
                        return (
                          <ListItem key={idx}>
                            <ListItemIcon>
                              <img
                                src={require(`assets/images/icons/` +
                                  item?.card?.brand +
                                  '.svg')}
                                alt={item?.card?.brand}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <>
                                  {item?.card?.brand}
                                  &nbsp;&bull;&bull;&bull;&bull;&nbsp;
                                  {item?.card?.last4}
                                </>
                              }
                              secondary={
                                <>
                                  Expires&nbsp;{item?.card?.exp_month}/
                                  {item?.card?.exp_year}{' '}
                                </>
                              }
                            />
                            {item?.is_default ? (
                              <Chip
                                label={'Default'}
                                size={'small'}
                                variant={'outlined'}
                                color="primary"
                              />
                            ) : (
                              <>
                                {isDefaultLoading ? (
                                  <CircularProgress color="primary" size={'18px'} />
                                ) : (
                                  <Chip
                                    label={'Set as default'}
                                    size={'small'}
                                    onClick={() => {
                                      apiRequestDefaultPaymentMethod(item?.id)
                                    }}
                                  />
                                )}
                                <IconButton
                                  onClick={() => {
                                    handleConfirmDeleteCard(item?.id)
                                  }}
                                  disabled={isRemoveLoading}
                                >
                                  {isRemoveLoading ? (
                                    <CircularProgress color="secondary" size={'18px'} />
                                  ) : (
                                    <Delete color={'secondary'} size={'small'} />
                                  )}
                                </IconButton>
                              </>
                            )}
                          </ListItem>
                        )
                      })}
                      <ListItem>
                        <Button
                          style={{ textTransform: 'initial', fontWeight: 'bold' }}
                          onClick={() => {
                            setShowAddPayment(!showAddPayment)
                          }}
                        >
                          {showAddPayment ? <Remove /> : <Add />}&nbsp;Add new card
                        </Button>
                      </ListItem>
                    </List>
                  </div>
                </Grid>
                {showAddPayment && clientSecret && (
                  <Grid item md={6} className={classes.cardFormGrid}>
                    <Typography
                      variant={'h6'}
                      component={'h2'}
                      className={classes.subHeading}
                    >
                      Add New Card
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setShowAddPayment(!showAddPayment)
                      }}
                      className={classes.closeBtn}
                    >
                      <Close />
                    </IconButton>
                    <Divider />
                    <div className={classes.contentCard}>
                      <Elements options={options} stripe={stripePromise}>
                        <StripePaymentForm
                          buttonLabel={'Add New Card'}
                          redirectPath={'/agency/billing'}
                        />
                      </Elements>
                    </div>
                  </Grid>
                )}

                {paymentStatus && message && !showAddPayment && (
                  <Grid item md={6} className={classes.cardFormGrid}>
                    <Typography
                      variant={'h6'}
                      component={'h2'}
                      className={classes.subHeading}
                    >
                      Add Payment Method
                    </Typography>
                    <Divider />
                    <div className={classes.statusWrap}>
                      <Card className={classes.statusCard} elevation={0}>
                        <CardContent>
                          {paymentStatus === 'success' && (
                            <div>
                              <CheckCircle color={'primary'} fontSize={'large'} />
                              <Typography variant={'h5'}>{message}</Typography>
                            </div>
                          )}
                          {paymentStatus === 'processing' && (
                            <div>
                              <CachedRounded color={'secondary'} fontSize={'large'} />

                              <Typography variant={'h5'}>{message}</Typography>
                            </div>
                          )}
                          {paymentStatus === 'unsuccessful' && (
                            <div>
                              <WarningRounded color={'error'} fontSize={'large'} />
                              <Typography variant={'h5'}>{message}</Typography>
                            </div>
                          )}
                          {paymentStatus === 'error' && (
                            <div>
                              <Error color={'error'} fontSize={'large'} />
                              <Typography variant={'h5'}>{message}</Typography>
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.formWrapper}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6}>
                  <Typography
                    variant={'h6'}
                    component={'h2'}
                    className={classes.subHeading}
                  >
                    My Subscription
                  </Typography>
                  <Divider />
                  <div className={classes.contentCard}>
                    {!isLoading && (
                      <Card
                        className={classes.subscriptionCard}
                        elevation={0}
                        variant={'outlined'}
                      >
                        <CardContent>
                          <Typography variant={'body2'} component={'p'}>
                            No active subscription.
                          </Typography>
                        </CardContent>
                      </Card>
                    )}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}

        {confirmRemoveCard && (
          <ConfirmDialog
            isOpen={confirmRemoveCard}
            confirmCallback={() => {
              apiRequestRemovePaymentMethod(cardIdToRemove)
            }}
            closeCallback={() => setConfirmRemoveCard(false)}
            showLogo={true}
            align={'center'}
            title={'Remove Card'}
            text={'Are you sure you want to remove selected card?'}
          />
        )}
      </Container>
    </div>
  )
}

export default withStyles(styles)(CustomerPortal)
