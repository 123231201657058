import React, { useState, useEffect } from 'react';

import Close from '@material-ui/icons/Close';

import { withStyles, Modal, Typography, FormControl, OutlinedInput } from '@material-ui/core';

const styles = theme => ({
    root: {
        margin: theme.spacing(2)
    },
    textField: {
        width: '100%'
    },
});

const StyledText = withStyles({
  root: {
      textAlign:'left',
      color:'#4D4D4D',
      fontSize:'14px',
      lineHeight:'1.19',
      paddingBottom:'10px',
  },
})(Typography);

const SavedInput = props => {  
    const { 
        classes,
        savedTitle,
        setSavedTitle,
        isError
    } = props;

    return (
        <div className={classes.root}>
            <FormControl className={classes.textField} variant="outlined">
                <StyledText variant="caption">
                    {'Name your search'}
                </StyledText>
                <OutlinedInput
                    value={savedTitle}
                    onChange={(value)=> setSavedTitle(value.target.value)}
                    labelWidth={0}
                    placeholder={'Moonee Ponds, Vic, 3039'}
                    error={isError}
                />
            </FormControl>
        </div>
    );
}

export default withStyles(styles)(SavedInput);