const convertPriceToScaledValue = (price, priceMarks) => {
  let index = 0

  for (index; index < priceMarks.length; index++) {
    // If price is equal to exact scale value
    if (price === priceMarks[index].scaledValue) {
      return priceMarks[index].value
    }

    if (price === priceMarks[index + 1].scaledValue) {
      return priceMarks[index + 1].value
    }

    // Find the 2 nearest scale for the price
    if (
      price > priceMarks[index].scaledValue &&
      price < priceMarks[index + 1].scaledValue
    ) {
      const scaleInterval = 25

      const pricePercentage =
        Math.abs(priceMarks[index].scaledValue - price) /
        (priceMarks[index + 1].scaledValue - priceMarks[index].scaledValue)

      const valueAddedToScale = scaleInterval * pricePercentage

      return priceMarks[index].value + valueAddedToScale
    }
  }
}

export default convertPriceToScaledValue
