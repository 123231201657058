import React, { useState, useEffect } from 'react';
import { Grid, withStyles, Button, Typography } from '@material-ui/core';
import ButtonStyleFileUpload from '../Form/ButtonStyleFileUpload';
import ConfirmDialog from '../../Web/CustomDialogs/ConfirmDialog';
import NotificationDialog from '../CustomDialogs/NotificationDialog';
import { Loader } from 'components/Common';
import { set } from 'lodash';
import AlertNotification from '../Form/AlertNotification';

const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
  },
  imageDark: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
    background:
      'linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%)',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width: '40%',
  },
  primaryBtn: {
    background: '#FFFFFF',
    border: '0.5px solid #c4c4c4',
    cursor: 'pointer',
    borderRadius: 25,
    padding: '12px 35px',
    transition: '0.3s ease',
    '&:hover': {
      opacity: 0.7,
    },
  },
  labelMb: {
    paddingBottom: 38,
  },
  statementImgContainer: {
    width: '295px',
    height: '157px',
    marginBottom: 35,
  },
  noStatementImg: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    borderStyle: 'dashed',
    borderColor: '#c4c4c4',
    borderWidth: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '44px 0',
    borderTop: '1px solid #cdcdcd',
    borderBottom: '1px solid #cdcdcd',
  },
  contentItems: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labelPb: {
    marginBottom: 15,
    '& > p': {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '23px',
    },
  },
  primaryBtnContainer: {
    marginBottom: 60,
  },
  statement: {
    fontSize: 21,
    fontWeight: 300,
    lineHeight: '32px',
    color: '#4d4d4d',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 768px)': {
      justifyContent: 'flex-start',
    },
  },
  skipButton: {
    minWidth: 120,
    border: '1px solid #35C0CA',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    marginRight: 16,
    borderRadius: 30,
    padding: '8px 16px',
    textTransform: 'capitalize',
    color: '#000',
  },
  nextStep: {
    paddingTop: 35,
    paddingBottom: 35,
  },
});

const AddPropertyStep4 = (props) => {
  const {
    classes,
    uploaded_intent,
    upload_status,
    upload_error,
    handleFieldChange,
    requestUploadIntent,
    setIsFormInvalid,
    logoutUser,
  } = props;

  const [confirmUpload, setConfirmUpload] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [showNotification, setShowNotification] = useState(false);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  useEffect(() => {
    setIsFormInvalid(true);

    return () => {
      setIsFormInvalid(true);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (upload_status === 'success' && !upload_error) {
      handleFieldChange('propertyInfo', null, 'statementOfIntent', uploaded_intent.uploadedFiles[0]);
      setLoading(false);
      setIsFormInvalid(false);
      setIsNotification({
        status: true,
        options: {
          type: 'success',
          message: 'Statement of Information uploaded successfully!',
        },
      });
    } else if (upload_error && files) {
      setLoading(false);
      setUploadError(true);
      setConfirmUpload(false);
      if (upload_error.status === 400 || upload_error.status === 401) {
        timeout = setTimeout(() => {
          logoutUser();
          setUploadError(false);
          window.location.reload();
        }, 4000);
      }
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [upload_status, upload_error]);

  const handleUploadConfirmation = (mode) => {
    var uploadData = new FormData();
    if (files && mode === 'upload') {
      setLoading(true);
      for (const file of files) {
        uploadData.append('soi', file, file.name);
      }
    }
    requestUploadIntent(uploadData);

    setConfirmUpload(false);
  };

  return (
    <div className={classes.root}>
      <Loader isLoading={loading} />
      <div className={classes.labelMb}>
        <Typography>Add the Statement of Information</Typography>
      </div>
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12} lg={6}>
          <Typography className={classes.statement}>Statement of Information</Typography>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.buttonContainer}>
          <Button className={classes.skipButton} onClick={() => setIsFormInvalid(false)}>
            Skip
          </Button>
          <ButtonStyleFileUpload
            label={
              uploaded_intent && upload_status && upload_status === 'success'
                ? uploaded_intent.uploadedFiles[0]
                : 'Upload Statement of Information Document'
            }
            onChange={(e) => {
              const maxFileSizeInKB = 20000000;
              if (e.target.files.length > 0) {
                if (e.target.files[0].size <= maxFileSizeInKB) {
                  setFileName(e.target.files[0].name);
                  setFiles(e.target.files);
                  setConfirmUpload(true);
                } else {
                  setFileSizeError(true);
                }
              }
            }}
            disabled={uploaded_intent && upload_status && upload_status === 'success'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className={classes.nextStep}>
        <Grid item xs={12} lg={6}>
          <Typography variant={'h6'}>Next Steps</Typography>
          <Typography variant={'body2'}>
            Your office admin plus anyone else you have nominated to approve the property <br />
            (i.e.: the vendor) will now be sent an email to view and accept/decline the property listing. Once all
            parties have approved, the property will go live.
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <ConfirmDialog
            isOpen={confirmUpload}
            confirmCallback={() => handleUploadConfirmation('upload')}
            closeCallback={() => setConfirmUpload(false)}
            showLogo={true}
            align={'center'}
            title={'Upload Statement of Information'}
            text={'Are you sure you want to upload ' + `${fileName ? fileName : 'selected file'}` + '?'}
          />
        </Grid>
      </Grid>
      {fileSizeError && (
        <NotificationDialog
          isOpen={fileSizeError}
          closeCallback={() => {
            setFileSizeError(false);
          }}
          title={'File uploaded exceeded on limit'}
          text="The file your trying to upload exceed the limit of 20MB please try another file"
          showLogo={true}
          align={'center'}
        />
      )}
      {uploadError && (
        <NotificationDialog
          isOpen={uploadError}
          closeCallback={() => {
            setUploadError(false);
          }}
          title={'Error'}
          text={
            upload_error.status === 401 || upload_error.status === 400
              ? 'Your session has expired or invalid. You will be asked to re-login after few seconds.'
              : 'Error encountered when uploading captured image.'
          }
          showLogo={true}
          align={'center'}
        />
      )}
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(AddPropertyStep4);
