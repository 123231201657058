import React, { useState } from 'react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import DreamPropertyCards from './DreamPropertyCards'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { withStyles, Typography, IconButton } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(10.6),
    paddingLeft: 0,
  },
  headerText: {
    fontSize: '1.5em',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 400,
    fontFamily: 'Montserrat, sans',
  },
  dot: {
    height: '8px',
    width: '8px',
    backgroundColor: '#bbb',
    borderRadius: '50%',
    display: 'inline-block',
    /*     '& li.slick-active button::before': {
      color: '#35C0CA',
    }, */
    '.button__bar :global(li.slick-active) button': {
      color: '#35C0CA',
    },
  },

  dots: {
    bottom: -50,
    '& li.slick-active button::before': {
      color: '#35C0CA',
    },
    '& li': {
      '& button::before': {
        fontSize: theme.typography.pxToRem(14),
        color: '#E0E0E0',
        opacity: 2,
      },
    },
  },
  cardContainer: {
    position: 'relative',
    display: 'block',
    width: 'auto',
    //overflow: 'hidden',
    '& .BrainhubCarousel__arrows': {
      position: 'absolute',
      top: '48%',
      transform: 'translateY(-50%)',
      zIndex: 2,
      backgroundColor: 'transparent',
      '& span': {
        borderColor: '#cdcdcd',
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },
    },
    '& .BrainhubCarousel__arrowRight': {
      right: '5%',
    },
    '& .BrainhubCarousel__arrowLeft': {
      left: '5%',
    },
    '& .BrainhubCarousel__arrows:hover': {
      backgroundColor: 'transparent',
      '& span': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  nextButton: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingRight: '0',
    color: '#ffffff',
    zIndex: 100,

    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
  prevButton: {
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: '0',
    color: '#ffffff',
    zIndex: 100,

    [theme.breakpoints.down(600)]: {
      display: 'none',
    },
  },
})

const DreamCarousel = (props) => {
  const {
    classes,
    properties,
    settings,
    authentication,
    apiRequestSaveProperty,
    toggleSignUp,
  } = props

  const [dreamProperties, setDreamProperties] = React.useState(null)
  const [slideIndex, setSlideIndex] = useState(0)
  const SliderRef = React.useRef()

  React.useEffect(() => {
    setDreamProperties(properties)
  }, [properties])

  const handleChange = (ind) => {
    if (slideIndex <= dreamProperties.length - 1 && !isNaN(ind)) {
      setSlideIndex(ind)
    }
  }

  const handleNext = () => {
    if (
      slideIndex <= dreamProperties.length - 1 &&
      slideIndex !== dreamProperties.length - 1 &&
      SliderRef?.current
    ) {
      setSlideIndex(slideIndex + 1)
      SliderRef.current.slickNext()
    }
  }

  const handlePrev = () => {
    if (slideIndex > 0 && SliderRef?.current) {
      setSlideIndex(slideIndex - 1)
      SliderRef.current.slickPrev()
    }
  }

  const carouselSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: `slick-dots ${classes.dots}`,
    //customPaging: (i) => <span className={classes.dot}></span>,
  }

  return (
    <div className={classes.root}>
      {dreamProperties && dreamProperties.length > 0 && (
        <div>
          <Typography className={classes.headerText} variant="h4" component="h4">
            {/* {"Handpicked for your viewing pleasure..."} */}
            {'Go on, dream a little...'}
          </Typography>
          <div className={classes.cardContainer}>
            <Slider
              value={slideIndex}
              afterChange={handleChange}
              ref={SliderRef}
              {...carouselSettings}
            >
              {dreamProperties.map((value, index) => {
                return (
                  <DreamPropertyCards
                    key={index}
                    properties={value}
                    type={'properties'}
                    toggleSignUp={toggleSignUp}
                    authentication={authentication}
                    settings={settings}
                    apiRequestSaveProperty={apiRequestSaveProperty}
                  />
                )
              })}
            </Slider>
            {dreamProperties && dreamProperties.length > 1 && (
              <>
                <IconButton
                  aria-label="Previous"
                  className={classes.prevButton}
                  disabled={slideIndex === 0}
                  onClick={handlePrev}
                >
                  <ChevronLeftIcon
                    style={{ fontSize: 50, color: 'rgb(169, 169, 169)' }}
                  />
                </IconButton>
                <IconButton
                  aria-label="Next"
                  className={classes.nextButton}
                  disabled={slideIndex === dreamProperties.length - 1}
                  onClick={handleNext}
                >
                  <ChevronRightIcon
                    style={{ fontSize: 50, color: 'rgb(169, 169, 169)' }}
                  />
                </IconButton>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(DreamCarousel)
