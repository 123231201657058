import React, { useEffect } from 'react'

import styled from 'styled-components'

import { withStyles, Typography } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  heading: {
    fontWeigth: '400',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: '#4d4d4d',
  },
  subHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
  },
  textContent: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
})

const Container = styled.div`
  max-width: 1440px;
  padding: 20px 120px 120px 155px;
  @media (min-width: 1440px) {
    margin: 0 auto;
  }
`

const PrivacyPolicyMobileApp = ({ classes, history }) => {
  useEffect(() => {
    localStorage.removeItem('hideState')
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={classes.root}>
      <Container>
        <Typography variant="h5" className={classes.heading}>
          Privacy Policy - PropertyMate Group Pty Ltd
        </Typography>
        <Typography variant="body1" className={classes.heading}>
          This Privacy Policy was last updated on 22 November 2022.
        </Typography>
        <Typography variant="h6" className={classes.subHeading}>
          1. About this Privacy Policy
        </Typography>
        <div className={classes.textContainer}>
          <Typography>1.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PropertyMate Group Pty Ltd ACN 642 236 039 (
            <strong>PropertyMate Group, we, us</strong> or <strong>our</strong>) has
            implemented this Privacy Policy to provide information about what kinds of
            Personal Information we may collect or hold, how we collect, hold, use and
            disclose that Personal Information, choices you have regarding our use of
            that Personal Information, and your ability to access or correct that
            Personal Information. If you wish to make any inquiries regarding this
            Privacy Policy, you should contact our Privacy Officer in any of the ways
            specified in paragraph 15.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>1.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            From time to time, PropertyMate Group may be related to other companies and
            entities (related entities). This Privacy Policy applies to the use of your
            Personal Information by us and by those related entities. A reference in
            this Privacy Policy to PropertyMate Group, we, us or our is also a reference
            to those related entities.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          2. Personal Information
        </Typography>
        <div className={classes.textContainer}>
          <Typography>2.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            <strong>"Personal Information"</strong> is information or an opinion,
            whether true or not, and whether recorded in a material form or not, about
            an identified individual or an individual who is reasonably identifiable.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          3. What Personal Information do we collect and hold?
        </Typography>
        <div className={classes.textContainer}>
          <Typography>3.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The types of Personal Information we may collect about an individual will
            depend upon the nature of our interaction with them. Personal Information
            that we collect may include (but is not limited to) the following:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp; name
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp; age and/or date of birth
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp; contact details including telephone numbers (landline
              and/or mobile)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp; address (including postal address)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (e) &nbsp;&nbsp; email address
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (f) &nbsp;&nbsp; gender
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (g) &nbsp;&nbsp; identification information such as driving licence
              details
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (h) &nbsp;&nbsp; payment details, such as your credit card or bank account
              details
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (i) &nbsp;&nbsp; occupation and employment details including employment
              status and any previous work experience
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (j) &nbsp;&nbsp; information from or in connection with your resume or job
              application if you apply for a position with us
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (k) &nbsp;&nbsp; geographical location
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (l) &nbsp;&nbsp; Australian Business Number (ABN)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (m) &nbsp;&nbsp; trade references, credit references or reports from a
              third party
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (n) &nbsp;&nbsp; photographs and/or images of you from camera footage, and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (o) &nbsp;&nbsp; information from social media accounts and profiles.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>3.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We collect and record Personal Information about individuals such as:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp; our customers, potential customers and their
              representatives
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp; our Website and mobile application (<strong>App</strong>)
              visitors and users (see paragraph 5)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp; our investors and shareholders
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp; our suppliers and potential suppliers and their
              representatives, directors, partners, proprietors and shareholders
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (e) &nbsp;&nbsp; contractors and subcontractors and potential contractors
              and subcontractors and their representatives in relation to providing
              goods and services to us
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (f) &nbsp;&nbsp; our employees past and present, including applicants, and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (g) &nbsp;&nbsp; any other person who comes into contact with PropertyMate
              Group.
            </span>
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          4. How and when do we collect Personal Information?
        </Typography>
        <div className={classes.textContainer}>
          <Typography>4.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We collect your Personal Information to allow us to conduct our business
            functions, to provide, market and sell our products and services and for the
            specified purposes set out in paragraph 6. In some circumstances the
            collection of Personal Information may be required by law.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may collect your Personal Information in the course of providing you with
            goods or services, or:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp; when you use or buy our products or services
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;when you provide us, or you offer or apply to supply us,
              with goods or services
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp;when you provide information to us in any way (including
              by completing a form, disclosing information over the phone or via email,
              or providing us a business card)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp;when you request information about us, our products or our
              services
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (e) &nbsp;&nbsp;when you provide feedback to us
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (f) &nbsp;&nbsp;when you visit or fill in a form on our Website or use our
              App (see paragraph 5)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (g) &nbsp;&nbsp;when you respond to surveys and/or promotions
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (h) &nbsp;&nbsp;when you visit premises from which we operate
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (i) &nbsp;&nbsp; when you (or your employer) provide that information to
              us in the course of conducting or administering our relationship with you,
              or when you are carrying out activities in connection with our business
              operations
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (j) &nbsp;&nbsp;when you submit a job application to us
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (k) &nbsp;&nbsp;when you otherwise contact us by telephone, fax, email,
              social media, post or in person, or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (l) &nbsp;&nbsp;where we are otherwise required or authorised by law to do
              so.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Generally, when providing our products and services, dealing with our
            personnel, or obtaining goods and services from our service providers,
            suppliers or contractors, we collect personal information directly from the
            relevant individual where reasonable and practicable.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may also collect Personal Information about you from third parties and
            other sources such as:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp; your nominated representatives (eg real estate agent,
              listing agent, spouse, accountant, power of attorney, brokers and other
              professional advisors)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;our share registry service provider
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp;publicly available sources of information, or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp;related entities, companies and businesses of PropertyMate
              Group,
            </span>
            but we will only collect your Personal Information in this way if it is
            unreasonable or impracticable to collect this information directly from you
            or if we are otherwise permitted to do so.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If the Personal Information we collect includes sensitive information,
            including health information, we will ask for your consent to collect
            sensitive information, unless the law allows us to collect it without your
            consent.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.6</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Where we engage with you multiple times over a short period in relation to
            the same matter, we may not provide you with a separate notice about privacy
            each time we engage with you.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>4.7</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If you choose not to provide your Personal Information to us for the
            purposes set out in this Privacy Policy, or if we do not or are unable to
            collect the Personal Information we require, we may not be able to provide
            you with requested information, products or services, or to effectively
            conduct our relationship with you.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          5. Information collected via our Website and App
        </Typography>
        <div className={classes.textContainer}>
          <Typography>5.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Personal information may be collected by us and by our third party service
            providers who assist us in operating our website at{' '}
            <a
              href="https://www.propertymate.com.au"
              target="_blank"
              style={{ color: '#000000' }}
            >
              https://www.propertymate.com.au
            </a>
            , including its subdomains and any other website we operate from time to
            time (collectively the <strong>Website</strong>), and our App
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may use various technological methods from time to time to track the
            visiting patterns of individuals accessing our Website or using our App,
            including but not limited to the methods set out in this paragraph 5.
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              &nbsp;&nbsp;
            </span>
            <strong>Google Analytics and other analytics services</strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may use analytics services such as Google Analytics to help analyse how
            you use our Website. These services generate statistical and other
            information about website or App use by means of cookies, which are stored
            on users' computers, or other technology. The information generated is used
            to create reports about the use of our Website.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If you do not want your Website visit data reported by such services, you
            may be able to opt-out, depending on the service used. For example, for
            Google Analytics, you can install the Google Analytics opt-out browser
            add-on. For more details on installing and uninstalling the add-on, please
            visit the Google Analytics opt-out page at{' '}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              style={{ textDecoration: 'none', color: '#000000' }}
            >
              https://tools.google.com/dlpage/gaoptout
            </a>
            .
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              &nbsp;&nbsp;
            </span>
            <strong>Click Stream Data</strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            When you read, browse or download information from our Website or use our
            App, we or our internet service provider or other service provider may also
            collect information such as the date, time and duration of a visit, the
            pages accessed, the IP address of your computer, browser type, device type
            and other device information, operating system, website visited immediately
            before coming to our site, and any information downloaded. This information
            is used for statistical, reporting and website administration, maintenance
            and improvement purposes.
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              &nbsp;&nbsp;
            </span>
            <strong>Cookies</strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.6</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Our Website may use 'cookies' from time to time. Cookies are small text
            files that are transferred to a user's computer hard drive by a website for
            the purpose of storing information about a user's identity, browser type or
            website visiting patterns. Cookies may be used on our Website to monitor and
            analyse web traffic, for example the time of visit, pages visited and some
            system information about the type of computer being used. We use this
            information to enhance the content and services offered on our Website. In
            addition, cookies may be used to serve relevant ads to Website visitors
            through third party services such as Google Adwords. These ads may appear on
            our Website or other websites you visit.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.7</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Cookies are sometimes also used to collect information about what pages you
            visit and the type of software you are using. If you access our Website or
            click-through to our Website from a link in an email we send you, a cookie
            may be downloaded onto your computer's hard drive.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.8</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Cookies may also be used for other purposes on our Website.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.9</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You can configure your browser to accept all cookies, reject all cookies, or
            notify you when a cookie is sent. Each browser is different, so check the
            "Help" menu of your browser to learn how to change your cookie preferences.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.10</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If you disable the use of cookies on your web browser or remove or reject
            specific cookies from our Website or linked sites then you may not be able
            to gain access to all of the content and facilities in those websites.
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              &nbsp;&nbsp;
            </span>
            <strong>Third party content (eg social media links)</strong>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>5.11</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Some of the content on our Website may include applications made available
            by third parties, such as social media buttons or links that allow you to
            share content or links to our Website through the relevant third party
            platforms. These third party applications themselves may facilitate
            collection of information by those third parties, through your interaction
            with the applications and sometimes even if you do not interact directly
            with them. These links are meant for your convenience only. Links to third
            party websites do not constitute sponsorship or endorsement or approval of
            these websites. We are not responsible for the technical operation of these
            applications or the collection and use practices of the relevant third
            parties. Please visit the relevant third party websites to understand their
            privacy practices and options they may make available to you in relation to
            their collection of your Personal Information.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          6. How do we use your Personal Information?
        </Typography>
        <div className={classes.textContainer}>
          <Typography>6.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We use the Personal Information we collect about you for our business
            functions and activities, in order to operate our business efficiently, and
            to market our products and services for the benefit of our customers.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>6.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may collect, hold and use your personal information:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp;to identify and communicate with you (by a variety of
              measures including but not limited to telephone, email, sms or mail)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;to enable us to provide you with requested information,
              products or services
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp;to otherwise assist customers by providing them with
              information and support
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp;to collect and process payments
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (e) &nbsp;&nbsp;to help us to manage and enhance products or services we
              provide to you
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (f) &nbsp;&nbsp;to help us to manage and enhance goods and services we
              procure from our suppliers and subcontractors
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (g) &nbsp;&nbsp;to help us manage and respond to a general or specific
              shareholder enquiry
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (h) &nbsp;&nbsp;to process share applications and service shareholders'
              needs
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (i) &nbsp;&nbsp;to provide facilities and services a shareholder may
              request
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (j) &nbsp;&nbsp;to carry out appropriate administration in relation to our
              shareholders and our share registry, including to submit listing
              applications to, or respond to enquiries from, corporate regulators
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (k) &nbsp;&nbsp;to personalise and customise your experiences on our
              Website and in our App
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (l) &nbsp;&nbsp;to manage and administer any account you may hold with us
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (m) &nbsp;&nbsp;to carry out debt recovery functions
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (n) &nbsp;&nbsp;to promote and market our products and services to you
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (o) &nbsp;&nbsp;to provide you with information that we believe may be of
              interest to you or that you may be interested in receiving, including
              advertising material, regarding us, our clients, and our business partners
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (p) &nbsp;&nbsp;to conduct research for the purposes of improving existing
              products or services or creating new products or services
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (q) &nbsp;&nbsp;to help us research the needs of our customers to enable
              us to market our products and services with a better understanding of your
              needs and the needs of customers generally
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (r) &nbsp;&nbsp;to analyse our shareholder base and for product
              development and planning
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (s) &nbsp;&nbsp;to protect you and us from fraud
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (t) &nbsp;&nbsp;to protect our legal rights
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (u) &nbsp;&nbsp;to provide for the safety and security of workers and
              onsite visitors
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (v) &nbsp;&nbsp;to help us manage our business operations
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (w) &nbsp;&nbsp;for business support purposes including maintenance,
              backup and audit
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>6.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may aggregate personal information for reporting, statistical and
            analysis purposes, and for business, product and service improvement
            purposes. This allows us to better inform ourselves and anticipate our
            customers' preferences and requirements, and to monitor and improve the
            effectiveness of our business, products and services. We may also
            de-identify information for inclusion in such aggregated databases or
            reports.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>6.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We reserve the right at all times to monitor, review, retain, and/or
            disclose any information as necessary to satisfy any applicable law, but we
            have no obligation to monitor the use of the Website or to retain the
            content of any user session.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>6.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You consent to us using your Personal Information in the above ways and as
            set out in this Privacy Policy.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>6.6</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may otherwise collect, use or disclose your Personal Information where
            the collection, use or disclosure is:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp;in accordance with this Privacy Policy or any agreement
              you enter into with us, or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;required or authorised by law, including without
              limitation the Australian Privacy Principles under the Privacy Act 1988
              (Cth).
            </span>
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          7. When do we disclose your Personal Information?
        </Typography>
        <div className={classes.textContainer}>
          <Typography>7.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PropertyMate Group may disclose, or provide access to, your Personal
            Information to third parties in connection with the purposes described in
            paragraph 6. Depending on the circumstances and the nature of your
            engagement with us, we may disclose your Personal Information to our related
            entities, to third parties that provide products and services to us or
            through us, or to other third parties (such as your referee(s) in connection
            with a job application you have submitted).
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may also disclose your Personal Information to:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp;any of PropertyMate Group's internal divisions, business
              units or departments
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;your nominated representatives
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp;other organisations or individuals who assist us in
              providing products and services to you
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp;professional service providers and advisors who perform
              functions on our behalf, such as lawyers
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (e) &nbsp;&nbsp;medical providers including medical and rehabilitation
              practitioners for assessing insurance claims
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (f) &nbsp;&nbsp;representatives, agents or contractors who are appointed
              by us in the ordinary operation of our business to assist us in providing
              goods or services or administering our business (such as for data storage
              or processing, printing, mailing, marketing, planning and product or
              service development)
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (g) &nbsp;&nbsp;debt collecting agencies
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (h) &nbsp;&nbsp;banks, lenders, valuers, insurers, brokers, auditors,
              business consultants and IT service providers, and
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (i) &nbsp;&nbsp;Government, regulatory authorities and other organisations
              as required or authorised by law (such as Centrelink or the Police).
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may also disclose your Personal Information to our Website host or
            software application providers in certain limited circumstances, for example
            when our Website experiences a technical problem or to ensure that it
            operates in an effective and secure manner.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>7.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            As we continue to develop our business, we may buy, merge or partner with
            other companies or organisations, and in so doing, acquire customer Personal
            Information. In such transactions, Personal Information may be among the
            transferred assets. Similarly, in the event that a portion or substantially
            all of our business or assets are sold or transferred to a third party, we
            may also disclose certain information including your Personal Information to
            a purchaser or potential purchaser in connection with the sale or potential
            sale of us, our business or any of our assets, including in insolvency.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          8. Overseas disclosures
        </Typography>
        <div className={classes.textContainer}>
          <Typography>8.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Some of your Personal Information may from time to time be disclosed,
            transferred, stored, processed or used overseas by us, or by third party
            service providers. This may happen if:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp;our offices or related entities are overseas
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;we outsource certain activities overseas
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp;transactions, information, services or products have an
              overseas connection, or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp;our computer systems including IT servers are located
              overseas.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>8.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You consent to the collection, use, storage, and processing of your Personal
            Information outside of Australia as set out in this Privacy Policy.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>8.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            In particular, your Personal Information may be disclosed to third parties
            in countries in which those parties or their, or our, computer systems may
            be located from time to time, where it may be used for the purposes
            described in this Privacy Policy.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          9. Other uses and disclosures
        </Typography>
        <div className={classes.textContainer}>
          <Typography>9.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may collect, use and disclose your Personal Information for other
            purposes not listed in this Privacy Policy. If we do so, we will make it
            known to you at the time we collect or use your Personal Information.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          10. Marketing
        </Typography>
        <div className={classes.textContainer}>
          <Typography>10.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You consent to us using your Personal Information for sending you
            information, including promotional material, about us or our products and
            services, opportunities available to you, as well as the products and
            services of our related entities and third parties, now and in the future.
            You also consent to us sending you such information by means of direct mail,
            email, SMS and MMS messages, and contacting you via telephone call.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>10.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If you do not want to receive marketing information from us, you can
            unsubscribe in any of the following ways:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp;clicking on the 'Unsubscribe' or subscription preferences
              link in a direct marketing email that you have received from us, or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;contacting us using the contact details specified in
              paragraph 15.
            </span>
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          11. Storage and security of Personal Information held by us
        </Typography>
        <div className={classes.textContainer}>
          <Typography>11.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We aim to keep your Personal Information secure. Any Personal Information
            that is collected via our Website or App or which is held on our computer
            systems is protected by safeguards including physical, technical (firewalls,
            SSL encryption etc) and procedural methods.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>11.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The transmission and exchange of information is carried out at your own
            risk. We cannot guarantee the security of any information that you transmit
            to us, or receive from us . Although we take measures to safeguard against
            unauthorised disclosures of information, we cannot assure you that Personal
            Information that we collect will not be accessed or disclosed in a manner
            that is inconsistent with this Privacy Policy.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>11.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If we find that we no longer require or have no further need for your
            Personal Information we may de-identify it or remove it from our systems and
            destroy all record of it.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          12. You can access and update your Personal Information
        </Typography>
        <div className={classes.textContainer}>
          <Typography>12.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You are generally entitled to access Personal Information that we hold about
            you. If you request access to your Personal Information, in ordinary
            circumstances we will give you full access to your Personal Information.
            Depending on the nature of the request, PropertyMate Group may charge for
            providing access to this information, however such charge will not be
            excessive. However, there may be some legal or administrative reasons to
            deny access. If we refuse your request to access your Personal Information,
            we will provide you with reasons for the refusal where we are required by
            law to give those reasons.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>12.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            A request for access can be made by contacting our Privacy Officer in any of
            the ways specified in paragraph 15.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>12.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Please contact us in any of the ways specified in paragraph 15 if you
            believe that the Personal Information is inaccurate, incomplete or out of
            date, and we will use all reasonable efforts to correct the information.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>12.4</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We take all reasonable steps to ensure that any Personal Information we
            collect and use is accurate, complete and up-to-date. To assist us in this,
            you need to provide true, accurate, current and complete information about
            yourself as requested, and properly update the information provided to us to
            keep it true, accurate, current and complete.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>12.5</Typography>
          <Typography variant="body1" className={classes.textContent}>
            It would assist us to ensure we properly understand your request, and allow
            us to respond more promptly, if requests are made in writing and include as
            much detail as possible.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          13. How do we deal with complaints about privacy?
        </Typography>
        <div className={classes.textContainer}>
          <Typography>13.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If you feel that we have not respected your privacy or that we have
            conducted ourselves inconsistently with this Privacy Policy, please contact
            our Privacy Officer in any of the ways specified in paragraph 15 and advise
            us as soon as possible. We will investigate your queries and privacy
            complaints within a reasonable period of time depending on the complexity of
            the complaint.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>13.2</Typography>
          <Typography variant="body1" className={classes.textContent}>
            It would assist us to respond to your complaint promptly if it is made in
            writing. Please detail information relevant to your complaint.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>13.3</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We will notify you of the outcome of our investigation.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          14. Updates to this Privacy Policy
        </Typography>
        <div className={classes.textContainer}>
          <Typography>14.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            We may, from time to time, review and update this Privacy Policy, including
            to take into account new laws, regulations, practices and technology. All
            Personal Information held by us will be governed by our most recent Privacy
            Policy. Any changes to this Privacy Policy may be advised to you by posting
            the revised Privacy Policy on our Website, App (for users of the App) or
            notice board. We encourage you to check this page from time to time for any
            changes.
          </Typography>
        </div>
        <Typography variant="h6" className={classes.subHeading}>
          15. What to do if you have a question, problem or complaint, or want to
          contact us about our use of your Personal Information or this Privacy Policy
        </Typography>
        <div className={classes.textContainer}>
          <Typography>15.1</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If you:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (a) &nbsp;&nbsp;have a query or concern about this Privacy Policy or our
              Personal Information handling processes
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (b) &nbsp;&nbsp;wish to make a complaint in relation to a breach of your
              privacy
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (c) &nbsp;&nbsp;would like to access your Personal Information held by us
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (d) &nbsp;&nbsp;would like to update or correct your Personal Information
              held by us, or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (e) &nbsp;&nbsp;would like to opt out of direct marketing,
            </span>
            please contact our Privacy Officer in any of the following ways:
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (») &nbsp;&nbsp; Email Address: &nbsp;&nbsp;
              <a
                href="privacy@propertymate.com.au"
                //target="_blank"
                style={{ textDecoration: 'none', color: '#000000' }}
              >
                privacy@propertymate.com.au
              </a>
              , or
            </span>
            <span
              className={classes.textContainer}
              style={{ marginTop: 8, marginBottom: 8 }}
            >
              (») &nbsp;&nbsp; Mailing Address: &nbsp;&nbsp;45 Whitehorse Rd, Balwyn,
              Victoria, 3103
            </span>
          </Typography>
        </div>
      </Container>
    </div>
  )
}

export default withStyles(styles)(PrivacyPolicyMobileApp)
