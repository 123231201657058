import {
  ADD_LIKED_DRILL_DOWN_PROPERTIES,
  ADD_LIKED_PROPERTIES,
  ADD_UNLIKED_DRILL_DOWN_PROPERTIES,
  ADD_UNLIKED_PROPERTIES,
  CLEAR_CURRENT_SELECTED_PROPERTY,
  CLEAR_FAVORITE_STATUS,
  CLEAR_INQUIRY_DATA,
  CLEAR_LIKED_AND_UNLIKED_PROPERTIES,
  CLEAR_PERFECT_PROPERTIES,
  CLEAR_PROPERTY_PAYLOAD,
  REQUEST_DELETE_SAVED_SEARCH,
  REQUEST_DELETE_SAVED_SEARCH_FAIL,
  REQUEST_DELETE_SAVED_SEARCH_SUCCESS,
  REQUEST_PUT_SAVED_SEARCH,
  REQUEST_PUT_SAVED_SEARCH_FAIL,
  REQUEST_PUT_SAVED_SEARCH_SUCCESS,
  REQUEST_DISLIKE_PROPERTIES,
  REQUEST_DISLIKE_PROPERTIES_FAIL,
  REQUEST_DISLIKE_PROPERTIES_SUCCESS,
  REQUEST_DREAM_PROPERTIES,
  REQUEST_DREAM_PROPERTIES_FAIL,
  REQUEST_DREAM_PROPERTIES_SUCCESS,
  REQUEST_FAVORITE_PROPERTIES,
  REQUEST_FAVORITE_PROPERTIES_FAIL,
  REQUEST_FAVORITE_PROPERTIES_SUCCESS,
  REQUEST_GET_SAVE_PROPERTY,
  REQUEST_GET_SAVE_PROPERTY_FAIL,
  REQUEST_GET_SAVE_PROPERTY_SUCCESS,
  REQUEST_LIKE_PROPERTIES,
  REQUEST_LIKE_PROPERTIES_FAIL,
  REQUEST_LIKE_PROPERTIES_SUCCESS,
  CLEAR_LIKE_PROPERTIES,
  REQUEST_PROPERTY_DETAILS,
  REQUEST_PROPERTY_DETAILS_FAIL,
  REQUEST_PROPERTY_DETAILS_SUCCESS,
  REQUEST_PROPERTY_SEARCH,
  REQUEST_PROPERTY_SEARCH_FAIL,
  REQUEST_PROPERTY_SEARCH_RENTAL,
  REQUEST_PROPERTY_SEARCH_RENTAL_FAIL,
  REQUEST_PROPERTY_SEARCH_RENTAL_SUCCESS,
  REQUEST_PROPERTY_SEARCH_SOLD,
  REQUEST_PROPERTY_SEARCH_SOLD_FAIL,
  REQUEST_PROPERTY_SEARCH_SOLD_SUCCESS,
  REQUEST_PROPERTY_SEARCH_SUCCESS,
  REQUEST_PROPERTY_SUGGESTIONS,
  REQUEST_PROPERTY_SUGGESTIONS_FAIL,
  REQUEST_PROPERTY_SUGGESTIONS_SUCCESS,
  CLEAR_PROPERTY_SUGGESTIONS,
  REQUEST_SAVED_SEARCH_DATA,
  REQUEST_SAVED_SEARCH_DATA_FAIL,
  REQUEST_SAVED_SEARCH_DATA_SUCCESS,
  REQUEST_SAVE_PROPERTY,
  REQUEST_SAVE_PROPERTY_FAIL,
  REQUEST_SAVE_PROPERTY_SUCCESS,
  REQUEST_SAVE_SEARCH,
  REQUEST_SAVE_SEARCH_FAIL,
  REQUEST_SAVE_SEARCH_SUCCESS,
  REQUEST_SEARCH_PERFECT_PROPERTY,
  REQUEST_SEARCH_PERFECT_PROPERTY_FAIL,
  REQUEST_SEARCH_PERFECT_PROPERTY_SUCCESS,
  SEND_AGENT_CONTACT_INQUIRY,
  SEND_AGENT_CONTACT_INQUIRY_FAIL,
  SEND_AGENT_CONTACT_INQUIRY_SUCCESS,
  CLEAR_AGENT_CONTACT_INQUIRY,
  SEND_LIKES_DISLIKES_PROPERTIES,
  SEND_LIKES_DISLIKES_PROPERTIES_FAIL,
  SEND_LIKES_DISLIKES_PROPERTIES_SUCCESS,
  SEND_PERFECT_PROPERTY_FILTERS,
  SEND_PERFECT_PROPERTY_FILTERS_FAIL,
  SEND_PERFECT_PROPERTY_FILTERS_SUCCESS,
  SEND_SEARCH_TALLIES,
  SEND_SEARCH_TALLIES_FAIL,
  SEND_SEARCH_TALLIES_SUCCESS,
  SET_CURRENT_SELECTED_PROPERTY,
  SET_PERFECT_PROPERTIES,
  REQUEST_VENDOR_OTP,
  REQUEST_VENDOR_OTP_SUCCESS,
  REQUEST_VENDOR_OTP_FAIL,
  PROPERTY_DETAILS_CLEAR,
  REQUEST_SAVED_SEARCH_CLEAR,
} from '../constants'

/** CLEAR PAYLOAD */
export function clearPropertyPayload() {
  return {
    type: CLEAR_PROPERTY_PAYLOAD,
  }
}
/** CLEAR PAYLOAD */
export function clearFavoriteStatus() {
  return {
    type: CLEAR_FAVORITE_STATUS,
  }
}

/** PROPERTY SAVE ACTIONS */
export function propertySaveRequest(params) {
  return {
    type: REQUEST_SAVE_PROPERTY,
    params,
  }
}

export function propertySaveSuccess(payload) {
  return {
    type: REQUEST_SAVE_PROPERTY_SUCCESS,
    payload,
  }
}

export function propertySaveError(error) {
  return {
    type: REQUEST_SAVE_PROPERTY_FAIL,
    error,
  }
}

/** PROPERTY GET SAVE ACTIONS */
export function propertyGetSaveRequest(params) {
  return {
    type: REQUEST_GET_SAVE_PROPERTY,
    params,
  }
}

export function propertyGetSaveSuccess(payload) {
  return {
    type: REQUEST_GET_SAVE_PROPERTY_SUCCESS,
    payload,
  }
}

export function propertyGetSaveError(error) {
  return {
    type: REQUEST_GET_SAVE_PROPERTY_FAIL,
    error,
  }
}

/** PROPERTY SEARCH ACTIONS */
export function propertySearch(params) {
  return {
    type: REQUEST_PROPERTY_SEARCH,
    params,
  }
}

export function propertySearchSuccess(payload) {
  return {
    type: REQUEST_PROPERTY_SEARCH_SUCCESS,
    payload,
  }
}

export function propertySearchError(error) {
  return {
    type: REQUEST_PROPERTY_SEARCH_FAIL,
    error,
  }
}

/** PROPERTY DETAILS ACTIONS */
export function propertyDetailsRequest(params) {
  return {
    type: REQUEST_PROPERTY_DETAILS,
    params,
  }
}

export function propertyDetailsRequestSuccess(payload) {
  return {
    type: REQUEST_PROPERTY_DETAILS_SUCCESS,
    payload,
  }
}

export function propertyDetailsRequestError(error) {
  return {
    type: REQUEST_PROPERTY_DETAILS_FAIL,
    error,
  }
}

export function propertyDetailsClear() {
  return {
    type: PROPERTY_DETAILS_CLEAR,
  }
}

/** EDIT PROPERTY DETAILS ACTIONS */
export function propertyEditRequest(params) {
  return {
    type: REQUEST_PROPERTY_DETAILS,
    params,
  }
}

export function propertyEditSuccess(payload) {
  return {
    type: REQUEST_PROPERTY_DETAILS_SUCCESS,
    payload,
  }
}

export function propertyEditError(error) {
  return {
    type: REQUEST_PROPERTY_DETAILS_FAIL,
    error,
  }
}

/** SET PERFECT PROPERTY ACTIONS */
export function setPerfectproperty(params) {
  return {
    type: SET_PERFECT_PROPERTIES,
    params,
  }
}

/** CLEAR PERFECT PROPERTY ACTIONS */
export function clearPerfectProperty() {
  return {
    type: CLEAR_PERFECT_PROPERTIES,
  }
}

/** SET CURRENT PROPERTY ACTIONS */
export function setCurrentProperty(params) {
  return {
    type: SET_CURRENT_SELECTED_PROPERTY,
    params,
  }
}

/** CLEAR CURRENT PROPERTY ACTIONS */
export function clearCurrentProperty() {
  return {
    type: CLEAR_CURRENT_SELECTED_PROPERTY,
  }
}

/** ClEAR LIKED and UNLIKED PROPERTIES */
export function clearLikedUnlikedProperties() {
  return {
    type: CLEAR_LIKED_AND_UNLIKED_PROPERTIES,
  }
}

/** ADD LIKED PROPERTIES */
export function addLikedProperty(params) {
  return {
    type: ADD_LIKED_PROPERTIES,
    params,
  }
}

/** ADD UNLIKED PROPERTIES */
export function addUnlikedProperty(params) {
  return {
    type: ADD_UNLIKED_PROPERTIES,
    params,
  }
}

/** ADD LIKED DRILLDOWN PROPERTIES */
export function addLikedDrillDownProperty(params) {
  return {
    type: ADD_LIKED_DRILL_DOWN_PROPERTIES,
    params,
  }
}

/** ADD UNLIKED DRILLDOWN PROPERTIES */
export function addUnlikedDrillDownProperty(params) {
  return {
    type: ADD_UNLIKED_DRILL_DOWN_PROPERTIES,
    params,
  }
}

/** SEND CONTACT AGENT */
export function sendAgentContactRequest(params) {
  return {
    type: SEND_AGENT_CONTACT_INQUIRY,
    params,
  }
}

export function sendAgentContactSuccess(payload) {
  return {
    type: SEND_AGENT_CONTACT_INQUIRY_SUCCESS,
    payload,
  }
}

export function sendAgentContactError(error) {
  return {
    type: SEND_AGENT_CONTACT_INQUIRY_FAIL,
    error,
  }
}

export function clearAgentContactInquiry(error) {
  return {
    type: CLEAR_AGENT_CONTACT_INQUIRY,
    error,
  }
}

export function clearInquiryData() {
  return {
    type: CLEAR_INQUIRY_DATA,
  }
}

/** REQUEST LIKED PROPERTIES */
export function requestLikedPropertiesRequest(params) {
  return {
    type: REQUEST_LIKE_PROPERTIES,
    params,
  }
}

export function requestLikedPropertiesSuccess(payload) {
  return {
    type: REQUEST_LIKE_PROPERTIES_SUCCESS,
    payload,
  }
}

export function requestLikedPropertiesError(error) {
  return {
    type: REQUEST_LIKE_PROPERTIES_FAIL,
    error,
  }
}

export function clearLikedProperties(error) {
  return {
    type: CLEAR_LIKE_PROPERTIES,
    error,
  }
}

/** REQUEST DISLIKED PROPERTIES */
export function requestDislikedPropertiesRequest(params) {
  return {
    type: REQUEST_DISLIKE_PROPERTIES,
    params,
  }
}

export function requestDislikedPropertiesSuccess(payload) {
  return {
    type: REQUEST_DISLIKE_PROPERTIES_SUCCESS,
    payload,
  }
}

export function requestDislikedPropertiesError(error) {
  return {
    type: REQUEST_DISLIKE_PROPERTIES_FAIL,
    error,
  }
}

/** SEND LIKES DISLIKES PROPERTIES */
export function sendLikesDislikesPropertiesRequest(params) {
  return {
    type: SEND_LIKES_DISLIKES_PROPERTIES,
    params,
  }
}

export function sendLikesDislikesPropertiesSuccess(payload) {
  return {
    type: SEND_LIKES_DISLIKES_PROPERTIES_SUCCESS,
    payload,
  }
}

export function sendLikesDislikesPropertiesError(error) {
  return {
    type: SEND_LIKES_DISLIKES_PROPERTIES_FAIL,
    error,
  }
}

/** SEND SEARCH TALLIES */
export function sendSearchTalliesRequest(params) {
  return {
    type: SEND_SEARCH_TALLIES,
    params,
  }
}

export function sendSearchTalliesSuccess(payload) {
  return {
    type: SEND_SEARCH_TALLIES_SUCCESS,
    payload,
  }
}

export function sendSearchTalliesError(error) {
  return {
    type: SEND_SEARCH_TALLIES_FAIL,
    error,
  }
}

/** PROPERTY SEARCH PERFECT PROPERTY ACTIONS */
export function propertySearchPerfectProperty(params) {
  return {
    type: REQUEST_SEARCH_PERFECT_PROPERTY,
    params,
  }
}

export function propertySearchPerfectPropertySuccess(payload) {
  return {
    type: REQUEST_SEARCH_PERFECT_PROPERTY_SUCCESS,
    payload,
  }
}

export function propertySearchPerfectPropertyError(error) {
  return {
    type: REQUEST_SEARCH_PERFECT_PROPERTY_FAIL,
    error,
  }
}

/** SEND PERFECT PROPERTY FILTERS */
export function sendPerfectPropertiesFilters(params) {
  return {
    type: SEND_PERFECT_PROPERTY_FILTERS,
    params,
  }
}

export function sendPerfectPropertiesFiltersSuccess(payload) {
  return {
    type: SEND_PERFECT_PROPERTY_FILTERS_SUCCESS,
    payload,
  }
}

export function sendPerfectPropertiesFiltersError(error) {
  return {
    type: SEND_PERFECT_PROPERTY_FILTERS_FAIL,
    error,
  }
}

/** REQUEST PROPERTY SUGGESSTIONS */
export function requestPropertySuggestions(params) {
  return {
    type: REQUEST_PROPERTY_SUGGESTIONS,
    params,
  }
}

export function requestPropertySuggestionsSuccess(payload) {
  return {
    type: REQUEST_PROPERTY_SUGGESTIONS_SUCCESS,
    payload,
  }
}

export function clearPropertySuggestions(payload) {
  return {
    type: CLEAR_PROPERTY_SUGGESTIONS,
    payload,
  }
}

export function requestPropertySuggestionsError(error) {
  return {
    type: REQUEST_PROPERTY_SUGGESTIONS_FAIL,
    error,
  }
}

/** REQUEST PROPERTY SEARCH RENTAL */
export function propertySearchRental(params) {
  return {
    type: REQUEST_PROPERTY_SEARCH_RENTAL,
    params,
  }
}

export function propertySearchRentalSuccess(payload) {
  return {
    type: REQUEST_PROPERTY_SEARCH_RENTAL_SUCCESS,
    payload,
  }
}

export function propertySearchRentalError(error) {
  return {
    type: REQUEST_PROPERTY_SEARCH_RENTAL_FAIL,
    error,
  }
}

/** REQUEST PROPERTY SEARCH SOLD */
export function propertySearchSold(params) {
  return {
    type: REQUEST_PROPERTY_SEARCH_SOLD,
    params,
  }
}

export function propertySearchSoldSuccess(payload) {
  return {
    type: REQUEST_PROPERTY_SEARCH_SOLD_SUCCESS,
    payload,
  }
}

export function propertySearchSoldError(error) {
  return {
    type: REQUEST_PROPERTY_SEARCH_SOLD_FAIL,
    error,
  }
}

/** PROPERTY SAVE SEARCH ACTIONS */
export function propertySaveSearch(params) {
  return {
    type: REQUEST_SAVE_SEARCH,
    params,
  }
}

export function propertySaveSearchSuccess(payload) {
  return {
    type: REQUEST_SAVE_SEARCH_SUCCESS,
    payload,
  }
}

export function propertySaveSearchError(error) {
  return {
    type: REQUEST_SAVE_SEARCH_FAIL,
    error,
  }
}

/** GET PROPERTY SAVE SEARCH ACTIONS */
export function getPropertySaveSearch(params) {
  return {
    type: REQUEST_SAVED_SEARCH_DATA,
    params,
  }
}

export function getPropertySaveSearchSuccess(payload) {
  return {
    type: REQUEST_SAVED_SEARCH_DATA_SUCCESS,
    payload,
  }
}

export function getPropertySaveSearchError(error) {
  return {
    type: REQUEST_SAVED_SEARCH_DATA_FAIL,
    error,
  }
}

/** DELETE PROPERTY SAVE SEARCH ACTIONS */
export function deletePropertySaveSearch(params) {
  return {
    type: REQUEST_DELETE_SAVED_SEARCH,
    params,
  }
}

export function deletePropertySaveSearchSuccess(payload) {
  return {
    type: REQUEST_DELETE_SAVED_SEARCH_SUCCESS,
    payload,
  }
}

export function deletePropertySaveSearchError(error) {
  return {
    type: REQUEST_DELETE_SAVED_SEARCH_FAIL,
    error,
  }
}

/** PUT PROPERTY SAVE SEARCH ACTIONS */
export function putPropertySaveSearch(params) {
  return {
    type: REQUEST_PUT_SAVED_SEARCH,
    params,
  }
}

export function putPropertySaveSearchSuccess(payload) {
  return {
    type: REQUEST_PUT_SAVED_SEARCH_SUCCESS,
    payload,
  }
}

export function putPropertySaveSearchError(error) {
  return {
    type: REQUEST_PUT_SAVED_SEARCH_FAIL,
    error,
  }
}

/** GET FAVORITE PROPERTIES ACTIONS */
export function requestFavoritePropertiesRequest(params) {
  return {
    type: REQUEST_FAVORITE_PROPERTIES,
    params,
  }
}

export function requestFavoritePropertiesSuccess(payload) {
  return {
    type: REQUEST_FAVORITE_PROPERTIES_SUCCESS,
    payload,
  }
}

export function requestFavoritePropertiesError(error) {
  return {
    type: REQUEST_FAVORITE_PROPERTIES_FAIL,
    error,
  }
}

/** REQUEST PROPERTY SUGGESSTIONS */
export function requestDreamProperties(params) {
  return {
    type: REQUEST_DREAM_PROPERTIES,
    params,
  }
}

export function requestDreamPropertiesSuccess(payload) {
  return {
    type: REQUEST_DREAM_PROPERTIES_SUCCESS,
    payload,
  }
}

export function requestDreamPropertiesError(error) {
  return {
    type: REQUEST_DREAM_PROPERTIES_FAIL,
    error,
  }
}

/** VENDOR OTP */
export function requestVendorOTP(params) {
  return {
    type: REQUEST_VENDOR_OTP,
    params,
  }
}

export function requestVendorOTPSuccess(payload) {
  return {
    type: REQUEST_VENDOR_OTP_SUCCESS,
    payload,
  }
}

export function requestVendorOTPFail(error) {
  return {
    type: REQUEST_VENDOR_OTP_FAIL,
    error,
  }
}

export function requestSavedSearchClear() {
  return {
    type: REQUEST_SAVED_SEARCH_CLEAR,
  }
}
