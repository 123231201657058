import React, { useRef, useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Searchbar from '../../components/Mobile/Searchbar'
import PropertySearchFilter from '../../components/Mobile/Searchbar/PropertySearchFilter'
import PropertyFaveLikes from '../../components/Mobile/Property/PropertyFaveLikes'
import PropertyLoader from '../../components/Mobile/Property/PropertyLoader'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'

import { scrollToRef } from '../../components/Mobile/ScrollEvents'
import DynamicIcons from '../../components/Mobile/DynamicIcons'

import _ from 'lodash'

/** api redux request */
import {
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
  requestLikeProperties,
  requestGetPropertySave,
} from '../../store/api'

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters,
  clearLikedProperties,
  clearSortingFilters,
} from '../../store/actions'

import { Button, withStyles } from '@material-ui/core'
import classNames from 'classnames'

import { useDispatch, useSelector } from 'react-redux'

import { getDislikes, getStatus, getErrors } from 'store/selectors/mpp.selectors'
import { requestGETAPIMpp, requestPUTAPIMpp } from 'store/api/mpp.api'
import { mppActions } from 'store/actions'
import { useHistory } from 'react-router-dom'
import { useRouterChange } from 'utils/router.utils'

const BackButton = withStyles({
  root: {
    //width: '100%',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const styles = (theme) => ({
  root: {
    //height: window.innerHeight,
    backgroundColor: '#f5f5f5',
  },
  content: {
    //height: '100%',
    backgroundColor: '#f5f5f5',
  },
  backButtonContainer: {
    paddingTop: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  mainContainer: {
    //height: '100%',
    zIndex: 6,
    // backgroundColor: '#FFF',
    position: 'relative',
    borderRadius: 4,
  },
  dislikedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Tab: {
    width: '100%',
    border: '2px solid transparent',
    borderColor: '#ddd #ddd #f5f5f5',
    textAlign: 'center',
    borderTopLeftRadius: '1.25rem',
    borderTopRightRadius: '1.25rem',
    padding: '15px',
    '&:hover': {
      backgroundColor: '#e2e2e2',
    },
  },
  activeButton: {
    background: '#57bdc7',
  },
  'MuiButton-root:hover': {
    backgroundColor: 'none !important',
  },
})

const DislikedProperties = (props) => {
  const {
    classes,
    authentication,
    settings,
    property,
    filters,
    toggleSignUp,
    toggleFilter,
    savePropertySearch,
    searchProperty,
    setCurrentProperty,
    clearCurrentProperty,
    setSearchFilters,
    setSearchSortingFilters,
    property_save_status,
    property_save_error,
    clearFilters,
  } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()
  const DISLIKES = useSelector(getDislikes)
  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)

  const topContainer = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d) //add `false` as second parameter to scroll without transition.

  const [isFiltered, setIsFiltered] = useState({ isActive: false, suburbs: null })
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [dislikedPropertiesData, setDislikedPropertiesData] = useState([])
  const [isFlag, setIsFlag] = useState(false)

  const [activeSortFilter, setActiveSortFilter] = useState()

  const history = useHistory()

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  const [pagination, setPagination] = useState({
    propertiesFilters: [],
    totalResults: null,
  })

  useEffect(() => {
    if (authentication && authentication.token === null) {
      routerChange('/')
    }
  }, [authentication])

  useEffect(() => {
    if (activeSortFilter) {
      setDislikedPropertiesData([])
      apiRequestDislikedProperties('0')
    }
  }, [activeSortFilter])

  useEffect(() => {
    if (filters.sorting) {
      let activeSort = filters.sorting.filter((sort) => sort.active === true)[0]

      if (activeSort) {
        let activeSortValue = activeSort.value.toLowerCase()

        if (activeSortValue === 'price (high to low)') activeSortValue = 'priceHigh'
        if (activeSortValue === 'price (low to high)') activeSortValue = 'priceLow'

        setActiveSortFilter(activeSortValue)
      }
    }
  }, [filters])

  useEffect(() => {
    /**
     * Initial Scroll to Top
     * */
    clearFilters()
    handleClearMPPDislikes()
    handleClearMPPDislikes()
    executeTopScroll(false)
    localStorage.removeItem('hideState')
    if (authentication && authentication.user_status === 'logged') {
      setIsLoading(true)
      apiRequestFavouritesLists()
      apiRequestDislikedProperties()
    } else {
      toggleSignUp(true)
    }
  }, [])

  /* 
  handle dislikes callback 
  */
  useEffect(() => {
    /**
     * property data setter */
    if (DISLIKES) {
      setIsLoading(false)
      if (DISLIKES) {
        handleClearMPPDislikes()
        defaultSortData(DISLIKES)
      }
    }
  }, [DISLIKES])

  useEffect(() => {
    if (STATUS && STATUS === 'mpp/GET_MPP_DISLIKES_REQUEST') {
      setIsLoading(true)
    }
    if (STATUS && STATUS === 'mpp/GET_MPP_DISLIKES_SUCCESS') {
      setIsLoading(false)
    }

    if (STATUS && STATUS === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      setIsLoading(false)
      apiRequestFavouritesLists()
    }
    if (STATUS && STATUS === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      setIsLoading(false)
      apiRequestFavouritesLists()
    }
    if (STATUS && STATUS === 'mpp/GET_MPP_FAVOURITES_SUCCESS') {
      setIsLoading(false)
    }
    if (STATUS && STATUS === 'mpp/GET_MPP_DISLIKES_SUCCESS') {
      setIsLoading(false)
    }
    if (STATUS && STATUS === 'mpp/GET_MPP_DISLIKES_FAILED') {
      setIsLoading(false)
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
    }
    if (STATUS && STATUS === 'mpp/PUT_MPP_DISLIKES_FAILED') {
      setIsLoading(false)
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
    }
  }, [ERROR, STATUS])

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const handleSortBy = (data, propertiesData) => {
    setSearchSortingFilters(data)
    const propertyData = Object.assign({}, dislikedPropertiesData.propertiesFilters)
    const propertiesFilters = Object.assign([], propertyData)
    // let type = _.filter(data, function (o) {
    //   return o.active
    // })
    // let sortedData = propertiesFilters
    // if (data && type && type.length > 0) {
    //   switch (type[0].value) {
    //     case 'Relevance':
    //       sortedData = propertiesFilters
    //       break
    //     case 'Newest':
    //       sortedData =
    //         getRoutePath() === 'sold'
    //           ? sortData(propertiesFilters, ['soldDate'], 'desc')
    //           : sortData(propertiesFilters, ['publishDate'], 'desc')
    //       break
    //     case 'Price (High to Low)':
    //       sortedData =
    //         getRoutePath() === 'rent'
    //           ? sortData(
    //               propertiesFilters,
    //               ['rentalPrice', 'publishDate'],
    //               ['desc', 'desc']
    //             )
    //           : sortData(propertiesFilters, ['price', 'publishDate'], ['desc', 'desc'])
    //       break
    //     case 'Price (Low to High)':
    //       sortedData =
    //         getRoutePath() === 'rent'
    //           ? sortData(
    //               propertiesFilters,
    //               ['rentalPrice', 'publishDate'],
    //               ['asc', 'asc']
    //             )
    //           : sortData(propertiesFilters, ['price', 'publishDate'], ['asc', 'asc'])
    //       break
    //     case 'Bedrooms':
    //       sortedData = sortData(
    //         propertiesFilters,
    //         ['bedrooms', 'price'],
    //         ['desc', 'desc']
    //       )
    //       break
    //     case 'Bathrooms':
    //       sortedData = sortData(
    //         propertiesFilters,
    //         ['bathrooms', 'price'],
    //         ['desc', 'desc']
    //       )
    //       break
    //     case 'Sqm':
    //       sortedData = sortData(
    //         propertiesFilters,
    //         ['landSize', 'price'],
    //         ['desc', 'desc']
    //       )
    //       break
    //     default:
    //       sortedData = propertiesFilters
    //   }
    //   propertyData.propertiesFilters = sortedData
    //   setDislikedPropertiesData(propertyData)
    //   setPagination(propertyData)
    // }
    propertyData.propertiesFilters = propertiesFilters
    setDislikedPropertiesData(propertyData)
    setPagination(propertyData)
  }

  const defaultSortData = (properties) => {
    const propertyData = Object.assign({}, properties.data)
    const propertiesFilters = Object.assign([], propertyData)
    // const sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['asc'])

    propertyData.propertiesFilters = propertiesFilters

    const pageData = {
      propertiesFilters: _.union(
        dislikedPropertiesData.propertiesFilters,
        propertiesFilters
      ),
      totalResults: properties.totalResults,
    }
    setDislikedPropertiesData(pageData)
    setPagination(pageData)
  }

  const sortData = (data, type, order) => {
    const sortBy = type
    return _.orderBy(
      data,
      sortBy.map((s) => {
        return (r) => {
          return r[s] ? r[s] : ''
        }
      }),
      order
    )
  }

  /** Save Property API MPP*/
  const apiRequestSaveProperty = (id) => {
    let request = {
      query: null,
      data: {
        propertyId: id,
      },
    }
    dispatch(requestPUTAPIMpp(request, 'PUT_MPP_FAVOURITES', 'REQUEST'))
  }

  const apiPutRemoveDislikedProperty = (id) => {
    let request = {
      query: null,
      data: {
        propertyPreference: {
          dislikes: [id],
          action: 'remove',
        },
      },
    }
    dispatch(requestPUTAPIMpp(request, 'PUT_MPP_LIKES_DISLIKES', 'REQUEST'))
  }

  /** Get Disiked Properties */
  function apiRequestDislikedProperties(skipCount) {
    handleClearMPPDislikes()

    let locaLSkipCount = skipCount
      ? skipCount
      : dislikedPropertiesData.propertiesFilters
        ? dislikedPropertiesData.propertiesFilters.length
        : 0

    if (locaLSkipCount !== undefined) {
      let request = {
        query: `?propertyPreference=dislikes&take=9&skip=${locaLSkipCount}&sortBy=${activeSortFilter ? activeSortFilter : 'default'
          }`,
        data: {},
      }

      //setIsLoading(true)
      dispatch(requestGETAPIMpp(request, 'GET_MPP_DISLIKES'))
    }
  }

  const handleClearMPPDislikes = () => {
    dispatch(mppActions(null, 'GET_MPP_DISLIKES', 'CLEAR'))
  }

  /** Get List of Favourite and Dislike Properties */
  function apiRequestFavouritesLists() {
    handleClearMPPFavouritesList()
    //setIsLoading(true)
    dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'))
  }

  const handleClearMPPFavouritesList = () => {
    dispatch(mppActions(null, 'GET_MPP_FAVOURITES_IDS', 'CLEAR'))
  }

  function saveFilters(params) {
    if (filters && filters.filters) {
      let obj = Object.assign({}, filters.filters)
      if (params) {
        obj.propertyDetails.bedrooms = params[0].selected
        obj.propertyDetails.bathrooms = params[2].selected
        obj.propertyDetails.price.min = params[1].values[0].value.min
        obj.propertyDetails.price.max = params[1].values[0].value.max
        if (params[3].values) {
          obj.propertyType = params[3].values
          obj.propertyType.map((item, index) => {
            item.active = params[3].values[index].active
          })
        }
        setSearchFilters(obj)
      }
    }
  }

  const handlePagination = () => {
    apiRequestDislikedProperties()
  }

  const handleMatchCount = (property) => {
    let count = 0
    if (filters && property) {
      filters.filters.advanceProperty.map((item, index) => {
        if (item.active && property.coefficients) {
          if (property.coefficients[item.key] >= 0.7) {
            count++
          }
        }
      })
    }
    return count
  }

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <div ref={topContainer} className={classes.topAnchor}></div>
      <div className={classes.mainContainer}>
        <div className={classes.dislikedContainer}>
          <div
            className={classes.Tab}
            onClick={() => routerChange('/favourites')}
            style={{ cursor: 'pointer' }}
          >
            <div> Favourites</div>
          </div>
          <div className={classNames(classes.Tab, classes.activeButton)}>
            <div>Review Disliked</div>
          </div>
        </div>
      </div>

      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routerChange('back')}
        >
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
        <div>
          <Searchbar
            type={'search'}
            sortType={'disliked-property'}
            showSearchBar={false}
            showFilters={false}

            authentication={authentication}
            settings={settings}
            toggleSignUp={toggleSignUp}
            toggleFilter={toggleFilter}
            setSearchSortingFilters={handleSortBy}
            filters={filters}
            saveFilters={saveFilters}
            properties={dislikedPropertiesData}
            isFiltered={isFiltered}
            path={getRoutePath()}
          />
        </div>
      </div>

      <div className={classes.content}>
        {/* <div style={{ paddingBottom: 20 }}> */}
        <PropertyFaveLikes

          authentication={authentication}
          settings={settings}
          toggleSignUp={toggleSignUp}
          apiRequestSaveProperty={apiRequestSaveProperty}
          apiPutRemoveDislikedProperty={apiPutRemoveDislikedProperty}
          setCurrentProperty={setCurrentProperty}
          properties={pagination}
          isFiltered={isFiltered}
          isLoading={isLoading}
          pagination={pagination}
          handlePagination={handlePagination}
          handleMatchCount={handleMatchCount}
          listingType={getRoutePath()}
          cardType={'grid'}
        />
        {/* <Property
          
          authentication={authentication}
          settings={settings}
          toggleSignUp={toggleSignUp}
          apiRequestSaveProperty={apiRequestSaveProperty}
          setCurrentProperty={setCurrentProperty}
          properties={pagination} 
          isFiltered={isFiltered}
          isLoading={isLoading}
          handlePagination={handlePagination} 
          handleMatchCount={handleMatchCount}
          listingType={getRoutePath()} /> */}
      </div>
      <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
      {/* </div> */}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
    property_save_status: state.property.property_save_property_status,
    property_save_error: state.property.property_save_property_error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchProperty: (params) => requestPropertySearch(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setSearchSortingFilters: (params) => setSortingFilters(params),
      clearFilters: (params) => clearSortingFilters(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DislikedProperties))
