import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import PropertyCard from './PropertyCard'
import NoResultsCard from './NoResultsCard'
import { PROPERTY_CARDS } from './constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { css } from 'styled-components'
import _ from 'lodash'

/** Redux **/
import { toggleFilterWeb } from '../../../store/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'

import { withStyles, CircularProgress, Grid } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  loader: { padding: '5px 23px 20px', textAlign: 'center', marginTop: 20 },
})

const PropertyFaveLikes = (props) => {
  const {
    classes,
    authentication,
    settings,
    toggleSignUp,
    setCurrentProperty,
    properties,
    apiRequestSaveProperty,
    apiPutRemoveDislikedProperty,
    isLoading,
    isFiltered,
    listingType,
    pagination,
    origin,
    setActivePreviewProperty,
    activePreviewProperty,
    handlePagination,
    state,
  } = props

  const [items, setItems] = useState([]) // PROPERTY_CARDS
  const [hasMore, setHasMore] = useState(true)

  // const [favoriteProperties, setFavoriteProperties] = useState()

  // useEffect(() => {
  //   if (state.property.favorite_properties.data) {
  //     if (state.property.favorite_properties.data.data.length !== 0)
  //       setFavoriteProperties([...state.property.favorite_properties.data.data])
  //   }
  // }, [state.property.favorite_properties.data])

  // const favoritePropertiesHandler = (propertyId) => {
  //   const updatedFavoriteProperties = favoriteProperties.filter((property) => {
  //     return property.property !== propertyId
  //   })

  //   setFavoriteProperties(updatedFavoriteProperties)
  // }

  useEffect(() => {
    /**
     * Property Data
     * */
    setItems(_.uniqBy(properties.propertiesFilters, 'property'))
    if (properties.propertiesFilters) {
      let totalresults = pagination.totalResults
      let propertyLoadedCount = properties.propertiesFilters.length
      if (totalresults > propertyLoadedCount) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }
    }
  }, [properties])

  const fetchMoreData = () => {
    if (pagination.totalResults === items.length) {
      setHasMore(false)
      return
    }
    handlePagination()
  }

  const refrestData = () => {
    setItems(_.uniqBy(properties.propertiesFilters, 'property'))
  }

  const getSuburb = () => {
    let suburbs = ''
    if (isFiltered && isFiltered.suburbs) {
      isFiltered.suburbs.map((item, index) => {
        suburbs += item.locality + ', '
      })
    }

    return suburbs.replace(/,\s*$/, '')
  }

  const noResultsMessage = (listing) => {
    switch (listing) {
      case 'favourites':
        return (
          <p>
            There are <strong>0</strong> properties on your favourite list. Try adding
            some to see them listed here.
          </p>
        )

      case 'disliked-properties':
        return (
          <p>
            There are <strong>0</strong> properties on your dislikes list. Try adding
            some to see them listed here.
          </p>
        )

      case 'liked-properties':
        return (
          <p>
            You haven't added any homes yet. Start searching for properties to add now.
          </p>
        )
      default:
        return (
          <p>
            There are <strong>0</strong> properties for sale in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )
    }
  }

  const _renderLoader = () => (
    <div className={classes.loader}>
      <CircularProgress size={20} />
    </div>
  )

  const _renderEndMessage = () => (
    <p style={{ textAlign: 'center', fontSize: 14, padding: '20px 0 0' }}>
      We have loaded all properties
    </p>
  )

  const _renderPullDownToRefreshContent = () => (
    <p style={{ textAlign: 'center', padding: '10px ' }}>
      &#8595; Pull down to refresh
    </p>
  )

  const _renderReleaseToRefreshContent = () => (
    <p style={{ textAlign: 'center', padding: '10px ' }}>&#8593; Release to refresh</p>
  )

  const _renderCardContent = () => (
    <>
      {items.map((value, index) => {
        return (
          <PropertyCard
            key={index}
            cardType={'list'}
            properties={value}
            {...propertyConfigSetter(
              'card-type',
              value ? (value.subscriptionType ? value.subscriptionType : '') : ''
            )}

            authentication={authentication}
            settings={settings}
            toggleSignUp={toggleSignUp}
            setCurrentProperty={setCurrentProperty}
            apiRequestSaveProperty={apiRequestSaveProperty}
            apiPutRemoveDislikedProperty={apiPutRemoveDislikedProperty}
            handleMatchCount={props.handleMatchCount}
            listingType={listingType}
          //editFavoriteStatus={favoritePropertiesHandler}
          />
        )
      })}
    </>
  )

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {items && items.length > 0 ? (
        <InfiniteScroll
          scrollableTarget="scroll-container"
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={_renderLoader()}
          endMessage={_renderEndMessage()}
          refreshFunction={refrestData}
          pullDownToRefresh={false}
          pullDownToRefreshThreshold={100}
          pullDownToRefreshContent={_renderPullDownToRefreshContent()}
          releaseToRefreshContent={_renderReleaseToRefreshContent()}
        >
          {_renderCardContent()}
        </InfiniteScroll>
      ) : !isLoading ? (
        noResultsMessage(listingType)
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleFilterWeb: (checked) => toggleFilterWeb(checked),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertyFaveLikes))
