export const DEFAULT_PAYLOAD = {
  countTotal: true, //boolean
  nearbyList: [], //array
  nearby_filters: {}, //object of nearby filters,
  property_filters: {}, //object of priperty filters
  range_filters: {}, //pricing filters. Ex. {price: {min: 20000, max: 500000}}
  rental_filters: {},
  state: '', //string. Ex "VIC"
  nearby_suburbs: true, //boolean
  reference: '', //string. Ex. "buy"
  suburb: [], //array of suburb objects. Ex. {postcode: "3122", suburb: "HAWTHORN", state: "VIC"}
  keywords: [],
  skip: 0,
  take: 700,
}
