import React, { useState } from 'react';
import { IconButton, Paper, Container, AppBar, Typography, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DialogContainer from './DialogContainer';
import DynamicIcons from '../DynamicIcons';



const styles = theme => ({ 
    headerBar: {
        backgroundColor: theme.palette.light.main,
        boxShadow:'none'
    },
    modal: {
        margin: theme.spacing(7),
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: '95%',
        borderRadius: 38,
        position: 'relative',    
        '&:hover': {
        outline: 'none',
        },
        overflow: 'hidden',
        padding: theme.spacing(3.25, 0, 5)
    },
    closeicon: {
        position: 'absolute',
        top: 20, 
        right: theme.spacing(2.5)
    },
    text: {
        fontSize: 16,
        fontWeight: 300
    },
    title: {
        fontSize: 24,
        fontWeight: 500,
        marginBottom: theme.spacing(2)
    },
});

const MainHeader = withStyles({
    root: {
      boxShadow:'none',
      padding:'10px 0px'
    }
  })(AppBar);

const NotificationDialog = props => {  
    const {
      classes,      
      isOpen, 
      closeCallback,
      title,
      text,
      align,
      showLogo
    } = props;

    const style={
        textAlign: align
    }

    return (                  
        <DialogContainer
                isOpen={isOpen}
                closeCallback={closeCallback}
            >
                <Paper className={classes.modal} elevation={5}>                
                    <MainHeader className={classes.headerBar} position="static">
                        <IconButton aria-label="close" className={classes.closeicon} onClick={()=> {closeCallback(false)}}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>                         
                    </MainHeader>
                    <Container style={style}>
                        { showLogo &&
                            <DynamicIcons icon="2DCPIconColor" style={{height: '61px', width: '40px', position:'relative', top:'-3px'}}/>
                        }
                        {title && 
                            <Typography variant={'h3'}  className={classes.title}>{title}</Typography>
                        }
                        {text && 
                            <Typography variant={'h5'} className={classes.text}>{text}</Typography>
                        }
                    </Container>
                </Paper>
        </DialogContainer>  
    );
}

export default withStyles(styles)(NotificationDialog);