import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import _ from 'lodash'

import Heading from './Heading'
//import PropertyType from './PropertyType'
import SliderContainer from './SliderContainer'
import SliderContainerV2 from './SliderContainerV2'
import FeatureList from './FeatureList'
//import AdvancedSearch from './AdvancedSearch'
//import StateSelect from './StateSelect'
import CustomSelect from './CustomSelect'
import CustomField from './CustomField'
import CustomCheckbox from './CustomCheckbox'
import NearProperties from './NearProperties'
//import DistanceContainer from './DistanceContainer'
import CustomDatePicker from './CustomDatePicker'

import { DEFAULT_FILTERS, DEFAULT_MPP_FILTERS } from 'store/reducers/default'

import {
  PROPERTY_TYPE,
  PROPERTY_DETAILS,
  PROPERTY_LAND_SIZE,
  PROPERTY_FEATURES,
  PROPERTY_ADVANCE,
  PROPERTY_KEYWORD,
  PERFECT_PROPERTY,
} from './DefaultValues'
import LandSize from 'components/Common/GlobalFilters/LandSize'
import Keywords from 'components/Common/GlobalFilters/Keywords'
import Furnished from 'components/Common/GlobalFilters/Furnished'
import UnderOffer from 'components/Common/GlobalFilters/UnderOffer'
import AvailableDate from 'components/Common/GlobalFilters/AvailableDate'
import Features from 'components/Common/GlobalFilters/Features'
import StateSelect from 'components/Common/GlobalFilters/StateSelect'
import AdvancedSearch from 'components/Common/GlobalFilters/AdvancedSearch'
import DistanceContainer from 'components/Common/GlobalFilters/DistanceContainer'
import PropertyType from 'components/Common/GlobalFilters/PropertyType'

const FilterContainer = styled.div`
  width: 100%;
  padding: 10px 23px 30px;
  overflow: hidden;
`

const Wrap = styled.div``

const nearDefaults = [
  {
    id: '0',
    label: 'Capital city',
    active: true,
  },
  /* {
  id:'1',
  label:'Suburbs',
  active:false
},{
  id:'2',
  label:'Rural setting',
  active:false
},{
  id:'3',
  label:'Country Town',
  active:false
} */
]

const Filter = (props) => {
  const [hideState, setHideState] = useState(false)
  const [propertyType, setPropertyType] = useState(
    props.filters ? props.filters.filters.propertyType : PROPERTY_TYPE
  )

  const [propertyDetails, setPropertyDetails] = useState(
    props.filters ? props.filters.filters.propertyDetails : PROPERTY_DETAILS
  )
  const [landSize, setLandSize] = useState(
    props.filters ? props.filters.filters.landSize : PROPERTY_LAND_SIZE
  )
  const [features, setFeatures] = useState(
    props.filters ? props.filters.filters.features : PROPERTY_FEATURES
  )
  const [establishment, setEstablishment] = useState(
    props.filters ? props.filters.filters.establishment : null
  )
  const [keywords, setKeywords] = useState(
    props.filters ? props.filters.filters.keywords : PROPERTY_KEYWORD
  )
  const [advanceProperty, setAdvanceProperty] = useState(
    props.filters ? props.filters.filters.advanceProperty : PROPERTY_ADVANCE
  )
  const [perfectProperty, setPerfectProperty] = useState(
    props.filters
      ? props.filters.perfectProperty
        ? props.filters.perfectProperty
        : PERFECT_PROPERTY
      : PERFECT_PROPERTY
  )
  const [furniture, setFurniture] = useState(
    props.filters ? props.filters.filters.furniture : null
  )
  const [availableDate, setAvailableDate] = useState(
    props.filters ? props.filters.filters.availableDate : null
  )
  const [distanceFromKm, setDistanceFromKm] = useState(
    props.filters && props.filters.filters.distanceFromKm
      ? props.filters.filters.distanceFromKm
      : {
          near: nearDefaults,
          value: { min: 0, max: 500 },
          scale: [0, 500],
        }
  )

  const [dataInitialized, setDataInitialized] = useState(false)

  const txtKeyword = useRef('')
  const txtLandMax = useRef('')
  const txtLandMin = useRef('')

  /** IMPORANT!!!:
   * Commented on Mar 02 -> Causing endless loop to filters restates (BON)
   * */
  // useEffect(() => {
  //   if (props.filters.filters.propertyDetails !== propertyDetails && !dataInitialized) {
  //     setPropertyDetails(props.filters.filters.propertyDetails);
  //     setDataInitialized(true);
  //   }

  //   if (props.filters.filters.propertyType !== propertyType) {
  //     setPropertyType(props.filters.filters.propertyType);
  //   }

  //   if (props.filters.filters.features !== features) {
  //     setFeatures(props.filters.filters.features);
  //   }

  //   if (props.filters.filters.advanceProperty !== advanceProperty) {
  //     setAdvanceProperty(props.filters.filters.advanceProperty);
  //   }

  //   if (props.filters.filters.landSize !== landSize) {
  //     setLandSize(props.filters.filters.landSize);
  //   }
  //   if (props.filters.filters.keywords !== keywords) {
  //     setKeywords(props.filters.filters.keywords);
  //   }

  //   /* if(props.filters.filters.distanceFromKm  && props.filters.filters.distanceFromKm !== distanceFromKm) {
  //     setDistanceFromKm(props.filters.filters.distanceFromKm)
  //   }else{
  //     setDistanceFromKm({
  //         near: nearDefaults,
  //         value: 0
  //       })
  //   } */
  // }, []);

  const INITIAL_STATE = {
    filters: DEFAULT_FILTERS,
    perfectProperty: DEFAULT_MPP_FILTERS,
    suburbs: [],
    sorting: null,
    includeNearby: true,
  }

  /* Watch all data */
  useEffect(() => {
    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }
    const baseFilters = props.filters.filters
    const isReset = _.isEqual(
      JSON.stringify(baseFilters),
      JSON.stringify(INITIAL_STATE.filters)
    )
    if (props.handleFilter) {
      switch (true) {
        case props.filters &&
          !_.isEqual(baseFilters.propertyType, propertyType) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, { propertyType: propertyType }))
          break

        case props.filters &&
          !_.isEqual(baseFilters.propertyDetails, propertyDetails) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, propertyDetails))

          break
        case props.filters &&
          !_.isEqual(baseFilters.landSize, landSize) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, landSize))
          break

        case props.filters &&
          !_.isEqual(baseFilters.features, features) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, features))
          break

        case props.filters &&
          !_.isEqual(baseFilters.establishment, establishment) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, establishment))
          break

        case props.filters &&
          !_.isEqual(baseFilters.keywords, keywords) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, keywords))
          break

        case props.filters &&
          // !_.isEqual(baseFilters.advanceProperty, advanceProperty) &&
          !props.isClearFilter:
          props.handleFilter({ ...filterData, advanceProperty })
          break

        case props.filters &&
          !_.isEqual(baseFilters.perfectProperty, perfectProperty) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, perfectProperty))
          break

        /*  case props.filters &&
           !_.isEqual(baseFilters.furniture, furniture) &&
           !props.isClearFilter:
           props.handleFilter(Object.assign(filterData, furniture));
           break; */

        /* case props.filters &&
          !_.isEqual(baseFilters.availableDate, availableDate) &&
          !props.isClearFilter:
          props.handleFilter(Object.assign(filterData, availableDate));
          break; */

        case props.filters &&
          !_.isEqual(baseFilters.distanceFromKm, distanceFromKm) &&
          !props.isClearFilter:
          props.handleFilter({ ...filterData, distanceFromKm })
          break
      }
    }
  }, [
    propertyType,
    propertyDetails,
    features,
    landSize,
    establishment,
    keywords,
    advanceProperty,
    perfectProperty,
    distanceFromKm,
  ])

  useEffect(() => {
    if (props.isClearFilter) {
      setLandSize({ ...landSize, min: '', max: '' })
      setKeywords({ ...keywords, keyword: '', offer: false })
      setPerfectProperty(PERFECT_PROPERTY)
      setFurniture(null)
      setAvailableDate(null)
      // setDistanceFromKm({near: nearDefaults, value: 0})
      clearTextField()
    }
  }, [props.isClearFilter])

  useEffect(() => {
    if (props.filters.perfectProperty !== perfectProperty) {
      setPerfectProperty(props.filters.perfectProperty)
    }
  }, [props.filters.perfectProperty])

  useEffect(() => {
    if (propertyType && props.propertyTypesCount) {
      const types = propertyType
      const typeKeys = Object.keys(props.propertyTypesCount)
      types.map((i, id) => {
        if (typeKeys.includes(i.title)) {
          Object.assign(types[id], {
            count:
              props.propertyTypesCount[i.title] !== 0
                ? props.propertyTypesCount[i.title]
                : 0,
          })
        } else {
          Object.assign(types[id], { count: 0 })
        }
      })
      setPropertyType(types)
    } else {
      const types = propertyType
      types.map((i, id) => {
        Object.assign(types[id], { count: 0 })
      })
      setPropertyType(types)
    }
  }, [props.propertyTypesCount])

  useEffect(() => {
    setHideState(localStorage.getItem('hideState'))
  }, [localStorage.getItem('hideState')])

  const clearTextField = () => {
    if (txtLandMin && txtLandMin.current !== '') {
      txtLandMin.current.value = ''
    }
    if (txtLandMax && txtLandMax.current !== '') {
      txtLandMax.current.value = ''
    }
    if (txtLandMax && txtLandMax.current !== '') {
      txtLandMax.current.value = ''
    }
  }

  const handlePropertyType = (data) => {
    setPropertyType(data)
  }

  const handlePropertyDetails = (data) => {
    setPropertyDetails(data)
  }

  const handleFeatures = (data) => {
    setFeatures(data)
  }

  const handleEstablishment = (data) => {
    setEstablishment(data)
  }

  const handleFurniture = (data) => {
    console.debug('data', data)

    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }
    if (props.handleFilter) {
      props.handleFilter({ ...filterData, furniture: data })
    }
    setFurniture(data)
  }

  const handleAvailableDate = (data) => {
    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }
    if (props.handleFilter) {
      props.handleFilter({ ...filterData, availableDate: data })
    }
    setAvailableDate(data)
  }

  const handleAdvanceProperty = (data) => {
    setAdvanceProperty(data)
  }

  const handlePerfectProperty = (data, type) => {
    let params = Object.assign({}, perfectProperty)
    if (type === 'state') {
      params.state = data
    }
    if (type === 'near') {
      params.near = data
    }
    if (type === 'radius') {
      params.radius = data
    }
    setPerfectProperty(params)
  }

  const handleLandSizeMin = (data) => {
    setLandSize({ ...landSize, min: data !== 'any' ? parseInt(data) : '' })
  }

  const handleLandSizeMax = (data) => {
    setLandSize({ ...landSize, max: data !== 'any' ? parseInt(data) : '' })
  }

  const handleDistanceFromKm = (data) => {
    let newDistance

    if (distanceFromKm?.near) {
      newDistance = { near: distanceFromKm?.near, ...data }
    } else {
      newDistance = { near: nearDefaults, ...data }
    }

    setDistanceFromKm(newDistance)

    const filterData = {
      propertyType,
      propertyDetails,
      landSize,
      features,
      establishment,
      keywords,
      advanceProperty,
      perfectProperty,
      furniture,
      availableDate,
      distanceFromKm,
    }

    if (props.handleFilter && props.filters) {
      props.handleFilter({ ...filterData, distanceFromKm })
    }
  }

  const _renderPropertyTypeFilters = () => {
    return (
      <>
        <Heading variant="h6" style={{ fontWeight: 400 }}>
          Select Property Type
        </Heading>
        <PropertyType />
        {/*         <PropertyType
          handlePropertyType={handlePropertyType}
          isClearFilter={props.isClearFilter}
          propertyType={propertyType}
          propertyTypesCount={props.propertyTypesCount}
        /> */}
        <SliderContainerV2 path={props.path} />

        {/*         <SliderContainer
          handlePropertyDetails={handlePropertyDetails}
          isClearFilter={props.isClearFilter}
          propertyDetails={propertyDetails}
          path={props.path}
          filters={props.filters}
        /> */}
      </>
    )
  }

  const _renderPerfectPropertyFilters = () => {
    return (
      <div>
        <Heading variant="h6" style={{ fontWeight: 400 }}>
          Where are you looking?
        </Heading>
        {/* <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
          Your perfect Victorian property awaits. Other states hitting the platform
          soon.
        </Heading> */}
        <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
          Your perfect property awaits.
        </Heading>
        <StateSelect />
        {/*         <StateSelect
          isClearFilter={props.isClearFilter}
          listData={perfectProperty.state}
          handlePerfectProperty={handlePerfectProperty}
        /> */}
        {/* <NearProperties
            isClearFilter={props.isClearFilter}
            distanceFromKm={distanceFromKm}
            listData={
              distanceFromKm && distanceFromKm.near ? distanceFromKm.near : nearDefaults
            }
            handleDistanceFromKm={handleDistanceFromKm}
          /> */}
        <DistanceContainer />
        {/*         <DistanceContainer
          isClearFilter={props.isClearFilter}
          distanceFromKm={distanceFromKm}
          radius={distanceFromKm ? distanceFromKm.value : 0}
          handleDistanceFromKm={handleDistanceFromKm}
          scaledDistance={distanceFromKm.scale}
        /> */}
        <AdvancedSearch
          title={'What’s most important to you?'}
          isToggle={false}
          isMPP={true}
        />
        {/*         <AdvancedSearch
          title={'What’s most important to you?'}
          isToggle={false}
          handleAdvanceProperty={handleAdvanceProperty}
          isClearFilter={props.isClearFilter}
          data={advanceProperty}
          isMPP={true}
        /> */}
      </div>
    )
  }

  const _renderRentFilters = () => {
    // const today = new Date()

    return (
      <div>
        <Wrap>
          <AvailableDate />
          {/*  <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
            Available Date
          </Heading>
          <CustomDatePicker
            value={availableDate}
            handleDate={handleAvailableDate}
            isClearFilter={props.isClearFilter}
            minDate={today}
          /> */}
        </Wrap>
        <Wrap>
          <Furnished />
          {/* <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
            Furnished / Unfurnished
          </Heading>
          <CustomSelect
            options={furnitureOption}
            handleSelect={handleFurniture}
            isClearFilter={props.isClearFilter}
            value={furniture}
          /> */}
        </Wrap>
      </div>
    )
  }

  const _renderCommonFilters = () => {
    return (
      <div>
        <LandSize />
        {/* <Wrap>
          <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
            Land Size
          </Heading>
          <Row>
            <Col>
              <SelectLabel>Min</SelectLabel>
              <CustomSelect
                options={landSizeOptions}
                handleSelect={handleLandSizeMin}
                isClearFilter={props.isClearFilter}
                value={landSize.min}
                max={landSize.max !== 'any' ? landSize.max : null}
              />
            </Col>
            <Col>
              <SelectLabel>Max</SelectLabel>
              <CustomSelect
                options={landSizeOptions}
                handleSelect={handleLandSizeMax}
                isClearFilter={props.isClearFilter}
                value={landSize.max}
                min={landSize.min !== 'any' ? landSize.min : null}
                max={landSize.max !== 'any' ? landSize.max : null}
              />
            </Col>
          </Row>
        </Wrap> */}
        <Features />
        {/*         <FeatureList
          handleFeatures={handleFeatures}
          isClearFilter={props.isClearFilter}
          features={features}
        /> */}
        <Wrap>
          <Keywords />
          {/* <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
            Keywords
          </Heading>
          <CustomField
            key={'text-' + keywords.keyword}
            inputRef={txtKeyword}
            // value={keywords.keyword}
            defaultValue={keywords.keyword}
            type="text"
            placeholder="eg. waterfront, street name etc"
            containerstyle={{ paddingBottom: 0 }}
            onBlur={(e) => {
              let value = e.target.value
              setKeywords({ ...keywords, keyword: value })
            }}
          /> */}
          <br />
          <UnderOffer />
          {/* <CustomCheckbox
            key={Math.random().toString(36)}
            checked={keywords.offer}
            label="Exclude under offer"
            onChange={(e) => {
              let value = e.target.value
              setKeywords({ ...keywords, offer: !keywords.offer })
            }}
          /> */}
        </Wrap>
        {/*    <AdvancedSearch
          title={"Advance Property Search"}
          subTitle={"What’s most important to you?"}
          isToggle={true}
          handleAdvanceProperty={handleAdvanceProperty}
          isClearFilter={props.isClearFilter}
          data={advanceProperty}
        /> */}
      </div>
    )
  }

  return (
    <FilterContainer>
      {props.filterType &&
        props.filterType === 'perfect-property' &&
        _renderPerfectPropertyFilters()}
      {_renderPropertyTypeFilters()}
      {props.path && props.path === 'rent' && _renderRentFilters()}
      {/* <Wrap>
        <Heading variant="body1" style={{ fontWeight: 400, color: "#4d4d4d" }}>
          New / Established
        </Heading>
        <CustomSelect
          options={establishmentOption}
          handleSelect={handleEstablishment}
          isClearFilter={props.isClearFilter}
          value={establishment}
        />
      </Wrap> */}
      {!props.filterType &&
        props.filterType !== 'perfect-property' &&
        _renderCommonFilters()}
    </FilterContainer>
  )
}

export default Filter
