import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import theme from 'theme/light';
import { Box, Checkbox, Typography } from '@material-ui/core';
import WelcomeImage from '../../../assets/images/welcome-modal.png';
import CloseIcon from '@material-ui/icons/Close';
import { Close } from '@material-ui/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageModal = ({ open, setOpen }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{ style: { borderRadius: 16, overflow: 'hidden' } }}
    >
      <Box style={{ width: 608, height: 687, position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 0, width: 608, display: 'flex', flexDirection: 'column' }}>
          <img src={WelcomeImage} style={{ width: '100%', height: '306px' }} />
          <Close
            style={{ position: 'absolute', top: 15, right: 25, color: '#FFF', cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
          <Box sx={{ marginTop: 20, marginX: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 4 }}>
              <Typography style={{ fontSize: 32, fontFamily: 'Montserrat', fontWeight: 700 }}>Welcome!</Typography>
              <Typography style={{ fontFamily: 'PT Serif', fontSize: 30, lineHeight: 1.2 }}>
                Here’s where you manage all your Coming Soon & Off Market properties
              </Typography>
            </Box>
            <Box sx={{ marginY: 2 }}>
              <Typography style={{ color: '#4D4D4D' }}>
                We’re busy building out your brand new Agency Portal, so all your On Market properties will be available
                to view and manage here soon.{' '}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ marginLeft: 29 }} display="flex" flexDirection="flow" alignItems="center">
            <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} color="primary" />
            <Typography style={{ color: '#6A6A6A' }}>Don't show this message again</Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
            <Button
              onClick={() => {
                setOpen(!open);
                localStorage.setItem('isWelcomeModalRead', true);
                if (checked) {
                  localStorage.setItem('dontShowPopupAgain', checked);
                }
              }}
              variant="contained"
              style={{
                width: '50%',
                height: 48,
                background: '#35C0CA',
                textAlign: 'center',
                borderRadius: '48px',
                color: '#FFF',
                fontWeight: 700,
                fontSize: 18,
                textTransform: 'capitalize',
              }}
            >
              Got it, Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ManageModal;
