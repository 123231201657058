import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import TuneIcon from '@material-ui/icons/Tune';
import _ from 'lodash'

import ButtonSelection from './ButtonSelection';
import OptionModalTop from './OptionModalTop';
import SliderContainer from './SliderContainer';

import ListOptions from "./ListOptions";

import { BUTTON_FILTERS, PROPERTY_TYPE } from './constants';
import { PRICE_RANGE, PRICE_MARKS } from '../../../constants/constants'

import {
    withStyles,
    IconButton,
    Paper,
    Button,
    Badge,
    Checkbox,
    FormControlLabel,
    Tooltip,
} from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = theme => ({
    filterMainContainer: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    /** Search Filter */
    searchFilterContainer: {
        paddingTop: '0.625em',
        paddingBottom: '0.625em',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        whiteSpace: 'noWrap',
        flexDirection: 'row',
        overflowX: 'scroll',
        alignItems: 'center',
        position: 'relative',
        zIndex: 6,
        backgroundColor: '#fff',
        '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent'
        }
    },
    /** Top Option Modal */
    optionContainer: {
        padding: '1.250em',
        boxShadow: 'none',
        '&:hover': {
            outline: 'none',
        },
    },
    optionButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: '0.625em'
    },
    optionButton: {
        textTransform: 'none',
        borderRadius: 20,
        margin: '0.625em'
    },
    filterContainer: {
        paddingTop: '3.125em',
    },
    filterContainerList: {
        paddingTop: '4.125em',
    },
    iconTune: {
        color: '#000'
    },
    checkBoxText: {
        fontSize: 10,
        fontWeight: 'normal',
        color: '#6A6A6A'
    },
    optionBottomContainer: {
        '&:hover': {
            outline: 'none',
        },
    },
    optionList: {
        height: '40vh',
        overflowY: 'scroll'
    }

});

const priceMarks = PRICE_MARKS
const SCALE_PRICE = PRICE_MARKS[PRICE_MARKS.length - 1].value;

const priceRange = PRICE_RANGE

const handleScale = (valueArray) => {
    return [scale(valueArray[0]), scale(valueArray[1])];
};

const scale = (value) => {
    if (value === undefined) {
        return 0;
    }
    try {
        const previousMarkIndex = Math.floor(value / 25);
        const previousMark = priceMarks[previousMarkIndex];
        const remainder = value % 25;
        if (remainder === 0 && previousMark) {
            return previousMark.scaledValue;
        }

        const nextMark = priceMarks[previousMarkIndex + 1];
        const increment = (nextMark.scaledValue - previousMark.scaledValue) / 25;
        return remainder * increment + previousMark.scaledValue;
    } catch (err) {
        return 0;
    }

};

const FilterOptions = props => {
    const {
        classes,
        type,
        showModalOptions,
        showOptionModal,
        selectedFilterType,
        setIsInputFocused,
        setIncludeNearbyFilter,
        apiRequestSearchProperty,
        filters,
        navigateFilterButtonTo,
        toggleFilterWeb,
        saveFilters
    } = props;
    const [routerChange] = useRouterChange()

    // const cbIncludeNearby = React.useRef('')

    const [buttonFilters, setButtonFilters] = React.useState(BUTTON_FILTERS);
    const [filterData, setFilterData] = React.useState({
        bedrooms: { label: 'Bedrooms', selected: { value: 0, exactMatch: false } },
        price: { label: 'Price', selected: '', min: PRICE_RANGE[0] ?? 0, max: PRICE_RANGE[1] ?? SCALE_PRICE },
        rangedPrice: { label: 'Price', selected: '', min: PRICE_RANGE[0] ?? 0, max: PRICE_RANGE[1] ?? SCALE_PRICE },
        priceRent: { label: 'Price Per Week', selected: '', min: 250, max: 5000 },
        bathrooms: { label: 'Bathrooms', selected: { value: 0, exactMatch: false } },
    });
    const [filterDataProperty, setFilterDataProperty] = React.useState(null);
    const [isNearby, setIsNearby] = React.useState(filters ? filters.includeNearby ? filters.includeNearby : false : true);
    const [filterBed, setFilterBed] = React.useState(null)
    const [filterBath, setFilterBath] = React.useState(null)
    const [filterPrice, setFilterPrice] = React.useState(null)
    const [filterPriceRent, setFilterPriceRent] = React.useState(null)

    const [updatedPropertyFilters, setUpdatedPropertyFilters] = React.useState(null)

    const formatPriceLabel = (valueObj) => {
        let label = ''
        if (valueObj.min < SCALE_PRICE && valueObj.max <= SCALE_PRICE) {
            const scaledVals = handleScale([valueObj.min, valueObj.max])
            label = '$' + nFormatter(scaledVals[0], 2, true) + ' - $' + nFormatter(scaledVals[1], 2, true)
        } else {
            label = '$' + nFormatter(valueObj.min, 2, true) + ' - $' + nFormatter(valueObj.max, 2, true)
        }

        return label
    }

    React.useEffect(() => {

        if (filters) {
            if (filters.filters.propertyDetails) {
                const propertyDetails = filters.filters.propertyDetails;
                const isAny = propertyDetails.price.min === 0 && propertyDetails.price.max === SCALE_PRICE;
                const isAnyRent = propertyDetails.priceRent.min === 250 && propertyDetails.priceRent.max === 5000;
                let sign = '';
                if (propertyDetails.price.max === PRICE_RANGE[1]) {
                    sign = '+'
                }
                let newData = {
                    bedrooms: { label: propertyDetails.bedrooms && propertyDetails.bedrooms.value > 1 ? 'Bedrooms' : 'Bedroom', selected: propertyDetails.bedrooms || '' },
                    price: { label: isAny ? 'Any' : 'Price', selected: isAny ? '' : formatPriceLabel(propertyDetails.price ?? [0, SCALE_PRICE]) + sign, min: propertyDetails.price && propertyDetails.price.min, max: propertyDetails.price && propertyDetails.price.max },
                    priceRent: { label: isAnyRent ? 'Any' : 'Price Per Week', selected: isAnyRent ? '' : '$' + nFormatter(propertyDetails.priceRent.min) + ' to $' + nFormatter(propertyDetails.priceRent.max) + '', min: propertyDetails.priceRent.min, max: propertyDetails.priceRent.max },
                    bathrooms: { label: propertyDetails.bathrooms && propertyDetails.bathrooms.value > 1 ? 'Bathrooms' : 'Bathroom', selected: propertyDetails.bathrooms || '' },
                    scaledPrice: propertyDetails.scaledPrice ?? [0, SCALE_PRICE]
                };
                setFilterData(newData)
                handleData(newData)
            }
            setIsNearby(filters.includeNearby || false)
        }
    }, [filters])

    React.useEffect(() => {
        if (!_.isEqual(filters.filters.propertyType, filterDataProperty) || !filterDataProperty) {
            setFilterDataProperty(filters.filters.propertyType)
        }
    }, [filters.filters.propertyType])


    React.useEffect(() => {
        if (props.isClearFilter) {
            // cbIncludeNearby.current.checked = false;
            setIsNearby(false)
        }
    }, [props.isClearFilter])

    React.useEffect(() => {
        if (filterDataProperty) {
            handleData(null, 'preset')
        }
    }, [filterDataProperty])

    React.useEffect(() => {
        handleData()
    }, [filterData])

    function handleNearbyCheck(value) {
        setIncludeNearbyFilter(value)
        setIsNearby(value)
    }

    function formatLabel(val1, val2) {
        if ((val1 === 0 && val2 === SCALE_PRICE) || (val1 < PRICE_RANGE[0] && val2 === PRICE_RANGE[1])) {
            return 'Any'
        }
        else if ((val1 === 0 && val2 === SCALE_PRICE) || (val1 < PRICE_RANGE[0] && val2 < PRICE_RANGE[1] && val2 > PRICE_RANGE[0])) {
            return 'Below $' + nFormatter(val2, 2, true);
        }
        else if ((val1 === 0 && val2 === SCALE_PRICE) || (val1 < PRICE_RANGE[0] && val2 <= PRICE_RANGE[0])) {
            return 'Below $' + nFormatter(PRICE_RANGE[0], 2, true);
        }
        else {
            let sign = '';
            if (val2 === PRICE_RANGE[1]) {
                sign = '+';
            }
            return '$' + nFormatter(val1, 2, true) + ' - $' + nFormatter(val2, 2, true) + sign
        }
    }

    const handleSlider = (type, data) => {

        const sliderFilter = filterData;

        if (type === 'bedrooms') {
            sliderFilter.bedrooms.label = data.value > 1 ? 'Bedrooms' : 'Bedroom'
            sliderFilter.bedrooms.selected = data

            setFilterBed(sliderFilter.bedrooms)
        }
        if (type === 'price') {
            const isAny = data[0] === PRICE_RANGE[0] && data[1] === PRICE_RANGE[1];
            sliderFilter.price.label = isAny ? 'Any' : ''
            sliderFilter.price.selected = isAny ? '' : formatLabel(data[0], data[1])
            sliderFilter.price.min = data[0]
            sliderFilter.price.max = data[1]

            setFilterPrice(sliderFilter.price)
        }

        if (type === 'scaledPrice') {
            sliderFilter.scaledPrice = data
        }

        if (type === 'priceRent') {
            const isAny = data[0] === 250 && data[1] === 5000;
            sliderFilter.priceRent.label = isAny ? 'Any' : ''
            sliderFilter.priceRent.selected = isAny ? '' : '$' + nFormatter(data[0]) + ' - $' + nFormatter(data[1])
            sliderFilter.priceRent.min = data[0]
            sliderFilter.priceRent.max = data[1]

            setFilterPriceRent(sliderFilter.priceRent)

        }
        if (type === 'bathrooms') {
            sliderFilter.bathrooms.label = data.value > 1 ? 'Bathrooms' : 'Bathroom'
            sliderFilter.bathrooms.selected = data

            setFilterBath(sliderFilter.bathrooms)
        }
        setFilterData({ ...sliderFilter, sliderFilter })

        const propertyData = {
            price: { min: sliderFilter.price.min, max: sliderFilter.price.max },
            rangedPrice: { min: sliderFilter.price.min, max: sliderFilter.price.max },
            priceRent: { min: sliderFilter.priceRent.min, max: sliderFilter.priceRent.max },
            bedrooms: sliderFilter.bedrooms.selected,
            bathrooms: sliderFilter.bathrooms.selected,
            scaledPrice: sliderFilter.scaledPrice
        }

        if (propertyData.rangedPrice.min <= 275 && propertyData.rangedPrice.max <= 275) {
            const scaled = handleScale(propertyData.scaledPrice)
            propertyData.rangedPrice = { min: scaled[0], max: scaled[1] }
        }

        const baseDetails = filters.filters.propertyDetails
        const mergedDetails = Object.assign(baseDetails, propertyData)
        setUpdatedPropertyFilters({ ...filters.filters, propertyDetails: mergedDetails })
    }

    const handlePropertyType = (data) => {
        if (updatedPropertyFilters) {
            setUpdatedPropertyFilters({ ...updatedPropertyFilters, propertyType: data })
        } else {
            setUpdatedPropertyFilters({ ...filters.filters, propertyType: data })
        }
        setFilterDataProperty(data)
    }

    const handleData = (data, isSave) => {
        let newData = data || filterData
        const isRent = props.path && props.path === 'rent';
        // console.log(isRent)
        // console.log(newData)
        if (newData) {
            // const isAny = newData.price.min === PRICE_RANGE[0] && newData.price.max === PRICE_RANGE[1];
            let data = [{
                type: 'bed',
                label: newData.bedrooms.label,
                selected: newData.bedrooms.selected !== "" ? newData.bedrooms.selected : '',
                values: [{
                    name: '',
                    value: newData.bedrooms.selected,
                }]
            }, {
                type: 'cost',
                label: isRent ? newData.priceRent.label : newData.price.label,
                selected: isRent ? newData.priceRent.selected !== "" ? newData.priceRent.selected : '' : newData.price.selected !== "" ? newData.price.selected : '',
                values: [{
                    name: '',
                    value: {
                        min: isRent ? newData.priceRent.min : newData.price.min,
                        max: isRent ? newData.priceRent.max : newData.price.max,
                    },
                }]
            }, {
                type: 'bath',
                label: newData.bathrooms.label,
                selected: newData.bathrooms.selected !== "" ? newData.bathrooms.selected : '',
                values: [{
                    name: '',
                    value: newData.bathrooms.selected,
                }]
            }, {
                type: 'propertyType',
                label: 'Property Type',
                selected: '',
                values: null
            }]

            if (filterDataProperty) {
                data[3].values = filterDataProperty
            }
            setButtonFilters(data)
            if (saveFilters && isSave === 'save' && updatedPropertyFilters) {
                saveFilters(updatedPropertyFilters);
                showOptionModal(null, true);
                setIsInputFocused(false);
                apiRequestSearchProperty()
            }

            if (isSave === 'preset' && updatedPropertyFilters) {
                //setUpdatedPropertyFilters({...updatedPropertyFilters, propertyType: filterDataProperty})
                saveFilters({ ...updatedPropertyFilters, propertyType: filterDataProperty });
            }
        }

    }

    function nFormatter(num, digits) {
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "k" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "B" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return ((num ?? 0) / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }

    function renderListContent() {
        return (
            <Paper
                className={classes.optionContainer}
                // style={{height: selectedFilterType === 'propertyType' ? '80vh' : '60vh'}} 
                elevation={5}>
                <div className={classes.filterContainerList}>
                    <div className={classes.optionList}>
                        <ListOptions
                            type={'property'}
                            options={filterDataProperty}
                            handleType={handlePropertyType}
                        />
                    </div>
                </div>
                <div className={classes.optionButtonContainer}>
                    <Button size={'small'} className={classes.optionButton} onClick={() => {
                        showOptionModal(null, true)
                        setIsInputFocused(false)
                    }}>Cancel</Button>
                    <Button size={'small'} className={classes.optionButton} color={'primary'} variant="contained" onClick={() => {
                        handleData(null, 'save')
                    }}>Apply</Button>
                </div>
            </Paper>
        )
    }

    function renderSearchModalOptions() {
        return (
            <Paper
                className={classes.optionContainer}
                // style={{height: selectedFilterType === 'propertyType' ? '80vh' : '60vh'}} 
                elevation={5}>
                <div className={classes.filterContainer}>
                    <SliderContainer path={props.path} type={selectedFilterType} handleSlider={handleSlider} filterData={filterData} currentFilters={filters.filters.propertyDetails} propertyDetails={filters.filters.propertyDetails} />
                </div>
                <div className={classes.optionButtonContainer}>
                    <Button size={'small'} className={classes.optionButton} onClick={() => {
                        showOptionModal(null, true)
                        setIsInputFocused(false)
                    }}>Cancel</Button>
                    <Button size={'small'} className={classes.optionButton} color={'primary'} variant="contained" onClick={() => {
                        handleData(null, 'save')

                    }}>Apply</Button>
                </div>
            </Paper>
        )
    }

    /** filter view */
    function renderFilterOptions() {
        return (
            <>
                <div className={classes.filterMainContainer}>
                    <FormControlLabel
                        className={classes.checkBoxText}
                        control={
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />}
                                name="checkedI"
                                checked={isNearby}
                                onChange={(e) => { handleNearbyCheck(!isNearby) }}
                            />
                        }
                        label="Include nearby suburbs"
                    />
                </div>
            </>
        )
    }

    /** result view */
    function renderSearchOptions() {
        return (
            <>
                <div className={classes.searchFilterContainer}>
                    <Tooltip title="Filters">
                        <IconButton onClick={() => routerChange(navigateFilterButtonTo)}>
                            <Badge badgeContent={null} color="primary">
                                <TuneIcon className={classes.iconTune} />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    {
                        buttonFilters.map((value, index) => {
                            return (
                                <ButtonSelection
                                    key={index}
                                    data={value}
                                    setShowModalOptions={() => {
                                        showOptionModal(value.type)
                                        setIsInputFocused(false)
                                    }} />
                            )
                        })
                    }
                </div>
            </>
        )
    }

    return (
        <div style={{ position: 'relative' }}>
            <OptionModalTop
                isOpen={showModalOptions}
                showOptionModal={showOptionModal}
                content={selectedFilterType === 'propertyType' ? renderListContent() : renderSearchModalOptions()} />
            {type === 'search' ? renderSearchOptions() : renderFilterOptions()}
        </div>
    )
};

export default withStyles(styles)(FilterOptions);
