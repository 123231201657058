import React, { useState } from 'react';

import HorizontalContainer from './HorizontalContainer';
import PropertySuggestionCards from './PropertySuggestionCards';

import { PROPERTY_CARDS, TRADES } from './constants';

import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: '#f5f5f5',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2)
    },
    headerText: {
        fontWeight: 'normal',
        fontSize: '1.250em',
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    cardContainer: {
        display: 'flex',
        width: 'auto',
        overflow: 'hidden',
    }
});

const PropertySuggestions = props => {
    const {
        classes,
        properties,
        settings,
        authentication,
        apiRequestSaveProperty,
        toggleSignUp,
    } = props;

    const [suggestions, setSuggestions] = React.useState(null);

    React.useEffect(() => {
        // console.log('PROPERTIES', properties)
        setSuggestions(properties)
    }, [properties])

    return (
        <div className={classes.root}>
            {suggestions && <div>
                <Typography
                    className={classes.headerText}
                    variant="h6"
                    component="p">
                    {"Properties we think you'll love"}
                </Typography>
                <div className={classes.cardContainer}>
                    <HorizontalContainer>
                        {
                            suggestions.map((value, index) => {
                                return <PropertySuggestionCards key={index} properties={value} type={'properties'}
                                    toggleSignUp={toggleSignUp}
                                    authentication={authentication}
                                    settings={settings}
                                    apiRequestSaveProperty={apiRequestSaveProperty}

                                />
                            })
                        }
                    </HorizontalContainer>
                </div>
            </div>}
            {/* {TRADES && <div>
                <Typography 
                    className={classes.headerText}
                    variant="h6" 
                    component="p">
                    {'Trades suggested for you'}
                </Typography>               
                <div className={classes.cardContainer}>                
                    <HorizontalContainer>
                    {
                        TRADES.map((value, index) => {
                            return <PropertySuggestionCards key={index} trades={value} type={'trades'}
                                toggleSignUp={toggleSignUp}
                                authentication={authentication}
                                settings={settings}
                                apiRequestSaveProperty={apiRequestSaveProperty} 
                            />
                        })
                    }
                    </HorizontalContainer>
                </div>
            </div>} */}
        </div>
    )
}

export default withStyles(styles)(PropertySuggestions);
