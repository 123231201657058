import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Chip, withStyles } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PMLogo from 'assets/images/pm-logo-inverted.png';
import clsx from 'clsx';

const styles = (theme) => ({
  chip: {
    // minWidth: 150,
    display: 'flex',
    alignItems: 'center',
    color: '#383838',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  icon: {
    textTransform: 'capitalize',
  },
  chipFull: {
    // width: '100%',
    textTransform: 'capitalize',
  },
  greenChip: {
    color: '#6dc5a1',
  },

  yellowChip: {
    color: '#fdbd26',
  },

  redChip: {
    color: '#EF7070',
  },

  orangeChip: {
    color: '#EF6631',
  },

  greyChip: {
    color: '#6A6A6A',
  },
  noDocuSignChip: {},
});

const SuperStatusAgencyTables = (props) => {
  const { classes, type, fullWidth } = props;

  const modifyText = (text) => {
    return text ? text.replace('_', ' ') : '';
  };

  const _renderAgencyStatusChip = () => {
    return (
      <>
        {type && (
          <Typography variant="display1" className={classes.chip}>
            <FiberManualRecordIcon
              className={clsx(classes.icon, {
                [classes.chipFull]: fullWidth,
                [classes.greenChip]:
                  type === 'active' ||
                  type === 'approved' ||
                  type === 'verified' ||
                  type === 'activated' ||
                  type === 'trial_active' ||
                  type === 'completed' ||
                  type === 'Credit Card Added' ||
                  type === 'charged' ||
                  type === 'docusign_signed' ||
                  type === 'signed' ||
                  type === 'Subscription Charged' ||
                  type === 'Promo Code Applied',
                [classes.yellowChip]:
                  type === 'pending' ||
                  type === 'Credit Card Pending' ||
                  type === 'payment_set' ||
                  type === 'docusign_pending' ||
                  type === 'payment_pending' ||
                  type === 'Subscription charge Pending',
                [classes.greyChip]: type === 'not_activated',
                [classes.orangeChip]:
                  type === 'pending_approval' ||
                  type === 'pending_activation' ||
                  type === 'docusign_sent' ||
                  type === 'docusign_resent' ||
                  type === 'sent',
                [classes.redChip]:
                  type === 'error' ||
                  type === 'deleted' ||
                  type === 'archived' ||
                  type === 'suspended' ||
                  type === 'cancelled' ||
                  type === 'stale' ||
                  type === 'rejected' ||
                  type === 'unpaid' ||
                  type === 'Subscription payment failed' ||
                  type === 'resend_docusign',
                [classes.noDocuSignChip]: type === 'no_docusign',
              })}
            />
            {modifyText(type)}
          </Typography>
          /*<Chip
            className={clsx(classes.chip, {
              [classes.chipFull]: fullWidth,
              [classes.greenChip]:
                type === 'active' ||
                type === 'approved' ||
                type === 'verified' ||
                type === 'activated' ||
                type === 'trial_active' ||
                type === 'completed' ||
                type === 'Credit Card Added' ||
                type === 'charged' ||
                type === 'docusign_signed' ||
                type === 'signed' ||
                type === 'Subscription Charged' ||
                type === 'Promo Code Applied',
              [classes.greyChip]:
                type === 'pending' ||
                type === 'Credit Card Pending' ||
                type === 'payment_set' ||
                type === 'docusign_pending' ||
                type === 'payment_pending' ||
                type === 'Subscription charge Pending',
              [classes.orangeChip]:
                type === 'pending_approval' ||
                type === 'pending_activation' ||
                type === 'docusign_sent' ||
                type === 'docusign_resent' ||
                type === 'sent',
              [classes.redChip]:
                type === 'error' ||
                type === 'deleted' ||
                type === 'archived' ||
                type === 'suspended' ||
                type === 'cancelled' ||
                type === 'stale' ||
                type === 'rejected' ||
                type === 'unpaid' ||
                type === 'Subscription payment failed' ||
                type === 'resend_docusign',
              [classes.noDocuSignChip]: type === 'no_docusign',
            })}
            label={modifyText(type)}
          />*/
        )}
      </>
    );
  };

  return <div>{_renderAgencyStatusChip()}</div>;
};

SuperStatusAgencyTables.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
};

export default withStyles(styles)(SuperStatusAgencyTables);
