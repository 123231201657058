import React from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { AuthImages } from 'components/Web/Superadmin'
import AgentLoginForm from 'components/Web/Agent/AgentLoginForm'

const styles = () => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    height: '100vh',
    padding: 40,
    paddingLeft: '10vw',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const AgentLogin = (props) => {
  const { classes } = props

  return (
    <div className={classes.content}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={5} className={classes.leftColumn}>
          <AgentLoginForm />
        </Grid>
        <Grid item xs={12} md={6} lg={7} className={classes.rightColumn}>
          <AuthImages type={'login'} />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(AgentLogin)
