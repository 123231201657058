export function getStatus(state) {
    return state.auth.status
}

export function getErrors(state) {
    return state.auth.error
}

export function getSuccessPayload(state) {
    return state.auth.payload_success
}
