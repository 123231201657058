import React, { useEffect } from 'react'
import Crumbs from '../../components/Web/Header/Crumbs'

import styled from 'styled-components'

import { withStyles, Typography } from '@material-ui/core'
import '../../utils/styles/OfferingTable.css'

const styles = (theme) => ({
  root: {
    //backgroundColor: '#FFFFFF',
  },
})
const OfferingsTable = ({ classes }) => {
  return (
    <div className={classes.root}>
      <table class="offering">
        <thead>
          <tr>
            <th>OFFERING</th>
            <th>ELITE (not available as at the date of this Agreement)</th>
            <th>EXECUTIVE</th>
            <th>EXTENDEDr</th>
            <th>ENTRY </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-column="Offering">Listing Price (subject to clause 18.1)</td>
            <td data-column="Elite">$450 ex GST</td>
            <td data-column="Executive">$250 ex GST</td>
            <td data-column="Extended">$100 ex GST</td>
            <td data-column="Entry">Not applicable</td>
          </tr>
          <tr>
            <td data-column="Offering">Order of Priority</td>
            <td data-column="Elite">1st</td>
            <td data-column="Executive">2nd</td>
            <td data-column="Extended">3rd</td>
            <td data-column="Entry">4th</td>
          </tr>
          <tr>
            <td data-column="Offering">
              How long will a "New" flag be included with the Listing to alert customers
              that the Listing is new?{' '}
            </td>
            <td data-column="Elite">
              For the first 7 days that the property is published on the Website.{' '}
            </td>
            <td data-column="Executive">
              For the first 7 days that the property is published on the Website.
            </td>
            <td data-column="Extended">
              For the first 3 days that the property is published on the Website.
            </td>
            <td data-column="Entry">Not applicable</td>
          </tr>
          <tr>
            <td data-column="Offering">
              Where the Listing is updated, how long will the Listing include an
              "Updated" flag to alert customers that the Listing has been updated?
            </td>
            <td data-column="Elite">
              For the first 3 days immediately following the implementation of the
              update.
            </td>
            <td data-column="Executive">
              For the first 3 days immediately following the implementation of the
              update.
            </td>
            <td data-column="Extended">
              For the first 3 days immediately following the implementation of the
              update.
            </td>
            <td data-column="Entry">Not applicable</td>
          </tr>
          <tr>
            <td data-column="Offering">
              Are Listing Refreshes included for the Core Product (refer to clause
              5.14)?
            </td>
            <td data-column="Elite">
              Yes (not available as at the date of this Agreement)
            </td>
            <td data-column="Executive">
              Yes (not available as at the date of this Agreement)
            </td>
            <td data-column="Extended">No</td>
            <td data-column="Entry">No</td>
          </tr>
          <tr>
            <td data-column="Offering">
              May the Listing include the Customer's logo or photos depicting the
              Customer's brand?{' '}
            </td>
            <td data-column="Elite">Yes</td>
            <td data-column="Executive">Yes</td>
            <td data-column="Extended">Yes</td>
            <td data-column="Entry">No</td>
          </tr>
          <tr>
            <td data-column="Offering">Is the social share function available? </td>
            <td data-column="Elite">Yes</td>
            <td data-column="Executive">No</td>
            <td data-column="Extended">No</td>
            <td data-column="Entry">No</td>
          </tr>
          <tr>
            <td data-column="Offering">
              How long will the Listing receive the offerings of the Core Product?{' '}
            </td>
            <td data-column="Elite">
              60 days, following which the Listing will receive the offerings (including
              order of priority) of an Extended Listing.
            </td>
            <td data-column="Executive">
              60 days, following which the Listing will receive the offerings (including
              order of priority) of an Extended Listing.
            </td>
            <td data-column="Extended">For the duration of the Listing</td>
            <td data-column="Entry">For the duration of the Listing</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default withStyles(styles)(OfferingsTable)
