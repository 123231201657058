import React, { useState, useEffect } from "react";
import styled, { css } from 'styled-components'

import RangeSlider from './RangeSlider';

import { Typography, withStyles } from '@material-ui/core';
import { DISTANCE_RANGE, DISTANCE_MARKS } from "../../../constants/constants";

const Container = styled.div`
  padding:20px 0;
`

const SliderItem = styled.div`
  padding:5px 0;

  .MuiSlider-mark[data-index="0"] {
    opacity:0;
  }
  .MuiSlider-mark[data-index="5"] {
    opacity:0;
  }
  .slider-Within {
    .MuiSlider-markLabel[data-index="0"] {
       left: 15px !important;
    }
    .MuiSlider-markLabel[data-index="2"] {
      right: -50px !important;
    }
  }
`

const marksDefault = [
  {
    value: 0,
    label: '0 km',
  },
  {
    value: 250,
    label: '250 km',
  },
  {
    value: 500,
    label: '500 km',
  }

];

const distanceDefault = [0, 500]

const DistanceContainer = props => {

  const SCALE_DISTANCE = DISTANCE_MARKS[DISTANCE_MARKS.length - 1].value;
  const distanceRange = DISTANCE_RANGE

  // const [distance, setDistance] = useState(props.radius);
  const [scaledDistance, setScaledDistance] = useState(props.scaledDistance ?? [0, SCALE_DISTANCE])
  const [distance, setDistance] = useState([props.distanceFromKm.value.min, props.distanceFromKm.value.max]);

  const distanceMarks = DISTANCE_MARKS



  useEffect(() => {
    if (props.isClearFilter) {
      setDistance(null);
      handleData('clear');
    }
  }, [props.isClearFilter])


  const handleDistance = (event, newValue) => {
    setDistance(newValue);
  };

  const handleChangeCommitted = () => {
    handleData();
  }
  const handleData = (clear) => {
    if (props.handleDistanceFromKm) {
      let data = { value: { min: handleScale(distance)[0], max: handleScale(distance)[1] }, scale: scaledDistance }
      if (clear) {
        data = { value: 0, scale: [0, SCALE_DISTANCE] }
      }
      props.handleDistanceFromKm(data)
    }
  }

  const scale = (value) => {
    if (value === undefined) {
      return 0;
    }
    try {
      const modifier = 25;
      const previousMarkIndex = Math.floor(value / modifier);
      const previousMark = distanceMarks[previousMarkIndex];
      const remainder = value % modifier;
      if (remainder === 0 && previousMark) {
        return previousMark.scaledValue;
      }

      const nextMark = distanceMarks[previousMarkIndex + 1];
      const increment = (nextMark.scaledValue - previousMark.scaledValue) / modifier;
      return remainder * increment + previousMark.scaledValue;
    } catch (err) {
      return 0;
    }

  };

  const handleScale = (valueArray) => {
    if (valueArray) {
      return [scale(valueArray[0]), scale(valueArray[1])];
    } else {
      return DISTANCE_RANGE
    }

  };

  const handleDistanceChange = (event, newValue) => {
    let minVal = parseInt(newValue[0]);
    /* if (minVal > (5000000) && minVal < 10000000) {
      setPriceStep(500000);
    } else if (minVal > (10000000 - 1) && minVal < 20000000) {
      setPriceStep(1000000);
    } else if (minVal > (20000000 - 1)) {
      setPriceStep(5000000);
    } else {
      setPriceStep(50000);
    } */

    if (newValue[0] > 50000 && newValue[0] < distanceRange[1] || newValue[1] > 50000 && newValue[1] < distanceRange[1]) {
      if (newValue[0].toFixed(2).search('500.00') > -1) {
        let nMin = String(newValue[0]).replace('500', '000');
        newValue[0] = parseInt(nMin);
      }
      if (newValue[1].toFixed(2).search('500.00') > -1) {
        let nMax = String(newValue[1]).replace('500', '000');
        newValue[1] = parseInt(nMax);
      }
      setDistance(newValue)
    } else {
      setDistance(newValue)
    }

    setScaledDistance(newValue)
  };


  return (
    <Container>
      <SliderItem>


        {/* <RangeSlider
          label="Within kilometers of capital city"
          icon="Apartment"
          value={distance}
          handleChange={handleDistance}
          handleChangeCommitted={handleChangeCommitted}
          valueLabelDisplay={'off'}
          step={1}
          marks={marksDefault}
          min={distanceDefault[0]}
          max={distanceDefault[1]}
          isSingle={true}
        /> */}

        <RangeSlider label="Within kilometers of capital city" icon="Apartment"
          value={scaledDistance}
          min={0}
          step={5}
          max={SCALE_DISTANCE}
          marks={false}
          scale={handleScale}
          scaledValues={handleScale(scaledDistance)}
          handleChange={handleDistanceChange}
          handleChangeCommitted={handleChangeCommitted}
          valueLabelDisplay={'off'}
          isScaled={true}
          enableExactControls={false}
          isDistance={true}
          isSingle={true}
          labelSuffix={'km'}
        />
      </SliderItem>
    </Container>
  );
};

export default DistanceContainer;