import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import HeadingContentSmall from '../Form/HeadingContentSmall'
import { Button, Container, IconButton, Typography, withStyles } from '@material-ui/core';
import ConfirmDialog from '../../Mobile/CustomDialogs/ConfirmDialog'
import PhotoCamera from '@material-ui/icons/PhotoCamera'

/** Property Utils */
import ButtonStyleFileUpload from '../../../components/Mobile/Form/ButtonStyleFileUpload'
import { requestUploadStatementOfIntent } from '../../../store/api'
import CaptureSOI from './CaptureSOI'
import NotificationDialog from '../CustomDialogs/NotificationDialog'
import { logoutRequest } from '../../../store/actions'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    width: '100%',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  intentUploadWrap: {
    marginTop: theme.spacing(3),
  },
  nextStepsList: {
    paddingLeft: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '35px 0',
  },
})

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
  },
})(Button)

const LogoWrap = styled.div`
  background: black;
  padding: 20px;
  img {
    object-fit: cover;
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
  }
`

const ContentWrap = styled.div`
  padding: 40px 0;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  padding: 0;
`

const DirectUploadStep4 = (props) => {
  const {
    classes,
    apiCreateProperty,
    requestUploadIntent,
    uploaded_intent,
    upload_status,
    upload_error,
    handleFieldChange,
    logo,
    logoutUser,
    hex,
  } = props

  const [confirmUpload, setConfirmUpload] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [files, setFiles] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [isCapture, setIsCapture] = useState(false)
  const [haveFiles, setHaveFiles] = useState(false)

  useEffect(() => {
    if (upload_status === 'success' && !upload_error) {
      setConfirmUpload(false)
      setHaveFiles(true)
      handleFieldChange(
        'propertyInfo',
        null,
        'statementOfIntent',
        uploaded_intent.uploadedFiles[0]
      )
    } else if (upload_error && files) {
      setUploadError(true)
      if (upload_error.status === 400 || upload_error.status === 401) {
        setTimeout(() => {
          logoutUser()
          setUploadError(false)
          window.location.reload()
        }, 4000)
      }
    }
  }, [upload_status, upload_error])

  const handleUploadConfirmation = (mode) => {
    setIsCapture(false)
    var uploadData = new FormData()
    if (files && mode === 'upload') {
      for (const file of files) {
        uploadData.append('soi', file, file.name)
      }
    } else {
      uploadData.append('soi', files, files.name)
    }
    requestUploadIntent(uploadData)
  }

  const handleCancel = () => {
    setIsCapture(false)
    setFiles(null)
  }

  return (
    <div className={classes.root}>
      <Container>
        <LogoWrap style={{ backgroundColor: hex }}>
          <img src={logo} />
        </LogoWrap>
        <ContentWrap>
          <HeadingContentSmall
            title={'Property Direct Upload Step 4'}
            content={'Add the Statement of Information'}
          />
        </ContentWrap>

        <div className={classes.intentUploadWrap}>
          <HeadingContentSmall title={'Statement of Information'} />

          <ButtonStyleFileUpload
            label={
              uploaded_intent && upload_status && upload_status === 'success'
                ? uploaded_intent.uploadedFiles[0]
                : 'Upload Statement of Information'
            }
            onChange={(e, value) => {
              if (e.target.files.length > 0) {
                setFileName(e.target.files[0].name)
              }
              setFiles(e.target.files)
              setConfirmUpload(true)
            }}
            disabled={uploaded_intent && upload_status && upload_status === 'success'}
          />
          {(upload_status === 'failed' || !upload_status) && (
            <>
              <Typography align={'center'}>OR</Typography>
              <ButtonWrap>
                <IconButton
                  color="primary"
                  aria-label="Upload Statement of Information"
                  component="span"
                  onClick={() => {
                    setIsCapture(true)
                  }}
                >
                  <PhotoCamera />
                  <Typography variant={'caption'}>&nbsp;&nbsp;Take Photo</Typography>
                </IconButton>
              </ButtonWrap>
            </>
          )}

          <Typography variant={'h6'}>Next Steps</Typography>
          <ol className={classes.nextStepsList}>
            <Typography variant={'body2'} component={'li'}>
              Click accept
            </Typography>
            <Typography variant={'body2'} component={'li'}>
              The vendor will be sent an email with a link
            </Typography>
            <Typography variant={'body2'} component={'li'}>
              Vendor clicks link to view ad and is sent a code via sms
            </Typography>
            <Typography variant={'body2'} component={'li'}>
              Vendor types in code to accept the ad or clicks Not Accepted
            </Typography>
            <Typography variant={'body2'} component={'li'}>
              Your agency admin is sent an email with a link
            </Typography>
            <Typography variant={'body2'} component={'li'}>
              Admin clicks accept
            </Typography>
            <Typography variant={'body2'} component={'li'}>
              The property will appear on PropertyMate within 24 hours.
            </Typography>
          </ol>
        </div>

        <div className={classes.buttonContainer}>
          <ButtonWrap>
            <StyledButton
              color="primary"
              variant="contained"
              disabled={!haveFiles}
              onClick={() => {
                apiCreateProperty()
              }}
            >
              Accept
            </StyledButton>
          </ButtonWrap>
          <ButtonWrap>
            <StyledButton
              color="primary"
              variant="contained"
              onClick={() => {
                apiCreateProperty()
              }}
            >
              Skip
            </StyledButton>
          </ButtonWrap>
        </div>
        <ConfirmDialog
          isOpen={confirmUpload}
          confirmCallback={() => handleUploadConfirmation('upload')}
          closeCallback={() => setConfirmUpload(false)}
          showLogo={true}
          align={'center'}
          title={'Upload Statement of Intent'}
          text={
            'Are you sure you want to upload ' +
            `${fileName ? fileName : 'selected file'}` +
            '?'
          }
        />
        {uploadError && (
          <NotificationDialog
            isOpen={uploadError}
            closeCallback={() => {
              setUploadError(false)
              handleCancel()
            }}
            title={'Error'}
            text={
              upload_error.status === 401 || upload_error.status === 400
                ? 'Your session has expired or invalid. You will be asked to re-login after few seconds.'
                : 'Error encountered when uploading captured image.'
            }
            showLogo={true}
            align={'center'}
          />
        )}

        {isCapture && !uploadError && (
          <CaptureSOI
            isOpen={isCapture}
            confirmCallback={() => handleUploadConfirmation('capture')}
            closeCallback={handleCancel}
            setFiles={setFiles}
          />
        )}
      </Container>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    upload_status: state.agents.request_upload_property_intent_status,
    upload_error: state.agents.request_upload_property_intent_error,
    uploaded_intent: state.agents.request_upload_property_intent,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      requestUploadIntent: (params) => requestUploadStatementOfIntent(params),
      logoutUser: () => logoutRequest(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DirectUploadStep4))
