import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { navigateReset } from '../../../store/actions'

/** api redux request */
import {
  requestAPIUserLogin,
  requestAPIFacebookAuth,
  requestAPIGoogleAuth,
  requestAPIAppleAuth,
} from '../../../store/api'

import {
  SOCIAL_MEDIA_BUTTONS,
  SIGNIN_VALIDATION_CONFIG,
} from '../../../constants/constants'

import checkField from 'components/Common/Validator/validationService'

import HeadingContent from './HeadingContent'
import FormField from './FormField'
import FormNavigationGroup from './FormNavigationGroup'
import SocialButtonGroup from './SocialButtonGroup'
import AlertNotification from './AlertNotification'

import _ from 'lodash'

import { Grid, Typography, Checkbox, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    //padding: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      outline: 'none',
    },
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
  },
  container: {
    padding: '30px 24px 40px',
  },
  avatar: {},
})

const LOGIN_FIELDS = {
  email: {
    name: 'email',
    id: 'email',
    value: 'email@ema',
    placeholder: 'Email Address',
    label: 'Email Address *',
    type: 'text',
  },
  password: {
    name: 'password',
    id: 'password',
    value: '',
    placeholder: 'Password',
    label: 'Password *',
    type: 'password',
  },
}

const FormLabel = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px',
  },
})(Typography)

const StyledCheckbox = withStyles({
  root: {
    paddingLeft: 0,
    color: '#4D4D4D',
  },
})(Checkbox)

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #646464;
    letter-spacing: 0.09px;
    a {
      color: #646464;
      text-decoration: underline;
    }
  }
`

const MainForm = styled.form`
  padding-top: 0;
`

const Login = (props) => {
  const { classes, setIsForgotPasswordView, tabChange, goBack } = props

  /** redux props */
  const { authentication, settings } = props

  /** settings redux */
  const { isLoading } = settings

  /** redux api actions */
  const { userLogin, facebookAuth, googleAuth, appleAuth, navigateResetRedux } = props

  const [value, setValue] = React.useState(0)

  //Use `errors` object to immediately show errors on the UI on lost focus or `submittedErrors` to only show errors on form submit
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [formData, setFormData] = useState(null)
  const [isRememberChecked, setIsRememberChecked] = useState(false)
  const [errors, setErrors] = useState({
    email: { status: false, message: '' },
    password: { status: false, message: '' },
  })

  const formCheck = (fields, errors) => {
    const reqFields = fields.email.trim() && fields.password.trim() ? true : false
    if (reqFields && Object.keys(errors).length > 0) {
      if (!errors.email.status && !errors.password.status) {
        setIsButtonDisabled(false)
      } else {
        setIsButtonDisabled(true)
      }
    } else {
      setIsButtonDisabled(true)
    }
  }

  /** Reducer Changes Listeners */
  useEffect(() => {
    handleAPIErrorResponse()
    if (goBack && authentication?.user_status === 'logged') {
      // goBack()
    }
  }, [authentication])

  useEffect(() => {
    formCheck({ email, password }, errors)
  }, [email, password])

  /** Login API */
  function apiRequestLogin() {
    userLogin({
      email: email,
      password: password,
      query: "?userRole=Customer"
    })
  }

  /** Facebook API */
  function apiRequestFacebook(token) {
    facebookAuth({
      access_token: token,
    })
  }

  /** Google API */
  function apiRequestGoogle(token) {
    googleAuth({
      access_token: token,
    })
  }

  /** Apple API */
  function apiRequestApple(token) {
    appleAuth({
      access_token: token,
    })
  }

  /** Validate Submission */
  const validateSubmission = () => {
    if (email === '' && password === '') {
      handleNotification(true, {
        message: 'Please input email and password.',
        severity: 'error',
      })
    } else if (email === '') {
      handleNotification(true, { message: 'Please input email.', severity: 'error' })
    } else if (errors.email.status) {
      handleNotification(true, {
        message: 'Please provide valid email.',
        severity: 'error',
      })
    } else if (password === '') {
      handleNotification(true, { message: 'Please input password.', severity: 'error' })
    } else {
      if (_.size(errors) > 0) {
        if (!errors.email.status && !errors.password.status) {
          apiRequestLogin()
        }
      } else {
        apiRequestLogin()
      }
    }
  }

  /** API Error Response Handler */
  function handleAPIErrorResponse() {
    /** Login */
    if (authentication.login_status === 'success') {
      handleNotification(true, { message: 'Login Successful.', severity: 'success' })
      return
    }
    if (authentication.login_status === 'error') {
      if (authentication.login_error) {
        if (
          authentication.login_error.message === 'Illegal arguments: string, undefined'
        ) {
          handleNotification(true, {
            message: 'Unknown user',
            severity: 'error',
          })

          return
        }
        handleNotification(true, {
          message: authentication.login_error.message,
          severity: 'error',
        })
        return
      }
    }
  }

  const handleSocialCallback = (data) => {
    if (data && data.token) {
      if (data.provider === 'facebook') {
        apiRequestFacebook(data.token.accessToken)
      }
      if (data.provider === 'google') {
        apiRequestGoogle(data.token.id_token)
      }
      if (data.provider === 'apple') {
        apiRequestApple(data.token)
      }
    }
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
    navigateResetRedux()
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  return (
    <div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Grid className={classes.container} container wrap="nowrap">
        <Grid item xs={12}>
          <HeadingContent title={'Welcome back'} content={'Login using your email'} />
          <MainForm>
            {/* <FormLabel>Login using your email</FormLabel> */}
            <FormField
              type={'email'}
              value={email}
              placeholder={'Email Address *'}
              errorMsg={errors.email.message}
              showError={errors.email.status}
              onChange={(e) => {
                const value = e.target.value
                setEmail(value)
                if (checkField(value, SIGNIN_VALIDATION_CONFIG.fields.email) === null) {
                  setErrors((prevState) => ({
                    ...prevState,
                    email: { ...prevState.email, status: false },
                  }))
                } else {
                  setErrors((prevState) => ({
                    ...prevState,
                    email: {
                      ...prevState.email,
                      status: true,
                      message: checkField(value, SIGNIN_VALIDATION_CONFIG.fields.email),
                    },
                  }))
                }
              }}
            />
            <FormField
              type={'password'}
              value={password}
              placeholder={'Password *'}
              errorMsg={errors.password.message}
              showError={errors.password.status}
              onChange={(e) => {
                const value = e.target.value
                setPassword(value)
                if (
                  checkField(value, SIGNIN_VALIDATION_CONFIG.fields.password) === null
                ) {
                  setErrors((prevState) => ({
                    ...prevState,
                    password: { ...prevState.password, status: false },
                  }))
                } else {
                  setErrors((prevState) => ({
                    ...prevState,
                    password: {
                      ...prevState.password,
                      status: true,
                      message: checkField(
                        value,
                        SIGNIN_VALIDATION_CONFIG.fields.password
                      ),
                    },
                  }))
                }
              }}
            />

            {/* <CheckBoxLabel htmlFor="remember-checkbox">                
                <StyledCheckbox
                  checked={isRememberChecked}
                  onChange={()=>{
                    setIsRememberChecked(!isRememberChecked)
                  }}
                  id="remember-checkbox"
                  name="remember-checkbox"
                  color="primary"
                />
                <p>Remember me</p>
              </CheckBoxLabel> */}
            <FormNavigationGroup
              type={'login'}
              disabled={isButtonDisabled}
              setIsForgotPasswordView={setIsForgotPasswordView}
              tabChange={tabChange}
              onClick={validateSubmission}
            />
          </MainForm>
          <SocialButtonGroup
            type={'login'}
            label={'Or log in using any of the following methods'}
            socialCallback={handleSocialCallback}
          />
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    loginFields: LOGIN_FIELDS,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userLogin: (params) => requestAPIUserLogin(params),
      facebookAuth: (params) => requestAPIFacebookAuth(params),
      googleAuth: (params) => requestAPIGoogleAuth(params),
      appleAuth: (params) => requestAPIAppleAuth(params),
      navigateResetRedux: () => navigateReset(),
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login))
