import React, { useState } from 'react';
import Image from 'material-ui-image';

import agentLogo from '../../../assets/images/RT-Edgar.png';
import { LOGO_OBJECT, getLogoByAgencyId, getAgencyHexByAgencyId, DEFAULT_AGENCIES, HEX_OBJECT } from '../../../constants/constants'
import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'

import { withStyles, Card } from '@material-ui/core';

const styles = theme => ({
    root: {
        width: '100%',
        height: '1.563em',
        backgroundColor: '#000',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    },
    agentLogo: {
        maxHeight: '1.313em',
        width: 'auto',
        backgroundColor: 'transparent',
        imageRendering: 'auto',
        // imageRendering: 'crisp-edges',
        // imageRendering: 'pixelated',
        imageRendering: '-webkit-optimize-contrast',
        maxWidth: '75%'
    }
});

const PropertySuggestionsAgentBanner = props => {
    const {
        classes,
        properties
    } = props;

    const checkIfLight = (color) => {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness > 155;
    }

    const logoObject = LOGO_OBJECT,
        hexObject = HEX_OBJECT

    const getAgencyLogo = (agencyName) => {
        if (logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] && logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0) {
            return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
        } else {
            return LogoPlaceholder
        }
    }

    const getAgencyHex = (agencyName) => {
        if (hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)] && hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0) {
            return hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)]
        } else {
            return "#000000"
        }
    }

    const brandColor =
        properties.agency && properties.agency.brandColor
            ? properties.agency.brandColor
            : properties.reaAgencyId
                ? getAgencyHex(properties.reaAgencyId || properties.agency.reaAgencyId)
                : '#000000'

    return (
        <div className={classes.root} style={{ backgroundColor: brandColor, boxShadow: checkIfLight(brandColor) ? "0 0 0 1px #000000" : "none" }}>
            <img
                className={classes.agentLogo}
                src={properties.agency && properties.agency.logoUrl ? properties.agency.logoUrl : getAgencyLogo(properties.reaAgencyId || properties.agency.reaAgencyId)} />
        </div>
    )
}

export default withStyles(styles)(PropertySuggestionsAgentBanner);
