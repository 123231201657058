import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DynamicIcons from "../../components/Mobile/DynamicIcons";
import Searchbar from '../../components/Mobile/Searchbar';
import PropertySuggestionCards from '../../components/Mobile/Property/PropertySuggestionCards';
import { PROPERTY_CARDS, TRADES } from '../../components/Mobile/Property/constants';

/** actions */
import {
  setPropertySuburbSearch,
  clearSuburbSearch,
} from '../../store/actions';

/** api redux request */
import {
  requestPropertySearch,
} from "../../store/api";

import { withStyles, Button, Typography } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px'
  },
})(Typography);

const BackButton = withStyles({
  root: {
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
  },
  content: {
    margin: theme.spacing(2)
  },
  cardContent: {
    width: '100vw',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative'
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)'
  }
});

const TradesResults = props => {

  const {
    classes
  } = props;
  const [routerChange] = useRouterChange()

  React.useEffect(() => {

  }, [])

  return (
    <div className={classes.root}>

      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routerChange('/trades')}>
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
      </div>

      <div className={classes.content}>
        <StyledText variant="h2">
          Find a trade or service
        </StyledText>
      </div>

      <Searchbar
        type={'search'}
        sortType={'trades-results'}
        showSearchBar={false}
        showFilters={false}

        trades={TRADES}
      />

      <div className={classes.cardContent}>
        {
          TRADES && TRADES.map((value, index) => {
            return <PropertySuggestionCards key={index} trades={value} type={'trades'} />
          })
        }
      </div>

    </div>
  );
};

const mapStateToProps = state => {
  return {
    stepCounter: null
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      searchProperty: params => requestPropertySearch(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TradesResults));
