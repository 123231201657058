import React from 'react'
import styled, { css } from 'styled-components'
import { Modal, Collapse, Slide, withStyles } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
  content: {
    height: '100%',
  },
})

const Container = styled.div`
  overflow: hidden;
  box-shadow: 0px 7px 5px -1px rgba(0, 0, 0, 0.2), 0px 12px 8px 0px rgba(0, 0, 0, 0.14),
    0px 8px 14px 0px rgba(0, 0, 0, 0.12);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);

  /* &:after {
    content:'';
    display:block;
    position:fixed;
    background-color:rgba(0,0,0,0.5);
    height:100%;
    width:100%;
    z-index:-1;
    top:0;
    opacity:0;
    pointer-events:none;
    transition:0.3s ease opacity;
    ${(props) =>
    props.active === true &&
    css`
      opacity: 1;
      pointer-events: auto;
    `}
  } */
`

const OptionModal = (props) => {
  const { classes, style } = props

  const handleClose = () => {
    props.showOptionModal(null, true)
  }

  return (
    <Container active={props.isOpen} style={style}>
      <Collapse in={props.isOpen} onClose={handleClose}>
        <Slide
          className={classes.root}
          direction="down"
          in={props.isOpen}
          mountOnEnter
          unmountOnExit
        >
          <div className={classes.content}>{props.content}</div>
        </Slide>
      </Collapse>
    </Container>
  )
}

export default withStyles(styles)(OptionModal)
