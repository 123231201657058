//Ari ang ni trigger sa mga pag add sa mga na delete nga favourites

import React, { useEffect, useState, Fragment, Component } from 'react';
import classNames from 'classnames';

import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import Header from 'components/Web/Header';
import { SuperHeader } from 'components/Web/Superadmin';
import Footer from 'components/Web/Footer';
import Sidebar from 'components/Web/Sidebar';
// import LiveChat from 'components/Web/LiveChat'
// import DialogContainer from "components/Mobile/DialogContainer";
// import Filter from "components/Web/Filter";

import _ from 'lodash';

import FilterSearch from 'containers/Web/FiltersSearch';
import FiltersPerfect from 'containers/Web/FiltersPerfect';

import FilterSearchV2 from 'containers/Web/FiltersSearchV2';
import FiltersPerfectV2 from 'containers/Web/FiltersPerfectV2';

import {
  toggleSignUp,
  toggleFilterWeb,
  clearPropertyPayload,
  clearLikedUnlikedProperties,
  mppActions,
} from 'store/actions';
import Form from 'components/Web/Form';

/** api redux request */
import { requestSendSearchTallies, requestSendLikesDislikesProperties, requestGetPropertySave } from 'store/api';

/** Object Filters */
import { reform } from 'utils/filters.utils';
import { IconButton, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { getDislikes, getMpp, getStatus, getErrors, getLikesDislikes } from 'store/selectors/mpp.selectors';
import { requestGETAPIMpp, requestPUTAPIMpp } from 'store/api/mpp.api';
import useFilters from 'utils/customHooks/useFilters';
import { useRouterChange } from 'utils/router.utils';

const drawerWidth = 150;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    position: 'relative',
    zIndex: 7,
  },
  closeBtn: {
    zIndex: 99,
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
    fontSize: 14,
    transition: 'all 0.15s linear',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  contentShift: {
    // marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen
    // })
  },
});

const FilterWrap = styled.div`
  max-width: calc(573px + 90px);
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  padding-left: 90px;
  overflow: hidden;
  height: 100vh;
  left: -663px;
  transition: left 0.7s ease;
  &.active {
    left: 0;
    box-shadow: 10px 20px 20px 5px rgba(0, 0, 0, 0.1);
    .filter-button {
      left: 90px;
    }
  }
`;

const FilterBlock = styled.div`
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  width: 100%;
  max-width: calc(573px + 90px);
  height: 100vh;
  left: 0;
  top: 0;
  overflow-y: auto;
  padding-left: 90px;
  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
  }
`;

const MainLayout = (props) => {
  const {
    classes,
    children,
    authentication,
    property,
    settings,
    clearPropertyPayload,
    clearLikeAndUnlike,
    saveSearchTallies,
    saveLikesDislikes,
    toggleSignUp,
    toggleFilterWeb,
    //getSavedProperties,
  } = props;
  const [routerChange] = useRouterChange();
  const [getFilter, setFilter] = useFilters();
  const SEARCH_BUTTONS = getFilter('search_reference');

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const lightHeaders = [
    '/trades',
    '/agent',
    '/agent/results',
    '/trades/results',
    '/agency/results/:id',
    '/agent/results/:id',
    '/manage-agents',
    '/billing',
    '/invoice',
  ];
  const withFooterRoutes = [
    '/buy/:id',
    '/rent/:id',
    '/off-market/:id',
    '/sold/:id',
    '/trades',
    '/agent',
    '/onboarding',
    '/agency/results/:id',
    '/agent/results/:id',
    '/manage-agents',
    '/agency/dashboard',
    '/agency-account-settings',
    '/billing',
    '/invoice',
    '/agent/my-properties',
    '/agent/dashboard',
    '/agent/add-property',
    '/agent/account-settings',
    '/',
    '/contact-us',
    '/privacy-policy',
    '/terms-and-conditions',
    '/about-us',
    '/agent/add-property',
    // "/agent/edit-property/:id",
    '/agency/sign-up',
  ];
  const withLightCrumbs = ['/trades', '/agent', '/property-value'];
  const noHeaders = ['/confirm-property/:target/:id'];

  const GET_LIKES_DISLIKES = useSelector(getLikesDislikes);
  const GET_MPP = useSelector(getMpp);
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);

  /* if you want to make this function work, please search for the function name */

  // const handleSubmitLikesDislikes = () => {
  //   apiPutLikesDislikesProperties()
  // }

  useEffect(() => {
    if (authentication?.user_status === 'logged' && GET_MPP.status === 'mpp/PUT_MPP_LIKES_DISLIKES_SUCCESS') {
      // clearPutLikesDislikes()
      setIsSaving(false);
    }
  }, [GET_MPP]);

  // const apiPutLikesDislikesProperties = () => {
  //   let request = {
  //     query: null,
  //     data: {
  //       propertyPreference: {
  //         ...GET_LIKES_DISLIKES,
  //         action: 'add',
  //       },
  //     },
  //   }
  //   if (
  //     (GET_LIKES_DISLIKES.likes.length > 0 || GET_LIKES_DISLIKES.dislikes.length > 0) &&
  //     !isSaving &&
  //     GET_MPP.status !== 'mpp/PUT_MPP_LIKES_DISLIKES_SUCCESS'
  //   ) {
  //     dispatch(requestPUTAPIMpp(request, 'PUT_MPP_LIKES_DISLIKES', 'REQUEST'))
  //     setIsSaving(true)
  //   }
  // }

  // const clearPutLikesDislikes = () => {
  //   dispatch(mppActions(null, 'LOCAL_MPP_LIKES_DISLIKES', 'CLEAR'))
  // }

  useEffect(() => {
    /**
     * Clear Property Payload
     * */
    clearPropertyPayload();

    if (authentication && authentication.user_status === 'logged') {
      /**
       * Send Tallies API
       * -- if logged in
       * -- if navigation is no on search nor mpp
       * */
      if (children.props.match.path !== '/buy/:id' && children.props.match.path !== '/perfect-property') {
        if (
          property.liked_properties.length > 0 ||
          property.unliked_properties.length > 0 ||
          property.liked_drill_down_properties.length > 0
        ) {
          apiSendLikesDislikesProperties();
          apiSearchTallies();

          clearLikeAndUnlike();
        }
      }
      if (children.props.match.path === '/') {
        clearLikeAndUnlike();
      }
    }
    toggleFilterWeb({ type: null, status: false });
  }, []);

  useEffect(() => {
    if (authentication?.user_status === 'logged') {
      apiRequestGetSavedProperties();
      /*
      
      - this function is commented out since it is the one that add removed favourites again 

      handleSubmitLikesDislikes()

      */
      /* 
         * Login issue fix
         **
          let currentPath = children.props.history.location.pathname.toLowerCase();
          const authPathArr = [ "/login", "/signup" ]

          if (authPathArr.includes(currentPath)) {
            routeChange("/");
          }
        */
    }
  }, [authentication]);

  useEffect(() => {
    if (settings && settings.showSignUp) {
      toggleSignUp(false);
      routeChange('/login');
    }
  }, [settings.showSignUp]);

  /** GET Save Property API */
  async function apiRequestGetSavedProperties() {
    //getSavedProperties()
    apiRequestFavouritesLists();
  }

  /** Get List of Favourite and Dislike Properties */
  function apiRequestFavouritesLists() {
    handleClearMPPFavouritesList();
    dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'));
  }

  const handleClearMPPFavouritesList = () => {
    dispatch(mppActions(null, 'GET_MPP_FAVOURITES_IDS', 'CLEAR'));
  };

  // useEffect(()=>{
  //   let s = null;
  //   if(page) {
  //     s = setTimeout(()=>{
  //       setIsExpanded(page.active)
  //       clearTimeout(s)
  //     },1)
  //   } else {
  //     setIsExpanded(false)
  //     clearTimeout(s)
  //   }
  // },[page])

  const handleToggleDrawer = (value) => {
    setOpen(value);
  };

  function checkPath() {
    if (lightHeaders.includes(children.props.match.path)) {
      return 'LIGHT';
    } else {
      return 'DARK';
    }
  }

  function checkIfFooterPath() {
    return withFooterRoutes.includes(children.props.match.path);
  }

  function checkIfLightCrumbsShow() {
    return withLightCrumbs.includes(children.props.match.path);
  }

  function checkCrumbsValue() {
    if (children.props.match.path === '/agent') {
      return { label: 'Find an agent', route: '/agent' };
    }
    if (children.props.match.path === '/trades') {
      return { label: 'Find a trade and service', route: '/trades' };
    }
  }
  function checkIfMpp() {
    let currentPath = children.props.history.location.pathname.toLowerCase();
    const ifMpp = SEARCH_BUTTONS.some((button) => button.active === true && button.id === 'mpp');
    if (ifMpp && currentPath !== '/perfect-property') {
      const homeButtons = SEARCH_BUTTONS.map((item) =>
        item.path.toLowerCase().replace('/', '') === 'buy' ? { ...item, active: true } : { ...item, active: false },
      );
      console.debug('load home search ref iff mpp', homeButtons);
      setFilter({ key: 'search_reference', value: homeButtons });
    }
  }

  /**
   * Send Tallies Properties */
  async function apiSearchTallies() {
    let properties = {
      likes: property.liked_properties,
      dislikes: property.unliked_properties,
      likes_drill_down: property.liked_drill_down_properties,
    };
    let params = {
      swipeTallies: await reform('Swipe_Tallies', properties),
      drillDownTallies: await reform('Drill_Down_Tallies', properties),
    };
    saveSearchTallies(params);
  }

  /**
   * Send Likes and Dislikes Properties */
  async function apiSendLikesDislikesProperties() {
    let params = {
      propertyPreference: {
        likes: await reform('Likes', property.liked_properties),
        dislikes: await reform('Dislikes', property.unliked_properties),
      },
    };
    saveLikesDislikes(params);
  }

  const routeChange = (path, state) => {
    routerChange(path, state);
    setIsExpanded(false);
    setOpen(false);
    /** reset redux state */
    toggleFilterWeb({ type: null, status: false });

    /* 
       * Login issue fix
       **
        // Check target path, if its the same as the current path, dont add on history
        if (path !== children.props.history.location.pathname) {
          children.props.history.push(path)
          setIsExpanded(false);
          setOpen(false);
          toggleFilterWeb({type: null, status: false});
        }
      */
  };

  const handleNavigation = (type) => {
    if (type.filter) {
      if (type.cls === 'perfect-property') {
        toggleFilterWeb({ type: 'perfect-property', status: type.active });
      } else if (type.cls === 'buy') {
        toggleFilterWeb({ type: 'buy', status: type.active });
      } else if (type.cls === 'rent') {
        toggleFilterWeb({ type: 'rent', status: type.active });
      } else if (type.cls === 'sold') {
        toggleFilterWeb({ type: 'sold', status: type.active });
      } else if (type.cls === 'off-market') {
        toggleFilterWeb({ type: 'off-market', status: type.active });
      } else if (type.cls === 'hush-hush') {
        toggleFilterWeb({ type: 'hush-hush', status: type.active });
      } else if (type.cls === 'coming-soon') {
        toggleFilterWeb({ type: 'coming-soon', status: type.active });
      }
      setPage(type);
    } else {
      setPage(null);
      routeChange(type.path);
    }
  };

  const standardSearchScreens = ['buy', 'off-market', 'rent', 'sold', 'hush-hush', 'coming-soon'];

  const getRoutePath = () => {
    let path = props.children.props.match.path.split('/')[1];
    const pathArr = [
      '/agent/my-properties',
      '/agent/dashboard',
      '/agent/account-settings',
      '/agent/email',
      '/agency/dashboard',
      '/agency/account-settings',
      '/agent/add-property',
      '/agent/forgot-password',
    ];
    const oldPathsArr = ['manage-agents', 'billing', 'invoice'];

    if (oldPathsArr.includes(path) || pathArr.includes(props.children.props.match.path)) {
      return true;
    }
    return false;
  };

  const changeRoute = (path) => {
    children.props.history.push(path);
    // children.props.history(path)
  };

  return (
    <Fragment>
      <Sidebar
        handleToggleDrawer={handleToggleDrawer}
        handleNavigation={handleNavigation}
        routerChange={routeChange}
        auth={authentication.user_status}
        page={settings.showFilterWeb.type}
        open={open}
        filterWebActive={settings.showFilterWeb.status}
        isAgent={getRoutePath()}
        isSuperadmin={true}
        isSupera
        authentication={authentication}
        {...children.props}
      />
      <FilterWrap className={settings.showFilterWeb.status ? 'active' : ''}>
        <FilterBlock className={settings.showFilterWeb.type === 'perfect-property' ? 'active' : ''}>
          <IconButton
            className={classes.closeBtn}
            disableFocusRipple
            disableRipple
            color={'inherit'}
            onClick={() => {
              toggleFilterWeb({ type: null, status: false });
            }}
          >
            Close&nbsp;
            <CloseIcon fontSize={'small'} />
          </IconButton>
          <FiltersPerfectV2 page={settings.showFilterWeb.type} />
        </FilterBlock>
        <FilterBlock className={standardSearchScreens.includes(settings.showFilterWeb.type) ? 'active' : ''}>
          <IconButton
            className={classes.closeBtn}
            disableFocusRipple
            disableRipple
            color={'inherit'}
            onClick={() => {
              toggleFilterWeb({ type: null, status: false });
            }}
          >
            Close&nbsp;
            <CloseIcon fontSize={'small'} />
          </IconButton>
          <FilterSearch page={settings.showFilterWeb.type} />
        </FilterBlock>
      </FilterWrap>

      <main
        className={classNames(classes.content, { [classes.contentShift]: open })}
        onClick={(e) => {
          if (props.children.props.match.path.search('agent') === -1) {
            if (settings.showFilterWeb.type) {
              if (
                !e.target?.classList?.contains('MuiButton-label') &&
                !e.target?.classList?.contains('btn-hero') &&
                !e.target?.parentElement?.classList?.contains('Filter-Button')
              ) {
                e.preventDefault();
                checkIfMpp();
                toggleFilterWeb({ type: null, status: false });
              }
            }
            // if(settings.showFilterWeb.type !== null){
            // //   toggleFilterWeb({type: null, status: false})
            // // }
            setPage(null);
          }
        }}
      >
        <SuperHeader {...children.props} user={authentication} />
        {children}
      </main>

      {checkIfFooterPath() && <Footer isAgent={getRoutePath()} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilterWeb: (checked) => toggleFilterWeb(checked),
      clearPropertyPayload: () => clearPropertyPayload(),
      saveLikesDislikes: (params) => requestSendLikesDislikesProperties(params),
      saveSearchTallies: (params) => requestSendSearchTallies(params),
      clearLikeAndUnlike: () => clearLikedUnlikedProperties(),
      //getSavedProperties: () => requestGetPropertySave(),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainLayout));
