import React from 'react';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import DynamicIcons from '../DynamicIcons';
import useFilters from 'utils/customHooks/useFilters';
import { toggleFilterWeb } from 'store/actions';
import { useDispatch } from 'react-redux';
import useGoogleEvents from 'utils/customHooks/useGoogleEvents';

const useStyles = makeStyles((theme) => ({
  headingHeadline: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 65,
    lineHeight: '60.5px',
    color: '#FFFFFF',
    marginBottom: theme.spacing(6),
    letterSpacing: '-0.78px',

    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
    },
    '@media (max-width: 1140px)': {
      fontSize: 50,
      lineHeight: '50px',
      marginBottom: theme.spacing(4),
    },

    [theme.breakpoints.down(1300)]: {
      fontSize: '50px',
    },
  },
  myPerfectPropertyContainer: {
    paddingBottom: '15px',
    paddingTop: '30px',

    [theme.breakpoints.down(1200)]: {
      paddingTop: '15px',
      fontSize: '12px',
    },
  },

  myPerfectPropertyButton: {
    height: '41px',
    borderRadius: '19px',
    margin: '0',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primaryYellow.main,
    boxShadow: '0px 5px 5px rgb(0 0 0 / 30%)',
    color: '#000 !important',
    width: '100%',
    maxWidth: 300,
    '&:hover': {
      backgroundColor: theme.palette.primaryYellow.main,
      color: '#000 !important',
    },
    '&:active': {
      backgroundColor: theme.palette.primaryYellow.main,
      color: '#000 !important',
    },
  },
}));

const HomePageSearchHeading = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [getFilter, setFilter] = useFilters();
  const SEARCH_BUTTONS = getFilter('search_reference');
  const { triggerAnalyticsEvent } = useGoogleEvents();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography component="h1" className={classes.headingHeadline}>
          <strong>find</strong> where you belong
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: 'grid',
          justifyItems: 'end',
        }}
      >
        <Box className={classes.myPerfectPropertyContainer} maxWidth="300px">
          <Typography className={classes.myPerfectPropertyContainer} style={{ lineHeight: '20px' }} variant="body1">
            You’ll discover more homes if you prioritise lifestyle over location. Allow My Perfect Property to lead you
            further
          </Typography>
          <Button
            className={classes.myPerfectPropertyButton}
            variant="outlined"
            color="primary"
            size="small"
            endIcon={<DynamicIcons icon={SEARCH_BUTTONS[6].icon} />}
            style={{
              color: '#fff',
              borderColor: '#EADF74',
              paddingLeft: '1.250em',
            }}
            onClick={(e) => {
              dispatch(
                toggleFilterWeb({
                  type: SEARCH_BUTTONS[6].path.replace('/', ''),
                  status: true,
                }),
              );
              const homeButtons = SEARCH_BUTTONS.map((item) =>
                item.path.toLowerCase().replace('/', '') === 'perfect-property'
                  ? { ...item, active: true }
                  : { ...item, active: false },
              );
              setFilter({ key: 'search_reference', value: homeButtons });
              triggerAnalyticsEvent({
                event: 'homepage_actions',
                eventFrom: 'Web',
                actionScope: 'homepage_filters',
                actionName: 'Homepage Filters',
                actionTarget: 'Selected ' + SEARCH_BUTTONS[6]?.id + ' filter',
                actionReference: 'homepage',
              });
            }}
          >
            <span
              style={{
                textTransform: 'capitalize',
                textAlign: 'center',
                justifyContent: 'center',
                textTransform: 'capitalize',
                lineHeight: 1,
                fontWeight: 400,
                letterSpacing: '0.0848923px',
                whiteSpace: 'nowrap',
                fontSize: '16px',
              }}
            >
              Find My Perfect Property
              {/* {SEARCH_BUTTONS[6].name} */}
            </span>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default HomePageSearchHeading;
