import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

const id = Math.floor((Math.random() * 1000) + 1);

export function SaleDropInIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M8.81355 23.1164L8.72582 23.0287L8.60725 22.9922C3.911 21.545 0.5 17.1744 0.5 12C0.5 5.64614 5.64614 0.5 12 0.5C18.3539 0.5 23.5 5.64614 23.5 12C23.5 17.1739 20.0796 21.5545 15.3633 23.002L15.2444 23.0385L15.1564 23.1264L11.99 26.2929L8.81355 23.1164Z" fill={props.fill ? props.fill : 'white'} stroke={props.stroke ? props.stroke : 'black'}/>
      <mask id={id} mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="4" width="11" height="15">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 4.7002H18V18.7002H7V4.7002Z" fill="white"/>
      </mask>
      <g mask={'url(' + id + ')'}>
        <path fillRule="evenodd" clipRule="evenodd" d="M14.7059 11.7826V11.7829H10.4337V9.22899L12.5742 7.7569L14.7059 9.22329V11.7826ZM12.5 4.7002C9.46801 4.7002 7.00938 7.08439 7.00059 10.0283L7 10.0277V18.7002C7.04834 18.6737 10.281 17.6309 10.4325 14.9837C11.0817 15.2459 11.7532 15.3885 12.5 15.3885C15.5376 15.3885 18 12.9961 18 10.0445C18 7.09293 15.5376 4.7002 12.5 4.7002Z" fill={props.secondaryfill ? props.secondaryfill : 'black'}/>
      </g>
    </SvgIcon>
  );
}

export function SaleDropInIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SaleDropInIcon />
      <SaleDropInIcon color="primary" />
      <SaleDropInIcon color="secondary" />
      <SaleDropInIcon color="action" />
      <SaleDropInIcon color="disabled" />
      <SaleDropInIcon style={{ color: green[500] }} />
    </div>
  );
}
