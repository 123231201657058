import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react'

const DownloadQRCodeUI = ({ imageURL, altText, qrCodeRef, referralCodeLink }) => {
  return (
    <Box
      ref={qrCodeRef}
      width={420}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <img
        src={imageURL}
        altText={altText}
        style={{ marginTop: 20, width: 135.49, height: 32 }}
      />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        marginBottom={5}
      >
        <Box mb={1} mt={2}>
          <QRCodeSVG value={referralCodeLink} />
        </Box>
        <Typography style={{ fontFamily: 'Montserrat', fontSize: 21, marginTop: 10 }}>
          Scan the QR Code
        </Typography>
        <Typography style={{ fontSize: 13, marginTop: 10 }}>
          By scanning the QR Code, you will be redirected to Propertymate site.
        </Typography>
      </Box>
    </Box>
  )
}

export default DownloadQRCodeUI
