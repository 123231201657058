import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import Sticky from 'react-sticky-el'

import {
  Typography,
  IconButton,
  Badge,
  Paper,
  Grid,
  Button,
  Box,
  withStyles,
  Tooltip,
  Link,
} from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'
import BedIcon from '@material-ui/icons/Hotel'
import ParkingIcon from '@material-ui/icons/DriveEta'
import ViewIcon from '@material-ui/icons/Visibility'
import EmailIcon from '@material-ui/icons/Email'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'

import DynamicIcons from '../DynamicIcons'
import ReadMore from './ReadMore'
import OptionModalBottom from './OptionModalBottom'
import ListOptions from './ListOptions'

import { OPTION_MODAL } from '../../Web/Property/constants'
import AgentCards from '../../Web/Agent/AgentCards'
import DOMPurify from 'dompurify'
import { useHandleOutsideClickCloseModal } from 'utils/customHooks/useHandleOutsideClickCloseModal'
import { useRouterChange } from 'utils/router.utils'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  rootSearch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rootProperty: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoContainerSearch: {
    //paddingBottom: theme.spacing(1)
  },
  infoContainer: {
    padding: theme.spacing(1, 0),
    fontSize: 16,
  },
  infoGrid: {
    position: 'relative',
    '& > div:last-child': {
      minWidth: 325,
    },
    '& .sticky': {
      top: '100px !important',
    },
  },
  icon: {
    color: '#000',
  },
  iconSave: {
    color: '#000',
    transform: 'rotate(180deg)',
  },
  customIcon: {
    color: '#000',
    width: 20,
    height: 20,
  },
  emailContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emailIcon: {
    width: '0.625em',
    height: '0.625em',
  },
  emailText: {
    marginLeft: '0.625em',
  },
  infoDescription: {
    paddingTop: theme.spacing(3),
    whiteSpace: 'pre-line',
    fontSize: 16,
    lineHeight: '23px',
    letterSpacing: '0.09px',

    '& .MuiTypography-caption': {
      fontSize: '1em',
    },
  },
  /** Bottomn Option Modal */
  optionBottomContainer: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    '&:hover': {
      outline: 'none',
    },
  },
  optionBottomHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  optionBottomHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionBottomHeaderText: {
    marginLeft: '5px',
    fontFamily: 'Montserrat',
    fontWeight: '300',
  },
  optionBottonList: {
    height: '25vh',
    overflowY: 'scroll',
  },
  arrow: {
    backgroundColor: '#FFF',
  },
  filterMain: {
    minHeight: 40,
    width: 150,
    borderRadius: 4,
    backgroundColor: '#FFF',
    position: 'absolute',
    zIndex: 1500,
    border: '1px solid #000000',
    top: 50,
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
  },
  filterText: {
    fontSize: 14,
    fontWeight: 'normal',
    color: '#000',
  },
  triangle: {
    position: 'absolute',
    top: -11,
    zIndex: 1000,
    left: '45%',
    width: 0,
    borderBottom: 'solid 10px black',
    borderRight: 'solid 10px transparent',
    borderLeft: 'solid 10px transparent',
  },
  empty: {
    position: 'absolute',
    top: -10,
    zIndex: 1200,
    left: '45%',
    width: 0,
    borderBottom: 'solid 10px white',
    borderRight: 'solid 10px transparent',
    borderLeft: 'solid 10px transparent',
  },
  priceText: {
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  priceTextInner: {
    fontSize: 24,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  rentalBond: {
    fontSize: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  dateText: {
    fontSize: '12px',
  },
  otherInfoText: {
    fontSize: '13px',
    paddingBottom: theme.spacing(1),
  },
  addressText: {
    fontSize: 14,
    lineHeight: '29px',
  },
  addressTextInner: {
    fontSize: '16px',
    // lineHeight: '29px',
  },
  detailsColumn: {
    paddingRight: theme.spacing(10),
    fontSize: '13px',

    [theme.breakpoints.down(768)]: {
      flexGrow: 1,
      paddingRight: '10px',
      maxWidth: '100%',
    },
  },
  inspectionDateText: {
    color: '#70757A',
    fontSize: 12,
  },
  cta: {
    color: '#000000',
    borderRadius: '10rem',
    textTransform: 'initial',
    padding: theme.spacing(0.6, 1),
    minWidth: 180,
    marginRight: theme.spacing(3),
  },
  headlineTextInner: {
    lineHeight: '29px',
    whiteSpace: 'normal',
    marginTop: '10px',
    marginBottom: '5px',
  },

  //email button
  emailButton: {
    minWidth: '0px',

    [theme.breakpoints.down(1450)]: {
      padding: '5px 10px',
    },

    '& .MuiButton-startIcon': {
      [theme.breakpoints.down(1450)]: {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },

    [theme.breakpoints.down(1250)]: {
      padding: '5px 5px',
    },
  },
  emailButtonText: {
    [theme.breakpoints.down(1450)]: {
      display: 'none',
    },
  },

  // Share Content Modal
  shareContentModal: {
    top: '120%',
    left: '-52.5%',
    minWidth: '400px',
    zIndex: 991,
    position: 'absolute',
  },
})

const CardContainer = styled.div`
  position: absolute;
  &.is-sticky {
    @media screen and (min-width: 1240px) {
      opacity: 0;
      pointer-events: none;
    }
  }
`

// function auctionTimeCalculator(time) {
//   const formattedTime = time.split(':')

//   let meridiem
//   let hour

//   const hourData = formattedTime[0]
//   const minutesData = formattedTime[1]

//   if (hourData === '0') {
//     hour = '12'
//     meridiem = 'am'

//     return `${hour}:${minutesData}${meridiem} `
//   }

//   if (hourData < 12 && hourData !== '0') {
//     hour = hourData
//     meridiem = 'am'

//     return `${hour}:${minutesData}${meridiem} `
//   }

//   if (hourData > 12 && hourData !== '0') {
//     hour = hourData % 12
//     meridiem = 'pm'

//     return `${hour}:${minutesData}${meridiem} `
//   }
// }

function inspectionDateHandler(properties, inspectionDates, dateNow) {
  for (let index = 0; index < inspectionDates.length; index++) {
    const inspectionDate = new Date(inspectionDates[index].toString())
    inspectionDate.setDate(inspectionDate.getDate() + 1)

    if (inspectionDate > dateNow) {
      const inspectionTimeArray = properties.inspectionTimes[index].split(' ')
      inspectionTimeArray.shift()
      const inspectionTime = inspectionTimeArray.join(' ')

      const inspectionFullDate = properties.inspectionTimes[index].split(' ', 1)
      const fullFormattedDate = moment(inspectionFullDate.toString()).format(
        'ddd D MMM'
      )

      const formattedInspectionDate = `${fullFormattedDate}, ${inspectionTime}`

      return {
        type: 'Inspection',
        date: formattedInspectionDate,
        showDate: true,
      }
    }
  }
}

// function getAllPendingAuctionInspections(properties) {
//   const pendingAuctionInspections = {
//     auction: '',
//     inspections: [],
//   }
//   const dateNow = new Date()

//   if (properties.auctionDate) {
//     const formattedAuctionDate = new Date(properties.auctionDate)
//     formattedAuctionDate.setDate(formattedAuctionDate.getDate() + 1)

//     if (formattedAuctionDate > dateNow) {
//       formattedAuctionDate.setDate(formattedAuctionDate.getDate() - 1)
//       const utc = formattedAuctionDate.toUTCString()

//       const formattedUTCTime = auctionTimeCalculator(utc.split(' ')[4])
//       const formattedUTCDate = utc.split(' ')
//       formattedUTCDate.splice(4, 2)

//       pendingAuctionInspections.auction = `${formattedUTCDate.join(
//         ' '
//       )} ${formattedUTCTime}`
//     }
//   }

//   properties.inspectionTimes.forEach((inspectionDate) => {
//     const fullInspectionDate = inspectionDate.split(' ', 1).toString()
//     const formattedInspectionDate = new Date(fullInspectionDate.toString())
//     formattedInspectionDate.setDate(formattedInspectionDate.getDate() + 1)

//     if (formattedInspectionDate > dateNow) {
//       const inspectionDateformatted = moment(fullInspectionDate).format('ddd D MMMM Y')

//       const inspectionTimeArray = inspectionDate.split(' ')
//       inspectionTimeArray.shift()
//       const inspectionTime = inspectionTimeArray.join(' ')

//       pendingAuctionInspections.inspections.push(
//         `${inspectionDateformatted} ${inspectionTime}`
//       )
//     }
//   })

//   return pendingAuctionInspections
// }

const htmlDecode = (text) => {
  let e = document.createElement('div')
  e.innerHTML = text
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
}

const PropertyInfo = (props) => {
  const {
    classes,
    properties,
    type,

    selectProperty,
    matchCount,
    listingType,
    executeScroll,
    executeScrollPriceGuide,
    addIconOnCard,
    iconPointerEvents,
  } = props
  const [routerChange] = useRouterChange()
  const [showModalBottomOptions, setShowModalBottomOptions] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const addressRegex = / *AU *$|AUS *$/gm

  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents()

  function showOptionBottomModal() {
    setShowModalBottomOptions(!showModalBottomOptions)
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  function formatUSD(num) {
    if (num && typeof num !== 'object') {
      return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return null
    }
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltip = () => {
    setOpen(!open)
  }

  function renderInfoSearchScreen() {
    const dateNow = new Date()

    let inspectionDateData = {
      type: '',
      date: '',
      showDate: false,
    }

    const inspectionDates = properties.inspectionTimes?.map((inspectionDate) =>
      inspectionDate.split(' ', 1)
    )

    if (inspectionDates?.length > 0) {
      let inspectionData = inspectionDateHandler(properties, inspectionDates, dateNow)
      inspectionDateData = {
        ...inspectionData,
      }
    }

    return (
      <div>
        {properties &&
          properties.inspectionTimes &&
          properties.inspectionTimes.length > 0 &&
          inspectionDateData.showDate && (
            <Box marginBottom="5px">
              <Typography
                component="p"
                variant="body2"
                className={classes.inspectionDateText}
              >
                {`Inspection ${inspectionDateData.date}`}
              </Typography>
            </Box>
          )}

        <div
          className={classes.rootSearch}
          style={{
            pointerEvents: iconPointerEvents ? iconPointerEvents : 'all',
            alignItems: 'center',
          }}
        >
          {properties.propertyType?.toLowerCase() === 'land' ||
          properties.reaPropertyType?.toLowerCase() === 'land' ? null : (
            <div style={{ marginLeft: '-12px' }}>
              <Tooltip title="Bedrooms">
                <IconButton
                  style={{
                    background: 'none',
                    paddingTop: 0,
                    paddingBottom: 0,
                    cursor: 'auto',
                    zIndex: 4,
                  }}
                  onClick={() => ''}
                  disableRipple
                >
                  <BedIcon className={classes.icon} />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                    style={{ paddingLeft: 5 }}
                  >
                    {properties?.bedrooms ? properties?.bedrooms : 0}
                  </Typography>
                </IconButton>
              </Tooltip>

              <Tooltip title="Bathrooms">
                <IconButton
                  style={{
                    background: 'none',
                    paddingTop: 0,
                    paddingBottom: 0,
                    cursor: 'auto',
                    zIndex: 4,
                  }}
                  onClick={() => ''}
                  disableRipple
                >
                  <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                    style={{ paddingLeft: 5 }}
                  >
                    {properties.bathrooms ? properties.bathrooms : 0}
                  </Typography>
                </IconButton>
              </Tooltip>

              <Tooltip title="Parking">
                <IconButton
                  style={{
                    background: 'none',
                    paddingTop: 0,
                    paddingBottom: 0,
                    cursor: 'auto',
                    zIndex: 4,
                  }}
                  onClick={() => ''}
                  disableRipple
                >
                  <ParkingIcon className={classes.icon} />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                    style={{ paddingLeft: 5 }}
                  >
                    {properties?.totalParking ? properties?.totalParking : 0}
                  </Typography>
                </IconButton>
              </Tooltip>
            </div>
          )}

          {addIconOnCard && (
            <Box>
              <Button
                className={classes.emailButton}
                startIcon={<EmailIcon />}
                color={'primary'}
                variant="outlined"
                onClick={() => {
                  routerChange('/' + listingType + '/' + properties.property, {
                    previous_screen: listingType,
                    from: 'cardEmailIcon',
                  })
                }}
              >
                <Box className={classes.emailButtonText}>EMAIL</Box>
              </Button>
            </Box>
          )}
        </div>

        <div className={classes.infoContainerSearch}>
          {properties && !properties.priceView ? (
            (properties?.propertyStatus === 'rental' ||
              properties?.propertyStatus === 'leased') &&
            properties?.rentalPrice ? (
              <Typography
                className={classes.priceText}
                align={'left'}
                variant="h6"
                component="p"
              >
                {`$${properties.rentalPrice} weekly`}
              </Typography>
            ) : (
              <Link
                onClick={() => {
                  selectProperty(true)
                  triggerAnalyticsEvent({
                    event: 'property_actions',
                    eventFrom: 'Web',
                    actionScope: 'property_card',
                    actionName: 'Property Card',
                    actionTarget: 'Clicked Contact Agent',
                    actionReference: 'property',
                    ...getPropertyData(properties),
                  })
                }}
                underline="none"
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  className={classes.priceText}
                  align={'left'}
                  variant="h6"
                  component="p"
                >
                  {'Contact Agent'}
                </Typography>
              </Link>
            )
          ) : (
            properties?.propertyStatus !== 'rental' &&
            properties?.propertyStatus !== 'leased' &&
            (properties?.soldDetails?.displaySoldPrice === 'yes' &&
            properties?.soldDetails?.soldPrice ? (
              <Typography
                className={classes.priceText}
                align={'left'}
                variant="h6"
                component="p"
              >
                {'$' + numberWithCommas(properties?.soldDetails?.soldPrice)}
              </Typography>
            ) : properties.propertyStatus === 'sold' ? (
              <Link
                onClick={() => {
                  selectProperty(true)
                  triggerAnalyticsEvent({
                    event: 'property_actions',
                    eventFrom: 'Web',
                    actionScope: 'property_card',
                    actionName: 'Property Card',
                    actionTarget: 'Clicked Contact Agent',
                    actionReference: 'property',
                    ...getPropertyData(properties),
                  })
                }}
                underline="none"
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  className={classes.priceText}
                  align={'left'}
                  variant="h6"
                  component="p"
                >
                  {'Contact Agent'}
                </Typography>
              </Link>
            ) : properties?.propertyStatus !== 'rental' &&
              properties?.propertyStatus !== 'leased' ? (
              <Typography
                className={classes.priceText}
                align={'left'}
                variant="h6"
                component="p"
              >
                {properties.priceView?.toLowerCase()?.includes('contact agent') ? (
                  <>
                    <Link
                      onClick={() => {
                        selectProperty(true)
                        triggerAnalyticsEvent({
                          event: 'property_actions',
                          eventFrom: 'Web',
                          actionScope: 'property_card',
                          actionName: 'Property Card',
                          actionTarget: 'Clicked Contact Agent',
                          actionReference: 'property',
                          ...getPropertyData(properties),
                        })
                      }}
                      underline="none"
                      style={{ cursor: 'pointer' }}
                    >
                      <Typography
                        className={classes.priceText}
                        align={'left'}
                        variant="h6"
                        component="span"
                      >
                        {'Contact Agent'}
                      </Typography>
                    </Link>
                  </>
                ) : (
                  properties.priceView || formatUSD(properties.price)
                )}
              </Typography>
            ) : null)
          )}

          {properties?.rentalPrice &&
          (properties?.propertyStatus === 'rental' ||
            properties?.propertyStatus === 'leased') &&
          properties?.priceView ? (
            <Typography
              className={classes.priceText}
              align={'left'}
              variant="h6"
              component="p"
            >
              {/* {'$' + properties.rentalPrice + ' ' + (properties.rentalPaymentFrequency ? properties.rentalPaymentFrequency : '') } */}
              {properties.priceView
                ? properties.priceView
                : '$' +
                  properties.rentalPrice +
                  ' ' +
                  (properties.rentalPaymentFrequency
                    ? properties.rentalPaymentFrequency
                    : '')}
            </Typography>
          ) : null}

          {properties &&
          properties.rentalPrice &&
          properties.propertyStatus === 'On-Sale' ? (
            <Typography
              className={classes.priceText}
              align={'left'}
              variant="h6"
              component="p"
            >
              {properties.priceView
                ? properties.priceView
                : '$' +
                  properties.rentalPrice +
                  ' ' +
                  (properties.rentalPaymentFrequency
                    ? properties.rentalPaymentFrequency
                    : '')}
            </Typography>
          ) : null}
          {/* {(properties.propertyStatus === 'rental' ||
            properties.propertyStatus === 'leased') &&
            properties.inspectionDate && (
              <Typography
                className={classes.dateText}
                align={'left'}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Inspection Date ' +
                  moment(properties.inspectionDate).format('ddd Do MMMM YYYY')}
              </Typography>
            )} */}
          {properties.propertyStatus === 'sold' && properties.soldDate && (
            <Typography
              className={classes.dateText}
              align={'left'}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {'Sold on ' + moment(properties.soldDate).format('ddd Do MMMM YYYY')}
            </Typography>
          )}
          {/* {properties.propertyStatus !== 'sold' &&
            properties.propertyStatus !== 'rental' &&
            properties.propertyStatus !== 'leased' &&
            properties.propertyStatus !== 'comingsoon' &&
            properties.propertyStatus !== 'offmarket' &&
            properties.auctionDate && (
              <Typography
                className={classes.dateText}
                align={'left'}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Auction ' + moment(properties.auctionDate).format('ddd Do MMMM YYYY')}
              </Typography>
            )} */}

          {properties.propertyStatus !== 'comingsoon' &&
          properties.propertyStatus !== 'offmarket' &&
          properties.addressText ? (
            <Typography
              className={classes.addressText}
              align={'left'}
              variant="body2"
              component="p"
              dangerouslySetInnerHTML={{
                __html:
                  properties.addressText &&
                  properties.addressText.replace(addressRegex, ''),
              }}
            >
              {/* {properties.addressText && properties.addressText.replace(addressRegex, '')} */}
            </Typography>
          ) : (
            <div>
              <Typography
                className={classes.addressText}
                align={'left'}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {properties.propertyStatus === 'comingsoon'
                  ? properties?.addressText
                  : properties.addressSuburb}
              </Typography>
              <div
                className={classes.emailContainer}
                onClick={() => {
                  selectProperty(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                <MailIcon className={classes.emailIcon} />
                <Typography
                  align={'left'}
                  className={classes.emailText}
                  variant="caption"
                  component="p"
                >
                  {'Email Agent for more details'}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  const getListCoefficients = (data) => {
    let arr = []
    if (data) {
      if (data.coefficients) {
        const selectedProperty = data.coefficients
        if (props.filters && selectedProperty) {
          props.filters.filters.advanceProperty.map((item, index) => {
            if (item.active) {
              if (selectedProperty[item.key] >= 0.7) {
                arr.push(item.label)
              }
            }
          })
        }
      }
    }
    return arr
  }

  function renderInfoPropertyScreen() {
    const rentalStatusArr = ['leased', 'rental']

    // const auctionInspectionDates = getAllPendingAuctionInspections(properties)

    return (
      <div>
        <div className={classes.rootProperty} style={{ flexDirection: 'column' }}>
          <Grid
            container
            style={{
              marginLeft: '-12px',
              flexDirection: 'column',
            }}
          >
            {properties.propertyType?.toLowerCase() === 'land' ||
            properties.reaPropertyType?.toLowerCase() === 'land' ? null : (
              <Grid
                item
                xs={12}
                lg={8}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <Tooltip title="Bedrooms">
                    <IconButton
                      style={{ background: 'none', cursor: 'auto' }}
                      onClick={() => ''}
                      disableRipple
                    >
                      <BedIcon className={classes.icon} />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                        style={{ paddingLeft: 5 }}
                      >
                        {properties.bedrooms}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Bathrooms">
                    <IconButton
                      style={{ background: 'none', cursor: 'auto' }}
                      onClick={() => ''}
                      disableRipple
                    >
                      <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                        style={{ paddingLeft: 5 }}
                      >
                        {properties.bathrooms}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Parking">
                    <IconButton
                      style={{ background: 'none', cursor: 'auto' }}
                      onClick={() => ''}
                      disableRipple
                    >
                      <ParkingIcon className={classes.icon} />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                        style={{ paddingLeft: 5 }}
                      >
                        {properties?.totalParking ? properties?.totalParking : 0}
                      </Typography>
                    </IconButton>
                  </Tooltip>
                </div>
                <Box position="relative">
                  {showModalBottomOptions && <ShareContentModal />}
                  <Tooltip title="Share">
                    <Button
                      variant={'outlined'}
                      color={'primary'}
                      className={classes.cta}
                      onClick={() => setShowModalBottomOptions(!showModalBottomOptions)}
                      style={{ marginLeft: 75 }}
                    >
                      Share&nbsp;&nbsp;
                      <DynamicIcons icon="ShareTwoTone" />
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            )}
            {/* <Grid item xs={6} sm={7} style={{ paddingLeft: 78 }}>
              <Button
                variant="outlined"
                color="primary"
                fontSize="large"
                className={classes.cta}
                onClick={() => executeScrollPriceGuide()}
              >
                View Agent Price Guide
              </Button>
            </Grid> */}
            {/* <Box marginLeft="10px" color="#9B9B9B">
              {auctionInspectionDates.inspections?.length > 0 && (
                <>
                  {auctionInspectionDates.inspections.length === 1 ? (
                    <Typography component="p" variant="body2" fontSize="12px">
                      Open for inspection {auctionInspectionDates.inspections[0]}
                    </Typography>
                  ) : (
                    <Typography component="p" variant="body2" fontSize="12px">
                      Open for inspection:
                    </Typography>
                  )}
                </>
              )}
              {auctionInspectionDates.inspections.length > 1 &&
                auctionInspectionDates.inspections.map(
                  (auctionInspectionDate, index) => (
                    <Typography
                      key={'auction-inspection-dates' + index}
                      component="p"
                      variant="body2"
                      fontSize="12px"
                    >
                      {auctionInspectionDate}
                    </Typography>
                  )
                )}
              {auctionInspectionDates.auction && (
                <Typography
                  component="p"
                  variant="body2"
                  fontSize="12px"
                  style={{ marginTop: '5px' }}
                >
                  Auction {auctionInspectionDates.auction}
                </Typography>
              )}
            </Box> */}
          </Grid>
          {properties.propertyStatus !== 'sold' && (
            <Grid item xs={6} sm={7}>
              {/*               <Typography
                className={classes.priceTextInner}
                align={'left'}
                variant="h6"
                component="p"
              >
                View Agent Price Guide
              </Typography> */}
              {/*               <div style={{ padding: '20px 20px 20px 0px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.cta}
                  href={
                    properties && properties.statementOfIntent
                      ? properties.statementOfIntent
                      : properties && properties.statementOfInformation
                      ? properties.statementOfInformation
                      : null
                  }
                  target="_blank"
                >
                  View Agent Price Guide
                </Button>
              </div> */}
            </Grid>
          )}
          {/* <div>
                        <IconButton style={{background:'none'}} onClick={()=> setShowModalBottomOptions(!showModalBottomOptions)}>
                            <SaveIcon className={classes.iconSave}/>
                        </IconButton>
                        <IconButton style={{background:'none'}} onClick={handleTooltip} style={{position: 'relative'}}>
                            <Badge badgeContent={matchCount > 0 ? matchCount : null} color="primary">
                                <DynamicIcons className={classes.icon} icon="2DCListIcon" />
                            </Badge>
                            {
                                open &&
                                <div className={classes.filterMain}>
                                    <div className={classes.triangle}></div>
                                    <div className={classes.empty}></div>
                                    <Typography variant="body2" className={classes.filterText} >
                                        {
                                            (getListCoefficients(properties).length > 0) ?
                                                getListCoefficients(properties).map((item, keyIndex) => {

                                                    if(keyIndex === 0) {
                                                        return item
                                                    } else {
                                                        return ', ' + item.replace('Close to', '').toLowerCase();
                                                    }
                                                })
                                            : 'No selected filter'
                                        }
                                    </Typography>
                                </div>
                            }
                        </IconButton>
                        <IconButton style={{background:'none', pointerEvents:'none'}} onClick={()=> ''}>
                            <Badge badgeContent={properties.views} color="primary">
                                <ViewIcon className={classes.icon}/>
                            </Badge>
                        </IconButton>
                    </div> */}
        </div>
        <div className={classes.infoContainer}>
          <Grid
            container
            spacing={0}
            style={{ justifyContent: 'space-between' }}
            className={classes.infoGrid}
          >
            <Grid item xs={12} sm={6} md={6} lg={8} className={classes.detailsColumn}>
              {properties &&
              properties.addressText &&
              properties.propertyStatus !== 'offmarket' ? (
                <Box
                  color="#010101"
                  sx={{ fontWeight: 'bold' }}
                  style={{ marginBottom: 20 }}
                >
                  <Typography
                    className={classes.addressTextInner}
                    align={'left'}
                    variant="h6"
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        htmlDecode(
                          properties.addressText.replace(/ *AU *$|AUS *$/gm, '')
                        )
                      ),
                    }}
                  >
                    {/* {properties.addressText.replace(/ *AU *$|AUS *$/gm, '')} */}
                  </Typography>
                </Box>
              ) : (
                <Box
                  color="#010101"
                  sx={{ fontWeight: 'bold' }}
                  style={{ marginBottom: 20 }}
                >
                  <Typography
                    className={classes.addressTextInner}
                    align={'left'}
                    variant="h6"
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(htmlDecode(properties.addressSuburb)),
                    }}
                  >
                    {/* {properties.addressSuburb} */}
                  </Typography>
                </Box>
              )}
              {properties &&
                !rentalStatusArr.includes(properties.propertyStatus) &&
                (properties?.soldDetails?.displaySoldPrice === 'yes' &&
                properties?.soldDetails?.soldPrice ? (
                  <>
                    <Typography
                      className={classes.priceTextInner}
                      align={'left'}
                      variant="h3"
                      component="p"
                      style={{ marginBottom: 20 }}
                    >
                      {'$' + numberWithCommas(properties?.soldDetails?.soldPrice)}
                    </Typography>
                  </>
                ) : properties.propertyStatus === 'sold' ? (
                  <Typography
                    className={classes.priceTextInner}
                    align={'left'}
                    variant="h3"
                    component="p"
                    style={{ marginBottom: 20 }}
                  >
                    {''}
                  </Typography>
                ) : (
                  <Typography
                    className={classes.priceTextInner}
                    align={'left'}
                    variant="h3"
                    component="p"
                    style={{ marginBottom: 20 }}
                    dangerouslySetInnerHTML={{
                      __html: properties.priceView
                        ? DOMPurify.sanitize(
                            htmlDecode(
                              properties.priceView !== 'Contact Agent'
                                ? properties.priceView.replace(/ *AU *$|AUS *$/gm, '')
                                : 'Contact Agent'
                            )
                          )
                        : 'Contact Agent',
                    }}
                  />
                ))}

              {properties &&
              properties.rentalPrice &&
              rentalStatusArr.includes(properties.propertyStatus) ? (
                <>
                  <Typography
                    className={classes.priceTextInner}
                    align={'left'}
                    variant="h3"
                    component="p"
                    style={{ marginBottom: 20 }}
                  >
                    {properties.priceView
                      ? properties.priceView
                      : '$' +
                        numberWithCommas(properties.rentalPrice) +
                        ' ' +
                        (properties.rentalPaymentFrequency
                          ? properties.rentalPaymentFrequency
                          : '')}
                  </Typography>
                  {properties &&
                    properties.rentDetails &&
                    properties?.rentDetails?.bond && (
                      <Typography
                        className={classes.rentalBond}
                        align={'left'}
                        variant="h3"
                        component="p"
                        style={{ marginBottom: 20 }}
                      >
                        {/* {'$' + properties.rentalPrice + ' ' + (properties.rentalPaymentFrequency ? properties.rentalPaymentFrequency : '') } */}
                        {'Bond $' + numberWithCommas(properties?.rentDetails?.bond)}
                      </Typography>
                    )}
                </>
              ) : null}
              {/* {listingType !== 'rent' && properties && properties.auctionDate && (
                <Typography
                  className={classes.dateText}
                  align={'left'}
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  {'Auction ' +
                    moment(properties.auctionDate).format('ddd Do MMMM YYYY')}
                </Typography>
              )}
              {listingType === 'rent' && properties.inspectionDate && (
                <Typography
                  className={classes.dateText}
                  align={'left'}
                  variant="caption"
                  color="textSecondary"
                  component="p"
                >
                  {'Inspection Date ' +
                    moment(properties.inspectionDate).format('ddd Do MMMM YYYY')}
                </Typography>
              )} */}
              {properties && properties.headline && (
                <Typography
                  className={`${classes.priceTextInner} ${classes.headlineTextInner}`}
                  align={'left'}
                  variant="h6"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      htmlDecode(properties.headline.replace(/ *AU *$|AUS *$/gm, ''))
                    ),
                  }}
                />
              )}

              {properties?.description && (
                <div className={classes.infoDescription}>
                  <ReadMore limit={true} description={properties.description} />
                </div>
              )}
            </Grid>
            {/* {
              <CardContainer className={props.isSticky ? 'is-sticky' : ''}>
                {properties && (
                  <AgentCards
                    agent={properties}
                    type={'agent-card'}
                    
                    sticky={true}
                    executeScroll={executeScroll}
                  />
                )}
              </CardContainer>
            } */}
          </Grid>
        </div>
      </div>
    )
  }

  const ShareContentModal = () => {
    const wrapperRef = useRef(null)

    useHandleOutsideClickCloseModal(wrapperRef, () => showOptionBottomModal(false))

    return (
      <div ref={wrapperRef} className={classes.shareContentModal}>
        <Paper style={{ padding: '10px' }} elevation={5}>
          <div>
            <Box
              paddingLeft="25px"
              alignItems="center"
              display="flex"
              flexDirection="row"
              color="#6A6A6A"
            >
              <UnfoldMoreIcon />
              <Typography
                className={classes.optionBottomHeaderText}
                variant="h6"
                component="h2"
              >
                {'Share Listing'}
              </Typography>
              <IconButton
                style={{ marginLeft: 'auto' }}
                onClick={() => showOptionBottomModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </div>
          <div>
            <ListOptions type={'share'} options={OPTION_MODAL} data={properties} />
          </div>
        </Paper>
      </div>
    )
  }

  return (
    <>
      {type === 'search' && renderInfoSearchScreen()}
      {type === 'property' && renderInfoPropertyScreen()}
      {/* <OptionModalBottom
        isOpen={showModalBottomOptions}
        showOptionBottomModal={showOptionBottomModal}
        content={renderShareContent()}
      /> */}
    </>
  )
}

export default withStyles(styles)(PropertyInfo)
