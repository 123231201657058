import React from 'react'

import AgencyCard from './AgencyCard'
import PropertyLoader from '../Property/PropertyLoader'

import { withStyles, Grid, Typography } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

/* const StyledText = withStyles({
    root: {
        textAlign:'left',
        color:'#000000',
        fontSize:'20px',
        lineHeight:'1.19',
        padding:'10px',
        zIndex: 10,
        position: 'relative',
    },
  })(Typography); */

const StyledSubText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '15px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
  },
})(Typography)

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  content: {
    margin: theme.spacing(2),
  },
  searchbarContainer: {
    padding: theme.spacing(1),
  },
  cardContent: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    width: '100%',
    padding: 10,
  },
  nullMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '40vh',
  },
  nullMessage: {
    textAlign: 'center',
    color: 'gray',
  },
})

const AgencyResults = (props) => {
  const { classes, agenciesData, executeTopScroll } = props
  const [routerChange] = useRouterChange()

  return (
    <>
      <div className={classes.cardContent}>
        {agenciesData && agenciesData.length > 0 && (
          <Grid container spacing={2}>
            {agenciesData &&
              agenciesData.map((value, index) => {
                return (
                  <AgencyCard key={index} agency={value} />
                )
              })}
          </Grid>
        )}
        {agenciesData && agenciesData.length === 0 && (
          <div className={classes.nullMessageContainer}>
            <StyledSubText className={classes.nullMessage} variant="h2">
              No result found
            </StyledSubText>
          </div>
        )}
      </div>
      {agenciesData && agenciesData.length > 4 && (
        <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
      )}
    </>
  )
}

export default withStyles(styles)(AgencyResults)
