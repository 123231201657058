import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CallRoundedIcon from '@material-ui/icons/CallRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

import { withStyles, Button } from '@material-ui/core';

const styles = theme => ({
    root : {
        display: "flex",
        justifyContent: 'center',
        marginTop: theme.spacing(-1.8),
        position: 'relative',
        zIndex: 6,
        paddingLeft: theme.spacing(2), 
        paddingRight: theme.spacing(2),
        '& .btn-cta' : {
            color: '#ffffff',
            margin: 0 + ' ' + theme.spacing(1) + 'px',
            width: '100%'

        }
    }
})

const ContactWrap = (props)=>{
    const {
        classes, 
    } = props;
    return (
        <div className={classes.root}>
            <Button 
                className="btn-cta" 
                variant="contained" 
                color="primary" 
                disableElevation 
                size="large" 
            >
                <EmailRoundedIcon className="btn-icons" /> Email
            </Button>
            
            <Button 
                className="btn-cta" 
                variant="contained" 
                color="primary" 
                disableElevation
                size="large" 
                href={'tel:123'}>
                <CallRoundedIcon className="btn-icons"/>Call
            </Button>
        </div>
    )
}

const mapStateToProps = state => {
    return {
      
    };
  };

const mapDispatchToProps = dispatch => {
   return bindActionCreators(
        {
          
        },
    dispatch
   );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ContactWrap));