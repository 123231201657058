import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Image from 'material-ui-image'

//import { Grid, withStyles, Typography, Button } from '@material-ui/core'

import {
  Paper,
  AppBar,
  Tabs,
  Tab,
  Container,
  Button,
  Grid,
  IconButton,
  withStyles,
  Typography,
} from '@material-ui/core'

import DynamicIcons from '../DynamicIcons'
import HeroImage from '../../../assets/images/hero/hero-image-v2.jpg'
import HomePageImage from '../../../assets/images/agent-3.jpg'

import Login from '../Form/Login'
import Register from '../Form/Register'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginTop: '10px',
    overflow: 'hidden',
    backgroundColor: '#6DC5A1',
  },
  formWrapper: {
    padding: '16px 16px 60px',
    backgroundColor: '#F5F5F5',
    minHeight: 'calc(100vh - 92px)',
    maxWidth: 500,
    margin: '20px',
    borderRadius: 20,
  },
  heroHeading: {
    marginTop: '50px',
    maxWidth: '300px',
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 55,
    lineHeight: '60.5px',
    color: '#FFFFFF',
    margin: '0 auto 20px',
    letterSpacing: '-0.78px',

    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
    },
  },
  heroLeadText: {
    maxWidth: '300px',
    color: '#FFFFFF',
    margin: '0 auto 20px',
    fontSize: 16,
  },
  heroLeftColumn: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
})

const TabHeader = withStyles({
  root: {
    justifyContent: 'space-evenly',
    padding: '0',
  },
})(Tabs)

const StyledTab = withStyles({
  root: {
    width: 'calc(50% - 10px)',
    margin: '0 auto',
    backgroundColor: '#F5F5F5',
  },
  selected: {
    color: '#000 !important',
    backgroundColor: '#fff',
  },
})(Tab)

const BackButton = withStyles({
  root: {
    width: '100px',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const ScrollContainer = withStyles({
  root: {
    // overflowY:'auto', overflowX:'hidden', maxHeight:'calc(90vh - 104px)', width:'100%'
  },
})(Container)

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .btn-sell {
    svg {
      transform-origin: center;
      transform: rotate(90deg);
      font-size: 16px !important;
    }
  }
  .btn-rent {
    svg {
      transform-origin: center;
      transform: rotate(90deg);
      font-size: 16px !important;
    }
  }
  .btn-property {
    .MuiSvgIcon-root {
      font-size: 14px !important;
    }
  }
  .btn-find-your-perfect-property {
    .MuiSvgIcon-root {
      font-size: 14px !important;
    }
    .MuiButton-label > span:first-child {
      width: 100%;
    }
  }
  .btn-trade {
    .MuiSvgIcon-root {
      font-size: 16px !important;
    }
  }

  .btn-find-hush-hush-properties {
    background-color: #5cc1a3;
    max-width: 300px;
    &:hover {
      background-color: #ffffff;
    }
    small {
      text-align: center !important;
    }

    .MuiButton-label > span:first-child {
      width: 100%;
    }
  }
`

const StyledButton = withStyles((theme) => ({
  root: {
    height: '41px',
    width: '100%',
    borderRadius: '19px',
    margin: '0 5px',
    padding: theme.spacing(2),
    color: theme.palette.textSecondary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiButton-label > span:first-child': {
      width: '100%',
    },
  },
  label: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
  },
}))(Button)

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
`

const StyledButtonYellow = withStyles((theme) => ({
  root: {
    height: '41px',
    borderRadius: '19px',
    margin: '0 30px 0px 50px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primaryYellow.main,
    color: '#000 !important',
    width: '100%',
    maxWidth: 300,
    '&.active': {
      backgroundColor: theme.palette.primaryYellow.main,
      color: '#000 !important',
    },
  },
  label: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
  },
}))(Button)

const DEFAULT_BUTTONS = [
  {
    name: 'Buy',
    icon: 'AttachMoney',
    path: '/buy',
    active: false,
  },
  {
    name: 'Rent',
    icon: 'VpnKey',
    path: '/rent',
    active: false,
  },
  {
    name: 'Sold',
    icon: 'Gavel',
    path: '/sold',
    active: false,
  },
  {
    name: 'Find Your Perfect Property',
    icon: 'ThumbUpAlt',
    path: '/perfect-property',
    active: false,
  },
  {
    name: 'Find Hush-Hush Properties',
    icon: 'WatchLater',
    path: '/hush-hush',
    active: false,
  },
]

const Hero = (props) => {
  const { classes, location, goBack } = props
  const [routerChange] = useRouterChange()
  const [homeButtons, setHomeButtons] = useState(DEFAULT_BUTTONS)
  const [page, setPage] = useState('/buy')

  const [value, setValue] = React.useState(0)

  useEffect(() => {
    if (!props.filterWebActive) {
      handleClick(null, null, 0)
    }
  }, [props.filterWebActive])

  const handleClick = (e, obj, index) => {
    if (e) {
      e.preventDefault()
    }
    if (obj) {
      props.toggleFilterWeb({ type: obj.path.replace('/', ''), status: true })
      setHomeButtons(
        homeButtons.map((item) =>
          item.name === obj.name
            ? { ...item, active: true }
            : { ...item, active: false }
        )
      )
      setPage(obj.path)

      if (index === 3) {
        localStorage.setItem('hideState', true)
      } else {
        localStorage.removeItem('hideState')
      }
      props.handlePage(obj.path)
    } else {
      setHomeButtons(DEFAULT_BUTTONS)
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)

    if (newValue === 0) {
      routerChange('/login')
    } else {
      routerChange('/signup')
    }
  }

  function renderForms() {
    return (
      <div className={classes.formWrapper}>
        <AppBar className={classes.appBar} position="static">
          <TabHeader
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            centered
            variant="fullWidth"
          >
            {location && location.path === '/login' ? (
              <StyledTab label="LOGIN" />
            ) : (
              <StyledTab
                label="LOGIN"
                onClick={() => {
                  //toggleSignUp(false, 0)
                  routerChange('/login')
                }}
              />
            )}
            {location && location.path === '/signup' ? (
              <StyledTab label="SIGN UP" />
            ) : (
              <StyledTab
                label="SIGN UP"
                onClick={() => {
                  //toggleSignUp(false, 1)
                  routerChange('/signup')
                }}
              />
            )}
          </TabHeader>
        </AppBar>
        <ScrollContainer className={classes.container}>
          {value === 0 ? (
            <Login tabChange={handleChange} changeRoute={routerChange} goBack={goBack} />
          ) : value === 1 ? (
            <Register tabChange={handleChange} />
          ) : null}
        </ScrollContainer>
      </div>
    )
  }
  return (
    <div className={classes.root}>
      <Grid container style={{ justifyContent: 'center' }}>
        <Grid
          item
          sm={12}
          lg={4}
          className={classes.heroLeftColumn}
          style={{ justifyContent: 'center' }}
        >
          <div>
            <Typography component={'h1'} className={classes.heroHeading}>
              <strong>find</strong> where you belong
            </Typography>
            {/*  {renderForms()} */}
            <Typography component={'h6'} className={classes.heroLeadText}>
              You’ll discover more homes if you prioritise lifestyle over location.
              Allow My Perfect Property to lead you further
            </Typography>
            <ButtonWrap>
              <ButtonRow style={{ marginBottom: 20 }}>
                <StyledButtonYellow
                  className={
                    homeButtons[3].active
                      ? 'active btn-hero btn-' +
                      homeButtons[3].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                      homeButtons[3].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<DynamicIcons icon={homeButtons[3].icon} />}
                  style={{
                    color: '#fff',
                    borderColor: '#EADF74',
                    paddingLeft: '1.250em',
                  }}
                  onClick={() => routerChange('/perfect-property/filters')}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[3].name}</span>
                </StyledButtonYellow>
              </ButtonRow>
              {/* <ButtonRow style={{ marginBottom: 20 }}>
                <StyledButton
                  className={
                    homeButtons[4].active
                      ? 'active btn-hero btn-' +
                        homeButtons[4].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                        homeButtons[4].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<DynamicIcons icon={homeButtons[4].icon} />}
                  style={{ textAlign: 'center', backgroundColor: '##5CC1A3' }}
                  onClick={() => routerChange('/hush-hush/filters')}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[4].name}</span>
                </StyledButton>
              </ButtonRow>
              <ButtonRow>
                <StyledButton
                  className={
                    homeButtons[0].active
                      ? 'active btn-hero btn-' +
                        homeButtons[0].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                        homeButtons[0].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<DynamicIcons icon={homeButtons[0].icon} />}
                  onClick={() => routerChange('/buy/filters')}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[0].name}</span>
                </StyledButton>
                <StyledButton
                  className={
                    homeButtons[1].active
                      ? 'active btn-hero btn-' +
                        homeButtons[1].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                        homeButtons[1].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<DynamicIcons icon={homeButtons[1].icon} />}
                  onClick={() => routerChange('/rent/filters')}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[1].name}</span>
                </StyledButton>
                <StyledButton
                  className={
                    homeButtons[2].active
                      ? 'active btn-hero btn-' +
                        homeButtons[2].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                        homeButtons[2].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<DynamicIcons icon={homeButtons[2].icon} />}
                  onClick={() => routerChange('/sold/filters')}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[2].name}</span>
                </StyledButton>
              </ButtonRow> */}
            </ButtonWrap>
          </div>
        </Grid>
      </Grid>
      <div>
        <Image
          src={HeroImage}
          aspectRatio={16 / 11}
          imageStyle={{ objectFit: 'cover' }}
        ></Image>
      </div>
    </div>
  )
}

export default withStyles(styles)(Hero)
