import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuperBreadCrumbs, AdminsLists } from 'components/Web/Superadmin';

import DashboardBanner from 'components/Web/Dashboard/DashboardBanner';
import dashBannerIMG from 'assets/images/agentbanner.png';
/** redux */
import { agencyv2Actions } from 'store/actions';

import { requestGETAPIAgency, requestDELETEAPIAgency, requestGETAPISuperadmin } from 'store/api';
import { getAdminStatus, getAdminErrors, getAllAdmins } from 'store/selectors/agencyv2.selectors';
import { getAgency } from 'store/selectors/superadmin.selectors';
import Loader from 'components/Web/Loader';

import { withStyles } from '@material-ui/core';
import { AdminsHeader } from 'components/Web/Superadmin';
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
    overflow: 'scroll',
    '& .MuiBackdrop-root': {
      maxWidth: 'initial !important',
      left: 0,
    },
  },
});

const SuperadminAgencyAdmins = (props) => {
  const { classes, history, match } = props;
  const [routerChange] = useRouterChange();
  const dispatch = useDispatch();

  /**
   * ? Maintain selector variable format to UPPERCASE to know distinction
   */
  const STATUS = useSelector(getAdminStatus);
  const ERROR = useSelector(getAdminErrors);

  const ADMINS = useSelector(getAllAdmins);
  const AGENCY = useSelector(getAgency);

  const [filteredAdmins, setFilteredAdmins] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const defaultNotification = { status: false, options: null };
  const defaultConfirmation = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [confirmation, setConfirmation] = useState(defaultConfirmation);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
    {
      type: 'secondary',
      label: 'Manage Agency Information',
      path: '/superadmin/manage-agencies/' + match?.params?.id,
    },
    {
      type: 'primary',
      label: 'Manage Admins',
      path: '',
    },
  ];

  window.onbeforeunload = (e) => {
    handleClearData();
  };

  useEffect(() => {
    handleClearData();
    handleGetAdmins();
    if (!AGENCY) {
      handleGetAgencyData();
    }

    return () => {
      handleClearData();
    };
  }, []);

  useEffect(() => {
    if (ADMINS?.entities) {
      const filtered = ADMINS.entities.filter((item, index) => item.AgencyAdminStateList !== 'NONE');
      setFilteredAdmins(filtered);
    }
    if (ADMINS) {
      setIsLoading(false);
    }
  }, [ADMINS]);

  useEffect(() => {
    if (STATUS && STATUS === 'agency/AGENCY_DELETE_ADMIN_SUCCESS') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Successfully deleted admin account.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
      handleCloseConfirmation();
      handleGetAdmins();
    }
    if (STATUS && STATUS === 'agency/AGENCY_DELETE_ADMIN_FAILED') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error deleting admin account.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
      handleCloseConfirmation();
    }
  }, [STATUS]);

  const handleConfirmDialog = (data) => {
    setConfirmation(data);
  };

  const handleCloseConfirmation = () => {
    setConfirmation(defaultConfirmation);
  };

  /**
   *? API Request */

  /**
   * Sample API Request via Redux Dispatcher
   * @param query leave null if no inline url query is required
   * @param data leave as empty obj as default
   * ? Always use dispatch for api calls
   */
  /*   const handlePostSample = (data) => {
      let request = {
        query: null,
        data: { ...data },
      }
      dispatch(requestPOSTAPIAgency(request, 'AGENCY_LOGIN'))
    } */

  const handleGetAdmins = (data) => {
    setIsLoading(true);
    if (match?.params?.id) {
      let request = {
        query: '?agencyReference=' + match?.params?.id,
        data: null,
      };
      dispatch(requestGETAPIAgency(request, 'AGENCY_GET_ALL_ADMINS'));
    } else {
      routerChange('/superadmin/login');
    }
  };

  const handleDeleteAdmin = () => {
    setIsLoading(true);
    const data = confirmation?.options?.data;
    if (AGENCY) {
      let request = {
        query: `/${data?._id}/delete`,
        data: null,
      };
      dispatch(requestDELETEAPIAgency(request, 'AGENCY_DELETE_ADMIN'));
    } else {
      routerChange('/superadmin/login');
    }
  };

  const handleGetAgencyData = (data) => {
    let request = {
      query: '/' + match.params.id,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_AGENCY_DATA'));
  };

  const handleClearData = () => {
    // dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
  };

  return (
    <div className={classes.root}>
      <Loader isLoading={isLoading} message={'Loading admins...'} />
      {/* <DashboardBanner title={'Manage Admins'} image={dashBannerIMG} /> */}
      <SuperBreadCrumbs section={'Manage Admins'} crumbsLinks={CRUMBS} />
      <AdminsHeader type={'superadmin'} match={match} />
      {!isLoading && (
        <AdminsLists
          type={'superadmin'}
          adminData={filteredAdmins}
          match={match}
          handleConfirmDialogOpen={handleConfirmDialog}
        />
      )}
      {confirmation.status && (
        <ConfirmDialog
          isOpen={confirmation.status}
          title={confirmation.options.title}
          text={confirmation.options.message}
          closeCallback={handleCloseConfirmation}
          confirmCallback={handleDeleteAdmin}
        />
      )}
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(SuperadminAgencyAdmins);
