import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
/** actions */
import { agencyv2Actions } from 'store/actions'

/** api redux request */
import { requestPOSTAPIAgency } from 'store/api'

import { getAgency, getStatus } from 'store/selectors/agencyv2.selectors'
import { SuperBreadCrumbs, BulkResults } from 'components/Web/Superadmin'

import { get } from 'lodash'
import AlertNotification from 'components/Web/Form/AlertNotification'
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
})

const AgencyBulkResults = (props) => {
  const { classes, history } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()
  const AGENCY = useSelector(getAgency)
  const STATUS = useSelector(getStatus)

  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }
  const [agentsArr, setAgentsArr] = useState([])

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/agency/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agents',
      path: '/agency/manage-agents',
    },
    {
      type: 'primary',
      label: 'Bulk Upload Agents',
      path: '',
    },
  ]

  useEffect(() => {
    getAgents()
  }, [props])

  useEffect(() => {
    if (STATUS === 'agency/AGENCY_ADD_AGENT_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Successfully added agents.',
          onClose: () => setTimeout(() => goBack(), 3000),
        },
      }
      setIsNotification(options)
    } else if (STATUS === 'agency/AGENCY_ADD_AGENT_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error adding agents. Please try again.',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    } else if (STATUS === 'agency/AGENCY_ADD_AGENT_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Adding agents...',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    }
  }, [STATUS])

  function goBack() {
    routerChange('back')
  }

  const getAgents = () => {
    if (props.location && props.location.state) {
      setAgentsArr(get(props.location.state, 'agents'))
    }
  }

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'))
  }

  /*   const handleClearData = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
  }

  const handleClearNotices = () => {
    handleClearStatus()
    handleClearError()
    setIsNotification(defaultNotification)
  } */

  const handleNewAgent = (data) => {
    const params = {
      query: null,
      data: {
        reaAgencyId: AGENCY?.reaAgencyId,
        agentList: data,
      },
    }
    if (AGENCY) {
      dispatch(requestPOSTAPIAgency(params, 'AGENCY_ADD_AGENT'))
    } else {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error, unknown Agency ID. Please logout and log in again.',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    }
  }

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs
        section={'Bulk Upload Agents'}
        crumbsLinks={CRUMBS}

      />
      <BulkResults goBack={goBack} submitCallback={handleNewAgent} agents={agentsArr} />
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  )
}
export default withStyles(styles)(AgencyBulkResults)
