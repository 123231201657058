import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from 'react-simple-snackbar'

import AgentLanding from "../../components/Web/Agent";

/** actions */
import {
  setAgentSearchParams,
  clearAgentSearchParams,
} from '../../store/actions';

/** api redux request */
import {
  requestAgentAgencyData
} from "../../store/api";

import Agent1 from '../../assets/images/agent-1.jpg';
import Agent2 from '../../assets/images/agent-2.jpg';
import Agent3 from '../../assets/images/agent-3.jpg';
import Agent4 from '../../assets/images/agent-4.jpg';
import { useRouterChange } from "utils/router.utils";

const AgentSearch = props => {
  const [routerChange] = useRouterChange()
  const [currentBG, setCurrentBG] = React.useState(Agent1)
  const [agencies, setAgencies] = React.useState(null)
  const [agents, setAgents] = React.useState(null)

  React.useEffect(() => {
    setCurrentBG(randomizeBackground())

    if (props.agent.agencies === null && props.agent.agents === null) {
      apiRequestAllAgents();
    }

    props.clearAgentSearchParams();

    localStorage.removeItem('hideState');
    routerChange('/agent/dashboard');
  }, [])

  React.useEffect(() => {
    if (agencies === null && agents === null) {
      setAgencies(props.agent.agencies)
      setAgents(props.agent.agents)
    }
  }, [props.agent])

  /** Search Property */
  async function apiRequestSearchAgent(params) {
    let request = params && params.length > 0 && params[0];
    switch (request.type) {
      case 'suburb':
        routerChange('/agent/results');
        props.setAgentAgencyParams(request);
        break;
      case 'agency':
        routerChange('/agency/results/' + request.id);
        props.setAgentAgencyParams(request);
        break;
      case 'agent':
        routerChange('/agent/results/' + request.id);
        props.setAgentAgencyParams(request);
        break;
      default:
        routerChange('/agent/results');
        props.setAgentAgencyParams(request);
        break;
    }
  }

  /** Get all agents list */
  async function apiRequestAllAgents() {
    props.requestAllAgentsAgency();
  }

  function randomizeBackground() {
    let randomBgKey = Math.floor(Math.random() * 3);
    switch (randomBgKey) {
      case 0:
        return Agent1;
      case 1:
        return Agent2;
      case 2:
        return Agent3;
      case 3:
        return Agent4;
      default:
        return Agent1;
    }
  }

  return (
    <div style={{
      height: '75vh',
      display: "flex",
      flexDirection: 'column',
      alignItems: "center",
      justifyContent: "center",
    }}>
      <AgentLanding
        imageBG={currentBG}
        agents={agents}
        agencies={agencies}
        clearAgentSearchParams={props.clearAgentSearchParams}

        apiRequestSearchAgent={apiRequestSearchAgent} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    agent: state.agents
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setAgentAgencyParams: (params) => setAgentSearchParams(params),
      clearAgentSearchParams: () => clearAgentSearchParams(),
      requestAllAgentsAgency: params => requestAgentAgencyData(params),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AgentSearch));
