import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shouldPureComponentUpdate from 'react-pure-render/function';
import DynamicIcons from "../DynamicIcons";

import GoogleMap from 'google-map-react';
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'

//const Marker = ({ content, details }) => <div>{content}{details.propertyStatus}</div>;

const Marker = props => {
  const { content, details, active } = props;
  //console.log(active, 'active')

  return (
    <div>{content}</div>
  )
}

const apiIsLoaded = (map, maps, offmarkets, instance) => {
  /* const circle = new maps.Circle({
    strokeColor: "#ffffff",
    strokeOpacity: 0,
    strokeWeight: 0,
    fillColor: "#000000",
    fillOpacity: 0.2,
    map,
    center: center,
    radius: radius
  }); */


  // console.log(map, 'MAP')

  map.addListener('dragend', function () {
    const items = [];

    offmarkets.map((item, index) => {
      // console.log(item, map.getBounds().contains({lat: item.lat, lng: item.lng}), "Off Market item")
      if (map.getBounds().contains({ lat: item.lat, lng: item.lng })) {
        //instance._setVisibleOffmarkets(item);
        items.push(item.details)
      }
    })

    instance._setVisibleOffmarkets(items)
  });

  map.addListener('zoom_changed', function () {
    maps.event.trigger(this, 'dragend')
  })


  //center map
  map.fitBounds(map.getBounds());
  map.setZoom(14)

};

const getMapOptions = (maps, type) => {
  // console.log(maps, 'MAPS')
  return {
    panControl: false,
    mapTypeControl: false,
    mapTypeId: type ? 'satellite' : 'roadmap',
    fullscreenControl: false,
    zoomControl: false,
    clickableIcons: false
  }
}



export default class PropertySearchMapView extends Component {
  static propTypes = {
    data: PropTypes.object,
    onCenterChange: PropTypes.func,
    activePropertySetter: PropTypes.func,
    offMarketToggleSetter: PropTypes.func,
    visibleOffmarketItems: PropTypes.func
  };

  static defaultProps = {
    data: {
      map: {
        coordinates: [-37.796300, 144.925120],
        center: { lat: -37.796300, lng: 144.925120 },
        comingsoon: [{ lat: -37.796300, lng: 144.925120 }],
        offmarket: [],
        current: [],
        rental: [],
        sold: [],
        zoom: 17
      },
      address: '108/62 Altona Street, Kensington',
      radius: 600
    }
  };

  shouldComponentUpdate = shouldPureComponentUpdate;


  constructor(props) {
    super(props);

    this.state = {
      center: [-37.796300, 144.925120],
      active: null,
      visibleOffmarkets: null
    };

    this.mapRef = React.createRef()
  }

  _setVisibleOffmarkets = (items) => {
    this.setState((state, props) => {
      return { ...state, visibleOffmarkets: items };
    });

    this.props.visibleOffmarketItems(this.state.visibleOffmarkets);
  }

  _onChildClick = (key, childProps) => {
    if (key !== this.state.active) {
      this.setState((state, props) => {
        return { ...state, active: key };
      });
      this.props.activePropertySetter(childProps.details)
    } else {
      this.props.activePropertySetter(null)
    }
    if (childProps.details.propertyStatus !== 'offmarket') {
      this.props.offMarketToggleSetter(false)
    }


    // console.log('MARKER DETAILS', childProps, key, this.state.center)
  }

  _onClick = (props) => {
    this.setState((state, props) => {
      return { ...state, active: null };
    });
    this.props.activePropertySetter(null)
    // console.log(props, 'Non marker event')
  }


  _onDragEnd = (props) => {
    //console.log(props)
    //console.log('Map Ref', this.mapRef.current, this.props.data.map.offmarket)
  }

  _onChildMouseEnter = (key, childProps) => {
    // console.log('Entry', key)
  }

  _onChildMouseLeave = (key, childProps) => {
    // console.log('Leave', key)
  }

  render() {
    // console.log(this.props.data, "MAPDATA")
    return (

      <GoogleMap
        apiKey={GOOGLE_MAP_API_KEY}
        ref={this.mapRef}
        options={({ map, maps }) => getMapOptions(maps, this.props.data.map.maptype)}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, this.props.data.map.offmarket, this)}
        center={this.props.data.map.coordinates ? this.props.data.map.coordinates : this.state.center}
        zoom={this.props.data.map.zoom}
        onClick={this._onClick}
        onDragEnd={this._onDragEnd}
        onChildClick={this._onChildClick}
        onChildMouseEnter={this._onChildMouseEnter}
        onChildMouseLeave={this._onChildMouseLeave}
        hoverDistance={40}
      >

        {/*    { this.props.data.map.center &&            
              <Marker {...this.props.data.map.center} 
                content={<DynamicIcons viewBox="0 0 24 28"
                  style={{transform: 'translate(-30%, -70%)', width: '1.5em', height:'1.5em'}}
                  icon="2DCPropertyMapIcon" color="primary"/>
                }
              />
        }  */}

        {!this.props.data.filteroffmarket && this.props.data.map.comingsoon.length > 0 && this.props.data.map.comingsoon.map((comingsoon, idx) => {
          if (comingsoon.details.longitude && comingsoon.details.latitude) {
            return (
              <Marker {...comingsoon} key={comingsoon.details.property}
                content={<DynamicIcons viewBox="0 0 24 28"
                  style={{ transform: 'translate(-50%, -70%)', width: '1.5em', height: '1.5em', position: 'relative', zIndex: this.state.active === comingsoon.details.property && !this.props.data.filteroffmarket ? '1' : 'auto' }}
                  icon="2DCSaleDropInIcon" fill={this.state.active === comingsoon.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#000000'} stroke={this.state.active === comingsoon.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#000000'} secondaryfill="#ffffff" />
                }
              />
            )
          }
        })
        }
        {this.props.data.map.offmarket.length > 0 && this.props.data.map.offmarket.map((offmarket, idx) => {
          // if(offmarket.details.longitude && offmarket.details.latitude) {           
          return (
            <Marker {...offmarket} key={offmarket.details.property}
              content={<DynamicIcons viewBox="0 0 75 75"
                style={{ transform: 'translate(-50%, -70%)', width: '4.6rem', height: '4.6rem', position: 'relative', zIndex: this.state.active === offmarket.details.property && !this.props.data.filteroffmarket ? '1' : 'auto' }}
                icon="2DCMapCircleIcon" stroke={this.state.active === offmarket.details.property ? '#f50057' : '#35C0CA'}
              />
              }
            />
          )
          // }
        })
        }
        {!this.props.data.filteroffmarket && this.props.data.map.current.length > 0 && this.props.data.map.current.map((current, idx) => {
          if (current.details.longitude && current.details.latitude) {
            return (
              <Marker {...current} key={current.details.property}
                content={<DynamicIcons viewBox="0 0 24 28"
                  style={{ transform: 'translate(-50%, -70%)', width: '1.5em', height: '1.5em', position: 'relative', zIndex: this.state.active === current.details.property && !this.props.data.filteroffmarket ? '1' : 'auto' }}
                  icon="2DCSaleDropInIcon" fill={this.state.active === current.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#ffffff'} stroke={this.state.active === current.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#000000'} secondaryfill={this.state.active === current.details.property && !this.props.data.filteroffmarket ? '#ffffff' : '#000000'} />
                }
              />
            )
          }
        })
        }
        {/* { this.props.data.map.sold.length > 0 && this.props.data.map.sold.map((sold, idx) => {
            if(sold.details.longitude && sold.details.latitude) {             
              return (
                <Marker {...sold} key={sold.details.property}
                  content={ <DynamicIcons viewBox="0 0 24 28"
                    style={{transform: 'translate(-50%, -70%)', width: '1.5em', height:'1.5em', position: 'relative', zIndex: this.state.active === sold.details.property && !this.props.data.filteroffmarket ? '1' : 'auto'  }}
                    icon="2DCSaleDropInIcon" fill={this.state.active === sold.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#ffffff' }  stroke={this.state.active === sold.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#000000' } secondaryfill={this.state.active === sold.details.property && !this.props.data.filteroffmarket ? '#ffffff' : '#000000' } /> 
                  }
                />
              )
            }
          })
        }         
        { this.props.data.map.rental.length > 0 && this.props.data.map.rental.map((rental, idx) => {
            if(rental.details.longitude && rental.details.latitude) {             
              return (
                <Marker {...rental} key={rental.details.property}
                  content={ <DynamicIcons viewBox="0 0 24 28"
                    style={{transform: 'translate(-50%, -70%)', width: '1.5em', height:'1.5em', position: 'relative', zIndex: this.state.active === rental.details.property && !this.props.data.filteroffmarket ? '1' : 'auto'  }}
                    icon="2DCSaleDropInIcon" fill={this.state.active === rental.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#ffffff' }  stroke={this.state.active === rental.details.property && !this.props.data.filteroffmarket ? '#35C0CA' : '#000000' } secondaryfill={this.state.active === rental.details.property && !this.props.data.filteroffmarket ? '#ffffff' : '#000000' } /> 
                  }
                />
              )
            }
          })
        }       */}
      </GoogleMap>
    );
  }
}