import _ from "lodash";
import { PURGE } from "redux-persist";
import {
  GET_EMAIL_HISTORY_SUCCESS,
  GET_EMAIL_HISTORY_FAIL,
  GET_SINGLE_EMAIL_SUCCESS,
  GET_SINGLE_EMAIL_FAIL,
} from "../constants";

const INITIAL_STATE_LIST = {
  emailLogs: {
    ok: false,
    message: null,
    data: null,
  },
};

const emailHistoryReducer = (state = INITIAL_STATE_LIST, action) => {
  switch (action.type) {
    case GET_EMAIL_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_EMAIL_HISTORY_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const INITIAL_STATE_SINGLE = {
  data: {
    ok: false,
    message: null,
  },
};

export const getSingleEmailReducer = (state = INITIAL_STATE_SINGLE, action) => {
  switch (action.type) {
    case GET_SINGLE_EMAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.data,
        },
      };
    case GET_SINGLE_EMAIL_FAIL:
      return {
        ...state,
      };
    case PURGE:
      return INITIAL_STATE_SINGLE
    default:
      return state;
  }
};

export default emailHistoryReducer;
