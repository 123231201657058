import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

//import Searchbar from '../../components/Mobile/Searchbar'
import Filter from '../../components/Mobile/Filter'
import Heading from '../../components/Mobile/Filter/Heading'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'
import DynamicIcons from '../../components/Mobile/DynamicIcons'
import SearchbarFilter from '../../components/Common/GlobalFilters/SearchbarFilter'
import IncludeNearbySuburbs from '../../components/Common/GlobalFilters/IncludeNearbySuburbs'

/** api redux request */
import {
  getFilters,
  getReferenceFilters,
  getTypesCounters,
} from '../../store/selectors/filters.selectors'

/** actions */
import {
  setPropertySearchFilters,
  clearFilters,
  setPropertySuburbSearch,
  setIncludeNearby,
  setTypesCounters,
  setReferenceFilters,
  clearSuburbSearch,
  FiltersActions,
} from '../../store/actions'

/** Object Filters */
import { reform } from '../../utils/filters.utils'
import { formatRequest } from '../../utils/search.utils'

import { Typography, Button, withStyles, Box, Container } from '@material-ui/core'
import { scrollToRef } from 'components/Mobile/ScrollEvents'
import { useSearch } from 'utils/search/useSearch'
import useFilters from 'utils/customHooks/useFilters'
import { useRouterChange } from 'utils/router.utils'
import ClearFiltersButton from 'components/Common/GlobalFilters/ClearFiltersButton'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 56,

    [theme.breakpoints.down(300)]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  content: {
    height: '100%',
    paddingBottom: '30px',
  },
})

const StyledButton = withStyles({
  root: {
    borderRadius: '30px',
  },
})(Button)

const Header = styled.div`
  position: relative;
  padding: 10px 23px 15px;
`
const HeaderNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
`

const BackButton = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.073125px;
  display: flex;
  align-items: center;
  margin-left: -10px;
  cursor: pointer;
`

const ClearButton = styled.a`
  text-decoration: underline;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.073125px;
`

const ButtonWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 15px;
  box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-center: center;
  justify-content: center;
  z-index: 9;
`
const ValidationSearch = styled.div`
  position: absolute;
  bottom: -6%;
  left: 13%;
  border-radius: 3px;
  opacity: 0;
  background-color: rgb(17, 192, 201);
  padding: 10px;
  z-index: 9;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 0.3s ease;
  &.active {
    transform: translateY(0px);
    opacity: 1;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent rgb(17, 192, 201) transparent;
  }
  p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
`
const StyledBox = styled(Box)``

const FiltersSearch = (props) => {
  const { classes } = props
  const [routerChange] = useRouterChange()

  const [filter, setFilter] = useState(null)
  const [isClearFilter, setIsClearFilter] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [invalidSearch, setInvalidSearch] = useState(false)
  const [propertyTypesCount, setPropertyTypesCount] = useState(null)
  const [initiateSearch] = useSearch()
  const dispatch = useDispatch()

  const FILTERS = useSelector(getFilters)
  const REFERENCE_FILTERS = useSelector(getReferenceFilters)
  const TYPE_COUNTERS = useSelector(getTypesCounters)

  const setSearchFilters = (params) => dispatch(setPropertySearchFilters(params))
  const clearFiltersState = (params) => dispatch(clearFilters(params))
  const setPropertySearch = (params) => dispatch(setPropertySuburbSearch(params))
  const setIncludeNearbyFilter = (params) => dispatch(setIncludeNearby(params))
  const setTypesCount = (params) => dispatch(setTypesCounters(params))
  const saveReferenceFilters = (params, reference, cursor) =>
    dispatch(setReferenceFilters(params, reference, cursor))
  const clearPropertySuburb = () => dispatch(clearSuburbSearch())

  const topContainerRef = useRef()

  const executeTopScroll = (noTransition) => scrollToRef(topContainerRef, noTransition)

  const [getFilter] = useFilters()
  const SUBURBS_FILTER = getFilter('suburbs')

  React.useEffect(() => {
    if (FILTERS && FILTERS.suburbs) {
      setInvalidSearch(false)
    }
    if (SUBURBS_FILTER) {
      setInvalidSearch(false)
    }

    return () => {
      setIsLoading(false)
      setPropertyTypesCount(null)
    }
  }, [])

  function routeGoBack() {
    routerChange('back')
  }

  function saveFilters(params) {
    setSearchFilters(params)
  }

  function resetFilters() {
    clearPropertySuburb()
    clearFiltersState()
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  // scroll to top of searchbar if no suburbs were inputted
  useEffect(() => {
    if (invalidSearch) {
      executeTopScroll(false)
    }
  }, [invalidSearch])

  /* handle properties callback */

  useEffect(() => {
    if (
      FILTERS &&
      REFERENCE_FILTERS &&
      JSON.stringify(FILTERS) !== JSON.stringify(REFERENCE_FILTERS.filters)
    ) {
      setPropertyTypesCount(null)
    }

    if (REFERENCE_FILTERS && REFERENCE_FILTERS.scope !== getRoutePath()) {
      setPropertyTypesCount(null)
    }
  }, [FILTERS, REFERENCE_FILTERS])

  useEffect(() => {
    if (TYPE_COUNTERS) {
      setPropertyTypesCount(TYPE_COUNTERS)
    } else {
      setPropertyTypesCount(null)
    }
  }, [TYPE_COUNTERS])

  async function actionSetTypesCount(params) {
    if (params) {
      setTypesCount(params)
    }
  }

  async function actionSaveReferenceFilters(params, reference, cursor) {
    if (params && reference) {
      saveReferenceFilters(params, reference, cursor)
    }
  }

  /** Search Property */
  async function apiRequestSearchProperty(clear) {
    let path = getRoutePath()
    if (FILTERS?.suburbs.length) {
      setInvalidSearch(false)
      routerChange('/' + path)
    } else {
      setInvalidSearch(true)
    }
  }

  const handleFilter = (obj) => {
    if (obj) {
      setFilter(obj)
      if (JSON.stringify(obj) !== JSON.stringify(filter)) {
        saveFilters(obj)
      }
    }
  }

  const applyFilter = (status) => {
    if (invalidSearch) {
      executeTopScroll()
      return
    }

    let c = status === 'clear' ? true : null
    apiRequestSearchProperty(c)
  }

  const applyFilterV2 = () => {
    if (SUBURBS_FILTER.length) {
      setInvalidSearch(false)
      initiateSearch()
    } else {
      setInvalidSearch(true)
    }
  }
  const handleClearFilter = () => {
    dispatch(
      FiltersActions(
        null,
        'GENERAL_FILTERS',
        'CLEAR_ALL_EXCEPT_SEARCH_REFERENCE_AND_SUBURBS'
      )
    )
  }

  const validateFilter = (params) => {
    if (params.suburb.length > 0) {
      return true
    } else {
      return false
    }
  }

  const goBack = (e) => {
    e.preventDefault()
    routeGoBack()
    localStorage.removeItem('isOffMarket')
  }

  const clearSelectedFilters = (e) => {
    e.preventDefault()
    handleClearFilter()
  }
  const pagePath = getRoutePath()

  return (
    <StyledBox ref={topContainerRef}>
      <div className={classes.root}>
        <AlertNotification
          status={isNotification}
          options={notificationOptions}
          closeNotification={closeNotification}
        />
        <Loader isLoading={isLoading} />

        <Header>
          <HeaderNav>
            <BackButton onClick={goBack}>
              <DynamicIcons icon="ChevronLeft" /> GO BACK
            </BackButton>
          </HeaderNav>
          <Heading variant="h6" style={{ fontWeight: 400 }}>
            {localStorage.getItem('isOffMarket')
              ? 'Search Off Market Properties'
              : 'Filters'}
          </Heading>
        </Header>
        <div style={{ position: 'relative' }}>
          <Box
            onFocus={() => {
              setInvalidSearch(false)
            }}
            width="100%"
            paddingX="15px"
          >
            {/*             <Searchbar
              type={'filter'}
              showFilters={true}
              isClearFilter={isClearFilter}
              setPropertySearch={setPropertySearch}
              setIncludeNearbyFilter={setIncludeNearbyFilter}
              apiRequestSearchProperty={apiRequestSearchProperty}
              filters={FILTERS}
              isFiltered={null}
              showSearchBar={true}
              path={getRoutePath()}
            /> */}

            <Box display="flex" width="100%">
              <SearchbarFilter />
            </Box>
            <Box display="flex" justifyContent="space-between" marginLeft="10px">
              <IncludeNearbySuburbs />
              <Box marginTop="10px">
                <ClearFiltersButton
                  type={pagePath === 'rent' ? 'rent' : 'normal'}
                  onClick={clearSelectedFilters}
                  isSmallMobile={true}
                />
              </Box>
            </Box>
          </Box>

          <ValidationSearch className={invalidSearch ? 'active' : ''}>
            <p>Please add the location you are looking for</p>
          </ValidationSearch>
        </div>
        <div className={classes.content}>
          <Filter
            handleFilter={handleFilter}
            isClearFilter={isClearFilter}
            saveFilters={saveFilters}
            resetFilters={resetFilters}
            filters={FILTERS}
            path={pagePath}
            propertyTypesCount={propertyTypesCount}
          />
        </div>

        <ButtonWrap>
          <ClearFiltersButton
            type={pagePath === 'rent' ? 'rent' : 'normal'}
            onClick={clearSelectedFilters}
          />
          <StyledButton
            variant="contained"
            onClick={applyFilterV2}
            style={{
              backgroundColor: '#6DC5A1',
              marginLeft: '10px',
              textTransform: 'none',
              color: 'white',
            }}
          >
            Apply Filters
          </StyledButton>
        </ButtonWrap>
      </div>
    </StyledBox>
  )
}

export default withStyles(styles)(FiltersSearch)
