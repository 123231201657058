import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Paper, Typography, withStyles } from "@material-ui/core";
import { Add, CheckCircleRounded, Close } from "@material-ui/icons";
import clsx from "clsx";
import Dropzone, { useDropzone } from "react-dropzone";

const styles = (theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        height: "65vh",
        overflowY: "scroll",
        overflowX: "hidden",
        marginRight: 20,
        marginTop: 10,
    },
    title: {
        fontWeight: 300,
        fontSize: "16px",
        color: "rgba(0, 0, 0, 0.87)",
        textAlign: "left",
    },
    photodrop: {
        minWidth: 300,
        height: 150,
        padding: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        transition: 'all easeinout 0.3s'
    },
    addIconContainer: {
        width: 40,
        height: 40,
        backgroundColor: "#e0e0e0",
        borderRadius: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
    },
    addIcon: {
        width: 30,
        height: 30,
        color: "#706053",
    },
});

const AvatarDropzone = (props) => {
    const { classes, setPreview, confirmCallback } = props;
    const [currentFile, setCurrentFile] = useState(null)

    const onDropAccepted = (data) => {
        setCurrentFile(data)
        if (setPreview) {
            setPreview(data.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })))
        }
        confirmCallback(data)

    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({ onDropAccepted, accept: 'image/*', maxFiles: 1 });

    const focusedStyle = {
        borderColor: 'transparent',
        backgroundColor: '#f5f5f5'
    };

    const acceptStyle = {
        borderColor: '#35C0CA',
        backgroundColor: 'rgba(53, 192, 202,0.5)'
    };

    const rejectStyle = {
        backgroundColor: 'rgba(245, 0, 87, 0.5)',
        borderColor: '#f50057'
    };



    const style = useMemo(() => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (


        <Paper className={classes.photodrop} {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div className={classes.addIconContainer}>
                {!currentFile && !isDragAccept && !isDragReject &&
                    <Add className={classes.addIcon} />
                }
                {
                    isDragReject &&
                    <Close color="secondary" fontSize="large" />
                }
                {(currentFile || isDragAccept) && !isDragReject &&
                    <CheckCircleRounded color="primary" fontSize="large" />
                }
            </div>
            {currentFile && !isDragReject && currentFile[0]?.name &&
                <Typography className={classes.title} variant={"body2"}>
                    {currentFile[0].name}
                </Typography>}

            {!currentFile && !isDragAccept && !isDragReject &&
                <Typography className={classes.title} variant={"body2"}>
                    {"Click to upload or drag and drop"}
                </Typography>
            }
            {!currentFile && !isDragReject && !isDragAccept &&
                <Typography className={classes.title} variant={"body2"}>
                    {"PNG, JPG (Max 400px x 400px)"}
                </Typography>}
            {isDragReject &&
                <Typography className={classes.title} variant={"body2"}>
                    {"File not supported or invalid."}
                </Typography>
            }
            {isDragAccept &&
                <Typography className={classes.title} variant={"body2"}>
                    {"Release to confirm upload"}
                </Typography>
            }
        </Paper>


    );
};

AvatarDropzone.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(AvatarDropzone);
