import React, { useEffect, useState, Fragment, Component } from 'react'
import classNames from 'classnames'
import { bindActionCreators } from 'redux'

import { connect, useDispatch, useSelector } from 'react-redux'
import Header from '../../components/Mobile/Header'

import Sidebar from '../../components/Mobile/Sidebar'
import DialogContainer from '../../components/Mobile/DialogContainer'
import FloatingNavigationButton from '../../components/Mobile/FloatingNavigationButton'
import {
  toggleSignUp,
  clearPropertyPayload,
  clearLikedUnlikedProperties,
  mppActions,
  toggleMPPScroll,
} from '../../store/actions'

import Form from '../../components/Mobile/Form'
/** api redux request */
import {
  requestSendSearchTallies,
  requestSendLikesDislikesProperties,
  requestGetPropertySave,
} from '../../store/api'

/** Object Filters */
import { reform } from '../../utils/filters.utils'
import { getLikesDislikes, getMpp } from 'store/selectors/mpp.selectors'
import { requestGETAPIMpp, requestPUTAPIMpp } from 'store/api/mpp.api'

import { withStyles } from '@material-ui/core'
import { getPagination } from 'utils/search/selectors/selectors.search'
import { useRouterChange } from 'utils/router.utils'
import { useHistory } from 'react-router-dom'

const styles = (theme) => ({
  content: {
    marginTop: '74px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
  },
})

const MainLayout = (props) => {
  const {
    classes,
    children,
    authentication,
    property,
    settings,
    clearPropertyPayload,
    clearLikeAndUnlike,
    saveSearchTallies,
    saveLikesDislikes,
    toggleSignUp,
  } = props
  const [routerChange] = useRouterChange()
  const [open, setOpen] = useState(false)

  const history = useHistory()

  const GET_LIKES_DISLIKES = useSelector(getLikesDislikes)
  const GET_MPP = useSelector(getMpp)
  const PAGINATION = useSelector(getPagination)

  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)

  const getSavedProperties = () =>
    dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'))

  const handleSubmitLikesDislikes = () => {
    apiPutLikesDislikesProperties()
  }

  useEffect(() => {
    // Check if route is mpp and disable scroll if on mpp screen

    const path = history.location.pathname.split('/')
    const rootPath = path[path.length - 1]

    if (rootPath === 'perfect-property') {
      dispatch(toggleMPPScroll(true))
    } else {
      dispatch(toggleMPPScroll(false))
    }
  }, [history.location])

  useEffect(() => {
    if (
      authentication?.user_status === 'logged' &&
      GET_MPP.status === 'mpp/PUT_MPP_LIKES_DISLIKES_SUCCESS'
    ) {
      // clearPutLikesDislikes()
      setIsSaving(false)
    }
  }, [GET_MPP])

  const apiPutLikesDislikesProperties = () => {
    let request = {
      query: null,
      data: {
        propertyPreference: {
          ...GET_LIKES_DISLIKES,
          action: 'add',
        },
      },
    }
    if (
      (GET_LIKES_DISLIKES.likes.length > 0 || GET_LIKES_DISLIKES.dislikes.length > 0) &&
      !isSaving &&
      GET_MPP.status !== 'mpp/PUT_MPP_LIKES_DISLIKES_SUCCESS'
    ) {
      dispatch(requestPUTAPIMpp(request, 'PUT_MPP_LIKES_DISLIKES', 'REQUEST'))
      setIsSaving(true)
    }
  }

  // const clearPutLikesDislikes = () => {
  //   dispatch(mppActions(null, 'LOCAL_MPP_LIKES_DISLIKES', 'CLEAR'))
  // }

  useEffect(() => {
    /**
     * Clear Property Payload
     * */
    clearPropertyPayload()

    if (authentication && authentication.user_status === 'logged') {
      /**
       * Send Tallies API
       * -- if logged in
       * -- if navigation is no on search nor mpp
       * */
      if (
        children.props.match.path !== '/buy/:id' &&
        children.props.match.path !== '/perfect-property'
      ) {
        if (
          property.liked_properties.length > 0 ||
          property.unliked_properties.length > 0 ||
          property.liked_drill_down_properties.length > 0
        ) {
          apiSendLikesDislikesProperties()
          apiSearchTallies()

          clearLikeAndUnlike()
        }
      }
      if (children.props.match.path === '/') {
        clearLikeAndUnlike()
      }
    }
  }, [])

  useEffect(() => {
    if (authentication?.user_status === 'logged') {
      apiRequestGetSavedProperties()
      handleSubmitLikesDislikes()
    }
  }, [authentication])

  const handleToggleDrawer = () => {
    setOpen(!open)
  }

  /** GET Save Property API */
  async function apiRequestGetSavedProperties() {
    getSavedProperties()
  }

  function checkPath() {
    return children.props.match.path === '/trades' ||
      children.props.match.path === '/agent'
      ? 'LIGHT'
      : 'DARK'
  }

  /**
   * Send Tallies Properties */
  async function apiSearchTallies() {
    let properties = {
      likes: property.liked_properties,
      dislikes: property.unliked_properties,
      likes_drill_down: property.liked_drill_down_properties,
    }
    let params = {
      swipeTallies: await reform('Swipe_Tallies', properties),
      drillDownTallies: await reform('Drill_Down_Tallies', properties),
    }
    saveSearchTallies(params)
  }

  /**
   * Send Likes and Dislikes Properties */
  async function apiSendLikesDislikesProperties() {
    let params = {
      propertyPreference: {
        likes: await reform('Likes', property.liked_properties),
        dislikes: await reform('Dislikes', property.unliked_properties),
      },
    }
    saveLikesDislikes(params)
  }

  const routeChangeToggle = (path) => {
    routerChange(path)
    handleToggleDrawer()
  }

  const routeChangeBack = () => {
    routerChange('back')
  }

  const floatingNavReferences = [
    '/buy',
    '/buy/map-view',
    '/rent',
    '/rent/map-view',
    '/sold',
    '/sold/map-view',
    '/off-market',
    '/off-market/map-view',
    '/liked-properties',
    '/liked-properties/liked-properties-map',
    '/coming-soon',
    '/coming-soon/map-view',
    '/off-market',
    '/off-market/map-view',
    '/hush-hush',
    '/hush-hush/map-view',
  ]

  return (
    <Fragment>
      <div>
        {children.props.match.path !== '/agent/confirm-property/:target/:id' && (
          <Header
            handleToggleDrawer={handleToggleDrawer}
            type={checkPath()}
            currentRoute={children.props.match.path}
            auth={authentication.user_status}
          />
        )}
        <DialogContainer
          isOpen={settings.showSignUp}
          toggleSignUp={() => toggleSignUp(settings.showSignUp)}
          content={<Form type={'Authentication'} />}
        />
        {children &&
          children.props &&
          floatingNavReferences.includes(children.props.match.path) &&
          PAGINATION?.totalResults > 0 && (
            <FloatingNavigationButton
              match={children.props.match}
              routeChangeBack={routeChangeBack}
              mapPath={children.props.match.path + '/map-view'}
            />
          )}
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          {children}
        </main>
      </div>
      <Sidebar
        open={open}
        routerChange={routeChangeToggle}
        handleToggleDrawer={handleToggleDrawer}
        auth={authentication.user_status}
      />
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      clearPropertyPayload: () => clearPropertyPayload(),
      saveLikesDislikes: (params) => requestSendLikesDislikesProperties(params),
      saveSearchTallies: (params) => requestSendSearchTallies(params),
      clearLikeAndUnlike: () => clearLikedUnlikedProperties(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MainLayout))
