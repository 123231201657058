import { POST, FETCH, PUT, DELETE } from 'utils/api';
import { agencyv2Actions } from 'store/actions/agencyv2.actions';
import { apiEndpoints } from 'utils/api/endpoints';

const urlSetter = (params, actionMain) => {
  let URL = apiEndpoints[actionMain];
  if (params.query && params.query !== '') {
    URL = apiEndpoints[actionMain] + params.query;
  }
  return URL;
};

export function requestPOSTAPIAgency(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(agencyv2Actions(params, actionMain, 'REQUEST'));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyv2Actions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(agencyv2Actions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestGETAPIAgency(params, actionMain) {
  let URL = urlSetter(params, actionMain);

  return (dispatch) => {
    dispatch(agencyv2Actions(params, actionMain, 'REQUEST'));
    FETCH(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyv2Actions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(agencyv2Actions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestPUTAPIAgency(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(agencyv2Actions(params, actionMain, 'REQUEST'));
    PUT(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyv2Actions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(agencyv2Actions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestAgencyCreateProperty(params, actionMain) {
  let URL = urlSetter(params, actionMain);

  return (dispatch) => {
    dispatch(agencyv2Actions(params, actionMain, 'REQUEST'));
    POST(URL, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyv2Actions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(agencyv2Actions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestDELETEAPIAgency(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(agencyv2Actions(params, actionMain, 'REQUEST'));
    DELETE(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyv2Actions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(agencyv2Actions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestAgencySetPauseProperty(params, actionMain) {
  let URL = urlSetter(params, actionMain);

  return (dispatch) => {
    dispatch(agencyv2Actions(params, actionMain, 'REQUEST'));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyv2Actions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(agencyv2Actions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function sendAgentActivationEmail(params, actionMain) {
  let URL = urlSetter(params, actionMain);

  return (dispatch) => {
    dispatch(agencyv2Actions(params, actionMain, 'REQUEST'));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(agencyv2Actions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(agencyv2Actions(error.response, actionMain, 'FAILED'));
      });
  };
}
