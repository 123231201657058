import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import DynamicIcons from '../DynamicIcons'

import { Button, Link, Typography, withStyles } from '@material-ui/core';

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
  },
})(Button)

const StyledTypography = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#979797',
  },
})(Typography)

const StyledLink = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    textDecoration: 'underline',
  },
})(Link)

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`

const FromNavigationGroup = (props) => {
  const { type, disabled, setIsForgotPasswordView, apiResendCode, onClick, tabChange } =
    props

  const renderLoginType = () => {
    return (
      <div>
        <FlexRow>
          <StyledButton
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
            style={{ backgroundColor: '#35C0CA', color: '#000' }}
          >
            Continue
          </StyledButton>
          <StyledTypography>
            Don't have an account?&nbsp;
            <StyledLink
              href="#"
              onClick={(e) => {
                e.preventDefault()
                tabChange(e, 1)
              }}
            >
              Sign Up
            </StyledLink>
          </StyledTypography>
        </FlexRow>
        <StyledTypography>
          <Link
            style={{ color: '#979797' }}
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setIsForgotPasswordView(true)
            }}
          >
            Forgot your password?
          </Link>
        </StyledTypography>
      </div>
    )
  }

  const renderResetType = () => {
    return (
      <div>
        <FlexRow>
          <StyledButton
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
          >
            Reset password
          </StyledButton>
        </FlexRow>
        <StyledTypography>
          <Link
            style={{ color: '#979797' }}
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setIsForgotPasswordView(false)
            }}
          >
            <DynamicIcons icon="ChevronLeft" style={{ verticalAlign: 'middle' }} /> Go
            back to sign in
          </Link>
        </StyledTypography>
      </div>
    )
  }

  const renderChangePasswordType = () => {
    return (
      <div>
        <FlexRow>
          <StyledButton
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
          >
            Change password
          </StyledButton>
        </FlexRow>
        <StyledTypography>
          <Link
            style={{ color: '#979797' }}
            href="#"
            onClick={(e) => {
              e.preventDefault()
              setIsForgotPasswordView(false)
            }}
          >
            <DynamicIcons icon="ChevronLeft" style={{ verticalAlign: 'middle' }} /> Go
            back to sign in
          </Link>
        </StyledTypography>
      </div>
    )
  }

  const renderRegisterType = () => {
    return (
      <div>
        <FlexRow>
          <StyledButton
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
          >
            Continue
          </StyledButton>
          <StyledTypography>
            Already have an account?&nbsp;
            <StyledLink
              href="#"
              onClick={(e) => {
                e.preventDefault()
                tabChange(e, 0)
              }}
            >
              Log in
            </StyledLink>
          </StyledTypography>
        </FlexRow>
      </div>
    )
  }

  const renderAgencyRegisterType = () => {
    return (
      <div>
        <FlexRow>
          <StyledButton
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
          >
            Submit
          </StyledButton>
          <StyledTypography
            style={{ lineHeight: '1.4em', paddingLeft: 16, color: '#000000' }}
          >
            By submitting a listing feed, you agree to PropertyMate Terms of Use
          </StyledTypography>
        </FlexRow>
      </div>
    )
  }

  const renderRegisterCodeType = () => {
    return (
      <div>
        <FlexRow>
          <StyledButton
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={onClick}
          >
            Continue
          </StyledButton>
          <StyledTypography>
            Didn't receive the code?&nbsp;
            <StyledLink
              href="#"
              onClick={(e) => {
                e.preventDefault()
                //tabChange(e, 0);
                apiResendCode()
              }}
            >
              Click here
            </StyledLink>
          </StyledTypography>
        </FlexRow>
      </div>
    )
  }

  return (
    <div>
      {type === 'login' && renderLoginType()}

      {type === 'reset' && renderResetType()}

      {type === 'change password' && renderChangePasswordType()}

      {type === 'register' && renderRegisterType()}

      {type === 'register code' && renderRegisterCodeType()}

      {type === 'agencyRegister' && renderAgencyRegisterType()}
    </div>
  )
}

export default FromNavigationGroup
