import React, { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { scrollToRef } from '../../components/Mobile/ScrollEvents'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'
import DynamicIcons from '../../components/Web/DynamicIcons'
import SavedCards from '../../components/Web/SavedCards'
import PropertyLoader from '../../components/Web/Property/PropertyLoader'
import Crumbs from '../../components/Web/Header/Crumbs'
import ConfirmDialog from '../../components/Web/CustomDialogs/ConfirmDialog'

import {
  getUserDetails,
  requestGetAllSavedSearch,
  requestDeleteSavedSearchItem,
  requestPutSavedSearchItem,
  requestPropertySearch,
  requestPropertySearchSold,
  requestPropertySearchRentals,
} from '../../store/api'

import { setPropertySearchFilters, setPropertySuburbSearch } from '../../store/actions'

import { formatRequest } from '../../utils/search.utils'

import { withStyles, Typography, Button, Grid, Snackbar } from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    height: '100vh',
  },
  content: {
    margin: theme.spacing(4),
    backgroundColor: '#F5F5F5',
    height: 'auto',
    marginTop: 20,
    marginLeft: 146,
  },
  searchbarContainer: {
    padding: theme.spacing(1),
  },
  cardContent: {
    width: '100vw',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  nullMessageContainer: {
    marginTop: '40%',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nullMessage: {
    textAlign: 'center',
    color: 'gray',
  },
  crumbsWrap: {
    padding: '20px 0 16px 100px',
  },
  mainContainer: {
    //height: '100%',
    zIndex: 6,
    backgroundColor: '#f5f5f5',
    position: 'relative',
    borderRadius: 4,
    padding: '0 118px 0 55px',
  },
})

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '21px',
    lineHeight: '1.19',
    zIndex: 10,
    position: 'relative',
    fontFamily: 'Roboto !important',
    fontWeight: '500 !important',
    paddingLeft: 9,
  },
})(Typography)

const StyledLinkText = withStyles({
  root: {
    textAlign: 'right',
    color: '#000000',
    fontSize: '15px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
})(Typography)

const StyledSubText = withStyles({
  root: {
    textAlign: 'center',
    color: '#000000',
    fontSize: '15px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
  },
})(Typography)

const BackButton = withStyles({
  root: {},
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const TEMP = []

const Savedsearches = (props) => {
  const {
    classes,
    property,
    users,
    getUserDetails,
    getAllSavedSearch,
    deleteSavedSearchItem,
    putSavedSearchItem
  } = props
  const [routerChange] = useRouterChange()
  const topContainer = React.useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d)

  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [savedSearchData, setSavedSearchdata] = useState([])
  const [invalidSearch, setInvalidSearch] = useState(false)
  const [redirectPage, setRedirectPage] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [openReminder, setOpenReminder] = useState(false)
  const [deleteParams, setDeleteParams] = useState({})

  useEffect(() => {
    apiRequestAllSavedSearches()
    document.querySelector('#scroll-container').style.overflow = 'unset'

    // Get user details
    if (users?.user?._id) {
      getUserDetails(users?.user?._id)
      if (!users?.user?.propertyNotification) {
        setOpenReminder(true)
      }
    }
  }, [])

  useEffect(() => {
    /** success handler */
    if (property) {
      if (
        property.payload &&
        property.payload.data &&
        property.payload.data.ok &&
        isLoading
      ) {
        setIsLoading(false)
        if (redirectPage) {
          routerChange('/' + redirectPage)
        }
      }
    }
    if (property && property?.request?.previous === 'put_saved_search') {
      handleNotification(true, {
        message: 'Update successful',
        severity: 'success',
      })
      setIsLoading(false)
    }
    /** error hanlder */
    if (property && property.property_search_error) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }

    if (property && property.put_properties_error) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }
  }, [property])

  useEffect(() => {
    if (property) {
      if (
        property.saved_properties &&
        property.saved_properties.data &&
        property.saved_properties.data.data
      ) {
        setSavedSearchdata(property.saved_properties.data.data)
      }
      if (
        property.payload &&
        property.payload.data &&
        property.payload.data.message === 'Delete request successful'
      ) {
        apiRequestAllSavedSearches()
      }
    }
  }, [property])

  const routeChange = (path) => {
    routerChange(path)
  }

  const action = (
    <Button
      color="primary"
      size="small"
      onClick={(e) => {
        routeChange('/account-settings')
      }}
    >
      Settings
    </Button>
  );

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  function apiRequestAllSavedSearches() {
    getAllSavedSearch()
  }

  function apiRequestDeleteSavedSearch(params) {
    let request = {
      id: params,
    }
    setConfirmDelete(true)
    setDeleteParams(request)
  }

  function apiConfirmDeleteSavedSearch(data) {
    setConfirmDelete(data)
    deleteSavedSearchItem(deleteParams)
    setDeleteParams({})
  }

  function apiRequestPutSavedSearch(id, notify) {
    let request = {
      id: id,
      notifyUser: notify
    }

    putSavedSearchItem(request)
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }
  /** Search Property */
  let st
  async function apiRequestSearchProperty(data) {
    let path = data.searchType
    let params = await formatRequest(path, data.parameters)
    if (params && validateFilter(params)) {
      setInvalidSearch(false)
      setIsLoading(true)

      if (path === 'buy' || path === 'off-market') {
        props.searchBuyProperty(params)
      }
      if (path === 'sold') {
        props.searchSoldProperty(params)
      }
      if (path === 'rent') {
        props.searchRentProperty(params)
      }
      props.setPropertySearch(data.parameters.suburbs)
      props.setSearchFilters(data.parameters.filters)
      setRedirectPage(path)
    } else {
      setInvalidSearch(true)
      st = setTimeout(() => {
        setInvalidSearch(false)
        clearTimeout(st)
        st = null
      }, 2000)
    }
  }

  const validateFilter = (params) => {
    if (params.suburb.length > 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className={classes.root}>
      <div ref={topContainer} className={classes.topAnchor}></div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <div className={classes.mainContainer}>
        <div className={classes.crumbsWrap}>
          <Crumbs
            crumbsOption={{ label: 'Saved Searches', route: '/saved-searches' }}

          />
        </div>
      </div>

      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <StyledText variant="h2">Your Saved Searches</StyledText>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <StyledLinkText
              variant="h2"
              onClick={(e) => {
                routeChange('/account-settings')
              }}
            >
              Edit Email Notications
            </StyledLinkText>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {savedSearchData && savedSearchData.length > 0 ? (
            savedSearchData.map((value, index) => {
              return (
                <Grid key={index} item xs={6} sm={6} md={4} lg={3} xl={3}>
                  <SavedCards
                    value={value}
                    path={getRoutePath()}
                    apiRequestDeleteSavedSearch={apiRequestDeleteSavedSearch}
                    apiRequestPutSavedSearch={apiRequestPutSavedSearch}
                    apiRequestSearchProperty={apiRequestSearchProperty}
                  />
                </Grid>
              )
            })
          ) : (
            <div className={classes.nullMessageContainer}>
              <StyledSubText className={classes.nullMessage} variant="h2">
                No result found
              </StyledSubText>
            </div>
          )}
        </Grid>
      </div>
      <div>
        {savedSearchData.length > 0 && (
          <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
        )}
      </div>
      {confirmDelete && (
        <ConfirmDialog
          isOpen={confirmDelete}
          confirmCallback={() => apiConfirmDeleteSavedSearch(false)}
          closeCallback={() => setConfirmDelete(false)}
          align={'center'}
          title={'Delete Saved Search'}
          text={'Are you sure you want to delete this saved search?'}
        />
      )}
       { openReminder && (
          <Snackbar
            open={openReminder}
            onClose={setOpenReminder}
            message={"Your email notifications are currently turned off. To receive property updates, go to your "}
            action={action}
          />
        )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    users: state.users,
    settings: state.settings,
    property: state.property,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserDetails: (id) => getUserDetails(id),
      getAllSavedSearch: () => requestGetAllSavedSearch(),
      deleteSavedSearchItem: (params) => requestDeleteSavedSearchItem(params),
      putSavedSearchItem: (params) => requestPutSavedSearchItem(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      searchBuyProperty: (params) => requestPropertySearch(params),
      searchSoldProperty: (params) => requestPropertySearchSold(params),
      searchRentProperty: (params) => requestPropertySearchRentals(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Savedsearches))
