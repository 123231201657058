import React from "react";
import styled from "styled-components";
import { withStyles, Button } from '@material-ui/core';

const styles = (theme) => ({
  statementUpload: {
    display: "none",
  },
});

const StyledButton = withStyles((theme) => ({
  root: {
    borderRadius: 30,
    textTransform: "initial",
    fontWeight: "400",
    letterSpacing: "0.073125px",
    padding: theme.spacing(1, 2),
    minWidth: "118px",
    border: "1px solid #4D4D4D",
    backgroundColor: "#fff",
    color: "#EC0000",
    fontSize: 14,
    "& .MuiButton-label ": {
      maxWidth: 300,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "&:hover": {
      backgroundColor: "#f2f2f2",
    }
  },
}))(Button);

const ButtonWrap = styled.div`
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ButtonStyleFileUpload = (props) => {
  const { onChange, classes, label, disabled } = props;

  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(event, newValue);
    }
  };

  return (
    <ButtonWrap className="statementUpload">
      <input
        accept=".pdf,image/jpeg,image/png"
        className={classes.statementUpload}
        id="upload-statement-file"
        type="file"
        onChange={(e) => handleChange(e, e.target.value)}
      />
      <label htmlFor="upload-statement-file" className="statementUploadLabel">
        <StyledButton
          color="primary"
          variant="contained"
          disabled={disabled}
          component="span"
          style={props.style}
          disableElevation={props.disableElevation}
        >
          {label}
        </StyledButton>
      </label>
    </ButtonWrap>
  );
};

export default withStyles(styles)(ButtonStyleFileUpload);
