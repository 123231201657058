import React from "react";

import styled from "styled-components";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@material-ui/core';

const DialogContentTextWrap = styled(DialogContentText)`
  min-width: 400px;
  display: flex;
  flex-direction: column-reverse;
  p { margin: 0 0 30px; }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({ isOpen, content, handleClick }) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Mail Content"}</DialogTitle>
        <DialogContent>
          <DialogContentTextWrap
            id="alert-dialog-slide-description"
            className="content"
            dangerouslySetInnerHTML={{ __html: content }}
          ></DialogContentTextWrap>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClick(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialogSlide;
