import AgencyAgents from 'containers/Web/Agency/AgencyAgents'
import AgencyLogin from 'containers/Web/Agency/AgencyLogin'
import AgencyActivate from 'containers/Web/Agency/AgencyActivate'
import AgencyForgot from 'containers/Web/Agency/AgencyForgot'
import AgencySignUp from 'containers/Web/Agency/AgencySignUp'
import AgencyDetails from 'containers/Web/Agency/AgencyDetails'
import AgencyDashboardV2 from 'containers/Web/Agency/AgencyDashboardV2'
import AgencyAccountSettings from 'containers/Web/Agency/AgencyAccountSettings'
import AgencyVerification from 'containers/Web/Agency/AgencyVerification'
import AgencyEditAgent from 'containers/Web/Agency/AgencyEditAgent'
import AgencyAddAgent from 'containers/Web/Agency/AgencyAddAgent'
import AgencyBulkResults from 'containers/Web/Agency/AgencyBulkResults'
import ManageProperties from 'containers/Web/Agency/ManageProperties'
import EditProperty from 'containers/Web/Agency/EditProperty'
import AgencyAddProperty from 'containers/Web/Agency/AgencyAddProperty'
import CustomerPortal from 'containers/Web/Agency/CustomerPortal'
import ChangeRequest from 'containers/Web/Agency/ChangeRequest'
import InvoicePayment from 'containers/Web/InvoicePayment'
import AgencySignUpStatus from 'components/Common/Agency/AgencySignUpStatus'
import AgencyAdmins from 'containers/Web/Agency/AgencyAdmins'
import AddAdmin from 'containers/Web/Agency/AddAdmin'
import EditAdmin from 'containers/Web/Agency/EditAdmin'

let routes = [
  {
    path: '/agency/login',
    component: AgencyLogin,
  },
  {
    path: '/agency/activate/:token',
    component: AgencyActivate,
  },
  {
    path: '/agency/forgot-password',
    component: AgencyForgot,
  },
  {
    path: '/agency/dashboard',
    component: AgencyDashboardV2,
  },
  {
    path: '/agency/manage-agents',
    component: AgencyAgents,
  },
  {
    path: '/agency/manage-agents/:id/edit',
    component: AgencyEditAgent,
  },
  {
    path: '/agency/manage-agents/add-an-agent',
    component: AgencyAddAgent,
  },
  {
    path: '/agency/manage-agents/bulk-upload-agents',
    component: AgencyBulkResults,
  },
  {
    path: '/agency/manage-properties',
    component: ManageProperties,
  },
  {
    path: '/agency/add-property',
    component: AgencyAddProperty,
  },
  {
    path: '/agency/update-property/:id',
    component: EditProperty,
  },
  {
    path: '/agency/sign-up',
    component: AgencySignUp,
  },
  {
    path: '/agency/results/:id',
    component: AgencyDetails,
  },
  {
    path: '/agency/account-settings',
    component: AgencyAccountSettings,
  },
  {
    path: '/agency/verify-registration/:code/:email',
    component: AgencyVerification,
  },
  {
    path: '/agency/verify-registration',
    component: AgencyVerification,
  },
  {
    path: '/agency/billing',
    component: CustomerPortal,
  },
  {
    path: '/agency/change-request',
    component: ChangeRequest,
  },
  {
    path: '/agency/invoice',
    component: InvoicePayment,
  },
  {
    path: '/agency/registration/status',
    component: AgencySignUpStatus,
  },
  {
    path: '/agency/manage-admins',
    component: AgencyAdmins,
  },
  {
    path: '/agency/manage-admins/:id/edit',
    component: EditAdmin,
  },
  {
    path: '/agency/manage-admins/add-an-admin',
    component: AddAdmin,
  }
]

export default routes
