import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Button,
  withStyles,
  Checkbox,
  Menu,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import styled from 'styled-components'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { CheckBox } from '@material-ui/icons'
import SelectField from '../Form/SelectField'
import useFilters from 'utils/customHooks/useFilters'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { formatUSD } from 'utils/formatter/priceFormatter'
import { RENT_PRICE_MARKS, PRICE_MARKS } from 'constants/globalfilters'
import { useSearch } from 'utils/search/useSearch'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const useStyles = makeStyles((theme) => ({
  filterMainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxText: {
    fontSize: 10,
    fontWeight: 'normal',
    margin: '20px',
  },
  // Dropdowns
  selectDropdown: {
    border: '0px',

    '& .MuiSelect-icon': {
      right: '7px',
    },

    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: '0px',
    },

    '&::before': {
      border: '0px',
      '&:hover': {
        border: '0px',
      },
    },

    '&::after': {
      border: '0px',
    },

    [theme.breakpoints.down(1340)]: {
      fontSize: '12px',
    },
  },

  priceRangeContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '10px',

    [theme.breakpoints.down(1200)]: {
      gridGap: '5px',
    },
  },

  selectPaper: {
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#35C0CA',
    },
  },
  propertyTypeDropdown: {
    border: '0px',

    '& .MuiSelect-icon': {
      right: '10px',
    },

    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: '0px',
    },

    '&::before': {
      border: '0px',
      '&:hover': {
        border: '0px',
      },
    },

    '&::after': {
      border: '0px',
    },

    [theme.breakpoints.down(1340)]: {
      fontSize: '12px',
    },
  },

  priceRangeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}))

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
`

const StyledButtonBlue = withStyles((theme) => ({
  root: {
    height: '41px',
    borderRadius: '19px',
    margin: '0px 120px 0px 120px',
    padding: theme.spacing(3),
    backgroundColor: '#35C0CA',
    color: '#000 !important',
    width: '100%',
    maxWidth: 300,
    '&.active': {
      backgroundColor: '#35C0CA',
      color: '#000 !important',
    },
  },
  label: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
    color: '#FFFFFF',
  },
}))(Button)

const PRICE_RANGE = [
  50000, 75000, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 275000, 300000,
  325000, 350000, 375000, 400000, 425000, 450000, 475000, 500000, 550000, 600000,
  650000, 700000, 750000, 800000, 850000, 900000, 950000, 1000000, 1100000, 1200000,
  1300000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000, 2000000, 2250000,
  2500000, 2750000, 3000000, 3500000, 4000000, 4500000, 5000000, 6000000, 7000000,
  8000000, 9000000, 10000000, 11000000, 12000000, 13000000, 14000000, 15000000,
]

const RENT_PRICE_RANGE = [
  50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 425, 450,
  475, 500, 525, 550, 575, 600, 625, 650, 675, 700, 725, 750, 800, 850, 900, 950, 1000,
  1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2500, 3000, 3500, 4000,
  4500, 5000,
]

const convertPriceToScaledValue = (price, priceMarks) => {
  let index = 0

  for (index; index < priceMarks.length; index++) {
    // If price is equal to exact scale value
    if (price === priceMarks[index].scaledValue) {
      return priceMarks[index].value
    }

    if (price === priceMarks[index + 1].scaledValue) {
      return priceMarks[index + 1].value
    }

    // Find the 2 nearest scale for the price
    if (
      price > priceMarks[index].scaledValue &&
      price < priceMarks[index + 1].scaledValue
    ) {
      const scaleInterval = 25

      const pricePercentage =
        Math.abs(priceMarks[index].scaledValue - price) /
        (priceMarks[index + 1].scaledValue - priceMarks[index].scaledValue)

      const valueAddedToScale = scaleInterval * pricePercentage

      return priceMarks[index].value + valueAddedToScale
    }
  }
}

const calculatePriceOptions = (price, priceType, isRentPrice) => {
  if (isRentPrice) {
    return priceType === 'min'
      ? RENT_PRICE_RANGE.filter((priceOption) => priceOption < price)
      : RENT_PRICE_RANGE.filter((priceOption) => priceOption > price)
  }

  return priceType === 'min'
    ? PRICE_RANGE.filter((priceOption) => priceOption < price)
    : PRICE_RANGE.filter((priceOption) => priceOption > price)
}

const checkIfRentSearch = (searchButtons) => {
  return searchButtons.some((button) => button.active === true && button.id === 'rent')
}

// COMPONENTS-------------------------------------------------------------------------------------------
const PropertyTypeSelect = ({ options, containerStyle }) => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const PROPERTY_TYPES = getFilter('property_type')

  const activePropertyTypes = options.filter(
    (propertyType) => propertyType.active === true
  )

  const [propertyTypeAll, setPropertyTypeAll] = useState(
    () => options.length === activePropertyTypes.length
  )

  const handleAllClicked = (isAllClicked) => {
    const filters = PROPERTY_TYPES.map((filter) => {
      return { ...filter, active: isAllClicked }
    })

    setFilter({
      key: 'property_type',
      value: filters,
    })
    setPropertyTypeAll(isAllClicked)

    // if (isAllClicked) {
    //   setPropertyTypeAll(true)
    //   const filters = PROPERTY_TYPES.map((filter) => {
    //     return { ...filter, active: true }
    //   })

    //   setFilter({
    //     key: 'property_type',
    //     value: filters,
    //   })
    // } else {
    //   setPropertyTypeAll(false)
    //   const filters = PROPERTY_TYPES.map((filter) => {
    //     return { ...filter, active: false }
    //   })

    //   setFilter({
    //     key: 'property_type',
    //     value: filters,
    //   })
    // }
  }

  const handlePropertyTypeChange = (inputActivePropertyTypes) => {
    let changedFilter
    const currentlyClickedFilter =
      inputActivePropertyTypes[inputActivePropertyTypes.length - 1]

    // Check if clicked filter is all
    if (currentlyClickedFilter === 'all') {
      return !propertyTypeAll ? handleAllClicked(true) : handleAllClicked(false)
    }

    // If all is not clicked make clicked filter into active or not active
    changedFilter = PROPERTY_TYPES.filter(
      (filter) => filter.id === currentlyClickedFilter
    )[0]

    setFilter({
      key: 'property_type',
      value: { ...changedFilter, active: !changedFilter.active },
    })
  }

  const renderPropertyTypeText = (inputActivePropertyTypes) => {
    // If all property types are active
    if (propertyTypeAll) {
      return 'All'
    }

    // // For 1 active property types
    if (inputActivePropertyTypes.length === 1) {
      return inputActivePropertyTypes[0].title
    }

    // // For many active property types
    return `${
      inputActivePropertyTypes[0].title === 'Apartment & Unit'
        ? 'Apt. & Unit'
        : inputActivePropertyTypes[0].title
    }, ${inputActivePropertyTypes.length - 1}+`
  }

  useEffect(() => {
    if (activePropertyTypes.length === options.length) {
      setPropertyTypeAll(true)
    } else {
      setPropertyTypeAll(false)
    }
  }, [activePropertyTypes, options])

  return (
    <FormControl
      style={
        containerStyle ? { width: '350px', ...containerStyle } : { width: '350px' }
      }
      variant="standard"
    >
      <Select
        style={{
          backgroundColor: 'white',
          border: '0.5px solid black',
          borderRadius: '20px',
          padding: '3px 0px 3px 0px',
        }}
        className={classes.selectDropdown}
        value={activePropertyTypes ? activePropertyTypes : ''}
        onChange={(event) => {
          handlePropertyTypeChange(event.target.value)
        }}
        displayEmpty
        renderValue={(field) => {
          return field.length > 0 ? (
            <Box marginLeft="15px">{renderPropertyTypeText(field)}</Box>
          ) : (
            <Box marginLeft="15px">Property Types</Box>
          )
        }}
        MenuProps={{
          getContentAnchorEl: () => null,

          PaperProps: {
            className: classes.selectPaper,
            style: { marginTop: '40px', width: '350px', maxHeight: '250px' },
          },
        }}
        multiple
      >
        <MenuItem disabled value="">
          Property Types
        </MenuItem>
        <MenuItem
          key="all"
          value="all"
          style={{ backgroundColor: propertyTypeAll ? '#CCCCCC' : 'white' }}
        >
          All
        </MenuItem>
        {options.map((field) => (
          <MenuItem
            style={{ backgroundColor: field.active ? '#CCCCCC' : 'white' }}
            key={field.id}
            value={field.id}
          >
            {field.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const PriceSelect = ({
  value,
  onInputChange,
  options,
  containerStyle,
  label,
  name,
}) => {
  const classes = useStyles()

  return (
    <FormControl
      style={
        containerStyle ? { width: '350px', ...containerStyle } : { width: '350px' }
      }
      variant="standard"
    >
      <Select
        style={{
          backgroundColor: 'white',
          border: '0.5px solid black',
          borderRadius: '20px',
          padding: '3px 0px 3px 0px',
        }}
        className={classes.selectDropdown}
        value={value ? value : ''}
        onChange={onInputChange}
        displayEmpty
        renderValue={(field) => {
          return field ? (
            <Box marginLeft="15px">{formatUSD(field)}</Box>
          ) : (
            <Box marginLeft="15px">{label}</Box>
          )
        }}
        MenuProps={{
          getContentAnchorEl: () => null,
          PaperProps: {
            className: classes.selectPaper,
            style: { marginTop: '40px', width: '140px', maxHeight: '250px' },
          },
        }}
        name={name}
      >
        <MenuItem disabled value="">
          {label}
        </MenuItem>
        {options.map((field) => (
          <MenuItem key={field} value={field}>
            {formatUSD(field)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const HomeFilters = () => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const PROPERTY_TYPES = getFilter('property_type')
  const PRICE = getFilter('price')
  const RENT_PRICE = getFilter('price_rent')
  const INCLUDE_NEARBY_SUBURBS = getFilter('include_nearby_suburbs')
  const SEARCH_BUTTONS = getFilter('search_reference')
  const [initiateSearch] = useSearch()

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const activatedFilter = PROPERTY_TYPES.find(
    (propertyType) => propertyType.active === true
  )

  const isRentPrice = checkIfRentSearch(SEARCH_BUTTONS)

  const priceMinOptions = calculatePriceOptions(
    isRentPrice ? RENT_PRICE.max : PRICE.max,
    'min',
    isRentPrice ? true : false
  )
  const priceMaxOptions = calculatePriceOptions(
    isRentPrice ? RENT_PRICE.min : PRICE.min,
    'max',
    isRentPrice ? true : false
  )

  const handleRentPriceChange = (event) => {
    const scaledValue =
      event.target.name === 'min'
        ? [
            convertPriceToScaledValue(event.target.value, RENT_PRICE_MARKS),
            RENT_PRICE.scaled[1],
          ]
        : [
            RENT_PRICE.scaled[0],
            convertPriceToScaledValue(event.target.value, RENT_PRICE_MARKS),
          ]

    setFilter({
      key: 'price_rent',
      value: {
        ...RENT_PRICE,
        [event.target.name]: event.target.value,
        scaled: scaledValue,
      },
    })
  }

  const handlePropertyTypeChange = (event) => {
    const changedFilter = PROPERTY_TYPES.map((filter) => {
      if (filter.id === event.target.value) {
        return { ...filter, active: !filter.active }
      } else {
        return { ...filter, active: false }
      }
    })

    setFilter({
      key: 'property_type',
      value: changedFilter,
    })
  }

  const handlePriceChange = (event) => {
    const scaledValue =
      event.target.name === 'min'
        ? [convertPriceToScaledValue(event.target.value, PRICE_MARKS), PRICE?.scaled[1]]
        : [PRICE?.scaled[0], convertPriceToScaledValue(event.target.value, PRICE_MARKS)]

    setFilter({
      key: 'price',
      value: {
        ...PRICE,
        [event.target.name]: event.target.value,
        scaled: scaledValue,
      },
    })
  }

  return (
    <Box className={classes.filterMainContainer}>
      <FormControlLabel
        style={{ color: '#35C0CA', marginLeft: 20 }}
        control={
          <Checkbox
            labelstyle={{ color: 'black' }}
            iconstyle={{ fill: '#35C0CA' }}
            inputstyle={{ color: 'black' }}
            style={{ color: '#35C0CA' }}
            classes={{
              root: classes.checkboxContainer,
              checked: classes.checkedCheckbox,
            }}
            color="default"
            name="includeNearbySuburbs"
            checked={INCLUDE_NEARBY_SUBURBS}
            onChange={(event) => {
              setFilter({
                key: 'include_nearby_suburbs',
                value: !INCLUDE_NEARBY_SUBURBS,
              })

              triggerAnalyticsEvent({
                event: 'homepage_actions',
                eventFrom: 'Mobile',
                actionScope: 'homepage_filters',
                actionName: 'Homepage Filters',
                actionTarget: INCLUDE_NEARBY_SUBURBS
                  ? 'Including nearby suburbs'
                  : 'Not includes nearby suburbs',
                actionReference: 'homepage',
                actionData: !INCLUDE_NEARBY_SUBURBS,
              })
            }}
          />
        }
        label="Include nearby suburbs"
      />
      {/*  <Box display="flex" flexDirection="column" justifyContent="space-between">
        <PropertyTypeSelect
          options={PROPERTY_TYPES}
          value={activatedFilter}
          onInputChange={handlePropertyTypeChange}
        />
        <br />
        <Box className={classes.priceRangeContainer}>
          <PriceSelect
            value={isRentPrice ? RENT_PRICE.min : PRICE.min}
            name="min"
            options={priceMinOptions}
            label={'Price (min)'}
            onInputChange={isRentPrice ? handleRentPriceChange : handlePriceChange}
          />
          <br />
          <PriceSelect
            value={isRentPrice ? RENT_PRICE.max : PRICE.max}
            name="max"
            options={priceMaxOptions}
            label={'Price (max)'}
            onInputChange={isRentPrice ? handleRentPriceChange : handlePriceChange}
          />
        </Box>
        <br />
        <ButtonRow style={{ marginBottom: 20 }}>
          <StyledButtonBlue
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<SearchIcon />}
            style={{
              paddingLeft: '1.250em',
              borderRadius: '100px',
              paddingTop: '14px',
              paddingBottom: '14px',
            }}
            onClick={() => {
              initiateSearch()
            }}
          >
            <span style={{ textAlign: 'center' }}>{'Search'}</span>
          </StyledButtonBlue>
        </ButtonRow>
      </Box> */}
    </Box>
  )
}

export default HomeFilters
