import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { IconButton, Paper, Container, AppBar, Typography, Button, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DialogContainer from '../CustomDialogs/DialogContainer';
import DynamicIcons from '../DynamicIcons';
import ImageCapture from '../ImageCapture/ImageCapture';
import Image from '../Image';



const styles = theme => ({ 
    headerBar: {
        backgroundColor: theme.palette.light.main,
        boxShadow:'none'
    },
    modal: {        
        marginRight: 'auto',
        marginLeft: 'auto',        
        borderRadius: 0,
        position: 'relative',    
        '&:hover': {
        outline: 'none',
        },
        overflow: 'hidden',        
        height: '100vh'
    },
    modalContent: {
        padding: '0 !important',
        '& > div:first-child' : {
            width:'100% !important',

        },
        '& > div:first-child > video' : {
            width: '100% !important'
        }
    },
    ctaRounded: {
        borderRadius: 25,
        color: theme.palette.text.primary, 
        textTransform: 'initial',
        paddingLeft: theme.spacing(3.2),
        paddingRight: theme.spacing(3.2),
        marginLeft: theme.spacing(1.5),
        backgroundColor: theme.palette.common.white,
        '&.MuiButton-contained' : {
          backgroundColor: theme.palette.primary.main
        }
    },
    confirmActions: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(3),
        width: '100%',
        position: 'fixed',
        bottom: theme.spacing(5),
        left: 0
    },
    previewImage: {
        width: '100%',
        height: 'auto !important'
    },
    closeCapture: {
        position: 'fixed',
        top:theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 9999
    }
});

const CaptureSOI = props => {  
    const {
      classes,      
      isOpen, 
      closeCallback,
      confirmCallback,
      setFiles
    } = props;

    useEffect(()=>{
        return ()=>{
            setPreviewSrc(null)
            setShowImgCapture(true)
        }
    },[])
    const [showImgCapture, setShowImgCapture] = useState(true);
    const [previewSrc, setPreviewSrc] = useState(null)
    const onCapture = (imageData) => {
        setFiles(imageData.file);
        setPreviewSrc(imageData.webP)
        setShowImgCapture(false);
      };

    const onError = useCallback((error) => { console.log(error) }, []);

    const config = useMemo(() => ({
            video: {
                    facingMode: 'environment',
                    width: {
                    min: 1280,
                },
                    height: {
                    min: 720,
                }
            }
        }), []);
    /*
        { video: true } - Default Camera View
        { video: { facingMode: environment } } - Back Camera
        { video: { facingMode: "user" } } - Front Camera
    */

    const confirmation = ()=>{
        confirmCallback()
        setPreviewSrc(null)
        setShowImgCapture(true)
    }

    const cancelCaptured = ()=> {
        setPreviewSrc(null)
        setShowImgCapture(true)
    }

    return (                  
        <DialogContainer
                isOpen={isOpen}
                closeCallback={closeCallback}
            >
                <Paper className={classes.modal} elevation={5}>
                    <Container className={classes.modalContent}>
                    
                        
              
                    {showImgCapture && 
                        <ImageCapture
                            onCapture={onCapture}
                            onError={onError}                        
                            userMediaConfig={config}
                            className={classes.cameraCapture}
                        />
                    }
                    {previewSrc &&  
                        <Image
                            src={previewSrc}
                            className={classes.previewImage}
                        />
                    }

                    
                    { previewSrc &&                    
                        <div className={classes.confirmActions}>                            
                            <Button className={classes.ctaRounded}
                                variant={'text'}                                
                                disableElevation
                                onClick={ cancelCaptured }
                                >Cancel</Button>
                            <Button className={classes.ctaRounded}
                                variant={'contained'}
                                color={'primary'}
                                disableElevation
                                onClick={ confirmation }
                                >Confirm</Button>
                        </div>
                    }

                        <IconButton                           
                            aria-label="Upload Statement of Information"
                            component={'span'}
                            onClick={closeCallback}
                            className={classes.closeCapture}
                            
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Container>
                </Paper>
        </DialogContainer>  
    );
}

export default withStyles(styles)(CaptureSOI);