import { PURGE } from 'redux-persist'

import {
  REQUEST_SEARCH_AGENT,
  REQUEST_SEARCH_AGENT_SUCCESS,
  REQUEST_SEARCH_AGENT_FAIL,
  REQUEST_AGENT_AGENCY_DATA,
  REQUEST_AGENT_AGENCY_DATA_FAIL,
  REQUEST_AGENT_AGENCY_DATA_SUCCESS,
  REQUEST_AGENT_DETAILS,
  REQUEST_AGENT_DETAILS_SUCCESS,
  REQUEST_AGENT_DETAILS_FAIL,
  SET_SEARCH_AGENT_PARAMS,
  CLEAR_SEARCH_AGENT_PARAMS,
  REQUEST_GET_AGENT_PROPERTIES,
  REQUEST_GET_AGENT_PROPERTIES_SUCCESS,
  REQUEST_GET_AGENT_PROPERTIES_FAIL,
  REQUEST_AGENT_SET_PAUSE_PROPERTY,
  REQUEST_AGENT_SET_PAUSE_PROPERTY_SUCCESS,
  REQUEST_AGENT_SET_PAUSE_PROPERTY_FAIL,
  REQUEST_CREATE_PROPERTY,
  REQUEST_CREATE_PROPERTY_SUCCESS,
  REQUEST_CREATE_PROPERTY_FAIL,
  REQUEST_AGENT_CLEAR_PAUSE_PROPERTY_STATUS,
  REQUEST_UPLOAD_SO_INTENT,
  REQUEST_UPLOAD_SO_INTENT_SUCCESS,
  REQUEST_UPLOAD_SO_INTENT_FAIL,
  REQUEST_STATISTICS,
  REQUEST_STATISTICS_SUCCESS,
  REQUEST_STATISTICS_FAIL,
  REQUEST_GET_TO_CONFIRM_PROPERTY,
  REQUEST_GET_TO_CONFIRM_PROPERTY_SUCCESS,
  REQUEST_GET_TO_CONFIRM_PROPERTY_FAIL,
  REQUEST_APPROVE_PROPERTY,
  REQUEST_APPROVE_PROPERTY_SUCCESS,
  REQUEST_APPROVE_PROPERTY_FAIL,
  REQUEST_EDIT_PROPERTY,
  REQUEST_EDIT_PROPERTY_SUCCESS,
  REQUEST_EDIT_PROPERTY_FAIL,
  REQUEST_DELETE_PROPERTY,
  REQUEST_DELETE_PROPERTY_SUCCESS,
  REQUEST_DELETE_PROPERTY_FAIL,
  REQUEST_AGENT_STATE,
  REQUEST_AGENT_STATE_SUCCESS,
  REQUEST_AGENT_STATE_FAIL,
  REQUEST_AGENT_ACTIVATE,
  REQUEST_AGENT_ACTIVATE_SUCCESS,
  REQUEST_AGENT_ACTIVATE_FAIL,
  CLEAR_STATUS,
  CLEAR_AGENT_STATE,
} from '../constants'

const INITIAL_STATE = {
  request: null,
  search_params: null,
  agentResults: null,
  agents: null,
  agencies: null,
  request_agents_error: null,
  request_agents_status: null,
  request_agent_agency_error: null,
  request_agent_agency_status: null,
  current_agent: null,
  myproperties: null,
  myproperties_status: null,
  myproperties_error: null,
  request_pause_property: null,
  request_pause_property_status: null,
  request_pause_property_error: null,
  request_create_property: null,
  request_create_property_success: null,
  request_create_property_error: null,
  request_upload_property_intent: null,
  request_upload_property_intent_status: null,
  request_upload_property_intent_error: null,
  statistics: null,
  request_statistics_error: null,
  request_statistics_success: null,
  to_confirm_property: null,
  request_to_confirm_property_status: null,
  request_to_confirm_property_error: null,
  request_approve_property: null,
  request_approve_property_status: null,
  request_approve_property_error: null,
  request_edit_property: null,
  request_edit_property_status: null,
  request_edit_property_error: null,
  request_delete_property: null,
  request_delete_property_status: null,
  request_delete_property_error: null,
  agent_state: null,
  agent_state_status: null,
  agent_state_error: null,
  agent_activate: null,
  agent_activate_status: null,
  agent_activate_error: null,
}

const agentReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_SEARCH_AGENT:
      return {
        ...state,
        request: action.params,
        request_agents_status: 'progress',
        request_agents_error: null,
      }

    case REQUEST_SEARCH_AGENT_SUCCESS:
      return {
        ...state,
        agentResults: action.payload.data,
        request_agents_status: 'success',
        request_agents_error: null,
      }
    case REQUEST_SEARCH_AGENT_FAIL:
      return {
        ...state,
        request_agents_status: 'failed',
        request_agents_error: action.error,
      }
    case REQUEST_AGENT_DETAILS:
      return {
        ...state,
        request: action.params,
        request_agent_details_status: 'progress',
        request_agent_details_error: null,
      }

    case REQUEST_AGENT_DETAILS_SUCCESS:
      return {
        ...state,
        current_agent: action.payload.data,
        request_agent_details_status: 'success',
        request_agent_details_error: null,
      }
    case REQUEST_AGENT_DETAILS_FAIL:
      return {
        ...state,
        request_agent_details_status: 'failed',
        request_agent_details_error: action.error,
      }

    case REQUEST_AGENT_AGENCY_DATA:
      return {
        ...state,
        request: action.params,
        request_agent_agency_status: 'progress',
        request_agent_agency_error: null,
      }
    case REQUEST_AGENT_AGENCY_DATA_SUCCESS:
      return {
        ...state,
        agents: action.payload.data.data.agents,
        agencies: action.payload.data.data.agencies,
        request_agent_agency_status: 'success',
        request_agent_agency_error: null,
      }
    case REQUEST_AGENT_AGENCY_DATA_FAIL:
      return {
        ...state,
        request_agent_agency_status: 'failed',
        request_agent_agency_error: action.error,
      }

    case SET_SEARCH_AGENT_PARAMS: {
      return {
        ...state,
        search_params: action.params,
      }
    }
    case CLEAR_SEARCH_AGENT_PARAMS: {
      return {
        ...state,
        search_params: null,
      }
    }
    case REQUEST_GET_AGENT_PROPERTIES:
      return {
        ...state,
        request: action.params,
        myproperties_status: 'progress',
        myproperties_error: null,
      }
    case REQUEST_GET_AGENT_PROPERTIES_SUCCESS:
      return {
        ...state,
        myproperties: action.payload.data,
        myproperties_status: 'success',
        myproperties_error: null,
      }
    case REQUEST_GET_AGENT_PROPERTIES_FAIL:
      return {
        ...state,
        myproperties_status: 'failed',
        myproperties_error: action.error,
      }
    case REQUEST_AGENT_SET_PAUSE_PROPERTY:
      return {
        ...state,
        request: action.params,
        request_pause_property_status: 'progress',
        request_pause_property_error: null,
      }
    case REQUEST_AGENT_SET_PAUSE_PROPERTY_SUCCESS:
      return {
        ...state,
        request_pause_property: action.payload.data,
        request_pause_property_status: 'success',
        request_pause_property_error: null,
      }
    case REQUEST_AGENT_SET_PAUSE_PROPERTY_FAIL:
      return {
        ...state,
        request_pause_property_status: 'failed',
        request_pause_property_error: action.error,
      }
    case REQUEST_AGENT_CLEAR_PAUSE_PROPERTY_STATUS:
      return {
        ...state,
        request_pause_property: null,
        request_pause_property_status: null,
        request_pause_property_error: null,
      }
    case REQUEST_CREATE_PROPERTY:
      return {
        ...state,
        request: action.params,
        request_create_property_status: 'progress',
        request_create_property_error: null,
      }
    case REQUEST_CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        request_create_property: action.payload.data,
        request_create_property_status: 'success',
        request_create_property_error: null,
      }
    case REQUEST_CREATE_PROPERTY_FAIL:
      return {
        ...state,
        request_create_property_status: 'failed',
        request_create_property_error: action.error,
      }
    case REQUEST_UPLOAD_SO_INTENT:
      return {
        ...state,
        request: action.params,
        request_upload_property_intent_status: 'progress',
        request_upload_property_intent_error: null,
      }
    case REQUEST_UPLOAD_SO_INTENT_SUCCESS:
      return {
        ...state,
        request_upload_property_intent: action.payload.data,
        request_upload_property_intent_status: 'success',
        request_upload_property_intent_error: null,
      }
    case REQUEST_UPLOAD_SO_INTENT_FAIL:
      return {
        ...state,
        request_upload_property_intent_status: 'failed',
        request_upload_property_intent_error: action.error,
      }
    case REQUEST_GET_TO_CONFIRM_PROPERTY:
      return {
        ...state,
        request: action.params,
        request_to_confirm_property_status: 'progress',
        request_to_confirm_property_error: null,
      }
    case REQUEST_GET_TO_CONFIRM_PROPERTY_SUCCESS:
      return {
        ...state,
        to_confirm_property: action.payload.data,
        request_to_confirm_property_status: 'success',
        request_to_confirm_property_error: null,
      }
    case REQUEST_GET_TO_CONFIRM_PROPERTY_FAIL:
      return {
        ...state,
        request_to_confirm_property_status: 'failed',
        request_to_confirm_property_error: action.error,
      }
    case REQUEST_APPROVE_PROPERTY:
      return {
        ...state,
        request: action.params,
        request_approve_property_status: 'progress',
        request_approve_property_error: null,
      }
    case REQUEST_APPROVE_PROPERTY_SUCCESS:
      return {
        ...state,
        request_approve_property: action.payload.data,
        request_approve_property_status: 'success',
        request_approve_property_error: null,
      }
    case REQUEST_APPROVE_PROPERTY_FAIL:
      return {
        ...state,
        request_approve_property_status: 'failed',
        request_approve_property_error: action.error,
      }

    case REQUEST_EDIT_PROPERTY:
      return {
        ...state,
        request: action.params,
        request_edit_property_status: 'progress',
        request_edit_property_error: null,
      }
    case REQUEST_EDIT_PROPERTY_SUCCESS:
      return {
        ...state,
        request_edit_property: action.payload.data,
        request_edit_property_status: 'success',
        request_edit_property_error: null,
      }
    case REQUEST_EDIT_PROPERTY_FAIL:
      return {
        ...state,
        request_edit_property_status: 'failed',
        request_edit_property_error: action.error,
      }
    case REQUEST_DELETE_PROPERTY:
      return {
        ...state,
        request: action.params,
        request_delete_property_status: 'progress',
        request_delete_property_error: null,
      }
    case REQUEST_DELETE_PROPERTY_SUCCESS:
      return {
        ...state,
        request_delete_property: action.payload.data,
        request_delete_property_status: 'success',
        request_delete_property_error: null,
      }
    case REQUEST_DELETE_PROPERTY_FAIL:
      return {
        ...state,
        request_delete_property_status: 'failed',
        request_delete_property_error: action.error,
      }
    case REQUEST_STATISTICS:
      return {
        ...state,
        request: action.params,
        request_statistics_success: 'progress',
        request_statistics_error: null,
      }

    case REQUEST_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload.data,
        request_statistics_success: 'success',
        request_statistics_error: null,
      }
    case REQUEST_STATISTICS_FAIL:
      return {
        ...state,
        request_statistics_success: 'failed',
        request_statistics_error: action.error,
      }
    case REQUEST_AGENT_STATE:
      return {
        ...state,
        agent_state: null,
        agent_state_status: 'progress',
        agent_state_error: null,
      }
    case REQUEST_AGENT_STATE_SUCCESS:
      return {
        ...state,
        agent_state: action.payload.data,
        agent_state_status: 'success',
        agent_state_error: null,
      }
    case REQUEST_AGENT_STATE_FAIL:
      return {
        ...state,
        agent_state: null,
        agent_state_status: 'failed',
        agent_state_error: action.error,
      }
    case REQUEST_AGENT_ACTIVATE:
      return {
        ...state,
        agent_activate: null,
        agent_activate_status: 'progress',
        agent_activate_error: null,
      }
    case REQUEST_AGENT_ACTIVATE_SUCCESS:
      return {
        ...state,
        agent_activate: action.payload,
        agent_activate_status: 'success',
        agent_activate_error: null,
      }
    case REQUEST_AGENT_ACTIVATE_FAIL:
      return {
        ...state,
        agent_activate: action.payload,
        agent_activate_status: 'failed',
        agent_activate_error: action.error,
      }

    case CLEAR_STATUS:
      return {
        ...state,
        agent_state: null,
        agent_state_status: null,
        agent_activate_status: null,
        request_agent_agency_status: null,
        request_upload_property_intent_status: null,
      }

    case CLEAR_AGENT_STATE:
      return {
        ...INITIAL_STATE,
      }
    case PURGE:
      return INITIAL_STATE

    default:
      return state
  }
}

export default agentReducers
