import React from 'react';
import { grey } from "@material-ui/core/colors";
import _ from 'lodash';
import NearbyTabsList from "../NearbyTabsList";

import SectionHeading from "../SectionHeading";
import { NEARBY_FILTERS, NEARBY_SCHOOLS } from './constants';

import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {
        padding: theme.spacing(3),
        backgroundColor: grey[100]
    }
});

const NearbySection = props => {
    const {
        classes,
        properties
    } = props;

    const [currentView, setCurrentView] = React.useState(null);

    React.useEffect(() => {
        selectedFilter('all');
    },[properties])

    /**
     * set object based from selected header */
    function selectedFilter(selected){
        let selectedArr = []
        if(properties?.nearbyPlaceListData?.nearbyPlaceList) {
        _.filter(properties.nearbyPlaceListData?.nearbyPlaceList, function(obj) {
            if(selected === 'all'){
                if(obj.types.includes('primary_school') || obj.types.includes('secondary_school') || obj.types.includes('university')){
                    selectedArr.push(obj)
                }
            }
            if(selected === 'primary'){
                if(obj.types.includes('primary_school')){
                    selectedArr.push(obj)
                }
            }
            if(selected === 'secondary'){
                if(obj.types.includes('secondary_school')){
                    selectedArr.push(obj)
                }
            }
            if(selected === 'university'){
                if(obj.types.includes('university')){
                    selectedArr.push(obj)
                }
            }

        });
    }
        setCurrentView(selectedArr)
    }

    return (
        <div className={classes.root}>
            <SectionHeading variant="h6" component="h2">
                Nearby Schools &amp; Universities
            </SectionHeading>

            <NearbyTabsList
                labels={NEARBY_FILTERS}
                data={currentView}
                selectedFilter={selectedFilter} />
        </div>
    )
}

export default withStyles(styles)(NearbySection);
