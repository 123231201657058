// import React, { useEffect, useState } from 'react'

// // Redux
// import { connect } from 'react-redux'

// // Components
// import Crumbs from '../../components/Web/Header/Crumbs'

// // MUI
// import { withStyles } from '@material-ui/core/styles'
// import Grid from '@material-ui/core/Grid'
// import Card from '@material-ui/core/Card'
// import Typography from '@material-ui/core/Typography'

// // Images
// import plusIcon from '../../assets/images/plus.png'

// // Custom styles
// const styles = (theme) => ({
//   root: {
//    display: 'flex',
//     flexDirection: 'column',
//     backgroundColor: '#FFF',
//     width: '100%',
//     height: '100vh',
//     paddingLeft: '10vw',
//     // padding: '23px 48px 125px 100px',
//   },
//   container: {
//     maxWidth: 1350,
//     margin: '0 auto',
//   },
//   titleWrap: {
//     paddingBottom: 24,
//     borderBottom: '0.5px solid #828282',
//     marginBottom: 10,
//   },
//   title: {
//     fontFamily: 'Montserrat, serif',
//     fontSize: 24,
//     fontWeight: 300,
//     lineHeight: 1.3,
//     color: '#4d4d4d',
//   },
//   crumbsWrap: {
//     marginBottom: 27,
//   },
//   agentNameWrap: {
//     marginBottom: 63,
//   },
//   agentName: {
//     fontSize: 36,
//     fontWeight: 300,
//     lineHeight: 1.2,
//     textAlign: 'center',
//     color: '#4d4d4d',
//   },
//   cardsWrap: {
//     maxWidth: 1012,
//     margin: '0 auto',
//   },
//   card: {
//     width: '100%',
//     height: 225,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     '@media (max-width: 960px)': {
//       width: 328,
//       margin: '0 auto',
//     },
//   },
//   iconWrap: {
//     width: 80,
//     height: 80,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: '#FDBD26',
//     marginBottom: 14,
//     borderRadius: '50%',
//     transition: '0.3s all ease-in-out',
//     cursor: 'pointer',
//     opacity: 0.9,
//     '& img': {
//       display: 'block',
//     },
//     '&:hover': {
//       opacity: 1,
//     },
//   },
//   cardTitle: {
//     fontFamily: 'Montserrat, serif',
//     fontSize: 20,
//     fontWeight: 400,
//     lineHeight: 1.3,
//     textAlign: 'center',
//     color: '#4d4d4d',
//   },
// })

// const CardItem = ({
//   classes,
//   iconColor = '#fff',
//   title = 'Add Title',
//
//   route,
// }) => (
//   <Card className={classes.card}>
//     <div
//
//       className={classes.iconWrap}
//       style={{ backgroundColor: iconColor }}
//     >
//       <img src={plusIcon} alt="Add" />
//     </div>
//     <Typography className={classes.cardTitle}>{title}</Typography>
//   </Card>
// )

// const AgentDashboard = ({ classes, user, authentication, history }) => {
//   const [agentName, setAgentName] = useState(null)

//   useEffect(() => {
//     if (authentication && authentication.user_status !== 'logged') {
//
//     }

//     if (user && user.userType !== 'Agent') {
//
//     }

//     if (user && user.name) {
//       setAgentName(user.name)
//     }
//   }, [])

//   return (
//     <div className={classes.root}>
//       <div className={classes.container}>
//         <div className={classes.titleWrap}>
//           <Typography className={classes.title}>Dashboard</Typography>
//         </div>
//         <div className={classes.crumbsWrap}>
//           <Crumbs
//             type={'DARK'}
//             crumbsOption={[{ label: 'Dashboard', route: history.location.pathname }]}
//
//           />
//         </div>
//         <div className={classes.agentNameWrap}>
//           <Typography className={classes.agentName}>
//             Hi {agentName ? agentName : 'Agent Name'}
//           </Typography>
//         </div>
//         <Grid container spacing={2} className={classes.cardsWrap}>
//           <Grid item xs={12} md={4}>
//             <CardItem
//               classes={classes}
//               iconColor="#FDBD26"
//               title="Add Properties"
//
//               route="/agent/add-property"
//             />
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <CardItem
//               classes={classes}
//               iconColor="#6DC5A1"
//               title="View Properties"
//
//               route="/agent/my-properties"
//             />
//           </Grid>
//           <Grid item xs={12} md={4}>
//             <CardItem
//               classes={classes}
//               iconColor="#EF6631"
//               title="Account Settings"
//
//               route="/agent/account-settings"
//             />
//           </Grid>
//         </Grid>
//       </div>
//     </div>
//   )
// }

// const mapStateToProps = (state) => {
//   return {
//     authentication: state.authentication,
//     user: state.users.user,
//   }
// }

// export default connect(mapStateToProps)(withStyles(styles)(AgentDashboard))

/**
 * !!! commented by Bon ^
 * * Code below from super admin dashboard
 */

import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { SuperBreadCrumbs } from 'components/Web/Superadmin';

/** redux */
import { getSuperadminToken } from 'store/selectors/superadmin.selectors';
import { getUser, getAuthentication } from 'store/selectors/authentication.selectors';
import { useRouterChange } from 'utils/router.utils';
import ManageModal from 'components/Common/Dialog/ManageModal';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
  greetings: {
    marginBottom: 50,
    fontWeight: 300,
    fontSize: '32px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  icon: {
    fontSize: '40px',
  },
  button: {
    width: 300,
    height: 250,
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    width: 60,
    height: 60,
    borderRadius: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 10,
    fontWeight: 400,
    fontSize: '21px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
  profileImage: {
    width: '200px',
    height: '200px',
    marginTop: 10,
    padding: '1rem',
    margin: '1rem',
    borderRadius: '50%',
    objectFit: 'cover',
  },
});

const ButtonOptions = (props) => {
  const { classes, disabled } = props;
  const ColorArr = ['#fdbd26', '#6dc5a1', '#ef6631'];
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="inherit"
      disableFocusRipple
      disableTouchRipple
      disableRipple
      onClick={props.onClick}
    >
      <div className={classes.buttonContent}>
        <div className={classes.buttonIcon} style={{ backgroundColor: ColorArr[props.idx] }}>
          <AddIcon className={classes.icon} />
        </div>
        <Typography className={classes.title} variant={'h1'}>
          {props.label}
        </Typography>
      </div>
    </Button>
  );
};
const AgentDashboard = (props) => {
  const { classes, history } = props;
  const [routerChange] = useRouterChange();
  const SUPERADMIN_TOKEN = useSelector(getSuperadminToken);
  const USER = useSelector(getUser);
  const AUTH = useSelector(getAuthentication);
  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/agent/dashboard',
    },
    {
      type: 'primary',
      label: 'Dashboard',
      path: '/agent/dashboard',
    },
  ];

  useEffect(() => {
    /**
     * * Agent Token checker
     */
    checkAgentToken();
  }, []);

  function setGreetings() {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      return 'Good morning, ' + USER?.user?.name;
    } else if (curHr < 18) {
      return 'Good afternoon, ' + USER?.user?.name;
    } else {
      return 'Good evening, ' + USER?.user?.name;
    }
  }

  const checkAgentToken = () => {
    /**
     * Reroute to login when token does not exist
     */
    if (AUTH?.token === null) {
      // add more conditions here
      routerChange('/agent/login');
    }
  };

  const _renderButtons = () => (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid item>
        <ButtonOptions
          idx={0}
          classes={classes}
          label={'Add Properties'}
          onClick={() => routerChange('/agent/add-property')}
        />
      </Grid>
      <Grid item>
        <ButtonOptions
          idx={1}
          classes={classes}
          label={'View Properties'}
          onClick={() => routerChange('/agent/my-properties')}
        />
      </Grid>
      <Grid item>
        <ButtonOptions
          idx={2}
          disabled
          classes={classes}
          label={'Account Settings'}
          onClick={() => routerChange('/agent/account-settings')}
        />
      </Grid>
    </Grid>
  );

  const [openModal, setOpenModal] = useState(false);

  const isWelcomeModalRead = !!localStorage.getItem('isWelcomeModalRead');
  const dontShowPopupAgain = !!localStorage.getItem('dontShowPopupAgain');

  useEffect(() => {
    if (AUTH?.token && !isWelcomeModalRead && !dontShowPopupAgain) {
      setOpenModal(true);
    }
  }, [AUTH, isWelcomeModalRead, dontShowPopupAgain]);

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs section={'Dashboard'} crumbsLinks={CRUMBS} />
      <div className={classes.buttonContainer}>
        {(USER?.user_profile?.agent?.image || USER?.user_profile?.profile?.image) && (
          <img
            src={USER?.user_profile?.agent?.image ?? USER?.user_profile?.profile?.image}
            className={classes.profileImage}
          />
        )}
        <Typography className={classes.greetings} variant={'h1'}>
          {`WELCOME ${
            USER?.user_profile?.agent?.firstName?.toUpperCase() ||
            USER?.user_profile?.profile?.firstName?.toUpperCase() ||
            ''
          } TO YOUR DASHBOARD`}
        </Typography>
        {_renderButtons()}
      </div>
      <ManageModal open={openModal} setOpen={setOpenModal} />
    </div>
  );
};
export default withStyles(styles)(AgentDashboard);

