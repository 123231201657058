import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function LandIcon(props: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      {/* <rect
        width="59.5"
        height="59.5"
        x="0.25"
        y="0.25"
        stroke="#000"
        strokeWidth="0.5"
        rx="4.75"
      ></rect> */}
      <path
        fill="#000"
        fillRule="evenodd"
        d="M50.407 44.203H10.22a.222.222 0 01-.195-.116.226.226 0 01.01-.229l6.483-9.722a.218.218 0 01.306-.061.22.22 0 01.06.306l-6.251 9.38h39.358l-6.25-9.38a.218.218 0 01.06-.306c.1-.068.24-.042.306.06l6.483 9.723c.044.07.05.155.01.229a.217.217 0 01-.194.116"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0"
        width="41"
        height="35"
        x="10"
        y="12"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M10 46.795h40.63V12H10v34.795z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g fill="#000" fillRule="evenodd" clipRule="evenodd">
        <path d="M10.442 46.354h39.743v-2.15H10.442v2.15zm39.966.442H10.221a.222.222 0 01-.221-.221v-2.592c0-.124.1-.221.221-.221h40.187a.22.22 0 01.221.22v2.593c0 .121-.099.22-.22.22z"></path>
        <path d="M15.957 44.203a.22.22 0 01-.199-.317l3.397-7.129a.225.225 0 01.295-.105c.11.054.156.186.105.297l-3.399 7.129a.218.218 0 01-.199.125M22.622 40.963a.23.23 0 01-.06-.01.219.219 0 01-.152-.272l.93-3.242a.223.223 0 01.273-.15.219.219 0 01.151.272l-.93 3.242a.218.218 0 01-.212.16M44.673 44.204a.218.218 0 01-.2-.126l-1.237-2.595a.222.222 0 01.103-.295.224.224 0 01.297.105l1.236 2.594a.22.22 0 01-.2.317M42.2 39.016A.218.218 0 0142 38.89l-2.163-4.535a.22.22 0 01.103-.295.226.226 0 01.297.103l2.16 4.536a.22.22 0 01-.198.317M38.006 40.963a.218.218 0 01-.212-.16l-.928-3.242a.219.219 0 01.151-.273.222.222 0 01.273.151l.928 3.242a.219.219 0 01-.15.273.228.228 0 01-.062.01"></path>
        <path d="M19.355 37.074h-4.382a.222.222 0 010-.442h4.382a.22.22 0 110 .442M48.678 41.61h-5.242a.22.22 0 110-.442h5.242a.22.22 0 110 .443M46.952 39.02h-4.75a.22.22 0 01-.221-.222.22.22 0 01.22-.221h4.751a.22.22 0 110 .442M29.018 37.721h-5.467a.22.22 0 110-.442h5.468a.22.22 0 110 .442M37.077 37.721H31.67a.22.22 0 110-.442h5.407a.22.22 0 110 .442M38.005 40.963H22.619a.22.22 0 110-.442h15.386a.22.22 0 01.22.221c0 .122-.099.221-.22.221"></path>
        <path d="M30.313 12.442c-5.239 0-9.5 4.263-9.5 9.502 0 7.584 8.292 15.448 9.5 16.557 1.208-1.108 9.502-8.975 9.502-16.557 0-5.24-4.261-9.502-9.502-9.502m0 26.576a.218.218 0 01-.146-.055c-.025-.022-2.475-2.181-4.89-5.333-2.239-2.92-4.906-7.319-4.906-11.686 0-5.482 4.458-9.944 9.942-9.944s9.944 4.462 9.944 9.944c0 4.367-2.67 8.766-4.908 11.686-2.415 3.152-4.865 5.31-4.89 5.333a.218.218 0 01-.146.055"></path>
        <path d="M26.054 34.48h-9.35a.22.22 0 01-.222-.221.22.22 0 01.221-.221h9.351a.22.22 0 110 .442M43.925 34.48h-9.35a.22.22 0 01-.222-.221.22.22 0 01.222-.221h9.35a.22.22 0 01.221.22c0 .122-.1.222-.22.222M34.203 26.703h-7.777a.22.22 0 110-.442h7.777a.22.22 0 110 .442"></path>
        <path d="M27.073 26.703a.222.222 0 01-.22-.221v-7.78c0-.121.099-.22.22-.22.122 0 .221.099.221.22v7.78c0 .121-.099.22-.22.22M33.556 26.703a.222.222 0 01-.221-.221v-5.464a.222.222 0 01.442 0v5.464c0 .121-.1.22-.221.22"></path>
        <path d="M25.776 22.166a.228.228 0 01-.18-.092.222.222 0 01.053-.31l4.537-3.242a.222.222 0 01.308.052c.07.1.047.237-.054.31l-4.537 3.241a.213.213 0 01-.127.04"></path>
        <path d="M34.852 22.166a.213.213 0 01-.127-.04l-4.54-3.243a.222.222 0 01-.053-.31.222.222 0 01.308-.05l4.539 3.241c.101.072.123.21.053.31a.228.228 0 01-.18.092M30.314 26.703a.222.222 0 01-.221-.221v-1.945c0-.122.1-.222.22-.222.122 0 .222.1.222.221v1.946c0 .121-.1.22-.221.22"></path>
      </g>
    </svg>
  );
}

export function LandIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LandIcon />
      <LandIcon color="primary" />
      <LandIcon color="secondary" />
      <LandIcon color="action" />
      <LandIcon color="disabled" />
      <LandIcon style={{ color: green[500] }} />
    </div>
  );
}
