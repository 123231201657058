import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Card, CardContent, Typography, IconButton, Grid, Chip, Box } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import Image from 'material-ui-image';

import AgentBanner from '../Property/AgentBanner';
import PropertyInfo from '../Property/PropertyInfo';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import styled from 'styled-components';

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils';
import { Button, withStyles } from '@material-ui/core';
import ManagePropertyDialog from './ManagePropertyDialog';
import EditPropertyDialogContent from './EditPropertyDialogContent';
import NotificationDialog from '../CustomDialogs/NotificationDialog';
import { requestAgentSetPauseProperty, requestDeleteProperty, requestSetEditProperty } from '../../../store/api';
import { agentClearPausePropertyStatus } from '../../../store/actions';
import _ from 'lodash';

import { cloudinaryCardTransforms, cloudinaryImageHeightSmall } from '../../../constants/constants';
import { useSelector } from 'react-redux';
import { getMpp } from 'store/selectors/mpp.selectors';

const CarouselWrapper = styled.div`
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
`;

const styles = (theme) => ({
  cardFull: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 0,
    minHeight: 300,
    backgroundColor: '#FFF',
  },
  cardPadded: {
    margin: theme.spacing(3),
    width: window.innerWidth - theme.spacing(3) * 2,
    maxHeight: '31.125em',
    backgroundColor: '#FFF',
  },
  cardPaddedSwipe: {
    margin: theme.spacing(3),
    width: window.innerWidth - theme.spacing(3) * 2,
    height: '35.125em',
    backgroundColor: '#FFF',
  },
  media: {
    width: '100%',
    height: '28.571em',
    objectFit: 'cover !important',
  },
  favoriteContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
  tagContainer: {
    position: 'relative',
  },
  tagTypeText: {
    color: '#FFF',
    fontSize: '0.750em',
  },
  tagTypeAuction: {
    width: '13.929em',
    height: '1.786em',
    backgroundColor: 'rgba(6, 18, 82, 0.8)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagTypeComingSoon: {
    width: '13.929em',
    height: '1.786em',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagTypeOffMarket: {
    width: '13.929em',
    height: '1.786em',
    backgroundColor: '#F50057',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLabelCloseTo: {
    fontSize: '0.875em',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  chipText: {
    fontSize: 10,
  },
  chipIcon: {
    width: 10,
    height: 10,
  },
  rowContainerCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  rowContainerEven: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  chipContainer: {
    minWidth: '3.750em',
    height: '1.300em',
    paddingRight: '0.425em',
    paddingLeft: '0.425em',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    border: ' 0.5px solid #35C0CA',
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    marginBottom: '0.425em',
    marginRight: '0.125em',
  },
  manageBtn: {
    borderRadius: theme.spacing(3),
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    fontSize: 9,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.common.white,
  },
  pauseBtn: {
    borderRadius: theme.spacing(3),
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    fontSize: 9,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.common.white,
  },
  cardPaused: {
    opacity: 0.75,
  },
  cardUnpaused: {
    opacity: 1,
  },
  cardChipContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    borderRadius: 20,
  },
  vendorApproved: {
    backgroundColor: '#4ce2a4',
    fontFamily: 'Montserrat, sans',
  },
  vendorReject: {
    backgroundColor: '#ef7070',
    fontFamily: 'Montserrat, sans',
  },
  agencyApproved: {
    backgroundColor: '#38bfc9',
    fontFamily: 'Montserrat, sans',
  },
  agencyPending: {
    backgroundColor: '#e0e0e0',
    fontFamily: 'Montserrat, sans',
  },
  soiPending: {
    backgroundColor: '#ffffff',
    color: '#EC0000',
    fontWeight: 'bold',
    fontFamily: 'Montserrat, sans',
  },
  soiContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 999,
  },
});

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 20px)',
  width: 60,
  height: 60,
  cursor: 'pointer',
};

const iconStyles = {
  width: '100%',
  height: '100%',
};

const PropertyStatusChip = (props) => {
  const { classes, type } = props;
  if (type === 'isVendorPending') {
    return (
      <Box className={classes.chip} boxShadow={3}>
        <Chip size="small" label="Vendor Pending" className={classes.agencyPending} />
      </Box>
    );
  }
  // case 'isVendorPending':
  //   ChipContent = (
  //     <Chip
  //       style={{
  //         backgroundColor: '#e0e0e',
  //         color: 'rgba(0, 0, 0, 0.87)',
  //         fontFamily: 'Montserrat, sans',
  //       }}
  //       size="small"
  //       label="Vendor Pending"
  //     />
  //   )
  //   break

  if (type === 'isVendorApproved') {
    return (
      <Box className={classes.chip} boxShadow={3}>
        <Chip size="small" label="Vendor Approved" className={classes.vendorApproved} />
      </Box>
    );
  }
  if (type === 'isVendorRejected') {
    return (
      <Box className={classes.chip} boxShadow={3}>
        <Chip size="small" label="Vendor Rejected" className={classes.vendorReject} />
      </Box>
    );
  }
  if (type === 'isAgencyApproved') {
    return (
      <Box className={classes.chip} boxShadow={3}>
        <Chip size="small" label="Published" className={classes.agencyApproved} />
      </Box>
    );
  }
  if (type === 'isAgencyPending') {
    return (
      <Box className={classes.chip} boxShadow={3}>
        <Chip size="small" label="Agency Review Pending" className={classes.agencyPending} />
      </Box>
    );
  }
  if (type === 'soiPending') {
    return (
      <Box className={classes.chip} boxShadow={3}>
        <Chip size="small" label="SOI Pending" className={classes.soiPending} />
      </Box>
    );
  }
  return null;
};

const MyPropertiesCard = (props) => {
  const {
    classes,
    isFullWidth,
    properties,
    authentication,
    settings,
    toggleSignUp,
    apiRequestSaveProperty,
    cardType,
    listingType,
    enableManage,
    setPauseProperty,
    pause_unpause,
    pause_unpause_status,
    pause_unpause_error,
    clearStatus,
    saveEditProperty,
    deleteProperty,
    delete_property,
    delete_property_status,
    delete_property_error,
    request,
    users,
    isAgentDashboardView,
  } = props;

  const [value, setValue] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  const [manageItemToggle, setManageItemToggle] = useState(false);
  const [pauseUpdate, setPauseUpdate] = useState(null);
  const [pauseConfirm, setPauseConfirm] = useState(false);
  const [unpauseConfirm, setUnpauseConfirm] = useState(false);

  const [pauseSuccess, setPauseSuccess] = useState(false);
  const [pauseUnpauseError, setPauseUnpauseError] = useState(false);
  const [unpauseSuccess, setUnpauseSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [editPropertyActiveTab, setEditPropertyActiveTab] = useState(0);

  const [saveConfirm, setSaveConfirm] = useState(false);

  const [galleryImages, setGalleryImages] = useState(null);

  const MPP = useSelector(getMpp);
  let likes = MPP?.favourites?.data?.likes;

  useEffect(() => {
    if (properties && properties.images && properties.images.length > 0) {
      let gallery_array = [];
      if (properties.cloudinaryImages && properties.cloudinaryImages.length > 0) {
        properties.cloudinaryImages.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageHeightSmall + '/' + item.file;
          gallery_array.push(file);
        });
      } else {
        gallery_array = _.compact(properties.images);
      }

      setGalleryImages(gallery_array);
    }
  }, [properties]);

  useEffect(() => {
    if (pause_unpause && pause_unpause.data.property === properties.property) {
      if (pause_unpause_status && pause_unpause_status === 'success') {
        switch (pause_unpause.data.isPaused) {
          case true:
            setPauseSuccess(true);
            setPauseUpdate(pause_unpause.data.isPaused);
            break;
          case false:
            setUnpauseSuccess(true);
            setPauseUpdate(pause_unpause.data.isPaused);
            break;
          default:
          //break;
        }
      } else if (pause_unpause_error && pause_unpause_status === 'failed') {
        setPauseUnpauseError(true);
      }
    }
  }, [pause_unpause_status, pause_unpause_error, pause_unpause]);

  useEffect(() => {
    if (request && request.id === properties.property) {
      if (delete_property_status && delete_property_status === 'success') {
        setDeleteSuccess(true);
      } else if (delete_property_error && delete_property_status === 'failed') {
        setDeleteError(true);
      }
    }
  }, [delete_property, delete_property_status, delete_property_error]);

  const handleClose = () => {
    setManageItemToggle(false);
    setSaveConfirm(false);
  };

  const handleConfirm = () => {
    if (editPropertyActiveTab === 0) {
      setEditPropertyActiveTab(editPropertyActiveTab + 1);
    } else if (!saveConfirm) {
      setManageItemToggle(false);
      setSaveConfirm(true);
    }
  };

  const handlePauseClose = () => {
    setPauseConfirm(false);
  };

  const handlePauseConfirm = () => {
    apiRequestSetPauseProperty(true);
    setPauseConfirm(false);
  };

  const handleUnpauseClose = () => {
    setUnpauseConfirm(false);
  };

  const handleUnpauseConfirm = () => {
    apiRequestSetPauseProperty(false);
    setUnpauseConfirm(false);
  };

  async function apiRequestSetPauseProperty(status) {
    let params = { property: null, is_paused: null };
    if (properties) {
      params.property = properties.property;
      params.is_paused = status;
      setPauseProperty(params);
    }
  }

  function renderTypeTag(type, property) {
    let propertyStatus = type.toLowerCase().replace(/[^\w\s]/gi, '');
    let isForRent = property.forRent ? true : false;
    let conditionCheck = propertyStatus + ':' + isForRent;
    const auctionDate = property.auctionDate ? moment(property.auctionDate).format('Do MMMM YYYY') : '';
    const soldDate = property.soldDate ? moment(property.soldDate).format('Do MMMM YYYY') : '';
    switch (conditionCheck) {
      case 'onsale:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
              {'Auction ' + auctionDate}
            </Typography>
          </div>
        );
      case 'current:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
              {'Auction ' + auctionDate}
            </Typography>
          </div>
        );
      case 'sold:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
              {'Sold' + soldDate}
            </Typography>
          </div>
        );
      case 'comingsoon:false':
        return (
          <div className={classes.tagTypeComingSoon}>
            <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
              Coming Soon Property
            </Typography>
          </div>
        );
      case 'offmarket:false':
        return (
          <div className={classes.tagTypeOffMarket}>
            <Typography className={classes.tagTypeText} variant="caption" color="textSecondary" component="p">
              Off Market Property
            </Typography>
          </div>
        );
    }
  }

  function handleStatus(status) {
    if (status) {
      setUnpauseConfirm(true);
    } else {
      setPauseConfirm(true);
    }
  }

  function handlePropertyStatus(type, data) {
    if (type === 'agency') {
      if (data.isApprovedByAgency) {
        return 'isAgencyApproved';
      }
      if (!data.isApprovedByAgency) {
        return 'isAgencyPending';
      }
    }
    if (type === 'vendor') {
      if (data.isApprovedByVendor) {
        return 'isVendorApproved';
      }
      if (!data.isApprovedByVendor && data.vendor.remarks) {
        return 'isVendorRejected';
      }
      if (!data.isApprovedByVendor) {
        return 'isVendorPending';
      }
    }
    // if (type === 'soi') {
    //   if (data.statementOfIntent === null || data.statementOfIntent === '') {
    //     return 'soiPending';
    //   }
    // }
  }

  function renderForGridCard() {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={4}
        xl={3}
        className={`${pauseUpdate === null ? (properties.isPaused ? classes.cardPaused : classes.cardUnpaused) : ''} ${
          pauseUpdate !== null ? (pauseUpdate ? classes.cardPaused : classes.cardUnpaused) : ''
        }`}
      >
        <Card elevation={1} className={classes.grid}>
          <div className={classes.tagContainer}>
            <div className={classes.soiContainer}>
              {properties.addressState === 'VIC' && properties.propertyStatus === 'comingsoon' && (
                <PropertyStatusChip classes={classes} type={handlePropertyStatus('soi', properties)} />
              )}
            </div>
            {properties && properties.propertyStatus !== 'offmarket' && galleryImages && galleryImages.length > 1 ? (
              <CarouselWrapper>
                <Carousel
                  showArrows={true}
                  showIndicators={false}
                  showStatus={false}
                  infiniteLoop={false}
                  showThumbs={false}
                  useKeyboardArrows={false}
                  autoPlay={false}
                  swipeable={true}
                  emulateTouch={true}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, left: 0, color: '#fff' }}
                      >
                        <ChevronLeftIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <IconButton
                        onClick={onClickHandler}
                        title={label}
                        style={{ ...arrowStyles, right: 0, color: '#fff' }}
                      >
                        <ChevronRightIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  onSwipeStart={() => {
                    setIsSwiping(true);
                  }}
                  onSwipeEnd={() => {
                    setIsSwiping(false);
                  }}
                >
                  {galleryImages &&
                    galleryImages.map((img, index) => {
                      return (
                        <Image
                          style={{ width: '100%' }}
                          key={index}
                          className={classes.media}
                          src={propertyConfigSetter('image-banner-card', {
                            type: properties.propertyStatus,
                            image: img,
                          })}
                          aspectRatio={16 / 9}
                          disableSpinner
                        />
                      );
                    })}
                </Carousel>
              </CarouselWrapper>
            ) : properties && properties.propertyStatus === 'offmarket' && galleryImages && galleryImages.length > 0 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: properties.propertyStatus,
                  image: galleryImages[0],
                })}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : properties && galleryImages && galleryImages.length >= 1 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: properties.propertyStatus,
                  image: galleryImages[0],
                })}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : (
              <></>
            )}
          </div>
          {
            <AgentBanner
              type={'search'}
              properties={properties}
              {...propertyConfigSetter('agent', properties.subscriptionType)}
            />
          }
          <CardContent>
            <PropertyInfo
              type={'search'}
              properties={properties}
              matchCount={props.handleMatchCount(properties)}
              listingType={listingType}
            />
          </CardContent>
        </Card>
        <div className={classes.cardChipContainer}>
          <PropertyStatusChip classes={classes} type={handlePropertyStatus('vendor', properties)} />
          <PropertyStatusChip classes={classes} type={handlePropertyStatus('agency', properties)} />
        </div>
      </Grid>
    );
  }

  function renderForListCard() {
    return (
      <Card elevation={1} className={isFullWidth ? classes.cardFull : classes.cardPadded}>
        <div className={classes.tagContainer}>
          {properties && properties.propertyStatus !== 'offmarket' && galleryImages && galleryImages.length > 1 ? (
            <CarouselWrapper>
              <Carousel
                showArrows={true}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={false}
                showThumbs={false}
                useKeyboardArrows={false}
                autoPlay={false}
                swipeable={true}
                emulateTouch={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, left: 0, color: '#fff' }}
                    >
                      <ChevronLeftIcon style={{ ...iconStyles }} />
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, right: 0, color: '#fff' }}
                    >
                      <ChevronRightIcon style={{ ...iconStyles }} />
                    </IconButton>
                  )
                }
                onSwipeStart={() => {
                  setIsSwiping(true);
                }}
                onSwipeEnd={() => {
                  setIsSwiping(false);
                }}
              >
                {galleryImages &&
                  galleryImages.map((img, index) => {
                    return (
                      <Image
                        style={{ width: '100%' }}
                        key={index}
                        className={classes.media}
                        src={propertyConfigSetter('image-banner-card', {
                          type: properties.propertyStatus,
                          image: img,
                        })}
                        aspectRatio={16 / 9}
                        disableSpinner
                      />
                    );
                  })}
              </Carousel>
            </CarouselWrapper>
          ) : properties && properties.propertyStatus === 'offmarket' && galleryImages && galleryImages.length > 0 ? (
            <Image
              style={{ width: '100%' }}
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: galleryImages[0],
              })}
              aspectRatio={16 / 9}
              disableSpinner
            />
          ) : properties && galleryImages && galleryImages.length === 1 ? (
            <Image
              style={{ width: '100%' }}
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: galleryImages[0],
              })}
              aspectRatio={16 / 9}
              disableSpinner
            />
          ) : (
            <div></div>
          )}
          {enableManage && (
            <Button variant={'contained'} className={classes.manageBtn} onClick={() => setManageItemToggle(true)}>
              Edit
            </Button>
          )}
          <Button
            variant={'contained'}
            className={classes.pauseBtn}
            onClick={() => handleStatus(pauseUpdate !== null ? pauseUpdate : properties.isPaused)}
          >
            {pauseUpdate === null ? (properties.isPaused ? 'Unpause' : 'Pause') : ''}
            {pauseUpdate !== null ? (pauseUpdate ? 'Unpause' : 'Pause') : ''}
          </Button>
        </div>
        {
          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />
        }
        <CardContent>
          <PropertyInfo
            type={'search'}
            properties={properties}
            matchCount={props.handleMatchCount(properties)}
            listingType={listingType}
          />
        </CardContent>
      </Card>
    );
  }

  function renderForSwipeCard() {
    return (
      <Card elevation={1} className={classes.cardPaddedSwipe}>
        <div className={classes.tagContainer}>
          {properties && galleryImages && galleryImages.length > 0 && (
            <Image
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: galleryImages[0],
              })}
              aspectRatio={16 / 9}
              disableSpinner
            />
          )}
          {properties.propertyStatus && renderTypeTag(properties.propertyStatus, properties)}
        </div>
        {
          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />
        }
        <CardContent>
          <PropertyInfo type={'search'} properties={properties} />
        </CardContent>
        <div className={classes.rowContainerCenter}>
          {properties.coefficients.close_to_cafes >= 0.7 ||
          properties.coefficients.close_to_parks >= 0.7 ||
          properties.coefficients.close_to_shops >= 0.7 ||
          properties.coefficients.close_to_schools >= 0.7 ||
          properties.coefficients.close_to_universities >= 0.7 ||
          properties.coefficients.foody_area >= 0.7 ||
          properties.coefficients.inner_city_vibe >= 0.7 ||
          properties.coefficients.close_to_public_transports >= 0.7 ||
          properties.coefficients.close_to_secondary_schools >= 0.7 ? (
            <>
              <div style={{ width: '25%', paddingLeft: 5 }}>
                <div className={classes.rowContainerCenter}>
                  <p className={classes.textLabelCloseTo}>Close to:</p>
                </div>
                <div className={classes.rowContainerCenter}>
                  <p></p>
                </div>
              </div>
              {renderSwipeCloseTo(properties)}
            </>
          ) : null}
        </div>
      </Card>
    );
  }

  function renderSwipeCloseTo(data) {
    return (
      <div style={{ width: '80%' }}>
        <div className={classes.rowContainerEven}>
          {data.coefficients.close_to_cafes >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Cafes</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_parks >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Parks</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_shops >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Shops</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_schools >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Schools</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_universities >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Universities</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.foody_area >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Foody Area</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.inner_city_vibe >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Inner City Vibe</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_public_transports >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Transports</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_secondary_schools >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Secondary Schools</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {cardType === 'list' && renderForListCard()}
      {cardType === 'swipe' && renderForSwipeCard()}
      {cardType === 'grid' && renderForGridCard()}
      <ManagePropertyDialog
        isOpen={manageItemToggle}
        closeCallback={handleClose}
        confirmCallback={handleConfirm}
        showLogo={false}
        align={'left'}
        text={''}
        visibleTab={editPropertyActiveTab}
        content={
          <EditPropertyDialogContent
            property={properties}
            visibleTab={editPropertyActiveTab}
            setVisibleTab={setEditPropertyActiveTab}
            saveConfirm={saveConfirm}
            setSaveConfirm={setSaveConfirm}
            saveEditProperty={saveEditProperty}
            deleteProperty={deleteProperty}
            setManageItemToggle={setManageItemToggle}
            users={users}
          />
        }
        maxWidth={'980px'}
        confirmLabel={editPropertyActiveTab !== 0 ? 'Save' : 'Next'}
        actionsIndexToggle={4}
      />
      <ManagePropertyDialog
        isOpen={pauseConfirm}
        closeCallback={handlePauseClose}
        confirmCallback={handlePauseConfirm}
        showLogo={false}
        align={'center'}
        title={'Pause Property'}
        text={'Are you sure you want to Pause this listing?'}
        cancelLabel={'No, Cancel'}
        confirmLabel={'Yes, Continue'}
        actionsIndexToggle={false}
      />
      <ManagePropertyDialog
        isOpen={unpauseConfirm}
        closeCallback={handleUnpauseClose}
        confirmCallback={handleUnpauseConfirm}
        showLogo={false}
        align={'center'}
        title={'Unpause Property'}
        text={'Are you sure you want to Unpause this listing?'}
        cancelLabel={'No, Cancel'}
        confirmLabel={'Yes, Continue'}
        actionsIndexToggle={false}
      />
      {pauseSuccess && (
        <NotificationDialog
          isOpen={pauseSuccess}
          closeCallback={() => {
            setPauseSuccess(false);
            clearStatus();
          }}
          title={'Success!'}
          text={'Your listing is now Paused!'}
          showLogo={false}
          align={'center'}
        />
      )}
      {unpauseSuccess && (
        <NotificationDialog
          isOpen={unpauseSuccess}
          closeCallback={() => {
            setUnpauseSuccess(false);
            clearStatus();
          }}
          title={'Success!'}
          text={'Your listing is now Unpaused!'}
          showLogo={false}
          align={'center'}
        />
      )}
      {pauseUnpauseError && (
        <NotificationDialog
          isOpen={pauseUnpauseError}
          closeCallback={() => {
            setPauseUnpauseError(false);
            clearStatus();
          }}
          title={'Error!'}
          text={'Error pausing/unpausing property. Please try again.'}
          showLogo={false}
          align={'center'}
        />
      )}
      {deleteSuccess && (
        <NotificationDialog
          isOpen={deleteSuccess}
          closeCallback={() => {
            setDeleteSuccess(false);
          }}
          title={'Success!'}
          text={'Your listing has been deleted!'}
          showLogo={false}
          align={'center'}
        />
      )}
      {deleteError && (
        <NotificationDialog
          isOpen={deleteError}
          closeCallback={() => {
            setDeleteError(false);
          }}
          title={'Error!'}
          text={'Error deleting property. Please try again.'}
          showLogo={false}
          align={'center'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pause_unpause_status: state.agents.request_pause_property_status,
    pause_unpause_error: state.agents.request_pause_property_error,
    pause_unpause: state.agents.request_pause_property,
    delete_property: state.agents.request_delete_property,
    delete_property_status: state.agents.request_delete_property_status,
    delete_property_error: state.agents.request_delete_property_error,
    request: state.agents.request,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setPauseProperty: (params) => requestAgentSetPauseProperty(params),
      clearStatus: () => agentClearPausePropertyStatus(),
      saveEditProperty: (params) => requestSetEditProperty(params),
      deleteProperty: (params) => requestDeleteProperty(params),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyPropertiesCard));
