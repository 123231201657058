import React, { useRef, useState } from 'react'
import { Box, Button, Container, withStyles } from '@material-ui/core'
import Sticky from 'react-sticky-el'

import PropertyCarousel from '../../components/Mobile/Property/PropertyCarousel'
import MapsSection from '../../components/Mobile/Property/MapsSection'
import InspectProperty from '../../components/Mobile/Property/InspectProperty'
import NearbySection from '../../components/Mobile/Property/NearbySection'
import PriceGuideSection from '../../components/Mobile/Property/PriceGuideSection'
import AgentBanner from '../../components/Mobile/Property/AgentBanner'
import ContactSection from '../../components/Mobile/Property/ContactSection'
import PropertyInfo from '../../components/Mobile/Property/PropertyInfo'
import PropertySuggestions from '../../components/Mobile/Property/PropertySuggestions'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'
import AgencyBar from '../../components/Mobile/Property/AgencyBar'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { scrollToRef } from '../../components/Mobile/ScrollEvents'
import { formatRequest } from '../../utils/search.utils'

import DynamicIcons from '../../components/Mobile/DynamicIcons'
import _, { random } from 'lodash'

/** Property Utils */
import { propertyConfigSetter } from '../../utils/property.utils'

/** api redux request */
import {
  requestPropertySave,
  requestContactAgent,
  requestPropertyDetails,
  requestPropertySuggestion,
  requestGetPropertySave,
} from '../../store/api'

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  clearCurrentProperty,
  clearPropertySuggestions,
  clearAgentContactInquiry,
} from '../../store/actions';

import { cloudinaryCardTransforms, cloudinaryImageWidth } from '../../constants/constants'
import { SaveAlt } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import AuctionInspectionTimes from 'components/Mobile/Property/AuctionInspectionTimes'

import { requestGETAPIMpp, requestPUTAPIMpp } from 'store/api/mpp.api';
import { getUser } from 'store/selectors/agencyv2.selectors';
import { getMpp } from 'store/selectors/mpp.selectors';
import { useRouterChange } from 'utils/router.utils';
import useGoogleEvents from 'utils/customHooks/useGoogleEvents';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    '& .sticky': {
      zIndex: 500,
    },
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-65px)',
  },
  cta: {
    color: theme.palette.common.black,
    borderRadius: '16px',
    textTransform: 'initial',
    padding: theme.spacing(1, 2),
    minWidth: 94,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.light.main,
    fontSize: 13,
    lineHeight: '15.6px',
    letterSpacing: '-0.25px',
  },
  priceGuideAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
})

const BackButton = withStyles({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const Property = (props) => {
  const {
    classes,
    history,
    authentication,
    settings,
    property,
    toggleSignUp,
    contactAgent,
    getPropertyDetails,
    getPropertySuggestions,
    clearCurrentProperty,
    filters,
    suggestions,
    inquiry_error,
    inquiry_status,
    loaded_property,
    clearSuggestions,
    clearInquiry,
  } = props
  const [routerChange] = useRouterChange()
  const [currentProperty, setCurrentProperty] = React.useState(null)
  const [currentSuggestions, setCurrentSuggestions] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [isNotification, setIsNotification] = React.useState(false)
  const [notificationOptions, setNotificationOptions] = React.useState(null)
  const [matchCount, setMatchCount] = React.useState(0)
  const [scrolledTop, setScrolledTop] = React.useState(0)
  const [galleryImages, setGalleryImages] = React.useState(null)
  const [floorPlan, setFloorPlan] = React.useState(false)
  const [virtualTours, setVirtualTours] = useState(null)
  const [nearbySchools, setNearbySchools] = useState(null)

  const [mergedPropertyDetails, setMergedPropertyDetails] = React.useState(null)

  const { getPropertyData, triggerAnalyticsEvent } = useGoogleEvents()

  const stickyWrapper = React.useRef(null)
  const agencyBarRef = React.useRef(null)
  const priceGuideRef = React.useRef(null)

  const MPP = useSelector(getMpp)
  const USER = useSelector(getUser)
  const dispatch = useDispatch()

  const saveProperty = (params) =>
    dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'))

  const getSavedProperties = () =>
    dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'))

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  React.useEffect(() => {
    if (MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      apiRequestGetSavedProperties()
    }
  }, [MPP])

  React.useEffect(() => {
    /**
     * Initial Scroll to Top */
    // window.scrollTo(0, 0);

    /**
     * request property details */
    if (props.match.params && props.match.params.id) {
      apiRequestPropertyDetails(props.match.params.id)
    }

    if (history.location.state && history.location.state.previous_screen) {
      localStorage.setItem('previous_screen', history.location.state.previous_screen)
    }
  }, [])

  React.useEffect(() => {
    if (props?.location?.state?.scrollToAgent && contactAgentRef?.current) {
      setTimeout(() => {
        executeScroll()
      }, 1500)
    } else {
      if (scrolledTop === 0) {
        executeTopScroll(false)
        setScrolledTop(1)
      }
    }
  }, [])

  React.useEffect(() => {
    /**
     * callback for like properties */
    // if(property.payload && property.payload.data && property.request.previous === "save_property"){
    //   apiRequestPropertyDetails(props.match.params.id);
    // }

    /**
     * load request property details combined with data form list */
    if (
      property.properties_details &&
      property.properties_details.data &&
      property.properties_details.data.data &&
      property.properties_details.data.data.property &&
      isLoading
    ) {
      setIsLoading(false)
      setCurrentProperty({
        ...property.properties_details.data.data.property,
        ...property.current_selected_property,
      })
    }

    /**
     * callback for contact agent */
    if (
      property.payload &&
      property.payload.data &&
      property.payload.data.message === 'Post request successful' &&
      isLoading
    ) {
      setIsLoading(false)
      // handleNotification(true, {message: 'Inquiry Successfully Sent', severity: 'success'})
    }

    if (
      property.request &&
      property.request.previous === 'send_agent_inquiry' &&
      property.send_agent_inquiry_error &&
      isLoading
    ) {
      setIsLoading(false)
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
    }
  }, [property])

  React.useEffect(() => {
    let theProperty = null
    /*  if (
      currentProperty &&
      loaded_property &&
      loaded_property.data &&
      loaded_property.data.data &&
      loaded_property.data.data.property
    ) {
      theProperty = { ...currentProperty, ...loaded_property };
    } else */
    if (
      loaded_property &&
      loaded_property.data &&
      loaded_property.data.data &&
      loaded_property.data.data.property
    ) {
      theProperty = loaded_property.data.data.property
    }

    if (theProperty && theProperty.images && theProperty.images.length > 0) {
      let gallery_array = []
      if (theProperty.cloudinaryImages && theProperty.cloudinaryImages.length > 0) {
        theProperty.cloudinaryImages.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageWidth + '/' + item.file
          gallery_array.push(file)
        })
      } else {
        gallery_array = _.compact(theProperty.images)
      }

      if (
        theProperty.cloudinaryFloorPlans &&
        theProperty.cloudinaryFloorPlans.length > 0
      ) {
        theProperty.cloudinaryFloorPlans.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageWidth + '/' + item.file
          gallery_array.push(file)
        })
        setFloorPlan(true)
      } else {
        if(
          theProperty.floorPlan &&
          theProperty.floorPlan.length > 0
        ) {
          gallery_array.push(theProperty?.floorPlan?.[0]?.['@_url'])
          setFloorPlan(true)
        } else {
          setFloorPlan(false)
        }
      }

      /*       if (
        theProperty.floorPlan &&
        theProperty.floorPlan[0] &&
        theProperty.floorPlan[0]['@_url']
      ) {
        //gallery_array.push(theProperty.floorPlan[0]['@_url'])
        theProperty.floorPlan.map((item) => {
          if (item?.['@_url']) {
            gallery_array.push(item?.['@_url'])
          }
        })
        setFloorPlan(true)
      } else {
        setFloorPlan(false)
      } */
      setGalleryImages(gallery_array)
    }

    if (
      theProperty &&
      theProperty.externalLinks &&
      theProperty.externalLinks.length > 0
    ) {
      const filterTours = _.groupBy(theProperty.externalLinks, 'title')
      const tours = filterTours.virtualTour
        ? filterTours.virtualTour.map((i) => i.link)
        : null

      setVirtualTours(tours)
    }

    setMergedPropertyDetails(theProperty)
  }, [currentProperty, loaded_property])

  React.useEffect(() => {
    if (mergedPropertyDetails) {
      let ALL_SCHOOLS = _.filter(
        mergedPropertyDetails?.nearbyPlaceListData?.nearbyPlaceList,
        (obj) => {
          if (
            obj.types.includes('primary_school') ||
            obj.types.includes('secondary_school') ||
            obj.types.includes('university')
          ) {
            return obj
          }
        }
      )
      setNearbySchools(ALL_SCHOOLS)

      triggerAnalyticsEvent({
        event: 'property_actions',
        eventFrom: 'Mobile',
        actionScope: 'property_visit',
        actionName: 'Property Inner Page',
        actionTarget: 'Viewed',
        actionReference: 'property',
        ...getPropertyData(mergedPropertyDetails),
      })
    }
  }, [mergedPropertyDetails])

  React.useEffect(() => {
    if (history && mergedPropertyDetails) {
      if (history.location?.state?.from === 'cardEmailIcon') {
        executeScroll(true)
      }
    }
  }, [mergedPropertyDetails])

  React.useEffect(() => {
    // GET SUGGESTIONS IF THERE'S ANY
    if (
      suggestions &&
      suggestions.data &&
      suggestions.data.data &&
      suggestions.data.data.propertiesFilters
    ) {
      setIsLoading(false)

      const objSuggestions = property.properties_suggestions.data.data.propertiesFilters
      const ALL_RENTAL = _.pickBy(
        objSuggestions,
        (e) =>
          _.includes(['rental', 'Leased', 'leased'], e.propertyStatus) &&
          e.property !== props.match.url.split('/')[2]
      )
      const ALL_SOLD = _.pickBy(
        objSuggestions,
        (e) =>
          _.includes(['sold'], e.propertyStatus) &&
          e.property !== props.match.url.split('/')[2]
      )
      const ALL_CURRENT = _.pickBy(
        objSuggestions,
        (e) =>
          _.includes(
            ['current', 'On-Sale', 'offmarket', 'comingsoon'],
            e.propertyStatus
          ) && e.property !== props.match.url.split('/')[2]
      )
      if (getRoutePath() === 'rent') {
        setCurrentSuggestions([..._.values(ALL_RENTAL)].slice(0, 4))
      } else if (getRoutePath() === 'sold') {
        setCurrentSuggestions([..._.values(ALL_SOLD)].slice(0, 4))
      } else {
        setCurrentSuggestions([..._.values(ALL_CURRENT)].slice(0, 4))
      }
    }
  }, [suggestions])

  /**
   * Get Property Details API */
  function apiRequestPropertyDetails(id) {
    setIsLoading(true)
    let params = {
      property: id,
    }
    getPropertyDetails(params)
  }
  /**
   * Get Property Suggestions API */
  async function apiRequestPropertySuggestions(filters) {
    setIsLoading(true)
    clearSuggestions()
    let path = getRoutePath()
    // let params = await formatRequest(path, filters);
    let params = {}
    params.id = props.match.params.id
    params.take = 20
    if (path === 'hush-hush') {
      params.status = ['offmarket', 'comingsoon']
    } else if (path === 'perfect-property') {
      params.status = ['buy']
    } else {
      params.status = [path.replace(/-/g, '')]
    }
    if (filters.suburbs.length > 0) {
      getPropertySuggestions(params)
    }
  }
  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    }

    saveProperty(params)
  }

  /** GET Save Property API */
  async function apiRequestGetSavedProperties() {
    getSavedProperties()
  }

  React.useEffect(() => {
    apiRequestPropertySuggestions(filters)

    return () => {
      clearSuggestions()
      clearInquiry()
    }
  }, [])

  /**
   * Contact Property Agent API */
  function apiRequestContactAgent(request) {
    clearInquiry()
    if (currentProperty.property || currentProperty._id) {
      setIsLoading(true)
      let params = {
        id: currentProperty.property ?? currentProperty._id,
        ...request,
      }
      contactAgent(params)
      handleNotification(true, { message: 'Sending your inquiry...', severity: 'info' })

      triggerAnalyticsEvent({
        event: 'property_actions',
        eventFrom: 'Mobile',
        actionScope: 'property_contact_form',
        actionName: 'Property Inner Page',
        actionTarget: 'Property enquiry form submitted',
        actionReference: 'property',
        ...getPropertyData(mergedPropertyDetails),
        contact_subject: request?.inquiry?.join('|'),
      })
    } else {
      console.debug(currentProperty, mergedPropertyDetails, 'DETAILS')
      handleNotification(true, {
        message: "We can't submit an enquiry at this moment. Try again later.",
        severity: 'error',
      })
    }
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const contactAgentRef = useRef(null)
  const executeScroll = (d) => scrollToRef(contactAgentRef, d) //add `false` as second parameter to scroll without transition.

  const topContainer = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d) //add `false` as second parameter to scroll without transition.

  const executePriceGuideScroll = (d) => scrollToRef(priceGuideRef, d) //add `false` as second parameter to scroll without transition.

  const handleMatchCount = (obj) => {
    let data = obj || property
    if (data) {
      if (data.coefficients) {
        const selectedProperty = data.coefficients
        let count = 0
        if (filters && selectedProperty) {
          filters.filters.advanceProperty.map((item, index) => {
            if (item.active) {
              if (selectedProperty[item.key] >= 0.7) {
                count++
              }
            }
          })
          // setMatchCount(count);
          return count
        }
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  return (
    <div className={classes.root} ref={stickyWrapper} id={'property-details-wrap'}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />
      <div ref={topContainer} className={classes.topAnchor}></div>
      <BackButton
        variant="text"
        disableRipple={true}
        fullWidth={false}
        onClick={() => routerChange('back', props?.match)}
      >
        <DynamicIcons icon="ChevronLeft" /> GO BACK
      </BackButton>
      <div ref={agencyBarRef}>
        {mergedPropertyDetails && mergedPropertyDetails.agent && (
          <Container style={{ padding: 0 }}>
            <Sticky
              scrollElement={'#property-details-wrap'}
              topOffset={
                stickyWrapper
                  ? stickyWrapper.offsetTop
                    ? stickyWrapper.offsetTop
                    : 0
                  : 0
              }
            >
              <AgencyBar
                property={mergedPropertyDetails}
                agency={mergedPropertyDetails.agency}
              />
            </Sticky>
          </Container>
        )}
      </div>
      {mergedPropertyDetails && galleryImages && galleryImages.length > 0 && (
        <PropertyCarousel
          properties={propertyConfigSetter('image-banner', {
            type: mergedPropertyDetails.propertyStatus,
            image: galleryImages,
          })}
          authentication={authentication}
          currentProperty={mergedPropertyDetails}
          settings={settings}
          toggleSignUp={toggleSignUp}
          apiRequestSaveProperty={apiRequestSaveProperty}
          floorPlan={floorPlan}
          virtualTours={virtualTours}
        />
      )}
      {mergedPropertyDetails && (
        <AgentBanner
          type={'property'}
          properties={mergedPropertyDetails}
          {...propertyConfigSetter(
            'agent',
            mergedPropertyDetails ? mergedPropertyDetails.subscriptionType : ''
          )}
        />
      )}

      {mergedPropertyDetails && (
        <PropertyInfo
          type={'property'}
          properties={mergedPropertyDetails}
          matchCount={handleMatchCount(mergedPropertyDetails)}
          listingType={getRoutePath()}
          filters={props.filters}
          executePriceGuideScroll={executePriceGuideScroll}
        />
      )}
      {mergedPropertyDetails &&
        mergedPropertyDetails.propertyStatus !== 'offmarket' && (
          <MapsSection
            previous={props.location.state && props.location.state.previous_screen}
            property={currentProperty}
            listingType={getRoutePath()}
          />
        )}
      <InspectProperty
        currentProperty={mergedPropertyDetails}
        executeScroll={executeScroll}
      />
      {nearbySchools?.length > 0 && (
        <NearbySection properties={mergedPropertyDetails} />
      )}

      {/*       <div ref={priceGuideRef} className={classes.priceGuideAnchor}></div>
      {mergedPropertyDetails && mergedPropertyDetails.statementOfIntent && (
        <PriceGuideSection
          properties={mergedPropertyDetails}
          executeTopScroll={executeTopScroll}
        />
      )} */}
      {mergedPropertyDetails && (
        <Box maxWidth="782px" padding="24px" paddingTop="0px">
          <AuctionInspectionTimes property={mergedPropertyDetails} />
        </Box>
      )}

      <div ref={contactAgentRef} className={classes.topAnchor}></div>
      {mergedPropertyDetails && (
        <ContactSection
          user={USER}
          properties={mergedPropertyDetails}
          executeScroll={executeScroll}
          apiRequestContactAgent={apiRequestContactAgent}
          inquiry_status={inquiry_status}
          inquiry_error={inquiry_error}
        />
      )}

      {currentSuggestions && currentSuggestions.length > 0 && (
        <PropertySuggestions
          properties={currentSuggestions}
          toggleSignUp={toggleSignUp}
          authentication={authentication}
          settings={settings}
          apiRequestSaveProperty={apiRequestSaveProperty}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
    suggestions: state.property.properties_suggestions,
    loaded_property: state.property.properties_details,
    inquiry_status: state.property.send_agent_inquiry_status,
    inquiry_error: state.property.send_agent_inquiry_error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      contactAgent: (params) => requestContactAgent(params),
      getPropertyDetails: (params) => requestPropertyDetails(params),
      getPropertySuggestions: (params) => requestPropertySuggestion(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      clearSuggestions: () => clearPropertySuggestions(),
      clearInquiry: () => clearAgentContactInquiry(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Property))
