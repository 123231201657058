import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'

import { bindActionCreators } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'

import Searchbar from '../../components/Web/InlineSearchbar'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Web/Form/AlertNotification'

import { scrollToRef } from '../../components/Web/ScrollEvents'

// import CardSwipe from '../../components/Web/CardSwipe'

import _ from 'lodash'

/** api redux request */
import {
  requestLikeProperties,
  requestPropertySave,
  requestPropertySaveSearch,
  requestSearchPerfectProperty,
  requestSendLikesDislikesProperties,
  requestSendSearchTallies,
} from '../../store/api'

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  toggleFilterWeb,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters,
  setPerfectproperty,
  clearPerfectProperty,
  clearLikedUnlikedProperties,
  addLikedDrillDownProperty,
  addUnlikedDrillDownProperty,
  setPerfectPropertyFilters,
  clearLikedProperties,
  mppActions,
  searchActions,
} from '../../store/actions'

/** Object Filters */
import { formatRequest, paramsToBase64 } from '../../utils/search.utils'
import { PRICE_RANGE } from '../../constants/constants'

import {
  Button,
  Badge,
  withStyles,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { getLikesDislikes, getMpp, getFavourites } from 'store/selectors/mpp.selectors'
import { requestPUTAPIMpp, requestGETAPIMpp } from 'store/api/mpp.api'
import {
  getAllFilters,
  getMppSearchResults,
  getMppPagination,
} from 'utils/search/selectors/selectors.search'
import { useSearch } from 'utils/search/useSearch'
import CardSwipeV4 from 'components/Web/CardSwipe/CardSwipeV4'
import { useHistory } from 'react-router-dom'
import { getAuthentication } from 'store/selectors/authentication.selectors'
import { getSettings } from 'store/selectors/superadmin.selectors'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const ValidationSearch = styled.div`
  position: absolute;
  bottom: -10px;
  left: 100px;
  border-radius: 3px;
  opacity: 0;
  background-color: rgb(17, 192, 201);
  padding: 10px;
  z-index: 9;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 0.3s ease;
  @media screen and (max-width: 1280px) {
    bottom: 50px;
  }
  &.active {
    transform: translateY(0px);
    opacity: 1;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent rgb(17, 192, 201) transparent;
  }
  p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
`
const EndLabel = styled.p`
  text-align: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  padding-top: 10%;
  z-index: 1;
`

const usestyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    paddingLeft: 90,
    paddingBottom: 30,
    position: 'relative',
  },
  content: {
    position: 'relative',
    zIndex: 1,
    userSelect: 'none',
    paddingTop: 30,
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
    paddingRight: 10,
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  cardStackContainer: {
    maxWidth: '100vw',
    margin: '0 auto 0',
    minHeight: '38.125em',
    position: 'relative',
  },
  swipeMessageContainer: {
    textAlign: 'center',
    paddingTop: 20,
  },
  dragContainer: {
    textAlign: 'center',
    padding: 30,
  },
  messageText: {
    color: '#4D4D4D',
    fontSize: '16px',
  },
  messageTextBottom: {
    color: '#4D4D4D',
    fontSize: '13px',
  },
  buttonViewContainer: {
    textAlign: 'center',

    '& .MuiBadge-badge': {
      right: 20,
    },
  },
  buttonView: {
    borderRadius: 30,
    // backgroundColor: '#35C0CA',
    textTransform: 'none',
    color: '#ffffff',
    '&:hover': {
      // backgroundColor: '#35C0CA',
    },
    '@media (max-width: 890px)': {
      fontSize: 13,
    },
    '@media (max-width: 768px)': {
      fontSize: 11,
    },
  },
  caption: {
    fontSize: 16,
    color: '#4d4d4d',
    paddingLeft: 63,
    '@media (max-width: 890px)': {
      fontSize: 13,
    },
    '@media (max-width: 768px)': {
      fontSize: 11,
    },
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
  },
  legendIcon: {
    color: 'gray',
    border: '1px solid gray',
    padding: 3,
  },
  legendText: {
    marginLeft: 10,
    color: 'gray',
    fontWeight: '400',
  },
}))

let timeout = null

const PerfectProperty = (
  //   authentication,
  //   settings,
  property,
  filters,
  toggleSignUp,
  toggleFilter,
  toggleFilterWeb,
  saveProperty,
  savePropertySearch,
  searchPerfectProperty,
  setCurrentProperty,
  setPropertySearch,
  setPerfectPropertyFilter,
  clearCurrentProperty,
  setIncludeNearbyFilter,
  setSearchFilters,
  setSearchSortingFilters,
  clearPerfectPropertySelected,
  addLikedDrillDown,
  addUnlikedDrillDown,
  getLikedProperties,
  users,
  likedPropertiesClear
) => {
  const topContainer = useRef(null)
  const contentScroll = useRef(null)

  const classes = usestyles()

  const authentication = useSelector(getAuthentication)
  const settings = useSelector(getSettings)

  const history = useHistory()

  //add `false` as second parameter to scroll without transition.

  const GET_LIKES_DISLIKES = useSelector(getLikesDislikes)
  const GET_USER_LIKES_DISLIKES = useSelector(getFavourites)
  const GET_MPP = useSelector(getMpp)
  const paginationV2 = useSelector(getMppPagination)
  let searchProperties = useSelector(getMppSearchResults)
  const globalFilters = useSelector(getAllFilters)

  const dispatch = useDispatch()

  const [isLikesDislikesSubmit, setIsLikesDislikesSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)

  const [isFiltered, setIsFiltered] = useState({ isActive: false, suburbs: null })
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [searchResultProperties, setSearchResultProperties] = useState([])
  const [initialProperties, setInitialProperties] = useState(null)
  const [isEnd, setIsEnd] = useState(false)
  const [invalidSearch, setInvalidSearch] = useState(false)
  const [combinedLikes, setCombinedLikes] = useState([])
  const [savedLikedProperties, setSavedLikedProperties] = useState(null)
  const [initializeSearch] = useSearch()

  const [renderedPropertyCards, setRenderedPropertyCards] = useState([])
  const [allPropertyCards, setAllPropertyCards] = useState([])

  const [likeCounter, setLikeCounter] = useState(0)

  const [likeDislikeHandler, setLikeDislikeHandler] = useState({
    initiateLikeDisliked: () => {},
  })

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const countLikedProperties = () => {
    if (authentication?.user_status === 'logged') {
      const likedproperties = GET_USER_LIKES_DISLIKES?.data?.likes?.map(
        (property) => property.property
      )

      if (likedproperties && GET_LIKES_DISLIKES?.likes) {
        const uniqueLikedProperties = [
          ...new Set([...likedproperties, ...GET_LIKES_DISLIKES.likes]),
        ]

        return uniqueLikedProperties.length + likeCounter
      }

      return '0'
    }

    return GET_LIKES_DISLIKES?.likes?.length + likeCounter
      ? GET_LIKES_DISLIKES?.likes?.length + likeCounter
      : '0'
  }

  // Gets previous liked and dislike properties on the search resutls
  const handleGetLikesDislikes = () => {
    let refLiked
    if (authentication?.user_status !== 'logged') {
      refLiked = GET_LIKES_DISLIKES
    } else {
      refLiked = {
        likes: [
          ...(GET_USER_LIKES_DISLIKES?.data?.likes?.map(
            (property) => property.property
          ) ?? []),
          ...(GET_LIKES_DISLIKES?.likes ?? []),
        ],
        dislikes: [
          ...(GET_USER_LIKES_DISLIKES?.data?.dislikes?.map(
            (property) => property.property
          ) ?? []),
          ...(GET_LIKES_DISLIKES?.dislikes ?? []),
        ],
      }
    }

    return refLiked
  }

  const handlePaginationV2 = () => {
    // force rerender swipe component in order to get new swiped properties
    setRenderedPropertyCards([])

    if (allPropertyCards.length - paginationV2?.pageSize <= 0) {
      const likedDislikedProperties = handleGetLikesDislikes()

      initializeSearch({
        likes: likedDislikedProperties.likes,
        dislikes: likedDislikedProperties.dislikes,
      })
    }
  }

  const executeTopScroll = (d) => scrollToRef(topContainer, d)

  const apiGETUserFavourites = () => {
    let request = {
      query: null,
      data: null,
    }
    dispatch(requestGETAPIMpp(request, 'GET_MPP_FAVOURITES', 'REQUEST'))
  }

  const clearUserFavourites = () => {
    dispatch(mppActions(null, 'GET_MPP_FAVOURITES', 'CLEAR'))
  }

  const defaultPagination = {
    pageNumber: 0,
    pageSize: 10,
    propertiesFilters: [],
    totalResults: null,
  }

  const getRoutePath = () => {
    return history.location.pathname ? history.location.pathname.split('/')[1] : ''
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const defaultSortData = (data) => {
    const propertyData = Object.assign({}, data)
    const propertiesFilters = Object.assign([], propertyData.data)
    const sortedByScore = sortData(propertiesFilters, ['score'], ['desc'])
    const sortedData = sortData(sortedByScore, ['subscriptionTypeWeight'], ['asc'])

    propertyData.data = propertiesFilters
    const pageData = {
      pageNumber: paginationV2.pageNumber,
      pageSize: paginationV2.pageSize,
      propertiesFilters: paginate(
        propertiesFilters,
        paginationV2.pageSize,
        paginationV2.pageNumber
      ),
      totalResults: propertiesFilters.length,
    }
    setSearchResultProperties(propertyData)
    dispatch(mppActions(pageData, 'MPP_PAGINATION_REFERENCE', 'UPDATE'))
  }

  const sortData = (data, type, order) => {
    const sortBy = type
    return _.orderBy(
      data,
      sortBy.map((s) => {
        return (r) => {
          return r[s] ? r[s] : ''
        }
      }),
      order
    )
  }

  const paginate = (array, page_size, page_number) => {
    const res = array
      ?.slice(page_number * page_size, page_number * page_size + page_size)
      .reverse()
    return res
  }
  var st

  const validateFilter = (params) => {
    return _.findIndex(params.perfectProperty.state, { active: true }) > -1
  }

  /**
   * Save Property API */
  function apiRequestSaveProperty(id) {
    let params = {
      propertyId: id,
    }
    saveProperty(params)
  }

  /**
   * Save Property Search API */
  function apiRequestSaveSearch(params) {
    setIsLoading(true)

    savePropertySearch(filters.suburbs)
  }

  function routeChange(path, state) {
    history.push(path, state)
  }

  function saveFilters(params) {
    if (filters && filters.filters) {
      let obj = Object.assign({}, filters.filters)
      if (params) {
        obj.propertyDetails.bedrooms = params[0].selected
        obj.propertyDetails.bathrooms = params[2].selected
        obj.propertyDetails.price.min = params[1].values[0].value.min
        obj.propertyDetails.price.max = params[1].values[0].value.max
        if (params[3].values) {
          obj.propertyType = params[3].values
          obj.propertyType.map((item, index) => {
            item.active = params[3].values[index].active
          })
        }
        setSearchFilters(obj)
      }
    }
  }

  const getSuburbLabel = () => {
    let label = ''
    if (filters.perfectProperty && filters.perfectProperty.state) {
      filters.perfectProperty.state.map((item, index) => {
        if (item.active) {
          label = getState(item.title)
        }
      })
    }

    return label
  }

  const getState = (label) => {
    let t = label.toLowerCase()
    switch (t) {
      case 'vic':
        return 'Victoria'
      case 'nsw':
        return 'New South Wales'
      case 'wa':
        return 'Western Australia'
      case 'tas':
        return 'Tasmania'
      case 'sa':
        return 'South Australia'
      case 'nt':
        return 'Northern Territory'
      case 'act':
        return 'Australian Capital Territory'
      case 'qld':
        return 'Queensland'
      default:
        return '' // default
    }
  }

  function searchPerfect() {
    let address = filters
      ? filters.perfectProperty
        ? 'in ' + getSuburbLabel()
        : ''
      : ''
    return (
      <p className={classes.caption}>
        {paginationV2 &&
          paginationV2.totalResults &&
          paginationV2.totalResults + ' properties found ' + address}
      </p>
    )
  }

  const LikeDislikeCard = (direction) => {
    console.debug(direction)
  }

  const getLikedCounter = (data) => {
    if (authentication?.token) {
      if (data?.length && GET_USER_LIKES_DISLIKES?.data?.likes?.length) {
        return data.length + GET_USER_LIKES_DISLIKES?.data?.likes?.length
      } else if (data?.length && GET_USER_LIKES_DISLIKES?.data?.likes?.length <= 0) {
        return data?.length ?? 0
      } else if (!data?.length && GET_USER_LIKES_DISLIKES?.data?.likes?.length) {
        return GET_USER_LIKES_DISLIKES?.data?.likes?.length ?? 0
      } else {
        return data?.length ?? 0
      }
    } else {
      return data?.length
    }
  }

  useEffect(() => {
    // make local like counter to 0
    setLikeCounter(0)
  }, [GET_LIKES_DISLIKES, GET_USER_LIKES_DISLIKES])

  useEffect(() => {
    if (searchProperties?.status === 'mpp/PERFECT_PROPERTY_RECOMMENDATION_REQUEST') {
      setIsLoading(true)
    } else if (
      searchProperties?.status === 'mpp/PERFECT_PROPERTY_RECOMMENDATION_SUCCESS'
    ) {
      setIsLoading(false)
      if (authentication && authentication.user_status === 'logged') {
        // likedPropertiesClear()
        // getLikedProperties({ take: 1000, skip: 0 })
        apiGETUserFavourites()
      }

      if (searchProperties?.mpp_search_results?.data) {
        const likedDislikedProperties = [
          ...handleGetLikesDislikes().likes,
          ...handleGetLikesDislikes().dislikes,
        ]

        const filteredLikesDislikes = searchProperties.mpp_search_results.data.filter(
          (property) => !likedDislikedProperties.includes(property.property)
        )

        filteredLikesDislikes.length > 0 ? setIsEnd(false) : setIsEnd(true)
      }
    }
  }, [searchProperties])

  useEffect(() => {
    if (searchProperties?.mpp_search_results?.data?.length > 0) {
      const likedDislikedProperties = [
        ...handleGetLikesDislikes().likes,
        ...handleGetLikesDislikes().dislikes,
      ]

      const filteredLikesDislikes = searchProperties.mpp_search_results.data.filter(
        (property) => !likedDislikedProperties.includes(property.property)
      )
      // const slicedArray = array.slice(0, n)

      setAllPropertyCards([...filteredLikesDislikes])
      setRenderedPropertyCards([...filteredLikesDislikes.slice(0, 10)])
    }
  }, [searchProperties])

  React.useEffect(() => {
    if (authentication && authentication.user_status === 'logged') {
      // likedPropertiesClear()
      clearUserFavourites()
      // getLikedProperties({ take: 1000, skip: 0 })
      apiGETUserFavourites()
    }
  }, [authentication])

  /* 
    handle properties callback */
  React.useEffect(() => {
    /**
     * error hanlder mpp property */
    if (searchProperties?.mpp_search_results && searchProperties.error && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }
  }, [searchProperties?.mpp_search_results])

  //Clear redux state
  useEffect(() => {
    triggerAnalyticsEvent({
      event: 'mpp_result_actions',
      eventFrom: 'Web',
      actionScope: 'mpp_recommendation_results',
      actionName: 'MPP Recommendations',
      actionTarget: 'Showed MPP recommendation results',
      actionReference: 'mpp_result',
    })
    return () => {
      if (authentication?.user_status === 'logged') {
        dispatch(mppActions(null, 'LOCAL_MPP_LIKES_DISLIKES', 'CLEAR'))
      }
      clearUserFavourites()
    }
  }, [])

  window.addEventListener('unload', function (e) {
    dispatch(mppActions(null, 'PERFECT_PROPERTY_RECOMMENDATION', 'CLEAR'))
    dispatch(mppActions(null, 'MPP_PAGINATION_REFERENCE', 'CLEAR'))
  })

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />
      <div ref={topContainer} className={classes.topAnchor}></div>
      <div style={{ position: 'relative', backgroundColor: '#ffffff' }}>
        <Searchbar
          type={'search'}
          sortType={'perfect-property'}
          showSearchBar={true}
          showFilters={true}
          routeChange={routeChange}
          authentication={authentication}
          settings={settings}
          toggleSignUp={toggleSignUp}
          toggleFilter={toggleFilter}
          toggleFilterWeb={toggleFilterWeb}
          apiRequestSaveSearch={apiRequestSaveSearch}
          setPropertySearch={setPropertySearch}
          setPerfectPropertyFilter={setPerfectPropertyFilter}
          setIncludeNearbyFilter={setIncludeNearbyFilter}
          setSearchSortingFilters={() => {}}
          filters={filters}
          saveFilters={saveFilters}
          properties={paginationV2}
          isFiltered={isFiltered}
          path={getRoutePath()}
          isSearchGrid={true}
          customGrid={4}
        />
      </div>

      {!isLoading && renderedPropertyCards.length > 0 && !isEnd && (
        <Box paddingTop="70px">
          <CardSwipeV4
            authentication={authentication}
            isDesktop={true}
            filters={filters}
            pagination={renderedPropertyCards}
            setCurrentProperty={setCurrentProperty}
            setLikeDislikeHandler={setLikeDislikeHandler}
            handlePaginationV2={handlePaginationV2}
            listingType={getRoutePath()}
            setLikeCounter={setLikeCounter}
          />
          <div className={classes.buttonViewContainer}>
            <Badge badgeContent={countLikedProperties()} color="secondary">
              <Button
                className={classes.buttonView}
                variant="contained"
                onClick={() => {
                  triggerAnalyticsEvent({
                    event: 'mpp_result_actions',
                    eventFrom: 'Web',
                    actionScope: 'save_and_view_favourites',
                    actionName: 'Save and View Favorites',
                    actionTarget: 'Save current likes and dislikes',
                    actionReference: 'mpp_result',
                    mpp_current_dislikes:
                      GET_LIKES_DISLIKES?.dislikes?.length ?? undefined,
                    mpp_current_likes: GET_LIKES_DISLIKES?.likes?.length ?? undefined,
                  })
                  if (authentication && authentication.user_status === 'logged') {
                    likeDislikeHandler.initiateLikeDisliked()
                    setTimeout(() => {
                      routeChange('/favourites')
                    }, 1000)
                  } else {
                    routeChange('/login', {
                      origin: '/favourites',
                    })
                  }
                }}
                color={'primary'}
                // disabled={!combinedLikes || combinedLikes.length === 0 }
              >
                View favourite properties
              </Button>
            </Badge>
          </div>
        </Box>
      )}

      {isEnd && (
        <Box marginTop="10%" display="flex" flexDirection="column" alignItems="center">
          <Typography style={{ marginBottom: '5%' }}>
            No more properties to swipe.
          </Typography>
          <Box>
            <Button
              style={{ marginRight: '15px' }}
              className={classes.buttonView}
              variant="contained"
              color={'secondary'}
              onClick={() => {
                triggerAnalyticsEvent({
                  event: 'mpp_result_actions',
                  eventFrom: 'Web',
                  actionScope: 'mpp_end_view_dislikes',
                  actionName: 'MPP End',
                  actionTarget: 'View Dislikes',
                  actionReference: 'mpp_result',
                })
                if (authentication?.user_status === 'logged') {
                  routeChange('/disliked-properties')
                } else {
                  routeChange('/login', {
                    origin: '/disliked-properties',
                  })
                }
              }}
            >
              Review disliked properties
            </Button>
            <Badge badgeContent={countLikedProperties()} color="secondary">
              <Button
                className={classes.buttonView}
                variant="contained"
                color={'primary'}
                onClick={() => {
                  triggerAnalyticsEvent({
                    event: 'mpp_result_actions',
                    eventFrom: 'Web',
                    actionScope: 'mpp_end_view_likes',
                    actionName: 'MPP End',
                    actionTarget: 'View Likes',
                    actionReference: 'mpp_result',
                  })
                  if (authentication && authentication.user_status === 'logged') {
                    likeDislikeHandler.initiateLikeDisliked()
                    setTimeout(() => {
                      routeChange('/favourites')
                    }, 1000)
                  } else {
                    routeChange('/login', {
                      origin: '/favourites',
                    })
                  }
                }}
              >
                View favourite properties
              </Button>
            </Badge>
          </Box>
        </Box>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      toggleFilterWeb: (checked) => toggleFilterWeb(checked),
      saveProperty: (params) => requestPropertySave(params),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchPerfectProperty: (params) => requestSearchPerfectProperty(params),
      saveLikesDislikes: (params) => requestSendLikesDislikesProperties(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      saveSearchTallies: (params) => requestSendSearchTallies(params),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setSearchSortingFilters: (params) => setSortingFilters(params),
      setPerfectPropertySelected: (params) => setPerfectproperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      clearPropertySuburb: () => clearSuburbSearch(),
      clearPerfectPropertySelected: () => clearPerfectProperty(),
      clearLikeAndUnlike: () => clearLikedUnlikedProperties(),
      addLikedDrillDown: (params) => addLikedDrillDownProperty(params),
      addUnlikedDrillDown: (params) => addUnlikedDrillDownProperty(params),
      setPerfectPropertyFilter: (params) => setPerfectPropertyFilters(params),
      getLikedProperties: (params) => requestLikeProperties(params),
      likedPropertiesClear: () => clearLikedProperties(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles()(PerfectProperty))
