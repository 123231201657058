import CustomDatePicker from 'components/Web/Filter/CustomDatePicker'
import Heading from 'components/Web/Filter/Heading'
import React from 'react'
import useFilters from 'utils/customHooks/useFilters'
const { Box } = require('@material-ui/core')

const AvailableDate = () => {
  const [getFilter, setFilter] = useFilters()
  const AVAILABLE_DATE = getFilter('available_date')

  const today = new Date()

  const handleAvailableDateChange = (value) => {
    setFilter({ key: 'available_date', value: value })
  }

  return (
    <Box>
      <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
        Available Date
      </Heading>
      <CustomDatePicker
        value={AVAILABLE_DATE}
        handleDate={handleAvailableDateChange}
        // isClearFilter={props.isClearFilter}
        minDate={today}
      />
    </Box>
  )
}

export default AvailableDate
