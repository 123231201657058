import React, { useEffect, useState } from "react";

// Components
import AlertEmailDialogBox from "../../components/Web/DialogContainer/AlertEmailDialogBox";
import AlertNotification from "../../components/Mobile/Form/AlertNotification";

// Redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getEmailLogs, getSingleEmail } from "../../store/api";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Link,
  withStyles,
} from "@material-ui/core";

// Custom style
const styles = () => ({
  root: {
    padding: "60px 120px 16px 170px",
    backgroundColor: "#fff",
    minHeight: "calc(100vh - 92px)",
    maxWidth: 1440,
    margin: "0 auto",
  },
});

// Table Columns - Headers
const columns = [
  { id: "sentDate", label: "DATA", minWidth: 100 },
  { id: "sender", label: "SENDER", minWidth: 100 },
  { id: "subject", label: "SUBJECT", minWidth: 100 },
  { id: "type", label: "MAIL TYPE", minWidth: 100 },
  { id: "id", label: "CONTENT", minWidth: 0 },
];

const createData = (id, sentDate, sender, subject, type) => {
  return { id, sentDate, sender, subject, type };
};

// Main Component
const EmailLogs = ({
  classes,
  getEmailStatus,
  getEmailLogs,
  getSingleEmail,
  getSingleEmailStatus,
}) => {
  // Table states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Dialog states
  const [dialogContent, setDialogContent] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  // Alert Notification states
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);

  // Notification handlers
  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const closeNotification = () => {
    setIsNotification(false);
  };

  // Table Items
  let rows = [];

  // Table handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGoClick = (e) => {
    const params = { reference: e.target.id };
    e.preventDefault();
    getSingleEmail({ params });
    handleNotification(true, { message: "Getting content..." });
  };

  // Get single email
  useEffect(() => {
    if (getSingleEmailStatus !== undefined && getSingleEmailStatus.ok) {
      handleNotification(true, { message: "Content received..." });
      setShowDialog(!showDialog);
    }
    if (getSingleEmailStatus && getSingleEmailStatus.ok) {
      setDialogContent(getSingleEmailStatus.mailHistory.mailContent);
    }
  }, [getSingleEmailStatus]);

  // Get email history list on intial render
  useEffect(() => {
    const getLogs = async () => {
      const result = await getEmailLogs();
      return result;
    };
    getLogs();
  }, []);

  // Create table from returned data from getLogs
  // Tried to put it on useEffect, data not showing
  if (getEmailStatus && getEmailStatus !== undefined) {
    let id, sentDate, sender, subject, type;
    for (let i = 0; i < getEmailStatus.data.length; i++) {
      let dateFormat = new Date(getEmailStatus.data[i].mailSentDateTimestamp);
      let idFormat = (
        <Link onClick={handleGoClick} href="#" id={getEmailStatus.data[i]._id}>
          View
        </Link>
      );

      id = idFormat;
      sentDate = dateFormat.toISOString().substring(0, 10);
      sender = getEmailStatus.data[i].mailReceiverAddress;
      subject = getEmailStatus.data[i].mailSubject;
      type = getEmailStatus.data[i].mailType;
      rows.unshift(createData(id, sentDate, sender, subject, type));
    }
  }

  return (
    <div className={classes.root}>
      {/* Alert Notification */}
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
        style={{
          position: "fixed",
          zIndex: 9999,
          top: "25vh",
          width: "100%",
        }}
      />
      {/* Dialog Box */}
      <AlertEmailDialogBox
        isOpen={showDialog}
        content={dialogContent}
        handleClick={setShowDialog}
      />
      {/* Table */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, idx) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={`${row.id}-${idx}`} // row.id creates an error - added index number as key
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    getEmailStatus: state.emailHistoryReducer.data,
    getSingleEmailStatus: state.getSingleEmailReducer.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmailLogs: () => getEmailLogs(),
      getSingleEmail: (params) => getSingleEmail(params),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EmailLogs));
