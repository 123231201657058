import React, { useState, useEffect } from 'react'
import { Paper, Typography, Button, TextField, withStyles } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import AlertNotification from 'components/Web/Form/AlertNotification'
import { Loader } from 'components/Common'

/** redux */
import { connect, useDispatch } from 'react-redux'

/** api */
import { requestAPIUserLogin } from '../../../store/api'
import { clearLoginStates } from 'store/actions'
import { useRouterChange } from 'utils/router.utils'

/** Default input */
const FormInput = (props) => (
  <TextField {...props} fullWidth id="outlined-basic" variant="outlined" />
)

/** Custom style */
const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    width: 450,
    minHeight: 450,
    borderRadius: 20,
    paddingTop: 30,
    paddingBottom: 30,
  },
  content: {
    paddingTop: 20,
    paddingRight: 40,
    paddingLeft: 40,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
    },
    marginTop: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
  },
  button: {
    borderRadius: 20,
    textTransform: 'capitalize',
    minWidth: 150,
    marginBottom: 10,
  },
  forgotButton: {
    borderRadius: 20,
    textTransform: 'capitalize',
    fontSize: 12,
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subtitle: {
    fontWeight: 300,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px',
  },
})

/** Validation */
const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
})

/** Main component */
const AgentLoginForm = ({ classes, authentication }) => {
  const [routerChange] = useRouterChange();
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  /** Form set-up, validation integration and submit handler */
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => submitLogin(values),
  })

  /** Close alert notification */
  const closeNotification = () => setIsNotification(false)

  const submitLogin = (values) => {
    setLoading(true)
    dispatch(requestAPIUserLogin({ ...values, query: '?userRole=Agent' }))
  }

  useEffect(() => {
    handleClearLoginStates()
    return () => {
      handleClearLoginStates()
    }
  }, [])

  /** Every authentication change */
  useEffect(() => {
    /** Redirect after successful login */
    if (authentication && authentication.user_status === 'logged') {
      routerChange('/agent/dashboard')
      setLoading(false)
    }

    /** If login fails */
    if (authentication && authentication.login_status === 'error') {
      setIsNotification(true)
      setNotificationOptions({
        message: 'Email or password is incorrect, please try again..',
        severity: 'error',
      })
      setLoading(false)
    }
  }, [authentication])

  const handleClearLoginStates = () => {
    dispatch(clearLoginStates())
  }

  /** Initial render */
  useEffect(() => setIsNotification(isNotification), [])

  return (
    <Paper className={classes.root}>
      <Loader isLoading={loading} />
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />

      <div className={classes.content}>
        <Typography className={classes.title} variant={'h1'}>
          Log In
        </Typography>
        <Typography className={classes.subtitle} variant={'body2'}>
          Log In using your email
        </Typography>
        <div className={classes.form}>
          <form onSubmit={formik.handleSubmit}>
            <FormInput
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormInput
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                color={'primary'}
                variant="contained"
                type="submit"
              >
                Sign In
              </Button>
              <Button
                className={classes.forgotButton}
                color={'inherit'}
                variant="text"
                onClick={() => routerChange('/agent/forgot-password')}
              >
                Forgot your password?
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Paper>
  )
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
})

export default connect(mapStateToProps, null)(withStyles(styles)(AgentLoginForm))
