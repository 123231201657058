/** APP CONFIG */
export const APP_MODE = process.env.REACT_APP_API_MODE;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const GOOGLE_MAP_API_KEY = process?.env?.REACT_APP_GOOGLE_MAP_API_KEY;

/** STYLE CONFIG */
export const fontUrl =
  'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap'
export const headingFont = 'Montserrat, sans-serif'
export const fontFamily = 'Roboto, sans-serif'

/* WEBSOCKET NOTIFICATION CONFIG */

export const NOTIFICATION_PORT = 7777
export const NOTIFICATION_API = `${APP_MODE !== 'local'
  ? 'https://pmate-dev-websockets.azurewebsites.net'
  : 'http://localhost'
  }`
export const NOTIFICATION_ENDPOINT = `${APP_MODE !== 'local' ? NOTIFICATION_API : NOTIFICATION_API + ':' + NOTIFICATION_PORT
  }`
