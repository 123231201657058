import React, { useState, useEffect } from 'react'
import SavedCardsInfo from './SavedCardsInfo'

import styled from 'styled-components'

import {
  withStyles,
  Modal,
  Typography,
  CardContent,
  Card,
  CardActions,
  Button,
  Tooltip,
} from '@material-ui/core'
import { FiltersActions } from 'store/actions'
import { useDispatch } from 'react-redux'
import { useSearch } from 'utils/search/useSearch'
import { searchActions } from 'store/actions'

import CustomCheckbox from '../Filter/CustomCheckbox'

const styles = (theme) => ({
  root: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    padding: theme.spacing(1)
  },
  textField: {
    width: '100%',
  },
  deleteContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
  },
  headContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  nameContainer: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  deleteButton: {
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#000',
    fontWeight: 'normal',
    boxShadow: 'none',
    float: 'right',
  },
  emailContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    paddingLeft: theme.spacing(1),
  },
  cardBody: {
    paddingBottom: 0,
  },
  cardContent: {
    borderTop: '0.5px solid rgb(106, 106, 106)'
  },
  horizontalLine: {
    borderTop: '2px solid #35C0CA',
    width: '8vw',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

const Link = styled.a`
  display: block;
  text-decoration: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
`
const CustomCheckboxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  label {
    margin:0;
  }
  span {
    white-space: normal;
    padding-left: 0;
    font-size: 14px
  }
`

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#4D4D4D',
    fontSize: '14px',
    lineHeight: '1.19',
    paddingBottom: '10px',
  },
})(Typography)

const SavedCards = (props) => {
  const { classes, value, path, apiRequestDeleteSavedSearch, apiRequestPutSavedSearch } = props
  const [initiateSearch] = useSearch()

  const [linkClicked, setLinkClicked] = useState(false)
  const [notify, setNotify] = useState(false)

  const dispatch = useDispatch()

  const handleSavedSearch = () => {
    dispatch(
      FiltersActions({ data: value.parameters }, 'GENERAL_FILTERS', 'SET_ALL_FILTER')
    )

    // dispatch(searchActions(null, 'GENERAL_SEARCH', 'RESET_STATES'))
    // initiateSearch()
    setLinkClicked(true)
  }

  useEffect(() => {
    if (value?.parameters) {
      if (linkClicked) {
        initiateSearch()
      }
    }
    if (value?.notifyUser) {
      setNotify(value?.notifyUser)
    }
  }, [linkClicked, value?.parameters])

  return (
    <Card className={classes.root}>
      <CardActions>
        <div className={classes.deleteContainer}>
          <div className={classes.headContainer}>
          <Tooltip title={value.name}>
            <Typography className={classes.nameContainer} variant="body1" component="h6">
              {value && value.name && value.name}
            </Typography>
          </Tooltip>
            <div className={classes.horizontalLine}></div>
          </div>
          <Button
            className={classes.deleteButton}
            size="small"
            onClick={() => apiRequestDeleteSavedSearch(value._id)}
          >
            Delete
          </Button>
        </div>
      </CardActions>
      <CardContent>
        <Link
          href="#"
          onClick={(e) => {
            e.preventDefault()

            handleSavedSearch()
          }}
        >
          {value.parameters && (
            <SavedCardsInfo path={path} value={{ ...value.parameters, ...value }} />
          )}
        </Link>
        <div className={classes.cardContent}></div>
      </CardContent>
      <CardActions>
        <div className={classes.emailContainer}>
          <CustomCheckboxWrap>
            <CustomCheckbox
              label={'Email me properties that match this search'}
              checked={notify ?? false}
              onChange={(e) => {
                setNotify(!notify)
                apiRequestPutSavedSearch(value._id, !notify)
              }}
            />
          </CustomCheckboxWrap>
        </div>
      </CardActions>
    </Card>
  )
}

export default withStyles(styles)(SavedCards)
