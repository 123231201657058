import React, { useEffect, useRef, useState } from 'react';
import Sticky from 'react-sticky-el';

import PropertyCarousel from '../../components/Web/Property/PropertyCarousel';
import MapsSection from '../../components/Web/Property/MapsSection';
import InspectProperty from '../../components/Web/Property/InspectProperty';
import NearbySection from '../../components/Web/Property/NearbySection';
import PriceGuideSection from '../../components/Web/Property/PriceGuideSection';
import ContactSection from '../../components/Web/Property/ContactSection';
import PropertyInfo from '../../components/Web/Property/PropertyInfo';
import PropertySuggestions from '../../components/Web/Property/PropertySuggestions';
import Loader from '../../components/Mobile/Loader';
import AlertNotification from '../../components/Mobile/Form/AlertNotification';
import AgentCards from '../../components/Web/Agent/AgentCards';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { scrollToRef } from '../../components/Mobile/ScrollEvents';

import DynamicIcons from '../../components/Mobile/DynamicIcons';
import _, { random } from 'lodash';
import { cloudinaryCardTransforms, cloudinaryImageWidth } from '../../constants/constants';

/** Property Utils */
import { propertyConfigSetter } from '../../utils/property.utils';

/** api redux request */
import {
  requestPropertySave,
  requestContactAgent,
  requestPropertyDetails,
  requestPropertySuggestion,
  requestGetPropertySave,
} from '../../store/api';

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  clearCurrentProperty,
  clearPropertyPayload,
  clearInquiryData,
  setStatView,
  setStatisticsPool,
  clearPropertySuggestions,
  clearAgentContactInquiry,
} from '../../store/actions';
import CrumbsBack from '../../components/Web/Header/CrumbsBack';
import { formatRequest } from '../../utils/search.utils';
import { Container, withStyles, Button, Box } from '@material-ui/core';
import AgencyBar from '../../components/Web/Property/AgencyBar';
import Crumbs from '../../components/Web/Header/Crumbs';
import AuctionInspectionTimes from 'components/Web/Property/AuctionInspectionTimes';

import { getUser } from 'store/selectors/agencyv2.selectors';
import { getMpp, getStatus, getErrors } from 'store/selectors/mpp.selectors';
import { requestGETAPIMpp, requestPUTAPIMpp } from 'store/api/mpp.api';
import { mppActions } from 'store/actions';

import useGoogleEvents from 'utils/customHooks/useGoogleEvents';
import { Helmet } from 'react-helmet';
import SEO from 'components/Common/SEO';

const styles = (theme) => ({
  root: {
    height: 'auto !important',
    width: '100%',
    paddingLeft: 100,
    overflowY: 'auto',
    // paddingTop: 135,

    '& .sticky': {
      zIndex: 999,
    },
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-135px)',
  },
  pageSections: {
    maxWidth: 1157,
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    '& .sticky-container .sticky': {
      zIndex: '998 !important',
    },
    '& .sticky-agent-card': {
      zIndex: 9,
      // '@media (max-width: 1240px) ': {
      //   display: 'none',
      // },
    },
  },
  crumbsWrap: {
    padding: theme.spacing(2, 2, 2, 2),
  },

  agentsCardSticky: {
    [theme.breakpoints.down(768)]: {
      display: 'none',
    },
  },
});

const BackButton = withStyles({
  root: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    paddingLeft: 0,
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const Property = (props) => {
  const {
    classes,
    history,
    authentication,
    settings,
    property,
    toggleSignUp,
    contactAgent,
    getPropertyDetails,
    getPropertySuggestions,
    clearCurrentProperty,
    save_property_status,
    save_property_error,
    filters,
    suggestions,
    inquiry_error,
    inquiry_status,
    requestClearInquiryData,
    requestSetStatView,
    saveStatToPool,
    statistics_pool,
    loaded_property,
    clearSuggestions,
    clearInquiry,
  } = props;

  const [currentProperty, setCurrentProperty] = useState(null);
  const [currentSuggestions, setCurrentSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);
  const [matchCount, setMatchCount] = useState(0);
  const [galleryImages, setGalleryImages] = useState(null);
  const [floorPlan, setFloorPlan] = useState(false);
  const [mergedPropertyDetails, setMergedPropertyDetails] = useState(null);
  const [virtualTours, setVirtualTours] = useState(null);
  const [nearbySchools, setNearbySchools] = useState(null);

  const stickyWrapper = useRef(null);
  const agencyBarRef = useRef(null);
  const stickyAgentRef = useRef(null);

  const dispatch = useDispatch();

  const USER = useSelector(getUser);

  //MPP
  const MPP = useSelector(getMpp);
  const getSavedProperties = () => dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'));
  const saveProperty = (params) => dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'));

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : '';
  };

  const { getPropertyData, triggerAnalyticsEvent } = useGoogleEvents();

  useEffect(() => {
    if (MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      apiRequestGetSavedProperties();
    }
  }, [MPP]);

  useEffect(() => {
    /**
     * Initial Scroll to Top */
    window.scrollTo(0, 0);
    document.getElementById('scroll-container').style.height = 'auto !important';
    document.getElementById('scroll-container').style.overflowY = 'unset';

    /**
     * request property details */
    if (props.match.params && props.match.params.id) {
      apiRequestPropertyDetails(props.match.params.id);
    }

    if (history.location.state && history.location.state.previous_screen) {
      localStorage.setItem('previous_screen', history.location.state.previous_screen);
    }
    requestClearInquiryData();

    return () => {
      clearPropertyPayload();
      requestClearInquiryData();
    };
  }, []);

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.scrollToAgent) {
      executeScroll();
    } else {
      executeTopScroll(false);
    }
  }, [props]);

  useEffect(() => {
    /**
     * callback for like properties */
    // if(property.payload && property.payload.data && property.request.previous === "save_property"){
    //   apiRequestPropertyDetails(props.match.params.id);
    // }

    /**
     * load request property details combined with data form list */
    if (
      property.properties_details &&
      property.properties_details.data &&
      property.properties_details.data.data &&
      property.properties_details.data.data.property &&
      isLoading
    ) {
      setIsLoading(false);
      setCurrentProperty({
        ...property.properties_details.data.data.property,
        ...property.current_selected_property,
      });
    }

    /**
     * callback for contact agent */
    if (
      property.payload &&
      property.payload.data &&
      property.payload.data.message === 'Post request successful' &&
      isLoading
    ) {
      setIsLoading(false);
      // handleNotification(true, {message: 'Inquiry Successfully Sent', severity: 'success'})
    }

    if (
      property.request &&
      property.request.previous === 'send_agent_inquiry' &&
      property.send_agent_inquiry_error &&
      isLoading
    ) {
      setIsLoading(false);
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      });
    }
  }, [property]);

  useEffect(() => {
    let theProperty = null;
    /*  if (
      currentProperty &&
      loaded_property &&
      loaded_property.data &&
      loaded_property.data.data &&
      loaded_property.data.data.property
    ) {
      theProperty = { ...currentProperty, ...loaded_property };
    } else */
    if (loaded_property && loaded_property.data && loaded_property.data.data && loaded_property.data.data.property) {
      theProperty = loaded_property.data.data.property;
    }

    if (theProperty && theProperty.images && theProperty.images.length > 0) {
      let gallery_array = [];
      if (theProperty.cloudinaryImages && theProperty.cloudinaryImages.length > 0) {
        theProperty.cloudinaryImages.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageWidth + '/' + item.file;
          gallery_array.push(file);
        });
      } else {
        gallery_array = _.compact(theProperty.images);
      }

      if (theProperty.videoLink) {
        gallery_array.push(theProperty.videoLink);
      }

      if (theProperty.cloudinaryFloorPlans && theProperty.cloudinaryFloorPlans.length > 0) {
        theProperty.cloudinaryFloorPlans.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageWidth + '/' + item.file;
          gallery_array.push(file);
        });
        setFloorPlan(true);
      } else {
        if (theProperty.floorPlan && theProperty.floorPlan.length > 0) {
          if (typeof theProperty.floorPlan[0]['@_url'] !== 'undefined') {
            gallery_array.push(theProperty.floorPlan[0]['@_url']);
          }
          setFloorPlan(true);
        } else {
          setFloorPlan(false);
        }
      }

      /*       if (
        theProperty.floorPlan &&
        theProperty.floorPlan[0] &&
        theProperty.floorPlan[0]['@_url']
      ) {
        //gallery_array.push(theProperty.floorPlan[0]['@_url'])
        theProperty.floorPlan.map((item) => {
          if (item?.['@_url']) {
            gallery_array.push(item?.['@_url'])
          }
        })
        setFloorPlan(true)
      } else {
        setFloorPlan(false)
      } */
      setGalleryImages(gallery_array);
    }

    if (theProperty && theProperty.externalLinks && theProperty.externalLinks.length > 0) {
      const filterTours = _.groupBy(theProperty.externalLinks, 'title');
      const tours = filterTours.virtualTour ? filterTours.virtualTour.map((i) => i.link) : null;

      setVirtualTours(tours);
    }

    setMergedPropertyDetails(theProperty);
  }, [currentProperty, loaded_property]);

  useEffect(() => {
    if (mergedPropertyDetails) {
      let ALL_SCHOOLS = _.filter(mergedPropertyDetails?.nearbyPlaceListData?.nearbyPlaceList, (obj) => {
        if (
          obj.types.includes('primary_school') ||
          obj.types.includes('secondary_school') ||
          obj.types.includes('university')
        ) {
          return obj;
        }
      });
      setNearbySchools(ALL_SCHOOLS);

      triggerAnalyticsEvent({
        event: 'property_actions',
        eventFrom: 'Web',
        actionScope: 'property_visit',
        actionName: 'Property Inner Page',
        actionTarget: 'Viewed',
        actionReference: 'property',
        ...getPropertyData(mergedPropertyDetails),
      });
    }
  }, [mergedPropertyDetails]);

  useEffect(() => {
    if (inquiry_status === 'success' && !inquiry_error) {
      handleNotification(true, { message: 'Inquiry Successful!', severity: 'success' });
    } else if (inquiry_error && inquiry_status === 'fail') {
      handleNotification(true, {
        message: 'We encountered error sending your inquiry. Try again.',
        severity: 'error',
      });
    }
  }, [inquiry_status, inquiry_error]);

  useEffect(() => {
    // GET SUGGESTIONS IF THERE'S ANY
    if (suggestions && suggestions.data && suggestions.data.data && suggestions.data.data.propertiesFilters) {
      setIsLoading(false);

      const objSuggestions = property.properties_suggestions.data.data.propertiesFilters;
      const ALL_RENTAL = _.pickBy(
        objSuggestions,
        (e) =>
          _.includes(['rental', 'Leased', 'leased'], e.propertyStatus) && e.property !== props.match.url.split('/')[2],
      );
      const ALL_SOLD = _.pickBy(
        objSuggestions,
        (e) => _.includes(['sold'], e.propertyStatus) && e.property !== props.match.url.split('/')[2],
      );
      const ALL_CURRENT = _.pickBy(
        objSuggestions,
        (e) =>
          _.includes(['current', 'On-Sale', 'offmarket', 'comingsoon'], e.propertyStatus) &&
          e.property !== props.match.url.split('/')[2],
      );
      if (getRoutePath() === 'rent') {
        setCurrentSuggestions([..._.values(ALL_RENTAL)].slice(0, 4));
      } else if (getRoutePath() === 'sold') {
        setCurrentSuggestions([..._.values(ALL_SOLD)].slice(0, 4));
      } else {
        setCurrentSuggestions([..._.values(ALL_CURRENT)].slice(0, 4));
      }
    }
  }, [suggestions]);

  /**
   * Get Property Details API */
  function apiRequestPropertyDetails(id) {
    setIsLoading(true);
    let params = {
      property: id,
    };
    getPropertyDetails(params);
  }
  /**
   * Get Property Suggestions API */
  async function apiRequestPropertySuggestions(filters) {
    setIsLoading(true);
    clearSuggestions();
    let path = getRoutePath();
    // let params = await formatRequest(path, filters);
    let params = {};
    params.id = props.match.params.id;
    params.take = 20;
    if (path === 'hush-hush') {
      params.status = ['offmarket', 'comingsoon'];
    } else if (path === 'perfect-property') {
      params.status = ['buy'];
    } else {
      params.status = [path.replace(/-/g, '')];
    }
    if (filters.suburbs.length > 0) {
      getPropertySuggestions(params);
    }
  }

  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    };

    saveProperty(params);
  }

  useEffect(() => {
    apiRequestPropertySuggestions(filters);
    return () => {
      clearSuggestions();
      clearInquiry();
    };
  }, []);

  useEffect(() => {
    if (history && mergedPropertyDetails) {
      if (history.location?.state?.from === 'cardEmailIcon') {
        executeScroll(true);
      }
    }
  }, [mergedPropertyDetails]);

  /**
   * Contact Property Agent API */
  function apiRequestContactAgent(request) {
    if (currentProperty.property || currentProperty._id) {
      setIsLoading(true);
      let params = {
        id: currentProperty.property ?? currentProperty._id,
        ...request,
      };
      contactAgent(params);
      handleNotification(true, { message: 'Sending your inquiry...', severity: 'info' });
      triggerAnalyticsEvent({
        event: 'property_actions',
        eventFrom: 'Web',
        actionScope: 'property_contact_form',
        actionName: 'Property Inner Page',
        actionTarget: 'Property enquiry form submitted',
        actionReference: 'property',
        ...getPropertyData(mergedPropertyDetails),
        contact_subject: request?.inquiry?.join('|'),
      });
    } else {
      handleNotification(true, {
        message: "We can't submit an enquiry at this moment. Try again later.",
        severity: 'error',
      });
    }
  }

  /** GET Save Property API MPP */
  async function apiRequestGetSavedProperties() {
    getSavedProperties();
  }

  function routeBack() {
    let previousScreen = localStorage.getItem('previous_screen');
    let previousPath = localStorage.getItem('previous_path');
    if (previousScreen === getRoutePath()) {
      props.history.push('/' + getRoutePath());
    } else if (previousPath === getRoutePath()) {
      props.history.push('/' + getRoutePath());
    }
    // if(previousScreen === 'liked-properties'){
    //   props.history.push('/perfect-property/liked-properties');
    // } else if(previousScreen === 'liked-properties-map'){
    //   props.history.push('/perfect-property/liked-properties-map');
    // }else if(previousScreen === getRoutePath()){
    //   props.history.push('/' + getRoutePath());
    // }
  }

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const closeNotification = () => {
    setIsNotification(false);
  };

  const contactAgentRef = useRef(null);
  const executeScroll = (d) => scrollToRef(contactAgentRef, d);
  //add `false` as second parameter to scroll without transition.

  const priceGuideRef = useRef(null);
  const executeScrollPriceGuide = (d) => scrollToRef(priceGuideRef, d); //add `false` as second parameter to scroll without transition.

  const topContainer = useRef(null);
  const executeTopScroll = (d) => {
    return;
  }; //add `false` as second parameter to scroll without transition.

  const handleMatchCount = (obj) => {
    let data = obj || property;
    if (data) {
      if (data.coefficients) {
        const selectedProperty = data.coefficients;
        let count = 0;
        if (filters && selectedProperty) {
          filters.filters.advanceProperty.map((item, index) => {
            if (item.active) {
              if (selectedProperty[item.key] >= 0.7) {
                count++;
              }
            }
          });
          // setMatchCount(count);
          return count;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const apiRequestSetStatView = (data, stat) => {
    const params = {
      property: data.property,
    };

    requestSetStatView(params, stat);
  };

  const requestSaveStatToPool = (args) => {
    let params = {
      property: currentProperty.property,
    };

    if (statistics_pool && Object.keys(statistics_pool).length > 0) {
      params = {
        ...params,
        [params.property]: {
          ...params.property,
          ...args,
        },
      };
    }
  };

  const previousRoute = () => {
    const backPath = props.history.location.pathname.split('/')[1];

    if (props?.history?.location?.state?.previous_screen === '/') {
      return '/';
    }

    return 'back';
  };

  const getCrumbs = () => {
    let crumbs;
    crumbs = [
      { label: getRoutePath(), route: '/' + getRoutePath() },
      { label: 'Property listing', route: null },
    ];

    if (props?.history?.location?.state?.previous_screen === '/') {
      crumbs = [{ label: 'Property listing', route: null }];
    }

    return crumbs;
  };

  let currentPpty = mergedPropertyDetails;

  const metaDescriptionPropertyStatuses = (propertyStatus) => {
    switch (propertyStatus) {
      case 'On-Sale':
        return 'for Sale';
      case 'rental':
        return 'for Rent';
      default:
        return 'Sold Property';
    }
  };

  const metaDescription = `${currentPpty?.addressText} ${currentPpty?.propertyType} ${metaDescriptionPropertyStatuses(
    currentPpty?.propertyStatus,
  )} ${currentPpty?.bedrooms} bedrooms ${currentPpty?.bathrooms} bathrooms ${currentPpty?.description}`;

  return (
    <div className={classes.root} ref={stickyWrapper} id={'property-details-wrap'}>
      <SEO title={mergedPropertyDetails?.addressText} description={metaDescription} />
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
        style={{
          position: 'fixed',
          zIndex: 9999,
          top: '25vh',
          width: '100%',
        }}
      />
      <Loader isLoading={isLoading} />
      <div ref={topContainer} className={classes.topAnchor}></div>
      <Container>
        <div className={classes.crumbsWrap}>
          <Crumbs
            type={'DARK'}
            crumbsOption={getCrumbs()}
            backButton={true}
            previousRoute={previousRoute()}
            previousRouteText={
              props.history &&
                props.history.location &&
                props.history.location.state &&
                props.history.location.state.previous_screen === '/'
                ? 'Back to Home'
                : 'Back to Property Search'
            }
            match={props?.match}
          />
        </div>

        {/*         <div>
          <BackButton
            variant="text"
            disableRipple={true}
            fullWidth={false}
            onClick={() => {
              const backPath = props.history.location.pathname.split('/')[1]
              if (backPath === 'buy' || backPath === 'rent' || backPath === 'sold') {
                return props.history.push('/' + backPath)
              }
              return props.history.goBack()
            }}
          >
            {props.history &&
            props.history.location &&
            props.history.location.state &&
            props.history.location.state.previous_screen === '/'
              ? '< Back to Home'
              : '< Back to Property Search'}
          </BackButton>
        </div> */}
      </Container>
      <div ref={agencyBarRef}>
        {mergedPropertyDetails && mergedPropertyDetails.agent && (
          <Container style={{ marginTop: '1px' }}>
            <Sticky
              scrollElement={window.body}
              topOffset={stickyWrapper ? (stickyWrapper.offsetTop ? stickyWrapper.offsetTop : 0) : 0}
            >
              <AgencyBar property={mergedPropertyDetails} agency={mergedPropertyDetails.agency} />
            </Sticky>
          </Container>
        )}
      </div>
      {mergedPropertyDetails && galleryImages && galleryImages.length > 0 && (
        <Container style={{ marginTop: '1px' }}>
          <PropertyCarousel
            properties={propertyConfigSetter('image-banner', {
              type: mergedPropertyDetails.propertyStatus,
              image: galleryImages,
            })}
            authentication={authentication}
            currentProperty={mergedPropertyDetails}
            settings={settings}
            toggleSignUp={toggleSignUp}
            apiRequestSaveProperty={apiRequestSaveProperty}
            executeScroll={executeScroll}
            apiRequestSetStatView={apiRequestSetStatView}
            floorPlan={floorPlan}
            virtualTours={virtualTours}
          />
        </Container>
      )}
      <div className={classes.pageSections}>
        <div className="sticky-container">
          {/*           <div className={classes.crumbsWrap}>
            <Crumbs
              type={'DARK'}
              crumbsOption={[
                { label: getRoutePath(), route: '/' + getRoutePath() },
                { label: 'Property listing', route: null },
              ]}
              
            />
          </div> */}
          <div className={classes.agentsCardSticky} ref={stickyAgentRef} style={{ zIndex: 991, position: 'relative' }}>
            <Sticky
              boundaryElement=".sticky-container"
              bottomOffset={
                stickyAgentRef.current && stickyAgentRef.current.scrollHeight > 0
                  ? stickyAgentRef.current.scrollHeight + 120
                  : 450
              }
              hideOnBoundaryHit={false}
            >
              {mergedPropertyDetails && (
                <div style={{ width: '325px', top: 70, position: 'absolute', right: 0 }} className="sticky-agent-card">
                  <AgentCards
                    agent={mergedPropertyDetails}
                    type={'agent-card'}
                    sticky={true}
                    executeScroll={executeScroll}
                  />
                </div>
              )}
            </Sticky>
          </div>

          {mergedPropertyDetails && (
            <PropertyInfo
              type={'property'}
              properties={mergedPropertyDetails}
              matchCount={handleMatchCount(mergedPropertyDetails)}
              listingType={getRoutePath()}
              filters={props.filters}
              isSticky={true}
              agencyBarOffset={stickyWrapper ? (stickyWrapper.offsetTop ? stickyWrapper.offsetTop : 0) : 0}
              stickyWrapperId={'#property-details-wrap'}
              executeScroll={executeScroll}
              executeScrollPriceGuide={executeScrollPriceGuide}
            />
          )}
          <div style={{ maxWidth: 782, width: '100%' }}>
            {mergedPropertyDetails && mergedPropertyDetails.propertyStatus !== 'offmarket' && (
              <MapsSection
                previous={props.location.state && props.location.state.previous_screen}
                property={mergedPropertyDetails}
                listingType={getRoutePath()}
              />
            )}

            <InspectProperty executeScroll={executeScroll} />
            {nearbySchools?.length > 0 && <NearbySection properties={mergedPropertyDetails} />}
            {/*             <div ref={priceGuideRef} className={classes.topAnchor}></div>
            {mergedPropertyDetails && mergedPropertyDetails.statementOfIntent && (
              <PriceGuideSection
                properties={mergedPropertyDetails}
                executeTopScroll={executeTopScroll}
              />
            )} */}
          </div>
        </div>
        {mergedPropertyDetails && (
          <Box maxWidth="782px" paddingY="15px">
            <AuctionInspectionTimes property={mergedPropertyDetails} />
          </Box>
        )}
        <div style={{ maxWidth: 782, width: '100%' }}>
          <div ref={contactAgentRef} className={classes.topAnchor}></div>
          {mergedPropertyDetails && (
            <ContactSection
              user={USER}
              properties={mergedPropertyDetails}
              executeScroll={executeScroll}
              apiRequestContactAgent={apiRequestContactAgent}
              apiRequestSetStatView={apiRequestSetStatView}
              inquiry_status={inquiry_status}
              inquiry_error={inquiry_error}
            />
          )}
        </div>
      </div>
      {currentSuggestions && currentSuggestions.length > 0 && (
        <div style={{ backgroundColor: '#f5f5f5', paddingBottom: 70 }}>
          <div style={{ maxWidth: 1124, margin: '0 auto' }}>
            <PropertySuggestions
              properties={currentSuggestions}
              toggleSignUp={toggleSignUp}
              authentication={authentication}
              settings={settings}
              apiRequestSaveProperty={apiRequestSaveProperty}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
    save_property_status: state.property.property_save_property_status,
    save_property_error: state.property.property_save_property_error,
    suggestions: state.property.properties_suggestions,
    inquiry_status: state.property.send_agent_inquiry_status,
    inquiry_error: state.property.send_agent_inquiry_error,
    statistics_pool: state.statistics.stat_pool,
    loaded_property: state.property.properties_details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      contactAgent: (params) => requestContactAgent(params),
      getPropertyDetails: (params) => requestPropertyDetails(params),
      getPropertySuggestions: (params) => requestPropertySuggestion(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      requestClearInquiryData: () => clearInquiryData(),
      requestSetStatView: (params, stat) => setStatView(params, stat),
      saveStatToPool: (params) => setStatisticsPool(params),
      clearSuggestions: () => clearPropertySuggestions(),
      clearInquiry: () => clearAgentContactInquiry(),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Property));
