import { 
    REQUEST_UPLOAD_IMAGE,
    REQUEST_UPLOAD_IMAGE_SUCCESS,
    REQUEST_UPLOAD_IMAGE_FAIL,
    CLEAR_UPLOAD_IMAGE
} from '../constants/uploadImage.constants';

export const uploadImage = (data, target) => {
    return {
        type: REQUEST_UPLOAD_IMAGE,
        params: data,
        target: target
    };
};
export const uploadImageSuccess = (data, target) => {
    return {
        type: REQUEST_UPLOAD_IMAGE_SUCCESS,
        params: data,
        target: target
    };
};
export const uploadImageFail = (data, target) => {
    return {
        type: REQUEST_UPLOAD_IMAGE_FAIL,
        params: data,
        target: target
    };
};

export const clearUploadImage = () => {
    return {
        type: CLEAR_UPLOAD_IMAGE
    };
};
