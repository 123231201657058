import React, { useState, useEffect } from "react";
import styled, { css } from 'styled-components'
import Heading from './Heading'
import CustomCheckbox from './CustomCheckbox'
import _ from 'lodash'
import { Typography, withStyles } from '@material-ui/core';
const Container = styled.div`
  padding:20px 0;
`

const Row = styled.div`
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
  padding-top:10px;
`

const Col = styled.div`
  width:50%;
`


const featureListData = [
  {
    id: '0',
    label: 'Gas',
    active: false
  }, {
    id: '1',
    label: 'Balcony',
    active: false
  }, {
    id: '2',
    label: 'Study',
    active: false
  }, {
    id: '3',
    label: 'Air Conditioning',
    active: false
  }, {
    id: '4',
    label: 'Built-In Wardrobes',
    active: false
  }, {
    id: '5',
    label: 'Garden/Courtyard',
    active: false
  }, {
    id: '6',
    label: 'Swimming Pool',
    active: false
  }
]

const FeatureList = props => {
  const [listData, setListData] = useState(props.features);

  useEffect(() => {
    if (props.isClearFilter) {
      setListData(featureListData)
    }
  }, [props.isClearFilter])

  useEffect(() => {
    if (props.features !== listData) {
      setListData(props.features)
    }
  }, [props.features])

  // useEffect(()=>{
  //   console.log('======')
  //   console.log(props.features)
  //   console.log(listData)

  // },[listData])

  const handleChange = (e, obj, index) => {
    let data = listData.map(item =>
      item.id === obj.id
        ? { ...item, active: !obj.active }
        : item
    );
    setListData(data);
    if (props.handleFeatures) {
      props.handleFeatures(data)
    }
  };

  return (
    <Container>
      <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>Features</Heading>
      <Row>

        <Col>
          {
            listData && listData.map((item, index) => {
              if (index < 4) {
                return <CustomCheckbox checked={item.active} label={item.label} key={index} onChange={(e) => { handleChange(e, item, index) }} />
              }
            })
          }
        </Col>
        <Col>
          {
            listData && listData.map((item, index) => {
              if (index > 3) {
                return <CustomCheckbox checked={item.active} label={item.label} key={index} onChange={(e) => { handleChange(e, item, index) }} />
              }
            })
          }
        </Col>

      </Row>
    </Container>
  );
};

export default FeatureList;