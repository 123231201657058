import React, { useState, useEffect } from 'react'
import { Grid, IconButton, Container, withStyles } from '@material-ui/core'
import Crumbs from '../Header/Crumbs'
import defaultBanner from './imgs/default-banner.jpg'
import defaultLogo from './imgs/default-logo.png'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import AlertNotification from '../../Mobile/Form/AlertNotification'
import { Editor } from '@tinymce/tinymce-react'
import { FilterNoneSharp } from '@material-ui/icons'
import { Snackybar } from '../Superadmin'

const TINYMCE_API_KEY = process.env.TINYMCE_API_KEY;

const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
    padding: theme.spacing(6.25, 5),
    paddingTop: theme.spacing(3),
  },
  main: {
    backgroundColor: '#f5f5f5',
    padding: '2.6rem',
    margin: '2rem auto',
    maxWidth: '1012px',
    borderRadius: '5px',
  },
  imgBanner: {
    height: '262px',
    width: '100%',
    objectFit: 'cover',
  },
  imgLogo: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
    maxHeight: '150px',

    margin: '0 auto',
    objectFit: 'contain',
  },
  uploadLogo: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    transform: 'translateY(-50%)',
    maxHeight: '150px',
    textAlign: 'center',
    margin: '0 auto',
    objectFit: 'contain',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  mainContent: {
    margin: '0',
    paddingTop: '2rem',
  },
  content: {
    marginBottom: '1.5rem',
    paddingRight: theme.spacing(1.5),
  },
  primaryBtn: {
    background: '#FFFFFF',
    border: '0.5px solid #35C0CA',
    borderRadius: '20px',
    padding: '9px 20px',
    cursor: 'pointer',
    outline: 'none',
    transition: '0.3s ease',
    '&:hover': {
      opacity: 0.7,
    },
  },
  textarea: {
    width: '100%',
    fontFamily: 'Roboto, sans-serif',
    marginBottom: '1.5rem',
    fontSize: '14px',
    lineHeight: '150%',
  },
  closeicon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
})

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const AgencyName = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.09000000357627869px;
  text-align: left;
  color: 0, 0, 0, 0.87;
  margin-bottom: 15px;
`

const Content = styled.div`
  margin-bottom: 15px;
  p {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.09000000357627869px;
    text-align: left;
    color: #4d4d4d;
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const ContactText = styled.p`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  text-wrap: nowrap;
  a {
    color: #4d4d4d;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`

const ContactBox = styled.div`
  p {
    color: #4d4d4d;
    line-height: 1.43;
    margin-bottom: 3px;
  }
`

const FormModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 38px;
  width: 100%;
  max-width: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 6;
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s ease;
  top: 50%;
  transform: translateY(-50%);
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.525px;
    color: #4d4d4d;
    margin-bottom: 15px;
  }
  .modal-wrap {
    padding: 30px;
    .button-wrap {
      padding-top: 25px;
      display: flex;
      justify-content: flex-end;
    }
    .public-DraftEditor-content {
      height: 250px;
      overflow-y: auto;
      border: 1px solid rgba(0, 0, 0, 0.23);
      box-sizing: border-box;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding: 0 15px;
    }
  }
  .form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }

    label {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.09px;
      color: rgba(0, 0, 0, 0.54);
      margin-bottom: 5px;
    }

    input {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.23);
      box-sizing: border-box;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 56px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.54);
      padding: 15px;
      transition: outline 0.3s ease;
      &:focus {
        outline-color: #35c0ca !important;
      }
    }
    textarea {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.23);
      box-sizing: border-box;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      min-height: 100px;
      font-size: 14px;
      font-family: inherit;
      padding: 15px;
      resize: none;
      width: 100%;
      transition: outline 0.3s ease;
      &:focus {
        outline-color: #35c0ca !important;
      }
    }
  }
`

const CRUMBS = [
  { label: 'Agency Dashboard', route: '/agency/dashboard' },
  { label: 'Account Settings', route: '/agency-account-settings' },
]

const AgencyAccountSetting = (props) => {
  const {
    classes,
    apiRequestUpdateAgency,
    uploadedImage,
    requestUploadImage,
    requestClearUploadImage,
    agency,
    agencyAdmin,
  } = props

  //const [editInfo, setEditInfo] = useState(false)
  const [activeModal, setActiveModal] = useState(false)
  const [activeForm, setActiveForm] = useState('info')
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [isNotification, setIsNotification] = useState(false)

  const [agencyData, setAgencyData] = useState({
    coverImageUrl: defaultBanner,
    logoUrl: defaultLogo,
    companyName: '',
    phone: '',
    adminEmail: '',
    email: '',
    companyWebsite: '',
    companyInfo: '',
  })
  const [brandColor, setBrandColor] = useState(null)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)
  const [adminEmail, setAdminEmail] = useState(null)
  const [website, setWebsite] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [bannerIntro, setBannerIntro] = useState(null)
  const [uploadTarget, setUploadTarget] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)

  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  })

  useEffect(() => {
    requestClearUploadImage()
  }, [])

  useEffect(() => {
    if (props.user && props.agency) {
      setAgencyData({
        ...agencyData,
        companyName: props.agency?.companyName,
        companyWebsite: props.agency?.companyWebsite ?? '',
        phone: props?.agencyAdmin?.phone ?? props.agency?.phone ?? null,
        adminEmail: props.agency.adminEmail,
        email: props?.agencyAdmin?.email ?? props.agency.email,
        logoUrl: props.agency?.logoUrl ?? null,
        coverImageUrl: props.agency.coverImageUrl
          ? props.agency.coverImageUrl
          : defaultBanner,
        companyInfo: props.agency?.companyInfo ?? '',
        bannerIntro: props.agency?.bannerIntro ?? '',
        brandColor: props.agency?.brandColor ?? null,
        firstName: props?.agencyAdmin?.firstName ?? '',
        lastName: props?.agencyAdmin?.lastName ?? '',
      })
    }
  }, [props.user, props.agency])

  useEffect(() => {
    setPhone(agencyData.phone)
    setEmail(agencyData.email)
    setAdminEmail(agencyData.adminEmail)
    setWebsite(agencyData.companyWebsite)
    setCompanyName(agencyData.companyName)
    setCompanyInfo(agencyData.companyInfo)
    setBannerIntro(agencyData.bannerIntro)
    setBrandColor(agencyData.brandColor)
    setFirstName(agencyData.firstName)
    setLastName(agencyData.lastName)
  }, [agencyData])

  useEffect(() => {
    if (uploadedImage && uploadTarget) {
      if (uploadTarget === 'logo') {
        setAgencyData({ ...agencyData, logoUrl: uploadedImage })
      } else if (uploadTarget === 'coverImage') {
        setAgencyData({ ...agencyData, coverImageUrl: uploadedImage })
      }
    }
  }, [uploadedImage])

  const closeNotification = () => {
    setIsNotification(false)
  }

  const handleUploadImage = (fileState, target) => {
    if (fileState) {
      var uploadData = new FormData()
      var files = fileState
      for (const file of files) {
        uploadData.append('photo', file, file.name)
      }
      requestClearUploadImage()
      requestUploadImage(uploadData, target)
    }
  }

  const handleImage = (e, type) => {
    if (!e.target.files[0]) return null
    let img = URL.createObjectURL(e.target.files[0])
    let obj = Object.assign({}, agencyData)

    if (type === 'logo') {
      obj.logoUrl = img
      handleUploadImage(e.target.files, type)
    }

    if (type === 'coverImage') {
      obj.coverImageUrl = img
      handleUploadImage(e.target.files, type)
    }

    setUploadTarget(type)

    setAgencyData(obj)
  }

  const handleAgencyInfo = () => {
    let obj = Object.assign({}, agencyData)
    obj.companyName = companyName
    obj.companyInfo = companyInfo
    if (phone !== props.agency?.phone && phone !== null) {
      obj.phone = phone
    }
    obj.companyWebsite = website
    obj.adminEmail = adminEmail
    obj.email = email
    obj.bannerIntro = bannerIntro
    obj.brandColor = brandColor
    obj.firstName = firstName
    obj.lastName = lastName
    setAgencyData({ ...agencyData, ...obj })
    setActiveModal(false)
  }

  const handleUpdateSettings = () => {
    apiRequestUpdateAgency(agencyData)
  }

  // edit info
  const handleEditInfo = () => {
    // setEditInfo(!editInfo)
    setActiveForm('info')
    setActiveModal(true)
  }

  useEffect(() => {
    console.debug(agencyAdmin, 'AGENCY')
  }, [agencyAdmin])

  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(
      `${process?.env.REACT_APP_PUBLIC_URL}/referral/${
        props?.agency.referralCode || ''
      }`
    )
    setShowMessage({
      open: true,
      type: 'info',
      message: 'Referral Code copied to clipboard',
    })
  }

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    })
  }

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <Container style={{ maxWidth: 1012, padding: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <Crumbs type={'DARK'} crumbsOption={CRUMBS} />
          </Grid>
        </Grid>
      </Container>
      <main className={classes.main}>
        <div
          style={{
            position: 'relative',
          }}
        >
          <img
            className={classes.imgBanner}
            src={agencyData.coverImageUrl}
            onError={() => {
              setAgencyData({ ...agencyData, coverImageUrl: defaultBanner })
            }}
            alt={'cover Image Url'}
          />
          {agencyData.logoUrl ? (
            <img
              className={classes.imgLogo}
              src={agencyData.logoUrl}
              alt={'logo Url'}
            />
          ) : (
            <div className={classes.uploadLogo}>
              {agency?.userType !== 'AgencyAdmin' && (
                <label>Please upload a Logo</label>
              )}
            </div>
          )}
          {agency?.userType !== 'AgencyAdmin' && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '1.7rem 2rem',
                }}
              >
                <div>
                  <label className={classes.primaryBtn} htmlFor="profile-logo-upload">
                    + Edit Profile Logo{' '}
                  </label>
                  <input
                    id="profile-logo-upload"
                    type="file"
                    onChange={(e) => {
                      handleImage(e, 'logo')
                    }}
                    style={{ display: 'none' }}
                  />
                </div>

                <div>
                  <label className={classes.primaryBtn} htmlFor="banner-image-upload">
                    + Edit Banner Image{' '}
                  </label>
                  <input
                    id="banner-image-upload"
                    type="file"
                    onChange={(e) => {
                      handleImage(e, 'coverImage')
                    }}
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Grid container className={classes.mainContent}>
          <Grid item xs={4}>
            <div className={classes.content}>
              {agencyData?.companyName && (
                <ContactBox>
                  <p>
                    {agencyData?.companyName +
                      (props?.agency?.contactSendListingOffice
                        ? ' - ' + props?.agency?.contactSendListingOffice
                        : '')}
                  </p>
                </ContactBox>
              )}
              {agency.userType === 'AgencyAdmin' && (
                <>
                  <ContactBox>
                    <ContactText>
                      First Name:&nbsp;
                      {agencyAdmin?.firstName && <>{agencyAdmin?.firstName}</>}
                    </ContactText>
                  </ContactBox>
                  <ContactBox>
                    <ContactText>
                      Last Name:&nbsp;
                      {agencyAdmin?.lastName && <>{agencyAdmin?.lastName}</>}
                    </ContactText>
                  </ContactBox>
                </>
              )}
              <ContactBox>
                <ContactText>
                  Phone:&nbsp;
                  {agencyAdmin?.phone ||
                    (agencyData?.phone && (
                      <a href={'tel:' + agencyAdmin?.phone ?? agencyData.phone}>
                        {agencyAdmin?.phone ?? agencyData.phone}
                      </a>
                    ))}
                </ContactText>
              </ContactBox>
              {agency?.userType !== 'AgencyAdmin' && (
                <ContactBox>
                  <ContactText>
                    Admin&nbsp;Email:&nbsp;
                    {agencyData.adminEmail && (
                      <a href={'mailto:' + agencyData.adminEmail}>
                        {agencyData.adminEmail}
                      </a>
                    )}
                  </ContactText>
                </ContactBox>
              )}
              <ContactBox>
                <ContactText>
                  Email:&nbsp;
                  {agencyData.email && (
                    <a href={'mailto:' + agencyAdmin?.email ?? agencyData.email}>
                      {agencyAdmin?.email ?? agencyData.email}
                    </a>
                  )}
                </ContactText>
              </ContactBox>
              {agency?.userType !== 'AgencyAdmin' && (
                <ContactBox>
                  <ContactText>
                    Website:&nbsp;
                    {agencyData.companyWebsite && (
                      <a href={agencyData.companyWebsite} target="_blank">
                        {agencyData.companyWebsite}
                      </a>
                    )}
                  </ContactText>
                </ContactBox>
              )}
            </div>
            <button
              className={classes.primaryBtn}
              onClick={() => {
                setActiveForm('contact')
                setActiveModal(true)
              }}
            >
              {' '}
              + Edit Contact Info
            </button>
          </Grid>
          <Grid item xs={8}>
            {agencyData?.companyName && (
              <AgencyName>
                {agencyData?.companyName +
                  (props?.agency?.contactSendListingOffice
                    ? ' - ' + props?.agency?.contactSendListingOffice
                    : '')}
              </AgencyName>
            )}
            <Content
              dangerouslySetInnerHTML={{ __html: agencyData?.companyInfo }}
            ></Content>
            <ButtonWrap>
              {agency?.userType !== 'AgencyAdmin' && (
                <button className={classes.primaryBtn} onClick={handleEditInfo}>
                  + Edit Info
                </button>
              )}
              <button
                style={{ marginLeft: 20 }}
                className={classes.primaryBtn}
                onClick={() => {
                  handleUpdateSettings()
                }}
              >
                Update Settings
              </button>
            </ButtonWrap>
          </Grid>
        </Grid>
        <FormModal className={activeModal ? 'active' : ''}>
          <IconButton
            aria-label="close"
            className={classes.closeicon}
            onClick={() => {
              setActiveModal(false)
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <div className="modal-wrap">
            {activeForm === 'info' && (
              <div>
                <h2>Edit Info</h2>
                <div className="form-wrap">
                  <div className="form-field">
                    <label>Brand Color</label>
                    <input
                      type="color"
                      value={brandColor ?? ''}
                      onChange={(e) => setBrandColor(e.target.value)}
                      style={{ width: 'auto' }}
                    />
                  </div>
                  <div className="form-field">
                    <label>Banner Intro</label>
                    <Editor
                      apiKey={TINYMCE_API_KEY}
                      initialValue={agencyData.bannerIntro}
                      init={{
                        height: 100,
                        menubar: false,
                        plugins: [],
                        toolbar: '',
                      }}
                      onEditorChange={(content, editor) => {
                        setBannerIntro(content)
                      }}
                    />
                  </div>
                  <div className="form-field">
                    <label>Agency Info</label>

                    <Editor
                      apiKey={TINYMCE_API_KEY}
                      initialValue={agencyData.companyInfo}
                      init={{
                        height: 250,
                        menubar: false,
                        plugins: [],
                        toolbar: '',
                      }}
                      onEditorChange={(content, editor) => {
                        setCompanyInfo(content)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {activeForm === 'contact' && (
              <div>
                <h2>Contact Info</h2>
                <div className="form-wrap">
                  {agency?.userType !== 'AgencyAdmin' && (
                    <div className="form-field">
                      <label>Agency Name</label>
                      <input
                        name="contact-agency-name"
                        defaultValue={agencyData.companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value)
                        }}
                      />
                    </div>
                  )}
                  {agency?.userType === 'AgencyAdmin' && (
                    <>
                      <div className="form-field">
                        <label>First Name</label>
                        <input
                          name="contact-first-name"
                          defaultValue={agencyAdmin.firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value)
                          }}
                        />
                      </div>
                      <div className="form-field">
                        <label>Last Name</label>
                        <input
                          name="contact-last-name"
                          defaultValue={agencyAdmin.lastName}
                          onChange={(e) => {
                            setLastName(e.target.value)
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div className="form-field">
                    <label>Phone</label>
                    <input
                      name="contact-phone"
                      defaultValue={agencyAdmin?.phone ?? agencyData.phone}
                      onChange={(e) => {
                        setPhone(e.target.value)
                      }}
                    />
                  </div>
                  {agency?.userType !== 'AgencyAdmin' && (
                    <div className="form-field">
                      <label>Admin Email</label>
                      <input
                        name="contact-email"
                        defaultValue={agencyData.adminEmail}
                        onChange={(e) => {
                          // setEmail(e.target.value)

                          setAgencyData({ ...agencyData, adminEmail: e.target.value })
                        }}
                      />
                    </div>
                  )}
                  <div className="form-field">
                    <label>Email</label>
                    <input
                      name="contact-email"
                      defaultValue={agencyAdmin?.email ?? agencyData.email}
                      onChange={(e) => {
                        // setEmail(e.target.value)

                        setAgencyData({ ...agencyData, email: e.target.value })
                      }}
                    />
                  </div>
                  {agency?.userType !== 'AgencyAdmin' && (
                    <div className="form-field">
                      <label>Website</label>
                      <input
                        name="contact-website"
                        defaultValue={agencyData.companyWebsite}
                        onChange={(e) => {
                          setWebsite(e.target.value)
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="button-wrap">
              <button
                className={classes.primaryBtn}
                onClick={() => {
                  handleAgencyInfo()
                }}
              >
                Save
              </button>
            </div>
          </div>
        </FormModal>
      </main>
    </div>
  )
}

export default withStyles(styles)(AgencyAccountSetting)
