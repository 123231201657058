import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

import { bindActionCreators } from 'redux'
import { connect, useSelector, useDispatch } from 'react-redux'

import PMLogo from '../../../assets/images/pm-logo-inverted.png'
import PMLogoWhite from '../../../assets/images/pm-logo.png'

import {
  FOOTER_LINKS,
  FOOTER_AGENCY_LINKS,
  FOOTER_AGENT_LINKS,
  FOOTER_SOCIAL_LINKS,
} from './default'

import styled from 'styled-components'

import { logoutRequest, toggleFilterWeb } from '../../../store/actions'

import { getSettings } from 'store/selectors/generic.selectors'

import { withStyles, Typography, Link } from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'
import useFilters from 'utils/customHooks/useFilters'

const MenuWrap = styled.div`
  flex: 0 1 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    flex: 1 1 auto;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
`
const MenuFlex = styled.div`
  flex: 1 0 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h2 {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.09000000357627869px;
    text-align: left;
    color: #fff;
    margin-bottom: 15px;
  }

  @media (min-width: 1280px) {
    flex: 1 0 30%;
  }
  @media (min-width: 1440px) {
    flex: 1 0 22%;
  }
`
const MenuAgentWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  p {
    margin-bottom: 10px;
  }
  .col-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 40px;
    &:first-child {
      padding-left: 0;
    }
  }
`

const styles = (theme) => ({
  root: {
    width: '100vw',
    height: '231px',
    backgroundColor: '#E5E5E5',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width: 768px)': {
      height: 'auto',
      paddingBottom: 20,
    },
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingLeft: '155px',
    width: '100%',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
    '@media (min-width: 1440px)': {
      paddingRight: '120px',
    },
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  colContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'center',
    width: '100%',
    height: '20vh',
    paddingTop: 15,
    paddingBottom: 15,
    marginRight: 30,
    '@media (max-width: 768px)': {
      height: 'auto',
      marginRight: 0,
    },
  },
  colContainerMargined: {
    marginRight: '15%',
    '@media (max-width: 768px)': {
      marginRight: '8%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      marginRight: 0,
      paddingTop: 20,
    },
  },
  bottomContainer: {
    width: '100%',
    height: '16px',
    position: 'absolute',
    bottom: 0,
  },
  coloredContainer: {
    width: '100%',
    height: '16px',
    background: 'linear-gradient(90deg, #35C0CA, 50%, #e5e5e5 50%)',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    width: '100%',
  },
  imageLogo: {
    width: 230,
    height: 'auto',
    cursor: 'pointer',
  },
  socialTermsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 2,
  },
  socialContainer: {
    flex: '0 1 125px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  socialLogo: {
    width: 20,
    height: 20,
    position: 'relative',
    top: 0,
    marginRight: 20,
    transition: 'top 0.2s ease',
    cursor: 'pointer',
    '&:hover': {
      top: '-2px',
    },
  },
})

const FooterText = withStyles({
  root: {
    textAlign: 'center',
    marginLeft: '24%',
    alignSelf: 'center',
    color: '#9B9B9B',
    fontSize: '12px',
    lineHeight: '0.8',
    zIndex: 10,
    '@media (max-width: 1340px)': {
      textAlign: 'right',
      paddingRight: '15px',
    },
  },
})(Typography)

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#9B9B9B',
    fontSize: '12px',
    lineHeight: '1.19',
  },
})(Typography)

const StyledTextLink = withStyles({
  root: {
    fontSize: '12px',
    textAlign: 'left',
    padding: '3px 0',
  },
})(Typography)

const StyledLink = withStyles({
  root: {
    color: '#333',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: '700',
      textDecoration: 'none',
      color: '#35C0CA !important',
    },
  },
})(Link)

const Footer = ({ classes, isAgent, logoutUser }) => {
  const currentYear = new Date().getFullYear()
  const [routerChange] = useRouterChange()
  const [getFilter, setFilter] = useFilters()

  const SEARCH_BUTTONS = getFilter('search_reference')

  const dispatch = useDispatch()

  const SETTINGS = useSelector(getSettings)

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const TOGGLE_FILTERWEB = (params) => dispatch(toggleFilterWeb(params))

  const onLinkClick = (e, route) => {
    triggerAnalyticsEvent({
      event: 'footer_actions',
      eventFrom: 'Web',
      actionScope: 'footer_links',
      actionName: 'Footer Links',
      actionTarget: 'Clicked ' + route + ' from Footer',
      actionReference: 'footer',
    })
    const SEARCH_ROUTES = ['/buy', '/sold', '/rent', '/perfect-property', '/hush-hush']
    e.preventDefault()
    if (SEARCH_ROUTES.includes(route)) {
      handleClick(null, route)
    } else {
      routerChange(route)
    }
  }

  useEffect(() => {
    if (!SETTINGS.showFilterWeb.status) {
      handleClick(null, null)
    }
  }, [SETTINGS.showFilterWeb.status])

  const handleClick = (e, route) => {
    if (route === '/hush-hush') {
      console.log("her here ")
      SEARCH_BUTTONS.map((e, k) => {
        if (e.id === 'comingSoon' || e.id === 'offMarket') {
          SEARCH_BUTTONS[k].active = true;
        }

        return e
      })

      setFilter({ key: 'search_reference', value: SEARCH_BUTTONS });
      TOGGLE_FILTERWEB({ type: route.replace('/', ''), status: true })
    } else if (route) {
      console.log('did it go here?')
      const trimRoute = route.replace('/', '')

      SEARCH_BUTTONS.map((e, k) => {
        if (e.id === trimRoute) {
          SEARCH_BUTTONS[k].active = true;
        } else if (e.path === route){
          SEARCH_BUTTONS[k].active = true;
        } else {
          SEARCH_BUTTONS[k].active = false;
        }

        return e
      })

      setFilter({ key: 'search_reference', value: SEARCH_BUTTONS });
      TOGGLE_FILTERWEB({ type: route.replace('/', ''), status: true })
    }

  }

  const renderAgentLinks = () => {
    return (
      <MenuFlex>
        <h2>Quick Links</h2>
        <MenuAgentWrap>
          <div className="col-container">
            {FOOTER_AGENT_LINKS.map((value, index) => {
              if (value.row === 1)
                return (
                  <StyledTextLink key={index}>
                    <StyledLink
                      onClick={(e) => onLinkClick(e, value.route)}
                      style={{ color: isAgent ? '#E0E0E0' : '#333' }}
                    >
                      {value.label}
                    </StyledLink>
                  </StyledTextLink>
                )
            })}
          </div>
          <div className="col-container">
            {FOOTER_AGENT_LINKS.map((value, index) => {
              if (value.row === 2 && value.label !== 'Log out') {
                return (
                  <StyledTextLink key={index}>
                    <StyledLink
                      onClick={(e) => onLinkClick(e, value.route)}
                      style={{ color: isAgent ? '#E0E0E0' : '#333' }}
                    >
                      {value.label}
                    </StyledLink>
                  </StyledTextLink>
                )
              } else if (value.row === 2 && value.label === 'Log out') {
                return (
                  <StyledTextLink key={index}>
                    <StyledLink
                      onClick={() => {
                        logoutUser()
                        routerChange('/')
                      }}
                      style={{ color: isAgent ? '#E0E0E0' : '#333' }}
                    >
                      {value.label}
                    </StyledLink>
                  </StyledTextLink>
                )
              } else {
                return null
              }
            })}
          </div>
        </MenuAgentWrap>
      </MenuFlex>
    )
  }

  const renderDefaultLinks = () => (
    <MenuWrap>
      <div className={classes.colContainer}>
        {FOOTER_LINKS.map((value, index) => {
          if (value.row === 1)
            return (
              <StyledTextLink key={index}>
                <StyledLink onClick={(e) => onLinkClick(e, value.route)}>
                  {value.label}
                </StyledLink>
              </StyledTextLink>
            )
        })}
      </div>
      <div className={classes.colContainer}>
        {FOOTER_LINKS.map((value, index) => {
          if (value.row === 2)
            return (
              <StyledTextLink key={index}>
                <StyledLink onClick={(e) => onLinkClick(e, value.route)}>
                  {value.label}
                </StyledLink>
              </StyledTextLink>
            )
        })}
      </div>
    </MenuWrap>
  )

  const renderSocialIcons = () =>
    FOOTER_SOCIAL_LINKS.map((social) => (
      <Link key={social.name} href={social.route} target="_blank" rel="noopener">
        <img className={classes.socialLogo} src={social.logo} alt="Social Icons" />
      </Link>
    ))

  const renderTermLinks = () =>
    FOOTER_LINKS.map((value, index) => {
      const separator = index === 7 ? '|' : null
      if (value.row === 3) {
        return (
          <React.Fragment key={index}>
            <StyledTextLink style={{ margin: '-2px 10px 0' }}>
              <StyledLink onClick={(e) => onLinkClick(e, value.route)}>
                {value.label}
              </StyledLink>
            </StyledTextLink>
            {separator}
          </React.Fragment>
        )
      }
    })

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: isAgent ? '#4D4D4D' : '#E5E5E5' }}
    >
      <div className={classes.mainContainer}>
        <div className={clsx(classes.colContainer, classes.colContainerMargined)}>
          <div className={classes.logoContainer}>
            <img
              className={classes.imageLogo}
              src={isAgent ? PMLogoWhite : PMLogo}
              onClick={(e) => {
                e.preventDefault()
                if (routerChange) {
                  routerChange('/')
                }

                triggerAnalyticsEvent({
                  event: 'footer_actions',
                  eventFrom: 'Mobile',
                  actionScope: 'footer_links',
                  actionName: 'Footer Links',
                  actionTarget: 'Clicked PropertyMate logo from Footer',
                  actionReference: 'footer',
                })
              }}
            />
          </div>
          <StyledText
            style={{
              maxWidth: 298,
              marginBottom: 20,
              color: isAgent ? '#9B9B9B' : '#4F4F4F',
            }}
          >
            A modern, intuitive, and exciting new way to discover your perfect property
            and find your sense of place.
          </StyledText>
          <div className={classes.socialTermsContainer}>
            <div className={classes.socialContainer}>{renderSocialIcons()}</div>
            {!isAgent ? (
              <div className={classes.termsContainer}>{renderTermLinks()}</div>
            ) : null}
          </div>
        </div>
        {isAgent ? renderAgentLinks() : renderDefaultLinks()}
      </div>
      <div className={classes.bottomContainer}>
        <div
          className={classes.coloredContainer}
          style={{
            background: isAgent
              ? 'linear-gradient(90deg, #35C0CA, 50%, #4D4D4D 50%)'
              : 'linear-gradient(90deg, #35C0CA, 50%, #e5e5e5 50%)',
          }}
        >
          <FooterText>
            Copyright {currentYear ? currentYear : '2022'} PropertyMate Pty Ltd. All
            rights reserved
          </FooterText>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutUser: () => logoutRequest(),
    },
    dispatch
  )
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Footer))
