import * as Rules from 'components/Common/Validator/Rules';

function checkfField(fieldValue = '', fieldConfig) {
  for (let validatorName in fieldConfig) {
    const validatorConfig = fieldConfig[validatorName];
    const configuredValidator = Rules[validatorName](validatorConfig);
    const errorMessage = configuredValidator(fieldValue);
    if (errorMessage) {
      return errorMessage;
    }
  }
  return null;
}

export default checkfField