import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, withStyles } from '@material-ui/core';
import PMLogo from 'assets/images/pm-logo-inverted.png';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

/** redux */
import { superadminActions, logoutRequest } from 'store/actions';
import { clearSession } from 'store/actions';
import { getAuthentication, getUser } from 'store/selectors/authentication.selectors';
import { getAuthentication as getAdminAuthentication } from 'store/selectors/superadmin.selectors';
import { useCheckUserRole } from 'utils/customHooks/useCheckUserRole';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    marginLeft: '10vw',
    marginRight: 20,
  },
  border: {
    borderBottom: '0.5px solid #828282',
  },
  logo: {
    width: 200,
    height: 'auto',
  },
  buttonEdge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    marginLeft: 20,
    borderRadius: 20,
    minWidth: 180,
    textTransform: 'capitalize',
  },
  name: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
});

let defaultAlertOptions = {
  status: false,
  options: null,
};

const SuperHeader = (props) => {
  const { classes, history, match, user } = props;
  const [routerChange] = useRouterChange();
  const dispatch = useDispatch();

  const AUTHENTICATION = useSelector(getAuthentication);
  const USER = useSelector(getUser);
  const ADMIN_USER = useSelector(getAdminAuthentication);

  const [alertOptions, setAlertOptions] = useState(defaultAlertOptions);

  const isAuthenticated = () => {
    return (AUTHENTICATION?.token || USER?.user || USER?.agency || USER?.agent || ADMIN_USER?.superadminToken) ?? false;
  };

  const isLoginRoute = () => {
    return match.path.includes('/login') || match.path.includes('/forgot-password') || match.path.includes('/activate');
  };

  const isNotConfirmProperty = () => {
    return match.path.includes('/confirm-property');
  };

  const popupModalOnLogout = () => {
    const isWelcomeModalRead = !!localStorage.getItem('isWelcomeModalRead');

    if (isWelcomeModalRead) {
      localStorage.removeItem('isWelcomeModalRead');
    }
  };

  const exitSuperadmin = () => {
    if (isLoginRoute()) {
      window.open(process.env.REACT_APP_REDIRECT_URL);
    } else {
      handleLogoutSuperadmin();
      if (match.path.includes('agency')) {
        routerChange('/agency/login');
      }
      if (match.path.includes('superadmin')) {
        routerChange('/superadmin/login');
      }
      if (match.path.includes('agent')) {
        routerChange('/agent/login');
      }

      popupModalOnLogout();
      return dispatch(clearSession());
    }
  };

  const handleLogoutSuperadmin = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'LOG_OUT'));
    dispatch(logoutRequest());
  };

  const getuserInfo = () => {
    /** If its an admin account */
    if (match.path.includes('superadmin')) {
      return user?.firstName ? user?.firstName : '';
    }

    if (
      user &&
      (user?.user?.userType === 'Agency' ||
        user?.user?.userType === 'Agent' ||
        ['Agent', 'Agency', 'AgencyAdmin'].some((e) => user?.user?.userTypeList?.includes(e)))
    ) {
      return user.user.name;
    }

    return '';
  };

  const { isSuccess } = useCheckUserRole(ADMIN_USER?.superadminuser?.data?.user, 'Admin', history);

  useEffect(() => {
    if (!isSuccess) {
      setAlertOptions({
        status: true,
        options: {
          severity: 'warning',
          message: 'User Type has not enough permission to view this page. Redirecting...',
          autoHideDuration: 3000,
          onClose: () => routerChange('/superadmin/login'),
        },
      });
      routerChange('/superadmin/login');
    }
  }, [isSuccess]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.border]: isLoginRoute(),
      })}
    >
      {alertOptions.status && <AlertNotification status={alertOptions.status} options={alertOptions.options} />}
      <img src={PMLogo} className={classes.logo} />
      {!isNotConfirmProperty() && (
        <div className={classes.buttonEdge}>
          {!isLoginRoute() && (
            <Typography className={classes.name} variant={'h6'}>
              {`Welcome ${getuserInfo()}`}
            </Typography>
          )}
          <Button
            className={classes.button}
            color={'primary'}
            variant="outlined"
            onClick={exitSuperadmin}
            disableElevation
          >
            {isLoginRoute() || !isAuthenticated() ? 'Exit to Homepage' : 'Log Out'}
          </Button>
        </div>
      )}
    </div>
  );
};

SuperHeader.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
};

export default withStyles(styles)(SuperHeader);
