import React from 'react'
import { withStyles, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#F2F2F2',
  },
  image: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
  },
  imageDark: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
    background:
      'linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%)',
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width: '40%',
  },
  contentWrap: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
})

const StyledText = withStyles({
  root: {
    position: 'relative',
    textAlign: 'center',
    color: '#fff',
    fontSize: '30px',
    lineHeight: '1.19',
    zIndex: 5,
    marginBottom: 16,
  },
})(Typography)

const DashboardBanner = (props) => {
  const { classes, title, content, image } = props
  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <div>
          <StyledText variant="h2">{title}</StyledText>
          {content}
        </div>
      </div>
      {/* <Typography variant="body1" color="#ffffff">Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde soluta libero sunt quo amet consectetur ex ipsa totam.</Typography> */}
      <img className={classes.imageDark} src={image} alt="Dashboard Banner Image" />
    </div>
  )
}

export default withStyles(styles)(DashboardBanner)
