import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function HouseLineIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <line x1="7.8877" y1="68.8591" x2="160" y2="68.8591" stroke="#35C0CA" strokeWidth="2"/>
      <g clipPath="url(#clip0)">
        <path d="M25.3446 27.198C26.0826 27.198 26.6809 26.606 26.6809 25.8756C26.6809 25.1452 26.0826 24.5532 25.3446 24.5532C24.6066 24.5532 24.0083 25.1452 24.0083 25.8756C24.0083 26.606 24.6066 27.198 25.3446 27.198Z" fill="black"/>
        <path d="M15.3376 39.0852H35.352C36.0944 39.0852 36.6883 38.4975 36.6883 37.7628V25.3613L38.4848 26.8894C39.049 27.3596 39.8953 27.3009 40.3705 26.7425C40.8456 26.1841 40.7862 25.3613 40.222 24.8764L26.2059 12.9892C25.7011 12.5631 24.9736 12.5631 24.4688 12.9892L10.4527 24.8764C9.88854 25.3466 9.82915 26.1841 10.3043 26.7425C10.7794 27.3009 11.6257 27.3596 12.1899 26.8894L13.9864 25.3613V37.7628C14.0161 38.4975 14.61 39.0852 15.3376 39.0852ZM16.6738 23.0985L25.3448 15.7369L34.0157 23.0985V36.4403H26.6811V31.1653C26.6811 30.4306 26.0872 29.8429 25.3448 29.8429C24.6024 29.8429 24.0085 30.4306 24.0085 31.1653V36.4403H16.6738V23.0985Z" fill="black"/>
        <path d="M24.3944 67.2089C24.6468 67.4587 24.9883 67.6056 25.3595 67.6056C25.7159 67.6056 26.0722 67.4587 26.3246 67.2089C34.8471 58.5102 50.7191 38.9383 50.7191 25.8903C50.7191 21.2765 49.457 16.7214 47.0517 12.6806C46.6805 12.0488 45.8639 11.8431 45.2255 12.2105C44.587 12.5778 44.3792 13.3859 44.7504 14.0178C46.9032 17.6471 48.0465 21.7467 48.0465 25.8903C48.0465 39.2468 29.7989 59.6123 25.3595 64.3583C20.9201 59.6123 2.67255 39.2321 2.67255 25.8756C2.67255 13.2831 13.0509 2.64486 25.3447 2.64486C29.398 2.64486 33.392 3.80566 36.896 5.98033C37.5196 6.36236 38.3362 6.18604 38.7371 5.56891C39.1232 4.95177 38.945 4.14362 38.3214 3.74689C34.4017 1.29304 29.9029 0 25.3447 0C11.1356 0 0 12.2545 0 25.8756C0 38.9236 15.8571 58.4809 24.3944 67.2089Z" fill="black"/>
        <path d="M42.3006 9.90354C43.0386 9.90354 43.6369 9.31147 43.6369 8.58111C43.6369 7.85075 43.0386 7.25868 42.3006 7.25868C41.5626 7.25868 40.9644 7.85075 40.9644 8.58111C40.9644 9.31147 41.5626 9.90354 42.3006 9.90354Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="50.7042" height="67.6056" fill="white"/>
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export function HouseLineIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HouseLineIcon />
      <HouseLineIcon color="primary" />
      <HouseLineIcon color="secondary" />
      <HouseLineIcon color="action" />
      <HouseLineIcon color="disabled" />
      <HouseLineIcon style={{ color: green[500] }} />
    </div>
  );
}
