import React from 'react';
import { IconButton, Paper, Container, AppBar, Typography, Button, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DialogContainer from '../CustomDialogs/DialogContainer';
import DynamicIcons from '../DynamicIcons';

const styles = theme => ({
    root: {
        padding: theme.spacing(0, 2)
    },
    headerBar: {
        backgroundColor: theme.palette.light.main,
        boxShadow: 'none'
    },
    modal: {
        margin: theme.spacing(3),
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 640,
        borderRadius: 20,
        position: 'relative',
        '&:hover': {
            outline: 'none',
        },
        overflow: 'hidden',
        padding: theme.spacing(3.25, 0)
    },
    closeicon: {
        position: 'absolute',
        top: 10,
        right: theme.spacing(1.25)
    },
    text: {
        fontSize: 12,
        fontWeight: 300
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: theme.spacing(2)
    },
    ctaRounded: {
        borderRadius: 25,
        color: theme.palette.text.primary,
        textTransform: 'initial',
        paddingLeft: theme.spacing(3.2),
        paddingRight: theme.spacing(3.2),
        backgroundColor: theme.palette.common.white,
        '&.MuiButton-contained': {
            backgroundColor: theme.palette.primary.main
        }
    },
    confirmActions: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(3),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },
    media: {
        objectFit: 'cover',
        border: 'solid 2px transparent',
        borderRadius: theme.spacing(1)
    },
    activeMedia: {
        objectFit: 'cover',
        border: 'solid 2px ' + theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(1)
    }
});

const MainHeader = withStyles({
    root: {
        boxShadow: 'none',
        padding: '10px 0px'
    }
})(AppBar);

const ManagePropertyDialog = props => {
    const {
        classes,
        isOpen,
        closeCallback,
        title,
        text,
        align,
        showLogo,
        confirmCallback,
        confirmLabel,
        cancelLabel,
        content,
        maxWidth,
        visibleTab,
        actionsIndexToggle
    } = props;

    const style = {
        textAlign: align,
        maxHeight: '70vh',
        overflowY: 'auto'
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            closeCallback={closeCallback}
        >
            <Paper className={classes.modal} elevation={5} style={{ maxWidth: maxWidth ? maxWidth : '640px' }}>
                <MainHeader className={classes.headerBar} position="static">
                    <IconButton aria-label="close" className={classes.closeicon} onClick={() => { closeCallback(false) }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </MainHeader>
                <Container style={style}>
                    {showLogo &&
                        <DynamicIcons icon="2DCPIconColor" style={{ height: '61px', width: '40px', position: 'relative', top: '-3px' }} />
                    }
                    {title &&
                        <Typography variant={'h3'} className={classes.title}>{title}</Typography>
                    }
                    {text &&
                        <Typography variant={'h5'} className={classes.text}>{text}</Typography>
                    }

                    {content}
                </Container>
                {actionsIndexToggle && visibleTab && visibleTab === 0 || visibleTab !== actionsIndexToggle &&
                    <Container>
                        <div className={classes.confirmActions}>
                            <Button className={classes.ctaRounded}
                                variant={'outlined'}
                                color={'primary'}
                                disableElevation
                                onClick={closeCallback}
                            >{cancelLabel ? cancelLabel : 'Cancel'}</Button>
                            <Button className={classes.ctaRounded}
                                variant={'contained'}
                                color={'primary'}
                                disableElevation
                                onClick={confirmCallback}
                            >{confirmLabel ? confirmLabel : 'Confirm'}</Button>
                        </div>
                    </Container>
                }

            </Paper>
        </DialogContainer>
    );
}

export default withStyles(styles)(ManagePropertyDialog);