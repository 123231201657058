import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Button, Typography, withStyles } from "@material-ui/core";
import CommentIcon from '@material-ui/icons/Comment';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import Crumbs from '../Header/Crumbs';

const styles = theme => ({
    root: {
        position: 'relative',
        zIndex: 6,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& .btn-cta': {
            minWidth: 228,
            maxWidth: '228px',
            marginBottom: theme.spacing(2.5)
        },
        '& .btn-cta.email': {
            color: '#ffffff',
            maxWidth: '228px',
        },
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 375
    },
    meta: {
        padding: theme.spacing(5, 0),
        color: "#4d4d4d"
    }
})

const ContactWrap = (props) => {
    const {
        classes,
        data
    } = props;

    function checkCrumbsValue() {
        return { label: 'Find an agency', route: '/agency/results' }
    }
    return (
        <div className={classes.root}>
            <Crumbs type={'DARK'} crumbsOption={checkCrumbsValue()} />

            <div className={classes.meta}>
                <Typography variant="body1">{data.registeredTradeName ? data.registeredTradeName : data.companyName}</Typography>
                <Typography variant="body1">Phone: {data.phone}</Typography>
                <Typography variant="body1">Email: {data.adminEmail}</Typography>
                <Typography variant="body1">Website: {data.companyWebsite}</Typography>
            </div>
            <Button
                className="btn-cta email"
                variant="contained"
                color="primary"
                disableElevation
                size="large"
                startIcon={<EmailRoundedIcon />}
            >
                Email
            </Button>

            <Button
                className="btn-cta"
                variant="outlined"
                color="inherit"
                disableElevation
                size="large"
                href={'#'}
                startIcon={<CommentIcon />}
            >
                SMS
            </Button>
        </div>
    )
}

const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {

        },
        dispatch
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ContactWrap));