import React from "react";
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AlertNotification = props => {
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'topcenter'}
        open={props.status}
        autoHideDuration={props.options && props.options.autoHideDuration ? props.options.autoHideDuration : 3000}
        onClose={props.options && props.options.onClose ? props.options.onClose : props.closeNotification}
      >
        <Alert
          onClose={props.options && props.options.onClose ? props.options.onClose : props.closeNotification}
          severity={props.options ? props.options.severity : 'success'}>
          {props.options ? props.options.message : ''}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AlertNotification;
