import React, { useState } from 'react';
import MapHorizontalContainer from '../Map/MapHorizontalContainer';

import MapPropertyCard from '../Map/MapPropertyCard';
import _, { random } from "lodash";
import { withStyles } from '@material-ui/core';

const styles = theme => ({
    root: {

    },
    cardContainer: {
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        '& .HorizontalContainer': {
            width: '100%'
        },
        padding: theme.spacing(2, 2),
        fontSize: 9.5
    },
    horizontalContainer: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    }
});

function iterateItems(obj) {
    const items = [];
    for (const [key, details] of Object.entries(obj)) {
        items.push({ ...details })
    }
    return items;
}

const MapPropertyCards = props => {
    const {
        classes,
        properties,
        activeProperty,
        filterOffmarkets,

        visibleOffMarkets,
        currentScreen,
        setCurrentProperty,
        pathType,
        currentPath
    } = props;

    const [offMarketProperties, setOffMarketProperties] = useState(null);
    const [onSaleProperties, setOnSaleProperties] = useState(null);
    const notListedProperties = ['offmarket', 'notListed-xml', 'notListed-api', 'notListed-2dc'];
    const ALL_OFFMARKET = properties ? _.pickBy(properties, e => _.includes(notListedProperties, e.propertyStatus)) : null;
    const ALL_SALE = properties ? _.pickBy(properties, e => { return e.propertyStatus !== 'offmarket' || 'notListed-xml' || 'notListed-api' || 'notListed-2dc' ? true : false }) : null;

    React.useEffect(() => {
        if (ALL_OFFMARKET) {
            setOffMarketProperties(iterateItems(ALL_OFFMARKET))
        }
    }, [filterOffmarkets]);

    React.useEffect(() => {
        if (properties && ALL_SALE) {
            setOnSaleProperties(iterateItems(ALL_SALE))
        }
    }, [properties]);
    return (
        <div className={classes.root}>
            <div className={classes.cardContainer}>

                {
                    !filterOffmarkets && onSaleProperties && onSaleProperties.map((value, index) => {
                        return <MapPropertyCard key={index} properties={value} type={'properties'} currentScreen={currentScreen} setCurrentProperty={setCurrentProperty} pathType={pathType} currentPath={currentPath} />
                    })
                }
                {
                    filterOffmarkets && visibleOffMarkets && visibleOffMarkets.length > 0 && visibleOffMarkets.map((value, index) => {
                        return <MapPropertyCard key={index} properties={value} type={'properties'} currentScreen={currentScreen} setCurrentProperty={setCurrentProperty} pathType={pathType} currentPath={currentPath} />
                    })
                }

                {
                    filterOffmarkets && !visibleOffMarkets && offMarketProperties && offMarketProperties.map((value, index) => {
                        return <MapPropertyCard key={index} properties={value} type={'properties'} currentScreen={currentScreen} setCurrentProperty={setCurrentProperty} pathType={pathType} currentPath={currentPath} />
                    })
                }
            </div>
        </div>
    )
}

export default withStyles(styles)(MapPropertyCards);
