import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { superadminActions } from 'store/actions';

export const useCheckUserRole = (user, type, history) => {
  const dispatch = useDispatch();

  const [success, setSuccess] = useState(true);

  useEffect(() => {
    let timeOut = null;

    if (user) {
      if (history.location.pathname.includes('superadmin')) {
        if (user.userType !== type && !user.userTypeList.includes(type)) {
          switch (user.userType) {
            case 'Agency' || 'AgencyAdmin':
              dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'RESET_STATES'));
              setSuccess(false);
              timeOut = setTimeout(() => history.push('/agency/login'), [3000]);
              break;

            case 'Agent':
              dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'RESET_STATES'));
              setSuccess(false);
              timeOut = setTimeout(() => history.push('/agent/login'), [3000]);
              break;

            case 'Superadmin':
              setSuccess(true);
              break;

            default:
              setSuccess(false);
              timeOut = setTimeout(() => history.push('/'), [3000]);
          }
        } else {
          setSuccess(true);
        }
      }
    }

    return () => {
      if (timeOut) clearTimeout(timeOut);
    };
  }, []);

  return { isSuccess: success };
};
