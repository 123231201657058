import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DynamicIcons from '../../components/Mobile/DynamicIcons'
// import CustomCheckbox from '../../components/Mobile/Filter/CustomCheckbox'
import CustomSwitch from '../../components/Mobile/Filter/CustomSwitch'
import CustomSelect from '../../components/Mobile/Filter/CustomSelect'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'

import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'

import checkField from 'components/Common/Validator/validationService'

import { REGISTER_VALIDATION_CONFIG } from '../../constants/constants'
/** api redux request */
import { requestUpdateUser, getUserDetails } from '../../store/api'
import { logoutRequest, toggleSignUp } from '../../store/actions'

import { withStyles, Button, Typography, Input } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
  },
  content: {
    margin: theme.spacing(2),
  },
  cardContent: {
    width: '100vw',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
})

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px',
  },
})(Typography)

const BackButton = withStyles({
  root: {},
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '7px 10px',
    minWidth: '100px',
  },
})(Button)

const SettingsContainer = styled.div`
  background-color: #f5f5f5;
  padding: 16px;
  min-height: calc(100vh - 92px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &.is-edit {
    background-color: #fff;
  }

  h1 {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    letter-spacing: -0.52px;
    margin: 12px 0 20px;
  }

  h4 {
    font-family: Roboto;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.09px;
    font-weight: 400;
    color: #4d4d4d;
    padding: 15px 0;
  }
`

const InputWrap = styled.div`
  padding: 14px 0 0;
  &.edit-mode {
    .btn-expand {
      &:after {
        opacity: 0;
      }
    }
    .email-list {
      display: block;
    }

    a {
      text-decoration: none;
    }

    input {
      pointer-events: auto;
    }
  }
`
const LabelWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  p {
    color: #6a6a6a;
    font-size: 16px;
    line-height: 1;
    font-family: Roboto;
    letter-spacing: 0.09px;
  }
  a {
    font-size: 12px;
    color: #6a6a6a;
    letter-spacing: -0.2px;
    font-family: Roboto;
    line-height: 1;
  }

  .btn-expand {
    display: block;
    width: 16px;
    height: 16px;
    padding: 4px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 8px;
      height: 2px;
      background-color: #6a6a6a;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 8px;
      background-color: #6a6a6a;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`
const FieldWrap = styled.div`
  border-bottom: 0.5px solid #6a6a6a;
  padding-bottom: 14px;
  &.invalid {
    border-color: #eb4335;
    input {
      color: #eb4335;
    }
  }
`

const InputField = styled.input`
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #000;
  padding: 20px 0 11px;
  background-color: transparent;
  border: 0;
  display: block;
  width: 100%;
  &:focus {
    outline: none;
  }
  pointer-events: none;

  &::placeholder {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.23);
  }
`
const CheckList = styled.div`
  padding: 10px 0 0;
  display: none;
`
const FormWrap = styled.div``
const ButtonWrap = styled.div`
  padding: 50px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const emailAlertList = [
  {
    id: '0',
    label: 'Auctions',
    active: false,
  },
  {
    id: '1',
    label: 'New properties',
    active: false,
  },
  {
    id: '2',
    label: 'New housing',
    active: false,
  },
  {
    id: '3',
    label: 'Promotions',
    active: false,
  },
]

const frequencyOptionsList = [
  {
    id: '0',
    label: 'Daily',
    value: 'DAILY',
  },
  {
    id: '1',
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    id: '2',
    label: 'Monthly',
    value: 'MONTHLY',
  },
]

const AccountSettings = (props) => {
  const {
    classes,
    authentication,
    users,
    updateUser,
    toggleSignUp,
    logoutUser,
    getUserDetails,
  } = props
  const [routerChange] = useRouterChange()
  const [isEditMode, setIsEditMode] = useState(false)

  const [email, setEmail] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [name, setName] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [firstName, setFirstName] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [lastName, setLastName] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [phone, setPhone] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [password, setPassword] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [newPassword, setNewPassword] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [confirmPassword, setConfirmPassword] = useState({
    valid: true,
    edit: false,
    value: '',
  })

  const [emailList, setEmailList] = useState({
    valid: true,
    edit: true,
    value: emailAlertList,
    propertyNotification: true,
    frequencyOptions: frequencyOptionsList,
    frequency: frequencyOptionsList[0].value,
  })

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [isNotification, setIsNotification] = useState(false)

  const emailRef = useRef()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const phoneRef = useRef()

  useEffect(() => {
    if (!authentication.token) {
      window.location.replace(window.location.origin)
      return
    }
  }, [])

  useEffect(() => {
    if (users?.user?._id) {
      getUserDetails(users.user._id)
    }

    if (users?.user_profile) {
      if (
        users?.user?.userType.toLowerCase() !== 'customer' &&
        !users?.user?.userTypeList?.includes('Customer') &&
        users.user_profile[users.user.userType.toLowerCase()]
      ) {
        const { firstName, lastName } =
          users.user_profile[users.user.userType.toLowerCase()]
        setFirstName({ ...firstName, value: firstName, valid: firstName !== '' })
        setLastName({ ...lastName, value: lastName, valid: lastName !== '' })
      }

      if (users?.user_profile?.profile) {
        const { firstName, lastName } = users.user_profile.profile
        setFirstName({ ...firstName, value: firstName, valid: firstName !== '' })
        setLastName({ ...lastName, value: lastName, valid: lastName !== '' })
      }
    }
  }, [])

  useEffect(() => {
    if (users?.user) {
      let user = users.user
      setEmail({ ...email, value: user.email, valid: validateEmail(user.email) })

      setFirstName({
        ...firstName,
        value: user.firstName,
        valid: user.firstName !== '',
      })
      setLastName({
        ...lastName,
        value: user.lastName,
        valid: user.lastName !== '',
      })

      if (user?.phone) {
        setPhone({ ...phone, value: user.phone, valid: user.phone !== '' })
      }

      let el = Object.assign([], emailAlertList)
      el[0].active = user.emailAlertAuction
      el[1].active = user.emailAlertNewProperties
      el[2].active = user.emailAlertNewHousing
      el[3].active = user.emailAlertPromotions
      //el[4].active = user.doNotWantEmailAlerts
      setEmailList({
        ...emailList,
        propertyNotification: user?.propertyNotification,
        frequency: user?.matchingEmailFrequency,
        value: el
      })
    }
  }, [authentication])

  useEffect(() => {
    if (isSubmitted) {
      if (users.update_user_status === 'success') {
        setIsNotification(true)
        setNotificationOptions({ message: 'Update successful!', severity: 'success' })
        setIsSubmitted(false)
        setEmail({ ...email, value: users.user.email, edit: false })
        // setName({...name,  edit:false})
        setFirstName({ ...firstName, edit: false })
        setLastName({ ...lastName, edit: false })
        setPhone({ ...phone, value: users.user.phone, edit: false })
        setPassword({ ...password, edit: false, value: '', valid: true })
        setNewPassword({ ...newPassword, edit: false, value: '', valid: true })
        setConfirmPassword({ ...confirmPassword, edit: false, value: '', valid: true })

        let el = Object.assign([], emailAlertList)
        el[0].active = users.user.emailAlertAuction
        el[1].active = users.user.emailAlertNewProperties
        el[2].active = users.user.emailAlertNewHousing
        el[3].active = users.user.emailAlertPromotions
        //el[4].active = user.doNotWantEmailAlerts
        setEmailList({
          ...emailList,
          propertyNotification: users?.user?.propertyNotification,
          frequency: users?.user?.matchingEmailFrequency,
          value: el
        })
        // setIsEditMode(false)
      }
      if (users.update_user_status === 'error') {
        setIsNotification(true)
        if (
          users.update_user_error.data.reason.message ===
          'child "email" fails because ["email" must be a valid email]'
        ) {
          setNotificationOptions({
            message: 'Invalid email address format',
            severity: 'error',
          })
        } else {
          setNotificationOptions({
            message: users.update_user_error.data.reason.message,
            severity: 'error',
          })
          setIsSubmitted(false)
        }

        if (
          users.update_user_error &&
          users.update_user_error.reason &&
          users.update_user_error.reason.message === 'User not found'
        ) {
          logoutUser()
          toggleSignUp(true)
        }
      }
    }
  }, [users])

  useEffect(() => {
    if (
      email.edit ||
      firstName.edit ||
      lastName.edit ||
      phone.edit ||
      password.edit ||
      emailList.edit
    ) {
      if (!isEditMode) {
        setIsEditMode(true)
      }
    } else {
      if (isEditMode) {
        setIsEditMode(false)
      }
    }
  }, [isEditMode, email, firstName, lastName, phone, password, emailList])

  function routeChangeBack() {
    routerChange("back")
  }

  const handleChange = (e, obj, index) => {
    setEmailList({
      ...emailList,
      value: emailList.value.map((item) =>
        item.id === obj.id ? { ...item, active: !obj.active } : item
      ),
    })
  }

  const handleSaveUpdate = (e) => {
    e.preventDefault()

    let params = {
      email: email.value,
      name: `${firstName.value} ${lastName.value}`,
      firstName: firstName.value,
      lastName: lastName.value,
      password: newPassword.value,
      confirmPassword: confirmPassword.value,
      currentPassword: password.value,
      phone: phone.value,
      // doNotWantAlerts:emailList.value[4].active,
      propertyNotification: emailList.propertyNotification,
      matchingEmailFrequency: emailList.frequency,
      alerts: {
        auctions: emailList.value[0].active,
        newProperties: emailList.value[1].active,
        newHousing: emailList.value[2].active,
        promotions: emailList.value[3].active,
      },
    }

    if (
      params.email === '' ||
      !params.email
      // params.email === authentication.token.user.email
    ) {
      setEmail({ ...email, value: '', valid: false })
      delete params.email
    }

    // if(params.name === '' || !params.name) {
    //   delete params.name;
    // }

    if (params.firstName === '' || !params.firstName) {
      setFirstName({ ...firstName, value: '', valid: false })
      delete params.name
      delete params.firstName
    }

    if (params.lastName === '' || !params.lastName) {
      setLastName({ ...lastName, value: '', valid: false })
      delete params.name
      delete params.lastName
    }

    if (params.password === '' || !params.password) {
      delete params.password
    }

    if (params.confirmPassword === '' || !params.confirmPassword) {
      delete params.confirmPassword
    }

    if (params.currentPassword === '' || !params.currentPassword) {
      delete params.currentPassword
    }

    if (params.phone === '' || !params.phone) {
      delete params.phone
    }

    if (
      confirmPassword.value !== '' &&
      newPassword.value !== '' &&
      newPassword.value === confirmPassword.value &&
      !users.user.password
    ) {
      if (users) {
        if (users.user.signUpMethod.toLowerCase() !== 'email') {
          params.currentPassword = ''
        }
      }
    }

    if (phone.valid && email.valid && firstName.valid && lastName.valid) {
      updateUser(params)
      setIsSubmitted(true)
    } else {
      setIsNotification(true)
      if (!phone.valid) {
        setNotificationOptions({ message: 'Invalid phone', severity: 'error' })
      } else if (!email.valid) {
        setNotificationOptions({ message: 'Invalid email', severity: 'error' })
      } else if (!firstName.valid) {
        setNotificationOptions({ message: 'Invalid first name', severity: 'error' })
      } else if (!lastName.valid) {
        setNotificationOptions({ message: 'Invalid last name', severity: 'error' })
      } else {
        setIsNotification(true)
        setNotificationOptions({
          message: 'Please enter valid information',
          severity: 'error',
        })
      }
    }
  }

  const validateEmail = (val) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      val
    );
  }

  const validatePassword = (val) => {
    return /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/.test(val);
  }

  const isNumber = (evt) => {
    evt = evt ? evt : window.event
    var charCode = evt.which ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false
    }
    return true
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const handleTelChange = (e) => {
    var value = e.target.value.replace(/\D/g, '')
    if (checkField(value, REGISTER_VALIDATION_CONFIG('phone').fields.phone) === null) {
      setPhone({ ...phone, value: value, valid: true })
    } else {
      setPhone({ ...phone, value: value, valid: false })
    }
  }

  const handleFrequencyChange = (e) => {
    setEmailList({ ...emailList, frequency: e })
  }

  const handleFocus = (inputRef, state) => {
    if (!state.edit) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(state.value.length, state.value.length)
    }
  }

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routeChangeBack()}
        >
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
      </div>
      {authentication && authentication.token && (
        <SettingsContainer>
          <FormWrap>
            <h1>Account Settings</h1>
            <h4>Profile Details</h4>
            <InputWrap className={email.edit ? 'edit-mode' : ''}>
              <LabelWrap>
                <p>Email Address: *</p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setEmail({ ...email, edit: !email.edit })
                    handleFocus(emailRef, email)
                  }}
                >
                  {email.edit ? 'Done' : 'Edit'}
                </a>
              </LabelWrap>
              <FieldWrap className={!email.valid ? 'invalid' : ''}>
                <InputField
                  ref={emailRef}
                  type="text"
                  value={email.value}
                  onChange={(e) => {
                    let value = e.target.value
                    setEmail({
                      ...email,
                      value: e.target.value,
                      valid: validateEmail(value),
                    })
                  }}
                />
              </FieldWrap>
            </InputWrap>
            {/* <InputWrap className={name.edit ? 'edit-mode' : ''}>
            <LabelWrap>
              <p>Name:</p>
              <a href="#" onClick={(e)=>{
                e.preventDefault();
                setName({...name, edit:!name.edit});
                handleFocus(nameRef, name);
              }}>{name.edit ? 'Done': 'Edit'}</a>
            </LabelWrap>
            <FieldWrap className={!name.valid ? 'invalid' : ''}>
              <InputField ref={nameRef} type="text" defaultValue={name.value} onChange={(e)=>{
                let value = e.target.value;
                setName({...name, value:value, valid:value !== ''})
              }}
              maxLength={50}
              />
            </FieldWrap>
          </InputWrap> */}

            <InputWrap className={firstName.edit ? 'edit-mode' : ''}>
              <LabelWrap>
                <p>First Name:</p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setFirstName({ ...firstName, edit: !firstName.edit })
                    handleFocus(firstNameRef, firstName)
                  }}
                >
                  {firstName.edit ? 'Done' : 'Edit'}
                </a>
              </LabelWrap>
              <FieldWrap className={!firstName.valid ? 'invalid' : ''}>
                <InputField
                  ref={firstNameRef}
                  type="text"
                  value={firstName.value}
                  onChange={(e) => {
                    let value = e.target.value
                    setFirstName({ ...firstName, value: value, valid: value !== '' })
                  }}
                  maxLength={50}
                />
              </FieldWrap>
            </InputWrap>
            <InputWrap className={lastName.edit ? 'edit-mode' : ''}>
              <LabelWrap>
                <p>Last Name:</p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setLastName({ ...lastName, edit: !lastName.edit })
                    handleFocus(lastNameRef, lastName)
                  }}
                >
                  {lastName.edit ? 'Done' : 'Edit'}
                </a>
              </LabelWrap>
              <FieldWrap className={!lastName.valid ? 'invalid' : ''}>
                <InputField
                  ref={lastNameRef}
                  type="text"
                  value={lastName.value}
                  onChange={(e) => {
                    let value = e.target.value
                    setLastName({ ...lastName, value: value, valid: value !== '' })
                  }}
                  maxLength={50}
                />
              </FieldWrap>
            </InputWrap>

            <InputWrap className={phone.edit ? 'edit-mode' : ''}>
              <LabelWrap>
                <p>Phone number: *</p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    // setPhone({...phone, edit:!phone.edit})
                    if (
                      checkField(
                        phone.value,
                        REGISTER_VALIDATION_CONFIG('phone').fields.phone
                      ) === null
                    ) {
                      setPhone({ ...phone, edit: !phone.edit, valid: true })
                    } else {
                      setPhone({ ...phone, edit: !phone.edit, valid: false })
                    }
                    handleFocus(phoneRef, phone)
                  }}
                >
                  {phone.edit ? 'Done' : 'Edit'}
                </a>
              </LabelWrap>
              <FieldWrap className={!phone.valid ? 'invalid' : ''}>
                <InputMask
                  mask={'9999 999 999'}
                  maskPlaceholder={null}
                  value={phone.value}
                  onChange={handleTelChange}
                  name={name}
                >
                  <InputField
                    ref={phoneRef}
                    type="text"
                    autoComplete="phone-number"
                    defaultValue={phone.value}
                    onChange={handleTelChange}
                    onKeyPress={(e) => {
                      if (!isNumber(e)) {
                        e.preventDefault()
                        return isNumber(e)
                      }
                    }}
                  />
                </InputMask>
              </FieldWrap>
            </InputWrap>
            {users &&
              users.user &&
              users.user.signUpMethod &&
              users.user.signUpMethod.toLowerCase() === 'email' && (
                <InputWrap className={password.edit ? 'edit-mode' : ''}>
                  <LabelWrap>
                    <p>Password:</p>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        setPassword({ ...password, edit: !password.edit })
                      }}
                    >
                      Reset
                    </a>
                  </LabelWrap>
                  {password.edit ? (
                    <div>
                      <FieldWrap className={!password.valid ? 'invalid' : ''}>
                        <InputField
                          autoComplete="user-password"
                          name="user-password"
                          type="password"
                          placeholder={'Type in current password'}
                          onChange={(e) => {
                            let value = e.target.value
                            setPassword({
                              ...password,
                              valid: value !== '',
                              value: value,
                            })
                          }}
                        />
                      </FieldWrap>
                      <FieldWrap className={!newPassword.valid ? 'invalid' : ''}>
                        <InputField
                          autoComplete="new-password"
                          name="new-password"
                          type="password"
                          placeholder={'Type in new password'}
                          onChange={(e) => {
                            let value = e.target.value
                            setNewPassword({
                              ...newPassword,
                              valid: validatePassword(value),
                              value: value,
                            })
                          }}
                        />
                      </FieldWrap>
                      <FieldWrap className={!confirmPassword.valid ? 'invalid' : ''}>
                        <InputField
                          autoComplete="confirm-password"
                          name="confirm-password"
                          type="password"
                          placeholder={'Retype new password'}
                          onChange={(e) => {
                            let value = e.target.value
                            setConfirmPassword({
                              ...confirmPassword,
                              valid: value !== '' && newPassword.value === value,
                              value: value,
                            })
                          }}
                        />
                      </FieldWrap>
                    </div>
                  ) : (
                    <FieldWrap></FieldWrap>
                  )}
                </InputWrap>
              )}
            <InputWrap className={emailList.edit ? 'edit-mode' : ''}>
              <LabelWrap>
                <p>Email alerts:</p>
                {/*<a
                  href="#"
                  className="btn-expand"
                  onClick={(e) => {
                    e.preventDefault()
                    setEmailList({ ...emailList, edit: !emailList.edit })
                  }}
                ></a>*/}
              </LabelWrap>
              <FieldWrap>
                <LabelWrap>
                  <div>
                    <p style={{ color: '#000', paddingTop: 25 }}>
                      Property Notifications
                    </p>
                    <p style={{ fontSize: 14,paddingTop: 10 }}>
                      We'll email you new properties that match your Saves Searches.
                    </p>
                  </div>
                  <CustomSwitch
                    checked={emailList.propertyNotification}
                    onChange={(e) => {
                      setEmailList({ ...emailList, propertyNotification: !emailList.propertyNotification })
                    }}
                  />
                </LabelWrap>
                <LabelWrap>
                  <div>
                    <p style={{ color: '#000', paddingTop: 25 }}>
                      Notification Frequency
                    </p>
                    <p style={{ fontSize: 14,paddingTop: 10, paddingBottom: 10 }}>
                      Let us know how often you want new properties emailed.
                    </p>
                  </div>
                </LabelWrap>
                <LabelWrap>
                  <CustomSelect
                    styles={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    isForm={true}
                    options={emailList.frequencyOptions}
                    handleSelect={handleFrequencyChange}
                    isClearFilter={false}
                    value={users?.user?.matchingEmailFrequency ?? emailList.frequency}
                  />
                </LabelWrap>
              </FieldWrap>
            </InputWrap>
          </FormWrap>
          <ButtonWrap>
            <StyledButton
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSaveUpdate}
            >
              Save update
            </StyledButton>
          </ButtonWrap>
        </SettingsContainer>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUser: (params) => requestUpdateUser(params),
      toggleSignUp: (params) => toggleSignUp(params),
      // updateUser: params => updateUser(params),
      logoutUser: () => logoutRequest(),
      getUserDetails: (id) => getUserDetails(id),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountSettings))

