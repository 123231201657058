import React, { useEffect, useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect, useSelector, useDispatch } from 'react-redux'

import { Button, Typography, Grid, withStyles } from '@material-ui/core';
import Image from 'material-ui-image'
import dashBannerIMG from '../../assets/images/agentbanner.png'
import AlertNotification from 'components/Web/Form/AlertNotification'
import { Loader } from 'components/Common'
/** actions */
import { toggleSignUp, agencyv2Actions } from '../../store/actions'

/** api redux request */
import { requestGETAPIBilling, requestPOSTAPIBilling } from 'store/api/billing.api'
/* import {  
  requestAgentsRegister
} from "../../store/api"; */
import DashboardBanner from '../../components/Web/Dashboard/DashboardBanner'
import Invoice from '../../components/Web/Billing/Invoice'
/*
 * SELECTORS
 */
import {
  getAllInvoices,
  getAllInvoicesXero,
  getStatus,
  getErrors,
} from 'store/selectors/billing.selectors'
import { getAuthentication, getUser } from 'store/selectors/agencyv2.selectors'
import { billingActions } from 'store/actions/billing.actions'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 90,
    minHeight: '75vh',
  },
})

const InvoicePayment = (props) => {
  const { classes } = props

  const [invoices, setInvoices] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  })
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [isNotification, setIsNotification] = useState(false)

  const dispatch = useDispatch()
  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)
  const INVOICES = useSelector(getAllInvoices)
  const AUTH = useSelector(getAuthentication)
  const USER = useSelector(getUser)
  const XERO_INVOICE = useSelector(getAllInvoicesXero)
  const handleToggleSignUp = (checked) => dispatch(toggleSignUp(checked))

  const allStatusRequest = [
    'billing/BILLING_GET_ALL_INVOICES_REQUEST',
    'billing/BILLING_GET_INVOICE_XERO_REQUEST',
  ]

  const allStatusFailed = [
    'billing/BILLING_GET_ALL_INVOICES_FAILED',
    'billing/BILLING_GET_INVOICE_XERO_FAILED',
  ]

  const allStatusSuccess = [
    'billing/BILLING_GET_ALL_INVOICES_SUCCESS',
    'billing/BILLING_GET_INVOICE_XERO_SUCCESS',
  ]

  /** Redux Actions */
  const handleClearStatus = () => {
    setTimeout(() => setShowMessage({ ...showMessage, open: false }), 2000)
    dispatch(billingActions(null, 'GENERAL_BILLING', 'CLEAR_STATUS'))
  }
  const handleClearError = () => {
    dispatch(billingActions(null, 'GENERAL_BILLING', 'CLEAR_ERROR'))
  }

  const handleGetInvoiceClear = () => {
    dispatch(billingActions(null, 'BILLING_GET_ALL_INVOICES', 'CLEAR'))
  }

  const apiRequestInvoices = () => {
    const request = {
      query: '?agencyReference=' + USER?.agency,
      data: null,
    }
    handleGetInvoiceClear()
    dispatch(requestGETAPIBilling(request, 'BILLING_GET_ALL_INVOICES', 'REQUEST'))
  }

  const handleGetInvoiceXeroClear = () => {
    dispatch(billingActions(null, 'BILLING_GET_INVOICE_XERO', 'CLEAR'))
  }
  console.log('XERO_INVOICE ' + JSON.stringify(XERO_INVOICE))

  useEffect(() => {
    /*     if (!USER) {
      // toggleSignUp(true);
    } */
    if (!invoices) {
      apiRequestInvoices()
    }
  }, [])

  useEffect(() => {
    if (INVOICES?.invoices) {
      setInvoices(INVOICES?.invoices)
    }
  }, [INVOICES])

  useEffect(() => {
    if (allStatusRequest.some((i) => STATUS?.includes(i))) {
      setLoading(true)
      handleClearError()
    }
    if (allStatusFailed.some((i) => STATUS?.includes(i))) {
      setLoading(false)
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR.message : ERROR,
      })
      handleClearError()
    }
    if (allStatusSuccess.some((i) => STATUS?.includes(i))) {
      setLoading(false)
      setShowMessage({
        open: true,
        type: 'success',
        message: 'Success',
      })
    }
    if (STATUS === 'billing/BILLING_GET_INVOICE_XERO_SUCCESS') {
      if (XERO_INVOICE?.xeroOnlineInvoiceUrl) {
        openInNewTab(XERO_INVOICE?.xeroOnlineInvoiceUrl)
      }
    }
    handleClearStatus()
  }, [STATUS, ERROR])

  function closeNotification() {
    setIsNotification(false)
  }

  const checkCrumbsValue = () => {
    return { label: 'Billing & Payment', route: '/agency/billing' }
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  const handleXeroUrl = (data) => {
    console.log('data ' + data)
    const request = {
      query: '/' + data + '/xero',
      data: null,
    }
    handleGetInvoiceXeroClear()
    dispatch(requestGETAPIBilling(request, 'BILLING_GET_INVOICE_XERO', 'REQUEST'))
  }

  return (
    <div className={classes.root}>
      <DashboardBanner
        title={'Billing & Payment'}
        content={
          <Typography variant="body1">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam quia et
            voluptas minima <br /> repellat odit, facilis sint cupiditate obcaecati?
          </Typography>
        }
        image={dashBannerIMG}
      />
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={loading} />

      {INVOICES && INVOICES?.entities && (
        <Invoice

          authentication={AUTH}
          initialData={INVOICES?.entities}
          handleXeroUrl={handleXeroUrl}
        />
      )}
    </div>
  )
}
export default withStyles(styles)(InvoicePayment)
