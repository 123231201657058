import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Button,
  IconButton,
  withStyles,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import clsx from 'clsx'
import { toDateFormat } from 'utils/formatter/dateFormatter'
import { SuperStatusChip } from 'components/Web/Superadmin'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
  table: {
    height: '70vh',
  },
})

const TABLE_HEADER = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'agency',
    label: 'Agency Name',
  },
  {
    key: 'companyName',
    label: 'Office Name',
  },
  {
    key: 'type',
    label: 'Change Request Type',
  },
  {
    key: 'action',
    label: 'Action',
  },
]

const ManageChangeRequestsTable = (props) => {
  const { classes, tableData, pagination } = props
  const [routerChange] = useRouterChange()
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (tableData?.length ?? 0) - currentPage * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    // handlePageUpdate(newPage + 1);
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value == '-1') {
      setRowsPerPage(parseInt(pagination?.totalItemCount, 10))
      setCurrentPage(0)
    } else {
      setRowsPerPage(parseInt(event.target.value, 10))
      setCurrentPage(0)
    }
  }

  const handleClick = (event, data) => {
    routerChange('/superadmin/change-requests/' + data.agencyReference)
  }

  const _renderRows = () => {
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? tableData.slice(
            currentPage * rowsPerPage,
            currentPage * rowsPerPage + rowsPerPage
          )
          : tableData
        ).map((value, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left" className={classes.mainText}>
              {tableData.findIndex((i) => i._id === value._id) + 1}
            </TableCell>
            <TableCell align="left">{value?.agency?.registeredTradeName ? value?.agency?.registeredTradeName : value?.agency?.companyName}</TableCell>
            <TableCell align="left">{value?.agency?.officeName}</TableCell>
            <TableCell align="left">{value.type}</TableCell>
            <TableCell>
              <IconButton
                onClick={(e) => {
                  handleClick(e, value)
                }}
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const _renderEmpty = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell align={'center'} colSpan={TABLE_HEADER.length + 1}>
            No Data to View
          </TableCell>
        </TableRow>
      </TableBody>
    )
  }

  return (
    <div className={classes.root}>
      <TableContainer className={classes.table} component={Paper}>
        <Table stickyHeader size="medium">
          <TableHead>
            <TableRow>
              {TABLE_HEADER.map((value, index) => {
                return <TableCell key={index}>{value.label}</TableCell>
              })}
            </TableRow>
          </TableHead>
          {tableData && tableData.length > 0 && _renderRows()}
          {tableData && tableData.length === 0 && _renderEmpty()}
        </Table>
      </TableContainer>
      {tableData && tableData.length > 0 && (
        <TablePagination
          rowsPerPageOptions={props.rowsPerPageOptions ?? [10, 20, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          count={
            pagination && pagination.totalItemCount ? pagination.totalItemCount : 0
          }
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
        />
      )}
    </div>
  )
}

ManageChangeRequestsTable.propTypes = {
  classes: PropTypes.object,
  tableData: PropTypes.array,
  pagination: PropTypes.object,
}

export default withStyles(styles)(ManageChangeRequestsTable)
