import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuperBreadCrumbs, AgentsAddForm } from 'components/Web/Superadmin';

import AlertNotification from 'components/Web/Form/AlertNotification';
/** selectors */
import {
  getAuthentication,
  getSettings,
  getUser,
  checkEmailAvailable,
  getStatus,
  getErrors,
  getAgency,
} from 'store/selectors/superadmin.selectors';

/** redux */
import { superadminActions } from 'store/actions';

import { requestPOSTAPISuperadmin, requestGETAPISuperadmin } from 'store/api';
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
});

const SuperadminAddAgent = (props) => {
  const { classes, history, match } = props;
  const [routerChange] = useRouterChange();
  const dispatch = useDispatch();
  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getErrors);
  const GETEMAIL = useSelector(checkEmailAvailable);
  const AGENCY = useSelector(getAgency);

  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [isLoading, setIsLoading] = useState(false);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
    {
      type: 'secondary',
      label: 'Manage Agency Information',
      path: '/superadmin/manage-agencies/' + match.params.id,
    },
    {
      type: 'primary',
      label: 'Add Agent',
      path: '',
    },
  ];

  function goBack() {
    history.goBack();
  }

  const handleEmailCheck = (data) => {
    setIsLoading(true);
    const params = {
      query: '?email=' + data.email,
      data: null,
    };

    dispatch(requestGETAPISuperadmin(params, 'SUPERADMIN_CHECK_AGENT_EMAIL'));
  };

  useEffect(() => {
    handleGetAgencyData();

    return () => {
      handleClearData();
    };
  }, []);

  const handleGetAgencyData = () => {
    let request = {
      query: '/' + match.params.id,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_AGENCY_DATA'));
  };

  useEffect(() => {
    if (STATUS === 'superadmin/SUPERADMIN_CHECK_AGENT_EMAIL_SUCCESS') {
      setIsLoading(false);
    } else if (STATUS === 'superadmin/AGENCY_CHECK_AGENT_EMAIL_REQUEST') {
      setIsLoading(true);
    } else if (STATUS === 'superadmin/AGENCY_CHECK_AGENT_EMAIL_FAILED' && ERROR?.message !== 'Agent not found') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error checking email availability. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (ERROR?.message === 'Agent not found') {
      setIsLoading(false);
    } else if (STATUS === 'superadmin/SUPERADMIN_ADD_SINGLE_AGENT_SUCCESS') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Successfully added an agent. Agent account must be activated',
          onClose: () => setTimeout(() => goBack(), 3000),
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'superadmin/SUPERADMIN_ADD_SINGLE_AGENT_FAILED') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error adding agent. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'superadmin/SUPERADMIN_ADD_SINGLE_AGENT_REQUEST') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Adding agent...',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }

    if (STATUS === 'superadmin/SUPERADMIN_UPDATE_AGENT_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Agent successfully updated.',
          autoHideDuration: 3000,
          onClose: handleClearNotices,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'superadmin/SUPERADMIN_UPDATE_AGENT_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Saving changes...',
          autoHideDuration: 3000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'superadmin/SUPERADMIN_UPDATE_AGENT_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error saving changes. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  }, [STATUS]);

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'));
  };

  const handleClearData = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'RESET_STATES'));
  };

  const handleClearNotices = () => {
    handleClearStatus();
    handleClearError();
    setIsNotification(defaultNotification);
  };

  const handleNewAgent = (data) => {
    const params = {
      query: null,
      data: {
        reaAgencyId: AGENCY?.data?.entity?.reaAgencyId,
        agentList: [{ ...data, phone: data.telephone }],
      },
    };

    if (AGENCY) {
      dispatch(requestPOSTAPISuperadmin(params, 'SUPERADMIN_ADD_SINGLE_AGENT'));
    } else {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error, unknown Agency ID. Please logout and log in again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  };

  const handleSubmit = (data) => {
    handleNewAgent(data);
  };

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs section={'Add an Agent'} crumbsLinks={CRUMBS} />
      <AgentsAddForm
        goBack={goBack}
        handleEmailCheck={handleEmailCheck}
        isLoading={isLoading}
        error={STATUS === 'superadmin/SUPERADMIN_CHECK_AGENT_EMAIL_FAILED' && ERROR?.message !== 'Agent not found'}
        result={ERROR?.message === 'Agent not found' ? ERROR : GETEMAIL}
        clearPayload={() => handleClearNotices()}
        handleSubmit={handleSubmit}
        noReset={true}
        match={match}
      />

      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(SuperadminAddAgent);
