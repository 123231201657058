import mapDrawEnable from './mapDrawEnable'
function mapDraw(maps, map, type, instance, mapPolys) {
    //the polygon
    var drawing = new maps.Polyline({map:map,clickable:false});
    
    //move-listener
    var move=maps.event.addListener(map,'mousemove',function(e){
        drawing.getPath().push(e.latLng);
    });
    
    //mouseup-listener
    
    maps.event.addListenerOnce(map,'mouseup',function(e){
        maps.event.removeListener(move);
        var path=drawing.getPath();
        drawing.setMap(null);
        drawing=new maps.Polygon({
            map:map,
            path:path,
            strokeColor: "#11c0c9",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#11c0c9",
            fillOpacity: 0
        });                
        maps.event.clearListeners(map.getDiv(), 'mousedown');        
        mapDrawEnable(map, type)
        /* 
        var testCoords = new maps.LatLng({lat: -37.7867363, lng: 144.9211832 })
        console.log(poly, "POLY OBJECT", maps.geometry.poly.containsLocation(
            testCoords,
            poly
            )) */
        instance.setState((state) => {
            return { ...state, polygonFilter: drawing };
        });

        mapPolys.push(drawing);
            
    });
    
}

export default mapDraw
