import { FETCH } from "../../utils/api";
import {
  getEmailLogsSuccess,
  getEmailLogsFail,
  getSingleEmailSuccess,
  getSingleEmailFail,
} from "../actions";

import { errorhandler } from ".";

import { apiEndpoints } from "../../utils/api/endpoints";

export function getEmailLogs() {
  return (dispatch) => {
    dispatch(getEmailLogsSuccess());
    FETCH(apiEndpoints.MAIL_HISTORY_LIST)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(getEmailLogsSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(getEmailLogsFail(errorhandler(error)));
      });
  };
}

export function getSingleEmail(params) {
  return (dispatch) => {
    dispatch(getSingleEmailSuccess(params));
    FETCH(apiEndpoints.GET_SINGLE_MAIL, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(getSingleEmailSuccess(response));
        return response;
      })
      .catch((error) => {
        dispatch(getSingleEmailFail(errorhandler(error)));
      });
  };
}
