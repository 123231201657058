import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withSnackbar } from 'react-simple-snackbar'

import {
  clearFilters
} from '../../store/actions';

import { Typography, withStyles } from '@material-ui/core';

const PageHeading = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px'
  },
})(Typography);

const Agency = props => {

  const { openSnackbar, closeSnackbar, clearAllFilters } = props

  React.useEffect(() => {
    /** show alert to relogin */
    if (localStorage.getItem("showSnackBar") !== null) {
      openSnackbar('Session Expired.')
      localStorage.removeItem('showSnackBar');
    }

    /** CLEAR FILTERS */
    //clearAllFilters();
  }, [])

  return (
    <div style={{
      display: "flex",
      flexDirection: 'column',
      alignItems: "center",
      justifyContent: "center",
    }}>
      <PageHeading variant="h2">Agency Landing Page</PageHeading>
    </div>
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearAllFilters: () => clearFilters(),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Agency));
