import React, { useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles, Typography } from "@material-ui/core";

import socketIOClient from "socket.io-client";

import { NOTIFICATION_API, NOTIFICATION_PORT } from '../../utils/constants.utils';

const ENDPOINT = `${NOTIFICATION_API}:${NOTIFICATION_PORT}`;
const SOCKET_ADDRESS = 'notifications:';

const styles = theme => ({
  root: {
    paddingLeft: 90,
    paddingTop: 135,
  },
  notificationWrap: {
    padding: theme.spacing(2, 9.75)
  }
})

const NotificationList = props => {
  const {
    classes,
    authentication,
    user
  } = props


  const [response, setResponse] = useState(null);

  React.useEffect(() => {
    console.debug(response, 'response')
  }, [response])

  React.useEffect(() => {

    let arrayNotif = []

    if (user) {
      const socket = socketIOClient(ENDPOINT);

      /** socket iop events */
      socket.on(`default ${SOCKET_ADDRESS}${user._id}`, data => {
        console.debug(data)
        arrayNotif.push(data)
        setResponse(arrayNotif);
      });
      socket.on(`realtime ${SOCKET_ADDRESS}${user._id}`, data => {
        console.debug(data)
        arrayNotif.push(data)
        setResponse(arrayNotif);
      });
      socket.on('error', function () {
        console.error("Sorry, there seems to be an error!");
      });
      socket.on('connection_error', function () {
        console.error("Sorry, there seems to be an issue with the websockets connection!");
      });
      socket.on('server_error', function () {
        console.error("Sorry, there seems to be an issue with the websockets server!");
      });

      /** Disconnect socket */
      return () => socket.disconnect();
    }

  }, [user])

  const TEST_RESPONSE = [
    {
      sharedId: '01',
      createdAt: "2020-12-16T00:53:23.780Z",
      updatedAt: "2020-12-16T01:25:01.780Z",
      seenAt: null,
      data: {
        id: '698hg6789s789f6ghjnf6780g6789sfg67hsd89756fg',
        soldDate: "2020-12-16T01:25:01.780Z",
        addressText: '162A Spencer Street, ESSENDON VIC 3040 AU'
      },
      body: 'A property has been sold.',
      user: {},
      type: 'propertySold',
      seen: false,

    }
  ]
  const addressRegex = / *AU *$|AUS *$/gm;

  return (
    <div className={classes.root}>
      <div className={classes.notificationWrap}>
        <Typography variant="h4" component="h2" style={{ marginBottom: 20 }}>
          Notifications
        </Typography>
        {
          TEST_RESPONSE && TEST_RESPONSE.map((value, index) => { //response is the actual object
            return (
              <div style={{ marginBottom: 20 }} key={index}>
                <Typography variant="body2" component="h2">
                  {value.data && value.data.id}
                </Typography>
                <Typography variant="body2" component="h2">
                  {value.body}
                </Typography>
                <Typography variant="body2" component="h2">
                  {value.data.addressText && value.data.addressText.replace(addressRegex, '')}
                </Typography>
              </div>
            )
          })
        }

        {!TEST_RESPONSE &&  //response is the actual object
          <Typography variant='body2'>No notifications.</Typography>
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    user: state.users.user
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {

    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NotificationList));
