import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Chip, withStyles } from '@material-ui/core';
import PMLogo from 'assets/images/pm-logo-inverted.png'
import clsx from 'clsx'

const styles = (theme) => ({
  chip: {
    minWidth: 150,
    textTransform: 'capitalize',
  },
  chipFull: {
    width: '100%',
    textTransform: 'capitalize',
  },
  activeChip: {
    color: '#FFF',
    backgroundColor: '#6dc5a1',
  },
  completedChip: {
    color: '#FFF',
    backgroundColor: '#6dc5a1',
  },
  pendingChip: {
    backgroundColor: '#fdbd26',
  },
  pendingApprovalChip: {
    backgroundColor: '#fdbd26',
  },
  pendingActivationChip: {
    backgroundColor: '#ef6631',
  },
  pendingDefaultChip: {
    backgroundColor: '#d2d2d2',
  },
  errorChip: {
    backgroundColor: '#EF6631',
  },
  noDocuSignChip: {},
})

const SuperStatusChip = (props) => {
  const { classes, type, fullWidth } = props

  const modifyText = (text) => {
    return text ? text.replace('_', ' ') : ''
  }

  const _renderAgencyStatusChip = () => {
    return (
      <>
        {type && (
          <Chip
            className={clsx(classes.chip, {
              [classes.chipFull]: fullWidth,
              [classes.activeChip]:
                type === 'active' ||
                type === 'approved' ||
                type === 'verified' ||
                type === 'activated' ||
                type === 'trial_active' ||
                type === 'sent' ||
                type === 'signed',
              [classes.completedChip]: type === 'completed',
              [classes.pendingChip]:
                type === 'pending' ||
                type === 'payment_set' ||
                type === 'payment_pending' ||
                type === 'charged',
              [classes.pendingApprovalChip]: type === 'pending_approval',
              [classes.pendingActivationChip]: type === 'pending_activation',
              [classes.errorChip]:
                type === 'error' ||
                type === 'deleted' ||
                type === 'archived' ||
                type === 'suspended' ||
                type === 'stale' ||
                type === 'rejected' ||
                type === 'unpaid',
              [classes.noDocuSignChip]: type === 'no_docusign',
            })}
            label={modifyText(type)}
          />
        )}
      </>
    )
  }

  return <div>{_renderAgencyStatusChip()}</div>
}

SuperStatusChip.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string.isRequired,
}

export default withStyles(styles)(SuperStatusChip)
