import React, { useState } from 'react';
import { IconButton, Paper, Container, AppBar, Typography, Button, Grid, withStyles } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import DialogContainer from './DialogContainer';
import DynamicIcons from '../DynamicIcons';
import Image from 'material-ui-image';

const styles = theme => ({
    root: {
        padding: theme.spacing(0, 2)
    },
    headerBar: {
        backgroundColor: theme.palette.light.main,
        boxShadow:'none'
    },
    modal: {
        margin: theme.spacing(3),
        marginRight: 'auto',
        marginLeft: 'auto',
        maxWidth: 'calc(100% - 32px)', 
        borderRadius: 20,
        position: 'relative',    
        '&:hover': {
        outline: 'none',
        },
        overflow: 'hidden',
        padding: theme.spacing(3.25,0)
    },
    closeicon: {
        position: 'absolute',
        top: 10, 
        right: theme.spacing(1.25)
    },
    text: {
        fontSize: 12,
        fontWeight: 300
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: theme.spacing(2)
    },
    ctaRounded: {
        borderRadius: 25,
        color: theme.palette.text.primary, 
        textTransform: 'initial',
        paddingLeft: theme.spacing(3.2),
        paddingRight: theme.spacing(3.2),
        marginLeft: theme.spacing(1.5),
        backgroundColor: theme.palette.common.white,
        '&.MuiButton-contained' : {
          backgroundColor: theme.palette.primary.main
        }
    },
    confirmActions: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing(3),
        marginTop: theme.spacing(2)
    },
    media: {
        // marginTop: theme.spacing(2),
        objectFit: 'cover',
        border: 'solid 2px transparent',
        borderRadius: theme.spacing(1)
    },
    activeMedia: {
        // marginTop: theme.spacing(2),  
        objectFit: 'cover',
        border: 'solid 2px ' + theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.main,
        borderRadius: theme.spacing(1)
    }
});

const MainHeader = withStyles({
    root: {
      boxShadow:'none',
      padding:'10px 0px'
    }
  })(AppBar);

const ImageSelectorDialog = props => {  
    const {
      classes,      
      isOpen, 
      closeCallback,
      title,
      text,
      align,
      showLogo,
      confirmCallback,
      imageOptions
    } = props;

    const style={
        textAlign: align,
        maxHeight: '60vh',
        overflowY: 'scroll'
    }

    const [selectedImage, setSelectedImage] = useState(null); 

    return (                  
        <DialogContainer
                isOpen={isOpen}
                closeCallback={closeCallback}                
            >
                <Paper className={classes.modal} elevation={5}>                
                    <MainHeader className={classes.headerBar} position="static">
                        <IconButton aria-label="close" className={classes.closeicon} onClick={()=> {closeCallback(false)}}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>                         
                    </MainHeader>
                    <Container style={style}>
                        { showLogo &&
                            <DynamicIcons icon="2DCPIconColor" style={{height: '61px', width: '40px', position:'relative', top:'-3px'}}/>
                        }
                        {title && 
                            <Typography variant={'h3'}  className={classes.title}>{title}</Typography>
                        }
                        {text && 
                            <Typography variant={'h5'} className={classes.text}>{text}</Typography>
                        }                       
                        <Grid container spacing={2}>
                            {
                                imageOptions && imageOptions.map((img, index) => {
                                return (
                                    <Grid item xs={6} key={index}>
                                        <Image
                                            style={
                                                {
                                                    width:'100%',
                                                    objectFit:'cover'                                      
                                                }
                                            }
                                            key={index}
                                            className={`${selectedImage && selectedImage === img ? classes.activeMedia : classes.media}`}
                                            src={img}                                
                                            aspectRatio={(4/3)}
                                            disableSpinner
                                            onClick={()=>setSelectedImage(img)}
                                        />
                                    </Grid>
                                )
                                })
                            }
                        </Grid>

                        
                    </Container>
                    <Container>
                        <div className={classes.confirmActions}>
                            <Button className={classes.ctaRounded}
                                variant={'outlined'}
                                color={'primary'}
                                disableElevation
                                onClick={closeCallback }
                                >Cancel</Button>
                            <Button className={classes.ctaRounded}
                                variant={'contained'}
                                color={'primary'}
                                disableElevation
                                onClick={() => confirmCallback(selectedImage) }
                                >Confirm</Button>
                        </div>
                    </Container>
                </Paper>
        </DialogContainer>  
    );
}

export default withStyles(styles)(ImageSelectorDialog);