import React, { useState, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button, Container, Box, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import dashBannerIMG from 'assets/images/agentbanner.png';
import { scrollToRef } from 'components/Web/ScrollEvents';
import _ from 'lodash';
import PropertyLoader from 'components/Web/Property/PropertyLoader';
import AlertNotification from 'components/Web/Form/AlertNotification';

/** actions */
import { agencyv2Actions } from 'store/actions';

/*
 * SELECTORS
 */
import {
  getProperties,
  getAuthentication,
  getUser,
  getCurrentAgencyDeleteProperty,
  getAgency,
} from 'store/selectors/agencyv2.selectors';

/** api redux request */
import { requestGETAPIAgency } from 'store/api';
import DashboardBanner from 'components/Web/Dashboard/DashboardBanner';
import PropertiesListing from 'components/Web/Agency/PropertiesListing';
import { Loader } from 'components/Common';
import Crumbs from 'components/Web/Header/Crumbs';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 90,
    minHeight: '75vh',
  },
  crumbsWrap: {
    padding: '20px 0 16px 0',
  },
});

const ManageProperties = (props) => {
  const { classes } = props;
  const [routerChange] = useRouterChange();
  const dispatch = useDispatch();

  const AGENCY_PROPERTIES = useSelector(getProperties);
  const AUTHENTICATION = useSelector(getAuthentication);
  const USER = useSelector(getUser);
  const AGENCY = useSelector(getAgency);
  const DELETE_PROPERTY_STATUS = useSelector(getCurrentAgencyDeleteProperty);

  const topContainer = useRef(null);
  const executeTopScroll = (d) => scrollToRef(topContainer, d); //add `false` as second parameter to scroll without transition.

  const [isLoading, setIsLoading] = useState(false);
  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  // const [likedPropertiesData, setLikedPropertiesData] = useState([]);
  const [agencyPropertiesData, setAgencyPropertiesData] = useState([]);
  const [deletedProperties, setDeletedProperties] = useState([]);
  /*   const SORT_DEFAULT = [
    {
      title: 'Relevance',
      value: 'Relevance',
      active: true,
    },
    {
      title: 'Newest',
      value: 'Newest',
      active: false,
    },
    {
      title: 'Price (High to Low)',
      value: 'Price (High to Low)',
      active: false,
    },
    {
      title: 'Price (Low to High)',
      value: 'Price (Low to High)',
      active: false,
    },
    {
      title: 'Bedrooms',
      value: 'Bedrooms',
      active: false,
    },
    {
      title: 'Bathrooms',
      value: 'Bathrooms',
      active: false,
    },
    {
      title: 'Sqm',
      value: 'Sqm',
      active: false,
    },
  ] */

  const CRUMBS = [
    { label: 'Agency Dashboard', route: '/agency/dashboard' },
    { label: 'Manage Properties', route: '/agency/manage-properties' },
  ];

  /*   const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  } */
  const defaultPagination = {
    propertiesFilters: [],
    pageNumber: 0,
    pageSize: 100,
    totalResults: null,
  };
  const [pagination, setPagination] = useState(defaultPagination);

  const clearReduxState = () => {
    handleClearPause();
    handleClearDelete();
  };

  window.onbeforeunload = (e) => {
    return clearReduxState();
  };

  useEffect(() => {
    return () => {
      clearReduxState();
    };
  }, []);

  /* useEffect(() => {
        if (AUTHENTICATION && AUTHENTICATION.token === null) {
            routerChange("/");
        }
    }, [AUTHENTICATION]); */

  useEffect(() => {
    /**
     * Initial Scroll to Top
     * */
    executeTopScroll(false);
    localStorage.removeItem('hideState');
    if (AUTHENTICATION && AUTHENTICATION.user_status === 'logged') {
      setIsLoading(true);
      apiRequestAgencyProperties();
    } else {
      // toggleSignUp(true);
      routerChange('/login', {
        origin: props?.history?.location?.pathname ?? window.location.pathname,
      });
    }
    handleClearProperties();

    return () => {
      handleClearProperties();
    };
  }, []);

  /* 
    handle properties callback 
    */
  useEffect(() => {
    /**
     * property data setter */
    if (AGENCY_PROPERTIES) {
      setIsLoading(false);
      if (AGENCY_PROPERTIES?.data && AGENCY_PROPERTIES.data?.entities) {
        const transformedData = {
          propertiesFilters: AGENCY_PROPERTIES.data.entities,
          totalResults: AGENCY_PROPERTIES.data.entities.length,
          skip: 0,
          take: AGENCY_PROPERTIES.data.entities.length,
        };

        defaultSortData(transformedData);
        // setAgencyPropertiesData(transformedData)
      }
    }
  }, [AGENCY_PROPERTIES]);

  useEffect(() => {
    if (DELETE_PROPERTY_STATUS?.status === 'agency/AGENCY_DELETE_PROPERTY_SUCCESS') {
      const options = {
        status: true,
        options: {
          message: 'Property Deleted',
          severity: 'success',
          autoHideDuration: 3000,
        },
      };
      setIsNotification(options);
      const transformedData = {
        propertiesFilters: AGENCY_PROPERTIES.data.entities,
        totalResults: AGENCY_PROPERTIES?.data?.totalResults,
      };
      deletePropertyData(transformedData, DELETE_PROPERTY_STATUS?.payload_success?.data?.data?.entity?._id);
      handleClearDelete();

      //handleClearProperties()
      //handlePagination()
    }
    if (DELETE_PROPERTY_STATUS?.status === 'agency/AGENCY_DELETE_PROPERTY_FAILED') {
      const options = {
        status: true,
        options: {
          message: 'Property Deleting failed',
          severity: 'error',
          autoHideDuration: 3000,
        },
      };
      setIsNotification(options);
      handleClearDelete();
    }
  }, [DELETE_PROPERTY_STATUS]);

  useEffect(() => {
    if (agencyPropertiesData.length) {
      defaultSortData(agencyPropertiesData);
    }
  }, [agencyPropertiesData]);

  /*   const handleSortBy = (data, propertiesData) => {
    //setSearchSortingFilters(data);
    const propertyData = Object.assign({}, agencyPropertiesData?.propertiesFilters)
    const propertiesFilters = Object.assign([], propertyData)
    let type = _.filter(data, function (o) {
      return o.active
    })
    let sortedData = propertiesFilters
    if (data && type && type.length > 0) {
      switch (type[0].value) {
        case 'Relevance':
          sortedData = propertiesFilters
          break
        case 'Newest':
          sortedData =
            getRoutePath() === 'sold'
              ? sortData(propertiesFilters, ['soldDate'], 'desc')
              : sortData(propertiesFilters, ['publishDate'], 'desc')
          break
        case 'Price (High to Low)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                  propertiesFilters,
                  ['rentalPrice', 'publishDate'],
                  ['desc', 'desc']
                )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['desc', 'desc'])
          break
        case 'Price (Low to High)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                  propertiesFilters,
                  ['rentalPrice', 'publishDate'],
                  ['asc', 'asc']
                )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['asc', 'asc'])
          break
        case 'Bedrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bedrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Bathrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bathrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Sqm':
          sortedData = sortData(
            propertiesFilters,
            ['landSize', 'price'],
            ['desc', 'desc']
          )
          break
        default:
          sortedData = propertiesFilters
      }
      propertyData.propertiesFilters = sortedData
      setAgencyPropertiesData(propertyData)
      setPagination(propertyData)
    }
  } */

  const deletePropertyData = (properties, deletedID) => {
    let deletedIds = [];
    deletedIds.push(deletedID);
    deletedIds = [...deletedProperties, ...deletedIds];
    let newArr = properties?.propertiesFilters?.filter(function (item) {
      return deletedIds.indexOf(item?._id) === -1;
    });
    setDeletedProperties(deletedIds);

    const propertyData = Object.assign({}, newArr);
    const propertiesFilters = Object.assign([], propertyData);
    const sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['asc']);
    propertyData.propertiesFilters = sortedData;

    const pageData = {
      ...pagination,
      propertiesFilters: propertyData.propertiesFilters,
      totalResults: propertyData.propertiesFilters.length,
    };
    setPagination(pageData);
  };

  const defaultSortData = (properties) => {
    const propertyData = Object.assign({}, properties.propertiesFilters);
    const propertiesFilters = Object.assign([], propertyData);
    const sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['asc']);
    propertyData.propertiesFilters = sortedData;

    const pageData = {
      ...pagination,
      propertiesFilters: _.union(agencyPropertiesData.propertiesFilters, propertiesFilters),
      totalResults: properties.totalResults,
    };
    setAgencyPropertiesData(pageData);
    setPagination(pageData);
  };

  const sortData = (data, type, order) => {
    const sortBy = type;
    return _.orderBy(
      data,
      sortBy.map((s) => {
        return (r) => {
          return r[s] ? r[s] : '';
        };
      }),
      order,
    );
  };

  /** Get Agency Properties */
  function apiRequestAgencyProperties() {
    let skipCount = agencyPropertiesData.propertiesFilters ? agencyPropertiesData.propertiesFilters.length : 0;

    const ID = USER.agency ?? AGENCY?._id;

    if (skipCount !== undefined && ID) {
      // let request = { take: 10, skip: skipCount };

      let request = {
        query: '?agencyReference=' + ID,
        data: { id: ID },
      };
      dispatch(requestGETAPIAgency(request, 'AGENCY_DASHBOARD_GET_PROPERTIES'));
    }
  }

  /* function saveFilters(params) {
        if (filters && filters.filters) {
            let obj = Object.assign({}, filters.filters);
            if (params) {
                obj.propertyDetails.bedrooms = params[0].selected;
                obj.propertyDetails.bathrooms = params[2].selected;
                obj.propertyDetails.price.min = params[1].values[0].value.min;
                obj.propertyDetails.price.max = params[1].values[0].value.max;
                if (params[3].values) {
                    obj.propertyType = params[3].values;
                    obj.propertyType.map((item, index) => {
                        item.active = params[3].values[index].active;
                    });
                }
                // setSearchFilters(obj);
            }
        }
    } */

  const handlePagination = () => {
    apiRequestAgencyProperties();
  };

  const handleMatchCount = (property) => {
    let count = 0;
    /* if (filters && property) {
            filters.filters.advanceProperty.map((item, index) => {
                if (item.active && property.coefficients) {
                    if (property.coefficients[item.key] >= 0.7) {
                        count++;
                    }
                }
            });
        } */
    return count;
  };

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const handleClearProperties = () => {
    dispatch(agencyv2Actions(null, 'AGENCY_DASHBOARD_GET_PROPERTIES', 'CLEAR'));
  };

  const handleClearPause = () => {
    dispatch(agencyv2Actions(null, 'AGENCY_PAUSE_PROPERTY', 'CLEAR'));
  };

  const handleClearDelete = () => {
    dispatch(agencyv2Actions(null, 'AGENCY_DELETE_PROPERTY', 'CLEAR'));
  };

  useEffect(() => {
    if (USER && !isAgency()) {
      const options = {
        status: true,
        options: {
          severity: 'warning',
          message: 'User Type has not enough permission to view this page.',
          autoHideDuration: 3000,
          onClose: () => routerChange('/agent/login'),
        },
      };
      setIsNotification(options);
      routerChange('/agent/login');
    } else if (!USER && !isAgency()) {
      routerChange('/agency/login');
    }
  }, [USER]);

  const isAgency = () => {
    return USER?.userType.toLowerCase() === 'agency' ||
      USER?.userTypeList.some((i) => ['Agency', 'AgencyAdmin'].includes(i))
      ? true
      : false;
  };

  return (
    <div className={classes.root}>
      <div ref={topContainer} className={classes.topAnchor}></div>
      {isAgency() && (
        <>
          <DashboardBanner title={'Manage Properties'} image={dashBannerIMG} />
          <Loader message={'Loading your properties'} isLoading={isLoading} />

          <Container
            maxWidth={false}
            style={{
              paddingLeft: '6vw',
              paddingRight: '3vw',
            }}
          >
            <Box justifyContent="space-between" display="flex" alignItems="center" className={classes.crumbsWrap}>
              <Crumbs crumbsOption={CRUMBS} />
              <Button
                color={'primary'}
                variant="outlined"
                disableElevation
                startIcon={<AddIcon />}
                onClick={() => routerChange('/agency/add-property')}
                style={{
                  borderRadius: '20px',
                  minWidth: '180px',
                  textTransform: 'capitalize',
                }}
              >
                Add property
              </Button>
            </Box>
            <PropertiesListing
              isLoading={isLoading}
              properties={pagination}
              listingType={'agency-manage'}
              cardType={'grid'}
              handlePagination={handlePagination}
              handleMatchCount={handleMatchCount}
              pagination={pagination}
            />

            <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
          </Container>
        </>
      )}

      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(ManageProperties);
