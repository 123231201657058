import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SuperBreadCrumbs, AgentsHeader, AgentsLists } from 'components/Web/Superadmin'

import AddAgentForm from 'components/Web/ManageAgents/AddAgentForm'
import BulkAddAgentForm from 'components/Web/ManageAgents/BulkAddAgentForm'
import DashboardBanner from 'components/Web/Dashboard/DashboardBanner'
import dashBannerIMG from 'assets/images/agentbanner.png'
/** redux */
import { agencyv2Actions } from 'store/actions'

import { requestGETAPIAgency, requestPUTAPIAgency } from 'store/api'
import {
  getStatus,
  getErrors,
  getAllAgents,
  getAgency,
} from 'store/selectors/agencyv2.selectors'
import Loader from 'components/Web/Loader'
import AlertNotification from 'components/Web/Form/AlertNotification'
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog'
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
    overflow: 'scroll',
    '& .MuiBackdrop-root': {
      maxWidth: 'initial !important',
      left: 0,
    },
  },
})

const AgencyAgents = (props) => {
  const { classes, history, match } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()

  /**
   * ? Maintain selector variable format to UPPERCASE to know distinction
   */
  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)

  const AGENTS = useSelector(getAllAgents)
  const AGENCY = useSelector(getAgency)

  const [isAddingAgent, setIsAddingAgent] = useState(false)
  const [isBulkAddingAgent, setIsBulkAddingAgent] = useState(false)
  const [success, setSuccess] = useState(false)
  const [failed, setFailed] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [filteredAgents, setFilteredAgents] = useState(null)

  const defaultNotification = { status: false, options: null }
  const defaultConfirmation = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)
  const [confirmation, setConfirmation] = useState(defaultConfirmation)

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/agency/dashboard',
    },
    {
      type: 'primary',
      label: 'Manage Agents',
      path: '',
    },
  ]

  window.onbeforeunload = (e) => {
    handleClearData()
  }

  useEffect(() => {
    handleClearData()
    handleGetAgents()

    return () => {
      handleClearData()
    }
  }, [])

  useEffect(() => {
    if (AGENTS?.entity?.agentList) {
      const filtered = AGENTS.entity.agentList.filter(
        (item, index) => item.agentState !== 'NONE'
      )
      setFilteredAgents(filtered)
    }
  }, [AGENTS])

  useEffect(() => {
    /**
     * ! Change Action Type on condition
     */
    if (STATUS === 'agency/AGENCY_GET_ALL_AGENTS_SUCCESS') {
      setIsLoading(false)
    } else if (STATUS === 'agency/AGENCY_GET_ALL_AGENTS_REQUEST') {
      setIsLoading(true)
    } else if (STATUS === 'agency/AGENCY_GET_ALL_AGENTS_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error getting list of agents. Please try again.',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    }

    if (STATUS === 'agency/AGENCY_DELETE_AGENT_SUCCESS') {
      setIsLoading(false)
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Successfully deleted agent.',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
      handleCloseConfirmation()
      handleGetAgents()
    } else if (STATUS === 'agency/AGENCY_DELETE_AGENT_REQUEST') {
    } else if (STATUS === 'agency/AGENCY_DELETE_AGENT_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error deleting agent. Please try again in a few minutes.',
          autoHideDuration: 4000,
        },
      }
      setIsNotification(options)
    }
  }, [STATUS])

  const handleConfirmDialog = (data) => {
    setConfirmation(data)
  }

  const handleCloseConfirmation = () => {
    setConfirmation(defaultConfirmation)
  }

  /**
   *? API Request */

  /**
   * Sample API Request via Redux Dispatcher
   * @param query leave null if no inline url query is required
   * @param data leave as empty obj as default
   * ? Always use dispatch for api calls
   */
  /*   const handlePostSample = (data) => {
    let request = {
      query: null,
      data: { ...data },
    }
    dispatch(requestPOSTAPIAgency(request, 'AGENCY_LOGIN'))
  } */

  const handleGetAgents = (data) => {
    setIsLoading(true)
    if (AGENCY) {
      let request = {
        query: AGENCY._id + '/agent/list',
        data: {},
      }
      dispatch(requestGETAPIAgency(request, 'AGENCY_GET_ALL_AGENTS'))
    }
  }

  /*   const handlePutSample = (data) => {
    let request = {
      query: null,
      data: { ...data },
    }
    dispatch(requestPUTAPIAgency(request, 'AGENCY_LOGIN'))
  } */

  const handleDeleteAgent = () => {
    if (confirmation.options?.data?._id && AGENCY) {
      let request = {
        query: confirmation.options.data._id,
        data: {
          agencyReference: AGENCY._id,
        },
      }
      dispatch(requestPUTAPIAgency(request, 'AGENCY_DELETE_AGENT'))
    }
  }

  /***** End Sample Here */

  /**
   * ? Redux Localized Actions
   * */
  /*   const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'))
  } */

  const handleClearData = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
  }

  const submitAgents = (data) => {
    setIsBulkAddingAgent(false)
    routerChange('/agency/manage-agents/bulk-upload-agents', data)
  }

  return (
    <div className={classes.root}>
      <Loader isLoading={isLoading} message={'Loading agents...'} />
      <DashboardBanner title={'Manage Agent'} image={dashBannerIMG} />
      <SuperBreadCrumbs
        section={'Manage Agents'}
        crumbsLinks={CRUMBS}

      />
      <AddAgentForm
        isAddingAgent={isAddingAgent}
        success={success}
        failed={failed}
        status={STATUS}
        error={ERROR}
        setSuccess={setSuccess}
        setFailed={setFailed}
        submitAgents={submitAgents}
        setIsAddingAgent={setIsAddingAgent}
      />
      <BulkAddAgentForm
        isBulkAddingAgent={isBulkAddingAgent}
        success={success}
        failed={failed}
        status={STATUS}
        error={ERROR}
        setSuccess={setSuccess}
        setFailed={setFailed}
        submitAgents={submitAgents}
        setIsBulkAddingAgent={setIsBulkAddingAgent}
      />
      <AgentsHeader
        type={'agency'}
        setIsAddingAgent={setIsAddingAgent}
        setIsBulkAddingAgent={setIsBulkAddingAgent}

        match={match}
      />
      {!isLoading && filteredAgents && (
        <AgentsLists
          type={'agency'}
          agentsData={filteredAgents}

          match={match}
          handleConfirmDialogOpen={handleConfirmDialog}
        />
      )}
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
      {confirmation.status && (
        <ConfirmDialog
          isOpen={confirmation.status}
          title={confirmation.options.title}
          text={confirmation.options.message}
          closeCallback={handleCloseConfirmation}
          confirmCallback={handleDeleteAgent}
        />
      )}
    </div>
  )
}
export default withStyles(styles)(AgencyAgents)
