export function getMpp(state) {
  return state?.mpp
}

export function getRecommendations(state) {
  return state?.mpp?.recommendations
}

export function getLikesDislikes(state) {
  return state?.mpp?.likes_dislikes
}

export function getFavourites(state) {
  return state.mpp.mpp_favourites
}

export function getDislikes(state) {
  return state.mpp.mpp_dislikes
}

export function getStatus(state) {
  return state.mpp.status
}

export function getErrors(state) {
  return state.mpp.error
}
