import AgentSearch from "../../containers/Mobile/AgentSearch";
import AgentResults from "../../containers/Mobile/AgentResults";
import AgentDetails from "../../containers/Mobile/AgentDetails";
import AgentAddProperty from "../../containers/Mobile/AgentAddProperty";
import ConfirmProperty from "../../containers/Mobile/ConfirmProperty";

let routes = [{
    path: '/agent',
    component: AgentSearch,
},{
    path: '/agent/results',
    component: AgentResults,
},{
    path: '/agent/results/:id',
    component: AgentDetails,
},{
    path: '/agent/add-property',
    component: AgentAddProperty,
},
{
    path: '/confirm-property/:target/:id',
    component: ConfirmProperty,
}]

export default routes;
