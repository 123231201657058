import {
  REQUEST_AGENCY_REGISTER,
  REQUEST_AGENCY_REGISTER_SUCCESS,
  REQUEST_AGENCY_REGISTER_FAIL,
  REQUEST_AGENCY_CONTACT_ADMIN,
  REQUEST_AGENCY_CONTACT_ADMIN_SUCCESS,
  REQUEST_AGENCY_CONTACT_ADMIN_FAIL,
  REQUEST_AGENCY_DETAILS,
  REQUEST_AGENCY_DETAILS_SUCCESS,
  REQUEST_AGENCY_DETAILS_FAIL,
  REQUEST_AGENTS_REGISTER,
  REQUEST_AGENTS_REGISTER_SUCCESS,
  REQUEST_AGENTS_REGISTER_FAIL,
  REQUEST_AGENCY_AGENTS,
  REQUEST_AGENCY_AGENTS_SUCCESS,
  REQUEST_AGENCY_AGENTS_FAIL,
  REQUEST_RESEND_AGENT_INVITATION,
  REQUEST_RESEND_AGENT_INVITATION_SUCCESS,
  REQUEST_RESEND_AGENT_INVITATION_FAIL,
  REQUEST_DISABLE_AGENT,
  REQUEST_DISABLE_AGENT_SUCCESS,
  REQUEST_DISABLE_AGENT_FAIL,
  REQUEST_VERIFY_AGENCY,
  REQUEST_VERIFY_AGENCY_SUCCESS,
  REQUEST_VERIFY_AGENCY_FAIL,
  REQUEST_AGENCY_ME,
  REQUEST_AGENCY_ME_SUCCESS,
  REQUEST_AGENCY_ME_FAIL,
  REQUEST_UPGRADE_PROPERTY,
  REQUEST_UPGRADE_PROPERTY_SUCCESS,
  REQUEST_UPGRADE_PROPERTY_FAIL,
  REQUEST_STATISTICS,
  REQUEST_STATISTICS_SUCCESS,
  REQUEST_STATISTICS_FAIL,
  REQUEST_UPDATE_AGENCY,
  REQUEST_UPDATE_AGENCY_SUCCESS,
  REQUEST_UPDATE_AGENCY_FAIL,
  REQUEST_APPLY_PROMO_CODE,
  REQUEST_APPLY_PROMO_CODE_SUCCESS,
  REQUEST_APPLY_PROMO_CODE_FAIL,
  REQUEST_APPLY_PROMO_CODE_RESET,
} from '../constants/agency.constants';
import { PURGE } from 'redux-persist';

const INITIAL_STATE = {
  request: null,
  agency: null,
  agents_listing: null,
  new_agents_listing: null,
  request_agency_register_error: null,
  request_agency_register_status: null,
  agency_contact_admin: null,
  request_agency_contact_admin_error: null,
  request_agency_contact_admin_status: null,
  current_agency: null,
  request_agency_details_error: null,
  request_agency_details_success: null,
  request_agents_register_error: null,
  request_agents_register_status: null,
  request_agents_listing_error: null,
  request_agents_listing_status: null,
  request_resend_agent_invitation_error: null,
  request_resend_agent_invitation_status: null,
  request_disable_agent_error: null,
  request_disable_agent_status: null,
  request_verify_agency: null,
  request_verify_agency_error: null,
  request_verify_agency_status: null,
  agency_me: null,
  request_agency_me_status: null,
  request_agency_me_error: null,
  upgrade_property_data: null,
  request_upgrade_property_status: null,
  request_upgrade_property_error: null,
  statistics: null,
  request_statistics_error: null,
  request_statistics_success: null,
  request_agency_update: null,
  request_agency_update_status: null,
  request_agency_update_error: null,
  request_apply_promo_code: null,
  request_apply_promo_code_status: null,
  request_apply_promo_code_error: null,
};

const agencyReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_AGENCY_REGISTER:
      return {
        ...state,
        request: action.params,
        request_agency_register_status: 'progress',
        request_agency_register_error: null,
      };

    case REQUEST_AGENCY_REGISTER_SUCCESS:
      return {
        ...state,
        agency: action.payload.data,
        request_agency_register_status: 'success',
        request_agency_register_error: null,
      };
    case REQUEST_AGENCY_REGISTER_FAIL:
      return {
        ...state,
        request_agency_register_status: 'failed',
        request_agency_register_error: action.error,
      };

    case REQUEST_AGENCY_CONTACT_ADMIN:
      return {
        ...state,
        request: action.params,
        request_agency_contact_admin_status: 'progress',
        request_agency_contact_admin_error: null,
      };

    case REQUEST_AGENCY_CONTACT_ADMIN_SUCCESS:
      return {
        ...state,
        agency_contact_admin: action.payload.data,
        request_agency_contact_admin_status: 'success',
        request_agency_contact_admin_error: null,
      };
    case REQUEST_AGENCY_CONTACT_ADMIN_FAIL:
      return {
        ...state,
        request_agency_contact_admin_status: 'failed',
        request_agency_contact_admin_error: action.error,
      };
    case REQUEST_AGENCY_DETAILS:
      return {
        ...state,
        request: action.params,
        request_agency_details_success: 'progress',
        request_agency_details_error: null,
      };

    case REQUEST_AGENCY_DETAILS_SUCCESS:
      return {
        ...state,
        current_agency: action.payload.data,
        request_agency_details_success: 'success',
        request_agency_details_error: null,
      };
    case REQUEST_AGENCY_DETAILS_FAIL:
      return {
        ...state,
        request_agency_details_success: 'failed',
        request_agency_details_error: action.error,
      };
    case REQUEST_AGENTS_REGISTER:
      return {
        ...state,
        request: action.params,
        request_agents_register_status: 'progress',
        request_agents_register_error: null,
      };

    case REQUEST_AGENTS_REGISTER_SUCCESS:
      return {
        ...state,
        new_agents_listing: action.payload.data,
        request_agents_register_status: 'success',
        request_agents_register_error: null,
      };
    case REQUEST_AGENTS_REGISTER_FAIL:
      return {
        ...state,
        request_agents_register_status: 'failed',
        request_agents_register_error: action.error,
      };
    case REQUEST_AGENCY_AGENTS:
      return {
        ...state,
        request: action.params,
        request_agents_listing_status: 'progress',
        request_agents_listing_error: null,
      };

    case REQUEST_AGENCY_AGENTS_SUCCESS:
      return {
        ...state,
        agents_listing: action.payload.data,
        request_agents_listing_status: 'success',
        request_agents_listing_error: null,
      };
    case REQUEST_AGENCY_AGENTS_FAIL:
      return {
        ...state,
        request_agents_listing_status: 'failed',
        request_agents_listing_error: action.error,
      };
    case REQUEST_RESEND_AGENT_INVITATION:
      return {
        ...state,
        agency_actions_request: { id: action.params, action: 'resend_invitation' },
        request_resend_agent_invitation_status: 'progress',
        request_resend_agent_invitation_error: null,
      };

    case REQUEST_RESEND_AGENT_INVITATION_SUCCESS:
      return {
        ...state,
        request_resend_agent_invitation_status: 'success',
        request_resend_agent_invitation_error: null,
      };
    case REQUEST_RESEND_AGENT_INVITATION_FAIL:
      return {
        ...state,
        request_resend_agent_invitation_status: 'failed',
        request_resend_agent_invitation_error: action.error,
      };
    case REQUEST_DISABLE_AGENT:
      return {
        ...state,
        agency_actions_request: { id: action.params, action: 'disable_agent' },
        request_disable_agent_status: 'progress',
        request_disable_agent_error: null,
      };

    case REQUEST_DISABLE_AGENT_SUCCESS:
      return {
        ...state,
        request_disable_agent_status: 'success',
        request_disable_agent_error: null,
      };
    case REQUEST_DISABLE_AGENT_FAIL:
      return {
        ...state,
        request_disable_agent_status: 'failed',
        request_disable_agent_error: action.error,
      };
    case REQUEST_VERIFY_AGENCY:
      return {
        ...state,
        request: action.params,
        request_verify_agency_status: 'progress',
        request_verify_agency_error: null,
      };

    case REQUEST_VERIFY_AGENCY_SUCCESS:
      return {
        ...state,
        request_verify_agency: action.payload.data,
        request_verify_agency_status: 'success',
        request_verify_agency_error: null,
      };
    case REQUEST_VERIFY_AGENCY_FAIL:
      return {
        ...state,
        request_verify_agency_status: 'failed',
        request_verify_agency_error: action.error,
      };
    case REQUEST_AGENCY_ME:
      return {
        ...state,
        request: action.params,
        request_agency_me_status: 'progress',
        request_agency_me_error: null,
      };

    case REQUEST_AGENCY_ME_SUCCESS:
      return {
        ...state,
        agency_me: action.payload.data,
        request_agency_me_status: 'success',
        request_agency_me_error: null,
      };
    case REQUEST_AGENCY_ME_FAIL:
      return {
        ...state,
        request_agency_me_status: 'failed',
        request_agency_me_error: action.error,
      };
    case REQUEST_UPGRADE_PROPERTY:
      return {
        ...state,
        request: action.params,
        request_upgrade_property_status: 'progress',
        request_upgrade_property_error: null,
      };

    case REQUEST_UPGRADE_PROPERTY_SUCCESS:
      return {
        ...state,
        upgrade_property_data: action.payload.data,
        request_upgrade_property_status: 'success',
        request_upgrade_property_error: null,
      };
    case REQUEST_UPGRADE_PROPERTY_FAIL:
      return {
        ...state,
        request_upgrade_property_status: 'failed',
        request_upgrade_property_error: action.error,
      };
    case REQUEST_STATISTICS:
      return {
        ...state,
        request: action.params,
        request_statistics_success: 'progress',
        request_statistics_error: null,
      };

    case REQUEST_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: action.payload.data,
        request_statistics_success: 'success',
        request_statistics_error: null,
      };
    case REQUEST_STATISTICS_FAIL:
      return {
        ...state,
        request_statistics_success: 'failed',
        request_statistics_error: action.error,
      };
    case REQUEST_UPDATE_AGENCY:
      return {
        ...state,
        request_agency_update: null,
        request_agency_update_status: 'progress',
      };

    case REQUEST_UPDATE_AGENCY_SUCCESS:
      return {
        ...state,
        request_agency_update: action.payload.data,
        request_agency_update_status: 'success',
      };
    case REQUEST_UPDATE_AGENCY_FAIL:
      return {
        ...state,
        request_agency_update_status: 'failed',
        request_agency_update_error: action.error,
      };
    case REQUEST_APPLY_PROMO_CODE:
      return {
        ...state,
        request_apply_promo_code: null,
        request_apply_promo_code_status: 'progress',
      };

    case REQUEST_APPLY_PROMO_CODE_SUCCESS:
      return {
        ...state,
        request_apply_promo_code: action.payload.data,
        request_apply_promo_code_status: 'success',
      };
    case REQUEST_APPLY_PROMO_CODE_FAIL:
      return {
        ...state,
        request_apply_promo_code_status: 'failed',
        request_apply_promo_code_error: action.error,
      };
    case REQUEST_APPLY_PROMO_CODE_RESET:
      return {
        ...state,
        request_apply_promo_code_status: null,
        request_apply_promo_code_error: null,
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default agencyReducers;
