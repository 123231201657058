import React from 'react'
import { Link } from 'react-router-dom'

import NoResultsCard from './NoResultsCard'

import PropertyCard from './PropertyCard'
import { PROPERTY_CARDS } from './constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { css } from 'styled-components'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'

import { withStyles, CircularProgress, Box } from '@material-ui/core'
import { useRef } from 'react'
import { scrollToRef } from '../ScrollEvents'
import { useEffect } from 'react'
import { StorageActions } from 'store/actions/storage.actions'
import { useDispatch, useSelector } from 'react-redux'
import { getPropertySearchedRef } from 'store/selectors/storage.selectors'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
})

const NoResult = styled.div`
  padding: 20px 20px 20px;
  p {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
  }
`

const Property = (props) => {
  const {
    classes,
    currentScreen,
    authentication,
    settings,
    toggleSignUp,
    setCurrentProperty,
    properties,
    apiRequestSaveProperty,
    apiRequestSearchProperty,
    isLoading,
    isFiltered,
    listingType,
    skipTakeData,

    history,
  } = props

  const [items, setItems] = React.useState([]) // PROPERTY_CARDS
  const [hasMore, setHasMore] = React.useState(true)
  const [requestIndex, setRequestIndex] = React.useState(1)

  const PROPERTY_SEARCH_REF = useSelector(getPropertySearchedRef)
  const dispatch = useDispatch()

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const clickedPropertyRef = useRef()

  let sto = null

  React.useEffect(() => {
    return () => {
      clearTimeout(sto)
      setItems([])
    }
  }, [])

  React.useEffect(() => {
    /**
     * Property Data
     * */
    setItems(properties.propertiesFilters)

    if (properties.propertiesFilters) {
      var totalresults =
        skipTakeData && skipTakeData.totalResults ? skipTakeData.totalResults : 0
      if (skipTakeData && totalresults > properties.propertiesFilters.length) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }
    }
  }, [properties])

  const fetchMoreData = () => {
    if (skipTakeData.totalResults === items.length || props.omcsFullyLoaded === true) {
      setHasMore(false)
      return
    }

    const maxRequest = Math.ceil(properties.totalResults / skipTakeData.take)
    if (items.length < properties.totalResults && requestIndex < maxRequest) {
      apiRequestSearchProperty(skipTakeData, requestIndex)
      setRequestIndex(requestIndex + 1)
    }
    sto = setTimeout(() => {
      props.handlePagination()
    }, 2000)

    triggerAnalyticsEvent({
      event: 'results_actions',
      eventFrom: 'Mobile',
      actionScope: 'results_listing_loadmore',
      actionName: 'Results Listing Load More',
      actionTarget: 'Load more triggered ' + requestIndex + 'x',
      actionReference: 'results',
    })
  }

  const refrestData = () => {
    setItems(properties.propertiesFilters)
  }

  const getSuburb = () => {
    let suburbs = ''
    if (isFiltered && isFiltered.suburbs) {
      isFiltered.suburbs.map((item, index) => {
        suburbs += item.locality + ', '
      })
    }

    return suburbs.replace(/,\s*$/, '')
  }

  const noResultsMessage = (listing) => {
    switch (listing) {
      case 'favourites':
        return (
          <p>
            There are <strong>0</strong> properties on your favourite list. Try adding
            some to see them listed here.
          </p>
        )

      case 'disliked-properties':
        return (
          <p>
            There are <strong>0</strong> properties on your dislikes list. Try adding
            some to see them listed here.
          </p>
        )
      case 'liked-properties':
        return (
          <p>
            You haven't added any homes yet. Start searching for properties to add now.
          </p>
        )

      case 'rent':
        return (
          <p>
            There are <strong>0</strong> properties for rent in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )

      case 'sold':
        return (
          <p>
            There are <strong>0</strong> properties sold in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )

      case 'offmarket':
        return (
          <p>
            There are <strong>0</strong> offmarket properties in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )
      default:
        return (
          <p>
            There are <strong>0</strong> properties for sale in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )
    }
  }

  useEffect(() => {
    if (items && items.length > 0 && clickedPropertyRef.current) {
      scrollToRef(clickedPropertyRef, false)
      dispatch(StorageActions(null, 'GENERAL_SETTINGS', 'SET_PROPERTY_SEARCH_REF'))
    }
  }, [items, clickedPropertyRef])

  return (
    <Box>
      {items && items.length > 0 ? (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div style={{ padding: '5px 23px 20px', textAlign: 'center' }}>
              {skipTakeData && skipTakeData.totalResults !== items.length ? (
                <CircularProgress size={20} />
              ) : (
                <p style={{ textAlign: 'center', fontSize: 14, padding: '20px 0 0' }}>
                  Change your search criteria to view more properties
                </p>
              )}
            </div>
          }
          endMessage={
            <p style={{ textAlign: 'center' }}>
              Change your search criteria to view more properties.
            </p>
          }
          scrollableTarget="scroll-container"
          refreshFunction={refrestData}
          pullDownToRefresh={false}
          pullDownToRefreshThreshold={100}
          pullDownToRefreshContent={
            <p style={{ textAlign: 'center', padding: '10px ' }}>
              &#8595; Pull down to refresh
            </p>
          }
          releaseToRefreshContent={
            <p style={{ textAlign: 'center', padding: '10px ' }}>
              &#8593; Release to refresh
            </p>
          }
        >
          {items.map((value, index) => {
            return (
              <div
                key={index}
                ref={value.property === PROPERTY_SEARCH_REF ? clickedPropertyRef : null}
                onClick={() =>
                  dispatch(
                    StorageActions(
                      value.property,
                      'GENERAL_SETTINGS',
                      'SET_PROPERTY_SEARCH_REF'
                    )
                  )
                }
              >
                <PropertyCard
                  addSMSOnCard={true}
                  cardType={'list'}
                  properties={value}
                  {...propertyConfigSetter(
                    'card-type',
                    value ? (value.subscriptionType ? value.subscriptionType : '') : ''
                  )}
                  authentication={authentication}
                  settings={settings}
                  toggleSignUp={toggleSignUp}
                  setCurrentProperty={setCurrentProperty}
                  apiRequestSaveProperty={apiRequestSaveProperty}
                  // handleMatchCount={props.handleMatchCount}
                  listingType={listingType}
                />
              </div>
            )
          })}
        </InfiniteScroll>
      ) : !isLoading ? (
        <NoResult>
          {listingType.includes('liked-properties') ||
          listingType.includes('favourites') ? (
            noResultsMessage(listingType)
          ) : (
            <NoResultsCard pathName={history?.location?.pathname} />
          )}
        </NoResult>
      ) : (
        ''
      )}
    </Box>
  )
}

export default withStyles(styles)(Property)
