import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from '../Image'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { withStyles, IconButton } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
  content: {
    height: '100%',
    width: '100%',
  },
  wrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  carousel: {
    // maxHeight: '281px'
  },
  photoCount: {
    position: 'absolute',
    right: theme.spacing(2.5),
    bottom: theme.spacing(2.5),
    padding: theme.spacing(0, 0.5),
    color: theme.palette.common.white,
    borderRadius: 20,
    pointerEvents: 'none',
  },
  nextButton: {
    position: 'absolute',
    right: 0,
    top: '45%',
    paddingRight: '0',
    color: '#ffffff',
  },
  prevButton: {
    position: 'absolute',
    left: 0,
    top: '45%',
    paddingLeft: '0',
    color: '#ffffff',
  },
})

const ImageCarousel = ({
  classes,
  data,
  selectImageIndex,
  setSelectImageIndex,
  floorPlan,
  handleFullscreenIndex
}) => {
  const [images, setImages] = useState(data)
  const [slideIndex, setSlideIndex] = useState(0)
  const SliderRef = React.useRef()

  React.useEffect(() => {
    let isUnmount = false

    if (!isUnmount) {
      setImages(data)
    }

    return () => {
      isUnmount = true
    }
  }, [data])

  React.useEffect(() => {
    if (selectImageIndex) {
      setSlideIndex(selectImageIndex)
    }

    return () => {
      setSelectImageIndex(null)
    }
  }, [selectImageIndex])

  const handleChange = (ind) => {
    if (slideIndex <= images.length - 1 && !isNaN(ind)) {
      setSlideIndex(ind)
    }
  }

  const handleNext = () => {
    if (
      slideIndex <= images.length - 1 &&
      slideIndex !== images.length - 1 &&
      SliderRef?.current
    ) {
      setSlideIndex(slideIndex + 1)
      SliderRef.current.slickNext()
    }
  }

  const handlePrev = () => {
    if (slideIndex > 0 && SliderRef?.current) {
      setSlideIndex(slideIndex - 1)
      SliderRef.current.slickPrev()
    }
  }

  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className={classes.wrapper}>
      {images !== null && images.length !== 0 && (
        <Slider
          className={classes.carousel}
          value={slideIndex}
          afterChange={handleChange}
          ref={SliderRef}
          {...carouselSettings}
        >
          {images !== null &&
            images.map((src, idx) => {
              const getFPIndex = idx + 1
              const imgStyle = {
                objectFit:
                  floorPlan && getFPIndex === images.length ? 'contain' : 'cover',
              }
              return (
                <Image
                  key={idx}
                  src={src}
                  index={idx}
                  aspectRatio={3 / 2}
                  imageStyle={imgStyle}
                  onClick={() => { handleFullscreenIndex(idx) }}
                />
              )
            })}
        </Slider>
      )}

      {images && images.length > 1 && (
        <>
          <IconButton
            aria-label="Previous"
            className={classes.prevButton}
            disabled={slideIndex === 0}
            onClick={handlePrev}
          >
            <ChevronLeftIcon style={{ fontSize: 50 }} />
          </IconButton>
          <IconButton
            aria-label="Next"
            className={classes.nextButton}
            disabled={slideIndex === images.length - 1}
            onClick={handleNext}
          >
            <ChevronRightIcon style={{ fontSize: 50 }} />
          </IconButton>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(ImageCarousel)
