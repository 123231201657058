import React, { useEffect, useRef } from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

/** Custom Components */
import SearchInput from './SearchInput'
import FilterOptions from './FilterOptions'
import Crumbs from '../Header/Crumbs'
import {
  clearCurrentProperty,
  clearSuburbSearch,
  setCurrentProperty,
  setIncludeNearby,
  setPropertySearchFilters,
  setPropertySuburbSearch,
  toggleFilter,
  toggleFilterWeb,
  toggleSignUp,
} from '../../../store/actions'
import {
  requestGetPropertySave,
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
  requestPropertySearchRentals,
  requestPropertySearchSold,
} from '../../../store/api'
import ToggleButton from '@material-ui/lab/ToggleButton'
import { makeStyles, Grid, Checkbox } from '@material-ui/core'
import FilterOptionsV2 from './FilterOptionsV2'

const useStyles = makeStyles((theme) => ({
  crumbsWrap: {
    padding: theme.spacing(2, 3),
  },
  mainContainer: {
    zIndex: 6,
    backgroundColor: '#FFF',
    position: 'relative',
    borderRadius: 4,
    padding: '0 142px 0 55px',
    '@media (max-width: 1140px)': {
      '& > div > div': {
        maxWidth: '100%',
      },
    },
  },
  inlineSearchGridWrap: {
    [`@media (max-width: 1366px) and (orientation: landscape)`]: {
      flexDirection: 'column',
      '& > div': {
        maxWidth: '100%',
        flexBasis: '100%',
      },
    },
  },
  perfectPropertyWrap: {
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    '@media (max-width: 1140px)': {
      flexDirection: 'column',
      '& > div:first-of-type': {
        marginBottom: 16,
      },
    },
  },
  optionButton: {
    textTransform: 'none',
    width: '10.500em',
    height: '2.875em',
    borderRadius: '2.250em',
    color: '#000',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 1,
    marginRight: 10,
    padding: '11px 0',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '110px',
  },
  optionIcon: {
    marginLeft: '0.313em',
    width: '0.625em',
    height: '0.625em',
  },

  checkboxRoot: {
    paddingRight: 0,
    paddingLeft: '5px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  checkedCheckbox: {
    color: 'white',
  },
}))

const Searchbar = (props) => {
  const {
    type,

    settings,
    toggleFilter,
    toggleFilterWeb,
    apiRequestSearchProperty,
    navigateFilterButtonTo,
    setPropertySearch,
    setIncludeNearbyFilter,
    filters,
    isFiltered,
    showSearchBar,
    showFilters,
    isFullWidth,
    setPerfectPropertyFilter,
    path,
    setSearchFilters,
    setInvalidSearch,
    isSearchGrid = true,
    showCrumbs,
    showToggles,
    setOmcsStatus,
    omcsStatus,
    customGrid,
    sortType,
  } = props

  const [selectedChipValue, setSelectedChipValue] = React.useState([])

  /** controller */
  const [showModalOptions, setShowModalOptions] = React.useState(false)
  const [selectedFilterType, setSelectedFilterType] = React.useState('')
  const [showModalBottomOptions, setShowModalBottomOptions] = React.useState(false)
  const [isInputFocused, setIsInputFocused] = React.useState(false)
  const [suburbs, setSuburbs] = React.useState('')
  const [crumbs, setCrumbs] = React.useState(null)
  const [toggleStatus, setToggleStatus] = React.useState(omcsStatus)
  const classes = useStyles()
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  React.useEffect(() => {
    if (isFiltered) {
      let suburb = []
      if (isFiltered.suburbs && isFiltered.suburbs.length) {
        isFiltered.suburbs.map((item, index) => {
          suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode)
        })
      }
      setSuburbs(suburb)
    }
  }, [isFiltered])

  React.useEffect(() => {
    crumbsOption(path, path)
  }, [])

  React.useEffect(() => {
    if (setOmcsStatus) {
      setOmcsStatus(toggleStatus)
    }

    if (props.handleFilter) {
      props.handleFilter(toggleStatus)
    }
  }, [toggleStatus])

  /**
   * do not hide modal when selecting a different option
   * | close modal when selecting cancel or apply */
  function showOptionModal(selected, closeModal) {
    if (closeModal) {
      setShowModalOptions(false)
      setSelectedFilterType('')
      toggleFilter(false)
    } else {
      setShowModalOptions(true)
      toggleFilter(true)
      setSelectedFilterType(selected)
    }
  }

  function showOptionBottomModal() {
    setShowModalBottomOptions(!showModalBottomOptions)
  }

  async function saveFilters(params) {
    if (filters && filters.filters) {
      /*  let obj = Object.assign({}, filters.filters);
          if(params) {
            obj.propertyDetails.bedrooms = params[0].selected
            obj.propertyDetails.bathrooms = params[2].selected
    
            if(path === 'rent') {
              obj.propertyDetails.priceRent.min = params[1].values[0].value.min
              obj.propertyDetails.priceRent.max = params[1].values[0].value.max
            } else {
              obj.propertyDetails.price.min = params[1].values[0].value.min
              obj.propertyDetails.price.max = params[1].values[0].value.max
            }
            
            if(params[3].values) {
              obj.propertyType = params[3].values
              obj.propertyType.map((item,index) => {
                item.active = params[3].values[index].active
              })
            } */
      setSearchFilters(params)
      // apiRequestSearchProperty();
    }
  }

  function crumbsOption(label, route) {
    setCrumbs({ label: label, route: '/' + route })
  }

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          showOptionModal(null, true)
          setIsInputFocused(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        {showCrumbs && (
          <div className={classes.crumbsWrap}>
            {crumbs && <Crumbs crumbsOption={crumbs} />}
          </div>
        )}
        <Grid
          container
          className={
            sortType === 'perfect-property'
              ? classes.perfectPropertyWrap
              : classes.inlineSearchGridWrap
          }
        >
          <Grid
            item
            xs={12}
            md={sortType === 'perfect-property' ? 3 : 12}
            lg={customGrid ? customGrid : isSearchGrid ? 6 : 12}
          >
            {showSearchBar && (
              <SearchInput
                setChipValue={setSelectedChipValue}
                setIsInputFocused={setIsInputFocused}
                isInputFocused={isInputFocused}
                isFullWidth={isFullWidth}
                setPropertySearch={setPropertySearch}
                setPerfectPropertyFilter={setPerfectPropertyFilter}
                isClearFilter={props.isClearFilter}
                filters={filters}
                apiRequestSearchProperty={apiRequestSearchProperty}
                placeholder={props.placeholder}
                path={props.path}
                onFocus={props.onFocus}
                setInvalidSearch={setInvalidSearch}
                sortType={sortType}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={sortType === 'perfect-property' ? 9 : 12}
            lg={customGrid ? 12 - customGrid : isSearchGrid ? 6 : 12}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {showToggles && (
                <div style={{ display: 'inline-flex', paddingLeft: 10 }}>
                  <ToggleButton
                    className={classes.optionButton}
                    style={{
                      backgroundColor: toggleStatus[0].active ? '#e1315a' : '#FFF',
                      width: '135px',
                    }}
                    value="check"
                    selected={toggleStatus[0].active}
                    onChange={() => {
                      const o = Object.assign([], toggleStatus)
                      o[0].active = !o[0].active
                      setToggleStatus(o)
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: toggleStatus[0].active ? '#FFF' : '#4d4d4d',
                      }}
                    >
                      {'Off Market '}
                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checkedCheckbox,
                        }}
                        color="default"
                        name="comingsoonCheckbox"
                        checked={toggleStatus[0].active}
                        onChange={() => {
                          const o = Object.assign([], toggleStatus)
                          o[0].active = !o[0].active
                          setToggleStatus(o)
                        }}
                        size="small"
                      />
                    </span>
                  </ToggleButton>

                  <ToggleButton
                    className={classes.optionButton}
                    style={{
                      backgroundColor: toggleStatus[1].active
                        ? 'rgba(253, 189, 38, 0.9)'
                        : '#FFF',
                      width: '135px',
                    }}
                    value="check"
                    selected={toggleStatus[1].active}
                    onChange={() => {
                      const o = Object.assign([], toggleStatus)
                      o[1].active = !o[1].active
                      setToggleStatus(o)
                      if (!o[0].active) {
                        localStorage.removeItem('isOffMarket')
                      }
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: toggleStatus[1].active ? '#FFF' : '#4d4d4d',
                      }}
                    >
                      {'Coming Soon '}

                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checkedCheckbox,
                        }}
                        color="default"
                        name="comingsoonCheckbox"
                        checked={toggleStatus[1].active}
                        onChange={() => {
                          const o = Object.assign([], toggleStatus)
                          o[1].active = !o[1].active
                          setToggleStatus(o)
                          if (!o[0].active) {
                            localStorage.removeItem('isOffMarket')
                          }
                        }}
                        size="small"
                      />
                    </span>
                  </ToggleButton>
                </div>
              )}
              {showFilters && (
                <div ref={wrapperRef}>
                  {/* <FilterOptions
                    type={type}
                    sortType={sortType}
                    navigateFilterButtonTo={navigateFilterButtonTo}
                    
                    showModalOptions={showModalOptions}
                    showOptionModal={showOptionModal}
                    selectedFilterType={selectedFilterType}
                    isInputFocused={isInputFocused}
                    setIsInputFocused={setIsInputFocused}
                    settings={settings}
                    toggleFilter={toggleFilter}
                    toggleFilterWeb={toggleFilterWeb}
                    isClearFilter={props.isClearFilter}
                    filters={filters}
                    saveFilters={saveFilters}
                    setIncludeNearbyFilter={setIncludeNearbyFilter}
                    apiRequestSearchProperty={apiRequestSearchProperty}
                    path={props.path}
                  /> */}
                  <FilterOptionsV2
                    showModalOptions={showModalOptions}
                    showOptionModal={showOptionModal}
                    setIsInputFocused={setIsInputFocused}
                    path={props.path}
                    toggleFilterWeb={toggleFilterWeb}
                    selectedFilterType={selectedFilterType}
                  />
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      toggleFilterWeb: (checked) => toggleFilterWeb(checked),
      saveProperty: (params) => requestPropertySave(params),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchBuyProperty: (params) => requestPropertySearch(params),
      searchSoldProperty: (params) => requestPropertySearchSold(params),
      searchRentProperty: (params) => requestPropertySearchRentals(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      getSavedProperties: () => requestGetPropertySave(),
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar)
