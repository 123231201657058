import { POST, FETCH } from '../../utils/api';

import {
    uploadImage,
    uploadImageSuccess,
    uploadImageFail
} from '../actions';

import {
    errorhandler
} from '.';

import { 
    apiEndpoints
} from '../../utils/api/endpoints';

export function requestUploadImage(params, target){    
    return dispatch => {
        dispatch(uploadImage(params, target));
        POST(apiEndpoints.UPLOAD_IMAGE, params)
        .then(response => {
            if(response.error) {
                throw(response.error);
            }
            dispatch(uploadImageSuccess(response, target));
            return response;
        })
        .catch(error => {
            dispatch(uploadImageFail(errorhandler(error), target));
        })
    }
}
