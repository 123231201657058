import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import styled from 'styled-components';

import PropertyType from '../Form/PropertyType';
import SliderContainer from '../Form/SliderContainerDesktop';
import CustomSwitch from '../Filter/CustomSwitch';
import HeadingContentSmall from '../Form/HeadingContentSmall';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils';

import _ from 'lodash';

import { PROPERTY_TYPE, PROPERTY_DETAILS_UPLOAD } from '../Filter/DefaultValues';
import SuburbSearchField from 'components/Common/Fields/SuburbSearchField';
import { STATES } from 'constants/constants';
import SelectField from '../Form/SelectField';
import { addressFormatter } from 'utils/formatter/addressFormatter';

const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
  },
  stepHint: {
    marginBottom: 25,
  },
});

const StyledText = withStyles(() => ({
  root: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
}))(Typography);

const FormWrap = styled.div`
  padding: 0 0 0;
`;

const CheckWrap = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 232px;
  margin: 0;
  justify-content: space-between;
  align-items: center;
`;
const GoogleWrap = styled.div`
  padding: 12px 0;

  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .css-yk16xz-control {
    border: 1px solid #ced4da !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    height: 56px;
    outline: none !important;
    transition: border-color 0.3s ease;
  }
  .css-g1d714-ValueContainer {
    height: 56px;
    outline: none !important;
  }
  .css-1wa3eu0-placeholder {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.35);
  }
  .css-1uccc91-singleValue {
    font-family: Roboto, sans-serif;
    font-size: 16px;
  }
  input,
  .css-b8ldur-Input,
  .css-b8ldur-Input div {
    font-family: Roboto, sans-serif !important;
    font-size: 16px !important;
  }

  .css-26l3qy-menu,
  .css-26l3qy-menu * {
    font-family: Roboto, sans-serif !important;
  }

  .css-1pahdxg-control {
    box-shadow: none !important;
    border-radius: 4px !important;
    border-color: #35c0ca !important;
  }
`;
const FormInput = ({ errorMessage, required, label, style, ...props }) => (
  <div style={{ marginTop: '18px', position: 'relative' }}>
    <Typography variant={'caption'} style={{ color: 'gray' }}>
      {required ? `${label} *` : label}
    </Typography>

    <TextField
      style={style ? { ...style, backgroundColor: '#FFF' } : { backgroundColor: '#FFF' }}
      {...props}
      label={null}
      fullWidth
      id="outlined-basic"
      variant="outlined"
    />
    {props.error && (
      <Box position="absolute" bottom={-22} left={0} fontSize="14px" color="red">
        {errorMessage}
      </Box>
    )}
  </div>
);

const AddressField = ({ addPropertyFields, handleFieldChange }) => {
  const [suburbFieldOpen, setSuburbFieldOpen] = useState(false);
  const [suburb, setSuburb] = useState();
  const [suburbInputValue, setSuburbInputValue] = useState();

  const suburbRef = useRef(addPropertyFields.propertyInfo.address.value.suburb);

  // Auto Populate State and postcode based on suburb
  useEffect(() => {
    if (suburb) {
      handleFieldChange('propertyInfo', null, 'address', {
        ...addPropertyFields.propertyInfo.address.value,
        suburb: suburb.locality,
        postcode: suburb.postcode,
        state: suburb.state,
      });
    }
  }, [suburb]);

  // Get full address
  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'address', {
      ...addPropertyFields.propertyInfo.address.value,
      full: addressFormatter(addPropertyFields.propertyInfo.propertyType.value, {
        subNumber: addPropertyFields?.propertyInfo?.address?.value?.subNumber?.trim(),
        lotNumber: addPropertyFields?.propertyInfo?.address?.value?.lotNumber?.trim(),
        streetNumber: addPropertyFields?.propertyInfo?.address?.value?.streetNumber?.trim(),
        streetName: addPropertyFields?.propertyInfo?.address?.value?.streetName?.trim(),
        suburb: addPropertyFields?.propertyInfo?.address?.value?.suburb,
        state: addPropertyFields?.propertyInfo?.address?.value?.state,
        postcode: addPropertyFields?.propertyInfo?.address?.value?.postcode,
      }),
    });
  }, [
    addPropertyFields.propertyInfo.address.value.subNumber,
    addPropertyFields.propertyInfo.address.value.lotNumber,
    addPropertyFields.propertyInfo.address.value.streetNumber,
    addPropertyFields.propertyInfo.address.value.streetName,
    addPropertyFields.propertyInfo.address.value.suburb,
    addPropertyFields.propertyInfo.address.value.state,
    addPropertyFields.propertyInfo.address.value.postcode,
  ]);

  useEffect(() => {
    if (addPropertyFields.propertyInfo.propertyType.value !== 'Land') {
      handleFieldChange('propertyInfo', null, 'address', {
        ...addPropertyFields.propertyInfo.address.value,
        lotNumber: '',
      });
    }
  }, [addPropertyFields.propertyInfo.propertyType.value]);

  return (
    <Box maxWidth="550px">
      <HeadingContentSmall title={'Input Property Address'} />
      <Box display="grid" gridAutoFlow="column" gridGap="30px">
        <FormInput
          fullWidth
          id="unitNumber"
          name="unitNumber"
          label="Unit No."
          variant="outlined"
          value={addPropertyFields.propertyInfo.address.value.subNumber ?? ''}
          onChange={(event) => {
            handleFieldChange('propertyInfo', null, 'address', {
              ...addPropertyFields.propertyInfo.address.value,
              subNumber: event.target.value,
            });
          }}
          // error={errors?.firstName?.status}
          // errorMessage={errors?.firstName?.message}
        />
        {addPropertyFields.propertyInfo.propertyType.value === 'Land' && (
          <FormInput
            required
            fullWidth
            id="lotNumber"
            name="lotNumber"
            label="Lot No."
            variant="outlined"
            value={addPropertyFields.propertyInfo.address.value.lotNumber ?? ''}
            onChange={(event) => {
              handleFieldChange('propertyInfo', null, 'address', {
                ...addPropertyFields.propertyInfo.address.value,
                lotNumber: event.target.value,
              });
            }}
            // error={errors?.firstName?.status}
            // errorMessage={errors?.firstName?.message}
          />
        )}
        <FormInput
          required
          fullWidth
          id="streetNumber"
          name="streetNumber"
          label="Street No."
          variant="outlined"
          value={addPropertyFields.propertyInfo.address.value.streetNumber ?? ''}
          onChange={(event) => {
            handleFieldChange('propertyInfo', null, 'address', {
              ...addPropertyFields.propertyInfo.address.value,
              streetNumber: event.target.value,
            });
          }}
          // error={errors?.firstName?.status}
          // errorMessage={errors?.firstName?.message}
        />
      </Box>
      <FormInput
        required
        fullWidth
        id="streetName"
        name="streetName"
        label="Street Name"
        variant="outlined"
        value={addPropertyFields.propertyInfo.address.value.streetName ?? ''}
        onChange={(event) => {
          handleFieldChange('propertyInfo', null, 'address', {
            ...addPropertyFields.propertyInfo.address.value,
            streetName: event.target.value,
          });
        }}
        // error={errors?.firstName?.status}
        // errorMessage={errors?.firstName?.message}
      />
      <Box marginTop="18px">
        <SuburbSearchField
          name="suburb"
          value={suburb}
          defaultValue={{
            locality: suburbRef.current ?? '',
          }}
          inputValue={suburbInputValue}
          setValueCallback={setSuburb}
          open={suburbFieldOpen}
          setOpen={setSuburbFieldOpen}
          placeholder={'Suburb *'}
          onInputChange={(e, value) => {
            if (value) {
              setSuburbInputValue(value);
            } else {
              setSuburbFieldOpen(false);
            }
          }}
        />
      </Box>
      <Box width="100%" display="flex">
        <Box width="200px">
          <FormInput
            required
            fullWidth
            id="postcode"
            name="postcode"
            label="Postcode"
            variant="outlined"
            value={addPropertyFields.propertyInfo.address.value.postcode ?? ''}
            onChange={(event) => {
              handleFieldChange('propertyInfo', null, 'address', {
                ...addPropertyFields.propertyInfo.address.value,
                postcode: event.target.value,
              });
            }}
            // error={errors?.firstName?.status}
            // errorMessage={errors?.firstName?.message}
          />
        </Box>
        <Box marginLeft="30px" width="150px" marginTop="18px">
          <Typography style={{ fontSize: '12px', color: 'gray' }}>State *</Typography>
          <Box marginTop="-10px">
            <SelectField
              options={STATES}
              placeholder={'State'}
              value={addPropertyFields.propertyInfo.address.value.state ?? ''}
              onChange={(e, value) => {
                handleFieldChange('propertyInfo', null, 'address', {
                  ...addPropertyFields.propertyInfo.address.value,
                  state: value,
                });
              }}
              onBlur={(e, value) => {
                handleFieldChange('propertyInfo', null, 'address', {
                  ...addPropertyFields.propertyInfo.address.value,
                  state: value,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const AddPropertyStep2 = (props) => {
  const { classes, addPropertyFields, handleFieldChange, setIsFormInvalid } = props;
  const [propertyType, setPropertyType] = useState(PROPERTY_TYPE);

  const [propertyDetails, setPropertyDetails] = useState(
    Object.keys(addPropertyFields.propertyInfo.propertyDetails).length > 0
      ? addPropertyFields.propertyInfo.propertyDetails.value
      : PROPERTY_DETAILS_UPLOAD,
  );
  const [propertyStatus, setPropertyStatus] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState(null);
  const [price, setPrice] = useState(50000);
  const [geoCodedDetails, setGeoCodedDetails] = useState(null);
  const [hidePrice, setHidePrice] = useState(false);

  useEffect(() => {
    if (
      addPropertyFields.propertyInfo.propertyStatus &&
      addPropertyFields.propertyInfo.propertyStatus.value === 'offmarket'
    ) {
      setPropertyStatus(true);
    } else {
      setPropertyStatus(false);
    }
    handleFieldChange('propertyInfo', null, 'propertyStatus', handlePropertyStatus());
    setType();

    // if (
    //   !addPropertyFields.propertyInfo.fullAddress ||
    //   !addPropertyFields.propertyInfo.propertyType.value

    // ) {
    //   setIsFormInvalid(true)
    // }
  }, []);

  useEffect(() => {
    if (
      !addPropertyFields.propertyInfo.address.value.full ||
      !addPropertyFields.propertyInfo.address.value.postcode ||
      !addPropertyFields.propertyInfo.address.value.state ||
      !addPropertyFields.propertyInfo.address.value.suburb ||
      !addPropertyFields.propertyInfo.address.value.streetName ||
      !addPropertyFields.propertyInfo.propertyType.value ||
      !propertyDetails.price ||
      propertyDetails?.price <= 0
    ) {
      setIsFormInvalid(true);
    } else {
      setIsFormInvalid(false);
    }
  }, [addPropertyFields, propertyDetails]);

  useEffect(() => {
    setType();
  }, [propertyType]);

  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'propertyStatus', handlePropertyStatus());
  }, [propertyStatus]);

  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'propertyDetails', propertyDetails);
  }, [propertyDetails]);

  // useEffect(() => {
  //   if (
  //     propertyAddress &&
  //     propertyAddress.value &&
  //     _.filter(propertyType, { active: true }).length > 0
  //   ) {
  //     setIsFormInvalid(false)
  //   } else {
  //     if (!addPropertyFields.propertyInfo.fullAddress) {
  //       setIsFormInvalid(true)
  //     }
  //   }

  //   if (propertyAddress && propertyAddress.value) {
  //     geocodeByPlaceId(propertyAddress.value.place_id)
  //       .then((results) => setGeoCodedDetails(results[0]))
  //       .catch((error) => setGeoCodedDetails(null))
  //   }
  // }, [propertyAddress, propertyType])

  // useEffect(() => {
  //   if (geoCodedDetails) {
  //     let details = {}
  //     details.postcode =
  //       _.find(geoCodedDetails.address_components, { types: ['postal_code'] })
  //         .short_name ?? ''
  //     details.state =
  //       _.find(geoCodedDetails.address_components, {
  //         types: ['administrative_area_level_1', 'political'],
  //       }).short_name ?? ''
  //     details.municipality = _.find(geoCodedDetails.address_components, {
  //       types: ['administrative_area_level_2', 'political'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, {
  //           types: ['administrative_area_level_2', 'political'],
  //         }).short_name
  //       : ''
  //     details.suburb = _.find(geoCodedDetails.address_components, {
  //       types: ['locality', 'political'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, {
  //           types: ['locality', 'political'],
  //         }).short_name
  //       : ''
  //     details.streetName = _.find(geoCodedDetails.address_components, {
  //       types: ['route'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, { types: ['route'] }).short_name
  //       : ''
  //     details.streetNumber = _.find(geoCodedDetails.address_components, {
  //       types: ['street_number'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, {
  //           types: ['street_number'],
  //         }).short_name
  //       : ''

  //     if (details) {
  //       var lotNumber = details.lotNumber ?? '',
  //         streetName = details.streetName,
  //         streetNumber = details.streetNumber,
  //         subNumber = details.subNumber ?? '',
  //         suburb = details.suburb,
  //         municipality = details.municipality,
  //         state = details.state,
  //         postcode = details.postcode

  //       const fullAddressText =
  //         '' +
  //         lotNumber +
  //         ' ' +
  //         streetNumber +
  //         ' ' +
  //         streetName +
  //         ' ' +
  //         subNumber +
  //         ' ' +
  //         suburb +
  //         ', ' +
  //         municipality +
  //         ' ' +
  //         state +
  //         ' ' +
  //         postcode
  //       details.full = fullAddressText.replace(/ {1,}/g, ' ').trim()
  //       handleFieldChange('propertyInfo', null, 'address', details)
  //     }
  //   }
  // }, [geoCodedDetails])

  const setType = () => {
    let ptype = null;
    propertyType.map((item) => {
      if (item.active) {
        ptype = item.title;
      }
    });
    handleFieldChange('propertyInfo', null, 'propertyType', ptype || '');

    // if (ptype || addPropertyFields.propertyInfo.propertyType.value) {
    //   setIsFormInvalid(false)
    // } else {
    //   setIsFormInvalid(true)
    // }
  };

  const handlePropertyType = (data) => {
    setPropertyType(data);
  };

  const handlePropertyDetails = (data) => {
    setPropertyDetails(data);
  };

  const handlePropertyStatus = () => {
    const status = propertyStatus ? 'offmarket' : 'comingsoon';
    return status;
  };

  const handleGooglePlaces = (data) => {
    /*     let newData = { ...data, label: data?.label?.replace(', Australia', '') }
    addPropertyFields.propertyInfo.fullAddress = newData
    setPropertyAddress(newData) */
    addPropertyFields.propertyInfo.fullAddress = data;
    setPropertyAddress(data);
  };

  return (
    <div className={classes.root}>
      <div className={classes.stepHint}>
        <Typography>Add your vendor's property to PropertyMate</Typography>
      </div>
      <FormWrap>
        <Grid container spacing={5}>
          <Grid item xs={12} lg={7}>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: '40px' }}>
                <AddressField addPropertyFields={addPropertyFields} handleFieldChange={handleFieldChange} />
                {/* <GoogleWrap>
                  <GooglePlacesAutocomplete
                    apiKey={GOOGLE_MAP_API_KEY}
                    selectProps={{
                      placeholder: 'Property Address',
                      value:
                        addPropertyFields.propertyInfo.fullAddress || propertyAddress,
                      onChange: handleGooglePlaces,
                    }}
                    autocompletionRequest={{
                      componentRestrictions: { country: 'au' },
                      fields: ['address_components', 'geometry'],
                      types: ['address'],
                    }}
                  />
                </GoogleWrap> */}
              </Grid>
              <Grid item xs={12}>
                <HeadingContentSmall title={'Select Property Type'} />
                <CheckWrap>
                  <CustomSwitch
                    label={'Coming soon'}
                    checked={propertyStatus}
                    onChange={(e) => {
                      setPropertyStatus(!propertyStatus);
                    }}
                  />
                  <StyledText>Off Market</StyledText>
                </CheckWrap>
                <PropertyType
                  handlePropertyType={handlePropertyType}
                  // isClearFilter={props.isClearFilter}
                  propertyType={propertyType}
                  activeProperty={addPropertyFields.propertyInfo.propertyType.value}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <SliderContainer
              handlePropertyDetails={handlePropertyDetails}
              propertyDetails={propertyDetails}
              hidePrice={hidePrice}
              setHidePrice={setHidePrice}
              price={price}
              showPriceOptions={true}
              addPropertyFields={addPropertyFields}
            />
          </Grid>
        </Grid>
      </FormWrap>
    </div>
  );
};

export default withStyles(styles)(AddPropertyStep2);
