//import PerfectProperty from '../../containers/Mobile/PerfectProperty'
// import PerfectProperty from '../../containers/Mobile/PerfectPropertyV2'
import PerfectProperty from '../../containers/Mobile/PerfectPropertyV3'
//import FiltersPerfect from '../../containers/Mobile/FiltersPerfect'
import FiltersPerfect from '../../containers/Mobile/FiltersPerfectV2'
import LikedProperties from '../../containers/Mobile/LikedProperties'
import LikedPropertiesMap from '../../containers/Mobile/LikedPropertiesMap'
import Property from '../../containers/Mobile/Property'
import PropertyMapView from '../../containers/Mobile/PropertyMapView'
import PropertyStreetView from '../../containers/Mobile/PropertyStreetView'
import DislikedProperties from '../../containers/Mobile/DislikedProperties'

let routes = [
  {
    path: '/perfect-property',
    component: PerfectProperty,
  },
  {
    path: '/perfect-property/filters',
    component: FiltersPerfect,
  },
  {
    path: '/liked-properties',
    component: LikedProperties,
  },
  {
    path: '/liked-properties/map-view',
    component: LikedPropertiesMap,
  },
  {
    path: '/liked-properties/:id',
    component: Property,
  },
  {
    path: '/liked-properties/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/liked-properties/:id/location/street',
    component: PropertyStreetView,
  },
  {
    path: '/disliked-properties',
    component: DislikedProperties,
  },
  {
    path: '/disliked-properties/:id',
    component: Property,
  },
  {
    path: '/perfect-property/:id',
    component: Property,
  },
  {
    path: '/perfect-property/:id/location/map',
    component: PropertyMapView,
  },
  {
    path: '/perfect-property/:id/location/street',
    component: PropertyStreetView,
  },
]

export default routes
