import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import DynamicIcons from "../DynamicIcons";
import scriptjs from 'scriptjs';

import { withStyles, Button, Typography } from '@material-ui/core';

const buttonStyle = {
  size:'large',
  borderColor:'#000',
  textColor:'#000',
  colorTheme:'primary'
}

const StyledButton = withStyles({
  root: {
    borderColor:buttonStyle.borderColor, 
    display:'flex', 
    padding:'11px 12px', 
    width:'100%',
    borderRadius:30,
    position:'relative',
    textTransform:'initial',
    fontWeight:400

  },
  startIcon: {
    position:'absolute',
    left:30
  }
})(Button);

const ButtonWrap = styled.div`
  padding:8px 0;
`

class AppleAuth extends Component {

  componentDidMount() {
    /** initialize apple */
    this.loadAppleAuth();
  }
  
  loadAppleAuth = () =>{
    scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', () => {
      const params = {
        clientId: 'com.au.propertymate',
        redirectURI: window.location.origin + '/apple/callback',
        scope: 'name email',
        usePopup: true
      };
      console.log('apple params => ', params)
      if(window.AppleId) {
        window.AppleID.auth.init(params);
      }
    });
  }

  async requestToApple(){
    try {
      const data = await window.AppleID.auth.signIn();
      if(data.authorization){
        this.props.callback({
          _profile: null,
          _provider: 'apple',
          _token: data.authorization.id_token
        })
      }
    } catch ( error ) {
      // console.log('apple =>', error)
    }
  }
  
  render () {
    const { label, icon } = this.props

    return (
      <ButtonWrap>
        <StyledButton 
          variant={'outlined'} 
          size={buttonStyle.size} 
          startIcon={icon} 
          onClick={()=> this.requestToApple()}>
          <Typography color={buttonStyle.colorTheme} style={{color:buttonStyle.textColor, fontSize:'12px'}}>{label}</Typography>
        </StyledButton>
      </ButtonWrap>
    )
  }
}

export default AppleAuth;