import AgencySignUpStatus from 'components/Common/Agency/AgencySignUpStatus'
import Agency from '../../containers/Mobile/Agency'
import AgencySignUp from '../../containers/Mobile/Agency/AgencySignUp'
import AgencyDetails from '../../containers/Mobile/AgencyDetails'

let routes = [
  {
    path: '/agency',
    component: Agency,
  },
  {
    path: '/agency/sign-up',
    component: AgencySignUp,
  },
  {
    path: '/agency/results/:id',
    component: AgencyDetails,
  },
  {
    path: '/agency/registration/status',
    component: AgencySignUpStatus,
  },
]

export default routes
