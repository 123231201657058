import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Button,
  FormControl,
  IconButton,
  InputProps,
  InputLabel,
  InputAdornment,
  Input,
  TextField,
  OutlinedInput,
  withStyles,
  Typography,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortUnFoldMore from 'assets/icons/SortUnFoldMore';
import clsx from 'clsx';
import { toDateFormat } from 'utils/formatter/dateFormatter';
import {
  SuperFilter,
  SuperStatusAgencyTables,
} from 'components/Web/Superadmin';
import { useRouterChange } from 'utils/router.utils';
import { requestSendLikesDislikesProperties } from 'store/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { handleManageAgencyPageCount } from 'store/actions';
import { useHistory } from 'react-router-dom';
import { StorageActions } from 'store/actions/storage.actions';
import { getSuperadminManageAgencyPageCount } from 'store/selectors/storage.selectors';
import useHandleManageAgencyRowCount from 'utils/customHooks/useHandleManageAgencyRowCount';
import useHandleManageAgencyPageCount from 'utils/customHooks/useHandleManageAgencyPageCount';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    marginTop: 20,
  },
  section: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '29px',
    marginBottom: '20px',
  },
  buttonGroup: {
    marginBottom: '20px',
  },
  button: {
    height: '46px',
    width: '120px',
    padding: '16px 40px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '8px',
    '&:hover': {},
  },
  buttonSearch: {
    height: '54px',
    width: '100px',
    marginLeft: '10px',
    padding: '16px 40px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '8px',
  },
  table: {
    height: '70vh',
    marginBottom: '30px',
  },
  payments: {
    display: 'flex',
    // rowGap: '10px',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'space-between',
  },
  chipContainer: {
    minWidth: '3.750em',
    height: '1.300em',
    paddingRight: '0.425em',
    paddingLeft: '0.425em',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    border: '0.5px solid #C0C0C0',
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    marginBottom: '0.425em',
    marginRight: '0.125em',
  },
  chipText: {
    fontSize: 10,
    color: '#C0C0C0',
  },
  availability: {
    width: 150,
  },
  searchContainer: {
    marginBottom: '20px',
  },
  search: {
    margin: theme,
  },
  searchTextField: {
    minWidth: 315,
  },
  iconButton: {
    color: '#6A6A6A',
  },
});

const TABLE_HEADER = [
  {
    key: 'registeredTradeName',
    label: 'Agency Name',
    sort: true,
  },
  {
    key: 'address',
    label: 'Agency Address',
    sort: true,
  },
  {
    key: 'subscription.billingDate',
    label: 'Billing Date',
    sort: true,
  },
  {
    key: 'Subscription',
    label: 'Subscription Status ',
    sort: false,
  },
  /*{
    key: 'Docusign',
    label: 'Docusign Status',
    sort: false,
  },*/
  {
    key: 'Payment',
    label: 'Payment Status',
    sort: false,
  },
  {
    key: 'action',
    label: 'Action',
    sort: false,
  },
];

const TABLE_HEADER_FREE = [
  {
    key: 'registeredTradeName',
    label: 'Agency Name',
    sort: true,
  },
  {
    key: 'adminEmail',
    label: 'Admin Email',
    sort: true,
  },
  {
    key: 'active',
    label: 'Account Status',
    sort: false,
  },
  {
    key: 'action',
    label: 'Action',
    sort: false,
  },
];

const ManageAgenciesTable = (props) => {
  const [rowCount, setRowCount] = useHandleManageAgencyRowCount();
  const [pageCount, setPageCount] = useHandleManageAgencyPageCount();

  const {
    classes,
    tableData,
    tableDataPageCount,
    tableDataPageRow,
    pagination,
    section,
    clearFilter,
    applyFilter,
    statuses,
    sort,
    handleChangeStatus,
    agencyNameFilter,
    handleChangeNameFilter,
    handleChangeNameFilterPress,
    handleChangeNameFilterSearch,
    handleSortRequest,
    manageButtons,
    changeManageTable,
    clearChangeNameFilter,
  } = props;
  const [routerChange] = useRouterChange();
  const [currentPage, setCurrentPage] = useState(pageCount);
  const [rowsPerPage, setRowsPerPage] = useState(rowCount);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (tableData?.length ?? 0) - currentPage * rowsPerPage);

  //subscription states
  const subscriptionStatuses = [
    'INITIALIZE',
    'FINALIZE',
    'ARCHIVED',
    'LOCKED',
    'TERMINATED',
    'PENDING',
    'APPROVED',
    'ACTIVATED',
    'SUBSCRIPTION_PENDING',
    'SUBSCRIPTION_CANCELLED',
    'SUBSCRIPTION_TRIAL_ACTIVE',
    'SUBSCRIPTION_ACTIVE',
  ];
  //docusign states
  const docusignStatuses = [
    'CONTRACT_PENDING',
    'CONTRACT_SENT',
    'CONTRACT_SIGNED',
    'CONTRACT_RESEND',
    'CONTRACT_REJECTED',
    'CONTRACT_STALE',
  ];
  //payment states
  const paymentStatuses = [
    'SUBSCRIPTION_PAYMENT_SET',
    'SUBSCRIPTION_PAYMENT_PENDING',
    'SUBSCRIPTION_PAYMENT_FAILED',
    'SUBSCRIPTION_PAYMENT_CHARGED',
    'SUBSCRIPTION_DISCOUNT_APPLIED',
    'DISCOUNT_APPLIED',
  ];
  //invoice states
  const invoiceStatuses = [
    'INVOICE_ISSUED',
    'INVOICE_DUE',
    'INVOICE_REMINDER_SENT',
    'INVOICE_PAID',
  ];

  const handleChangePage = (event, newPage) => {
    // handlePageUpdate(newPage + 1);
    setPageCount(newPage);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    setPageCount(tableDataPageCount);
    setCurrentPage(tableDataPageCount);
    setRowsPerPage(tableDataPageRow);
  }, [manageButtons, tableDataPageCount, tableDataPageRow]);

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value == '-1') {
      setRowsPerPage(parseInt(pagination?.totalItemCount, 10));
      setRowCount(parseInt(pagination?.totalItemCount, 10));
      setPageCount(0);
      setCurrentPage(0);
    } else {
      setRowsPerPage(parseInt(event.target.value, 10));
      setRowCount(parseInt(event.target.value, 10));
      setPageCount(0);
      setCurrentPage(0);
    }
  };

  const handleClick = (event, data) => {
    routerChange('/superadmin/manage-agencies/' + data._id);
  };

  const getSortIcon = (key, sorting) => {
    let findData = sort.find((x) => x.key === key);
    if (findData && Object.keys(findData).length > 0 && sorting === true) {
      return findData?.sortOrder === null ? (
        <SortUnFoldMore />
      ) : findData?.sortOrder === 'asc' ? (
        <ArrowDropUpIcon />
      ) : (
        <ArrowDropDownIcon />
      );
    } else {
      return sorting === true ? <SortUnFoldMore /> : null;
    }
  };

  const checkSubscriptionStatus = (data) => {
    if (data?.subscription?.subscriptionStatus == false) {
      return 'suspended';
    } else {
      let pending_match = data?.agencyStateList?.filter(function (item) {
        return item === 'PENDING';
      });
      if (
        subscriptionStatuses.some((i) => data?.agencyStateList?.includes(i))
      ) {
        const checker = (value) =>
          subscriptionStatuses.some((element) => value?.includes(element));
        let subscriptionData = [];
        subscriptionData = data?.agencyStateList.filter(checker);
        if (pending_match) {
          subscriptionData.push('PENDING');
        }
        const domainPrecedence = [
          'SUBSCRIPTION_CANCELED',
          'SUBSCRIPTION_ACTIVE',
          'SUBSCRIPTION_TRIAL_ACTIVE',
          'SUBSCRIPTION_PENDING',
          'PENDING',
        ];
        const sorted = subscriptionData.sort((a, b) => {
          let index1 = domainPrecedence.indexOf(a);
          let index2 = domainPrecedence.indexOf(b);
          return index1 == -1 ? 1 : index2 == -1 ? -1 : index1 - index2;
        });
        let subscriptionValue = '';

        if (sorted[0] === 'SUBSCRIPTION_PENDING') {
          subscriptionValue = 'pending_activation';
        } else if (sorted[0] === 'PENDING') {
          subscriptionValue = 'pending_approval';
        } else {
          subscriptionValue = sorted[0]
            ?.replace('SUBSCRIPTION_', '')
            ?.toLowerCase();
        }

        return subscriptionValue;
      }
    }
  };

  const checkDocusignStatus = (data) => {
    if (!filterParentAgency(data)) {
      if (docusignStatuses.some((i) => data?.agencyStateList?.includes(i))) {
        const checker = (value) =>
          docusignStatuses.some((element) => value?.includes(element));
        let docusignData = [];
        docusignData = data?.agencyStateList.filter(checker);

        if (
          data?.docusignDocumentLink &&
          `${data.docusignDocumentLink}`.includes('https') === true
        ) {
          if (
            data.agencyStateList.includes('CONTRACT_RESEND') ||
            data.agencyStateList.includes('CONTRACT_REJECTED') ||
            data.agencyStateList.includes('CONTRACT_STALE')
          ) {
            return 'resend_docusign';
          } else if (
            docusignData.includes('CONTRACT_PENDING') &&
            docusignData.includes('CONTRACT_PENDING') &&
            data.docusignDocumentLink
          ) {
            return 'docusign_resent';
          } else if (
            docusignData.includes('CONTRACT_PENDING') &&
            docusignData.includes('CONTRACT_SENT')
          ) {
            return 'docusign_sent';
          } else if (
            docusignData.includes('CONTRACT_SENT') &&
            docusignData.includes('CONTRACT_SIGNED')
          ) {
            return 'docusign_signed';
          } else {
            return docusignData[0]
              ?.replace('CONTRACT_', 'DOCUSIGN_')
              ?.toLowerCase();
          }
        } else {
          return 'docusign_pending';
        }
      } else {
        return 'docusign_pending';
      }
    }
  };

  const checkSubscriptionPaymentStatus = (data) => {
    if (!filterParentAgency(data)) {
      if (data?.subscription) {
        if (
          data?.subscription?.subscriptionStateList?.includes(
            'DISCOUNT_APPLIED'
          ) ||
          data?.subscription?.discount
        ) {
          let paymentValue = 'Promo Code Applied';
          return paymentValue;
        } else if (
          data?.subscription?.subscriptionStateList?.includes('PAYMENT_SET')
        ) {
          let paymentValue = 'Credit Card Added';
          return paymentValue;
        } else if (
          !data?.subscription?.subscriptionStateList?.includes('PAYMENT_SET')
        ) {
          let paymentValue = 'Credit Card Pending';
          return paymentValue;
        }
      } else if (
        paymentStatuses.some((i) => data?.agencyStateList?.includes(i))
      ) {
        const checker = (value) =>
          paymentStatuses.some((element) => value.includes(element));
        let paymentData = [];
        paymentData = data?.agencyStateList.filter(checker);

        let paymentValue = '';
        if (paymentData[0] === 'SUBSCRIPTION_PAYMENT_PENDING') {
          paymentValue = 'Subscription charge Pending';
        } else if (paymentData[0] === 'SUBSCRIPTION_PAYMENT_CHARGED') {
          paymentValue = 'Subscription Charged';
        } else if (paymentData[0] === 'SUBSCRIPTION_PAYMENT_FAILED') {
          paymentValue = 'Subscription payment failed';
        } else {
        }

        return paymentValue;
      }
    }
  };

  const checkActivation = (data) => {
    if (!data?.subscription) {
      return 'not_active';
    } else {
      if (data?.subscription?.subscriptionStatus == false) {
        return 'suspended';
      } else {
        let pending_match = data?.agencyStateList?.filter(function (item) {
          return item === 'PENDING';
        });
        if (data?.agencyStateList?.includes('ACTIVATED')) {
          return 'active';
        } else {
          return 'not_active';
        }
      }
    }
  };

  // const checkInvoicePaymentStatus = (data) => {
  //   let invoiceValue = '';
  //   if (invoiceStatuses.some((i) => data?.agencyStateList?.includes(i))) {
  //     const checker = (value) => invoiceStatuses.some((element) => value.includes(element));

  //     let invoiceData = [];
  //     invoiceData = data?.agencyStateList.filter(checker);

  //     const domainPrecedence = ['INVOICE_PAID', 'INVOICE_REMINDER_SENT', 'INVOICE_DUE', 'INVOICE_ISSUED'];

  //     const sorted = invoiceData.sort((a, b) => {
  //       let index1 = domainPrecedence.indexOf(a);
  //       let index2 = domainPrecedence.indexOf(b);
  //       return index1 == -1 ? 1 : index2 == -1 ? -1 : index1 - index2;
  //     });

  //     invoiceValue = sorted[0]?.replace('INVOICE_', '')?.toLowerCase();
  //   }
  //   return invoiceValue;
  // };

  const checkBillingDate = (data) => {
    const isLinkSaved =
      data.docusignDocumentLink && `${data.docusignDocumentLink}`.length > 0;
    const isPreviousSigned =
      data.documentSigned === true ||
      data.agencyStateList.includes('CONTRACT_SIGNED') === true;
    const isSigned = isLinkSaved || isPreviousSigned;

    let billingDate;
    if (data?.subscription?.billingDate) {
      billingDate = moment.utc(data?.subscription?.billingDate);
    } else {
      billingDate = moment.utc(data?.createdAt).add(90, 'days');
    }

    const numDays = 7;
    const now = moment.utc(new Date());
    // Billing date has passed
    const isAfter = now.isAfter(billingDate, 'days');
    const isStarted = isAfter && now.diff(billingDate, 'days') <= numDays;
    // Nearing billing date
    const isBefore = now.isBefore(billingDate, 'days');
    const isRenewing = isBefore && billingDate.diff(now, 'days') <= numDays;

    let config = {
      date: `${billingDate.format('DD/MM/yyyy')}`,
    };

    if (isSigned && isStarted && !isRenewing) {
      config = { ...config, color: 'green', isCritical: false };
    } else if (isSigned && !isStarted && isRenewing) {
      config = { ...config, color: 'red', isCritical: false };
    } else if (!isSigned && isStarted && !isRenewing) {
      config = { ...config, color: 'red', isCritical: true };
    } else if (!isSigned && !isStarted && isRenewing) {
      config = { ...config, color: 'red', isCritical: false };
    } else {
      config = { ...config, color: 'black', isCritical: false };
    }

    return config;
  };

  const checkStartDate = (data) => {
    const isLinkSaved =
      data.docusignDocumentLink && `${data.docusignDocumentLink}`.length > 0;
    const isPreviousSigned =
      data.documentSigned === true ||
      data.agencyStateList.includes('CONTRACT_SIGNED') === true;
    const isSigned = isLinkSaved || isPreviousSigned;

    let startDate;
    if (data.subscription?.startDate) {
      startDate = moment(data.subscription.startDate);
    } else {
      startDate = moment(data.createdAt).add(90, 'days');
    }

    const numDays = 7;
    const now = moment(new Date());
    // Billing date has passed
    const isAfter = now.isAfter(startDate, 'days');
    const isStarted = isAfter && now.diff(startDate, 'days') <= numDays;
    // Nearing billing date
    const isBefore = now.isBefore(startDate, 'days');
    const isRenewing = isBefore && startDate.diff(now, 'days') <= numDays;

    let config = {
      date: `${startDate.format('DD/MM/yyyy')}`,
    };

    if (isSigned && isStarted && !isRenewing) {
      config = { ...config, color: 'black', isCritical: false };
    } else if (isSigned && !isStarted && isRenewing) {
      config = { ...config, color: 'black', isCritical: false };
    } else if (!isSigned && isStarted && !isRenewing) {
      config = { ...config, color: 'black', isCritical: false };
    } else if (!isSigned && !isStarted && isRenewing) {
      config = { ...config, color: 'black', isCritical: false };
    } else {
      config = { ...config, color: 'black', isCritical: false };
    }

    return config;
  };

  const isHeadOffice = (data) => {
    const isParent =
      data.headAgencyReference === undefined ||
      data.headAgencyReference === null;
    return isParent;
  };

  const filterParentAgency = (data) => {
    const headAgencyId = data.headAgencyReference;

    const filter = (item) => item._id == headAgencyId;
    const filtered = tableData.filter(filter);
    if (filtered.length > 0) {
      return filtered[0].registeredTradeName || filtered[0].companyName;
    } else {
      return null;
    }
  };

  const getAgencyName = (data) => {
    const primary = data.registeredTradeName || data.companyName;
    return {
      name: primary,
      headAgency: filterParentAgency(data),
    };
  };

  const renderAgencyName = (data) => {
    const { name, headAgency } = data;
    return (
      <div>
        {name ? <body>{name}</body> : null}
        {headAgency ? (
          <div className={classes.chipContainer}>
            <p className={classes.chipText}>{`Suboffice of ${headAgency}`}</p>
          </div>
        ) : null}
      </div>
    );
  };

  const _renderRows = () => {
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? tableData // tableData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
          : tableData
        ).map((value, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align='left'>
              {renderAgencyName(getAgencyName(value))}
            </TableCell>
            <TableCell align='left'>
              {value.address ? value.address.toUpperCase() : '-'}
            </TableCell>
            <TableCell
              align='left'
              style={{
                color: checkBillingDate(value).color,
                fontWeight: checkBillingDate(value).isCritical ? 800 : 400,
              }}
            >
              {checkBillingDate(value).date}
            </TableCell>
            <TableCell align='left'>
              <SuperStatusAgencyTables type={checkSubscriptionStatus(value)} />
            </TableCell>
            {/*<TableCell align='left'>
              <SuperStatusAgencyTables type={checkDocusignStatus(value)} />
            </TableCell>*/}

            <TableCell align='left'>
              <div className={classes.payments}>
                <SuperStatusAgencyTables
                  type={checkSubscriptionPaymentStatus(value)}
                />
                {/* <SuperStatusAgencyTables type={checkInvoicePaymentStatus(value)} /> */}
              </div>
            </TableCell>
            <TableCell>
              <IconButton
                onClick={(e) => {
                  handleClick(e, value);
                }}
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const _renderRowsFree = () => {
    return (
      <TableBody>
        {(rowsPerPage > 0
          ? tableData // tableData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
          : tableData
        ).map((value, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align='left'>
              {renderAgencyName(getAgencyName(value))}
            </TableCell>
            <TableCell align='left'>
              {value.adminEmail && !value.adminEmail.includes('johndoe')
                ? value.adminEmail
                : '-'}
            </TableCell>
            <TableCell align='left'>
              <SuperStatusAgencyTables type={checkActivation(value)} />
            </TableCell>
            <TableCell>
              <IconButton
                onClick={(e) => {
                  handleClick(e, value);
                }}
              >
                <Edit />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    );
  };

  const _renderEmpty = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell align={'center'} colSpan={TABLE_HEADER.length + 1}>
            No Data to View
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  const _renderEmptyFree = () => {
    return (
      <TableBody>
        <TableRow>
          <TableCell align={'center'} colSpan={TABLE_HEADER_FREE.length + 1}>
            No Data to View
          </TableCell>
        </TableRow>
      </TableBody>
    );
  };

  return (
    <div className={classes.root}>
      {section && (
        <div>
          <Typography className={classes.section}>{section}</Typography>
        </div>
      )}
      <Grid container className={classes.buttonGroup} spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent='left' spacing={2}>
            {manageButtons.map(({ id, label, active }) => (
              <Grid key={id} item>
                <Button
                  className={classes.button}
                  color={active ? 'primary' : 'grey'}
                  variant='contained'
                  onClick={(e) => changeManageTable(e, id)}
                  disableElevation
                  disabled={active ? true : false}
                  style={{
                    backgroundColor: active ? '#35C0CA' : '#e0e0e0',
                    color: active ? '#ffffff' : '#9B9B9B',
                  }}
                >
                  {label}
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <SuperFilter
        data={tableData}
        clearFilter={clearFilter}
        applyFilter={applyFilter}
        status={statuses}
        paid={manageButtons[0].active ? true : false}
        handleChange={handleChangeStatus}
      />
      <Grid container className={classes.searchContainer}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            <FormControl className={classes.search} variant='outlined'>
              <InputLabel htmlFor='input-with-icon-adornment'>
                Search agency name
              </InputLabel>
              <OutlinedInput
                className={classes.searchTextField}
                id='input-with-icon-adornment'
                value={agencyNameFilter}
                onChange={handleChangeNameFilter}
                onKeyDown={handleChangeNameFilterPress}
                endAdornment={
                  <InputAdornment position='end'>
                    {agencyNameFilter.length > 2 && (
                      <IconButton
                        onClick={clearChangeNameFilter}
                        className={classes.iconButton}
                        aria-label='directions'
                      >
                        <ClearIcon color='primary' />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
                labelWidth={150}
              />
            </FormControl>
            <Button
              className={classes.buttonSearch}
              color={'primary'}
              variant='contained'
              onClick={handleChangeNameFilterSearch}
              disableElevation
              style={{
                color: '#ffffff',
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>
        {tableData && tableData.length > 0 && (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TablePagination
              rowsPerPageOptions={props.rowsPerPageOptions ?? [10, 20, 50, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              component='div'
              count={
                pagination && pagination.totalItemCount
                  ? pagination.totalItemCount
                  : 0
              }
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              labelDisplayedRows={(paginationData) => {
                let totalPages = Math.floor(paginationData.count / rowsPerPage);

                if (paginationData.count % rowsPerPage > 0) {
                  totalPages += 1;
                }

                return `Page ${paginationData.page + 1} of ${totalPages}`;
              }}
            />
          </Grid>
        )}
      </Grid>
      <TableContainer className={classes.table} component={Paper}>
        <Table stickyHeader size='medium'>
          <TableHead>
            <TableRow>
              {manageButtons[0].active &&
                TABLE_HEADER.map((value, index) => {
                  let findData = sort.find((x) => x.key === value.key);
                  return (
                    <TableCell key={index}>
                      <TableSortLabel
                        active={value.sort === true}
                        direction={
                          sort.includes(value.key)
                            ? sort.find((x) => x.key === value.key).sortOrder
                            : 'asc'
                        }
                        onClick={() => handleSortRequest(value.key)}
                        IconComponent={() => getSortIcon(value.key, value.sort)}
                        style={{
                          pointerEvents:
                            findData && findData?.key !== 'undefined'
                              ? 'auto'
                              : 'none',
                        }}
                      >
                        {value.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              {manageButtons[1].active &&
                TABLE_HEADER_FREE.map((value, index) => {
                  let findData = sort.find((x) => x.key === value.key);
                  return (
                    <TableCell key={index}>
                      <TableSortLabel
                        active={value.sort === true}
                        direction={
                          sort.includes(value.key)
                            ? sort.find((x) => x.key === value.key).sortOrder
                            : 'asc'
                        }
                        onClick={() => handleSortRequest(value.key)}
                        IconComponent={() => getSortIcon(value.key, value.sort)}
                        style={{
                          pointerEvents:
                            findData && findData?.key !== 'undefined'
                              ? 'auto'
                              : 'none',
                        }}
                      >
                        {value.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          {tableData &&
            tableData.length > 0 &&
            manageButtons[0].active &&
            _renderRows()}
          {tableData &&
            tableData.length > 0 &&
            manageButtons[1].active &&
            _renderRowsFree()}
          {tableData &&
            tableData.length === 0 &&
            manageButtons[0].active &&
            _renderEmpty()}
          {tableData &&
            tableData.length === 0 &&
            manageButtons[1].active &&
            _renderEmptyFree()}
        </Table>
      </TableContainer>
    </div>
  );
};

ManageAgenciesTable.propTypes = {
  classes: PropTypes.object,
  tableData: PropTypes.array,
  pagination: PropTypes.object,
  section: PropTypes.string,
};

export default withStyles(styles)(ManageAgenciesTable);
