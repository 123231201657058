import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function HousePin(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
     <path fillRule="evenodd" clipRule="evenodd" d="M21.8 10.9355C21.8 15.7301 18.416 19.7314 13.9142 20.6652L11.8994 23L9.88434 20.6649C5.38328 19.7305 2 15.7295 2 10.9355C2 5.44827 6.43238 1 11.9 1C17.3676 1 21.8 5.44827 21.8 10.9355ZM14.1 16.4H17.4L17.4264 10.3482C17.6752 10.3482 18.0493 10.2417 18.2252 10.0521C18.4012 9.8625 18.5 9.60535 18.5 9.33722L16.3 7.05V3.75H14.1V5.4L11.9066 3.2L5.3 9.2945C5.3 9.56263 5.39884 9.81979 5.57477 10.0094C5.75071 10.199 6.15119 10.3055 6.4 10.3055V16.4H9.7V12H14.1V16.4Z" fill="inherit"/>
    </SvgIcon>
  );
}

export function PayPalIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HousePin />
      <HousePin color="primary" />
      <HousePin color="secondary" />
      <HousePin color="action" />
      <HousePin color="disabled" />
      <HousePin style={{ color: green[500] }} />
    </div>
  );
}
