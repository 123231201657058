import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Typography, Avatar, Badge, Grid, withStyles, Button, IconButton } from "@material-ui/core";
import Image from 'material-ui-image';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import FormField from '../../components/Mobile/Form/FormField';

import DynamicIcons from "../../components/Mobile/DynamicIcons";

import AgentReview from "../../components/Web/Agent/AgentReview"

/** actions */
import {
  setPropertySuburbSearch,
  clearSuburbSearch,
} from '../../store/actions';

/** api redux request */
import {
  requestPropertySearch,
  requestAgentDetails,
  requestAgentSetRating
} from "../../store/api";
import { useRouterChange } from "utils/router.utils";

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px'
  },
})(Typography);

const BackButton = withStyles({
  root: {
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 90,
    minHeight: 'calc(100vh - 231px)'
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative'
  },
  headerImageContainer: {
    position: 'relative',
    width: '100%',
    height: 352,
    overflow: 'hidden'
  },
  overlayColor: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,0.5)', // 'rgba(255,255,255,0.5)'
    width: '100%',
    height: '100%'
  },
  media: {
    width: '100%',
    objectFit: 'cover',
    maxHeight: 352 + 'px !important',
  },
  agentLogo: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    height: '3.857em',
    width: 'auto',
    imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    // imageRendering: 'pixelated',
    imageRendering: '-webkit-optimize-contrast'
  },
  profileImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: '#FFF',
  },
  avatarImage: {
    width: '7.5em',
    height: '7.5em',
    // borderRadius: '2.813em',
    border: '1px solid #FFF',
    objectFit: 'cover',
    margin: theme.spacing(3, 0)
  },
  optionButton: {

    textTransform: 'none',
    width: 100,
    height: 35,
    marginRight: theme.spacing(2),
    borderRadius: '1.250em',
    color: '#000',
    fontWeight: 'normal',
    float: 'right'
  },

  submitButton: {
    margin: '0 auto',
    borderRadius: '1.250em',
    textTransform: 'none',
    width: 124,
    display: 'inline-flex',
  },

  submitWrap: {
    display: 'flex',
    justifyContent: 'center'
  },

  content: {
    margin: theme.spacing(2)
  },

  agentName: {
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 400,
    marginBottom: theme.spacing(2)
  },

  ratingsWrap: {
    display: "flex",
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  },
  heading: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: 24,
    fontWeight: 400,
  },
  agentMetadata: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  contentText: {
    marginBottom: theme.spacing(3)
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: theme.spacing(1)
    }
  },
  infoSection: {
    //backgroundColor: "#F5F5F5"
  },
  infoWrap: {
    margin: '0 auto',
    backgroundColor: "#F5F5F5",
    paddingLeft: 114
  },
  detailsGrid: {
    background: '#ffffff',
    paddingRight: 114 + 'px !important',
    paddingLeft: theme.spacing(3) + 'px !important',
    paddingBottom: theme.spacing(4) + 'px !important',
    paddingTop: theme.spacing(4) + 'px !important',

  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const RatingBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#6FCF97",
    color: '#ffffff',
    top: theme.spacing(-1),
    right: theme.spacing(-1),
  },
}))(Badge);

const RatingIconButton = withStyles((theme) => ({
  root: {
    boxShadow: "inset 0 0 0 0.25px #000000",
    marginRight: theme.spacing(1.5)
  },
}))(IconButton);

const AGENT_DATA = {
  firstName: 'Ryan',
  lastName: 'Gosling',
  rating: '+99',
  positiveRatingPercentage: 99,
  agencyName: 'Pagan Real Estate - Travancore',
  phone: '0765689432',
  email: 'ryan.king@pagalrealestate.com',
  website: 'http://paganrealestate.com.au',
  description: 'Cras et tortor ornare, mattis justo at, rutrum neque. Integer at lectus non nulla hendrerit pretium non nec ipsum. Proin lacinia ut diam vel cursus. Morbi tristique magna eget quam lacinia mollis. In faucibus vulputate libero et blandit. Donec mattis est felis, eu molestie eros consequat quis. In hac habitasse platea dictumst. Phasellus sodales venenatis congue.'
}

const AgentDetails = props => {

  const {
    classes,
    currentAgent,
    getAgentDetails,
    user,
    authentication
  } = props;
  const [routerChange] = useRouterChange()
  const [isReviewing, setIsReviewing] = React.useState(false);
  const [selectedAgent, setSelectedAgent] = React.useState(null)

  /** 
   * Save Current Agent API */
  async function apiRequestSetCurrentAgent() {
    let params = props.match.params.id;
    if (params) {
      getAgentDetails(params);
    }
  }

  React.useEffect(() => {
    apiRequestSetCurrentAgent();
  }, [])

  React.useEffect(() => {
    if (currentAgent && currentAgent.data && currentAgent.data.entity) {
      setSelectedAgent(currentAgent.data.entity)
    }
  }, [currentAgent])

  function routeBack(path) {
    routerChange('back')
  }

  function calculatePercentage(value, total) {
    return (value / total) * 100;
  }

  return (
    <div className={classes.root}>
      {selectedAgent &&
        <>
          <div className={classes.headerImageContainer}>
            <div className={classes.overlayColor}></div>
            <Image
              className={classes.media}
              src={'https://hgtvhome.sndimg.com/content/dam/images/hgtv/fullset/2019/8/1/1/uo2019_living-room-01-wide-blinds-up-KB2A8968_h.jpg.rend.hgtvcom.966.644.suffix/1564684055231.jpeg'}
              onClick={() => ''}
              aspectRatio={(2.35 / 1)}
              disableSpinner

            />
            <img
              className={classes.agentLogo}
              src={'https://placeholder.com/wp-content/uploads/2018/10/placeholder.com-logo1.png'} />

          </div>
          <div className={classes.infoSection}>
            <Grid container spacing={2} className={classes.infoWrap}>
              <Grid item xs={12} lg={3} className={classes.infoGrid}>
                <div className={classes.agentMetadata}>
                  <Avatar
                    className={classes.avatarImage}
                    src={'https://i.pinimg.com/736x/d6/a9/57/d6a957f1d8045c9c973c12bf5968326f.jpg'}
                  />
                  <div className={classes.flexWrap}>
                    <Typography className={classes.agentName} variant="body2" component="h3">
                      {selectedAgent.firstName + ' ' + selectedAgent.lastName}
                    </Typography>
                    <div>
                      {authentication && !authentication.user_status &&
                        <Button
                          className={classes.optionButton}
                          size={'small'}
                          color={'primary'}
                          variant={'contained'}
                          onClick={() => setIsReviewing(!isReviewing)}>
                          {'Rate Agent'}
                        </Button>
                      }
                      {authentication && authentication.user_status === 'logged' && user &&
                        <>
                          {user && user.agentsRated && !user.agentsRated.includes(selectedAgent._id) ?
                            <Button
                              className={classes.optionButton}
                              size={'small'}
                              color={'primary'}
                              variant={'contained'}
                              onClick={() => setIsReviewing(!isReviewing)}>
                              {'Rate Agent'}
                            </Button>
                            :
                            <Button
                              className={classes.optionButton}
                              size={'small'}
                              color={'primary'}
                              variant={'contained'}
                              disabled={true}
                            >
                              {'Rated'}
                            </Button>

                          }
                        </>
                      }
                    </div>
                  </div>
                  {/* <div className={classes.ratingsWrap}>
                    <RatingIconButton aria-label="rating"
                      onClick={()=> {
                        return user && user.agentsRated &&  !user.agentsRated.includes(selectedAgent._id) ? setIsReviewing(!isReviewing) : false
                      }}
                      >
                      <RatingBadge badgeContent={selectedAgent.positiveRatings}>
                        <ThumbUpAltIcon color="primary"/>
                      </RatingBadge>
                    </RatingIconButton>           
                    {selectedAgent && selectedAgent.reviews && selectedAgent.reviews.length > 0 ?
                      <Typography variant="body2" component="p">
                        {calculatePercentage(selectedAgent.positiveRatings ? selectedAgent.positiveRatings : 0, selectedAgent.reviews.length)}% Positive Rating
                      </Typography>
                    : 
                      <Typography variant="body2" component="p">
                        No ratings yet
                      </Typography>
                    }
                  </div> */}
                  {selectedAgent.agencyName && <Typography variant="body1" component="p">{selectedAgent.agencyName}</Typography>}
                  {selectedAgent.phone && <Typography variant="body1" component="p">Phone: {selectedAgent.phone}</Typography>}
                  {selectedAgent.email && <Typography variant="body1" component="p">Email: {selectedAgent.email}</Typography>}
                  {selectedAgent.website && <Typography variant="body1" component="p">Website: {selectedAgent.website}</Typography>}
                </div>
              </Grid>
              <Grid item xs={12} lg={9} className={classes.detailsGrid}>
                {selectedAgent.about && !isReviewing &&
                  <>
                    <div className={classes.contentText}>
                      <Typography variant="body1" component="h3" className={classes.heading}>About {selectedAgent.firstName}</Typography>
                      <Typography variant="body2" component="p">{selectedAgent.about}</Typography>
                    </div>

                    <div className={classes.contentText}>
                      <Typography variant="body1" component="h3" className={classes.heading}>{selectedAgent.firstName}'s Skills and Expertise</Typography>
                      <Typography variant="body2" component="p">{selectedAgent.about}</Typography>
                    </div>
                  </>
                }

                {isReviewing &&
                  <AgentReview agentData={selectedAgent} setIsReviewing={setIsReviewing} isReviewing={isReviewing} match={props.match} />
                }
              </Grid>
            </Grid>
          </div>
        </>
      }

    </div>
  );
};

const mapStateToProps = state => {
  return {
    stepCounter: null,
    currentAgent: state.agents.current_agent,
    authentication: state.authentication,
    user: state.users.user
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      searchProperty: params => requestPropertySearch(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      getAgentDetails: params => requestAgentDetails(params),

    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentDetails));
