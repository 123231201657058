import React from 'react'
import { Link } from 'react-router-dom'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ViewIcon from '@material-ui/icons/Visibility'

import { withStyles, Button, Badge, Typography, Checkbox } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  optionButton: {
    textTransform: 'none',
    width: '10.500em',
    height: '2.875em',
    borderRadius: '2.250em',
    color: '#000',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 1,
  },
  optionIcon: {
    marginLeft: '0.313em',
    width: '0.625em',
    height: '0.625em',
  },

  checkboxRoot: {
    paddingRight: 0,
    paddingLeft: '5px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  checkedCheckbox: {
    color: 'white',
  },
})

const PropertySearchFilter = (props) => {
  const { classes, omcsStatus, setOmcsStatus } = props

  const [toggleStatus, setToggleStatus] = React.useState(omcsStatus)

  React.useEffect(() => {
    setOmcsStatus(toggleStatus)
  }, [toggleStatus])

  return (
    <>
      <div className={classes.root} style={{ display: 'flex' }}>
        <ToggleButton
          className={classes.optionButton}
          style={{ backgroundColor: toggleStatus[0].active ? '#e1315a' : '#FFF' }}
          value="check"
          selected={toggleStatus[0].active}
          onChange={() => {
            const o = Object.assign([], toggleStatus)
            o[0].active = !o[0].active
            setToggleStatus(o)
            if (!o[0].active) {
              localStorage.removeItem('isOffMarket')
            }
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              color: toggleStatus[0].active ? '#FFF' : '#4d4d4d',
            }}
          >
            {'Off Market '}
            <Checkbox
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checkedCheckbox,
              }}
              color="default"
              name="comingsoonCheckbox"
              checked={toggleStatus[0].active}
              onChange={() => {
                const o = Object.assign([], toggleStatus)
                o[0].active = !o[0].active
                setToggleStatus(o)
                if (!o[0].active) {
                  localStorage.removeItem('isOffMarket')
                }
              }}
              size="small"
            />
          </span>
        </ToggleButton>
        <ToggleButton
          className={classes.optionButton}
          style={{
            backgroundColor: toggleStatus[1].active
              ? 'rgba(253, 189, 38, 0.9)'
              : '#FFF',
          }}
          value="check"
          selected={toggleStatus[1].active}
          onChange={() => {
            const o = Object.assign([], toggleStatus)
            o[1].active = !o[1].active
            setToggleStatus(o)
          }}
        >
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              color: toggleStatus[1].active ? '#FFF' : '#4d4d4d',
            }}
          >
            {'Coming Soon '}
            <Checkbox
              classes={{
                root: classes.checkboxRoot,
                checked: classes.checkedCheckbox,
              }}
              color="default"
              name="comingsoonCheckbox"
              checked={toggleStatus[1].active}
              onChange={() => {
                const o = Object.assign([], toggleStatus)
                o[1].active = !o[1].active
                setToggleStatus(o)
              }}
              size="small"
            />
          </span>
        </ToggleButton>
      </div>
    </>
  )
}

export default withStyles(styles)(PropertySearchFilter)
