import { useEffect } from 'react'

export const useHandleOutsideClickCloseModal = (ref, closeFunction) => {
  useEffect(() => {
    /**
     * if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (closeFunction) {
          closeFunction()
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
