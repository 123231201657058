import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function RuralIcon(props: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      {/* <rect
        width="59.5"
        height="59.5"
        x="0.25"
        y="0.25"
        stroke="#000"
        strokeWidth="0.5"
        rx="4.75"
      ></rect> */}
      <mask
        id="mask0"
        width="3"
        height="11"
        x="7"
        y="36"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7.24 36.63h2.11v10.043H7.24V36.631z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M8.238 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11.162 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267M8.237 37.166a.267.267 0 01-.189-.456l1.463-1.462a.267.267 0 11.378.378l-1.463 1.462a.271.271 0 01-.189.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11.162 37.166a.272.272 0 01-.188-.078L9.51 35.626a.267.267 0 11.378-.378l1.462 1.462a.267.267 0 01-.189.456M14.088 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.146 0 .266.12.266.27v9.506c0 .147-.12.267-.266.267M17.014 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.146 0 .266.12.266.27v9.506c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.089 37.166a.267.267 0 01-.189-.456l1.462-1.462a.267.267 0 11.378.378l-1.462 1.462a.271.271 0 01-.189.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M17.014 37.166a.271.271 0 01-.189-.078l-1.462-1.462a.267.267 0 11.378-.378l1.462 1.462a.267.267 0 01-.189.456M19.94 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.266-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267M22.865 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M19.939 37.166a.267.267 0 01-.189-.456l1.462-1.462a.267.267 0 11.378.378l-1.462 1.462a.271.271 0 01-.19.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M22.864 37.166a.271.271 0 01-.19-.078l-1.461-1.462a.267.267 0 11.377-.378l1.463 1.462a.267.267 0 01-.19.456M25.789 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.146 0 .266.12.266.27v9.506c0 .147-.12.267-.266.267M28.715 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.146 0 .266.12.266.27v9.506c0 .147-.12.267-.266.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M25.79 37.166a.267.267 0 01-.189-.456l1.463-1.462a.267.267 0 11.377.378l-1.462 1.462a.271.271 0 01-.189.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M28.715 37.166a.271.271 0 01-.189-.078l-1.462-1.462a.267.267 0 11.378-.378l1.462 1.462a.267.267 0 01-.189.456M31.64 46.673a.267.267 0 01-.266-.267V36.9c0-.15.12-.27.266-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267M34.566 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M31.64 37.166a.267.267 0 01-.189-.456l1.462-1.462a.267.267 0 11.378.378l-1.462 1.462a.272.272 0 01-.19.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M34.565 37.166a.271.271 0 01-.189-.078l-1.462-1.462a.267.267 0 11.377-.378l1.463 1.462a.267.267 0 01-.19.456M37.492 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267M40.416 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M37.491 37.166a.267.267 0 01-.189-.456l1.463-1.462a.267.267 0 11.377.378l-1.462 1.462a.271.271 0 01-.189.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M40.416 37.166a.271.271 0 01-.188-.078l-1.463-1.462a.267.267 0 11.378-.378l1.462 1.462a.267.267 0 01-.189.456M43.342 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.146 0 .266.12.266.27v9.506c0 .147-.12.267-.266.267M46.267 46.673a.267.267 0 01-.266-.267V36.9c0-.15.12-.27.266-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M43.34 37.166a.267.267 0 01-.189-.456l1.463-1.462a.267.267 0 11.378.378l-1.462 1.462a.271.271 0 01-.19.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M46.266 37.166a.271.271 0 01-.189-.078l-1.462-1.462a.267.267 0 11.378-.378l1.462 1.462a.267.267 0 01-.189.456M49.193 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask1"
        width="3"
        height="11"
        x="51"
        y="36"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M51.007 36.63h2.11v10.043h-2.11V36.631z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M52.119 46.673a.267.267 0 01-.267-.267V36.9c0-.15.12-.27.267-.27.147 0 .267.12.267.27v9.506c0 .147-.12.267-.267.267"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M49.193 37.166a.267.267 0 01-.189-.456l1.462-1.462a.267.267 0 11.378.378l-1.463 1.462a.271.271 0 01-.188.078"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M52.118 37.166a.271.271 0 01-.19-.078l-1.461-1.462a.267.267 0 11.377-.378l1.462 1.462a.267.267 0 01-.188.456M14.088 41.554h-2.925a.267.267 0 110-.533h2.925c.147 0 .267.117.267.266 0 .147-.12.267-.267.267M14.088 43.748h-2.925a.266.266 0 110-.533h2.925c.147 0 .267.117.267.266 0 .147-.12.267-.267.267M19.94 41.554h-2.925a.267.267 0 110-.533h2.924a.266.266 0 110 .533M19.94 43.748h-2.925a.266.266 0 110-.533h2.924c.147 0 .267.117.267.266 0 .147-.12.267-.267.267M25.789 41.554h-2.924a.267.267 0 110-.533h2.924a.266.266 0 110 .533M25.789 43.748h-2.924a.266.266 0 110-.533h2.924c.147 0 .267.117.267.266 0 .147-.12.267-.267.267M31.64 41.554h-2.924a.267.267 0 110-.533h2.925a.266.266 0 110 .533M31.64 43.748h-2.924a.266.266 0 110-.533h2.925c.146 0 .266.117.266.266 0 .147-.12.267-.266.267M37.49 41.554h-2.924a.267.267 0 110-.533h2.924a.266.266 0 110 .533M37.49 43.748h-2.924a.266.266 0 110-.533h2.924c.147 0 .267.117.267.266 0 .147-.12.267-.267.267M43.342 41.554h-2.925a.267.267 0 110-.533h2.925c.146 0 .266.117.266.266 0 .147-.12.267-.266.267M43.342 43.748h-2.925a.266.266 0 110-.533h2.925c.146 0 .266.117.266.266 0 .147-.12.267-.266.267M49.191 41.554h-2.924a.267.267 0 110-.533h2.924c.147 0 .267.117.267.266 0 .147-.12.267-.267.267M49.191 43.748h-2.924a.266.266 0 110-.533h2.924c.147 0 .267.117.267.266 0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2"
        width="3"
        height="3"
        x="51"
        y="40"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M51.372 40.176h1.744v2.222h-1.744v-2.222z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M52.848 41.554h-.731a.266.266 0 01-.267-.267c0-.149.118-.267.267-.267h.73a.267.267 0 010 .533"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask3"
        width="2"
        height="3"
        x="7"
        y="40"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7.24 40.176h1.743v2.222H7.239v-2.222z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M8.238 41.554h-.73a.266.266 0 01-.267-.267c0-.149.117-.267.266-.267h.731a.266.266 0 110 .534"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask4"
        width="2"
        height="3"
        x="7"
        y="42"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M7.24 42.37h1.743v2.222H7.239V42.37z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M8.238 43.748h-.73a.266.266 0 01-.267-.267c0-.149.117-.266.266-.266h.731c.147 0 .267.117.267.266 0 .147-.12.267-.267.267"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask5"
        width="3"
        height="3"
        x="51"
        y="42"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M51.372 42.37h1.744v2.222h-1.744V42.37z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M52.848 43.748h-.731a.266.266 0 01-.267-.267c0-.149.118-.266.267-.266h.73c.147 0 .267.117.267.266 0 .147-.12.267-.266.267"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M40.257 27.901a.267.267 0 01-.267-.266v-9.967a4.519 4.519 0 00-4.515-4.515 4.52 4.52 0 00-4.518 4.515v2.393a.266.266 0 01-.267.267.267.267 0 01-.266-.267v-2.393a5.056 5.056 0 015.05-5.049 5.054 5.054 0 015.05 5.049v9.967c0 .146-.12.266-.267.266"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M51.021 27.901H40.257a.266.266 0 110-.533h10.498v-9.7a4.521 4.521 0 00-4.518-4.515.266.266 0 01-.267-.267c0-.15.118-.267.267-.267a5.054 5.054 0 015.051 5.049v9.967c0 .146-.12.266-.267.266M42.25 13.152h-6.776a.267.267 0 01-.267-.266c0-.15.12-.267.267-.267h6.775a.267.267 0 010 .533"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M40.258 36.67a.267.267 0 01-.267-.266v-8.769c0-.149.12-.269.267-.269.146 0 .266.12.266.27v8.768c0 .147-.12.267-.266.267M43.447 36.67a.267.267 0 01-.267-.266v-8.769c0-.149.12-.269.267-.269.147 0 .267.12.267.27v8.768c0 .147-.12.267-.267.267M43.249 19.53a.73.73 0 10-.003 1.46.73.73 0 00.003-1.46m0 1.993a1.267 1.267 0 01-1.265-1.265 1.264 1.264 0 111.264 1.265"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask6"
        width="3"
        height="10"
        x="41"
        y="11"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M41.139 11.422h2.222v9.104h-2.222v-9.104z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M42.25 20.526a.267.267 0 01-.267-.266v-8.572a.267.267 0 01.533 0v8.572c0 .146-.12.266-.266.266"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask7"
        width="5"
        height="2"
        x="41"
        y="11"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M41.982 11.422h3.725V12.8h-3.725v-1.378z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M45.44 11.956h-3.19a.267.267 0 01-.268-.267c0-.149.12-.266.267-.266h3.191c.147 0 .267.117.267.266 0 .147-.12.267-.267.267"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M45.44 14.347a.267.267 0 01-.267-.266V11.69a.266.266 0 11.533 0v2.39c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M45.44 14.348h-3.19a.267.267 0 01-.268-.267c0-.15.12-.267.267-.267h3.191a.267.267 0 010 .533M40.259 27.901H26.308a.266.266 0 01-.267-.267c0-.148.118-.266.267-.266h13.95a.266.266 0 110 .533"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M28.298 27.902a.268.268 0 01-.267-.267v-7.573c0-.15.12-.267.267-.267h11.96a.266.266 0 110 .533H28.565v7.307c0 .147-.12.267-.267.267"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M34.876 24.712a.255.255 0 01-.146-.046l-6.578-4.385a.263.263 0 01-.076-.369.268.268 0 01.37-.073l6.577 4.385a.264.264 0 01.075.368.265.265 0 01-.222.12"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M34.877 24.712a.265.265 0 01-.223-.12.264.264 0 01.076-.368l5.38-3.587a.265.265 0 01.369.073.262.262 0 01-.073.37l-5.383 3.586a.254.254 0 01-.146.046M46.237 13.152h-.796a.266.266 0 110-.533h.796a.267.267 0 010 .533"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function RuralIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <RuralIcon />
      <RuralIcon color="primary" />
      <RuralIcon color="secondary" />
      <RuralIcon color="action" />
      <RuralIcon color="disabled" />
      <RuralIcon style={{ color: green[500] }} />
    </div>
  );
}
