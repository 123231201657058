import React from 'react';
import BlockButton from "../BlockButton";
import TextSmall from "../TextSmall";
import SectionHeading from "../SectionHeading";
import KeyboardArrowUpSharpIcon from '@material-ui/icons/KeyboardArrowUpSharp';
import FILE_PDF from '../../../assets/statementofinformation-3686.pdf'

import { withStyles, Link } from '@material-ui/core';

const styles = theme => ({
    root: {    
        padding: theme.spacing(3)
    },
    toTopIconGroup : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color:'#4d4d4d',
        fontSize: '0.75rem'        
    },
    toTopGroupIcon :{
        marginRight: theme.spacing(1)
    },
    cta : {
        color: theme.palette.common.black,
        borderRadius: '20px',
        textTransform: 'initial',
        padding: theme.spacing(2, 1),
        minWidth: 285, 
        marginRight: theme.spacing(3),
        backgroundColor: theme.palette.light.main,
        fontSize: 16
    }
  });

const PriceGuideSection = props => {
    const { 
        classes,
        executeTopScroll,
        properties
     } = props; 

    return (
        <div className={classes.root}>                    
            <Link style={{cursor:'pointer'}}
                className={classes.toTopIconGroup} 
                onClick={()=> executeTopScroll()}>
                <KeyboardArrowUpSharpIcon 
                    fontSize="small" 
                    className={classes.toTopGroupIcon}/>
                <span>Back to Top</span>            
            </Link>
            <SectionHeading variant="h6" component="h2">
                Price Guide
            </SectionHeading>
            {/* <TextSmall>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias laborum ea quia amet provident reprehenderit, animi pariatur ullam cupiditate asperiores.
            </TextSmall> */}
            <BlockButton variant="outlined" className={classes.cta} color={'primary'} href={properties && properties.statementOfIntent ? properties.statementOfIntent : properties && properties.statementOfInformation ? properties.statementOfInformation : null} target="_blank">View statement of information</BlockButton>   
        </div>
    )
}

export default withStyles(styles)(PriceGuideSection);
