import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'
import ListOptions from './ListOptions'
import PriceSlider from 'components/Common/GlobalFilters/PriceSlider'
import OptionModalTop from './OptionModalTop'
import useFilters from 'utils/customHooks/useFilters'
import { priceRangeLabelFormatter } from 'utils/formatter/priceRangeLabelFormatter'
import ModalFilterButtons from '../../Mobile/Searchbar/ModalFilterButtons'
import Bedrooms from 'components/Common/GlobalFilters/Bedrooms'
import Bathrooms from 'components/Common/GlobalFilters/Bathrooms'
import IncludeNearbySuburbs from 'components/Common/GlobalFilters/IncludeNearbySuburbs'
import { useSearch } from 'utils/search/useSearch'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const useStyles = makeStyles((theme) => ({
  iconTune: {
    color: '#000',
  },
  filterMainContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  /** Search Filter */
  searchFilterContainer: {
    paddingTop: '0.5em',
    //paddingBottom: '0.25em',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'noWrap',
    flexDirection: 'row',
    overflowX: 'scroll',
    alignItems: 'center',
    position: 'relative',
    zIndex: 6,
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
  },

  /** Top Option Modal */
  optionContainer: {
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
    padding: '70px 35px 20px 35px',
  },
  optionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '0.625em',
  },
  optionButton: {
    textTransform: 'none',
    borderRadius: 20,
    margin: '0.625em',
  },
}))

const SearchModalOptions = ({ showOptionModal, setIsInputFocused, children }) => {
  const classes = useStyles()
  const [initiateSearch] = useSearch()

  return (
    <Paper className={classes.optionContainer} elevation={5}>
      <div className={classes.filterContainer}>{children}</div>
      <div className={classes.optionButtonContainer}>
        <Button
          size={'small'}
          className={classes.optionButton}
          onClick={() => {
            showOptionModal(null, true)
            setIsInputFocused(false)
          }}
        >
          Cancel
        </Button>
        <Button
          size={'small'}
          className={classes.optionButton}
          color={'primary'}
          variant="contained"
          onClick={() => {
            showOptionModal(null, true)
            setIsInputFocused(false)
            initiateSearch()
          }}
        >
          Apply
        </Button>
      </div>
    </Paper>
  )
}

const PropertyTypeModalOption = ({ showOptionModal, setIsInputFocused }) => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const [initiateSearch] = useSearch()

  const PROPERTY_TYPES = getFilter('property_type')

  const handlePropertyType = (data) => {
    setFilter({ key: 'property_type', value: data })
  }

  return (
    <Paper className={classes.optionContainer} elevation={5}>
      <Box>
        <Box>
          <ListOptions
            type={'property'}
            options={PROPERTY_TYPES}
            handleType={handlePropertyType}
          />
        </Box>
      </Box>
      <div className={classes.optionButtonContainer}>
        <Button
          size={'small'}
          className={classes.optionButton}
          onClick={() => {
            showOptionModal(null, true)
            setIsInputFocused(false)
          }}
        >
          Cancel
        </Button>
        <Button
          size={'small'}
          className={classes.optionButton}
          color={'primary'}
          variant="contained"
          onClick={() => {
            // handleData(null, 'save')
            initiateSearch()
            showOptionModal(null, true)
            setIsInputFocused(false)
          }}
        >
          Apply
        </Button>
      </div>
    </Paper>
  )
}

const FilterOptionsV2 = ({
  type,
  showModalOptions,
  showOptionModal,
  setIsInputFocused,
  path,
  toggleFilter,
  selectedFilterType,
  navigateFilterButtonTo,
}) => {
  const classes = useStyles()

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const handleFilterButtonClick = (filterType) => {
    showOptionModal(filterType)
    setIsInputFocused(false)

    triggerAnalyticsEvent({
      event: 'results_actions',
      eventFrom: 'Mobile',
      actionScope: 'inline_search_filters',
      actionName: 'Inline Search Filters',
      actionTarget: 'Expanded ' + filterType + ' options',
      actionReference: 'results',
    })
  }

  let modalContent = null

  switch (selectedFilterType) {
    case 'bed':
      modalContent = <Bedrooms />
      break
    case 'bath':
      modalContent = <Bathrooms />
      break
    case 'cost':
      modalContent = <PriceSlider path={path} />
      break
  }

  function renderFilterOptions() {
    return (
      <>
        <div className={classes.filterMainContainer}>
          <IncludeNearbySuburbs />
        </div>
      </>
    )
  }

  function renderSearchOptions() {
    return (
      <>
        <div className={classes.searchFilterContainer}>
          <ModalFilterButtons
            handleFilterButtonClick={handleFilterButtonClick}
            path={path}
            navigateFilterButtonTo={navigateFilterButtonTo}
          />
        </div>
      </>
    )
  }

  return (
    <Box style={{ position: 'relative' }}>
      <OptionModalTop
        isOpen={showModalOptions}
        showOptionModal={showOptionModal}
        content={
          selectedFilterType === 'propertyType' ? (
            <PropertyTypeModalOption
              showOptionModal={showOptionModal}
              setIsInputFocused={setIsInputFocused}
            />
          ) : (
            <SearchModalOptions
              showOptionModal={showOptionModal}
              setIsInputFocused={setIsInputFocused}
            >
              {modalContent}
            </SearchModalOptions>
          )
        }
      />
      {type === 'search' ? renderSearchOptions() : renderFilterOptions()}
    </Box>
  )
}

export default FilterOptionsV2
