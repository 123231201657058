import React, { useState, useEffect } from 'react'
import Image from 'material-ui-image'
import HeartFullIcon from '@material-ui/icons/Favorite'
import { connect } from 'react-redux'

import HeartIcon from '@material-ui/icons/FavoriteBorder'

import PropertySuggestionsAgentBanner from './PropertySuggestionsAgentBanner'
import PropertySuggestionsInfo from './PropertySuggestionsInfo'

import IMGComingSoon from '../../../assets/images/coming_soon.svg'
import IMGOffMarket from '../../../assets/images/off_market.svg'
import { cloudinaryCardTransforms, cloudinaryImageHeightSmall } from '../../../constants/constants'

import _ from 'lodash'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'

import { withStyles, Card, IconButton, Tooltip } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: 'auto',
    position: 'relative',
  },
  card: {
    width: 240,
    minHeight: 240,
    margin: theme.spacing(1),
    position: 'relative',
    paddingBottom: theme.spacing(0.5),
  },
  media: {
    width: '100%',
    height: '6.250em',
    objectFit: 'cover !important',
    transition: 'all 0.3s ease',
    filter: 'brightness(1)',
    cursor: 'pointer',
    '&:hover': {
      filter: 'brightness(1.1)',
    },
  },
  favoriteContainer: {
    width: '0.625em',
    height: '0.625em',
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
    width: '0.625em',
    height: '0.625em',
  },
  favoriteIconActive: {
    color: '#57bdc7',
    width: '0.625em',
    height: '0.625em',
  },
})

const PropertySuggestionsCards = (props) => {
  const {
    classes,
    type,
    properties,
    trades,
    authentication,
    toggleSignUp,
    settings,
    apiRequestSaveProperty,

  } = props
  const [routerChange] = useRouterChange()

  const MPP = useSelector(getMpp)
  let likes = MPP?.favourites?.data?.likes
  const [galleryImages, setGalleryImages] = useState(null)

  useEffect(() => {
    if (properties && properties.images && properties.images.length > 0) {
      let gallery_array = []
      if (properties.cloudinaryImages && properties.cloudinaryImages.length > 0) {
        properties.cloudinaryImages.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageHeightSmall + '/' + item.file
          gallery_array.push(file)
        })
      } else {
        gallery_array = _.compact(properties.images)
      }

      setGalleryImages(gallery_array)
    }
  }, [properties])

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
    } else {
      /** User Logged */
      apiRequestSaveProperty(properties.property)

      console.log(properties.property)
    }
  }

  function imageType(type, image) {
    switch (type) {
      case 'auction':
        return image
      case 'comingsoon':
        return IMGComingSoon
      case 'offmarket':
        return IMGOffMarket
      default:
        return image
    }
  }

  const onPropertyClick = (e, property) => {
    const currentPath = window.location.pathname
    const currentPage = currentPath.substring(
      currentPath.indexOf('/'),
      currentPath.indexOf('/', 2)
    )
    e.preventDefault()
    routerChange(`${currentPage}/${property}`)
    window.location.reload()
  }

  function renderProperties() {
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <Image
            className={classes.media}
            src={propertyConfigSetter('image-banner-card', {
              type: properties.propertyStatus,
              image:
                galleryImages && galleryImages[0]
                  ? galleryImages[0]
                  : properties.images[0],
            })}
            onClick={(e) => onPropertyClick(e, properties.property)}
            aspectRatio={16 / 9}
            disableSpinner
          />
          <IconButton
            className={classes.favoriteContainer}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              checkLoginStatus()
            }}
          >
            {authentication &&
              authentication.user_status === 'logged' &&
              likes &&
              likes?.includes(properties?.property) ? (
              <Tooltip title="Remove to favorite">
                <HeartFullIcon className={classes.favoriteIconActive} />
              </Tooltip>
            ) : (
              <Tooltip title="Add to favorite">
                <HeartIcon className={classes.favoriteIcon} />
              </Tooltip>
            )}
          </IconButton>
          <PropertySuggestionsAgentBanner properties={properties} />
          <PropertySuggestionsInfo type={type} properties={properties} />
        </Card>
      </div>
    )
  }

  function renderTrades() {
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <Image
            className={classes.media}
            src={imageType('', trades.image)}
            onClick={() => ''}
            aspectRatio={16 / 9}
            disableSpinner
          />
          <PropertySuggestionsInfo type={type} trades={trades} />
        </Card>
      </div>
    )
  }

  return (
    <>
      {type === 'properties' && renderProperties()}
      {type === 'trades' && renderTrades()}
    </>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(withStyles(styles)(PropertySuggestionsCards))
