import { PURGE } from 'redux-persist';
import {
    CLEAR_REFERENCE_SEARCH_FILTERS,
    CLEAR_TYPES_COUNTERS,
    REFERENCE_SEARCH_FILTERS,
    SET_TYPES_COUNTERS
} from '../constants';



const INITIAL_STATE = {
    filters: null,
    typesCounters: null,
    scope: null,
    cursor: null
};

const referenceFiltersReducers = (state = INITIAL_STATE, action) => {

    switch (action.type) {


        case REFERENCE_SEARCH_FILTERS:
            return {
                ...state,
                filters: action.params,
                scope: action.reference,
                cursor: action.cursor
            }

        case CLEAR_REFERENCE_SEARCH_FILTERS:
            return INITIAL_STATE;

        case SET_TYPES_COUNTERS:
            return {
                ...state,
                typesCounters: action.params,
            }

        case CLEAR_TYPES_COUNTERS:
            return {
                ...state,
                typesCounters: null
            };
        case PURGE:
            return INITIAL_STATE

        default: return state;
    }
}

export default referenceFiltersReducers