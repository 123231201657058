import React, { useState, useEffect } from 'react'
import { Checkbox, Typography, Button, Grid, withStyles } from '@material-ui/core'
import styled from 'styled-components'
import _ from 'lodash'

import SectionHeading from '../SectionHeading'
import FormField from '../../../components/Mobile/Form/FormField'
import TextAreaField from '../../../components/Mobile/Form/TextAreaField'
import ContentDialog from '../CustomDialogs/ContentDialog'

import checkField from 'components/Common/Validator/validationService'

import { CONTACTFORM_FIELDS_CONFIG } from '../../Mobile/Property/constants'

import AgentBanner from './AgentBanner'
import ImageContent from '../../../assets/images/content/guy-chair-chat-m.svg'
import Image from 'material-ui-image'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'
const StyledCheckbox = withStyles({
  root: {
    paddingLeft: 0,
    color: '#4D4D4D',
  },
})(Checkbox)

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.09px;
    a {
      color: #646464;
      text-decoration: underline;
    }
  }
`

const styles = (theme) => ({
  section: {
    padding: theme.spacing(3, 0),
    '&:last-of-type': {
      paddingBottom: '5.5em',
      paddingTop: 0,
    },
  },
  submitButton: {
    borderRadius: 20,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '12.6875em',
    textTransform: 'initial',
  },
  buttonWrap: {
    textAlign: 'left',
  },
  cta: {
    color: '#ffffff',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '10.625em',
  },
  ctaWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  btnIcons: {
    marginRight: theme.spacing(1),
  },
  formWrap: {
    maxWidth: 670,
  },
  errorMessage: {
    color: '#EB4335',
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.09px',
    margin: '-20px 0 0 2px',
    position: 'absolute',
  },
  modalHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 32,
    lineHeight: '29px',
    color: '#4f4f4f',
    marginBottom: 20,
    letterSpacing: '-0.78px',
    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
      fontSize: 32,
    },
  },
  modalImageColumn: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const ContactSection = ({
  classes,
  user,
  properties,
  apiRequestContactAgent,
  inquiry_status,
}) => {
  const [errors, setErrors] = useState({
    email: { status: false, message: '' },
    name: { status: false, message: '' },
    postcode: { status: false, message: '' },
    phone: { status: false, message: '' },
    enquiry: { status: false, message: '' },
  })

  const [about, setAbout] = useState({
    inspectionTime: { name: 'Inspection Time', isChecked: false },
    priceGuide: { name: 'Price Guide', isChecked: false },
    propertySize: { name: 'Property Size', isChecked: false },
    floorPlan: { name: 'Floor Plan', isChecked: false },
    fees: { name: 'Fees', isChecked: false },
    requestSOI: { name: 'Request SOI', isChecked: false },
  })

  const [name, setName] = useState(user?.name || '')
  const [phone, setPhone] = useState(user?.phone || '')
  const [email, setEmail] = useState(user?.email || '')
  const [postcode, setPostcode] = useState('')
  const [message, setMessage] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [enquiryError, setEnquiryError] = useState(false)
  const [contactStatus, setContactStatus] = useState(null)

  useEffect(() => {
    if (inquiry_status === 'success') {
      setContactStatus(inquiry_status)
    }
  }, [inquiry_status])

  const ModalContent = () => {
    return (
      <Grid container spacing={0}>
        {/*         <Grid xs={6} style={{ marginBottom: 40, position: 'relative', zIndex: 2 }}>
          <Typography component={'h2'} className={classes.modalHeading}>
            <strong>good</strong> news
          </Typography>
          <Typography
            component={'p'}
            variant={'body2'}
            style={{ paddingBottom: 20, color: '#828282', fontSize: 14 }}
          >
            Your message to our PropertyMate team has landed in our inbox.
          </Typography>
          <Typography
            component={'p'}
            variant={'body2'}
            style={{ color: '#828282', fontSize: 14 }}
          >
            We&rsquo;re&nbsp;feverishly&nbsp;sifting through all our emails and promise
            to get back to you within 24hrs.
          </Typography>
        </Grid> */}
        <Grid item xs={6} style={{ marginBottom: 40, position: 'relative', zIndex: 2 }}>
          <Typography component={'h2'} className={classes.modalHeading}>
            <strong>Good</strong> news!
          </Typography>
          <Typography
            component={'p'}
            variant={'body2'}
            style={{ paddingBottom: 20, color: '#828282', fontSize: 14 }}
          >
            Your message has landed in the Agent&rsquo;s inbox.
          </Typography>
          <Typography
            component={'p'}
            variant={'body2'}
            style={{ color: '#828282', fontSize: 14 }}
          >
            They&rsquo;ll be in touch shortly with all the info you need.
          </Typography>
        </Grid>
        <Grid xs={6}>
          <Image
            src={ImageContent}
            style={{ width: 222 }}
            aspectRatio={4 / 3.6}
            color={'transparent'}
            imageStyle={{ objectFit: 'contain' }}
          />
        </Grid>
      </Grid>
    )
  }

  const formCheck = (fields, errors) => {
    const reqFields =
      fields.email.trim() && fields.postcode.trim() && fields.name.trim() ? true : false
    if (reqFields && Object.keys(errors).length > 0) {
      if (
        !errors.email.status &&
        !errors.postcode.status &&
        !errors.name.status &&
        !errors.enquiry.status
      ) {
        setIsButtonDisabled(false)
      } else {
        setIsButtonDisabled(true)
      }
    } else {
      setIsButtonDisabled(true)
    }
  }

  useEffect(() => {
    if (phone === '' || !errors.phone.status || !errors.enquiry.status) {
      formCheck({ email, postcode, name }, errors)
    }
    if (
      errors.email.status ||
      errors.postcode.status ||
      errors.name.status ||
      errors.phone.status
    ) {
      setEnquiryError(true)
      setIsButtonDisabled(true)
    }
  }, [email, postcode, name, phone, about, errors])

  useEffect(() => {
    resetStates()
  }, [properties])

  useEffect(() => {
    let statusArr = []
    for (let prop in about) {
      statusArr.push(about[prop].isChecked)
    }
    if (statusArr.includes(true)) {
      setErrors({ ...errors, enquiry: { status: false, message: '' } })
    } else {
      setErrors({
        ...errors,
        enquiry: { status: true, message: 'Enquiry checkbox is required.' },
      })
    }
  }, [about])

  const resetStates = () => {
    setAbout({
      inspectionTime: { name: 'Inspection Time', isChecked: false },
      priceGuide: { name: 'Price Guide', isChecked: false },
      propertySize: { name: 'Property Size', isChecked: false },
      floorPlan: { name: 'Floor Plan', isChecked: false },
      fees: { name: 'Fees', isChecked: false },
      requestSOI: { name: 'Request SOI', isChecked: false },
    })
    setName(user?.name || '')
    setPhone(user?.phone || '')
    setEmail(user?.email || '')
    setPostcode('')
    setMessage('')
    setEnquiryError(false)
    setIsButtonDisabled(true)
  }

  const sendInquiry = () => {
    let tempSubj = []
    _.filter(about, function (o) {
      if (o.isChecked) {
        tempSubj.push(o.name)
      }
    })

    let request = {
      name: name,
      email: email,
      phone: phone,
      postcode: postcode,
      message: message,
      inquiry: tempSubj,
    }
    apiRequestContactAgent(request)
    resetStates()
  }

  const updateAbout = (event) => {
    setAbout({
      ...about,
      [event.target.name]: {
        name: event.target.value,
        isChecked: event.target.checked,
      },
    })
  }

  return (
    <div style={{ marginTop: 30 }}>
      <AgentBanner
        type={'property'}
        properties={properties}
        isMultipleAgents={properties.agents.length > 1 ? true : false}
        {...propertyConfigSetter('agent', properties.subscriptionType)}
        showAgentAvatar={false}
        style={{ height: 60 }}
      />
      <ContentDialog
        content={<ModalContent />}
        isOpen={contactStatus && contactStatus === 'success' ? true : false}
        closeCallback={() => setContactStatus(null)}
        style={{ padding: 0 }}
      />
      <div className={classes.section}>
        {properties.agent && properties.agency && (
          <SectionHeading variant="body1" gutterBottom>
            {properties.agents.length && properties.agents.length > 1
              ? `Email enquiry to ${
                  properties.agency.registeredTradeName
                    ? properties.agency.registeredTradeName
                    : properties.agency.companyName
                }`
              : `Email enquiry to ${
                  properties?.agent?.name ??
                  properties?.agent?.firstName + ' ' + properties?.agent?.lastName
                }`}
          </SectionHeading>
        )}
        {properties && properties.agent && properties.agents && (
          <AgentBanner
            type={'property'}
            properties={properties}
            isMultipleAgents={properties.agents.length > 0 ? true : false}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
            showBanner={false}
            style={{ height: 60 }}
          />
        )}

        <Typography variant="body1" gutterBottom style={{ marginBottom: 18 }}>
          What is your enquiry about?
        </Typography>

        {enquiryError ? (
          <Typography className={classes.errorMessage} variant="body1" gutterBottom>
            {errors.enquiry.message}
          </Typography>
        ) : null}

        <Grid container spacing={2}>
          <Grid item xs={6} md={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CheckBoxLabel htmlFor="inspection-time">
              <StyledCheckbox
                id="inspection-time"
                name="inspectionTime"
                color="primary"
                value="Inspection times"
                checked={about.inspectionTime.isChecked}
                onChange={updateAbout}
              />
              <Typography variant="body1" color="textPrimary">
                Inspection times
              </Typography>
            </CheckBoxLabel>
          </Grid>
          <Grid item xs={6} md={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CheckBoxLabel htmlFor="price-guide">
              <StyledCheckbox
                id="price-guide"
                name="priceGuide"
                color="primary"
                value="Price guide"
                checked={about.priceGuide.isChecked}
                onChange={updateAbout}
              />
              <Typography variant="body1">Price guide</Typography>
            </CheckBoxLabel>
          </Grid>
          <Grid item xs={6} md={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CheckBoxLabel htmlFor="property-size">
              <StyledCheckbox
                id="property-size"
                name="propertySize"
                color="primary"
                value="Property size"
                checked={about.propertySize.isChecked}
                onChange={updateAbout}
              />
              <Typography variant="body1">Property size</Typography>
            </CheckBoxLabel>
          </Grid>
          <Grid item xs={6} md={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CheckBoxLabel htmlFor="floor-plan">
              <StyledCheckbox
                id="floor-plan"
                name="floorPlan"
                color="primary"
                value="Floor plan"
                checked={about.floorPlan.isChecked}
                onChange={updateAbout}
              />
              <Typography variant="body1">Floor plan</Typography>
            </CheckBoxLabel>
          </Grid>
          <Grid item xs={6} md={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CheckBoxLabel htmlFor="fees">
              <StyledCheckbox
                id="fees"
                name="fees"
                color="primary"
                value="Strata, fees, levies, rates"
                checked={about.fees.isChecked}
                onChange={updateAbout}
              />
              <Typography variant="body1">Strata, fees, levies, rates</Typography>
            </CheckBoxLabel>
          </Grid>

          <Grid item xs={6} md={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
            <CheckBoxLabel htmlFor="request-soi">
              <StyledCheckbox
                id="request-soi"
                name="requestSOI"
                color="primary"
                value="Request SOI"
                checked={about.requestSOI.isChecked}
                onChange={updateAbout}
              />
              <Typography variant="body1">Request SOI</Typography>
            </CheckBoxLabel>
          </Grid>
        </Grid>
      </div>
      <div className={classes.section}>
        <SectionHeading variant="h6" component="h2">
          Add a message (optional)
        </SectionHeading>
        <Grid container spacing={2} className={classes.formWrap}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={'text'}
              value={name}
              placeholder={'Name *'}
              errorMsg={errors.name.message}
              showError={errors.name.status}
              onChange={(e) => {
                const value = e.target.value
                setName(value)
                if (checkField(value, CONTACTFORM_FIELDS_CONFIG.fields.name) === null) {
                  setErrors((prevState) => ({
                    ...prevState,
                    name: { ...prevState.name, status: false },
                  }))
                } else {
                  setErrors((prevState) => ({
                    ...prevState,
                    name: {
                      ...prevState.name,
                      status: true,
                      message: checkField(value, CONTACTFORM_FIELDS_CONFIG.fields.name),
                    },
                  }))
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={'tel'}
              value={phone}
              placeholder={'Phone'}
              errorMsg={errors.phone.message}
              showError={phone !== '' && errors.phone.status}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '')
                setPhone(value)
                if (
                  checkField(value, CONTACTFORM_FIELDS_CONFIG.fields.phone) === null
                ) {
                  setErrors((prevState) => ({
                    ...prevState,
                    phone: { ...prevState.phone, status: false },
                  }))
                } else {
                  setErrors((prevState) => ({
                    ...prevState,
                    phone: {
                      ...prevState.phone,
                      status: true,
                      message: checkField(
                        value,
                        CONTACTFORM_FIELDS_CONFIG.fields.phone
                      ),
                    },
                  }))
                }
              }}
              maxlength={14}
              telformat={'9999999999'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={'email'}
              value={email}
              placeholder={'Email Address *'}
              errorMsg={errors.email.message}
              showError={errors.email.status}
              onChange={(e) => {
                const value = e.target.value
                setEmail(value)
                if (
                  checkField(value, CONTACTFORM_FIELDS_CONFIG.fields.email) === null
                ) {
                  setErrors((prevState) => ({
                    ...prevState,
                    email: { ...prevState.email, status: false },
                  }))
                } else {
                  setErrors((prevState) => ({
                    ...prevState,
                    email: {
                      ...prevState.email,
                      status: true,
                      message: checkField(
                        value,
                        CONTACTFORM_FIELDS_CONFIG.fields.email
                      ),
                    },
                  }))
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              autocomplete="autocomplete_off_inquire_postcode"
              type={'text'}
              value={postcode}
              placeholder={'Postcode *'}
              errorMsg={errors.postcode.message}
              showError={errors.postcode.status}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '')
                setPostcode(value)
                if (
                  checkField(value, CONTACTFORM_FIELDS_CONFIG.fields.postcode) === null
                ) {
                  setErrors((prevState) => ({
                    ...prevState,
                    postcode: { ...prevState.postcode, status: false },
                  }))
                } else {
                  setErrors((prevState) => ({
                    ...prevState,
                    postcode: {
                      ...prevState.postcode,
                      status: true,
                      message: checkField(
                        value,
                        CONTACTFORM_FIELDS_CONFIG.fields.postcode
                      ),
                    },
                  }))
                }
              }}
              maxlength={4}
            />
          </Grid>
          <Grid item xs={12}>
            <TextAreaField
              aria-label="Add your message"
              placeholder="Add your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>
        </Grid>
        <div className={classes.buttonWrap}>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disableElevation
            disabled={isButtonDisabled}
            onClick={() => {
              sendInquiry()
            }}
          >
            Send an enquiry
          </Button>
        </div>
      </div>
    </div>
  )
}

export default withStyles(styles)(ContactSection)
