import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuperBreadCrumbs } from 'components/Web/Superadmin';
import AlertNotification from 'components/Web/Form/AlertNotification';
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  useMediaQuery,
  Avatar,
  Container,
  withStyles,
  useTheme,
} from '@material-ui/core';

import DashboardBanner from 'components/Web/Dashboard/DashboardBanner';
import dashBannerIMG from 'assets/images/agentbanner.png';
import InputMask from 'react-input-mask';
/** redux */
import { agencyv2Actions } from 'store/actions';

import { requestGETAPIAgency, requestPUTAPIAgency, requestGETAPISuperadmin } from 'store/api';
import { getAdminStatus, getAdminErrors } from 'store/selectors/agencyv2.selectors';

import { getAgency } from 'store/selectors/superadmin.selectors';
import Loader from 'components/Web/Loader';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { requestPOSTAPIAgency } from 'store/api';
import { AddEditAdminForm } from 'components/Web/Superadmin';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
    paddingRight: '40px',
    overflow: 'scroll',
    '& .MuiBackdrop-root': {
      maxWidth: 'initial !important',
      left: 0,
    },
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left',
  },
  subtitle: {
    fontWeight: 300,
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left',
  },
  formContainer: {
    width: '100%',
    height: 'auto',
    marginRight: 20,
    padding: '2%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 60,
    marginRight: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'capitalize',
    borderRadius: 20,
    marginLeft: 10,
    minWidth: 120,
  },
  icon: {
    fontSize: 25,
  },
  fieldGroup: {
    marginTop: 20,
    borderTop: '1px solid #d3d3d3',
    paddingTop: 20,
    paddingBottom: 20,
  },
});

const FormFields = {
  firstName: { name: 'firstName', label: 'First Name' },
  lastName: { name: 'lastName', label: 'Last Name' },
  email: { name: 'email', label: 'Email Address' },
  phone: { name: 'phone', label: 'Phone' },
  password: { name: 'password', label: 'Password' },
};

const validationSchema = yup.object({
  email: yup.string('Enter admin email').email('Enter a valid email').required('Email is required'),
});

const FormInput = (props) => (
  <div style={{ marginTop: 10 }}>
    <Typography variant={'caption'} style={{ color: 'gray' }}>
      {props.label}
    </Typography>
    <TextField
      style={{ backgroundColor: '#FFF' }}
      {...props}
      label={null}
      fullWidth
      id="outlined-basic"
      variant="outlined"
    />
  </div>
);

const SuperadminAddAdmin = (props) => {
  const { classes, history, match } = props;

  const dispatch = useDispatch();

  /**
   * ? Maintain selector variable format to UPPERCASE to know distinction
   */
  const STATUS = useSelector(getAdminStatus);
  const ERROR = useSelector(getAdminErrors);

  const AGENCY = useSelector(getAgency);

  const [isLoading, setIsLoading] = useState(false);
  const defaultNotification = { status: false, options: null };
  const defaultConfirmation = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [confirmation, setConfirmation] = useState(defaultConfirmation);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
    {
      type: 'secondary',
      label: 'Manage Agency Information',
      path: '/superadmin/manage-agencies/' + match?.params?.id,
    },
    {
      type: 'secondary',
      label: 'Manage Admins',
      path: '/superadmin/manage-agencies/' + match?.params?.id + '/admins',
    },
    {
      type: 'primary',
      label: 'Add Admins',
      path: '',
    },
  ];

  window.onbeforeunload = (e) => {
    handleClearData();
  };

  useEffect(() => {
    handleClearData();
    if (!AGENCY) {
      handleGetAgencyData();
    }

    return () => {
      handleClearData();
    };
  }, []);

  const handleConfirmDialog = (data) => {
    setConfirmation(data);
  };

  const handleCloseConfirmation = () => {
    setConfirmation(defaultConfirmation);
  };

  /**
   *? API Request */

  /**
   * Sample API Request via Redux Dispatcher
   * @param query leave null if no inline url query is required
   * @param data leave as empty obj as default
   * ? Always use dispatch for api calls
   */
  /*   const handlePostSample = (data) => {
      let request = {
        query: null,
        data: { ...data },
      }
      dispatch(requestPOSTAPIAgency(request, 'AGENCY_LOGIN'))
    } */

  const handleGetAgencyData = (data) => {
    let request = {
      query: '/' + match.params.id,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_AGENCY_DATA'));
  };

  const handleClearData = () => {
    // dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'))
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'));
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'));
  };

  return (
    <div className={classes.root}>
      <Loader isLoading={isLoading} message={'Loading admins...'} />
      {/* <DashboardBanner title={'Manage Admins'} image={dashBannerIMG} /> */}
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
      <SuperBreadCrumbs section={'Add Admins'} crumbsLinks={CRUMBS} />

      <AddEditAdminForm agency={AGENCY?.data?.entity} type={'superadmin'} match={match} />
    </div>
  );
};
export default withStyles(styles)(SuperadminAddAdmin);
