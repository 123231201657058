export const getProperties = state => state.property

export const getSearchProperties = state => state.search

export const getSavePropertyStatus = state => state.property.property_save_property_status

export const getSavePropertyError = state => state.property.property_save_property_error

export const getSkipTakeData = state => state.skipTakeData

export const getSaveSearchStatus = state => state.property.property_save_search_status

export const getSaveSearchError = state => state.property.property_save_search_error

export const getSaveSearchSuccess = state => state.property.property_save_property_success