import { useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getAllFilters } from 'utils/search/selectors/selectors.search';

import { typeChecker } from 'utils/compareGlobalFilters';

import SUBURBS from 'assets/data/suburbs-all.json';
import { MPP_FILTERS } from 'constants/constants';
import { RENT_FILTERS } from 'constants/constants';
import { NORMAL_FILTERS } from 'constants/constants';
import { GLOBAL_FILTER_DEFAULT } from 'constants/globalfilters';
import convertPriceToScaledValue from 'utils/formatter/convertPriceToScaledValue';
import { handleSearchPropertyReference } from 'utils/handleSearchPropertyReference';
import checkFilterKeyCategory from 'utils/search/utils/checkFilterKeyCategory';
import compareGlobalFilters from 'utils/compareGlobalFilters';
import { searchActions } from 'store/actions';
import { StorageActions } from 'store/actions/storage.actions';
import { FiltersActions } from 'store/actions';
import { formatRequest } from 'utils/search/query/formatRequest';
import { paramsToSha1 } from 'utils/search.utils';
import { requestPOSTAPISearch } from 'store/api';
import { useEffect, useState } from 'react';
import { PRICE_MARKS } from 'constants/globalfilters';
import { RENT_PRICE_MARKS } from 'constants/globalfilters';
import { getFromInnerPage } from 'store/selectors/storage.selectors';

// const NORMAL_FILTERS = [
//     'suburbs',
//     'include_nearby_suburbs',
//     'property_type',
//     'price',
//     'bedroom',
//     'bathroom',
//     'carparking',
//     'land_size',
//     'features',
//     'keyword',
//     'exclude_under_offers',
//     // 'is_established',
//   ]

//   const RENT_FILTERS = [
//     'suburbs',
//     'include_nearby_suburbs',
//     'property_type',
//     'price_rent',
//     'bedroom',
//     'bathroom',
//     'carparking',
//     'land_size',
//     'features',
//     'available_date',
//     'keyword',
//     'exclude_under_offers',
//     // 'is_established',
//     'furnished',
//   ]

//   const MPP_FILTERS = [
//     'state',
//     'radius_distance',
//     'property_type',
//     'price',
//     'bedroom',
//     'bathroom',
//     'carparking',
//     'nearby_filters',
//   ]

const SEARCH_ROUTES = ['/buy', '/rent', 'coming-soon', '/off-market', '/hush-hush', '/perfect-property'];

const sanitizer = (data) => data?.replace(/(\r\n|\n|\r)/gm, '').trim();

const mutateQueryStringToFilter = (filterKey, filterValue) => {
  const filterKeyCategory = checkFilterKeyCategory(filterKey);

  const sanitizedFilter = sanitizer(filterValue);

  switch (filterKeyCategory) {
    case 'array_obj_id_active': {
      const currentFilter = GLOBAL_FILTER_DEFAULT[filterKey]?.map((filter) => {
        if (
          sanitizedFilter
            .replace(/(\r\n|\n|\r|\t)/gm, '')
            .trim()
            .split(',')
            .includes(filter.id)
        ) {
          return { ...filter, active: true };
        } else {
          return filter;
        }
      });

      return { [filterKey]: currentFilter };
    }

    case 'array_obj_id': {
      const suburbsFromUrl = [];

      let parsedIds = sanitizedFilter.split(',').map((id) => {
        let parsedId = Number(id);
        return parsedId === 0 ? parsedId : parsedId || id;
      });

      SUBURBS.forEach((suburb) => {
        if (parsedIds.includes(suburb.id)) {
          suburbsFromUrl.push({
            ...suburb,
            name: suburb.locality + ', ' + suburb.state + ', ' + suburb.postcode,
          });
        }
      });

      return {
        [filterKey]: suburbsFromUrl,
      };
    }
    case 'key_value': {
      const isBedBathPark = ['bedroom', 'bathroom', 'carparking'].includes(filterKey);

      if (isBedBathPark) {
        let bedBathParkFilter = GLOBAL_FILTER_DEFAULT[filterKey];
        let queryParamsFilter = sanitizedFilter.split('-');

        return {
          [filterKey]: {
            ...bedBathParkFilter,
            value: Number(queryParamsFilter[0]),
            exact: queryParamsFilter.length > 1 ? true : false,
          },
        };
      }

      return {
        [filterKey]: sanitizedFilter == 'true' ? true : sanitizedFilter == 'false' ? false : sanitizedFilter,
      };
    }

    case 'range': {
      const isPrice = ['price', 'price_rent'].includes(filterKey);
      const filterValues = sanitizedFilter.split('-');

      if (isPrice) {
        const parsedMinPrice = Number(filterValues[0]);
        const parsedMaxPrice = Number(filterValues[1]);

        return {
          [filterKey]: {
            ...GLOBAL_FILTER_DEFAULT[filterKey],
            min: parsedMinPrice,
            max: parsedMaxPrice,
            scaled: [
              convertPriceToScaledValue(parsedMinPrice, filterKey === 'price' ? PRICE_MARKS : RENT_PRICE_MARKS),
              convertPriceToScaledValue(parsedMaxPrice, filterKey === 'price' ? PRICE_MARKS : RENT_PRICE_MARKS),
            ],
          },
        };
      }

      return {
        [filterKey]: {
          min: filterValues[0] === 'null' ? null : filterValues[0],
          max: filterValues[1] === 'null' ? null : filterValues[1],
        },
      };
    }

    case 'date': {
      //TODO: url query date implementation
      break;
    }

    default: {
      console.debug('error in converting filter into query string');
      return {};
    }
  }
};

const mutateLocalSearchRefFromUrl = (urlPath) => {
  return GLOBAL_FILTER_DEFAULT.search_reference.map((searchRef) => {
    if (urlPath === searchRef.path) {
      return { ...searchRef, active: true };
    } else if (urlPath === '/hush-hush') {
      if (searchRef.path === '/coming-soon' || searchRef.path === '/off-market') {
        return { ...searchRef, active: true };
      }
    }

    return { ...searchRef, active: false };
  });
};

export const convertUrlQueryToFilters = (queryParameters, urlSearchReference) => {
  let filtersFromUrl = { ...GLOBAL_FILTER_DEFAULT };

  queryParameters.forEach((filterValue, filterKey) => {
    filtersFromUrl = {
      ...filtersFromUrl,
      ...mutateQueryStringToFilter(filterKey, filterValue),
      search_reference: urlSearchReference,
    };
  });

  return filtersFromUrl;
};

export default function useConvertUrlToFilters() {
  const history = useHistory();
  const queryParameters = new URLSearchParams(history.location.search);

  const CURRENT_FILTERS = useSelector(getAllFilters);
  const FROM_INNERPAGE = useSelector(getFromInnerPage);

  const dispatch = useDispatch();

  const [isFiltersFromUrl, setIsFiltersFromUrl] = useState(false);

  useEffect(() => {
    const filtersFromUrlQuery = convertUrlQueryToFilters(
      queryParameters,
      mutateLocalSearchRefFromUrl(history.location.pathname),
    );

    const isUrlDifferentFromLocal =
      compareGlobalFilters(
        history.location.pathname === '/rent' ? 'rent' : 'normal',
        CURRENT_FILTERS,
        filtersFromUrlQuery,
      ).length > 0;

    const pathFromFilters = handleSearchPropertyReference(CURRENT_FILTERS?.search_reference);

    if (
      (isUrlDifferentFromLocal ||
        filtersFromUrlQuery.suburbs.length > 0 ||
        pathFromFilters != history.location.pathname ||
        history.location.state?.previousSearchRef !== history.location.state?.currentSearchRef) &&
      !FROM_INNERPAGE
    ) {
      setIsFiltersFromUrl(true);
      dispatch(searchActions(null, 'FUNNEL_DOWN_SEARCH', 'CLEAR'));

      dispatch(searchActions(null, 'GENERAL_SEARCH', 'RESET_STATES'));

      dispatch(
        FiltersActions(
          { data: filtersFromUrlQuery.suburbs.length > 0 ? filtersFromUrlQuery : CURRENT_FILTERS },
          'GENERAL_FILTERS',
          'SET_ALL_FILTER',
        ),
      );

      const payload = formatRequest(filtersFromUrlQuery.suburbs.length > 0 ? filtersFromUrlQuery : CURRENT_FILTERS);

      //?This payload reference check will fallback if in some cases the search_reference did not register and a search request has been initiated, it will fallback to current screen pathname
      if (!payload?.reference) {
        payload.reference =
          history.location.pathname !== '/' && SEARCH_ROUTES.includes(history.location.pathname)
            ? history.location.pathname?.split('/')[1]
            : undefined;
      }

      const params = {
        query: `?filters=${paramsToSha1(payload)}`,
        data: payload,
      };
      if (isFiltersFromUrl) {
        dispatch(requestPOSTAPISearch(params, 'POST_PROPERTY_SEARCH'));
      }
    } else {
      setIsFiltersFromUrl(false);
    }
  }, [window.location.href]);

  return [isFiltersFromUrl, setIsFiltersFromUrl];
}
