import React, { useState, useRef } from 'react'
import Sticky from 'react-sticky-el'
import SatelliteIcon from '@material-ui/icons/Satellite'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

import PropertySearchMapView from '../../Web/Map/PropertySearchMapViewDrawing'
import Property from '../../Web/Property/PropertyV2'
import PropertyLoader from '../../Mobile/Property/PropertyLoader'
import SavedSearch from '../../Mobile/SavedSearch'

import { scrollToRef } from '../../Mobile/ScrollEvents'
import _ from 'lodash'

import {
  withStyles,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getAllFilters } from 'utils/search/selectors/selectors.search'
import { getReferenceFilters } from 'utils/search/selectors/selectors.search'
import DynamicIcons from '../DynamicIcons'
import SortByProperties from 'components/Common/SortByProperties'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {
    overflow: 'hidden',
  },
  columnWrapper: {
    padding: theme.spacing(3, 4),
    paddingBottom: 0,
    paddingLeft: 65,
  },
  cardColumn: {
    paddingTop: 10 + ' !important',
    paddingBottom: 0 + ' !important',
    overflowY: 'scroll',
    '& .sticky': {
      backgroundColor: '#f5f5f5',
      paddingBottom: '5px',
      zIndex: 5,
    },
  },
  propFilters: {
    padding: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mapColumn: {
    paddingTop: 0 + ' !important',
    paddingBottom: 0 + ' !important',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    // transform: 'translateY(-56px)'
  },
  mapWrapper: {
    overflow: 'hidden',
    position: 'relative',
  },
  propertyCards: {
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(1),
    width: '100%',
  },
  mapTypeToggle: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',

    '& .MuiButton-contained': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 12,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'capitalize',
      fontSize: 12,
    },
  },
  offMarketToggle: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    '& .MuiButton-contained': {
      fontSize: 13,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'left',
      borderRadius: theme.spacing(2),
      // backgroundColor: '#ffffff'
    },
  },
  checkWrapper: {
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },

  // Sticky
  stickyHolder: {
    height: '50px',
  },
})

const SearchMapView = (props) => {
  const {
    classes,
    property,
    setCurrentPropertySelected,
    filters,
    authentication,
    settings,
    setCurrentProperty,
    save_property_status,
    save_property_error,
    getSavedProperties,
    clearSkipTakeData,
    saveSkipTakeData,
    skipTakeData,
    pagination,
    suburbs,
    showSaveSearch,
    showSaveSearchModal,
    apiRequestSaveSearch,
    getRoutePath,
    handleFilter,

    checkLoginStatus,
    isFiltered,
    setSearchSortingFilters,
    showModalBottomOptions,
    showOptionBottomModal,
    handlePagination,
    handleMatchCount,
    apiRequestSearchProperty,
    apiRequestSaveProperty,
    isLoading,
    setOmcsStatus,
    omcsStatus,
    toggleSignUp,
    history,
    ...other
  } = props

  const [mapType, setMapType] = React.useState(false)
  const [offMarketToggle, setoffMarketToggle] = React.useState(false)
  const [topOffset, setTopOffset] = React.useState(230)
  const [comingSoonLength, setComingSoonLength] = React.useState(0)
  const [offMarketLength, setOffMarketLength] = React.useState(0)
  const [currentLength, setCurrentLength] = React.useState(0)

  const [activeProperty, setActiveProperty] = React.useState(null)
  const [visibleOffMarkets, setVisibleOffMarkets] = React.useState(null)
  const [suburbCoordinates, setSuburbCoordinates] = React.useState(null)

  const ALL_FILTERS = useSelector(getAllFilters)
  const REFERENCE_FILTERS = useSelector(getReferenceFilters)

  const [mapDrawEnabled, setMapDrawEnabled] = React.useState(false)
  const [refKey, setRefKey] = useState(0)

  const mapListTop = useRef(null)
  const mapWrapperRef = useRef(null)
  const executeMapListTop = (d) => scrollToRef(mapListTop, d) //add `false` as second parameter to scroll without transition.
  const BASE_RADIUS = 2000

  const [activePreviewProperty, setActivePreviewProperty] = useState(null)

  const { triggerAnalyticsEvent } = useGoogleEvents()

  React.useEffect(() => {
    if (save_property_status && save_property_status === 'success') {
      apiRequestGetSavedProperties()
    }
  }, [save_property_error, save_property_status])

  React.useEffect(() => {
    if (mapWrapperRef) {
      setTopOffset(mapWrapperRef.current.offsetTop)
    }
    document.querySelector('#scroll-container').style.overflowY = 'hidden'
    document.querySelector('#scroll-container').style.height = '100vh'
    return () => {
      document.querySelector('#scroll-container').style.height = 'auto'
    }
  }, [])

  /** GET Save Property API */
  async function apiRequestGetSavedProperties() {
    getSavedProperties()
  }
  const mapDataDefaults = {
    map: {
      /* coordinates: [-37.796300, 144.925120],
            center: { lat: -37.796300, lng: 144.925120 }, */
      comingsoon: [],
      offmarket: [],
      current: [],
      zoom: 13,
    },
  }
  const [mapData, setMapData] = useState(mapDataDefaults)

  const getRandomCoordinates = (lat, lng, r) => {
    const METERS_IN_DEGREE = 111_300
    const u = Math.random(),
      v = Math.random()

    let radius = parseFloat(r) / METERS_IN_DEGREE

    let w = radius * Math.sqrt(u)
    let t = 2 * Math.PI * v

    let x = (w * Math.cos(t)) / Math.cos(lat)
    let y = w * Math.sin(t)

    return { lat: y + lat, lng: x + lng } //returns an object of lat long pair
  }

  function iterateItems(obj, r) {
    const items = []

    for (const [key, details] of Object.entries(obj)) {
      if (r && !details.latitude && !details.longitude) {
        const randomCoordinate = getRandomCoordinates(
          ...mapData.map.coordinates,
          r ? r : BASE_RADIUS
        )
        items.push({ ...randomCoordinate, details })
      } else {
        if (
          details.latitude &&
          details.longitude &&
          details.propertyStatus !== 'offmarket'
        ) {
          items.push({
            ...{
              lat: parseFloat(details.latitude),
              lng: parseFloat(details.longitude),
            },
            details,
          })
        } else if (
          details.latitude &&
          details.longitude &&
          details.propertyStatus === 'offmarket'
        ) {
          const randomCoordinate = getRandomCoordinates(
            parseFloat(details.latitude),
            parseFloat(details.longitude),
            r ? r : BASE_RADIUS
          )
          items.push({ ...randomCoordinate, details })
        }
      }
    }

    return items
  }

  React.useEffect(() => {
    if (pagination?.propertiesFilters?.length > 0) {

      const notListedProperties = ['offmarket', 'notListed-xml', 'notListed-api', 'notListed-2dc'];
      const ALL_COMING_SOON = _.pickBy(pagination.propertiesFilters, (e) =>
        _.includes(['comingsoon'], e.propertyStatus)
      )
      const ALL_OFFMARKET = _.pickBy(pagination.propertiesFilters, (e) =>
        _.includes(notListedProperties, e.propertyStatus)
      )
      const ALL_CURRENT = _.pickBy(pagination.propertiesFilters, (e) =>
        _.includes(['current', 'On-Sale', 'rental', 'sold'], e.propertyStatus)
      )

      let i
      setComingSoonLength(Object.keys(ALL_COMING_SOON).length)
      setOffMarketLength(Object.keys(ALL_OFFMARKET).length)
      setCurrentLength(Object.keys(ALL_CURRENT).length)

      setMapData((prevData) => ({
        map: {
          ...prevData.map,
          center: suburbCoordinates,
          comingsoon: iterateItems(ALL_COMING_SOON),
          offmarket: iterateItems(ALL_OFFMARKET, 200),
          current: iterateItems(ALL_CURRENT),
          comingsoonlimit: comingSoonLength,
          offmarketlimit: offMarketLength,
          currentlimit: currentLength,
        },
      }))
    } else {
      setMapData(mapDataDefaults)
    }

    if (mapWrapperRef) {
      setTopOffset(mapWrapperRef.current.offsetTop)
    }
    setRefKey(refKey + 1)
  }, [pagination])

  React.useEffect(() => {
    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        maptype: mapType,
        drawing: mapDrawEnabled,
      },
    }))
  }, [mapType, mapDrawEnabled])

  React.useEffect(() => {
    if (suburbCoordinates) {
      setMapData((prevData) => ({
        map: {
          ...prevData.map,
          center: suburbCoordinates,
          coordinates: [suburbCoordinates.lat, suburbCoordinates.lng],
        },
      }))
    }
  }, [suburbCoordinates])

  React.useEffect(() => {
    if (ALL_FILTERS?.suburbs?.length > 0) {
      setSuburbCoordinates({
        lat: ALL_FILTERS?.suburbs[0]?.lat,
        lng: ALL_FILTERS?.suburbs[0]?.long,
      })
    }
  }, [ALL_FILTERS?.suburbs])

  const activePropertySetter = (property) => {
    setActiveProperty(property)
  }

  const offMarketToggleSetter = (value) => {
    setoffMarketToggle(value)
  }

  const visibleOffmarketItems = (items) => {
    //if(!visibleOffMarkets.some(v => v["property"] === item.property)) {
    setVisibleOffMarkets((prevData) => items)
    //}
  }

  const setMapCenter = (centerObj) => {
    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        center: centerObj,
        coordinates: [centerObj.lat, centerObj.lng],
      },
    }))
    setSuburbCoordinates({ lat: centerObj.lat, lng: centerObj.lng })
  }

  const type = 'search'

  const renderResultsText = () => {
    let propertyText = ' properties found'
    let suburbText = ''
    let around = REFERENCE_FILTERS?.include_nearby_suburbs ? 'and around ' : ''

    if (history.location.pathname === '/hush-hush') {
      propertyText = ' hush hush properties found'
    } else if (history.location.pathname === '/buy') {
      propertyText = ' On Sale properties found'
    } else {
      if (history.location.pathname === '/off-market') {
        propertyText = ' Off Market properties found'
      } else if (history.location.pathname === '/sold') {
        propertyText = ' Sold properties found'
      } else if (history.location.pathname === '/rent') {
        propertyText = ' Rental properties found'
      } else if (history.location.pathname === '/coming-soon') {
        propertyText = ' Coming Soon properties found'
      }
    }

    if (ALL_FILTERS?.suburbs) {
      if (ALL_FILTERS?.suburbs.length === 1) {
        suburbText += ALL_FILTERS?.suburbs[0].name + '.'
      } else {
        for (let i = 0; i < ALL_FILTERS?.suburbs.length; i++) {
          if (i === ALL_FILTERS?.suburbs.length - 1) {
            suburbText += ALL_FILTERS?.suburbs[i].name + '.'
          } else {
            suburbText += ALL_FILTERS?.suburbs[i].name + ' - '
          }
        }
      }
    }

    return (
      <Typography variant="body2" component="h2">
        {pagination &&
        pagination.propertiesFilters &&
        pagination.propertiesFilters.length > 0
          ? 'Showing ' + pagination.propertiesFilters.length + ' of '
          : ''}
        {pagination.propertiesFilters.length <= 0
          ? 0 + propertyText
          : pagination?.totalResults
          ? pagination?.totalResults + propertyText
          : 0 + propertyText}
        {/* {ALL_FILTERS?.suburbs ? ' in and around ' + suburbText : ''} */}
        {ALL_FILTERS?.suburbs?.length > 0
          ? ` in ${around} ${ALL_FILTERS?.suburbs?.length} suburb${
              ALL_FILTERS?.suburbs?.length > 1 ? 's' : ''
            } `
          : ''}
      </Typography>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4} className={classes.columnWrapper}>
        <Grid
          item
          xs={6}
          className={classes.cardColumn}
          id="cards-scroll-column"
          style={{ height: window.innerHeight - (topOffset + 100) }}
        >
          <div ref={mapListTop} className={classes.topAnchor}></div>
          {/* <SavedSearch
            showSaveSearch={showSaveSearch}
            filters={filters}
            showSaveSearchModal={showSaveSearchModal}
            apiRequestSaveSearch={apiRequestSaveSearch}
            path={getRoutePath()}
          /> */}

          <Sticky
            className={classes.stickyHolder}
            scrollElement={`.${classes.cardColumn}`}
            topOffset={-50}
          >
            {!isLoading ? (
              <Box className={classes.propFilters}>
                <Box paddingRight="50px">{renderResultsText()}</Box>
                <SortByProperties />
              </Box>
            ) : null}
          </Sticky>

          <Property
            authentication={authentication}
            settings={settings}
            toggleSignUp={toggleSignUp}
            setCurrentProperty={setCurrentProperty}
            properties={pagination}
            isFiltered={isFiltered}
            isLoading={isLoading}
            currentScreen={getRoutePath()}
            apiRequestSaveProperty={apiRequestSaveProperty}
            handlePagination={handlePagination}
            handleMatchCount={() => {}}
            listingType={getRoutePath()}
            apiRequestSearchProperty={apiRequestSearchProperty}
            skipTakeData={skipTakeData}
            origin={'map-view'}
            omcsFullyLoaded={props.omcsFullyLoaded}
            cardType={'grid'}
            setActivePreviewProperty={setActivePreviewProperty}
            activePreviewProperty={activePreviewProperty}
            history={history}
          />
          {pagination?.propertiesFilters?.length > 0 && (
            <PropertyLoader executeTopScroll={executeMapListTop} isBackTop={true} />
          )}
        </Grid>
        <Grid item xs={6} className={classes.mapColumn}>
          <div
            className={classes.mapWrapper}
            ref={mapWrapperRef}
            style={{
              height: window.innerHeight - (topOffset + 100),
              paddingBottom: '16px',
            }}
          >
            <PropertySearchMapView
              style={{ height: '674px', paddingBottom: '16px' }}
              data={{
                mode: 'map',
                title: 'Map View',
                ...mapData,
                address: '',
                radius: BASE_RADIUS,
                filteroffmarket: offMarketToggle,
              }}
              key={refKey}
              mapDrawEnabled={mapDrawEnabled}
              setMapDrawEnabled={setMapDrawEnabled}
              activePropertySetter={activePropertySetter}
              offMarketToggleSetter={offMarketToggleSetter}
              visibleOffmarketItems={visibleOffmarketItems}
              setCurrentProperty={setCurrentProperty}
              currentPath={getRoutePath()}
              enableSatellite={mapType}
              setMapCenter={setMapCenter}
              activePreviewProperty={activePreviewProperty}
              suburbs={(filters && filters.suburbs) ?? null}
              authentication={authentication}
              apiRequestSaveProperty={apiRequestSaveProperty}
              toggleSignUp={toggleSignUp}
              settings={settings}
            />

            {offMarketLength > 0 && (
              <div className={classes.offMarketToggle}>
                <Button
                  variant="contained"
                  disableElevation
                  color={offMarketToggle ? 'secondary' : 'inherit'}
                  style={{ backgroundColor: !offMarketToggle ? '#ffffff' : '' }}
                  onClick={() => {
                    setoffMarketToggle(!offMarketToggle)
                    setActiveProperty(null)
                    triggerAnalyticsEvent({
                      event: 'results_actions',
                      eventFrom: 'Web',
                      actionScope: 'search_results_map',
                      actionName: 'Search Results Map',
                      actionTarget:
                        'Set Offmarket toggle to ' +
                        (!offMarketToggle ? 'Hide' : 'Show'),
                      actionReference: 'results',

                      results_total: pagination?.totalResults,
                    })
                  }}
                >
                  {!offMarketToggle && <CheckBoxOutlineBlankIcon fontSize="small" />}
                  <span className={classes.checkWrapper}>
                    {offMarketToggle && (
                      <CheckBoxIcon
                        fontSize="small"
                        color={offMarketToggle ? 'primary' : 'inherit'}
                      />
                    )}
                  </span>

                  <span>{offMarketLength} Off Market</span>
                </Button>
              </div>
            )}
            <div className={classes.propertyCards}>
              <div style={{ position: 'relative' }}>
                <div className={classes.mapTypeToggle}>
                  {/*
                  * ?This section is intentionally commented to temporarily hide the Draw feature on the map
                  <Button
                    variant="contained"
                    disableElevation
                    color={mapDrawEnabled ? 'primary' : 'inherit'}
                    onClick={()=>{
                      setMapDrawEnabled(!mapDrawEnabled)
                    }}
                >
                  {
                      !mapDrawEnabled ? <GestureIcon fontSize="small" /> : <CloseIcon fontSize="small" />
                  }
                  <span>{ !mapDrawEnabled ? 'Draw' : 'Done'}</span>
                </Button> */}

                  <Button
                    variant="contained"
                    disableElevation
                    color={mapType ? 'primary' : 'inherit'}
                    onClick={() => {
                      setMapType(!mapType)
                    }}
                  >
                    <SatelliteIcon fontSize="small" />
                    <span>Satellite</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(SearchMapView)
