import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  Fragment,
} from 'react'
import moment from 'moment'
import getDayOfYear from 'date-fns/getDayOfYear'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import HeartIcon from '@material-ui/icons/FavoriteBorder'
import HeartFullIcon from '@material-ui/icons/Favorite'
import CheckIcon from '@material-ui/icons/Check'
import InfoIcon from '@material-ui/icons/Info'
import DeleteIcon from '@material-ui/icons/Delete'
import Image from 'material-ui-image'

import AgentBanner from './AgentBanner'
import PropertyInfo from './PropertyInfo'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import styled from 'styled-components'
import _ from 'lodash'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'
import ReloadIcon from '../../../assets/images/reload.svg'
import NotificationDialog from '../CustomDialogs/NotificationDialog'

import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  DEFAULT_AGENCIES,
  cloudinaryCardTransforms,
  cloudinaryImageHeightSmall,
} from '../../../constants/constants'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'

/** api redux request */
import { requestGetPropertySave } from '../../../store/api'
import { logoutRequest } from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { convertTagToString, extractTagsToDisplay } from 'utils/propertyTagsUtils'

import {
  withStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
  Grid,
  Tooltip,
  Box,
} from '@material-ui/core'
import useFilters from 'utils/customHooks/useFilters'
import { useRouterChange } from 'utils/router.utils'

import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const CarouselWrapper = styled.div`
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  position: relative;
`

const OverlayReload = styled.div`
  position: absolute;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    display: block;
    text-align: center;
    text-decoration: none;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    pointer-events: none;
    width: 2em !important;
  }
  p {
    color: #fff;
    pointer-events: none;
    margin-top: 5px;
  }
`

const DotsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  .BrainhubCarousel__dots {
    .BrainhubCarousel__dot {
      padding: 20px 5px;
      &:before {
        background: #fff;
        width: 7px;
        height: 7px;
      }
    }
  }
`

const styles = (theme) => ({
  paddedRoot: {
    padding: 40,
  },
  cardFull: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    // borderRadius: 0,
    minHeight: 364,
    // minHeight:435,
    width: '100%',
    backgroundColor: '#FFF',
  },
  cardPadded: {
    width: '100%',
    maxHeight: '364',
    backgroundColor: '#FFF',
  },
  // propertyCards: {
  //   [theme.breakpoints.down(1024)]: {
  //     flexGrow: '0',
  //     maxWidth: '100%',
  //     flexBasis: '100%',
  //   },
  // },
  grid: {
    // borderRadius: 0,
    // minHeight:435,
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    transition: 'all 0.03s ease',
    '&:hover': {
      boxShadow:
        '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)',
    },
  },
  cardPaddedSwipe: {
    // margin: 40,
    // width: window.innerWidth - (theme.spacing(3) * 2),
    // height: '35.125em',
    width: 350,
    // minHeight: 425,
    backgroundColor: '#FFF',
    fontSize: '12px',
    paddingBottom: 10,

    '@media (max-width: 1024px)': {
      minHeight: 400,
    },
  },
  media: {
    width: '100%',
    height: '210px',
    objectFit: 'cover !important',
  },
  favoriteContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
  deleteIconActive: {
    color: '#FFFFFF',
  },

  tagContainer: {
    position: 'relative',
    cursor: 'pointer',
    pointerEvents: 'auto',
    '& > button + div': {
      paddingTop: 'calc(50%) !important',
    },
  },
  tagTypeText: {
    color: '#FFF',
    fontSize: 12, // previously 1.25em

    [theme.breakpoints.down(1400)]: {
      fontSize: '11px',
    },
  },
  tagTypeAuction: {
    backgroundColor: 'rgba(6, 18, 82, 0.8)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 20px',
  },
  tagTypeComingSoon: {
    width: '110px',
    backgroundColor: 'rgba(253, 189, 38, 0.9)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.375, 2),

    [theme.breakpoints.down(1400)]: {
      width: '105px',
      height: '18px',
    },
  },
  tagTypeOffMarket: {
    width: '110px',
    backgroundColor: '#F50057',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.375, 2),

    [theme.breakpoints.down(1400)]: {
      width: '105px',
      height: '18px',
    },
  },
  textLabelCloseTo: {
    fontSize: '0.875em',
    fontWeight: 'bold',
    fontStyle: 'normal',
  },
  chipText: {
    fontSize: 10,
  },
  chipIcon: {
    width: 10,
    height: 10,
  },
  dots: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    '.BrainhubCarousel__dot': {
      padding: '20px 5px',
    },
  },
  rowContainerCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  rowContainerEven: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  chipContainer: {
    minWidth: '3.750em',
    height: '1.300em',
    paddingRight: '0.425em',
    paddingLeft: '0.425em',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    border: ' 0.5px solid #35C0CA',
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    marginBottom: '0.425em',
    marginRight: '0.125em',
  },
  infoIconContainer: {
    zIndex: 100,
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 0,
    cursor: 'pointer',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    backgroundColor: '#ffffff',
    // transition: 'opacity 0.3s ease',
    borderRadius: 20,
    fontSize: 12,
    padding: '3px 5px',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
  },
  infoIcon: {
    width: 30,
    height: 30,
    color: '#57bdc7',
  },
  agencyLogoOverlay: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(1),
    minWidth: 90,
    maxWidth: 150,
    '& > img': {
      maxWidth: 135,
    },
  },
  cardContentBase: {
    padding: theme.spacing(1.2),
  },
  newListingLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: theme.spacing(0.375, 2),

    // maxWidth: 112,
    // minWidth: 112,
    position: 'absolute',
    bottom: 0,
    left: 0,
    // backgroundColor: '#ef6631',
    textAlign: 'center',
    color: '#fff',
    '& > p': {
      fontWeight: 300,
      lineHeight: '1.55',
      padding: '2px 8px',
      marginRight: '4px',
    },

    [theme.breakpoints.down(1400)]: {
      height: '15px',
    },
  },
  underOfferListingLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.375, 2),

    // maxWidth: 112,
    // minWidth: 112,
    position: 'absolute',
    bottom: 25,
    left: 5,
    borderRadius: 10,
    backgroundColor: '#ffffff',
    textAlign: 'center',
    color: '#000000',
    padding: '1px 16px',
    '& > p': {
      fontWeight: 300,
      lineHeight: '1.55',
      color: '#000000',
    },

    [theme.breakpoints.down(1400)]: {
      height: '15px',
    },
  },
  removeFromFavoriteList: {
    display: 'none',
  },
  addFavoritesContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: 65,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
})

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 20px)',
  width: 60,
  height: 60,
  cursor: 'pointer',
}

const iconStyles = {
  width: '100%',
  height: '100%',
}

const logoObject = LOGO_OBJECT

const getAgencyLogo = (agencyName) => {
  if (
    logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
    logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
  ) {
    return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
  } else {
    return LogoPlaceholder
  }
}

const handleShowBanner = (subscriptionType) => {
  const mutatedSubscriptionType = subscriptionType?.toLowerCase()

  return mutatedSubscriptionType === 'entry' ? false : true
}

const PropertyCard = forwardRef((props, ref) => {
  const {
    classes,
    isFullWidth,
    properties,
    property,

    authentication,
    settings,
    toggleSignUp,
    setCurrentProperty,
    apiRequestSaveProperty,
    apiPutRemoveDislikedProperty,
    cardType,
    addLikedDrillDown,
    addUnlikedDrillDown,
    listingType,
    key,
    logoutUser,
    setActivePreviewProperty,
    activePreviewProperty,
    editFavoriteStatus,
  } = props

  const [routerChange] = useRouterChange()

  let atFavoritesRoute = false
  let atDislikesRoute = false

  if (typeof window !== 'undefined') {
    const favoritesUrl = window.location.pathname.split('/')[1]
    const DislikesUrl = window.location.pathname.split('/')[1]
    if (favoritesUrl === 'favourites') {
      atFavoritesRoute = true
    }
    if (DislikesUrl === 'disliked-properties') {
      atDislikesRoute = true
    }
  }

  const [getFilter, setFilter] = useFilters()
  const NEARBY_FILTERS = getFilter('nearby_filters')
  //console.log('NEARBY_FILTERS', NEARBY_FILTERS)

  const [value, setValue] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const buxtonLogo = 'https://buxton.com.au/static/img/buxton-logo.6b0f8172347d.svg'
  // const [propertImages, setPropertyImages] = useState(_.compact(properties.images));
  const [imgError, setImgError] = useState(false)
  const [carouselOffset, setCarouselOffset] = useState(3)
  const [heartClicked, setHeartClicked] = useState(false)
  const [heartActionError, setHeartActionError] = useState(false)
  const handleCarouselChange = (e) => {
    setCarouselOffset(e + 3)
  }

  const [galleryImages, setGalleryImages] = useState(null)
  const { getPropertyData, triggerAnalyticsEvent } = useGoogleEvents()

  // const sampleLocation = useLocation()

  // console.log('sampleLocation', sampleLocation.pathname)

  const showNewListingLabel = () => {
    const publishedDate = new Date(properties.createdAt)
    const publishedDayOfYear = getDayOfYear(publishedDate)
    const publishedYear = publishedDate.getFullYear()
    const currentDayOfYear = getDayOfYear(new Date())
    const currentYear = new Date().getFullYear()

    if (publishedYear >= currentYear) {
      let newTagDuration

      switch (properties.subscriptionType?.toLowerCase()) {
        case 'elite':
        case 'executive':
        case 'platinum':
        case 'premium':
          newTagDuration = 7
          break

        case 'extended':
        case 'standard':
          newTagDuration = 3
          break

        default:
          newTagDuration = -1
      }

      if (publishedDayOfYear + newTagDuration >= currentDayOfYear) {
        return true
      }
      return false
    } else {
      return false
    }
  }

  const MPP = useSelector(getMpp)
  const [dislikeClicked, setDislikedClicked] = useState(false)
  const [isDisliked, setIsDisliked] = useState(false)

  let likes = MPP?.favourites?.data?.likes
  let dislikes = MPP?.favourites?.data?.dislikes

  const [isFavorite, setIsfavorite] = useState()
  const [showTooltip, setShowTooltip] = useState(true);

  useEffect(() => {
    if (properties && likes) {
      likes?.includes(properties?.property) ? setIsfavorite(true) : setIsfavorite(false)
    }
    if (properties && dislikes) {
      dislikes?.includes(properties?.property)
        ? setIsDisliked(true)
        : setIsDisliked(false)
    }
  }, [likes, dislikes, properties])

  useEffect(() => {
    if (properties && properties.images && properties.images.length > 0) {
      let gallery_array = []
      if (properties.cloudinaryImages && properties.cloudinaryImages.length > 0) {
        properties.cloudinaryImages.map((item, idx) => {
          if (item) {
            const file =
              item?.path +
              cloudinaryCardTransforms +
              ',' +
              cloudinaryImageHeightSmall +
              '/' +
              item?.file
            gallery_array.push(file)
          } else {
            gallery_array = _.compact(properties.images)
          }
        })
      } else {
        gallery_array = _.compact(properties.images)
      }

      setGalleryImages(gallery_array)
    }
  }, [properties])

  const onChange = (value) => {
    setValue(value)
  }

  useImperativeHandle(ref, () => ({
    /**
     * set property on card swipe selected */
    selectProperty(params) {
      /**
       * Add to Drill Down Property */
      property.liked_drill_down_properties.push(params)
      addLikedDrillDown(property.liked_drill_down_properties)

      /**
       * Set current property */
      setCurrentProperty(params)
      routerChange('/' + listingType + '/' + params.property, {
        previous_screen: listingType,
      })
    },
  }))

  useEffect(() => {
    if (
      MPP?.error &&
      MPP?.status === 'mpp/PUT_MPP_FAVOURITES_FAILED' &&
      heartClicked &&
      authentication &&
      authentication.user_status === 'logged'
    ) {
      setHeartActionError(true)
      setHeartClicked(false)
    }
    if (!MPP?.error && MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      setHeartActionError(false)
      setHeartClicked(false)
    }
  }, [MPP, heartClicked, authentication])

  const AgencyLogoOverlay = (props) => {
    return (
      <div className={classes.agencyLogoOverlay}>
        {/*        <Image
          src={props.image}
          aspectRatio={(16/9)}
          disableSpinner
          color={'transparent'}
        /> */}

        <img src={props.image} />
      </div>
    )
  }

  const showUpdatedListingLabel = () => {
    const publishedDate = new Date(properties.publishDate)
    const publishedDayOfYear = getDayOfYear(publishedDate)
    const publishedYear = publishedDate.getFullYear()
    const currentDayOfYear = getDayOfYear(new Date())
    const currentYear = new Date().getFullYear()

    if (
      publishedYear >= currentYear &&
      properties.subscriptionType?.toLowerCase() !== 'entry'
    ) {
      if (publishedDayOfYear + 3 >= currentDayOfYear) {
        return true
      }
      return false
    } else {
      return false
    }
  }

  function selectProperty(willScroll) {
    if (!isSwiping) {
      setCurrentProperty(properties)
      triggerAnalyticsEvent({
        event: 'property_actions',
        eventFrom: 'Web',
        actionScope: 'property_card_click',
        actionName: 'Property Card',
        actionTarget: 'Viewed Property',
        actionReference: 'property',
      })
      if (willScroll) {
        routerChange('/' + listingType + '/' + properties.property, {
          scrollToAgent: true,
          previous_screen: props.listingType,
        })
      } else {
        routerChange('/' + listingType + '/' + properties.property, {
          previous_screen: props.listingType,
        })
      }
    }
  }

  function renderTypeTag(type, property) {
    let propertyStatus = type.toLowerCase().replace(/[^\w\s]/gi, '')
    let isForRent = property.forRent ? true : false
    let conditionCheck = propertyStatus + ':' + isForRent
    const soldDate = property.soldDate
      ? moment(property.soldDate).format('Do MMMM YYYY')
      : ''

    const dateNow = new Date()
    // Reset time to 0 to show todays auction for the whole day
    dateNow.setHours(0, 0, 0, 0)

    let auctionDateData = {
      showDate: false,
      date: '',
    }

    if (property.auctionDate) {
      const auctionDate = new Date(property.auctionDate)

      if (auctionDate > dateNow) {
        auctionDateData = {
          date: moment(auctionDate).format('Do MMMM YYYY'),
          showDate: true,
        }
      }
    }

    switch (conditionCheck) {
      case 'leased:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              Leased
            </Typography>
          </div>
        )
      case 'onsale:false':
        return (
          auctionDateData.showDate && (
            <div className={classes.tagTypeAuction}>
              <Typography
                className={classes.tagTypeText}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Auction ' + auctionDateData.date}
              </Typography>
            </div>
          )
        )
      case 'current:false':
        return (
          auctionDateData.showDate && (
            <div className={classes.tagTypeAuction}>
              <Typography
                className={classes.tagTypeText}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Auction ' + auctionDateData.date}
              </Typography>
            </div>
          )
        )
      case 'sold:false':
        return (
          soldDate && (
            <div className={classes.tagTypeAuction}>
              <Typography
                className={classes.tagTypeText}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Sold ' + soldDate}
              </Typography>
            </div>
          )
        )
      case 'comingsoon:false':
        return (
          <div className={classes.tagTypeComingSoon}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              Coming Soon
            </Typography>
          </div>
        )
      case 'offmarket:false':
        return (
          <div className={classes.tagTypeOffMarket}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              Off Market
            </Typography>
          </div>
        )
    }
  }

  function checkLoginStatus() {
    setHeartClicked(true)
    if (authentication && authentication.user_status !== 'logged') {
      // toggleSignUp(!settings.showSignUp);
      routerChange('/login', {
        origin: props?.history?.location?.pathname ?? window.location.pathname,
      })
    } else {
      /** User Logged */
      /*       if (editFavoriteStatus) {
        console.debug('editFavoriteStatus', properties.property)
        editFavoriteStatus(properties.property)
      } */
      setIsfavorite((prev) => !prev)
      apiRequestSaveProperty(properties.property)
      setShowTooltip(false)
      setTimeout(() => {
        setShowTooltip(true)
     }, 500)
    }
  }

  function removeDislikedProperty() {
    setDislikedClicked(true)
    if (authentication && authentication.user_status !== 'logged') {
      // toggleSignUp(!settings.showSignUp);
      routerChange('/login', {
        origin: props?.history?.location?.pathname ?? window.location.pathname,
      })
    } else {
      /** User Logged */
      setIsDisliked((prev) => !prev)
      apiPutRemoveDislikedProperty(properties.property)
      triggerAnalyticsEvent({
        event: 'property_actions',
        eventFrom: 'Web',
        actionScope: 'property_card_remove_from_dislikes',
        actionName: 'Property Card',
        actionTarget: 'Remove from Dislikes',
        actionReference: 'property',
        ...getPropertyData(properties),
      })
    }
  }

  function addToFavoriteFromDislike() {
    setHeartClicked(true)
    if (authentication && authentication.user_status !== 'logged') {
      // toggleSignUp(!settings.showSignUp);
      routerChange('/login', {
        origin: props?.history?.location?.pathname ?? window.location.pathname,
      })
    } else {
      /** User Logged */
      /*       if (editFavoriteStatus) {
        console.debug('editFavoriteStatus', properties.property)
        editFavoriteStatus(properties.property)
      } */
      setIsDisliked((prev) => !prev)
      apiRequestSaveProperty(properties.property)
      triggerAnalyticsEvent({
        event: 'property_actions',
        eventFrom: 'Web',
        actionScope: 'property_card_add_to_favourites',
        actionName: 'Property Card',
        actionTarget: 'Add to Likes',
        actionReference: 'property',
        ...getPropertyData(properties),
      })
    }
  }

  const reloadImages = () => {
    let pi = Object.assign([], galleryImages)
    let newPi = []
    _.each(pi, (item, index) => {
      if (item.split('?').length) {
        newPi.push(item.split('?')[0] + '?t=' + Date.now())
      } else {
        newPi.push(item + '?t=' + Date.now())
      }
    })
    setGalleryImages(newPi)
    setImgError(false)
  }

  function renderForListCard() {
    return (
      <div className={isFullWidth ? '' : classes.paddedRoot}>
        <Card
          elevation={1}
          className={isFullWidth ? classes.cardFull : classes.cardPadded}
        >
          <div className={classes.tagContainer}>
            {properties &&
            properties.propertyStatus !== 'offmarket' &&
            galleryImages &&
            galleryImages.length > 1 ? (
              <CarouselWrapper>
                {imgError && (
                  <OverlayReload>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        reloadImages()
                      }}
                    >
                      <img src={ReloadIcon} />
                      <p>Load image</p>
                    </a>
                  </OverlayReload>
                )}
                <Carousel
                  showArrows={true}
                  showIndicators={false}
                  showStatus={false}
                  infiniteLoop={false}
                  showThumbs={false}
                  useKeyboardArrows={false}
                  autoPlay={false}
                  swipeable={true}
                  emulateTouch={true}
                  onChange={handleCarouselChange}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <IconButton
                        onClick={(e) => {
                          onClickHandler(e)
                          triggerAnalyticsEvent({
                            event: 'property_actions',
                            eventFrom: 'Web',
                            actionScope: 'property_card_gallery_previous',
                            actionName: 'Property Card',
                            actionTarget: 'Gallery clicked previous',
                            actionReference: 'property',
                          })
                        }}
                        title={label}
                        style={{ ...arrowStyles, left: 5, color: '#fff' }}
                      >
                        <ChevronLeftIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <IconButton
                        onClick={(e) => {
                          onClickHandler(e)
                          triggerAnalyticsEvent({
                            event: 'property_actions',
                            eventFrom: 'Web',
                            actionScope: 'property_card_gallery_next',
                            actionName: 'Property Card',
                            actionTarget: 'Gallery clicked next',
                            actionReference: 'property',
                          })
                        }}
                        title={label}
                        style={{ ...arrowStyles, right: 5, color: '#fff' }}
                      >
                        <ChevronRightIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  onSwipeMove={() => {
                    if (!isSwiping) {
                      setIsSwiping(true)
                    }
                  }}
                  onSwipeEnd={() => {
                    if (isSwiping) {
                      let st = setTimeout(() => {
                        setIsSwiping(false)
                        clearTimeout(st)
                      }, 50)
                    }
                  }}
                >
                  {!imgError ? (
                    galleryImages.map((img, index) => {
                      const indexRef = index + 1
                      if (img) {
                        return (
                          <Fragment key={index}>
                            <Image
                              style={{ width: '100%' }}
                              key={index}
                              className={classes.media}
                              src={
                                indexRef <= carouselOffset
                                  ? propertyConfigSetter('image-banner-card', {
                                      type: properties.propertyStatus,
                                      image: img,
                                    })
                                  : null
                              }
                              onClick={() => selectProperty()}
                              aspectRatio={16 / 9}
                              disableSpinner
                              onLoad={() => {
                                setImgError(false)
                              }}
                              onError={() => {
                                setImgError(true)
                              }}
                            />
                          </Fragment>
                        )
                      }
                    })
                  ) : (
                    <>
                      <Image
                        style={{ width: '100%' }}
                        className={classes.media}
                        src={propertyConfigSetter('image-banner-card', {
                          type: properties.propertyStatus,
                          image: null,
                        })}
                        onClick={() => selectProperty()}
                        aspectRatio={16 / 9}
                        disableSpinner
                      />
                    </>
                  )}
                </Carousel>
              </CarouselWrapper>
            ) : properties &&
              properties.propertyStatus === 'offmarket' &&
              galleryImages &&
              galleryImages.length > 0 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: properties.propertyStatus,
                  image: galleryImages[0],
                })}
                onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : properties && galleryImages && galleryImages.length === 1 ? (
              <Image
                style={{ width: '100%' }}
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: properties.propertyStatus,
                  image: galleryImages[0],
                })}
                onClick={() => selectProperty()}
                aspectRatio={16 / 9}
                disableSpinner
              />
            ) : (
              <div></div>
            )}
            {properties.propertyStatus &&
              renderTypeTag(properties.propertyStatus, properties)}

            {authentication && authentication.user_status === 'logged' && isFavorite ? (
              <Tooltip title="Remove from favorite">
                <IconButton
                  className={classes.favoriteContainer}
                  onClick={() => checkLoginStatus()}
                >
                  <HeartFullIcon className={classes.favoriteIconActive} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Add to favorite">
                <IconButton
                  className={classes.favoriteContainer}
                  onClick={() => checkLoginStatus()}
                >
                  <HeartIcon className={classes.favoriteIcon} />
                </IconButton>
              </Tooltip>
            )}
            {/* <AgencyLogoOverlay image={properties.agency && properties.agency.logoUrl ? properties.agency.logoUrl : getAgencyLogo(properties.reaAgencyId || properties.agency.reaAgencyId)}/> */}
          </div>
          {
            <AgentBanner
              type={'search'}
              properties={properties}
              {...propertyConfigSetter('agent', properties.subscriptionType)}
            />
          }
          <CardContent className={classes.cardContentBase}>
            <PropertyInfo
              type={'search'}
              properties={properties}
              selectProperty={selectProperty}
              matchCount={props.handleMatchCount(properties)}
              listingType={listingType}
              filters={props.filters}
            />
          </CardContent>
        </Card>
        {heartActionError && (
          <NotificationDialog
            isOpen={heartActionError}
            closeCallback={() => {
              setHeartActionError(false)
            }}
            title={'Error'}
            text={
              MPP?.error?.status === 401 || MPP?.error?.status === 400
                ? 'Your session has expired or invalid. You will be asked to re-login after few seconds.'
                : 'Error encountered making this property favorite.'
            }
            showLogo={true}
            align={'center'}
          />
        )}
      </div>
    )
  }

  function renderForGridCard() {
    const columnCount =
      listingType === 'disliked-properties' ||
      listingType === 'liked-properties' ||
      listingType === 'favourites'
        ? 4
        : 6

    return (
      <Grid
        item
        xs={12}
        md={6}
        lg={columnCount}
        className={
          (!isFavorite && atFavoritesRoute) || (!isDisliked && atDislikesRoute)
            ? classes.removeFromFavoriteList
            : ''
        }
      >
        <Card
          elevation={1}
          className={classes.grid}
          onMouseEnter={() => {
            if (setActivePreviewProperty) {
              setActivePreviewProperty(properties.property)
            }
          }}
          onMouseLeave={() => {
            if (setActivePreviewProperty) {
              setActivePreviewProperty(null)
            }
          }}
        >
          <div className={classes.tagContainer}>
            {properties && galleryImages && galleryImages.length > 0 ? (
              <CarouselWrapper>
                {imgError && (
                  <OverlayReload>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        reloadImages()
                      }}
                    >
                      <img src={ReloadIcon} />
                      <p>Load image</p>
                    </a>
                  </OverlayReload>
                )}
                <Carousel
                  showArrows={true}
                  showIndicators={false}
                  showStatus={false}
                  infiniteLoop={false}
                  showThumbs={false}
                  useKeyboardArrows={false}
                  autoPlay={false}
                  swipeable={true}
                  emulateTouch={true}
                  onChange={handleCarouselChange}
                  renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                      <IconButton
                        onClick={(e) => {
                          onClickHandler(e)
                          triggerAnalyticsEvent({
                            event: 'property_actions',
                            eventFrom: 'Web',
                            actionScope: 'property_card_gallery_previous',
                            actionName: 'Property Card',
                            actionTarget: 'Gallery clicked previous',
                            actionReference: 'property',
                          })
                        }}
                        title={label}
                        style={{ ...arrowStyles, left: 5, color: '#fff' }}
                      >
                        <ChevronLeftIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                      <IconButton
                        onClick={(e) => {
                          onClickHandler(e)
                          triggerAnalyticsEvent({
                            event: 'property_actions',
                            eventFrom: 'Web',
                            actionScope: 'property_card_gallery_next',
                            actionName: 'Property Card',
                            actionTarget: 'Gallery clicked next',
                            actionReference: 'property',
                          })
                        }}
                        title={label}
                        style={{ ...arrowStyles, right: 5, color: '#fff' }}
                      >
                        <ChevronRightIcon style={{ ...iconStyles }} />
                      </IconButton>
                    )
                  }
                  onSwipeMove={() => {
                    if (!isSwiping) {
                      setIsSwiping(true)
                    }
                  }}
                  onSwipeEnd={() => {
                    if (isSwiping) {
                      let st = setTimeout(() => {
                        setIsSwiping(false)
                        clearTimeout(st)
                      }, 50)
                    }
                  }}
                >
                  {!imgError ? (
                    galleryImages.map((img, index) => {
                      const indexRef = index + 1
                      if (img) {
                        return (
                          <Fragment key={index}>
                            <Image
                              style={{
                                height: handleShowBanner(properties.subscriptionType)
                                  ? '12vw'
                                  : '14vw',
                                minHeight: '105px',
                              }}
                              key={index}
                              className={classes.media}
                              src={
                                indexRef <= carouselOffset
                                  ? propertyConfigSetter('image-banner-card', {
                                      type: properties.propertyStatus,
                                      image: img,
                                    })
                                  : null
                              }
                              onClick={() => selectProperty()}
                              aspectRatio={16 / 9}
                              disableSpinner
                              onLoad={() => {
                                setImgError(false)
                              }}
                              onError={() => {
                                setImgError(true)
                              }}
                            />
                          </Fragment>
                        )
                      }
                    })
                  ) : (
                    <>
                      <Image
                        style={{ width: '100%' }}
                        className={classes.media}
                        src={propertyConfigSetter('image-banner-card', {
                          type: properties.propertyStatus,
                          image: null,
                        })}
                        onClick={() => selectProperty()}
                        aspectRatio={16 / 9}
                        disableSpinner
                      />
                    </>
                  )}
                </Carousel>
              </CarouselWrapper>
            ) : (
              <Image
                className={classes.media}
                src={propertyConfigSetter('image-banner-card', {
                  type: properties.propertyStatus,
                  image: null,
                })}
                onClick={() =>
                  properties.propertyStatus === 'comingsoon' ||
                  properties.propertyStatus === 'offmarket'
                    ? selectProperty(true)
                    : selectProperty()
                }
                aspectRatio={16 / 9}
                disableSpinner
              />
            )}

            {properties.propertyStatus &&
              renderTypeTag(properties.propertyStatus, properties)}

            {properties ? (
              <div className={classes.newListingLabel}>
                {showNewListingLabel() && (
                  <Typography
                    className={classes.tagTypeText}
                    variant="caption"
                    color="textSecondary"
                    component="p"
                    style={{
                      background: '#ef6631',
                    }}
                  >
                    New
                  </Typography>
                )}
                {showUpdatedListingLabel() && !showNewListingLabel() && (
                  <Typography
                    className={classes.tagTypeText}
                    variant="caption"
                    color="textSecondary"
                    component="p"
                    style={{
                      background: '#ef6631',
                    }}
                  >
                    Updated
                  </Typography>
                )}

                {properties?.underOffer && (
                  <Typography
                    className={classes.tagTypeText}
                    variant="caption"
                    color="textSecondary"
                    component="p"
                    style={{
                      background: '#ffffff',
                      color: '#000000',
                    }}
                  >
                    Under Offer
                  </Typography>
                )}
              </div>
            ) : null}

            {/*             {authentication && authentication.user_status === 'logged' && isFavorite ? (
              <Tooltip title="Remove from favorite">
                <IconButton
                  className={classes.favoriteContainer}
                  onClick={() => checkLoginStatus()}
                >
                  <HeartFullIcon className={classes.favoriteIconActive} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Add to favorite">
                <IconButton
                  className={classes.favoriteContainer}
                  onClick={() => checkLoginStatus()}
                >
                  <HeartIcon className={classes.favoriteIcon} />
                </IconButton>
              </Tooltip>
            )} */}
            {(() => {
              if (
                authentication &&
                authentication.user_status === 'logged' &&
                isFavorite &&
                !atFavoritesRoute &&
                !atDislikesRoute
              ) {
                return (
                  <Tooltip title="Remove from favorite">
                    <IconButton
                      className={classes.favoriteContainer}
                      onClick={() => checkLoginStatus()}
                    >
                      <HeartFullIcon className={classes.favoriteIconActive} />
                    </IconButton>
                  </Tooltip>
                )
              } else if (
                authentication &&
                authentication.user_status === 'logged' &&
                isFavorite &&
                atFavoritesRoute &&
                !atDislikesRoute
              ) {
                return (
                  <Tooltip title="Remove from favorite">
                    <IconButton
                      className={classes.favoriteContainer}
                      onClick={() => checkLoginStatus()}
                    >
                      <DeleteIcon className={classes.deleteIconActive} />
                    </IconButton>
                  </Tooltip>
                )
              } else if (
                authentication &&
                authentication.user_status === 'logged' &&
                !isFavorite &&
                isDisliked &&
                atDislikesRoute
              ) {
                return (
                  <>
                    <Tooltip title="Remove from disliked items">
                      <IconButton
                        className={classes.favoriteContainer}
                        onClick={() => removeDislikedProperty()}
                      >
                        <DeleteIcon className={classes.deleteIconActive} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Add to favorites">
                      <IconButton
                        className={classes.addFavoritesContainer}
                        onClick={() => addToFavoriteFromDislike()}
                      >
                        <HeartIcon className={classes.favoriteIcon} />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              } else {
                if (showTooltip) {
                  return (
                    <Tooltip title="Add to favorites">
                      <IconButton
                        className={classes.favoriteContainer}
                        onClick={() => checkLoginStatus()}
                      >
                        <HeartIcon className={classes.favoriteIcon} />
                      </IconButton>
                    </Tooltip>
                  )
                } else {
                  return (
                    <IconButton
                      className={classes.favoriteContainer}
                      onClick={() => checkLoginStatus()}
                    >
                      <HeartIcon className={classes.favoriteIcon} />
                    </IconButton>
                  )
                }
              }
            })()}
          </div>

          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties?.subscriptionType)}
          />
          <CardContent className={classes.cardContentBase}>
            <PropertyInfo
              addIconOnCard={true}
              type={'search'}
              properties={properties}
              selectProperty={selectProperty}
              matchCount={props.handleMatchCount(properties)}
              listingType={listingType}
              filters={props.filters}
            />
          </CardContent>
        </Card>
      </Grid>
    )
  }

  function renderForSwipeCard() {
    if (properties && galleryImages && galleryImages.length > 0)
      console.debug(
        'mpp image',
        propertyConfigSetter('image-banner-card', {
          type: properties.propertyStatus,
          image: galleryImages[0],
        })
      )

    return (
      <Card elevation={1} className={classes.cardPaddedSwipe}>
        <div className={classes.tagContainer} style={{ pointerEvents: 'none' }}>
          {/* <Tooltip  title="Information">
            <IconButton
              className={classes.infoIconContainer}
              style={{ pointerEvents: 'all' }}
              onClick={() => {
                console.debug('hello')
                selectProperty()
              }}
            >
              <InfoIcon className={classes.infoIcon} />
              <span>More Info</span>
            </IconButton>
          </Tooltip> */}
          {properties && galleryImages && galleryImages.length > 0 && (
            <Image
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: galleryImages[0],
              })}
              onClick={() => selectProperty()}
              aspectRatio={16 / 9}
              disableSpinner
            />
          )}
          {properties.propertyStatus &&
            renderTypeTag(properties.propertyStatus, properties)}
        </div>
        {
          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />
        }
        <CardContent>
          <PropertyInfo
            type={'search'}
            properties={properties}
            selectProperty={selectProperty}
            matchCount={props.handleMatchCount(properties)}
            filters={props.filters}
            iconPointerEvents="none"
          />
        </CardContent>
        {/* <div className={classes.rowContainerCenter}>
          {properties.coefficients.close_to_cafes >= 0.7 ||
          properties.coefficients.close_to_parks >= 0.7 ||
          properties.coefficients.close_to_shops >= 0.7 ||
          properties.coefficients.close_to_schools >= 0.7 ||
          properties.coefficients.close_to_universities >= 0.7 ||
          properties.coefficients.foody_area >= 0.7 ||
          properties.coefficients.inner_city_vibe >= 0.7 ||
          properties.coefficients.close_to_public_transports >= 0.7 ||
          properties.coefficients.close_to_secondary_schools >= 0.7 ? ( */}
        {properties && (
          <div className={classes.rowContainerCenter}>
            {properties?.closeToFilters?.close_to_cafes ||
            properties?.closeToFilters?.close_to_parks ||
            properties?.closeToFilters?.close_to_shops ||
            properties?.closeToFilters?.close_to_schools ||
            properties?.closeToFilters?.close_to_universities ||
            properties?.closeToFilters?.foody_area ||
            properties?.closeToFilters?.inner_city_vibe ||
            properties?.closeToFilters?.close_to_public_transports ||
            properties?.closeToFilters?.close_to_secondary_schools ? (
              <>
                <div style={{ width: '20%' }}>
                  <div className={classes.rowContainerCenter}>
                    <p className={classes.textLabelCloseTo}>Close to:</p>
                  </div>
                  <div className={classes.rowContainerCenter}>
                    <p></p>
                  </div>
                </div>
                {renderSwipeCloseTo(properties)}
              </>
            ) : null}
          </div>
        )}
      </Card>
    )
  }

  function renderSwipeCloseTo(data) {
    const tagsToDisplay = extractTagsToDisplay(data.closeToFilters, NEARBY_FILTERS)
    return (
      <div style={{ width: '80%' }}>
        <div className={classes.rowContainerEven}>
          {tagsToDisplay.map((item) => {
            return (
              <div className={classes.chipContainer}>
                <p className={classes.chipText}>{convertTagToString(item)}</p>
                <CheckIcon className={classes.chipIcon} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Fragment key={key}>
      {cardType === 'list' && renderForListCard()}
      {cardType === 'swipe' && renderForSwipeCard()}
      {cardType === 'grid' && renderForGridCard()}
    </Fragment>
  )
})

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutUser: (params) => logoutRequest(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertyCard))
