import React, { useEffect, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import dashBannerIMG from 'assets/images/agentbanner.png';
import AlertNotification from 'components/Web/Form/AlertNotification';
import DashboardBanner from 'components/Web/Dashboard/DashboardBanner';
import AgencyAccountSetting from 'components/Web/AgencyAccountSettings/AgencyAccountSetting';
/** actions */
import { clearUploadImage, agencyv2Actions } from 'store/actions';

/** api redux request */
import { requestUpdateAgency, requestUploadImage, requestGETAPIAgency, requestPUTAPIAgency } from 'store/api';

import {
  getUser,
  getCurrentAgency,
  getAgencyUpload,
  getStatus,
  getAdminStatus,
  getAgencyAdmin,
  getSingleAdmin,
} from 'store/selectors/agencyv2.selectors';

import { getAnalytics, getStatus as getIppReferralStatus } from 'store/selectors/ippReferral.selectors';

import { useSelector, useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';
import ReferralSection from 'components/Web/AgencyAccountSettings/ReferralSection';
import { requestPOSTAPIAgencyReferral } from 'store/api';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 90,
    minHeight: '75vh',
  },
});

const AgencyAccountSettings = (props) => {
  const { classes } = props;
  const [routerChange] = useRouterChange();

  const dispatch = useDispatch();
  const USER = useSelector(getUser);
  const AGENCY = useSelector(getCurrentAgency);
  const STATUS = useSelector(getStatus);
  const AGENCY_UPLOAD = useSelector(getAgencyUpload);
  const ADMIN_STATUS = useSelector(getAdminStatus);
  const USER_AGENCY = useSelector(getAgencyAdmin);
  const CURRENT_SINGLE_ADMIN = useSelector(getSingleAdmin);
  const IPP_REFERRAL_ANALYTICS = useSelector(getAnalytics);
  const IPP_REFERRAL_STATUS = useSelector(getIppReferralStatus);

  console.debug(AGENCY);

  const defaultNotification = { status: false, options: null };

  const [ippSelectDate, setIppSelectDate] = useState('TODAY');
  const [ippStartDate, setIppStartDate] = useState(null);
  const [ippEndDate, setIppEndDate] = useState(null);
  const [referralLoading, setReferralLoading] = useState(false);
  const [isNotification, setIsNotification] = useState(defaultNotification);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const handleUploadImage = (data, type) => {
    const params = {
      query: AGENCY._id + `/?photo=${type}`,
      data: data,
    };
    dispatch(requestPUTAPIAgency(params, 'AGENCY_UPLOAD_IMAGE'));
  };

  const handlePutAgencyDetails = (data) => {
    const filteredData = data;
    Object.keys(filteredData).forEach((key) => {
      if (filteredData[key] === null) {
        delete filteredData[key];
      }
    });

    let params;

    if (AGENCY?._id) {
      params = {
        query: `${AGENCY?._id}/update`,
        data: data,
      };
    }

    if (USER_AGENCY?._id && USER_AGENCY) {
      params = {
        query: `/${USER_AGENCY?._id}/update`,
        data: {
          email: data?.email,
          firstName: data?.firstName ?? '',
          lastName: data?.lastName ?? '',
          phone: data?.phone ?? '',
        },
      };

      if (USER?.email === params.data?.email) {
        delete params.data.email;
      }
    }

    if (data && AGENCY?._id) {
      dispatch(requestPUTAPIAgency(params, 'UPDATE_AGENCY_DETAILS'));
    }
    if (data && USER_AGENCY?._id) {
      dispatch(requestPUTAPIAgency(params, 'AGENCY_EDIT_ADMIN'));
    }
  };

  const handleGetAgencyDetails = () => {
    if (USER_AGENCY) {
      const params = {
        query: `?_id=${USER_AGENCY._id}`,
        data: null,
      };

      dispatch(requestGETAPIAgency(params, 'AGENCY_GET_SINGLE_ADMIN'));
    } else if (USER) {
      const params = {
        query: USER.agency,
        data: null,
      };

      dispatch(requestGETAPIAgency(params, 'GET_AGENCY_DETAILS'));
    }
  };

  const handleRefetchDetails = () => {
    handleClearNotices();
    handleGetAgencyDetails();
  };

  useEffect(() => {
    handleClearNotices();
    handleGetAgencyDetails();

    return () => handleClearNotices();
  }, []);

  const handleStartDateChange = (date) => {
    setIppStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setIppEndDate(date);
  };

  useEffect(() => {
    if (AGENCY && AGENCY?.referralCode) {
      const params = {
        referralCode: AGENCY?.referralCode,
        range: ippSelectDate,
      };
      dispatch(
        requestPOSTAPIAgencyReferral(
          {
            data:
              ippSelectDate !== 'CUSTOM' && !ippStartDate && !ippEndDate
                ? params
                : {
                    ...params,
                    fromDate: ippStartDate,
                    toDate: ippEndDate,
                  },
          },
          'GET_IPP_ANALYTICS',
        ),
      );
    }
  }, [AGENCY, ippSelectDate, ippStartDate, ippEndDate]);

  useEffect(() => {
    setReferralLoading(
      (ippSelectDate === 'CUSTOM' && (!ippStartDate || ippStartDate === null || !ippEndDate || ippEndDate === null)) ||
        IPP_REFERRAL_STATUS === 'ipp-analytics/GET_IPP_ANALYTICS_REQUEST',
    );
  }, [IPP_REFERRAL_STATUS, ippSelectDate, ippStartDate, ippEndDate]);

  useEffect(() => {
    if (ippSelectDate !== 'CUSTOM') {
      setIppStartDate(null);
      setIppEndDate(null);
    }
  }, [ippSelectDate, ippStartDate, ippEndDate]);

  useEffect(() => {
    if (STATUS === 'agency/UPDATE_AGENCY_DETAILS_SUCCESS' || ADMIN_STATUS === 'agency/AGENCY_EDIT_ADMIN_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Changes successfully saved.',
          autoHideDuration: 3000,
          onClose: handleRefetchDetails,
        },
      };
      setIsNotification(options);
    } else if (
      STATUS === 'agency/UPDATE_AGENCY_DETAILS_REQUEST' ||
      ADMIN_STATUS === 'agency/AGENCY_EDIT_ADMIN_REQUEST'
    ) {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Saving changes...',
          autoHideDuration: 3000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'agency/UPDATE_AGENCY_DETAILS_FAILED' || ADMIN_STATUS === 'agency/AGENCY_EDIT_ADMIN_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error saving changes. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  }, [STATUS, ADMIN_STATUS]);

  useEffect(() => {
    if (AGENCY_UPLOAD && AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Upload successful!',
          autoHideDuration: 3000,
          onClose: () => {
            handleClearUploadsStatuses();
            setIsNotification(defaultNotification);
          },
        },
      };
      setIsNotification(options);
    } else if (AGENCY_UPLOAD && AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Uploading image...',
          autoHideDuration: 3000,
        },
      };
      setIsNotification(options);
    } else if (AGENCY_UPLOAD && AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: AGENCY_UPLOAD?.payload_failed?.data?.reason?.message ?? 'Error uploading image. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  }, [AGENCY_UPLOAD]);

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'));
  };

  const handleClearData = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'));
  };

  const handleClearUploadsStatuses = () => {
    dispatch(agencyv2Actions(null, 'AGENCY_UPLOAD_IMAGE', 'CLEAR_STATUS'));
    dispatch(agencyv2Actions(null, 'AGENCY_UPLOAD_IMAGE', 'CLEAR_ERROR'));
  };

  const handleClearNotices = () => {
    handleClearStatus();
    handleClearError();
    setIsNotification(defaultNotification);
  };

  useEffect(() => {
    if (USER && !isAgency()) {
      const options = {
        status: true,
        options: {
          severity: 'warning',
          message: 'User Type has not enough permission to view this page.',
          autoHideDuration: 3000,
          onClose: () => routerChange('/agent/login'),
        },
      };
      setIsNotification(options);
      routerChange('/agent/login');
    } else if (!USER && !isAgency()) {
      routerChange('/agency/login');
    }
  }, [USER]);

  const isAgency = () => {
    return USER?.userType.toLowerCase() === 'agency' ||
      USER?.userTypeList.some((i) => ['Agency', 'AgencyAdmin'].includes(i))
      ? true
      : false;
  };

  window.onbeforeunload = (e) => {
    return handleClearData();
  };

  const isValidAgencyData = () => {
    return (isAgency() && AGENCY !== undefined) || (isAgency() && USER !== undefined);
  };

  console.log(AGENCY ?? USER);
  return (
    <div className={classes.root}>
      {isValidAgencyData() && (
        <>
          <DashboardBanner title={'Account Settings'} image={dashBannerIMG} />
          <AgencyAccountSetting
            user={USER}
            agency={AGENCY ?? USER}
            agencyAdmin={CURRENT_SINGLE_ADMIN}
            apiRequestUpdateAgency={handlePutAgencyDetails}
            requestUploadImage={handleUploadImage}
            requestClearUploadImage={() => handleClearUploadsStatuses}
            uploadedImage={AGENCY_UPLOAD?.response?.uploadedFiles[0]}
          />
        </>
      )}

      <ReferralSection
        ippReferralData={IPP_REFERRAL_ANALYTICS?.ippAnalytics}
        referralCode={AGENCY?.referralCode}
        companyName={AGENCY?.companyName}
        ippSelectDate={ippSelectDate}
        setIppSelectDate={setIppSelectDate}
        ippStartDate={ippStartDate}
        ippEndDate={ippEndDate}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        referralLoading={referralLoading}
      />

      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    uploadImage: state.uploadImage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateAgency: (params) => requestUpdateAgency(params),
      requestUploadImage: (params, target) => requestUploadImage(params, target),
      requestClearUploadImage: () => clearUploadImage(),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AgencyAccountSettings));
