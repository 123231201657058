import { PURGE } from 'redux-persist';
import {
    REQUEST_SET_AGENT_RATING,
    REQUEST_SET_AGENT_RATING_SUCCESS,
    REQUEST_SET_AGENT_RATING_FAIL,
} from '../constants';



const INITIAL_STATE = {
    request: null,
    rate_agent_data: null,
};

const rateAgentReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_SET_AGENT_RATING:
            return {
                ...state,
                request: action.params,
                request_set_agent_rating_status: 'progress',
                request_set_agent_rating_error: null,
            };
        case REQUEST_SET_AGENT_RATING_SUCCESS:
            return {
                ...state,
                request: action.params,
                rate_agent_data: state.action.payload.data.data,
                request_set_agent_rating_status: 'success',
                request_set_agent_rating_error: null
            }
        case REQUEST_SET_AGENT_RATING_FAIL:
            return {
                ...state,
                request: action.params,
                request_set_agent_rating_status: 'failed',
                request_set_agent_rating_error: action.error
            }
        case PURGE:
            return INITIAL_STATE
        default: return state;
    }
}

export default rateAgentReducers

