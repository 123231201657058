import React from 'react'
import { Box, TextField, Typography } from '@material-ui/core'

const FormInput = ({ errorMessage, required, label, style, ...props }) => (
  <div style={{ marginTop: '15px', position: 'relative' }}>
    <Typography variant={'caption'} style={{ color: 'gray' }}>
      {required ? `${label} *` : label}
    </Typography>

    <TextField
      style={
        style ? { ...style, backgroundColor: '#FFF' } : { backgroundColor: '#FFF' }
      }
      {...props}
      label={null}
      fullWidth
      id="outlined-basic"
      variant="outlined"
    />
    {props.error && (
      <Box position="absolute" bottom={-22} left={0} fontSize="14px" color="red">
        {errorMessage}
      </Box>
    )}
  </div>
)

export default FormInput
