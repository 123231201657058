import moment from 'moment';
export const toDateFormat = (date, format) => {
  if (moment(date).isValid()) {
    switch (format) {
      case 'time':
        return moment(date).format('hh:mm A');
      case 'shortTime':
        return moment(date).format('h:mm A');
      case 'digitalClock':
        return moment(date).format('hh:mm:ss:a');
      case 'militaryTime':
        return moment(date).format('HH:mm');
      case 'monthDay':
        return moment(date).format('MMM DD');
      case 'day':
        return moment(date).format('dddd');
      case 'slashDate':
        return moment(date).format('DD/MM/YYYY');
      case 'shortDate':
        return moment(date).format("MMM DD 'YY");
      case 'longDate':
        return moment(date).format('MMM DD, YYYY');
      case 'momentCompare':
        return moment(date).format('YYYY-MM-DD');
      default:
        return moment(date).format('MMM DD, YYYY');
    }
  }
  return '-';
};
