import IMGComingSoon from '../assets/images/coming_soon.svg'
import IMGOffMarket from '../assets/images/off_market.svg'
import DefaultImage from '../assets/images/default-placeholder-image.svg'

export function propertyConfigSetter(type, params) {
  switch (type) {
    case 'agent':
      return showAgentBanner(params)
    case 'image-banner':
      return imageType(params.type, params.image ? params.image : DefaultImage)
    case 'image-banner-card':
      return imageCardType(params.type, params.image ? params.image : DefaultImage)
    case 'card-type':
      return getCardConfig(params)
  }
}

function getCardConfig(type) {
  let subscriptionType = type ? type.toLowerCase() : ''
  switch (subscriptionType) {
    case 'elite':
    case 'executive':
    case 'platinum':
      return {
        isFullWidth: true,
        showAgentAvatar: true,
        showAgentBanner: true,
      }

    case 'premium':
      return {
        isFullWidth: true,
        showAgentAvatar: true,
        showAgentBanner: true,
      }

    case 'extended':
    case 'standard':
      return {
        isFullWidth: false,
        showAgentAvatar: false,
        showAgentBanner: true,
      }

    case 'entry':
      return {
        isFullWidth: false,
        showAgentAvatar: false,
        showAgentBanner: false,
      }

    default: // if no key is detected return standard
      return {
        isFullWidth: false,
        showAgentAvatar: false,
        showAgentBanner: true,
      }
  }
}

function showAgentBanner(type) {
  let agentBanner = type ? type.toLowerCase() : ''
  switch (agentBanner) {
    case 'elite':
    case 'executive':
    case 'platinum':
      return {
        showBanner: true,
        showAgentAvatar: true,
      }

    case 'premium':
      return {
        showBanner: true,
        showAgentAvatar: true,
      }

    case 'extended':
    case 'standard':
      return {
        showBanner: true,
        showAgentAvatar: false,
      }

    case 'entry':
      return {
        showBanner: false,
        showAgentAvatar: false,
      }
    default:
      return {
        showBanner: true,
        showAgentAvatar: false,
      }
  }
}

function imageType(type, image) {
  const customOffmarket = image && image !== 'default' ? image : [IMGOffMarket],
    customComingSoon = image && image !== 'default' ? image : [IMGComingSoon]
  let t = type ? type.toLowerCase().replace(/[^\w\s]/gi, '') : ''
  switch (t) {
    case 'onsale':
      return image
    case 'current':
      return image
    case 'rental':
      return image
    case 'sold':
      return image
    case 'leased':
      return image
    case 'comingsoon':
      return image ? image : customComingSoon
    case 'offmarket':
      return image ? image : customOffmarket
    default:
      return customOffmarket // default
  }
}

function imageCardType(type, image) {
  const customOffmarket = image && image !== 'default' ? image : IMGOffMarket,
    customComingSoon = image && image !== 'default' ? image : IMGComingSoon
  let t = type ? type.toLowerCase().replace(/[^\w\s]/gi, '') : ''
  switch (t) {
    case 'onsale':
      return image
    case 'current':
      return image
    case 'rental':
      return image
    case 'sold':
      return image
    case 'leased':
      return image
    case 'comingsoon':
      return image ? image : customComingSoon
    case 'offmarket':
      return image ? image : customOffmarket
    default:
      return customOffmarket // default
  }
}
