import Home from 'containers/Web/Home';
import SuperadminLogin from 'containers/Web/Superadmin/SuperadminLogin';
import SuperadminDashboard from 'containers/Web/Superadmin/SuperadminDashboard';
import SuperadminAgency from 'containers/Web/Superadmin/SuperadminAgency';
import SuperadminProperties from 'containers/Web/Superadmin/SuperadminProperties';
import SuperadminSettings from 'containers/Web/Superadmin/SuperadminSettings';
import SuperadminEmailLogs from 'containers/Web/Superadmin/SuperadminEmailLogs';
import SuperadminAgencyDetails from 'containers/Web/Superadmin/SuperadminAgencyDetails';
import SuperadminFreeAgencyDetails from 'containers/Web/Superadmin/SuperadminFreeAgencyDetails';
import SuperadminAgents from 'containers/Web/Superadmin/SuperadminAgents';
import SuperadminEditAgent from 'containers/Web/Superadmin/SuperadminEditAgent';
import SuperadminAddAgent from 'containers/Web/Superadmin/SuperadminAddAgent';
import SuperadminBulkResults from 'containers/Web/Superadmin/SuperadminBulkResults';
import SuperAdminAddAgency from 'containers/Web/Superadmin/SuperAdminAddAgency';
import SuperadminChangeRequests from 'containers/Web/Superadmin/SuperadminChangeRequests';
import SuperadminChangeRequestsDetails from 'containers/Web/Superadmin/SuperadminChangeRequestsDetails';
import AgencyAddStatusPage from 'components/Web/Superadmin/Billing/AgencyAddStatusPage';
import SuperAdminNotificationList from 'containers/Web/Superadmin/SuperAdminNotificationList';
import SuperadminAgencyAdmins from 'containers/Web/Superadmin/SuperadminAgencyAdmins';
import SuperadminAddAdmin from 'containers/Web/Superadmin/SuperadminAddAdmin';
import SuperadminEditAdmin from 'containers/Web/Superadmin/SuperadminEditAdmin';
import SuperadminReporting from 'containers/Web/Superadmin/SuperadminReporting/SuperadminReporting';

let routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/superadmin/login',
    component: SuperadminLogin,
  },

  {
    path: '/superadmin/dashboard',
    component: SuperadminDashboard,
  },
  {
    path: '/superadmin/manage-agencies',
    component: SuperadminAgency,
  },
  {
    path: '/superadmin/manage-properties',
    component: SuperadminProperties,
  },
  {
    path: '/superadmin/account-settings',
    component: SuperadminSettings,
  },
  {
    path: '/superadmin/email-logs',
    component: SuperadminEmailLogs,
  },
  {
    path: '/superadmin/manage-agencies/:id',
    component: SuperadminAgencyDetails,
  },
  {
    path: '/superadmin/manage-agencies/:id/add-an-agent',
    component: SuperadminAddAgent,
  },
  {
    path: '/superadmin/manage-agencies/:id/bulk-upload-agents',
    component: SuperadminBulkResults,
  },
  {
    path: '/superadmin/manage-agencies/:id/agents',
    component: SuperadminAgents,
  },
  {
    path: '/superadmin/manage-agencies/:id/agents/:agent/edit',
    component: SuperadminEditAgent,
  },
  {
    path: '/superadmin/add-agency',
    component: SuperAdminAddAgency,
  },
  {
    path: '/superadmin/add-agency/billing-status',
    component: AgencyAddStatusPage,
  },
  {
    path: '/superadmin/change-requests',
    component: SuperadminChangeRequests,
  },
  {
    path: '/superadmin/change-requests/:id',
    component: SuperadminChangeRequestsDetails,
  },
  {
    path: '/superadmin/notification-list',
    component: SuperAdminNotificationList,
  },
  {
    path: '/superadmin/manage-agencies/:id/admins',
    component: SuperadminAgencyAdmins,
  },
  {
    path: '/superadmin/manage-agencies/:id/add-an-admin',
    component: SuperadminAddAdmin,
  },
  {
    path: '/superadmin/manage-agencies/:id/admins/:admin/edit',
    component: SuperadminEditAdmin,
  },
  {
    path: '/superadmin/manage-agencies/:id/upgrade',
    component: SuperadminFreeAgencyDetails,
  },
  {
    path: '/superadmin/reports',
    component: SuperadminReporting,
  },
];

export default routes;
