import Splash from '../../containers/Mobile/Splash';

let routes = [
  {
    path: '/splash',
    component: Splash,
  },
];

export default routes;
