import React, { useEffect, useState, useRef, createRef } from 'react'

import Image from '../Image'
import { Grid, Typography, withStyles } from '@material-ui/core';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item, useGallery } from 'react-photoswipe-gallery'

const GalleryStyles = (theme) => ({
  root: {
    //    maxHeight: 596,
    //    overflow: 'hidden'
  },
  smallImages: {
    ' & > div': {
      marginBottom: theme.spacing(1.25),
      '& img': {
        cursor: 'pointer',
      },
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  imageCounter: {
    position: 'relative',
    '& strong': {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Montserrat, sans',
      fontSize: '48px',
      letterSpacing: '-0.88px',
      lineHeight: '44px',
      fontWeight: 500,
      color: '#ffffff',
      pointerEvents: 'none',
      textShadow: '0 0 5px rgba(0,0,0,0.38)',
    },
  },
  primaryImage: {
    '& > div': {
      height: '100%',
    },
  },
})

const CoverGallery = withStyles(GalleryStyles)((props) => {
  const { data, classes } = props
  const { openGallery } = useGallery()
  const getLastItem = (array) => {
    const length = array.length
    if (array && array.length > 0) {
      return array[length - 1]
    }
  }

  useEffect(() => {
    if (props.selectImageIndex) {
      // openGallery("group2", props.selectImageIndex)
      // openGallery(props.selectImageIndex)
      console.debug(openGallery, 'OPEN Gallery')
    }
  }, [props.selectImageIndex])

  return (
    <div>
      <Grid container spacing={2}>
        {data ? (
          <>
            <Grid item sm={data.length > 1 ? 9 : 12} className={classes.primaryImage}>
              <Image
                src={data[0]}
                aspectRatio={20 / 13}
                imageStyle={{ objectFit: 'cover', cursor: 'pointer' }}
                //   onClick={() => openGallery("group2", 0)}
                onClick={() => useGallery.open(0)}
              />
            </Grid>
            {data.length > 1 && (
              <Grid item sm={3} className={classes.smallImages}>
                {data.length > 1 && (
                  <>
                    <Image
                      src={getLastItem(data)}
                      aspectRatio={20 / 13}
                      imageStyle={{ objectFit: 'cover' }}
                      //   onClick={() => openGallery("group2", (data.length - 1))}
                    />
                    {data[1] && (
                      <div className={classes.imageCounter}>
                        <Image
                          src={data[1]}
                          aspectRatio={20 / 13}
                          imageStyle={{ objectFit: 'cover' }}
                          //   onClick={() => openGallery("group2", 1)}
                        />
                        {data.length > 4 && (
                          <strong>
                            <span>+{data.length - 4}</span>
                          </strong>
                        )}
                      </div>
                    )}
                    {data[2] && (
                      <Image
                        src={data[2]}
                        aspectRatio={20 / 13}
                        imageStyle={{ objectFit: 'cover' }}
                        //   onClick={() => openGallery("group2", 2)}
                      />
                    )}
                  </>
                )}
              </Grid>
            )}
          </>
        ) : (
          <Typography component={'p'} variant={'overline'}>
            No available images
          </Typography>
        )}
      </Grid>
    </div>
  )
})

const PhotoSwipeGallery = (props) => {
  const { data } = props

  const itemRefs = useRef([])

  itemRefs.current = data.map((_, i) => itemRefs.current[i] ?? createRef())
  return (
    <div className="content">
      <div>
        <Gallery options={{ getThumbBoundsFn: undefined, showHideOpacity: true }}>
          <CoverGallery data={data} {...props} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'block',
            }}
          >
            <ul>
              {data.map((img, idx) => (
                <Item original={img} thumbnail={img} key={idx}>
                  {({ ref, open }) => (
                    <li ref={ref}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          open()
                        }}
                      >
                        Photo {idx}
                      </a>
                    </li>
                  )}
                </Item>
              ))}
            </ul>
          </div>
        </Gallery>
      </div>
    </div>
  )
}

export default PhotoSwipeGallery
