import { useDispatch, useSelector } from 'react-redux';
import { StorageActions } from 'store/actions/storage.actions';
import { getSuperadminManageAgencyPageCount } from 'store/selectors/storage.selectors';

export default function useHandleManageAgencyPageCount() {
  const dispatch = useDispatch();
  const agencyPageCount = useSelector(getSuperadminManageAgencyPageCount);

  const setAgencyPageCount = (pageCount) => {
    dispatch(StorageActions(pageCount, 'GENERAL_SETTINGS', 'SET_SUPERADMIN_MANAGE_AGENCY_PAGE_COUNT'));
  };

  return [agencyPageCount, setAgencyPageCount];
}
