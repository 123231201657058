import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function DropPinIconPPT(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12,2C7.6,2,4,5.4,4,9.6l0,0V22c0.1,0,4.8-1.5,5-5.3c0.9,0.4,1.9,0.6,3,0.6c4.4,0,8-3.4,8-7.6S16.4,2,12,2L12,2z M15.2,12.1L15.2,12.1L15.2,12.1l-6.2,0V8.5l3.1-2.1l3.1,2.1V12.1z"/>    
    </SvgIcon>
  );
}

export function AppleIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DropPinIconPPT />
      <DropPinIconPPT color="primary" />
      <DropPinIconPPT color="secondary" />
      <DropPinIconPPT color="action" />
      <DropPinIconPPT color="disabled" />
      <DropPinIconPPT style={{ color: green[500] }} />
    </div>
  );
}
