import React from 'react'
import {
  Paper,
  Typography,
  Tab,
  Tabs,
  Button,
  TextField,
  withStyles,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { ErrorHandler } from 'components/Web/Superadmin'
import { useRouterChange } from 'utils/router.utils'

const FormInput = (props) => (
  <TextField {...props} fullWidth id="outlined-basic" variant="outlined" />
)

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    width: 450,
    height: 485,
    borderRadius: 20,
    paddingTop: 30,
  },
  content: {
    paddingTop: 20,
    paddingRight: 40,
    paddingLeft: 40,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
    },
    marginTop: 20,
    // marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
    marginBottom: 20,
    // borderBottom: "0.3px solid #BDBDBD",
  },
  button: {
    borderRadius: 20,
    textTransform: 'capitalize',
    minWidth: 150,
    marginBottom: 10,
  },
  forgotButton: {
    borderRadius: 20,
    textTransform: 'capitalize',
    fontSize: 12,
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subtitle: {
    fontWeight: 300,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px',
  },
})

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
})

const SuperLoginForm = (props) => {
  const { classes, error, handleSuperadminLogin } = props
  const [routerChange] = useRouterChange()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSuperadminLogin(values)
    },
  })

  return (
    <Paper className={classes.root}>
      <Tabs indicatorColor={'primary'} value={0}>
        <Tab label="Log In" />
      </Tabs>
      <div className={classes.content}>
        <Typography className={classes.title} variant={'h1'}>
          Welcome back,
        </Typography>
        <Typography className={classes.subtitle} variant={'body2'}>
          Log In using your email
        </Typography>
        <div className={classes.form}>
          <form onSubmit={formik.handleSubmit}>
            <FormInput
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormInput
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <ErrorHandler error={error ?? null} />
            <div className={classes.buttonContainer}>
              <Button
                className={classes.button}
                color={'primary'}
                variant="contained"
                type="submit"
              >
                Sign In
              </Button>
              <Button
                className={classes.forgotButton}
                color={'inherit'}
                variant="text"
                onClick={() => routerChange('/superadmin/forgot-password')}
              >
                Forgot your password?
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Paper>
  )
}

export default withStyles(styles)(SuperLoginForm)
