import { useDispatch, useSelector } from 'react-redux';
import { StorageActions } from 'store/actions/storage.actions';
import { getSuperadminManageAgencySort } from 'store/selectors/storage.selectors';

export default function useHandleManageAgencyFilters() {
  const dispatch = useDispatch();
  const manageSort = useSelector(getSuperadminManageAgencySort);

  const setManageSort = (sorts) => {
    dispatch(StorageActions(sorts, 'GENERAL_SETTINGS', 'SET_SUPERADMIN_MANAGE_AGENCY_SORTS'));
  };

  return [manageSort, setManageSort];
}
