import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  optionButton: {
    textTransform: 'none',
    color: '#000',
    fontWeight: 'normal'
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  labelHeader: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  labelAddress: {

  }
});

const AgentFilters = props => {
  const {
    classes,
    category,
    agencies,
    agents,
    suburb
  } = props;

  function setmessage() {
    if (category === 'agent') {
      if (suburb.locality) {
        return agents.length + ' agents found in ' + suburb.locality + ', ' + suburb.state + ', ' + suburb.postcode
      } else {
        return agents.length + ' agents found'
      }
    }
    if (category === 'agency') {
      if (suburb.locality) {
        return agencies.length + ' agencies found in ' + suburb.locality + ', ' + suburb.state + ', ' + suburb.postcode
      } else {
        return agencies.length + ' agencies found'
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.labelContainer}>
        <Typography className={classes.labelHeader} gutterBottom variant="caption" component="h2">
          {'Filter by Agency'}
        </Typography>
        <Typography className={classes.labelAddress} variant="caption" component="h2">
          {setmessage()}
        </Typography>
      </div>
    </div>
  );
}

export default withStyles(styles)(AgentFilters);