import { Box } from '@material-ui/core'
import CustomSelect from 'components/Web/Filter/CustomSelect'
import Heading from 'components/Web/Filter/Heading'
import React from 'react'
import useFilters from 'utils/customHooks/useFilters'

const LAND_SIZE_OPTIONS = [
  {
    label: 'Any',
    value: 'any',
  },
  {
    label: '10 ㎡',
    value: '10',
  },
  {
    label: '20 ㎡',
    value: '20',
  },
  {
    label: '30 ㎡',
    value: '30',
  },
  {
    label: '40 ㎡',
    value: '40',
  },
  {
    label: '50 ㎡',
    value: '50',
  },
  {
    label: '60 ㎡',
    value: '60',
  },
  {
    label: '70 ㎡',
    value: '70',
  },
  {
    label: '80 ㎡',
    value: '80',
  },
  {
    label: '90 ㎡',
    value: '90',
  },
  {
    label: '100 ㎡',
    value: '100',
  },
  {
    label: '200 ㎡',
    value: '200',
  },
  {
    label: '300 ㎡',
    value: '300',
  },
  {
    label: '400 ㎡',
    value: '400',
  },
  {
    label: '500 ㎡',
    value: '500',
  },
  {
    label: '600 ㎡',
    value: '600',
  },
  {
    label: '700 ㎡',
    value: '700',
  },
  {
    label: '800 ㎡',
    value: '800',
  },
  {
    label: '900 ㎡',
    value: '900',
  },
  {
    label: '1,000 ㎡',
    value: '1000',
  },
  {
    label: '1,200 ㎡',
    value: '1200',
  },
  {
    label: '1,500 ㎡',
    value: '1500',
  },
  {
    label: '1,750 ㎡',
    value: '1750',
  },
  {
    label: '2,000 ㎡',
    value: '2000',
  },
  {
    label: '3,000 ㎡',
    value: '3000',
  },
  {
    label: '4,000 ㎡',
    value: '4000',
  },
  {
    label: '5,000 ㎡',
    value: '5000',
  },
  {
    label: '10,000 ㎡',
    value: '10000',
  },
  {
    label: '20,000 ㎡',
    value: '20000',
  },
  {
    label: '30,000 ㎡',
    value: '30000',
  },
  {
    label: '40,000 ㎡',
    value: '40000',
  },
  {
    label: '50,000 ㎡',
    value: '50000',
  },
  {
    label: '100,000 ㎡',
    value: '100000',
  },
  {
    label: '200,000 ㎡',
    value: '200000',
  },
  {
    label: '300,000 ㎡',
    value: '300000',
  },
  {
    label: '400,000 ㎡',
    value: '400000',
  },
  {
    label: '500,000 ㎡',
    value: '500000',
  },
  {
    label: '600,000 ㎡',
    value: '600000',
  },
  {
    label: '700,000 ㎡',
    value: '700000',
  },
  {
    label: '800,000 ㎡',
    value: '800000',
  },
  {
    label: '900,000 ㎡',
    value: '900000',
  },
]

const CustomLandSelect = ({ label, value, handleSelect, min, max }) => {
  return (
    <Box width="50%" paddingRight="10%">
      <Box marginBottom="-10px" paddingTop="10px" color="rgb(77, 77, 77)">
        {label}
      </Box>
      <CustomSelect
        options={LAND_SIZE_OPTIONS}
        value={value}
        handleSelect={handleSelect}
        min={min}
        max={max}
      />
    </Box>
  )
}

const LandSize = () => {
  const [getFilter, setFilter] = useFilters()

  const LAND_SIZE = getFilter('land_size')

  const handleMinSelect = (value) => {
    setFilter({
      key: 'land_size',
      value: {
        min: value === 'any' ? null : value,
        max: LAND_SIZE.max,
      },
    })
  }

  const handleMaxSelect = (value) => {
    setFilter({
      key: 'land_size',
      value: {
        min: LAND_SIZE.min,
        max: value === 'any' ? null : value,
      },
    })
  }

  return (
    <Box>
      <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
        Land Size
      </Heading>
      <Box display="flex" alignItems="flex-start">
        <CustomLandSelect
          label="Min"
          value={LAND_SIZE.min}
          handleSelect={handleMinSelect}
          max={LAND_SIZE.max !== 'any' ? LAND_SIZE.max : null}
        />
        <CustomLandSelect
          label="Max"
          value={LAND_SIZE.min === 'any' ? 'any' : LAND_SIZE.max}
          handleSelect={handleMaxSelect}
          min={LAND_SIZE.min !== 'any' ? LAND_SIZE.min : null}
        />
      </Box>
    </Box>
  )
}

export default LandSize
