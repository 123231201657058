import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function Logo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="37.0035" y1="11.1127" x2="-2.0227" y2="58.9549">
	<stop  offset="0.6116" style={{stopColor:"#37BFC9"}}/>
	<stop  offset="0.7139" style={{stopColor:"#5DC6CA"}}/>
	<stop  offset="0.8095" style={{stopColor:"#7DCCCA"}}/>
	<stop  offset="0.9353" style={{stopColor:"#B2DED8"}}/>
</linearGradient>
<path fill="url(#SVGID_1_)" d="M25.4,0C11.4,0,0,11.3,0,25.3l0,0v41.2c0,0,2.1-0.7,4.8-2.2C5,64.1,5.2,64,5.4,63.9
	c0.6-0.3,1.2-0.7,1.8-1.2c0.2-0.2,0.5-0.3,0.7-0.5c0.4-0.3,0.7-0.6,1.1-0.9c0.2-0.2,0.5-0.4,0.7-0.6c0.6-0.5,1.2-1.1,1.7-1.7
	c2.3-2.6,4.2-6,4.4-10.2l0,0c3,1.2,6.1,1.9,9.5,1.9c14,0,25.4-11.4,25.4-25.4S39.4,0,25.4,0z M35.5,33.6L35.5,33.6L35.5,33.6
	l-19.7,0c0-4.1,0-8.4,0-12.1l9.9-7l9.8,7V33.6z"/>
<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="33.6497" y1="42.1935" x2="6.3215" y2="42.1617">
	<stop  offset="0.1827" style={{stopColor:"#37BFC9"}}/>
	<stop  offset="0.2478" style={{stopColor:"#34B4BE"}}/>
	<stop  offset="0.3633" style={{stopColor:"#2E96A1"}}/>
	<stop  offset="0.5156" style={{stopColor:"#236572"}}/>
	<stop  offset="0.695" style={{stopColor:"#142332"}}/>
</linearGradient>
<path fill="url(#SVGID_2_)" d="M35.4,33.6l-19.5,0v15.2c3,1.2,6.2,1.9,9.6,1.9c7.3,0,14-3.1,18.6-8.1L35.4,33.6L35.4,33.6z"/>
<path fill="#FFFFFF" d="M7.8,83.1c0.6,0.4,1.1,0.9,1.5,1.6c0.3,0.7,0.5,1.5,0.5,2.4c0,0.9-0.2,1.7-0.5,2.4c-0.3,0.7-0.8,1.2-1.4,1.6
	c-0.6,0.4-1.4,0.6-2.2,0.6c-0.6,0-1.1-0.1-1.5-0.3c-0.5-0.2-0.9-0.5-1.2-0.9v4.4H0V82.6h3v1.2c0.7-0.8,1.5-1.3,2.6-1.3
	C6.4,82.5,7.2,82.7,7.8,83.1z M6.3,88.7c0.4-0.4,0.5-0.9,0.5-1.6s-0.2-1.2-0.5-1.6c-0.4-0.4-0.8-0.6-1.4-0.6c-0.6,0-1,0.2-1.4,0.6
	c-0.4,0.4-0.5,1-0.5,1.6s0.2,1.2,0.5,1.6c0.4,0.4,0.8,0.6,1.4,0.6C5.5,89.3,5.9,89.1,6.3,88.7z"/>
<path fill="#FFFFFF" d="M15.5,82.9c0.5-0.3,1.1-0.4,1.7-0.4v2.7c-0.1,0-0.2,0-0.4,0c-0.7,0-1.3,0.2-1.7,0.5
	c-0.4,0.3-0.7,0.8-0.7,1.3v4.6h-3v-9h3v1.5C14.6,83.6,15,83.2,15.5,82.9z"/>
<path fill="#FFFFFF" d="M25.2,83.1c0.7,0.4,1.3,0.9,1.7,1.6c0.4,0.7,0.6,1.5,0.6,2.4c0,0.9-0.2,1.7-0.6,2.4c-0.4,0.7-1,1.2-1.7,1.6
	c-0.7,0.4-1.6,0.6-2.6,0.6c-1,0-1.8-0.2-2.6-0.6c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.4c0-0.9,0.2-1.7,0.6-2.4
	c0.4-0.7,1-1.2,1.7-1.6c0.7-0.4,1.6-0.6,2.6-0.6C23.6,82.5,24.5,82.7,25.2,83.1z M21.3,85.5c-0.4,0.4-0.5,0.9-0.5,1.6
	c0,0.7,0.2,1.2,0.5,1.6c0.4,0.4,0.8,0.6,1.4,0.6c0.6,0,1.1-0.2,1.4-0.6c0.4-0.4,0.5-1,0.5-1.6c0-0.7-0.2-1.2-0.5-1.6
	c-0.4-0.4-0.8-0.6-1.4-0.6C22.1,84.9,21.6,85.1,21.3,85.5z"/>
<path fill="#FFFFFF" d="M37,83.1c0.6,0.4,1.1,0.9,1.5,1.6c0.3,0.7,0.5,1.5,0.5,2.4c0,0.9-0.2,1.7-0.5,2.4c-0.3,0.7-0.8,1.2-1.4,1.6
	c-0.6,0.4-1.4,0.6-2.2,0.6c-0.6,0-1.1-0.1-1.5-0.3c-0.5-0.2-0.9-0.5-1.2-0.9v4.4h-3V82.6h3v1.2c0.7-0.8,1.5-1.3,2.6-1.3
	C35.6,82.5,36.4,82.7,37,83.1z M35.5,88.7c0.4-0.4,0.5-0.9,0.5-1.6s-0.2-1.2-0.5-1.6c-0.4-0.4-0.8-0.6-1.4-0.6c-0.6,0-1,0.2-1.4,0.6
	c-0.4,0.4-0.5,1-0.5,1.6s0.2,1.2,0.5,1.6c0.4,0.4,0.8,0.6,1.4,0.6C34.6,89.3,35.1,89.1,35.5,88.7z"/>
<path fill="#FFFFFF" d="M47.9,83.8c0.8,0.9,1.2,2.1,1.2,3.6c0,0.2,0,0.4,0,0.5h-6.3c0.1,0.5,0.4,0.9,0.7,1.2
	c0.4,0.3,0.8,0.4,1.3,0.4c0.4,0,0.8-0.1,1.1-0.2c0.4-0.2,0.7-0.4,1-0.7l1.5,1.5c-0.5,0.5-1,0.9-1.7,1.2c-0.7,0.3-1.4,0.4-2.2,0.4
	c-1,0-1.8-0.2-2.5-0.6c-0.7-0.4-1.3-0.9-1.7-1.6c-0.4-0.7-0.6-1.5-0.6-2.4c0-0.9,0.2-1.7,0.6-2.4c0.4-0.7,0.9-1.2,1.6-1.6
	c0.7-0.4,1.5-0.6,2.5-0.6C46,82.5,47.1,83,47.9,83.8z M46.2,86.3c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.3-0.7-0.5-1.2-0.5
	c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.5,0.7-0.6,1.3H46.2z"/>
<path fill="#FFFFFF" d="M54.7,82.9c0.5-0.3,1.1-0.4,1.7-0.4v2.7c-0.1,0-0.2,0-0.4,0c-0.7,0-1.3,0.2-1.7,0.5
	c-0.4,0.3-0.7,0.8-0.7,1.3v4.6h-3v-9h3v1.5C53.8,83.6,54.2,83.2,54.7,82.9z"/>
<path fill="#FFFFFF" d="M63.6,91.2c-0.3,0.2-0.7,0.3-1.1,0.4c-0.4,0.1-0.9,0.2-1.3,0.2c-0.9,0-1.6-0.3-2.2-0.8
	c-0.5-0.5-0.8-1.2-0.8-2.1V85h-1.2v-2h1.2v-2.5h2.9v2.5h2.3v2h-2.3v3.4c0,0.6,0.3,0.9,0.8,0.9c0.3,0,0.7-0.1,1.1-0.3L63.6,91.2z"/>
<path fill="#FFFFFF" d="M68.5,94.4c-0.6,0.4-1.3,0.6-2.1,0.6c-0.5,0-1-0.1-1.4-0.2c-0.4-0.2-0.9-0.4-1.3-0.7l1.2-2
	c0.2,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.4,0.1,0.6,0.1c0.5,0,0.8-0.2,1.1-0.7l0.2-0.4l-3.7-8.8h3l2.1,5.9l1.9-5.9h2.9l-3.9,9.9
	C69.5,93.4,69.1,94,68.5,94.4z"/>
<path fill="#FFFFFF" d="M14,99.5c0.6,0.6,0.9,1.4,0.9,2.5v5.4h-1.6v-5c0-0.7-0.2-1.3-0.6-1.7c-0.4-0.4-1-0.6-1.7-0.6
	c-0.8,0-1.5,0.3-2,0.9c-0.5,0.6-0.7,1.3-0.7,2.2v4.3H6.8v-5c0-0.7-0.2-1.3-0.6-1.7c-0.4-0.4-1-0.6-1.7-0.6c-0.8,0-1.5,0.3-2,0.9
	s-0.7,1.3-0.7,2.2v4.3H0.3v-8.8h1.5v1.8c0.6-1.2,1.7-1.9,3.3-1.9c0.8,0,1.5,0.2,2,0.6s0.9,0.9,1.1,1.6c0.5-1.5,1.7-2.2,3.4-2.2
	C12.7,98.6,13.5,98.9,14,99.5z"/>
<path fill="#FFFFFF" d="M23.1,107.5v-1.2c-0.7,0.9-1.7,1.3-3.1,1.3c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.8-0.6-1.1-1
	c-0.2-0.4-0.4-0.9-0.4-1.4c0-0.8,0.3-1.4,0.9-1.9c0.6-0.5,1.4-0.7,2.4-0.7H23v-0.4c0-0.6-0.2-1.1-0.6-1.4c-0.4-0.3-0.9-0.5-1.7-0.5
	c-0.9,0-1.8,0.3-2.7,0.9l-0.6-1.1c0.6-0.4,1.2-0.7,1.8-0.9c0.5-0.2,1.2-0.3,1.9-0.3c1.1,0,1.9,0.3,2.5,0.8c0.6,0.5,0.9,1.3,0.9,2.3
	l0,5.8H23.1z M22.1,105.7c0.5-0.3,0.8-0.8,0.9-1.4v-0.9h-2.6c-0.7,0-1.2,0.1-1.6,0.3c-0.3,0.2-0.5,0.5-0.5,1c0,0.5,0.2,0.8,0.5,1.1
	c0.3,0.3,0.8,0.4,1.4,0.4C21,106.2,21.6,106.1,22.1,105.7z"/>
<path fill="#FFFFFF" d="M32.1,106.9c-0.7,0.4-1.4,0.6-2.1,0.6c-0.7,0-1.3-0.2-1.7-0.6c-0.4-0.4-0.6-1-0.6-1.9v-4.9h-1.2V99h1.2v-2.4
	h1.6V99h2.7v1.2h-2.7v4.6c0,0.5,0.1,0.8,0.3,1s0.4,0.3,0.8,0.3c0.4,0,0.9-0.1,1.4-0.4L32.1,106.9z"/>
<path fill="#FFFFFF" d="M40.5,99.8c0.7,0.8,1,1.9,1,3.4c0,0.2,0,0.4,0,0.5h-7c0.1,0.8,0.5,1.4,1,1.9c0.5,0.5,1.2,0.7,2,0.7
	c0.5,0,1-0.1,1.5-0.3c0.4-0.2,0.8-0.5,1.2-0.8L41,106c-0.4,0.5-1,0.8-1.6,1.1c-0.6,0.3-1.3,0.4-2,0.4c-0.9,0-1.6-0.2-2.3-0.6
	c-0.7-0.4-1.2-0.9-1.6-1.6c-0.4-0.7-0.6-1.4-0.6-2.3c0-0.9,0.2-1.6,0.6-2.3c0.4-0.7,0.9-1.2,1.6-1.6c0.7-0.4,1.4-0.6,2.3-0.6
	C38.7,98.6,39.8,99,40.5,99.8z M40.1,102.5c0-0.8-0.3-1.4-0.8-1.9c-0.5-0.5-1.1-0.7-1.9-0.7c-0.8,0-1.4,0.2-1.9,0.7
	c-0.5,0.5-0.8,1.1-0.9,1.9H40.1z"/>
    </SvgIcon>
  );
}

export function SvgIconsColor() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Logo />
      {/* <Logo color="primary" />
      <Logo color="secondary" />
      <Logo color="action" />
      <Logo color="disabled" />
      <Logo style={{ color: green[500] }} /> */}
    </div>
  );
}
