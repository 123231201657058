import { useSelector } from 'react-redux'
import { toggleSignUp } from 'store/actions'
import { getAuthentication } from 'store/selectors/authentication.selectors'

/* Returns true or false, if false redirect the user to the login 
   page using the component that called this custom hook */

export const useCheckIsUserAuthenticated = () => {
  const AUTHENTICATION = useSelector(getAuthentication)

  if (AUTHENTICATION && AUTHENTICATION.user_status !== 'logged') {
    toggleSignUp(false)
    return false
  } else {
    /** User Logged */
    return true
  }
}
