import { Box, Button, Typography, withStyles } from '@material-ui/core'
import { Loader } from 'components/Common'
import AlertNotification from 'components/Web/Form/AlertNotification'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { superadminActions } from 'store/actions'
import { requestPOSTAPISuperadmin } from 'store/api'
import { getSuperadminToken } from 'store/selectors/superadmin.selectors'
import { getUser } from 'store/selectors/superadmin.selectors'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    padding: '25px 25px 25px',
  },
})

const AgencyAddStatusPage = ({ history, location, classes }) => {
  const [paymentStatus, setPaymentStatus] = useState()
  const [message, setMessage] = useState()
  const [rerouteDelay, setRerouteDelay] = useState(5)

  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const params = new URLSearchParams(location?.search)
  const paramsAgencyId = params.get('agencyId')

  const dispatch = useDispatch()
  const SUPERADMIN_TOKEN = useSelector(getSuperadminToken)
  // const AGENCY_DETAILS = useSelector(getUser)
  const [routerChange] = useRouterChange()

  const checkSuperadminToken = () => {
    /**
     * Reroute to login when token does not exist
     */
    if (SUPERADMIN_TOKEN === null) {
      // add more conditions here
      routerChange('/superadmin/login')
    }
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  useEffect(() => {
    /**
     * * Superadmin Token checker
     */
    checkSuperadminToken()
  }, [])

  useEffect(() => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))

    if (location?.search) {
      switch (params.get('redirect_status')) {
        case 'succeeded':
          setMessage('Successfully added payment details!')
          setPaymentStatus('success')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          setPaymentStatus('error')
          break
        default:
          setMessage('Something went wrong.')
          setPaymentStatus('error')
          break
      }
    }

    return () => {
      dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
      dispatch(superadminActions(null, 'SUPERADMIN_ADD_AGENCY', 'CLEAR'))
    }
  }, [])

  // Reroute to dashboard
  useEffect(() => {
    let rerouteInterval = null

    if (!isLoading) {
      rerouteInterval = setInterval(() => {
        setRerouteDelay((prev) => prev - 1)
      }, 1000)
    }

    return () => {
      if (rerouteInterval) clearInterval(rerouteInterval)
    }
  }, [isLoading])

  useEffect(() => {
    if (rerouteDelay === 1) {
      if (paramsAgencyId) {
        routerChange(`/superadmin/manage-agencies/${paramsAgencyId}`)
      } else {
        routerChange(`/superadmin/dashboard`)
      }
    }
  }, [rerouteDelay])

  // Send Docusign
  // useEffect(() => {
  //   //TODO: Get data needed for docusign

  //   let request = {
  //     query: '/' + paramsAgencyId,
  //     data: null,
  //   }

  //   dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_SEND_DOCUSIGN_AGENCY'))

  //   return () => {
  //     dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  //   }
  // }, [])

  // useEffect(() => {
  //   let successTimeOut = null

  //   if (paymentStatus === 'success') {
  //     if (
  //       AGENCY_DETAILS?.status === 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_REQUEST'
  //     ) {
  //       setIsLoading(true)
  //       dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  //     }

  //     if (
  //       AGENCY_DETAILS?.status === 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_FAILED'
  //     ) {
  //       setIsLoading(false)
  //       handleNotification(true, {
  //         severity: 'error',
  //         message: `Error on sending Docusign`,
  //       })
  //       dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  //     }

  //     if (
  //       AGENCY_DETAILS?.status === 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_SUCCESS'
  //     ) {
  //       setIsLoading(false)
  //       handleNotification(true, {
  //         severity: 'success',
  //         message: `Successfully sent Docusign`,
  //       })
  //     }
  //   }

  //   return () => {
  //     if (successTimeOut) clearTimeout(successTimeOut)
  //   }
  // }, [AGENCY_DETAILS, paymentStatus])

  return (
    <div className={classes.root}>
      {/* <Loader
        isLoading={isLoading}
        message="Sending docusign..."
        style={{ left: '0px' }}
      /> */}
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={() => setIsNotification(false)}
      />
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        alignItems="center"
        flexDirection="column"
        gridGap="15px"
      >
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {message}
          </Typography>
          <Typography style={{ fontSize: '40px' }}>{rerouteDelay}</Typography>
          <Typography style={{ fontSize: '14px' }}>
            Thank you! You will be automatically redirected to the edit agency page
          </Typography>
        </Box>
        <Button
          color={'primary'}
          variant="contained"
          onClick={() => {
            routerChange('/superadmin/dashboard')
          }}
        >
          Go back to dashboard
        </Button>
      </Box>
    </div>
  )
}

export default withStyles(styles)(AgencyAddStatusPage)
