import React from "react";
import { Container, withStyles, Button } from "@material-ui/core";
import styled from 'styled-components';

/** Property Utils */
import ButtonStyleFileUpload from "../../Mobile/Form/ButtonStyleFileUpload";

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
    width: '100%'
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative'
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)'
  },
  intentUploadWrap: {
    marginTop: theme.spacing(3)
  }
});


const EditPropertyUploadStatement = props => {
  const {
    classes,
    property
  } = props;

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.intentUploadWrap}>
          <ButtonStyleFileUpload
            label={property && property.statementOfIntent}
            disabled={true}
          />
        </div>
      </Container>

    </div>
  );
};

export default withStyles(styles)(EditPropertyUploadStatement);
