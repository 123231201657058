import React, { useEffect } from 'react';

import styled from 'styled-components';

import { withStyles, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  heading: {
    fontWeigth: '400',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: '#4d4d4d',
  },
  subHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
  },
  textContent: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
});

const Container = styled.div`
  max-width: 1440px;
  padding: 20px 120px 120px 155px;
  @media (min-width: 1440px) {
    margin: 0 auto;
  }
`;

const InnerText = styled.span`
  flex-direction: column;
  font-size: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 12px;
`;

const TermsMobileApp = ({ classes, history }) => {
  useEffect(() => {
    localStorage.removeItem('hideState');
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Container>
        <Typography variant="h5" className={classes.heading}>
          MOBILE APPLICATION TERMS AND CONDITIONS OF USE
        </Typography>
        <Typography variant="h6" className={classes.subHeading}>
          1. About the App
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Welcome to www.propertymate.com.au (the <strong>'App'</strong>).
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The App is operated by PropertyMate Group Pty Ltd, ACN 642 236 039 (‘PropertyMate’, ‘we’, ‘our’ or ‘us’).
            Access to and use of the App, or any of its associated Products or Services (the{' '}
            <strong>'Services '</strong>
            ), is provided by PropertyMate. Please read these terms and conditions (the <strong>'Terms '</strong>)
            carefully. By using, browsing and/or reading the App or any of the Services, you signify that you have read,
            understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of
            the App, or any of the Services, immediately.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(c)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PropertyMate reserves the right to review and change any of the Terms by updating this page at its sole
            discretion. When PropertyMate updates the Terms, it will use reasonable endeavours to provide you with
            notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their
            publication. Before you continue, we recommend you keep a copy of the Terms for your records
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          2. Acceptance of the Terms
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You accept the Terms by remaining on the App or using the Services. You may also accept the Terms by
            clicking to accept or agree to the Terms where this option is made available to you by PropertyMate in the
            user interface.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          3. Registration to use the Services
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            In order to access the Services, you must first register for an account through the App (the{' '}
            <strong>'Account'</strong>).
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            As part of the registration process, or as part of your continued use of the Services, you may be required
            to provide personal information about yourself (such as identification or contact details), including:
            <span className={classes.textContainer}>(i) Email address</span>
            <span className={classes.textContainer}>(i) Mobile phone number, and</span>
            <span className={classes.textContainer}>(i) Street address</span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(c)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You warrant that any information you give to PropertyMate in the course of completing the registration
            process will always be accurate, correct and up to date.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(d)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Once you have completed the registration process, you will be a registered member of the App (
            <strong>'Member'</strong>) and agree to be bound by the Terms.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(e)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You may not use the Services and may not accept the Terms if:
            <span className={classes.textContainer} style={{ margin: '8px 0' }}>
              (i) you are not of legal age to form a binding contract with PropertyMate Group; or
            </span>
            <span className={classes.textContainer} style={{ margin: '8px 0' }}>
              (ii) you are a person barred from receiving the Services under the laws of Australia or other countries
              including the country in which you are resident or from which you use the Services.
            </span>
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          4. Your obligations as a Member
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            As a Member, you agree to comply with the following:
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; you will use the Services only for purposes that are permitted by:
              <span className={classes.textContainer}>
                <InnerText
                // variant="body1"
                // className={classes.textContent}
                // style={{ marginTop: 8, marginBottom: 8 }}
                >
                  (A)
                </InnerText>
                <InnerText
                // variant="body1"
                // className={classes.textContent}
                // style={{ marginTop: 8, marginBottom: 8 }}
                >
                  the Terms; and
                </InnerText>
              </span>
              <span className={classes.textContainer}>
                <InnerText
                // variant="body1"
                // className={classes.textContent}
                // style={{ marginTop: 8, marginBottom: 8 }}
                >
                  (B)
                </InnerText>
                <InnerText
                // variant="body1"
                // className={classes.textContent}
                // style={{ marginTop: 8, marginBottom: 8 }}
                >
                  any applicable law, regulation or generally accepted practices or guidelines in the relevant
                  jurisdictions;
                </InnerText>
              </span>
            </span>
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; you have the sole responsibility for protecting the confidentiality of your password
              and/or email address. Use of your password by any other person may result in the immediate cancellation of
              the Services;
            </span>
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (iii) &nbsp;&nbsp; any use of your registration information by any other person, or third parties, is
              strictly prohibited. You agree to immediately notify PropertyMate of any unauthorised use of your password
              or email address or any breach of security of which you have become aware;
            </span>
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (iv) &nbsp;&nbsp; access and use of the App is limited, non-transferable and allows for the sole use of
              the App by you for the purposes of PropertyMate providing the Services;
            </span>
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (v) &nbsp;&nbsp; you will not use the Services or the App in connection with any commercial endeavours
              except those that are specifically endorsed or approved by the management of PropertyMate;
            </span>
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (vi) &nbsp;&nbsp; you will not use the Services or App for any illegal and/or unauthorised use which
              includes collecting email addresses of Members by electronic or other means for the purpose of sending
              unsolicited email or unauthorised framing of or linking to the App;
            </span>
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (vii) &nbsp;&nbsp; you agree that commercial advertisements, affiliate links, and other forms of
              solicitation may be removed from the App without notice and may result in termination of the Services.
              Appropriate legal action will be taken by PropertyMate for any illegal or unauthorised use of the App; and
            </span>
            <span className={classes.textContainer} style={{ flexDirection: 'column', marginTop: 8, marginBottom: 8 }}>
              (viii) &nbsp;&nbsp; you acknowledge and agree that any automated use of the App or its Services is
              prohibited.
            </span>
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          5. Payment
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Where the option is given to you, you may make payment for the Services (the ' Services Fee') by way of:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; Electronic funds transfer (<strong>'EFT'</strong>) into our nominated bank account
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; Credit Card Payment (<strong>'Credit Card'</strong>)
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (iii) &nbsp;&nbsp; PayPal (<strong>'PayPal'</strong>)
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            All payments made in the course of your use of the Services are made using Macquarie Bank. In using the App,
            the Services or when making any payment in relation to your use of the Services, you warrant that you have
            read, understood and agree to be bound by the Macquarie Bank terms and conditions which are available on
            their website.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(c)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You acknowledge and agree that where a request for the payment of the Services Fee is returned or denied,
            for whatever reason, by your financial institution or is unpaid by you for any other reason, then you are
            liable for any costs, including banking fees and charges, associated with the Services Fee.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(d)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You agree and acknowledge that PropertyMate can vary the Services Fee at any time.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          6. Refund Policy
        </Typography>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.textContent}>
            Subject to the remainder of this clause, PropertyMate will only provide you with a refund of the Services
            Fee in the event we are unable to continue to provide the Services or if the manager of PropertyMate makes a
            decision, at its absolute discretion, that it is reasonable to do so under the circumstances (the{' '}
            <strong>'Refund'</strong>). However you have rights by law which arise under these Terms, and these Terms
            are subject to those rights.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          7. Copyright and Intellectual Property
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The App, the Services and all of the related products of PropertyMate are subject to copyright. The material
            on the App is protected by copyright under the laws of Australia and through international treaties. Unless
            otherwise indicated, all rights (including copyright and all other intellectual property rights) in the
            Services and compilation of the App (including but not limited to text, graphics, logos, button icons, video
            images, audio clips, App, code, scripts, design elements and interactive features) or the Services are owned
            or controlled for these purposes, and are reserved by PropertyMate or its contributors or licensors
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            All trademarks, service marks and trade names are owned, registered and/or licensed by PropertyMate Group,
            who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; use the App pursuant to the Terms;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; copy and store the App and the material contained in the App in your device's cache
              memory; and
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 12 }}>
              (iii) &nbsp;&nbsp; print pages from the App for your own personal and non-commercial use.
            </span>
            PropertyMate does not grant you any other rights whatsoever in relation to the App or the Services. All
            other rights are expressly reserved by PropertyMate.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(c)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PropertyMate retains all rights, title and interest in and to the App and all related Services. Nothing you
            do on or in relation to the App will transfer any:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; business name, trading name, domain name, trade mark, industrial design, patent,
              registered design or copyright, or
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; a right to use or exploit a business name, trading name, domain name, trade mark or
              industrial design, or
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (iii) &nbsp;&nbsp; a thing, system or process that is the subject of a patent, registered design or
              copyright (or an adaptation or modification of such a thing, system or process),
            </span>
            <span>to you.</span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(d)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You may not, without the prior written permission of PropertyMate and the permission of any other relevant
            rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in
            public, adapt or change in any way the App or the Services or third party Services for any purpose, unless
            otherwise provided by these Terms.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          8. Privacy
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PropertyMate takes your privacy seriously and any information provided through your use of the App and/or
            Services are subject to PropertyMate's Privacy Policy, which is available on the App. You must not upload or
            provide any personal information of another individual through your use of the App and/or Services unless
            you first make them aware of PropertyMate's Privacy Policy and have their consent to provide such personal
            information.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          9. General Disclaimer
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or
            imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be
            limited or excluded.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            To the fullest extent permitted by law, our liability for a breach of a non- excludable guarantee referred
            to in clause 9(a) is limited to, at your option:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; in the case of goods supplied or offered by us, any one or more of the following:
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(A)</Typography>
              &nbsp;&nbsp; Nothing in the Terms limits or excludes any guarantees, warranties, representations or
              conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them)
              which by law may not be limited or excluded.
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(B)</Typography>
              &nbsp;&nbsp; the repair of the goods;
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(C)</Typography>
              &nbsp;&nbsp; the payment of the cost of replacing the goods or of acquiring equivalent goods; or
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(D)</Typography>
              &nbsp;&nbsp;the payment of the cost of having the goods repaired; or
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; in the case of services supplied or offered by us:
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(A)</Typography>
              &nbsp;&nbsp; the supplying of the services again; or
            </span>
            <span className={classes.textContainer}>
              <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(B)</Typography>
              &nbsp;&nbsp; the payment of the cost of having the services supplied again.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(c)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Subject to this clause 9, and to the extent permitted by law:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; all terms, guarantees, warranties, representations or conditions which are not expressly
              stated in the Terms are excluded; and
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; PropertyMate will not be liable for any special, indirect or consequential loss or
              damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an
              applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in
              connection with the Services or these Terms (including as a result of not being able to use the Services
              or the late supply of the Services), whether at common law, under contract, tort (including negligence),
              in equity, pursuant to statute or otherwise.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(d)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Subject to this clause 9, and to the extent permitted by law, use of the App and the Services is at your own
            risk, and everything on the App and the Services is provided to you "as is" and "as available" without
            warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents,
            contributors and licensors of PropertyMate make any express or implied representation or warranty about the
            Services or any products or Services (including the products or Services of PropertyMate) referred to on the
            App, including (but is not restricted to) loss or damage you might suffer as a result of any of the
            following:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; failure of performance, error, omission, interruption, deletion, defect, failure to
              correct defects, delay in operation or transmission, computer virus or other harmful component, loss of
              data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or
              unauthorised access to records;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; the accuracy, suitability or currency of any information on the App, the Services, or
              any of its Services related products (including third party material and advertisements on the App);
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (iii) &nbsp;&nbsp; costs incurred as a result of you using the App, the Services or any of the products of
              PropertyMate Group; and
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (iv) &nbsp;&nbsp; the Services or operation in respect to links which are provided for your convenience.
            </span>
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          10. Limitation of liability
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Subject to clause 9(a), PropertyMate's total liability arising out of or in connection with the Services or
            these Terms, however arising, including under contract, tort (including negligence), in equity, under
            statute or otherwise, will not exceed the greater of the sum of $100 or the resupply of the Services to you.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You expressly understand and agree that subject to clause 9(a) PropertyMate, its affiliates, employees,
            agents, contributors and licensors shall not be liable to you for any loss of profit (whether incurred
            directly or indirectly), any loss of goodwill or business reputation and any other intangible loss, or for
            any, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however
            caused and under any theory of liability.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          11. Termination of Contract
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Terms will continue to apply until terminated by either you or by PropertyMate as set out below.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If you want to terminate the Terms, you may do so by:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; providing PropertyMate with 7 days' notice of your intention to terminate; and
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 12 }}>
              (ii) &nbsp;&nbsp; closing your accounts for all of the services which you use, where PropertyMate has made
              this option available to you.
            </span>
            Your notice should be sent, in writing, to PropertyMate via the 'Contact Us' link on our homepage.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(c)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            PropertyMate may at any time, terminate the Terms with you if:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; you have breached any provision of the Terms or intend to breach any provision;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; PropertyMate Group is required to do so by law;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (iii) &nbsp;&nbsp; the provision of the Services to you by PropertyMate Group is, in the opinion of
              PropertyMate Group, no longer commercially viable.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(d)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Subject to local applicable laws, PropertyMate reserves the right to discontinue or cancel your membership
            at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the App or
            the Services without notice if you breach any provision of the Terms or any applicable law or if your
            conduct impacts PropertyMate's name or reputation or violates the rights of those of another party.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          12. Indemnity
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            You agree to indemnify PropertyMate Group, its affiliates, employees, agents, contributors, third party
            content providers and licensors from and against:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage
              (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection
              with Your Content;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; any direct or indirect consequences of you accessing, using or transacting on the App or
              attempts to do so; and/or
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 12 }}>
              (iii) &nbsp;&nbsp; any breach of the Terms.
            </span>
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          13. Dispute Resolution
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            <strong>Compulsory:</strong> <br />
            If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court
            proceedings in relation to the dispute until 60 days after the giving of Notice (as set out in clause
            13.(c)), unless the following clauses have been complied with (except where urgent interlocutory relief is
            sought).
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(b)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            <strong>Notice:</strong> <br />A party to the Terms claiming a dispute (<strong>'Dispute'</strong>) has
            arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the
            desired outcome and the action required to settle the Dispute.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(c)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            <strong>Resolution:</strong> <br />
            On receipt of that notice (<strong>'Notice'</strong>) by that other party, the parties to the Terms (
            <strong>'Parties'</strong>) must:
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (i) &nbsp;&nbsp; Within 14 days of the Notice endeavour in good faith to resolve the Dispute expeditiously
              by negotiation or such other means upon which they may mutually agree;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (ii) &nbsp;&nbsp; If for any reason whatsoever, 14 days after the date of the Notice, the Dispute has not
              been resolved, the Parties may agree to mediation, and if both Parties agree to mediation the Parties must
              either agree upon selection of a mediator or request that an appropriate mediator be appointed by the
              President of the Law Institute of Victoria or his or her nominee;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 8 }}>
              (iii) &nbsp;&nbsp; The Parties are equally liable for the fees and reasonable expenses of a mediator and
              the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts
              requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their
              own costs associated with the mediation;
            </span>
            <span className={classes.textContainer} style={{ marginTop: 8, marginBottom: 12 }}>
              (iv) &nbsp;&nbsp; The mediation will be held in , Australia.
            </span>
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(d)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            <strong>Confidential:</strong> <br />
            All communications concerning negotiations made by the Parties arising out of and in connection with this
            dispute resolution clause are confidential and to the extent possible, must be treated as "without
            prejudice" negotiations for the purpose of applicable laws of evidence.
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography>(e)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            <strong>Termination of Mediation:</strong> <br />
            If 60 days have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved,
            either Party may ask the mediator to terminate the mediation and the mediator must do so.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          14. Venue and Jurisdiction
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Services offered by PropertyMate are intended to be viewed by residents of Australia. In the event of
            any dispute arising out of or in relation to the App, you agree that the exclusive venue for resolving any
            dispute shall be in the courts of Victoria, Australia.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          15. Governing Law
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            The Terms are governed by the laws of Victoria, Australia. Any dispute, controversy, proceeding or claim of
            whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be
            governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia, without
            reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law
            clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their
            successors and assigns.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          16. Independent Legal Advice
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            Both parties confirm and declare that the provisions of the Terms are fair and reasonable and both parties
            having taken the opportunity to obtain independent legal advice and declare the Terms are not against public
            policy on the grounds of inequality or bargaining power or general grounds of restraint of trade.
          </Typography>
        </div>

        <Typography variant="h6" className={classes.subHeading}>
          17. Severance
        </Typography>
        <div className={classes.textContainer}>
          <Typography>(a)</Typography>
          <Typography variant="body1" className={classes.textContent}>
            If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that
            part shall be severed and the rest of the Terms shall remain in force.
          </Typography>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles)(TermsMobileApp);
