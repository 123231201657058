import { useDispatch, useSelector } from 'react-redux';
import { StorageActions } from 'store/actions/storage.actions';
import { getSuperadminManageAgencyField } from 'store/selectors/storage.selectors';

export default function useHandleManageAgencySearchField() {
  const dispatch = useDispatch();
  const manageSearchField = useSelector(getSuperadminManageAgencyField);

  const setManageSearchField = (field) => {
    dispatch(StorageActions(field, 'GENERAL_SETTINGS', 'SET_SUPERADMIN_MANAGE_AGENCY_FIELD'));
  };

  return [manageSearchField, setManageSearchField];
}
