import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  getAgencyHexByAgencyId,
  DEFAULT_AGENCIES,
  HEX_OBJECT,
} from '../../../constants/constants'
import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'
import styled, { css } from 'styled-components'
import MessageIcon from '@material-ui/icons/Message'
import PhoneIcon from '@material-ui/icons/Phone'
import { withStyles, Typography, Avatar, Button } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: '3.571em',
    backgroundColor: '#000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarText: {
    color: '#FFF',
    textAlign: 'right',
    marginRight: '0.714em',
    marginLeft: '0.714em',
    lineHeight: 1.2,
  },
  avatarImage: {
    width: '3.286em',
    height: '3.286em',
    position: 'relative',
    bottom: 15,
    border: '1px solid #FFF',
    backgroundColor: '#bdbdbd',
  },
  avatarImageProperty: {
    width: '3.286em',
    height: '3.286em',
    position: 'relative',
    bottom: 0,
    border: '1px solid #FFF',
  },
  agentLogo: {
    // height: '1.8em',
    width: 'auto',
    maxWidth: '40%',
    maxHeight: '80%',
    backgroundColor: 'transparent',
    imageRendering: 'auto',
    // imageRendering: 'crisp-edges',
    // imageRendering: 'pixelated',
    imageRendering: '-webkit-optimize-contrast',
  },
  buttonOption: {
    marginTop: 5,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    padding: theme.spacing(1.25, 0.6),
  },
  agentName: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21px',
    marginLeft: 16,
    marginRight: 10,
  },
})

const Row = styled.div`
  padding-left: 24px;
  padding-right: 21px;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 22px 0;
    border-bottom: 0.25px solid #979797;
  }

  &:last-child {
    div {
      border: 0 !important;
    }
  }

  .avatar {
    width: 80px;
    height: 80px;
  }

  .btn-tel {
    width: 100px !important;
    margin: 0;
    padding: 10px 4.8px;
  }
`

const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 100px);
  &:last-child {
    justify-content: flex-end;
    width: 100px;
    flex-direction: column;
  }
`

const AgentBanner = (props) => {
  const { classes, showBanner, showAgentAvatar, properties, type, isMultipleAgents } =
    props

  const AgentImage = (img) => {
    if (img === 'http://lorempixel.com/200/200/people/9/') {
      return null
    } else {
      if (img) {
        const imgsplit = img.split('image/upload', 2)

        return imgsplit.length === 2
          ? imgsplit[0] + 'image/upload/f_webp,q_auto/c_fill,g_face,h_300,w_300' + imgsplit[1]
          : img
      }
      return img
    }
  }

  const checkIfLight = (color) => {
    const hex = color.replace('#', '')
    const c_r = parseInt(hex.substr(0, 2), 16)
    const c_g = parseInt(hex.substr(2, 2), 16)
    const c_b = parseInt(hex.substr(4, 2), 16)
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
    return brightness > 155
  }

  const logoObject = LOGO_OBJECT,
    hexObject = HEX_OBJECT

  const getAgencyLogo = (agencyName) => {
    if (
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return LogoPlaceholder
    }
  }

  const getAgencyHex = (agencyName) => {
    if (
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return '#000000'
    }
  }

  const brandColor =
    properties.agency && properties.agency.brandColor
      ? properties.agency.brandColor
      : properties.reaAgencyId
      ? getAgencyHex(properties.reaAgencyId || properties.agency.reaAgencyId)
      : '#000000'

  function renderAgentBannerResultScreen() {
    return (
      <>
        {showBanner && (
          <div
            className={classes.root}
            style={{
              backgroundColor: brandColor,
              boxShadow: checkIfLight(brandColor) ? '0 0 0 1px #000000' : 'none',
            }}
          >
            <img
              className={classes.agentLogo}
              src={
                properties.agency && properties.agency.logoUrl
                  ? properties.agency.logoUrl
                  : getAgencyLogo(
                      properties?.reaAgencyId || properties.agency?.reaAgencyId
                    )
              }
            />
            {showAgentAvatar && properties.agent && (
              <div className={classes.avatarContainer}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html:
                      properties.agent.name ??
                      properties.agent.firstName + ' ' + properties.agent.lastName,
                  }}
                  className={classes.avatarText}
                  variant="caption"
                  component="p"
                  style={{
                    color: checkIfLight(brandColor) ? '#000000' : '#ffffff',
                  }}
                ></Typography>
                <Avatar
                  className={classes.avatarImage}
                  src={AgentImage(properties.agent.image)}
                />
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  function renderAgentBannerPropertyScreen() {
    if (isMultipleAgents) {
      return (
        <>
          {showBanner && (
            <div
              className={classes.root}
              style={{
                backgroundColor: brandColor,
                boxShadow: checkIfLight(brandColor) ? '0 0 0 1px #000000' : 'none',
              }}
            >
              <img
                className={classes.agentLogo}
                style={{ maxWidth: '190px' }}
                src={
                  properties.agency && properties.agency.logoUrl
                    ? properties.agency.logoUrl
                    : getAgencyLogo(
                        properties?.reaAgencyId || properties.agency?.reaAgencyId
                      )
                }
              />
            </div>
          )}
          <div>
            {properties.agents.map((agent, index) => {
              const hasTelephone = agent.telephone ? true : agent.phone ? true : false

              if (hasTelephone) {
                return (
                  <Row key={'row-' + index}>
                    <div>
                      <Col>
                        <Avatar className="avatar" src={AgentImage(agent.image)} />
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: agent.name ? agent.name : '',
                          }}
                          className={classes.agentName}
                          variant="body2"
                          component="p"
                        ></Typography>
                      </Col>

                      {agent.telephone && (
                        <Col style={{ gap: '10px' }}>
                          <Button
                            href={`sms:${agent.telephone};?&body=${properties?.propertyStatus !== 'offmarket' ? properties?.addressText : ''} ${window.location.href} (Please enter your name and enquiry here)`}
                            className="btn-tel"
                            startIcon={<MessageIcon />}
                            style={{ marginTop: agent.telephone ? 5 : 0 }}
                            fullWidth
                            variant="outlined"
                            onClick={() => ''}
                          >
                            SMS
                          </Button>
                          <Button
                            href={`tel:${agent.telephone}`}
                            className="btn-tel"
                            startIcon={<PhoneIcon />}
                            fullWidth
                            variant="outlined"
                            onClick={() => ''}
                          >
                            CALL
                          </Button>
                        </Col>
                      )}
                    </div>
                  </Row>
                )
              }
            })}
          </div>
        </>
      )
    } else {
      return (
        <>
          {showBanner && (
            <div
              className={classes.root}
              style={{
                backgroundColor: brandColor,
                boxShadow: checkIfLight(brandColor) ? '0 0 0 1px #000000' : 'none',
              }}
            >
              <img
                className={classes.agentLogo}
                style={{ maxWidth: '190px' }}
                src={
                  properties.agency && properties.agency.logoUrl
                    ? properties.agency.logoUrl
                    : getAgencyLogo(
                        properties?.reaAgencyId || properties.agency?.reaAgencyId
                      )
                }
              />
              {showAgentAvatar && properties.agent && (
                <div className={classes.avatarContainer}>
                  <Typography
                    dangerouslySetInnerHTML={{ __html: properties.agent.name }}
                    className={classes.avatarText}
                    variant="caption"
                    component="p"
                    style={{
                      color: checkIfLight(brandColor) ? '#000000' : '#ffffff',
                    }}
                  ></Typography>
                  <Avatar
                    className={classes.avatarImageProperty}
                    src={AgentImage(properties.agent.image)}
                  />
                </div>
              )}
            </div>
          )}
        </>
      )
    }
  }

  return (
    <>
      {type === 'search' && renderAgentBannerResultScreen()}
      {type === 'property' && renderAgentBannerPropertyScreen()}
    </>
  )
}

export default withStyles(styles)(AgentBanner)
