import { PURGE } from 'redux-persist';
import _ from 'lodash'
import {
  LOGIN_USER_SUCCESS,
  FACEBOOK_AUTH_SUCCESS,
  GOOGLE_AUTH_SUCCESS,
  APPLE_AUTH_SUCCESS,
  SET_USER,
  GET_USER_SUCCESS,
  LOGOUT_REQUEST,
  CLEAR_SESSION,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
} from '../constants';

const INITIAL_STATE = {
  user: null,
  user_profile: null,
  agent: null,
  agency: null,
  update_user_status: null, // progress, success, error
  update_user_error: null,
  agency_admin: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    /** Clear Session */
    case CLEAR_SESSION:
    case LOGOUT_REQUEST:
      return {
        ...INITIAL_STATE
      }

    /** LOGIN ACTIONS */
    case SET_USER:
    case GET_USER_SUCCESS:
    case LOGIN_USER_SUCCESS:
    case FACEBOOK_AUTH_SUCCESS:
    case GOOGLE_AUTH_SUCCESS:
    case APPLE_AUTH_SUCCESS:
      return {
        ...state,
        user: action.payload.data.data.user,
        user_profile: action.payload.data.data,
        agent: action.payload.data.data.agent,
        agency: action.payload.data.data.agency,
        agency_admin: action.payload.data.data.agencyAdmin
      };
    case UPDATE_USER:
      return {
        ...state,
        update_user_error: null,
        update_user_status: 'progress',
        request: {
          ...state.request,
          current: 'update_user',
          params: action.params
        }
      }
    case UPDATE_USER_SUCCESS:
      let obj = Object.assign(state.user);
      _.forEach(action.payload.data.data, (item, key) => {
        obj[key] = item;
      })
      return {
        ...state,
        user: obj,
        update_user_error: null,
        update_user_status: 'success',
        request: {
          ...state.request,
          current: null,
          previous: 'update_user',
          params: action.params
        }
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        update_user_error: action.error,
        update_user_status: 'error',
        request: {
          ...state.request,
          current: null,
          previous: 'update_user',
          params: null
        }
      }
    case PURGE:
      return INITIAL_STATE

    default: return state;
  }

};

export default userReducer