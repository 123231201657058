import React, { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles,
  Box,
} from '@material-ui/core';
/** COMPONENTS */
import PropertyType from 'components/Web/Form/PropertyType';
import SliderContainer from 'components/Web/Form/SliderContainer';
/** DEPENDENCIES */

import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import _ from 'lodash';

/** API */
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils';
/** CONSTANTS */
import { PROPERTY_TYPE, PROPERTY_DETAILS_UPLOAD } from 'components/Web/Filter/DefaultValues';
import HeadingContentSmall from 'components/Web/Form/HeadingContentSmall';
import FormInput from 'components/Form/FormInput';
import SelectField from 'components/Web/Form/SelectField';
import { STATES } from 'constants/constants';
import SuburbSearchField from '../Fields/SuburbSearchField';
import { useRef } from 'react';
import { addressFormatter } from 'utils/formatter/addressFormatter';

/** STYLES */
const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
    color: '#4d4d4d',
  },
  propertyInformation: {
    maxWidth: 442,
    width: '100%',
    padding: '13px 31px 38px 39px',
    '@media (max-width: 1024px)': {
      maxWidth: 390,
    },
    '@media (max-width: 768px)': {
      maxWidth: 427,
    },
  },
  title: {
    fontWeight: 300,
    fontSize: 21,
    lineHeight: '31.92px',
    letterSpacing: '-0.52px',
    marginBottom: 6,
    color: '#000',
  },
  googleWrap: {
    marginBottom: 28,
    '& .css-1hb7zxy-IndicatorsContainer': {
      display: 'none',
    },
    '& .css-yk16xz-control': {
      border: '1px solid #ced4da !important',
      fontSize: '16px !important',
      borderRadius: '4px !important',
      backgroundColor: '#fff !important',
      height: 56,
      outline: 'none !important',
      transition: 'border-color 0.3s ease',
    },
    '& .css-g1d714-ValueContainer': {
      height: 56,
      outline: 'none !important',
    },
    '& .css-1wa3eu0-placeholder': {
      fontFamily: 'Roboto',
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.35)',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    '& .css-1uccc91-singleValue': {
      fontFamily: 'Roboto',
      fontSize: 16,
    },
    '& input, .css-b8ldur-Input, .css-b8ldur-Input div': {
      fontFamily: 'Roboto',
      fontSize: '16px !important',
    },
    '& .css-26l3qy-menu, .css-26l3qy-menu *': {
      fontFamily: 'Roboto',
    },
    '& .css-1pahdxg-control': {
      boxShadow: 'none !important',
      borderRadius: '4px !important',
      borderColor: '#35c0ca !important',
      height: 56,
    },
  },
  checkWrap: {
    padding: '8px 0 16px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    justifyContent: 'start',
    alignItems: 'start',
  },
  formLabel: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const AddressField = ({ property, updateCallback }) => {
  const [suburbFieldOpen, setSuburbFieldOpen] = useState(false);
  const [suburb, setSuburb] = useState();
  const [suburbInputValue, setSuburbInputValue] = useState();

  const suburbRef = useRef(property.addressSuburb);

  useEffect(() => {
    if (suburb) {
      updateCallback({
        addressSuburb: suburb.locality,
        addressPostcode: suburb.postcode,
        addressState: suburb.state,
      });
    }
  }, [suburb]);

  useEffect(() => {
    if (property) {
      updateCallback({
        addressText: addressFormatter(property?.propertyType, {
          subNumber: property?.addressSubNumber?.trim(),
          lotNumber: property?.addressLotNumber?.trim(),
          streetNumber: property?.addressStreetNumber?.trim(),
          streetName: property?.addressStreetName?.trim(),
          suburb: property?.addressSuburb,
          state: property?.addressState,
          postcode: property?.addressPostcode,
        }),
      });
    }
  }, [
    property?.propertyType,
    property?.addressSubNumber,
    property?.addressLotNumber,
    property?.addressStreetNumber,
    property?.addressStreetName,
    property?.addressSuburb,
    property?.addressPostcode,
    property?.addressState,
  ]);
  return (
    <Box maxWidth="550px">
      <Box display="grid" gridAutoFlow="column" gridGap="20px">
        <FormInput
          fullWidth
          id="unitNumber"
          name="unitNumber"
          label="Unit No."
          variant="outlined"
          value={property?.addressSubNumber ?? ''}
          onChange={(event) => {
            updateCallback({ addressSubNumber: event.target.value });
          }}
          // error={errors?.firstName?.status}
          // errorMessage={errors?.firstName?.message}
        />
        {property?.propertyType === 'Land' && (
          <FormInput
            required
            fullWidth
            id="lotNumber"
            name="lotNumber"
            label="Lot No."
            variant="outlined"
            value={property.addressLotNumber ?? ''}
            onChange={(event) => {
              updateCallback({ addressLotNumber: event.target.value });
            }}
            // error={errors?.firstName?.status}
            // errorMessage={errors?.firstName?.message}
          />
        )}
        <FormInput
          required
          fullWidth
          id="streetNumber"
          name="streetNumber"
          label="Street No."
          variant="outlined"
          value={property?.addressStreetNumber ?? ''}
          onChange={(event) => {
            updateCallback({ addressStreetNumber: event.target.value });
          }}
          // error={errors?.firstName?.status}
          // errorMessage={errors?.firstName?.message}
        />
      </Box>
      <FormInput
        required
        fullWidth
        id="streetName"
        name="streetName"
        label="Street Name"
        variant="outlined"
        value={property?.addressStreetName ?? ''}
        onChange={(event) => {
          updateCallback({ addressStreetName: event.target.value });
        }}
        // error={errors?.firstName?.status}
        // errorMessage={errors?.firstName?.message}
      />
      {property?.addressSuburb && (
        <Box marginTop="18px">
          <SuburbSearchField
            name="suburb"
            value={suburb}
            defaultValue={{
              locality: suburbRef.current ?? '',
            }}
            inputValue={suburbInputValue}
            setValueCallback={setSuburb}
            open={suburbFieldOpen}
            setOpen={setSuburbFieldOpen}
            placeholder={'Suburb *'}
            onInputChange={(e, value) => {
              if (value) {
                setSuburbInputValue(value);
              } else {
                setSuburbFieldOpen(false);
              }
            }}
          />
        </Box>
      )}

      <Box width="100%" display="flex">
        <Box width="200px">
          <FormInput
            required
            fullWidth
            id="postcode"
            name="postcode"
            label="Postcode"
            variant="outlined"
            value={property?.addressPostcode ?? ''}
            onChange={(event) => {
              updateCallback({ addressPostcode: event.target.value });
            }}
            // error={errors?.firstName?.status}
            // errorMessage={errors?.firstName?.message}
          />
        </Box>
        <Box marginLeft="20px" width="150px" marginTop="18px">
          <Typography style={{ fontSize: '12px', color: 'gray' }}>State *</Typography>
          <Box marginTop="-10px">
            <SelectField
              options={STATES}
              placeholder={'State'}
              value={property?.addressState ?? ''}
              onChange={(e, value) => {
                updateCallback({ addressState: value });
              }}
              onBlur={(e, value) => {
                updateCallback({ addressState: value });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const EditPropertyInformation = (props) => {
  const { classes, updateCallback, property, disabled, handleBucketChanges, propertyStatus, setPropertyStatus } = props;

  const [propertyAddress, setPropertyAddress] = useState(null);
  const [propertyType, setPropertyType] = useState(null);
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [hidePrice, setHidePrice] = useState(false);
  const [price, setPrice] = useState(50000);
  const [geoCodedDetails, setGeoCodedDetails] = useState(null);
  const [deconstructedAddress, setDeconstructedAddress] = useState(null);

  const [detailsInit, setDetailsInit] = useState(0);

  const [activePropertyType, setActivePropertyType] = useState();

  const handleGooglePlaces = (data) => {
    setPropertyAddress(data);
  };

  const handlePropertyType = (data) => {
    setPropertyType(data);
  };

  const handlePropertyDetails = (data) => {
    setPropertyDetails(data);
    handleBucketChanges(data);
    setDetailsInit(2);
  };

  const handlePropertyStatus = (val) => {
    setPropertyStatus(val);
  };

  const setType = () => {
    let ptype = null;
    propertyType.map((item, index) => {
      if (item.active) {
        ptype = item.title;
      }
    });

    return ptype;
  };

  useEffect(() => {
    if (propertyAddress && propertyAddress.value) {
      geocodeByPlaceId(propertyAddress.value.place_id)
        .then((results) => setGeoCodedDetails(results[0]))
        .catch((error) => setGeoCodedDetails(null));
    }
  }, [propertyAddress]);

  useEffect(() => {
    if (propertyType) {
      updateCallback({ propertyType: setType(), addressLotNumber: '' });
    }
  }, [propertyType]);

  useEffect(() => {
    if (propertyStatus) {
      updateCallback({ propertyStatus: propertyStatus });
    }
  }, [propertyStatus]);

  useEffect(() => {
    if (propertyDetails) {
      const params = {
        ...propertyDetails,
        carports: propertyDetails.carparking,
        totalParking: propertyDetails.carparking,
        // priceView: propertyDetails.showPrice === false ? 'Contact Agent' : null,
      };

      delete params.carparking;
      delete params.priceRent;
      delete params.priceRangeMin;
      delete params.priceRangeMax;

      updateCallback(params);
    }
  }, [propertyDetails]);

  // useEffect(() => {
  //   if (deconstructedAddress && deconstructedAddress.addressPostcode) {
  //     updateCallback({ ...deconstructedAddress })
  //   }
  // }, [deconstructedAddress])

  // useEffect(() => {
  //   if (property) {
  //     setPropertyType(property.propertyType)
  //   }
  // }, [property?.propertyType])

  useEffect(() => {
    if (property) {
      if (!propertyAddress) {
        handleGooglePlaces(property.addressText);
      }

      if (!propertyStatus) {
        handlePropertyStatus(property.propertyStatus);
      }

      if (
        !propertyDetails &&
        property.bathrooms !== undefined &&
        property.bedrooms !== undefined &&
        property.totalParking !== undefined &&
        property.price !== undefined
      ) {
        const detailsDefault = {
          bathrooms: property.bathrooms,
          bedrooms: property.bedrooms,
          carparking: property.totalParking,
          price: property.price,
          showPrice: property.priceView ? false : true,
          priceRent: PROPERTY_DETAILS_UPLOAD.priceRent,
        };

        setPropertyDetails(detailsDefault);
        setPrice(detailsDefault.price);
        setHidePrice(property.priceView ? false : true);
      }

      setActivePropertyType(property.propertyType);
    }
  }, [property]);

  // useEffect(() => {
  //   if (property) {
  //     setActivePropertyType(property?.propertyType)
  //   }
  // }, [property])

  // useEffect(() => {
  //   if (geoCodedDetails) {
  //     let details = {}
  //     details.addressPostcode =
  //       _.find(geoCodedDetails.address_components, { types: ['postal_code'] })
  //         .short_name ?? ''
  //     details.addressState =
  //       _.find(geoCodedDetails.address_components, {
  //         types: ['administrative_area_level_1', 'political'],
  //       }).short_name ?? ''
  //     details.addressMunicipality = _.find(geoCodedDetails.address_components, {
  //       types: ['administrative_area_level_2', 'political'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, {
  //           types: ['administrative_area_level_2', 'political'],
  //         }).short_name
  //       : ''
  //     details.addressSuburb = _.find(geoCodedDetails.address_components, {
  //       types: ['locality', 'political'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, {
  //           types: ['locality', 'political'],
  //         })?.short_name?.toUpperCase()
  //       : ''
  //     details.addressStreetName = _.find(geoCodedDetails.address_components, {
  //       types: ['route'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, { types: ['route'] }).short_name
  //       : ''
  //     details.addressStreetNumber = _.find(geoCodedDetails.address_components, {
  //       types: ['street_number'],
  //     })
  //       ? _.find(geoCodedDetails.address_components, {
  //           types: ['street_number'],
  //         }).short_name
  //       : ''

  //     if (details) {
  //       var lotNumber = details?.lotNumber ?? '',
  //         streetName = details.addressStreetName,
  //         streetNumber = details.addressStreetNumber,
  //         subNumber = details?.subNumber ?? '',
  //         suburb = details.addressSuburb,
  //         municipality = details.addressMunicipality,
  //         state = details.addressState,
  //         postcode = details.addressPostcode

  //       const fullAddressText =
  //         '' +
  //         lotNumber +
  //         ' ' +
  //         streetNumber +
  //         ' ' +
  //         streetName +
  //         ' ' +
  //         subNumber +
  //         ' ' +
  //         suburb +
  //         ', ' +
  //         municipality +
  //         ' ' +
  //         state +
  //         ' ' +
  //         postcode
  //       details.addressText = fullAddressText.replace(/ {1,}/g, ' ').trim()
  //       const addressArr = Object.keys(details)

  //       setDeconstructedAddress(details)
  //     }
  //   }
  // }, [geoCodedDetails])

  return (
    <>
      <Paper
        elevation={3}
        className={classes.propertyInformation}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        <Typography variant={'h2'} className={classes.title}>
          Property Information
        </Typography>
        {/* <div className={classes.googleWrap}>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_MAP_API_KEY}
            selectProps={{
              placeholder: propertyAddress ?? 'Property Address',
              value: propertyAddress,
              onChange: handleGooglePlaces,
            }}
            autocompletionRequest={{
              componentRestrictions: { country: 'au' },
              fields: ['address_components', 'geometry'],
              types: ['address'],
            }}
          />
        </div> */}
        {property && (
          <Box>
            <AddressField property={property} updateCallback={updateCallback} />
          </Box>
        )}

        {/* GOOGLE PLACES */}
        {/* END OF GOOGLE PLACES */}
        <Typography variant={'h2'} className={classes.title} style={{ fontSize: 20, fontWeight: 400 }}>
          Select Property Type
        </Typography>
        {/* CHECKBOX */}
        <div className={classes.checkWrap}>
          <FormControl>
            <FormGroup row>
              <FormControlLabel
                className={classes.formLabel}
                labelPlacement="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={propertyStatus === 'comingsoon'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handlePropertyStatus('comingsoon');
                      } else {
                        handlePropertyStatus('offmarket');
                      }
                    }}
                  />
                }
                label="Coming soon"
                style={{ margin: 0 }}
              />
              <FormControlLabel
                className={classes.formLabel}
                labelPlacement="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={propertyStatus === 'offmarket'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        handlePropertyStatus('offmarket');
                      } else {
                        handlePropertyStatus('comingsoon');
                      }
                    }}
                  />
                }
                label="Off Market"
              />
            </FormGroup>
          </FormControl>
        </div>
        {/* END OF CHECKBOX */}
        {/* PROPERTY TYPE */}
        {activePropertyType && (
          <PropertyType
            handlePropertyType={handlePropertyType}
            propertyType={PROPERTY_TYPE}
            activeProperty={activePropertyType}
          />
        )}
        {/* END OF PROPERTY TYPE */}
        {/* PRICE */}
        {propertyDetails && (
          <SliderContainer
            handlePropertyDetails={handlePropertyDetails}
            propertyDetails={propertyDetails}
            hidePrice={hidePrice}
            setHidePrice={setHidePrice}
            price={price}
          />
        )}
        {/* END OF PRICE */}
      </Paper>
    </>
  );
};

export default withStyles(styles)(EditPropertyInformation);
