import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  FormControl,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Select,
  Checkbox,
  Menu,
} from '@material-ui/core'
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp'

import { formatUSD } from 'utils/formatter/priceFormatter'
import useFilters from 'utils/customHooks/useFilters'
import { RENT_PRICE_MARKS, PRICE_MARKS } from 'constants/globalfilters'
import convertPriceToScaledValue from 'utils/formatter/convertPriceToScaledValue'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const useStyles = makeStyles((theme) => ({
  filterMainContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxText: {
    fontSize: 10,
    fontWeight: 'normal',
    color: '#000000',
    margin: '0px',
  },

  // Dropdowns
  propertyTypeSelectContainer: {
    width: '40%',
  },

  priceSelectContainer: {
    width: '12.5vw',
    maxWidth: '210px',
  },

  selectDropdown: {
    display: 'flex',
    justifyContent: 'center',
    border: '0px',
    height: '35px',

    paddingTop: '5px',

    '& .MuiSelect-icon': {
      right: '12px',
      color: 'black',
    },

    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: '0px',
    },

    '&::before': {
      border: '0px',
      '&:hover': {
        border: '0px',
      },
    },

    '&::after': {
      border: '0px',
    },

    [theme.breakpoints.down(1340)]: {
      fontSize: '12px',
      paddingTop: '0px',
    },
  },

  selectItem: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '15.6px',
    marginLeft: '20px',
  },

  priceRangeContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '10px',

    [theme.breakpoints.down(1200)]: {
      gridGap: '5px',
    },
  },

  selectPaper: {
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EF6631',
    },
  },

  //Checkbox
  checkboxContainer: {
    color: '#000000',
  },
  checkedCheckbox: {
    color: '#323232',
  },
}))

const PRICE_RANGE = [
  50000, 75000, 100000, 125000, 150000, 175000, 200000, 225000, 250000, 275000, 300000,
  325000, 350000, 375000, 400000, 425000, 450000, 475000, 500000, 550000, 600000,
  650000, 700000, 750000, 800000, 850000, 900000, 950000, 1000000, 1100000, 1200000,
  1300000, 1400000, 1500000, 1600000, 1700000, 1800000, 1900000, 2000000, 2250000,
  2500000, 2750000, 3000000, 3500000, 4000000, 4500000, 5000000, 6000000, 7000000,
  8000000, 9000000, 10000000, 11000000, 12000000, 13000000, 14000000, 15000000,
]

const RENT_PRICE_RANGE = [
  50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300, 325, 350, 375, 400, 425, 450,
  475, 500, 525, 550, 575, 600, 625, 650, 675, 700, 725, 750, 800, 850, 900, 950, 1000,
  1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2500, 3000, 3500, 4000,
  4500, 5000,
]

const calculatePriceOptions = (price, priceType, isRentPrice) => {
  if (isRentPrice) {
    return priceType === 'min'
      ? RENT_PRICE_RANGE.filter((priceOption) => priceOption < price)
      : RENT_PRICE_RANGE.filter((priceOption) => priceOption > price)
  }

  return priceType === 'min'
    ? PRICE_RANGE.filter((priceOption) => priceOption < price)
    : PRICE_RANGE.filter((priceOption) => priceOption > price)
}

const checkIfRentSearch = (searchButtons) => {
  return searchButtons.some((button) => button.active === true && button.id === 'rent')
}

const checkPriceDefault = (price, priceDefault) => {
  return price === priceDefault
}

// COMPONENTS-------------------------------------------------------------------------------------------
const PropertyTypeSelect = ({ options }) => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const PROPERTY_TYPES = getFilter('property_type')
  const { triggerAnalyticsEvent } = useGoogleEvents()

  const activePropertyTypes = options.filter(
    (propertyType) => propertyType.active === true
  )

  const [propertyTypeAll, setPropertyTypeAll] = useState(
    () => options.length === activePropertyTypes.length
  )

  const handleAllClicked = (isAllClicked) => {
    const filters = PROPERTY_TYPES.map((filter) => {
      return { ...filter, active: isAllClicked }
    })

    setFilter({
      key: 'property_type',
      value: filters,
    })
    setPropertyTypeAll(isAllClicked)
  }

  const handlePropertyTypeChange = (inputActivePropertyTypes) => {
    let changedFilter
    const currentlyClickedFilter =
      inputActivePropertyTypes[inputActivePropertyTypes.length - 1]

    // Check if clicked filter is all
    if (currentlyClickedFilter === 'all') {
      return !propertyTypeAll ? handleAllClicked(true) : handleAllClicked(false)
    }

    // If all is not clicked make clicked filter into active or not active
    changedFilter = PROPERTY_TYPES.filter(
      (filter) => filter.id === currentlyClickedFilter
    )[0]

    setFilter({
      key: 'property_type',
      value: { ...changedFilter, active: !changedFilter.active },
    })
  }

  const renderPropertyTypeText = (inputActivePropertyTypes) => {
    // If all property types are active
    if (propertyTypeAll) {
      return 'All'
    }

    // // For 1 active property types
    if (inputActivePropertyTypes.length === 1) {
      return inputActivePropertyTypes[0].title
    }

    // // For many active property types
    return `${
      inputActivePropertyTypes[0].title === 'Apartment & Unit'
        ? 'Apt. & Unit'
        : inputActivePropertyTypes[0].title
    }, ${inputActivePropertyTypes.length - 1}+`
  }

  useEffect(() => {
    if (activePropertyTypes.length === options.length) {
      setPropertyTypeAll(true)
    } else {
      setPropertyTypeAll(false)
    }
  }, [activePropertyTypes, options])

  return (
    <FormControl className={classes.propertyTypeSelectContainer} variant="standard">
      <Select
        style={{
          backgroundColor: 'white',
          border: '0.5px solid black',
          borderRadius: '20px',
        }}
        className={classes.selectDropdown}
        value={activePropertyTypes ? activePropertyTypes : ''}
        onChange={(event) => {
          handlePropertyTypeChange(event.target.value)
        }}
        displayEmpty
        renderValue={(field) => {
          return field.length > 0 ? (
            <Box className={classes.selectItem}>{renderPropertyTypeText(field)}</Box>
          ) : (
            <Box className={classes.selectItem}>Property Types</Box>
          )
        }}
        MenuProps={{
          getContentAnchorEl: () => null,

          PaperProps: {
            className: classes.selectPaper,
            style: { marginTop: '40px', width: '180px', maxHeight: '250px' },
          },
        }}
        IconComponent={KeyboardArrowDownSharpIcon}
        multiple={true}
      >
        <MenuItem disabled value="">
          Property Types
        </MenuItem>
        <MenuItem
          key="all"
          value="all"
          style={{ backgroundColor: propertyTypeAll ? '#CCCCCC' : 'white' }}
        >
          All
        </MenuItem>
        {options.map((field) => (
          <MenuItem
            style={{ backgroundColor: field.active ? '#CCCCCC' : 'white' }}
            key={field.id}
            value={field.id}
          >
            {field.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const PriceSelect = ({ value, onInputChange, options, label, name }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.priceSelectContainer} variant="standard">
      <Select
        style={{
          backgroundColor: 'white',
          border: '0.5px solid black',
          borderRadius: '20px',
        }}
        className={classes.selectDropdown}
        value={value ? value : ''}
        onChange={onInputChange}
        displayEmpty
        renderValue={(field) => {
          const isPriceDefaultValue =
            name === 'min'
              ? checkPriceDefault(field, options[0])
              : checkPriceDefault(field, options[options.length - 1])

          // Check if price is default
          if (isPriceDefaultValue) {
            return <Box className={classes.selectItem}>{label}</Box>
          }

          return field ? (
            <Box className={classes.selectItem}>{formatUSD(field)}</Box>
          ) : (
            <Box className={classes.selectItem}>{label}</Box>
          )
        }}
        MenuProps={{
          getContentAnchorEl: () => null,
          PaperProps: {
            className: classes.selectPaper,
            style: { marginTop: '40px', width: '140px', maxHeight: '250px' },
          },
        }}
        name={name}
        IconComponent={KeyboardArrowDownSharpIcon}
      >
        <MenuItem disabled value="">
          {label}
        </MenuItem>
        {options.map((field) => (
          <MenuItem key={field} value={field}>
            {formatUSD(field)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const HomeFilters = () => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const PROPERTY_TYPES = getFilter('property_type')
  const PRICE = getFilter('price')
  const RENT_PRICE = getFilter('price_rent')
  const INCLUDE_NEARBY_SUBURBS = getFilter('include_nearby_suburbs')
  const SEARCH_BUTTONS = getFilter('search_reference')

  const isRentPrice = checkIfRentSearch(SEARCH_BUTTONS)

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const priceMinOptions = calculatePriceOptions(
    isRentPrice ? RENT_PRICE.max : PRICE.max,
    'min',
    isRentPrice ? true : false
  )
  const priceMaxOptions = calculatePriceOptions(
    isRentPrice ? RENT_PRICE.min : PRICE.min,
    'max',
    isRentPrice ? true : false
  )

  const handlePriceChange = (event) => {
    const scaledValue =
      event.target.name === 'min'
        ? [convertPriceToScaledValue(event.target.value, PRICE_MARKS), PRICE?.scaled[1]]
        : [PRICE?.scaled[0], convertPriceToScaledValue(event.target.value, PRICE_MARKS)]

    setFilter({
      key: 'price',
      value: {
        ...PRICE,
        [event.target.name]: event.target.value,
        scaled: scaledValue,
      },
    })
  }

  const handleRentPriceChange = (event) => {
    const scaledValue =
      event.target.name === 'min'
        ? [
            convertPriceToScaledValue(event.target.value, RENT_PRICE_MARKS),
            RENT_PRICE.scaled[1],
          ]
        : [
            RENT_PRICE.scaled[0],
            convertPriceToScaledValue(event.target.value, RENT_PRICE_MARKS),
          ]

    setFilter({
      key: 'price_rent',
      value: {
        ...RENT_PRICE,
        [event.target.name]: event.target.value,
        scaled: scaledValue,
      },
    })
  }

  return (
    <Box className={classes.filterMainContainer}>
      <FormControlLabel
        style={{ color: 'black', marginLeft: '5px' }}
        control={
          <Checkbox
            classes={{
              root: classes.checkboxContainer,
              checked: classes.checkedCheckbox,
            }}
            color="default"
            name="includeNearbySuburbs"
            checked={INCLUDE_NEARBY_SUBURBS}
            onChange={(event) => {
              setFilter({
                key: 'include_nearby_suburbs',
                value: !INCLUDE_NEARBY_SUBURBS,
              })
            }}
          />
        }
        label="Include nearby suburbs"
      />

      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        marginTop="20px"
      >
        <PropertyTypeSelect options={PROPERTY_TYPES} />
        <Box className={classes.priceRangeContainer}>
          <PriceSelect
            value={isRentPrice ? RENT_PRICE.min : PRICE.min}
            name="min"
            options={priceMinOptions}
            label="Price (min)"
            onInputChange={isRentPrice ? handleRentPriceChange : handlePriceChange}
          />
          <PriceSelect
            value={isRentPrice ? RENT_PRICE.max : PRICE.max}
            name="max"
            options={priceMaxOptions}
            label="Price (max)"
            onInputChange={isRentPrice ? handleRentPriceChange : handlePriceChange}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default HomeFilters
