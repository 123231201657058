import React, { useEffect, useState } from 'react'
import Image from 'material-ui-image'
import HeartFullIcon from '@material-ui/icons/Favorite'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import HeartIcon from '@material-ui/icons/FavoriteBorder'

import PropertySuggestionsAgentBanner from './PropertySuggestionsAgentBanner'
import PropertySuggestionsInfo from './PropertySuggestionsInfo'
import NotificationDialog from '../CustomDialogs/NotificationDialog'

import IMGComingSoon from '../../../assets/images/coming_soon.svg'
import IMGOffMarket from '../../../assets/images/off_market.svg'
import { requestPropertySave } from '../../../store/api'
import { toggleSignUp, logoutRequest } from '../../../store/actions'

import { withStyles, Card, IconButton } from '@material-ui/core'
import { requestPUTAPIMpp } from 'store/api/mpp.api'
import { useDispatch, useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: 'auto',
    position: 'relative',
    height: '100%',
  },
  card: {
    width: '100%',
    minHeight: 364,
    position: 'relative',
  },
  media: {
    width: '100%',
    height: '6.250em',
    objectFit: 'cover !important',
  },
  favoriteContainer: {
    width: '0.625em',
    height: '0.625em',
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
    width: '0.625em',
    height: '0.625em',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
})

const PropertyCardFull = (props) => {
  const {
    classes,
    type,
    properties,
    trades,
    authentication,
    settings,
    toggleSignUp,
    setCurrentProperty,

    listingType,
    logoutUser,
  } = props

  const [routerChange] = useRouterChange()

  const [isSwiping, setIsSwiping] = useState(false)
  const [heartClicked, setHeartClicked] = useState(false)
  const [heartActionError, setHeartActionError] = useState(false)

  const dispatch = useDispatch()
  const saveProperty = (params) =>
    dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'))

  function checkLoginStatus() {
    setHeartClicked(true)
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
    } else {
      /** User Logged */
      apiRequestSaveProperty(properties.property)
    }
  }

  const MPP = useSelector(getMpp)
  let likes = MPP?.favourites?.data?.likes

  useEffect(() => {
    if (
      MPP?.error &&
      MPP?.status === 'mpp/PUT_MPP_FAVOURITES_FAILED' &&
      heartClicked &&
      authentication &&
      authentication.user_status === 'logged'
    ) {
      setHeartActionError(true)
      logoutUser()
      setHeartClicked(false)
    }
    if (!MPP?.error && MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      setHeartActionError(false)
      setHeartClicked(false)
    }
  }, [MPP, heartClicked, authentication])

  /** Save Property API MPP*/
  async function apiRequestSaveProperty(id) {
    let params = {
      propertyId: id,
    }
    saveProperty(params)
  }

  function imageType(type, image) {
    switch (type) {
      case 'auction':
        return image
      case 'comingsoon':
        return IMGComingSoon
      case 'offmarket':
        return IMGOffMarket
      default:
        return image
    }
  }

  function selectProperty(willScroll) {
    if (!isSwiping) {
      if (setCurrentProperty) {
        setCurrentProperty(properties)
      }
      if (willScroll) {
        routerChange('/' + listingType + '/' + properties.property, {
          scrollToAgent: true,
          previous_screen: props.listingType,
        })
      } else {
        routerChange('/' + listingType + '/' + properties.property, {
          previous_screen: props.listingType,
        })
      }
    }
  }

  function renderProperties() {
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <Image
            className={classes.media}
            src={imageType(properties.propertyStatus, properties.images[0])}
            onClick={() => selectProperty()}
            aspectRatio={16 / 9}
            disableSpinner
          />
          <IconButton
            className={classes.favoriteContainer}
            onClick={(e) => {
              e.preventDefault()
              checkLoginStatus()
            }}
          >
            {authentication &&
              authentication.user_status === 'logged' &&
              likes &&
              likes?.includes(properties?.property) ? (
              <HeartFullIcon className={classes.favoriteIconActive} />
            ) : (
              <HeartIcon className={classes.favoriteIcon} />
            )}
          </IconButton>
          <PropertySuggestionsAgentBanner properties={properties} />
          <PropertySuggestionsInfo type={type} properties={properties} />
        </Card>
        {heartActionError && (
          <NotificationDialog
            isOpen={heartActionError}
            closeCallback={() => {
              setHeartActionError(false)
            }}
            title={'Error'}
            text={
              MPP?.error?.status === 401 || MPP?.error?.status === 400
                ? 'Your session has expired or invalid. You will be asked to re-login after few seconds.'
                : 'Error encountered making this property favorite.'
            }
            showLogo={true}
            align={'center'}
          />
        )}
      </div>
    )
  }

  function renderTrades() {
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <Image
            className={classes.media}
            src={imageType('', trades.image)}
            onClick={() => ''}
            aspectRatio={16 / 9}
            disableSpinner
          />
          <PropertySuggestionsInfo type={type} trades={trades} />
        </Card>
      </div>
    )
  }

  return (
    <>
      {type === 'properties' && renderProperties()}
      {type === 'trades' && renderTrades()}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      logoutUser: (params) => logoutRequest(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertyCardFull))
