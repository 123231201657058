import React from 'react';
import { withStyles, List, ListItem, ListItemText } from '@material-ui/core';

const styles = theme => ({
    root: {
    },
    listitem:{
        textAlign: 'center',
    }
});

const ListOptions = props => {
    const { 
        type,
        classes, 
        options  
    } = props;
    
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [data, setData] = React.useState(options);
    function onHandleClick(value, index){
        switch(type){
            case 'property':
                handlePropertySelect(value, index);
                break;
            case 'sort':
                handleSortSelect(value, index);
                break;
        }
    }

    const handlePropertySelect = (obj, index) => {
        let newData = data.map(item => 
            item.title === obj.title 
            ? {...item, active : !obj.active} 
            : item 
          )
        setData(newData)
        props.handleType(newData)
        
    };

    const handleSortSelect = (obj, index) => {
        let newData = options.map(item => 
            item.title === obj.title 
            ? {...item, active : !obj.active} 
            : {...item, active : false}  
          )

        setData(newData)
        props.handleType(newData)
    };

    return (
        <>
           <List component="nav">
               {
                   
                   data && data.map((value, index) => {
                        return(
                            <ListItem
                                key={index}
                                button
                                selected={value.active}
                                onClick={(event) => onHandleClick(value, index)}>
                                <ListItemText className={classes.listitem} primary={value.title} />
                            </ListItem>
                        )
                   })
               }
            </List>
        </>
    );
};

export default withStyles(styles)(ListOptions);
