import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Heading from "./Heading";
import CustomSwitch from "./CustomSwitch";
import DynamicIcons from "../DynamicIcons";
import { PROPERTY_ADVANCE } from "components/Web/Filter/DefaultValues";

const Container = styled.div`
  padding: 20px 0;
`;

const SwitchWrap = styled.div``;
const ToggleItem = styled.a`
  text-decoration: none;
  color: #4d4d4d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${(props) =>
    props.disabled === true &&
    css`
      pointer-events: none;
    `}
`;

const ToggleWrap = styled.div`
  display: none;
  ${(props) =>
    props.active === true &&
    css`
      display: block;
    `}
`;

const propertyListData = PROPERTY_ADVANCE

const AdvancedSearch = (props) => {
  let isState = false;

  props.data.map((item) => {
    if (item.active) {
      isState = true;
    }
  });

  const [state, setState] = useState(props.isMPP ? props.isMPP : isState);
  const [listData, setListData] = useState(props.data);

  useEffect(() => {
    if (props.isClearFilter) {
      setListData(propertyListData);
      if (props.isToggle) {
        setState(false);
      }
    }
  }, [props.isClearFilter]);

  useEffect(() => {
    if (props.data !== listData) {
      setListData(props.data);
    }
  }, [props.data]);


  const handleChange = (e, obj) => {
    let data = listData.map((item) =>
      item.id === obj.id ? { ...item, active: !obj.active } : item
    );
    setListData(data);
    if (props.handleAdvanceProperty) {
      props.handleAdvanceProperty(data);
    }
  };

  return (
    <Container>
      <ToggleItem
        href="#"
        disabled={!props.isToggle}
        onClick={(e) => {
          e.preventDefault();
          setState(!state);
        }}
      >
        <Heading variant="h6" style={{ fontWeight: 400 }}>
          {props.title}
        </Heading>
        {props.isToggle ? (
          state ? (
            <DynamicIcons icon="ExpandMore" />
          ) : (
            <DynamicIcons icon="ChevronRight" />
          )
        ) : null}
      </ToggleItem>
      <ToggleWrap active={state}>
        {props.subTitle && (
          <Heading
            variant="body1"
            style={{
              fontWeight: 400,
              color: "#4d4d4d",
              padding: "20px 0 5px",
              letterSpacing: "-0.525px",
            }}
          >
            {props.subTitle}
          </Heading>
        )}
        <SwitchWrap>
          {listData.map((item, index) => {
            return (
              <CustomSwitch
                key={index}
                label={item.label}
                checked={item.active}
                sublabel={item.sublabel}
                onChange={(e) => {
                  handleChange(e, item, index);
                }}
              />
            );
          })}
        </SwitchWrap>
      </ToggleWrap>
    </Container>
  );
};

export default AdvancedSearch;
