import { PURGE } from 'redux-persist';
import {
    REQUEST_UPLOAD_IMAGE,
    REQUEST_UPLOAD_IMAGE_SUCCESS,
    REQUEST_UPLOAD_IMAGE_FAIL,
    CLEAR_UPLOAD_IMAGE
} from '../constants';



const INITIAL_STATE = {
    request: null,
    upload_image_data: null,
    request_upload_image_status: null,
    request_upload_image_error: null,
    target: null
};

const uploadImageReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_UPLOAD_IMAGE:
            return {
                ...state,
                request: action.params,
                request_upload_image_status: 'progress',
                request_upload_image_error: null,
                target: action.target
            };
        case REQUEST_UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                request: action.params,
                upload_image_data: action.params.data,
                request_upload_image_status: 'success',
                request_upload_image_error: null,
                target: action.target
            }
        case REQUEST_UPLOAD_IMAGE_FAIL:
            return {
                ...state,
                request: action.params,
                request_upload_image_status: 'failed',
                request_upload_image_error: action.message,
                target: action.target

            }
        case CLEAR_UPLOAD_IMAGE:
            return INITIAL_STATE

        case PURGE:
            return INITIAL_STATE
        default: return state;
    }
}

export default uploadImageReducers

