import { BILLING_ACTION_TYPES } from 'store/constants';
import { PURGE } from 'redux-persist';

const INITIAL_STATE = {
  // temporary values
  payload_request: null,
  payload_success: null,
  payload_failed: null,
  status: null,
  error: null,

  // stored values
  billing_invoices: null,
  invoice_xero: null,
  packages: null,
  checkout: null,
  clientSecret: null,
  paymentMethodIntent: null,
  subscription_details: null,
};

const billingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BILLING_ACTION_TYPES['BILLING_GET_ALL_INVOICES']['REQUEST']:
    case BILLING_ACTION_TYPES['BILLING_GET_INVOICE_XERO']['REQUEST']:
    case BILLING_ACTION_TYPES['STRIPE_GET_PAYMENTMETHODS']['REQUEST']:
    case BILLING_ACTION_TYPES['STRIPE_REMOVE_PAYMENTMETHOD']['REQUEST']:
    case BILLING_ACTION_TYPES['STRIPE_DEFAULT_PAYMENTMETHOD']['REQUEST']:
    case BILLING_ACTION_TYPES['STANDALONE_PAYMENT_GET_AGENCY_DETAILS']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_SUBSCRIPTION_DETAILS']['REQUEST']:
      return {
        ...state,
        subscription_details: {
          status: action.type,
          error: null,
        },
      };

    case BILLING_ACTION_TYPES['BILLING_GET_ALL_INVOICES']['FAILED']:
    case BILLING_ACTION_TYPES['BILLING_GET_INVOICE_XERO']['FAILED']:
    case BILLING_ACTION_TYPES['STRIPE_GET_PAYMENTMETHODS']['FAILED']:
    case BILLING_ACTION_TYPES['STRIPE_REMOVE_PAYMENTMETHOD']['FAILED']:
    case BILLING_ACTION_TYPES['STRIPE_DEFAULT_PAYMENTMETHOD']['FAILED']:
    case BILLING_ACTION_TYPES['STANDALONE_PAYMENT_GET_AGENCY_DETAILS']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params.data,
      };
    case BILLING_ACTION_TYPES['STRIPE_GET_SUBSCRIPTION_DETAILS']['FAILED']:
      return {
        ...state,
        subscription_details: {
          status: action.type,
          error: action.params.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_REMOVE_PAYMENTMETHOD']['SUCCESS']:
    case BILLING_ACTION_TYPES['STRIPE_DEFAULT_PAYMENTMETHOD']['SUCCESS']:
    case BILLING_ACTION_TYPES['STANDALONE_PAYMENT_GET_AGENCY_DETAILS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_PAYMENTMETHODS']['SUCCESS']:
      return {
        ...state,
        status: action.type,

        payment_methods: {
          status: action.type,
          data: action.params.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_SUBSCRIPTION_DETAILS']['SUCCESS']:
      return {
        ...state,
        subscription_details: {
          status: action.type,
          data: action.params.data?.data,
          error: null,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_SUBSCRIPTION_DETAILS']['CLEAR']:
      return {
        ...state,
        subscription_details: null,
      };

    case BILLING_ACTION_TYPES['BILLING_GET_ALL_INVOICES']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        billing_invoices: action.params.data?.data,
      };

    case BILLING_ACTION_TYPES['BILLING_GET_ALL_INVOICES']['CLEAR']:
      return {
        ...state,
        billing_invoices: null,
      };

    case BILLING_ACTION_TYPES['BILLING_GET_INVOICE_XERO']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_success: action.params,
        invoice_xero: action.params.data,
      };

    case BILLING_ACTION_TYPES['BILLING_GET_INVOICE_XERO']['CLEAR']:
      return {
        ...state,
        invoice_xero: null,
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_PRICES']['REQUEST']:
      return {
        ...state,
        packages: {
          status: action.type,
          payload_request: action.params,
          error: null,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_PRICES']['SUCCESS']:
      return {
        ...state,
        packages: {
          status: action.type,
          payload_success: action.params,
          packages: action.params.data?.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_PRICES']['FAILED']:
      return {
        ...state,
        packages: {
          status: action.type,
          payload_failed: action.params,
          error: action.params?.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_GET_PRICES']['CLEAR']:
      return {
        ...state,
        packages: null,
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CHECKOUT']['REQUEST']:
      return {
        ...state,
        checkout: {
          status: action.type,
          payload_request: action.params,
          error: null,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CHECKOUT']['SUCCESS']:
      return {
        ...state,
        checkout: {
          status: action.type,
          payload_success: action.params,
          data: action.params.data?.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CHECKOUT']['FAILED']:
      return {
        ...state,
        checkout: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CHECKOUT']['CLEAR']:
      return {
        ...state,
        checkout: null,
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CLIENTSECRET']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CLIENTSECRET']['SUCCESS']:
      return {
        ...state,
        client_secret: {
          status: action.type,
          payload_success: action.params,
          data: action.params.data?.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CLIENTSECRET']['FAILED']:
      return {
        ...state,
        client_secret: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_CLIENTSECRET']['CLEAR']:
      return {
        ...state,
        client_secret: null,
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_PAYMENTMETHODINTENT']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_PAYMENTMETHODINTENT']['SUCCESS']:
      return {
        ...state,
        paymentMethodIntent: {
          status: action.type,
          payload_success: action.params,
          data: action.params.data?.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_PAYMENTMETHODINTENT']['FAILED']:
      return {
        ...state,
        paymentMethodIntent: {
          status: action.type,
          payload_failed: action.params,
          error: action.params.data,
        },
      };

    case BILLING_ACTION_TYPES['STRIPE_POST_PAYMENTMETHODINTENT']['CLEAR']:
      return {
        ...state,
        paymentMethodIntent: null,
      };

    case BILLING_ACTION_TYPES['GENERAL_BILLING']['CLEAR_ERROR']:
      return {
        ...state,
        error: null,
      };

    case BILLING_ACTION_TYPES['GENERAL_BILLING']['CLEAR_STATUS']:
      return {
        ...state,
        status: null,
      };

    case BILLING_ACTION_TYPES['GENERAL_BILLING']['RESET_STATES']:
      return INITIAL_STATE;

    case PURGE:
      return INITIAL_STATE;

    default: {
      return state;
    }
  }
};

export default billingReducer;
