import React, { useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  Container,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  withStyles,
} from '@material-ui/core';
import DynamicIcons from 'components/Web/DynamicIcons';
import clsx from 'clsx';
import { PACKAGE_NAMES, PACKAGE_FEATURES, FEATURE_LABELS } from './packageFeatures';
import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import Icon_True from 'assets/images/package_yes.png';
import Icon_False from 'assets/images/package_no.png';

const styles = (theme) => ({
  packageWrap: {
    padding: theme.spacing(0, 0),
  },
  subtitle: {
    fontSize: 24,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  subtitle2: {
    fontSize: 21,
    fontWeight: 300,
    marginBottom: theme.spacing(1.5),
  },
  packageGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
    width: 1600,
  },
  iconWrap: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  packageCardContent: {
    padding: theme.spacing(5, 2),
    '& h5': {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: theme.spacing(2),
    },
    '& p': {
      color: '#9b9b9b',
      letterSpacing: 0.09,
    },
  },
  packageHeader: {
    padding: theme.spacing(1.5, 2),
    textAlign: 'center',
    '& h3': {
      fontSize: 20,
      fontWeight: 400,
    },
  },
  packageStandardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  packagePremiumHeader: {
    backgroundColor: '#EADF74',
    color: theme.palette.common.black,
  },
  packagePrice: {
    color: 'black',
    fontSize: 32,
    fontFamily: 'Montserrat',
    fontWeight: '600',
    wordWrap: 'break-word',
  },
  packageHeading: {
    color: 'black',
    fontSize: 20,
    fontFamily: 'PT Serif',
    fontWeight: '400',
    wordWrap: 'break-word',
  },
  btn: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    fontSize: 20,
    lineHeight: 24,
    letterSpacing: '0.073125px',
    padding: '6px 8px',
    marginTop: 10,
    width: 180,
    height: 48,
    color: 'white',
    backgroundColor: theme.palette.primaryBlue.main,
  },
  btnsWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    marginBottom: 20,
  },
  packageCard: {
    '& h2 > strong': {
      textTransform: 'capitalize',
    },
    borderRadius: 24,
    border: 'solid 1px #E1E2EB',
    minWidth: 230,
    width: '100%',
    paddingBottom: 40,
    position: 'relative',
  },
  featuresCard: {
    borderRadius: 24,
    border: 'solid 1px #E1E2EB',
    width: 230,
    height: 625,
    position: 'relative',
    marginTop: 150,
    marginLeft: theme.spacing(2),
  },
  featureItemLabel: {
    height: 48,
    width: '100%',
  },
  iconCheck: {
    '& path': {
      fill: theme.palette.primaryGreen.main,
    },
  },
  listText: {
    '& span': {
      fontSize: 13,
      letterSpacing: '-0.4px',
    },
  },
  featureListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    height: 48,
    justifyContent: 'flex-start',
    color: '#4D4D4D',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19.2,
    wordWrap: 'break-word',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    height: 48,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#4D4D4D',
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 19.2,
    wordWrap: 'break-word',
  },
  cardContent: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  itemIcon: {
    height: 24,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const SelectPackage = (props) => {
  const { classes, packages, isLoading, selectCallback, selectedPackage, subscriptionTerm, isCustomerSubscribed } =
    props;

  const [confirmSelect, setConfirmSelect] = useState(false);
  const [subscriptionPackage, setSubscriptionPackage] = useState(null);
  const [subscriptionPackageSelected, setSubscriptionPackageSelected] = useState(false);

  const formatUSD = (num) => {
    return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const handleConfirmPackage = (type) => {
    setSubscriptionPackage(type);
    setConfirmSelect(true);
  };

  const handleConfirmCallback = () => {
    setConfirmSelect(false);
    if (subscriptionPackage) {
      selectCallback(subscriptionPackage);
      setSubscriptionPackageSelected(true);
    }
  };

  const isEvenNum = (num) => {
    return num % 2 === 0;
  };

  const filterPackages = (array) => {
    const filter = (pack) =>
      PACKAGE_NAMES.findIndex((item) => item.toUpperCase() === pack.propertyPlanType.toUpperCase()) !== -1;
    const filtered = array.filter(filter);
    const sorter = (a, b) => {
      return parseInt(b?.propertyPlanTypeWeight) - parseInt(a?.propertyPlanTypeWeight);
    };
    const sorted = filtered.sort(sorter);
    return sorted;
  };

  const capitalize = (name) => {
    const firstChar = name.charAt(0).toUpperCase();
    const otherChars = name.slice(1).toLowerCase();
    return firstChar + otherChars;
  };

  return (
    <div className={classes.packageWrap}>
      {packages && !isLoading && (
        <>
          <Typography variant="h4" className={classes.subtitle}>
            {!isCustomerSubscribed && 'Select '}Your Package
          </Typography>
          <Typography variant="caption" color={'textPrimary'} component={'p'}>
            *All prices exclusive of GST
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={9} lg={10} xl={10}>
              <Grid
                container
                spacing={4}
                className={classes.packageGrid}
                justifyContent={'center'}
                alignItems={'stretch'}
              >
                <Card className={classes.featuresCard}>
                  <CardContent className={classes.cardContent}>
                    {FEATURE_LABELS && (
                      <List>
                        {FEATURE_LABELS.map((item, idx) => (
                          <div className={classes.featureItemLabel}>
                            <ListItem
                              key={idx}
                              className={classes.featureListItem}
                              style={
                                isEvenNum(idx) ? { backgroundColor: 'transparent' } : { backgroundColor: '#E7F7F9' }
                              }
                            >
                              {item}
                            </ListItem>
                          </div>
                        ))}
                      </List>
                    )}
                  </CardContent>
                </Card>
                {filterPackages(packages).map((pack, idx) => {
                  if (pack.active) {
                    return (
                      <Grid item xs key={idx} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card className={classes.packageCard}>
                          <CardHeader
                            title={
                              <>
                                <Typography align={'center'}>
                                  <strong className={classes.packagePrice}>{`$${pack?.propertyPlanPrice}`}</strong>
                                </Typography>
                                <Typography align={'center'}>
                                  <strong className={classes.packageHeading}>
                                    {capitalize(pack?.propertyPlanType)}
                                  </strong>
                                </Typography>
                              </>
                            }
                            style={{ paddingBottom: 0, height: 134, backgroundColor: '#6DC5A1' }}
                          />
                          <CardContent className={classes.cardContent}>
                            {PACKAGE_FEATURES && (
                              <List>
                                {PACKAGE_FEATURES[pack?.propertyPlanType.toLowerCase()]?.map((item, idx) => {
                                  return (
                                    <ListItem
                                      key={idx}
                                      className={classes.listItem}
                                      style={
                                        isEvenNum(idx)
                                          ? { backgroundColor: 'transparent' }
                                          : { backgroundColor: '#E7F7F9' }
                                      }
                                    >
                                      {item.isBoolean ? (
                                        <img
                                          src={item.value === true ? Icon_True : Icon_False}
                                          width={24}
                                          height={24}
                                        />
                                      ) : (
                                        <ListItem key={idx} className={classes.listItem}>
                                          {item.value}
                                        </ListItem>
                                      )}
                                    </ListItem>
                                  );
                                })}
                              </List>
                            )}
                            <div className={classes.btnsWrap}>
                              <Button
                                className={classes.btn}
                                onClick={() => {
                                  handleConfirmPackage(pack?.propertyPlanType);
                                }}
                                disabled={
                                  selectedPackage === pack?.propertyPlanType || !subscriptionTerm ? true : false
                                }
                              >
                                {selectedPackage === pack?.propertyPlanType ? 'Selected' : 'Select'}
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {confirmSelect && (
        <ConfirmDialog
          isOpen={confirmSelect}
          confirmCallback={() => handleConfirmCallback()}
          closeCallback={() => setConfirmSelect(false)}
          showLogo={true}
          align={'center'}
          title={<span style={{ textTransform: 'capitalize' }}>{subscriptionPackage.toLowerCase()} Package</span>}
          text={
            <>
              Please confirm your selection. <br />
              <br /> If your needs change down the track, simply contact PropertyMate to request a new package.
            </>
          }
          cancelLabel={'Decline'}
          confirmLabel={'Accept'}
        />
      )}

      {!packages && isLoading && (
        <Typography component="p" variant="body2">
          Loading subscription packages...
        </Typography>
      )}

      {!packages && !isLoading && (
        <Typography component="p" variant="body2">
          Error loading subscription packages
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(SelectPackage);
