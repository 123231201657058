import React, { useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DynamicIcons from "../../components/Mobile/DynamicIcons";
import { scrollToRef } from '../../components/Mobile/ScrollEvents';

import Loader from '../../components/Mobile/Loader';
import AlertNotification from '../../components/Mobile/Form/AlertNotification';
import AgentResultsComponent from '../../components/Mobile/Agent/AgentResults';
import AgencyResultsComponent from '../../components/Mobile/Agency/AgencyResults';
import TabSelector from "../../components/Mobile/Agent/TabSelector";

/** actions */
import {
  setAgentSearchParams,
  clearAgentSearchParams,
} from '../../store/actions';

/** api redux request */
import {
  requestSearchAgent,
  requestAgentAgencyData,
} from "../../store/api";

import { withStyles, Button, Typography } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
  },
})(Typography);

const StyledSubText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '15px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative'
  },
})(Typography);

const BackButton = withStyles({
  root: {
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative'
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)'
  },
});

const AgentResults = props => {

  const {
    classes,
    match,
    searchAgent,
    agent,
    clearAgentSearchParams,
  } = props;
  const [routerChange] = useRouterChange()
  const [isLoading, setIsLoading] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);
  const [agentsData, setAgentsData] = useState([]);
  const [agenciesData, setAgenciesData] = useState([]);

  const [agencies, setAgencies] = React.useState(null)
  const [agents, setAgents] = React.useState(null)
  const [initialTab, setInitialTab] = React.useState('agent')

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  const topContainer = React.useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d) //add `false` as second parameter to scroll without transition.

  React.useEffect(() => {
    apiRequestSearchAgentAPI();
  }, [initialTab])

  React.useEffect(() => {
    /** 
     * Initial Scroll to Top */
    executeTopScroll(false);
    apiRequestSearchAgentAPI();

    if (agent.agencies === null && agent.agents === null) {
      apiRequestAllAgents();
    }

    if (agencies === null && agents === null) {
      setAgencies(agent.agencies)
      setAgents(agent.agents)
    }

    /** Set initial tab */
    if (agent.search_params) {
      if (agent.search_params.category) {
        setInitialTab(agent.search_params.category)
      } else {
        setInitialTab('agent')
      }
    }
  }, [])

  useEffect(() => {
    if (agent && agent.agentResults && agent.agentResults.data) {
      /** Agent Data Response */
      if (agent.agentResults.data.agents && agent.agentResults.data.agents.length > 0) {
        setIsLoading(false);
        setAgentsData(agent.agentResults.data.agents)
      } else if (agent.agentResults.data.agents && agent.agentResults.data.agents.length === 0) {
        setIsLoading(false);
        setAgentsData([])
        //handleNotification(true, {message: 'No results found.', severity: 'error'});
        return;
      }

      /** Agency Data Response */
      if (agent.agentResults.data.agencies && agent.agentResults.data.agencies.length > 0) {
        setIsLoading(false);
        setAgenciesData(agent.agentResults.data.agencies)
      } else if (agent.agentResults.data.agencies && agent.agentResults.data.agencies.length === 0) {
        setIsLoading(false);
        setAgenciesData([])
        //handleNotification(true, {message: 'No results found.', severity: 'error'});
        return;
      }

    }

    if (agencies === null && agents === null) {
      setAgencies(agent.agencies)
      setAgents(agent.agents)
    }
  }, [agent])

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  /** Search Property */
  async function apiRequestSearchAgent(params) {
    let request = params && params.length > 0 && params[0];
    switch (request.type) {
      case 'suburb':
        props.setAgentAgencyParams(request);
        apiRequestSearchAgentAPI();
        break;
      case 'agency':
        props.setAgentAgencyParams(request);
        apiRequestSearchAgentAPI();
        break;
      case 'agent':
        props.setAgentAgencyParams(request);
        apiRequestSearchAgentAPI();
        break;
      default:
        props.setAgentAgencyParams(request);
        apiRequestSearchAgentAPI();
        break;
    }
  }

  /** Search Property */
  async function apiRequestSearchAgentAPI() {
    setIsLoading(true);
    let suburb = agent.search_params;
    /** params setter for specific suburb */
    if (suburb && suburb.locality && suburb.postcode && suburb.state) {
      const request = {
        "suburb": {
          "suburb": suburb.locality,
          "postcode": suburb.postcode,
          "state": suburb.state
        }
      }
      searchAgent(request, suburb.category);
    }
    /** params setter for search all */
    if (suburb && !suburb.locality && !suburb.postcode && !suburb.state) {
      searchAgent({}, initialTab);
    } else if (!suburb) {
      searchAgent({}, initialTab);
    }
  }

  /** Get all agents list */
  async function apiRequestAllAgents() {
    props.requestAllAgentsAgency();
  }

  return (
    <div className={classes.root}>
      <div ref={topContainer} className={classes.topAnchor}></div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routerChange('/agent')}>
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
      </div>
      <TabSelector setInitialTab={setInitialTab} initialTab={initialTab} />
      {
        initialTab === 'agent' &&
        <AgentResultsComponent
          match={match}
          agentsData={agentsData}
          agentReducer={agent}
          agents={agents}
          agencies={agencies}

          clearAgentSearchParams={clearAgentSearchParams}
          apiRequestSearchAgent={apiRequestSearchAgent}
          executeTopScroll={executeTopScroll} />
      }
      {
        // set agency component here @Topher
        initialTab === 'agency' &&
        <AgencyResultsComponent
          match={match}
          agenciesData={agenciesData}
          agentReducer={agent}
          agents={agents}
          agencies={agencies}

          clearAgentSearchParams={clearAgentSearchParams}
          apiRequestSearchAgent={apiRequestSearchAgent}
          executeTopScroll={executeTopScroll} />
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    stepCounter: null,
    agent: state.agents,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      searchAgent: (params, type) => requestSearchAgent(params, type),
      setAgentAgencyParams: (params) => setAgentSearchParams(params),
      clearAgentSearchParams: () => clearAgentSearchParams(),
      requestAllAgentsAgency: params => requestAgentAgencyData(params),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentResults));
