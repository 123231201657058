import React from 'react'
import { Backdrop, CircularProgress, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    flexDirection: 'column',
    width: '100vw',
    left: '90px',
  },
}))

export default function Loader({ isLoading, message, ...props }) {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open={isLoading} {...props}>
      <CircularProgress color="primary" />
      <Typography variant="body1" component="h2">
        {message ? message : 'Please wait..'}
      </Typography>
    </Backdrop>
  )
}
