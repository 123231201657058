import React from "react";
import styled from 'styled-components';
import DynamicIcons from "../DynamicIcons";

import { FormControl, TextField, FormHelperText, withStyles } from '@material-ui/core';

const styles = theme => ({
  textarea: {
		'& .Mui-focused fieldset' : {
			borderWidth: '1px !important',
			
		},
		 
		'& .Mui-error textarea': {
      color:'#EB4335',
      borderColor:'#EB4335',
      paddingRight:'40px'
		}
  }
});

const StyledFormControl = withStyles({
  root: {
    display:'block',
    width:'100%',
    padding:'0 0 12px',
    position:'relative'
  }
})(FormControl);

const StyledTextField = withStyles((theme) => ({
  root: {
		width:'100%',
		backgroundColor: theme.palette.common.white,		
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&.Mui-error textarea': {
      color:'#EB4335',
      borderColor:'#EB4335',
      paddingRight:'40px'
		},
		'&:focus': {
			borderColor: theme.palette.primary.main,
			border: '1px solid #ced4da',
    }
  },
}))(TextField);

const StyledErrorMessage = withStyles({
  root: {
    position:'absolute',
    top:'-12px',
    left:'15px',
    fontSize:'12px',
    color:'#EB4335',
    lineHeight:'24px',
    letterSpacing:'0.09px'
  }
})(FormHelperText);

const IconWrap = styled.div`
  position:absolute;
  right:18px;
  top:50%;
  transform:translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events:none;
`

const StyledWrap = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
`

const TextAreaField = props => {
  const { type, value, showError, errorMsg, placeholder, onChange, onBlur, onKeyUp, onKeyPress, classes} = props
  
  const handleChange = (event, newValue) => {
    if(onChange) {
      onChange(event, newValue)
    }
  };

  const handleBlur = (event, newValue) => {
    if(onBlur) {
      onBlur(event, newValue)
    }
  };
  
  const handleKeyUp = (event, newValue) => {
    if(onKeyUp) {
      onKeyUp(event, newValue)
    }
  };
  
  const handleKeyPress = (event, newValue) => {
    if(onKeyPress) {
      onKeyPress(event, newValue)
    }
  };

  return (
    <StyledWrap>
      <StyledFormControl >
				<StyledTextField
          placeholder={placeholder}
					multiline
					rows={5}
					variant="outlined"					
					className={classes.textarea}
          //defaultValue={value} 
          value={value}
          placeholder={placeholder} 
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyUp={handleKeyUp}
          onKeyPress={handleKeyPress}
					error={showError}					
        />

        {
          showError && 
            <StyledErrorMessage>{errorMsg}</StyledErrorMessage>
        }
        {
          showError &&
          <IconWrap>
            <DynamicIcons icon={'2DCErrorIcon'} />
          </IconWrap>
        }
        
      </StyledFormControl>
    </StyledWrap>
  )
};

export default withStyles(styles)(TextAreaField)