import React from 'react'
import { withStyles, Breadcrumbs, Link } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { useHistory } from 'react-router-dom'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    fontSize: '0.925em',
  },
  bCrumbs: {
    fontSize: '0.925em',
    display: 'flex',
  },
})

const Crumbs = (props) => {
  const {
    type,
    crumbsOption,

    classes,
    backButton,
    previousRoute,
    previousRouteText,
  } = props

  const history = useHistory()
  const [routerChange] = useRouterChange()

  return (
    <div className={classes.bCrumbs}>
      {backButton && (
        <Link
          style={{
            textTransform: 'capitalize',
            color: type === 'LIGHT' ? '#FFF' : '#6a6a6a',
            display: 'flex',
            cursor: 'pointer',
            color: '#11c0c9',
            marginRight: '20px',
          }}
          onClick={(e) => {
            e.preventDefault()
            if (routerChange) {
              if (previousRoute) {
                routerChange(previousRoute, props?.match)
              } else {
                routerChange('back', props?.match)
              }
            }
          }}
        >
          <ChevronLeftIcon style={{ width: 20, height: 20, marginRight: 5 }} />
          {previousRouteText}
        </Link>
      )}
      <Breadcrumbs
        separator=">"
        style={{ color: type === 'LIGHT' ? '#FFF' : '#6a6a6a' }}
        className={classes.root}
      >
        <Link
          style={{
            textTransform: 'capitalize',
            color: type === 'LIGHT' ? '#FFF' : '#6a6a6a',
            display: 'flex',
            cursor: 'pointer',
            color: '#00639e',
          }}
          onClick={(e) => {
            e.preventDefault()
            if (routerChange) {
              routerChange('/')
            }
          }}
        >
          Home
        </Link>
        {!crumbsOption.length ? (
          <Link
            style={{
              textTransform: 'capitalize',
              color: type === 'LIGHT' ? '#FFF' : '#6a6a6a',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.preventDefault()
              if (routerChange) {
                routerChange(crumbsOption.route)
              }
            }}
          >
            {crumbsOption.label ? crumbsOption.label.replace(/-/g, ' ') : ''}
          </Link>
        ) : (
          crumbsOption.map((o, k) => {
            return (
              <Link
                key={k}
                disabled={o.route ? false : true}
                style={{
                  textTransform: 'capitalize',
                  color: type === 'LIGHT' ? '#FFF' : '#6a6a6a',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.preventDefault()
                  if (routerChange && o.route) {
                    routerChange(o.route)
                  }
                }}
              >
                {o.label ? o.label.replace(/-/g, ' ') : ''}
              </Link>
            )
          })
        )}
      </Breadcrumbs>
    </div>
  )
}

export default withStyles(styles)(Crumbs)
