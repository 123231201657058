import React from 'react';
import { default as BaseImage } from 'material-ui-image'
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {    
    width:'100%'
  },
  
});

const Image = props => {    
    return (
        <BaseImage {...props} style={{width:'100%'}}/>
    );
}

export default withStyles(styles)(Image);