export const convertTagToString = (tag) => {
  switch (tag) {
    case 'close_to_cafes':
      return 'Cafes'
    case 'inner_city_vibe':
      return 'Inner City Vibe'
    case 'close_to_parks':
      return 'Parks'
    case 'close_to_primary_schools':
      return 'Primary Schools'
    case 'close_to_secondary_schools':
      return 'Secondary Schools'
    case 'close_to_shops':
      return 'Shops'
    case 'close_to_public_transports':
      return 'Transports'
    case 'close_to_schools':
      return 'Schools'
    case 'foody_area':
      return 'Foody Area'
    default:
      return ''
  }
}

export const extractTagsToDisplay = (tags, nearbyFilters) => {
  const trueTags = []
  const falseTags = []

  Object.entries(tags).forEach((entry) => {
    const key = entry[0]
    const value = entry[1]
    if (value == true) {
      trueTags.push(key)
    } else {
      falseTags.push(key)
    }
  })

  const activeFilters = nearbyFilters
    .filter((item) => item.active === true)
    .map((item) => item.key)

  if (activeFilters.length > 0) {
    const combined = [...trueTags, ...activeFilters]
    const duplicates = combined.filter(
      (item, index) => combined.indexOf(item) !== index
    )
    return duplicates
  } else {
    return [...trueTags, ...falseTags]
  }
}
