import React from 'react';
import DynamicIcons from "../DynamicIcons";

import SocialAuth from "../SocialAuth";

import { withStyles, Button, Typography, ButtonGroup } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width:'100%',
    padding:'0 30px',
    "& > *": {
        margin: theme.spacing(1),
    }
  },
});

const GROUPED_BUTTONS = [{
  label: 'Apple',
  value: false,
  to: '/'  
},{
  label: 'Facebook',
  value: true,
  to: '/'

},
{
  label: 'Google',
  value: false,
  to: '/'

}]

const Icons = {
  'Apple': <DynamicIcons icon="2DCAppleIcon" />,
  'Facebook': <DynamicIcons icon="2DCFacebookIcon" />,
  'Google': <DynamicIcons icon="2DCGoogleIcon" />,
}

const buttonStyle = {
  size:'small',
  borderColor:'#000',
  textColor:'#000',
  colorTheme:'primary'
}

const ButtonGroupsCustom = props => {
  const { open, classes, buttonData, callback, showLabels } = props;

  function handleChange(type){
    callback(type)
  };

  function renderButtonGroupsCustom(value, index){
    console.log('value', value)
    return(
      <SocialAuth 
        key={index}
        type={value.type}
      />
    )
  }

  return (
    <div className={classes.root}>
        <Button variant={'outlined'} size={buttonStyle.size} startIcon={Icons['Apple']} style={{borderColor:buttonStyle.borderColor, display:'flex', padding:'10px', width:'100%'}}>
            <Typography color={buttonStyle.colorTheme} style={{color:buttonStyle.textColor, fontSize:'12px'}}>Apple</Typography>
        </Button>
        <Button variant={'outlined'} size={buttonStyle.size} startIcon={Icons['Facebook']} style={{borderColor:buttonStyle.borderColor, display:'flex', padding:'10px', width:'100%'}}>
            <Typography color={buttonStyle.colorTheme} style={{color:buttonStyle.textColor, fontSize:'12px'}}>Facebook</Typography>
        </Button>
        <Button variant={'outlined'} size={buttonStyle.size} startIcon={Icons['Google']} style={{borderColor:buttonStyle.borderColor, display:'flex', padding:'10px', width:'100%'}}>
            <Typography color={buttonStyle.colorTheme} style={{color:buttonStyle.textColor, fontSize:'12px'}}>Google</Typography>
        </Button>
    </div>
  );
};

export default withStyles(styles)(ButtonGroupsCustom);
