import React, { useState, useCallback, useEffect } from 'react'
import { IconButton, Button, Grid, AppBar, Paper, Container, Typography, withStyles, FormHelperText } from '@material-ui/core';
import DialogContainer from '../CustomDialogs/DialogContainer'
import ConfirmDialog from '../CustomDialogs/ConfirmDialog'

import CloseIcon from '@material-ui/icons/Close'
import HeadingContent from './HeadingContent'
import DropField from '../DropField'
import csv from 'csv'
import XLSX from 'xlsx'
import CSVFile from '../../../assets/csv/pmate_agents_bulk_upload_template.csv'

const styles = (theme) => ({
  ctaRounded: {
    borderRadius: 25,
    color: theme.palette.text.primary,
    textTransform: 'initial',
    paddingLeft: theme.spacing(3.2),
    paddingRight: theme.spacing(3.2),
    marginLeft: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    '&.Mui-disabled': {
      borderWidth: 0.5,
      borderColor: 'rgba(0, 0, 0, 0.26)',
    },
  },
  actionWrap: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  headerBar: {
    backgroundColor: theme.palette.light.main,
    boxShadow: 'none',
  },
  modal: {
    margin: theme.spacing(7),
    marginRight: '25%',
    marginLeft: '25%',
    borderRadius: 38,
    position: 'relative',
    '&:hover': {
      outline: 'none',
    },
    overflow: 'hidden',
    padding: theme.spacing(3.25, 6.75),
  },
  closeicon: {
    position: 'absolute',
    top: 20,
    right: theme.spacing(2.5),
  },
  rowWrap: {
    display: 'flex',
  },
  moreRows: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&  div > span': {
      alignSelf: 'center',
    },
  },
  rowField: {
    display: 'flex',
  },
  moreBtn: {
    textTransform: 'capitalize',
    textDecoration: 'underline',
    minWidth: 'auto',
    fontSize: '13px',
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  ctaRounded: {
    borderRadius: 25,
    color: theme.palette.text.primary,
    textTransform: 'initial',
    paddingLeft: theme.spacing(3.2),
    paddingRight: theme.spacing(3.2),
    backgroundColor: theme.palette.common.white,
    minWidth: 180,
  },
  actionBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  drop: {
    marginTop: theme.spacing(5),
    // marginBottom: theme.spacing(4),
  },
})

const ScrollContainer = withStyles({
  root: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 'calc(85vh - 100px)',
    width: '100%',
    padding: 0,
    minHeight: '30vh',
  },
})(Container)

const MainHeader = withStyles({
  root: {
    boxShadow: 'none',
    padding: '10px 0px',
  },
})(AppBar)

const toObj = (data) => {
  var keys = data.shift(),
    i = 0,
    k = 0,
    obj = null,
    output = []

  for (i = 0; i < data.length; i++) {
    obj = {}
    for (k = 0; k < keys.length; k++) {
      obj[keys[k]] = data[i][k]
    }
    output.push(obj)
  }

  return output
}

const BulkAddAgentForm = (props) => {
  const {
    classes,
    isBulkAddingAgent,
    setIsBulkAddingAgent,
    setSuccess,
    submitAgents,
    status,
    error,
  } = props

  const [fileState, setFileState] = useState(null)
  const [confirmSubmit, setConfirmSubmit] = useState(false)
  const [fileInfo, setFileInfo] = useState(null)

  const onDropAccepted = useCallback((acceptedFiles) => {
    const reader = new FileReader()
    const extRegx = /(?:\.([^.]+))?$/
    const rABS = !!reader.readAsBinaryString
    let ext

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading failed')
    reader.onload = (e) => {
      if (ext === 'csv') {
        csv.parse(e.target.result, (err, data) => {
          const parsedData = toObj(data)
          // setFileState((prevState) => { return {...prevState, data: toObj(data)}})
          acceptedFiles.map((file) =>
            setFileState((prevState) => {
              return { ...prevState, error: null, ...file, data: parsedData }
            })
          )
        })
      } else {
        const file = e.target.result
        const wb = XLSX.read(file, { type: rABS ? 'binary' : 'array', bookVBA: true })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws)
        acceptedFiles.map((file) =>
          setFileState((prevState) => {
            return { ...prevState, error: null, ...file, data: data }
          })
        )
      }
    }
    // read file contents
    acceptedFiles.forEach((file) => {
      ext = extRegx.exec(file.path)[1]
      return reader.readAsBinaryString(file)
    })
  }, [])

  const onDropRejected = useCallback((fileRejections) => {
    fileRejections.map((file) =>
      setFileState((prevState) => {
        return { ...prevState, error: true, ...file }
      })
    )
  }, [])

  useEffect(() => {
    if (status && !error) {
      setIsBulkAddingAgent(false)
      setSuccess(true)
      resetDropField()
    }
  }, [status, error])
  const filterKeys = ["email"]
  const filteredData = (data) => {
    return data?.filter((value, index, self) => self.findIndex(v => filterKeys.every(k => v[k] === value[k])) === index);
  }
  const filteredBlanks = (data) => {
    return data?.filter((value) => value?.email !== "");
  }

  useEffect(() => {
    const filtered = filteredData(fileState?.data?.filter(i => i.email && i.email !== ""))
    if (fileState?.data && filtered && filtered?.length !== 0 && filtered?.length < filteredBlanks(fileState?.data).length) {
      setFileInfo("Duplicate email found! Please review the file and upload again.")
    } else if (filtered?.length <= 0) {
      setFileInfo("File is either empty or invalid due to blank emails.")
    }
    else {
      setFileInfo(null)
    }
  }, [fileState])

  const resetDropField = () => {
    setFileState(null)
    setIsBulkAddingAgent(false)
  }

  /* Submit Agents to API */
  async function apiRequestAddAgents() {

    var params = {
      agents: filteredData(fileState?.data?.filter(i => i.email && i.email !== "")),
    }
    if (fileState && fileState.data) {
      submitAgents(params)
    }
  }

  const handleSubmit = () => {
    apiRequestAddAgents()
  }

  return (
    <DialogContainer isOpen={isBulkAddingAgent} closeCallback={setIsBulkAddingAgent}>
      <Paper className={classes.modal} elevation={5}>
        <MainHeader className={classes.headerBar} position="static">
          <IconButton
            aria-label="close"
            className={classes.closeicon}
            onClick={() => {
              setIsBulkAddingAgent(false)
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <HeadingContent
            title={'Add Agents in Bulk'}
            content={
              '<p>Upload a .csv file consisting of Agent’s first name, last name, email address and phone number.</p><p>Download <a target="_blank" href="' +
              CSVFile +
              '" download="pmate_agents_bulk_upload_template">this</a> template for help.</p>'
            }
          />
        </MainHeader>

        <ScrollContainer>
          <div className={classes.formWrap}>
            <div className={classes.drop}>
              <DropField
                onDropAccepted={onDropAccepted}
                onDropRejected={onDropRejected}
                fileState={fileState}
                maxFiles={1}
                accept={'.csv, .xls, .xlsx'} //string of comma separated file extensions to accept
              />
            </div>
            {
              fileInfo ?
                <FormHelperText error={fileInfo ? true : false} margin={"dense"}>{fileInfo}</FormHelperText>
                : null
            }
            <div className={classes.actionBtns}>
              <Button
                variant="outlined"
                color="default"
                disableElevation
                className={classes.ctaRounded}
                onClick={resetDropField}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={!fileState || fileState.error || (fileInfo ? true : false)}
                disableElevation
                className={classes.ctaRounded}
                onClick={() => setConfirmSubmit(true)}
              >
                + Add Agent/s
              </Button>
            </div>
          </div>
        </ScrollContainer>
      </Paper>
      <ConfirmDialog
        isOpen={confirmSubmit}
        confirmCallback={handleSubmit}
        closeCallback={() => setConfirmSubmit(false)}
        showLogo={true}
        align={'center'}
        title={'Add Agent'}
        text={'Are you sure you want add to listed agent/s?'}
      />
    </DialogContainer>
  )
}

export default withStyles(styles)(BulkAddAgentForm)
