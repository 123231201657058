import React, { useState } from 'react'

import HorizontalContainer from './HorizontalContainer'
import PropertySuggestionCards from './PropertySuggestionCards'
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { PROPERTY_CARDS, TRADES } from '../../Web/Property/constants'

import { withStyles, Typography, IconButton } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  headerText: {
    fontWeight: 'normal',
    fontSize: 24,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingRight: 55,
    paddingLeft: 55,
    textAlign: 'left',
    fontWeight: 500,
  },
  cardContainer: {
    display: 'flex',
    width: 'auto',
    overflow: 'hidden',

    '& .BrainhubCarousel__arrows': {
      backgroundColor: 'transparent',
      '& span': {
        borderColor: '#cdcdcd',
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },
    },
    '& .BrainhubCarousel__arrows:hover': {
      backgroundColor: 'transparent',
      '& span': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
})

const PropertySuggestions = (props) => {
  const {
    classes,
    properties,
    settings,
    authentication,
    apiRequestSaveProperty,
    toggleSignUp,

  } = props

  const [suggestions, setSuggestions] = React.useState(null)

  React.useEffect(() => {
    // console.log('PROPERTIES', properties)
    setSuggestions(properties)
  }, [properties])

  return (
    <div className={classes.root}>
      {suggestions && suggestions.length > 0 && (
        <div>
          <Typography className={classes.headerText} variant="h6" component="p">
            {"Properties we think you'll love"}
          </Typography>
          <div className={classes.cardContainer}>
            <HorizontalContainer>
              {suggestions.map((value, index) => {
                return (
                  <PropertySuggestionCards
                    key={index}
                    properties={value}
                    type={'properties'}
                    toggleSignUp={toggleSignUp}
                    authentication={authentication}
                    settings={settings}
                    apiRequestSaveProperty={apiRequestSaveProperty}

                  />
                )
              })}
            </HorizontalContainer>
          </div>
        </div>
      )}
      {/*    {TRADES && <div>
                <Typography 
                    className={classes.headerText}
                    variant="h6" 
                    component="p">
                    {'Trades suggested for you'}
                </Typography>               
                <div className={classes.cardContainer}>                
                    <Carousel
                        plugins={[                            
                        'arrows',
                        {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: 4,                            
                        }
                        },
                    ]}
                >
                    {
                        TRADES.map((value, index) => {
                            return <PropertySuggestionCards key={index} trades={value} type={'trades'}
                                toggleSignUp={toggleSignUp}
                                authentication={authentication}
                                settings={settings}
                                apiRequestSaveProperty={apiRequestSaveProperty} 

                            />
                        })
                    }
                    </Carousel>
                </div>
            </div>} */}
    </div>
  )
}

export default withStyles(styles)(PropertySuggestions)
