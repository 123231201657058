import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);



export function DownloadIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} >
        <path d="M16 9V16H2V9H0L0 16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V9H16ZM10 9.67L12.59 7.09L14 8.5L9 13.5L4 8.5L5.41 7.09L8 9.67V0L10 0V9.67Z" fill={props.fill ? props.fill : 'white'}/>
    </SvgIcon>
  );
}

export function DownloadIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DownloadIcon />
      <DownloadIcon color="primary" />
      <DownloadIcon color="secondary" />
      <DownloadIcon color="action" />
      <DownloadIcon color="disabled" />
      <DownloadIcon style={{ color: green[500] }} />
    </div>
  );
}
