import _ from 'lodash'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useFilters from './customHooks/useFilters'
import { useCallback } from 'react'
import { handleSearchPropertyReference } from './handleSearchPropertyReference'
import { useDispatch, useSelector } from 'react-redux'
import { StorageActions } from 'store/actions/storage.actions'
import { getFromInnerPage } from 'store/selectors/storage.selectors'

const SEARCH_ROUTES = [
  '/buy',
  '/rent',
  '/coming-soon',
  '/off-market',
  '/hush-hush',
  '/perfect-property',
]

function debounce(func, wait, immediate) {
  var timeout
  return (...args) => {
    var context = this

    var later = () => {
      timeout = null
      if (!immediate) func.apply(context, args)
    }

    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function useRouterChange() {
  let history = useHistory()
  const [getFilter, setFilter] = useFilters()
  const FROM_INNERPAGE = useSelector(getFromInnerPage)
  const SEARCH_REFERENCE = getFilter('search_reference')
  const dispatch = useDispatch()

  const updateReferenceFilter = useCallback(
    debounce((refFilter) => {
      setFilter({
        key: 'search_reference',
        value: refFilter,
      })
    }, 100),
    []
  )

  useEffect(() => {
    const active_reference_path = handleSearchPropertyReference(SEARCH_REFERENCE)

    if (
      history.location.pathname !== '/' &&
      SEARCH_ROUTES.includes(history.location.pathname)
    ) {
      if (active_reference_path !== history?.location?.pathname) {
        let modified_reference = SEARCH_REFERENCE.map((item) =>
          item.path === history.location.pathname
            ? { ...item, active: true }
            : { ...item, active: false }
        )
        setFilter({
          key: 'search_reference',
          value: modified_reference,
        })

        // updateReferenceFilter(modified_reference)
      }
    }
  }, [history.location])

  function routerChange(path, state = {}) {
    console.debug('Global Router Hook triggered')
    // let referenceLocation = history.location
    // delete referenceLocation.key

    if (path && path !== '' && path !== 'back') {
      history.push(path, {
        ...state,
        // routeReference: referenceLocation ? JSON.stringify(referenceLocation) : null,
      })
      if (FROM_INNERPAGE) {
        dispatch(StorageActions(null, 'GENERAL_SETTINGS', 'SET_FROM_INNERPAGE'))
      }
    } else if (path === 'back') {
      const keys = state?.params ? Object.keys(state?.params) : []
      if (keys?.includes('id')) {
        dispatch(StorageActions(true, 'GENERAL_SETTINGS', 'SET_FROM_INNERPAGE'))
      }
      history.goBack()
    }
  }

  return [routerChange]
}
