import React from 'react'
import styled, { css } from 'styled-components'
import DynamicIcons from '../DynamicIcons'
import SocialAuth from '../SocialAuth'
//import AppleAuth from '../SocialAuth/AppleAuth'
import GoogleAuth from '../SocialAuth/GoogleAuth'

import { withStyles, Typography } from '@material-ui/core';

const SocialWrap = styled.div`
  padding: 30px 19px 0;
  margin-top: 18px;
  border-top: 1px solid #bdbdbd;

  ${(props) =>
    props.border === 'none' &&
    css`
      border: 0;
      margin: 0;
    `}
`

const styles = (theme) => ({
  innerWrap: {
    maxWidth: 326,
    margin: '0 auto',
    textAlign: 'center',
  },
})

const StyledTypography = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#646464',
    marginBottom: 15,
  },
})(Typography)

const SocialButtonGroup = (props) => {
  const { border, label, type, classes } = props

  const labelText = type === 'login' ? 'Continue' : 'Sign up'

  const SocialIcons = [
    {
      label: labelText + ' with Apple',
      icon: <DynamicIcons icon="2DCAppleIcon" />,
      url: '',
      type: 'apple',
    },
    {
      label: labelText + ' with Facebook',
      icon: <DynamicIcons icon="2DCFacebookIcon" />,
      url: '',
      type: 'facebook',
    },
    {
      label: labelText + ' with Google',
      icon: <DynamicIcons icon="2DCGoogleIcon" />,
      url: '',
      type: 'google',
    },
  ]

  function socialMediaCallback(data) {
    /** Get Social Media User Data */
    props.socialCallback({
      profile: data._profile,
      provider: data._provider,
      token: data._token,
    })
  }

  function FacebookAuthButton(obj, key) {
    return (
      <SocialAuth
        key={key}
        type={obj.type}
        label={obj.label}
        icon={obj.icon}
        callback={(res) => socialMediaCallback(res)}
      />
    )
  }

  function GoogleAuthButton(obj, key) {
    return (
      <GoogleAuth
        key={key}
        type={obj.type}
        label={obj.label}
        icon={obj.icon}
        callback={(res) => socialMediaCallback(res)}
      />
    )
  }

  /*   function AppleAuthButton(obj, key) {
    return (
      <AppleAuth
        key={key}
        type={obj.type}
        label={obj.label}
        icon={obj.icon}
        callback={(res) => socialMediaCallback(res)}
      />
    )
  } */

  return (
    <SocialWrap border={border}>
      <div className={classes.innerWrap}>
        {label && <StyledTypography>{label}</StyledTypography>}
        {SocialIcons.map((obj, key) => {
          /** APPLE */
          /* if(obj.type === 'apple'){
              return AppleAuthButton(obj, key)
            } */
          /** FACEBOOK */
          if (obj.type === 'facebook') {
            return FacebookAuthButton(obj, key)
          }
          /** GOOGLE */
          if (obj.type === 'google') {
            return GoogleAuthButton(obj, key)
          }
        })}
      </div>
    </SocialWrap>
  )
}

export default withStyles(styles)(SocialButtonGroup)
