export const MPP_ACTION_TYPES = {
  GET_MPP: {
    REQUEST: 'mpp/GET_MPP_REQUEST',
    SUCCESS: 'mpp/GET_MPP_SUCCESS',
    FAILED: 'mpp/GET_MPP_FAILED',
  },
  GET_MPP_FAVOURITES: {
    REQUEST: 'mpp/GET_MPP_FAVOURITES_REQUEST',
    SUCCESS: 'mpp/GET_MPP_FAVOURITES_SUCCESS',
    FAILED: 'mpp/GET_MPP_FAVOURITES_FAILED',
    CLEAR: 'mpp/GET_MPP_FAVOURITES_CLEAR',
  },
  GET_MPP_DISLIKES: {
    REQUEST: 'mpp/GET_MPP_DISLIKES_REQUEST',
    SUCCESS: 'mpp/GET_MPP_DISLIKES_SUCCESS',
    FAILED: 'mpp/GET_MPP_DISLIKES_FAILED',
    CLEAR: 'mpp/GET_MPP_DISLIKES_CLEAR',
  },
  GET_MPP_FAVOURITES_IDS: {
    REQUEST: 'mpp/GET_MPP_FAVOURITES_IDS_REQUEST',
    SUCCESS: 'mpp/GET_MPP_FAVOURITES_IDS_SUCCESS',
    FAILED: 'mpp/GET_MPP_FAVOURITES_IDS_FAILED',
    CLEAR: 'mpp/GET_MPP_FAVOURITES_IDS_CLEAR',
  },
  POST_MPP_LIKES_DISLIKES: {
    REQUEST: 'mpp/POST_MPP_LIKES_DISLIKES_REQUEST',
    SUCCESS: 'mpp/POST_MPP_LIKES_DISLIKES_SUCCESS',
    FAILED: 'mpp/POST_MPP_LIKES_DISLIKES_FAILED',
  },
  PUT_MPP_LIKES_DISLIKES: {
    REQUEST: 'mpp/PUT_MPP_LIKES_DISLIKES_REQUEST',
    SUCCESS: 'mpp/PUT_MPP_LIKES_DISLIKES_SUCCESS',
    FAILED: 'mpp/PUT_MPP_LIKES_DISLIKES_FAILED',
  },
  PUT_MPP_FAVOURITES: {
    REQUEST: 'mpp/PUT_MPP_FAVOURITES_REQUEST',
    SUCCESS: 'mpp/PUT_MPP_FAVOURITES_SUCCESS',
    FAILED: 'mpp/PUT_MPP_FAVOURITES_FAILED',
  },
  LOCAL_MPP_LIKES_DISLIKES: {
    SET: 'mpp/SET_MPP_LIKES_DISLIKES',
    CLEAR: 'mpp/CLEAR_LOCAL_MPP_LIKES_DISLIKES',
  },
  GENERAL_MPP: {
    CLEAR_ERROR: 'mpp/CLEAR_ERROR',
    CLEAR_STATUS: 'mpp/CLEAR_STATUS',
    RESET_STATES: 'mpp/RESET_STATES',
  },
  MPP_PAGINATION_REFERENCE: {
    UPDATE: 'mpp/MPP_PAGINATION_REFERENCE_UPDATE',
    CLEAR: 'mpp/MPP_PAGINATION_REFERENCE_CLEAR',
  },
  PERFECT_PROPERTY_RECOMMENDATION: {
    REQUEST: 'mpp/PERFECT_PROPERTY_RECOMMENDATION_REQUEST',
    SUCCESS: 'mpp/PERFECT_PROPERTY_RECOMMENDATION_SUCCESS',
    FAILED: 'mpp/PERFECT_PROPERTY_RECOMMENDATION_FAILED',
    CLEAR: 'mpp/PERFECT_PROPERTY_RECOMMENDATION_CLEAR',
  },
}
