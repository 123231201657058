import { 
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAIL
} from '../constants';

export function sendContactRequest(payload) {
  return {
      type: SEND_CONTACT_REQUEST,
      payload
  }
}

export function sendContactSuccess(payload) {
  return {
      type: SEND_CONTACT_SUCCESS,
      payload
  }
}

export function sendContactFail(payload) {
  return {
      type: SEND_CONTACT_FAIL,
      payload
  }
}

