import React, { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { StreetView } from 'react-google-map-street-view';
import DynamicIcons from "../DynamicIcons";
import Image from '../Image'

import GoogleMapView from "./GoogleMapView";
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'

import { withStyles, Card, CardContent, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        height: '100%'
    },
    mapviewport: {
        maxHeight: '114px',
        position: 'relative',
        overflow: 'hidden'
    },
    cardcontent: {
        '&:last-child': {
            paddingBottom: theme.spacing(2)
        }
    },
    maps: {
        height: '100%',
        width: '100%'
    },
    details: {
        fontSize: 12
    }
});

const streetViewCSS = {
    height: '100%',
    width: '100%'
}
const Marker = ({ content }) => <div>{content}</div>;
const handleApiLoaded = (map, maps) => {
    // use map and maps objects
};



const MapCard = props => {
    const { classes, data } = props;
    const STATIC_MAP_SRC = 'https://maps.googleapis.com/maps/api/staticmap?center=' + data.map.coordinates.toString() + '&zoom=13&size=152x114&scale=2&maptype=roadmap&key=' + GOOGLE_MAP_API_KEY + '';
    const STATIC_STREETVIEW_SRC = 'https://maps.googleapis.com/maps/api/streetview?size=152x114&location=' + data.map.coordinates.toString() + '&fov=120&heading=70&pitch=0&key=' + GOOGLE_MAP_API_KEY + ''

    // console.log(data, 'DATA ON MAPCARD')

    const Map = (props) => {
        const isInteractive = props.isInteractive;
        if (isInteractive) {
            return <GoogleMapView data={data} />
        }
        return <div>
            <Image src={STATIC_MAP_SRC} aspectRatio={16 / 9} imageStyle={{ objectFit: 'cover' }} />
            <DynamicIcons viewBox="0 0 24 28"
                style={{ position: 'absolute', left: '38%', top: '30%', width: '1.5em', height: '1.5em' }}
                icon="2DCSaleDropInIcon"
            />
        </div>
    }
    const Street = (props) => {
        const isInteractive = props.isInteractive;
        if (isInteractive) {
            return <StreetView mapStyle={streetViewCSS} address={data.address} APIkey={GOOGLE_MAP_API_KEY} streetView zoomLevel={data.map.zoom} />
        }
        return <Image src={STATIC_STREETVIEW_SRC} aspectRatio={4 / 3} />;
    }

    return (
        <Card className={classes.root}>

            <div className={classes.mapviewport}>
                {data.mode === 'map' &&
                    <Map isInteractive={data.interactive} />
                }
                {data.mode === 'street' &&
                    <Street isInteractive={data.interactive} />
                }
            </div>

            <CardContent className={classes.cardcontent}>
                <Typography variant="subtitle2" gutterBottom>
                    {data.title}
                </Typography>

                {data.mode === 'map' && <Typography variant="body2" className={classes.details}>Explore the area around {data.address}</Typography>}
                {data.mode === 'street' && <Typography variant="body2" className={classes.details}>Take a virtual walk around the neighborhood</Typography>}
            </CardContent>
        </Card>
    )
}

export default withStyles(styles)(MapCard);
