import { PURGE } from 'redux-persist';
import {
    VALIDATE_IMMEDIATELY,
    VALIDATE_ON_BLUR,
    VALIDATE_ON_CHANGE,
    VALIDATE_ON_SUBMIT,
    DO_VALIDATE,
} from '../constants/validation.constants';

const INITIAL_STATE = {
    values: {},
    errors: {},
    blurred: {},
    changed: {},
    submitted: false,
};

const validationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VALIDATE_ON_CHANGE:
            const values = { ...state.values, ...action.payload };
            return {
                ...state,
                values,
                errors: {},
            };
        case VALIDATE_ON_BLUR:
            const blurred = {
                ...state.blurred,
                [action.payload]: true
            };
            return { ...state, blurred };
        case VALIDATE_IMMEDIATELY:
            const changed = {
                ...state.changed,
                [action.payload]: true
            };
            return { ...state, changed };

        case VALIDATE_ON_SUBMIT:
            return { ...state, submitted: action.payload };
        case DO_VALIDATE:
            return { ...state, errors: action.payload };
        case PURGE:
            return INITIAL_STATE
        default: return state;
    }
}

export default validationReducer