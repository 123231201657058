import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function HandCrown(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>      
      <path d="M54.2455 42.8593L47.0312 47.6253C46.1164 46.0711 44.4078 45.0178 42.492 45.0178H36.1234C34.7599 45.0178 34.1041 44.0162 33.4655 43.55C28.5466 39.2848 21.1079 39.2848 16.1891 43.55L14.7911 44.7415H1.31169C0.586811 44.7415 0 45.3286 0 46.0539V61.7678C0 62.4931 0.586811 63.0802 1.31169 63.0802H14.8946L38.3498 66.9827C38.695 67.0345 39.0574 66.9655 39.3336 66.741L66.465 46.9518C67.0518 46.5201 67.1726 45.7085 66.7584 45.1214C63.8761 41.1843 58.3877 40.1655 54.2455 42.8593ZM38.229 64.3407L15.2053 60.5072C15.1363 60.49 15.0672 60.49 14.9982 60.49H2.60613V47.4008H15.2743C16.0683 47.4008 16.2926 46.8309 17.8977 45.5704C21.8501 42.1513 27.8045 42.1513 31.7396 45.5704C32.2919 45.933 33.569 47.6771 36.1061 47.6771H42.4748C43.89 47.6771 45.0981 48.7995 45.0981 50.2155C45.0981 51.6314 43.9245 52.7711 42.4748 52.7711H26.7689C26.0441 52.7711 25.4572 53.3583 25.4572 54.0835C25.4572 54.8088 26.0441 55.3959 26.7689 55.3959H42.4748C45.3225 55.3959 47.6525 53.1338 47.7043 50.3363L55.6607 45.0696C58.2496 43.3946 61.5806 43.7227 63.7553 45.743L38.229 64.3407Z" fill="#020202"/>
      <path d="M42.6644 25.643C42.6644 26.3683 42.0776 26.9554 41.3527 26.9554C40.6278 26.9554 40.041 26.3683 40.041 25.643C40.041 24.9178 40.6278 24.3307 41.3527 24.3307C42.0776 24.3479 42.6644 24.935 42.6644 25.643Z" fill="#020202"/>
      <path d="M25.5093 32.2049H56.9209C57.6458 32.2049 58.2326 31.6178 58.2326 30.8925V25.7812L62.2022 5.49124C62.3058 4.9732 62.0814 4.43789 61.65 4.14433C61.2185 3.85077 60.6317 3.85077 60.2002 4.14433L49.5168 11.276L42.3888 0.587113C42.1471 0.224485 41.7329 0 41.3014 0C40.87 0 40.4557 0.224485 40.2141 0.587113L33.0688 11.276L22.3682 4.14433C21.9367 3.85077 21.3671 3.85077 20.9184 4.14433C20.4869 4.43789 20.2625 4.95593 20.3488 5.47397L24.1976 25.7812V30.8925C24.2149 31.6178 24.7844 32.2049 25.5093 32.2049ZM32.7064 14.1771C33.3104 14.5742 34.1216 14.4188 34.5186 13.8144L41.2842 3.66082L48.0497 13.8144C48.4467 14.4188 49.2579 14.5742 49.862 14.1771L59.0266 8.06418L55.8509 24.3479H47.2386C46.5137 24.3479 45.9269 24.9351 45.9269 25.6603C45.9269 26.3856 46.5137 26.9727 47.2386 26.9727H55.6093V29.5974H26.821V26.9727H35.4506C36.1755 26.9727 36.7623 26.3856 36.7623 25.6603C36.7623 24.9351 36.1755 24.3479 35.4506 24.3479H26.5794L23.49 8.06418L32.7064 14.1771Z" fill="#020202"/>
    </SvgIcon>
  );
}

export function HandCrownSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HandCrown />
      <HandCrown color="primary" />
      <HandCrown color="secondary" />
      <HandCrown color="action" />
      <HandCrown color="disabled" />
      <HandCrown style={{ color: green[500] }} />
    </div>
  );
}
