export function getAgentState(state) {
  return state.agents.agent_state?.data?.agent?.agentState
}
export function getAgentStateStatus(state) {
  return state.agents.agent_state_status
}

export function getAgentActivate(state) {
  return state.agents.agent_activate
}
export function getAgentActivateStatus(state) {
  return state.agents.agent_activate_status
}

export function getUploadSOI(state) {
  return state.agents.request_upload_property_intent
}
export function getUploadSOIStatus(state) {
  return state.agents.request_upload_property_intent_status
}
export function getUploadSOIError(state) {
  return state.agents.request_upload_property_intent_error
}

export default { getAgentState, getAgentStateStatus, getAgentActivate, getAgentActivateStatus }
