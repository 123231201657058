import React from 'react'
import { withStyles, Breadcrumbs, Link } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    fontSize: '0.925em',
  },
})

const CrumbsBack = (props) => {
  const { type, crumbsOption, classes, goBack } = props
  const [routerChange] = useRouterChange()

  return (
    <Breadcrumbs
      separator=">"
      style={{ color: type === 'LIGHT' ? '#FFF' : '#6a6a6a' }}
      className={classes.root}
    >
      <Link
        style={{
          textTransform: 'capitalize',
          color: type === 'LIGHT' ? '#FFF' : '#6a6a6a',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          if (routerChange) {
            routerChange('/')
          }
        }}
      >
        Home
      </Link>
      {!crumbsOption.length ? (
        <Link
          style={{
            textTransform: 'capitalize',
            color: type === 'LIGHT' ? '#FFF' : '#6a6a6a',
            cursor: 'pointer',
          }}
          onClick={() =>
            crumbsOption.route ? routerChange(crumbsOption.route) : routerChange('back')
          }
        >
          {crumbsOption.label ? crumbsOption.label.replace(/-/g, ' ') : ''}
        </Link>
      ) : (
        crumbsOption.map((o, k) => {
          return (
            <Link
              key={k}
              style={{
                textTransform: 'capitalize',
                color: type === 'LIGHT' ? '#FFF' : '#6a6a6a',
                cursor: 'pointer',
              }}
              onClick={() => routerChange(o.route)}
            >
              {o.label ? o.label.replace(/-/g, ' ') : ''}
            </Link>
          );
        })
      )}
    </Breadcrumbs>
  );
}

export default withStyles(styles)(CrumbsBack)
