import { useEffect, useState } from 'react'

const getIsSmall = () => window.innerWidth <= 1280
const getIsMedium = () => window.innerWidth <= 1707
const getIsLarge = () => window.innerWidth > 1707

const useSize = () => {
  const [isSmall, setIsSmall] = useState(getIsSmall())
  const [isMedium, setIsMedium] = useState(getIsMedium())
  const [isLarge, setIsLarge] = useState(getIsLarge())

  useEffect(() => {
    const onResize = () => {
      setIsSmall(getIsSmall())
      setIsMedium(getIsMedium())
      setIsLarge(getIsLarge())
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return { isSmall, isMedium, isLarge }
}

export default useSize
