import { PURGE } from 'redux-persist'
import { MPP_ACTION_TYPES } from 'store/constants/mpp.constants'

const defaultMppPaginationObj = {
  pageNumber: 0,
  pageSize: 10,
  propertiesFilters: [],
  totalResults: null,
}

const INITIAL_STATE = {
  // temporary values
  payload_request: null,
  payload_success: null,
  payload_failed: null,
  status: null,
  error: null,
  recommendations: null,
  likes_dislikes: {
    likes: [],
    dislikes: [],
  },
  mpp_favourites: null,
  mpp_dislikes: null,
  favourites: null,
  mpp_search_results: null,
  mppPagination: defaultMppPaginationObj,
}

const mppReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
        mpp_favourites: null,
      }

    case MPP_ACTION_TYPES['GET_MPP_DISLIKES']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
        mpp_dislikes: null,
      }

    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES_IDS']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
        favourites: null,
      }

    case MPP_ACTION_TYPES['GET_MPP']['REQUEST']:
    case MPP_ACTION_TYPES['PUT_MPP_FAVOURITES']['REQUEST']:
    case MPP_ACTION_TYPES['PUT_MPP_LIKES_DISLIKES']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
        recommendations: null,
      }

    case MPP_ACTION_TYPES['PERFECT_PROPERTY_RECOMMENDATION']['REQUEST']:
      return {
        ...state,
        status: action.type,
        payload_request: action.params,
        error: null,
      }

    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES']['FAILED']:
    case MPP_ACTION_TYPES['GET_MPP_DISLIKES']['FAILED']:
    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES_IDS']['FAILED']:
    case MPP_ACTION_TYPES['GET_MPP']['FAILED']:
    case MPP_ACTION_TYPES['PUT_MPP_FAVOURITES']['FAILED']:
    case MPP_ACTION_TYPES['PUT_MPP_LIKES_DISLIKES']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params.data,
      }

    case MPP_ACTION_TYPES['PERFECT_PROPERTY_RECOMMENDATION']['FAILED']:
      return {
        ...state,
        status: action.type,
        payload_failed: action.params,
        error: action.params?.data,
      }

    case MPP_ACTION_TYPES['PUT_MPP_FAVOURITES']['SUCCESS']:
    case MPP_ACTION_TYPES['PUT_MPP_LIKES_DISLIKES']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        payload_failed: null,
        error: null,
        payload_success: action.params.data,
      }
    case MPP_ACTION_TYPES['PERFECT_PROPERTY_RECOMMENDATION']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        mpp_search_results: action.params.data,
        payload_failed: null,
        error: null,
        payload_success: action.params.data,
      }

    case MPP_ACTION_TYPES['GET_MPP']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        recommendations: action.params.data,
      }

    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        mpp_favourites: action.params.data,
      }

    case MPP_ACTION_TYPES['GET_MPP_DISLIKES']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        mpp_dislikes: action.params.data,
      }

    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES_IDS']['SUCCESS']:
      return {
        ...state,
        status: action.type,
        favourites: action.params.data,
      }

    case MPP_ACTION_TYPES['LOCAL_MPP_LIKES_DISLIKES']['SET']:
      const likedItems = [
        ...new Set([...state.likes_dislikes.likes, ...action.params.likes]),
      ]
      const dislikedItems = [
        ...new Set([...state.likes_dislikes.dislikes, ...action.params.dislikes]),
      ]
      return {
        ...state,
        status: action.type,
        likes_dislikes: {
          ...state.likes_dislikes,
          likes: likedItems,
          dislikes: dislikedItems,
        },
      }
    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_request: null,
        payload_success: null,
        mpp_favourites: null,
      }

    case MPP_ACTION_TYPES['GET_MPP_DISLIKES']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_request: null,
        payload_success: null,
        mpp_dislikes: null,
      }

    case MPP_ACTION_TYPES['GET_MPP_FAVOURITES_IDS']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_request: null,
        payload_success: null,
        favourites: null,
      }

    case MPP_ACTION_TYPES['LOCAL_MPP_LIKES_DISLIKES']['CLEAR']:
      return {
        ...state,
        status: action.type,
        payload_request: null,
        payload_success: null,
        likes_dislikes: {
          likes: [],
          dislikes: [],
        },
      }

    case MPP_ACTION_TYPES['PERFECT_PROPERTY_RECOMMENDATION']['CLEAR']:
      return {
        ...state,
        status: action.type,
        mpp_search_results: null,
        payload_request: null,
        error: null,
      }

    case MPP_ACTION_TYPES['MPP_PAGINATION_REFERENCE']['CLEAR']:
      return {
        ...state,
        mppPagination: defaultMppPaginationObj,
      }

    case MPP_ACTION_TYPES['MPP_PAGINATION_REFERENCE']['UPDATE']:
      return {
        ...state,
        status: 'mpp/MPP_PAGINATION_REFERENCE_UPDATE',
        mppPagination: action.params,
      }

    case MPP_ACTION_TYPES['GENERAL_MPP']['CLEAR_ERROR']:
      return {
        ...state,
        status: null,
        payload_request: null,
        payload_success: null,
        payload_failed: null,
        error: null,
      }

    case MPP_ACTION_TYPES['GENERAL_MPP']['CLEAR_STATUS']:
      return {
        ...state,
        status: null,
      }
    case MPP_ACTION_TYPES['GENERAL_MPP']['RESET_STATES']:
      return INITIAL_STATE
    case PURGE:
      return INITIAL_STATE

    default: {
      return state
    }
  }
}

export default mppReducers
