export const PRICE_RANGE = [50000, 15000000];
export const PRICE_RANGE_RENT = [50, 5000];
export const DISTANCE_RANGE = [0, 500];

export const PRICE_MARKS = [
  {
    value: 0,
    scaledValue: 50000,
    label: '50k',
  },
  {
    value: 25,
    scaledValue: 300000,
    label: '300k',
  },
  {
    value: 50,
    scaledValue: 550000,
    label: '550k',
  },
  {
    value: 75,
    scaledValue: 1000000,
    label: '1M',
  },
  {
    value: 100,
    scaledValue: 1500000,
    label: '1.5M',
  },
  {
    value: 125,
    scaledValue: 2000000,
    label: '2M',
  },
  {
    value: 150,
    scaledValue: 3000000,
    label: '3M',
  },
  {
    value: 175,
    scaledValue: 4000000,
    label: '4M',
  },
  {
    value: 200,
    scaledValue: 5000000,
    label: '5M',
  },
  {
    value: 225,
    scaledValue: 8000000,
    label: '8M',
  },
  {
    value: 250,
    scaledValue: 10000000,
    label: '10M',
  },
  {
    value: 275,
    scaledValue: 15000000,
    label: '15M',
  },
];

export const RENT_PRICE_MARKS = [
  {
    value: 0,
    scaledValue: 50,
    label: '50',
  },
  {
    value: 25,
    scaledValue: 100,
    label: '100',
  },
  {
    value: 50,
    scaledValue: 150,
    label: '150',
  },
  {
    value: 75,
    scaledValue: 300,
    label: '300',
  },
  {
    value: 100,
    scaledValue: 450,
    label: '450',
  },
  {
    value: 125,
    scaledValue: 800,
    label: '800',
  },
  {
    value: 150,
    scaledValue: 1000,
    label: '1k',
  },
  {
    value: 175,
    scaledValue: 2000,
    label: '2k',
  },
  {
    value: 200,
    scaledValue: 3000,
    label: '3k',
  },
  {
    value: 225,
    scaledValue: 4000,
    label: '4k',
  },
  {
    value: 250,
    scaledValue: 5000,
    label: '5k',
  },
];

export const DISTANCE_MARKS = [
  {
    value: 0,
    scaledValue: 0,
    label: '0',
  },
  {
    value: 25,
    scaledValue: 50,
    label: '50',
  },
  {
    value: 50,
    scaledValue: 100,
    label: '100',
  },
  {
    value: 75,
    scaledValue: 150,
    label: '150',
  },
  {
    value: 100,
    scaledValue: 200,
    label: '200',
  },
  {
    value: 125,
    scaledValue: 450,
    label: '450',
  },
  {
    value: 150,
    scaledValue: 500,
    label: '500',
  },
];

export const GLOBAL_FILTER_DEFAULT = {
  listing_search_sort: [
    {
      id: 'relevance',
      title: 'Relevance (Default)',
      value: 'relevance',
      active: true,
    },

    {
      id: 'createdAt',
      title: 'Latest Property',
      value: 'latestProperty',
      active: false,
    },
    {
      id: 'priceDesc',
      title: 'Price (High to Low)',
      value: 'priceDesc',
      active: false,
    },

    { id: 'priceAsc', title: 'Price (Low to High)', value: 'priceAsc', active: false },

    { id: 'bedrooms', title: 'Bedrooms', value: 'Bedrooms', active: false },

    { id: 'bathrooms', title: 'Bathrooms', value: 'Bathrooms', active: false },

    { id: 'sqm', title: 'Sqm', value: 'Sqm', active: false },
  ],

  //For State Filter
  state: [
    { id: 'act', title: 'ACT', active: false },
    { id: 'nsw', title: 'NSW', active: false },
    { id: 'nt', title: 'NT', active: false },
    { id: 'qld', title: 'QLD', active: false },
    { id: 'sa', title: 'SA', active: false },
    { id: 'tas', title: 'TAS', active: false },
    { id: 'vic', title: 'VIC', active: false },
    { id: 'wa', title: 'WA', active: false },
  ],
  suburbs: [],
  include_nearby_suburbs: true,

  search_reference: [
    {
      id: 'buy',
      name: 'Buy',
      icon: 'AttachMoney',
      path: '/buy',
      active: true,
      enabled: true,
    },
    {
      id: 'rent',
      name: 'Rent',
      icon: 'VpnKey',
      path: '/rent',
      active: false,
      enabled: true,
    },
    {
      id: 'sold',
      name: 'Sold',
      icon: 'Gavel',
      path: '/sold',
      active: false,
      enabled: true,
    },
    {
      id: 'address',
      name: 'Address',
      icon: 'House',
      path: '/address',
      active: false,
      enabled: true,
    },
    {
      id: 'comingSoon',
      name: 'Coming Soon',
      icon: 'WatchLater',
      path: '/coming-soon',
      active: false,
      enabled: true,
    },
    {
      id: 'offMarket',
      name: 'Off Market',
      icon: 'Lock',
      path: '/off-market',
      active: false,
      enabled: true,
    },
    {
      id: 'mpp',
      name: 'Find My Perfect Property',
      icon: 'ThumbUpAlt',
      path: '/perfect-property',
      active: false,
      enabled: true,
    },
  ],

  // For Distance by Kilometer Radius
  radius_distance: {
    options: [
      {
        id: 'capital',
        label: 'Capital city',
        active: false,
        enabled: true,
      },
      {
        id: 'suburbs',
        label: 'Suburbs',
        active: false,
        enabled: false,
      },
      {
        id: 'rural',
        label: 'Rural setting',
        active: false,
        enabled: false,
      },
      {
        id: 'country',
        label: 'Country Town',
        active: false,
        enabled: false,
      },
    ],
    min: DISTANCE_RANGE[0],
    max: DISTANCE_RANGE[1],
    scaled: [DISTANCE_MARKS[0].value, DISTANCE_MARKS[DISTANCE_MARKS.length - 1].value],
  },
  property_type: [
    {
      id: 'house',
      title: 'House',
      icon: '2DCHouseIcon',
      active: false,
      enabled: true,
      count: 0,
    },
    {
      id: 'townhouse',
      title: 'Townhouse',
      icon: '2DCTownHouseIcon',
      active: false,
      enabled: true,
      count: 0,
    },
    {
      id: 'apartment',
      title: 'Apartment & Unit',
      icon: '2DCApartmentIcon',
      active: false,
      enabled: true,
      count: 0,
    },
    {
      id: 'land',
      title: 'Land',
      icon: '2DCLandIcon',
      active: false,
      enabled: true,
      count: 0,
    },
    {
      id: 'rural',
      title: 'Rural',
      icon: '2DCRuralIcon',
      active: false,
      enabled: true,
    },
    {
      id: 'retirement',
      title: 'Retirement',
      icon: '2DCRetirementVillageIcon',
      active: false,
      enabled: true,
      count: 0,
    },
    {
      id: 'villa',
      title: 'Villa',
      icon: '2DCVillaIcon',
      active: false,
      enabled: true,
      count: 0,
    },
  ],
  price: {
    type: 'price',
    min: PRICE_RANGE[0],
    max: PRICE_RANGE[1],
    scaled: [PRICE_MARKS[0].value, PRICE_MARKS[PRICE_MARKS.length - 1].value],
    ranged: {
      min: PRICE_RANGE[0],
      max: PRICE_RANGE[1],
    },
  },
  price_rent: {
    type: 'price',
    min: PRICE_RANGE_RENT[0],
    max: PRICE_RANGE_RENT[1],
    scaled: [RENT_PRICE_MARKS[0].value, RENT_PRICE_MARKS[RENT_PRICE_MARKS.length - 1].value],
  },
  bedroom: {
    value: 0,
    label: 'Bedroom',
    exact: false,
  },
  bathroom: {
    value: 0,
    label: 'Bathroom',
    exact: false,
  },
  carparking: {
    value: 0,
    label: 'Parking',
    exact: false,
  },
  land_size: {
    min: null,
    max: null,
  },
  features: [
    {
      id: 'gas',
      label: 'Gas',
      active: false,
      enabled: true,
    },
    {
      id: 'balcony',
      label: 'Balcony',
      active: false,
      enabled: true,
    },
    {
      id: 'study',
      label: 'Study',
      active: false,
      enabled: true,
    },
    {
      id: 'aircon',
      label: 'Air Conditioning',
      active: false,
      enabled: true,
    },
    {
      id: 'builtinwardrobes',
      label: 'Built-In Wardrobes',
      active: false,
      enabled: true,
    },
    {
      id: 'garden',
      label: 'Garden/Courtyard',
      active: false,
      enabled: true,
    },
    {
      id: 'pool',
      label: 'Swimming Pool',
      active: false,
      enabled: true,
    },
  ],
  nearby_filters: [
    {
      id: 'park',
      label: 'Parklife',
      sublabel: 'Walk to greenspace within 15 mins',
      key: 'close_to_parks',
      active: false,
      enabled: true,
    },
    {
      id: 'shop',
      label: 'Shopping',
      sublabel: 'Drive to retail within 15 mins',
      key: 'close_to_shops',
      active: false,
      enabled: true,
    },
    {
      id: 'primary_school',
      label: 'Primary School',
      sublabel: 'Drive to primary schools within 15 mins',
      key: 'close_to_primary_schools',
      active: false,
      enabled: true,
    },
    {
      id: 'secondary_school',
      label: 'Secondary School',
      sublabel: 'Drive to secondary schools within 15 mins',
      key: 'close_to_secondary_schools',
      active: false,
      enabled: true,
    },
    {
      id: 'cafe',
      label: 'Cafe',
      sublabel: 'Drive to cafes in 5 minutes',
      key: 'close_to_cafes',
      active: false,
      enabled: true,
    },
    {
      id: 'transport',
      label: 'Transport',
      sublabel: 'Walk to public transport within 20min',
      key: 'close_to_public_transports',
      active: false,
      enabled: true,
    },
    {
      id: 'nightlife',
      label: 'Nightlife',
      sublabel: 'Drive to bars/restaurants within 15min',
      key: 'inner_city_vibe',
      active: false,
      enabled: true,
    },
  ],
  sorting: null,
  available_date: null,
  keyword: null,
  exclude_under_offers: false,
  is_established: null,
  furnished: null,
  reference_filters: null,
};
