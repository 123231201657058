import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  Fragment,
} from 'react'
import moment from 'moment'
import getDayOfYear from 'date-fns/getDayOfYear'
import { connect } from 'react-redux'

import HeartIcon from '@material-ui/icons/FavoriteBorder'
import HeartFullIcon from '@material-ui/icons/Favorite'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'

import Image from 'material-ui-image'

import AgentBanner from './AgentBanner'
import PropertyInfo from './PropertyInfo'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ReloadIcon from '../../../assets/images/reload.svg'
import styled from 'styled-components'
import _ from 'lodash'

import { cloudinaryCardTransforms, cloudinaryImageHeightSmall } from '../../../constants/constants'

import { useDispatch, useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { convertTagToString, extractTagsToDisplay } from 'utils/propertyTagsUtils'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'
import {
  withStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import useFilters from 'utils/customHooks/useFilters'
import { useRouterChange } from 'utils/router.utils'
const CarouselWrapper = styled.div`
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
`

const OverlayReload = styled.div`
  position: absolute;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    display: block;
    text-align: center;
    text-decoration: none;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    pointer-events: none;
    width: 2em !important;
  }
  p {
    color: #fff;
    pointer-events: none;
    margin-top: 5px;
  }
`

const DotsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  .BrainhubCarousel__dots {
    .BrainhubCarousel__dot {
      padding: 20px 5px;
      &:before {
        background: #fff;
        width: 7px;
        height: 7px;
      }
    }
  }
`

const styles = (theme) => ({
  cardFull: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 0,
    minHeight: 300,
    backgroundColor: '#FFF',
  },
  cardPadded: {
    margin: theme.spacing(3),
    width: window.innerWidth - theme.spacing(3) * 2,
    // maxHeight: '31.125em',
    backgroundColor: '#FFF',
  },
  cardPaddedSwipe: {
    margin: theme.spacing(3),
    width: window.innerWidth - theme.spacing(3) * 2,
    height: '35.125em',
    backgroundColor: '#FFF',
  },
  media: {
    width: '100%',
    height: '28.571em',
    objectFit: 'cover !important',
  },
  favoriteContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
  tagContainer: {
    position: 'relative',
  },
  tagTypeText: {
    color: '#FFF',
    fontSize: 12,
  },
  tagTypeAuction: {
    width: '13.929em',
    height: '1.786em',
    backgroundColor: 'rgba(6, 18, 82, 0.8)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagTypeComingSoon: {
    minWidth: '140px',
    maxWidth: '140px',
    backgroundColor: 'rgba(253, 189, 38, 0.9)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 0',
    textAlign: 'center',
  },
  tagTypeOffMarket: {
    minWidth: '140px',
    maxWidth: '140px',
    backgroundColor: '#F50057',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 0',
    textAlign: 'center',
  },
  textLabelCloseTo: {
    fontSize: '0.875em',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  chipText: {
    fontSize: 10,
  },
  chipIcon: {
    width: 10,
    height: 10,
  },

  rowContainerCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  rowContainerEven: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  chipContainer: {
    minWidth: '3.750em',
    height: '1.300em',
    paddingRight: '0.425em',
    paddingLeft: '0.425em',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    border: ' 0.5px solid #35C0CA',
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    marginBottom: '0.425em',
    marginRight: '0.125em',
  },
  reloadWrapper: {
    pointerEvents: 'none',
    '& a': {
      pointerEvents: 'initial',
    },
  },
  newListingLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: theme.spacing(0.375, 2),
    // maxWidth: 112,
    // minWidth: 112,
    position: 'absolute',
    bottom: 0,
    left: 0,
    // backgroundColor: '#ef6631',
    textAlign: 'center',
    color: '#fff',
    '& > p': {
      fontWeight: 300,
      lineHeight: '1.55',
      padding: '2px 8px',
      marginRight: '4px',
    },
  },
  infoIconContainer: {
    zIndex: 100,
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 0,
    cursor: 'pointer',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    backgroundColor: '#ffffff',
    transition: 'opacity 0.3s ease',
    borderRadius: 20,
    fontSize: 12,
    padding: '3px 5px',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
  },
  infoIcon: {
    width: 30,
    height: 30,
    color: '#57bdc7',
  },
  removeFromFavoriteList: {
    display: 'none',
  },
  addFavoritesContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: 65,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
})

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 20px)',
  width: 60,
  height: 60,
  cursor: 'pointer',
}

const iconStyles = {
  width: '100%',
  height: '100%',
}

const PropertyCard = forwardRef((props, ref) => {
  const {
    classes,
    isFullWidth,
    properties,
    property,

    authentication,
    settings,
    toggleSignUp,
    setCurrentProperty,
    apiRequestSaveProperty,
    apiPutRemoveDislikedProperty,
    cardType,
    addLikedDrillDown,
    addUnlikedDrillDown,
    listingType,
    key,
    editFavoriteStatus,
  } = props
  const [routerChange] = useRouterChange()

  const [value, setValue] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const [currentFavorites, setCurrentFavorites] = useState(null)
  // const [propertImages, setPropertyImages] = useState(_.compact(properties.images));
  const [imgError, setImgError] = useState(false)
  const [carouselOffset, setCarouselOffset] = useState(3)
  const [heartClicked, setHeartClicked] = useState(false)
  const [heartActionError, setHeartActionError] = useState(false)
  const handleCarouselChange = (e) => {
    setCarouselOffset(e + 3)
  }

  let atFavoritesRoute = false
  let atDislikesRoute = false
  if (typeof window !== 'undefined') {
    const favoritesUrl = window.location.pathname.split('/')[1]
    const DislikesUrl = window.location.pathname.split('/')[1]
    if (favoritesUrl === 'favourites') {
      atFavoritesRoute = true
    }
    if (DislikesUrl === 'disliked-properties') {
      atDislikesRoute = true
    }
  }

  const [getFilter, setFilter] = useFilters()
  const NEARBY_FILTERS = getFilter('nearby_filters')

  const [galleryImages, setGalleryImages] = useState(null)

  const MPP = useSelector(getMpp)
  const [dislikeClicked, setDislikedClicked] = useState(false)
  const [isDisliked, setIsDisliked] = useState(false)

  let likes = MPP?.favourites?.data?.likes
  let dislikes = MPP?.favourites?.data?.dislikes

  const [isFavorite, setIsfavorite] = useState()

  useEffect(() => {
    if (properties && likes) {
      likes?.includes(properties?.property) ? setIsfavorite(true) : setIsfavorite(false)
    }
    if (properties && dislikes) {
      dislikes?.includes(properties?.property)
        ? setIsDisliked(true)
        : setIsDisliked(false)
    }
  }, [likes, dislikes, properties])

  useEffect(() => {
    if (properties && properties.images && properties.images.length > 0) {
      let gallery_array = []
      if (properties.cloudinaryImages && properties.cloudinaryImages.length > 0) {
        properties.cloudinaryImages.map((item, idx) => {
          const file = item?.path + cloudinaryCardTransforms + ',' + cloudinaryImageHeightSmall + '/' + item?.file
          gallery_array.push(file)
        })
      } else {
        gallery_array = _.compact(properties.images)
      }

      setGalleryImages(gallery_array)
    }
  }, [properties])

  const showNewListingLabel = () => {
    const publishedDate = new Date(properties.createdAt)
    const publishedDayOfYear = getDayOfYear(publishedDate)
    const publishedYear = publishedDate.getFullYear()
    const currentDayOfYear = getDayOfYear(new Date())
    const currentYear = new Date().getFullYear()

    if (publishedYear >= currentYear) {
      let newTagDuration

      switch (properties.subscriptionType?.toLowerCase()) {
        case 'elite':
        case 'executive':
        case 'platinum':
        case 'premium':
          newTagDuration = 7
          break

        case 'extended':
        case 'standard':
          newTagDuration = 3
          break

        default:
          newTagDuration = -1
      }

      if (publishedDayOfYear + newTagDuration >= currentDayOfYear) {
        return true
      }
      return false
    } else {
      return false
    }
  }

  const onChange = (value) => {
    setValue(value)
  }

  useImperativeHandle(ref, () => ({
    /**
     * set property on card swipe selected */
    selectProperty(params) {
      /**
       * Add to Drill Down Property */
      property.liked_drill_down_properties.push(params)
      addLikedDrillDown(property.liked_drill_down_properties)

      /**
       * Set current property */
      setCurrentProperty(params)
      routerChange('/' + listingType + '/' + params.property, {
        previous_screen: listingType,
      })
    },
  }))

  useEffect(() => {
    if (
      MPP?.error &&
      MPP?.status === 'mpp/PUT_MPP_FAVOURITES_FAILED' &&
      heartClicked &&
      authentication &&
      authentication.user_status === 'logged'
    ) {
      setHeartActionError(true)
      setHeartClicked(false)
    }
    if (!MPP?.error && MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      setHeartActionError(false)
      setHeartClicked(false)
    }
  }, [MPP, heartClicked, authentication])

  function selectProperty(willScroll) {
    console.debug('CHECK MORE INFO IS CLICKED')
    if (!isSwiping) {
      setCurrentProperty(properties)
      if (willScroll) {
        routerChange('/' + listingType + '/' + properties.property, {
          scrollToAgent: true,
          previous_screen: props.listingType,
        })
      } else {
        routerChange('/' + listingType + '/' + properties.property, {
          previous_screen: props.listingType,
        })
      }
    }
  }

  function renderTypeTag(type, property) {
    let propertyStatus = type.toLowerCase().replace(/[^\w\s]/gi, '')
    let isForRent = property.forRent ? true : false
    let conditionCheck = propertyStatus + ':' + isForRent
    const soldDate = property.soldDate
      ? moment(property.soldDate).format('Do MMMM YYYY')
      : ''

    const dateNow = new Date()
    // Reset time to 0 to show todays auction for the whole day
    dateNow.setHours(0, 0, 0, 0)

    let auctionDateData = {
      showDate: false,
      date: '',
    }

    if (property.auctionDate) {
      const auctionDate = new Date(property.auctionDate)

      if (auctionDate > dateNow) {
        auctionDateData = {
          date: moment(auctionDate).format('Do MMMM YYYY'),
          showDate: true,
        }
      }
    }

    switch (conditionCheck) {
      case 'leased:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              Leased
            </Typography>
          </div>
        )
      case 'onsale:false':
        return (
          auctionDateData.showDate && (
            <div className={classes.tagTypeAuction}>
              <Typography
                className={classes.tagTypeText}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Auction ' + auctionDateData.date}
              </Typography>
            </div>
          )
        )
      case 'current:false':
        return (
          auctionDateData.showDate && (
            <div className={classes.tagTypeAuction}>
              <Typography
                className={classes.tagTypeText}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Auction ' + auctionDateData.date}
              </Typography>
            </div>
          )
        )
      case 'sold:false':
        return (
          soldDate && (
            <div className={classes.tagTypeAuction}>
              <Typography
                className={classes.tagTypeText}
                variant="caption"
                color="textSecondary"
                component="p"
              >
                {'Sold ' + soldDate}
              </Typography>
            </div>
          )
        )
      case 'comingsoon:false':
        return (
          <div className={classes.tagTypeComingSoon}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
              style={{ fontSize: '14px' }}
            >
              Coming Soon
            </Typography>
          </div>
        )
      case 'offmarket:false':
        return (
          <div className={classes.tagTypeOffMarket}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
              style={{ fontSize: '14px' }}
            >
              Off Market
            </Typography>
          </div>
        )
    }
  }

  const handleShowBanner = (subscriptionType) => {
    const mutatedSubscriptionType = subscriptionType?.toLowerCase()

    return mutatedSubscriptionType === 'entry' ? false : true
  }

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
    } else {
      /** User Logged */
      // if (editFavoriteStatus) {
      //   editFavoriteStatus(properties.property)
      // }
      setIsfavorite((prev) => !prev)
      apiRequestSaveProperty(properties.property)
    }
  }

  function removeDislikedProperty() {
    setDislikedClicked(true)
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
      // routerChange('/login', {
      //   origin: props?.history?.location?.pathname ?? window.location.pathname,
      // })
    } else {
      /** User Logged */
      setIsDisliked((prev) => !prev)
      apiPutRemoveDislikedProperty(properties.property)
    }
  }

  function addToFavoriteFromDislike() {
    setHeartClicked(true)
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
      // routerChange('/login', {
      //   origin: props?.history?.location?.pathname ?? window.location.pathname,
      // })
    } else {
      /** User Logged */
      /*       if (editFavoriteStatus) {
        editFavoriteStatus(properties.property)
      } */
      setIsDisliked((prev) => !prev)
      apiRequestSaveProperty(properties.property)
    }
  }

  const reloadImages = () => {
    let pi = Object.assign([], galleryImages)
    let newPi = []
    _.each(pi, (item, index) => {
      if (item.split('?').length) {
        newPi.push(item.split('?')[0] + '?t=' + Date.now())
      } else {
        newPi.push(item + '?t=' + Date.now())
      }
    })
    setGalleryImages(newPi)
    setImgError(false)
  }

  const showUpdatedListingLabel = () => {
    const publishedDate = new Date(properties.publishDate)
    const publishedDayOfYear = getDayOfYear(publishedDate)
    const publishedYear = publishedDate.getFullYear()
    const currentDayOfYear = getDayOfYear(new Date())
    const currentYear = new Date().getFullYear()

    if (
      publishedYear >= currentYear &&
      properties.subscriptionType?.toLowerCase() !== 'entry'
    ) {
      if (publishedDayOfYear + 3 >= currentDayOfYear) {
        return true
      }
      return false
    } else {
      return false
    }
  }

  function renderForListCard() {
    return (
      <Card
        elevation={1}
        className={`${isFullWidth ? classes.cardFull : classes.cardPadded} ${
          (!isFavorite && atFavoritesRoute) || (!isDisliked && atDislikesRoute)
            ? classes.removeFromFavoriteList
            : ''
        }`}
        key={properties.property}
      >
        <div className={classes.tagContainer}>
          {properties &&
          properties.propertyStatus !== 'offmarket' &&
          galleryImages &&
          galleryImages.length > 1 ? (
            <CarouselWrapper>
              {imgError && galleryImages && galleryImages.length > 0 && (
                <OverlayReload className={classes.reloadWrapper}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      reloadImages()
                    }}
                  >
                    <img src={ReloadIcon} />
                    <p>Load image</p>
                  </a>
                </OverlayReload>
              )}
              <Carousel
                showArrows={true}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={false}
                showThumbs={false}
                useKeyboardArrows={false}
                autoPlay={false}
                swipeable={false}
                emulateTouch={true}
                onChange={handleCarouselChange}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, left: 0, color: '#fff' }}
                    >
                      <ChevronLeftIcon style={{ ...iconStyles }} />
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, right: 0, color: '#fff' }}
                    >
                      <ChevronRightIcon style={{ ...iconStyles }} />
                    </IconButton>
                  )
                }
                onSwipeStart={() => {
                  setIsSwiping(true)
                }}
                onSwipeEnd={() => {
                  setIsSwiping(false)
                }}
              >
                {!imgError ? (
                  galleryImages.map((img, index) => {
                    const indexRef = index + 1
                    if (img) {
                      return (
                        <Fragment key={index}>
                          <Image
                            style={{
                              height: handleShowBanner(properties.subscriptionType)
                                ? '12vw'
                                : '14vw',
                              minHeight: '105px',
                            }}
                            key={index}
                            className={classes.media}
                            src={
                              indexRef <= carouselOffset
                                ? propertyConfigSetter('image-banner-card', {
                                    type: properties.propertyStatus,
                                    image: img,
                                  })
                                : null
                            }
                            onClick={() => selectProperty()}
                            aspectRatio={16 / 9}
                            disableSpinner
                            onLoad={() => {
                              setImgError(false)
                            }}
                            onError={() => {
                              setImgError(true)
                            }}
                          />
                        </Fragment>
                      )
                    }
                  })
                ) : (
                  <>
                    <Image
                      style={{ width: '100%' }}
                      className={classes.media}
                      src={propertyConfigSetter('image-banner-card', {
                        type: properties.propertyStatus,
                        image: null,
                      })}
                      onClick={() => selectProperty()}
                      aspectRatio={16 / 9}
                      disableSpinner
                    />
                  </>
                )}
              </Carousel>
            </CarouselWrapper>
          ) : properties &&
            properties.propertyStatus === 'offmarket' &&
            galleryImages &&
            galleryImages.length > 0 ? (
            <Image
              style={{ width: '100%' }}
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: galleryImages[0],
              })}
              onClick={() =>
                properties.propertyStatus === 'comingsoon' ||
                properties.propertyStatus === 'offmarket'
                  ? selectProperty(true)
                  : selectProperty()
              }
              aspectRatio={16 / 9}
              disableSpinner
            />
          ) : properties && galleryImages && galleryImages.length === 1 ? (
            <Image
              style={{ width: '100%' }}
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: galleryImages[0],
              })}
              onClick={() =>
                properties.propertyStatus === 'comingsoon' ||
                properties.propertyStatus === 'offmarket'
                  ? selectProperty(true)
                  : selectProperty()
              }
              aspectRatio={16 / 9}
              disableSpinner
            />
          ) : (
            <div></div>
          )}
          {properties.propertyStatus &&
            renderTypeTag(properties.propertyStatus, properties)}

          {properties ? (
            <div className={classes.newListingLabel}>
              {showNewListingLabel() && (
                <Typography
                  className={classes.tagTypeText}
                  variant="caption"
                  color="textSecondary"
                  component="p"
                  style={{
                    background: '#ef6631',
                  }}
                >
                  New
                </Typography>
              )}
              {showUpdatedListingLabel() && !showNewListingLabel() && (
                <Typography
                  className={classes.tagTypeText}
                  variant="caption"
                  color="textSecondary"
                  component="p"
                  style={{
                    background: '#ef6631',
                  }}
                >
                  Updated
                </Typography>
              )}

              {properties?.underOffer && (
                <Typography
                  className={classes.tagTypeText}
                  variant="caption"
                  color="textSecondary"
                  component="p"
                  style={{
                    background: '#ffffff',
                    color: '#000000',
                  }}
                >
                  Under Offer
                </Typography>
              )}
            </div>
          ) : null}

          {/* {authentication && authentication.user_status === 'logged' && isFavorite ? (
            <Tooltip title="Remove from favorite">
              <IconButton
                className={classes.favoriteContainer}
                onClick={(e) => {
                  e.preventDefault()
                  checkLoginStatus()
                }}
              >
                <HeartFullIcon className={classes.favoriteIconActive} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Add to favorite">
              <IconButton
                className={classes.favoriteContainer}
                onClick={(e) => {
                  e.preventDefault()
                  checkLoginStatus()
                }}
              >
                <HeartIcon className={classes.favoriteIcon} />
              </IconButton>
            </Tooltip>
          )} */}
          {(() => {
            if (
              authentication &&
              authentication.user_status === 'logged' &&
              isFavorite &&
              !atFavoritesRoute &&
              !atDislikesRoute
            ) {
              return (
                <Tooltip title="Remove from favorite">
                  <IconButton
                    className={classes.favoriteContainer}
                    onClick={() => checkLoginStatus()}
                  >
                    <HeartFullIcon className={classes.favoriteIconActive} />
                  </IconButton>
                </Tooltip>
              )
            } else if (
              authentication &&
              authentication.user_status === 'logged' &&
              isFavorite &&
              atFavoritesRoute &&
              !atDislikesRoute
            ) {
              return (
                <Tooltip title="Remove from favorite">
                  <IconButton
                    className={classes.favoriteContainer}
                    onClick={() => checkLoginStatus()}
                  >
                    <DeleteIcon className={classes.deleteIconActive} />
                  </IconButton>
                </Tooltip>
              )
            } else if (
              authentication &&
              authentication.user_status === 'logged' &&
              !isFavorite &&
              isDisliked &&
              atDislikesRoute
            ) {
              return (
                <>
                  <Tooltip title="Remove from disliked items">
                    <IconButton
                      className={classes.favoriteContainer}
                      onClick={() => removeDislikedProperty()}
                    >
                      <DeleteIcon className={classes.deleteIconActive} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add to favorites">
                    <IconButton
                      className={classes.addFavoritesContainer}
                      onClick={() => addToFavoriteFromDislike()}
                    >
                      <HeartIcon className={classes.favoriteIcon} />
                    </IconButton>
                  </Tooltip>
                </>
              )
            } else {
              return (
                <Tooltip title="Add to favorite">
                  <IconButton
                    className={classes.favoriteContainer}
                    onClick={() => checkLoginStatus()}
                  >
                    <HeartIcon className={classes.favoriteIcon} />
                  </IconButton>
                </Tooltip>
              )
            }
          })()}
        </div>
        {
          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />
        }
        <CardContent>
          <PropertyInfo
            addIconOnCard={true}
            type={'search'}
            properties={properties}
            selectProperty={selectProperty}
            // matchCount={props.handleMatchCount(properties)}
            listingType={listingType}
            filters={props.filters}
          />
        </CardContent>
      </Card>
    )
  }

  function renderForSwipeCard() {
    return (
      <Card elevation={1} className={classes.cardPaddedSwipe} key={properties.property}>
        <div className={classes.tagContainer}>
          <Tooltip title="Information">
            <IconButton
              className={classes.infoIconContainer}
              onClick={() => selectProperty()}
            >
              {/* <InfoIcon className={classes.infoIcon}/> */}
              <span>More Info</span>
            </IconButton>
          </Tooltip>
          {properties && galleryImages && galleryImages.length > 0 && (
            <Image
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: galleryImages[0],
              })}
              onClick={() => selectProperty()}
              aspectRatio={16 / 9}
              disableSpinner
            />
          )}
          {properties.propertyStatus &&
            renderTypeTag(properties.propertyStatus, properties)}
          {/* <IconButton 
            className={classes.favoriteContainer} 
            onClick={()=> checkLoginStatus()}>
              {
                properties && properties.favorite && properties.favorite ?
                  <HeartFullIcon className={classes.favoriteIconActive}/>
                :
                  <HeartIcon className={classes.favoriteIcon}/>
              }
          </IconButton> */}
        </div>
        {
          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />
        }
        <CardContent>
          <PropertyInfo
            type={'search'}
            properties={properties}
            selectProperty={selectProperty}
            matchCount={props.handleMatchCount(properties)}
            filters={props.filters}
          />
        </CardContent>
        {/*         <div className={classes.rowContainerCenter}>
          {properties.coefficients.close_to_cafes >= 0.7 ||
          properties.coefficients.close_to_parks >= 0.7 ||
          properties.coefficients.close_to_shops >= 0.7 ||
          properties.coefficients.close_to_schools >= 0.7 ||
          properties.coefficients.close_to_universities >= 0.7 ||
          properties.coefficients.foody_area >= 0.7 ||
          properties.coefficients.inner_city_vibe >= 0.7 ||
          properties.coefficients.close_to_public_transports >= 0.7 ||
          properties.coefficients.close_to_secondary_schools >= 0.7 ? ( */}
        {properties && (
          <div className={classes.rowContainerCenter}>
            {properties?.closeToFilters?.close_to_cafes ||
            properties?.closeToFilters?.close_to_parks ||
            properties?.closeToFilters?.close_to_shops ||
            properties?.closeToFilters?.close_to_schools ||
            properties?.closeToFilters?.close_to_universities ||
            properties?.closeToFilters?.foody_area ||
            properties?.closeToFilters?.inner_city_vibe ||
            properties?.closeToFilters?.close_to_public_transports ||
            properties?.closeToFilters?.close_to_secondary_schools ? (
              <>
                <div style={{ width: '25%', paddingLeft: 5 }}>
                  <div className={classes.rowContainerCenter}>
                    <p className={classes.textLabelCloseTo}>Close to:</p>
                  </div>
                  <div className={classes.rowContainerCenter}>
                    <p></p>
                  </div>
                </div>
                {renderSwipeCloseTo(properties)}
              </>
            ) : null}
          </div>
        )}
      </Card>
    )
  }

  function renderSwipeCloseTo(data) {
    const tagsToDisplay = extractTagsToDisplay(data.closeToFilters, NEARBY_FILTERS)
    return (
      <div style={{ width: '80%' }}>
        <div className={classes.rowContainerEven}>
          {tagsToDisplay.map((item) => {
            return (
              <div className={classes.chipContainer}>
                <p className={classes.chipText}>{convertTagToString(item)}</p>
                <CheckIcon className={classes.chipIcon} />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Fragment key={key}>
      {cardType === 'list' && renderForListCard()}
      {cardType === 'swipe' && renderForSwipeCard()}
    </Fragment>
  )
})

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(PropertyCard))
