import { POST, FETCH, PUT, DELETE } from 'utils/api';
import { billingActions } from 'store/actions';
import { apiEndpoints } from 'utils/api/endpoints';

const urlSetter = (params, actionMain) => {
  let URL = apiEndpoints[actionMain];
  if (params.query && params.query !== '') {
    URL = apiEndpoints[actionMain] + params.query;
  }
  return URL;
};

export function requestGETAPIBilling(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(billingActions(params, actionMain, 'REQUEST'));
    FETCH(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(billingActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(billingActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestPOSTAPIBilling(params, actionMain) {
  let URL = urlSetter(params, actionMain);

  return (dispatch) => {
    dispatch(billingActions(params, actionMain, 'REQUEST'));
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(billingActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(billingActions(error.response, actionMain, 'FAILED'));
      });
  };
}

export function requestPUTAPIBilling(params, actionMain) {
  let URL = urlSetter(params, actionMain);
  return (dispatch) => {
    dispatch(billingActions(params, actionMain, 'REQUEST'));
    PUT(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }
        dispatch(billingActions(response, actionMain, 'SUCCESS'));
        return response;
      })
      .catch((error) => {
        dispatch(billingActions(error.response, actionMain, 'FAILED'));
      });
  };
}
