import React, { useState } from 'react';
import ImageCarousel from '../ImageCarousel';
import Image from '../Image'
import ImageBannerUI from '../ImageBannerUI';
import IMGComingSoon from '../../../assets/images/coming_soon.svg';
import IMGOffMarket from '../../../assets/images/off_market.svg';
import LightBoxGallery from '../GridCarousel/LightBoxGallery';

const PropertyCarousel = props => {
    const {
        classes,
        authentication,
        settings,
        properties,
        currentProperty,
        toggleSignUp,
        apiRequestSaveProperty,
        floorPlan,
        virtualTours
    } = props;

    // console.log(properties, 'Prop Carousel')

    const [images, setImages] = useState(properties);
    const [selectImageIndex, setSelectImageIndex] = useState(null);
    const [fullScreenIndex, setFullScreenIndex] = useState(null)

    React.useEffect(() => {
        setImages(properties)
    }, [properties])

    function handleFullscreenIndex(index) {
        setSelectImageIndex(index)
    }

    return (
        <ImageBannerUI
            data={{ images: images }}
            floorPlan={floorPlan}
            selectImageIndex={selectImageIndex}
            setSelectImageIndex={setSelectImageIndex}
            toggleSignUp={toggleSignUp}
            currentProperty={currentProperty}
            apiRequestSaveSearch={props.apiRequestSaveSearch}
            apiRequestSaveProperty={apiRequestSaveProperty}
            virtualTours={virtualTours}
            {...props}>

            <ImageCarousel
                data={images}
                floorPlan={floorPlan}
                selectImageIndex={selectImageIndex}
                setSelectImageIndex={setSelectImageIndex}
                handleFullscreenIndex={handleFullscreenIndex}
            />
            <LightBoxGallery
                data={images}
                selectImageIndex={selectImageIndex}
                setSelectImageIndex={setSelectImageIndex}
            />
        </ImageBannerUI>
    )
}

export default (PropertyCarousel);
