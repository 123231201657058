import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function SortUnFoldMore(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 13.75 8.75 L 6.25 8.75 L 10 3.75 Z M 6.25 11.25 L 13.75 11.25 L 10 16.25 Z M 6.25 11.25"
        fill="#383838"
      />
    </svg>
  );
}

export function SortUnFoldMoreSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SortUnFoldMore />
      <SortUnFoldMore color="primary" />
      <SortUnFoldMore color="secondary" />
      <SortUnFoldMore color="action" />
      <SortUnFoldMore color="disabled" />
      <SortUnFoldMore style={{ color: green[500] }} />
    </div>
  );
}

export default SortUnFoldMore;
