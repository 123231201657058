import { Box, Typography, makeStyles } from '@material-ui/core'
import DynamicIcons from 'components/Mobile/DynamicIcons'
import ListOptions from 'components/Web/Searchbar/ListOptions'
import React, { useCallback } from 'react'
import { useState } from 'react'

import { OPTION_MODAL } from 'components/Web/Searchbar/constants'
import useFilters from 'utils/customHooks/useFilters'
import { useEffect } from 'react'
import { useRef } from 'react'
import { useSearch } from 'utils/search/useSearch'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',
    marginLeft: 'auto',
    whiteSpace: 'nowrap',
    position: 'relative',
  },

  sortByText: {
    marginRight: '5px',

    [theme.breakpoints.down(1024)]: {
      marginRight: '0px',
    },
  },

  modalContainer: {
    minWidth: '300px',
    position: 'absolute',
    top: '120%',
    left: '-280%',
    boxShadow:
      ' 0px 7px 5px -1px rgb(0 0 0 / 20%), 0px 12px 8px 0px rgb(0 0 0 / 14%), 0px 8px 14px 0px rgb(0 0 0 / 12%)',
    padding: '25px',
    borderRadius: '40px',
    backgroundColor: 'white',
    zIndex: 1,

    [theme.breakpoints.down(1024)]: {
      left: '-340%',
      top: '135%',
    },
  },
}))

const SortByProperties = () => {
  const classes = useStyles()

  const [openModal, setOpenModal] = useState(false)

  const wrapperRef = useRef(null)

  const [getFilter, setFilter] = useFilters()

  const LISTING_SEARCH_SORT = getFilter('listing_search_sort')
  const sortSearchRef = useRef()

  const [initiateSearch] = useSearch()

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenModal(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(wrapperRef)

  useEffect(() => {
    if (sortSearchRef.current) {
      initiateSearch()
    }
  }, [sortSearchRef.current])

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        onClick={() => setOpenModal(true)}
      >
        <Typography variant="body2" className={classes.sortByText}>
          Sort by
        </Typography>
        <DynamicIcons icon="ImportExport" />
      </Box>
      {openModal && (
        <Box className={classes.modalContainer}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            marginBottom="10px"
          >
            <DynamicIcons icon="ImportExport" />
            <Typography
              style={{ fontSize: '20px', fontFamily: 'Montserrat', marginLeft: '10px' }}
            >
              Sort by
            </Typography>
            <Box marginLeft="auto">
              <DynamicIcons
                icon="Close"
                onClick={() => {
                  setOpenModal(false)
                }}
              />
            </Box>
          </Box>
          <Box paddingX="10px">
            <ListOptions
              type={'sort'}
              options={LISTING_SEARCH_SORT}
              handleType={(sortData) => {
                setFilter({ key: 'listing_search_sort', value: sortData })
                sortSearchRef.current = sortData

                triggerAnalyticsEvent({
                  event: 'results_actions',
                  eventFrom: window.innerWidth <= 1024 ? 'Mobile' : 'Web',
                  actionScope: 'sort_results_by',
                  actionName: 'Sort Results by',
                  actionTarget:
                    'Sorted by ' + sortData?.filter((item) => item.active)?.[0]?.value,
                  actionReference: 'results',
                })
              }}
            />
          </Box>
        </Box>
      )}
    </div>
  )
}

export default SortByProperties
