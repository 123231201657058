import { POST, FETCH, PUT, DELETE } from 'utils/api'
import { mppActions } from 'store/actions/mpp.actions'
import { apiEndpoints } from 'utils/api/endpoints'

const urlSetter = (params, actionMain) => {
    let URL = apiEndpoints[actionMain]
    if (params.query && params.query !== '') {
        URL = apiEndpoints[actionMain] + params.query
    }
    return URL
}

export function requestPOSTAPIMpp(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(mppActions(params, actionMain, 'REQUEST'))
        POST(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(mppActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(mppActions(error.response, actionMain, 'FAILED'))
            })
    }
}

export function requestGETAPIMpp(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(mppActions(params, actionMain, 'REQUEST'))
        FETCH(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(mppActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(mppActions(error.response, actionMain, 'FAILED'))
            })
    }
}

export function requestPUTAPIMpp(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(mppActions(params, actionMain, 'REQUEST'))
        PUT(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(mppActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(mppActions(error.response, actionMain, 'FAILED'))
            })
    }
}

export function requestDELETEAPIMpp(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(mppActions(params, actionMain, 'REQUEST'))
        DELETE(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(mppActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(mppActions(error.response, actionMain, 'FAILED'))
            })
    }
}
