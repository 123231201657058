import React from 'react';
import { Box, Button, Grid, IconButton, withStyles, Typography } from '@material-ui/core';

import DynamicIcons from '../DynamicIcons';
import styled from 'styled-components';
import HeroImage from '../../../assets/images/hero/hero-image-v2.jpg';
import HomePageSearchBar from './HomePageSearchBar';
import HomeFilters from './HomeFilters';
import useFilters from 'utils/customHooks/useFilters';
import { useDispatch } from 'react-redux';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginTop: '10px',
    overflow: 'hidden',
    backgroundColor: '#6DC5A1',
  },
  formWrapper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    // minHeight: 'calc(100vh - 92px)',
    maxWidth: '370px',
    minWidth: '350px',
    //margin: '0 auto 20px',
  },

  heroHeading: {
    maxHeight: '88px',
    maxWidth: '340px',
    fontFamily: 'PT Serif',
    fontWeight: 400,
    fontWeight: 400,
    fontSize: 42,
    lineHeight: '44px',
    color: '#FFFFFF',
    margin: '20px',
    letterSpacing: '-0.78px',

    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
    },
  },
  heroLeadText: {
    maxWidth: '323px',
    maxHeight: '53px',
    color: '#FFFFFF',
    marginTop: '30px',
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '5px',
    fontSize: 16,
  },
  perfectButtonAlignment: {
    //maxWidth: '400px',
    margin: '0 auto 20px',
  },
  // heroLeftColumn: {
  //   [theme.breakpoints.up('lg')]: {
  //     display: 'flex',
  //     alignItems: 'center',
  //   },
  // },
  myPerfectPropertyButton: {
    height: '41px',
    borderRadius: '19px',
    //marginLeft: '100px',
    paddingLeft: '50px !important',
    paddingRight: '50px',
    backgroundColor: theme.palette.primaryYellow.main,
    color: '#000 !important',
    width: '100%',
    maxWidth: '270px',
    '&:hover': {
      backgroundColor: theme.palette.primaryYellow.main,
      color: '#000 !important',
    },
    '&:active': {
      backgroundColor: theme.palette.primaryYellow.main,
      color: '#000 !important',
    },
  },
});

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .btn-sell {
    svg {
      transform-origin: center;
      transform: rotate(90deg);
      font-size: 16px !important;
    }
  }
  .btn-rent {
    svg {
      transform-origin: center;
      transform: rotate(90deg);
      font-size: 16px !important;
    }
  }
  .btn-property {
    .MuiSvgIcon-root {
      font-size: 14px !important;
    }
  }
  .btn-find-your-perfect-property {
    .MuiSvgIcon-root {
      font-size: 14px !important;
    }
    .MuiButton-label > span:first-child {
      width: 100%;
    }
  }
  .btn-trade {
    .MuiSvgIcon-root {
      font-size: 16px !important;
    }
  }

  .btn-find-hush-hush-properties {
    background-color: #5cc1a3;
    max-width: 300px;
    &:hover {
      background-color: #ffffff;
    }
    small {
      text-align: center !important;
    }

    .MuiButton-label > span:first-child {
      width: 100%;
    }
  }
`;

const StyledButton = withStyles((theme) => ({
  root: {
    height: '41px',
    width: '100%',
    borderRadius: '19px',
    margin: '0 5px',
    padding: theme.spacing(2),
    color: theme.palette.textSecondary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiButton-label > span:first-child': {
      width: '100%',
    },
  },
  label: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
  },
}))(Button);

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 8px 0;
`;

const StyledButtonYellow = withStyles((theme) => ({
  root: {
    height: '41px',
    borderRadius: '19px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primaryYellow.main,
    color: '#000 !important',
    width: '100%',
    //maxWidth: '400px !important',
    '&.active': {
      backgroundColor: theme.palette.primaryYellow.main,
      color: '#000 !important',
    },
  },
  label: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
  },
}))(Button);

const DEFAULT_BUTTONS = [
  {
    name: 'Buy',
    icon: 'AttachMoney',
    path: '/buy',
    active: false,
  },
  {
    name: 'Rent',
    icon: 'VpnKey',
    path: '/rent',
    active: false,
  },
  {
    name: 'Sold',
    icon: 'Gavel',
    path: '/sold',
    active: false,
  },
  {
    name: 'Find Your Perfect Property',
    icon: 'ThumbUpAlt',
    path: '/perfect-property',
    active: false,
  },
  {
    name: 'Find Hush-Hush Properties',
    icon: 'WatchLater',
    path: '/hush-hush',
    active: false,
  },
];

const HomePageSearchHeading = (props) => {
  const { homeButtons, handleClick, classes, location, goBack } = props;
  const [routerChange] = useRouterChange();
  //const dispatch = useDispatch()

  const [getFilter, setFilter] = useFilters();
  const SEARCH_BUTTONS = getFilter('search_reference');

  const handlePerfectPropertyButtonClick = () => {
    const homeButtons = SEARCH_BUTTONS.map((item) =>
      item.id === 'mpp' ? { ...item, active: true } : { ...item, active: false },
    );
    setFilter({ key: 'search_reference', value: homeButtons });
    routerChange('/perfect-property/filters');
  };

  return (
    <Box
      // item
      // sm={12}
      // lg={4}
      // className={classes.heroLeftColumn}
      // style={{ justifyContent: 'center' }}
      display="flex"
      justifyContent="center"
    >
      <Box>
        <Typography component={'h1'} className={classes.heroHeading}>
          <strong>find where</strong> you belong
        </Typography>
        <Box>
          <Box className={classes.formWrapper}>
            <Box paddingTop="20px">
              <HomePageSearchBar />
            </Box>
            <Box padding="5px 0px 20px 0px">
              <HomeFilters />
            </Box>
          </Box>
        </Box>
        <Typography component={'h6'} className={classes.heroLeadText} style={{ textAlign: 'center' }}>
          You’ll discover more homes if you prioritise lifestyle over location. Allow My Perfect Property to lead you
          further
        </Typography>
        <ButtonWrap className={classes.perfectButtonAlignment}>
          <ButtonRow>
            <StyledButtonYellow
              className={classes.myPerfectPropertyButton}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<DynamicIcons style={{ height: '25px', width: '25px' }} icon={SEARCH_BUTTONS[6].icon} />}
              style={{
                color: '#fff',
                borderColor: '#EADF74',
                paddingLeft: '1.250em',
                marginTop: '24px',
                marginLeft: 'auto',
                marginRight: 'auto',
                borderRadius: '100px',
                paddingTop: '14px',
                paddingBottom: '14px',
              }}
              onClick={() => handlePerfectPropertyButtonClick()}
            >
              <span style={{ textAlign: 'center' }}>{SEARCH_BUTTONS[6].name}</span>
            </StyledButtonYellow>
          </ButtonRow>
        </ButtonWrap>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(HomePageSearchHeading);
