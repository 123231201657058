import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function PropertyMapIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.5884 13.742V13.7425H6.60562V8.97042L10.6052 6.21973L14.5884 8.95978V13.742ZM10.4671 0.508301C4.8016 0.508301 0.20746 4.96334 0.191036 10.4642L0.189941 10.4631V26.6683C0.280269 26.6188 6.32075 24.6703 6.60378 19.7238C7.81691 20.2137 9.07165 20.4802 10.4671 20.4802C16.143 20.4802 20.7442 16.0097 20.7442 10.4945C20.7442 4.9793 16.143 0.508301 10.4671 0.508301Z" fill={props.fill}/>
    </SvgIcon>
  );
}

export function PropertyMapIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PropertyMapIcon />
      <PropertyMapIcon color="primary" />
      <PropertyMapIcon color="secondary" />
      <PropertyMapIcon color="action" />
      <PropertyMapIcon color="disabled" />
      <PropertyMapIcon style={{ color: green[500] }} />
    </div>
  );
}
