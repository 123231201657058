import { 
    SET_STAT_VIEW,
    SET_STAT_VIEW_SUCCESS,
    SET_STAT_VIEW_FAIL,
    CLEAR_STATISTICS_DATA,
    SET_STATISTICS_POOL,
    CLEAR_STATISTICS_POOL,
    GET_STATISTICS_DATA,
    GET_STATISTICS_DATA_SUCCESS,
    GET_STATISTICS_DATA_FAIL,
} from '../constants/statistics.constants';

export const setStatView = (data, stat) => {
    return {
        type: SET_STAT_VIEW,
        params: data,
        stat: stat
    };
};
export const setStatViewSuccess = (payload, stat) => {
    return {
        type: SET_STAT_VIEW_SUCCESS,
        params: payload,
        stat: stat
    };
};
export const setStatViewFail = (error, stat) => {
    return {
        type: SET_STAT_VIEW_FAIL,        
        params: error,
        stat: stat
    };
};


export const clearStatisticsData = () => {
    return {
        type: CLEAR_STATISTICS_DATA,
    };
};

export const setStatisticsPool = (data) => {
    return {
        type: SET_STATISTICS_POOL,        
        params: data,        
    };
};

export const clearStatisticsPool = (data) => {
    return {
        type: CLEAR_STATISTICS_POOL,                
    };
};
export const getStatisticsData = (data) => {
    return {
        type: GET_STATISTICS_DATA,        
        params: data,        
    };
};
export const getStatisticsDataSuccess = (data) => {
    return {
        type: GET_STATISTICS_DATA_SUCCESS,        
        params: data,        
    };
};
export const getStatisticsDataError = (error) => {
    return {
        type: GET_STATISTICS_DATA_FAIL,        
        params: error,        
    };
};