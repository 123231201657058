import React, {useState} from "react";
import styled, { css } from 'styled-components'

import {
  Tooltip,
  Typography,
  withStyles,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
} from "@material-ui/core";

const Container = styled.div`
  padding:0 0;
`

const StyledFormControl = withStyles({
  root:{
    margin:0,
    width:'100%',
    padding:'10px 0'
  }
})(FormControl);


const StyledFormGroup = withStyles({
  root:{
    margin:0
  }
})(FormGroup);

const StyledFormControlLabel = withStyles({
  root:{
    margin:0,
    width:'100%',
    justifyContent:'space-between'
  }
})(FormControlLabel);

const StyledSwitch = withStyles({
  root:{}
})(Switch);

const CustomSwitch = props => {

  const [state, setState] = React.useState({
    checked: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Container>      
      <StyledFormControl component="fieldset">
        <StyledFormGroup aria-label="position" row>
          {props.helptext ? 
            
              <StyledFormControlLabel
                checked={props.checked}
                value={props.label}
                control={<StyledSwitch color="primary" />}
                label={<Tooltip title={props.helptext} placement="top-start" arrow><span>{props.label}</span></Tooltip>}
                labelPlacement={'start'}
                onChange={props.onChange}
              />            
          : 
            <StyledFormControlLabel
              checked={props.checked}
              value={props.label}
              control={<StyledSwitch color="primary" />}
              label={props.sublabel? <><b>{props.label}</b><br/>{props.sublabel}</>:<>{props.label}</>}
              labelPlacement={'start'}
              onChange={props.onChange}
            />
          }
        </StyledFormGroup>
      </StyledFormControl>
    </Container>
  );
};

export default CustomSwitch;