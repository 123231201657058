export const SEARCH_ACTION_TYPES = {
  GET_PROPERTY_SEARCH: {
    REQUEST: 'search/GET_PROPERTY_SEARCH_REQUEST',
    SUCCESS: 'search/GET_PROPERTY_SEARCH_SUCCESS',
    FAILED: 'search/GET_PROPERTY_SEARCH_FAILED',
  },

  POST_PROPERTY_SEARCH: {
    REQUEST: 'search/POST_PROPERTY_SEARCH_REQUEST',
    SUCCESS: 'search/POST_PROPERTY_SEARCH_SUCCESS',
    FAILED: 'search/POST_PROPERTY_SEARCH_FAILED',
  },

  GET_SOLD_PROPERTY_SEARCH: {
    REQUEST: 'search/GET_SOLD_PROPERTY_SEARCH_REQUEST',
    SUCCESS: 'search/GET_SOLD_PROPERTY_SEARCH_SUCCESS',
    FAILED: 'search/GET_SOLD_PROPERTY_SEARCH_FAILED',
  },

  POST_SOLD_PROPERTY_SEARCH: {
    REQUEST: 'search/POST_SOLD_PROPERTY_SEARCH_REQUEST',
    SUCCESS: 'search/POST_SOLD_PROPERTY_SEARCH_SUCCESS',
    FAILED: 'search/POST_SOLD_PROPERTY_SEARCH_FAILED',
  },

  GET_RENTAL_PROPERTY_SEARCH: {
    REQUEST: 'search/GET_RENTAL_PROPERTY_SEARCH_REQUEST',
    SUCCESS: 'search/GET_RENTAL_PROPERTY_SEARCH_SUCCESS',
    FAILED: 'search/GET_RENTAL_PROPERTY_SEARCH_FAILED',
  },
  POST_RENTAL_PROPERTY_SEARCH: {
    REQUEST: 'search/POST_RENTAL_PROPERTY_SEARCH_REQUEST',
    SUCCESS: 'search/POST_RENTAL_PROPERTY_SEARCH_SUCCESS',
    FAILED: 'search/POST_RENTAL_PROPERTY_SEARCH_FAILED',
  },

  FUNNEL_DOWN_SEARCH: {
    UPDATE: 'search/SEARCH_FUNNEL_DOWN',
    CLEAR: 'search/SEARCH_FUNNEL_DOWN_CLEAR',
  },

  PAGINATION_REFERENCE: {
    UPDATE: 'search/PAGINATION_REFERENCE_UPDATE',
    CLEAR: 'search/PAGINATION_REFERENCE_CLEAR',
  },

  GENERAL_SEARCH: {
    CLEAR_ERROR: 'search/CLEAR_ERROR',
    CLEAR_STATUS: 'search/CLEAR_STATUS',
    RESET_STATES: 'search/RESET_STATES',
  },
}
