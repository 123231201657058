import React, { useState } from 'react';
import _ from 'lodash';

import ChipInput from 'material-ui-chip-input';

import SearchIcon from '@material-ui/icons/Search';

import { geolocated } from 'react-geolocated';
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils';
import Geocode from 'react-geocode';

import SUBURBS from '../../../assets/data/suburbs-all-new.json';

import styled from 'styled-components';

import { makeStyles, Paper, List, ListItem, ListItemText, IconButton, Select, MenuItem } from '@material-ui/core';
import { useSearch } from 'utils/search/useSearch';
import useFilters from 'utils/customHooks/useFilters';

const SelectPlaceholder = styled.p`
  font-size: 16px;
  font-family: Roboto, sans-serif;
  color: rgb(172 172 172);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
  z-index: 2;
  pointer-events: none;
`;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: 'transparent',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'relative',
    zIndex: 9,
  },
  mainContainerFullWidth: {
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 9,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 4,
    border: '1px solid #9B9B9B',
    position: 'relative',
    zIndex: 20,
  },
  chip: {
    backgroundColor: '#f5f5f5',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 0,
    height: '2.875em',
    fontSize: '0.875em',
  },
  chipContainer: {
    width: '100%',
    flexFlow: 'nowrap',
    overflowX: 'auto',
    alignSelf: 'center',
    border: 4,
  },
  chipContainerFull: {
    width: '60vw',
    flexFlow: 'nowrap',
    overflowX: 'auto',
    alignSelf: 'center',
    border: 4,
  },
  input: {
    marginLeft: theme.spacing(1),
    zIndex: 20,
    textAlign: 'left',
    width: '100%',
  },
  iconButton: {
    alignSelf: 'stretch',
    position: 'relative',
    padding: '0.625em',
    backgroundColor: '#11c0c9',
    borderRadius: 0,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    '&:hover': {
      backgroundColor: '#11c0c9',
    },
  },
  iconSearch: {
    color: '#FFF',
  },
  iconLoading: {
    color: '#FFF',
  },
  iconTune: {
    color: '#000',
  },
  listContainer: {
    position: 'relative',
  },
  list: {
    position: 'absolute',
    zIndex: 999,
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '11.250em',
    cursor: 'pointer',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24)',
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    },
    'li ': {
      cursor: 'pointer',
    },
  },
  selectRoot: {
    width: '100%',
  },
  selectMenu: {
    padding: '18px 24px 18px 8px',
  },
  mppRoot: {
    maxHeight: 44,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 4,
    border: '1px solid #9B9B9B',
    position: 'relative',
    zIndex: 20,
  },
}));
/* 
!NOTE: Commented default state to keep VIC active for initial launch
*/
const DEFAULT_STATE = [
  { id: 0, title: 'ACT', active: false },
  { id: 1, title: 'NSW', active: false },
  { id: 2, title: 'NT', active: false },
  { id: 3, title: 'QLD', active: false },
  { id: 4, title: 'SA', active: false },
  { id: 5, title: 'TAS', active: false },
  { id: 6, title: 'VIC', active: false },
  { id: 7, title: 'WA', active: false },
];
//const DEFAULT_STATE = [{ id: 6, title: 'VIC', active: true }]

const SearchInput = (props) => {
  const {
    coords,
    isGeolocationEnabled,
    isGeolocationAvailable,
    setInvalidSearch,
    removeReferenceFilters,
    localFilters,
    sortType,
  } = props;

  const [searchText, setSearchText] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [selectedChipValue, setSelectedChipValue] = React.useState([]);
  const [resultArr, setResultArr] = React.useState(null);
  const [filteredCalled, setFilterCalled] = React.useState(false);
  const [isSuburbRemoveCalled, setIsSuburbRemoveCalled] = React.useState(false);
  const [stateListData, setStateListData] = React.useState(DEFAULT_STATE);
  const [selectValue, setSelectValue] = React.useState('');
  const [defaultGeoCoordinates, setDefaultGeoCoordinates] = React.useState(null);
  const [defaultGeolocation, setDefaultGeolocation] = React.useState(null);
  const [geoSelectedKeyword, setGeoSelectedKeyword] = React.useState(null);
  const [presetCount, setPresetCount] = React.useState(false);
  const [initiateSearch] = useSearch();
  const classes = useStyles();

  const [getFilter, setFilter] = useFilters();
  const STATES = getFilter('state');

  let timeout;

  React.useEffect(() => {
    refreshSelectedState();
  }, [STATES]);

  React.useEffect(() => {
    if (props.isClearFilter) {
      setSearchText('');
      setResultArr(null);
      setSelectedValue([]);
      setFilterCalled(false);
      setSelectedChipValue([]);
      removeReferenceFilters();
    }
  }, [props.isClearFilter]);

  const refreshSelectedState = () => {
    if (STATES) {
      STATES.map((item) => {
        if (item.active === true) {
          setSelectValue(item.title);
        }
      });
    }
  };

  const setFilterChips = (filters) => {
    if (filters && filters.suburbs && filters.suburbs.length > 0) {
      setPresetCount(true);
      setSelectedValue(_.uniq(filters.suburbs));
      // if(JSON.stringify(props.filters.suburbs) !== JSON.stringify(selectedValue)) {
      //     props.setPropertySearch(props.filters.suburbs);
      // }
      let suburb = [];
      filters.suburbs.map((item, index) => {
        suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode);
      });
      setSelectedChipValue(_.uniq(suburb));
      if (JSON.stringify(_.uniq(suburb)) !== JSON.stringify(selectedChipValue)) {
        props.setChipValue(_.uniq(suburb));
      }
    }
  };

  /*     React.useEffect(()=>{
            if(referenceFilters && referenceFilters.filters && referenceFilters.filters.suburbs.length > 0) {
                setFilterChips(props.referenceFilters);            
            }        
        },[referenceFilters]) */

  React.useEffect(() => {
    if (geoSelectedKeyword && presetCount === false && sortType !== 'perfect-property') {
      const selectedChip = {
        locality: geoSelectedKeyword.suburb.toUpperCase(),
        postcode: geoSelectedKeyword.postcode,
        state: geoSelectedKeyword.state.toUpperCase(),
      };
      if (geoSelectedKeyword.suburb && geoSelectedKeyword.state && geoSelectedKeyword.postcode) {
        addSelection(selectedChip);
        setPresetCount(true);
        setInvalidSearch(false);
      }
    }
  }, [geoSelectedKeyword]);

  React.useEffect(() => {
    if (props.filters) {
      if (props.filters.suburbs.length > 0) {
        setPresetCount(true);

        setFilterChips(props.filters);
      }
      if (isSuburbRemoveCalled) {
        props.apiRequestSearchProperty();
        setIsSuburbRemoveCalled(false);
      }
      if (props.filters.perfectProperty && props.filters.perfectProperty.state) {
        props.filters.perfectProperty.state.map((item, index) => {
          if (item.active) {
            setSelectValue(item.title);
            // props.setPerfectPropertyFilter(props.filters.perfectProperty)
          }
        });
      }
    }
    refreshSelectedState();
  }, [props.filters]);

  React.useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      // Initialized Geocoding only if the function we need is available, enabled and already have data
      Geocode.setApiKey(GOOGLE_MAP_API_KEY);
      // Geocode.setRegion('au');
      Geocode.setLocationType('ROOFTOP');

      setDefaultGeoCoordinates({ lat: coords.latitude, lng: coords.longitude });
    }
  }, [coords, isGeolocationEnabled, isGeolocationAvailable]);

  React.useEffect(() => {
    if (defaultGeoCoordinates) {
      // const tempCoords = {lat: -37.7869071, lng: 144.9203547 }
      Geocode.fromLatLng(defaultGeoCoordinates.lat, defaultGeoCoordinates.lng).then(
        (response) => setDefaultGeolocation(response.results[0]),
        () => setDefaultGeolocation(null),
      );
    }
  }, [defaultGeoCoordinates]);

  React.useEffect(() => {
    if (defaultGeolocation) {
      const postcode = _.find(defaultGeolocation.address_components, { types: ['postal_code'] }) ?? null;
      const state =
        _.find(defaultGeolocation.address_components, {
          types: ['administrative_area_level_1', 'political'],
        }) ?? null;
      const municipality =
        _.find(defaultGeolocation.address_components, {
          types: ['administrative_area_level_2', 'political'],
        }) ?? null;
      const suburb =
        _.find(defaultGeolocation.address_components, {
          types: ['locality', 'political'],
        }) ?? null;
      const country =
        _.find(defaultGeolocation.address_components, {
          types: ['country', 'political'],
        }) ?? null;

      let details = {};
      details.postcode = postcode ? postcode.short_name : '';
      details.state = state ? state.short_name : '';
      details.municipality = municipality ? municipality.short_name : '';
      details.suburb = suburb ? suburb.short_name : '';
      if (country && country.short_name === 'AU') {
        setGeoSelectedKeyword(details);
      }
    }
  }, [defaultGeolocation]);

  function addSelection(value) {
    /** Set Selected Values */
    let selected = selectedValue;
    selected.push({
      ...value,
      name: value.locality + ', ' + value.state + ', ' + value.postcode,
    });
    setSelectedValue(selected);

    /** Set Property Suburb Search */
    props.setPropertySearch(selected);

    /** Set Chip Values */
    let selectedChip = selectedChipValue;
    selectedChip.push(value.locality + ', ' + value.state + ', ' + value.postcode);
    setSelectedChipValue(selectedChip);

    props.setChipValue(selectedChip);

    /** Reset Results and search text */
    setResultArr(null);
    //props.setIsInputFocused(false);
    setSearchText('');
  }

  function removeSelected(value, index) {
    /** Filter and Remove Matched Object from Selection */
    let selected = _.filter(selectedValue, function (n) {
      return n.name !== value;
    });
    setSelectedValue(selected);

    /** Set Property Suburb Search */
    props.setPropertySearch(selected);

    /** Set Chip Values */
    let selectedChip = _.filter(selectedChipValue, function (n) {
      return n !== value;
    });
    setSelectedChipValue(selectedChip);
    props.setChipValue([]);
    setIsSuburbRemoveCalled(true);
  }

  function mergeAddress(d, s) {
    let text = (d.locality + ', ' + d.state + ', ' + d.postcode)
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/ /g, '');
    let searchText = s
      .toLowerCase()
      .replace(/[^\w\s]/gi, '')
      .replace(/ /g, '');
    return text.toLowerCase().startsWith(searchText);
  }

  function filterResult(value) {
    setFilterCalled(true);

    if (value.length > 0) {
      /** Set filtered suburb based from results */
      let suburb = _.filter(SUBURBS, (d) => {
        return mergeAddress(d, value) || d.postcode.toLowerCase() === value.toLowerCase();
      });

      let groups = _.groupBy(suburb, 'state');
      let topSuburbs = [];
      _.forEach(groups, (item, key) => {
        _.forEach(item, (o, k) => {
          if (k < 10) {
            topSuburbs.push(o);
          }
        });
      });
      /** Filter and Remove Matched Object from Selection */
      var output = _.orderBy(
        _.filter(topSuburbs, (el) => {
          return (
            _.findIndex(selectedValue, (elem) => {
              return elem.id === el.id;
            }) === -1
          );
        }),
        ['locality'],
        ['asc'],
      );
      setResultArr(output);
    } else {
      setResultArr(null);
    }
    setFilterCalled(false);
  }

  function handleChange(e) {
    setSearchText(e.target.value);
    let txt = e.target.value || null;
    if (txt) {
      // handleFilterResult(txt)
      filterResult(txt);
    } else {
      setResultArr(null);
    }
  }

  return (
    <div
      className={props.isFullWidth ? classes.mainContainerFullWidth : classes.mainContainer}
      style={sortType === 'perfect-property' ? { paddingRight: 0, paddingLeft: 10 } : {}}
    >
      {props.path !== 'perfect-property' ? (
        <div className={classes.root}>
          <ChipInput
            clearInputValueOnChange={true}
            fullWidth
            fullWidthInput={false}
            classes={{
              chipContainer: props.isFullWidth ? classes.chipContainerFull : classes.chipContainer,
              input: classes.input,
              chip: classes.chip,
            }}
            disableUnderline={true}
            placeholder={
              props.placeholder ? props.placeholder : props.isFullWidth ? 'Suburb' : 'Search by Suburb or Postcode'
            }
            value={selectedChipValue}
            onDelete={(chip, index) => removeSelected(chip, index)}
            InputProps={{
              onChange: (event) => {
                handleChange(event);
              },
              onFocus: () => {
                if (props.onFocus) {
                  props.onFocus();
                }
              },
              onBlur: () => {
                if (!resultArr || resultArr.length === 0) {
                  setSearchText('');
                }
              },
              value: searchText,
            }}
          />
          <IconButton className={classes.iconButton} onClick={() => props.apiRequestSearchProperty()}>
            <SearchIcon className={classes.iconSearch} />
          </IconButton>
        </div>
      ) : (
        <div className={classes.mppRoot}>
          <Select
            disableUnderline={true}
            value={selectValue}
            style={{ width: '100%' }}
            classes={{
              root: classes.selectRoot,
              selectMenu: classes.selectMenu,
            }}
            onChange={(e) => {
              setSelectValue(e.target.value);
              let st = Object.assign([], stateListData);
              st.map((item) => {
                if (item.title === e.target.value) {
                  item.active = true;
                } else {
                  item.active = false;
                }
              });

              let p = {};

              if (props.filters && props.filters.perfectProperty) {
                p = props.filters.perfectProperty;
                p.state = st;
              } else {
                p.state = st;
              }
              // props.setPerfectPropertyFilter(p)

              setFilter({
                key: 'state',
                value: st,
              });
            }}
          >
            {STATES.map((item, index) => {
              return (
                <MenuItem value={item.title} key={index}>
                  {item.title}
                </MenuItem>
              );
            })}
          </Select>
          <IconButton
            className={classes.iconButton}
            onClick={() =>
              //props.apiRequestSearchProperty()
              initiateSearch()
            }
          >
            <SearchIcon className={classes.iconSearch} />
          </IconButton>
          {!selectValue && <SelectPlaceholder>Please select the state you want to find the property</SelectPlaceholder>}
        </div>
      )}

      <Paper className={classes.listContainer}>
        {resultArr && resultArr.length > 0 && (
          <List dense={true} className={classes.list}>
            {resultArr &&
              resultArr.map((value, index) => {
                let textLocation = value.locality + ', ' + value.state + ', ' + value.postcode;
                let isExist = _.findIndex(selectedChipValue, function (o) {
                  return o == textLocation;
                });
                if (isExist === -1) {
                  return (
                    <ListItem key={index} onClick={() => addSelection(value)}>
                      <ListItemText primary={textLocation} />
                    </ListItem>
                  );
                }
              })}
          </List>
        )}
      </Paper>
    </div>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(SearchInput);
