import { RENT_FILTERS } from 'constants/constants'
import { MPP_FILTERS } from 'constants/constants'
import { NORMAL_FILTERS } from 'constants/constants'
import _ from 'lodash'

export const typeChecker = (data) => {
  if (Array.isArray(data)) return 'array'
  if (Object.prototype.toString.call(data) === '[object Object]') return 'object'

  return 'primitive'
}

export const compareGlobalFilters = (
  type,
  currentGlobalFilters,
  comparedToGlobalFilters,
  isClearFilter,
  emptySuburbValue
) => {
  let basisFilter = []
  let changedFilters = []

  switch (type) {
    case 'normal':
      basisFilter = isClearFilter
        ? NORMAL_FILTERS.filter((filter) => filter !== 'suburbs')
        : NORMAL_FILTERS
      break

    case 'rent':
      basisFilter = isClearFilter
        ? RENT_FILTERS.filter((filter) => filter !== 'suburbs')
        : RENT_FILTERS
      break

    case 'mpp':
      basisFilter = MPP_FILTERS
      break

    default:
      basisFilter = NORMAL_FILTERS
      break
  }

  basisFilter.forEach((globalFilter) => {
    const currentGlobalFilter = currentGlobalFilters[globalFilter]
    const comparedToGlobalFilter = comparedToGlobalFilters[globalFilter]

    const filterDataType = typeChecker(comparedToGlobalFilter)

    switch (filterDataType) {
      case 'array': {
        if (emptySuburbValue && globalFilter === 'suburbs') {
          if (currentGlobalFilter.length > 0) {
            changedFilters.push({ key: globalFilter, value: currentGlobalFilter })
          }
        } else if (currentGlobalFilter.length !== comparedToGlobalFilter.length) {
          changedFilters.push({ key: globalFilter, value: currentGlobalFilter })
        } else if (
          !currentGlobalFilter?.every((filter, index) =>
            _.isEqual(filter, comparedToGlobalFilter[index])
          )
        ) {
          if (globalFilter === 'suburbs') {
            const currentFilterIds = currentGlobalFilter?.map((filter) => filter.id)

            const suburbFiltersDifferent = currentGlobalFilter?.filter((filter) => {
              if (!currentFilterIds?.includes(filter.id)) {
                changedFilters.push({ key: globalFilter, value: currentGlobalFilter })
              }
            })
          } else {
            changedFilters.push({ key: globalFilter, value: currentGlobalFilter })
          }
        }
        break
      }

      case 'object':
        if (globalFilter === 'price' || globalFilter === 'price_rent') {
          if (
            currentGlobalFilter.min !== comparedToGlobalFilter.min ||
            currentGlobalFilter.max !== comparedToGlobalFilter.max
          ) {
            changedFilters.push({ key: globalFilter, value: currentGlobalFilter })
          }
        } else if (!_.isEqual(currentGlobalFilter, comparedToGlobalFilter)) {
          changedFilters.push({ key: globalFilter, value: currentGlobalFilter })
        }
        break

      case 'primitive':
        if (currentGlobalFilter !== comparedToGlobalFilter) {
          changedFilters.push({ key: globalFilter, value: currentGlobalFilter })
        }
        break
    }
  })

  return changedFilters
}

export default compareGlobalFilters
