export function getAuthentication(state) {
  return state.superadmin;
}

export function getSettings(state) {
  return state.superadmin;
}

export function getUser(state) {
  return state.superadmin;
}

export function getStatus(state) {
  return state.superadmin.status;
}

export function getAgencies(state) {
  return state.superadmin.agencies;
}

export function getAgents(state) {
  return state.superadmin.agents?.data?.entity;
}

export function getAgency(state) {
  return state.superadmin.agency;
}

export function getOffice(state) {
  return state.superadmin.office;
}

export function getOfficeLists(state) {
  return state.superadmin.officeLists;
}

export function getErrors(state) {
  return state.superadmin.error;
}

export function getSingleAgent(state) {
  return state.superadmin.singleAgent;
}

export function getAgentUploadedImage(state) {
  return state.superadmin.agentImage;
}

export function getSuperadminToken(state) {
  return state.superadmin.superadmintoken;
}

export function getSuperadminEmailLogs(state) {
  return state.superadmin.emailLogs;
}

export function getSuperadminEmailContent(state) {
  return state.superadmin.emailContent;
}

export function getSuperAdminAccount(state) {
  return state.superadmin.superAdminAccount;
}
export function getAdminUser(state) {
  return state.superadmin.superadminuser?.data;
}

export function checkEmailAvailable(state) {
  return state.superadmin.payload_success?.data;
}

export function getAgentDetails(state) {
  return state.superadmin.currentEditedAgent;
}

export function getSuperadminChangeRequests(state) {
  return state.superadmin.changeRequests;
}

export function getSuperAdminCurrentAgencyAdded(state) {
  return state.superadmin.currentAddedAgency;
}

export function getSuperAdminReportsData(state) {
  return state.superadmin.reportsData;
}
