import { createTheme } from "@material-ui/core/styles";
import { fontFamily, headingFont } from "../utils/constants.utils";

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#35C0CA',
    },
    secondary: {
      main: '#f50057',
    },
    primaryYellow: {
      main: '#FDBD26',
    },
    primaryGreen: {
      main: '#6DC5A1',
    },
    primaryOrange: {
      main: '#EF6631',
    },
    primaryBlue: {
      main: '#35C0CA',
    },
    textPrimary: {
      main: '#2a2a2a',
    },
    textSecondary: {
      main: '#444444',
    },
    contrastText: {
      main: '#f7f6f6',
    },
    gray: {
      main: '#4f4f4f',
    },
    error: {
      main: '#ca0909',
    },
    sand: {
      main: '#F4DECB',
    },
    shell: {
      main: '#F8EEE7',
    },
    status: {
      danger: '#b71c1c',
    },
    light: {
      main: '#FFFFFF',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    button: {
      //textTransform: 'none',
      fontWeight: 'normal',
    },
    h1: {
      fontFamily: headingFont,
      fontSize: '6rem',
      fontWeight: 300,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: headingFont,
      fontSize: '3.75rem',
      fontWeight: 300,
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: headingFont,
      fontSize: '3rem',
      fontWeight: 400,
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: headingFont,
      fontSize: '2.125rem',
      fontWeight: 400,
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: headingFont,
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: headingFont,
      fontSize: '1.25rem',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontFamily: fontFamily,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: fontFamily,
      fontSize: '0.874rem',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
});

export default theme;
