export const compareValues = (current, reference) => {

    if (current && reference) {
        const conditions = {
            "current-lower-than-ref-not-exact": current.exact === false && current.value < reference.value,
            "current-lower-than-ref-exact": current.exact === true && current.value < reference.value,
            "current-not-exact-ref-exact": current.exact === false && reference.exact === true,
            "current-exact-ref=not-exact-within-ref": current.exact === true && reference.exact === false && current.value >= reference.value,
            "both-not-exact-current-higher-than-ref": current.exact === false && reference.exact === false && current.value > reference.value,
        }

        if (conditions["current-lower-than-ref-not-exact"] ||
            conditions["current-lower-than-ref-exact"] ||
            conditions["current-not-exact-ref-exact"]
        ) {
            return "widened"
        } else if (conditions["current-exact-ref=not-exact-within-ref"] || conditions["both-not-exact-current-higher-than-ref"]) {
            return "narrowed"
        } else {
            return null
        }
    } else {
        return null
    }
}