export const STORAGE_ACTION_TYPES = {
  GENERAL_STORAGE: {
    SET_STORAGE_VERSION: 'storage/SET_STORAGE_VERSION',
  },
  GENERAL_SETTINGS: {
    SET_POPUP_STATUS: 'popup/SET_POPUP_STATUS',
    SET_SCROLL_POSITION: 'scroll/SET_SCROLL_POSITION',
    SET_PROPERTY_SEARCH_REF: 'scroll/SET_PROPERTY_SEARCH_REF',
    SET_SUPERADMIN_MANAGE_AGENCY_ROW_COUNT: 'settings/SET_SUPERADMIN_MANAGE_AGENCY_ROW_COUNT',
    SET_SUPERADMIN_MANAGE_AGENCY_PAGE_COUNT: 'settings/SET_SUPERADMIN_MANAGE_AGENCY_PAGE_COUNT',
    SET_SUPERADMIN_MANAGE_AGENCY_BUTTONS: 'settings/SET_SUPERADMIN_MANAGE_AGENCY_BUTTONS',
    SET_SUPERADMIN_MANAGE_AGENCY_FILTERS: 'settings/SET_SUPERADMIN_MANAGE_AGENCY_FILTERS',
    SET_SUPERADMIN_MANAGE_AGENCY_SORTS: 'settings/SET_SUPERADMIN_MANAGE_AGENCY_SORTS',
    SET_SUPERADMIN_MANAGE_AGENCY_FIELD: 'settings/SET_SUPERADMIN_MANAGE_AGENCY_FIELD',
    SET_FROM_INNERPAGE: 'scroll/SET_FROM_INNERPAGE',
  },
};
