import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path id="Combined-Shape" d="M11.4,0.8c0.5-0.4,1.2-0.4,1.7,0l0,0l10.7,9.5c0.4,0.4,0.2,1.1-0.4,1.1l0,0h-1.7v11.2c0,0.7-0.6,1.3-1.3,1.3l0,0H4.1c-0.7,0-1.3-0.6-1.3-1.3l0,0V11.4H1.2c-0.6,0-0.9-0.7-0.4-1.1l0,0L11.4,0.8z M11.2,8.6c-2.7,0-4.8,2.1-4.8,4.8s2.2,4.8,4.8,4.8c1.2,0,2.3-0.4,3.1-1.1l0.2,0.2v0.6l3.7,3.7l1.1-1.1l-3.7-3.7h-0.6l-0.2-0.2c0.7-0.8,1.2-1.9,1.2-3.1C16,10.8,13.9,8.6,11.2,8.6z M11.2,10.1c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3C7.9,11.6,9.4,10.1,11.2,10.1z"/>
    </SvgIcon>
  );
}

export function SearchIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchIcon />
      <SearchIcon color="primary" />
      <SearchIcon color="secondary" />
      <SearchIcon color="action" />
      <SearchIcon color="disabled" />
      <SearchIcon style={{ color: green[500] }} />
    </div>
  );
}
