import React from 'react';
import styled from 'styled-components'

import AgentSearchBar from './AgentSearchBar'
import CustomSelect from './CustomSelect'

import { withStyles, Typography } from '@material-ui/core';

const AgentContainer = styled.div`
    width:100%;
    height: 100%;
`

const AgentContainerOverlay = styled.div`
    height:75vh;
    width:100%;
    background: linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%);
    position: absolute;
    z-index: 1;
`

const ContentContainer = styled.div`
    margin:25vh auto 0;
    padding-left: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const StyledText = withStyles({
    root: {
        textAlign: 'center',
        color: '#fff',
        fontSize: '36px',
        lineHeight: '1.19',
        zIndex: 5,
    },
})(Typography);

const StyledSubText = withStyles({
    root: {
        textAlign: 'center',
        color: '#fff',
        fontSize: '15px',
        lineHeight: '1.19',
        padding: '15px',
        zIndex: 5,
    },
})(Typography);

const InputWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding-top: 20px;
    @media (max-width: 768px) {
        flex-direction: column;
        > div {
            flex: 0 0 100%;
            margin: 0 0 20px;
            > div {
                width: 50vw;
            }
        }
    }
`

const categoryOptions = [
    {
        id: 0,
        label: 'Select a category',
        value: 'select'
    }, {
        id: 1,
        label: 'Agent',
        value: 'agent'
    }, {
        id: 2,
        label: 'Agency',
        value: 'agency'
    }]

const AgentLanding = props => {
    const {
        imageBG,
        clearAgentSearchParams,
    } = props;

    const [category, setCategory] = React.useState(null);

    const handleCategory = (data) => {
        setCategory(data)
    }

    return (
        <AgentContainer style={{
            backgroundImage: `url(${imageBG})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }}>
            <AgentContainerOverlay />
            <ContentContainer>
                <StyledText variant="h2">
                    Find your perfect agent or agency
                </StyledText>
                <StyledSubText variant="h2">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id lectus euismod
                    <br /> ex porta facilisis. Maecenas ut ultrices odio.
                </StyledSubText>
                <InputWrap>
                    <CustomSelect
                        options={categoryOptions}
                        handleCategory={handleCategory}
                        isClearFilter={false}
                        value={category}
                    />
                    <AgentSearchBar
                        showInlineSearchButton={true}
                        agents={props.agents}
                        agencies={props.agencies}
                        category={category}
                        clearAgentSearchParams={clearAgentSearchParams}
                        apiRequestSearchAgent={props.apiRequestSearchAgent} />
                </InputWrap>
            </ContentContainer>
        </AgentContainer>
    );
}

export default AgentLanding;