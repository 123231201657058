export const FILTER_ACTION_TYPES = {
  GENERAL_FILTERS: {
    SET_ALL_FILTER: 'filters/SET_ALL_FILTER',
    GET_ALL_FILTER: 'filters/GET_ALL_FILTER',
    CLEAR_ALL_FILTER: 'filters/CLEAR_ALL_FILTER',
    SET_SINGLE_FILTER: 'filters/SET_SINGLE_FILTER',
    GET_SINGLE_FILTER: 'filters/GET_SINGLE_FILTER',
    CLEAR_SINGLE_FILTER: 'filters/CLEAR_SINGLE_FILTER',
    SET_REFERENCE_FILTER: 'filters/SET_REFERENCE_FILTER',
    CLEAR_REFERENCE_FILTER: 'filters/CLEAR_REFERENCE_FILTER',
    CLEAR_ALL_EXCEPT_SEARCH_REFERENCE_AND_SUBURBS:
      'filters/CLEAR_ALL_EXCEPT_SEARCH_REFERENCE_AND_SUBURBS',
  },
}
