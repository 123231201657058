import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import HeartIcon from '@material-ui/icons/Favorite'

/** Custom Components */
import SearchInput from './SearchInput'
import SortSelection from './SortSelection'
import FilterOptions from './FilterOptions'
import FilterOptionsV2 from './FilterOptionsV2'
import { FilterSharp } from '@material-ui/icons'
import PropertySearchFilter from './PropertySearchFilter'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'
import SearchbarFilter from '../../Common/GlobalFilters/SearchbarFilter'
import SavedSearch from 'components/Web/SavedSearch'
import RouteButtons from 'components/Common/GlobalFilters/RouteButtons'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    //height: '100%',
    zIndex: 6,
    backgroundColor: '#FFF',
    position: 'relative',
    borderRadius: 4,
  },
}))

const ValidationSearch = styled.div`
  position: absolute;
  bottom: -40px;
  left: 25px;
  border-radius: 3px;
  opacity: 0;
  background-color: rgb(17, 192, 201);
  padding: 10px;
  z-index: 9;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 0.3s ease;

  &.active {
    transform: translateY(0px);
    opacity: 1;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent rgb(17, 192, 201) transparent;
  }
  p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
`

const savedPropertyUrlChecker = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname.split('/')[1]
  }

  return null
}

const Searchbar = (props) => {
  const {
    type,
    sortType,

    authentication,
    settings,
    toggleSignUp,
    toggleFilter,
    apiRequestSaveSearch,
    apiRequestSearchProperty,
    navigateFilterButtonTo,
    setPropertySearch,
    setSearchSortingFilters,
    setIncludeNearbyFilter,
    filters,
    saveFilters,
    properties,
    isFiltered,
    showSearchBar,
    showFilters,
    isFullWidth,
    trades,
    showSaveSearchModal,
    path,
    setInvalidSearch,
    invalidSearch,
    handleFilter,
    omcsStatus,
    setOmcsStatus,
  } = props

  const [selectedChipValue, setSelectedChipValue] = React.useState([])
  /** controller */
  const [showModalOptions, setShowModalOptions] = React.useState(false)
  const [selectedFilterType, setSelectedFilterType] = React.useState('')
  const [showModalBottomOptions, setShowModalBottomOptions] = React.useState(false)
  const [isInputFocused, setIsInputFocused] = React.useState(false)
  const [suburbs, setSuburbs] = React.useState('')
  const classes = useStyles()

  React.useEffect(() => {
    if (filters) {
      let suburb = []
      if (filters.suburbs && filters.suburbs.length) {
        filters.suburbs.map((item, index) => {
          suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode)
        })
      }
      setSuburbs(suburb)
    }
  }, [filters])

  React.useEffect(() => {
    if (setInvalidSearch) {
      if (filters.suburbs && filters.suburbs.length > 0) {
        setInvalidSearch(false)
      } else {
        setInvalidSearch(true)
      }
    }
  }, [filters.suburbs])

  /**
   * do not hide modal when selecting a different option
   * | close modal when selecting cancel or apply */
  function showOptionModal(selected, closeModal) {
    if (closeModal) {
      setShowModalOptions(false)
      setSelectedFilterType('')
      toggleFilter(false)
    } else {
      setShowModalOptions(true)
      toggleFilter(true)
      setSelectedFilterType(selected)
    }
  }

  function showOptionBottomModal() {
    setShowModalBottomOptions(!showModalBottomOptions)
  }

  return (
    <>
      <div className={classes.mainContainer}>
        <div style={{ position: 'relative' }}>
          {showSearchBar && (
            <Box display="flex" width="100%" padding="10px">
              <SearchbarFilter />
            </Box>
          )}
          {/* <ValidationSearch className={invalidSearch ? 'active' : ''}>
            <p>Please add the location you are looking for</p>
          </ValidationSearch> */}
        </div>

        {showFilters && (
          <FilterOptionsV2
            type={type}
            navigateFilterButtonTo={navigateFilterButtonTo}

            showModalOptions={showModalOptions}
            showOptionModal={showOptionModal}
            setIsInputFocused={setIsInputFocused}
            path={props.path}
            toggleFilter={toggleFilter}
            selectedFilterType={selectedFilterType}
          />
        )}
      </div>

      {sortType === 'trades-results' && trades && trades.length > 0 ? (
        <>
          <SortSelection
            sortType={sortType}
            current={trades}
            total={trades ? trades.length : 0}
            setSearchSortingFilters={setSearchSortingFilters}
            filters={filters}
            showModalBottomOptions={showModalBottomOptions}
            showOptionBottomModal={showOptionBottomModal}
            path={path}
          />
        </>
      ) : (
        //) : null
        properties &&
        properties.propertiesFilters &&
        properties.propertiesFilters.length > 0 && (
          <>
            <SortSelection
              sortType={sortType}
              current={
                properties
                  ? properties.propertiesFilters
                    ? properties.propertiesFilters.length
                    : 0
                  : 0
              }
              total={properties ? properties.totalResults : 0}
              setSearchSortingFilters={setSearchSortingFilters}
              filters={filters}
              showModalBottomOptions={showModalBottomOptions}
              showOptionBottomModal={showOptionBottomModal}
              path={path}
            />
          </>
        )
      )}
    </>
  )
}

export default Searchbar
