import {
  REQUEST_SEARCH_AGENT,
  REQUEST_SEARCH_AGENT_SUCCESS,
  REQUEST_SEARCH_AGENT_FAIL,
  REQUEST_AGENT_AGENCY_DATA,
  REQUEST_AGENT_AGENCY_DATA_FAIL,
  REQUEST_AGENT_AGENCY_DATA_SUCCESS,
  REQUEST_AGENT_DETAILS,
  REQUEST_AGENT_DETAILS_SUCCESS,
  REQUEST_AGENT_DETAILS_FAIL,
  SET_SEARCH_AGENT_PARAMS,
  CLEAR_SEARCH_AGENT_PARAMS,
  REQUEST_SET_AGENT_RATING,
  REQUEST_SET_AGENT_RATING_SUCCESS,
  REQUEST_SET_AGENT_RATING_FAIL,
  REQUEST_GET_AGENT_PROPERTIES,
  REQUEST_GET_AGENT_PROPERTIES_SUCCESS,
  REQUEST_GET_AGENT_PROPERTIES_FAIL,
  REQUEST_AGENT_SET_PAUSE_PROPERTY,
  REQUEST_AGENT_SET_PAUSE_PROPERTY_SUCCESS,
  REQUEST_AGENT_SET_PAUSE_PROPERTY_FAIL,
  REQUEST_CREATE_PROPERTY,
  REQUEST_CREATE_PROPERTY_SUCCESS,
  REQUEST_CREATE_PROPERTY_FAIL,
  REQUEST_AGENT_CLEAR_PAUSE_PROPERTY_STATUS,
  REQUEST_UPLOAD_SO_INTENT,
  REQUEST_UPLOAD_SO_INTENT_SUCCESS,
  REQUEST_UPLOAD_SO_INTENT_FAIL,
  REQUEST_STATISTICS,
  REQUEST_STATISTICS_SUCCESS,
  REQUEST_STATISTICS_FAIL,
  REQUEST_GET_TO_CONFIRM_PROPERTY,
  REQUEST_GET_TO_CONFIRM_PROPERTY_SUCCESS,
  REQUEST_GET_TO_CONFIRM_PROPERTY_FAIL,
  REQUEST_APPROVE_PROPERTY,
  REQUEST_APPROVE_PROPERTY_SUCCESS,
  REQUEST_APPROVE_PROPERTY_FAIL,
  REQUEST_EDIT_PROPERTY,
  REQUEST_EDIT_PROPERTY_SUCCESS,
  REQUEST_EDIT_PROPERTY_FAIL,
  REQUEST_DELETE_PROPERTY,
  REQUEST_DELETE_PROPERTY_SUCCESS,
  REQUEST_DELETE_PROPERTY_FAIL,
  REQUEST_AGENT_STATE,
  REQUEST_AGENT_STATE_SUCCESS,
  REQUEST_AGENT_STATE_FAIL,
  REQUEST_AGENT_ACTIVATE,
  REQUEST_AGENT_ACTIVATE_SUCCESS,
  REQUEST_AGENT_ACTIVATE_FAIL,
  CLEAR_STATUS,
  CLEAR_AGENT_STATE,
} from '../constants'

export const agentSearch = (params) => {
  return {
    type: REQUEST_SEARCH_AGENT,
    params,
  }
}

export const agentSearchSuccess = (payload) => {
  return {
    type: REQUEST_SEARCH_AGENT_SUCCESS,
    payload,
  }
}

export const agentSearchFail = (error) => {
  return {
    type: REQUEST_SEARCH_AGENT_FAIL,
    error,
  }
}

export const agentAgencyDataRequest = (params) => {
  return {
    type: REQUEST_AGENT_AGENCY_DATA,
    params,
  }
}

export const agentAgencyDataRequestSuccess = (payload) => {
  return {
    type: REQUEST_AGENT_AGENCY_DATA_SUCCESS,
    payload,
  }
}

export const agentAgencyDataRequestFail = (error) => {
  return {
    type: REQUEST_AGENT_AGENCY_DATA_FAIL,
    error,
  }
}

export const agentGetDetails = (params) => {
  return {
    type: REQUEST_AGENT_DETAILS,
  }
}

export const agentGetDetailsSuccess = (payload) => {
  return {
    type: REQUEST_AGENT_DETAILS_SUCCESS,
    payload,
  }
}

export const agentGetDetailsFail = (error) => {
  return {
    type: REQUEST_AGENT_DETAILS_FAIL,
    error,
  }
}

export const setAgentSearchParams = (params) => {
  return {
    type: SET_SEARCH_AGENT_PARAMS,
    params,
  }
}

export const clearAgentSearchParams = () => {
  return {
    type: CLEAR_SEARCH_AGENT_PARAMS,
  }
}

export const clearAgentState = () => {
  return {
    type: CLEAR_AGENT_STATE,
  }
}

export const agentSetRating = (params) => {
  return {
    type: REQUEST_SET_AGENT_RATING,
  }
}

export const agentSetRatingSuccess = (payload) => {
  return {
    type: REQUEST_SET_AGENT_RATING_SUCCESS,
    payload,
  }
}

export const agentSetRatingFail = (error) => {
  return {
    type: REQUEST_SET_AGENT_RATING_FAIL,
    error,
  }
}

export const agentGetProperties = (params) => {
  return {
    type: REQUEST_GET_AGENT_PROPERTIES,
  }
}

export const agentGetPropertiesSuccess = (payload) => {
  return {
    type: REQUEST_GET_AGENT_PROPERTIES_SUCCESS,
    payload,
  }
}

export const agentGetPropertiesFail = (error) => {
  return {
    type: REQUEST_GET_AGENT_PROPERTIES_FAIL,
    error,
  }
}

export const agentSetPauseProperty = (params) => {
  return {
    type: REQUEST_AGENT_SET_PAUSE_PROPERTY,
    params,
  }
}

export const agentSetPausePropertySuccess = (payload) => {
  return {
    type: REQUEST_AGENT_SET_PAUSE_PROPERTY_SUCCESS,
    payload,
  }
}

export const agentSetPausePropertyFail = (error) => {
  return {
    type: REQUEST_AGENT_SET_PAUSE_PROPERTY_FAIL,
    error,
  }
}
export const agentCreateProperty = (params) => {
  return {
    type: REQUEST_CREATE_PROPERTY,
    params,
  }
}

export const agentCreatePropertySuccess = (payload) => {
  return {
    type: REQUEST_CREATE_PROPERTY_SUCCESS,
    payload,
  }
}

export const agentCreatePropertyFail = (error) => {
  return {
    type: REQUEST_CREATE_PROPERTY_FAIL,
    error,
  }
}
export const agentClearPausePropertyStatus = () => {
  return {
    type: REQUEST_AGENT_CLEAR_PAUSE_PROPERTY_STATUS,
  }
}

export const agentUploadSoIntent = (params) => {
  return {
    type: REQUEST_UPLOAD_SO_INTENT,
    params,
  }
}

export const agentUploadSoIntentSuccess = (payload) => {
  return {
    type: REQUEST_UPLOAD_SO_INTENT_SUCCESS,
    payload,
  }
}

export const agentUploadSoIntentFail = (error) => {
  return {
    type: REQUEST_UPLOAD_SO_INTENT_FAIL,
    error,
  }
}

export const getToConfirmProperty = (params) => {
  return {
    type: REQUEST_GET_TO_CONFIRM_PROPERTY,
    params,
  }
}

export const getToConfirmPropertySuccess = (payload) => {
  return {
    type: REQUEST_GET_TO_CONFIRM_PROPERTY_SUCCESS,
    payload,
  }
}

export const getToConfirmPropertyFail = (error) => {
  return {
    type: REQUEST_GET_TO_CONFIRM_PROPERTY_FAIL,
    error,
  }
}

export const setConfirmProperty = (params) => {
  return {
    type: REQUEST_APPROVE_PROPERTY,
    params,
  }
}

export const setConfirmPropertySuccess = (payload) => {
  return {
    type: REQUEST_APPROVE_PROPERTY_SUCCESS,
    payload,
  }
}

export const setConfirmPropertyFail = (error) => {
  return {
    type: REQUEST_APPROVE_PROPERTY_FAIL,
    error,
  }
}

export const setEditProperty = (params) => {
  return {
    type: REQUEST_EDIT_PROPERTY,
    params,
  }
}

export const setEditPropertySuccess = (payload) => {
  return {
    type: REQUEST_EDIT_PROPERTY_SUCCESS,
    payload,
  }
}

export const setEditPropertyFail = (error) => {
  return {
    type: REQUEST_EDIT_PROPERTY_FAIL,
    error,
  }
}

export const deleteProperty = (params) => {
  return {
    type: REQUEST_DELETE_PROPERTY,
    params,
  }
}

export const deletePropertySuccess = (payload) => {
  return {
    type: REQUEST_DELETE_PROPERTY_SUCCESS,
    payload,
  }
}

export const deletePropertyFail = (error) => {
  return {
    type: REQUEST_DELETE_PROPERTY_FAIL,
    error,
  }
}

export const statistics = (params) => {
  return {
    type: REQUEST_STATISTICS,
    params,
  }
}

export const statisticsSuccess = (payload) => {
  return {
    type: REQUEST_STATISTICS_SUCCESS,
    payload,
  }
}

export const statisticsFail = (error) => {
  return {
    type: REQUEST_STATISTICS_FAIL,
    error,
  }
}

export const agentGetState = (params) => {
  return {
    type: REQUEST_AGENT_STATE,
  }
}

export const agentGetStateSuccess = (payload) => {
  return {
    type: REQUEST_AGENT_STATE_SUCCESS,
    payload,
  }
}

export const agentGetStateFail = (error) => {
  return {
    type: REQUEST_AGENT_STATE_FAIL,
    error,
  }
}

export const agentRequestActivate = (params) => {
  return {
    type: REQUEST_AGENT_ACTIVATE,
  }
}

export const agentRequestActivateSuccess = (payload) => {
  return {
    type: REQUEST_AGENT_ACTIVATE_SUCCESS,
    payload,
  }
}

export const agentRequestActivateFail = (error) => {
  return {
    type: REQUEST_AGENT_ACTIVATE_FAIL,
    error,
  }
}

export const clearAgentStatus = (params) => {
  return {
    type: CLEAR_STATUS,
    params,
  }
}
