export const BUTTON_FILTERS = [
  {
    type: 'bed',
    label: 'Bedrooms',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
  {
    type: 'cost',
    label: 'Price',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
  {
    type: 'bath',
    label: 'Bathrooms',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
  {
    type: 'propertyType',
    label: 'Property Type',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
]

export const OPTION_MODAL = [
  {
    title: 'Relevance',
    value: 'Relevance',
    active: false,
  },
  {
    title: 'Newest',
    value: 'Newest',
    active: false,
  },
  {
    title: 'Price (High to Low)',
    value: 'Price (High to Low)',
    active: false,
  },
  {
    title: 'Price (Low to High)',
    value: 'Price (Low to High)',
    active: false,
  },
  {
    title: 'Bedrooms',
    value: 'Bedrooms',
    active: false,
  },
  {
    title: 'Bathrooms',
    value: 'Bathrooms',
    active: false,
  },
  {
    title: 'Sqm',
    value: 'Sqm',
    active: false,
  },
]

export const TRADE_OPTION_MODAL = [
  {
    title: 'All',
    value: 'All',
    active: false,
  },
  {
    title: 'Landscaper',
    value: 'Landscaper',
    active: false,
  },
  {
    title: 'Plumber',
    value: 'Plumber',
    active: false,
  },
  {
    title: 'Gardener',
    value: 'Gardener',
    active: false,
  },
]

export const PROPERTY_TYPE = [
  {
    title: 'House',
    value: 'House',
    active: false,
  },
  {
    title: 'Townhouse',
    value: 'Townhouse',
    active: false,
  },
  {
    title: 'Apartment & Unit',
    value: 'Apartment & Unit',
    active: false,
  },
  {
    title: 'Land',
    value: 'Land',
    active: false,
  },
  {
    title: 'Rural',
    value: 'Rural',
    active: false,
  },
  {
    title: 'Retirement',
    value: 'Retirement',
    active: false,
  },
  {
    title: 'Villa',
    value: 'Villa',
    active: false,
  },
]
