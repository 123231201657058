import React, { useState, useEffect } from 'react';
import Image from 'material-ui-image';

import HeartIcon from '@material-ui/icons/FavoriteBorder';

import MapPropertyCardAgentBanner from './MapPropertyCardAgentBanner';
import MapPropertyCardInfo from './MapPropertyCardInfo';

import IMGComingSoon from '../../../assets/images/coming_soon.svg';
import IMGOffMarket from '../../../assets/images/off_market.svg';

import { cloudinaryCardTransforms, cloudinaryImageHeightSmall } from '../../../constants/constants';
import _ from "lodash";

import { withStyles, Card, IconButton } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = theme => ({
    wrap: {
        width: '100%'
    },
    root: {
        display: 'flex',
        position: 'relative',
    },
    rootActive: {
        display: 'flex',
        position: 'relative',
        width: '100%'
    },
    card: {
        minWidth: '10.500em',
        height: '13.438em',
        margin: '0.325em',
        marginBottom: '0.625em',
        position: 'relative'
    },
    cardActive: {
        minWidth: 365,
        margin: '0.325em',
        marginBottom: '0.625em',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        '& > div': {
            width: '45%'
        },
        '& > div:last-child': {
            paddingTop: theme.spacing(1),
            paddingbottom: theme.spacing(1),
            width: '55%'
        },
        '& .MuiIconButton-label .MuiTypography-caption': {
            fontSize: '9px',
            color: '#000000'
        },
        '& .MuiTypography-body2': {
            fontSize: '1rem'
        },
        '& .MapPropertyCardInfo-main': {
            padding: theme.spacing(2)
        }

    },
    media: {
        width: '100%',
        height: '6.250em',
        objectFit: 'cover !important'
    },
    favoriteContainer: {
        width: '0.625em',
        height: '0.625em',
        position: 'absolute',
        top: 15,
        right: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
    },
    favoriteIcon: {
        color: '#FFF',
        width: '0.625em',
        height: '0.625em'
    },
    relativeWrap: {
        position: 'relative'
    },
    triangle: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        borderBottom: '1px solid rgba(0,0,0,0.4)',
        borderRight: '1px solid rgba(0,0,0,0.4)',
        margin: '0 auto',
        position: 'absolute',
        left: '46.5%',
        bottom: -6,
        transform: 'rotate(45deg)',
        background: '#ffffff',
        zIndex: 1

    },
    offmarketApprox: {
        padding: theme.spacing(0.5, 1),
        width: '100%',
        display: 'inline-block'
    }
});

const MapPropertyOverlay = props => {
    const {
        classes,
        type,
        properties,
        trades,
        active,

        currentScreen,
        setCurrentProperty,
        pathType,
        currentPath
    } = props;
    const [routerChange] = useRouterChange()

    const [galleryImages, setGalleryImages] = useState(null)

    useEffect(() => {
        if (properties && properties.images && properties.images.length > 0) {
            let gallery_array = []
            if (properties.cloudinaryImages && properties.cloudinaryImages.length > 0) {
                properties.cloudinaryImages.map((item, idx) => {
                    const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageHeightSmall + '/' + item.file
                    gallery_array.push(file)
                })
            } else {
                gallery_array = _.compact(properties.images)
            }

            setGalleryImages(gallery_array)
        }
    }, [properties])

    function imageType(type, image) {
        switch (type) {
            case 'auction':
                return image;
            case 'comingsoon':
                return image ? image : IMGComingSoon;
            case 'offmarket':
                return image ? image : IMGOffMarket;
            default:
                return image;
        }
    }
    function listingType(type) {
        switch (type) {
            case 'rental':
                return 'rent';
            case 'sold':
                return 'sold';
            default:
                return 'buy';
        }
    }

    function renderProperties() {

        return (
            <div className={active ? classes.wrap : ''} onClick={() => {
                setCurrentProperty(properties)
                console.log(pathType)
                if (pathType === 'property') {
                    routerChange('/' + currentPath + '/' + properties.property, { previous_screen: currentScreen })
                }
                if (pathType === 'liked') {
                    routerChange('/liked-properties/' + properties.property, { previous_screen: currentScreen })
                }
            }}>
                {/* {!active && 
                    <div className={classes.root}> 
                        <Card className={classes.card}>
                            <Image
                                className={classes.media}
                                src={imageType(properties.propertyStatus, properties.images[0])}
                                onClick={()=> ''}
                                aspectRatio={(16/9)}
                                disableSpinner
                            />
                            <IconButton 
                                className={classes.favoriteContainer} 
                                onClick={()=> ''}>
                                <HeartIcon className={classes.favoriteIcon}/>
                            </IconButton>
                            <MapPropertyCardAgentBanner properties={properties}/>
                            <MapPropertyCardInfo type={type} properties={properties} />
                        </Card>
                    </div>
                } */}
                {active && active === properties.property &&
                    <div className={classes.rootActive}>
                        <Card className={classes.cardActive}>
                            <div className={classes.relativeWrap}>
                                <Image
                                    className={classes.media}
                                    src={imageType(properties.propertyStatus, galleryImages[0])}
                                    onClick={() => ''}
                                    aspectRatio={(16 / 10)}
                                    disableSpinner
                                />
                                <IconButton
                                    className={classes.favoriteContainer}
                                    onClick={() => ''}>
                                    <HeartIcon className={classes.favoriteIcon} />
                                </IconButton>
                                {/* <MapPropertyCardAgentBanner properties={properties}/> */}
                            </div>
                            <div>
                                <MapPropertyCardInfo type={type} properties={properties} />
                                {properties && properties.propertyStatus === 'offmarket' &&
                                    <small className={classes.offmarketApprox} >This position is approximate location within 200 meters.</small>
                                }
                            </div>
                        </Card>
                        <div className={classes.triangle}></div>
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            {renderProperties()}
        </>
    )
}

export default withStyles(styles)(MapPropertyOverlay);
