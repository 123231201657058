import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Image from 'material-ui-image'

import HeartIcon from '@material-ui/icons/FavoriteBorder'
import HeartFullIcon from '@material-ui/icons/Favorite'

import MapPropertyCardAgentBanner from './MapPropertyCardAgentBanner'
import MapPropertyCardInfo from './MapPropertyCardInfo'
import NotificationDialog from '../CustomDialogs/NotificationDialog'

import { cloudinaryCardTransforms, cloudinaryImageHeightSmall } from '../../../constants/constants'
import _ from 'lodash'

import IMGComingSoon from '../../../assets/images/coming_soon.svg'
import IMGOffMarket from '../../../assets/images/off_market.svg'

import { withStyles, Card, IconButton, Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { useRouterChange } from 'utils/router.utils'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  wrap: {
    width: '100%',
  },
  root: {
    display: 'flex',
    position: 'relative',
  },
  rootActive: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    maxWidth: 365,
  },
  card: {
    minWidth: '10.500em',
    height: '13.438em',
    margin: '0.325em',
    marginBottom: '0.625em',
    position: 'relative',
  },
  cardActive: {
    minWidth: 365,
    margin: '0.325em',
    marginBottom: '0.625em',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      width: '45%',
    },
    '& > div:last-child': {
      paddingTop: theme.spacing(1),
      paddingbottom: theme.spacing(1),
      width: '55%',
    },
    '& .MuiIconButton-label .MuiTypography-caption': {
      fontSize: '9px',
      color: '#000000',
    },
    '& .MuiTypography-body2': {
      fontSize: '1rem',
    },
    '& .MapPropertyCardInfo-main': {
      padding: theme.spacing(2),
    },
  },
  media: {
    width: '100%',
    height: '6.250em',
    objectFit: 'cover !important',
  },
  favoriteContainer: {
    width: '0.625em',
    height: '0.625em',
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
    width: '0.625em',
    height: '0.625em',
  },
  favoriteIconActive: {
    width: '0.75em',
    height: '0.75em',
    color: '#57bdc7',
  },
  relativeWrap: {
    position: 'relative',
    boxShadow: '0 0 5px 0px rgb(0 0 0 / 12%)',
  },
  triangle: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderBottom: '1px solid rgba(0,0,0,0.4)',
    borderRight: '1px solid rgba(0,0,0,0.4)',
    margin: '0 auto',
    position: 'absolute',
    left: '46.5%',
    bottom: -6,
    transform: 'rotate(45deg)',
    background: '#ffffff',
    zIndex: 1,
  },
  offmarketApprox: {
    padding: theme.spacing(0.5, 1),
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    zIndex: 99,
  },
})

const MapPropertyOverlay = (props) => {
  const {
    classes,
    type,
    properties,
    trades,
    active,

    currentScreen,
    setCurrentProperty,
    pathType,
    currentPath,
    authentication,
    apiRequestSaveProperty,
    toggleSignUp,
    settings,
  } = props
  const [routerChange] = useRouterChange()

  const MPP = useSelector(getMpp)
  let likes = MPP?.favourites?.data?.likes

  const [galleryImages, setGalleryImages] = useState(null)
  const [heartClicked, setHeartClicked] = useState(false)
  const [heartActionError, setHeartActionError] = useState(false)
  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents()

  useEffect(() => {
    if (properties && properties.images && properties.images.length > 0) {
      let gallery_array = []
      if (properties.cloudinaryImages && properties.cloudinaryImages.length > 0) {
        properties.cloudinaryImages.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageHeightSmall + '/' + item.file
          gallery_array.push(file)
        })
      } else {
        gallery_array = _.compact(properties.images)
      }

      setGalleryImages(gallery_array)
    }
  }, [properties])

  // useEffect(() => {
  //   if (
  //     MPP?.error &&
  //     MPP?.status === 'mpp/PUT_MPP_FAVOURITES_FAILED' &&
  //     heartClicked &&
  //     authentication &&
  //     authentication.user_status === 'logged'
  //   ) {
  //     setHeartActionError(true)
  //     setHeartClicked(false)
  //   }
  //   if (!MPP?.error && MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
  //     console.debug('here')
  //     setHeartActionError(false)
  //     setHeartClicked(true)
  //   }
  // }, [MPP, heartClicked, authentication])

  useEffect(() => {
    if (likes && properties?.property) {
      setHeartClicked(likes.includes(properties.property))
    }
  }, [likes, properties?.property])

  function imageType(type, image) {
    switch (type) {
      case 'auction':
        return image
      case 'comingsoon':
        return image ? image : IMGComingSoon
      case 'offmarket':
        return image ? image : IMGOffMarket
      default:
        return image
    }
  }
  function listingType(type) {
    switch (type) {
      case 'rental':
        return 'rent'
      case 'sold':
        return 'sold'
      default:
        return 'buy'
    }
  }

  function checkLoginStatus(e) {
    e.stopPropagation()

    if (authentication && authentication.user_status !== 'logged') {
      routerChange('/login', { origin: window?.location?.pathname })
    } else {
      /** User Logged */
      setHeartClicked((prev) => !prev)
      apiRequestSaveProperty(properties.property)
    }
  }

  function renderProperties() {
    return (
      <div
        className={active ? classes.wrap : ''}
        onClick={() => {
          setCurrentProperty(properties)
          if (pathType === 'property') {
            routerChange('/' + currentPath + '/' + properties.property, {
              previous_screen: currentScreen,
            })
          }
          if (pathType === 'liked') {
            routerChange('/liked-properties/' + properties.property, {
              previous_screen: currentScreen,
            })
          }

          triggerAnalyticsEvent({
            event: 'results_actions',
            eventFrom: 'Web',
            actionScope: 'map_card_click',
            actionName: 'Property Card',
            actionTarget: 'Viewed Property',
            actionReference: 'results',
            ...getPropertyData(properties),
          })
        }}
      >
        {active && active === properties.property && (
          <div className={classes.rootActive}>
            <Card className={classes.cardActive}>
              <div className={classes.relativeWrap}>
                <Image
                  className={classes.media}
                  src={
                    galleryImages
                      ? imageType(properties.propertyStatus, galleryImages[0])
                      : null
                  }
                  onClick={() => ''}
                  aspectRatio={16 / 11}
                  disableSpinner
                />

                {authentication &&
                authentication.user_status === 'logged' &&
                likes &&
                heartClicked ? (
                  <Tooltip title="Remove from favorite">
                    <IconButton
                      className={classes.favoriteContainer}
                      onClick={(e) => {
                        checkLoginStatus(e)
                        triggerAnalyticsEvent({
                          event: 'results_actions',
                          eventFrom: 'Web',
                          actionScope: 'map_card',
                          actionName: 'Property Card',
                          actionTarget: 'Add to Likes',
                          actionReference: 'results',
                          ...getPropertyData(properties),
                        })
                      }}
                    >
                      <HeartFullIcon className={classes.favoriteIconActive} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Add to favorite">
                    <IconButton
                      className={classes.favoriteContainer}
                      onClick={(e) => {
                        checkLoginStatus(e)
                        triggerAnalyticsEvent({
                          event: 'results_actions',
                          eventFrom: 'Web',
                          actionScope: 'map_card',
                          actionName: 'Property Card',
                          actionTarget: 'Removed from Likes',
                          actionReference: 'results',
                          ...getPropertyData(properties),
                        })
                      }}
                    >
                      <HeartIcon className={classes.favoriteIcon} />
                    </IconButton>
                  </Tooltip>
                )}

                {/* <IconButton 
                                    className={classes.favoriteContainer} 
                                    onClick={()=> ''}>
                                    <HeartIcon className={classes.favoriteIcon}/>
                                </IconButton> */}
                {/* <MapPropertyCardAgentBanner properties={properties}/> */}
              </div>
              <div>
                <MapPropertyCardInfo type={type} properties={properties} />
                {properties && properties.propertyStatus === 'offmarket' && (
                  <small className={classes.offmarketApprox}>
                    This position is approximate location within 200 meters.
                  </small>
                )}
              </div>
            </Card>
            {heartActionError && (
              <NotificationDialog
                isOpen={heartActionError}
                closeCallback={() => {
                  setHeartActionError(false)
                }}
                title={'Error'}
                text={
                  MPP?.error?.status === 401 || MPP?.error?.status === 400
                    ? 'Your session has expired or invalid. You will be asked to re-login after few seconds.'
                    : 'Error encountered making this property favorite.'
                }
                showLogo={true}
                align={'center'}
              />
            )}
            <div className={classes.triangle}></div>
          </div>
        )}
      </div>
    )
  }

  return <>{renderProperties()}</>
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(withStyles(styles)(MapPropertyOverlay))
