import React, { useState, useEffect } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import AGENCIES from '../../../assets/data/agencies.json'
import _ from 'lodash'
import { matchSorter } from 'match-sorter'
import { TextField } from '@material-ui/core'

const AgencySearchField = (props) => {
  const { open, setOpen, style = {} } = props
  const defaultOption = { name: 'Other' }
  const sortedAgencies = AGENCIES.sort((a, b) => {
    const x = a.name.toUpperCase()
    const y = b.name.toUpperCase()
    if (x < y) {
      return -1
    }

    if (x > y) {
      return 1
    }

    return 0
  })

  sortedAgencies.push(defaultOption)
  const finalAgencyList = [
    ...new Map(sortedAgencies.map((item) => [item['name'], item])).values(),
  ]

  const _filterOptions = createFilterOptions()
  const filterOptions = (options, state) => {
    const results = _filterOptions(options, state)

    if (!results.includes(defaultOption)) {
      results.unshift(defaultOption)
    }

    const finalResult = matchSorter(results, state.inputValue, {
      keys: [
        {
          threshold: matchSorter.rankings.STARTS_WITH,
          key: 'name',
        },
      ],
    })

    return finalResult?.length > 0 ? finalResult : results
  }

  const defaultProps = {
    options: finalAgencyList,
    getOptionLabel: (option) => option.name ?? '',
    renderOption: (option) => {
      return (
        option.name +
        (option.city ? ', ' + option.city : '') +
        (option.address ? ', ' + option.address : '') +
        (option.city ? ', ' + option.city : '') +
        (option.city ? ' ' + option.state : '')
      )
    },
    filterOptions: filterOptions,
  }

  const [selectorError, setSelectorError] = useState(false)

  return (
    <div>
      <Autocomplete
        {...defaultProps}
        onInputChange={props.onInputChange && props.onInputChange}
        open={open}
        onOpen={() => {
          if (props.inputValue) {
            setOpen(true)
          }
        }}
        forcePopupIcon={false}
        id="auto-complete"
        autoComplete
        includeInputInList
        defaultValue={{ name: props.inputValue }}
        onChange={(e, value) => {
          if (value) {
            setSelectorError(false)
          } else {
            setSelectorError(true)
            setOpen(false)
          }

          if (props.setselectedagency) {
            props.setselectedagency(value)
          }
        }}
        onClose={(e, reason) => {
          const value = e.target.value
          if (reason === 'blur' || reason === 'select-option') {
            if (value !== '') {
              setSelectorError(false)
            } else {
              setSelectorError(true)
            }

            setOpen(false)
          }
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              error={selectorError}
              helperText={
                selectorError
                  ? 'Please select an agency or choose Other to manual input Name'
                  : null
              }
              variant={'outlined'}
              label={props.placeholder}
              margin="normal"
              style={{ ...style }}
            />
          )
        }}
      />
    </div>
  )
}

export default AgencySearchField
