import React, { useState, useEffect } from 'react';
import { IconButton, Button, Grid, AppBar, Paper, Container, Typography, withStyles } from "@material-ui/core";
import DialogContainer from '../CustomDialogs/DialogContainer';
import ConfirmDialog from '../CustomDialogs/ConfirmDialog';

import CloseIcon from '@material-ui/icons/Close';
import HeadingContent from './HeadingContent';

import checkField from 'components/Common/Validator/validationService'
import FormField from '../Form/FormField';

import { 
    AGENTS_CONST
  } from "../../../constants/constants";
const styles = theme => ({
    ctaRounded: {
        borderRadius: 25,
        color: theme.palette.text.primary, 
        textTransform: 'initial',
        paddingLeft: theme.spacing(3.2),
        paddingRight: theme.spacing(3.2),
        marginLeft: theme.spacing(1.5),
        backgroundColor: theme.palette.common.white
    },
    actionWrap: {
        display: 'flex',
        width: '100%', 
        justifyContent: 'flex-end'
    },
    headerBar: {
        backgroundColor: theme.palette.light.main,
        boxShadow:'none'
    },
    modal: {
        margin: theme.spacing(7),
        marginRight: '25%',
        marginLeft: '25%',
        borderRadius: 38,
        position: 'relative',    
        '&:hover': {
        outline: 'none',
        },
        overflow: 'hidden',
        padding: theme.spacing(3.25, 6.75)
    },
    closeicon: {
        position: 'absolute',
        top: 20, 
        right: theme.spacing(2.5)
    },
    rowWrap: {
        display: 'flex',
    },
    moreRows: {
        display: 'flex',
        justifyContent: 'flex-end',
        '&  div > span' : {
            alignSelf: 'center'
        }
    },
    rowField: {
        display: 'flex'
    },
    moreBtn: {
        textTransform: 'capitalize',
        textDecoration: 'underline',
        minWidth: 'auto',
        fontSize: '13px',
        padding: 0,
        '&:hover' : {
            background: 'transparent'
        }
    },
    ctaRounded: {
        borderRadius: 25,
        color: theme.palette.text.primary, 
        textTransform: 'initial',
        paddingLeft: theme.spacing(3.2),
        paddingRight: theme.spacing(3.2),        
        backgroundColor: theme.palette.common.white,
        minWidth: 180
    },
    actionBtns: {
        display: 'flex', 
        justifyContent: 'space-between',
        paddingTop: theme.spacing(2)
    }
});

const ScrollContainer = withStyles({
    root: {
      overflowY:'auto', overflowX:'hidden', maxHeight:'calc(85vh - 100px)', width:'100%',
      padding: 0,
      minHeight: '30vh',
      paddingTop: 4
    }
  })(Container);

const MainHeader = withStyles({
    root: {
      boxShadow:'none',
      padding:'10px 0px'
    }
  })(AppBar);

const AddAgentForm = props => {  
    const {
      classes,
      isAddingAgent,
      setIsAddingAgent,
      success,
      setSuccess, 
      status,
      error,
      submitAgents
    } = props;

    const [agents, setAgents] = useState(null);
    const [rowsCounter, setRowsCounter] = useState(0);
    const [errors, setErrors] = useState({
        email: {status: false, message: ''}
    })

    const [confirmSubmit, setConfirmSubmit] = useState(false)

    

    const handleChange = (field, value, index) => {
        setAgents((state)=> {   
            var updateEntry = agents.slice();
            updateEntry[index] = {...updateEntry[index], [field]: value};
            setAgents(updateEntry);
        })
        if (field in errors){
            handleErrors(field, value, index);
        }
    } 
    
    const handleErrors = (field, value, index) => {
        var error = { status: false, message: null }; 

        // console.log(AGENTS_CONST(field).fields, "CONSTS")

        if(checkField(value, AGENTS_CONST(field).fields[field] ) !== null && checkField(value, AGENTS_CONST(field).fields[field] ) !== 'null') {             
            error = { status: true, message:checkField(value, AGENTS_CONST(field).fields[field] )}
        }else{
            error = { status: false, message: null}
        }

        var updateEntry = agents.slice();
            updateEntry[index].errors = error;
            setAgents(updateEntry);
    }

    useEffect(() => {
        setDefaultRow();
    },[])
    
    useEffect(() => {
        if(status && !error) {
            setDefaultRow();
            setIsAddingAgent(false);
            setSuccess(true)   
        }
    },[status, error])

/* Submit Agents to API */
    async function apiRequestAddAgents(){
        var params = {
            agents: agents
        }
        if(agents) {
            submitAgents(params)
        }
    }

    const handleSubmit = () => {
        apiRequestAddAgents()
    }

    const handleMoreRows = () => {
        var currentCount = agents.length;
        var counter; 
        var newEntry = agents.slice();
        
        for (counter = 0; counter < rowsCounter; counter++) {
            
            newEntry.push({
                id: currentCount++, 
                first_name: '',
                last_name: '',
                email: '',
                phone: '', 
                errors: {
                    status: false, 
                    message: ''
                }
            });
            setAgents(newEntry);
        }
            
    }

    const setDefaultRow = () => {
        setAgents([
            {
                id: 0, 
                first_name: '', 
                last_name: '',
                email: '',
                phone: '',
                errors: {
                    status: false, 
                    message: ''
                }
            }
        ]) 
        setRowsCounter(0)       
    }

  


    function renderAgentFieldRow(data, index){        
        return (
            <Grid container spacing={3} key={ 'first_name'+ index} >                            
                <Grid item xs={6} md={3} >
                    <FormField 
                        type={'text'}                        
                        placeholder={'First name'}
                        name={'first_name'}
                        // value={agents[index].first_name}
                        onChange={(e) => handleChange('first_name', e.target.value, index )}                                   
                    /> 
                </Grid>
                <Grid item xs={6} md={3} key={ 'last_name'+ index}>
                    <FormField 
                        type={'text'}                        
                        placeholder={'Last name'}                               
                        name={'last_name'}
                        // value={agents[index].last_name}
                        onChange={(e) => handleChange('last_name', e.target.value, index )}                                       
                    /> 
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormField 
                        type={'text'}                        
                        placeholder={'Email address'}  
                        errorMsg={agents[index].errors.message}
                        showError={agents[index].errors.status}                             
                        name={'email'}
            
                        onChange={(e) => handleChange('email', e.target.value, index )}     
                    /> 
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormField 
                        type={'tel'}                        
                        placeholder={'Phone number'}                               
                        name={'phone'}
                      
                        onChange={(e) => handleChange('phone', e.target.value, index )}     
                    /> 
                </Grid>
            </Grid>
        )
    }

    return (
        <DialogContainer
            isOpen={isAddingAgent}
            closeCallback={setIsAddingAgent}
        >
            <Paper className={classes.modal} elevation={5}>                
                <MainHeader className={classes.headerBar} position="static">
                    <IconButton aria-label="close" className={classes.closeicon} onClick={()=> {setIsAddingAgent(false)}}>
                        <CloseIcon fontSize="small"/>
                    </IconButton>
                    <HeadingContent
                        title={'Add My Agent'}
                        content={'Add details below to add an agent'}
                    />
                </MainHeader>

                <ScrollContainer>
                    <div className={classes.formWrap} key={'addAgentForm'}>
                        {agents && agents.map( (agent,idx) => {                                                        
                            return renderAgentFieldRow(agent, idx)
                        } )
                            
                        }
                        <div className={classes.moreRows}>
                            <Typography variant="caption" className={classes.rowField} component="div">
                                <span>Add</span> 
                                <FormField
                                    value={rowsCounter}
                                    type={'number'}
                                    style={{width: '130px', margin: '0 16px'}}
                                    onChange={(e)=> setRowsCounter(e.target.value)}
                                />
                                <span><Button className={classes.moreBtn} disableRipple onClick={handleMoreRows}>More</Button></span>
                            </Typography>
                        </div>
                        <div className={classes.actionBtns}>
                            <Button variant="outlined" color="default" disableElevation className={classes.ctaRounded} onClick={()=> {
                                    setIsAddingAgent(false)
                                    setDefaultRow()
                                }}>
                                Cancel
                            </Button>
                            <Button variant="outlined" color="primary"
                                disabled={agents && agents[0] && agents[0].email.length && !agents[0].errors.status ? false : true }
                                disableElevation
                                className={classes.ctaRounded}
                                onClick={()=>setConfirmSubmit(true)}>
                                + Add My Agent
                            </Button>
                        </div>
                    </div>
                </ScrollContainer>                                            
            </Paper>
           
            <ConfirmDialog        
                isOpen={confirmSubmit}
                confirmCallback={ handleSubmit }
                closeCallback={ ()=> setConfirmSubmit(false) }
                showLogo={true}
                align={'center'}
                title={'Add Agent'}
                text={'Are you sure you want add to listed agent/s?'}
            />
        </DialogContainer>

        
    );
}

export default withStyles(styles)(AddAgentForm);