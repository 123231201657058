import React, { useEffect } from 'react';

import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import CloseIcon from '@material-ui/icons/Close';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { OPTION_MODAL, AGENCY_MODAL, TRADE_OPTION_MODAL } from './constants';

import ListOptions from "./ListOptions";
import OptionModalBottom from "./OptionModalBottom";

import { withStyles, Button, Typography, Paper, IconButton } from '@material-ui/core';

const styles = theme => ({
    root: {
        paddingTop: theme.spacing(2),
        backgroundColor: '#FFF'
    },
    optionButton: {
        textTransform: 'none',
        color: '#000',
        fontWeight: 'normal'
    },
    optionIcon: {
        color: '#000000'
    },
    optionButtonFilter: {
        textTransform: 'none',
        textDecoration: 'underline',
        color: '#000',
        fontWeight: 'normal'
    },

    /** Bottomn Option Modal */
    optionBottomContainer: {
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        '&:hover': {
            outline: 'none',
        },
    },
    optionBottomHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        paddingTop: theme.spacing(4),
    },
    optionBottomHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    optionBottomHeaderText: {
        fontWeight: '300'
    },
    optionBottonList: {
        height: '30vh',
        overflowY: 'scroll'
    },

    headerContainer: {
        width: '70%',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    agentHeader: {
        fontSize: '1.000em',
        fontWeight: '400',
        fontStyle: 'normal'
    },
    sortContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing(2)
    },

});

const SortSelection = props => {

    const {
        classes,
        sortType,
        showModalBottomOptions,
        showOptionBottomModal,
        filters
    } = props;
    const OPTIONS = sortType === 'trades-results' ? TRADE_OPTION_MODAL : OPTION_MODAL;
    const [sortBy, setSortBy] = React.useState(filters ? filters.sorting ? filters.sorting : OPTIONS : OPTIONS);

    const [contentType, setContentType] = React.useState(null);

    const handleSortBy = (data) => {
        showOptionBottomModal()
        setContentType(null)
    }

    useEffect(() => {
        if (filters && filters.sorting) {
            setSortBy(filters.sorting)
        }
    }, [filters])

    const getSuburbLabel = () => {
        let label = '';
        if (filters.perfectProperty && filters.perfectProperty.state) {
            filters.perfectProperty.state.map((item, index) => {
                if (item.active) {
                    label = getState(item.title);
                }
            })
        }

        return label;
    }

    const getState = (label) => {
        let t = label.toLowerCase();
        switch (t) {
            case 'vic':
                return 'Victoria';
            case 'nsw':
                return 'New South Wales';
            case 'wa':
                return 'Western Australia';
            case 'tas':
                return 'Tasmania';
            case 'sa':
                return 'South Australia';
            case 'nt':
                return 'Northern Territory';
            case 'act':
                return 'Australian Capital Territory';
            case 'qld':
                return 'Queensland';
            default:
                return ''; // default
        }
    }

    function setContent(type) {
        setContentType(type)
        showOptionBottomModal()
    }

    function renderContent() {
        return (
            <Paper
                className={classes.optionBottomContainer}
                elevation={5}>
                <div className={classes.optionBottomHeaderContainer}>
                    <div className={classes.optionBottomHeader}>
                        <UnfoldMoreIcon />
                        <Typography className={classes.optionBottomHeaderText} variant="h6" component="h2">
                            {'Sort by'}
                        </Typography>
                    </div>
                    <IconButton onClick={() => showOptionBottomModal()}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.optionBottonList}>
                    <ListOptions
                        type={'sort'}
                        options={contentType === 0 ? AGENCY_MODAL : OPTION_MODAL}
                        handleType={handleSortBy} />
                </div>
            </Paper>
        )
    }

    function searchAgent() {
        return (
            <div className={classes.root}>
                <div className={classes.headerContainer}>
                    <Typography className={classes.agentHeader} variant="caption" component="h2">
                        {'88 agents found in Moonee Ponds, Vic, 3039'}
                    </Typography>
                </div>
                <div className={classes.sortContainer}>
                    <Button
                        className={classes.optionButtonFilter}
                        size={'small'}
                        onClick={() => setContent(0)}>
                        {'Filter by Agency'}
                    </Button>
                    <Button
                        className={classes.optionButton}
                        size={'small'}
                        endIcon={<ImportExportIcon className={classes.optionIcon} />}
                        onClick={() => setContent(1)}>
                        {'Sort'}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                sortType === 'agent-results' &&
                searchAgent()
            }
            <OptionModalBottom
                isOpen={showModalBottomOptions}
                showOptionBottomModal={showOptionBottomModal}
                content={renderContent()} />
        </>
    );
};

export default withStyles(styles)(SortSelection);
