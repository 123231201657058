import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import AgentInfo from './AgentInfo'
import {
  LOGO_OBJECT,
  getLogoByAgencyId,
  getAgencyHexByAgencyId,
  DEFAULT_AGENCIES,
  HEX_OBJECT,
  getAgencyNameByAgencyId,
} from '../../../constants/constants'
import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'
import PhoneIcon from '@material-ui/icons/Phone'

import { withStyles, Typography, Card, Avatar, Grid, Button } from '@material-ui/core'

import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  card: {
    width: 'auto',
  },
  profileImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    backgroundColor: '#FFF',
    cursor: 'auto',
    minHeight: 75,
  },
  media: {
    // top: 15,
    width: '4.000em',
    height: '4.000em',
    borderRadius: '2.000em',
    objectFit: 'cover',
    marginRight: theme.spacing(1),
    position: 'absolute',
    bottom: '-30px',
    right: 0,
  },
  agencyBanner: {
    width: '100%',
    height: 40,
    backgroundColor: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  agentLogo: {
    width: '90%',
    objectFit: 'contain',
    maxHeight: '70%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    imageRendering: 'auto',
    imageRendering: '-webkit-optimize-contrast',
    padding: '0px',
  },
  favoriteContainer: {
    width: '0.625em',
    height: '0.625em',
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
    width: '0.625em',
    height: '0.625em',
  },
  labelAgencyName: {
    paddingLeft: 10,
    marginBottom: 15,
    fontWeight: '400',
    fontSize: 16,
    paddingRight: '10px',
    width: '68%',
    lineHeight: 1.2,
  },
  labelAgency: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 1.2,
  },
  agentName: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21px',
  },
  buttonOption: {
    marginTop: 5,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    padding: theme.spacing(1.25, 0.6),
  },
})

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 22px 0;
  width: calc(100% - 50px);
  margin: 0 auto;
  &.bordered {
    border-bottom: 0.25px solid #979797;
  }

  &:last-child {
    border: 0;
  }

  .avatar {
    width: 80px;
    height: 80px;
    margin-left: 15px;
  }
`
const Col = styled.div`
  width: 50%;
  &:first-child {
    width: 45%;
  }
  &:last-child {
    width: 55%;
  }
`
const ButtonWrap = styled.div`
  padding: 18px 15px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    border: 0;
    width: 203px;
    margin: 0 auto;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    background-color: #35c0ca;
    border-radius: 20px;
    text-align: center;
    height: 48px;
    text-transform: none;
  }
`

const AgentCardsDisplayInfo = ({ classes, agent, getAgentImage }) => {
  const [isVisible, setIsVisible] = useState(false)
  const hasTelephone = agent.telephone ? true : agent.phone ? true : false
  const telNum = agent.telephone ? agent.telephone : agent.phone ? agent.phone : null
  const { getPropertyData, triggerAnalyticsEvent } = useGoogleEvents()

  return (
    <div>
      {hasTelephone && (
        <Row className="bordered">
          <Col>
            <Avatar className="avatar" src={getAgentImage(agent.image)} />
          </Col>
          <Col>
            <Typography
              dangerouslySetInnerHTML={{ __html: agent.name ? agent.name : '' }}
              className={classes.agentName}
              variant="body2"
              component="p"
            ></Typography>
            {hasTelephone && (
              <Button
                href={isVisible ? `tel:${telNum}` : '#'}
                className={classes.buttonOption}
                startIcon={<PhoneIcon />}
                fullWidth
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault()
                  setIsVisible((prev) => !prev)
                  triggerAnalyticsEvent({
                    event: 'property_actions',
                    eventFrom: 'Web',
                    actionScope: 'property_agent_card',
                    actionName: 'Property Agent Card',
                    actionTarget: 'Clicked Agent Mobile Number',
                    actionReference: 'property',
                    ...getPropertyData({ agent: agent }),
                  })
                }}
              >
                {isVisible ? telNum : 'Phone'}
              </Button>
            )}
          </Col>
        </Row>
      )}
    </div>
  )
}

const AgentCards = (props) => {
  const { classes, type, agent, executeScroll } = props

  const [agents, setAgents] = useState(agent.agents)

  const { getPropertyData, triggerAnalyticsEvent } = useGoogleEvents()

  useEffect(() => {
    let obj = agent.agents
    if (obj && obj.length) {
      obj.map((item) => {
        return (item['visible'] = false)
      })
      setAgents(obj)
    }
  }, [])

  const AgentImage = (img) => {
    if (img === 'http://lorempixel.com/200/200/people/9/') {
      return null
    } else {
      if (img) {
        const imgsplit = img.split('image/upload', 2)

        return imgsplit.length === 2
          ? imgsplit[0] + 'image/upload/f_webp,q_auto/c_fill,g_face,h_300,w_300' + imgsplit[1]
          : img
      }
      return img
    }
  }

  const checkIfLight = (color) => {
    const hex = color.replace('#', '')
    const c_r = parseInt(hex.substr(0, 2), 16)
    const c_g = parseInt(hex.substr(2, 2), 16)
    const c_b = parseInt(hex.substr(4, 2), 16)
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
    return brightness > 155
  }

  const logoObject = LOGO_OBJECT,
    hexObject = HEX_OBJECT

  const getAgencyLogo = (agencyName) => {
    if (
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return logoObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return LogoPlaceholder
    }
  }

  const getAgencyHex = (agencyName) => {
    if (
      hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)] &&
      hexObject[getLogoByAgencyId(DEFAULT_AGENCIES, agencyName)].length > 0
    ) {
      return hexObject[getAgencyHexByAgencyId(DEFAULT_AGENCIES, agencyName)]
    } else {
      return '#000000'
    }
  }

  const brandColor =
    agent.agency && agent.agency.brandColor
      ? agent.agency.brandColor
      : agent.reaAgencyId
      ? getAgencyHex(agent.reaAgencyId || agent.agency.reaAgencyId)
      : '#000000'

  function renderAgentCard() {
    return (
      <div className={classes.root}>
        <Card className={classes.card} style={{ borderRadius: 0 }}>
          <div
            className={classes.agencyBanner}
            style={{
              backgroundColor: brandColor,
              boxShadow: checkIfLight(brandColor) ? '0 0 0 1px #000000' : 'none',
            }}
          >
            <img
              className={classes.agentLogo}
              src={
                agent.agency && agent.agency.logoUrl
                  ? agent.agency.logoUrl
                  : getAgencyLogo(agent.reaAgencyId)
              }
            />
          </div>
          <Row style={{ paddingTop: 10, paddingBottom: 3 }}>
            <Col>&nbsp;</Col>
            <Col>
              <Typography
                className={classes.labelAgency}
                variant="caption"
                component="h2"
              >
                {agent.agency && agent.agency.companyName
                  ? agent.agency.registeredTradeName
                    ? agent.agency.registeredTradeName
                    : agent.agency.companyName
                  : getAgencyNameByAgencyId(agent.reaAgencyId)
                  ? getAgencyNameByAgencyId(agent.reaAgencyId)
                  : agent.agencyName ?? ''}
              </Typography>
            </Col>
          </Row>
          <div>
            {/*  {agents.map((o, index) => {
                const hasTelephone = o.telephone ? true : o.phone ? true : false
                const telNum = o.telephone ? o.telephone : o.phone ? o.phone : null
                if (hasTelephone) {
                  return (
                    <Row key={'row-' + index} className="bordered">
                      <Col>
                        <Avatar className="avatar" src={AgentImage(o.image)} />
                      </Col>
                      <Col>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: o.name ? o.name : '' }}
                          className={classes.agentName}
                          variant="body2"
                          component="p"
                        ></Typography>
                        {hasTelephone && (
                          <Button
                            href={o.visible ? `tel:${telNum}` : '#'}
                            className={classes.buttonOption}
                            startIcon={<PhoneIcon />}
                            fullWidth
                            variant="outlined"
                            onClick={(e) => {
                              e.preventDefault()
                              let obj = [...agents]
                              obj[index].visible = !o.visible
                              setAgents(obj)
                            }}
                          >
                            {o.visible ? telNum : 'Phone'}
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )
                }
              })}*/}
            {agents.map((agent, index) => (
              <AgentCardsDisplayInfo
                classes={classes}
                getAgentImage={AgentImage}
                agent={agent}
                key={'row-' + index}
              />
            ))}
          </div>
          <ButtonWrap>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                executeScroll()
                triggerAnalyticsEvent({
                  event: 'property_actions',
                  eventFrom: 'Web',
                  actionScope: 'property_agent_card',
                  actionName: 'Property Agent Card',
                  actionTarget: 'Clicked Email Agent',
                  actionReference: 'property',
                  ...getPropertyData(agent),
                })
              }}
            >
              Send an enquiry
            </Button>
          </ButtonWrap>
        </Card>
      </div>
    )
  }

  return (
    <>
      {props.sticky ? (
        <div style={{ minWidth: 325 }}>
          {type === 'agent-card' && renderAgentCard()}
        </div>
      ) : (
        <Grid item xs={6} sm={6} md={4} lg={3} xl={3}>
          {type === 'agent-card' && renderAgentCard()}
        </Grid>
      )}
    </>
  )
}

export default withStyles(styles)(AgentCards)
