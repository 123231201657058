/** Call all api functions here */
import { POST, FETCH } from '../../utils/api';

import {
    facebookAuthRequest,
    facebookAuthSuccess,
    facebookAuthError,

    googleAuthRequest,
    googleAuthSuccess,
    googleAuthError,

    appleAuthRequest,
    appleAuthSuccess,
    appleAuthError,

    registrationRequest,
    registrationSuccess,
    registrationError,

    verifyRegistrationRequest,
    verifyRegistrationSuccess,
    verifyRegistrationError,

    loginRequest,
    loginSuccess,
    loginError,

    forgotPasswordRequest,
    forgotPasswordSuccess,
    forgotPasswordError,

    changePasswordRequest,
    changePasswordSuccess,
    changePasswordError,

    resendCodeError,
    resendCodeRequest,
    resendCodeSuccess,

    updateUser,
    updateUserSuccess,
    updateUserError,

    validateUserToken,
    validateUserTokenSuccess,
    validateUserTokenError

} from '../actions';

import {
    errorhandler
} from '../api';

import {
    apiEndpoints
} from '../../utils/api/endpoints';

export function requestAPIFacebookAuth(params) {
    return dispatch => {
        dispatch(facebookAuthRequest(params));
        POST(apiEndpoints.FACEBOOK_AUTH, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(facebookAuthSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(facebookAuthError(errorhandler(error)));
            })
    }
}

export function requestAPIGoogleAuth(params) {
    return dispatch => {
        dispatch(googleAuthRequest(params));
        POST(apiEndpoints.GOOGLE_AUTH, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(googleAuthSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(googleAuthError(errorhandler(error)));
            })
    }
}

export function requestAPIAppleAuth(params) {
    return dispatch => {
        dispatch(appleAuthRequest(params));
        POST(apiEndpoints.APPLE_AUTH, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(appleAuthSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(appleAuthError(errorhandler(error)));
            })
    }
}

export function requestAPIUserRegistration(params) {
    return dispatch => {
        dispatch(registrationRequest(params));
        POST(apiEndpoints.REGISTRATION, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(registrationSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(registrationError(errorhandler(error)));
            })
    }
}

export function requestAPIVerifyUserRegistration(params) {
    return dispatch => {
        dispatch(verifyRegistrationRequest(params));
        POST(apiEndpoints.REGISTRATION_VERIFICATION, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(verifyRegistrationSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(verifyRegistrationError(errorhandler(error)));
            })
    }
}

export function requestAPIUserLogin(params) {
    const URL_SETTER = params.query ? apiEndpoints.LOGIN + params.query : apiEndpoints.LOGIN
    return dispatch => {
        dispatch(loginRequest(params));
        POST(URL_SETTER, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(loginSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(loginError(errorhandler(error)));
            });
    }
}

export function requestAPIForgotPassword(params) {
    return dispatch => {
        dispatch(forgotPasswordRequest(params));
        POST(apiEndpoints.FORGOT_PASSWORD, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(forgotPasswordSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(forgotPasswordError(errorhandler(error)));
            })
    }
}

export function requestAPIChangePassword(params) {
    return dispatch => {
        dispatch(changePasswordRequest(params));
        POST(apiEndpoints.CHANGE_PASSWORD, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(changePasswordSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(changePasswordError(errorhandler(error)));
            })
    }
}

export function requestAPIResendCode(params) {
    return dispatch => {
        dispatch(resendCodeRequest(params));
        POST(apiEndpoints.RESEND_CODE, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(resendCodeSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(resendCodeError(errorhandler(error)));
            })
    }
}

export function requestUpdateUser(params) {
    return dispatch => {
        dispatch(updateUser(params));
        POST(apiEndpoints.UPDATE_USER, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(updateUserSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(updateUserError(errorhandler(error)));
            })
    }
}

export function requestValidateUserToken(params) {
    return dispatch => {
        dispatch(validateUserToken(params));
        FETCH(apiEndpoints.VALIDATE_TOKEN, params)
            .then(response => {
                if (response.error) {
                    throw (response.error);
                }
                dispatch(validateUserTokenSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(validateUserTokenError(errorhandler(error)));
            })
    }
}
