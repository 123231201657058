import React from 'react'
import Image from 'material-ui-image'

import { Container, Grid, withStyles, Typography, Button } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    minHeight: 530,
    padding: '75px 0 64px 170px',
    backgroundSize: 'cover',
    '@media (max-width: 1200px)': {
      paddingLeft: '142px',
    },
  },
  sectionHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 52,
    lineHeight: '45.5px',
    color: theme.palette.gray.main,
    marginBottom: theme.spacing(6),
    letterSpacing: '-0.78px',
    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
      fontSize: 58,
    },
  },
  sectionImageColumn: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrap: {
    maxWidth: 475,
    display: 'block',
    margin: '0 auto',
  },
  sectionSubHeading: {
    fontFamily: 'Montserrat, sans',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.52px',
  },
  sectionLeadText: {
    color: theme.palette.gray.main,
    marginBottom: theme.spacing(4),
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '1.6em',
  },
  sectionLeftTextColumn: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      maxWidth: 400,
    },
  },
  sectionRightTextColumn: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      maxWidth: 407,
    },
    '@media (min-width: 1280px)': {
      paddingLeft: theme.spacing(8) + 'px !important',
    },
  },
  contentBox: {
    backgroundColor: 'rgba(20, 35, 50, 0.8)',
    padding: theme.spacing(8, 5),
    borderRadius: 15,
    color: '#ffffff',
    maxWidth: 480,
  },
  cbHeading: {
    fontFamily: 'Montserrat, sans',
    fontWeight: 500,
    fontSize: 24,
    marginBottom: theme.spacing(3),
  },
  cbText: {
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '1.6em',
    marginBottom: theme.spacing(6),
  },
  cbCTA: {
    borderRadius: 20,
    textTransform: 'capitalize',
  },
})

const TextImageSection = (props) => {
  const { classes, heading, subheading, text, isGray, variant, image, link } = props

  const renderLeftVariant = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.sectionLeftTextColumn}>
          <div>
            <Typography component={'h1'} className={classes.sectionHeading}>
              {heading}
            </Typography>
            <Typography component={'h6'} className={classes.sectionLeadText}>
              {text}
            </Typography>
            {subheading && (
              <Typography component={'h5'} className={classes.sectionSubHeading}>
                {subheading}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.sectionImageColumn}>
          {image && (
            <div className={classes.imageWrap}>
              <Image
                src={image}
                style={{ width: '100%' }}
                aspectRatio={4 / 3.6}
                color={'transparent'}
                imageStyle={{ objectFit: 'contain' }}
              />
            </div>
          )}
        </Grid>
      </Grid>
    )
  }

  const renderRightVariant = () => {
    return (
      <Grid container spacing={2} style={{ paddingBottom: 16 }}>
        <Grid item xs={12} sm={6} className={classes.sectionImageColumn}>
          {image && (
            <div
              className={classes.imageWrap}
              style={{ transform: 'translateX(-30px)' }}
            >
              <Image
                src={image}
                style={{ width: '100%' }}
                aspectRatio={4 / 4}
                color={'transparent'}
                imageStyle={{ objectFit: 'contain' }}
              />
            </div>
          )}
        </Grid>

        <Grid item xs={12} sm={6} className={classes.sectionRightTextColumn}>
          <div>
            <Typography component={'h1'} className={classes.sectionHeading}>
              {heading}
            </Typography>
            <Typography component={'h6'} className={classes.sectionLeadText}>
              {text}
            </Typography>
            {subheading && (
              <Typography component={'h5'} className={classes.sectionSubHeading}>
                {subheading}
              </Typography>
            )}
          </div>
        </Grid>
      </Grid>
    )
  }
  const renderImageVariant = () => {
    return (
      <Grid container spacing={2} style={{ marginLeft: '-51px' }}>
        <Grid item xs={12} sm={8} className={classes.sectionRightTextColumn}>
          <div className={classes.contentBox}>
            <Typography component={'h1'} className={classes.cbHeading}>
              {heading}
            </Typography>
            <Typography component={'h6'} className={classes.cbText}>
              {text}
            </Typography>
            {subheading && (
              <Typography component={'h5'} className={classes.cbSubheading}>
                {subheading}
              </Typography>
            )}
            {link && link.url && (
              <Button
                href={link.url}
                variant={'contained'}
                color={'primary'}
                className={classes.cbCTA}
                disableElevation
              >
                {link.text}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    )
  }

  const backgroundImage =
    variant === 'image' && (image !== undefined || image !== null)
      ? 'url(' + image + ')'
      : 'none'

  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: backgroundImage,
        backgroundColor: isGray ? '#f5f5f5' : 'transparent',
      }}
    >
      <Container style={{ maxWidth: 1030 }}>
        {variant && variant === 'right'
          ? renderRightVariant()
          : variant && variant === 'image'
          ? renderImageVariant()
          : renderLeftVariant()}
      </Container>
    </div>
  )
}

export default withStyles(styles)(TextImageSection)
