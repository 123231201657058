import React, { useState, useRef, useEffect } from 'react'
import {
  Button,
  Grid,
  Typography,
  withStyles,
  IconButton,
  InputAdornment,
  FormControl,
  InputBase,
} from '@material-ui/core';
import Crumbs from '../Header/Crumbs'
import styled, { css } from 'styled-components'

import SearchIcon from '@material-ui/icons/Search'
import LOGO from '../../../assets/images/pmlogo-invoice.png'
import CustomDatePicker from '../Filter/CustomDatePicker'
import PropertyLoader from '../Property/PropertyLoader'
import { scrollToRef } from '../../Mobile/ScrollEvents'
import moment from 'moment'
import SAMPLE_PDF from '../../../assets/sample.pdf'

const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
    padding: theme.spacing(6.25, 5),
  },
  formControl: {
    border: '1px solid rgba(155,155,155,1)',
    borderRadius: '4px',
  },
  input: {
    height: '51px',
    padding: '0 0px 0 24px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#979797',
    width: '338px',
  },
  edgeEnd: {
    margin: 0,
    height: '51px',
    background: '#35C0CA !important',
    borderRadius: '4px',
    color: '#fff !important',
    width: '51px',
  },
})

const InvoiceContainer = styled.div`
  max-width: 1090px;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 50px 0 70px;

  p {
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.15000000596046448px;
    text-align: left;
    color: rgba(77, 77, 77, 1);
  }
`
const Footer = styled.div`
  padding-top: 20px;
`
const CardWrapper = styled.div`
  width: calc(100% + 24px);
  margin: 0 -12px;
`
const Card = styled.div`
  width: 254px;
  min-height: 254px;
  background-color: rgba(245, 245, 245, 1);
  border-radius: 4px;
  padding: 24px;
  margin: 12px;
  display: inline-block;
  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .issued-date {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.09000000357627869px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 25px;
  }

  .view-pdf {
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.09000000357627869px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
    margin-bottom: 10px;
    &:hover {
      text-decoration: underline;
    }
  }

  .btn-view {
    background: #ffffff;
    border: 0.5px solid #35c0ca;
    border-radius: 20px;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.07312499731779099px;
    text-align: center;
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    text-decoration: none;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    &:hover {
      opacity: 0.7;
    }
  }

  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: -7px;
    position: relative;
    margin-bottom: 12px;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 36px;
      right: 0;
      width: 65px;
      height: 2px;
      background-color: #35c0ca;
      margin: 0 auto;
    }
    img {
      width: 57px;
    }
    p {
      font-family: Montserrat;
      font-size: 21px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: -0.5249999761581421px;
      text-align: center;
      color: rgba(77, 77, 77, 1);
    }
  }
`

const checkCrumbsValue = () => {
  return [
    { label: 'Billing & Payment', route: '/agency/billing' },
    { label: 'View Past Invoices', route: '/agency/invoice' },
  ]
}

const Invoice = (props) => {
  const {
    classes,

    properties,
    authentication,
    initialData,
    handleXeroUrl,
  } = props

  const today = new Date()

  const [startDate, setStartDate] = React.useState(today)
  const [endDate, setEndDate] = React.useState(today)

  const [dataFiltered, setDataFiltered] = useState(
    initialData?.filter((obj) => obj.sourceReference != '') || null
  )

  const [visible, setVisible] = useState(0)

  const topContainer = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d)

  const handleStartDate = (data) => {
    setStartDate(data)
  }

  const handleEndDate = (data) => {
    setEndDate(data)
  }

  useEffect(() => {
    let totalCount = dataFiltered.filter((row) => {
      let filterPass = true
      const date = new Date(row.issueDate)
      if (startDate) {
        filterPass = filterPass && new Date(startDate) < date
      }
      if (endDate) {
        filterPass = filterPass && new Date(endDate) > date
      }
      //if filterPass comes back `false` the row is filtered out
      return filterPass
    }).length

    if (totalCount > 0) {
      if (totalCount < 6) {
        setVisible(totalCount)
      } else {
        setVisible(6)
      }
    } else {
      setVisible(0)
    }
  }, [startDate, endDate])

  const showMoreItems = () => {
    let totalItem = dataFiltered.filter((row) => {
      let filterPass = true
      const date = new Date(row.issueDate)
      if (startDate) {
        filterPass = filterPass && new Date(startDate) < date
      }
      if (endDate) {
        filterPass = filterPass && new Date(endDate) > date
      }
      //if filterPass comes back `false` the row is filtered out
      return filterPass
    }).length

    if (visible < totalItem) {
      setVisible((prevValue) => {
        let visibleCount = prevValue + 6
        if (visibleCount > totalItem) {
          return totalItem
        } else {
          return visibleCount
        }
      })
    }
  }

  return (
    <div className={classes.root} ref={topContainer}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <Crumbs
            type={'DARK'}
            crumbsOption={checkCrumbsValue()}

          />
        </Grid>
      </Grid>

      <InvoiceContainer>
        <Header>
          <p>
            Showing {visible} results out of{' '}
            {dataFiltered.filter((row) => {
              let filterPass = true
              const date = new Date(row.issueDate)
              if (startDate) {
                filterPass = filterPass && new Date(startDate) < date
              }
              if (endDate) {
                filterPass = filterPass && new Date(endDate) > date
              }
              //if filterPass comes back `false` the row is filtered out
              return filterPass
            }).length || 0}
          </p>
          <div className="">
            <CustomDatePicker
              isForm={true}
              name="startDate"
              label="From"
              value={startDate}
              handleDate={handleStartDate}
              isClearFilter={false}
            />
          </div>
          <div className="">
            <CustomDatePicker
              isForm={true}
              name="endDate"
              label="To"
              value={startDate}
              handleDate={handleEndDate}
              isClearFilter={false}
              minDate={startDate}
            />
          </div>
        </Header>

        <CardWrapper>
          {dataFiltered
            .filter((row) => {
              let filterPass = true
              const date = new Date(row.issueDate)
              if (startDate) {
                filterPass = filterPass && new Date(startDate) < date
              }
              if (endDate) {
                filterPass = filterPass && new Date(endDate) > date
              }
              //if filterPass comes back `false` the row is filtered out
              return filterPass
            })
            ?.slice(0, visible)
            .map((item, key) => {
              return (
                <Card key={key}>
                  <div className="wrap">
                    <div className="logo">
                      <img src={LOGO} />
                      <p>Invoice</p>
                    </div>
                    <p className="issued-date">
                      Issued on {moment(item?.issueDate).format('MM/DD/YYYY')}
                    </p>
                    <Button
                      className="btn-view"
                      onClick={() => {
                        handleXeroUrl(item?._id)
                      }}
                    >
                      View
                    </Button>
                  </div>
                </Card>
              )
            })}
        </CardWrapper>

        <div className={classes.actionWrap}>
          <Button
            variant="outlined"
            color="primary"
            disableElevation
            className={classes.ctaRounded}
            onClick={showMoreItems}
          >
            Load More
          </Button>
        </div>

        <Footer>
          <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
        </Footer>
      </InvoiceContainer>
    </div>
  )
}

export default withStyles(styles)(Invoice)
