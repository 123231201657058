import { POST, FETCH, PUT, DELETE } from '../../utils/api'

import {
  agentSearch,
  agentSearchSuccess,
  agentSearchFail,
  agentGetDetails,
  agentGetDetailsSuccess,
  agentGetDetailsFail,
  agentAgencyDataRequest,
  agentAgencyDataRequestFail,
  agentAgencyDataRequestSuccess,
  agentSetRating,
  agentSetRatingSuccess,
  agentSetRatingFail,
  agentGetProperties,
  agentGetPropertiesSuccess,
  agentGetPropertiesFail,
  agentSetPauseProperty,
  agentSetPausePropertySuccess,
  agentSetPausePropertyFail,
  agentCreateProperty,
  agentCreatePropertySuccess,
  agentCreatePropertyFail,
  agentUploadSoIntent,
  agentUploadSoIntentSuccess,
  agentUploadSoIntentFail,
  statistics,
  statisticsSuccess,
  statisticsFail,
  getToConfirmProperty,
  getToConfirmPropertySuccess,
  getToConfirmPropertyFail,
  setConfirmProperty,
  setConfirmPropertySuccess,
  setConfirmPropertyFail,
  setEditProperty,
  setEditPropertySuccess,
  setEditPropertyFail,
  deleteProperty,
  deletePropertySuccess,
  deletePropertyFail,
  agentGetState,
  agentGetStateSuccess,
  agentGetStateFail,
  agentRequestActivate,
  agentRequestActivateSuccess,
  agentRequestActivateFail,
} from '../actions'

import { errorhandler } from '../api'

import { apiEndpoints } from '../../utils/api/endpoints'

export function requestSearchAgent(params, type) {
  let ENDPOINT =
    type === 'agency' ? apiEndpoints.SEARCH_AGENCY : apiEndpoints.SEARCH_AGENTS
  return (dispatch) => {
    dispatch(agentSearch(params))
    POST(ENDPOINT, params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentSearchSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentSearchFail(errorhandler(error)))
      })
  }
}

export function requestAgentAgencyData(params) {
  return (dispatch) => {
    dispatch(agentAgencyDataRequest(params))
    FETCH(apiEndpoints.AGENT_AGENCY_LIST, params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentAgencyDataRequestSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentAgencyDataRequestFail(errorhandler(error)))
      })
  }
}

export function requestAgentDetails(params) {
  return (dispatch) => {
    dispatch(agentGetDetails(params))
    FETCH(apiEndpoints.AGENT_GET_DETAILS + params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentGetDetailsSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentGetDetailsFail(errorhandler(error)))
      })
  }
}

export function requestAgentSetRating(params) {
  return (dispatch) => {
    dispatch(agentSetRating(params))
    POST(apiEndpoints.AGENT_RATING + params.id + '/rate', params.request)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentSetRatingSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentSetRatingFail(errorhandler(error)))
      })
  }
}
export function requestAgentGetProperties() {
  return (dispatch) => {
    dispatch(agentGetProperties())
    POST(apiEndpoints.AGENT_PROPERTIES)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentGetPropertiesSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentGetPropertiesFail(errorhandler(error)))
      })
  }
}

export function requestAgentGetPropertiesV2(params) {
  return (dispatch) => {
    dispatch(agentGetProperties())
    FETCH(
      apiEndpoints.AGENT_PROPERTIESV2 + `?agentReference=${params.agentReference}`,
      params
    )
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentGetPropertiesSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentGetPropertiesFail(errorhandler(error)))
      })
  }
}

export function requestAgentSetPauseProperty(params) {
  return (dispatch) => {
    dispatch(agentSetPauseProperty(params))
    POST(apiEndpoints.PROPERTY_PAUSE, params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentSetPausePropertySuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentSetPausePropertyFail(errorhandler(error)))
      })
  }
}

export function requestAgentCreateProperty(params) {
  return (dispatch) => {
    dispatch(agentCreateProperty(params))
    POST(apiEndpoints.PROPERTY_CREATE, params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentCreatePropertySuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentCreatePropertyFail(errorhandler(error)))
      })
  }
}
export function requestUploadStatementOfIntent(params) {
  return (dispatch) => {
    dispatch(agentUploadSoIntent(params))
    POST(apiEndpoints.UPLOAD_SO_INTENT, params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentUploadSoIntentSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentUploadSoIntentFail(errorhandler(error)))
      })
  }
}

export function requestGetToConfirmProperty(params) {
  return (dispatch) => {
    dispatch(getToConfirmProperty(params))
    FETCH(apiEndpoints.CONFIRM_PROPERTY + params.target + '/' + params.id, params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(getToConfirmPropertySuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(getToConfirmPropertyFail(errorhandler(error)))
      })
  }
}
export function requestSetConfirmProperty(params) {
  return (dispatch) => {
    dispatch(setConfirmProperty(params))
    POST(apiEndpoints.APPROVE_PROPERTY + params.target + '/' + params.id, {
      is_approved: params.is_approved,
    })
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(setConfirmPropertySuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(setConfirmPropertyFail(errorhandler(error)))
      })
  }
}

export function requestSetEditProperty(params) {
  return (dispatch) => {
    dispatch(setEditProperty(params))
    PUT(apiEndpoints.PROPERTY_EDIT, params)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(setEditPropertySuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(setEditPropertyFail(errorhandler(error)))
      })
  }
}

export function requestDeleteProperty(params) {
  return (dispatch) => {
    dispatch(deleteProperty(params))
    DELETE(apiEndpoints.PROPERTY_DELETE + '/' + params.id)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(deletePropertySuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(deletePropertyFail(errorhandler(error)))
      })
  }
}

export function requestStatistics() {
  return (dispatch) => {
    dispatch(statistics())
    FETCH(apiEndpoints.STATISTICS)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(statisticsSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(statisticsFail(errorhandler(error)))
      })
  }
}

export function requestAgentState(params) {
  return (dispatch) => {
    dispatch(agentGetState(params))
    FETCH(apiEndpoints.AGENT_GET_STATE + `?user=` + params.user)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentGetStateSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentGetStateFail(errorhandler(error)))
      })
  }
}

export function requestAgentActivate(params) {
  return (dispatch) => {
    dispatch(agentRequestActivate(params))
    PUT(apiEndpoints.AGENT_ACTIVATE + `?user=` + params.user, params.body)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(agentRequestActivateSuccess(response))
        return response
      })
      .catch((error) => {
        dispatch(agentRequestActivateFail(errorhandler(error)))
      })
  }
}
