import React, { useRef, useState, useEffect } from 'react'
import TuneIcon from '@material-ui/icons/Tune'
import styled, { css } from 'styled-components'

import { bindActionCreators } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'

import Searchbar from '../../components/Mobile/Searchbar'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'

import { scrollToRef } from '../../components/Mobile/ScrollEvents'

import DynamicIcons from '../../components/Mobile/DynamicIcons'
// import CardSwipe from '../../components/Mobile/CardSwipe'
//import CardSwipe from '../../components/Web/CardSwipe'

import _ from 'lodash'

/** api redux request */
import {
  requestLikeProperties,
  requestPropertySave,
  requestPropertySaveSearch,
  requestSearchPerfectProperty,
  requestSendLikesDislikesProperties,
  requestSendSearchTallies,
} from '../../store/api'

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters,
  setPerfectproperty,
  clearPerfectProperty,
  clearLikedUnlikedProperties,
  addLikedDrillDownProperty,
  addUnlikedDrillDownProperty,
  clearLikedProperties,
  mppActions,
  searchActions,
} from '../../store/actions'

/** Object Filters */
import { formatRequest, paramsToSha1 } from '../../utils/search.utils'
import { PRICE_RANGE } from '../../constants/constants'

import {
  Button,
  Badge,
  withStyles,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core'
import BackdropInstructions from 'components/Mobile/BackdropInstructions'
import { getLikesDislikes, getMpp, getFavourites } from 'store/selectors/mpp.selectors'
import { requestPUTAPIMpp, requestGETAPIMpp } from 'store/api/mpp.api'
import {
  getAllFilters,
  getMppSearchResults,
  getPagination,
  getMppPagination,
} from 'utils/search/selectors/selectors.search'
import { useSearch } from 'utils/search/useSearch'
import CardSwipeV4 from 'components/Web/CardSwipe/CardSwipeV4'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const EndLabel = styled.p`
  position: absolute;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 24px;
  font-size: 16px;
`
const BackButton = withStyles({
  root: {},
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const styles = (theme) => ({
  root: {
    //height: window.innerHeight,
    backgroundColor: '#FFF',
  },
  content: {
    //height: '100vh',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 20,
    //backgroundColor: '#FFF',
    //position: 'relative',
    paddingRight: 10,
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  cardStackContainer: {
    maxWidth: '100vw',
    height: '38.125em',
    position: 'relative',
  },
  swipeMessageContainer: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  messageText: {
    color: '#4D4D4D',
    fontSize: '0.950em',
  },
  buttonViewContainer: {
    textAlign: 'center',
    paddingBottom: 10,
  },
  buttonView: {
    borderRadius: 30,

    textTransform: 'none',
    '&:hover': {},
  },
})

let timeout = null

function PerfectProperty(props) {
  const {
    classes,
    authentication,
    settings,
    property,
    filters,
    toggleSignUp,
    toggleFilter,
    saveProperty,
    savePropertySearch,
    searchPerfectProperty,
    setCurrentProperty,
    setPropertySearch,
    clearCurrentProperty,
    setIncludeNearbyFilter,
    setSearchFilters,
    setSearchSortingFilters,
    clearPerfectPropertySelected,
    addLikedDrillDown,
    addUnlikedDrillDown,
    getLikedProperties,
    likedPropertiesClear,
    users,
  } = props

  const topContainer = useRef(null)

  //add `false` as second parameter to scroll without transition.
  const executeTopScroll = (d) => scrollToRef(topContainer, d)

  const searchProperties = useSelector(getMppSearchResults)
  const globalFilters = useSelector(getAllFilters)
  const GET_LIKES_DISLIKES = useSelector(getLikesDislikes)
  const GET_MPP = useSelector(getMpp)
  const dispatch = useDispatch()
  const [isLikesDislikesSubmit, setIsLikesDislikesSubmit] = useState(false)
  const GET_USER_LIKES_DISLIKES = useSelector(getFavourites)

  const [isFiltered, setIsFiltered] = useState({ isActive: false, suburbs: null })
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [searchResultProperties, setSearchResultProperties] = useState([])
  const [initialProperties, setInitialProperties] = useState(null)
  const [combinedLikes, setCombinedLikes] = useState([])
  //const [pagination, setPagination] = useState(defaultPagination)
  const paginationV2 = useSelector(getMppPagination)
  const [isEnd, setIsEnd] = useState(false)
  const [savedLikedProperties, setSavedLikedProperties] = useState(null)
  const [initializeSearch] = useSearch()

  const [renderedPropertyCards, setRenderedPropertyCards] = useState([])
  const [allPropertyCards, setAllPropertyCards] = useState([])

  const [likeCounter, setLikeCounter] = useState(0)

  const [likeDislikeHandler, setLikeDislikeHandler] = useState({
    initiateLikeDisliked: () => {},
  })

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const handleSubmitLikesDislikes = () => {
    if (authentication?.user_status === 'logged') {
      setIsLikesDislikesSubmit(true)
      apiPutLikesDislikesProperties()
    } else {
      routeChange('/login', {
        origin: '/favourites',
      })
    }
  }

  // Removes previous liked and dislike properties on the search resutls
  const handleGetLikesDislikes = () => {
    let refLiked
    if (authentication?.user_status !== 'logged') {
      refLiked = GET_LIKES_DISLIKES
    } else {
      refLiked = {
        likes: [
          ...(GET_USER_LIKES_DISLIKES?.data?.likes?.map(
            (property) => property.property
          ) ?? []),
          ...(GET_LIKES_DISLIKES?.likes ?? []),
        ],
        dislikes: [
          ...(GET_USER_LIKES_DISLIKES?.data?.dislikes?.map(
            (property) => property.property
          ) ?? []),
          ...(GET_LIKES_DISLIKES?.dislikes ?? []),
        ],
      }
    }

    return refLiked
  }

  const handlePaginationV2 = () => {
    // force rerender swipe component in order to get new swiped properties

    setRenderedPropertyCards([])

    if (allPropertyCards.length - paginationV2?.pageSize <= 0) {
      const likedDislikedProperties = handleGetLikesDislikes()

      initializeSearch({
        likes: likedDislikedProperties.likes,
        dislikes: likedDislikedProperties.dislikes,
      })
    }
  }

  const apiPutLikesDislikesProperties = () => {
    let request = {
      query: null,
      data: {
        propertyPreference: {
          ...GET_LIKES_DISLIKES,
          action: 'add',
        },
      },
    }
    if (GET_LIKES_DISLIKES.likes.length > 0 || GET_LIKES_DISLIKES.dislikes.length > 0) {
      dispatch(requestPUTAPIMpp(request, 'PUT_MPP_LIKES_DISLIKES', 'REQUEST'))
    } else {
      routeChange('/favourites', {
        origin: '/perfect-property',
      })
    }
  }

  const apiGETUserFavourites = () => {
    let request = {
      query: null,
      data: null,
    }
    dispatch(requestGETAPIMpp(request, 'GET_MPP_FAVOURITES', 'REQUEST'))
  }

  const clearUserFavourites = () => {
    dispatch(mppActions(null, 'GET_MPP_FAVOURITES', 'CLEAR'))
  }

  const countLikedProperties = () => {
    if (authentication?.user_status === 'logged') {
      const likedproperties = GET_USER_LIKES_DISLIKES?.data?.likes?.map(
        (property) => property.property
      )

      if (likedproperties && GET_LIKES_DISLIKES?.likes) {
        const uniqueLikedProperties = [
          ...new Set([...likedproperties, ...GET_LIKES_DISLIKES.likes]),
        ]

        return uniqueLikedProperties.length + likeCounter
      }

      return '0'
    }

    return GET_LIKES_DISLIKES?.likes?.length + likeCounter
      ? GET_LIKES_DISLIKES?.likes?.length + likeCounter
      : '0'
  }

  //   React.useEffect(() => {
  //     if (GET_LIKES_DISLIKES?.likes?.length > 0) {
  //       //change to GET_LIKES_DISLIKES?.likes
  //       setCombinedLikes(GET_LIKES_DISLIKES?.likes)
  //     }

  //     if (GET_USER_LIKES_DISLIKES?.data) {
  //       const mergedLikesDislikes = [
  //         ...(GET_USER_LIKES_DISLIKES?.data?.likes ?? []),
  //         ...(GET_USER_LIKES_DISLIKES?.data?.dislikes ?? []),
  //       ]
  //       setSavedLikedProperties(mergedLikesDislikes)
  //     }
  //   }, [GET_LIKES_DISLIKES, GET_USER_LIKES_DISLIKES])

  /* 
  handle properties callback */

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const defaultSortData = (data) => {
    const propertyData = Object.assign({}, data)
    const propertiesFilters = Object.assign([], propertyData.data)
    const sortedByScore = sortData(propertiesFilters, ['score'], ['desc'])
    const sortedData = sortData(sortedByScore, ['subscriptionTypeWeight'], ['asc'])

    propertyData.data = propertiesFilters
    const pageData = {
      pageNumber: paginationV2.pageNumber,
      pageSize: paginationV2.pageSize,
      propertiesFilters: paginate(
        propertiesFilters,
        paginationV2.pageSize,
        paginationV2.pageNumber
      ),
      totalResults: propertiesFilters.length,
    }
    setSearchResultProperties(propertyData)
    //setPagination(pageData)
    dispatch(mppActions(pageData, 'MPP_PAGINATION_REFERENCE', 'UPDATE'))
  }

  const handleSortBy = (data, propertiesData) => {
    setSearchSortingFilters(data)
    //const propertyData = Object.assign({}, property.perfect_properties.data)
    const propertyData = Object.assign({}, searchProperties?.search_results?.data)
    const propertiesFilters = Object.assign([], propertyData.data)
    let type = _.filter(data, function (o) {
      return o.active
    })
    let sortedData = propertiesFilters
    if (data && type && type.length > 0) {
      switch (type[0].value) {
        case 'Relevance':
          sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['desc'])
          break
        case 'Newest':
          sortedData =
            getRoutePath() === 'sold'
              ? sortData(propertiesFilters, ['soldDate'], 'desc')
              : sortData(propertiesFilters, ['publishDate'], 'desc')
          break
        case 'Price (High to Low)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                  propertiesFilters,
                  ['rentalPrice', 'publishDate'],
                  ['desc', 'desc']
                )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['desc', 'desc'])
          break
        case 'Price (Low to High)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                  propertiesFilters,
                  ['rentalPrice', 'publishDate'],
                  ['asc', 'asc']
                )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['asc', 'asc'])
          break
        case 'Bedrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bedrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Bathrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bathrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Sqm':
          sortedData = sortData(
            propertiesFilters,
            ['landSize', 'price'],
            ['desc', 'desc']
          )
          break
        default:
          sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['desc'])
      }
      propertyData.data = sortedData
      setSearchResultProperties(propertyData)

      const pageData = {
        pageNumber: paginationV2.pageNumber,
        pageSize: paginationV2.pageSize,
        propertiesFilters: paginate(
          sortedData,
          paginationV2.pageSize,
          paginationV2.pageNumber
        ),
        totalResults: propertyData.totalResults,
      }
      //setPagination(pageData)
      dispatch(searchActions(pageData, 'PAGINATION_REFERENCE', 'UPDATE'))
    }
  }

  const sortData = (data, type, order) => {
    const sortBy = type
    return _.orderBy(
      data,
      sortBy.map((s) => {
        return (r) => {
          return r[s] ? r[s] : ''
        }
      }),
      order
    )
  }

  const paginate = (array, page_size, page_number) => {
    const res = array
      ?.slice(page_number * page_size, page_number * page_size + page_size)
      .reverse()
    return res
  }

  /**
   * Save Property API */
  function apiRequestSaveProperty(id) {
    let params = {
      propertyId: id,
    }
    saveProperty(params)
  }

  /**
   * Save Property Search API */
  function apiRequestSaveSearch(params) {
    setIsLoading(true)

    savePropertySearch(filters.suburbs)
  }

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
    } else {
      /** User Logged */
      routeChange('/favourites')
    }
  }

  const handleSaveLikedProperties = () => {
    likedPropertiesClear()
    clearPerfectPropertySelected()
    setInitialProperties(null)
    if (authentication && authentication.user_status !== 'logged') {
      // toggleSignUp(!settings.showSignUp);
      routeChange('/login', {
        origin: '/favourites',
      })
      //routeChange('/authenticate')
    } else {
      /** User Logged */
      routeChange('/favourites')
    }
  }

  function routeChange(path, state) {
    props.history.push(path, state)
  }

  function saveFilters(params) {
    if (filters && filters.filters) {
      let obj = Object.assign({}, filters.filters)
      if (params) {
        obj.propertyDetails.bedrooms = params[0].selected
        obj.propertyDetails.bathrooms = params[2].selected
        obj.propertyDetails.price.min = params[1].values[0].value.min
        obj.propertyDetails.price.max = params[1].values[0].value.max
        if (params[3].values) {
          obj.propertyType = params[3].values
          obj.propertyType.map((item, index) => {
            item.active = params[3].values[index].active
          })
        }
        setSearchFilters(obj)
      }
    }
  }

  const getLikedCounter = (data) => {
    if (authentication?.token) {
      if (data?.length && GET_USER_LIKES_DISLIKES?.data?.likes?.length) {
        let user_likes = GET_USER_LIKES_DISLIKES?.data?.likes?.map((i) => i.property)
        const combinedLikes = [...data, ...user_likes]
        const mergedUserLikes = combinedLikes.filter((data, index) => {
          return combinedLikes.indexOf(data) === index
        })
        return mergedUserLikes.length
        // previous counter
        //return data.length + GET_USER_LIKES_DISLIKES?.data?.likes?.length
      } else if (data?.length && GET_USER_LIKES_DISLIKES?.data?.likes?.length <= 0) {
        return data?.length ?? 0
      } else if (!data?.length && GET_USER_LIKES_DISLIKES?.data?.likes?.length) {
        return GET_USER_LIKES_DISLIKES?.data?.likes?.length ?? 0
      } else {
        return data?.length ?? 0
      }
    } else {
      return data?.length
    }
  }

  //   React.useEffect(() => {
  //     /**
  //      * Initial Scroll to Top */
  //     executeTopScroll(false)

  //     /**
  //      * Clear Last Selected Property */
  //     clearCurrentProperty()
  //     likedPropertiesClear()
  //     clearPerfectPropertySelected()

  //     /**
  //      * Request Property Call */
  //     if (initialProperties === null) {
  //       //clear data and reducer here
  //       dispatch(mppActions(null, 'PERFECT_PROPERTY_RECOMMENDATION', 'CLEAR'))
  //       dispatch(mppActions(null, 'MPP_PAGINATION_REFERENCE', 'CLEAR'))
  //       initializeSearch(handleGetLikesDislikes())
  //     }

  //     /**
  //      * Clear Number of Likes and Unlike */
  //     //clearLikeAndUnlike();
  //     return () => {
  //       likedPropertiesClear()
  //       clearPerfectPropertySelected()
  //       setInitialProperties(null)
  //     }
  //   }, [])

  //   useEffect(() => {
  //     return () => {
  //       dispatch(mppActions(null, 'PERFECT_PROPERTY_RECOMMENDATION', 'CLEAR'))
  //       dispatch(mppActions(null, 'MPP_PAGINATION_REFERENCE', 'CLEAR'))
  //     }
  //   }, [])

  useEffect(() => {
    // make local like counter to 0
    setLikeCounter(0)
  }, [GET_LIKES_DISLIKES, GET_USER_LIKES_DISLIKES])

  useEffect(() => {
    if (searchProperties?.status === 'mpp/PERFECT_PROPERTY_RECOMMENDATION_REQUEST') {
      setIsLoading(true)
    } else if (
      searchProperties?.status === 'mpp/PERFECT_PROPERTY_RECOMMENDATION_SUCCESS'
    ) {
      setIsLoading(false)

      if (searchProperties?.mpp_search_results?.data) {
        const likedDislikedProperties = [
          ...handleGetLikesDislikes().likes,
          ...handleGetLikesDislikes().dislikes,
        ]

        const filteredLikesDislikes = searchProperties.mpp_search_results.data.filter(
          (property) => !likedDislikedProperties.includes(property.property)
        )

        filteredLikesDislikes.length > 0 ? setIsEnd(false) : setIsEnd(true)
      }
    }
  }, [searchProperties])

  useEffect(() => {
    if (searchProperties?.mpp_search_results?.data?.length > 0) {
      const likedDislikedProperties = [
        ...handleGetLikesDislikes().likes,
        ...handleGetLikesDislikes().dislikes,
      ]

      const filteredLikesDislikes = searchProperties.mpp_search_results.data.filter(
        (property) => !likedDislikedProperties.includes(property.property)
      )
      // const slicedArray = array.slice(0, n)

      setAllPropertyCards([...filteredLikesDislikes])
      setRenderedPropertyCards([...filteredLikesDislikes.slice(0, 10)])
    }
  }, [searchProperties])

  React.useEffect(() => {
    if (authentication && authentication.user_status === 'logged') {
      // likedPropertiesClear()
      clearUserFavourites()
      // getLikedProperties({ take: 1000, skip: 0 })
      apiGETUserFavourites()
    }
  }, [authentication])

  /* 
    handle properties callback */
  React.useEffect(() => {
    /**
     * error hanlder mpp property */
    if (searchProperties?.mpp_search_results && searchProperties.error && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }
  }, [searchProperties?.mpp_search_results])

  //Clear redux state
  useEffect(() => {
    triggerAnalyticsEvent({
      event: 'mpp_result_actions',
      eventFrom: 'Mobile',
      actionScope: 'mpp_recommendation_results',
      actionName: 'MPP Recommendations',
      actionTarget: 'Showed MPP recommendation results',
      actionReference: 'mpp_result',
    })
    return () => {
      if (authentication && authentication.user_status === 'logged') {
        dispatch(mppActions(null, 'LOCAL_MPP_LIKES_DISLIKES', 'CLEAR'))
      }
      clearUserFavourites()
    }
  }, [])

  window.addEventListener('unload', function (e) {
    dispatch(mppActions(null, 'PERFECT_PROPERTY_RECOMMENDATION', 'CLEAR'))
    dispatch(mppActions(null, 'MPP_PAGINATION_REFERENCE', 'CLEAR'))
  })

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <div ref={topContainer} className={classes.topAnchor}></div>

      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routeChange('/')}
        >
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
        <IconButton onClick={() => routeChange('/perfect-property/filters')}>
          <Badge badgeContent={null} color="primary">
            <TuneIcon className={classes.iconTune} />
          </Badge>
        </IconButton>
      </div>

      {/* {!isLoading && (
        <div className={classes.buttonViewContainer}>
          <Badge badgeContent={getLikedCounter(combinedLikes)} color="secondary">
            <Button
              className={classes.buttonView}
              variant="contained"
              color={'primary'}
              onClick={() => handleSubmitLikesDislikes()}
            >
              View favourite properties
            </Button>
          </Badge>
        </div>
      )} */}

      <Searchbar
        type={'search'}
        sortType={'perfect-property'}
        showSearchBar={false}
        showFilters={false}
        routeChange={routeChange}
        authentication={authentication}
        settings={settings}
        toggleSignUp={toggleSignUp}
        toggleFilter={toggleFilter}
        apiRequestSaveSearch={apiRequestSaveSearch}
        setPropertySearch={setPropertySearch}
        setIncludeNearbyFilter={setIncludeNearbyFilter}
        setSearchSortingFilters={handleSortBy}
        filters={filters}
        saveFilters={saveFilters}
        properties={paginationV2}
        isFiltered={isFiltered}
      />

      <BackdropInstructions />

      {!isLoading && renderedPropertyCards.length > 0 && !isEnd && (
        <>
          <div className={classes.buttonViewContainer}>
            <Badge badgeContent={countLikedProperties()} color="secondary">
              <Button
                className={classes.buttonView}
                variant="contained"
                onClick={() => {
                  triggerAnalyticsEvent({
                    event: 'mpp_result_actions',
                    eventFrom: 'Mobile',
                    actionScope: 'save_and_view_favourites',
                    actionName: 'Save and View Favorites',
                    actionTarget: 'Save current likes and dislikes',
                    actionReference: 'mpp_result',
                    mpp_current_dislikes:
                      GET_LIKES_DISLIKES?.dislikes?.length ?? undefined,
                    mpp_current_likes: GET_LIKES_DISLIKES?.likes?.length ?? undefined,
                  })
                  if (authentication && authentication.user_status === 'logged') {
                    likeDislikeHandler.initiateLikeDisliked()
                    setTimeout(() => {
                      routeChange('/favourites')
                    }, 1000)
                  } else {
                    routeChange('/login', {
                      origin: '/favourites',
                    })
                  }
                }}
                color={'primary'}
                // disabled={!combinedLikes || combinedLikes.length === 0 }
              >
                View favourite properties
              </Button>
            </Badge>
          </div>
          <CardSwipeV4
            authentication={authentication}
            filters={filters}
            pagination={renderedPropertyCards}
            setCurrentProperty={setCurrentProperty}
            setLikeDislikeHandler={setLikeDislikeHandler}
            // totalProperties={
            //   searchResultProperties &&
            //   searchResultProperties.data &&
            //   searchResultProperties.data.length
            // }
            // settings={settings}
            // clearPerfectPropertySelected={clearPerfectPropertySelected}
            // apiRequestSaveProperty={apiRequestSaveProperty}
            // routeChange={routeChange}
            // handlePagination={handlePagination}
            handlePaginationV2={handlePaginationV2}
            // addLikedDrillDown={addLikedDrillDown}
            // //property={property}
            // addUnlikedDrillDown={addUnlikedDrillDown}
            listingType={getRoutePath()}
            // cardEvent={LikeDislikeCard}

            setLikeCounter={setLikeCounter}
          />
        </>
      )}

      {isEnd && (
        <Box marginTop="10%" display="flex" flexDirection="column" alignItems="center">
          <Typography style={{ marginBottom: '25px' }}>
            No more properties to swipe.
          </Typography>
          <Badge
            style={{ marginBottom: '10px' }}
            badgeContent={countLikedProperties()}
            color="secondary"
          >
            <Button
              className={classes.buttonView}
              variant="contained"
              onClick={() => {
                triggerAnalyticsEvent({
                  event: 'mpp_result_actions',
                  eventFrom: 'Mobile',
                  actionScope: 'mpp_end_view_likes',
                  actionName: 'MPP End',
                  actionTarget: 'View Likes',
                  actionReference: 'mpp_result',
                })
                if (authentication && authentication.user_status === 'logged') {
                  likeDislikeHandler.initiateLikeDisliked()
                  setTimeout(() => {
                    routeChange('/favourites')
                  }, 1000)
                } else {
                  routeChange('/login', {
                    origin: '/favourites',
                  })
                }
              }}
              color={'primary'}
              // disabled={!combinedLikes || combinedLikes.length === 0 }
            >
              View favourite properties
            </Button>
          </Badge>
          <Button
            style={{}}
            className={classes.buttonView}
            variant="contained"
            color={'secondary'}
            onClick={() => {
              triggerAnalyticsEvent({
                event: 'mpp_result_actions',
                eventFrom: 'Mobile',
                actionScope: 'mpp_end_view_dislikes',
                actionName: 'MPP End',
                actionTarget: 'View Dislikes',
                actionReference: 'mpp_result',
              })
              if (authentication?.user_status === 'logged') {
                routeChange('/disliked-properties')
              } else {
                routeChange('/login', {
                  origin: '/disliked-properties',
                })
              }
            }}
          >
            Review disliked properties
          </Button>
        </Box>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      saveProperty: (params) => requestPropertySave(params),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchPerfectProperty: (params) => requestSearchPerfectProperty(params),
      saveLikesDislikes: (params) => requestSendLikesDislikesProperties(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      saveSearchTallies: (params) => requestSendSearchTallies(params),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setSearchSortingFilters: (params) => setSortingFilters(params),
      setPerfectPropertySelected: (params) => setPerfectproperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      clearPropertySuburb: () => clearSuburbSearch(),
      clearPerfectPropertySelected: () => clearPerfectProperty(),
      clearLikeAndUnlike: () => clearLikedUnlikedProperties(),
      addLikedDrillDown: (params) => addLikedDrillDownProperty(params),
      addUnlikedDrillDown: (params) => addUnlikedDrillDownProperty(params),
      getLikedProperties: (params) => requestLikeProperties(params),
      likedPropertiesClear: () => clearLikedProperties(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PerfectProperty))
