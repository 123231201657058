import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'

import HeartIcon from '@material-ui/icons/FavoriteBorder'
import HeartFullIcon from '@material-ui/icons/Favorite'
import CheckIcon from '@material-ui/icons/Check'

import Image from 'material-ui-image'

import AgentBanner from './AgentBanner'
import PropertyInfo from './PropertyInfo'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import styled from 'styled-components'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'
import {
  Button,
  withStyles,
  Card,
  CardContent,
  Typography,
  IconButton,
} from '@material-ui/core'
import ImageSelectorDialog from '../CustomDialogs/ImageSelectorDialog'
import { useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { DEFAULT_DOOR_URLS } from 'constants/constants'

const CarouselWrapper = styled.div`
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
`

const DotsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  .BrainhubCarousel__dots {
    .BrainhubCarousel__dot {
      padding: 20px 5px;
      &:before {
        background: #fff;
        width: 7px;
        height: 7px;
      }
    }
  }
`

const styles = (theme) => ({
  cardFull: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 0,
    minHeight: 300,
    backgroundColor: '#FFF',
  },
  cardPadded: {
    margin: theme.spacing(3),
    marginLeft: 'auto',
    marginRight: 'auto',
    // width: window.innerWidth - (theme.spacing(3) * 2),
    // maxHeight: '31.125em',
    backgroundColor: '#FFF',
  },
  cardPaddedSwipe: {
    margin: theme.spacing(3),
    width: window.innerWidth - theme.spacing(3) * 2,
    height: '35.125em',
    backgroundColor: '#FFF',
  },
  media: {
    width: '100%',
    height: '28.571em',
    objectFit: 'cover !important',
  },
  favoriteContainer: {
    width: '1.429em',
    height: '1.429em',
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
  tagContainer: {
    position: 'relative',
  },
  tagTypeText: {
    color: '#FFF',
    fontSize: '0.750em',
    fontWeight: 'bold',
  },
  tagTypeAuction: {
    width: '13.929em',
    height: '1.786em',
    backgroundColor: 'rgba(6, 18, 82, 0.8)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagTypeComingSoon: {
    width: '13.929em',
    height: '1.786em',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagTypeOffMarket: {
    width: '13.929em',
    height: '1.786em',
    backgroundColor: '#F50057',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textLabelCloseTo: {
    fontSize: '0.875em',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  chipText: {
    fontSize: 10,
  },
  chipIcon: {
    width: 10,
    height: 10,
  },

  rowContainerCenter: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  rowContainerEven: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  chipContainer: {
    minWidth: '3.750em',
    height: '1.300em',
    paddingRight: '0.425em',
    paddingLeft: '0.425em',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    alignItems: 'center',
    border: ' 0.5px solid #35C0CA',
    borderRadius: '30px',
    backgroundColor: '#FFFFFF',
    marginBottom: '0.425em',
    marginRight: '0.125em',
  },
  chooseImageBtn: {
    borderRadius: theme.spacing(3),
    position: 'absolute',
    left: theme.spacing(1),
    bottom: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    fontSize: 11,
    textTransform: 'capitalize',
    backgroundColor: theme.palette.common.white,
  },
})

const arrowStyles = {
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 20px)',
  width: 60,
  height: 60,
  cursor: 'pointer',
}

const iconStyles = {
  width: '100%',
  height: '100%',
}

const UploadPropertyCard = forwardRef((props, ref) => {
  const {
    classes,
    isFullWidth,
    properties,
    property,

    authentication,
    settings,
    toggleSignUp,
    setCurrentProperty,
    apiRequestSaveProperty,
    cardType,
    addLikedDrillDown,
    addUnlikedDrillDown,
    listingType,
    setCoverCallback,
    enableCoverSelector,
    setCoverSelected,
    handleFieldChange,
  } = props

  const [value, setValue] = useState(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const [selectCoverToggle, setSelectCoverToggle] = useState(false)
  const placeholderimages = DEFAULT_DOOR_URLS
  const [defaultImages, setDefaultImages] = useState()

  useEffect(() => {
    if (properties) {
      const getTransformations =
        properties.propertyStatus === 'comingsoon'
          ? 'e_grayscale'
          : 'e_colorize:60,co_rgb:35C0CA'
      const imgBucket = []
      placeholderimages.map((item, i) => {
        imgBucket.push(item.replace('XTRANSFORMX', getTransformations))
      })
      setDefaultImages(imgBucket)
    }
  }, [])

  const MPP = useSelector(getMpp)
  let likes = MPP?.favourites?.data?.likes

  const handleClose = (value) => {
    //setValue(value);

    setSelectCoverToggle(false)
  }

  const handleConfirm = (value) => {
    //setValue(value);

    console.log(value, 'Selected Image')
    setCoverSelected(value)
    handleFieldChange('propertyInfo', null, 'customCover', true)
    setSelectCoverToggle(false)
  }

  // useImperativeHandle(ref, () => ({
  //   /**
  //    * set property on card swipe selected */
  //   selectProperty(params){
  //     /**
  //      * Add to Drill Down Property */
  //     property.liked_drill_down_properties.push(params)
  //     addLikedDrillDown(property.liked_drill_down_properties);

  //     /**
  //      * Set current property */
  //     setCurrentProperty(params);
  //     routerChange('/'+ listingType +'/' + params.property, { previous_screen: listingType });
  //   }
  // }));

  /* function selectProperty(willScroll){
    if(!isSwiping) {
      setCurrentProperty(properties);
      if(willScroll){
        routerChange('/'+ listingType +'/' + properties.property, { scrollToAgent: true, previous_screen: props.listingType });
      } else {
        routerChange( '/'+ listingType +'/' + properties.property, { previous_screen: props.listingType });
      }
    }
  } */

  function renderTypeTag(type, property) {
    let propertyStatus = type.toLowerCase().replace(/[^\w\s]/gi, '')
    let isForRent = property.forRent ? true : false
    let conditionCheck = propertyStatus + ':' + isForRent
    const auctionDate = property.auctionDate
      ? moment(property.auctionDate).format('Do MMMM YYYY')
      : ''
    const soldDate = property.soldDate
      ? moment(property.soldDate).format('Do MMMM YYYY')
      : ''
    switch (conditionCheck) {
      case 'onsale:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {'Auction ' + auctionDate}
            </Typography>
          </div>
        )
      case 'current:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {'Auction ' + auctionDate}
            </Typography>
          </div>
        )
      case 'sold:false':
        return (
          <div className={classes.tagTypeAuction}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {'Sold ' + soldDate}
            </Typography>
          </div>
        )
      case 'comingsoon:false':
        return (
          <div className={classes.tagTypeComingSoon}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              Coming Soon Property
            </Typography>
          </div>
        )
      case 'offmarket:false':
        return (
          <div className={classes.tagTypeOffMarket}>
            <Typography
              className={classes.tagTypeText}
              variant="caption"
              color="textSecondary"
              component="p"
            >
              Off Market Property
            </Typography>
          </div>
        )
    }
  }

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
    } else {
      /** User Logged */
      apiRequestSaveProperty(properties.property)
    }
  }

  function renderForListCard() {
    return (
      <Card elevation={1} className={classes.cardPadded}>
        <div className={classes.tagContainer}>
          {properties &&
            properties.propertyStatus !== 'offmarket' &&
            properties.images &&
            properties.images.length > 1 ? (
            <CarouselWrapper>
              <Carousel
                showArrows={true}
                showIndicators={false}
                showStatus={false}
                infiniteLoop={false}
                showThumbs={false}
                useKeyboardArrows={false}
                autoPlay={false}
                swipeable={true}
                emulateTouch={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, left: 0, color: '#fff' }}
                    >
                      <ChevronLeftIcon style={{ ...iconStyles }} />
                    </IconButton>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <IconButton
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, right: 0, color: '#fff' }}
                    >
                      <ChevronRightIcon style={{ ...iconStyles }} />
                    </IconButton>
                  )
                }
                onSwipeStart={() => {
                  setIsSwiping(true)
                }}
                onSwipeEnd={() => {
                  setIsSwiping(false)
                }}
              >
                {properties.images &&
                  properties.images.map((img, index) => {
                    return (
                      <Image
                        style={{ width: '100%' }}
                        key={index}
                        className={classes.media}
                        src={propertyConfigSetter('image-banner-card', {
                          type: properties.propertyStatus,
                          image: img,
                        })}
                        // onClick={()=> selectProperty() }
                        aspectRatio={16 / 9}
                        disableSpinner
                      />
                    )
                  })}
              </Carousel>
            </CarouselWrapper>
          ) : properties &&
            properties.propertyStatus === 'offmarket' &&
            properties.images &&
            properties.images.length > 0 ? (
            <Image
              style={{ width: '100%' }}
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: properties.images[0],
              })}
              // onClick={()=> selectProperty() }
              aspectRatio={16 / 9}
              disableSpinner
            />
          ) : properties && properties.images && properties.images.length === 1 ? (
            <Image
              style={{ width: '100%' }}
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: properties.images[0],
              })}
              // onClick={()=> selectProperty() }
              aspectRatio={16 / 9}
              disableSpinner
            />
          ) : (
            <div></div>
          )}
          {properties.propertyStatus &&
            renderTypeTag(properties.propertyStatus, properties)}
          <IconButton
            className={classes.favoriteContainer}
          //onClick={()=> checkLoginStatus()}
          >
            {authentication &&
              authentication.user_status === 'logged' &&
              likes &&
              likes?.includes(properties?.property) ? (
              <HeartFullIcon className={classes.favoriteIconActive} />
            ) : (
              <HeartIcon className={classes.favoriteIcon} />
            )}
          </IconButton>
          {enableCoverSelector && (
            <Button
              variant={'contained'}
              className={classes.chooseImageBtn}
              onClick={() => setSelectCoverToggle(true)}
            >
              Select Cover Image
            </Button>
          )}
        </div>
        {
          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />
        }
        <CardContent>
          <PropertyInfo
            type={'search'}
            properties={properties}

            //selectProperty={selectProperty}
            matchCount={props.handleMatchCount(properties)}
            listingType={listingType}
          />
        </CardContent>
      </Card>
    )
  }

  function renderForSwipeCard() {
    return (
      <Card elevation={1} className={classes.cardPaddedSwipe}>
        <div className={classes.tagContainer}>
          {properties && properties.images && properties.images.length > 0 && (
            <Image
              className={classes.media}
              src={propertyConfigSetter('image-banner-card', {
                type: properties.propertyStatus,
                image: properties.images[0],
              })}
              // onClick={()=> selectProperty() }
              aspectRatio={16 / 9}
              disableSpinner
            />
          )}
          {properties.propertyStatus &&
            renderTypeTag(properties.propertyStatus, properties)}
          {/* <IconButton 
            className={classes.favoriteContainer} 
            onClick={()=> checkLoginStatus()}>
              {
                properties && properties.favorite && properties.favorite ?
                  <HeartFullIcon className={classes.favoriteIconActive}/>
                :
                  <HeartIcon className={classes.favoriteIcon}/>
              }
          </IconButton> */}
        </div>
        {
          <AgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />
        }
        <CardContent>
          <PropertyInfo
            type={'search'}
            properties={properties}

          //selectProperty={selectProperty}
          />
        </CardContent>
        <div className={classes.rowContainerCenter}>
          {properties.coefficients.close_to_cafes >= 0.7 ||
            properties.coefficients.close_to_parks >= 0.7 ||
            properties.coefficients.close_to_shops >= 0.7 ||
            properties.coefficients.close_to_schools >= 0.7 ||
            properties.coefficients.close_to_universities >= 0.7 ||
            properties.coefficients.foody_area >= 0.7 ||
            properties.coefficients.inner_city_vibe >= 0.7 ||
            properties.coefficients.close_to_public_transports >= 0.7 ||
            properties.coefficients.close_to_secondary_schools >= 0.7 ? (
            <>
              <div style={{ width: '25%', paddingLeft: 5 }}>
                <div className={classes.rowContainerCenter}>
                  <p className={classes.textLabelCloseTo}>Close to:</p>
                </div>
                <div className={classes.rowContainerCenter}>
                  <p></p>
                </div>
              </div>
              {renderSwipeCloseTo(properties)}
            </>
          ) : null}
        </div>
      </Card>
    )
  }

  function renderSwipeCloseTo(data) {
    return (
      <div style={{ width: '80%' }}>
        <div className={classes.rowContainerEven}>
          {data.coefficients.close_to_cafes >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Cafes</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_parks >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Parks</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_shops >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Shops</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_schools >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Schools</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_universities >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Universities</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.foody_area >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Foody Area</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.inner_city_vibe >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Inner City Vibe</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_public_transports >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Transports</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
          {data.coefficients.close_to_secondary_schools >= 0.7 && (
            <div className={classes.chipContainer}>
              <p className={classes.chipText}>Secondary Schools</p>
              <CheckIcon className={classes.chipIcon} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {cardType === 'list' && renderForListCard()}
      {cardType === 'swipe' && renderForSwipeCard()}

      <ImageSelectorDialog
        isOpen={selectCoverToggle}
        closeCallback={handleClose}
        confirmCallback={handleConfirm}
        showLogo={false}
        align={'center'}
        title={'Choose an image as your cover'}
        text={''}
        imageOptions={defaultImages}
      />
    </>
  )
})

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(withStyles(styles)(UploadPropertyCard))
