import React, { useState, useEffect } from 'react'
import _ from 'lodash'
/** Custom Components */
import SearchInput from './SearchInput'

import SavedSearch from './SavedSearch'
import SortSelection from './SortSelection'
import FilterOptions from './FilterOptions'
import { FilterSharp } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    //height: '100%',
    zIndex: 6,
    backgroundColor: '#FFF',
    position: 'relative',
    borderRadius: 4,
  },
}))

const Searchbar = (props) => {
  const {
    type,
    sortType,

    authentication,
    settings,
    toggleSignUp,
    toggleFilter,
    apiRequestSaveSearch,
    apiRequestSearchProperty,
    navigateFilterButtonTo,
    setPropertySearch,
    setSearchSortingFilters,
    setIncludeNearbyFilter,
    filters,
    saveFilters,
    properties,
    isFiltered,
    showSearchBar,
    showFilters,
    isFullWidth,
    trades,
    showSaveSearchModal,
    setPerfectPropertyFilter,
    toggleFilterWeb,
    path,
  } = props

  const [selectedChipValue, setSelectedChipValue] = useState([])

  /** controller */
  const [showModalOptions, setShowModalOptions] = useState(false)
  const [selectedFilterType, setSelectedFilterType] = useState('')
  const [showModalBottomOptions, setShowModalBottomOptions] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [suburbs, setSuburbs] = useState('')
  const classes = useStyles()

  useEffect(() => {
    if (isFiltered) {
      let suburb = []
      if (isFiltered.suburbs && isFiltered.suburbs.length) {
        isFiltered.suburbs.map((item, index) => {
          suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode)
        })
      }
      setSuburbs(suburb)
    }
  }, [isFiltered])

  /**
   * do not hide modal when selecting a different option
   * | close modal when selecting cancel or apply */
  function showOptionModal(selected, closeModal) {
    if (closeModal) {
      setShowModalOptions(false)
      setSelectedFilterType('')
      toggleFilter(false)
    } else {
      setShowModalOptions(true)
      toggleFilter(true)
      setSelectedFilterType(selected)
    }
  }

  function showOptionBottomModal() {
    setShowModalBottomOptions(!showModalBottomOptions)
  }

  return (
    <>
      <div className={classes.mainContainer}>
        {showSearchBar && (
          <SearchInput
            setChipValue={setSelectedChipValue}
            setIsInputFocused={setIsInputFocused}
            isInputFocused={isInputFocused}
            isFullWidth={isFullWidth}
            setPropertySearch={setPropertySearch}
            setPerfectPropertyFilter={setPerfectPropertyFilter}
            isClearFilter={props.isClearFilter}
            filters={filters}
            apiRequestSearchProperty={apiRequestSearchProperty}
            path={props.path}
            placeholder={props.placeholder}
            toggleFilterWeb={toggleFilterWeb}
            hideIcon={props.hideIcon}
          />
        )}

        {showFilters && (
          <FilterOptions
            type={type}
            navigateFilterButtonTo={navigateFilterButtonTo}

            showModalOptions={showModalOptions}
            showOptionModal={showOptionModal}
            selectedFilterType={selectedFilterType}
            isInputFocused={isInputFocused}
            setIsInputFocused={setIsInputFocused}
            settings={settings}
            toggleFilter={toggleFilter}
            isClearFilter={props.isClearFilter}
            filters={filters}
            saveFilters={saveFilters}
            setIncludeNearbyFilter={setIncludeNearbyFilter}
            apiRequestSearchProperty={apiRequestSearchProperty}
            path={props.path}
          />
        )}
      </div>

      {isFiltered &&
        isFiltered.isActive &&
        type === 'search' &&
        properties?.propertiesFilters &&
        properties.propertiesFilters.length > 0 && (
          <SavedSearch
            propertyCount={properties ? properties.totalResults : 0}
            propertySuburbs={suburbs}
            authentication={authentication}
            settings={settings}
            toggleSignUp={toggleSignUp}
            apiRequestSaveSearch={apiRequestSaveSearch}
            showSaveSearchModal={showSaveSearchModal}
          />
        )}

      {sortType === 'trades-results' && trades && trades.length > 0 ? (
        <>
          <SortSelection
            sortType={sortType}
            current={trades}
            total={trades ? trades.length : 0}
            setSearchSortingFilters={setSearchSortingFilters}
            filters={filters}
            showModalBottomOptions={showModalBottomOptions}
            showOptionBottomModal={showOptionBottomModal}
            path={path}
          />
        </>
      ) : (
        properties?.propertiesFilters &&
        properties.propertiesFilters.length > 0 && (
          <>
            <SortSelection
              sortType={sortType}
              current={
                properties
                  ? properties.propertiesFilters
                    ? properties.propertiesFilters.length
                    : 0
                  : 0
              }
              total={properties ? properties.totalResults : 0}
              setSearchSortingFilters={setSearchSortingFilters}
              filters={filters}
              showModalBottomOptions={showModalBottomOptions}
              showOptionBottomModal={showOptionBottomModal}
              path={path}
            />
          </>
        )
      )}
    </>
  )
}

export default Searchbar
