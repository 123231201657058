import { PURGE } from 'redux-persist';

import {
    LOGOUT_REQUEST,

    FACEBOOK_AUTH_REQUEST,
    FACEBOOK_AUTH_SUCCESS,
    FACEBOOK_AUTH_FAIL,

    GOOGLE_AUTH_REQUEST,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,

    APPLE_AUTH_REQUEST,
    APPLE_AUTH_SUCCESS,
    APPLE_AUTH_FAIL,

    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,

    VERIFY_REGISTRATION_REQUEST,
    VERIFY_REGISTRATION_SUCCESS,
    VERIFY_REGISTRATION_FAIL,

    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,

    CLEAR_LOGIN_STATES,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,

    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,

    RESEND_CODE_REQUEST,
    RESEND_CODE_FAIL,
    RESEND_CODE_SUCCESS,

    NAVIGATE_RESET,

    CLEAR_SESSION,
    VALIDATE_USER_TOKEN_REQUEST,
    VALIDATE_USER_TOKEN_SUCCESS,
    VALIDATE_USER_TOKEN_FAIL,

} from '../constants';

const INITIAL_STATE = {
    token: null,
    payload: null,
    login_error: null,
    registration_error: null,
    verify_registration_error: null,
    forgot_password_error: null,
    change_password_error: null,
    resend_code_error: null,
    social_error: null,
    request: {
        current: null, // registration, login, forgot-password
        previous: null, // registration, login, forgot-password
        params: null,
    },
    user_status: null,
    login_status: null, // progress, success, error
    registration_status: null, // progress, success, error
    verify_registration_status: null, // progress, success, error
    forgot_password_status: null, // progress, success, error
    change_password_status: null, // progress, success, error
    resend_code_status: null, // progress, success, error
    social_status: null, // progress, success, error
    token_validator: {
        data: null,
        status: null,
        error: null,
        reference: null
    }
}

export default function authenticationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        /** Clear Session */
        case CLEAR_SESSION:
            return {
                ...INITIAL_STATE
            }

        /** Logout */
        case LOGOUT_REQUEST:
            return {
                ...INITIAL_STATE
            }

        /** Navigate */
        case NAVIGATE_RESET:
            return {
                ...state,
                login_error: null,
                registration_error: null,
                verify_registration_error: null,
                forgot_password_error: null,
                change_password_error: null,
                login_status: null,
                registration_status: null,
                verify_registration_status: null,
                forgot_password_status: null,
                change_password_status: null,
            }

        /** Social Actions */
        case APPLE_AUTH_REQUEST:
        case GOOGLE_AUTH_REQUEST:
        case FACEBOOK_AUTH_REQUEST:
            return {
                ...state,
                social_error: null,
                social_status: 'progress',
                user_status: null,
                request: {
                    ...state.request,
                    current: 'social',
                    params: action.params
                }
            }

        case APPLE_AUTH_SUCCESS:
        case GOOGLE_AUTH_SUCCESS:
        case FACEBOOK_AUTH_SUCCESS:
            return {
                ...state,
                token: action.payload.data.data,
                social_error: null,
                payload: action.payload,
                social_status: 'success',
                user_status: 'logged',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'social',
                    params: action.params
                }
            }

        case APPLE_AUTH_FAIL:
        case GOOGLE_AUTH_FAIL:
        case FACEBOOK_AUTH_FAIL:
            return {
                ...state,
                social_error: action.error,
                social_status: 'error',
                user_status: null,
                request: {
                    ...state.request,
                    current: null,
                    previous: 'social',
                    params: null
                }
            }

        /** Register Actions */
        case REGISTER_USER_REQUEST:
            return {
                ...state,
                registration_error: null,
                registration_status: 'progress',
                request: {
                    ...state.request,
                    current: 'registration',
                    params: action.params
                }
            }
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                registration_error: null,
                payload: action.payload,
                registration_status: 'success',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'registration',
                    params: action.params
                }
            }
        case REGISTER_USER_FAIL:
            return {
                ...state,
                registration_error: action.error,
                registration_status: 'error',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'registration',
                    params: null
                }
            }

        /** Verify Actions */
        case VERIFY_REGISTRATION_REQUEST:
            return {
                ...state,
                verify_registration_error: null,
                verify_registration_status: 'progress',
                request: {
                    ...state.request,
                    current: 'verify_registration',
                    params: action.params
                }
            }
        case VERIFY_REGISTRATION_SUCCESS:
            return {
                ...state,
                verify_registration_error: null,
                payload: action.payload,
                verify_registration_status: 'success',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'verify_registration',
                    params: action.params
                }
            }
        case VERIFY_REGISTRATION_FAIL:
            return {
                ...state,
                verify_registration_error: action.error,
                verify_registration_status: 'error',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'verify_registration',
                    params: null
                }
            }

        /** Login Actions */
        case LOGIN_USER_REQUEST:
            return {
                ...state,
                login_error: null,
                login_status: 'progress',
                user_status: null,
                request: {
                    ...state.request,
                    current: 'login',
                    params: action.params
                }
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                token: action.payload.data.data,
                login_error: null,
                payload: action.payload,
                login_status: 'success',
                user_status: 'logged',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'login',
                    params: action.params
                }
            }
        case LOGIN_USER_FAIL:
            return {
                ...state,
                login_error: action.error,
                login_status: 'error',
                user_status: null,
                request: {
                    ...state.request,
                    current: null,
                    previous: 'login',
                    params: null
                }
            }

        /** Forgot Password Actions */
        case FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgot_password_error: null,
                forgot_password_status: 'progress',
                request: {
                    ...state.request,
                    current: 'forgot_password',
                    params: action.params
                }
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgot_password_error: null,
                payload: action.payload,
                forgot_password_status: 'success',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'forgot_password',
                    params: action.params
                }
            }
        case FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                forgot_password_error: action.error,
                forgot_password_status: 'error',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'forgot_password',
                    params: null
                }
            }

        /** Change Password Actions */
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                forgot_password_status: null,
                forgot_password_error: null,
                change_password_error: null,
                change_password_status: 'progress',
                request: {
                    ...state.request,
                    current: 'change_password',
                    params: action.params
                }
            }
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                change_password_error: null,
                change_password_status: 'success',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'change_password',
                    params: action.params
                }
            }
        case CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                change_password_error: action.error,
                change_password_status: 'error',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'change_password',
                    params: null
                }
            }

        /** Resend Code Actions */
        case RESEND_CODE_REQUEST:
            return {
                ...state,
                resend_code_error: null,
                resend_code_status: 'progress',
                request: {
                    ...state.request,
                    current: 'forgot_password',
                    params: action.params
                }
            }
        case RESEND_CODE_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                resend_code_error: null,
                resend_code_status: 'success',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'change_password',
                    params: action.params
                }
            }
        case RESEND_CODE_FAIL:
            return {
                ...state,
                resend_code_error: action.error,
                resend_code_status: 'error',
                request: {
                    ...state.request,
                    current: null,
                    previous: 'change_password',
                    params: null
                }
            }

        /** VALIDATE USER TOKEN */
        case VALIDATE_USER_TOKEN_REQUEST:
            return {
                ...state,
                token_validator: {
                    ...state.token_validator,
                    status: 'pending',
                    reference: action.params
                }
            }
        case VALIDATE_USER_TOKEN_SUCCESS:
            return {
                ...state,
                token_validator: {
                    ...state.token_validator,
                    data: action.payload?.data?.data,
                    status: 'success',
                    error: null
                }
            }
        case VALIDATE_USER_TOKEN_FAIL:
            return {
                ...state,
                token_validator: {
                    ...state.token_validator,
                    data: null,
                    status: 'error',
                    error: action.payload
                }
            }

        case CLEAR_LOGIN_STATES:
            return {
                ...state,
                login_status: null,
                login_error: null,
                request: null
            }
        case PURGE:
            return INITIAL_STATE

        default:
            return state;
    }
}

export const getAuthState = state => state;
export const getToken = state => state.token;
export const getPayload = state => state.payload;
export const getError = state => state.error;
