import React from 'react';
import { Modal, Slide, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    borderColor: 'red',
    outline: 'none',
    '&:hover': {
      outline: 'none',
    },
    '&:active': {
      outline: 'none',
    },
  },
  content: {
    height: '100%'
  }
});

const DialogContainer = props => {
  const { classes } = props;
  
  const handleClose = () => {
    props.toggleSignUp(props.isOpen)
  };

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Slide className={classes.root} direction="down" in={props.isOpen} mountOnEnter unmountOnExit>
          <div className={classes.content}>
            {props.content}
          </div>
        </Slide>
      </Modal>
    </div>
  );
}

export default withStyles(styles)(DialogContainer);