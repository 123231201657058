import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import {
  Box,
  Typography,
  Grid,
  withStyles,
  TextField,
  makeStyles,
} from '@material-ui/core'

import RangeSlider from './RangeSlider'
import { PRICE_RANGE } from '../../../constants/constants'
import CustomSwitch from '../Filter/CustomSwitch'
import SelectField from './SelectField'
import { NumericFormat } from 'react-number-format'
import PriceOptions from './PriceOptions'

const Container = styled.div`
  padding: 20px 0;
  @media (min-width: 1280px) {
    padding: 70px 0 20px 0;
  }
`

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
  .slider-Bedrooms {
    .MuiSlider-markLabel[data-index='0'] {
      left: 4% !important;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  priceInputField: {
    height: '55px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    padding: '0px 10px 0px 10px',
    border: 'solid #c0c0c0 1px',

    '&:hover': {
      borderColor: 'black',
    },
    '&:focus': {
      border: 'solid #35C0CA 2px',
      outline: 'none',
    },
  },

  priceInputFieldError: {
    border: 'solid red 1px',

    '&:hover': {
      borderColor: 'red',
    },
    '&:focus': {
      border: 'solid red 2px',
      outline: 'none',
    },
  },
}))

const StyledText = withStyles((theme) => ({
  root: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
}))(Typography)

const CheckWrap = styled.div`
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: -25px;
`

const marksBedroom = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
]

const marksDefault = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
]

const priceRange = PRICE_RANGE
const priceRangeRent = [250, 5000]

function formatUSD(num) {
  return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const handlePriceValid = (minPrice, maxPrice, price, priceChange) => {
  if (price > maxPrice) {
    priceChange(maxPrice)
    return false
  }
  if (price < minPrice) {
    priceChange(minPrice)
    return false
  }

  return true
}

const PriceInputField = ({ value, onValueChange, onBlur, isAllowed, errorMessage }) => {
  const classes = useStyles()

  return (
    <Box>
      <NumericFormat
        className={`${classes.priceInputField} ${
          errorMessage ? classes.priceInputFieldError : ''
        }`}
        value={value}
        prefix={'$'}
        thousandSeparator=","
        onValueChange={onValueChange}
        onBlur={onBlur}
        isAllowed={isAllowed}
        allowNegative={false}
      />
      {errorMessage && (
        <Typography
          style={{ margin: '3px 0px 0px 3px', color: 'red', fontSize: '12px' }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}

const SliderContainer = (props) => {
  const { showPriceOptions, addPropertyFields } = props

  const [price, setPrice] = useState([
    props.propertyDetails.priceRangeMin,
    props.propertyDetails.priceRangeMax,
  ])
  const [priceRent, setPriceRent] = useState([
    props.propertyDetails.priceRent.min,
    props.propertyDetails.priceRent.max,
  ])
  const [bedrooms, setBedrooms] = useState(props.propertyDetails.bedrooms)
  const [bathrooms, setBathrooms] = useState(props.propertyDetails.bathrooms)
  const [carparking, setCarparking] = useState(props.propertyDetails.carparking)
  const [pricePrecise, setPricePrecise] = useState(
    addPropertyFields.propertyInfo?.propertyDetails?.value?.price || 0
  )
  const [priceStep, setPriceStep] = useState(priceRange[0])

  const [priceOption, setPriceOption] = useState(
    props.propertyDetails.priceOption || 'Single Price'
  )

  const [priceRangeMin, setPriceRangeMin] = useState(
    props.propertyDetails.priceRangeMin || PRICE_RANGE[0]
  )
  const [priceRangeMax, setPriceRangeMax] = useState(
    props.propertyDetails.priceRangeMax || PRICE_RANGE[1]
  )

  useEffect(() => {
    if (props.isClearFilter) {
      setPriceRent(priceRangeRent)
      setPrice(priceRange)
      setBedrooms(null)
      setBathrooms(null)
      setCarparking(null)
      handleData('clear')
    }
  }, [props.isClearFilter])

  useEffect(() => {
    handleData()
  }, [props.hidePrice])

  useEffect(() => {
    if (priceOption) {
      handleData()
    }
  }, [priceOption])

  useEffect(() => {
    setPrice([Number(priceRangeMin), Number(priceRangeMax)])
  }, [priceRangeMin, priceRangeMax])

  const handlePriceChange = (event, newValue) => {
    let minVal = newValue[0]
    if (minVal > 5000000 && minVal < 10000000) {
      setPriceStep(500000)
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000)
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000)
    } else {
      setPriceStep(50000)
    }

    if (
      (newValue[0] > 5000000 && newValue[0] < priceRange[1]) ||
      (newValue[1] > 5000000 && newValue[1] < priceRange[1])
    ) {
      if (newValue[0].toFixed(2).search('50000.00') > -1) {
        let nMin = String(newValue[0]).replace('50000', '00000')
        newValue[0] = parseInt(nMin)
      }
      if (newValue[1].toFixed(2).search('50000.00') > -1) {
        let nMax = String(newValue[1]).replace('50000', '00000')
        newValue[1] = parseInt(nMax)
      }
      setPrice(newValue)
    } else {
      setPrice(newValue)
    }
  }

  const handlePricePreciseChange = (event, value) => {
    let minVal = value
    if (minVal > 5000000 - 1 && minVal < 10000000) {
      setPriceStep(500000)
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000)
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000)
    } else {
      setPriceStep(50000)
    }
    setPricePrecise(value)
  }

  const handlePriceRentChange = (event, newValue) => {
    setPriceRent(newValue)
  }

  const handleBedroomsChange = (event, newValue) => {
    setBedrooms(newValue)
  }

  const handleBathroomsChange = (event, newValue) => {
    setBathrooms(newValue)
  }

  const handleCarparkingChange = (event, newValue) => {
    setCarparking(newValue)
  }

  const handleChangeCommitted = () => {
    handleData()
  }

  const handleData = (clear) => {
    let priceView = ''

    if (showPriceOptions) {
      switch (priceOption) {
        case 'Single Price':
          priceView = formatUSD(Number(pricePrecise))
          break

        case 'Price Range':
          const rangePrice = `${formatUSD(Number(price[0]))} - ${formatUSD(
            Number(price[1])
          )}`
          priceView = rangePrice
          break

        case 'POA':
          priceView = 'POA'
          break

        case 'Contact Agent':
          priceView = 'Contact Agent'
          break
      }
    }

    const propertyData = {
      price: priceOption === 'Price Range' ? priceRangeMin : pricePrecise,
      priceRent: {
        min: priceRent[0],
        max: priceRent[1],
      },
      bedrooms,
      bathrooms,
      carparking,
      hidePrice: props.hidePrice,
      priceView: priceView,
      priceOption: priceOption,
      priceRangeMin: priceRangeMin,
      priceRangeMax: priceRangeMax,
    }

    if (props.handlePropertyDetails) {
      if (clear) {
        propertyData.price.min = priceRange[0]
        propertyData.price.max = priceRange[1]
        propertyData.priceRent.min = 250
        propertyData.priceRent.max = 5000
        propertyData.bedrooms = null
        propertyData.bathrooms = null
        propertyData.carparking = null
        priceView = ''

        setPriceOption('SinglePrice')
        setPriceRangeMin(priceRange[0])
        setPriceRangeMax(priceRange[1])
      }
      props.handlePropertyDetails(propertyData)
    }
  }

  return (
    <Container>
      {showPriceOptions ? (
        <PriceOptions
          pricePrecise={pricePrecise}
          setPricePrecise={setPricePrecise}
          handlePriceValid={handlePriceValid}
          priceOption={priceOption}
          setPriceOption={setPriceOption}
          priceRangeMin={priceRangeMin}
          setPriceRangeMin={setPriceRangeMin}
          priceRangeMax={priceRangeMax}
          setPriceRangeMax={setPriceRangeMax}
          handleData={handleData}
        />
      ) : (
        <>
          <SliderItem>
            {props.path === 'rent' ? (
              <RangeSlider
                path={props.path}
                label="Price Per Week"
                icon="MonetizationOn"
                value={priceRent}
                range={priceRangeRent}
                step={50}
                handleChange={handlePriceRentChange}
                handleChangeCommitted={handleChangeCommitted}
                valueLabelDisplay={'off'}
              />
            ) : (
              <RangeSlider
                label="Price"
                icon="MonetizationOn"
                value={pricePrecise}
                isPrecise={true}
                step={priceStep}
                handleChange={handlePricePreciseChange}
                handleChangeCommitted={handleChangeCommitted}
                min={priceRange[0]}
                max={priceRange[1]}
                valueLabelDisplay={'off'}
              />
            )}
          </SliderItem>
          <CheckWrap>
            <CustomSwitch
              label={'Show Price'}
              checked={props.hidePrice}
              onChange={(e) => {
                props.setHidePrice(!props.hidePrice)
              }}
            />
            <StyledText component={'span'}>POA</StyledText>
          </CheckWrap>
        </>
      )}

      {/* <RangeSlider
          label="Price"
          icon="MonetizationOn"
          value={price}
          range={priceRange}
          step={priceRange[0]}
          // handleChange={handlePriceChange}
          handleChangeCommitted={handleChangeCommitted}
          valueLabelDisplay={'off'}
        /> */}

      <Grid container spacing={8}>
        <Grid item xs={6} sm={6}>
          <SliderItem>
            <RangeSlider
              label="Bedrooms"
              icon="Hotel"
              value={bedrooms}
              handleChange={handleBedroomsChange}
              handleChangeCommitted={handleChangeCommitted}
              step={1}
              marks={marksBedroom}
              min={1}
              max={5}
            />
          </SliderItem>
        </Grid>
        <Grid item xs={6} sm={6}>
          <SliderItem>
            <RangeSlider
              label="Bathrooms"
              icon="2DCBathIcon"
              value={bathrooms}
              handleChange={handleBathroomsChange}
              handleChangeCommitted={handleChangeCommitted}
              step={1}
              marks={marksBedroom}
              min={1}
              max={5}
            />
          </SliderItem>
        </Grid>
      </Grid>
      <Grid container spacing={8}>
        <Grid item xs={6} sm={6}>
          <SliderItem>
            <RangeSlider
              label="Carparking"
              icon="DirectionsCar"
              value={carparking}
              handleChange={handleCarparkingChange}
              handleChangeCommitted={handleChangeCommitted}
              step={1}
              marks={marksDefault}
              min={0}
              max={5}
            />
          </SliderItem>
        </Grid>
      </Grid>
    </Container>
  )
}

export default SliderContainer
