import { Box } from '@material-ui/core'
import RouteButtons from 'components/Common/GlobalFilters/RouteButtons'
import React from 'react'
import { useHistory } from 'react-router-dom'
import FilterOptionsV2 from './FilterOptionsV2'

const RELATED_ROUTES = ['off-market', 'hush-hush', 'coming-soon', 'buy']

const InlineSearchBarV2 = () => {
  const history = useHistory()
  const path = history.location.pathname?.split('/')[1] || ''

  return (
    <Box display="flex" flexDirection="row">
      {RELATED_ROUTES.includes(path) && <RouteButtons />}

      <FilterOptionsV2 path={path} />
    </Box>
  )
}

export default InlineSearchBarV2
