import { Box, Checkbox, makeStyles } from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'

import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import useFilters from 'utils/customHooks/useFilters'
import { useSearch } from 'utils/search/useSearch'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    gridAutoFlow: 'row',
  },

  optionButton: {
    textTransform: 'none',
    width: '10.500em',
    height: '2.875em',
    borderRadius: '2.250em',
    color: '#000',
    fontWeight: 'normal',
    fontSize: 13,
    lineHeight: 1,
    marginRight: 10,
    padding: '11px 0',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '110px',

    [theme.breakpoints.down(1024)]: {
      margin: 0,
      marginRight: 5,
    },
  },

  // Button checkbox
  checkboxRoot: {
    paddingRight: 0,
    paddingLeft: '5px',
    color: 'rgba(0, 0, 0, 0.54)',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  checkedCheckbox: {
    color: 'white',
  },
}))

const CustomButton = ({ name, label, isActive, onChange, color }) => {
  const classes = useStyles()

  return (
    <ToggleButton
      name={name}
      className={classes.optionButton}
      style={{
        backgroundColor: isActive ? color : '#FFF',
        width: '135px',
      }}
      value={name}
      selected={isActive}
      onChange={onChange}
    >
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          color: isActive ? '#FFF' : '#4d4d4d',
        }}
      >
        {label}
        <Checkbox
          classes={{
            root: classes.checkboxRoot,
            checked: classes.checkedCheckbox,
          }}
          color="default"
          name={name}
          checked={isActive}
          onChange={onChange}
          size="small"
        />
      </span>
    </ToggleButton>
  )
}

const RouteButtons = () => {
  const classes = useStyles()
  const [initiateSearch] = useSearch()
  const [getFilter, setFilter] = useFilters()

  const history = useHistory()

  const SEARCH_REFERENCE = getFilter('search_reference')

  const [offMarketFilter, setOffMarketFilter] = useState(() =>
    SEARCH_REFERENCE.find((searceReference) => searceReference.id === 'offMarket')
  )
  const [comingSoonFilter, setComingSoonFilter] = useState(() =>
    SEARCH_REFERENCE.find((searceReference) => searceReference.id === 'comingSoon')
  )

  const componentDidMountRef = useRef(false)

  const handleChangeSearchReference = (searchReferenceId) => {
    searchReferenceId === 'comingSoon'
      ? setComingSoonFilter((prev) => ({ ...prev, active: !prev.active }))
      : setOffMarketFilter((prev) => ({ ...prev, active: !prev.active }))

    let updatedReference = SEARCH_REFERENCE.map((searchReference) => {
      if (searchReference.id === 'comingSoon' || searchReference.id === 'offMarket') {
        return searchReference.id === searchReferenceId
          ? {
              ...searchReference,
              active: !searchReference.active,
            }
          : searchReference
      } else {
        return {
          ...searchReference,
          active: false,
        }
      }
    })

    const updatedOffMarketFilter = updatedReference.find(
      (reference) => reference.id === 'offMarket'
    )
    const updatedComingSoonFilter = updatedReference.find(
      (reference) => reference.id === 'comingSoon'
    )

    if (!updatedOffMarketFilter.active && !updatedComingSoonFilter.active) {
      updatedReference = updatedReference.map((reference) => {
        if (reference.id === 'buy') {
          return { ...reference, active: true }
        }

        return reference
      })
    }

    setFilter({
      key: 'search_reference',
      value: updatedReference,
    })
  }

  useEffect(() => {
    if (offMarketFilter && comingSoonFilter && componentDidMountRef.current) {
      initiateSearch()
    } else {
      componentDidMountRef.current = true
    }
  }, [offMarketFilter, comingSoonFilter])

  return (
    <Box className={classes.container}>
      <CustomButton
        name="offMarket"
        label="Off Market"
        isActive={offMarketFilter.active}
        color="#e1315a"
        onChange={(event, value) => {
          // check if target.name is not null else get value of entire button, target.name is not null if checkbox is clicked
          handleChangeSearchReference(event.target.name || value)
        }}
      />

      <CustomButton
        name="comingSoon"
        label="Coming Soon"
        isActive={comingSoonFilter.active}
        color="rgba(253, 189, 38, 0.9)"
        onChange={(event, value) => {
          // check if target.name is not null else get value of entire button, target.name is not null if checkbox is clicked
          handleChangeSearchReference(event.target.name || value)
        }}
      />
    </Box>
  )
}

export default RouteButtons
