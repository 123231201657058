import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Grid, Typography, TextField, withStyles, Button } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import UploadPropertyCard from 'components/Web/Property/UploadPropertyCard';
import { toggleSignUp } from 'store/actions';
import styled from 'styled-components';

/** Property Utils */
import { propertyConfigSetter } from 'utils/property.utils';
import HeadingContentSmall from 'components/Mobile/Form/HeadingContentSmall';
import LogoOnlyHeader from 'components/Mobile/Header/LogoOnlyHeader';
import { requestGetToConfirmProperty, requestSetConfirmProperty } from 'store/api';
import ConfirmDialog from 'components/Mobile/CustomDialogs/ConfirmDialog';
import NotificationDialog from 'components/Mobile/CustomDialogs/NotificationDialog';
import Loader from 'components/Mobile/Loader';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    width: '100%',
    paddingTop: '5vh',
    height: '100vh',
    '& .MuiBackdrop-root': {
      width: '100%',
      maxWidth: 'initial',
      left: 0,
    },
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  intentUploadWrap: {
    marginTop: theme.spacing(3),
  },
  otpMain: {
    width: '100%',
    marginTop: 10,
  },
  otpTextContainer: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  otpText: {
    fontFamily: 'Montserrat, sans',
  },
  otpFormContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  otpField: {
    marginTop: 10,
    '& input': {
      padding: '12px 8px',
    },
    '& label': {
      transform: 'translate(14px, 15px) scale(1)',
    },
  },
  otpButton: {
    width: 150,
  },
});

/** Default input */
const FormInput = (props) => <TextField {...props} fullWidth id="outlined-basic" variant="outlined" />;

const StyledButton = withStyles((theme) => ({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: theme.spacing(1, 2),
    minWidth: '118px',
  },
}))(Button);

const ButtonWrap = styled.div`
  padding: 20px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    margin: 0 10px;
  }
`;

/** Validation */
const validationSchema = yup.object({
  otp: yup.number('Invalid OTP').required('OTP is required'),
  reason: yup.string('Enter your reason').required('Reason is required'),
});

let timercountdown = null;

const ConfirmProperty = (props) => {
  const {
    classes,
    match,
    authentication,
    settings,
    toggleSignUp,
    filters,
    getPropertyDetails,
    property,
    property_approval,
    approval_status,
    approval_error,
    setApproveProperty,
    property_status,
    property_error,
  } = props;
  const [routerChange] = useRouterChange();
  const [confirmApprove, setConfirmApprove] = useState(false);
  const [confirmDecline, setConfirmDecline] = useState(false);
  const [errorNotice, setErrorNotice] = useState(false);
  const [successNotice, setSuccessNotice] = useState(false);
  const [action, setAction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [invalidProperty, setInvalidProperty] = useState(false);

  /** Form set-up, validation integration and submit handler */
  const formik = useFormik({
    initialValues: {
      otp: '',
      reason: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => alert(values),
  });

  /**
   * *Countdown states
   */
  const [countdown, setCountdown] = useState(5); // ** set seconds here
  const [countdownState, setCountdownState] = useState(false);

  let isVendor = match.params.target === 'vendor';

  useEffect(() => {
    if (props.match.params && props.match.params.id) {
      const params = {
        id: props.match.params.id,
        target: props.match.params.target,
      };
      apiGetPropertyDetails(params);
      setIsLoading(true);
    }
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      window.location.href = process?.env.REACT_APP_REDIRECT_URL;
    }
  }, [countdown]);

  useEffect(() => {
    if (approval_status && approval_status === 'success' && !approval_error) {
      setSuccessNotice(true);
      setTimeout(() => {
        setSuccessNotice(false);
        window.location.href = process?.env.REACT_APP_REDIRECT_URL;
      }, 6000);
    } else {
      setErrorNotice(true);
      setTimeout(() => {
        setErrorNotice(false);
      }, 6000);
    }
  }, [approval_status, approval_error]);

  useEffect(() => {
    if (property) {
      setIsLoading(false);
    }
  }, [property]);

  useEffect(() => {
    if (countdownState === true && countdown > -1) {
      timercountdown = setInterval(() => setCountdown(countdown - 1), 1000);
    }
    /** clear timer on unmount  */
    return () => clearInterval(timercountdown);
  });

  useEffect(() => {
    if (property_status === 'failed' && property_error) {
      setIsLoading(false);
      setInvalidProperty(true);
      if (!countdownState && countdown > -1) {
        setCountdownState(true);
      }
    }
  }, [property_error, property_status]);

  async function apiGetPropertyDetails(id) {
    if (id) {
      getPropertyDetails(id);
    }
  }

  async function apiSetApproveProperty(status) {
    const params = {
      id: props.match.params.id,
      target: props.match.params.target,
      is_approved: status,
    };
    setApproveProperty(params);
  }

  const handleApproveConfirmation = (status) => {
    setConfirmApprove(false);
    apiSetApproveProperty(status);
    setAction(status);
  };

  const handleDeclineConfirmation = (status) => {
    setConfirmDecline(false);
    apiSetApproveProperty(status);
    setAction(status);
  };

  function routeChangeBack() {
    routerChange('back');
  }

  const handleMatchCount = (property) => {
    let count = 0;
    if (filters && property) {
      filters.filters.advanceProperty.map((item, index) => {
        if (item.active && property.coefficients) {
          if (property.coefficients[item.key] >= 0.7) {
            count++;
          }
        }
      });
    }
    return count;
  };

  const _renderFields = (type) => {
    return (
      <div className={classes.otpMain}>
        <div className={classes.otpTextContainer}>
          <Typography variant="caption" className={classes.otpText}>
            To proceed, please input the one-time pin (OTP) that is sent to your mobile device.
          </Typography>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={classes.otpFormContainer}>
            <FormInput
              className={classes.otpField}
              id="otp"
              name="otp"
              label="OTP *"
              variant="outlined"
              value={formik.values.otp}
              onChange={formik.handleChange}
              error={formik.touched.otp && Boolean(formik.errors.otp)}
              helperText={formik.touched.otp && formik.errors.otp}
            />
            <Button className={classes.otpButton} variant="text" color="primary" type="submit">
              Resend OTP
            </Button>
          </div>
          {type === 'decline' && (
            <FormInput
              className={classes.otpField}
              id="reason"
              name="reason"
              label="Reason *"
              variant="outlined"
              value={formik.values.reason}
              onChange={formik.handleChange}
              error={formik.touched.reason && Boolean(formik.errors.reason)}
              helperText={formik.touched.reason && formik.errors.reason}
            />
          )}
        </form>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Loader isLoading={isLoading} />
      {!isLoading && !invalidProperty && (
        <Container>
          <LogoOnlyHeader />

          {property && property.data && property.data.property && (
            <Grid container spacing={6} justify={'center'}>
              <Grid item md={4}>
                <HeadingContentSmall
                  title={isVendor ? 'Vendor Confirmation required' : 'Agency Confirmation required'}
                  content={`Please confirm that you give your consent for your property at <strong>${
                    property && property.data && property.data.property && property.data.property.addressText
                  }</strong> to be published on the PropertyMate platform.`}
                  style={{ textAlign: 'center' }}
                />
                <UploadPropertyCard
                  cardType={'list'}
                  properties={property.data.property}
                  {...propertyConfigSetter(
                    'card-type',
                    property.data.property
                      ? property.data.property.subscriptionType
                        ? property.data.property.subscriptionType
                        : ''
                      : '',
                  )}
                  authentication={authentication}
                  settings={settings}
                  toggleSignUp={toggleSignUp}
                  listingType={'properties'}
                  handleMatchCount={handleMatchCount}
                  setCoverCallback={() => false}
                  enableCoverSelector={false}
                />
              </Grid>
            </Grid>
          )}
          <ButtonWrap>
            <StyledButton
              color="secondary"
              variant="contained"
              disabled={false}
              onClick={() => setConfirmDecline(true)}
            >
              Decline
            </StyledButton>
            <StyledButton color="primary" variant="contained" disabled={false} onClick={() => setConfirmApprove(true)}>
              Approve
            </StyledButton>
          </ButtonWrap>

          <ConfirmDialog
            isOpen={confirmApprove}
            confirmCallback={() => handleApproveConfirmation(true)}
            closeCallback={() => setConfirmApprove(false)}
            align={'center'}
            title={'Approve Property'}
            text={'Are you sure you want to approve this listing?'}
            hasRender={isVendor} // ** Only show OTP field if link is being viewed by the VENDOR
            renderComponents={_renderFields('approved')}
          />
          <ConfirmDialog
            isOpen={confirmDecline}
            confirmCallback={() => handleDeclineConfirmation(false)}
            closeCallback={() => setConfirmDecline(false)}
            align={'center'}
            title={'Decline Property'}
            text={'Are you sure you want to decline this listing?'}
            hasRender={isVendor} // ** Only show OTP field if link is being viewed by the VENDOR
            renderComponents={_renderFields('decline')}
          />
          {property_approval && approval_status === 'success' && !approval_error && (
            <NotificationDialog
              isOpen={successNotice}
              closeCallback={() => {
                setSuccessNotice(false);
              }}
              title={'Success'}
              text={`${action ? 'Approval' : 'Decline'} Successful. You will be redirected to homepage shortly.`}
              showLogo={true}
              align={'center'}
            />
          )}
          {!property_approval && approval_status === 'failed' && approval_error && (
            <NotificationDialog
              isOpen={errorNotice}
              closeCallback={() => {
                setErrorNotice(false);
              }}
              title={'Error'}
              text={`${action ? 'Approval' : 'Decline'} Error. Please retry.`}
              showLogo={true}
              align={'center'}
            />
          )}
        </Container>
      )}
      {!isLoading && invalidProperty && (
        <Container style={{ paddingTop: '10vh' }}>
          <Typography component="p" style={{ textAlign: 'center' }}>
            Sorry, this approval link is invalid. This property might have been already approved, declined, or doesn't
            exist.
          </Typography>
          <Typography component="p" style={{ textAlign: 'center' }}>
            {"You'll be redirected to homepage in "} <b> {countdown}</b> seconds.
          </Typography>
          <Typography component="p" style={{ textAlign: 'center', paddingTop: '30px' }}>
            Not redirected?{' '}
            <Button
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              onClick={() => (window.location.href = process?.env.REACT_APP_REDIRECT_URL)}
            >
              Go to Homepage Now
            </Button>{' '}
          </Typography>
        </Container>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.agents.to_confirm_property,
    property_error: state.agents.request_to_confirm_property_error,
    property_status: state.agents.request_to_confirm_property_status,
    property_approval: state.agents.request_approve_property,
    approval_status: state.agents.request_approve_property_status,
    approval_error: state.agents.request_approve_property_error,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      getPropertyDetails: (params) => requestGetToConfirmProperty(params),
      setApproveProperty: (params) => requestSetConfirmProperty(params),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfirmProperty));
