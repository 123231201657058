import React, { useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  FormControl,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { dummyReferralData } from 'constants/referral';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReferralDataCards from '../Superadmin/Referral/ReferralDataCards';
import CustomDatePicker from '../Filter/CustomDatePicker';
import { QRCodeCanvas } from 'qrcode.react';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { QRCodeFileFormats } from 'constants/referral';
import downloadComponentAsImage from 'utils/downloadComponentAsImage.utils';
import { Snackybar } from '../Superadmin';
import DownloadQRCodeUI from 'components/Common/Referral/DownloadQRCodeUI';
import PropertyMateLogo from '../../../assets/images/propertymate_logo.svg';
import ReferralDatePicker from 'components/Common/Referral/ReferralDatePicker';
import { Skeleton } from '@material-ui/lab';
import roundOffTwoDecimal from 'utils/roundOffTwoDecimal.utils';
import useSize from 'utils/customHooks/useSize';

const styles = (theme) => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
    padding: theme.spacing(1, 1),
    paddingTop: theme.spacing(2),
  },
  main: {
    backgroundColor: '#f5f5f5',
    padding: '2.6rem',
    margin: '2rem auto',
    maxWidth: '1012px',
    borderRadius: '5px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  docusignButton: {
    height: 50,
    marginTop: 15,
    marginLeft: 5,
    borderRadius: 40,
    fontSize: 13,
    backgroundColor: '#fdbd26',
    borderColor: '#fdbd26',
    '&:hover': {
      backgroundColor: 'rgba(253, 189, 38, 0.8)',
    },
  },
  ippReferralProgram: {
    width: '100%',
  },
  formControl: {
    minWidth: 120,
  },
});

const ReferralSection = ({
  classes,
  referralCode,
  companyName,
  ippReferralData,
  ippSelectDate,
  setIppSelectDate,
  ippStartDate,
  ippEndDate,
  handleStartDateChange,
  handleEndDateChange,
  referralLoading,
}) => {
  const qrCodeRef = useRef(null);

  // Snackybar state
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  });

  const { isSmall, isMedium, isLarge } = useSize();

  const [qrFileFormat, setQrFileFormat] = useState('jpg');
  const [qrFormatList, setQrFormatList] = useState(null);
  const openQrList = Boolean(qrFormatList);
  const [qrDownloadLoading, setQrDownloadLoading] = useState(false);

  const ippReferralAnalytics = [
    {
      title: 'Number of Clicks',
      data: ippReferralData?.numberOfClicks,
    },
    { title: 'Number of Sign Ups', data: ippReferralData?.numberOfSignUps },
    { title: 'Bounce Rate', data: ippReferralData?.bounceRate },
  ];

  const referralCodeLink = `${process?.env.REACT_APP_REDIRECT_URL}/referral/${referralCode || ''}`;

  const handleCopyClipboard = () => {
    navigator.clipboard.writeText(referralCodeLink);
    setShowMessage({
      open: true,
      type: 'info',
      message: 'Referral Code copied to clipboard',
    });
  };

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    });
  };

  const handlePreventCutCopyPaste = (event) => {
    event.preventDefault();
  };

  const handleDownloadQRClick = (event) => {
    setQrFormatList(event.currentTarget);
  };

  useEffect(() => {
    if (qrDownloadLoading) {
      downloadComponentAsImage(
        qrCodeRef.current,
        `${companyName} ${referralCode}`,
        qrFileFormat,
        isSmall,
        isMedium,
        isLarge,
      );
      setQrDownloadLoading(false);
    }
  }, [qrDownloadLoading]);

  return (
    <Box className={classes.root}>
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      {qrDownloadLoading && (
        <DownloadQRCodeUI qrCodeRef={qrCodeRef} referralCodeLink={referralCodeLink} imageURL={PropertyMateLogo} />
      )}
      <Box className={classes.main} display="flex" justifyContent="space-between">
        <Box className={classes.ippReferralProgram}>
          <Typography style={{ fontSize: 24, fontFamily: 'Montserrat' }}>Referral Program</Typography>
          <Typography style={{ fontSize: 'Roboto', fontSize: 16, marginTop: 10 }}>
            The Referral Program is an agency incentive program rewarding early participation with equity. 9,000,000
            shares will be allocated to participating agencies over a 3yr period (2023-2025), with share points relative
            to office size. Agencies earn by listing, actively engaging with the platform, and referring people to
            PropertyMate (Referral Program).
          </Typography>
          <Typography style={{ marginTop: 20, fontWeight: 600 }} variant="h5">
            Referral Report
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Card
              variant="outlined"
              style={{
                height: 270,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                width: 607,
                position: 'relative',
              }}
            >
              <Box
                width="100%"
                position="absolute"
                top={0}
                left={0}
                display="flex"
                justifyContent="space-between"
                mb={5}
              >
                <Box mb={2} ml={1.5}>
                  <FormControl
                    variant="outlined"
                    style={{ width: 198.93 }}
                    size="small"
                    margin="normal"
                    className={classes.formControl}
                  >
                    <Typography fontSize={14}>Report Period</Typography>
                    <Select
                      value={ippSelectDate}
                      onChange={(e) => setIppSelectDate(e.target.value)}
                      style={{ marginTop: 4, borderRadius: 10, width: '100%' }}
                    >
                      <MenuItem value="TODAY">
                        <em>Today</em>
                      </MenuItem>
                      <MenuItem value="LAST7DAYS">Last 7 Days</MenuItem>
                      <MenuItem value="LAST30DAYS">Last 30 Days</MenuItem>
                      <MenuItem value="CUSTOM">Custom</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                {ippSelectDate === 'CUSTOM' && (
                  <Box mt={1.5}>
                    <ReferralDatePicker
                      label="Start Date"
                      selectedDate={ippStartDate}
                      handleDateChange={handleStartDateChange}
                      width={145}
                      marginRight={10}
                    />
                    <ReferralDatePicker
                      label="End Date"
                      selectedDate={ippEndDate}
                      handleDateChange={handleEndDateChange}
                      width={145}
                      marginRight={10}
                    />
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="space-evenly" marginTop={10}>
                {ippReferralAnalytics.map((item, index) =>
                  referralLoading ? (
                    <Skeleton>
                      <ReferralDataCards
                        key={index}
                        width={181}
                        height={185}
                        textData={item.data}
                        textLabel={item.title}
                      />
                    </Skeleton>
                  ) : (
                    <ReferralDataCards
                      key={index}
                      width={181}
                      height={122}
                      textData={
                        item.title === 'Bounce Rate'
                          ? item?.data
                            ? roundOffTwoDecimal(item.data)
                            : 'No Available Data'
                          : item.data
                      }
                      textLabel={item.title}
                    />
                  ),
                )}
              </Box>
            </Card>
            <Card
              variant="outlined"
              style={{
                borderRadius: 8,
                height: 270,
                width: 293,
                display: 'flex',
                flexDirection: 'column',
                width: '30%',
                alignItems: 'center',
              }}
            >
              <Box mt={2}>
                <Typography style={{ fontSize: 10, color: '#4D4D4D' }}>Copy Referral Code</Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={referralCode || ''}
                  onTouchMove={handlePreventCutCopyPaste}
                  onCut={handlePreventCutCopyPaste}
                  onCopy={handlePreventCutCopyPaste}
                  onPaste={handlePreventCutCopyPaste}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{
                          cursor: 'pointer',
                          border: '2px solid #E0E0E0',
                          color: '#35C0CA',
                        }}
                        onClick={handleCopyClipboard}
                      >
                        <FileCopyOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <QRCodeCanvas value={referralCodeLink} style={{ marginTop: 15, width: 94, height: 93 }} />
              <Button
                variant="outlined"
                id="download-qr"
                size="large"
                aria-controls={openQrList ? 'qr-list' : undefined}
                aria-haspopup="true"
                aria-expanded={openQrList ? 'true' : undefined}
                onClick={handleDownloadQRClick}
                style={{
                  marginTop: 10,
                  border: '2px solid #35C0CA',
                  color: '#35C0CA',
                  fontWeight: 400,
                  borderRadius: 20,
                }}
              >
                <Box display="flex">
                  <GetAppIcon style={{ marginTop: 1, marginRight: 8 }} />
                  <Typography fontSize={10} style={{ textTransform: 'capitalize', marginRight: 2 }}>
                    Download
                  </Typography>
                </Box>
              </Button>
              <Menu
                id="qr-list"
                anchorEl={qrFormatList}
                open={openQrList}
                onClose={() => setQrFormatList(null)}
                MenuListProps={{
                  'aria-labelledby': 'download-qr',
                }}
              >
                {QRCodeFileFormats.map((data, index) => (
                  <MenuItem
                    style={{
                      color: '#35C0CA',
                      '&:hover': {
                        color: '#35C0CA',
                      },
                    }}
                    value={data}
                    key={index}
                    onClick={() => {
                      setQrFileFormat(data);
                      setQrFormatList(null);
                      setQrDownloadLoading(true);
                    }}
                  >
                    {data.toUpperCase()}
                  </MenuItem>
                ))}
              </Menu>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ReferralSection);
