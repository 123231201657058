import React from "react";
import { Typography, makeStyles, Grid, IconButton } from "@material-ui/core";
import TextImage02 from 'assets/images/content/textimage02.svg'
import Image from "components/Web/Image";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EF6631",
    maxWidth: 600,
    height: "auto",
    padding: "34px",
    paddingTop: 70,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30,
    margin: "0 auto",
    top: "30vh",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: 18,
      paddingTop: 70,
      paddingBottom: 50,

    }
  },
  title: {
    fontFamily: "Montserrat, sans",
    fontWeight: "bold",
    color: "#FFFFFF",
    fontSize: "40px",
    lineHeight: "38px",
    marginBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "26px",
      marginBottom: "20px"
    }
  },
  content: {
    fontFamily: "Montserrat, sans",
    fontWeight: "300",
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "23px",
    letterSpacing: "0.09px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
      lineHeight: "14px",
    }
  },
  verticalCenter: {
    display: "flex",
    alignItems: "center"
  },
  closeButton: {
    color: "#000",
    top: 20,
    right: 20,
    padding: 0,
    "& svg": {
      fontSize: "38px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      }
    },
    position: "absolute"
  },
  imageContent: {
    [theme.breakpoints.down("xs")]: {
      padding: "0 !important",
      marginLeft: "-20px"
    }
  }
}));

const BrandNewPlatform = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconButton
        className={classes.closeButton}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={props?.handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={4}>
        <Grid item xs={6} className={classes.verticalCenter}>
          <div>
            <Typography variant={"h2"} className={classes.title} >
              this is a<br />
              brand new <br />
              platform
            </Typography>
            <Typography variant={"body1"} className={classes.content}>
              We're evolving every day, <br />
              so it'll only get better.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.imageContent}>
          <Image
            src={TextImage02}
            style={{ width: 475 }}
            aspectRatio={4 / 3.6}
            color={'transparent'}
            imageStyle={{ objectFit: 'contain' }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BrandNewPlatform;
