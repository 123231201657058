import React, { useState, useRef } from "react";
import SatelliteIcon from '@material-ui/icons/Satellite';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import Geocode from "react-geocode";
import TuneIcon from '@material-ui/icons/Tune';

import PropertySearchMapView from '../../components/Mobile/Map/PropertySearchMapView';
import SearchFilterHeader from '../../components/Web/Property/SearchFilterHeader'

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loader from '../../components/Mobile/Loader';
import AlertNotification from '../../components/Mobile/Form/AlertNotification'
import MapPropertyCards from '../../components/Web/Map/MapPropertyCards';
import PropertySearchFilter from '../../components/Web/Searchbar/PropertySearchFilter';
import SavedSearch from '../../components/Mobile/SavedSearch';
import SortSelection from '../../components/Web/Searchbar/SortSelection';

import DynamicIcons from '../../components/Mobile/DynamicIcons'

import { scrollToRef } from '../../components/Mobile/ScrollEvents';
import _, { random } from "lodash";

/** api redux request */
import {
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
  requestPropertySearchSold,
  requestPropertySearchRentals,
} from "../../store/api";

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters
} from '../../store/actions';

/** Object Filters */
import {
  reform
} from '../../utils/filters.utils';
import {
  formatRequest
} from '../../utils/search.utils';

import { withStyles, Button, IconButton, Badge, Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    paddingLeft: 90,
    paddingTop: 135,
  },
  columnWrapper: {
    padding: theme.spacing(3, 7.25)
  },
  cardColumn: {
    paddingTop: 0 + ' !important',
    paddingBottom: 0 + ' !important',
    overflowY: 'scroll'
  },
  propFilters: {
    padding: theme.spacing(1, 2)
  },
  mapColumn: {
    paddingTop: 0 + ' !important',
    paddingBottom: 0 + ' !important',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    // transform: 'translateY(-56px)'
  },
  mapWrapper: {
    overflow: 'hidden',
    position: 'relative',
  },
  propertyCards: {
    position: 'absolute',
    left: 0,
    bottom: 60,
    width: '100%'
  },
  mapTypeToggle: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(1),

    '& .MuiButton-contained': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 12,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)

    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'capitalize',
      fontSize: 12,
    }
  },
  offMarketToggle: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    '& .MuiButton-contained': {
      fontSize: 13,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'left',
      borderRadius: theme.spacing(2),
      // backgroundColor: '#ffffff'
    },
  },
  checkWrapper: {
    marginRight: theme.spacing(1),
    display: 'inline-flex'
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative'
  },
})

const BackButton = withStyles({
  root: {
    zIndex: 20,
    background: '#ffffff'
  },
  label: {
    textTransform: 'capitalize',
  }

})(Button);

const FavoritesMap = props => {
  const {
    classes,
    property,
    setCurrentPropertySelected,
    filters,
    savePropertySearch,
    authentication,
    settings,
    setIncludeNearbyFilter,
    setSearchFilters,
    setSearchSortingFilters,
    ...other
  } = props;
  const [properties, setProperties] = React.useState(null);
  const [mapType, setMapType] = React.useState(false);
  const [offMarketToggle, setoffMarketToggle] = React.useState(false);
  const [topOffset, setTopOffset] = React.useState(0);
  const [comingSoonLength, setComingSoonLength] = React.useState(0);
  const [offMarketLength, setOffMarketLength] = React.useState(0);
  const [currentLength, setCurrentLength] = React.useState(0);
  const [soldLength, setSoldLength] = React.useState(0);
  const [rentalLength, setRentalLength] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);


  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);

  const [searchResultProperties, setSearchResultProperties] = useState([]);
  const [showSaveSearch, setShowSaveSearch] = useState(false);
  const [suburbs, setSuburbs] = useState('')
  const [isFiltered, setIsFiltered] = useState({ isActive: false, suburbs: null });

  const [showModalOptions, setShowModalOptions] = React.useState(false);
  const [selectedFilterType, setSelectedFilterType] = React.useState('');
  const [showModalBottomOptions, setShowModalBottomOptions] = React.useState(false);

  const [activeProperty, setActiveProperty] = React.useState(null)
  const [visibleOffMarkets, setVisibleOffMarkets] = React.useState(null)
  const [suburbCoordinates, setSuburbCoordinates] = React.useState(null)
  const [previousScreen, setPreviousScreen] = React.useState(null)

  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 20,
    propertiesFilters: [],
    totalResults: null
  })

  const topContainer = useRef(null)
  const mapWrapperRef = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d) //add `false` as second parameter to scroll without transition.
  const BASE_RADIUS = 2000;
  const NEARBY_RADIUS = 8000;

  const getCurrentScreen = () => {
    console.debug(props.match ? props.match.url : '')
    return props.match ? props.match.url : ''
  }

  React.useEffect(() => {

    /** 
     * property data setter */
    if (property) {

      if (property && property.properties && property.properties.data && property.properties.data.data) {
        setIsLoading(false);
        setProperties(property.properties.data.data.propertiesFilters)

        // setLikedPropertiesData({propertiesFilters: property.properties_liked.data.data})
      }

    }

    /** 
     * error hanlder property search */
    if (property && property.properties && isLoading) {
      handleNotification(true, { message: 'Oops. An error has occured..', severity: 'error' })
      setIsLoading(false);
    }
    /** 
     * error hanlder save property */
    if (property && property.properties && isLoading) {
      handleNotification(true, { message: 'Oops. An error has occured..', severity: 'error' })
      setIsLoading(false);
    }

    if (mapWrapperRef) {
      setTopOffset(mapWrapperRef.current.offsetTop)
    }

    /* document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        setIsLoading(false);
      }
    }; */

    if (filters && filters.suburbs.length > 0) {
      setSuburbCoordinates({ lat: filters.suburbs[0].lat, lng: filters.suburbs[0].long })
    }
  }, [property])

  React.useEffect(() => {
    executeTopScroll(false)

    /** GET PREVIOUS SCREEN */
    setPreviousScreen(localStorage.getItem('previous_screen'))
  }, [])

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const [mapData, setMapData] = useState({
    map: {
      coordinates: [-37.796300, 144.925120],
      center: { lat: -37.796300, lng: 144.925120 },
      comingsoon: [],
      offmarket: [],
      current: [],
      zoom: 13
    }
  })

  const getRandomCoordinates = (lat, lng, r) => {
    const METERS_IN_DEGREE = 111_300
    const u = Math.random(), v = Math.random()

    let radius = parseFloat(r) / METERS_IN_DEGREE

    let w = radius * Math.sqrt(u)
    let t = 2 * Math.PI * v

    let x = (w * Math.cos(t)) / Math.cos(lat)
    let y = w * Math.sin(t)

    return { lat: y + lat, lng: x + lng } //returns an object of lat long pair
  }

  function isWithinRadius(checkPoint, centerPoint, km) {
    var ky = 40000 / 360;
    var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    km = km / 1000;
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  function generateRandomCoordinates(center, radius, count) {
    let i;
    let coords = [];

    for (i = 0; i < count; i++) {
      coords.push(getRandomCoordinates(center, radius));
    }

    return coords;

  }

  function iterateItems(obj, r) {
    const items = [];

    for (const [key, details] of Object.entries(obj)) {
      if (r && !details.latitude && !details.longitude) {
        const randomCoordinate = getRandomCoordinates(...mapData.map.coordinates, r ? r : BASE_RADIUS);
        items.push({ ...randomCoordinate, details })
      } else {
        if (details.latitude && details.longitude && details.propertyStatus !== 'offmarket') {
          items.push({ ...{ lat: parseFloat(details.latitude), lng: parseFloat(details.longitude) }, details })
        } else {
          const randomCoordinate = getRandomCoordinates(parseFloat(details.latitude), parseFloat(details.longitude), r ? r : BASE_RADIUS);
          items.push({ ...randomCoordinate, details })
        }
      }
    }

    return items;
  }

  React.useEffect(() => {
    const notListedProperties = ['offmarket', 'notListed-xml', 'notListed-api', 'notListed-2dc'];
    const ALL_COMING_SOON = _.pickBy(properties, e => _.includes(['comingsoon'], e.propertyStatus))
    const ALL_OFFMARKET = _.pickBy(properties, e => _.includes(notListedProperties, e.propertyStatus))
    const ALL_CURRENT = _.pickBy(properties, e => _.includes(['current', 'On-Sale', 'rental', 'sold'], e.propertyStatus))
    //const ALL_SOLD = _.pickBy(properties, e => _.includes(['sold'], e.propertyStatus))
    //const ALL_RENTAL = _.pickBy(properties, e => _.includes(['rental',], e.propertyStatus))
    let i;
    setComingSoonLength(Object.keys(ALL_COMING_SOON).length);
    setOffMarketLength(Object.keys(ALL_OFFMARKET).length);
    setCurrentLength(Object.keys(ALL_CURRENT).length);
    //setRentalLength(Object.keys(ALL_RENTAL).length);
    //setSoldLength(Object.keys(ALL_SOLD).length);

    // console.log(property.properties.data.data.propertiesFilters, 'DATA')  

    //START OF DUMMY MARKERS GENERATION

    // console.log(property, "PROPERTY")

    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        //markers: [randomCoordinate ],
        center: suburbCoordinates,
        comingsoon: iterateItems(ALL_COMING_SOON),
        offmarket: iterateItems(ALL_OFFMARKET, 200),
        current: iterateItems(ALL_CURRENT),
        //sold: iterateItems(ALL_SOLD), 
        //rental: iterateItems(ALL_RENTAL), 
        comingsoonlimit: comingSoonLength,
        offmarketlimit: offMarketLength,
        currentlimit: currentLength,
        //soldlimit: rentalLength,
        //rentallimit: soldLength,
      }
    }))
    //END OF DUMMY MARKERS GENERATION     
  }, [property]);

  React.useEffect(() => {
    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        maptype: mapType
      }
    }))
  }, [mapType])

  React.useEffect(() => {
    if (suburbCoordinates) {
      setMapData((prevData) => ({
        map: {
          ...prevData.map,
          center: suburbCoordinates,
          coordinates: [suburbCoordinates.lat, suburbCoordinates.lng],
        }
      }))
    }
  }, [suburbCoordinates])

  const activePropertySetter = (property) => {
    setActiveProperty(property)
  }

  const offMarketToggleSetter = (value) => {
    setoffMarketToggle(value);
  }

  const visibleOffmarketItems = (items) => {
    //if(!visibleOffMarkets.some(v => v["property"] === item.property)) {
    setVisibleOffMarkets((prevData) => items)
    //}
  }

  function getMainPath() {
    if (props.match.url === '/buy/map-view') {
      localStorage.setItem('previous_path', 'buy')
      return 'buy'
    }
    if (props.match.url === '/rent/map-view') {
      localStorage.setItem('previous_path', 'rent')
      return 'rent'
    }
    if (props.match.url === '/sold/map-view') {
      localStorage.setItem('previous_path', 'sold')
      return 'sold'
    }
  }

  React.useEffect(() => {
    if (isFiltered) {
      let suburb = [];
      if (isFiltered.suburbs && isFiltered.suburbs.length) {
        isFiltered.suburbs.map((item, index) => {
          suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode)
        })
      }
      setSuburbs(suburb)
    }
  }, [isFiltered])

  function showOptionBottomModal() {
    setShowModalBottomOptions(!showModalBottomOptions)
  }

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  const handleFilter = (toggleStatus) => {
    let propertyData = Object.assign({}, property && property.properties && property.properties.data.data);
    let propertiesFilters = Object.assign([], propertyData.propertiesFilters);
    let filteredData = propertiesFilters;

    if (!toggleStatus[0].active && !toggleStatus[1].active) {
      propertyData = Object.assign({}, property.properties.data.data);
      propertiesFilters = Object.assign([], propertyData.propertiesFilters)
      filteredData = propertiesFilters;
    } else {

      filteredData = _.filter(propertiesFilters, function (o) {
        let propertyStatus = o.propertyStatus ? o.propertyStatus.toLowerCase().replace('-', '') : null;
        if (toggleStatus[0].active && toggleStatus[1].active && propertyStatus) {
          return propertyStatus === toggleStatus[0].label || propertyStatus === toggleStatus[1].label;
        } else if (toggleStatus[0].active && !toggleStatus[1].active && propertyStatus) {
          return propertyStatus === toggleStatus[0].label;
        } else if (!toggleStatus[0].active && toggleStatus[1].active && propertyStatus) {
          return propertyStatus === toggleStatus[1].label;
        } else {
          return o;
        }
      });
    }

    const data = filteredData;

    const pageData = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      propertiesFilters: paginate(data, pagination.pageSize, pagination.pageNumber),
      totalResults: data.length
    }

    const pData = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      propertiesFilters: data,
      totalResults: data.length
    }

    setSearchResultProperties(pData);
    setPagination(pageData)
  }

  /** Save Property Search API */
  function apiRequestSaveSearch(params) {
    setIsLoading(true);

    savePropertySearch(params);
  }

  /** Show Save Search */
  function showSaveSearchModal(params) {
    setShowSaveSearch(params)
  }

  const paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size);
  };

  const type = 'search', sortType = 'search';

  return (
    <div className={classes.root}>
      <div ref={topContainer} className={classes.topAnchor}></div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />
      <SearchFilterHeader {...other} showSearchBar={false} showFilters={false} getRoutePath={getRoutePath} />
      <Grid container spacing={4} className={classes.columnWrapper}>
        <Grid item xs={6} className={classes.cardColumn} style={{ height: (window.innerHeight - topOffset) }}>
          <SavedSearch
            showSaveSearch={showSaveSearch}
            filters={filters}
            showSaveSearchModal={showSaveSearchModal}
            apiRequestSaveSearch={apiRequestSaveSearch}
            path={getRoutePath()}
          />

          <div className={classes.propFilters}>
            <PropertySearchFilter
              handleFilter={handleFilter}
            />
          </div>

          {isFiltered && isFiltered.isActive && type === 'search' && properties && properties.propertiesFilters && properties.propertiesFilters.length > 0 &&
            <>
              <SavedSearch
                propertyCount={properties ? properties.totalResults : 0}
                propertySuburbs={suburbs}
                authentication={authentication}
                settings={settings}
                toggleSignUp={toggleSignUp}
                apiRequestSaveSearch={apiRequestSaveSearch}
                showSaveSearchModal={showSaveSearchModal} />

            </>
          }

          {
            properties && properties.propertiesFilters && properties.propertiesFilters.length > 0 &&
            <>
              <SortSelection
                sortType={sortType}
                current={properties ? properties.propertiesFilters ? properties.propertiesFilters.length : 0 : 0}
                total={properties ? properties.totalResults : 0}
                setSearchSortingFilters={setSearchSortingFilters}
                filters={filters}
                showModalBottomOptions={showModalBottomOptions}
                showOptionBottomModal={showOptionBottomModal}
              />
            </>
          }


          <MapPropertyCards
            pathType={'property'}
            properties={properties}
            activeProperty={activeProperty}
            filterOffmarkets={offMarketToggle}
            visibleOffMarkets={visibleOffMarkets}

            currentScreen={previousScreen}
            currentPath={getMainPath()}
            setCurrentProperty={setCurrentPropertySelected} />
        </Grid>
        <Grid item xs={6} className={classes.mapColumn}>
          <div className={classes.mapWrapper} ref={mapWrapperRef} style={{ height: (window.innerHeight - topOffset) }}>
            <PropertySearchMapView style={{ height: '674px' }}
              data={{
                mode: 'map',
                title: 'Map View',
                ...mapData,
                address: '',
                radius: BASE_RADIUS,
                filteroffmarket: offMarketToggle
              }}

              activePropertySetter={activePropertySetter}
              offMarketToggleSetter={offMarketToggleSetter}
              visibleOffmarketItems={visibleOffmarketItems}
            />

            {offMarketLength && <div className={classes.offMarketToggle}>
              <Button
                variant="contained"
                disableElevation
                color={offMarketToggle ? 'secondary' : 'inherit'}
                style={{ backgroundColor: !offMarketToggle ? '#ffffff' : '' }}
                onClick={() => {
                  setoffMarketToggle(!offMarketToggle);
                  setActiveProperty(null);
                }}
              >
                {!offMarketToggle && <CheckBoxOutlineBlankIcon fontSize="small" />}
                <span className={classes.checkWrapper}>{offMarketToggle && <CheckBoxIcon fontSize="small" color={offMarketToggle ? 'primary' : 'inherit'} />}</span>

                <span>{offMarketLength} Off Market Properties</span>
              </Button>
            </div>}
            <div className={classes.propertyCards}>
              <div style={{ position: 'relative' }}>
                <div className={classes.mapTypeToggle}>
                  <Button
                    variant="contained"
                    disableElevation

                    color={mapType ? 'primary' : 'inherit'}

                    onClick={() => {
                      setMapType(!mapType);
                    }}
                  >
                    <SatelliteIcon fontSize="small" />
                    <span>Satellite</span>
                  </Button>
                </div>
              </div>

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleSignUp: checked => toggleSignUp(checked),
      toggleFilter: checked => toggleFilter(checked),
      saveProperty: params => requestPropertySave(params),
      savePropertySearch: params => requestPropertySaveSearch(params),
      searchBuyProperty: params => requestPropertySearch(params),
      searchSoldProperty: params => requestPropertySearchSold(params),
      searchRentProperty: params => requestPropertySearchRentals(params),
      setCurrentProperty: params => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: params => setPropertySearchFilters(params),
      setSearchSortingFilters: params => setSortingFilters(params),
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FavoritesMap));
