import React from 'react'

import styled from 'styled-components'

import {
  Box,
  Typography,
  Grid,
  withStyles,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { PRICE_RANGE } from 'constants/constants'
import { NumericFormat } from 'react-number-format/dist/react-number-format.cjs'
import SelectField from './SelectField'

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
  .slider-Bedrooms {
    .MuiSlider-markLabel[data-index='0'] {
      left: 4% !important;
    }
  }
`

const useStyles = makeStyles((theme) => ({
  priceInputField: {
    width: '150px',
    height: '55px',
    borderRadius: '4px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    padding: '0px 10px 0px 10px',
    border: 'solid #c0c0c0 1px',

    '&:hover': {
      borderColor: 'black',
    },
    '&:focus': {
      border: 'solid #35C0CA 2px',
      outline: 'none',
    },
  },

  priceInputFieldError: {
    border: 'solid red 1px',

    '&:hover': {
      borderColor: 'red',
    },
    '&:focus': {
      border: 'solid red 2px',
      outline: 'none',
    },
  },
}))

function formatUSD(num) {
  return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PriceInputField = ({ value, onValueChange, onBlur, isAllowed, errorMessage }) => {
  const classes = useStyles()

  return (
    <Box>
      <NumericFormat
        className={`${classes.priceInputField} ${errorMessage ? classes.priceInputFieldError : ''
          }`}
        value={value}
        prefix={'$'}
        thousandSeparator=","
        onValueChange={onValueChange}
        onBlur={onBlur}
        isAllowed={isAllowed}
        allowNegative={false}
      />
      {errorMessage && (
        <Typography
          style={{ margin: '3px 0px 0px 3px', color: 'red', fontSize: '12px' }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}

const PriceOptions = ({
  pricePrecise,
  setPricePrecise,
  handlePriceValid,
  priceOption,
  setPriceOption,
  priceRangeMin,
  setPriceRangeMin,
  priceRangeMax,
  setPriceRangeMax,
  handleData,
}) => {
  return (
    <SliderItem>
      {priceOption === 'Price Range' ? (
        <Box>
          <Typography>Price Range:</Typography>

          <Box marginTop="10px" display="flex">
            <PriceInputField
              value={priceRangeMin}
              onValueChange={(price) => setPriceRangeMin(price.floatValue)}
              onBlur={() => handleData()}
              isAllowed={(price) => {
                if (price.floatValue < PRICE_RANGE[1]) {
                  const predictedMax = price.floatValue < PRICE_RANGE[1] ? price.floatValue + price.floatValue / 100 * 10 : PRICE_RANGE[1]
                  setPriceRangeMax(predictedMax <= PRICE_RANGE[1] && price.floatValue < PRICE_RANGE[1] ? predictedMax : PRICE_RANGE[1])
                } else {
                  setPriceRangeMax(PRICE_RANGE[1])
                }

                return handlePriceValid(
                  0,
                  PRICE_RANGE[1],
                  price.floatValue,
                  setPriceRangeMin
                )
              }}
            />
            <Box position="relative" height="55px" width="30px">
              <Typography
                style={{ position: 'absolute', top: '17%', left: '42%' }}
                variant="h6"
              >
                -
              </Typography>
            </Box>
            <PriceInputField
              value={priceRangeMax}
              onValueChange={(price) => setPriceRangeMax(price.floatValue)}
              onBlur={() => handleData()}
              isAllowed={(price) => {
                return handlePriceValid(
                  0,
                  PRICE_RANGE[1],
                  price.floatValue,
                  setPriceRangeMax
                )
              }}
              errorMessage={
                priceRangeMax < priceRangeMin
                  ? `Price should be higher than ${formatUSD(priceRangeMin)}`
                  : ''
              }
            />
          </Box>
        </Box>
      ) : (
        <Box display="grid" gridGap="10px">
          <Typography>Price:</Typography>
          <PriceInputField
            value={pricePrecise}
            onValueChange={(price) => setPricePrecise(price.floatValue)}
            onBlur={() => handleData()}
            isAllowed={(price) => {
              return handlePriceValid(
                0,
                PRICE_RANGE[1],
                price.floatValue,
                setPricePrecise
              )
            }}
          />
        </Box>
      )}

      <Box marginY="20px" display="grid" gridGap="5px">
        <Typography>Price View:</Typography>
        <Box width="380px">
          <SelectField
            options={['Single Price', 'Price Range', 'POA', 'Contact Agent']}
            placeholder={'Price Options'}
            errorMsg={'Price Option is required.'}
            value={priceOption}
            selected={priceOption}
            onChange={(e, value) => {
              setPriceOption(value)
            }}
          />
        </Box>
      </Box>
    </SliderItem>
  )
}

export default PriceOptions
