import { PURGE } from 'redux-persist'
import {
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  TOGGLE_THEME_MODE,
  TOGGLE_SIGN_UP,
  TOGGLE_FILTER,
  TOGGLE_FILTER_WEB,
  TOGGLE_FORGOT_PASSWORD,
  CLEAR_SESSION,
  MPP_SCROLL,
} from '../constants'

const INITIAL_STATE = {
  isDarkMode: false,
  showSignUp: false,
  showFilter: false,
  showForgotPassword: false,
  showFilterWeb: { type: null, status: false },
  isLoading: false,
  tab: 0,
  mppScroll: false,
}

export default function settingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    /** Clear Session */
    case CLEAR_SESSION:
      return {
        ...INITIAL_STATE,
      }

    case TOGGLE_THEME_MODE:
      if (action.enableDark) {
        return {
          ...state,
          isDarkMode: true,
        }
      }
      return {
        ...state,
        isDarkMode: false,
      }

    case TOGGLE_SIGN_UP:
      return {
        ...state,
        showSignUp: action.showSignUp,
        tab: action.targetTab ?? 0,
      }

    case TOGGLE_FORGOT_PASSWORD:
      return {
        ...state,
        showForgotPassword: action.showForgotPassword,
      }

    case MPP_SCROLL:
      return {
        ...state,
        mppScroll: action.mppScroll,
      }

    case TOGGLE_FILTER:
      return {
        ...state,
        showFilter: action.showFilter,
      }

    case TOGGLE_FILTER_WEB:
      return {
        ...state,
        showFilterWeb: action.showFilter,
      }

    /** API Request Actions */
    case REGISTER_USER_REQUEST:
    case LOGIN_USER_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    /** Action Done */
    case REGISTER_USER_SUCCESS:
    case REGISTER_USER_FAIL:
    case LOGIN_USER_SUCCESS:
    case LOGIN_USER_FAIL:
    case FORGOT_PASSWORD_SUCCESS:
    case FORGOT_PASSWORD_FAIL:
    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
      }
    case PURGE:
      return INITIAL_STATE
    default:
      return state
  }
}

export const getIsLoadingState = (state) => state.isLoading
export const getIsDarkMode = (state) => state.isDarkMode
