import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CloseIcon from '@material-ui/icons/Close'
import DynamicIcons from '../DynamicIcons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import styled, { css } from 'styled-components'

import { toggleSignUp, logoutRequest } from '../../../store/actions'

import {
  makeStyles,
  withStyles,
  Drawer,
  CssBaseline,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core'
import useFilters from 'utils/customHooks/useFilters'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const ListItemWrap = styled.div`
  width: 90%;
  margin: 0 auto;
  border-bottom: 0.5px solid #9b9b9b;
  &:first-child {
    border-top: 0.5px solid #9b9b9b;
  }
  > div {
    padding: 0;
  }
  .MuiListItemIcon-root {
    min-width: 35px;
  }
  .icon-right {
    min-width: auto;
  }
  .item-icons {
    svg {
      font-size: 16px;
    }
  }
  .item-rent {
    svg {
      transform: rotate(90deg);
    }
  }
`

const NameWrap = styled.div`
  padding: 15px 0;
  h1 {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.52px;
    line-height: 1;
    text-align: right;
    color: #fff;
  }
`

const MenuFooterWrap = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  a {
    display: block;
    border-right: 1px solid #fff;
    color: #fff;
    font-family: Roboto;
    font-size: 12px;
    padding: 0 5px;
    line-height: 1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:last-child {
      border-right: 0;
    }
  }
`

const AuthButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 24px;
`

const ContentContainer = styled.div``

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '7px 10px',
    minWidth: '100px',
  },
})(Button)

const drawerWidth = '100%'

const DEFAULT_NAVIGATION = [
  {
    name: 'Home',
    icon: 'Home',
    path: '/',
  },
  {
    name: 'Find Your Perfect Property',
    icon: 'ThumbUpAlt',
    path: '/perfect-property/filters',
  },
  {
    name: 'Find Hush-Hush properties',
    icon: 'WatchLater',
    path: '/hush-hush/filters',
  },
  {
    name: 'Buy',
    icon: 'AttachMoney',
    path: '/buy/filters',
  },
  {
    name: 'Rent',
    icon: 'VpnKey',
    path: '/rent/filters',
  },
  {
    name: 'Sold',
    icon: 'Gavel',
    path: '/sold/filters',
  },
  {
    name: 'Contact Us',
    icon: 'ContactMail',
    path: '/contact-us',
  },
  /* {
    name: 'Find a trade or service',
    icon: 'Build',
    path: '/trades',
  }, 
  {
    name: 'Find an agent',
    icon: '2DCHousePin',
    path: '/agent',
  }*/
]

const LOGGED_IN_NAVIGATION = [
  {
    name: 'My favourite properties',
    icon: 'Favorite',
    path: '/favourites',
  },
  /* {
  name: 'My liked properties',
  icon: 'ThumbUpAlt',
  path: '/liked-properties',
},  */
  {
    name: 'Saved searches',
    icon: '2DCSavedSearchIcon',
    path: '/saved-searches',
  },
  {
    name: 'Account settings',
    icon: 'Settings',
    path: '/account-settings',
  },
]

const styles = (theme) => ({})

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: drawerWidth,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#000',
  },
  drawerPaperLogged: {
    width: drawerWidth,
    backgroundColor: '#666',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
  },
  drawerHeaderLogged: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  logocontainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0 24px 24px',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  logocontainerlogged: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: '0 24px 24px',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  logo: {
    width: 50,
    height: 50,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  list: {
    paddingTop: theme.spacing(3),
  },
  listItem: {
    // flex: 1,
    height: 50,
    width: '100%',
    color: '#FFF',
    borderBottomColor: 'red',
    borderBottomWidth: 1,
  },
  listIcons: {
    color: '#FFF',
  },
  divider: {
    borderColor: '#FFF',
  },
}))

const SEARCH_REFERENCE_FILTERS = [
  '/perfect-property/filters',
  '/hush-hush/filters',
  '/buy/filters',
  '/rent/filters',
  '/sold/filters',
]

const Sidebar = (props) => {
  const { settings, authentication, logoutUser, toggleSignUp, users, routerChange } =
    props
  const classes = useStyles()
  const theme = useTheme()
  const [getFilter, setFilter] = useFilters()
  const SEARCH_BUTTONS = getFilter('search_reference')

  const { triggerAnalyticsEvent } = useGoogleEvents()

  function checkTokenStatus() {
    if (authentication.token) {
      logoutUser()
      routerChange('/')
    } else {
      // toggleSignUp(!settings.showSignUp)
      routerChange('/login')
    }
  }

  function handleEvents(obj) {
    triggerAnalyticsEvent({
      event: 'menu_actions',
      eventFrom: 'Mobile',
      actionScope: 'menu',
      actionName: 'Menu',
      actionTarget: 'Clicked ' + obj?.path + ' from Menu',
      actionReference: 'menu',
    })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={props.open}
        classes={{
          paper:
            props.auth === 'logged' ? classes.drawerPaperLogged : classes.drawerPaper,
        }}
      >
        <ContentContainer>
          <div
            className={
              props.auth === 'logged'
                ? classes.drawerHeaderLogged
                : classes.drawerHeader
            }
          >
            <IconButton onClick={props.handleToggleDrawer}>
              <CloseIcon className={classes.listIcons} />
            </IconButton>
          </div>
          <div
            className={
              props.auth === 'logged'
                ? classes.logocontainerlogged
                : classes.logocontainer
            }
          >
            <DynamicIcons
              style={{ width: '48px', height: '70px' }}
              icon={'2DCPIconColor'}
            />

            {props.auth === 'logged' && (
              <NameWrap>
                <h1>
                  Welcome{' '}
                  {props.auth === 'logged' && users && users.user && users.user.name
                    ? users.user.name
                    : ''}{' '}
                </h1>
              </NameWrap>
            )}
          </div>
          <List className={classes.list}>
            {DEFAULT_NAVIGATION.map((value, index) => (
              <ListItemWrap key={'list' + index}>
                <ListItem
                  button
                  className={classes.listItem}
                  key={'list' + index}
                  onClick={() => {
                    handleEvents(value)

                    if (SEARCH_REFERENCE_FILTERS.includes(value.path)) {
                      if (
                        value.path
                          .toLowerCase()
                          .replace('/filters', '')
                          .replace('/', '') === 'hush-hush'
                      ) {
                        const homeButtons = SEARCH_BUTTONS.map((item) =>
                          item.path.toLowerCase().replace('/', '') === 'coming-soon' ||
                          item.path.toLowerCase().replace('/', '') === 'off-market'
                            ? { ...item, active: true }
                            : { ...item, active: false }
                        )
                        setFilter({ key: 'search_reference', value: homeButtons })
                      } else {
                        const homeButtons = SEARCH_BUTTONS.map((item) =>
                          item.path.toLowerCase().replace('/', '') ===
                          value.path
                            .toLowerCase()
                            .replace('/filters', '')
                            .replace('/', '')
                            ? { ...item, active: true }
                            : { ...item, active: false }
                        )
                        setFilter({ key: 'search_reference', value: homeButtons })
                      }
                    }

                    if (value.name === 'Off market properties') {
                      localStorage.setItem('isOffMarket', true)
                    } else {
                      localStorage.removeItem('isOffMarket')
                    }
                    routerChange(value.path)
                  }}
                >
                  <ListItemIcon
                    className={
                      'item-icons item-' + value.name.replace(/\s+/g, '-').toLowerCase()
                    }
                  >
                    <DynamicIcons className={classes.listIcons} icon={value.icon} />
                  </ListItemIcon>
                  <ListItemText primary={value.name} />
                  <ListItemIcon className="icon-right">
                    <ChevronRightIcon className={classes.listIcons} />
                  </ListItemIcon>
                </ListItem>
              </ListItemWrap>
            ))}

            {props.auth === 'logged' &&
              LOGGED_IN_NAVIGATION.map((value, index) => (
                <ListItemWrap key={'list' + index}>
                  <ListItem
                    button
                    className={classes.listItem}
                    key={'list' + index}
                    onClick={() => {
                      routerChange(value.path)
                      handleEvents(value)
                    }}
                  >
                    <ListItemIcon
                      className={
                        'item-icons item-' +
                        value.name.replace(/\s+/g, '-').toLowerCase()
                      }
                    >
                      <DynamicIcons className={classes.listIcons} icon={value.icon} />
                    </ListItemIcon>
                    <ListItemText primary={value.name} />
                    <ListItemIcon className="icon-right">
                      <ChevronRightIcon className={classes.listIcons} />
                    </ListItemIcon>
                  </ListItem>
                </ListItemWrap>
              ))}
          </List>
          <AuthButtonWrap>
            <StyledButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                checkTokenStatus()
                handleEvents({ path: 'login' })
              }}
            >
              {props.auth === 'logged' ? 'Sign Out' : 'Sign in'}
            </StyledButton>
          </AuthButtonWrap>
          <MenuFooterWrap>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                routerChange('/terms-and-condition')
              }}
            >
              Terms & Conditions
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault()
                routerChange('/privacy-policy')
              }}
            >
              Privacy Policy
            </a>
          </MenuFooterWrap>
        </ContentContainer>
      </Drawer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    authentication: state.authentication,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      logoutUser: () => logoutRequest(),
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar))
