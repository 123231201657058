import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import {
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Paper,
  IconButton,
  Fade,
  Button,
  Box,
  Grid,
  withStyles,
} from '@material-ui/core'
import PopUpDropdownField from '../Form/PopupDropdownField'
import { bindActionCreators } from 'redux'
import { connect, useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import HeadingContent from '../Form/HeadingContent'
import FormField from '../Form/FormField'
import FormNavigationGroup from '../Form/FormNavigationGroup'
import SocialButtonGroup from '../Form/SocialButtonGroup'
import AlertNotification from '../Form/AlertNotification'
import Loader from '../Loader'
import Image from 'components/Web/Image'

import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp'

import OptionModalBottom from '../Form/OptionModalBottom'
import ImageContent from 'assets/images/content/guy-chair-chat-no-plant.svg'
import AgencyPaymentSection from 'components/Common/Billing/AgencyPaymentSection'

import {
  AGENCY_REGISTER_VALIDATION_CONFIG,
  AGENCY_AUTHORIZATION_REGISTER_VALIDATION_CONFIG,
  STATES,
  CRMS,
  HOW_DID_YOU_FIND_US,
} from '../../../constants/constants'

/** actions */
import {
  toggleThemeMode,
  toggleSignUp,
  navigateReset,
  agencyv2Actions,
} from '../../../store/actions'

/** api redux request */
import { requestPOSTAPIAgency } from '../../../store/api'

import checkField from 'components/Common/Validator/validationService'
import NotificationDialog from '../CustomDialogs/NotificationDialog'
import SelectField from '../Form/SelectField'
import AgencySearchField from '../../Common/Fields/AgencySearchField'
import SuburbSearchField from '../../Common/Fields/SuburbSearchField'
import ContentDialog from 'components/Web/CustomDialogs/ContentDialog'
import {
  getAgencyRegistration,
  getAgencyRegistrationContactAdmin,
  getAuthentication,
} from 'store/selectors/agencyv2.selectors'
import { getSettings } from 'store/selectors/generic.selectors'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    //padding: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      outline: 'none',
    },
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
  },
  container: {
    padding: 0,
  },
  /** Bottomn Option Modal */
  optionBottomContainer: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    '&:hover': {
      outline: 'none',
    },
  },
  optionBottomHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  optionBottomHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionBottomHeaderText: {
    fontWeight: '300',
  },
  authorizationForm: {
    minHheight: '30vh',
    overflowY: 'scroll',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    fontSize: '1em',
    color: '#4d4d4d',
  },
  formHeading: {
    fontSize: 21,
    lineHeight: '31px',
    letterSpacing: '-0.52px',
    color: '#4d4d4d',
    fontWeight: 300,
    marginBottom: theme.spacing(2),
  },
  loadingwrap: {
    '& .MuiBackdrop-root': {
      maxWidth: 'initial',
      left: 0,
    },
  },
  formPaperWrap: {
    padding: theme.spacing(0, 3.75),
    borderRadius: 38,
    marginBottom: 135,
    maxWidth: 500,
  },
  modalHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 32,
    lineHeight: '30px',
    color: theme.palette.gray.main,
    marginBottom: 35,
    letterSpacing: '-0.78px',
    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
      fontSize: 40,
    },
  },
  modalImageColumn: {
    '& > div': {
      position: 'initial !important',
    },
  },

  confirmationModal: {
    maxWidth: 380,
  },
})

const FormLabel = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px',
  },
})(Typography)

const LinkSignUp = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
    textDecoration: 'underline',
  },
})(Link)

const StyledCheckbox = withStyles({
  root: {
    paddingLeft: 0,
    color: '#4D4D4D',
  },
})(Checkbox)

const MainForm = styled.form`
  padding-top: 20px;
  padding-bottom: 20px;
`

const LinkStyleWrap = styled.div`
  padding-top: 40px;
  text-align: center;
  width: 100%;
`

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #646464;
    letter-spacing: 0.09px;
    a {
      color: #646464;
      text-decoration: underline;
    }
  }
`

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
  },
})(Button)

const AgencySignUpForm = (props) => {
  /** component props */
  const { classes, formStep, setFormStep, location } = props
  const [routerChange] = useRouterChange();

  const ModalContent = (props) => {
    const { heading, paragraph1, paragraph2 } = props
    return (
      <Grid container spacing={2}>
        <Grid item sm={5}>
          <Typography component={'h2'} className={classes.modalHeading}>
            {heading ?? (
              <>
                <strong>good</strong> news
              </>
            )}
          </Typography>
          <Typography
            component={'p'}
            variant={'body2'}
            style={{ paddingBottom: 20, color: '#828282', fontSize: 14 }}
          >
            {paragraph1 ?? (
              <>Your sign up details are now in our PropertyMate system.</>
            )}
          </Typography>
          <Typography
            component={'p'}
            variant={'body2'}
            style={{ color: '#828282', fontSize: 14, marginBottom: 64 }}
          >
            {paragraph2 ?? (
              <>
                Our sales team will call you within the next 24hrs with the next steps.
              </>
            )}
          </Typography>
        </Grid>
        <Grid item sm={7} className={classes.modalImageColumn}>
          <Image
            src={ImageContent}
            style={{ width: 222, left: '38%', top: 47, position: 'absolute' }}
            aspectRatio={4 / 2.6}
            color={'transparent'}
            imageStyle={{
              objectFit: 'contain',
              maxWidth: 222,
              left: 'auto',
              right: '12px',
              bottom: '10px',
              top: 'auto',
              height: 'auto',
            }}
          />
        </Grid>
      </Grid>
    )
  }

  const dispatch = useDispatch()

  const AGENCY_REGISTRATION = useSelector(getAgencyRegistration)
  const AGENCY_CONTACT_ADMIN = useSelector(getAgencyRegistrationContactAdmin)
  const authentication = useSelector(getAuthentication)
  const settings = useSelector(getSettings)

  const handleClearAllStates = () => {
    dispatch(agencyv2Actions(null, 'AGENCY_REGISTER_CONTACT_ADMIN', 'CLEAR'))
    dispatch(agencyv2Actions(null, 'AGENCY_REGISTER', 'CLEAR'))
  }

  const [value, setValue] = React.useState(0)
  //Use `errors` object to immediately show errors on the UI on lost focus or `submittedErrors` to only show errors on form submit
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [message, setMessage] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)

  const [authCompanyName, setAuthCompanyName] = useState('')
  const [authFirstName, setAuthFirstName] = useState('')
  const [authLastName, setAuthLastName] = useState('')
  const [authEmail, setAuthEmail] = useState('')
  const [authPhone, setAuthPhone] = useState('')
  const [adminEmail, setAdminEmail] = useState('')

  const [permission, setPermission] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [businessNumber, setBusinessNumber] = useState('')
  const [suburb, setSuburb] = useState('')
  const [state, setState] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [crm, setCrm] = useState('')
  const [crmSales, setCrmSales] = useState('')
  const [crmRental, setCrmRental] = useState('')
  const [office, setOffice] = useState('')
  const [hdyfu, setHdyfu] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false)
  const [isPrivacyCheckedAuth, setIsPrivacyCheckedAuth] = useState(false)
  const [isTermsChecked, setIsTermsChecked] = useState(false)
  const [postcode, setPostcode] = useState('')
  const [manualCrm, setManualCrm] = useState('')
  const [manualCrmSales, setManualCrmSales] = useState('')
  const [manualCrmRental, setManualCrmRental] = useState('')

  const [manualHdyfu, setManualHdyfu] = useState('')
  const defaultErrors = {
    companyName: { status: false, message: '' },
    companyWebsite: { status: false, message: '' },
    businessNumber: { status: false, message: '' },
    suburb: { status: false, message: '' },
    firstName: { status: false, message: '' },
    lastName: { status: false, message: '' },
    email: { status: false, message: '' },
    office: { status: false, message: '' },
    phone: { status: false, message: '' },
    crm: { status: false, message: '' },
    crmSales: { status: false, message: '' },
    crmRental: { status: false, message: '' },
    hdyfu: { status: false, message: '' },
    postcode: { status: false, message: '' },
    authCompanyName: { status: false, message: '' },
    authFirstName: { status: false, message: '' },
    authLastName: { status: false, message: '' },
    authEmail: { status: false, message: '' },
    authPhone: { status: false, message: '' },
    adminEmail: { status: false, message: '' },
    isPrivacyChecked: { status: false, message: '' },
    manualCrm: { status: false, message: '' },
    manualCrmSales: { status: false, message: '' },
    manualCrmRental: { status: false, message: '' },
    manualHdyfu: { status: false, message: '' },
  }
  const [errors, setErrors] = useState(defaultErrors)

  const [successRegister, setSuccessRegister] = useState(false)
  const [errorRegister, setErrorRegister] = useState(false)
  const [successContact, setSuccessContact] = useState(false)
  const [errorContact, setErrorContact] = useState(false)

  const [agencySelector, setAgencySelector] = useState('')
  const [selectedAgency, setSelectedAgency] = useState(null)
  const [firstStepStatus, setFirstStepStatus] = useState(true)

  const [agencyFieldOpen, setAgencyFieldOpen] = useState(false)
  const [suburbFieldOpen, setSuburbFieldOpen] = useState(false)
  const [agencyReference, setAgencyReference] = useState(null)

  const regFormCheck = (fields, errors) => {
    var reqFields = _.pickBy(fields, (e) => (e ? true : false)),
      setfields = fields
    if (Object.keys(reqFields).length === Object.keys(setfields).length) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }

  const firstStepCheck = (fields, errors) => {
    var reqFields = _.pickBy(fields, (e) => (e ? true : false)),
      setfields = fields
    if (
      Object.keys(reqFields).length === Object.keys(setfields).length ||
      (permission && permission === 'not authorised')
    ) {
      setFirstStepStatus(false)
    } else {
      setFirstStepStatus(true)
    }
  }

  /** Listeners */
  /** Reducer Changes Listeners */

  useEffect(() => {
    regFormCheck(
      {
        companyName,
        companyWebsite,
        businessNumber,
        suburb,
        firstName,
        lastName,
        email,
        office,
        phone,
        postcode,
        //crm,
        //crmSales,
        //crmRental ,
        hdyfu,
        adminEmail,
        isTermsChecked,
      },
      errors
    )
  }, [
    companyName,
    companyWebsite,
    businessNumber,
    suburb,
    firstName,
    lastName,
    email,
    office,
    phone,
    postcode,
    //crm,
    //crmSales,
    //crmRental ,
    hdyfu,
    adminEmail,
    isTermsChecked,
  ])

  useEffect(() => {
    firstStepCheck(
      {
        companyName,
        companyWebsite,
        businessNumber,
        suburb,
        postcode,
        //crm,
        //crmSales,
        //crmRental ,
        hdyfu,
      },
      errors
    )
  }, [
    companyName,
    companyWebsite,
    businessNumber,
    suburb,
    postcode,
    //crm,
    //crmSales,
    //crmRental ,
    hdyfu,
    permission,
  ])

  useEffect(() => {
    regFormCheck(
      {
        authCompanyName,
        authFirstName,
        authLastName,
        authEmail,
        authPhone,
        isPrivacyCheckedAuth,
      },
      errors
    )
  }, [
    authCompanyName,
    authFirstName,
    authLastName,
    authEmail,
    authPhone,
    isPrivacyCheckedAuth,
  ])

  /** API Error Response Handler */
  function handleAPIErrorResponse() {
    /** Registration */
    if (AGENCY_REGISTRATION?.status === 'agency/AGENCY_REGISTER_SUCCESS') {
      setIsLoading(false)
      setSuccessRegister(true)
      if (AGENCY_REGISTRATION?.payload_success?.data?.data?.entity?._id) {
        setAgencyReference(
          AGENCY_REGISTRATION?.payload_success?.data?.data?.entity?._id
        )
        routerChange(
          '/agency/payment-details/' +
          AGENCY_REGISTRATION?.payload_success?.data?.data?.entity?._id +
          '?step=3'
        )
      }

      // handleNotification(true, {severity: 'success', message: "Registration Submitted!"});
      return
    }

    if (AGENCY_REGISTRATION?.status === 'agency/AGENCY_REGISTER_FAILED') {
      setIsLoading(false)
      setErrorRegister(true)
      // handleNotification(true, {severity: 'error', message: "Error submitting registration!"})
      return
    }

    /** Contact Admin */
    if (
      AGENCY_CONTACT_ADMIN?.status === 'agency/AGENCY_REGISTER_CONTACT_ADMIN_SUCCESS'
    ) {
      setIsLoading(false)
      setSuccessContact(true)
      // handleNotification(true, {severity: 'success', message: "Contact request sent!"});
      return
    }

    if (
      AGENCY_CONTACT_ADMIN?.status === 'agency/AGENCY_REGISTER_CONTACT_ADMIN_FAILED'
    ) {
      setIsLoading(false)
      setErrorContact(true)
      // handleNotification(true, {severity: 'error', message: "Error submitting contact request!"})
      return
    }
  }

  useEffect(() => {
    handleClearAllStates()
    const params = new URLSearchParams(location?.search)
    if (params.get('redirect_status')) {
      switch (params.get('redirect_status')) {
        case 'succeeded':
          setMessage('Subscription successful!')
          setPaymentStatus('success')
          break
        case 'processing':
          setMessage('Your subscription is processing.')
          setPaymentStatus('processing')
          break
        case 'requires_payment_method':
          setMessage('Your subscription was not successful, please try again.')
          setPaymentStatus('unsuccessful')
          break
        default:
          setMessage('Something went wrong.')
          setPaymentStatus('error')
          break
      }
    }
    return () => {
      handleClearAllStates()
    }
  }, [])

  useEffect(() => {
    handleAPIErrorResponse()
  }, [AGENCY_REGISTRATION, AGENCY_CONTACT_ADMIN])

  useEffect(() => {
    setIsButtonDisabled(true)
  }, [permission])

  useEffect(() => {
    if (suburb && suburb?.postcode) {
      setPostcode(suburb?.postcode)
      if (suburb?.state) {
        setState(suburb?.state)
      }
    }
  }, [suburb])

  async function apiRequestAgencyRegister() {
    handleClearAllStates()
    let request = {
      query: null,
      data: {
        signUpPermission: permission,
        email: email,
        adminEmail: adminEmail,
        firstName: firstName,
        lastName: lastName,
        companyName: companyName,
        companyWebsite: companyWebsite,
        abn: businessNumber,
        phone: phone,
        crm: crmSales === 'Other' ? manualCrmSales : crmSales,
        crmSales: crmSales === 'Other' ? manualCrmSales : crmSales,
        crmRental: crmRental === 'Other' ? manualCrmRental : crmRental,
        postcode: postcode.toString(),
        state: state,
        suburb: suburb?.locality,
        hdyfu: hdyfu === 'Other' ? manualHdyfu : hdyfu,
        newsletterSignup: isPrivacyChecked,
        contactSendListingOffice: office,
      },
    }

    let contactRequest = {
      query: null,
      data: {
        email: authEmail,
        firstName: authFirstName,
        lastName: authLastName,
        companyName: authCompanyName,
        phone: authPhone,
      },
    }
    if (permission !== 'not authorised') {
      // agencyRegister(request);

      dispatch(requestPOSTAPIAgency(request, 'AGENCY_REGISTER'))
    } else {
      dispatch(requestPOSTAPIAgency(contactRequest, 'AGENCY_REGISTER_CONTACT_ADMIN'))
    }
  }

  const handleReload = () => {
    setPermission('')
    setCompanyWebsite('')
    setBusinessNumber('')
    setSuburb('')
    setState('')
    setManualCrm('')
    setManualCrmSales('')
    setManualCrmRental('')
    setOffice('')
    setHdyfu('')
    setManualHdyfu('')
    setIsPrivacyChecked(false)
    setIsTermsChecked(false)
    setPostcode('')
    setEmail('')
    setAdminEmail('')
    setFirstName('')
    setLastName('')
    setCompanyName('')
    setPhone('')

    setAuthFirstName('')
    setAuthLastName('')
    setAuthCompanyName('')
    setAuthPhone('')
    setAuthEmail('')

    setIsButtonDisabled(true)
    setErrors(defaultErrors)
    handleClearAllStates()
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  async function handleCheckField(state, value, fieldName) {
    if (
      checkField(
        state,
        AGENCY_REGISTER_VALIDATION_CONFIG(fieldName).fields[fieldName]
      ) === null
    ) {
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: { ...prevState[fieldName], status: false },
      }))
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [fieldName]: {
          ...prevState[fieldName],
          status: true,
          message: checkField(
            value,
            AGENCY_REGISTER_VALIDATION_CONFIG(fieldName).fields[fieldName]
          ),
        },
      }))
    }
  }

  const handleSubmit = () => {
    setIsLoading(true)
    apiRequestAgencyRegister()
  }

  const resetAgencySelection = () => {
    setBusinessNumber('')
    setCompanyWebsite('')
    setSuburb('')
    setState('')
    setPostcode('')
    setOffice('')
    setPhone('')
  }

  const handleSelectedAgency = (agency) => {
    setBusinessNumber(
      agency.abn !== ''
        ? agency.abn.replace(/\D/g, '').replace(/ /g, '')
        : agency.abn2.replace(/\D/g, '').replace(/ /g, '')
    )
    setCompanyWebsite(agency.Website)
    setSuburb(agency.city)
    setState(agency.state)
    setPostcode(agency.postcode)
    setOffice(agency.city)
    setEmail(agency.email)
    setAdminEmail(agency.email)
    setPhone(agency.phone && agency.phone.replace(/\D/g, '').replace(/ /g, ''))
  }

  useEffect(() => {
    if (selectedAgency && selectedAgency.name !== 'Other') {
      handleSelectedAgency(selectedAgency)
    } else {
      resetAgencySelection()
    }
  }, [selectedAgency])

  const renderSignUpForm = () => {
    return (
      <div>
        <Fade in={formStep === 1}>
          <div>
            {formStep === 1 && (
              <>
                <Typography className={classes.formHeading} component={'h2'}>
                  Tell us about your company
                </Typography>

                <span></span>
                <SelectField
                  options={[
                    'an agency owner',
                    'authorised by the agency owner',
                    'not authorised',
                  ]}
                  placeholder={'I am...'}
                  errorMsg={'I am... is required.'}
                  value={permission}
                  selected={permission}
                  onChange={(e, value) => {
                    setPermission(value)
                  }}
                />

                {permission !== 'not authorised' && (
                  <>
                    <AgencySearchField
                      inputValue={agencySelector}
                      setselectedagency={setSelectedAgency}
                      placeholder={'Search for your agency *'}
                      open={agencyFieldOpen}
                      setOpen={setAgencyFieldOpen}
                      onInputChange={(e, value) => {
                        if (value !== 'Other') {
                          setCompanyName(value)
                          setAgencySelector(value)
                          handleCheckField(value, value, 'companyName')
                        } else {
                          setAgencySelector(value)
                          setCompanyName('')
                        }

                        if (!value) {
                          setAgencyFieldOpen(false)
                        }
                      }}
                    />
                    {agencySelector !== '' && agencySelector === 'Other' && (
                      <FormField
                        type={'text'}
                        value={companyName ?? ''}
                        placeholder={'Your Company Name *'}
                        errorMsg={errors.companyName.message}
                        showError={errors.companyName.status}
                        onChange={(e) => {
                          var value = e.target.value
                          setCompanyName(value)
                          handleCheckField(value, value, 'companyName')
                        }}
                        onBlur={(e) => {
                          var value = e.target.value
                          setCompanyName(value)
                          handleCheckField(value, value, 'companyName')
                        }}
                      />
                    )}
                    <FormField
                      type={'url'}
                      value={companyWebsite ?? ''}
                      placeholder={'Company Website *'}
                      errorMsg={errors.companyWebsite.message}
                      showError={errors.companyWebsite.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setCompanyWebsite(value)
                        if (companyWebsite) {
                          handleCheckField(value, value, 'companyWebsite')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setCompanyWebsite(value)
                        handleCheckField(value, value, 'companyWebsite')
                      }}
                    />
                    <FormField
                      type="tel"
                      telformat="99999999999"
                      value={businessNumber ?? ''}
                      placeholder={'ABN *'}
                      errorMsg={errors.businessNumber.message}
                      showError={errors.businessNumber.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setBusinessNumber(value)
                        if (businessNumber) {
                          handleCheckField(value, value, 'businessNumber')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setBusinessNumber(value)
                        handleCheckField(value, value, 'businessNumber')
                      }}
                    />

                    <SuburbSearchField
                      inputValue={suburb}
                      setValueCallback={setSuburb}
                      placeholder={'Suburb *'}
                      errorMsg={errors.suburb.message}
                      open={suburbFieldOpen}
                      setOpen={setSuburbFieldOpen}
                      defaultValue={suburb}
                      onInputChange={(e, value) => {
                        if (value) {
                          setSuburb(value)
                        } else {
                          setSuburbFieldOpen(false)
                        }
                      }}
                    />
                    <FormField
                      type={'number'}
                      value={postcode ?? ''}
                      placeholder={'Postcode *'}
                      errorMsg={errors.postcode.message}
                      showError={errors.postcode.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setPostcode(value)
                        if (postcode) {
                          handleCheckField(value, value, 'postcode')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setPostcode(value)
                        handleCheckField(value, value, 'postcode')
                      }}
                    />
                    <SelectField
                      options={STATES}
                      placeholder={'State'}
                      value={state ?? ''}
                      onChange={(e, value) => {
                        //var value = e.target.value;
                        setState(value)
                        if (value) {
                          handleCheckField(value, value, 'state')
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        setState(value)
                        handleCheckField(value, value, 'state')
                      }}
                    />

                    {/*                                        <SelectField
                      options={CRMS.sort()}
                      placeholder={
                        'Your Bulk Uploader Provider - who you upload listings through *'
                      }
                      value={manualCrm}
                      onChange={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrm(value)
                          setCrm(value)
                          handleCheckField(value, value, 'crm')
                        } else {
                          setManualCrm(value)
                          setCrm('')
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrm(value)
                          setCrm(value)
                          handleCheckField(value, value, 'crm')
                        } else {
                          setManualCrm(value)
                          setCrm('')
                        }
                      }}
                    />
                    {manualCrm && manualCrm !== '' && manualCrm === 'Other' && (
                      <FormField
                        type={'text'}
                        value={crm}
                        placeholder={'Type your Bulk Uploader Provider *'}
                        errorMsg={errors.crm.message}
                        showError={errors.crm.status}
                        onChange={(e) => {
                          var value = e.target.value
                          setCrm(value)
                          if (value) {
                            handleCheckField(value, value, 'crm')
                          }
                        }}
                        onBlur={(e) => {
                          var value = e.target.value
                          setCrm(value)
                          handleCheckField(value, value, 'crm')
                        }}
                      />
                    )} */}

                    <SelectField
                      options={CRMS.sort()}
                      placeholder={'Sales Listing Uploader *'}
                      value={manualCrmSales}
                      onChange={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmSales(value)
                          setCrmSales(value)
                          handleCheckField(value, value, 'crmSales')
                        } else {
                          setManualCrmSales(value)
                          setCrmSales('')
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmSales(value)
                          setCrmSales(value)
                          handleCheckField(value, value, 'crmSales')
                        } else {
                          setManualCrmSales(value)
                          setCrmSales('')
                        }
                      }}
                    />
                    {manualCrmSales &&
                      manualCrmSales !== '' &&
                      manualCrmSales === 'Other' && (
                        <FormField
                          type={'text'}
                          value={crmSales}
                          placeholder={'Sales Listing Uploader *'}
                          errorMsg={errors.crmSales.message}
                          showError={errors.crmSales.status}
                          onChange={(e) => {
                            var value = e.target.value
                            setCrmSales(value)
                            if (value) {
                              handleCheckField(value, value, 'crmSales')
                            }
                          }}
                          onBlur={(e) => {
                            var value = e.target.value
                            setCrmSales(value)
                            handleCheckField(value, value, 'crmSales')
                          }}
                        />
                      )}
                    <SelectField
                      options={CRMS.sort()}
                      placeholder={'Rental Listing Uploader *'}
                      value={manualCrmRental}
                      onChange={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmRental(value)
                          setCrmRental(value)
                          handleCheckField(value, value, 'crmRental')
                        } else {
                          setManualCrmRental(value)
                          setCrmRental('')
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualCrmRental(value)
                          setCrmRental(value)
                          handleCheckField(value, value, 'crmRental')
                        } else {
                          setManualCrmRental(value)
                          setCrmRental('')
                        }
                      }}
                    />
                    {manualCrmRental &&
                      manualCrmRental !== '' &&
                      manualCrmRental === 'Other' && (
                        <FormField
                          type={'text'}
                          value={crmRental}
                          placeholder={'Rental Listing Uploader *'}
                          errorMsg={errors.crmRental.message}
                          showError={errors.crmRental.status}
                          onChange={(e) => {
                            var value = e.target.value
                            setCrmRental(value)
                            if (value) {
                              handleCheckField(value, value, 'crmRental')
                            }
                          }}
                          onBlur={(e) => {
                            var value = e.target.value
                            setCrmRental(value)
                            handleCheckField(value, value, 'crmRental')
                          }}
                        />
                      )}
                    <FormControlLabel
                      style={{
                        color: 'gray',
                        fontFamily: ' "Roboto", "Helvetica", "Arial", "sans-serif"',
                      }}
                      control={
                        <Checkbox
                          style={{ color: '#35C0CA' }}
                          onChange={(event) => {
                            if (event.target.checked) {
                              if (manualCrmSales && manualCrmSales !== 'Other') {
                                setManualCrmRental(crmSales)
                                setCrmRental(crmSales)
                              } else {
                                setManualCrmRental('Other')
                                setCrmRental(crmSales)
                              }
                            }
                          }}
                        />
                      }
                      label="Same as Sales Listing Uploader"
                    />
                    <FormField
                      type={'text'}
                      value={office}
                      placeholder={'Which offices do you want to send listings from? *'}
                      errorMsg={errors.office.message}
                      showError={errors.office.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setOffice(value)
                        if (value) {
                          handleCheckField(value, value, 'office')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setOffice(value)
                        handleCheckField(value, value, 'office')
                      }}
                    />
                    <SelectField
                      options={HOW_DID_YOU_FIND_US}
                      placeholder={'How did you hear about us? *'}
                      value={manualHdyfu}
                      onChange={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualHdyfu(value)
                          setHdyfu(value)
                          handleCheckField(value, value, 'hdyfu')
                        } else {
                          setManualHdyfu(value)
                          setHdyfu('')
                        }
                      }}
                      onBlur={(e, value) => {
                        //var value = e.target.value;
                        if (value && value !== 'Other') {
                          setManualHdyfu(value)
                          setHdyfu(value)
                          handleCheckField(value, value, 'hdyfu')
                        } else {
                          setManualHdyfu(value)
                          setHdyfu('')
                        }
                      }}
                    />
                    {manualHdyfu && manualHdyfu !== '' && manualHdyfu === 'Other' && (
                      <FormField
                        type={'text'}
                        value={hdyfu}
                        placeholder={'How did you find us? *'}
                        errorMsg={errors.hdyfu.message}
                        showError={errors.hdyfu.status}
                        onChange={(e) => {
                          var value = e.target.value
                          setHdyfu(value)
                          if (value) {
                            handleCheckField(value, value, 'hdyfu')
                          }
                        }}
                        onBlur={(e) => {
                          var value = e.target.value
                          setHdyfu(value)
                          handleCheckField(value, value, 'hdyfu')
                        }}
                      />
                    )}
                  </>
                )}
                <Box
                  justifyContent={'space-between'}
                  display="flex"
                  alignItems={'center'}
                >
                  <Typography
                    style={{ fontSize: 21 }}
                    component={'span'}
                    variant={'body1'}
                    display={'inline'}
                  >
                    1 of 3
                  </Typography>
                  <StyledButton
                    style={{ minWidth: 200 }}
                    onClick={() => {
                      setFormStep(2)
                    }}
                    disabled={firstStepStatus}
                    variant="contained"
                    color={'primary'}
                  >
                    {permission !== 'not authorised' ? 'Next' : 'Skip to Step 2'}
                  </StyledButton>
                </Box>
              </>
            )}
          </div>
        </Fade>
        <Fade in={formStep === 2}>
          <div>
            {formStep === 2 && (
              <>
                {permission === 'not authorised' && renderAuthorizationFields()}

                {permission !== 'not authorised' && (
                  <>
                    <FormField
                      type={'text'}
                      value={firstName}
                      placeholder={'First Name *'}
                      errorMsg={errors.firstName.message}
                      showError={errors.firstName.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setFirstName(value)
                        if (firstName) {
                          handleCheckField(value, value, 'firstName')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setFirstName(value)
                        handleCheckField(value, value, 'firstName')
                      }}
                    />
                    <FormField
                      type={'text'}
                      value={lastName}
                      placeholder={'Last Name *'}
                      errorMsg={errors.lastName.message}
                      showError={errors.lastName.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setLastName(value)
                        if (lastName) {
                          handleCheckField(value, value, 'lastName')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setLastName(value)
                        handleCheckField(value, value, 'lastName')
                      }}
                    />
                    <FormField
                      type="tel"
                      value={phone ?? ''}
                      placeholder={'Mobile *'}
                      errorMsg={errors.phone.message}
                      showError={errors.phone.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setPhone(value)
                        if (phone) {
                          handleCheckField(value, value, 'phone')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setPhone(value)
                        handleCheckField(value, value, 'phone')
                      }}
                    />
                    <FormField
                      type={'email'}
                      value={email ?? ''}
                      placeholder={'Your Email *'}
                      errorMsg={errors.email.message}
                      showError={errors.email.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setEmail(value)
                        if (email) {
                          handleCheckField(value, value, 'email')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setEmail(value)
                        handleCheckField(value, value, 'email')
                      }}
                    />
                    <FormField
                      type={'email'}
                      value={adminEmail ?? ''}
                      placeholder={'Admin Email *'}
                      errorMsg={errors.adminEmail.message}
                      showError={errors.adminEmail.status}
                      onChange={(e) => {
                        var value = e.target.value
                        setAdminEmail(value)
                        if (adminEmail) {
                          handleCheckField(value, value, 'adminEmail')
                        }
                      }}
                      onBlur={(e) => {
                        var value = e.target.value
                        setAdminEmail(value)
                        handleCheckField(value, value, 'adminEmail')
                      }}
                    />
                    <Typography
                      variant="caption"
                      style={{
                        display: 'inherit',
                        marginTop: '-10px',
                        position: 'relative',
                      }}
                    >
                      This email will be used as the username
                    </Typography>
                  </>
                )}
                {/* <FormNavigationGroup
                      type={'agencyRegister'}
                      disabled={isButtonDisabled}                 
                      onClick={()=>{
                        handleSubmit()
                      }}
                    /> */}

                <CheckBoxLabel htmlFor="privacy-checkbox">
                  {(permission && permission === '') ||
                    (permission !== 'not authorised' && (
                      <StyledCheckbox
                        checked={isPrivacyChecked}
                        onChange={(e, value) => {
                          setIsPrivacyChecked(value)
                          if (value) {
                            handleCheckField(value, value, 'isPrivacyChecked')
                          }
                        }}
                        id="privacy-checkbox"
                        name="privacy-checkbox"
                        color="primary"
                      />
                    ))}
                  {permission && permission === 'not authorised' && (
                    <>
                      <StyledCheckbox
                        checked={isPrivacyCheckedAuth}
                        onChange={(e, value) => {
                          setIsPrivacyCheckedAuth(value)
                          if (value) {
                            handleCheckField(value, value, 'isPrivacyCheckedAuth')
                          }
                        }}
                        id="privacy-checkbox"
                        name="privacy-checkbox"
                        color="primary"
                      />
                    </>
                  )}
                  <Typography
                    variant="body2"
                    style={{ lineHeight: '1.1em', color: '#646464' }}
                  >
                    Inspire my inbox with insightful property musings
                  </Typography>
                </CheckBoxLabel>
                <CheckBoxLabel htmlFor="terms-checkbox">
                  {permission && permission !== 'not authorised' && (
                    <>
                      <StyledCheckbox
                        checked={isTermsChecked}
                        onChange={(e, value) => {
                          setIsTermsChecked(value)
                          if (value) {
                            handleCheckField(value, value, 'isTermsChecked')
                          }
                        }}
                        id="terms-checkbox"
                        name="terms-checkbox"
                        color="primary"
                      />
                      <Typography
                        variant="body2"
                        style={{ lineHeight: '1.1em', color: '#646464' }}
                      >
                        <Link
                          target="_blank"
                          href="/advertising-agreement"
                          style={{ textDecoration: 'none', color: '#35C0CA' }}
                        >
                          I love your Terms Of Use and promise to abide by them
                        </Link>
                      </Typography>
                    </>
                  )}
                </CheckBoxLabel>
                <Box
                  justifyContent={'space-between'}
                  display="flex"
                  alignItems={'center'}
                >
                  <StyledButton
                    onClick={() => {
                      setFormStep(1)
                    }}
                    variant={'text'}
                    style={{ minWidth: 'initial', fontSize: 18 }}
                  >
                    {' '}
                    &lt; Back
                  </StyledButton>
                  <Typography
                    style={{ fontSize: 21 }}
                    component={'span'}
                    variant={'body1'}
                    display={'inline'}
                  >
                    2 of 3
                  </Typography>
                  <StyledButton
                    style={{ minWidth: 200 }}
                    onClick={() => {
                      handleSubmit()
                    }}
                    disabled={firstStepStatus || isButtonDisabled}
                    variant="contained"
                    color={'primary'}
                  >
                    Next
                  </StyledButton>
                </Box>
              </>
            )}
          </div>
        </Fade>
        <Fade in={formStep === 3}>
          <div>
            {formStep === 3 && (
              <>
                {agencyReference && (
                  <AgencyPaymentSection
                    paymentStatus={paymentStatus}
                    message={message}
                    customerData={{ firstName, lastName, adminEmail, agencyReference }}
                  />
                )}
                {!paymentStatus && !message && (
                  <Box
                    justifyContent={'space-between'}
                    display="flex"
                    alignItems={'center'}
                    style={{ marginTop: 20 }}
                  >
                    <div>
                      <StyledButton
                        onClick={() => {
                          setFormStep(2)
                        }}
                        variant={'text'}
                        style={{ minWidth: 'initial', fontSize: 18 }}
                      >
                        {' '}
                        &lt; Back
                      </StyledButton>
                      <Typography
                        style={{
                          fontSize: 21,
                          verticalAlign: 'middle',
                          marginLeft: 14,
                        }}
                        component={'span'}
                        variant={'body1'}
                        display={'inline'}
                      >
                        3 of 3
                      </Typography>
                    </div>
                    {/* <StyledButton
                      style={{ minWidth: 200 }}
                      variant="contained"
                      color={'primary'}>Finished</StyledButton> */}
                  </Box>
                )}
              </>
            )}
          </div>
        </Fade>
      </div>
    )
  }

  const renderAuthorizationFields = () => {
    return (
      <>
        <Typography variant="body1" className={classes.paragraph}>
          Currently only Agency owners are permitted to sign up for our platform.
        </Typography>
        <Typography variant="body1" className={classes.paragraph}>
          If you are an agent, please leave your details below and we will be in touch
          within 24 hours.
        </Typography>
        <span></span>
        <FormField
          type={'text'}
          value={authCompanyName}
          placeholder={'Your Company Name *'}
          errorMsg={errors.authCompanyName.message}
          showError={errors.authCompanyName.status}
          onChange={(e) => {
            var value = e.target.value
            setAuthCompanyName(value)
            if (authCompanyName) {
              handleCheckField(value, value, 'authCompanyName')
            }
          }}
          onBlur={(e) => {
            var value = e.target.value
            setAuthCompanyName(value)
            handleCheckField(value, value, 'authCompanyName')
          }}
        />
        <FormField
          type={'text'}
          value={authFirstName}
          placeholder={'First Name *'}
          errorMsg={errors.authFirstName.message}
          showError={errors.authFirstName.status}
          onChange={(e) => {
            var value = e.target.value
            setAuthFirstName(value)
            if (authFirstName) {
              handleCheckField(value, value, 'authFirstName')
            }
          }}
          onBlur={(e) => {
            var value = e.target.value
            setAuthFirstName(value)
            handleCheckField(value, value, 'authFirstName')
          }}
        />
        <FormField
          type={'text'}
          value={authLastName}
          placeholder={'Last Name *'}
          errorMsg={errors.authLastName.message}
          showError={errors.authLastName.status}
          onChange={(e) => {
            var value = e.target.value
            setAuthLastName(value)
            if (authLastName) {
              handleCheckField(value, value, 'authLastName')
            }
          }}
          onBlur={(e) => {
            var value = e.target.value
            setAuthLastName(value)
            handleCheckField(value, value, 'authLastName')
          }}
        />
        <FormField
          type={'email'}
          value={authEmail}
          placeholder={'Your Email *'}
          errorMsg={errors.authEmail.message}
          showError={errors.authEmail.status}
          onChange={(e) => {
            var value = e.target.value
            setAuthEmail(value)
            if (authEmail) {
              handleCheckField(value, value, 'authEmail')
            }
          }}
          onBlur={(e) => {
            var value = e.target.value
            setAuthEmail(value)
            handleCheckField(value, value, 'authEmail')
          }}
        />
        <FormField
          type={'tel'}
          value={authPhone}
          placeholder={'Your Contact Phone *'}
          errorMsg={errors.authPhone.message}
          showError={errors.authPhone.status}
          onChange={(e) => {
            var value = e.target.value.replace(/\D/g, '').replace(/ /g, '')
            setAuthPhone(value)
            if (authPhone) {
              handleCheckField(value, value, 'authPhone')
            }
          }}
          onBlur={(e) => {
            var value = e.target.value
            setAuthPhone(value)
            handleCheckField(value, value, 'authPhone')
          }}
          maxlength={14}
          telformat={'9999999999'}
        />
      </>
    )
  }

  return (
    <div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <div className={classes.loadingwrap}>
        <Loader isLoading={isLoading} message={'Loading...'} />
      </div>
      <Paper className={classes.formPaperWrap}>
        <Grid className={classes.container} container wrap="nowrap">
          <Grid item xs={12}>
            {<MainForm>{renderSignUpForm()}</MainForm>}
          </Grid>
        </Grid>
      </Paper>

      {AGENCY_REGISTRATION?.status === 'agency/AGENCY_REGISTER_SUCCESS' &&
        !AGENCY_REGISTRATION.error && (
          <ContentDialog
            content={<ModalContent />}
            isOpen={successRegister}
            closeCallback={() => {
              setSuccessRegister(false)
            }}
            style={{ padding: '0' }}
            modalStyle={{ maxWidth: 380, padding: '15px 30px' }}
          />
        )}

      {AGENCY_CONTACT_ADMIN?.status ===
        'agency/AGENCY_REGISTER_CONTACT_ADMIN_SUCCESS' &&
        !AGENCY_CONTACT_ADMIN.error && (
          <ContentDialog
            content={
              <ModalContent
                paragraph1={
                  <>Your contact details are now in our PropertyMate system.</>
                }
                paragraph2={<>Our representative will call you for more details.</>}
              />
            }
            isOpen={successContact}
            closeCallback={() => {
              setSuccessContact(false)
              handleReload()
            }}
            style={{ padding: '0' }}
            modalStyle={{ maxWidth: 380, padding: '15px 30px' }}
          />
        )}

      {AGENCY_REGISTRATION?.status === 'agency/AGENCY_REGISTER_FAILED' &&
        AGENCY_REGISTRATION.error && (
          <ContentDialog
            content={
              <ModalContent
                heading={
                  <>
                    <strong>oh&nbsp;no...</strong> an error
                  </>
                }
                paragraph1={
                  <>
                    {AGENCY_REGISTRATION?.error?.message ??
                      'Unable to submit your registration.'}
                  </>
                }
                paragraph2={
                  <>
                    {AGENCY_REGISTRATION?.error?.message ? (
                      AGENCY_REGISTRATION?.error?.message ===
                        'This account already exists. Please log in.' ? (
                        <>
                          <StyledButton
                            onClick={() => routerChange('/agency/login')}
                            variant="contained"
                            color={'primary'}
                            style={{ padding: '2px 10px' }}
                          >
                            Log In
                          </StyledButton>
                        </>
                      ) : (
                        ''
                      )
                    ) : (
                      'Please try again in the next few minutes.'
                    )}
                  </>
                }
              />
            }
            isOpen={errorRegister}
            closeCallback={() => {
              setErrorRegister(false)
            }}
            style={{ padding: '0' }}
            modalStyle={{ maxWidth: 380, padding: '15px 30px' }}
          />
        )}

      {AGENCY_CONTACT_ADMIN?.status === 'agency/AGENCY_REGISTER_CONTACT_ADMIN_FAILED' &&
        AGENCY_CONTACT_ADMIN.error && (
          <ContentDialog
            content={
              <ModalContent
                heading={
                  <>
                    <strong>oh&nbsp;no...</strong> an error
                  </>
                }
                paragraph1={<>Unable to submit your contact details.</>}
                paragraph2={<>Please try again in the next few minutes.</>}
              />
            }
            isOpen={errorContact}
            closeCallback={() => {
              setErrorContact(false)
            }}
            style={{ padding: '0' }}
            modalStyle={{ maxWidth: 380, padding: '15px 30px' }}
          />
        )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    registerFields: AGENCY_REGISTER_VALIDATION_CONFIG,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      toggleSignUp: (checked) => toggleSignUp(checked),
      navigateResetRedux: () => navigateReset(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgencySignUpForm))
