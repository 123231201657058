import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { SuperBreadCrumbs } from 'components/Web/Superadmin';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { getUser, getAgency, getAuthentication } from 'store/selectors/agencyv2.selectors';
import { useSelector } from 'react-redux';
import { useRouterChange } from 'utils/router.utils';
import ManageModal from 'components/Common/Dialog/ManageModal';
//import PMLogo from 'assets/images/pm-logo-inverted.png'
const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
  greetings: {
    marginBottom: 20,
    fontWeight: 300,
    fontSize: '32px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  icon: {
    fontSize: '40px',
  },
  button: {
    width: 300,
    height: 250,
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    width: 60,
    height: 60,
    borderRadius: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 10,
    fontWeight: 400,
    fontSize: '21px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
  message: {
    marginBottom: 40,
  },
  logo: {
    width: 400,
    height: 200,
    marginTop: 10,
    padding: 18,
    margin: 6,
    borderRadius: '5%',
    objectFit: 'contain',
  },
});

const ButtonOptions = (props) => {
  const { classes } = props;
  const ColorArr = ['#fdbd26', '#6dc5a1', '#ef6631', '#F50057'];
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="inherit"
      disableFocusRipple
      disableTouchRipple
      disableRipple
      onClick={props.onClick}
    >
      <div className={classes.buttonContent}>
        <div className={classes.buttonIcon} style={{ backgroundColor: ColorArr[props.idx] }}>
          <AddIcon className={classes.icon} />
        </div>
        <Typography className={classes.title} variant={'h1'}>
          {props.label}
        </Typography>
      </div>
    </Button>
  );
};

/* function setGreetings() {
  var today = new Date()
  var curHr = today.getHours()

  if (curHr < 12) {
    return 'Good morning'
  } else if (curHr < 18) {
    return 'Good afternoon'
  } else {
    return 'Good evening'
  }
} */

const AgencyDashboardV2 = (props) => {
  const { classes, history } = props;
  const [routerChange] = useRouterChange();
  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/agency/dashboard',
    },
    {
      type: 'primary',
      label: 'Dashboard',
      path: '/agency/dashboard',
    },
  ];

  const USER = useSelector(getUser);
  const AGENCY = useSelector(getAgency);

  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const [openModal, setOpenModal] = useState(false);

  const isWelcomeModalRead = !!localStorage.getItem('isWelcomeModalRead');
  const dontShowPopupAgain = !!localStorage.getItem('dontShowPopupAgain');

  const AUTH = useSelector(getAuthentication);

  useEffect(() => {
    if (AUTH?.token && !isWelcomeModalRead && !dontShowPopupAgain) {
      setOpenModal(true);
    }
  }, [AUTH, isWelcomeModalRead, dontShowPopupAgain]);

  useEffect(() => {
    if (USER && !isAgency()) {
      const options = {
        status: true,
        options: {
          severity: 'warning',
          message: 'User Type has not enough permission to view this page.',
          autoHideDuration: 3000,
          onClose: () => routerChange('/agent/login'),
        },
      };
      setIsNotification(options);
      routerChange('/agent/login');
    } else if (!USER && !isAgency()) {
      routerChange('/agency/login');
    }
  }, [USER]);

  const isAgency = () => {
    return USER?.userType.toLowerCase() === 'agency' ||
      USER?.userTypeList.some((i) => ['Agency', 'AgencyAdmin'].includes(i))
      ? true
      : false;
  };

  const _renderButtons = () => (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid item>
        <ButtonOptions
          idx={0}
          classes={classes}
          label={'Manage Agents'}
          onClick={() => routerChange('/agency/manage-agents')}
        />
      </Grid>
      <Grid item>
        <ButtonOptions
          idx={1}
          classes={classes}
          label={'Manage Properties'}
          onClick={() => routerChange('/agency/manage-properties')}
        />
      </Grid>

      {USER?.userType.toLowerCase() === 'agency' && (
        <Grid item>
          <ButtonOptions
            idx={2}
            classes={classes}
            label={'Manage Admins'}
            onClick={() => routerChange('/agency/manage-admins')}
          />
        </Grid>
      )}

      <Grid item>
        <ButtonOptions
          idx={3}
          classes={classes}
          label={'Account Settings'}
          onClick={() => routerChange('/agency/account-settings')}
        />
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      {isAgency() && (
        <>
          <SuperBreadCrumbs section={'Dashboard'} crumbsLinks={CRUMBS} />
          <Box className={classes.buttonContainer}>
            {AGENCY?.logoUrl && (
              <Box>
                <img
                  className={classes.logo}
                  src={AGENCY?.logoUrl}
                  alt="logoUrl"
                  style={{ background: AGENCY?.brandColor ? AGENCY?.brandColor : '#fff' }}
                />
              </Box>
            )}

            <Typography className={classes.greetings} variant={'h1'}>
              {`WELCOME ${
                AGENCY?.registeredTradeName
                  ? AGENCY?.registeredTradeName?.toUpperCase()
                  : AGENCY?.companyName?.toUpperCase()
              } TO YOUR DASHBOARD`}
            </Typography>
            {/*             <Typography className={classes.message} variant={'body1'}>
              Home to all things people and property
            </Typography> */}
            {_renderButtons()}
          </Box>
        </>
      )}
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
      <ManageModal open={openModal} setOpen={setOpenModal} />
    </div>
  );
};
export default withStyles(styles)(AgencyDashboardV2);
