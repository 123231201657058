import React, { createRef, forwardRef, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useRef } from 'react'
import Hammer from 'hammerjs'
import { Box, IconButton, Tooltip, Typography, makeStyles } from '@material-ui/core'
import './index.css'

import { propertyConfigSetter } from 'utils/property.utils'
import PropertyCard from '../Property/PropertyCard'

import Image from 'components/Web/Image'
import IconLike from '../../../assets/images/like-icon.svg'
import IconDisLike from '../../../assets/images/thumbdown.svg'
import { mppActions } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { requestPUTAPIMpp } from 'store/api'
import { getLikesDislikes } from 'store/selectors/mpp.selectors'
import { debounce } from 'lodash'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    // [theme.breakpoints.down('400')]: {
    //   maxHeight: '550px',
    // },
  },

  infoIconContainer: {
    zIndex: 1,
    position: 'absolute',
    top: 20,
    right: 20,
    padding: 0,
    cursor: 'pointer',
    webkitBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    mozBoxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24);',
    backgroundColor: '#ffffff',
    // transition: 'opacity 0.3s ease',
    borderRadius: 20,
    fontSize: 12,
    padding: '3px 5px',
    color: '#000000',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000',
    },
  },

  // Like dislike button
  likeDislikeButtonContainer: {
    cursor: 'pointer',
    width: '120px',
    height: '120px',
  },
}))

const CardSwipeV4 = ({
  authentication,
  listingType,
  isDesktop,
  filters,
  pagination,
  setCurrentProperty,

  handlePagination,

  handlePaginationV2,
  setLikeCounter,
  setLikeDislikeHandler,
}) => {
  const classes = useStyles()

  const [cardElements, setCardElements] = useState()
  const cardContainerRef = useRef()

  const history = useHistory()

  const dispatch = useDispatch()
  const GET_LIKES_DISLIKES = useSelector(getLikesDislikes)

  const likedDislikedPropertiesRef = useRef({ likes: [], dislikes: [] })

  const [swipeCount, setSwipeCount] = useState(0)

  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents()

  const routeChange = (path, state) => {
    history.push(path, state)
  }

  const handleSubmitLikesDislikes = () => {
    if (likedDislikedPropertiesRef.current) {
      let request = {
        query: null,
        data: {
          propertyPreference: {
            ...likedDislikedPropertiesRef.current,
            action: 'add',
          },
        },
      }

      if (
        likedDislikedPropertiesRef.current.likes.length > 0 ||
        likedDislikedPropertiesRef.current.dislikes.length > 0
      ) {
        dispatch(
          mppActions(
            likedDislikedPropertiesRef.current,
            'LOCAL_MPP_LIKES_DISLIKES',
            'SET'
          )
        )

        if (authentication?.user_status === 'logged') {
          dispatch(requestPUTAPIMpp(request, 'PUT_MPP_LIKES_DISLIKES', 'REQUEST'))
        }
      }
    }
  }

  // const handleStoreLikesDislikesLocally = () => {
  //   if (
  //     likedDislikedPropertiesRef.current.likes.length > 0 ||
  //     likedDislikedPropertiesRef.current.dislikes.length > 0
  //   ) {
  //     dispatch(
  //       mppActions(
  //         likedDislikedPropertiesRef.current,
  //         'LOCAL_MPP_LIKES_DISLIKES',
  //         'SET'
  //       )
  //     )
  //   }
  // }

  // const apiPutLikesDislikesProperties = () => {
  //   let request = {
  //     query: null,
  //     data: {
  //       propertyPreference: {
  //         ...GET_LIKES_DISLIKES,
  //         action: 'add',
  //       },
  //     },
  //   }
  //   if (GET_LIKES_DISLIKES.likes.length > 0 || GET_LIKES_DISLIKES.dislikes.length > 0) {
  //     dispatch(requestPUTAPIMpp(request, 'PUT_MPP_LIKES_DISLIKES', 'REQUEST'))
  //   } else {
  //     routeChange('/favourites', {
  //       origin: '/perfect-property',
  //     })
  //   }
  // }

  const handleDebouncedSwipeCards = useCallback(
    debounce((action, propertyIndex, properties) => {
      triggerAnalyticsEvent({
        event: 'mpp_result_actions',
        eventFrom: window.innerWidth <= 1024 ? 'Mobile' : 'Web',
        actionScope: 'card_swipe',
        actionName: 'Card Swipe',
        actionTarget: 'Interacted with MPP',
        actionReference: 'mpp_result',
      })
      if (action === 'likes') {
        setLikeCounter((prev) => prev + 1)
      }
      setSwipeCount((prev) => prev + 1)
      handleLikeDislikeAction(action, propertyIndex, properties)
    }, 200),
    []
  )

  const handleMatchCount = (data) => {
    if (data) {
      if (data.coefficients) {
        const selectedProperty = data.coefficients
        let count = 0
        if (filters && selectedProperty) {
          filters.filters.advanceProperty.map((item, index) => {
            if (item.active) {
              if (selectedProperty[item.key] >= 0.7) {
                count++
              }
            }
          })
          return count
        }
      }
    }
  }

  const handleMoreInfoClick = (property) => {
    triggerAnalyticsEvent({
      event: 'mpp_result_actions',
      eventFrom: window.innerWidth <= 1024 ? 'Mobile' : 'Web',
      actionScope: 'card_more_info',
      actionName: 'Property Card',
      actionTarget: 'Clicked More Info on MPP',
      actionReference: 'mpp_result',
      ...getPropertyData(property),
    })
    routeChange('/' + listingType + '/' + property.property, {
      previous_screen: listingType,
    })
  }

  const initCards = (card, index) => {
    var newCards = window.document.querySelectorAll('.property--card:not(.removed)')
    if (newCards && cardElements) {
      newCards.forEach(function (card, index) {
        card.style.zIndex = cardElements.length - index
        card.style.transform = `scale(${(20 - index) / 20}) translateY(-${
          isDesktop ? 30 * index : 20 * index
        }px)`
        card.style.opacity = index === 0 ? 1 : 0.99
      })

      // if (tinderContainer) {
      //   tinderContainer.classList.add('loaded')
      // }
    }
  }

  const handleLikeDislikeAction = (action, propertyIndex, properties) => {
    likedDislikedPropertiesRef.current[action].push(
      properties[propertyIndex - 1].property
    )

    likedDislikedPropertiesRef.current[action] = [
      ...new Set(likedDislikedPropertiesRef.current[action]),
    ]

    triggerAnalyticsEvent({
      event: 'mpp_result_actions',
      eventFrom: window.innerWidth <= 1024 ? 'Mobile' : 'Web',
      actionScope: 'card_swipe',
      actionName: 'Card Swipe',
      actionTarget: action === 'likes' ? 'Add to Likes' : 'Add to Dislikes',
      actionReference: 'mpp_result',
      ...getPropertyData(properties[propertyIndex - 1]),
    })
  }

  const handleSwipeAction = () => {
    if (cardElements && cardElements?.length > 0) {
      cardElements.forEach(function (el, index) {
        var hammertime = new Hammer(el)

        let propertyCardLikeElement = el.querySelector('.propertyCardLike')
        let propertyCardDislikeElement = el.querySelector('.propertyCardDislike')

        var moveOutWidth = window.document.body.clientWidth

        hammertime.on('pan', function (event) {
          el.classList.add('moving')
        })

        hammertime.on('pan', function (event) {
          if (event.deltaX === 0) return
          if (event.center.x === 0 && event.center.y === 0) return

          // Add Overlay
          if (event.deltaX > (moveOutWidth > 1024 ? 80 : 20)) {
            propertyCardLikeElement.style.opacity = 1
            propertyCardDislikeElement.style.opacity = 0
          } else if (event.deltaX < (moveOutWidth > 1024 ? -80 : -20)) {
            propertyCardLikeElement.style.opacity = 0
            propertyCardDislikeElement.style.opacity = 1
          } else {
            propertyCardLikeElement.style.opacity = 0
            propertyCardDislikeElement.style.opacity = 0
          }

          var xMulti = event.deltaX * 0.03
          var yMulti = event.deltaY / 80
          var rotate = xMulti * yMulti
          // Makes the card slide
          if (event.target.style.opacity === '1') {
            event.target.style.transform =
              'translate(' +
              event.deltaX +
              'px, ' +
              event.deltaY +
              'px) rotate(' +
              rotate +
              'deg)'
          }
        })

        hammertime.on('panend', function (event) {
          el.classList.remove('moving')

          // position and velocity of when swip will succeed
          // var keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5
          var keep =
            moveOutWidth > 1024
              ? Math.abs(event.deltaX) < 80
              : Math.abs(event.deltaX) < 20

          event.target.classList.toggle('removed', !keep)

          if (keep) {
            event.target.style.transform = ''
          } else {
            var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth)
            var toX = event.deltaX > 0 ? endX : -endX
            var endY = Math.abs(event.velocityY) * moveOutWidth
            var toY = event.deltaY > 0 ? endY : -endY
            var xMulti = event.deltaX * 0.03
            var yMulti = event.deltaY / 80
            var rotate = xMulti * yMulti

            //  Add function of liking and disliking a property
            if (toX > 0) {
              handleDebouncedSwipeCards('likes', index, pagination)
            } else {
              handleDebouncedSwipeCards('dislikes', index, pagination)
            }

            // Exit animation of cards
            if (event.target.style.opacity === '1') {
              event.target.style.transform =
                'translate(' +
                toX +
                'px, ' +
                (toY + event.deltaY) +
                'px) rotate(' +
                rotate +
                'deg)'
              initCards()

              // el.remove()
              event.preventDefault()
            }
          }
        })
      })
    }
  }

  const handleLikeDislikePropertyClick = (event, like) => {
    var cards = window.document.querySelectorAll('.property--card:not(.removed)')
    var moveOutWidth = window.document.body.clientWidth * 1.5

    if (!cards.length) return false
    var card = cards[0]

    let propertyCardLikeElement = card.querySelector('.propertyCardLike')
    let propertyCardDislikeElement = card.querySelector('.propertyCardDislike')

    card.classList.add('removed')

    // Call like or dislike functions
    if (like) {
      // handleDebouncedSwipeCards('likes', swipeCount + 1, pagination)

      setLikeCounter((prev) => prev + 1)
      setSwipeCount((prev) => prev + 1)
      handleLikeDislikeAction('likes', swipeCount + 1, pagination)

      propertyCardLikeElement.style.opacity = 1
      propertyCardDislikeElement.style.opacity = 0
      card.style.transform = 'translate(' + moveOutWidth + 'px, -100px) rotate(-30deg)'
    } else {
      setSwipeCount((prev) => prev + 1)
      handleLikeDislikeAction('dislikes', swipeCount + 1, pagination)

      propertyCardLikeElement.style.opacity = 0
      propertyCardDislikeElement.style.opacity = 1
      card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)'
    }

    initCards()

    event.preventDefault()
  }

  useEffect(() => {
    if (pagination.length === swipeCount) {
      handleSubmitLikesDislikes()
      handlePaginationV2()
    }
  }, [swipeCount])

  useEffect(() => {
    if (pagination.length > 0 && cardContainerRef.current) {
      setCardElements([...cardContainerRef.current.childNodes])
      initCards()
      handleSwipeAction()
    }
  }, [pagination, cardContainerRef.current])

  useEffect(() => {
    if (cardContainerRef.current) {
      setCardElements(cardContainerRef.current.childNodes)
    }
  }, [pagination, cardContainerRef.current])

  useEffect(() => {
    setLikeDislikeHandler({ initiateLikeDisliked: handleSubmitLikesDislikes })

    return () => {
      // handleStoreLikesDislikesLocally()
      handleSubmitLikesDislikes()
    }
  }, [])

  return (
    <Box
      className={classes.rootContainer}
      // style={{ maxHeight: isDesktop ? '800px' : '550px' }}
    >
      {pagination && (
        <div ref={cardContainerRef} className="property--cards">
          <Box display={isDesktop ? '' : 'none'}>
            <Box
              top="45%"
              left="15%"
              position="absolute"
              style={{ transform: 'translate(-50%,-50%)' }}
            >
              <Tooltip title="Dislike">
                <Box
                  className={classes.likeDislikeButtonContainer}
                  onClick={(event) => handleLikeDislikePropertyClick(event, false)}
                >
                  <img src={IconDisLike} />
                </Box>
              </Tooltip>
            </Box>
            <Box
              top="45%"
              left="70%"
              position="absolute"
              style={{ transform: 'translate(50%,-50%)' }}
            >
              <Tooltip title="Like">
                <Box
                  className={classes.likeDislikeButtonContainer}
                  onClick={(event) => handleLikeDislikePropertyClick(event, true)}
                >
                  <img src={IconLike} />
                </Box>
              </Tooltip>
            </Box>
          </Box>

          {pagination.map((property, index) => (
            <div key={property._id} className="property--card">
              <div className="propertyCardLike">
                <img src={IconLike} />
              </div>
              <div className="propertyCardDislike">
                <img src={IconDisLike} />
              </div>

              <Tooltip title="Information">
                <IconButton
                  className={classes.infoIconContainer}
                  style={{ pointerEvents: 'all' }}
                  onClick={() => handleMoreInfoClick(property)}
                >
                  <span>More Info</span>
                </IconButton>
              </Tooltip>

              <PropertyCard
                // ref={cardref}
                cardType={'swipe'}
                properties={property}
                {...propertyConfigSetter(
                  'card-type',
                  property.subscriptionType ? property.subscriptionType : ''
                )}
                routeChange={routeChange}
                // authentication={authentication}
                // settings={settings}
                // toggleSignUp={toggleSignUp}
                setCurrentProperty={setCurrentProperty}
                // apiRequestSaveProperty={apiRequestSaveProperty}
                // addLikedDrillDown={addLikedDrillDown}
                // addUnlikedDrillDown={addUnlikedDrillDown}
                property={property}
                // listingType={listingType}
                handleMatchCount={handleMatchCount}
              />
            </div>
          ))}
        </div>
      )}
    </Box>
  )
}

export default CardSwipeV4
