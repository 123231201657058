import React, { useState } from 'react';
import Image from 'material-ui-image';

import HeartIcon from '@material-ui/icons/FavoriteBorder';

import MapPropertyCardAgentBanner from './MapPropertyCardAgentBanner';
import MapPropertyCardInfo from './MapPropertyCardInfo';

import IMGComingSoon from '../../../assets/images/coming_soon.svg';
import IMGOffMarket from '../../../assets/images/off_market.svg';
import PropertyCard from '../Property/PropertyCard';

import {
    propertyConfigSetter
} from '../../../utils/property.utils';

import { withStyles, Card, IconButton } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = theme => ({
    wrap: {
        width: '100%'
    },
    root: {
        display: 'flex',
        position: 'relative',
    },
    rootActive: {
        display: 'flex',
        position: 'relative',
        width: '100%'
    },
    card: {
        width: '100%',
        // height: '13.438em',
        margin: '0.325em',
        marginBottom: '0.625em',
        position: 'relative'
    },
    cardActive: {
        minWidth: '10.500em',
        margin: '0.325em',
        marginBottom: '0.625em',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        '& > div': {
            width: '50%'
        },
        '& > div:last-child': {
            paddingTop: theme.spacing(1),
            paddingbottom: theme.spacing(1),
        },
        '& .MuiIconButton-label .MuiTypography-caption': {
            fontSize: '9px',
            color: '#000000'
        },
        '& .MuiTypography-body2': {
            fontSize: '1rem'
        },
        '& .MapPropertyCardInfo-main': {
            padding: theme.spacing(2)
        }

    },
    media: {
        width: '100%',
        height: '6.250em',
        objectFit: 'cover'
    },
    favoriteContainer: {
        width: '0.625em',
        height: '0.625em',
        position: 'absolute',
        top: 15,
        right: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
    },
    favoriteIcon: {
        color: '#FFF',
        width: '0.625em',
        height: '0.625em'
    },
    relativeWrap: {
        position: 'relative'
    }
});

const MapPropertyCard = props => {
    const {
        classes,
        type,
        properties,
        trades,
        active,

        currentScreen,
        setCurrentProperty,
        pathType,
        currentPath
    } = props;
    const [routerChange] = useRouterChange()

    function imageType(type, image) {
        switch (type) {
            case 'auction':
                return image;
            case 'comingsoon':
                return image ? image : IMGComingSoon;
            case 'offmarket':
                return image ? image : IMGOffMarket;
            default:
                return image;
        }
    }
    function listingType(type) {
        switch (type) {
            case 'rental':
                return 'rent';
            case 'sold':
                return 'sold';
            default:
                return 'buy';
        }
    }

    const getRoutePath = () => {
        return props.match ? props.match.path.split('/')[1] : ''
    }

    function renderProperties() {

        return (
            <div className={active ? classes.wrap : ''} onClick={() => {
                if (properties.propertyStatus !== 'offmarket') {
                    setCurrentProperty(properties)
                    if (pathType === 'property') {
                        routerChange('/' + currentPath + '/' + properties.property, { previous_screen: currentScreen })
                    }
                    if (pathType === 'liked') {
                        routerChange('/liked-properties/' + properties.property, { previous_screen: currentScreen })
                    }
                }
            }}>
                {!active &&
                    <div className={classes.root}>
                        <PropertyCard
                            key={properties._id}
                            cardType={'list'}
                            properties={properties}
                            {...propertyConfigSetter('card-type', properties ? properties.subscriptionType ? properties.subscriptionType : '' : '')}
                            listingType={listingType(getRoutePath())}
                            handleMatchCount={() => false}
                            setCurrentProperty={setCurrentProperty}

                        />
                    </div>
                }
                {active &&
                    <div className={classes.rootActive}>
                        <PropertyCard
                            key={properties._id}
                            cardType={'list'}
                            properties={properties}
                            {...propertyConfigSetter('card-type', properties ? properties.subscriptionType ? properties.subscriptionType : '' : '')}
                            listingType={getRoutePath()}
                            handleMatchCount={() => false}
                        />
                    </div>
                }
            </div>
        )
    }

    return (
        <>
            {renderProperties()}
        </>
    )
}

export default withStyles(styles)(MapPropertyCard);
