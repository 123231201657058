import React from 'react'

import EmailIcon from '@material-ui/icons/Email'
import FacebookIcon from '@material-ui/icons/Facebook'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import ShareIcon from '@material-ui/icons/Share'
import MessageIcon from '@material-ui/icons/Message'

export const DEFAULT_OFF_MARKET = ''
export const DEFAULT_COMING_SOON = ''

export const PROPERTY_CARDS = [
  {
    type: 'platinum',
    status: 'auction',
    auctionDate: Date.now(),
    image: 'https://homedesignlover.com/wp-content/uploads/2013/10/1-525a-Upper.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Allegra Sierra',
    agentAvatar: 'https://petapixel.com/assets/uploads/2017/08/IMG_0513.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'platinum',
    status: 'auction',
    auctionDate: Date.now(),
    image:
      'https://www.artsample.com/blog/wp-content/uploads/sites/71/2019/10/buy-living-room-furniture.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Kourtney Bonner',
    agentAvatar:
      'https://main-designyoutrust.netdna-ssl.com/wp-content/uploads/2018/03/0-29.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'premium',
    status: 'auction',
    auctionDate: Date.now(),
    image:
      'https://www.artsample.com/blog/wp-content/uploads/sites/71/2019/10/buy-living-room-furniture.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Kourtney Bonner',
    agentAvatar:
      'https://main-designyoutrust.netdna-ssl.com/wp-content/uploads/2018/03/0-29.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'basic',
    status: 'auction',
    auctionDate: Date.now(),
    image:
      'https://2.bp.blogspot.com/_hjLO3myE9wI/SwH5t26PLjI/AAAAAAAADQ4/opvnOhRPwfA/s1600/Small+living+Room+Interior+design+Minimalist+Inspiration.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Ellice Wallace',
    agentAvatar: 'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'premium',
    status: 'off_market',
    auctionDate: Date.now(),
    image: 'https://homedesignlover.com/wp-content/uploads/2013/10/1-525a-Upper.jpg',
    suburb: 'Kensington',
    address: null,
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Allegra Sierra',
    agentAvatar: 'https://petapixel.com/assets/uploads/2017/08/IMG_0513.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'premium',
    status: 'off_market',
    auctionDate: Date.now(),
    image: 'https://homedesignlover.com/wp-content/uploads/2013/10/1-525a-Upper.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Allegra Sierra',
    agentAvatar: 'https://petapixel.com/assets/uploads/2017/08/IMG_0513.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'basic',
    status: 'off_market',
    auctionDate: Date.now(),
    image: 'https://homedesignlover.com/wp-content/uploads/2013/10/1-525a-Upper.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Allegra Sierra',
    agentAvatar: 'https://petapixel.com/assets/uploads/2017/08/IMG_0513.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'premium',
    status: 'coming_soon',
    auctionDate: Date.now(),
    image: 'https://homedesignlover.com/wp-content/uploads/2013/10/1-525a-Upper.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Allegra Sierra',
    agentAvatar: 'https://petapixel.com/assets/uploads/2017/08/IMG_0513.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'premium',
    status: 'coming_soon',
    auctionDate: Date.now(),
    image: 'https://homedesignlover.com/wp-content/uploads/2013/10/1-525a-Upper.jpg',
    suburb: 'Kensington',
    address: '108/62 Altona Street, Kensington, VIC 3031',
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Allegra Sierra',
    agentAvatar: 'https://petapixel.com/assets/uploads/2017/08/IMG_0513.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
  {
    type: 'basic',
    status: 'coming_soon',
    auctionDate: Date.now(),
    image: 'https://homedesignlover.com/wp-content/uploads/2013/10/1-525a-Upper.jpg',
    suburb: 'Kensington',
    address: null,
    price: '$150,000 - $200,000',
    bed: 2,
    bath: 3,
    parking: 2,
    views: 80,
    matches: 50,
    agentName: 'Allegra Sierra',
    agentAvatar: 'https://petapixel.com/assets/uploads/2017/08/IMG_0513.jpg',
    agentLogo:
      'https://www.ampmypage.com/sites/default/files/sample/logo-placeholder.png',
  },
]

export const OPTION_MODAL = {
  type: 'sort',
  value: [
    {
      name: 'Share on SMS',
      value: 'Share on SMS',
      icon: 'Message',
      type: 'SMS',
    },
    {
      name: 'Share via Email',
      value: 'Share via Email',
      icon: 'Mail',
      type: 'Email',
    },
    {
      name: 'Share via WhatsApp',
      value: 'Share via WhatsApp',
      icon: 'WhatsApp',
      type: 'WhatsApp',
    },
    {
      name: 'Share on Facebook',
      value: 'Share on Facebook',
      icon: 'Facebook',
      type: 'Facebook',
    },
    // {
    //   name: 'Share on LinkedIn',
    //   value: 'Share on LinkedIn',
    //   icon: 'LinkedIn',
    //   type: 'LinkedIn',
    // },
    // {
    //   name: 'Share on Twitter',
    //   value: 'Share on Twitter',
    //   icon: 'Twitter',
    //   type: 'Twitter',
    // },

    // {
    //   name: 'Copy Link',
    //   value: 'Copy Link',
    //   icon: 'FileCopy',
    //   type: 'Clipboard',
    // },
    // {
    //     name: 'More Options',
    //     value: 'More Options',
    //     icon: 'Share',
    //     type: 'More'
    // }
  ],
}

export const TRADES = [
  {
    image:
      'https://images.theconversation.com/files/138166/original/image-20160919-17029-2dn60d.jpg?ixlib=rb-1.1.0&rect=0%2C94%2C5760%2C2793&q=45&auto=format&w=1356&h=668&fit=crop',
    companyName: 'Garden Co.',
    website: 'http://www.gardening.co.au',
    addressText: '108/62 Altona Street, Kensington, VIC 3031',
  },
  {
    image:
      'https://fcfe85a28c5340277f6e-37a82cca4e77212133d427083e7ea727.ssl.cf3.rackcdn.com/articles/20190124065307/original/dps-banner.png',
    companyName: 'Off The Wall',
    website: 'http://www.offthewall.co.au',
    addressText: '108/62 Altona Street, Kensington, VIC 3031',
  },
  {
    image:
      'https://metropha.com/wp-content/uploads/2018/03/Professional-Plumbing-Services-in-Cleveland-GA-Why-and-When.jpg',
    companyName: 'Plum Co.',
    website: 'http://www.plum.co.au',
    addressText: '108/62 Altona Street, Kensington, VIC 3031',
  },
  {
    image:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRi-CWAn8Is3w42tzXUkIgkVOIeNVkf5wOt5g&usqp=CAU',
    companyName: 'Clean Me.',
    website: 'http://www.clean.me.au',
    addressText: '108/62 Altona Street, Kensington, VIC 3031',
  },
  {
    image:
      'https://images.theconversation.com/files/138166/original/image-20160919-17029-2dn60d.jpg?ixlib=rb-1.1.0&rect=0%2C94%2C5760%2C2793&q=45&auto=format&w=1356&h=668&fit=crop',
    companyName: 'Garden Co.',
    website: 'http://www.gardening.co.au',
    addressText: '108/62 Altona Street, Kensington, VIC 3031',
  },
  {
    image:
      'https://fcfe85a28c5340277f6e-37a82cca4e77212133d427083e7ea727.ssl.cf3.rackcdn.com/articles/20190124065307/original/dps-banner.png',
    companyName: 'Off The Wall',
    website: 'http://www.offthewall.co.au',
    addressText: '108/62 Altona Street, Kensington, VIC 3031',
  },
]

export const NEARBY_FILTERS = ['All', 'Primary', 'Secondary', 'University']
export const NEARBY_SCHOOLS = {
  items: [
    {
      primary: "St. Ambrose's Primary School",
      secondary: 'Catholic | Primary',
      distance: '1.1km',
      filter: ['primary', 'all'],
    },
    {
      primary: "St. Ambrose's Primary School",
      secondary: 'Catholic | Primary',
      distance: '1.2km',
      filter: ['primary', 'all'],
    },
    {
      primary: "St. Ambrose's Primary School",
      secondary: 'Catholic | Primary',
      distance: '1.3km',
      filter: ['primary', 'all'],
    },
    {
      primary: "St. Ambrose's Primary School",
      secondary: 'Catholic | Primary',
      distance: '1.4km',
      filter: ['primary', 'all'],
    },
    {
      primary: "St. Ambrose's Secondary School",
      secondary: 'Catholic | Secondary',
      distance: '1.1km',
      filter: ['secondary', 'all'],
    },
    {
      primary: "St. Ambrose's Secondary School",
      secondary: 'Catholic | Secondary',
      distance: '1.2km',
      filter: ['secondary', 'all'],
    },
    {
      primary: "St. Ambrose's Secondary School",
      secondary: 'Catholic | Secondary',
      distance: '1.3km',
      filter: ['secondary', 'all'],
    },
    {
      primary: "St. Ambrose's Secondary School",
      secondary: 'Catholic | Secondary',
      distance: '1.4km',
      filter: ['secondary', 'all'],
    },
    {
      primary: "St. Ambrose's Childcare School",
      secondary: 'Catholic | Childcare',
      distance: '1.1km',
      filter: ['childcare', 'all'],
    },
    {
      primary: "St. Ambrose's Childcare School",
      secondary: 'Catholic | Childcare',
      distance: '1.2km',
      filter: ['childcare', 'all'],
    },
    {
      primary: "St. Ambrose's Childcare School",
      secondary: 'Catholic | Childcare',
      distance: '1.3km',
      filter: ['childcare', 'all'],
    },
    {
      primary: "St. Ambrose's Childcare School",
      secondary: 'Catholic | Childcare',
      distance: '1.4km',
      filter: ['childcare', 'all'],
    },
  ],
}

export const TEST_LOCATION = {
  mode: 'map',
  title: 'Map View',
  map: { center: [-37.7963, 144.92512], zoom: 15 },
  address: '108/62 Altona Street, Kensington',
}
export const TEST_IMAGES = [
  'https://picsum.photos/id/1048/400',
  'https://picsum.photos/id/1076/400',
]

export const CONTACTFORM_FIELDS_CONFIG = {
  fields: {
    email: {
      isRequired: { message: 'Email is required' },
      isEmail: { message: 'Email is invalid' },
    },
    postcode: {
      isRequired: { message: 'Postcode is required' },
      isNumber: { message: 'Postcode must be all numbers' },
      isMinLength: { length: 4, message: 'Postcode requires 4 digits' },
    },
    name: {
      isRequired: { message: 'Name is required' },
    },
    phone: {
      isMinLength: { length: 10, message: 'Phone number is invalid' },
    },
  },
  showErrors: 'change',
}
