import React, { useEffect } from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { SuperBreadCrumbs } from 'components/Web/Superadmin';

/** redux */
import { getSuperadminToken } from 'store/selectors/superadmin.selectors';
import { useRouterChange } from 'utils/router.utils';
import useHandleManageAgencyRowCount from 'utils/customHooks/useHandleManageAgencyRowCount';
import useHandleManageAgencyPageCount from 'utils/customHooks/useHandleManageAgencyPageCount';
import useHandleManageAgencyButtons from 'utils/customHooks/useHandleManageAgencyButtons';
import useHandleManageAgencyFilters from 'utils/customHooks/useHandleManageAgencyFilters';
import useHandleManageAgencySort from 'utils/customHooks/useHandleManageAgencySort';
import useHandleManageAgencySearchField from 'utils/customHooks/useHandleManageAgencySearchField';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
  greetings: {
    marginBottom: 50,
    fontWeight: 300,
    fontSize: '32px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  icon: {
    fontSize: '40px',
  },
  button: {
    width: 300,
    height: 250,
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  buttonContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonIcon: {
    width: 60,
    height: 60,
    borderRadius: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginTop: 10,
    fontWeight: 400,
    fontSize: '21px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
});

const ButtonOptions = (props) => {
  const { classes } = props;
  const ColorArr = ['#fdbd26', '#6dc5a1', '#ef6631', '#35C0CA'];
  return (
    <Button
      className={classes.button}
      variant="contained"
      color="inherit"
      disableFocusRipple
      disableTouchRipple
      disableRipple
      onClick={props.onClick}
    >
      <div className={classes.buttonContent}>
        <div className={classes.buttonIcon} style={{ backgroundColor: ColorArr[props.idx] }}>
          <AddIcon className={classes.icon} />
        </div>
        <Typography className={classes.title} variant={'h1'}>
          {props.label}
        </Typography>
      </div>
    </Button>
  );
};

function setGreetings() {
  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 12) {
    return 'Good morning, ';
  } else if (curHr < 18) {
    return 'Good afternoon, ';
  } else {
    return 'Good evening, ';
  }
}

const SuperadminDashboard = (props) => {
  const { classes, history } = props;
  const [routerChange] = useRouterChange();
  const SUPERADMIN_TOKEN = useSelector(getSuperadminToken);

  const [rowCount, setRowCount] = useHandleManageAgencyRowCount();
  const [pageCount, setPageCount] = useHandleManageAgencyPageCount();
  const [manageButtons, setManageButtons] = useHandleManageAgencyButtons();
  const [manageFilters, setManageFilters] = useHandleManageAgencyFilters();
  const [manageSort, setManageSort] = useHandleManageAgencySort();
  const [manageSearchField, setManageSearchField] = useHandleManageAgencySearchField();

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Dashboard',
      path: '/superadmin/dashboard',
    },
  ];

  useEffect(() => {
    /**
     * * Superadmin Token checker
     */
    checkSuperadminToken();
  }, []);

  const checkSuperadminToken = () => {
    /**
     * Reroute to login when token does not exist
     */
    if (SUPERADMIN_TOKEN === null) {
      // add more conditions here
      routerChange('/superadmin/login');
    }
  };

  const _renderButtons = () => (
    <Grid container spacing={4} justifyContent="center" alignItems="center">
      <Grid item>
        <ButtonOptions
          idx={0}
          classes={classes}
          label={'Manage Agency'}
          onClick={() => {
            setRowCount(30);
            setPageCount(0);
            setManageButtons([
              {
                id: 0,
                label: 'Paid',
                active: true,
              },
              {
                id: 1,
                label: 'Free',
                active: false,
              },
            ]);
            setManageFilters({
              subscription: 'ALL',
              docusign: 'ALL',
              payment: 'ALL',
              adminEmail: 'ALL',
              active: 'ALL',
            });
            setManageSort([
              {
                id: 0,
                key: 'registeredTradeName',
                sort: false,
                sortOrder: null,
              },
              {
                id: 1,
                key: 'address',
                sort: false,
                sortOrder: null,
              },
              {
                id: 2,
                key: 'adminEmail',
                sort: false,
                sortOrder: null,
              },
              {
                id: 3,
                key: 'subscription.billingDate',
                sort: false,
                sortOrder: null,
              },
            ]);
            setManageSearchField('');
            routerChange('/superadmin/manage-agencies');
          }}
        />
      </Grid>
      <Grid item>
        <ButtonOptions
          idx={1}
          classes={classes}
          label={'Email Log'}
          onClick={() => routerChange('/superadmin/email-logs')}
        />
      </Grid>
      <Grid item>
        <ButtonOptions
          idx={2}
          classes={classes}
          label={'Add Agency'}
          onClick={() => routerChange('/superadmin/add-agency')}
        />
      </Grid>
      <Grid item>
        <ButtonOptions
          idx={3}
          classes={classes}
          label={'Reports'}
          onClick={() => routerChange('/superadmin/reports')}
        />
      </Grid>
    </Grid>
  );

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs section={'Dashboard'} crumbsLinks={CRUMBS} />
      <div className={classes.buttonContainer}>
        <Typography className={classes.greetings} variant={'h1'}>
          {setGreetings() + ' Super Admin'}
        </Typography>
        {_renderButtons()}
      </div>
    </div>
  );
};
export default withStyles(styles)(SuperadminDashboard);
