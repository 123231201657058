import { 
    SAVE_SKIPTAKEDATA,
    CLEAR_SKIPTAKEDATA
  } from "../constants"; 
  
  export const saveSkipTakeData = (payload) => {
    return {
      type: SAVE_SKIPTAKEDATA,
      payload
    };
  }
  export const clearSkipTakeData = () => {
    return {
      type: CLEAR_SKIPTAKEDATA,      
    };
  }
  