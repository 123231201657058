import { Box, Link, Typography, withStyles } from '@material-ui/core'
import { SuperBreadCrumbs } from 'components/Web/Superadmin'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { superadminActions } from 'store/actions'
import { getSuperadminToken } from 'store/selectors/superadmin.selectors'

import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { useRouterChange } from 'utils/router.utils'

const NOTIFICATIONS = [
  {
    action: 'Agency Requesting for docusign',
    date: '25/06/2022',
    url: '/superadmin/dashboard',
    isRead: false,
  },
  {
    action: 'New Agency Created',
    date: '12/01/2022',
    url: '/superadmin/dashboard',
    isRead: true,
  },
  {
    action:
      'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Ciceros De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with',
    date: '17/05/2022',
    url: '/superadmin/dashboard',
    isRead: false,
  },
  {
    action:
      'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Ciceros De Finibus Bonorum et Malorum for use in a type specimen book. It usually begins with',
    date: '07/12/2021',
    url: '/superadmin/dashboard',
    isRead: true,
  },
]

const styles = () => ({
  isRead: {
    background: 'white',
  },
  notRead: {
    background: '#CDCDCD',
  },
  notificationItem: {
    '&:hover': {
      background: 'white',
    },
  },
})

const NotificationItem = ({ notification, classes }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const params = {
      query: null,
      data: null,
    }

    //clear super admin state
    dispatch(superadminActions(params, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))

    //TODO: Call API for getting superadmin notifications
    // dispatch(superadminActions(params, 'SUPERADMIN_GET_NOTIFICATION_LIST', 'REQUEST'))
  }, [])

  return (
    <Box
      className={`${classes.notificationItem} ${notification.isRead ? classes.isRead : classes.notRead
        }`}
      padding="10px"
      borderBottom="1px solid #9B9B9B"
      display="flex"
      gridGap="15px"
    >
      <Link
        href={notification.url}
        underline="none"
        color="inherit"
        style={{ textDecoration: 'none', width: '100%' }}
      >
        <Box display="flex" flexDirection="column" alignSelf="end">
          <Typography variant="h5">{notification.action}</Typography>
          <Typography
            style={{ fontSize: '16px' }}
            variant="caption"
            display="block"
            gutterBottom
          >
            {notification.date}
          </Typography>
        </Box>
      </Link>
      <Box
        marginLeft="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
        gridGap="10px"
      >
        {notification.isRead ? (
          <DraftsOutlinedIcon fontSize="large" />
        ) : (
          <EmailOutlinedIcon fontSize="large" />
        )}

        <DeleteOutlineOutlinedIcon fontSize="large" />
      </Box>
    </Box>
  )
}

const SuperAdminNotificationList = ({ history, classes }) => {
  const [routerChange] = useRouterChange()
  const SUPERADMIN_TOKEN = useSelector(getSuperadminToken)

  useEffect(() => {
    /**
     * * Superadmin Token checker
     */
    checkSuperadminToken()
  }, [])

  const checkSuperadminToken = () => {
    if (SUPERADMIN_TOKEN === null) {
      // add more conditions here
      routerChange('/superadmin/login')
    }
  }

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Notification List',
      path: '/superadmin/notification-list',
    },
  ]

  return (
    <Box width="100%" height="100vh" paddingLeft="10vw">
      <SuperBreadCrumbs
        section={'Notification List'}
        crumbsLinks={CRUMBS}

      />

      <Box padding="20px" paddingRight="5vw" gridGap="10px">
        {NOTIFICATIONS.map((notification, index) => (
          <NotificationItem
            key={`notification-${index}`}
            classes={classes}
            notification={notification}
          />
        ))}
      </Box>
    </Box>
  )
}

export default withStyles(styles)(SuperAdminNotificationList)
