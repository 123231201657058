import React, { useState } from 'react'
import { IconButton, Container, Typography, Button, Tabs, Tab, Box, withStyles } from '@material-ui/core';
import Image from 'material-ui-image'
import EditPropertyPersonalInfo from './EditPropertyPersonalInfo'
import EditPropertyDetails from './EditPropertyDetails'
import EditPropertyImage from './EditPropertyImage'
import EditPropertyUploadStatement from './EditPropertyUploadStatement'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0, 2),
  },
  pageTabs: {
    '& .MuiTab-root': {
      textTransform: 'capitalize',
    },
  },
  text: {
    fontSize: 12,
    fontWeight: 300,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  centered: {
    textAlign: 'center',
  },
  ctaRounded: {
    borderRadius: 25,
    color: theme.palette.text.primary,
    textTransform: 'initial',
    paddingLeft: theme.spacing(3.2),
    paddingRight: theme.spacing(3.2),
    backgroundColor: theme.palette.common.white,
    '&.MuiButton-contained': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  confirmActions: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
  },
})

function a11yProps(index) {
  return {
    id: `manage-property-tabs-${index}`,
    'aria-controls': `manage-property-panel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`manage-property-tabpanel-${index}`}
      aria-labelledby={`manage-property-tabs-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const EditPropertyDialogContent = (props) => {
  const {
    classes,
    property,
    setVisibleTab,
    saveConfirm,
    setSaveConfirm,
    visibleTab,
    saveEditProperty,
    deleteProperty,
    setManageItemToggle,
    users,
  } = props

  const [activeTab, setActiveTab] = useState(0)
  const [editPropertyFields, setEditPropertyFields] = useState(null)

  const defaultEditPropertyFields = {
    agentInfo: {
      firstName: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      lastName: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      email: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      phone: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      agencyEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      vendorsEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      vendorsPhone: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      confirmVendorsEmail: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
    },
    propertyInfo: {
      images: {
        value: ['default'],
        error: {
          status: false,
          message: '',
        },
      },
      customCover: {
        value: false,
        error: {
          status: false,
          message: '',
        },
      },
      propertyType: '',
      propertyStatus: {
        value: null,
        error: {
          status: false,
          message: '',
        },
      },
      description: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
      propertyDetails: {},
      address: {
        value: {},
        error: {
          status: false,
          message: '',
        },
      },
      statementOfIntent: {
        value: '',
        error: {
          status: false,
          message: '',
        },
      },
    },
  }

  React.useEffect(() => {
    if (saveConfirm) {
      apiEditProperty()
    }
  }, [saveConfirm])

  React.useEffect(() => {
    setActiveTab(visibleTab)
  }, [visibleTab])

  React.useEffect(() => {
    setSaveConfirm(false)
  }, [activeTab])

  React.useEffect(() => {
    setEditPropertyFields({
      ...defaultEditPropertyFields,
      agentInfo: {
        ...defaultEditPropertyFields.agentInfo,
        firstName: {
          ...defaultEditPropertyFields.agentInfo.firstName,
          value:
            property && property.agent && property.agent.firstName
              ? property.agent.firstName
              : '',
        },
        lastName: {
          ...defaultEditPropertyFields.agentInfo.lastName,
          value:
            property && property.agent && property.agent.lastName
              ? property.agent.lastName
              : '',
        },
        email: {
          ...defaultEditPropertyFields.agentInfo.email,
          value:
            property && property.agent && property.agent.email
              ? property.agent.email
              : '',
        },
        phone: {
          ...defaultEditPropertyFields.agentInfo.phone,
          value:
            property && property.agent && property.agent.telephone
              ? property.agent.telephone
              : '',
        },
        vendorsEmail: {
          ...defaultEditPropertyFields.agentInfo.vendorsEmail,
          value: property && property.vendorEmail ? property.vendorEmail : '',
        },
        agencyEmail: {
          ...defaultEditPropertyFields.agentInfo.agencyEmail,
          value: users && users.agency ? users.agency.adminEmail : '',
        },
      },
    })
  }, [])

  const handleFieldChange = (fieldGroup, subGroup, fieldName, value) => {
    if (subGroup) {
      setEditPropertyFields({
        ...editPropertyFields,
        [fieldGroup]: {
          ...editPropertyFields[fieldGroup],
          [subGroup]: {
            ...editPropertyFields[fieldGroup][subGroup],
            [fieldName]: {
              ...editPropertyFields[fieldGroup][subGroup][fieldName],
              value: value,
            },
          },
        },
      })
    } else if (fieldGroup) {
      setEditPropertyFields({
        ...editPropertyFields,
        [fieldGroup]: {
          ...editPropertyFields[fieldGroup],
          [fieldName]: {
            ...editPropertyFields[fieldGroup][fieldName],
            value: value,
          },
        },
      })
    }
  }

  async function apiEditProperty() {
    const params = {}
    if (editPropertyFields) {
      params.property_status =
        editPropertyFields.propertyInfo.propertyStatus.value || property.propertyStatus
      params.property_type =
        editPropertyFields.propertyInfo.propertyType.value || property.propertyType
      params.address = {
        full:
          editPropertyFields.propertyInfo.address.value.full || property.addressText,
        lot_number:
          editPropertyFields.propertyInfo.address.value.lotNumber ||
          property.addressLotNumber,
        sub_number:
          editPropertyFields.propertyInfo.address.value.subNumber ||
          property.addressSubNumber,
        street_number:
          editPropertyFields.propertyInfo.address.value.streetNumber ||
          property.addressStreetNumber,
        street_name:
          editPropertyFields.propertyInfo.address.value.streetName ||
          property.addressStreetName,
        municipality:
          editPropertyFields.propertyInfo.address.value.municipality ||
          property.addressMunicipality,
        state:
          editPropertyFields.propertyInfo.address.value.state || property.addressState,
        suburb:
          editPropertyFields.propertyInfo.address.value.suburb ||
          property.addressSuburb,
        postcode:
          editPropertyFields.propertyInfo.address.value.postcode ||
          property.addressPostcode,
      }
      params.bedrooms =
        (editPropertyFields.propertyInfo.propertyDetails.value &&
          editPropertyFields.propertyInfo.propertyDetails.value.bedrooms) ||
        property.bedrooms
      params.bathrooms =
        (editPropertyFields.propertyInfo.propertyDetails.value &&
          editPropertyFields.propertyInfo.propertyDetails.value.bathrooms) ||
        property.bathrooms
      params.carparkings =
        (editPropertyFields.propertyInfo.propertyDetails.value &&
          editPropertyFields.propertyInfo.propertyDetails.value.carparking) ||
        property.carports
      params.agency_email =
        (editPropertyFields.agentInfo.agencyEmail &&
          editPropertyFields.agentInfo.agencyEmail.value) ||
        users.agency.agencyEmail
      params.vendor_email =
        (editPropertyFields.agentInfo.vendorsEmail &&
          editPropertyFields.agentInfo.vendorsEmail.value) ||
        property.vendor.email
      params.image =
        (editPropertyFields.propertyInfo.images &&
          editPropertyFields.propertyInfo.images.value[0] !== 'default' &&
          editPropertyFields.propertyInfo.images.value) ||
        property.images
      params.price =
        (editPropertyFields.propertyInfo.propertyDetails.value &&
          editPropertyFields.propertyInfo.propertyDetails.value.price) ||
        property.price
      params.customCover =
        editPropertyFields.propertyInfo.customCover.value || property.customCover
      params.statement_of_intent =
        editPropertyFields.propertyInfo.statementOfIntent.value ||
        property.statementOfIntent
      params.vendor_phone =
        (editPropertyFields.agentInfo.vendorsPhone.value &&
          editPropertyFields.agentInfo.vendorsPhone.value.match(/\d/g).join('')) ||
        property.vendor.phone
      params.description =
        (editPropertyFields.propertyInfo.description.value &&
          editPropertyFields.propertyInfo.description.value) ||
        property.description
      params.id = property.property
    }

    saveEditProperty(params)
  }

  async function apiDeleteProperty() {
    if (property && property.property) {
      const params = {
        id: property.property,
      }
      setManageItemToggle(false)
      deleteProperty(params)
      resetTabs()
    }
  }

  const resetTabs = () => {
    setActiveTab(0)
    if (setVisibleTab) {
      setVisibleTab(0)
    }
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
    if (setVisibleTab) {
      setVisibleTab(newValue)
    }
  }

  return (
    <Container className={classes.root}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="Manage Property"
        indicatorColor="primary"
        textColor="primary"
        className={classes.pageTabs}
        variant="fullWidth"
      >
        <Tab label="Personal Information" {...a11yProps(0)} />
        <Tab label="Property Details" {...a11yProps(1)} />
        <Tab label="Image" {...a11yProps(2)} />
        <Tab label="Statement" {...a11yProps(3)} />
        <Tab label="Delete" {...a11yProps(4)} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <Typography variant={'h3'} className={classes.title}>
          Please add your information
        </Typography>
        <EditPropertyPersonalInfo
          property={property}
          editPropertyFields={editPropertyFields}
          setEditPropertyFields={setEditPropertyFields}
          handleFieldChange={handleFieldChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <Typography variant={'h3'} className={classes.title}>
          Add your vendor's property to PropertyMate
        </Typography>
        <EditPropertyDetails
          property={property}
          editPropertyFields={editPropertyFields}
          setEditPropertyFields={setEditPropertyFields}
          handleFieldChange={handleFieldChange}
        />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <Typography variant={'h3'} className={classes.title}>
          Choose image
        </Typography>
        <EditPropertyImage
          property={property}
          editPropertyFields={editPropertyFields}
          setEditPropertyFields={setEditPropertyFields}
          handleFieldChange={handleFieldChange}
        />
      </TabPanel>
      {
        <TabPanel value={activeTab} index={3}>
          <Typography variant={'h3'} className={classes.title}>
            Statement of Intent
          </Typography>
          <EditPropertyUploadStatement
            property={property}
            editPropertyFields={editPropertyFields}
            setEditPropertyFields={setEditPropertyFields}
            handleFieldChange={handleFieldChange}
          />
        </TabPanel>
      }
      <TabPanel value={activeTab} index={4}>
        <div className={classes.centered}>
          <Typography variant={'h3'} className={classes.title}>
            Delete Property Listing
          </Typography>
          <Typography variant={'h5'} className={classes.text}>
            Are you sure you want to Delete this listing?
          </Typography>
        </div>
        <Container>
          <div className={classes.confirmActions}>
            <Button
              className={classes.ctaRounded}
              variant={'outlined'}
              color={'primary'}
              disableElevation
              onClick={() => {
                resetTabs()
                setManageItemToggle(false)
              }}
            >
              No, Cancel
            </Button>
            <Button
              className={classes.ctaRounded}
              variant={'contained'}
              color={'primary'}
              disableElevation
              onClick={apiDeleteProperty}
            >
              Yes, Confirm
            </Button>
          </div>
        </Container>
      </TabPanel>
    </Container>
  )
}

export default withStyles(styles)(EditPropertyDialogContent)
