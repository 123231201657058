export function errorhandler(error){
    if(error){
        if(error === undefined){
            return { message: 'Network Error', data: null }
        } else {
            if(error.response){
                return {
                    config: error.response.config || null,
                    data: error.response.data || null, 
                    message: error.response.data && error.response.data.reason && error.response.data.reason.message ? error.response.data.reason.message : error.response.statusText || null,
                    status: error.response.status || null, 
                }                
            } else {
                console.error('here')
                return { message: 'Something went wrong', data: null }
            }
        }
    }
}