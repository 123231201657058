import React, { useState } from 'react'
import MapHorizontalContainer from '../Map/MapHorizontalContainer'
import MapPropertyCard from '../Map/MapPropertyCard'
import _ from 'lodash'
import { withStyles } from '@material-ui/core'

const styles = (theme) => ({
  root: {},
  cardContainer: {
    display: 'flex',
    width: '100%',
    overflow: 'hidden',
    '& .HorizontalContainer': {
      width: '100%',
    },
  },
  horizontalContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
})

function iterateItems(obj) {
  const items = []
  for (const [key, details] of Object.entries(obj)) {
    items.push({ ...details })
  }
  return items
}

const MapPropertyCards = ({
  classes,
  properties,
  activeProperty,
  filterOffmarkets,

  visibleOffMarkets,
  currentScreen,
  setCurrentProperty,
  pathType,
  currentPath,
  drawingFilteredProperties,
  mapDrawEnabled,
  toggleSignUp,
  settings,
  apiRequestSaveProperty,
}) => {
  const [offMarketProperties, setOffMarketProperties] = useState(null);
  const notListedProperties = ['offmarket', 'notListed-xml', 'notListed-api', 'notListed-2dc'];
  const ALL_OFFMARKET = properties
    ? _.pickBy(properties, (e) => _.includes(notListedProperties, e.propertyStatus))
    : null

  React.useEffect(() => {
    if (ALL_OFFMARKET) {
      setOffMarketProperties(iterateItems(ALL_OFFMARKET))
    }
  }, [filterOffmarkets])

  React.useEffect(() => { }, [visibleOffMarkets])

  return (
    <div className={classes.root}>
      <div className={classes.cardContainer}>
        <MapHorizontalContainer className={classes.horizontalContainer}>
          {filterOffmarkets &&
            !activeProperty &&
            visibleOffMarkets &&
            visibleOffMarkets.length > 0 &&
            visibleOffMarkets.map((value, index) => {
              return (
                <MapPropertyCard
                  key={index}
                  properties={value}
                  type={'properties'}

                  currentScreen={currentScreen}
                  setCurrentProperty={setCurrentProperty}
                  pathType={pathType}
                  currentPath={currentPath}
                  toggleSignUp={toggleSignUp}
                  settings={settings}
                  apiRequestSaveProperty={apiRequestSaveProperty}
                />
              )
            })}

          {filterOffmarkets &&
            !activeProperty &&
            !visibleOffMarkets &&
            offMarketProperties &&
            offMarketProperties.map((value, index) => {
              return (
                <MapPropertyCard
                  key={index}
                  properties={value}
                  type={'properties'}

                  currentScreen={currentScreen}
                  setCurrentProperty={setCurrentProperty}
                  pathType={pathType}
                  currentPath={currentPath}
                  toggleSignUp={toggleSignUp}
                  settings={settings}
                  apiRequestSaveProperty={apiRequestSaveProperty}
                />
              )
            })}

          {!filterOffmarkets && activeProperty && (
            <MapPropertyCard
              key={activeProperty.property}
              properties={activeProperty}
              type={'properties'}
              active={activeProperty ? true : false}

              currentScreen={currentScreen}
              setCurrentProperty={setCurrentProperty}
              pathType={pathType}
              currentPath={currentPath}
              toggleSignUp={toggleSignUp}
              settings={settings}
              apiRequestSaveProperty={apiRequestSaveProperty}
            />
          )}

          {filterOffmarkets && activeProperty && (
            <MapPropertyCard
              key={activeProperty.property}
              properties={activeProperty}
              type={'properties'}
              active={activeProperty ? true : false}

              currentScreen={currentScreen}
              setCurrentProperty={setCurrentProperty}
              pathType={pathType}
              currentPath={currentPath}
              toggleSignUp={toggleSignUp}
              settings={settings}
              apiRequestSaveProperty={apiRequestSaveProperty}
            />
          )}

          {mapDrawEnabled &&
            !filterOffmarkets &&
            !activeProperty &&
            !visibleOffMarkets &&
            drawingFilteredProperties &&
            drawingFilteredProperties.map((value, index) => {
              return (
                <MapPropertyCard
                  key={index}
                  properties={value.details}
                  type={'properties'}

                  currentScreen={currentScreen}
                  setCurrentProperty={setCurrentProperty}
                  pathType={pathType}
                  currentPath={currentPath}
                  style={{ maxWidth: 155 }}
                  toggleSignUp={toggleSignUp}
                  settings={settings}
                  apiRequestSaveProperty={apiRequestSaveProperty}
                />
              )
            })}
        </MapHorizontalContainer>
      </div>
    </div>
  )
}

export default withStyles(styles)(MapPropertyCards)
