import { useState } from 'react';
import axios from 'axios';

const useCompanyInfoSearchField = ({ value, onValueChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const makeRequest = async (keyword) => {
    const method = 'post';
    const url = `${process.env.REACT_APP_PUBLIC_URL}/api/v1/agency/companyInfo`;
    const data = JSON.stringify({
      searchQuery: `${keyword}`,
    });
    const headers = {
      'accept-language': 'en-US,en;q=0.9',
      'content-type': 'application/json',
    };

    return axios({
      method,
      url,
      data,
      headers,
    });
  };

  const fetchResults = async (keyword) => {
    try {
      const response = await makeRequest(keyword);
      const results = response?.data?.data?.result;
      if (results && results.length > 0) {
        const tempOptions = [...results].map((item) => item.companyInfo);
        setOptions(tempOptions);
        setIsOpen(true);
      } else {
        setOptions([]);
        setIsOpen(false);
      }
    } catch (error) {
      setOptions([]);
    }
  };

  const onTextChangeHandler = (event) => {
    const value = event.target.value;
    if (value) {
      fetchResults(value);
    }
  };

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  const onCompanyInfoChangeHandler = (event, value, reason, details) => {
    onValueChange(value);
    setIsOpen(false);
  };

  return { onTextChangeHandler, onCloseHandler, onCompanyInfoChangeHandler, options, isOpen };
};

export default useCompanyInfoSearchField;
