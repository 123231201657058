import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {
  SuperBreadCrumbs,
  ManageChangeRequestsTable,
  Snackybar,
} from 'components/Web/Superadmin'

import { Loader } from 'components/Common'
/** redux */
import { superadminActions } from 'store/actions'

import { requestGETAPISuperadmin } from 'store/api'
import {
  getStatus,
  getErrors,
  getSuperadminChangeRequests,
} from 'store/selectors/superadmin.selectors'
import { withStyles } from '@material-ui/core'

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
})

const SuperadminChangeRequests = (props) => {
  const { classes, history } = props

  const dispatch = useDispatch()

  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)
  const CHANGE_REQUESTS = useSelector(getSuperadminChangeRequests)

  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  })

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Manage Change Requests',
      path: '/superadmin/change-requests',
    },
  ]

  useEffect(() => {
    handleGetChangeRequestList()
    /**
     * * Set Default Values
     */
    handleClearError()
    handleClearStatus()
  }, [])

  window.onbeforeunload = (e) => {
    return () => {
      handleGetChangeRequestList()
      /**
       * * Set Default Values
       */
      handleClearError()
      handleClearStatus()
    }
  }

  useEffect(() => {
    if (STATUS === 'superadmin/SUPERADMIN_GET_ALL_CHANGE_REQUESTS_REQUEST') {
      setLoading(true)
    }
    if (STATUS === 'superadmin/SUPERADMIN_GET_ALL_CHANGE_REQUESTS_FAILED') {
      setLoading(false)
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR?.message : ERROR,
      })
      handleClearError()
    }
    handleClearStatus()
  }, [STATUS, ERROR])

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    })
  }

  /** API Request */
  const handleGetChangeRequestList = () => {
    let request = {
      query: '?changeIntentState=NONE',
      data: {},
    }
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_ALL_CHANGE_REQUESTS'))
  }

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  }

  return (
    <div className={classes.root}>
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <SuperBreadCrumbs
        section={'Manage Change Requests'}
        crumbsLinks={CRUMBS}

      />
      <ManageChangeRequestsTable
        isChangeRequest={true}

        tableData={
          CHANGE_REQUESTS?.data?.entities
            ? _.orderBy(CHANGE_REQUESTS.data.entities, ['createdAt'], ['desc'])
            : null
        }
        pagination={{ totalItemCount: CHANGE_REQUESTS?.data?.entities.length }}
        pageSize={10}
        rowsPerPageOptions={[
          10,
          30,
          50,
          80,
          100,
          { value: CHANGE_REQUESTS?.data?.entities.length, label: 'All' },
        ]}
      />
    </div>
  )
}
export default withStyles(styles)(SuperadminChangeRequests)
