import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Heading from './Heading'
import CustomSwitch from './CustomSwitch'
import DynamicIcons from '../DynamicIcons'
import { fontFamily, headingFont } from '../../../utils/constants.utils'

import { Typography, withStyles, ButtonBase } from '@material-ui/core'

const Container = styled.div`
  padding: 10px 0 30px 0;
`

const ButtonWrap = styled.div``
const Item = styled.div`
  margin: 6px;
  display: inline-block;
  vertical-align: middle;
  width: 70px;
  height: 40px;
  text-align: center;
  border: 1px solid #000;
  border: 0.75px solid #35c0ca;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;
  button {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  p {
    color: #000;
  }
  &.is-active {
    background-color: #35c0ca;
    pointer-events: none;
  }
  &[disabled] {
    border-color: transparent;
    color: rgba(0, 0, 0, 0.26);
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;

    & p {
      color: rgba(0, 0, 0, 0.26);
    }
  }
`

const stateListData = [
  { id: 0, title: 'ACT', active: false },
  { id: 1, title: 'NSW', active: false },
  { id: 2, title: 'NT', active: false },
  { id: 3, title: 'QLD', active: false },
  { id: 4, title: 'SA', active: false },
  { id: 5, title: 'TAS', active: false },
  { id: 6, title: 'VIC', active: false },
  { id: 7, title: 'WA', active: false },
]

const StateSelect = (props) => {
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [listData, setListData] = useState(props.listData || stateListData)

  useEffect(() => {
    if (props.isClearFilter) {
      setListData(stateListData)
    }
  }, [props.isClearFilter])

  useEffect(() => {
    if (props.listData !== listData) {
      setListData(props.listData)
    }
  }, [props.listData])

  useEffect(() => {
    if (listData) {
      listData.map((item) => {
        if (item.active === false) {
          setIsSelectAll(false)
        }
      })
    }
  }, [listData])

  const handleChange = (obj, index) => {
    let data = listData.map((item) =>
      item.id === obj.id ? { ...item, active: !obj.active } : { ...item, active: false }
    )
    setListData(data)
    if (props.handlePerfectProperty) {
      props.handlePerfectProperty(data, 'state')
    }
  }

  function renderListItem(value, index) {
    return (
      <Item
        key={index}
        disabled={value.title !== 'VIC'}
        className={value.active ? 'is-active' : ''}
      >
        <ButtonBase
          focusRipple
          key={index}
          onClick={(e) => {
            handleChange(value, index)
          }}
          disabled={value.title !== 'VIC'}
        >
          <Typography>{value.title}</Typography>
        </ButtonBase>
      </Item>
    )
  }

  return (
    <Container>
      {listData &&
        listData.map((v, k) => {
          return renderListItem(v, k)
        })}
    </Container>
  )
}

export default StateSelect
