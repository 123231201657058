import React, { useState, useRef } from 'react'
import SatelliteIcon from '@material-ui/icons/Satellite'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import GestureIcon from '@material-ui/icons/Gesture'
import CloseIcon from '@material-ui/icons/Close'

import PropertySearchMapView from '../../components/Web/Map/PropertySearchMapViewDrawing'
import SearchFilterHeader from '../../components/Web/Property/SearchFilterHeader'
import Property from '../../components/Web/Property'
import PropertyLoader from '../../components/Mobile/Property/PropertyLoader'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Loader from '../../components/Mobile/Loader'
import AlertNotification from '../../components/Mobile/Form/AlertNotification'
import PropertySearchFilter from '../../components/Web/Searchbar/PropertySearchFilter'
import SavedSearch from '../../components/Mobile/SavedSearch'
import SortSelection from '../../components/Web/Searchbar/SortSelection'

import { scrollToRef } from '../../components/Mobile/ScrollEvents'
import _, { random } from 'lodash'

/** api redux request */
import {
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
  requestPropertySearchSold,
  requestPropertySearchRentals,
  requestGetPropertySave,
} from '../../store/api'

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters,
  saveSkipTakeData,
  clearSkipTakeData,
} from '../../store/actions'

/** Object Filters */
import { reform } from '../../utils/filters.utils'
import { formatRequest } from '../../utils/search.utils'

import { withStyles, Button, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { requestGETAPIMpp, requestPUTAPIMpp } from 'store/api/mpp.api'
import { getMpp } from 'store/selectors/mpp.selectors'

const styles = (theme) => ({
  root: {
    paddingLeft: 90,
    paddingTop: 135,
  },
  columnWrapper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(14.5),
      paddingRight: theme.spacing(14.5),
    },
    paddingBottom: 0,
  },
  cardColumn: {
    paddingTop: 0 + ' !important',
    paddingBottom: 0 + ' !important',
    overflowY: 'scroll',
  },
  propFilters: {
    padding: theme.spacing(1, 2),
  },
  mapColumn: {
    paddingTop: 0 + ' !important',
    paddingBottom: 0 + ' !important',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    // transform: 'translateY(-56px)'
  },
  mapWrapper: {
    overflow: 'hidden',
    position: 'relative',
  },
  propertyCards: {
    position: 'absolute',
    left: 0,
    bottom: theme.spacing(1),
    width: '100%',
  },
  mapTypeToggle: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',

    '& .MuiButton-contained': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: 12,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      textTransform: 'capitalize',
      fontSize: 12,
    },
  },
  offMarketToggle: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    '& .MuiButton-contained': {
      fontSize: 13,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'left',
      borderRadius: theme.spacing(2),
      // backgroundColor: '#ffffff'
    },
  },
  checkWrapper: {
    marginRight: theme.spacing(1),
    display: 'inline-flex',
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
})

const BackButton = withStyles({
  root: {
    zIndex: 20,
    background: '#ffffff',
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button)

const SearchResultsMapView = (props) => {
  const {
    classes,
    property,
    setCurrentPropertySelected,
    filters,
    savePropertySearch,
    authentication,
    settings,
    setIncludeNearbyFilter,
    setSearchFilters,
    setSearchSortingFilters,
    searchBuyProperty,
    searchRentProperty,
    searchSoldProperty,
    setCurrentProperty,
    save_property_status,
    save_property_error,
    clearSkipTakeData,
    saveSkipTakeData,
    skipTakeData,
    ...other
  } = props
  const [properties, setProperties] = React.useState(null)
  const [mapType, setMapType] = React.useState(false)
  const [offMarketToggle, setoffMarketToggle] = React.useState(false)
  const [topOffset, setTopOffset] = React.useState(0)
  const [comingSoonLength, setComingSoonLength] = React.useState(0)
  const [offMarketLength, setOffMarketLength] = React.useState(0)
  const [currentLength, setCurrentLength] = React.useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)

  const [searchResultProperties, setSearchResultProperties] = useState([])
  const [showSaveSearch, setShowSaveSearch] = useState(false)
  const [suburbs, setSuburbs] = useState('')
  const [isFiltered, setIsFiltered] = useState({ isActive: false, suburbs: null })

  const [showModalBottomOptions, setShowModalBottomOptions] = React.useState(false)

  const [activeProperty, setActiveProperty] = React.useState(null)
  const [visibleOffMarkets, setVisibleOffMarkets] = React.useState(null)
  const [suburbCoordinates, setSuburbCoordinates] = React.useState(null)
  const [previousScreen, setPreviousScreen] = React.useState(null)

  const [mapDrawEnabled, setMapDrawEnabled] = React.useState(false)
  const [invalidSearch, setInvalidSearch] = useState(false)
  const [omcsStatus, setOmcsStatus] = React.useState(false)

  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 20,
    propertiesFilters: [],
    totalResults: null,
  })

  const topContainer = useRef(null)
  const mapWrapperRef = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d) //add `false` as second parameter to scroll without transition.
  const BASE_RADIUS = 2000
  const NEARBY_RADIUS = 8000

  const getCurrentScreen = () => {
    console.debug(props.match ? props.match.url : '')
    return props.match ? props.match.url : ''
  }

  const MPP = useSelector(getMpp)
  const dispatch = useDispatch()

  const saveProperty = (params) =>
    dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'))

  const getSavedProperties = () =>
    dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'))

  React.useEffect(() => {
    if (MPP?.status === 'mpp/PUT_MPP_FAVOURITES_SUCCESS') {
      apiRequestGetSavedProperties()
    }
  }, [MPP])

  /** GET Save Property API */
  async function apiRequestGetSavedProperties() {
    getSavedProperties()
  }

  React.useEffect(() => {
    /**
     * property data setter */
    if (property) {
      if (
        property &&
        property.properties &&
        property.properties.data &&
        property.properties.data.data
      ) {
        setIsLoading(false)
        saveSkipTakeData(property.properties.data.data)
      }
    }

    /**
     * success handler save search property  */
    if (
      property &&
      property.payload &&
      property.payload.data &&
      property.payload.data &&
      property.payload.data.message === 'POST request successful'
    ) {
      /** refresh data */
      //clearSkipTakeData()
      //apiRequestSearchProperty();
    }
    /**
     * property data setter */
    if (property) {
      if (
        property.payload &&
        property.payload.data &&
        property.payload.data.ok &&
        isLoading
      ) {
        setIsLoading(false)
        if (
          property &&
          property.properties &&
          property.properties.data &&
          property.properties.data.data
        ) {
          saveSkipTakeData(property.properties.data.data)
        }
      }
    }

    /**
     * error hanlder property search */
    if (property && property.properties && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }
    /**
     * error hanlder save property */
    if (property && property.properties && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }

    if (mapWrapperRef) {
      setTopOffset(mapWrapperRef.current.offsetTop)
    }

    if (filters && filters.suburbs.length > 0) {
      setInvalidSearch(false)
      setSuburbCoordinates({
        lat: filters.suburbs[0].lat,
        lng: filters.suburbs[0].long,
      })
    } else {
      setInvalidSearch(true)
    }
  }, [property])

  React.useEffect(() => {
    if (skipTakeData && skipTakeData.propertiesFilters) {
      setIsLoading(false)
      setProperties(_.uniq(skipTakeData.propertiesFilters))

      defaultSortData(skipTakeData)
      if (filters) {
        const filterData = Object.assign([], filters.suburbs)
        if (filterData && filterData.length > 0) {
          setIsFiltered({ isActive: true, suburbs: filterData })
        } else {
          setIsFiltered({ isActive: false, suburbs: null })
        }
        if (filters.sorting) {
          handleSortBy(filters.sorting)
        }
      } else {
        setIsFiltered({ isActive: false, suburbs: null })
      }
    }

    if (omcsStatus) {
      handleFilter(omcsStatus)
    }
  }, [skipTakeData])

  React.useEffect(() => {
    executeTopScroll(true)

    /** GET PREVIOUS SCREEN */
    setPreviousScreen(localStorage.getItem('previous_screen'))

    /* if(skipTakeData && skipTakeData.propertiesFilters.length <= 0) {
      window.location.href = '/' + getRoutePath()
    } */

    console.log(localStorage.getItem('previous_screen'), 'Previous Screen')

    return () => {
      clearSkipTakeData()
    }
  }, [])

  /** Search Property */
  async function apiRequestSearchProperty(skipTakeData, requestIndex) {
    if (!skipTakeData || !requestIndex) {
      clearSkipTakeData()
    }
    setIsLoading(true)

    let path = getRoutePath()
    let params = await formatRequest(path, filters, skipTakeData, requestIndex)
    if (path === 'buy' || path === 'off-market') {
      searchBuyProperty(params)
    }
    if (path === 'sold') {
      searchSoldProperty(params)
    }
    if (path === 'rent') {
      searchRentProperty(params)
    }
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const [mapData, setMapData] = useState({
    map: {
      coordinates: [-37.7963, 144.92512],
      center: { lat: -37.7963, lng: 144.92512 },
      comingsoon: [],
      offmarket: [],
      current: [],
      zoom: 13,
    },
  })

  const getRandomCoordinates = (lat, lng, r) => {
    const METERS_IN_DEGREE = 111_300
    const u = Math.random(),
      v = Math.random()

    let radius = parseFloat(r) / METERS_IN_DEGREE

    let w = radius * Math.sqrt(u)
    let t = 2 * Math.PI * v

    let x = (w * Math.cos(t)) / Math.cos(lat)
    let y = w * Math.sin(t)

    return { lat: y + lat, lng: x + lng } //returns an object of lat long pair
  }

  function isWithinRadius(checkPoint, centerPoint, km) {
    var ky = 40000 / 360
    var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky
    km = km / 1000
    return Math.sqrt(dx * dx + dy * dy) <= km
  }

  function generateRandomCoordinates(center, radius, count) {
    let i
    let coords = []

    for (i = 0; i < count; i++) {
      coords.push(getRandomCoordinates(center, radius))
    }

    return coords
  }

  function iterateItems(obj, r) {
    const items = []

    for (const [key, details] of Object.entries(obj)) {
      if (r && !details.latitude && !details.longitude) {
        const randomCoordinate = getRandomCoordinates(
          ...mapData.map.coordinates,
          r ? r : BASE_RADIUS
        )
        items.push({ ...randomCoordinate, details })
      } else {
        if (
          details.latitude &&
          details.longitude &&
          details.propertyStatus !== 'offmarket'
        ) {
          items.push({
            ...{
              lat: parseFloat(details.latitude),
              lng: parseFloat(details.longitude),
            },
            details,
          })
        } else {
          const randomCoordinate = getRandomCoordinates(
            parseFloat(details.latitude),
            parseFloat(details.longitude),
            r ? r : BASE_RADIUS
          )
          items.push({ ...randomCoordinate, details })
        }
      }
    }

    return items
  }

  React.useEffect(() => {
    const notListedProperties = ['offmarket', 'notListed-xml', 'notListed-api', 'notListed-2dc'];
    if (pagination && pagination.propertiesFilters.length > 0) {
      const ALL_COMING_SOON = _.pickBy(pagination.propertiesFilters, (e) =>
        _.includes(['comingsoon'], e.propertyStatus)
      )
      const ALL_OFFMARKET = _.pickBy(pagination.propertiesFilters, (e) =>
        _.includes(notListedProperties, e.propertyStatus)
      )
      const ALL_CURRENT = _.pickBy(pagination.propertiesFilters, (e) =>
        _.includes(['current', 'On-Sale', 'rental', 'sold'], e.propertyStatus)
      )

      let i
      setComingSoonLength(Object.keys(ALL_COMING_SOON).length)
      setOffMarketLength(Object.keys(ALL_OFFMARKET).length)
      setCurrentLength(Object.keys(ALL_CURRENT).length)

      setMapData((prevData) => ({
        map: {
          ...prevData.map,
          center: suburbCoordinates,
          comingsoon: iterateItems(ALL_COMING_SOON),
          offmarket: iterateItems(ALL_OFFMARKET, 200),
          current: iterateItems(ALL_CURRENT),
          comingsoonlimit: comingSoonLength,
          offmarketlimit: offMarketLength,
          currentlimit: currentLength,
        },
      }))
    }
    //END OF DUMMY MARKERS GENERATION
  }, [pagination])

  React.useEffect(() => {
    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        maptype: mapType,
        drawing: mapDrawEnabled,
      },
    }))
  }, [mapType, mapDrawEnabled])

  React.useEffect(() => {
    if (suburbCoordinates) {
      setMapData((prevData) => ({
        map: {
          ...prevData.map,
          center: suburbCoordinates,
          coordinates: [suburbCoordinates.lat, suburbCoordinates.lng],
        },
      }))
    }
  }, [suburbCoordinates])

  const activePropertySetter = (property) => {
    setActiveProperty(property)
  }

  const offMarketToggleSetter = (value) => {
    setoffMarketToggle(value)
  }

  const visibleOffmarketItems = (items) => {
    //if(!visibleOffMarkets.some(v => v["property"] === item.property)) {
    setVisibleOffMarkets((prevData) => items)
    //}
  }

  function getMainPath() {
    if (props.match.url === '/buy/map-view') {
      localStorage.setItem('previous_path', 'buy')
      return 'buy'
    }
    if (props.match.url === '/rent/map-view') {
      localStorage.setItem('previous_path', 'rent')
      return 'rent'
    }
    if (props.match.url === '/sold/map-view') {
      localStorage.setItem('previous_path', 'sold')
      return 'sold'
    }
  }

  React.useEffect(() => {
    if (isFiltered) {
      let suburb = []
      if (isFiltered.suburbs && isFiltered.suburbs.length) {
        isFiltered.suburbs.map((item, index) => {
          suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode)
        })
      }
      setSuburbs(suburb)
    }
  }, [isFiltered])

  function showOptionBottomModal() {
    setShowModalBottomOptions(!showModalBottomOptions)
  }

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  const defaultSortData = (data) => {
    const propertyData = Object.assign({}, data)
    const propertiesFilters = Object.assign([], _.uniq(propertyData.propertiesFilters))
    const sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['asc'])
    propertyData.propertiesFilters = sortedData
    const pageData = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      propertiesFilters: paginate(
        sortedData,
        pagination.pageSize,
        pagination.pageNumber
      ),
      totalResults: propertyData.totalResults,
    }
    setSearchResultProperties(propertyData)
    setPagination(pageData)
  }

  const handleSortBy = (data, propertiesData) => {
    setSearchSortingFilters(data)
    const propertyData = Object.assign({}, skipTakeData)
    const propertiesFilters = Object.assign([], _.uniq(propertyData.propertiesFilters))
    let type = _.filter(data, function (o) {
      return o.active
    })
    let sortedData = propertiesFilters
    if (data && type && type.length > 0) {
      switch (type[0].value) {
        case 'Relevance':
          sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['asc'])
          break
        case 'Newest':
          sortedData =
            getRoutePath() === 'sold'
              ? sortData(propertiesFilters, ['soldDate'], 'desc')
              : sortData(propertiesFilters, ['publishDate'], 'desc')
          break
        case 'Price (High to Low)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                propertiesFilters,
                ['rentalPrice', 'publishDate'],
                ['desc', 'desc']
              )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['desc', 'desc'])
          break
        case 'Price (Low to High)':
          sortedData =
            getRoutePath() === 'rent'
              ? sortData(
                propertiesFilters,
                ['rentalPrice', 'publishDate'],
                ['asc', 'asc']
              )
              : sortData(propertiesFilters, ['price', 'publishDate'], ['asc', 'asc'])
          break
        case 'Bedrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bedrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Bathrooms':
          sortedData = sortData(
            propertiesFilters,
            ['bathrooms', 'price'],
            ['desc', 'desc']
          )
          break
        case 'Sqm':
          sortedData = sortData(
            propertiesFilters,
            ['landSize', 'price'],
            ['desc', 'desc']
          )
          break
        default:
          sortedData = sortData(propertiesFilters, ['subscriptionTypeWeight'], ['asc'])
      }
      propertyData.propertiesFilters = sortedData
      const pageData = {
        pageNumber: pagination.pageNumber,
        pageSize: pagination.pageSize,
        propertiesFilters: paginate(
          sortedData,
          pagination.pageSize,
          pagination.pageNumber
        ),
        totalResults: propertyData.totalResults,
      }
      setSearchResultProperties(propertyData)
      setPagination(pageData)
    }
  }

  const sortData = (data, type, order) => {
    const sortBy = type
    return _.orderBy(
      data,
      sortBy.map((s) => {
        return (r) => {
          return r[s] ? r[s] : ''
        }
      }),
      order
    )
  }

  const handleFilter = (toggleStatus) => {
    let propertyData = Object.assign(
      {},
      property && property.properties && skipTakeData
    )
    let propertiesFilters = Object.assign([], _.uniq(propertyData.propertiesFilters))
    let filteredData = propertiesFilters

    if (!toggleStatus[0].active && !toggleStatus[1].active) {
      propertyData = Object.assign({}, skipTakeData)
      propertiesFilters = Object.assign([], _.uniq(propertyData.propertiesFilters))
      filteredData = propertiesFilters
    } else {
      filteredData = _.filter(propertiesFilters, function (o) {
        let propertyStatus = o.propertyStatus
          ? o.propertyStatus.toLowerCase().replace('-', '')
          : null
        if (toggleStatus[0].active && toggleStatus[1].active && propertyStatus) {
          return (
            propertyStatus === toggleStatus[0].label ||
            propertyStatus === toggleStatus[1].label
          )
        } else if (
          toggleStatus[0].active &&
          !toggleStatus[1].active &&
          propertyStatus
        ) {
          return propertyStatus === toggleStatus[0].label
        } else if (
          !toggleStatus[0].active &&
          toggleStatus[1].active &&
          propertyStatus
        ) {
          return propertyStatus === toggleStatus[1].label
        } else {
          return o
        }
      })
    }

    const data = filteredData

    const pageData = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      propertiesFilters: paginate(data, pagination.pageSize, pagination.pageNumber),
      totalResults: data.length,
    }

    const pData = {
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
      propertiesFilters: data,
      totalResults: data.length,
    }

    setSearchResultProperties(pData)
    setPagination(pageData)
  }

  const handlePagination = () => {
    const nextPage = pagination.pageNumber + 1
    //  paginate(data, pagination.pageSize, nextPage) : pagination.propertiesFilters.concat(paginate(data, pagination.pageSize, nextPage)),
    const filterData = _.uniq(pagination.propertiesFilters).concat(
      paginate(
        _.uniq(searchResultProperties.propertiesFilters),
        pagination.pageSize,
        nextPage
      )
    )
    const pageData = {
      pageNumber: nextPage,
      pageSize: pagination.pageSize,
      propertiesFilters: filterData,
      totalResults: searchResultProperties.totalResults,
    }
    // setSearchResultProperties(pageData);
    setPagination(pageData)
  }

  const paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size)
  }

  const handleMatchCount = (property) => {
    let count = 0
    if (filters && property) {
      filters.filters.advanceProperty.map((item, index) => {
        if (item.active && property.coefficients) {
          if (property.coefficients[item.key] >= 0.7) {
            count++
          }
        }
      })
    }
    return count
  }

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp)
    } else {
      /** User Logged */
      showSaveSearchModal(true)
      //apiRequestSaveSearch()
    }
  }

  /** Save Property Search API */
  function apiRequestSaveSearch(params) {
    setIsLoading(true)

    savePropertySearch(params)
  }

  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    }

    saveProperty(params)
  }

  /** Show Save Search */
  function showSaveSearchModal(params) {
    setShowSaveSearch(params)
  }

  const setMapCenter = (centerObj) => {
    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        center: centerObj,
        coordinates: [centerObj.lat, centerObj.lng],
      },
    }))
  }

  const type = 'search',
    sortType = 'search'

  return (
    <div className={classes.root}>
      <div ref={topContainer} className={classes.topAnchor}></div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />
      <SearchFilterHeader
        {...other}
        getRoutePath={getRoutePath}
        invalidSearch={invalidSearch}
        setInvalidSearch={setInvalidSearch}
      />
      <Grid container spacing={4} className={classes.columnWrapper}>
        <Grid
          item
          xs={6}
          className={classes.cardColumn}
          id="cards-scroll-column"
          style={{ height: window.innerHeight - topOffset }}
        >
          <SavedSearch
            showSaveSearch={showSaveSearch}
            filters={filters}
            showSaveSearchModal={showSaveSearchModal}
            apiRequestSaveSearch={apiRequestSaveSearch}
            path={getRoutePath()}
          />

          <div className={classes.propFilters}>
            <PropertySearchFilter
              handleFilter={handleFilter}

              history={props.history}
              view={'map-view'}
              propertyCount={pagination ? pagination.totalResults : 0}
              propertySuburbs={filters && filters.suburbs}
              filters={filters}
              checkLoginStatus={checkLoginStatus}
              setOmcsStatus={setOmcsStatus}
            />
          </div>

          {isFiltered &&
            isFiltered.isActive &&
            type === 'search' &&
            properties &&
            properties.propertiesFilters &&
            properties.propertiesFilters.length > 0 && (
              <>
                <SavedSearch
                  propertyCount={properties ? properties.totalResults : 0}
                  propertySuburbs={suburbs}
                  authentication={authentication}
                  settings={settings}
                  toggleSignUp={toggleSignUp}
                  apiRequestSaveSearch={apiRequestSaveSearch}
                  showSaveSearchModal={showSaveSearchModal}
                />
              </>
            )}

          {properties &&
            properties.propertiesFilters &&
            properties.propertiesFilters.length > 0 && (
              <>
                <SortSelection
                  sortType={sortType}
                  current={
                    properties
                      ? properties.propertiesFilters
                        ? properties.propertiesFilters.length
                        : 0
                      : 0
                  }
                  total={properties ? properties.totalResults : 0}
                  setSearchSortingFilters={setSearchSortingFilters}
                  filters={filters}
                  showModalBottomOptions={showModalBottomOptions}
                  showOptionBottomModal={showOptionBottomModal}
                />
              </>
            )}

          <Property
            authentication={authentication}
            settings={settings}
            toggleSignUp={toggleSignUp}
            setCurrentProperty={setCurrentProperty}
            properties={pagination}
            property={property}
            isFiltered={isFiltered}
            isLoading={isLoading}
            currentScreen={getRoutePath()}

            apiRequestSaveProperty={apiRequestSaveProperty}
            handlePagination={handlePagination}
            handleMatchCount={handleMatchCount}
            listingType={getRoutePath()}
            apiRequestSearchProperty={apiRequestSearchProperty}
            skipTakeData={skipTakeData}
            origin={'map-view'}
            offMarketToggle={offMarketToggle}
          />
          {pagination && pagination.propertiesFilters.length > 0 && (
            <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
          )}
        </Grid>
        <Grid item xs={6} className={classes.mapColumn}>
          <div
            className={classes.mapWrapper}
            ref={mapWrapperRef}
            style={{ height: window.innerHeight - topOffset }}
          >
            <PropertySearchMapView
              style={{ height: '674px' }}
              data={{
                mode: 'map',
                title: 'Map View',
                ...mapData,
                address: '',
                radius: BASE_RADIUS,
                filteroffmarket: offMarketToggle,
              }}
              mapDrawEnabled={mapDrawEnabled}
              setMapDrawEnabled={setMapDrawEnabled}
              activePropertySetter={activePropertySetter}
              offMarketToggleSetter={offMarketToggleSetter}
              visibleOffmarketItems={visibleOffmarketItems}
              setCurrentProperty={setCurrentProperty}

              currentPath={getRoutePath()}
              enableSatellite={mapType}
              setMapCenter={setMapCenter}
            />

            {offMarketLength && (
              <div className={classes.offMarketToggle}>
                <Button
                  variant="contained"
                  disableElevation
                  color={offMarketToggle ? 'secondary' : 'inherit'}
                  style={{ backgroundColor: !offMarketToggle ? '#ffffff' : '' }}
                  onClick={() => {
                    setoffMarketToggle(!offMarketToggle)
                    setActiveProperty(null)
                  }}
                >
                  {!offMarketToggle && <CheckBoxOutlineBlankIcon fontSize="small" />}
                  <span className={classes.checkWrapper}>
                    {offMarketToggle && (
                      <CheckBoxIcon
                        fontSize="small"
                        color={offMarketToggle ? 'primary' : 'inherit'}
                      />
                    )}
                  </span>

                  <span>{offMarketLength} Off Market Properties</span>
                </Button>
              </div>
            )}
            <div className={classes.propertyCards}>
              <div style={{ position: 'relative' }}>
                <div className={classes.mapTypeToggle}>
                  <Button
                    variant="contained"
                    disableElevation
                    color={mapDrawEnabled ? 'primary' : 'inherit'}
                    onClick={() => {
                      setMapDrawEnabled(!mapDrawEnabled)
                    }}
                  >
                    {!mapDrawEnabled ? (
                      <GestureIcon fontSize="small" />
                    ) : (
                      <CloseIcon fontSize="small" />
                    )}
                    <span>{!mapDrawEnabled ? 'Draw' : 'Done'}</span>
                  </Button>

                  <Button
                    variant="contained"
                    disableElevation
                    color={mapType ? 'primary' : 'inherit'}
                    onClick={() => {
                      setMapType(!mapType)
                    }}
                  >
                    <SatelliteIcon fontSize="small" />
                    <span>Satellite</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
    save_property_status: state.property.property_save_property_status,
    save_property_error: state.property.property_save_property_error,
    skipTakeData: state.skipTakeData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchBuyProperty: (params) => requestPropertySearch(params),
      searchSoldProperty: (params) => requestPropertySearchSold(params),
      searchRentProperty: (params) => requestPropertySearchRentals(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setSearchSortingFilters: (params) => setSortingFilters(params),
      saveSkipTakeData: (params) => saveSkipTakeData(params),
      clearSkipTakeData: () => clearSkipTakeData(),
    },
    dispatch
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SearchResultsMapView))
