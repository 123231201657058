import React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import useFilters from 'utils/customHooks/useFilters'
import { priceRangeLabelFormatter } from 'utils/formatter/priceRangeLabelFormatter'
import { Box, Button, makeStyles } from '@material-ui/core'
import { PRICE_RANGE_RENT } from 'constants/globalfilters'
import { PRICE_RANGE } from 'constants/globalfilters'

const useStyles = makeStyles((theme) => ({
  // Modal filter button
  modalFilterButton: {
    height: '2.250em',
    textTransform: 'capitalize',
    marginLeft: '0.313em',
    marginRight: '0.313em',
    borderRadius: '1.250em',
    whiteSpace: 'noWrap',
    fontWeight: 'normal',
    padding: '12px 15px',
    lineHeight: '2.250em',
    '@media (max-width: 1024px)': {
      padding: '0px 10px',
    },
    '@media (max-width: 890px)': {
      padding: '12px 8px',
      fontSize: '13px',
      '& .MuiButton-endIcon': {
        marginLeft: 4,
      },
    },
    '@media (max-width: 768px)': {
      fontSize: '11px',
    },
  },
}))

const getPropertyTypeButtonText = (propertyTypes) => {
  const activePropertyTypeCount = propertyTypes.filter(
    (propertyType) => propertyType.active
  ).length

  let addPropertyTypeSuffix = activePropertyTypeCount > 1

  if (activePropertyTypeCount === 0) {
    return 'Property Type'
  }

  return `${activePropertyTypeCount} Property Type${addPropertyTypeSuffix ? 's' : ''}`
}

const PropertyBedBathroomFilter = ({ filter, handleFilterButtonClick }) => {
  const classes = useStyles()

  const isExactFilter = filter.exact
  const isPluralFilter = filter.value > 1

  let filterButtonMessage = ''

  if (filter.value === 0) {
    filterButtonMessage = `${filter.label}s`
  } else {
    filterButtonMessage = `${filter.value}${isExactFilter ? '' : '+'} ${filter.label}${
      isPluralFilter ? 's' : ''
    }`
  }

  return (
    <Button
      className={classes.modalFilterButton}
      variant="outlined"
      endIcon={<KeyboardArrowDownIcon />}
      onClick={(event) =>
        handleFilterButtonClick(filter.label === 'Bedroom' ? 'bed' : 'bath')
      }
    >
      <Box>{filterButtonMessage}</Box>
    </Button>
  )
}

const ModalFilterButtons = ({ handleFilterButtonClick, path }) => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()

  const PROPERTY_TYPE = getFilter('property_type')
  const PROPERTY_BEDROOMS = getFilter('bedroom')
  const PROPERTY_BATHROOMS = getFilter('bathroom')
  const PRICE = getFilter('price')
  const RENT_PRICE = getFilter('price_rent')

  const priceButtonText =
    path === 'rent'
      ? priceRangeLabelFormatter(
          RENT_PRICE.min,
          RENT_PRICE.max,
          PRICE_RANGE_RENT[0],
          PRICE_RANGE_RENT[1],
          null,
          true
        )
      : priceRangeLabelFormatter(
          PRICE.min,
          PRICE.max,
          PRICE_RANGE[0],
          PRICE_RANGE[1],
          null,
          true
        )

  const propertyTypeButtonText = getPropertyTypeButtonText(PROPERTY_TYPE)

  return (
    <Box>
      <PropertyBedBathroomFilter
        handleFilterButtonClick={handleFilterButtonClick}
        filter={PROPERTY_BEDROOMS}
      />
      <Button
        className={classes.modalFilterButton}
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event) => handleFilterButtonClick('cost')}
      >
        <Box>{priceButtonText}</Box>
      </Button>
      <PropertyBedBathroomFilter
        handleFilterButtonClick={handleFilterButtonClick}
        filter={PROPERTY_BATHROOMS}
      />
      <Button
        className={classes.modalFilterButton}
        variant="outlined"
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event) => handleFilterButtonClick('propertyType')}
      >
        <Box>{propertyTypeButtonText}</Box>
      </Button>
    </Box>
  )
}

export default ModalFilterButtons
