import {
  TOGGLE_THEME_MODE,
  TOGGLE_FILTER,
  TOGGLE_SIGN_UP,
  TOGGLE_FILTER_WEB,
  NAVIGATE_RESET,
  TOGGLE_FORGOT_PASSWORD,
  MPP_SCROLL,
} from '../constants'

export const toggleThemeMode = (enableDark) => {
  return {
    type: TOGGLE_THEME_MODE,
    enableDark,
  }
}

export const toggleMPPScroll = (mppScroll) => {
  return {
    type: MPP_SCROLL,
    mppScroll,
  }
}

export const toggleSignUp = (showSignUp, targetTab) => {
  return {
    type: TOGGLE_SIGN_UP,
    showSignUp,
    targetTab,
  }
}

export const toggleForgotPassword = (showForgotPassword) => {
  return {
    type: TOGGLE_FORGOT_PASSWORD,
    showForgotPassword,
  }
}

export const toggleFilter = (showFilter) => {
  return {
    type: TOGGLE_FILTER,
    showFilter,
  }
}

export const toggleFilterWeb = (showFilter) => {
  return {
    type: TOGGLE_FILTER_WEB,
    showFilter,
  }
}

export const navigateReset = () => {
  return {
    type: NAVIGATE_RESET,
  }
}
