import {
    LOGOUT_REQUEST,

    FACEBOOK_AUTH_REQUEST,
    FACEBOOK_AUTH_SUCCESS,
    FACEBOOK_AUTH_FAIL,

    GOOGLE_AUTH_REQUEST,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,

    APPLE_AUTH_REQUEST,
    APPLE_AUTH_SUCCESS,
    APPLE_AUTH_FAIL,

    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,

    VERIFY_REGISTRATION_REQUEST,
    VERIFY_REGISTRATION_SUCCESS,
    VERIFY_REGISTRATION_FAIL,

    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    CLEAR_LOGIN_STATES,

    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,

    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,

    RESEND_CODE_REQUEST,
    RESEND_CODE_FAIL,
    RESEND_CODE_SUCCESS,

    CLEAR_SESSION,

    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,

    VALIDATE_USER_TOKEN_REQUEST,
    VALIDATE_USER_TOKEN_SUCCESS,
    VALIDATE_USER_TOKEN_FAIL

} from '../constants';

/** LOGOUT ACTIONS */
export function logoutRequest(params) {
    return {
        type: LOGOUT_REQUEST,
        params
    }
}

/** FACEBOOK ACTIONS */
export function facebookAuthRequest(params) {
    return {
        type: FACEBOOK_AUTH_REQUEST,
        params
    }
}

export function facebookAuthSuccess(payload) {
    return {
        type: FACEBOOK_AUTH_SUCCESS,
        payload
    }
}

export function facebookAuthError(error) {
    return {
        type: FACEBOOK_AUTH_FAIL,
        error
    }
}

/** GOOGLE ACTIONS */
export function googleAuthRequest(params) {
    return {
        type: GOOGLE_AUTH_REQUEST,
        params
    }
}

export function googleAuthSuccess(payload) {
    return {
        type: GOOGLE_AUTH_SUCCESS,
        payload
    }
}

export function googleAuthError(error) {
    return {
        type: GOOGLE_AUTH_FAIL,
        error
    }
}

/** APPLE ACTIONS */
export function appleAuthRequest(params) {
    return {
        type: APPLE_AUTH_REQUEST,
        params
    }
}

export function appleAuthSuccess(payload) {
    return {
        type: APPLE_AUTH_SUCCESS,
        payload
    }
}

export function appleAuthError(error) {
    return {
        type: APPLE_AUTH_FAIL,
        error
    }
}

/** REGISTRATION ACTIONS */
export function registrationRequest(params) {
    return {
        type: REGISTER_USER_REQUEST,
        params
    }
}

export function registrationSuccess(payload) {
    return {
        type: REGISTER_USER_SUCCESS,
        payload
    }
}

export function registrationError(error) {
    return {
        type: REGISTER_USER_FAIL,
        error
    }
}

/** VERIFY ACTIONS */
export function verifyRegistrationRequest(params) {
    return {
        type: VERIFY_REGISTRATION_REQUEST,
        params
    }
}

export function verifyRegistrationSuccess(payload) {
    return {
        type: VERIFY_REGISTRATION_SUCCESS,
        payload
    }
}

export function verifyRegistrationError(error) {
    return {
        type: VERIFY_REGISTRATION_FAIL,
        error
    }
}

/** LOGIN ACTIONS */
export function loginRequest(params) {
    return {
        type: LOGIN_USER_REQUEST,
        params
    }
}

export function loginSuccess(payload) {
    return {
        type: LOGIN_USER_SUCCESS,
        payload
    }
}

export function loginError(error) {
    return {
        type: LOGIN_USER_FAIL,
        error
    }
}

export function clearLoginStates() {
    return {
        type: CLEAR_LOGIN_STATES,
    }
}

/** FORGOT PASSWORD ACTIONS */
export function forgotPasswordRequest(params) {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        params
    }
}

export function forgotPasswordSuccess(payload) {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload
    }
}

export function forgotPasswordError(error) {
    return {
        type: FORGOT_PASSWORD_FAIL,
        error
    }
}

/** CHANGE PASSWORD ACTIONS */
export function changePasswordRequest(params) {
    return {
        type: CHANGE_PASSWORD_REQUEST,
        params
    }
}

export function changePasswordSuccess(payload) {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload
    }
}

export function changePasswordError(error) {
    return {
        type: CHANGE_PASSWORD_FAIL,
        error
    }
}

/** RESEND CODE ACTIONS */
export function resendCodeRequest(params) {
    return {
        type: RESEND_CODE_REQUEST,
        params
    }
}

export function resendCodeSuccess(payload) {
    return {
        type: RESEND_CODE_SUCCESS,
        payload
    }
}

export function resendCodeError(error) {
    return {
        type: RESEND_CODE_FAIL,
        error
    }
}

/** CLEAR SESSION */
export function clearSession() {
    return {
        type: CLEAR_SESSION,
    }
}

export function validateUserToken(params) {
    return {
        type: VALIDATE_USER_TOKEN_REQUEST,
        params
    }
}

export function validateUserTokenSuccess(payload) {
    return {
        type: VALIDATE_USER_TOKEN_SUCCESS,
        payload
    }
}

export function validateUserTokenError(error) {
    return {
        type: VALIDATE_USER_TOKEN_FAIL,
        error
    }
}

/** UPDATE USER */
export function updateUser(params) {
    return {
        type: UPDATE_USER,
        params
    }
}

export function updateUserSuccess(payload) {
    return {
        type: UPDATE_USER_SUCCESS,
        payload
    }
}

export function updateUserError(error) {
    return {
        type: UPDATE_USER_FAIL,
        error
    }
}