import { useDispatch, useSelector } from 'react-redux';
import { StorageActions } from 'store/actions/storage.actions';
import { getSuperadminManageAgencyFilters } from 'store/selectors/storage.selectors';

export default function useHandleManageAgencyFilters() {
  const dispatch = useDispatch();
  const manageFilters = useSelector(getSuperadminManageAgencyFilters);

  const setManageFilters = (filters) => {
    dispatch(StorageActions(filters, 'GENERAL_SETTINGS', 'SET_SUPERADMIN_MANAGE_AGENCY_FILTERS'));
  };

  return [manageFilters, setManageFilters];
}
