import React, { useRef, useState, useEffect } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Searchbar from "../../components/Mobile/Searchbar";
import PropertySearchFilter from "../../components/Mobile/Searchbar/PropertySearchFilter";
import PropertyFaveLikes from "../../components/Mobile/Property/PropertyFaveLikes";
import PropertyLoader from "../../components/Mobile/Property/PropertyLoader";
import Loader from "../../components/Mobile/Loader";
import AlertNotification from "../../components/Mobile/Form/AlertNotification";

import { scrollToRef } from "../../components/Mobile/ScrollEvents";
import DynamicIcons from "../../components/Mobile/DynamicIcons";

import _ from "lodash";

/** api redux request */
import {
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
  requestLikeProperties,
  requestGetPropertySave,
} from "../../store/api";

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters,
  clearLikedProperties
} from "../../store/actions";

import { Button, withStyles } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";

const BackButton = withStyles({
  root: {
    //width: '100%',
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  content: {
    backgroundColor: "#f5f5f5",
  },
  backButtonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    zIndex: 20,
    backgroundColor: "#FFF",
    position: "relative",
  },
  topAnchor: {
    position: "relative",
    width: "100%",
    height: 0,
    top: 0,
    transform: "translateY(-56px)",
  },
});

const LikedProperties = (props) => {
  const {
    classes,
    authentication,
    settings,
    property,
    filters,
    toggleSignUp,
    toggleFilter,
    saveProperty,
    savePropertySearch,
    searchProperty,
    getLikedProperties,
    setCurrentProperty,
    clearCurrentProperty,
    setSearchFilters,
    setSearchSortingFilters,
    getSavedProperties,
    property_save_status,
    property_save_error,
    likedPropertiesClear
  } = props;
  const [routerChange] = useRouterChange()
  const topContainer = useRef(null);
  const executeTopScroll = (d) => scrollToRef(topContainer, d); //add `false` as second parameter to scroll without transition.

  const [isFiltered, setIsFiltered] = useState({ isActive: false, suburbs: null });
  const [isLoading, setIsLoading] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);
  const [likedPropertiesData, setLikedPropertiesData] = useState([]);
  const [isFlag, setIsFlag] = useState(false);

  const getRoutePath = () => {
    return props.match ? props.match.path.split("/")[1] : "";
  };

  const [pagination, setPagination] = useState({
    propertiesFilters: [],
    totalResults: null,
  });

  useEffect(() => {
    if (authentication && authentication.token === null) {
      routerChange("/");
    }
  }, [authentication]);

  useEffect(() => {
    /**
     * Initial Scroll to Top
     * */
    likedPropertiesClear()
    executeTopScroll(false);
    localStorage.removeItem("hideState");
    if (authentication && authentication.user_status === "logged") {
      apiRequestLikedProperties();
    } else {
      toggleSignUp(true);
    }
  }, []);

  useEffect(() => {
    if (property_save_status === "success" && !property_save_error) {
      getSavedProperties();
    }
  }, [property_save_status, property_save_error]);

  /* 
  handle properties callback */
  useEffect(() => {
    /**
     * property data setter */
    if (property) {
      setIsLoading(false);
      if (property?.properties_liked?.data?.data) {
        defaultSortData(property.properties_liked.data);
      }
      /** ------ NOTIFICATIONS ------*/
      /**
       * error hanlder property search
       * */
      if (property.favorite_properties_error && isLoading) {
        handleNotification(true, {
          message: "Oops. An error has occured..",
          severity: "error",
        });
      }
      /**
       * error hanlder save property
       * */
      if (property.propert_save_property_error && isLoading) {
        handleNotification(true, {
          message: "Oops. An error has occured..",
          severity: "error",
        });
      }
    }
  }, [property]);

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const closeNotification = () => {
    setIsNotification(false);
  };

  const handleSortBy = (data, propertiesData) => {
    setSearchSortingFilters(data);
    const propertyData = Object.assign({}, likedPropertiesData.propertiesFilters);
    const propertiesFilters = Object.assign([], propertyData);
    let type = _.filter(data, function (o) {
      return o.active;
    });
    let sortedData = propertiesFilters;
    if (data && type && type.length > 0) {
      switch (type[0].value) {
        case "Relevance":
          sortedData = propertiesFilters;
          break;
        case "Newest":
          sortedData =
            getRoutePath() === "sold"
              ? sortData(propertiesFilters, ["soldDate"], "desc")
              : sortData(propertiesFilters, ["publishDate"], "desc");
          break;
        case "Price (High to Low)":
          sortedData =
            getRoutePath() === "rent"
              ? sortData(
                propertiesFilters,
                ["rentalPrice", "publishDate"],
                ["desc", "desc"]
              )
              : sortData(propertiesFilters, ["price", "publishDate"], ["desc", "desc"]);
          break;
        case "Price (Low to High)":
          sortedData =
            getRoutePath() === "rent"
              ? sortData(
                propertiesFilters,
                ["rentalPrice", "publishDate"],
                ["asc", "asc"]
              )
              : sortData(propertiesFilters, ["price", "publishDate"], ["asc", "asc"]);
          break;
        case "Bedrooms":
          sortedData = sortData(
            propertiesFilters,
            ["bedrooms", "price"],
            ["desc", "desc"]
          );
          break;
        case "Bathrooms":
          sortedData = sortData(
            propertiesFilters,
            ["bathrooms", "price"],
            ["desc", "desc"]
          );
          break;
        case "Sqm":
          sortedData = sortData(
            propertiesFilters,
            ["landSize", "price"],
            ["desc", "desc"]
          );
          break;
        default:
          sortedData = propertiesFilters;
      }
      propertyData.propertiesFilters = sortedData;
      setLikedPropertiesData(propertyData);
      setPagination(propertyData);
    }
  };

  const defaultSortData = (properties) => {
    const propertyData = Object.assign({}, properties.data);
    const propertiesFilters = Object.assign([], propertyData);
    const sortedData = sortData(propertiesFilters, ["subscriptionTypeWeight"], ["asc"]);
    propertyData.propertiesFilters = sortedData;

    const pageData = {
      propertiesFilters: _.union(
        likedPropertiesData.propertiesFilters,
        propertiesFilters
      ),
      totalResults: properties.totalResults,
    };
    setLikedPropertiesData(pageData);
    setPagination(pageData);
  };

  const sortData = (data, type, order) => {
    const sortBy = type;
    return _.orderBy(
      data,
      sortBy.map((s) => {
        return (r) => {
          return r[s] ? r[s] : "";
        };
      }),
      order
    );
  };

  /** Save Property API */
  function apiRequestSaveProperty(id) {
    let params = {
      propertyId: id,
    };
    saveProperty(params);
  }

  /** Get Liked Properties */
  function apiRequestLikedProperties() {
    likedPropertiesClear()
    let skipCount = likedPropertiesData.propertiesFilters
      ? likedPropertiesData.propertiesFilters.length
      : 0;
    if (skipCount !== undefined) {
      let request = { take: 9, skip: skipCount };
      getLikedProperties(request);
    }
  }

  function saveFilters(params) {
    if (filters && filters.filters) {
      let obj = Object.assign({}, filters.filters);
      if (params) {
        obj.propertyDetails.bedrooms = params[0].selected;
        obj.propertyDetails.bathrooms = params[2].selected;
        obj.propertyDetails.price.min = params[1].values[0].value.min;
        obj.propertyDetails.price.max = params[1].values[0].value.max;
        if (params[3].values) {
          obj.propertyType = params[3].values;
          obj.propertyType.map((item, index) => {
            item.active = params[3].values[index].active;
          });
        }
        setSearchFilters(obj);
      }
    }
  }

  const handlePagination = () => {
    apiRequestLikedProperties();
  };

  const handleMatchCount = (property) => {
    let count = 0;
    if (filters && property) {
      filters.filters.advanceProperty.map((item, index) => {
        if (item.active && property.coefficients) {
          if (property.coefficients[item.key] >= 0.7) {
            count++;
          }
        }
      });
    }
    return count;
  };

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <div ref={topContainer} className={classes.topAnchor}></div>

      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routerChange("/perfect-property")}
        >
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
      </div>

      <Searchbar
        type={"search"}
        sortType={"liked-property"}
        showSearchBar={false}
        showFilters={false}

        authentication={authentication}
        settings={settings}
        toggleSignUp={toggleSignUp}
        toggleFilter={toggleFilter}
        setSearchSortingFilters={handleSortBy}
        filters={filters}
        saveFilters={saveFilters}
        properties={likedPropertiesData}
        isFiltered={isFiltered}
        path={getRoutePath()}
      />

      <div className={classes.content}>
        <div style={{ paddingBottom: 20 }}>
          <PropertyFaveLikes

            authentication={authentication}
            settings={settings}
            toggleSignUp={toggleSignUp}
            apiRequestSaveProperty={apiRequestSaveProperty}
            setCurrentProperty={setCurrentProperty}
            properties={pagination}
            isFiltered={isFiltered}
            isLoading={isLoading}
            pagination={pagination}
            handlePagination={handlePagination}
            handleMatchCount={handleMatchCount}
            listingType={getRoutePath()}
            cardType={"grid"}
          />
          {/* <Property
          
          authentication={authentication}
          settings={settings}
          toggleSignUp={toggleSignUp}
          apiRequestSaveProperty={apiRequestSaveProperty}
          setCurrentProperty={setCurrentProperty}
          properties={pagination} 
          isFiltered={isFiltered}
          isLoading={isLoading}
          handlePagination={handlePagination} 
          handleMatchCount={handleMatchCount}
          listingType={getRoutePath()} /> */}
        </div>
        <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
    property_save_status: state.property.property_save_property_status,
    property_save_error: state.property.property_save_property_error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      saveProperty: (params) => requestPropertySave(params),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchProperty: (params) => requestPropertySearch(params),
      getLikedProperties: (params) => requestLikeProperties(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setSearchSortingFilters: (params) => setSortingFilters(params),
      getSavedProperties: () => requestGetPropertySave(),
      likedPropertiesClear: () => clearLikedProperties()
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LikedProperties));
