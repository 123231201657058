import { PURGE } from 'redux-persist'

import {
  SET_PROPERTY_SEARCH_FILTERS,
  CLEAR_FILTERS,
  SET_PROPERTY_SUBURB_SEARCH,
  CLEAR_SUBURB_SEARCH,
  SET_PERFECT_PROPERTY_FILTERS,
  CLEAR_PERFECT_PROPERTY_FILTERS,
  SET_INCLUDE_NEARBY_SUBURB,
  CLEAR_SESSION,
  SET_SORTING_FILTERS,
  CLEAR_SORTING_FILTERS,
} from '../constants'

import { DEFAULT_FILTERS, DEFAULT_MPP_FILTERS } from './default'

const INITIAL_STATE = {
  filters: DEFAULT_FILTERS,
  perfectProperty: DEFAULT_MPP_FILTERS,
  suburbs: [],
  sorting: null,
  includeNearby: true,
}

export default function filtersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    /** Set Sorting */
    case SET_SORTING_FILTERS:
      return {
        ...state,
        sorting: action.params,
      }

    /** Clear Sorting */
    case CLEAR_SORTING_FILTERS:
      return {
        ...state,
        sorting: null,
      }

    /** Clear Session */
    case CLEAR_SESSION:
      return {
        ...INITIAL_STATE,
      }

    /** SET CURRENT FILTERS */
    case SET_PROPERTY_SEARCH_FILTERS:
      return {
        ...state,
        filters: action.params,
      }
    /** CLEAR FILTERS */
    case CLEAR_FILTERS:
      return {
        ...INITIAL_STATE,
      }

    /** SET PERFECT PROPERTY FILTERS */
    case SET_PERFECT_PROPERTY_FILTERS:
      return {
        ...state,
        perfectProperty: action.params,
      }
    /** CLEAR PERFECT PROPERTY FILTERS */
    case CLEAR_PERFECT_PROPERTY_FILTERS:
      return {
        ...state,
        perfectProperty: null,
      }

    /** SET CURRENT SUBURB SEARCH */
    case SET_PROPERTY_SUBURB_SEARCH:
      return {
        ...state,
        suburbs: action.params,
      }
    /** CLEAR SUBURB SEARCH */
    case CLEAR_SUBURB_SEARCH:
      return {
        ...state,
        suburbs: [],
      }

    /** SET NEARBY */
    case SET_INCLUDE_NEARBY_SUBURB:
      return {
        ...state,
        includeNearby: action.params,
      }

    case PURGE:
      return INITIAL_STATE

    default:
      return state
  }
}

export const getAllFilters = (state) => state
