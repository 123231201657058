import React, { useRef, useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Property from '../Property'
import PropertyLoader from '../Property/PropertyLoader'
import Loader from '../../Mobile/Loader'
import AlertNotification from '../Form/AlertNotification'

import { scrollToRef } from '../ScrollEvents'
import DashboardBanner from '../Dashboard/DashboardBanner'
import dashBannerIMG from '../../../assets/images/agentbanner.png'
import Crumbs from '../Header/Crumbs'
import { SuperBreadCrumbs } from 'components/Web/Superadmin'

import _ from 'lodash'

/** api redux request */
import {
  requestPropertySave,
  requestPropertySaveSearch,
  requestPropertySearch,
  requestAgentGetPropertiesV2,
} from 'store/api'

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  setCurrentProperty,
  clearCurrentProperty,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPropertySearchFilters,
  setSortingFilters,
} from 'store/actions'

import { Typography, withStyles } from '@material-ui/core'

import { useDispatch } from 'react-redux'

import { requestPUTAPIMpp } from 'store/api/mpp.api'

const styles = (theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    paddingLeft: 90,
    position: 'relative',
  },
  content: {
    backgroundColor: '#f2f2f2',
    padding: '20px 50px 20px 115px',
    fontSize: 10,
    '& .infinite-scroll-component': {
      overflowX: 'hidden !important',
      overflowY: 'auto !important',
    },
  },
  mainWrap: {
    width: '100%',
    margin: '0 auto',
    marginTop: 50,
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  crumbsWrap: {
    fontSize: 14,
    marginBottom: 60,
  },
})

const CRUMBS = [
  {
    type: 'secondary',
    label: 'Home',
    path: '/agent/dashboard',
  },
  {
    type: 'primary',
    label: 'Property Listings',
    path: '/agent/my-properties',
  },
]

const MyProperties = (props) => {
  const {
    classes,
    authentication,
    settings,
    myproperties_error,
    myproperties_status,
    myproperties,
    filters,
    toggleSignUp,
    getAgentProperties,
    setCurrentProperty,
    users,
  } = props

  const topContainer = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d)

  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }

  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 20,
    propertiesFilters: [],
    totalResults: null,
  })

  const dispatch = useDispatch()
  const saveProperty = (params) =>
    dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'))

  React.useEffect(() => {
    /**
     * Initial Scroll to Top */
    executeTopScroll(false)

    /**
     * Request Agent Properties */
    apiRequestGetAgentProperties()

    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(true)
    }
  }, [])

  React.useEffect(() => {
    if (
      authentication &&
      authentication.user_status === 'logged' &&
      users &&
      users.user
    ) {
      setIsLoading(false)
    }
  }, [authentication, users])

  React.useEffect(() => {
    if (myproperties && myproperties_error && isLoading) {
      handleNotification(true, {
        message: 'Oops. An error has occured..',
        severity: 'error',
      })
      setIsLoading(false)
    }

    if (myproperties && myproperties.data) {
      setPagination({
        ...pagination,
        propertiesFilters: myproperties.data.entities,
      })
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [myproperties])

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    }

    saveProperty(params)
  }

  /** Get Agent Properties */
  const apiRequestGetAgentProperties = async () => {
    setIsLoading(true)
    if (users?.agent?._id) {
      getAgentProperties({
        agentReference: users?.agent?._id,
      })
    }
  }

  const handlePagination = () => {
    const nextPage = pagination.pageNumber + 1
    const filterData = pagination.propertiesFilters.concat(
      paginate(pagination.propertiesFilters, pagination.pageSize, nextPage)
    )
    const pageData = {
      pageNumber: nextPage,
      pageSize: pagination.pageSize,
      propertiesFilters: filterData,
      totalResults: myproperties.data.entities.length,
    }
    setPagination(pageData)
  }

  const paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size)
  }

  const handleMatchCount = (property) => {
    let count = 0
    if (filters && property) {
      filters.filters.advanceProperty.map((item) => {
        if (item.active && property.coefficients) {
          if (property.coefficients[item.key] >= 0.7) {
            count++
          }
        }
      })
    }
    return count
  }

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />

      <Loader isLoading={isLoading} />

      <div ref={topContainer} className={classes.topAnchor}></div>
      <DashboardBanner
        title={'Property Listings'}
        content={
          <Typography variant="body1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo quod quae
            esse! Eligendi, nobis alias.
          </Typography>
        }
        image={dashBannerIMG}
      />
      <div className={classes.content}>
        <SuperBreadCrumbs
          section={'Property Listings'}
          crumbsLinks={CRUMBS}
        />

        <div className={classes.mainWrap}>
          <Property
            authentication={authentication}
            settings={settings}
            toggleSignUp={toggleSignUp}
            apiRequestSaveProperty={apiRequestSaveProperty}
            setCurrentProperty={setCurrentProperty}
            properties={pagination}
            isLoading={isLoading}
            handlePagination={handlePagination}
            handleMatchCount={handleMatchCount}
            listingType={getRoutePath()}
            cardType={'agent-properties'}
          />
          {!isLoading && (
            <PropertyLoader executeTopScroll={executeTopScroll} isBackTop={true} />
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    myproperties: state.agents.myproperties,
    myproperties_status: state.agents.myrproperties_status,
    myproperties_error: state.agents.myrproperties_error,
    edited_property: state.agents.request_edit_property,
    edited_property_status: state.agents.request_edit_property_status,
    edited_property_error: state.agents.request_edit_property_error,
    filters: state.filters,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
      searchProperty: (params) => requestPropertySearch(params),
      getAgentProperties: (params) => requestAgentGetPropertiesV2(params),
      setCurrentProperty: (params) => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setSearchSortingFilters: (params) => setSortingFilters(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyProperties))
