import { FETCH, POST } from 'utils/api'
import { searchActions } from 'store/actions/search.actions'
import { apiEndpoints } from 'utils/api/endpoints'

const urlSetter = (params, actionMain) => {
    let URL = apiEndpoints[actionMain]
    if (params.query && params.query !== '') {
        URL = apiEndpoints[actionMain] + params.query
    }
    return URL
}

export function requestGETAPISearch(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(searchActions(params, actionMain, 'REQUEST'))
        FETCH(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(searchActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(searchActions(error.response, actionMain, 'FAILED'))
            })
    }
}
export function requestPOSTAPISearch(params, actionMain) {
    let URL = urlSetter(params, actionMain)
    return (dispatch) => {
        dispatch(searchActions(params, actionMain, 'REQUEST'))
        POST(URL, params.data)
            .then((response) => {
                if (response.error) {
                    throw response.error
                }
                dispatch(searchActions(response, actionMain, 'SUCCESS'))
                return response
            })
            .catch((error) => {
                dispatch(searchActions(error.response, actionMain, 'FAILED'))
            })
    }
}