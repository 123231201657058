import React from "react";
import { createStyles } from "@material-ui/core/styles";
import styled from 'styled-components';
import { withStyles, fade, Button } from '@material-ui/core';


const styles = theme => ({
    statementUpload: {
        display: 'none'
    },
});

const StyledButton = withStyles(theme => ({
    root: {
      borderRadius:30,
      textTransform:'initial',
      fontWeight:'400',
      letterSpacing:'0.073125px',
      padding: theme.spacing(1, 2),
      minWidth:'118px',
     '& .MuiButton-label ' : {
      maxWidth: 300,
      whiteWspace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
     }
    }
  }))(Button);

const ButtonWrap = styled.div`
    padding:20px 0 30px;
    display:flex;
    justify-content:center;
    align-items:center;
    `

const ButtonStyleFileUpload = props => {
  const { onChange, classes, label, disabled} = props
  
  const handleChange = (event, newValue) => {
    if(onChange) {
      onChange(event, newValue)
    }
  };


  return (
    <ButtonWrap>
        <input
            accept=".doc,.docx,.pdf"
            className={classes.statementUpload}
            id="upload-statement-file"
            type="file"
            onChange={(e)=> handleChange(e, e.target.value) }
        />
        <label htmlFor="upload-statement-file">
            <StyledButton 
                color="primary"
                variant="contained"
                disabled={disabled}
                component="span"                        
            >
                { label }
            </StyledButton>
        </label>
    </ButtonWrap>
  )
};

export default withStyles(styles)(ButtonStyleFileUpload)