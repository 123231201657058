import React, { useState, useRef } from "react";
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import Geocode from "react-geocode";

import StreetViewComponent from '../../components/Mobile/Map/StreetViewComponent';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import DynamicIcons from '../../components/Mobile/DynamicIcons'
import FloatingNavigationButton from '../../components/Mobile/FloatingNavigationButton';

import { scrollToRef } from '../../components/Mobile/ScrollEvents';

/** Property Utils */
import {
  propertyConfigSetter
} from '../../utils/property.utils';

/** api redux request */
import {
  requestPropertyDetails,
} from "../../store/api";


import { withStyles, Button } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";


const styles = theme => ({
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)'
  }
})

const BackButton = withStyles({
  root: {
    zIndex: 20,
  },
  label: {
    textTransform: 'capitalize',
  }

})(Button);

const PropertyStreetView = props => {
  const { classes, property, authentication, settings, location, getPropertyDetails } = props;
  const [routerChange] = useRouterChange()
  const [currentProperty, setCurrentProperty] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const topContainer = useRef(null)
  const executeTopScroll = (d) => scrollToRef(topContainer, d) //add `false` as second parameter to scroll without transition.
  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : ''
  }


  React.useEffect(() => {
    /** Check current selected */
    /* if(property.current_selected_property){
      setCurrentProperty(property.current_selected_property)
    } */

    /** 
     * load request property details combined with data form list */
    if (property.properties_details && property.properties_details.data && property.properties_details.data.data && property.properties_details.data.data.property && isLoading) {
      setIsLoading(false);
      setCurrentProperty({ ...property.current_selected_property, ...property.properties_details.data.data.property })
    }
  }, [property])

  React.useEffect(() => {
    executeTopScroll(false)

    /** 
     * request property details */
    apiRequestPropertyDetails(props.match.params.id);
  }, [])

  /** 
   * Get Property Details API */
  function apiRequestPropertyDetails(id) {
    setIsLoading(true);
    let params = {
      "property": id
    }
    getPropertyDetails(params);
  }

  const [mapData, setMapData] = useState({
    map: {
      coordinates: [-37.796300, 144.925120],
      markers: [{ lat: -37.796300, lng: 144.925120 }],
      nearby: [{ lat: -37.793541, lng: 144.931722 }],
      zoom: 15
    }
  })

  const [propertyID, setPropertyID] = React.useState(null);

  React.useEffect(() => {
    if (currentProperty) {
      /* setMapData((prevData)=>({map: {
          ...prevData.map,
          coordinates: currentProperty.location.coordinates,
          markers: [{lat: currentProperty.latitude, lng: currentProperty.longitude}], 
          
      }})) */

      setPropertyID(currentProperty.property ? currentProperty.property : currentProperty._id)
    }

  }, [currentProperty]);

  return (
    <div>
      <div ref={topContainer} className={classes.topAnchor}></div>
      <BackButton
        variant="text"
        disableRipple={true}
        fullWidth={false}
        onClick={() => routerChange('/' + getRoutePath() + '/' + propertyID)}>
        <DynamicIcons icon="ChevronLeft" /> GO BACK
      </BackButton>

      {
        currentProperty &&
        <StreetViewComponent
          location={location}
          property={currentProperty}

          listingType={getRoutePath()}
          data={{
            title: 'Map View',
            ...mapData,
            address: currentProperty.addressText
          }} />
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPropertyDetails: params => requestPropertyDetails(params),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PropertyStreetView));
