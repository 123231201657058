import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import SocialLogin from 'react-social-login';
import DynamicIcons from "../DynamicIcons";
import { withStyles, Button, Typography } from '@material-ui/core';

const buttonStyle = {
  size:'large',
  borderColor:'#000',
  textColor:'#000',
  colorTheme:'primary'
}

const StyledButton = withStyles({
  root: {
    borderColor:buttonStyle.borderColor, 
    display:'flex', 
    padding:'11px 12px', 
    width:'100%',
    borderRadius:30,
    position:'relative',
    textTransform:'initial',
    fontWeight:400

  },
  startIcon: {
    position:'absolute',
    left:30
  }
})(Button);

const ButtonWrap = styled.div`
  padding:8px 0;
`

class SocialButton extends Component {
    static propTypes = {
      triggerLogin: PropTypes.func.isRequired,
    }
    
    render () {
      const { children, triggerLogin, label, icon, colorTheme, textColor, ...props } = this.props
  
      return (
        <ButtonWrap>
          <StyledButton 
            variant={'outlined'} 
            size={buttonStyle.size} 
            startIcon={icon} 
            onClick={triggerLogin} 
            {...props}>
            <Typography color={buttonStyle.colorTheme} style={{color:buttonStyle.textColor, fontSize:'12px'}}>{label}</Typography>
          </StyledButton>
        </ButtonWrap>
      )
    }
}

export default SocialLogin(SocialButton);