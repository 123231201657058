import {
  Box,
  Button,
  Grid,
  makeStyles,
  withStyles,
  Typography,
} from '@material-ui/core'
import styled from 'styled-components'
import React, { useState } from 'react'
import Image from 'material-ui-image'

import HomePageImage from '../../../assets/images/agent-3.jpg'
import { useDispatch } from 'react-redux'
import { toggleFilterWeb } from 'store/actions'
import HomePageSearchHeading from './HomePageSearchHeading'
import { HOME_DEFAULT_BUTTONS_MOBILE } from 'constants/constants'
import HomePageSearchBar from './HomePageSearchBar'
import HomeFilters from './HomeFilters'
import HOMEPAGE_IMAGE from '../../../assets/images/homepage-section-1.png'
const styles = (theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    marginTop: '10px',
    overflow: 'hidden',
    backgroundColor: '#6DC5A1',
  },
})

const HomePageSearchSection = (props) => {
  const { classes, location, goBack } = props

  const [homeButtons, setHomeButtons] = useState(HOME_DEFAULT_BUTTONS_MOBILE)

  const dispatch = useDispatch()

  const handleHomeButtons = (value) => {
    setHomeButtons(value)
  }

  const handleClick = (e, buttonObject, index) => {
    if (e) {
      e.preventDefault()
    }
    if (buttonObject) {
      dispatch(
        toggleFilterWeb({ type: buttonObject.path.replace('/', ''), status: true })
      )

      handleHomeButtons(
        homeButtons.map((item) =>
          item.name === buttonObject.name
            ? { ...item, active: true }
            : { ...item, active: false }
        )
      )
      //   setPage(buttonObject.path)
      if (index === 3) {
        localStorage.setItem('hideState', true)
      } else {
        localStorage.removeItem('hideState')
      }
      //   handlePage(buttonObject.path)
    } else {
      handleHomeButtons(HOME_DEFAULT_BUTTONS_MOBILE)
    }
  }

  return (
    <div className={classes.root}>
      <HomePageSearchHeading />
      {/*       <div>
        <Image
          src={HOMEPAGE_IMAGE}
          aspectRatio={16 / 11}
          imageStyle={{ objectFit: 'cover' }}
        ></Image>
      </div> */}
    </div>
  )
}

export default withStyles(styles)(HomePageSearchSection)
