import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import PropertyCard from './PropertyCard'
import NoResultsCard from './NoResultsCard'
import { PROPERTY_CARDS } from './constants'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled, { css } from 'styled-components'
import _ from 'lodash'

/** Redux **/
import { toggleFilterWeb } from '../../../store/actions'
import { useDispatch } from 'react-redux'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'
import MyPropertiesCard from '../Agent/MyPropertiesCard'

import { withStyles, CircularProgress, Grid } from '@material-ui/core'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
})

const NoResult = styled.div`
  padding: 20px 20px 20px 0;
  margin-left: -2px;
  p {
    color: #4d4d4d;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.25px;
  }
`

const Property = (props) => {
  const {
    authentication,
    settings,
    toggleSignUp,
    setCurrentProperty,
    properties,
    apiRequestSaveProperty,
    apiRequestSearchProperty,
    isLoading,
    isFiltered,
    listingType,
    skipTakeData,
    origin,
    setActivePreviewProperty,
    activePreviewProperty,
    history,
  } = props

  const [items, setItems] = useState([]) // PROPERTY_CARDS
  const [hasMore, setHasMore] = useState(true)
  const [requestIndex, setRequestIndex] = useState(1)

  const [agentPropertiesList, setAgentPropertiesList] = useState([])

  const dispatch = useDispatch()

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const handleToggleFilterWeb = (params) => dispatch(toggleFilterWeb(params))

  let sto = null

  useEffect(() => {
    return () => {
      clearTimeout(sto)
      setItems([])
    }
  }, [])

  useEffect(() => {
    if (properties) {
      setAgentPropertiesList(properties.propertiesFilters)
    }
  }, [properties])

  useEffect(() => {
    /**
     * Property Data
     * */
    setItems(_.uniqBy(properties.propertiesFilters, 'property'))

    if (properties.propertiesFilters) {
      var totalresults =
        skipTakeData && skipTakeData.totalResults ? skipTakeData.totalResults : 0
      if (skipTakeData && totalresults > properties.propertiesFilters.length) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }
    }
  }, [properties])

  const fetchMoreData = () => {
    clearTimeout(sto)

    if (skipTakeData.totalResults === items.length) {
      setHasMore(false)
      return
    }

    const maxRequest = Math.ceil(skipTakeData.totalResults / skipTakeData.take)
    if (items.length < skipTakeData.totalResults && requestIndex < maxRequest) {
      apiRequestSearchProperty(skipTakeData, requestIndex)
      setRequestIndex(requestIndex + 1)
    }

    sto = setTimeout(() => {
      props.handlePagination()
    }, 200)

    triggerAnalyticsEvent({
      event: 'results_actions',
      eventFrom: 'Web',
      actionScope: 'results_listing_loadmore',
      actionName: 'Results Listing Load More',
      actionTarget: 'Load more triggered ' + requestIndex + 'x',
      actionReference: 'results',
    })
  }

  const refrestData = () => {
    setItems(_.uniqBy(properties.propertiesFilters, 'property'))
  }

  const getSuburb = () => {
    let suburbs = ''
    if (isFiltered && isFiltered.suburbs) {
      isFiltered.suburbs.map((item, index) => {
        suburbs += item.locality + ', '
      })
    }

    return suburbs.replace(/,\s*$/, '')
  }

  const noResultsMessage = (listing) => {
    switch (listing) {
      case 'favourites':
        return (
          <p>
            There are <strong>0</strong> properties on your favourite list. Try adding
            some to see them listed here.
          </p>
        )

      case 'disliked-properties':
        return (
          <p>
            There are <strong>0</strong> properties on your dislikes list. Try adding
            some to see them listed here.
          </p>
        )

      case 'liked-properties':
        return (
          <p>
            You haven't added any homes yet. Start searching for properties to add now.
          </p>
        )

      case 'disliked-properties':
        return (
          <p>
            You haven't added any homes yet. Start searching for properties to add now.
          </p>
        )

      case 'rent':
        return (
          <p>
            There are <strong>0</strong> properties for rent in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )

      case 'sold':
        return (
          <p>
            There are <strong>0</strong> properties sold in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )

      case 'offmarket':
        return (
          <p>
            There are <strong>0</strong> offmarket properties in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )

      case 'agent':
        return <p>0 properties found.</p>

      default:
        return (
          <p>
            There are <strong>0</strong> properties for sale in{' '}
            <strong>{getSuburb()}</strong> that match your criteria. Try changing some
            of your criteria above to get more results.
          </p>
        )
    }
  }

  const gridView = (items) => {
    return (
      <Grid container spacing={3} alignItems="stretch">
        {items.map((value, index) => {
          return (
            <PropertyCard
              key={index}
              cardType={props.cardType ? props.cardType : 'list'}
              properties={value}
              {...propertyConfigSetter(
                'card-type',
                value ? (value.subscriptionType ? value.subscriptionType : '') : ''
              )}
              authentication={authentication}
              settings={settings}
              toggleSignUp={toggleSignUp}
              setCurrentProperty={setCurrentProperty}
              apiRequestSaveProperty={apiRequestSaveProperty}
              handleMatchCount={props?.handleMatchCount}
              listingType={listingType}
              filters={props.filters}
              setActivePreviewProperty={setActivePreviewProperty}
              activePreviewProperty={activePreviewProperty}
            />
          )
        })}
      </Grid>
    )
  }

  const agentProperties = (items) => {
    return (
      <Grid container spacing={3} justifyContent={'flex-start'} alignItems="stretch">
        {items.map((value, index) => {
          return (
            <MyPropertiesCard
              key={index}
              cardType={'grid'}
              isAgentDashboardView={props.cardType === 'agent-properties'}
              properties={value}
              {...propertyConfigSetter(
                'card-type',
                value ? (value.subscriptionType ? value.subscriptionType : '') : ''
              )}
              authentication={authentication}
              settings={settings}
              toggleSignUp={toggleSignUp}
              setCurrentProperty={setCurrentProperty}
              apiRequestSaveProperty={apiRequestSaveProperty}
              handleMatchCount={props.handleMatchCount}
              listingType={listingType}
              enableManage={true}
            />
          )
        })}
      </Grid>
    )
  }

  const defaultListing = (items) => {
    return (
      <>
        {items.map((value, index) => {
          return (
            <PropertyCard
              key={index}
              cardType={props.cardType ? props.cardType : 'list'}
              properties={value}
              {...propertyConfigSetter(
                'card-type',
                value ? (value.subscriptionType ? value.subscriptionType : '') : ''
              )}
              authentication={authentication}
              settings={settings}
              toggleSignUp={toggleSignUp}
              setCurrentProperty={setCurrentProperty}
              apiRequestSaveProperty={apiRequestSaveProperty}
              handleMatchCount={props.handleMatchCount}
              listingType={listingType}
              filters={props.filters}
              setActivePreviewProperty={setActivePreviewProperty}
            />
          )
        })}
      </>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      {items && items.length > 0 ? (
        <InfiniteScroll
          dataLength={items.length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div
              style={{ padding: '5px 23px 20px', textAlign: 'center', marginTop: 20 }}
            >
              {skipTakeData && skipTakeData.totalResults !== items.length ? (
                <CircularProgress size={20} />
              ) : (
                <p style={{ textAlign: 'center', fontSize: 14, padding: '20px 0 0' }}>
                  Change your search criteria to view more properties
                </p>
              )}
            </div>
          }
          endMessage={
            <p style={{ textAlign: 'center', fontSize: 14, padding: '20px 0 0' }}>
              {/* Change your search criteria to view more properties */}
            </p>
          }
          scrollableTarget={
            origin && origin === 'map-view' ? 'cards-scroll-column' : window.body
          }
          refreshFunction={refrestData}
          pullDownToRefresh={false}
          pullDownToRefreshThreshold={100}
          pullDownToRefreshContent={
            <p style={{ textAlign: 'center', padding: '10px ' }}>
              &#8595; Pull down to refresh
            </p>
          }
          releaseToRefreshContent={
            <p style={{ textAlign: 'center', padding: '10px ' }}>
              &#8593; Release to refresh
            </p>
          }
        >
          {props.cardType === 'grid'
            ? gridView(items)
            : props.cardType === 'agent-properties'
            ? agentProperties(agentPropertiesList)
            : defaultListing(items)}
        </InfiniteScroll>
      ) : !isLoading ? (
        <NoResult>
          {listingType.includes('liked-properties') ||
          listingType.includes('favourites') ||
          listingType.includes('agent') ? (
            noResultsMessage(listingType)
          ) : (
            <NoResultsCard
              toggleFilterWeb={handleToggleFilterWeb}
              pathName={history?.location?.pathname}
            />
          )}
        </NoResult>
      ) : null}
    </div>
  )
}

export default React.memo(withStyles(styles)(Property))
