import { PURGE } from 'redux-persist';
import {
    SET_STAT_VIEW,
    SET_STAT_VIEW_SUCCESS,
    SET_STAT_VIEW_FAIL,
    CLEAR_STATISTICS_DATA,
    SET_STATISTICS_POOL,
    CLEAR_STATISTICS_POOL,
    GET_STATISTICS_DATA,
    GET_STATISTICS_DATA_SUCCESS,
    GET_STATISTICS_DATA_FAIL,
} from '../constants';



const INITIAL_STATE = {
    request: null,
    set_statistic_data: null,
    set_statistic_data_status: null,
    set_statistic_data_error: null,
    stat: null,
    stat_pool: null,
    api_statistics_data: null,
    api_statistics_data_status: null,
    api_statistics_data_error: null
};

const statisticsReducers = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_STAT_VIEW:
            return {
                ...state,
                request: action.params,
                set_statistic_data_status: 'progress',
                set_statistic_data_error: null,
                stat: action.stat
            };
        case SET_STAT_VIEW_SUCCESS:
            return {
                ...state,
                request: action.params,
                set_statistic_data: action.params.data,
                set_statistic_data_status: 'success',
                set_statistic_data_error: null,
                stat: action.stat
            }
        case SET_STAT_VIEW_FAIL:
            return {
                ...state,
                request: action.params,
                set_statistic_data_status: 'failed',
                set_statistic_data_error: action.message,
                stat: action.stat

            }
        case SET_STATISTICS_POOL:
            return {
                ...state,
                stat_pool: action.params
            }
        case GET_STATISTICS_DATA:
            return {
                ...state,
                api_statistics_data: action.params,
                api_statistics_data_status: 'progress',
                api_statistics_data_error: null
            }
        case GET_STATISTICS_DATA_SUCCESS:
            return {
                ...state,
                api_statistics_data: action.params,
                api_statistics_data_status: 'success',
                api_statistics_data_error: null
            }
        case GET_STATISTICS_DATA_FAIL:
            return {
                ...state,
                api_statistics_data: action.params,
                api_statistics_data_status: 'error',
                api_statistics_data_error: action.params
            }

        case CLEAR_STATISTICS_POOL:
            return INITIAL_STATE

        case PURGE:
            return INITIAL_STATE
        default: return state;
    }
}

export default statisticsReducers