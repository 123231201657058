import React, { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import { Typography, Button } from '@material-ui/core'

import AgencySignUpForm from 'components/Web/Agency/AgencySignUpForm'
import Image from 'components/Web/Image'

import TextImage01 from 'assets/images/content/signup-step1.svg'
import TextImage02 from 'assets/images/content/signup-step2.svg'

import { clearFilters } from 'store/actions'
import theme from 'theme/light'
import { Card, Container, Fade, Grid, CardContent, withStyles } from '@material-ui/core'
import AgencyPaymentSection from 'components/Common/Billing/AgencyPaymentSection'
import { getSuccess, getStatus, getErrors } from 'store/selectors/billing.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { requestGETAPIBilling, requestPOSTAPIBilling } from 'store/api'
import { billingActions } from 'store/actions'
import AlertNotification from 'components/Mobile/Form/AlertNotification'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    backgroundColor: '#F5F5F5',
    padding: theme.spacing(2),
    margin: '0 auto',
    overflowX: 'hidden',
    // maxWidth: '75vw'
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  sectionHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 52,
    lineHeight: '45.5px',
    color: theme.palette.gray.main,
    letterSpacing: '-0.78px',
    marginBottom: '32px',
    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
      fontSize: 56,
    },
    '@media (max-width: 960px) ': {
      textAlign: 'center',
    },
  },
  sectionSubHeading: {
    fontFamily: 'Montserrat, sans',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.52px',
  },
  sectionLeadText: {
    color: theme.palette.gray.main,
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '1.6em',
    '@media (max-width: 960px) ': {
      marginBottom: 64,
      textAlign: 'center',
    },
  },
  textContents: {
    // flexDirection: "column-reverse",
    // alignItems: "flex-end",
    display: 'flex',
    paddingRight: '20px !important',
    paddingLeft: '15px',
    '@media (max-width: 1280px) ': {
      paddingLeft: 0,
    },
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textImageWrap: {
    /* paddingLeft: 75,
          "@media (max-width: 960px) " : {
            paddingLeft: 0,
          },
          '@media (min-width: 1440px)': {
            paddingLeft: 100,
          }, */
  },
  imageWrap: {
    maxWidth: 570,
    marginBottom: 18,
    '@media (max-width: 960px) ': {
      margin: '0 auto',
      marginBottom: 60,
      display: 'none',
    },
  },

  formWrapper: {
    '@media (max-width: 960px) ': {
      margin: '0 auto',
      '& > h2': {
        textAlign: 'center',
      },
    },
  },
  cardContainer: {
    borderRadius: 38,
    padding: theme.spacing(1, 0.5),
    marginBottom: theme.spacing(2),
  },
  btn: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: theme.spacing(0.45, 2),
    // minWidth: '224px',
    display: 'inline',
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
  },
  btnsWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(3),
  },
})

const PageHeading = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px 0',
    zIndex: 10,
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})(Typography)

const AgencyStandalonePayment = (props) => {
  const { classes, openSnackbar, history } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()

  const [formStep, setFormStep] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)
  const [agency, setAgency] = useState(null)
  const [showDocusignMessage, setShowDocusignMessage] = useState(false)

  const AGENCY = useSelector(getSuccess)
  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)

  const URL_REFERENCE = new URLSearchParams(props?.location?.search)

  let timercountdown = null

  const [countdown, setCountdown] = useState(10)
  const [countdownState, setCountdownState] = useState(false)

  useEffect(() => {
    const getEmail = props?.location?.search?.match(/email=([^&]*)/)?.[1]

    if (props?.match?.params?.token || getEmail) {
      apiGetAgencyDetailsRequest(props?.match?.params?.token)
    }

    if (getEmail) {
      setShowDocusignMessage(true)
    }

    const params = URL_REFERENCE
    if (params.get('redirect_status')) {
      switch (params.get('redirect_status')) {
        case 'succeeded':
          setMessage('Subscription successful!')
          setPaymentStatus('success')
          break
        case 'processing':
          setMessage('Your subscription is processing.')
          setPaymentStatus('processing')
          break
        case 'requires_payment_method':
          setMessage('Your subscription was not successful, please try again.')
          setPaymentStatus('unsuccessful')
          break
        default:
          setMessage('Something went wrong.')
          setPaymentStatus('error')
          break
      }
    }

    /** show alert to relogin */
    if (localStorage.getItem('showSnackBar') !== null) {
      openSnackbar('Session Expired.')
      localStorage.removeItem('showSnackBar')
    }
    document.body.style.backgroundColor = '#f5f5f5 !important'
    document.getElementById('scroll-container').style.overflowY = 'auto'
    /** CLEAR FILTERS */
    //clearAllFilters();

    if (props.history?.location?.search) {
      const params = URL_REFERENCE

      if (params.get('step')) {
        const currentStep =
          Number(params.get('step')) >= 1 && Number(params.get('step')) <= 3
            ? Number(params.get('step'))
            : 1
        setFormStep(currentStep)
      }
    }

    if (
      URL_REFERENCE?.get('redirect_status') === 'succeeded' &&
      URL_REFERENCE?.get('redirecttoactivate')
    ) {
      setCountdownState(true)
    }

    return () => {
      document.body.style.backgroundColor = 'initial !important'
      document.getElementById('root').style.overflowY = 'initial'

      handleGetAgencyDetailsClearStatus()
      handleGetAgencyDetailsResetState()
    }
  }, [])

  useEffect(() => {
    if (agency?.agencyStateList.includes('ACTIVATED')) {
      setCountdownState(true)
    }
    if (agency?.agencyStateList.includes('SUBSCRIPTION_PAYMENT_SET')) {
      setCountdownState(true)
    }
  }, [agency])

  useEffect(() => {
    if (
      countdown === 0 &&
      agency?._id &&
      !agency?.agencyStateList.includes('ACTIVATED')
    ) {
      //routerChange('/agency/activate/' + agency?._id)
    } else if (countdown === 0 && agency?.agencyStateList?.includes('ACTIVATED')) {
      setTimeout(() => {
        routerChange('/')
      }, 6000)
    }
  }, [countdown, agency])

  // useEffect(() => {
  //   if (countdownState === true && countdown > -1) {
  //     timercountdown = setInterval(() => setCountdown(countdown - 1), 1000)
  //   }
  //   /** clear timer on unmount  */
  //   return () => clearInterval(timercountdown)
  // })

  const apiGetAgencyDetailsRequest = (token) => {
    const getEmail = props?.location?.search?.match(/email=([^&]*)/)?.[1]
    setIsLoading(true)
    let request = {
      query: null,
      data: null,
    }
    if (token) {
      request.query = token
    } else if (getEmail) {
      request.query = getEmail + '/email'
    }

    handleGetAgencyDetailsClearStatus()
    dispatch(
      requestGETAPIBilling(request, 'STANDALONE_PAYMENT_GET_AGENCY_DETAILS', 'REQUEST')
    )
  }

  const handleGetAgencyDetailsClearStatus = () => {
    dispatch(billingActions(null, 'GENERAL_BILLING', 'CLEAR_STATUS'))
  }

  const handleGetAgencyDetailsResetState = () => {
    dispatch(billingActions(null, 'GENERAL_BILLING', 'RESET_STATES'))
  }

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }

  const apiRequestGetSetupClientSecret = (params) => {
    setIsLoading(true)
    const request = {
      query: null,
      data: params,
    }
    handleGetClientSecretClear()
    dispatch(requestPOSTAPIBilling(request, 'STRIPE_POST_CLIENTSECRET', 'REQUEST'))
  }

  const handleGetClientSecretClear = () => {
    dispatch(billingActions(null, 'STRIPE_POST_CLIENTSECRET', 'CLEAR'))
  }

  const apiRequestGetPaymentMethodSecret = () => {
    setIsLoading(true)
    const request = {
      query: null,
      data: {
        sourceActorReference: agency?.subscription?.sourceActorReference,
      },
    }
    handleGetSetupPaymentMethodIntentClear()
    dispatch(
      requestPOSTAPIBilling(request, 'STRIPE_POST_PAYMENTMETHODINTENT', 'REQUEST')
    )
  }

  const handleGetSetupPaymentMethodIntentClear = () => {
    dispatch(billingActions(null, 'STRIPE_POST_PAYMENTMETHODINTENT', 'CLEAR'))
  }

  useEffect(() => {
    if (
      STATUS === 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_SUCCESS' ||
      STATUS === 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_FAILED'
    ) {
      setIsLoading(false)
    }

    if (STATUS === 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_SUCCESS') {
      setAgency(AGENCY?.data?.data?.entity)
      if (
        AGENCY?.data?.data?.entity?.agencyStateList.includes(
          'SUBSCRIPTION_PAYMENT_SET'
        ) &&
        URL_REFERENCE?.get('redirecttoactivate') &&
        !URL_REFERENCE?.get('redirect_status')
      ) {
        setCountdown(0)
      }
    }
    if (STATUS === 'billing/STANDALONE_PAYMENT_GET_AGENCY_DETAILS_FAILED') {
      setIsNotification({
        status: true,
        options: {
          severity: 'error',
          message:
            'An error encountered while validating this link. Please retry after few seconds.',
        },
      })
    }
  }, [STATUS, ERROR])

  return (
    <div className={classes.root}>
      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
      <Container>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <div className={classes.textImageWrap}>
              <div className={classes.imageWrap}>
                <Fade in={formStep === 1}>
                  <div>
                    {formStep === 1 && (
                      <Image
                        src={TextImage01}
                        style={{ width: 480 }}
                        aspectRatio={4 / 2.6}
                        color={'transparent'}
                        imageStyle={{ objectFit: 'contain' }}
                      />
                    )}
                  </div>
                </Fade>
                <Fade in={formStep >= 2}>
                  <div style={{ marginTop: 60 }}>
                    {formStep >= 2 && (
                      <Image
                        src={TextImage02}
                        style={{ width: 432 }}
                        aspectRatio={4 / 2.6}
                        color={'transparent'}
                        imageStyle={{ objectFit: 'contain' }}
                      />
                    )}
                  </div>
                </Fade>
              </div>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={1}></Grid>
                <Grid item xs={12} lg={4}>
                  <Typography component={'h1'} className={classes.sectionHeading}>
                    <strong>proper</strong>
                    perks
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={7} className={classes.textContents}>
                  <Typography component={'h6'} className={classes.sectionLeadText}>
                    Wise choice. As a PropertyMate member, you&rsquo;ll get to enjoy a
                    suite of tasty benefits. Ready to experience something different?
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
          {!isLoading && (
            <Grid item xs={12} className={classes.formWrapper}>
              {agency &&
              !agency?.agencyStateList?.includes('SUBSCRIPTION_PAYMENT_SET') &&
              !agency?.subscription.subscriptionStateList?.includes('DISCOUNT_APPLIED') ? (
                <AgencyPaymentSection
                  paymentStatus={paymentStatus}
                  message={message}
                  customerData={{ ...agency, agencyReference: agency?._id }}
                  redirectUrl={props.history?.location?.pathname + props.history?.location?.search}
                  formStep={formStep}
                  history={history}
                />
              ) : null}

              {agency && agency?.subscription.subscriptionStateList?.includes('DISCOUNT_APPLIED') ? (
                <Card className={classes.cardRows} elevation={1}>
                  <CardContent>
                    <Typography variant={'body2'} component={'p'} align={'center'} gutterBottom>
                      [PROMO CODE] Thank you for signing the DocuSign.
                    </Typography>
                  </CardContent>
                </Card>
              ) : null}

              {countdownState && !agency?.agencyStateList?.includes('ACTIVATED') ? (
                <Card className={classes.cardRows} elevation={1}>
                  <CardContent>
                    {showDocusignMessage && (
                      <Typography variant={'body2'} component={'p'} align={'center'} gutterBottom>
                        Thank you for signing the DocuSign.
                      </Typography>
                    )}
                  </CardContent>
                </Card>
              ) : null}

              {countdownState && agency?.agencyStateList?.includes('ACTIVATED') && showDocusignMessage ? (
                <Card className={classes.cardRows} elevation={1}>
                  <CardContent>
                    <Typography variant={'body2'} component={'p'} align={'center'} gutterBottom>
                      Thank you for signing the DocuSign.
                    </Typography>
                  </CardContent>
                </Card>
              ) : null}

              {!isLoading && !agency && (
                <Card className={classes.cardRows} elevation={1}>
                  <CardContent>
                    <Typography
                      variant={'body2'}
                      component={'p'}
                      align={'center'}
                      gutterBottom
                    >
                      An error occurred while getting your user data or the link
                      expired.
                    </Typography>
                  </CardContent>
                </Card>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default withSnackbar(withStyles(styles)(AgencyStandalonePayment))
