import React from "react";
import styled from 'styled-components'
import { withStyles, FormControlLabel, Checkbox } from '@material-ui/core';

const Container = styled.div`
  padding:0 0;
`

const StyledFormControlLabel = withStyles({
  root: {
    '&.is-small .MuiFormControlLabel-label': {
      fontSize: '12px'
    }
  },
  label: {
    // marginLeft:'11px',
    color: '#4d4d4d',
    whiteSpace: 'nowrap'
  }
})(FormControlLabel);

const StyledCheckbox = withStyles({
  root: {}
})(Checkbox);

const CustomCheckbox = props => {

  const [state, setState] = React.useState({
    checked: props.checked || false,
  });

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  //   props.onChange()
  // };

  return (
    <Container>
      <StyledFormControlLabel
        className={props.size === 'small' ? 'is-small' : ''}
        control={
          <StyledCheckbox
            checked={props.checked}
            onChange={props.onChange}
            name="checked"
            color="primary"
          />
        }
        label={props.label}
      />
    </Container>
  );
};

export default CustomCheckbox;