import React from 'react'
import { withStyles } from '@material-ui/core'
import styled from 'styled-components'
import AddProperty from 'components/Web/Agency/AgencyAddProperty'
import { useDispatch } from 'react-redux'
import { clearAgentState } from 'store/actions'

const styles = (theme) => ({
  root: {
    backgroundColor: '#fff',
  },
})

const Container = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1440px) {
    padding: 0 142px 0 155px;
  }
`

const Content = styled.div`
  max-width: 1140px;
  width: 100%;
  background-color: #fff;
  margin-top: 2rem;
  padding: 0 0 140px;
`

const AgencyAddProperty = ({ classes, history }) => {
  const dispatch = useDispatch()

  window.onbeforeunload = (e) => {
    return dispatch(clearAgentState())
  }

  React.useEffect(() => {
    dispatch(clearAgentState())

    return () => dispatch(clearAgentState())
  }, [])

  return (
    <div className={classes.root}>
      <Container>
        <Content>
          <AddProperty />
        </Content>
      </Container>
    </div>
  )
}

export default withStyles(styles)(AgencyAddProperty)
