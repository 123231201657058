import { useDispatch, useSelector } from 'react-redux';
import { StorageActions } from 'store/actions/storage.actions';
import { getSuperadminManageAgencyButtons } from 'store/selectors/storage.selectors';

export default function useHandleManageAgencyButtons() {
  const dispatch = useDispatch();
  const manageButtons = useSelector(getSuperadminManageAgencyButtons);

  const setManageButtons = (buttons) => {
    dispatch(StorageActions(buttons, 'GENERAL_SETTINGS', 'SET_SUPERADMIN_MANAGE_AGENCY_BUTTONS'));
  };

  return [manageButtons, setManageButtons];
}
