import {
  SET_PROPERTY_SEARCH_FILTERS,
  SET_PROPERTY_SUBURB_SEARCH,
  SET_PERFECT_PROPERTY_FILTERS,
  SET_INCLUDE_NEARBY_SUBURB,
  SET_SORTING_FILTERS,
  CLEAR_PERFECT_PROPERTY_FILTERS,
  CLEAR_SUBURB_SEARCH,
  CLEAR_FILTERS,
  REFERENCE_SEARCH_FILTERS,
  CLEAR_REFERENCE_SEARCH_FILTERS,
  SET_TYPES_COUNTERS,
  CLEAR_TYPES_COUNTERS,
  CLEAR_SORTING_FILTERS,
} from '../constants'

/** SET PROPERTY SEARCH FILTERS */
export function setPropertySearchFilters(params) {
  return {
    type: SET_PROPERTY_SEARCH_FILTERS,
    params,
  }
}

/** CLEAR FILTERS */
export function clearFilters(params) {
  return {
    type: CLEAR_FILTERS,
    params,
  }
}

/** CLEAR SORTING FILTERS */
export function clearSortingFilters(params) {
  return {
    type: CLEAR_SORTING_FILTERS,
    params,
  }
}

/** SET PERFECT PROPERTY FILTERS */
export function setPerfectPropertyFilters(params) {
  return {
    type: SET_PERFECT_PROPERTY_FILTERS,
    params,
  }
}

/** CLEAR PERFECT PROPERTY FILTERS */
export function clearPerfectPropertyFilters(params) {
  return {
    type: CLEAR_PERFECT_PROPERTY_FILTERS,
    params,
  }
}

/** SET PROPERTY SEARCH SUBURBS */
export function setPropertySuburbSearch(params) {
  return {
    type: SET_PROPERTY_SUBURB_SEARCH,
    params,
  }
}

/** CLEAR SUBURB FILTERS */
export function clearSuburbSearch(params) {
  return {
    type: CLEAR_SUBURB_SEARCH,
    params,
  }
}

/** SET PROPERTY NEARBY */
export function setIncludeNearby(params) {
  return {
    type: SET_INCLUDE_NEARBY_SUBURB,
    params,
  }
}

/** SET SORTING FILTERS */
export function setSortingFilters(params) {
  return {
    type: SET_SORTING_FILTERS,
    params,
  }
}

/* Reference FILTERS */
export function setReferenceFilters(params, reference, cursor) {
  return {
    type: REFERENCE_SEARCH_FILTERS,
    reference: reference,
    cursor: cursor,
    params,
  }
}

/** CLEAR REference FILTERS */
export function clearReferenceFilters(params) {
  return {
    type: CLEAR_REFERENCE_SEARCH_FILTERS,
    params,
  }
}

/* SET Types Counters */
export function setTypesCounters(params) {
  return {
    type: SET_TYPES_COUNTERS,
    params,
  }
}

/** CLEAR Types Counters */
export function clearTypesCounters(params) {
  return {
    type: CLEAR_TYPES_COUNTERS,
    params,
  }
}
