export const SET_PROPERTY_SEARCH_FILTERS = 'filter/SET_PROPERTY_SEARCH_FILTERS'
export const SET_PERFECT_PROPERTY_FILTERS = 'filter/SET_PERFECT_PROPERTY_FILTERS'
export const SET_PROPERTY_SUBURB_SEARCH = 'filter/SET_PROPERTY_SUBURB_SEARCH'
export const SET_SORTING_FILTERS = 'filter/SET_SORTING_FILTERS'

export const CLEAR_SORTING_FILTERS = 'filter/CLEAR_SORTING_FILTERS'
export const CLEAR_FILTERS = 'filter/CLEAR_FILTERS'
export const CLEAR_PERFECT_PROPERTY_FILTERS = 'filter/CLEAR_PERFECT_PROPERTY_FILTERS'
export const CLEAR_SUBURB_SEARCH = 'filter/CLEAR_SUBURB_SEARCH'
export const SET_INCLUDE_NEARBY_SUBURB = 'filter/SET_INCLUDE_NEARBY_SUBURB'

export const REFERENCE_SEARCH_FILTERS = 'filter/REFERENCE_SEARCH_FILTERS'
export const CLEAR_REFERENCE_SEARCH_FILTERS = 'filter/REFERENCE_SEARCH_FILTERS'

export const SET_TYPES_COUNTERS = 'filter/SET_TYPES_COUNTERS'
export const CLEAR_TYPES_COUNTERS = 'filter/CLEAR_TYPES_COUNTERS'
