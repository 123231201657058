import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import RangeSlider from '../../Web/Filter/RangeSlider'
import useFilters from 'utils/customHooks/useFilters'

const Container = styled.div`
  padding: 20px 0;
`

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
  .slider-Bedrooms {
    .MuiSlider-markLabel[data-index='0'] {
    }
  }
`

const marksExact = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
]

const marksDefault = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: '1+',
  },
  {
    value: 2,
    label: '2+',
  },
  {
    value: 3,
    label: '3+',
  },
  {
    value: 4,
    label: '4+',
  },
  {
    value: 5,
    label: '5+',
  },
]

const Carparking = () => {
  const [getFilter, setFilter] = useFilters()
  const CARPARKING = getFilter('carparking')

  const handleCarparkingChange = (event, newValue, isExact, exactChange) => {
    if ((isExact !== undefined || isExact !== null) && exactChange === true) {
      setFilter({
        key: 'carparking',
        value: { ...CARPARKING, exact: isExact },
      })
    } else {
      setFilter({
        key: 'carparking',
        value: { ...CARPARKING, value: newValue },
      })
    }
  }

  return (
    <SliderItem>
      <RangeSlider
        label="Carparking"
        icon="DirectionsCar"
        value={(CARPARKING && CARPARKING.value) ?? 0}
        handleChange={handleCarparkingChange}
        step={1}
        marks={CARPARKING && CARPARKING.exact ? marksExact : marksDefault}
        min={0}
        max={5}
        isExact={CARPARKING && CARPARKING.exact}
        enableExactControls={true}
      />
    </SliderItem>
  )
}

export default Carparking
