export const AUTH_ACTION_TYPES = {
  AUTH_FORGOT_PASSWORD: {
    REQUEST: 'auth/AUTH_FORGOT_PASSWORD_REQUEST',
    SUCCESS: 'auth/AUTH_FORGOT_PASSWORD_SUCCESS',
    FAILED: 'auth/AUTH_FORGOT_PASSWORD_FAILED',
  },
  AUTH_RESET_PASSWORD: {
    REQUEST: 'auth/AUTH_RESET_PASSWORD_REQUEST',
    SUCCESS: 'auth/AUTH_RESET_PASSWORD_SUCCESS',
    FAILED: 'auth/AUTH_RESET_PASSWORD_FAILED',
  },
  GENERAL_AUTH: {
    CLEAR_ERROR: 'auth/CLEAR_ERROR',
    CLEAR_STATUS: 'auth/CLEAR_STATUS',
    RESET_STATES: 'auth/RESET_STATES',
  },

}
