import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmailAvailable, getStatus, getErrors, getAgency, getUser } from 'store/selectors/agencyv2.selectors';

import { agencyv2Actions } from 'store/actions';
import {
  requestPOSTAPIAgency,
  requestGETAPIAgency,
  //requestPUTAPIAgency,
} from 'store/api';
import { SuperBreadCrumbs, AgentsAddForm } from 'components/Web/Superadmin';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

const AgencyAddAgent = (props) => {
  const { classes, history } = props;
  const [routerChange] = useRouterChange();
  const dispatch = useDispatch();
  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getErrors);
  const GETEMAIL = useSelector(checkEmailAvailable);
  const AGENCY = useSelector(getAgency);
  const USER = useSelector(getUser);

  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [isLoading, setIsLoading] = useState(false);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/agency/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agents',
      path: '/agency/manage-agents',
    },
    {
      type: 'primary',
      label: 'Add Agent',
      path: '',
    },
  ];

  function goBack() {
    routerChange('back');
  }

  const handleEmailCheck = (data) => {
    setIsLoading(true);
    const params = {
      query: '?email=' + data.email,
      data: null,
    };

    dispatch(requestGETAPIAgency(params, 'AGENCY_CHECK_AGENT_EMAIL'));
  };

  window.onbeforeunload = (e) => {
    return handleClearData();
  };

  useEffect(() => {
    handleClearData();
    document.querySelector('#scroll-container').style.height = 'auto';
    document.querySelector('#scroll-container').style.overflowX = 'hidden';
    document.querySelector('#scroll-container').style.overflowY = 'auto';

    return () => {
      handleClearData();
    };
  }, []);

  useEffect(() => {
    if (STATUS === 'agency/AGENCY_CHECK_AGENT_EMAIL_SUCCESS') {
      setIsLoading(false);
    } else if (STATUS === 'agency/AGENCY_CHECK_AGENT_EMAIL_REQUEST') {
      setIsLoading(true);
    } else if (STATUS === 'agency/AGENCY_CHECK_AGENT_EMAIL_FAILED' && ERROR?.message !== 'Agent not found') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error checking email availability. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (ERROR?.message === 'Agent not found') {
      setIsLoading(false);
    } else if (STATUS === 'agency/AGENCY_ADD_AGENT_SUCCESS') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Successfully added an agent. Agent account must be activated',
          onClose: () => setTimeout(() => goBack(), 3000),
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'agency/AGENCY_ADD_AGENT_FAILED') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error adding agent. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'agency/AGENCY_ADD_AGENT_REQUEST') {
      setIsLoading(false);
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Adding agent...',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }

    if (STATUS === 'agency/AGENCY_PUT_AGENT_DETAILS_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Agent successfully updated.',
          autoHideDuration: 3000,
          onClose: handleClearNotices,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'agency/AGENCY_PUT_AGENT_DETAILS_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Saving changes...',
          autoHideDuration: 3000,
        },
      };
      setIsNotification(options);
    } else if (STATUS === 'agency/AGENCY_PUT_AGENT_DETAILS_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error saving changes. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  }, [STATUS]);

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'));
  };

  const handleClearData = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'));
  };

  const handleClearNotices = () => {
    handleClearStatus();
    handleClearError();
    setIsNotification(defaultNotification);
  };

  const handleNewAgent = (data) => {
    const params = {
      query: null,
      data: {
        reaAgencyId: AGENCY?.reaAgencyId,
        agentList: [{ ...data, phone: data.telephone }],
        agencyName: AGENCY?.companyName,
      },
    };
    if (AGENCY) {
      dispatch(requestPOSTAPIAgency(params, 'AGENCY_ADD_AGENT'));
    } else {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error, unknown Agency ID. Please logout and log in again.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  };

  /*   const handleUpdateAgent = (data) => {
    const params = {
      query: GETEMAIL?.data?.agent._id,
      data: data,
    }

    dispatch(requestPUTAPIAgency(params, 'AGENCY_PUT_AGENT_DETAILS'))
  } */

  const handleSubmit = (data) => {
    handleNewAgent(data);
    /* if (ERROR?.message === 'Agent not found') {
      handleNewAgent(data)
    } else if (GETEMAIL?.data?.agent) {
      handleUpdateAgent(data)
    } */
  };

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs section={'Add an Agent'} crumbsLinks={CRUMBS} />
      <AgentsAddForm
        match={{ params: { id: USER.agency } }}
        goBack={goBack}
        handleEmailCheck={handleEmailCheck}
        isLoading={isLoading}
        error={STATUS === 'agency/AGENCY_CHECK_AGENT_EMAIL_FAILED' && ERROR?.message !== 'Agent not found'}
        result={ERROR?.message === 'Agent not found' ? ERROR : GETEMAIL}
        clearPayload={handleClearData}
        handleSubmit={handleSubmit}
      />

      {isNotification.status && (
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}
        />
      )}
    </div>
  );
};
export default withStyles(styles)(AgencyAddAgent);
