import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import SectionHeading from '../SectionHeading'

const useStyles = makeStyles((theme) => ({
  auctionInspectionDate: {
    fontSize: '14px',
  },
}))

function auctionTimeCalculator(time) {
  const formattedTime = time.split(':')

  let meridiem
  let hour

  const hourData = formattedTime[0]
  const minutesData = formattedTime[1]

  if (hourData === '0') {
    hour = '12'
    meridiem = 'am'

    return `${hour}:${minutesData}${meridiem} `
  }

  if (hourData < 12 && hourData !== '0') {
    hour = hourData
    meridiem = 'am'

    return `${hour}:${minutesData}${meridiem} `
  }

  if (hourData > 12 && hourData !== '0') {
    hour = hourData % 12
    meridiem = 'pm'

    return `${hour}:${minutesData}${meridiem} `
  }
}

function auctionNewTimeCalculator(time) {
  const formattedTime = time.split(':')

  let meridiem
  let hour

  const hourData = formattedTime[0]
  const minutesData = formattedTime[1]

  //it is pm if hours from 12 onwards
  meridiem = (hourData >= 12)? 'pm' : 'am';

  //only -12 from hours if it is greater than 12 (if not back at mid night)
  hour = (hourData > 12)? hourData - 12 : hourData;

  //if 00 then it is 12 am
  hour = (hour == '00')? 12 : hour;

  return `${hour}:${minutesData} ${meridiem} `
}

function getAllPendingAuctionInspections(property) {
  const pendingAuctionInspections = {
    auction: { date: '', time: '' },
    inspections: [],
  }
  const dateNow = new Date()

  if (property.auctionDate) {
    const formattedAuctionDate = new Date(property.auctionDate)
    formattedAuctionDate.setDate(formattedAuctionDate.getDate() + 1)

    if (formattedAuctionDate > dateNow) {
      formattedAuctionDate.setDate(formattedAuctionDate.getDate() - 1)

      // Convert Date to UTC format
      const utc = formattedAuctionDate.toUTCString()

      // Split UTC date into time and date
      const formattedUTCTime = auctionNewTimeCalculator(utc.split(' ')[4])
      const formattedUTCDate = utc.split(' ')
      formattedUTCDate.splice(4, 2)

      const formattedFinalDate = moment(formattedUTCDate.join(' ')).format(
        'dddd D MMMM'
      )

      pendingAuctionInspections.auction.date = formattedFinalDate
      pendingAuctionInspections.auction.time = formattedUTCTime
    }
  }

  property.inspectionTimes.forEach((inspectionDate) => {
    const fullInspectionDate = inspectionDate.split(' ', 1).toString()
    const formattedInspectionDate = new Date(fullInspectionDate.toString())
    formattedInspectionDate.setDate(formattedInspectionDate.getDate() + 1)

    if (formattedInspectionDate > dateNow) {
      const inspectionDateformatted = moment(fullInspectionDate).format('dddd D MMMM')

      const inspectionTimeArray = inspectionDate.split(' ')
      inspectionTimeArray.shift()
      inspectionTimeArray[1] = '-'
      const inspectionTime = inspectionTimeArray.join(' ')

      pendingAuctionInspections.inspections.push({
        date: inspectionDateformatted,
        time: inspectionTime,
      })
    }
  })

  return pendingAuctionInspections
}

const AuctionInspectionTimes = ({ property }) => {
  const classes = useStyles()

  const auctionInspectionDates = getAllPendingAuctionInspections(property)

  return (
    <Box display="grid" gridGap="35px">
      {auctionInspectionDates.auction.date && (
        <Box>
          <SectionHeading variant="h6" component="h2">
            Auction Date
          </SectionHeading>
          <Typography
            className={classes.auctionInspectionDate}
            variant="subtitle1"
            style={{ fontWeight: 'bold' }}
          >
            {auctionInspectionDates.auction.date}
          </Typography>
          <Typography className={classes.auctionInspectionDate} variant="subtitle1">
            {auctionInspectionDates.auction.time}
          </Typography>
        </Box>
      )}
      {auctionInspectionDates.inspections.length > 0 && (
        <Box>
          <SectionHeading variant="h6" component="h2">
            Open for inspection
          </SectionHeading>
          {auctionInspectionDates.inspections.map((inspectionDate, index) => (
            <Box
              key={'auction-inspection-dates' + index}
              borderBottom="solid 1px #d9d9d9"
              paddingY="10px"
              paddingTop={index === 0 ? '0px' : '10px'}
            >
              <Typography
                className={classes.auctionInspectionDate}
                variant="subtitle1"
                style={{ fontWeight: 'bold' }}
              >
                {inspectionDate.date}
              </Typography>
              <Typography
                className={classes.auctionInspectionDate}
                key={'auction-inspection-dates' + index}
                variant="subtitle1"
              >
                {inspectionDate.time}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default AuctionInspectionTimes
