import React from 'react';
import AgentSearchBar from './AgentSearchBar';

import Crumbs from '../Header/Crumbs';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    width: '100%',
    height: '15vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
  },
  content: {
    marginLeft: 150
  },
  crumbs: {
    marginBottom: 10,
    marginLeft: 20
  }
});

const AgentSearchHeader = props => {
  const {
    classes,
    agents,
    agencies,
    category,
    clearAgentSearchParams,
    apiRequestSearchAgent,

  } = props;

  function checkCrumbsValue() {
    if (category === 'agent') {
      return { label: 'Find an agent', route: '/agent' }
    }
    if (category === 'agency') {
      return { label: 'Find an agency', route: '/agent' }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.crumbs}>
          <Crumbs type={'DARK'} crumbsOption={checkCrumbsValue()} />
        </div>
        <AgentSearchBar
          showInlineSearchButton={true}
          agents={agents}
          agencies={agencies}
          clearAgentSearchParams={clearAgentSearchParams}
          apiRequestSearchAgent={apiRequestSearchAgent} />
      </div>
    </div>
  );
}

export default withStyles(styles)(AgentSearchHeader);