export const BUTTON_FILTERS = [
  {
    type: 'bed',
    label: 'Bedrooms',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
  {
    type: 'cost',
    label: 'Price',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
  {
    type: 'bath',
    label: 'Bathrooms',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
  {
    type: 'propertyType',
    label: 'Property Type',
    selected: '',
    values: [
      {
        name: '',
        value: '',
      },
    ],
  },
]

export const OPTION_MODAL = [
  {
    title: 'Relevance',
    value: 'Relevance',
    active: false,
  },
  {
    title: 'Newest',
    value: 'Newest',
    active: false,
  },
  {
    title: 'Price (High to Low)',
    value: 'Price (High to Low)',
    active: false,
  },
  {
    title: 'Price (Low to High)',
    value: 'Price (Low to High)',
    active: false,
  },
  {
    title: 'Bedrooms',
    value: 'Bedrooms',
    active: false,
  },
  {
    title: 'Bathrooms',
    value: 'Bathrooms',
    active: false,
  },
  {
    title: 'Sqm',
    value: 'Sqm',
    active: false,
  },
]

export const AGENCY_MODAL = [
  {
    title: 'All',
    value: 'All',
    active: false,
  },
  {
    title: 'Agency 1',
    value: 'Agency 1',
    active: false,
  },
  {
    title: 'Agency 2',
    value: 'Agency 2',
    active: false,
  },
  {
    title: 'Agency 3',
    value: 'Agency 3',
    active: false,
  },
  {
    title: 'Agency 4',
    value: 'Agency 4',
    active: false,
  },
]

export const TRADE_OPTION_MODAL = [
  {
    title: 'All',
    value: 'All',
    active: false,
  },
  {
    title: 'Landscaper',
    value: 'Landscaper',
    active: false,
  },
  {
    title: 'Plumber',
    value: 'Plumber',
    active: false,
  },
  {
    title: 'Gardener',
    value: 'Gardener',
    active: false,
  },
]

export const PROPERTY_TYPE = [
  {
    title: 'House',
    value: 'House',
    active: false,
  },
  {
    title: 'Apartment Unit',
    value: 'Apartment Unit',
    active: false,
  },
  {
    title: 'Townhouse',
    value: 'Townhouse',
    active: false,
  },
  {
    title: 'Villa',
    value: 'Villa',
    active: false,
  },
  {
    title: 'Land',
    value: 'Land',
    active: false,
  },
  {
    title: 'Acreage',
    value: 'Acreage',
    active: false,
  },
  {
    title: 'Rural',
    value: 'Rural',
    active: false,
  },
  {
    title: 'Block of Units',
    value: 'Block of Units',
    active: false,
  },
  {
    title: 'Retirement Living',
    value: 'Retirement Living',
    active: false,
  },
]

export const AGENTS_MOCKS = [
  {
    id: '001',
    profileImage:
      'https://i.pinimg.com/736x/d6/a9/57/d6a957f1d8045c9c973c12bf5968326f.jpg',
    agency: 'Ray White',
    agent: 'Samantha Smith',
    website: 'www.RTedgar.com.au',
    email: 'g.sheeann@rtedgar.com.au',
    rating: '99',
    sold: '26',
    rent: '18',
    bg: '#12B064',
  },
  {
    id: '002',
    profileImage:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80',
    agency: 'Ray White',
    agent: 'Greg Sheeann',
    website: 'www.RTedgar.com.au',
    email: 'g.sheeann@rtedgar.com.au',
    rating: '99',
    sold: '26',
    rent: '18',
    bg: '#000000',
  },
  {
    id: '003',
    profileImage:
      'https://i.pinimg.com/originals/92/9d/55/929d55665a3afae86ce05678acbe51ed.jpg',
    agency: 'Ray White',
    agent: 'Jose Chan',
    website: 'www.RTedgar.com.au',
    email: 'g.sheeann@rtedgar.com.au',
    rating: '99',
    sold: '26',
    rent: '18',
    bg: '#FEF526',
  },
  {
    id: '004',
    profileImage:
      'https://i.pinimg.com/736x/d6/a9/57/d6a957f1d8045c9c973c12bf5968326f.jpg',
    agency: 'Ray White',
    agent: 'Samantha Smith',
    website: 'www.RTedgar.com.au',
    email: 'g.sheeann@rtedgar.com.au',
    rating: '99',
    sold: '26',
    rent: '18',
    bg: '#12B064',
  },
  {
    id: '005',
    profileImage:
      'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80',
    agency: 'Ray White',
    agent: 'Greg Sheeann',
    website: 'www.RTedgar.com.au',
    email: 'g.sheeann@rtedgar.com.au',
    rating: '99',
    sold: '26',
    rent: '18',
    bg: '#000000',
  },
  {
    id: '006',
    profileImage:
      'https://i.pinimg.com/originals/92/9d/55/929d55665a3afae86ce05678acbe51ed.jpg',
    agency: 'Ray White',
    agent: 'Jose Chan',
    website: 'www.RTedgar.com.au',
    email: 'g.sheeann@rtedgar.com.au',
    rating: '99',
    sold: '26',
    rent: '18',
    bg: '#FEF526',
  },
]
