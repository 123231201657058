import React, { useRef, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ChipInput from 'material-ui-chip-input';
import TuneIcon from '@material-ui/icons/Tune';
import _ from 'lodash';
import Geocode from 'react-geocode';

import DynamicIcons from '../DynamicIcons';

import SUBURBS from '../../../assets/data/suburbs-all-new.json';
import { formatRequest } from 'utils/search.utils';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { setPropertySuburbSearch } from 'store/actions';
import { getFilters } from 'store/selectors/generic.selectors';
import useFilters from 'utils/customHooks/useFilters';
import { useSearch } from 'utils/search/useSearch';
import { useRouterChange } from 'utils/router.utils';

import useGoogleEvents from 'utils/customHooks/useGoogleEvents';

const useStyles = makeStyles((theme) => ({
  customButton: {
    // height: '30px',
    // minWidth: '20px !important',
    //width: '100%',
    /*     borderRadius: '19px',
    borderColor: '#000000',
    margin: '0 10px 0 0',
    padding: theme.spacing(2), */
    // color: 'black',
    // fontSize: '16px',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 0px',

    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down(1340)]: {
      fontSize: '12px',
    },

    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
    '&.active': {
      backgroundColor: '#EEEEEE',
      //borderBottom: '5px solid #35C0CA',
    },
    // '& .MuiButton-label > span:first-child': {
    //   width: '100%',
    // },
  },

  customLine: {
    height: '5px',
    margin: '20px',
    //minWidth: '40px !important',
    //width: '100%',
    /*     borderRadius: '19px',
    borderColor: '#000000',
    margin: '0 10px 0 0',
    padding: theme.spacing(2), */
    width: '330px',
    borderBottom: '2px solid black',
  },

  container: {
    marginTop: '10px',
    marginBottom: '10px',
    //padding: '20px',
    width: '330px',
    height: '5px',
    //border: '1px solid black',
  },
  tabs: {
    display: 'flex',
    //position: 'relative',
    content: '',
    width: '330px',
    borderBottom: '1px solid grey',
    position: 'absolute',
    /*     '&::after': {
      content: '',
      width: '100%',
      borderBottom: '5px solid Red',
      position: 'absolute',
    }, */
  },
  activeTab: {
    borderBottom: '5px solid #35C0CA',
    zIndex: 2,
    marginTop: '5px !important',
    borderRadius: '5px',
  },

  buttonLabel: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'center',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
  },

  // Searchbar
  chipContainer: {
    flexGrow: 1,
    width: '100%',
    minHeight: '54px',
    // overflow: 'hidden',
    // minWidth: '370px',
    flexFlow: 'nowrap',
    alignSelf: 'center',
    border: 4,
    cursor: 'pointer',
    alignItems: 'center',
    padding: '8px 0px',
  },
  chip: {
    backgroundColor: '#f5f5f5',
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 0,
    height: '2.875em',
    fontSize: '0.875em',
  },
  input: {
    marginLeft: theme.spacing(1),
    zIndex: 2,
    textAlign: 'left',
    width: '100%',

    margin: '0px',
    padding: '8px 0px 8px 0px',
  },

  //Searchbar Icon
  iconButton: {
    alignSelf: 'stretch',
    position: 'relative',
    backgroundColor: '#35C0CA',
    borderRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,

    '&:hover': {
      backgroundColor: '#35C0CA',
    },
  },
  iconSearch: {
    color: '#FFF',
  },

  // Searchbar items
  listContainer: {
    position: 'relative',
  },
  list: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    width: '100%',
    overflowY: 'scroll',
    maxHeight: '11.250em',
    cursor: 'pointer',
    boxShadow: '3px 3px 11px 1px rgba(0,0,0,0.24)',
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
    },
    'li ': {
      cursor: 'pointer',
    },
  },

  expandSearchBar: {
    flexFlow: 'wrap',
    // padding: '0px 0px 5px 0px',
  },
}));

function mergeAddress(d, s) {
  let text = (d.locality + ', ' + d.state + ', ' + d.postcode)
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, '');
  let searchText = s
    .toLowerCase()
    .replace(/[^\w\s]/gi, '')
    .replace(/ /g, '');
  return text.toLowerCase().startsWith(searchText);
}

const SearchBar = (props) => {
  const { atMobileHomePage, routerChange } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const [getFilter, setFilter] = useFilters();
  const SEARCH_BUTTONS = getFilter('search_reference');
  const SUBURBS_FILTER = getFilter('suburbs');

  const FILTERS = useSelector(getFilters);

  const [searchText, setSearchText] = useState('');
  const [resultArr, setResultArr] = useState(null);

  const [defaultGeoCoordinates, setDefaultGeoCoordinates] = useState(null);
  const [defaultGeolocation, setDefaultGeolocation] = useState(null);
  const [geoSelectedKeyword, setGeoSelectedKeyword] = useState(null);
  const [presetCount, setPresetCount] = useState(false);

  const [expandSearchBar, setExpandSearchBar] = useState(false);
  const [mouseOnSearchBar, setMouseOnSearchBar] = useState(false);
  const [initiateSearch] = useSearch();
  const [error, setError] = useState(false);

  const chipValues = SUBURBS_FILTER.map((filter) => {
    return `${filter.locality}, ${filter.state}, ${filter.postcode}`;
  });

  const { getSearchData, triggerAnalyticsEvent } = useGoogleEvents();

  const handleSearch = () => {
    initiateSearch();
  };

  const searchBarRef = useRef();

  const handleSearchBarFocus = () => {
    searchBarRef.current.focus();
  };

  const filterResult = (value) => {
    // setFilterCalled(true)

    if (value.length > 0) {
      /** Set filtered suburb based from results */
      let suburb = _.filter(SUBURBS, (d) => {
        return mergeAddress(d, value) || d.postcode.toLowerCase() === value.toLowerCase();
      });

      let groups = _.groupBy(suburb, 'state');
      let topSuburbs = [];
      _.forEach(groups, (item, key) => {
        _.forEach(item, (o, k) => {
          if (k < 10) {
            topSuburbs.push(o);
          }
        });
      });
      /** Filter and Remove Matched Object from Selection */
      var output = _.orderBy(
        _.filter(topSuburbs, (el) => {
          return (
            _.findIndex(SUBURBS_FILTER, (elem) => {
              return elem.id === el.id;
            }) === -1
          );
        }),
        ['locality'],
        ['asc'],
      );

      setResultArr(output);
    } else {
      setResultArr(null);
    }
    // setFilterCalled(false)
  };

  function addSelection(value) {
    /** Set Selected Values */
    let selectedSuburbs = SUBURBS_FILTER;
    selectedSuburbs.push({
      ...value,
      name: value.locality + ', ' + value.state + ', ' + value.postcode,
    });
    setFilter({ key: 'suburbs', value: selectedSuburbs });

    /** Set Property Suburb Search */
    // dispatch(setPropertySuburbSearch(selectedSuburbs))

    /** Set Chip Values */

    let selectedChip = chipValues;
    selectedChip.push(value.locality + ', ' + value.state + ', ' + value.postcode);

    /** Reset Results and search text */
    setResultArr(null);
    //props.setIsInputFocused(false);
    setSearchText('');
  }

  function removeSelected(value, index) {
    /** Filter and Remove Matched Object from Selection */
    let selected = _.filter(SUBURBS_FILTER, function (n) {
      return n.name !== value;
    });
    setFilter({ key: 'suburbs', value: selected });

    /** Set Property Suburb Search */
    // dispatch(setPropertySuburbSearch(selected))

    /** Set Chip Values */
    let selectedChip = _.filter(chipValues, function (n) {
      return n !== value;
    });
  }

  function handleChange(e) {
    setSearchText(e.target.value);
    let txt = e.target.value || null;
    if (txt) {
      // handleFilterResult(txt)
      filterResult(txt);
    } else {
      setResultArr(null);
    }
  }

  //TODO: Get filter state from redux and pass to search property action
  const apiRequestSearchProperty = async () => {
    // setIsLoading(true)
    // let params = await formatRequest('buy', filters)
    // dispatch(requestPropertySearch(params))
  };

  useEffect(() => {
    if (geoSelectedKeyword && presetCount === false) {
      const selectedChip = {
        locality: geoSelectedKeyword.suburb.toUpperCase(),
        postcode: geoSelectedKeyword.postcode,
        state: geoSelectedKeyword.state.toUpperCase(),
      };
      if (geoSelectedKeyword.suburb && geoSelectedKeyword.state && geoSelectedKeyword.postcode) {
        addSelection(selectedChip);
        setPresetCount(true);
      }
    }
  }, [geoSelectedKeyword]);

  useEffect(() => {
    if (defaultGeoCoordinates) {
      const tempCoords = { lat: -37.7869071, lng: 144.9203547 };
      Geocode.fromLatLng(defaultGeoCoordinates.lat, defaultGeoCoordinates.lng).then(
        (response) => setDefaultGeolocation(response.results[0]),
        () => setDefaultGeolocation(null),
      );
    }
  }, [defaultGeoCoordinates]);

  useEffect(() => {
    if (defaultGeolocation) {
      const postcode = _.find(defaultGeolocation.address_components, { types: ['postal_code'] }) ?? null;
      const state =
        _.find(defaultGeolocation.address_components, {
          types: ['administrative_area_level_1', 'political'],
        }) ?? null;
      const municipality =
        _.find(defaultGeolocation.address_components, {
          types: ['administrative_area_level_2', 'political'],
        }) ?? null;
      const suburb =
        _.find(defaultGeolocation.address_components, {
          types: ['locality', 'political'],
        }) ?? null;
      const country =
        _.find(defaultGeolocation.address_components, {
          types: ['country', 'political'],
        }) ?? null;

      let details = {};
      details.postcode = postcode ? postcode.short_name : '';
      details.state = state ? state.short_name : '';
      details.municipality = municipality ? municipality.short_name : '';
      details.suburb = suburb ? suburb.short_name : '';
      if (country && country.short_name === 'AU') {
        setGeoSelectedKeyword(details);
      }
    }
  }, [defaultGeolocation]);

  // useEffect(() => {
  //   if (FILTERS) {
  //     if (FILTERS.suburbs.length > 0) {
  //       setPresetCount(true)
  //       setSelectedValue(_.uniq(FILTERS.suburbs))
  //       // if(JSON.stringify(FILTERS.suburbs) !== JSON.stringify(selectedValue)) {
  //       //     props.setPropertySearch(FILTERS.suburbs);
  //       // }
  //       let suburb = []
  //       FILTERS.suburbs.map((item, index) => {
  //         suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode)
  //       })

  //       setSelectedChipValue(_.uniq(suburb))
  //       // if (JSON.stringify(_.uniq(suburb)) !== JSON.stringify(selectedChipValue)) {
  //       //   props.setChipValue(_.uniq(suburb))
  //       // }
  //     } else {
  //       setSelectedChipValue([])
  //       setSelectedValue([])
  //     }
  //   }
  // }, [FILTERS])

  return (
    <Box
      display="flex"
      flexDirection="column"
      //width="350px"
      marginTop="25px"
      width="325px"
      marginRight="auto"
      marginLeft="auto"
    >
      <Box
        style={{
          borderRadius: '4px',
          backgroundColor: '#FFFFFF',
          borderTopRightRadius: '10px',
          borderBottomRightRadius: '10px',
          borderColor: '#35C0CA',
          boxShadow: 'rgb(0 0 0 / 30%) inset 0px 3.5px 5px',
        }}
        display="flex"
        width="100%"
        border={error ? '1px solid red' : '1px solid #000000'}
        alignItems="center"
        justifyContent="center"
        paddingLeft="10px"
        overflow="hidden"
      >
        <ChipInput
          ref={searchBarRef}
          onAdd={() => handleSearchBarFocus()}
          clearInputValueOnChange={true}
          fullWidth
          fullWidthInput={false}
          classes={{
            chipContainer: `${classes.chipContainer}  ${expandSearchBar ? classes.expandSearchBar : ''}`,
            input: classes.input,
            chip: classes.chip,
          }}
          disableUnderline={true}
          placeholder="Seek by Suburb"
          alwaysShowPlaceholder
          value={chipValues}
          onDelete={(chip, index) => {
            removeSelected(chip, index);
            handleSearchBarFocus();
          }}
          InputProps={{
            onChange: (e) => {
              handleChange(e);
              setExpandSearchBar(true);
              // handleChangeDebounce(e.target.value)
            },
            onBlur: () => {
              if (!resultArr || resultArr.length === 0) {
                setSearchText('');
              }
            },
            value: searchText,
          }}
          onFocus={() => {
            setError(false);
            setExpandSearchBar(true);
          }}
          onBlurCapture={() => {
            if (!mouseOnSearchBar) {
              setExpandSearchBar(false);
            }
          }}
          onMouseEnter={() => setMouseOnSearchBar(true)}
          onMouseLeave={() => {
            setMouseOnSearchBar(false);
          }}
        />

        <Box
          style={{
            minHeight: '40px',
            height: '80%',
            backgroundColor: 'white',
            zIndex: 2,
            paddingRight: '5px',
            display: 'flex',
          }}
        >
          <Tooltip title="More Filters">
            <IconButton
              style={{ padding: '5px' }}
              onClick={(e) => {
                const activeButton = SEARCH_BUTTONS.find((button) => button.active === true);
                triggerAnalyticsEvent({
                  event: 'homepage_actions',
                  eventFrom: 'Mobile',
                  actionScope: 'homepage_filters',
                  actionName: 'Homepage Filters',
                  actionTarget: `Expanded filter for ${activeButton.path}`,
                  actionReference: 'homepage',
                });

                routerChange(`${activeButton.path}/filters`);
              }}
            >
              <Badge badgeContent={null} color="primary" style={{ pointerEvents: 'none' }}>
                <TuneIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        </Box>
        <Box flexGrow={1} height="100%" zIndex={3}>
          <Tooltip title="Search">
            <IconButton
              className={classes.iconButton}
              style={{
                border: '2px solid #35C0CA',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                height: '100%',
                outline: '1px solid #35C0CA',
              }}
              onClick={(e) => {
                if (SUBURBS_FILTER.length > 0) {
                  handleSearch();
                  const activeButton = SEARCH_BUTTONS.find((button) => button.active === true);
                  triggerAnalyticsEvent({
                    event: 'homepage_actions',
                    eventFrom: 'Mobile',
                    actionScope: 'homepage_search',
                    actionName: 'Homepage Search',
                    actionTarget: 'Searched on ' + activeButton?.path,
                    actionReference: 'homepage',
                    ...getSearchData(),
                  });
                } else {
                  setError(true);
                }
              }}
            >
              <SearchIcon style={{ color: '#FFFFFF' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Paper style={{ position: 'relative', width: '100%' }}>
        {resultArr && resultArr.length > 0 && (
          <List dense={true} className={classes.list}>
            {resultArr &&
              resultArr.map((value, index) => {
                let textLocation = value.locality + ', ' + value.state + ', ' + value.postcode;

                let isExist = _.findIndex(chipValues, function (o) {
                  return o == textLocation;
                });

                // if(isExist === -1) {
                return (
                  <ListItem key={'search-suburb-list' + index} onClick={() => addSelection(value)}>
                    <ListItemText primary={textLocation} />
                  </ListItem>
                );
                // }
              })}
          </List>
        )}
      </Paper>
      {error && (
        <Box color="red" marginTop="5px">
          <Typography variant="caption">Please Add the location you are looking for</Typography>
        </Box>
      )}
    </Box>
  );
};

const HomePageSearchBar = (props) => {
  const classes = useStyles();
  const [routerChange] = useRouterChange();
  const [getFilter, setFilter] = useFilters();
  const SEARCH_BUTTONS = getFilter('search_reference');
  const activeButton = SEARCH_BUTTONS.find((button) => button.active === true);

  const { triggerAnalyticsEvent } = useGoogleEvents();

  const handleBasicSearchButtonClick = (filterIndex, buttonObject) => {
    const homeButtons = SEARCH_BUTTONS.map((item, itemIndex) =>
      filterIndex === itemIndex ? { ...item, active: true } : { ...item, active: false },
    );

    if (filterIndex === 5) {
      routerChange('/perfect-property/filters');
    } else {
      setFilter({ key: 'search_reference', value: homeButtons });
    }

    triggerAnalyticsEvent({
      event: 'homepage_actions',
      eventFrom: 'Mobile',
      actionScope: 'homepage_filters',
      actionName: 'Homepage Filters',
      actionTarget: 'Searched on ' + activeButton?.id?.toLowerCase(),
      actionReference: 'homepage',
    });
  };

  return (
    <Box display="grid">
      <Box margin="0px 10px " paddingBottom="20px" borderBottom="solid 1px #cccccc" flexDirection="row" display="flex">
        {SEARCH_BUTTONS?.map((button, index) => {
          return button.id != 'mpp' ? (
            <Box height="100%" width="20%" display="flex" flexDirection="column" position="relative">
              <Box
                key={'search-reference' + index}
                //className={classes.customButton}
                className={`${classes.customButton}
            ${
              button.active
                ? 'active btn-hero btn-' + button.name.replace(/\s+/g, '-').toLowerCase()
                : 'btn-hero btn-' + button.name.replace(/\s+/g, '-').toLowerCase()
            }`} //variant="outlined"
                style={{
                  backgroundColor: index === 5 ? '#FDBD26' : '#FFFFF',
                  borderRadius: index === 5 ? '45px' : '',
                  boxShadow: index === 5 ? '0 2px 2px 0 rgb(0 0 0 / 25%)' : '',
                  '-webkit-box-shadow': index === 5 ? '0 2px 2px 0 rgb(0 0 0 / 25%)' : '',
                  '-moz-box-shadow': index === 5 ? ' 0 2px 2px 0 rgba(0,0,0,0.25)' : '',
                }}
                // value={button.id}
                onClick={(event) => {
                  handleBasicSearchButtonClick(index, button);
                }}
              >
                <DynamicIcons
                  style={{
                    transform: button.id === 'rent' ? 'rotate(90deg)' : '',
                  }}
                  icon={button.icon}
                />
              </Box>
              {index !== 5 && (
                <Typography
                  style={{
                    letterSpacing: '-.05px',
                    fontSize: '12px',
                    paddingTop: '5px',
                    lineHeight: '14px',
                    // width: '50px',
                    textAlign: 'center',
                    //wordWrap: 'break-word',
                    //paddingLeft: '11px',
                  }}
                >
                  {button.name.toUpperCase()}
                </Typography>
              )}

              {button.active && (
                <Box
                  top="127.5%"
                  position="absolute"
                  width="100%"
                  height="5px"
                  borderRadius="20px"
                  style={{ backgroundColor: '#35C0CA' }}
                ></Box>
              )}
            </Box>
          ) : null;
        })}
      </Box>

      <SearchBar routerChange={routerChange} />
    </Box>
  );
};

export default HomePageSearchBar;
