import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SuperBreadCrumbs,
  AgencyDetailsUpgradeForm,
  AgencyDetailsUpgradePaidForm,
  Snackybar,
} from 'components/Web/Superadmin';
import UpgradeAgencyBillingForm from 'components/Web/Superadmin/Agency/UpgradeAgencyBillingForm';

import ConfirmDialog from 'components/Web/CustomDialogs/ConfirmDialog';
import AlertNotification from 'components/Web/Form/AlertNotification';
import { Loader } from 'components/Common';
import { getPricePackages } from 'store/selectors/billing.selectors';
import { billingActions } from 'store/actions/billing.actions';
import { requestGETAPIBilling } from 'store/api/billing.api';

import { FilterNoneSharp } from '@material-ui/icons';

import OFFICES from '../../../assets/data/offices.json';
/** actions */
import { superadminActions, agencyv2Actions, ippReferralActions } from 'store/actions';

import {
  requestPOSTAPISuperadmin,
  requestGETAPISuperadmin,
  requestPUTAPIAgency,
  requestPUTAPISuperadminAgency,
  requestDELETEAPISuperadminAgency,
} from 'store/api';
/** selectors */
import {
  getStatus,
  getErrors,
  getAgencies,
  getAgency,
  getOffice,
  getOfficeLists,
} from 'store/selectors/superadmin.selectors';

import { getAnalytics } from 'store/selectors/ippReferral.selectors';

import { getStatus as getAgencyStatus, getAgencyUpload } from 'store/selectors/agencyv2.selectors';

import { getStatus as getIppReferralStatus } from 'store/selectors/ippReferral.selectors';
import { sendEmailActivation } from 'store/api';
import { useRouterChange } from 'utils/router.utils';
import { withStyles } from '@material-ui/core';

import { requestPOSTAPIAgencyReferral, requestGETAPIAgencyReferral } from 'store/api';
import moment from 'moment';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
});

//subscription states
const subscriptionStatuses = [
  'SUBSCRIPTION_PENDING',
  'SUBSCRIPTION_TRIAL_ACTIVE',
  'SUBSCRIPTION_ACTIVE',
  'SUBSCRIPTION_CANCELED',
  'ACTIVATED',
];

const SuperadminFreeAgencyDetails = (props) => {
  const { classes, history, match } = props;
  const [routerChange] = useRouterChange();
  const dispatch = useDispatch();

  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getErrors);
  const AGENCIES = useSelector(getAgencies);
  const AGENCY = useSelector(getAgency);
  const AGENCY_STATUS = useSelector(getAgencyStatus);
  const PACKAGES = useSelector(getPricePackages);
  const AGENCY_UPLOAD = useSelector(getAgencyUpload);
  const IPP_REFERRAL_DATA_PER_AGENCY = useSelector(getAnalytics);
  const [ippSelectDate, setIppSelectDate] = useState('TODAY');
  const [ippStartDate, setIppStartDate] = useState(null);
  const [ippEndDate, setIppEndDate] = useState(null);

  //const OFFICES_LIST = OFFICES
  const OFFICES_LIST = useSelector(getOfficeLists);
  const OFFICE_DETAILS = useSelector(getOffice);

  //ipp referral status
  const IPP_REFERRAL_STATUS = useSelector(getIppReferralStatus);
  const [step, setStep] = useState(1);

  //packages
  const [packages, setPackages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //office lists
  const [officeLists, setOfficeLists] = useState(null);

  //docusign review
  const [isReviewDocusign, setIsReviewDocusign] = useState(false);

  const handleFormStep = (type) => {
    setStep((prev) => {
      if (type === 'next') return (prev += 1);
      if (type === 'back') return (prev -= 1);
    });
  };

  const handleGetPackageClear = () => {
    dispatch(billingActions(null, 'STRIPE_GET_PRICES', 'CLEAR'));
  };

  const handleGetOfficeListsClear = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_GET_OFFICE_LISTS', 'CLEAR'));
  };

  const handleStartDateChange = (date) => {
    setIppStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setIppEndDate(date);
  };

  const handleUploadImage = (data, type) => {
    const params = {
      query: AGENCY?.data?.entity?._id + `/?photo=${type}`,
      data: data,
    };
    dispatch(requestPUTAPIAgency(params, 'AGENCY_UPLOAD_IMAGE'));
  };

  const apiRequestGetPricePackages = () => {
    setIsLoading(true);
    const request = {
      query: null,
      data: null,
    };
    handleGetPackageClear();
    dispatch(requestGETAPIBilling(request, 'STRIPE_GET_PRICES', 'REQUEST'));
  };

  const apiRequestGetOfficeLists = () => {
    setIsLoading(true);
    const request = {
      query: '',
      data: null,
    };
    handleGetOfficeListsClear();
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_OFFICE_LISTS', 'REQUEST'));
  };

  // end of packages
  const defaultNotification = { status: false, options: null };

  const [docusignRequest, setDocusignRequest] = useState(false);
  const [docusignResendRequest, setResendDocusignRequest] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    type: '', // * activate_account | pause_account | delete_account | send_docusign | save_draft | request_credit_card
    title: '',
    message: '',
    data: null,
    continue: false,
  });
  const [currentRequest, setCurrentRequest] = useState(null);
  const [referralLoading, setReferralLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  });
  const [initialData, setInitialData] = useState(null);
  /** Alert Notification */
  const [notificationOptions, setNotificationOptions] = useState(null);
  const [isNotification, setIsNotification] = useState(false);
  const [recentStatus, setRecentStatus] = useState(false);

  const [office, setOffice] = useState(initialData?.reaAgencyId || '');
  const [officeId, setOfficeId] = useState(initialData?.reaAgencyId || '');

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
    {
      type: 'primary',
      label: 'Manage Agency Information',
      path: '',
    },
  ];

  //status
  const allStatusRequest = [
    'superadmin/SUPERADMIN_GET_AGENCY_DATA_REQUEST',
    'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_REQUEST',
    'superadmin/SUPERADMIN_PAUSE_ACCOUNT_REQUEST',
    'superadmin/SUPERADMIN_DELETE_ACCOUNT_REQUEST',
    'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_REQUEST',
    'superadmin/SUPERADMIN_GET_OFFICE_DATA_REQUEST',
    //'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_REQUEST',
    'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_REQUEST',
    'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_REQUEST',
  ];

  const allStatusSuccess = [
    'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_SUCCESS',
    'superadmin/SUPERADMIN_PAUSE_ACCOUNT_SUCCESS',
    'superadmin/SUPERADMIN_DELETE_ACCOUNT_SUCCESS',
    'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_SUCCESS',
    'superadmin/SUPERADMIN_GET_OFFICE_DATA_SUCCESS',
    //'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_SUCCESS',
    'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_SUCCESS',
    'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_SUCCESS',
  ];

  const allStatusFailed = [
    'superadmin/SUPERADMIN_GET_AGENCY_DATA_FAILED',
    'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_FAILED',
    'superadmin/SUPERADMIN_PAUSE_ACCOUNT_FAILED',
    'superadmin/SUPERADMIN_DELETE_ACCOUNT_FAILED',
    'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_FAILED',
    'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_FAILED',
    'superadmin/SUPERADMIN_GET_OFFICE_DATA_FAILED',
    'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_FAILED',
    'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_FAILED',
  ];

  const officesHandler = (officeList) => {
    const selectOfficesField = [];

    const defaultOption = {
      label: '-- Please Select --',
      value: 'pleaseSelect',
      _id: 'pleaseSelect',
    };

    let singleOffice = {};

    selectOfficesField.push(defaultOption);
    if (officeList) {
      officeList.forEach((offices) => {
        singleOffice = {
          label: `${offices.registeredTradeName ? offices.registeredTradeName : offices.companyName} - ${
            offices.reaAgencyId
          }`,
          value: offices.reaAgencyId,
          _id: offices._id,
        };
        selectOfficesField.push(singleOffice);
      });
    }

    // sort by name
    let byLabel = selectOfficesField.slice(0);
    byLabel.sort(function (a, b) {
      let x = a.label.toLowerCase();
      let y = b.label.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    return byLabel;
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Prevent the default behavior of the beforeunload event
      event.preventDefault();
      // Chrome requires returnValue to be set to an empty string
      event.returnValue = '';

      // You can provide a custom message here if needed
      // event.returnValue = 'Are you sure you want to leave this page?';
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (initialData) {
      setOffice(initialData.reaAgencyId);
      setOfficeId(initialData.reaAgencyId);
    }
  }, [initialData]);

  //packages
  useEffect(() => {
    if (!packages) {
      apiRequestGetPricePackages();
    }
    if (!officeLists) {
      apiRequestGetOfficeLists();
    }
  }, []);

  useEffect(() => {
    if (PACKAGES?.packages) {
      setPackages(PACKAGES?.packages);
    }

    if (PACKAGES) {
      if (
        PACKAGES.status === 'billing/STRIPE_GET_PRICES_SUCCESS' ||
        PACKAGES.status === 'billing/STRIPE_GET_PRICES_FAILED'
      ) {
        setIsLoading(false);
      }
    }
  }, [PACKAGES]);

  useEffect(() => {
    if (OFFICES_LIST?.officeLists) {
      setOfficeLists(OFFICES_LIST?.officeLists);
    }

    if (OFFICES_LIST) {
      if (
        OFFICES_LIST.status === 'superadmin/SUPERADMIN_GET_OFFICE_LISTS_SUCCESS' ||
        OFFICES_LIST.status === 'superadmin/SUPERADMIN_GET_OFFICE_LISTS_FAILED'
      ) {
        // setIsLoading(false)
        setLoading(false);
      }
    }
  }, [OFFICES_LIST]);

  useEffect(() => {
    if (OFFICE_DETAILS?.agency) {
      setConfirmDialog({
        open: true,
        type: 'send_officeId',
        title: `${
          OFFICE_DETAILS?.agency?.registeredTradeName
            ? OFFICE_DETAILS?.agency?.registeredTradeName
            : OFFICE_DETAILS?.agency?.companyName
        } - ${OFFICE_DETAILS?.agency?.reaAgencyId} `,
        message: `
        ${OFFICE_DETAILS?.propertySummary?.['On-Sale']}  On-Sale Properties,\n
        ${OFFICE_DETAILS?.propertySummary?.sold} Sold Properties,\n
        ${OFFICE_DETAILS?.propertySummary?.rental} Rental Properties,\n


        Do you want to claim this agency for this account?
        `,
        data: {
          oldAgencyReference: OFFICE_DETAILS?.agency?._id,
          newAgencyReference: match.params.id,
        },
      });
    }
    handleClearOfficeDetails();
  }, [OFFICE_DETAILS]);

  useEffect(() => {
    handleClearAgencyData();
    handleGetAgencyData();
    handleClearOfficeDetails();
    /**
     * * Set Default Values
     */
    handleClearError();
    handleClearStatus();
    handleClearUploadsStatuses();
    // return () => {
    //   handleClearAgencyData()
    //   handleClearOfficeDetails()
    //   handleClearUploadsStatuses()
    //   handleClearError()
    //   handleClearStatus()
    // }
  }, []);

  // window.onbeforeunload = (e) => {
  //   return () => {
  //     handleClearAgencyData()
  //     handleClearOfficeDetails()
  //     handleClearUploadsStatuses()
  //     handleClearError()
  //     handleClearStatus()
  //   }
  // }

  useEffect(() => {
    if (AGENCY?.data && AGENCY?.data?.entity) {
      setInitialData(AGENCY?.data?.entity);
    }
  }, [AGENCY]);

  useEffect(() => {
    //GET IPP ANALYTICS PER CURRENTLY VIEWED AGENCY
    if (AGENCY?.data && AGENCY?.data?.entity) {
      const params = {
        referralCode: AGENCY?.data?.entity?.referralCode,
        range: ippSelectDate,
      };
      dispatch(
        requestPOSTAPIAgencyReferral(
          {
            data:
              ippSelectDate !== 'CUSTOM' && !ippStartDate && !ippEndDate
                ? params
                : {
                    ...params,
                    fromDate: ippStartDate,
                    toDate: ippEndDate,
                  },
          },
          'GET_IPP_ANALYTICS',
        ),
      );
    }
  }, [AGENCY, ippSelectDate, ippStartDate, ippEndDate]);

  useEffect(() => {
    if (ippSelectDate !== 'CUSTOM') {
      setIppStartDate(null);
      setIppEndDate(null);
    }
  }, [ippSelectDate, ippStartDate, ippEndDate]);

  useEffect(() => {
    if (IPP_REFERRAL_STATUS === 'ipp-analytics/IPP_ANALYTICS_GENERATE_REFERRAL_CODE_SUCCESS') {
      setLoading(false);
      handleClearAgencyData();
      handleGetAgencyData();
      /**
       * * Set Default Values
       */
      handleClearError();
      handleClearStatus();
    }
    if (IPP_REFERRAL_STATUS === 'ipp-analytics/IPP_ANALYTICS_GENERATE_REFERRAL_CODE_REQUEST') {
      setLoading(true);
    }
  }, [AGENCY, IPP_REFERRAL_STATUS]);

  useEffect(() => {
    setReferralLoading(
      (ippSelectDate === 'CUSTOM' && (!ippStartDate || ippStartDate === null || !ippEndDate || ippEndDate === null)) ||
        IPP_REFERRAL_STATUS === 'ipp-analytics/GET_IPP_ANALYTICS_REQUEST',
    );
  }, [IPP_REFERRAL_STATUS, ippSelectDate, ippStartDate, ippEndDate]);

  useEffect(() => {
    if (AGENCY_STATUS === 'agency/UPDATE_AGENCY_DETAILS_SUCCESS') {
      setLoading(false);
      if (checkSubscriptionStatus(AGENCY?.data?.entity) === 'activated' && confirmDialog.type === 'save_draft') {
        routerChange(`/superadmin/manage-agencies/${match.params.id}`);
      }
      handleGetAgencyData();
      /**
       * * Set Default Values
       */
      handleClearError();
      handleClearStatus();
    }
    /*if (AGENCY_STATUS === 'agency/UPDATE_AGENCY_DETAILS_SUCCESS' && docusignRequest == true) {
      handleSendAgencyDocusign();
    }
    if (AGENCY_STATUS === 'agency/UPDATE_AGENCY_DETAILS_SUCCESS' && docusignResendRequest == true) {
      handleReSendAgencyDocusign();
    }*/
    if (AGENCY_STATUS === null) {
      setIsNotification(false);
    }
    if (AGENCY_STATUS?.includes('REQUEST')) {
      setIsNotification(true);
      setLoading(true);
      setNotificationOptions({
        message: 'Updating agency details',
        severity: 'info',
      });
    }
    if (AGENCY_STATUS?.includes('SUCCESS') && recentStatus) {
      setIsNotification(true);
      setLoading(false);
      setNotificationOptions({
        message: 'Agency details updated',
        severity: 'success',
      });
    }
    if (AGENCY_STATUS?.includes('FAILED') && recentStatus) {
      setIsNotification(true);
      setLoading(false);
      setNotificationOptions({
        message: 'Agency details update failed',
        severity: 'error',
      });
    }
    setTimeout(() => setIsNotification(false), 2000);
    return () => {
      setRecentStatus(true);
    };
  }, [AGENCY_STATUS]);

  useEffect(() => {
    if (allStatusRequest.some((i) => STATUS?.includes(i))) {
      setLoading(true);
      handleClearError();
    }
    if (allStatusFailed.some((i) => STATUS?.includes(i))) {
      setLoading(false);
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR.message : ERROR,
      });
    }
    if (allStatusSuccess.some((i) => STATUS?.includes(i))) {
      setLoading(false);
      setShowMessage({
        open: true,
        type: 'success',
        message: 'Success',
      });
    }
    /*     if (STATUS === 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_REQUEST') {
      setLoading(true)
      handleClearError()
      setNotificationOptions({
        message: 'Sending Agency Reference',
        severity: 'info',
      })
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_SUCCESS') {
      setLoading(false)
      setNotificationOptions({
        message: 'Agency Reference updated',
        severity: 'success',
      })
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_FAILED') {
      setIsNotification(true)
      setLoading(false)
      setNotificationOptions({
        message: 'Office update failed',
        severity: 'error',
      })
    } */
    if (STATUS === 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_REQUEST') {
      setLoading(true);
      handleClearError();
      setNotificationOptions({
        message: 'Sending docusign',
        severity: 'info',
      });
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_SUCCESS') {
      setLoading(false);
      setConfirmDialog({
        open: true,
        type: '',
        title: 'Docusign Status',
        message: 'The Docusign has been sent to the agency.',
        continue: true,
      });
      handleClearAgencyData();
      handleGetAgencyData();
      /**
       * * Set Default Values
       */
      handleClearError();
      handleClearStatus();
    }
    handleClearStatus();

    if (STATUS === 'superadmin/SUPERADMIN_SEND_ACTIVATION_EMAIL_SUCCESS') {
      setIsNotification(true);
      setNotificationOptions({
        message: 'Activation email sent.',
        severity: 'success',
      });
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_ACTIVATION_EMAIL_REQUEST') {
      setIsNotification(true);
      setNotificationOptions({
        message: 'Sending activation email...',
        severity: 'info',
      });
    }
    if (
      allStatusSuccess.some((i) => STATUS?.includes(i)) &&
      STATUS === 'superadmin/SUPERADMIN_DELETE_ACCOUNT_SUCCESS'
    ) {
      setTimeout(() => {
        routerChange('/superadmin/manage-agencies');
      }, 1000);
    }

    if (STATUS === 'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_REQUEST') {
      setLoading(false);
      setNotificationOptions({
        message: 'Sending credit card request email...',
        severity: 'info',
      });
      setIsNotification(true);
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_SUCCESS') {
      setLoading(false);
      setNotificationOptions({
        message: 'Credit card request email sent.',
        severity: 'success',
      });
      setIsNotification(true);
      setShowMessage({
        ...showMessage,
        open: false,
      });
    }
    if (STATUS === 'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_FAILED') {
      setLoading(false);
      setNotificationOptions({
        message: 'Sending credit card request email failed.',
        severity: 'error',
      });
      setIsNotification(true);
      setShowMessage({
        ...showMessage,
        open: false,
      });
    }
  }, [STATUS, ERROR]);

  useEffect(() => {
    if (AGENCY_UPLOAD && AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Upload successful!',
          autoHideDuration: 3000,
          onClose: () => {
            setNotificationOptions(defaultNotification);
            setIsNotification(false);
            handleClearUploadsStatuses();
          },
        },
      };
      setNotificationOptions(options?.options);
      setIsNotification(true);
    } else if (AGENCY_UPLOAD && AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Uploading image...',
          autoHideDuration: 3000,
        },
      };
      setNotificationOptions(options?.options);
      setIsNotification(true);
    } else if (AGENCY_UPLOAD && AGENCY_UPLOAD.status === 'agency/AGENCY_UPLOAD_IMAGE_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: AGENCY_UPLOAD?.payload_failed?.data?.reason?.message ?? 'Error uploading image. Please try again.',
          autoHideDuration: 4000,
        },
      };
      setNotificationOptions(options?.options);
      setIsNotification(true);
    }
  }, [AGENCY_UPLOAD]);

  const checkSubscriptionStatus = (data) => {
    if (data?.agencyStateList.length === 0) {
      return 'pending_activation';
    } else {
      if (data?.subscription?.subscriptionStatus == false) {
        return 'suspended';
      } else {
        let pending_match = data?.agencyStateList?.filter(function (item) {
          return item === 'PENDING';
        });

        if (!data?.agencyStateList?.includes('ACTIVATED')) {
          return 'pending_activation';
        } else {
          if (subscriptionStatuses.some((i) => data?.agencyStateList?.includes(i))) {
            const checker = (value) => subscriptionStatuses.some((element) => value.includes(element));
            let subscriptionData = [];
            subscriptionData = data?.agencyStateList.filter(checker);
            if (pending_match) {
              subscriptionData.push('PENDING');
            }
            const domainPrecedence = [
              'SUBSCRIPTION_CANCELED',
              'ACTIVATED',
              'SUBSCRIPTION_ACTIVE',
              'SUBSCRIPTION_TRIAL_ACTIVE',
              'SUBSCRIPTION_PENDING',
              'PENDING',
            ];
            const sorted = subscriptionData.sort((a, b) => {
              let index1 = domainPrecedence.indexOf(a);
              let index2 = domainPrecedence.indexOf(b);
              return index1 == -1 ? 1 : index2 == -1 ? -1 : index1 - index2;
            });
            let subscriptionValue = '';

            if (sorted[0] === 'SUBSCRIPTION_PENDING') {
              subscriptionValue = 'pending_activation';
            } else if (sorted[0] === 'PENDING') {
              subscriptionValue = 'pending_approval';
            } else {
              subscriptionValue = sorted[0]?.replace('SUBSCRIPTION_', '')?.toLowerCase();
            }

            return subscriptionValue;
          }
        }
      }
    }
  };

  const handleClearUploadsStatuses = () => {
    dispatch(agencyv2Actions(null, 'AGENCY_UPLOAD_IMAGE', 'CLEAR_STATUS'));
    dispatch(agencyv2Actions(null, 'AGENCY_UPLOAD_IMAGE', 'CLEAR_ERROR'));
  };

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    });
  };

  const handleConfirmDialogCallback = (state) => {
    handleConfirmDialogClose();
    if (confirmDialog.type === 'activate_account') {
      // * call activate API
      handleSendActivationEmail();
    }
    if (confirmDialog.type === 'pause_account') {
      // * call pause API
      handlePauseAgencyAccount();
    }
    if (confirmDialog.type === 'delete_account') {
      // * call delete API
      handleDeleteAgencyAccount();
    }
    if (confirmDialog.type === 'send_docusign') {
      setIsReviewDocusign(false);
      handleSaveAgencyDraft();
      setDocusignRequest(true);
    }
    if (confirmDialog.type === 'resend_docusign') {
      setIsReviewDocusign(false);
      handleSaveAgencyDraft();
      setResendDocusignRequest(true);
    }
    if (confirmDialog.type === 'save_draft') {
      // * call save draft API
      if (checkSubscriptionStatus(AGENCY?.data?.entity) !== 'activated') {
        handleFormStep('next');
      }
      handleSaveAgencyDraft();
    }
    if (confirmDialog.type === 'send_officeId') {
      // * call POST /agency/match/agencyReference API
      handlePostAgencyReference(confirmDialog.data);
    }
    if (confirmDialog.type === 'request_credit_card') {
      handleRequestCreditCard();
    }
    if (confirmDialog.type === 'upgrade_package') {
      routerChange(`/superadmin/manage-agencies/${currentRequest.id}/upgrade`);
    }
  };

  const handleConfirmDialogOpen = (data) => {
    setConfirmDialog({
      open: data.open,
      type: data.type,
      title: data.title,
      message: data.message,
      data: data.data,
    });

    if (data && data.data) {
      setCurrentRequest(data.data);
    }
  };

  const handleConfirmDialogClose = (state) => {
    if (confirmDialog.type === 'send_officeId') {
      setOffice(initialData?.reaAgencyId);
      setOfficeId(initialData?.reaAgencyId);
    }

    setConfirmDialog({
      ...confirmDialog,
      open: false,
    });
  };

  function closeNotification() {
    setIsNotification(false);
  }

  /** API Request */
  const handleGetAgencyData = (data) => {
    let request = {
      query: '/' + match.params.id,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_AGENCY_DATA'));
  };

  const handleSendActivationEmail = () => {
    const agencyId = initialData?._id ?? '';
    const params = { query: '/' + agencyId + '/send-activation-mail' };

    if (initialData?.agencyState !== 'ACTIVATED') {
      dispatch(sendEmailActivation(params, 'SUPERADMIN_SEND_ACTIVATION_EMAIL'));
    }
  };

  const handlePauseAgencyAccount = (data) => {
    const agencyId = initialData?._id ?? '';
    let request = { query: '/' + agencyId + '/disable' };
    if (initialData?.agencyStatus) {
      dispatch(requestPUTAPISuperadminAgency(request, 'SUPERADMIN_PAUSE_ACCOUNT'));
    }
  };

  const handleDeleteAgencyAccount = (data) => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: `/${agencyId}/delete`,
      data: null,
    };
    dispatch(requestDELETEAPISuperadminAgency(request, 'SUPERADMIN_DELETE_ACCOUNT'));
  };

  const handleSaveAgencyDraft = (data) => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: agencyId + '/update',
      data: data ? { ...data } : { ...currentRequest },
    };
    dispatch(requestPUTAPIAgency(request, 'UPDATE_AGENCY_DETAILS'));
  };

  const handleSendAgencyDocusign = (data) => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: '/' + agencyId,
      data: null,
    };
    dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_SEND_DOCUSIGN_AGENCY'));
    setDocusignRequest(false);
  };

  const handleReSendAgencyDocusign = (data) => {
    const agencyId = initialData?._id ?? '';
    let request = {
      query: '/' + agencyId,
      data: null,
    };
    dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_RESEND_DOCUSIGN_AGENCY'));
    setResendDocusignRequest(false);
  };

  const handlePostAgencyReference = (data) => {
    let request = {
      query: '/' + data?.oldAgencyReference + '/' + data?.newAgencyReference,
      data: null,
    };

    dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_SEND_OFFICE_REFERENCE'));

    const matchedAgencyViaId = OFFICES_LIST?.agencyList.find((agency) => agency?.reaAgencyId === officeId);

    setTimeout(() => {
      handleClearAgencyData();
      handleGetAgencyData();
    }, 500);

    setOffice(matchedAgencyViaId?.reaAgencyId);
    setOfficeId(matchedAgencyViaId?.reaAgencyId);
  };

  const handleRequestCreditCard = () => {
    const agencyId = initialData?._id;
    let request = {
      query: '/' + agencyId + '/add-creditcard-mail',
      data: null,
    };
    dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL'));
  };

  const handleClearAndGetAgency = (data) => {
    let request = {
      query: '/' + data,
      data: {},
    };
    handleClearOfficeDetails();
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_OFFICE_DATA'));
  };

  const handleOfficeDetails = (data) => {
    let request = {
      query: '/' + data,
      data: {},
    };
    handleClearOfficeDetails();
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_OFFICE_DATA'));
  };

  /** Redux Actions */
  const handleClearStatus = () => {
    setTimeout(() => setShowMessage({ ...showMessage, open: false }), 2000);
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'));
  };

  const handleClearAgencyData = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_GET_AGENCY_DATA', 'CLEAR'));
  };

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'));
  };

  const handleClearOfficeDetails = () => {
    dispatch(superadminActions(null, 'SUPERADMIN_GET_OFFICE_DATA', 'CLEAR'));
  };

  const handleBackgroundSave = (data) => {
    setIsReviewDocusign(true);
    handleSaveAgencyDraft(data);
  };

  return (
    <div className={classes.root}>
      <AlertNotification status={isNotification} options={notificationOptions} closeNotification={closeNotification} />
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <ConfirmDialog
        isOpen={confirmDialog.open}
        confirmCallback={() => handleConfirmDialogCallback()}
        closeCallback={() => handleConfirmDialogClose(false)}
        showLogo={true}
        align={'center'}
        title={confirmDialog.title}
        text={confirmDialog.message}
        continueButton={confirmDialog.continue}
      />
      <SuperBreadCrumbs section={'Manage Agency Information'} crumbsLinks={CRUMBS} />

      {checkSubscriptionStatus(AGENCY?.data?.entity) === 'activated' &&
        AGENCY &&
        AGENCY?.data?.entity &&
        step === 1 && (
          <>
            <AgencyDetailsUpgradePaidForm
              initialData={AGENCY?.data?.entity}
              packages={packages}
              agencies={AGENCIES?.data?.entities}
              offices={OFFICES_LIST?.agencyList}
              match={match}
              handleConfirmDialogOpen={handleConfirmDialogOpen}
              requestUploadImage={handleUploadImage}
              requestClearUploadImage={() => handleClearUploadsStatuses}
              uploadedImage={AGENCY_UPLOAD?.response?.uploadedFiles[0]}
              handleOfficeDetails={handleOfficeDetails}
              office={office}
              setOffice={setOffice}
              officeId={officeId}
              setOfficeId={setOfficeId}
              officesHandler={officesHandler}
              ippReferralData={IPP_REFERRAL_DATA_PER_AGENCY?.ippAnalytics}
              ippSelectDate={ippSelectDate}
              setIppSelectDate={setIppSelectDate}
              referralLoading={referralLoading}
              ippStartDate={ippStartDate}
              setIppStartDate={setIppStartDate}
              ippEndDate={ippEndDate}
              setIppEndDate={setIppEndDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              handleBackgroundSave={handleBackgroundSave}
            />
          </>
        )}

      {checkSubscriptionStatus(AGENCY?.data?.entity) !== 'activated' &&
        AGENCY &&
        AGENCY?.data?.entity &&
        step === 1 && (
          <>
            <AgencyDetailsUpgradeForm
              initialData={AGENCY?.data?.entity}
              packages={packages}
              agencies={AGENCIES?.data?.entities}
              offices={OFFICES_LIST?.agencyList}
              match={match}
              handleConfirmDialogOpen={handleConfirmDialogOpen}
              requestUploadImage={handleUploadImage}
              requestClearUploadImage={() => handleClearUploadsStatuses}
              uploadedImage={AGENCY_UPLOAD?.response?.uploadedFiles[0]}
              handleOfficeDetails={handleOfficeDetails}
              office={office}
              setOffice={setOffice}
              officeId={officeId}
              setOfficeId={setOfficeId}
              officesHandler={officesHandler}
              ippReferralData={IPP_REFERRAL_DATA_PER_AGENCY?.ippAnalytics}
              ippSelectDate={ippSelectDate}
              setIppSelectDate={setIppSelectDate}
              referralLoading={referralLoading}
              ippStartDate={ippStartDate}
              setIppStartDate={setIppStartDate}
              ippEndDate={ippEndDate}
              setIppEndDate={setIppEndDate}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              handleBackgroundSave={handleBackgroundSave}
              nextFormAction={handleFormStep}
            />
          </>
        )}

      {checkSubscriptionStatus(AGENCY?.data?.entity) !== 'activated' && step === 2 && (
        <UpgradeAgencyBillingForm agencyId={match.params.id} currentAgency={AGENCY?.data?.entity} />
      )}
    </div>
  );
};
export default withStyles(styles)(SuperadminFreeAgencyDetails);
