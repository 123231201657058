import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'

import HeadingContentSmall from '../Form/HeadingContentSmall'
import FormField from '../Form/FormField'
import LogoPlaceholder from '../../../assets/images/biggin-scott.jpg'

import PropertyType from '../Form/PropertyType'
import SliderContainer from '../Form/SliderContainer'
import CustomSwitch from '../Filter/CustomSwitch'

import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import _ from 'lodash'

import {
  PROPERTY_TYPE,
  PROPERTY_DETAILS_UPLOAD,
  PROPERTY_LAND_SIZE,
  PROPERTY_FEATURES,
  PROPERTY_ADVANCE,
  PROPERTY_KEYWORD,
  PERFECT_PROPERTY,
} from '../Filter/DefaultValues'

import { withStyles, Button, Typography } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    padding: '20px 21px 0 24px',
    overflow: 'hidden',
  },
})

const StyledButton = withStyles({
  root: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
    margin: '0 4px',
  },
})(Button)

const StyledText = withStyles((theme) => ({
  root: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
}))(Typography)

const LogoWrap = styled.div`
  background: black;
  padding: 20px;
  img {
    object-fit: cover;
    max-width: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
  }
`
const ContentWrap = styled.div`
  padding: 20px 0;
`
const FormWrap = styled.div`
  padding: 0 0 0;
`
const ButtonWrap = styled.div`
  padding: 20px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CheckWrap = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  width: 232px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
`
const GoogleWrap = styled.div`
  .css-1hb7zxy-IndicatorsContainer {
    display: none;
  }
  .css-yk16xz-control {
    border: 1px solid #ced4da !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    background-color: #fff !important;
    height: 56px;
    outline: none !important;
    transition: border-color 0.3s ease;
  }
  .css-g1d714-ValueContainer {
    height: 56px;
    outline: none !important;
  }
  .css-1wa3eu0-placeholder {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.35);
  }
  .css-1uccc91-singleValue {
    font-family: Roboto, sans-serif;
    font-size: 16px;
  }
  input,
  .css-b8ldur-Input,
  .css-b8ldur-Input div {
    font-family: Roboto, sans-serif !important;
    font-size: 16px !important;
  }

  .css-26l3qy-menu,
  .css-26l3qy-menu * {
    font-family: Roboto, sans-serif !important;
  }

  .css-1pahdxg-control {
    box-shadow: none !important;
    border-radius: 4px !important;
    border-color: #35c0ca !important;
  }
`

const DirectUploadStep2 = (props) => {
  const {
    classes,

    setActiveStep,
    activeStep,
    addPropertyFields,
    handleFieldChange,
    users,
    logo,
    hex,
    handleBackClick,
    backClick,
  } = props
  const [propertyType, setPropertyType] = useState(PROPERTY_TYPE)
  const [propertyDetails, setPropertyDetails] = useState(PROPERTY_DETAILS_UPLOAD)
  const [propertyStatus, setPropertyStatus] = useState(false)
  const [propertyAddress, setPropertyAddress] = useState(null)
  const [geoCodedDetails, setGeoCodedDetails] = useState(null)
  const [price, setPrice] = useState(50000)
  const [isDisabled, setIsDisabled] = useState(true)
  const [description, setDescription] = useState(null)
  const [hidePrice, setHidePrice] = useState(false)

  useEffect(() => {
    if (
      addPropertyFields.propertyInfo.propertyStatus &&
      addPropertyFields.propertyInfo.propertyStatus.value === 'offmarket'
    ) {
      setPropertyStatus(true)
    } else {
      setPropertyStatus(false)
    }
    handleFieldChange('propertyInfo', null, 'propertyStatus', handlePropertyStatus())
    setType()
  }, [])
  useEffect(() => {
    setType()
  }, [propertyType])

  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'propertyStatus', handlePropertyStatus())
  }, [propertyStatus])

  useEffect(() => {
    handleFieldChange('propertyInfo', null, 'propertyDetails', propertyDetails)
  }, [propertyDetails])

  useEffect(() => {
    if (
      (propertyAddress &&
        propertyAddress.value &&
        _.filter(propertyType, { active: true }).length > 0) ||
      backClick
    ) {
      setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }

    if (propertyAddress && propertyAddress.value) {
      geocodeByPlaceId(propertyAddress.value.place_id)
        .then((results) => setGeoCodedDetails(results[0]))
        .catch((error) => setGeoCodedDetails(null))
      // console.log(getPlaceDetails(propertyAddress), "Property Details")
    }
  }, [propertyAddress, propertyType])

  useEffect(() => {
    if (geoCodedDetails) {
      let details = {}
      details.postcode =
        _.find(geoCodedDetails.address_components, { types: ['postal_code'] })
          .short_name ?? ''
      details.state =
        _.find(geoCodedDetails.address_components, {
          types: ['administrative_area_level_1', 'political'],
        }).short_name ?? ''
      details.municipality = _.find(geoCodedDetails.address_components, {
        types: ['administrative_area_level_2', 'political'],
      })
        ? _.find(geoCodedDetails.address_components, {
          types: ['administrative_area_level_2', 'political'],
        }).short_name
        : ''
      details.suburb = _.find(geoCodedDetails.address_components, {
        types: ['locality', 'political'],
      })
        ? _.find(geoCodedDetails.address_components, {
          types: ['locality', 'political'],
        }).short_name
        : ''
      details.streetName = _.find(geoCodedDetails.address_components, {
        types: ['route'],
      })
        ? _.find(geoCodedDetails.address_components, { types: ['route'] }).short_name
        : ''
      details.streetNumber = _.find(geoCodedDetails.address_components, {
        types: ['street_number'],
      })
        ? _.find(geoCodedDetails.address_components, { types: ['street_number'] })
          .short_name
        : ''

      if (details) {
        var lotNumber = details.lotNumber ?? '',
          streetName = details.streetName,
          streetNumber = details.streetNumber,
          subNumber = details.subNumber ?? '',
          suburb = details.suburb,
          municipality = details.municipality,
          state = details.state,
          postcode = details.postcode

        const fullAddressText =
          '' +
          lotNumber +
          ' ' +
          streetNumber +
          ' ' +
          streetName +
          ' ' +
          subNumber +
          ' ' +
          suburb +
          ', ' +
          municipality +
          ' ' +
          state +
          ' ' +
          postcode
        details.full = fullAddressText.replace(/ {1,}/g, ' ').trim()
        const addressArr = Object.keys(details)

        handleFieldChange('propertyInfo', null, 'address', details)
      }
    }
  }, [geoCodedDetails])

  const setType = () => {
    let ptype = ''
    propertyType.map((item, index) => {
      if (item.active) {
        ptype = item.title
      }
    })
    handleFieldChange('propertyInfo', null, 'propertyType', ptype)
  }

  const handlePropertyType = (data) => {
    setPropertyType(data)
  }

  const handlePropertyDetails = (data) => {
    setPropertyDetails(data)
  }

  const handlePropertyStatus = () => {
    const status = propertyStatus ? 'offmarket' : 'comingsoon'
    return status
  }

  const handleGooglePlaces = (data) => {
    setPropertyAddress(data)
  }

  return (
    <div className={classes.root}>
      <LogoWrap style={{ backgroundColor: hex }}>
        <img src={logo} />
      </LogoWrap>
      <ContentWrap>
        <HeadingContentSmall
          title={`Property Direct Upload Step 2 of ${(addPropertyFields &&
            addPropertyFields.propertyInfo.address.value.state !== 'VIC') ||
            addPropertyFields.propertyInfo.propertyStatus.value === 'offmarket'
            ? '3'
            : '4'
            }`}
          content={`Add your vendors’ property to PropertyMate`}
        />
      </ContentWrap>
      <FormWrap>
        <HeadingContentSmall title={'Property Information'} />
        <GoogleWrap>
          <GooglePlacesAutocomplete
            apiKey={GOOGLE_MAP_API_KEY}
            selectProps={{
              placeholder: 'Property Address',
              value: propertyAddress,
              onChange: handleGooglePlaces,
            }}
            autocompletionRequest={{
              componentRestrictions: { country: 'au' },
              fields: ['address_components', 'geometry'],
              types: ['address'],
            }}
          />
        </GoogleWrap>

        <CheckWrap>
          <CustomSwitch
            label={'Coming soon'}
            checked={propertyStatus}
            onChange={(e) => {
              setPropertyStatus(!propertyStatus)
            }}
          />
          <StyledText>Off Market</StyledText>
        </CheckWrap>

        <HeadingContentSmall title={'Select Property Type'} />
        <PropertyType
          handlePropertyType={handlePropertyType}
          // isClearFilter={props.isClearFilter}
          propertyType={propertyType}
          selectedPropertyType={addPropertyFields.propertyInfo.propertyType}
        />
        <SliderContainer
          handlePropertyDetails={handlePropertyDetails}
          // isClearFilter={props.isClearFilter}
          propertyDetails={propertyDetails}
          // path={props.path}
          hidePrice={hidePrice}
          setHidePrice={setHidePrice}
          price={price}
          selectedPropertyDetails={addPropertyFields.propertyInfo.propertyDetails.value}
        />
      </FormWrap>
      <FormField
        type={'textarea'}
        placeholder={'Property Description'}
        showError={false}
        value={addPropertyFields.propertyInfo.description.value}
        onChange={(e) => {
          const value = e.target.value
          setDescription(value)
          handleFieldChange('propertyInfo', null, 'description', value)
        }}
      />
      <ButtonWrap>
        <StyledButton
          color="primary"
          variant="contained"
          disabled={isDisabled}
          onClick={() => {
            setActiveStep(activeStep - 1)
            handleBackClick()
          }}
        >
          Back
        </StyledButton>
        <StyledButton
          color="primary"
          variant="contained"
          disabled={isDisabled}
          onClick={() => {
            setActiveStep(activeStep + 1)
          }}
        >
          Next
        </StyledButton>
      </ButtonWrap>
    </div>
  )
}

export default withStyles(styles)(DirectUploadStep2)
