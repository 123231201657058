import {
  VALIDATE_IMMEDIATELY, 
  VALIDATE_ON_BLUR, 
  VALIDATE_ON_CHANGE, 
  VALIDATE_ON_SUBMIT, 
  DO_VALIDATE 
} from "../constants/validation.constants";

export const validateOnBlur = (payload) => {  
  return {
    type: VALIDATE_ON_BLUR,
    payload
  };
}

export const validateImmediately = (payload) => {  
  return {
    type: VALIDATE_IMMEDIATELY,
    payload
  };
}

export const validateOnChange = (payload) => {
  return {
    type: VALIDATE_ON_CHANGE,
    payload
  };
}
export const validateOnSubmit = (payload) => {
  return {
    type: VALIDATE_ON_SUBMIT,
    payload
  };
}
export const doValidate = (payload) => {
  return {
    type: DO_VALIDATE,
    payload
  };
}