import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import registerServiceWorker from './registerServiceWorker';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';

import { CacheLoader } from './components/Common';
import MainApp from './App.Main';
import { store, persistor } from './store/store';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { HelmetProvider } from 'react-helmet-async';

const GTM_ID = process?.env?.REACT_APP_GOOGLE_TAG_MANAGER_ID;

const hasAppReloaded = () => {
  console.debug('Cache cleared, app is reloaded', 'APP_VERSION=>', version);
};

const gtmParams = {
  id: GTM_ID,
};

const helmetContext = {};

const AppWithProviders = (
  <CacheBuster
    currentVersion={version}
    isEnabled={true}
    isVerboseMode={false}
    loadingComponent={<CacheLoader />}
    onCacheClear={hasAppReloaded}
  >
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GTMProvider state={gtmParams}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <MainApp />
            </MuiPickersUtilsProvider>
          </GTMProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </CacheBuster>
);

const rootElement = document.getElementById('root');

ReactDOM.render(AppWithProviders, rootElement);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(AppWithProviders, rootElement);
} else {
  ReactDOM.render(AppWithProviders, rootElement);
}
