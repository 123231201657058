export const addressFormatter = (propertyType, addressDetails) => {
  const { subNumber, lotNumber, streetNumber, streetName, suburb, state, postcode } =
    addressDetails

  if (propertyType === 'Land') {
    return `${subNumber ? subNumber + '/' : ''}${
      lotNumber ? lotNumber + '/' : ''
    }${streetNumber} ${streetName} ${suburb?.toUpperCase()} ${state?.toUpperCase()} ${postcode}`
  } else {
    return `${
      subNumber ? subNumber + '/' : ''
    }${streetNumber} ${streetName} ${suburb?.toUpperCase()} ${state?.toUpperCase()} ${postcode}`
  }
}
