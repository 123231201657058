import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import PhoneIcon from '@material-ui/icons/Phone'
import MessageIcon from '@material-ui/icons/Message'
import EmailIcon from '@material-ui/icons/Email'
import ThumbsUp from '@material-ui/icons/ThumbUp'

import { Box, withStyles, Typography, IconButton, Button } from '@material-ui/core'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {},
  main: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  iconContainer: {
    marginRight: '0.313em',
    width: '0.625em',
    height: '0.625em',
  },
  icon: {
    color: '#000',
    width: '0.625em',
    height: '0.625em',
    marginRight: '0.213em',
  },
  customIconContainer: {
    marginRight: '0.313em',
    width: '0.525em',
    height: '0.525em',
  },
  customIcon: {
    color: '#000',
    width: '0.525em',
    height: '0.525em',
    marginRight: '0.213em',
  },
  pricing: {
    fontSize: 12,
    fontWeight: 500,
  },

  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.625em',
    padding: theme.spacing(1, 1.25),
  },
  rowContainerCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '0.625em',
    padding: theme.spacing(0, 0.66),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '0.625em',
  },
  callIcon: {
    color: '#000',
    width: '1.2em',
    height: '1.2em',
  },
  thumbsup: {
    color: '#000',
    width: '0.5em',
    height: '0.5em',
  },
  agentName: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '21px',
  },
  website: {
    textAlign: 'left',
    fontSize: 12,
    fontWeight: 400,
    color: '#000',
    cursor: 'pointer',
  },
  footerContainer: {
    position: 'relative',
    bottom: 0,
    width: '100%',
    height: 50,
    backgroundColor: '#F5F5F5',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowContainerFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  bottomRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  countLabel: {
    textAlign: 'left',
    padding: 0,
    margin: 0,
    fontSize: 10,
    fontWeight: 500,
    color: '#9B9B9B',
    marginRight: 5,
  },
  buttonOption: {
    margin: 5,
    padding: theme.spacing(1.25, 0.6),
  },
})

const AgentInfo = (props) => {
  const { classes, type, agent, executeScroll } = props
  const [isVisible, setIsVisible] = useState(false)
  const { getPropertyData, triggerAnalyticsEvent } = useGoogleEvents()

  const hasTelephone =
    agent.agent && agent.agent.telephone ? true : agent.phone ? true : false

  const telNum =
    agent.agent && agent.agent.telephone
      ? agent.agent.telephone
      : agent.agent.phone
      ? agent.agent.phone
      : null

  function renderAgentCard() {
    return (
      <div className={classes.root}>
        <div className={classes.main}>
          <div className={classes.rowContainer}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: agent.agent && agent.agent.name ? agent.agent.name : '',
              }}
              className={classes.agentName}
              variant="body2"
              component="p"
            ></Typography>
            <Typography className={classes.website} variant="body2" component="p">
              {agent.agent && agent.agent.website ? agent.agent.website : ''}
            </Typography>
          </div>

          <div className={classes.rowContainerCenter}>
            {hasTelephone && agent?.propertyStatus !== 'offmarket' ? (
              <Button
                href={isVisible ? `tel:${telNum}` : '#'}
                className={classes.buttonOption}
                startIcon={<PhoneIcon />}
                fullWidth
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault()
                  setIsVisible((prev) => !prev)
                  triggerAnalyticsEvent({
                    event: 'property_actions',
                    eventFrom: 'Web',
                    actionScope: 'property_agent_card',
                    actionName: 'Property Agent Card',
                    actionTarget: 'Clicked Agent Mobile Number',
                    actionReference: 'property',
                    ...getPropertyData(agent),
                  })
                }}
              >
                {isVisible ? telNum : 'Phone'}
              </Button>
            ) : null}
            <Button
              className={classes.buttonOption}
              startIcon={<EmailIcon />}
              fullWidth
              color={'primary'}
              variant="outlined"
              onClick={() => {
                executeScroll()
                triggerAnalyticsEvent({
                  event: 'property_actions',
                  eventFrom: 'Web',
                  actionScope: 'property_agent_card',
                  actionName: 'Property Agent Card',
                  actionTarget: 'Clicked Email Agent',
                  actionReference: 'property',
                  ...getPropertyData(agent),
                })
              }}
            >
              EMAIL
            </Button>
          </div>
        </div>
        <div className={classes.footerContainer}></div>
      </div>
    )
  }

  return <>{type === 'agent-card' && renderAgentCard()}</>
}

export default withStyles(styles)(AgentInfo)
