import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Snackybar, AuthImages } from 'components/Web/Superadmin'
import { AgencyLoginForm } from 'components/Web/Agency'

/** actions */
import { agencyv2Actions } from 'store/actions'
import { requestAPIUserLogin } from 'store/api'
import { getAuthentication } from 'store/selectors/agencyv2.selectors'
import { Loader } from 'components/Common'
import { clearLoginStates } from 'store/actions'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    height: '100vh',
    padding: 40,
    paddingLeft: '10vw',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const AgencyLogin = (props) => {
  const { classes } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()

  const AUTH = useSelector(getAuthentication)

  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  })

  useEffect(() => {
    handleClearLoginStates()

    return () => {
      handleClearLoginStates()
    }
  }, [])

  useEffect(() => {
    if (AUTH.login_status === 'progress') {
      setLoading(true)
    }
    if (AUTH.login_status === 'error') {
      setLoading(false)
      setShowMessage({
        open: true,
        type: 'error',
        message: 'Failed to log in user',
      })
    }
    if (AUTH.token) {
      routerChange('/agency/dashboard')
      setLoading(false)
    }
  }, [AUTH])

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    })
  }

  /** API Request */
  const handleAgencyLogin = (data) => {
    let request = {
      // data: {
      email: data.email,
      password: data.password,
      query: '?userRole=Agency',
      // }
    }
    dispatch(requestAPIUserLogin(request, 'AGENCY_LOGIN'))
  }

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'))
  }

  const handleClearLoginStates = () => {
    dispatch(clearLoginStates())
  }

  return (
    <>
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={5} className={classes.leftColumn}>
            <AgencyLoginForm

              handleSubmit={handleAgencyLogin}
              handleClear={handleClearStatus}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={7} className={classes.rightColumn}>
            <AuthImages type={'login'} />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default withStyles(styles)(AgencyLogin)
