import React from 'react';
import { grey } from "@material-ui/core/colors";
import { withStyles, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      height: '100%',
      width: '100%',
      position: 'absolute',
      zIndex: 99,
      top:0,
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zindex: 100,
        marginTop:'-60px'
    },
    headingText: {
        color: '#FFF',
        fontWeight: '400',
        fontSize: 16
    },
    contentText: {
        marginTop: theme.spacing(1),
        color: '#FFF',
        fontWeight: 'bold'
    }
});

const BackdropInstructions = props => {    
    const {
        classes
    } = props;

    const [showOverlay, setShowOverlay] = React.useState(true);

    return (
        <>
        {
            showOverlay &&
            <div className={classes.root} onClick={()=> setShowOverlay(false)}>
                <div className={classes.textContainer}>
                <Typography className={classes.headingText} variant="h6" component="p">
                    {'< Swipe right to add a property, '}
                </Typography>
                <Typography className={classes.headingText} variant="h6" component="p">
                    {'swipe left to delete a property >'}
                </Typography>
                <Typography className={classes.contentText} variant="body2" component="p">
                    {'Tap to continue'}
                </Typography>
                </div>
            </div>
        }
        </>
    );
}

export default (withStyles(styles)(BackdropInstructions));