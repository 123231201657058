import React from 'react';
import { Link } from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { withStyles, Button, Icon } from '@material-ui/core';

const styles = theme => ({
  root: {
  },
  button: {
    height: '2.250em',
    textTransform: 'capitalize',
    marginLeft: '0.313em',
    marginRight: '0.313em',
    borderRadius: '1.250em',
    whiteSpace: 'noWrap',
    fontWeight: 'normal',
    padding:'0px 15px',
    lineHeight:'2.250em'
  }
});

const ButtonSelection = props => {
  const { open, classes, data } = props;

  return (
    <>
        <Button
            className={classes.button} 
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={props.setShowModalOptions}>
            {
                (data.type === 'cost') ?
                    data.label + ' ' + data.selected
                :
                (data.type === 'propertyType') ?
                    data.label + ' ' + data.selected
                :
                    data.selected + ' ' + data.label
            }
        </Button>
    </>
  );
};

export default withStyles(styles)(ButtonSelection);
