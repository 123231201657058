import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function BathIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M5,.93A3.39,3.39,0,0,0,1.77,4.42v9H2.85v-9A2.23,2.23,0,0,1,5,2.1a2.18,2.18,0,0,1,2.1,1.74H8.24A3.34,3.34,0,0,0,5,.93ZM7.74,4.42A2.25,2.25,0,0,0,5.57,6.74H9.92A2.25,2.25,0,0,0,7.74,4.42ZM6.11,7.91a.56.56,0,0,0-.54.58v.58a.56.56,0,0,0,.54.58.57.57,0,0,0,.55-.58V8.49A.57.57,0,0,0,6.11,7.91Zm3.26,0a.56.56,0,0,0-.54.58v.58a.56.56,0,0,0,.54.58.57.57,0,0,0,.55-.58V8.49A.57.57,0,0,0,9.37,7.91Zm-1.63,2a.56.56,0,0,0-.54.58v.58a.56.56,0,0,0,.54.58.57.57,0,0,0,.55-.58v-.58A.57.57,0,0,0,7.74,9.94ZM.14,14a7,7,0,0,0,6.79,7.26H14A7,7,0,0,0,20.79,14Zm5.5,7.84a2,2,0,0,0,1.83,1.74h6a2,2,0,0,0,1.83-1.74Z"/>
    </SvgIcon>
  );
}

export function BathIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BathIcon />
      <BathIcon color="primary" />
      <BathIcon color="secondary" />
      <BathIcon color="action" />
      <BathIcon color="disabled" />
      <BathIcon style={{ color: green[500] }} />
    </div>
  );
}
