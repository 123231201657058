import React, { useRef, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Property from '../../components/Web/Property';
import PropertyLoader from '../../components/Mobile/Property/PropertyLoader';
import Loader from '../../components/Mobile/Loader';
import AlertNotification from '../../components/Mobile/Form/AlertNotification';

import SortSelection from '../../components/Web/Searchbar/SortSelection';

import { scrollToRef } from '../../components/Mobile/ScrollEvents';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Sticky from 'react-sticky-el';
import NotificationDialog from '../../components/Web/CustomDialogs/NotificationDialog';
import SearchMapView from '../../components/Web/Map/SearchMapViewV2';
import Searchbar from '../../components/Web/InlineSearchbar';

import _ from 'lodash';

/** api redux request */

/** api redux request */
import {
  requestPropertySave,
  requestGetPropertySave,
  requestPropertySaveSearch,
  requestPOSTAPISearch,
} from '../../store/api';

import { requestPUTAPIMpp } from 'store/api/mpp.api';

import {
  getProperties,
  getSavePropertyStatus,
  getSavePropertyError,
  getSkipTakeData,
  getSaveSearchStatus,
  getSaveSearchError,
  getSaveSearchSuccess,
  getSearchProperties,
} from 'store/selectors/search.selectors';

/** actions */
import { toggleSignUp, setCurrentProperty, searchActions } from '../../store/actions';

import { withStyles, Grid, Box } from '@material-ui/core';
import { getAuthentication } from 'store/selectors/authentication.selectors';
import { getSettings } from 'store/selectors/generic.selectors';
import { requestGETAPIMpp } from 'store/api/mpp.api';
import { getAllFilters, getSearchResults } from 'utils/search/selectors/selectors.search';
import { formatRequest } from 'utils/search/query/formatRequest';
import InlineSearchBarV2 from 'components/Web/InlineSearchbar/InlineSearchBarV2';
import { getPagination } from 'utils/search/selectors/selectors.search';
import { StorageActions } from 'store/actions/storage.actions';
import { getScrollPosition } from 'store/selectors/storage.selectors';
import useConvertUrlToFilters from 'utils/customHooks/useConvertUrlToFilters';

import useGoogleEvents from 'utils/customHooks/useGoogleEvents';
import { getFromInnerPage } from 'store/selectors/storage.selectors';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const getAd = importAll(require.context('../../assets/ads', false, /\.(png|jpe?g|gif)$/));

const styles = (theme) => ({
  root: {
    backgroundColor: '#f5f5f5',
    paddingLeft: 90,

    position: 'relative',
    '& .infinite-scroll-component': {
      overflowX: 'hidden !important',
    },
  },
  resultsWrap: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    justifyContent: 'center',
  },
  content: {
    backgroundColor: '#f5f5f5',
    paddingBottom: 14,
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
  adContainer: {
    marginTop: theme.spacing(3),
    maxWidth: 364,
  },
  adItem: {
    border: '0',
    height: '100%',
    '& .sticky': {
      top: '120px !important',
    },
  },
  adsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    width: '100%',
    '& a': {
      display: 'block',
    },
    '& .ads-desktop': {
      display: 'none',
      marginBottom: '20px',
    },
    '& .ads-ipad': {
      display: 'block',
      width: '100%',
    },
    '& .each-fade': {
      textAlign: 'center',
    },
    '& .each-fade img': {
      maxWidth: '80%',
    },
    [theme.breakpoints.up(1200)]: {
      '& .ads-desktop': {
        display: 'block',
      },
      '& .ads-ipad': {
        display: 'none',
      },
    },
  },
});

const CombinedViewSearch = (props) => {
  const { classes } = props;

  const topContainer = useRef(null);

  const executeTopScroll = (d) => scrollToRef(topContainer, d); //add `false` as second parameter to scroll without transition.

  const authentication = useSelector(getAuthentication);
  const settings = useSelector(getSettings);

  const save_property_status = useSelector(getSavePropertyStatus);
  const save_property_error = useSelector(getSavePropertyError);
  const skipTakeData = useSelector(getSkipTakeData);
  const save_search_status = useSelector(getSaveSearchStatus);
  const save_search_error = useSelector(getSaveSearchError);
  const save_search_success = useSelector(getSaveSearchSuccess);
  const searchProperties = useSelector(getSearchResults);
  const filters = useSelector(getAllFilters);
  const pagination = useSelector(getPagination);
  const scroll_position = useSelector(getScrollPosition);

  const dispatch = useDispatch();

  const handleSetCurrentProperty = (params) => dispatch(setCurrentProperty(params));
  const savePropertySearch = (params) => dispatch(requestPropertySaveSearch(params));
  const handleToggleSignUp = (checked) => dispatch(toggleSignUp(checked));
  const saveProperty = (params) => dispatch(requestPUTAPIMpp(params, 'PUT_MPP_FAVOURITES', 'REQUEST'));
  const getSavedProperties = () => dispatch(requestGETAPIMpp('', 'GET_MPP_FAVOURITES_IDS'));

  const [notificationStatus, setNotificationStatus] = useState({
    status: false,
    options: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSaveSearch, setShowSaveSearch] = useState(false);

  const [isFiltersFromUrl, setIsFiltersFromUrl] = useConvertUrlToFilters();

  const { getSearchData, triggerAnalyticsEvent } = useGoogleEvents();

  const relatedRoutes = ['off-market', 'hush-hush', 'coming-soon', 'buy'];

  const defaultPaginationObj = {
    pageNumber: 0,
    pageSize: 20,
    propertiesFilters: [],
    totalResults: null,
  };

  const getRoutePath = () => {
    return props.match ? props.match.path.split('/')[1] : '';
  };

  /** Show Save Search */
  function showSaveSearchModal(params) {
    setShowSaveSearch(params);
  }

  /** Save Property Search API */
  async function apiRequestSaveSearch(params) {
    setIsLoading(true);

    let path = getRoutePath();
    let filterParameters = await formatRequest(path, filters);
    let request = {
      name: params.name,
      searchType: path,
      parameters: filters,
    };

    savePropertySearch(request);
  }

  function checkLoginStatus() {
    if (authentication?.user_status !== 'logged') {
      handleToggleSignUp(!settings.showSignUp);
    } else {
      /** User Logged */
      showSaveSearchModal(true);
    }
  }

  const scrollerContainer = document.querySelector('#cards-scroll-column');

  React.useEffect(() => {
    if (scrollerContainer && scroll_position > 0) {
      scrollerContainer.scrollTop = scroll_position - 100;
    }
  }, [scrollerContainer]);

  React.useEffect(() => {
    /**
     * Initial Scroll to Top */
    executeTopScroll(false);

    window.onbeforeunload = () => {
      dispatch(StorageActions(null, 'GENERAL_SETTINGS', 'SET_FROM_INNERPAGE'));
      dispatch(StorageActions(0, 'GENERAL_SETTINGS', 'SET_SCROLL_POSITION'));
    };

    return () => {
      const scrollerContainer = document.querySelector('#cards-scroll-column');
      dispatch(StorageActions(scrollerContainer?.scrollTop, 'GENERAL_SETTINGS', 'SET_SCROLL_POSITION'));
    };
  }, []);

  React.useEffect(() => {
    if (searchProperties?.status === 'search/POST_PROPERTY_SEARCH_REQUEST') {
      setIsLoading(true);
    } else if (searchProperties?.status === 'search/POST_PROPERTY_SEARCH_SUCCESS') {
      setIsLoading(false);
      dispatch(searchActions(null, 'FUNNEL_DOWN_SEARCH', 'CLEAR'));
      dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'));

      triggerAnalyticsEvent({
        event: 'results_actions',
        eventFrom: 'Web',
        actionScope: 'search_results',
        actionName: 'Search Results',
        actionTarget: 'Showed search results from ' + getSearchData()?.search_reference,
        actionReference: 'results',
        ...getSearchData(),
        results_total: searchProperties?.search_results?.data?.totalResults,
      });
    }

    if (searchProperties?.status === 'search/PAGINATION_REFERENCE_UPDATE') {
      setIsLoading(false);
    }
  }, [searchProperties]);

  React.useEffect(() => {
    if (searchProperties?.search_results?.data) {
      handlePagination(searchProperties?.search_results?.data);
    }
    return () => {};
  }, [searchProperties?.search_results?.data]);

  React.useEffect(() => {
    if (searchProperties?.narrowed_results?.data) {
      handlePagination(searchProperties?.narrowed_results?.data);

      triggerAnalyticsEvent({
        event: 'results_actions',
        eventFrom: 'Web',
        actionScope: 'search_results',
        actionName: 'Search Results',
        actionTarget: 'Showed search results from ' + getSearchData()?.search_reference,
        actionReference: 'results',
        ...getSearchData(),
        results_total: searchProperties?.narrowed_results?.data?.totalResult,
        search_direction: searchProperties?.narrowed_results ? 'narrowed' : undefined,
      });
    }
    return () => {};
  }, [searchProperties?.narrowed_results?.data]);

  async function apiRequestSearchProperty() {}

  /** Save Property API MPP*/
  function apiRequestSaveProperty(id) {
    let params = {
      query: null,
      data: {
        propertyId: id,
      },
    };

    saveProperty(params);
  }

  const handleNotification = (status, options) => {
    setNotificationStatus({ status, options });
  };

  const closeNotification = () => {
    setNotificationStatus({ status: false, options: null });
  };

  const handlePagination = (customData) => {
    const nextPage = customData ? pagination?.pageNumber : pagination?.pageNumber + 1;
    const theSearchData = customData
      ? customData
      : searchProperties?.narrowed_results?.data ?? searchProperties?.search_results?.data;

    const filterData = pagination?.propertiesFilters.concat(
      paginate(theSearchData?.propertiesFilters ?? [], pagination?.pageSize, nextPage),
    );

    const pageData = isFiltersFromUrl
      ? {
          pageNumber: nextPage,
          pageSize: pagination?.pageSize,
          propertiesFilters: _.uniqBy(
            paginate(searchProperties?.search_results?.data?.propertiesFilters ?? [], pagination?.pageSize, nextPage),
            'property',
          ),
          totalResults: searchProperties?.search_results?.data?.totalResults,
        }
      : {
          pageNumber: nextPage,
          pageSize: pagination?.pageSize,
          propertiesFilters: _.uniqBy(filterData, 'property'),
          totalResults:
            searchProperties?.narrowed_results?.data?.totalResults > 0
              ? searchProperties?.narrowed_results?.data?.totalResults
              : pagination?.totalResults ?? searchProperties?.search_results?.data?.totalResults,
        };

    if (isFiltersFromUrl) {
      setIsFiltersFromUrl(false);
    }
    dispatch(searchActions(pageData, 'PAGINATION_REFERENCE', 'UPDATE'));
  };

  const paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size);
  };

  window.addEventListener('unload', function (e) {
    dispatch(searchActions(null, 'PAGINATION_REFERENCE', 'CLEAR'));
    dispatch(searchActions(null, 'GENERAL_SEARCH', 'RESET_STATES'));
  });

  return (
    <div className={classes.root}>
      <AlertNotification
        status={notificationStatus?.status}
        options={notificationStatus?.options}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />
      <div ref={topContainer} className={classes.topAnchor}></div>

      <Box paddingLeft="65px" paddingRight="24px" style={{ backgroundColor: 'white' }}>
        <InlineSearchBarV2 />
      </Box>

      <div className={classes.content}>
        <SearchMapView
          authentication={authentication}
          settings={settings}
          setCurrentProperty={handleSetCurrentProperty}
          save_property_status={save_property_status}
          save_property_error={save_property_error}
          getSavedProperties={getSavedProperties}
          skipTakeData={pagination ?? defaultPaginationObj}
          pagination={pagination ?? defaultPaginationObj}
          suburbs={filters?.suburbs}
          showSaveSearch={showSaveSearch}
          showSaveSearchModal={showSaveSearchModal}
          apiRequestSaveSearch={apiRequestSaveSearch}
          getRoutePath={getRoutePath}
          checkLoginStatus={checkLoginStatus}
          handlePagination={handlePagination}
          apiRequestSearchProperty={apiRequestSearchProperty}
          apiRequestSaveProperty={apiRequestSaveProperty}
          isLoading={isLoading}
          toggleSignUp={handleToggleSignUp}
          history={props.history}
          searchResponse={searchProperties}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(CombinedViewSearch);
