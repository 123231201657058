/** API REQUEST */
export const REQUEST_SAVE_PROPERTY = 'property/REQUEST_SAVE_PROPERTY'
export const REQUEST_SAVE_PROPERTY_SUCCESS = 'property/REQUEST_SAVE_PROPERTY_SUCCESS'
export const REQUEST_SAVE_PROPERTY_FAIL = 'property/REQUEST_SAVE_PROPERTY_FAIL'

export const REQUEST_GET_SAVE_PROPERTY = 'property/REQUEST_GET_SAVE_PROPERTY'
export const REQUEST_GET_SAVE_PROPERTY_SUCCESS =
  'property/REQUEST_GET_SAVE_PROPERTY_SUCCESS'
export const REQUEST_GET_SAVE_PROPERTY_FAIL = 'property/REQUEST_GET_SAVE_PROPERTY_FAIL'

export const REQUEST_SAVE_SEARCH = 'property/REQUEST_SAVE_SEARCH'
export const REQUEST_SAVE_SEARCH_SUCCESS = 'property/REQUEST_SAVE_SEARCH_SUCCESS'
export const REQUEST_SAVE_SEARCH_FAIL = 'property/REQUEST_SAVE_SEARCH_FAIL'

export const REQUEST_PROPERTY_SEARCH = 'property/REQUEST_PROPERTY_SEARCH'
export const REQUEST_PROPERTY_SEARCH_SUCCESS =
  'property/REQUEST_PROPERTY_SEARCH_SUCCESS'
export const REQUEST_PROPERTY_SEARCH_FAIL = 'property/REQUEST_PROPERTY_SEARCH_FAIL'

export const REQUEST_PROPERTY_SEARCH_SOLD = 'property/REQUEST_PROPERTY_SEARCH_SOLD'
export const REQUEST_PROPERTY_SEARCH_SOLD_SUCCESS =
  'property/REQUEST_PROPERTY_SEARCH_SOLD_SUCCESS'
export const REQUEST_PROPERTY_SEARCH_SOLD_FAIL =
  'property/REQUEST_PROPERTY_SEARCH_SOLD_FAIL'

export const REQUEST_PROPERTY_SEARCH_RENTAL = 'property/REQUEST_PROPERTY_SEARCH_RENTAL'
export const REQUEST_PROPERTY_SEARCH_RENTAL_SUCCESS =
  'property/REQUEST_PROPERTY_SEARCH_RENTAL_SUCCESS'
export const REQUEST_PROPERTY_SEARCH_RENTAL_FAIL =
  'property/REQUEST_PROPERTY_SEARCH_RENTAL_FAIL'

export const REQUEST_PROPERTY_DETAILS = 'property/REQUEST_PROPERTY_DETAILS'
export const REQUEST_PROPERTY_DETAILS_SUCCESS =
  'property/REQUEST_PROPERTY_DETAILS_SUCCESS'
export const REQUEST_PROPERTY_DETAILS_FAIL = 'property/REQUEST_PROPERTY_DETAILS_FAIL'
export const PROPERTY_DETAILS_CLEAR = 'property/PROPERTY_DETAILS_CLEAR'

export const REQUEST_PROPERTY_EDIT = 'property/REQUEST_PROPERTY_EDIT'
export const REQUEST_PROPERTY_EDIT_SUCCESS = 'property/REQUEST_PROPERTY_EDIT_SUCCESS'
export const REQUEST_PROPERTY_EDIT_FAIL = 'property/REQUEST_PROPERTY_EDIT_FAIL'

export const SEND_AGENT_CONTACT_INQUIRY = 'property/SEND_AGENT_CONTACT_INQUIRY'
export const SEND_AGENT_CONTACT_INQUIRY_SUCCESS =
  'property/SEND_AGENT_CONTACT_INQUIRY_SUCCESS'
export const SEND_AGENT_CONTACT_INQUIRY_FAIL =
  'property/SEND_AGENT_CONTACT_INQUIRY_FAIL'
export const CLEAR_AGENT_CONTACT_INQUIRY = 'property/CLEAR_AGENT_CONTACT_INQUIRY'

export const CLEAR_INQUIRY_DATA = 'property/CLEAR_INQUIRY_DATA,'
export const CLEAR_FAVORITE_STATUS = 'property/CLEAR_FAVORITE_STATUS,'

export const REQUEST_LIKE_PROPERTIES = 'property/REQUEST_LIKE_PROPERTIES'
export const REQUEST_LIKE_PROPERTIES_SUCCESS =
  'property/REQUEST_LIKE_PROPERTIES_SUCCESS'
export const REQUEST_LIKE_PROPERTIES_FAIL = 'property/REQUEST_LIKE_PROPERTIES_FAIL'
export const CLEAR_LIKE_PROPERTIES = 'property/CLEAR_LIKE_PROPERTIES'

export const REQUEST_DISLIKE_PROPERTIES = 'property/REQUEST_DISLIKE_PROPERTIES'
export const REQUEST_DISLIKE_PROPERTIES_SUCCESS =
  'property/REQUEST_DISLIKE_PROPERTIES_SUCCESS'
export const REQUEST_DISLIKE_PROPERTIES_FAIL =
  'property/REQUEST_DISLIKE_PROPERTIES_FAIL'

export const REQUEST_SEARCH_PERFECT_PROPERTY =
  'property/REQUEST_SEARCH_PERFECT_PROPERTY'
export const REQUEST_SEARCH_PERFECT_PROPERTY_SUCCESS =
  'property/REQUEST_SEARCH_PERFECT_PROPERTY_SUCCESS'
export const REQUEST_SEARCH_PERFECT_PROPERTY_FAIL =
  'property/REQUEST_SEARCH_PERFECT_PROPERTY_FAIL'

export const REQUEST_PROPERTY_SUGGESTIONS = 'property/REQUEST_PROPERTY_SUGGESTIONS'
export const REQUEST_PROPERTY_SUGGESTIONS_SUCCESS =
  'property/REQUEST_PROPERTY_SUGGESTIONS_SUCCESS'
export const REQUEST_PROPERTY_SUGGESTIONS_FAIL =
  'property/REQUEST_PROPERTY_SUGGESTIONS_FAIL'
export const CLEAR_PROPERTY_SUGGESTIONS = 'property/CLEAR_PROPERTY_SUGGESTIONS'

export const SEND_LIKES_DISLIKES_PROPERTIES = 'property/SEND_LIKES_DISLIKES_PROPERTIES'
export const SEND_LIKES_DISLIKES_PROPERTIES_SUCCESS =
  'property/SEND_LIKES_DISLIKES_PROPERTIES_SUCCESS'
export const SEND_LIKES_DISLIKES_PROPERTIES_FAIL =
  'property/SEND_LIKES_DISLIKES_PROPERTIES_FAIL'

export const SEND_PERFECT_PROPERTY_FILTERS = 'property/SEND_PERFECT_PROPERTY_FILTERS'
export const SEND_PERFECT_PROPERTY_FILTERS_SUCCESS =
  'property/SEND_PERFECT_PROPERTY_FILTERS_SUCCESS'
export const SEND_PERFECT_PROPERTY_FILTERS_FAIL =
  'property/SEND_PERFECT_PROPERTY_FILTERS_FAIL'

export const SEND_SEARCH_TALLIES = 'property/SEND_SEARCH_TALLIES'
export const SEND_SEARCH_TALLIES_SUCCESS = 'property/SEND_SEARCH_TALLIES_SUCCESS'
export const SEND_SEARCH_TALLIES_FAIL = 'property/SEND_SEARCH_TALLIES_FAIL'

/** LOCAL DATA */
export const SET_CURRENT_SELECTED_PROPERTY = 'property/SET_CURRENT_SELECTED_PROPERTY'
export const SET_PERFECT_PROPERTIES = 'property/SET_PERFECT_PROPERTIES'

export const CLEAR_PERFECT_PROPERTIES = 'property/CLEAR_PERFECT_PROPERTIES'
export const CLEAR_CURRENT_SELECTED_PROPERTY =
  'property/CLEAR_CURRENT_SELECTED_PROPERTY'

export const ADD_LIKED_PROPERTIES = 'property/ADD_LIKED_PROPERTIES'
export const ADD_UNLIKED_PROPERTIES = 'property/ADD_UNLIKED_PROPERTIES'
export const ADD_LIKED_DRILL_DOWN_PROPERTIES =
  'property/ADD_LIKED_DRILL_DOWN_PROPERTIES'
export const ADD_UNLIKED_DRILL_DOWN_PROPERTIES =
  'property/ADD_UNLIKED_DRILL_DOWN_PROPERTIES'

export const CLEAR_LIKED_AND_UNLIKED_PROPERTIES =
  'property/CLEAR_LIKED_AND_UNLIKED_PROPERTIES'

export const CLEAR_PROPERTY_PAYLOAD = 'property/CLEAR_PROPERTY_PAYLOAD'

export const REQUEST_SAVED_SEARCH_DATA = 'property/REQUEST_SAVED_SEARCH_DATA'
export const REQUEST_SAVED_SEARCH_DATA_SUCCESS =
  'property/REQUEST_SAVED_SEARCH_DATA_SUCCESS'
export const REQUEST_SAVED_SEARCH_DATA_FAIL = 'property/REQUEST_SAVED_SEARCH_DATA_FAIL'

export const REQUEST_DELETE_SAVED_SEARCH = 'property/REQUEST_DELETE_SAVED_SEARCH'
export const REQUEST_DELETE_SAVED_SEARCH_SUCCESS =
  'property/REQUEST_DELETE_SAVED_SEARCH_SUCCESS'
export const REQUEST_DELETE_SAVED_SEARCH_FAIL =
  'property/REQUEST_DELETE_SAVED_SEARCH_FAIL'

export const REQUEST_PUT_SAVED_SEARCH = 'property/REQUEST_PUT_SAVED_SEARCH'
export const REQUEST_PUT_SAVED_SEARCH_SUCCESS =
  'property/REQUEST_PUT_SAVED_SEARCH_SUCCESS'
export const REQUEST_PUT_SAVED_SEARCH_FAIL =
  'property/REQUEST_PUT_SAVED_SEARCH_FAIL'

export const REQUEST_FAVORITE_PROPERTIES = 'property/REQUEST_FAVORITE_PROPERTIES'
export const REQUEST_FAVORITE_PROPERTIES_SUCCESS =
  'property/REQUEST_FAVORITE_PROPERTIES_SUCCESS'
export const REQUEST_FAVORITE_PROPERTIES_FAIL =
  'property/REQUEST_FAVORITE_PROPERTIES_FAIL'

export const REQUEST_DREAM_PROPERTIES = 'property/REQUEST_DREAM_PROPERTIES'
export const REQUEST_DREAM_PROPERTIES_SUCCESS =
  'property/REQUEST_DREAM_PROPERTIES_SUCCESS'
export const REQUEST_DREAM_PROPERTIES_FAIL = 'property/REQUEST_DREAM_PROPERTIES_FAIL'

/** VENDOR OTP */
export const REQUEST_VENDOR_OTP = 'property/REQUEST_VENDOR_OTP'
export const REQUEST_VENDOR_OTP_SUCCESS = 'property/REQUEST_VENDOR_OTP_SUCCESS'
export const REQUEST_VENDOR_OTP_FAIL = 'property/REQUEST_VENDOR_OTP_FAIL'

// CLEAR
export const REQUEST_SAVED_SEARCH_CLEAR = 'property/REQUEST_SAVED_SEARCH_CLEAR'
