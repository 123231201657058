import { Box, Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    backgroundColor: '#F5F5F5',
    paddingLeft: 106,
    paddingRight: theme.spacing(2),
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      width: '100%',
    },
  },
  cardRows: {
    width: '100%',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(3),
  },

  statusWrap: {
    textAlign: 'center',
    minHeight: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const AgencySignUpStatus = ({ history }) => {
  const classes = useStyles()

  const params = new URLSearchParams(history?.location?.search)
  const regStatus = params.get('reg_status')

  const [isSuccess, setIsSuccess] = useState()

  useEffect(() => {
    if (regStatus) {
      if (regStatus === 'success') {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
      }
    }
  }, [])

  return (
    <Box className={classes.root}>
      <Card className={classes.cardRows} elevation={1}>
        <CardContent>
          <div className={classes.statusWrap}>
            {isSuccess ? (
              <div>
                <CheckCircle color={'primary'} fontSize={'large'} />
                <Typography variant={'h5'}>Nice work</Typography>
                <Typography variant={'body1'}>
                  You&rsquo;ve done the hard yards, now it&rsquo;s our turn.
                </Typography>
                {/*                 <Typography variant={'body1'}>
                  Keep an eye on your inbox, confirmation of your PropertyMate
                  registration is imminent.
                </Typography> */}
                <Typography variant={'body1'}>
                  We'll be in touch soon to ask a few more questions and finalise your
                  account.
                </Typography>
              </div>
            ) : (
              <div>
                <Typography variant={'h5'}>Error encountered</Typography>
                <Typography variant={'body1'}>
                  There seems to be a problem on selecting a package for your account
                </Typography>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  )
}

export default AgencySignUpStatus
