import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';
import DynamicIcons from "../DynamicIcons";

import { GoogleLogin } from '@dump-work/react-google-login';

import { withStyles, Button, Typography } from '@material-ui/core';

const staticVar = {
    facebookAppID: process.env.REACT_APP_FACEBOOK_APP_ID,
    googleAppID: process.env.REACT_APP_GOOGLE_APP_ID,
}

const buttonStyle = {
    size:'large',
    borderColor:'#000',
    textColor:'#000',
    colorTheme:'primary'
  }

const StyledButton = withStyles({
  root: {
    borderColor:buttonStyle.borderColor,
    display:'flex',
    padding:'11px 12px',
    width:'100%',
    borderRadius:30,
    position:'relative',
    textTransform:'initial',
    fontWeight:400

  },
  startIcon: {
    position:'absolute',
    left:30
  }
})(Button);

const ButtonWrap = styled.div`
  padding:8px 0;
`

const GoogleAuth = props => {

    function handleSuccess(data){
        let params = {
            _profile: data.profileObj,
            _provider: 'google',
            _token: data.tokenObj
        }
        props.callback(params);
    }

    function handleFailure(data){
        props.callback(data);
    }

    function renderGoogleButton(value){
        return(
            <GoogleLogin
                clientId={staticVar.googleAppID}
                render={renderProps => (
                    <ButtonWrap>
                        <StyledButton
                        variant={'outlined'}
                        size={buttonStyle.size}
                        startIcon={props.icon}
                        onClick={renderProps.onClick}>
                        <Typography color={buttonStyle.colorTheme} style={{color:buttonStyle.textColor, fontSize:'12px'}}>{props.label}</Typography>
                        </StyledButton>
                    </ButtonWrap>
                )}
                buttonText=""
                onSuccess={handleSuccess}
                onFailure={handleFailure}
                cookiePolicy={'single_host_origin'}
            />
        )
    }

    return renderGoogleButton(props)
}

GoogleAuth.propTypes={
    icon: PropTypes.object,
    label: PropTypes.string,
    type: PropTypes.string,
}

export default GoogleAuth;
