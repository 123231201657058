import React from 'react'

import styled from 'styled-components'
import InputMask from 'react-input-mask'

import DynamicIcons from '../DynamicIcons'

import { FormControl, InputBase, FormHelperText, withStyles } from '@material-ui/core';

const StyledFormControl = withStyles({
  root: {
    display: 'block',
    width: '100%',
    padding: '0 0 18px',
    position: 'relative',
  },
})(FormControl)

const StyledInput = withStyles((theme) => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '&.Mui-error input': {
      color: '#EB4335',
      borderColor: '#EB4335',
      paddingRight: '40px',
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: '100%',
    padding: '10px',
    height: '34px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
      backgroundColor: '#f5f5f5',
    },
  },
  multiline: {
    '& textarea': {
      minHeight: 150,
    },
  },
}))(InputBase)

const StyledErrorMessage = withStyles({
  root: {
    position: 'absolute',
    top: '-21px',
    left: '0',
    fontSize: '12px',
    color: '#EB4335',
    lineHeight: '24px',
    letterSpacing: '0.09px',
  },
})(FormHelperText)

const IconWrap = styled.div`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
`

const StyledWrap = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

const FormField = (props) => {
  const {
    type,
    value,
    showError,
    errorMsg,
    placeholder,
    onChange,
    onBlur,
    onKeyUp,
    onKeyPress,
    id,
    rowsMin,
    style,
    keyid,
    disabled,
    name,
    telformat,
    maxlength,
    autocomplete,
  } = props

  const handleChange = (event, newValue) => {
    if (onChange) {
      if (type === 'tel') {
        onChange(event, parseInt(newValue))
      } else {
        onChange(event, newValue)
      }
    }
  }

  const handleBlur = (event, newValue) => {
    if (onBlur) {
      onBlur(event, newValue)
    }
  }

  const handleKeyUp = (event, newValue) => {
    if (onKeyUp) {
      onKeyUp(event, newValue)
    }
  }

  const handleKeyPress = (event, newValue) => {
    if (onKeyPress) {
      onKeyPress(event, newValue)
    }
  }

  return (
    <StyledWrap style={style}>
      <StyledFormControl>
        {type !== 'tel' && type !== 'textarea' && (
          <StyledInput
            type={type}
            //defaultValue={value}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            onKeyPress={handleKeyPress}
            error={showError}
            key={keyid}
            disabled={disabled}
            name={name}
            autoComplete="autocomplete_off_hack_xfr4!k"
            inputProps={{ maxLength: maxlength, autoComplete: autocomplete }}
          />
        )}
        {type === 'textarea' && (
          <StyledInput
            type={type}
            //defaultValue={value}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            onKeyPress={handleKeyPress}
            error={showError}
            multiline={true}
            disabled={disabled}
            name={name}
            inputProps={{ maxLength: maxlength, autoComplete: autocomplete }}
          />
        )}

        {type === 'tel' && (
          <InputMask
            mask={telformat ? telformat : '9999999999'}
            maskPlaceholder={null}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            onKeyPress={handleKeyPress}
            name={name}
            disabled={disabled}
          >
            <StyledInput
              type={type}
              autoComplete="autocomplete_off_hack_xfr4!k"
              value={value}
              name={name}
              placeholder={placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyUp={handleKeyUp}
              onKeyPress={handleKeyPress}
              error={showError}
              disabled={disabled}
              inputProps={{ maxLength: maxlength }}
            />
          </InputMask>
        )}
        {showError && <StyledErrorMessage>{errorMsg}</StyledErrorMessage>}
        {showError && (
          <IconWrap>
            <DynamicIcons icon={'2DCErrorIcon'} />
          </IconWrap>
        )}
      </StyledFormControl>
    </StyledWrap>
  )
}

export default FormField
