import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import RangeSlider from '../../Web/Filter/RangeSlider'
import useFilters from 'utils/customHooks/useFilters'

const Container = styled.div`
  padding: 20px 0;
`

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
  .slider-Bedrooms {
    .MuiSlider-markLabel[data-index='0'] {
    }
  }
`

const marksBedroomExact = [
  {
    value: 0,
    label: 'Studio',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
]

const marksDefault = [
  {
    value: 0,
    label: 'All',
  },
  {
    value: 1,
    label: '1+',
  },
  {
    value: 2,
    label: '2+',
  },
  {
    value: 3,
    label: '3+',
  },
  {
    value: 4,
    label: '4+',
  },
  {
    value: 5,
    label: '5+',
  },
]

const Bedrooms = () => {
  const [getFilter, setFilter] = useFilters()
  const BEDROOMS = getFilter('bedroom')

  const handleBedroomsChange = (event, newValue, isExact, exactChange) => {
    if ((isExact !== undefined || isExact !== null) && exactChange === true) {
      setFilter({
        key: 'bedroom',
        value: { ...BEDROOMS, exact: isExact },
      })
    } else {
      setFilter({
        key: 'bedroom',
        value: { ...BEDROOMS, value: newValue },
      })
    }
  }

  return (
    <SliderItem>
      <RangeSlider
        label="Bedrooms"
        icon="Hotel"
        value={(BEDROOMS && BEDROOMS.value) ?? 0}
        handleChange={handleBedroomsChange}
        step={1}
        marks={BEDROOMS && BEDROOMS.exact ? marksBedroomExact : marksDefault}
        min={0}
        max={5}
        isExact={BEDROOMS && BEDROOMS.exact}
        enableExactControls={true}
      />
    </SliderItem>
  )
}

export default Bedrooms
