import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import Filter from '../../components/Web/Filter'
import Heading from '../../components/Web/Filter/Heading'
import Loader from '../../components/Web/Loader'
import AlertNotification from '../../components/Web/Form/AlertNotification'
import DynamicIcons from '../../components/Web/DynamicIcons'

/** api redux request */
import { requestSearchPerfectProperty } from '../../store/api'

/** actions */
import {
  setPropertySearchFilters,
  clearFilters,
  setPropertySuburbSearch,
  clearSuburbSearch,
  setIncludeNearby,
  setPerfectPropertyFilters,
  clearPerfectPropertyFilters,
  clearLikedUnlikedProperties,
  setTypesCounters,
  clearTypesCounters,
  setReferenceFilters,
  clearReferenceFilters,
  FiltersActions,
} from '../../store/actions'

/** Object Filters */
import { formatRequest, paramsToSha1 } from '../../utils/search.utils'

import { Typography, Button, withStyles } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { useSearch } from 'utils/search/useSearch'
import {
  getAllFilters,
  getSearchResults,
} from 'utils/search/selectors/selectors.search'
import { useDispatch } from 'react-redux'
import ClearFiltersButton from 'components/Common/GlobalFilters/ClearFiltersButton'

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 45,
  },
  content: {
    height: '100%',
    paddingBottom: '30px',
  },

  applyFiltersButton: {
    borderRadius: '30px',
    backgroundColor: '#6DC5A1',
    marginLeft: '20px',
    textTransform: 'none',
    color: 'white',

    '&:hover': {
      backgroundColor: '#62b190',
    },
  },
})

const Header = styled.div`
  position: relative;
  padding: 10px 23px 15px;
`

const HeaderNav = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
`

const BackButton = styled.a`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  letter-spacing: 0.073125px;
  display: flex;
  align-items: center;
  margin-left: -10px;
  cursor: pointer;
`

const ClearButton = styled.a`
  text-decoration: underline;
  color: #000;
  font-size: 13px;
  letter-spacing: 0.073125px;
  cursor: pointer;
`

const ButtonWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 90px;
  max-width: calc(573px);
  width: 100%;
  background-color: #fff;
  padding: 10px 35px 10px 15px;
  box-shadow: 0px -8px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  align-center: center;
  justify-content: end;
  z-index: 9;

  &.filter-button {
    left: -573px;
    transition: left 0.7s ease;
  }
`

const FiltersPerfect = (props) => {
  const {
    classes,
    filters,
    property,
    setSearchFilters,
    clearFiltersState,
    searchPerfectProperty,
    setPerfectPropertyFilter,
    setTypesCount,
    typeCounters,
    clearTypeCount,
    referenceFilters,
    saveReferenceFilters,
    removeReferenceFilters,
    filterScope,
  } = props

  const [filter, setFilter] = useState(null)
  const [isClearFilter, setIsClearFilter] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationOptions, setNotificationOptions] = useState(null)
  const [propertyTypesCount, setPropertyTypesCount] = useState(null)
  const [initiateSearch] = useSearch()
  //const filters = useSelector(getAllFilters)
  // console.debug('data', searchProperties?.search_results?.data)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      setIsLoading(false)
      setPropertyTypesCount(null)
    }
  }, [])

  useEffect(() => {
    if (
      filters &&
      referenceFilters &&
      JSON.stringify(filters) !== JSON.stringify(referenceFilters.filters)
    ) {
      setPropertyTypesCount(null)
    }

    if (filterScope && referenceFilters && referenceFilters.scope !== filterScope) {
      setPropertyTypesCount(null)
    }
  }, [filters, filterScope, referenceFilters])

  useEffect(() => {
    if (typeCounters) {
      setPropertyTypesCount(typeCounters)
    } else {
      setPropertyTypesCount(null)
    }
  }, [typeCounters])

  async function actionSetTypesCount(params) {
    if (params) {
      setTypesCount(params)
    }
  }

  async function actionSaveReferenceFilters(params, reference) {
    if (params && reference) {
      saveReferenceFilters(params, reference)
    }
  }

  const handleFilter = (obj) => {
    if (obj) {
      setFilter(obj)
      if (JSON.stringify(obj) !== JSON.stringify(filter)) {
        saveFilters(obj)
      }
    }
  }

  function saveFilters(params) {
    setSearchFilters(params)
    if (params && params.perfectProperty) {
      setPerfectPropertyFilter(params.perfectProperty)
    }
  }

  function resetFilters() {
    clearFiltersState()
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const applyFilter = () => {
    initiateSearch()
  }

  const handleClearFilter = () => {
    dispatch(
      FiltersActions(
        null,
        'GENERAL_FILTERS',
        'CLEAR_ALL_EXCEPT_SEARCH_REFERENCE_AND_SUBURBS'
      )
    )
  }

  const clearSelectedFilters = (e) => {
    e.preventDefault()
    handleClearFilter()
  }

  return (
    <div className={classes.root}>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />

      <Header>
        <Heading variant="h6" style={{ fontWeight: 400 }}>
          Find My Perfect Property
        </Heading>
        <Heading variant="body1" style={{ fontWeight: 400, color: '#4d4d4d' }}>
          Enter your lifestyle needs to discover perfect properties beyond where you
          know.
        </Heading>
      </Header>

      <div className={classes.content}>
        <Filter
          handleFilter={handleFilter}
          isClearFilter={isClearFilter}
          saveFilters={saveFilters}
          resetFilters={resetFilters}
          filters={filters}
          filterType={'perfect-property'}
          propertyTypesCount={propertyTypesCount}
        />
      </div>

      <ButtonWrap className="filter-button">
        <ClearFiltersButton type="mpp" onClick={clearSelectedFilters} />
        <Button
          className={classes.applyFiltersButton}
          variant="contained"
          onClick={applyFilter}
        >
          Apply Filters
        </Button>
      </ButtonWrap>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    filters: state.filters,
    property: state.property,
    typeCounters: state.referenceFilters.typesCounters,
    referenceFilters: state.referenceFilters,
    filterScope: state.settings.showFilterWeb.type,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSearchFilters: (params) => setPropertySearchFilters(params),
      clearFiltersState: (params) => clearFilters(params),
      searchPerfectProperty: (params) => requestSearchPerfectProperty(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      setPerfectPropertyFilter: (params) => setPerfectPropertyFilters(params),
      clearPerfectProperty: () => clearPerfectPropertyFilters(),
      clearLikeAndUnlike: () => clearLikedUnlikedProperties(),
      setTypesCount: (params) => setTypesCounters(params),
      clearTypeCount: () => clearTypesCounters(),
      saveReferenceFilters: (params, reference) =>
        setReferenceFilters(params, reference),
      removeReferenceFilters: () => clearReferenceFilters(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FiltersPerfect))
