import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
/** actions */
import {
  superadminActions
} from "store/actions";

/** api redux request */
import {
  requestPOSTAPISuperadmin,
  requestGETAPISuperadmin
} from "store/api";

import { getUser, getAgency, getErrors, getStatus } from 'store/selectors/superadmin.selectors'
import { SuperBreadCrumbs, BulkResults } from 'components/Web/Superadmin'

import { get } from 'lodash'
import AlertNotification from "components/Web/Form/AlertNotification";
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
    overflow: 'hidden',
  },
})

const SuperadminBulkResults = (props) => {
  const { classes, history, match } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()
  const AGENCY = useSelector(getAgency)
  const STATUS = useSelector(getStatus)

  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }
  const [agentsArr, setAgentsArr] = useState([]);

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Manage Agencies',
      path: '/superadmin/manage-agencies',
    },
    {
      type: 'secondary',
      label: 'Manage Agency Information',
      path: '/superadmin/manage-agencies/' + match.params.id + '/agents',
    },
    {
      type: 'primary',
      label: 'Bulk Upload Agents',
      path: '',
    },
  ]

  useEffect(() => {

    handleGetAgencyData()

    return () => {
      handleClearData()
    }
  }, [])

  const handleGetAgencyData = () => {
    let request = {
      query: '/' + match.params.id,
      data: {},
    }
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_AGENCY_DATA'))
  }

  useEffect(() => {
    getAgents();
  }, [props]);

  useEffect(() => {
    if (STATUS === "superadmin/SUPERADMIN_ADD_SINGLE_AGENT_SUCCESS") {

      const options = {
        status: true,
        options: {
          severity: 'success',
          message: "Successfully added agents.",
          onClose: () => setTimeout(() => goBack(), 3000)
        }
      }
      setIsNotification(options)
    } else if (STATUS === "superadmin/SUPERADMIN_ADD_SINGLE_AGENT_FAILED") {

      const options = {
        status: true,
        options: {
          severity: 'error',
          message: "Error adding agents. Please try again.",
          autoHideDuration: 4000,
        }
      }
      setIsNotification(options)
    } else if (STATUS === "superadmin/SUPERADMIN_ADD_SINGLE_AGENT_REQUEST") {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: "Adding agents...",
          autoHideDuration: 4000,
        }
      }
      setIsNotification(options)
    }
  }, [STATUS]);

  function goBack() {
    routerChange('back')
  }

  const getAgents = () => {
    if (props.location && props.location.state) {
      setAgentsArr(get(props.location.state, "agents"));
    }
  };

  /**
 * ? Redux Localized Actions
 * */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  }

  const handleClearData = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'RESET_STATES'))
  }

  const handleClearNotices = () => {
    handleClearStatus()
    handleClearError()
    setIsNotification(defaultNotification)
  }

  const handleNewAgent = (data) => {

    const params = {
      query: null,
      data: {
        reaAgencyId: AGENCY?.data?.entity?.reaAgencyId,
        agentList: data
      }
    }
    if (AGENCY) {
      dispatch(requestPOSTAPISuperadmin(params, 'SUPERADMIN_ADD_SINGLE_AGENT'))
    } else {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: "Error, unknown Agency ID. Please logout and log in again.",
          autoHideDuration: 4000,
        }
      }
      setIsNotification(options)
    }
  }

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs
        section={'Bulk Upload Agents'}
        crumbsLinks={CRUMBS}

      />
      <BulkResults
        goBack={goBack}
        submitCallback={handleNewAgent}
        agents={agentsArr}
      />
      {
        isNotification.status &&
        <AlertNotification
          status={isNotification.status}
          options={isNotification.options}
          closeNotification={handleNotificationClose}

        />
      }
    </div>
  )
}
export default withStyles(styles)(SuperadminBulkResults)
