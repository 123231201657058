import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function PIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.90739 10.0062V10.0066H4.41463V6.72304L7.16666 4.83036L9.90739 6.71572V10.0062ZM7.07143 0.900391C3.17316 0.900391 0.0120538 3.96579 0.000753362 7.75077L0 7.75004V18.9004C0.0621523 18.8664 4.21845 17.5256 4.41319 14.122C5.24792 14.4591 6.11127 14.6425 7.07143 14.6425C10.9769 14.6425 14.1429 11.5665 14.1429 7.77163C14.1429 3.97677 10.9769 0.900391 7.07143 0.900391Z" />
    </SvgIcon>
  );
}

export function PIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PIcon />
      <PIcon color="primary" />
      <PIcon color="secondary" />
      <PIcon color="action" />
      <PIcon color="disabled" />
      <PIcon style={{ color: green[500] }} />
    </div>
  );
}
