export const compareMinMax = (current, reference) => {

    switch (true) {
        case current?.min < reference?.min ||
            current?.max > reference?.max ||
            current?.min > reference?.max ||
            current?.max < reference?.min:
            return "widened"

        case (current?.min > reference?.min &&
            current?.min <= reference?.max) ||
            (current?.max < reference?.max &&
                current?.max >= reference?.min):
            return "narrowed"

        default:
            return null
    }

}