import React from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '@material-ui/icons/Close'
import DynamicIcons from '../../../components/Web/DynamicIcons'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  toggleThemeMode,
  toggleSignUp,
  navigateReset,
  logoutRequest,
  toggleForgotPassword,
} from '../../../store/actions'

import { Paper, AppBar, Container, Button, Link, Typography, IconButton, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    // padding: 0,
    // paddingTop: theme.spacing(2),
    margin: theme.spacing(7),
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingBottom: theme.spacing(3),
    borderRadius: 38,
    maxWidth: 370,
    '&:hover': {
      outline: 'none',
    },
    overflow: 'hidden',
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
    boxShadow: 'none',
  },
  container: {
    padding: 0,
  },
  profileContainer: {
    padding: '30px 24px 50px',
  },
  avatar: {},
  formWrapper: {
    padding: theme.spacing(0, 5),
  },
  closeicon: {
    position: 'absolute',
    top: 20,
    right: theme.spacing(2.5),
  },
  heading: {
    fontSize: 21,
    letterSpacing: -0.52,
    fontWeight: 300,
    lineHeight: 1.1,
    marginBottom: theme.spacing(3),
  },
  ctawrap: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& button': {
      borderRadius: 20,
      textTransform: 'capitalize',
      fontSize: 13,
    },
  },
})

const MainHeader = withStyles({
  root: {
    boxShadow: 'none',
    padding: '10px 0px',
    position: 'relative',
  },
})(AppBar)

/* const TabHeader = withStyles({
  root: {
    justifyContent:'space-evenly',
    padding:'0 21px'
  }
})(Tabs);

const StyledTab = withStyles({
  root: {
    width:'calc(50% - 10px)',
    margin:'0 5px',
    backgroundColor:'#F5F5F5',
  },
  selected: {
    color:'#000 !important',
    backgroundColor:'#fff'
  }
})(Tab); */

const ScrollContainer = withStyles({
  root: {
    width: '100%',
  },
})(Container)

const LogoutNotice = (props) => {
  const { classes } = props

  /** redux props */
  const { authentication, toggleForgotPassword, toggleSignUp } = props

  /** redux api actions */
  const { navigateResetRedux, confirmLogout, checkTokenStatus } = props

  const [isLogged, setIsLogged] = React.useState(false)

  React.useEffect(() => {
    /** Check if Token Exist */
    if (authentication.token) {
      setIsLogged(true)
      //   confirmLogout(false);
    } else {
      setIsLogged(false)
    }
  }, [authentication])

  const handleConfirmLogout = (value) => {
    confirmLogout(false)
    checkTokenStatus(value)
  }

  function renderForms() {
    return (
      <div className={classes.formWrapper}>
        <ScrollContainer className={classes.container}>
          <DynamicIcons
            icon="2DCPIconColor"
            style={{ height: '61px', width: '40px', position: 'relative', top: '-3px' }}
          />
          <Typography variant="h6" className={classes.heading}>
            Thanks for visiting,
            <br /> see you around mate!
          </Typography>
          <Typography variant="body1" gutterBottom>
            We hope we've helped you find your perfect property match!
          </Typography>
          <div className={classes.ctawrap}>
            <Typography variant="button" component="p">
              <Link
                style={{ color: '#000000' }}
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  toggleForgotPassword(true)
                  navigateResetRedux()
                  handleConfirmLogout(true)
                  toggleSignUp(true)
                }}
              >
                Reset Password
              </Link>
            </Typography>

            <Button
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => {
                handleConfirmLogout(true)
              }}
            >
              Log back in
            </Button>
          </div>
        </ScrollContainer>
      </div>
    )
  }

  return (
    <Paper className={classes.root} elevation={5}>
      <MainHeader className={classes.appBar} position="static">
        <IconButton
          aria-label="close"
          className={classes.closeicon}
          onClick={() => {
            handleConfirmLogout()
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </MainHeader>
      {renderForms()}
    </Paper>
  )
}

LogoutNotice.propTypes = {
  type: PropTypes.string,
  classes: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    user: state.user,
    authentication: state.authentication,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleForgotPassword: (checked) => toggleForgotPassword(checked),
      navigateResetRedux: () => navigateReset(),
      logoutUser: () => logoutRequest(),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LogoutNotice))
