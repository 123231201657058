import React, { useState, useEffect } from 'react';

import Close from '@material-ui/icons/Close';

import SavedInput from './SavedInput';
import SavedPropertyInfo from './SavedPropertyInfo';
import SavedButton from './SavedButton';

import { withStyles, Modal, Typography, IconButton } from '@material-ui/core';

import AlertNotification from '../Form/AlertNotification';
import { useSelector } from 'react-redux';
import { getSavedSearchRequestStatus, getSavedSearchRequestError } from 'store/selectors/propertyv2.selectors';

const styles = theme => ({
    root: {
        position: 'absolute',
        width: '90vw',
        height: 'auto',
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        '&:hover': {
            outline: 'none',
        },
        '&:active': {
            outline: 'none',
        },
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    closeButton: {
        color: '#000'
    },
});

const StyledText = withStyles({
    root: {
        textAlign: 'left',
        color: '#000000',
        fontSize: '20px',
        lineHeight: '1.19',
        padding: '10px',
        zIndex: 10,
        position: 'relative',
    },
})(Typography);

const SavedSearch = props => {
    const {
        classes,
        filters,
        path,
        showSaveSearch,
        showSaveSearchModal,
        apiRequestSaveSearch,
    } = props;

    const [open, setOpen] = useState(true);
    const [suburbs, setSuburbs] = useState(true);
    const [savedTitle, setSavedTitle] = useState('');
    const [isError, setIsError] = useState(false);

    const defaultNotification = { status: false, options: null }
    const [isNotification, setIsNotification] = useState(defaultNotification)

    const SAVE_STATUS = useSelector(getSavedSearchRequestStatus)
    const SAVE_ERROR = useSelector(getSavedSearchRequestError)

    const handleNotificationClose = () => {
        setIsNotification(defaultNotification)
    }

    useEffect(() => {
        if (SAVE_STATUS === "success") {
            const options = {
                status: true,
                options: {
                    severity: 'success',
                    message:
                        'Search criteria successfully saved.',
                },
            }
            setIsNotification(options)
        }
        if (SAVE_ERROR === "error") {
            const options = {
                status: true,
                options: {
                    severity: 'error',
                    message:
                        'Error saving search criteria. Please try again.',
                },
            }
            setIsNotification(options)
        }
    }, [SAVE_STATUS, SAVE_ERROR])

    useEffect(() => {
        if (savedTitle.length > 0) {
            setIsError(false)
        }
    }, [savedTitle])

    const handleOpen = () => {
        setOpen(true);
        showSaveSearchModal(true);
    };

    const handleClose = () => {
        setOpen(false);
        showSaveSearchModal(false);
    };

    useEffect(() => {
        let suburb = [];
        if (filters && filters.suburbs && filters.suburbs.length) {
            filters.suburbs.map((item, index) => {
                suburb.push(item.locality + ', ' + item.state + ', ' + item.postcode)
            })
        }
        setSuburbs(suburb)
    }, [filters])

    function handleSubmitSaveSearch() {
        let params = {
            name: savedTitle,
        }
        if (savedTitle === '') {
            setIsError(true);
        } else {
            setIsError(false);
            apiRequestSaveSearch(params);
            setSavedTitle('');
            handleClose();
        }
    }

    function renderContent() {
        return (
            <div className={classes.root}>
                <div className={classes.rowContainer}>
                    <StyledText variant="h2">
                        Saved Search
                    </StyledText>
                    <IconButton className={classes.closeButton} component="span" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </div>
                <SavedInput savedTitle={savedTitle} setSavedTitle={setSavedTitle} isError={isError} />
                <SavedPropertyInfo path={path} filters={filters} suburbs={suburbs} />
                <SavedButton handleClose={handleClose} isError={isError} handleSubmitSaveSearch={handleSubmitSaveSearch} />
            </div>
        )
    }

    return (
        <>
            {isNotification.status && (
                <AlertNotification
                    status={isNotification.status}
                    options={isNotification.options}
                    closeNotification={handleNotificationClose}
                />
            )}
            <Modal
                open={showSaveSearch}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {renderContent()}
            </Modal>
        </>
    );
}

export default withStyles(styles)(SavedSearch);