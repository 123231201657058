import { FormControlLabel, Checkbox, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import useFilters from 'utils/customHooks/useFilters'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    color: '#6A6A6A',
    fontSize: '16px',

    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
    },

    [theme.breakpoints.down(400)]: {
      fontSize: '14px',
    },

    [theme.breakpoints.down(375)]: {
      fontSize: '12px',
    },

    [theme.breakpoints.down(300)]: {
      fontSize: '11px',
      marginLeft: '-5px',
    },
  },
}))

const IncludeNearbySuburbs = () => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()
  const INCLUDE_NEARBY_SUBURBS = getFilter('include_nearby_suburbs')
  const { triggerAnalyticsEvent } = useGoogleEvents()

  return (
    <div>
      <FormControlLabel
        style={{ color: '#6A6A6A', marginRight: '0px' }}
        control={
          <Checkbox
            style={{ color: '#35C0CA' }}
            color="default"
            name="includeNearbySuburbs"
            checked={INCLUDE_NEARBY_SUBURBS}
            onChange={(event) => {
              setFilter({
                key: 'include_nearby_suburbs',
                value: !INCLUDE_NEARBY_SUBURBS,
              })

              triggerAnalyticsEvent({
                event: 'header_actions',
                eventFrom: 'Generic',
                actionScope: 'header_filters',
                actionName: 'Header Filters',
                actionTarget: INCLUDE_NEARBY_SUBURBS
                  ? 'Including nearby suburbs'
                  : 'Not includes nearby suburbs',
                actionReference: 'header',
                actionData: !INCLUDE_NEARBY_SUBURBS,
              })
            }}
          />
        }
        label={
          <Typography className={classes.checkboxLabel}>
            Include nearby suburbs
          </Typography>
        }
      />
    </div>
  )
}

export default IncludeNearbySuburbs
