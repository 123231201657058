import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import RangeSlider from './RangeSlider';
import CustomSwitch from '../Filter/CustomSwitch';
import { PRICE_RANGE } from '../../../constants/constants';

import { Box, Typography, withStyles } from '@material-ui/core';
import PriceOptions from './PriceOptions';
import { getPropertyDetails } from 'store/selectors/agencyv2.selectors';
import { useSelector } from 'react-redux';
import { formatUSD } from 'utils/formatter/priceFormatter';

const SliderItem = styled.div`
  padding: 5px 0;

  .MuiSlider-mark[data-index='0'] {
    opacity: 0;
  }
  .MuiSlider-mark[data-index='5'] {
    opacity: 0;
  }
`;
const StyledText = withStyles((theme) => ({
  root: {
    fontSize: '1rem',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
}))(Typography);

const CheckWrap = styled.div`
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: -25px;
`;

const marksBedroom = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
];

const marksDefault = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '+5',
  },
];

const priceRange = PRICE_RANGE;
const priceRangeRent = [250, 5000];

const handlePriceValid = (minPrice, maxPrice, price, priceChange) => {
  if (price > maxPrice) {
    priceChange(maxPrice);
    return false;
  }
  if (price < minPrice) {
    priceChange(minPrice);
    return false;
  }

  return true;
};

const handleMinMaxPriceRangeFormat = (priceRange) => {
  if (priceRange) {
    const maxMinPriceRange = priceRange.split('-');
    if (maxMinPriceRange.length > 1) {
      const minPriceRange = maxMinPriceRange[0].replace(/\$|,/g, '');
      const maxPriceRange = maxMinPriceRange[1].replace(/\$|,/g, '');

      return [Number(minPriceRange), Number(maxPriceRange)];
    }
  }

  return [PRICE_RANGE[0], PRICE_RANGE[1]];
};

const getPriceOption = (priceView) => {
  if (priceView === 'POA') return 'POA';
  if (priceView === 'Contact Agent') return 'Contact Agent';

  if (priceView?.includes('-')) {
    return 'Price Range';
  } else {
    return 'Single Price';
  }
};

const SliderContainer = (props) => {
  const { handlePropertyDetails } = props;
  const reduxProperty = useSelector(getPropertyDetails);
  const property = reduxProperty?.data.data.property;

  const [price, setPrice] = useState(
    props.propertyDetails?.price?.min && props.propertyDetails?.price?.max
      ? [props.propertyDetails.price.min, props.propertyDetails.price.max]
      : props.propertyDetails.price,
  );
  const [priceRent, setPriceRent] = useState([
    props.propertyDetails.priceRent.min,
    props.propertyDetails.priceRent.max,
  ]);
  const [bedrooms, setBedrooms] = useState(props.propertyDetails.bedrooms);
  const [bathrooms, setBathrooms] = useState(props.propertyDetails.bathrooms);
  const [carparking, setCarparking] = useState(props.propertyDetails.carparking);
  const [pricePrecise, setPricePrecise] = useState(props.price);
  const [priceStep, setPriceStep] = useState(priceRange[0]);

  const [priceOption, setPriceOption] = useState('');
  const [priceRangeMin, setPriceRangeMin] = useState(PRICE_RANGE[0]);
  const [priceRangeMax, setPriceRangeMax] = useState(PRICE_RANGE[1]);

  useEffect(() => {
    handleData();
  }, [priceRangeMin, priceRangeMax]);

  useEffect(() => {
    if (property) {
      setPricePrecise(property.price);
      setPriceOption(getPriceOption(property.priceView));
    }
  }, [property]);

  useEffect(() => {
    if (property) {
      if (priceOption === 'Price Range') {
        const [minPriceRange, maxPriceRange] = handleMinMaxPriceRangeFormat(property.priceView);
        setPriceRangeMin(minPriceRange);
        setPriceRangeMax(maxPriceRange);
      }

      handleData();
    }
  }, [priceOption, property]);

  useEffect(() => {
    if (props.isClearFilter) {
      setPriceRent(priceRangeRent);
      setPrice(priceRange);
      setBedrooms(null);
      setBathrooms(null);
      setCarparking(null);
      handleData('clear');
    }
  }, [props.isClearFilter]);

  useEffect(() => {
    handleData();
  }, [props.hidePrice]);

  const handlePriceChange = (event, newValue) => {
    let minVal = newValue[0];
    if (minVal > 5000000 && minVal < 10000000) {
      setPriceStep(500000);
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000);
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000);
    } else {
      setPriceStep(50000);
    }

    if (
      (newValue[0] > 5000000 && newValue[0] < priceRange[1]) ||
      (newValue[1] > 5000000 && newValue[1] < priceRange[1])
    ) {
      if (newValue[0].toFixed(2).search('50000.00') > -1) {
        let nMin = String(newValue[0]).replace('50000', '00000');
        newValue[0] = parseInt(nMin);
      }
      if (newValue[1].toFixed(2).search('50000.00') > -1) {
        let nMax = String(newValue[1]).replace('50000', '00000');
        newValue[1] = parseInt(nMax);
      }
      setPrice(newValue);
    } else {
      setPrice(newValue);
    }
  };

  const handlePricePreciseChange = (event, value) => {
    let minVal = value;
    if (minVal > 5000000 - 1 && minVal < 10000000) {
      setPriceStep(500000);
    } else if (minVal > 10000000 - 1 && minVal < 20000000) {
      setPriceStep(1000000);
    } else if (minVal > 20000000 - 1) {
      setPriceStep(5000000);
    } else {
      setPriceStep(50000);
    }
    setPricePrecise(value);
  };

  const handlePriceRentChange = (event, newValue) => {
    setPriceRent(newValue);
  };

  const handleBedroomsChange = (event, newValue) => {
    setBedrooms(newValue);
  };

  const handleBathroomsChange = (event, newValue) => {
    setBathrooms(newValue);
  };

  const handleCarparkingChange = (event, newValue) => {
    setCarparking(newValue);
  };

  const handleChangeCommitted = () => {
    handleData();
  };

  const handleData = (clear) => {
    let priceView = '';

    switch (priceOption) {
      case 'Single Price':
        priceView = formatUSD(Number(pricePrecise));
        break;

      case 'Price Range':
        const rangePrice = `${formatUSD(Number(priceRangeMin))} - ${formatUSD(Number(priceRangeMax))}`;

        priceView = rangePrice;
        break;

      case 'POA':
        priceView = 'POA';
        break;

      case 'Contact Agent':
        priceView = 'Contact Agent';
        break;
    }

    const propertyData = {
      price: pricePrecise,
      priceRent: {
        min: priceRent[0],
        max: priceRent[1],
      },
      bedrooms,
      bathrooms,
      carparking,
      hidePrice: props.hidePrice,
      priceView: priceView,
      priceOption: priceOption,
      priceRangeMin: priceRangeMin,
      priceRangeMax: priceRangeMax,
    };

    if (props.handlePropertyDetails) {
      if (clear) {
        propertyData.price.min = priceRange[0];
        propertyData.price.max = priceRange[1];
        propertyData.priceRent.min = 250;
        propertyData.priceRent.max = 5000;
        propertyData.bedrooms = null;
        propertyData.bathrooms = null;
        propertyData.carparking = null;
        priceView = '';
        priceOption = 'Single Price';
        priceRangeMin = priceRange[0];
        priceRangeMax = priceRange[1];
      }
      props.handlePropertyDetails(propertyData);
    }
  };

  return (
    <Box>
      <SliderItem>
        {
          // <RangeSlider label="Price" icon="MonetizationOn" value={price} range={priceRange} step={priceRange[0]} handleChange={handlePriceChange} handleChangeCommitted={handleChangeCommitted} valueLabelDisplay={'off'} />
          props.path === 'rent' ? (
            <RangeSlider
              path={props.path}
              label="Price Per Week"
              icon="MonetizationOn"
              value={priceRent}
              range={priceRangeRent}
              step={50}
              handleChange={handlePriceRentChange}
              handleChangeCommitted={handleChangeCommitted}
              valueLabelDisplay={'off'}
            />
          ) : (
            <PriceOptions
              pricePrecise={pricePrecise}
              setPricePrecise={setPricePrecise}
              handlePriceValid={handlePriceValid}
              priceOption={priceOption}
              setPriceOption={setPriceOption}
              priceRangeMin={priceRangeMin}
              setPriceRangeMin={setPriceRangeMin}
              priceRangeMax={priceRangeMax}
              setPriceRangeMax={setPriceRangeMax}
              handleData={handleData}
            />
          )
        }
      </SliderItem>
      {/* {
        <CheckWrap>
          <CustomSwitch
            label={'Show Price'}
            checked={props.hidePrice}
            onChange={(e) => {
              props.setHidePrice(!props.hidePrice)
            }}
          />
          <StyledText component={'span'}>POA</StyledText>
        </CheckWrap>
      } */}
      <SliderItem>
        <RangeSlider
          label="Bedrooms"
          icon="Hotel"
          value={bedrooms}
          handleChange={handleBedroomsChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={marksBedroom}
          min={0}
          max={6}
        />
      </SliderItem>
      <SliderItem>
        <RangeSlider
          label="Bathrooms"
          icon="2DCBathIcon"
          value={bathrooms}
          handleChange={handleBathroomsChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={marksDefault}
          min={0}
          max={6}
        />
      </SliderItem>
      <SliderItem>
        <RangeSlider
          label="Carparking"
          icon="DirectionsCar"
          value={carparking}
          handleChange={handleCarparkingChange}
          handleChangeCommitted={handleChangeCommitted}
          step={1}
          marks={marksDefault}
          min={0}
          max={6}
        />
      </SliderItem>
    </Box>
  );
};

export default SliderContainer;
