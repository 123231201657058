import { numberFormatter } from './numberFormatter'

export const priceRangeLabelFormatter = (
  priceMin,
  priceMax,
  priceMinGlobalFilters,
  priceMaxGlobalFilters,
  labelSuffix,
  isLabelFromButton
) => {
  let addPricePrefix
  let addPriceSuffix

  if (priceMin === priceMinGlobalFilters && priceMax === priceMaxGlobalFilters) {
    return isLabelFromButton ? 'Price' : 'Any'
  }

  addPricePrefix = priceMin === priceMinGlobalFilters
  addPriceSuffix = priceMax === priceMaxGlobalFilters

  return `${addPricePrefix ? 'Below' : '$' + numberFormatter(priceMin, 2)} ${
    labelSuffix ? labelSuffix : ''
  } - $${numberFormatter(priceMax, 2)}${addPriceSuffix ? '+' : ''} ${
    labelSuffix ? labelSuffix : ''
  }`
}
