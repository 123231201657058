import React, { useEffect } from 'react'
import {
  Paper,
  Typography,
  Tab,
  Tabs,
  Button,
  TextField,
  withStyles,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ErrorHandler } from 'components/Web/Superadmin'

const FormInput = (props) => (
  <TextField {...props} fullWidth id="outlined-basic" variant="outlined" />
)

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#FFF',
    width: 450,
    borderRadius: 20,
    paddingTop: 30,
    paddingBottom: 30,
  },
  content: {
    paddingTop: 20,
    paddingRight: 40,
    paddingLeft: 40,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: "center",
    // alignItems: "center",
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2),
    },
    marginTop: 20,
    // marginBottom: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 20,
    // borderBottom: "0.3px solid #BDBDBD",
  },
  button: {
    borderRadius: 20,
    textTransform: 'capitalize',
    minWidth: 150,
    marginBottom: 10,
  },
  forgotButton: {
    borderRadius: 20,
    textTransform: 'capitalize',
    fontSize: 12,
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    marginBottom: '10px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  subtitle: {
    fontWeight: 300,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px',
  },
})

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
})

const validationSchemaReset = yup.object({
  code: yup.string('Enter your reset code').required('Reset code is required'),
  password: yup
    .string()
    .required('Password is required')
    .min(8, 'Password should be of minimum 8 characters length'),
  // .matches(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z])/, 'Invalid password format'),
  confirmPassword: yup
    .string()
    .required('Password is required')
    .when('password', {
      is: (val) => val && val.length > 0,
      then: yup.string().oneOf([yup.ref('password')], 'Password do not match'),
    }),
})

const AgencyForgotForm = (props) => {
  const {
    classes,
    goBack,
    error,
    handleFormSubmit,
    handleFormSubmitReset,
    forgotStatus,
    resetStatus,
  } = props

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFormSubmit(formik.values)
    },
  })

  const resetFormik = useFormik({
    initialValues: {
      email: formik.values.email,
      code: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchemaReset,
    onSubmit: (values) => {
      const params = Object.assign(resetFormik.values, formik.values)
      delete params.confirmPassword
      handleFormSubmitReset(params)
    },
  })

  useEffect(() => {
    if (resetStatus) {
      formik.resetForm()
      resetFormik.resetForm()
    }
  }, [resetStatus])

  return (
    <Paper className={classes.root}>
      <Tabs indicatorColor={'primary'} value={0}>
        <Tab label="Forgot Password" />
      </Tabs>
      <div className={classes.content}>
        <Typography className={classes.title} variant={'h1'}>
          Forgot your password? <br />
          No problem, these things happen
        </Typography>
        <Typography className={classes.subtitle} variant={'body2'}>
          {forgotStatus
            ? 'Check your email for the Reset Code'
            : 'Reset your password using your email'}
        </Typography>
        {!forgotStatus && (
          <div className={classes.form}>
            <form onSubmit={formik.handleSubmit}>
              <FormInput
                fullWidth
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.email)}
                helperText={formik.errors.email}
              />
              <ErrorHandler error={error} />
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  color={'primary'}
                  variant="contained"
                  type="submit"
                  disabled={!formik.dirty}
                >
                  Reset Password
                </Button>
                <Button
                  className={classes.forgotButton}
                  color={'inherit'}
                  variant="text"
                  onClick={() => goBack()}
                >
                  {'< Go back to sign in'}
                </Button>
              </div>
            </form>
          </div>
        )}
        {forgotStatus && (
          <div className={classes.form}>
            <form onSubmit={resetFormik.handleSubmit}>
              <FormInput
                fullWidth
                id="code"
                name="code"
                label="Reset Code"
                variant="outlined"
                inputProps={{ autocomplete: 'new-password' }}
                value={resetFormik.values.code}
                onChange={resetFormik.handleChange}
                error={resetFormik.touched.code && Boolean(resetFormik.errors.code)}
                helperText={resetFormik.touched.code && resetFormik.errors.code}
              />
              <FormInput
                fullWidth
                id="password"
                name="password"
                type="password"
                inputProps={{ autocomplete: 'new-password' }}
                label="New Password"
                variant="outlined"
                value={resetFormik.values.password}
                onChange={resetFormik.handleChange}
                error={
                  resetFormik.values?.password && Boolean(resetFormik.errors.password)
                }
                helperText={resetFormik.values?.password && resetFormik.errors.password}
              />
              <FormInput
                fullWidth
                id="confirm-password"
                name="confirmPassword"
                type="password"
                label="Re-type Password"
                variant="outlined"
                inputProps={{ autocomplete: 'new-password' }}
                value={resetFormik.values.confirmPassword}
                onChange={resetFormik.handleChange}
                error={
                  resetFormik.values?.confirmPassword &&
                  Boolean(resetFormik.errors.confirmPassword)
                }
                helperText={
                  resetFormik.values?.confirmPassword &&
                  resetFormik.errors.confirmPassword
                }
              />
              <ErrorHandler error={error} />
              <div className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  color={'primary'}
                  variant="contained"
                  type="submit"
                  disabled={
                    !resetFormik.dirty || Object.keys(resetFormik.errors).length !== 0
                  }
                >
                  Confirm Reset Password
                </Button>
                <Button
                  className={classes.forgotButton}
                  color={'inherit'}
                  variant="text"
                  onClick={() => goBack()}
                >
                  {'< Go back to sign in'}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default withStyles(styles)(AgencyForgotForm)
