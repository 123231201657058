import React, { useEffect, useRef, useState } from 'react'
import {
  Badge,
  Box,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Tooltip,
} from '@material-ui/core'
import TuneIcon from '@material-ui/icons/Tune'

import PriceSlider from 'components/Common/GlobalFilters/PriceSlider'
import OptionModalTop from './OptionModalTop'
import useFilters from 'utils/customHooks/useFilters'
import { priceRangeLabelFormatter } from 'utils/formatter/priceRangeLabelFormatter'
import ModalFilterButtons from './ModalFilterButtons'
import Bedrooms from 'components/Common/GlobalFilters/Bedrooms'
import Bathrooms from 'components/Common/GlobalFilters/Bathrooms'
import ListOptions from './ListOptions'
import { toggleFilterWeb } from 'store/actions'
import { useDispatch } from 'react-redux'
import { useSearch } from 'utils/search/useSearch'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const useStyles = makeStyles((theme) => ({
  wrapper: { position: 'relative', display: 'inline-block' },

  iconTune: {
    color: '#000',
  },
  /** Search Filter */
  searchFilterContainer: {
    display: ' flex',
    marginTop: '-5px',
    paddingLeft: 0,
    paddingRight: theme.spacing(2),
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    zIndex: 6,
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
    '& .Filter-Button': {
      paddingRight: 4,
      paddingLeft: 8,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '@media (max-width: 768px)': {
      paddingRight: 0,
    },
  },

  /** Top Option Modal */
  optionContainer: {
    zIndex: 6,
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
    },
    padding: '70px 35px 20px 35px',
  },
  optionButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '0.625em',
  },
  optionButton: {
    textTransform: 'none',
    borderRadius: 20,
    margin: '0.625em',
  },
}))

const SearchModalOptions = ({
  showOptionModal,
  setIsInputFocused,
  children,
  handleSearch,
}) => {
  const classes = useStyles()

  return (
    <Paper className={classes.optionContainer} elevation={5}>
      <div className={classes.filterContainer}>{children}</div>
      <div className={classes.optionButtonContainer}>
        <Button
          size={'small'}
          className={classes.optionButton}
          onClick={() => {
            showOptionModal(null, true)
            setIsInputFocused(false)
          }}
        >
          Cancel
        </Button>
        <Button
          size={'small'}
          className={classes.optionButton}
          color={'primary'}
          variant="contained"
          onClick={() => {
            handleSearch()
            showOptionModal(null, true)
          }}
        >
          Apply
        </Button>
      </div>
    </Paper>
  )
}

const PropertyTypeModalOption = ({
  showOptionModal,
  setIsInputFocused,
  handleSearch,
}) => {
  const classes = useStyles()

  const [getFilter, setFilter] = useFilters()

  const PROPERTY_TYPES = getFilter('property_type')

  const handlePropertyType = (data) => {
    setFilter({ key: 'property_type', value: data })
  }

  return (
    <Paper className={classes.optionContainer} elevation={5}>
      <Box>
        <Box>
          <ListOptions
            type={'property'}
            options={PROPERTY_TYPES}
            handleType={handlePropertyType}
          />
        </Box>
      </Box>
      <div className={classes.optionButtonContainer}>
        <Button
          size={'small'}
          className={classes.optionButton}
          onClick={() => {
            showOptionModal(null, true)
            setIsInputFocused(false)
          }}
        >
          Cancel
        </Button>
        <Button
          size={'small'}
          className={classes.optionButton}
          color={'primary'}
          variant="contained"
          onClick={() => {
            handleSearch();
            showOptionModal(null, true)
          }}
        >
          Apply
        </Button>
      </div>
    </Paper>
  )
}

const FilterOptionsV2 = ({ path }) => {
  const classes = useStyles()

  const [showModalOptions, setShowModalOptions] = React.useState(false)
  const [isInputFocused, setIsInputFocused] = React.useState(false)
  const [selectedFilterType, setSelectedFilterType] = React.useState('')

  const { triggerAnalyticsEvent } = useGoogleEvents()

  const dispatch = useDispatch()

  const wrapperRef = useRef(null)

  const [initiateSearch] = useSearch()

  const handleSearch = () => {
    initiateSearch()
  }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          showOptionModal(null, true)
          setIsInputFocused(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const showOptionModal = (selected, closeModal) => {
    if (closeModal) {
      setShowModalOptions(false)
      setSelectedFilterType('')
      //   toggleFilter(false)
    } else {
      setShowModalOptions(true)
      //   toggleFilter(true)
      setSelectedFilterType(selected)
    }
  }

  const handleFilterButtonClick = (filterType) => {
    showOptionModal(filterType)
    setIsInputFocused(false)

    triggerAnalyticsEvent({
      event: 'results_actions',
      eventFrom: 'Web',
      actionScope: 'inline_search_filters',
      actionName: 'Inline Search Filters',
      actionTarget: 'Expanded ' + filterType + ' options',
      actionReference: 'results',
    })
  }

  let modalContent = null

  switch (selectedFilterType) {
    case 'bed':
      modalContent = <Bedrooms />
      break
    case 'bath':
      modalContent = <Bathrooms />
      break
    case 'cost':
      modalContent = <PriceSlider path={path} />
      break
  }

  useOutsideAlerter(wrapperRef)

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <OptionModalTop
        style={{ zIndex: 6 }}
        isOpen={showModalOptions}
        showOptionModal={showOptionModal}
        content={
          selectedFilterType === 'propertyType' ? (
            <PropertyTypeModalOption
              showOptionModal={showOptionModal}
              setIsInputFocused={setIsInputFocused}
              handleSearch={handleSearch}
            />
          ) : (
            <SearchModalOptions
              showOptionModal={showOptionModal}
              setIsInputFocused={setIsInputFocused}
              handleSearch={handleSearch}
            >
              {modalContent}
            </SearchModalOptions>
          )
        }
      />
      {/* {type === 'search' ? renderSearchOptions() : renderFilterOptions()} */}

      <Box className={classes.searchFilterContainer}>
        <Tooltip title="Filters">
          <IconButton
            className={'Filter-Button'}
            onClick={() => {
              dispatch(toggleFilterWeb({ type: path, status: true }))
              triggerAnalyticsEvent({
                event: 'results_actions',
                eventFrom: 'Web',
                actionScope: 'inline_search_filters',
                actionName: 'Inline Search Filters',
                actionTarget: 'Show All Current Filters',
                actionReference: 'results',
              })
            }}
          >
            <Badge
              badgeContent={null}
              color="primary"
              style={{ pointerEvents: 'none' }}
            >
              <TuneIcon className={classes.iconTune} />
            </Badge>
          </IconButton>
        </Tooltip>
        <ModalFilterButtons
          handleFilterButtonClick={handleFilterButtonClick}
          path={path}
        />
      </Box>
    </div>
  )
}

export default FilterOptionsV2
