import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Popover,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  withStyles,
  Tooltip,
  Box,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Person from '@material-ui/icons/Person';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import Crumbs from './Crumbs';
import DialogContainer from '../../../components/Web/DialogContainer';
import LogoutNotice from '../../../components/Web/Form/LogoutNotice';
import Searchbar from '../../../components/Web/Searchbar';
import SavedSearch from '../../../components/Web/SavedSearch';
import HeartIcon from '@material-ui/icons/Favorite';

import {
  toggleThemeMode,
  toggleSignUp,
  logoutRequest,
  clearFilters,
  setPropertySearchFilters,
  setPropertySuburbSearch,
  setIncludeNearby,
} from '../../../store/actions';

import DynamicIcons from '../../../components/Web/DynamicIcons';
import LogoInverted from '../../../assets/images/pm-logo-inverted.png';
import PMLogo from '../../../assets/images/pm-logo.png';

import AvatarPlaceholder from '../../../assets/images/Avatar.png';
import SampleCompanyLogo from '../../../assets/images/jellis.png';
import ChevronDown from '../../../assets/images/chevron-down.png';
import NotificationsList from '../Notifications/NotificationsList';
import socketIOClient from 'socket.io-client';
import { NOTIFICATION_ENDPOINT } from '../../../utils/constants.utils';
import { formatRequest } from '../../../utils/search.utils';
import {
  requestPropertySearch,
  requestPropertySearchRentals,
  requestPropertySearchSold,
  requestPropertySaveSearch,
} from '../../../store/api';
import IncludeNearbySuburbs from 'components/Common/GlobalFilters/IncludeNearbySuburbs';
import SearchbarFilter from 'components/Common/GlobalFilters/SearchbarFilter';
import { useRouterChange } from 'utils/router.utils';

const ENDPOINT = NOTIFICATION_ENDPOINT;
const SOCKET_ADDRESS = 'notifications:';

const styles = (theme) => ({
  appBar: {
    boxShadow: 'none',
    paddingRight: '5%',
    paddingLeft: 155,
    paddingBottom: 16,
    paddingTop: 16,

    [theme.breakpoints.down(1100)]: {
      paddingLeft: 125,
    },
  },
  appBarAgent: {
    paddingTop: 12,
    paddingLeft: 0,
    paddingRight: 80,
    paddingBottom: 9,
    backgroundColor: '#35C0CA !important',
  },
  toolbarRoot: {
    justifyContent: 'space-between',
    padding: 0,
  },
  toolbarRootBordered: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #828282',
    padding: '0 0 14px 0',
  },
  toolbarRootAgentBordered: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #828282',
    padding: '0 0 0 0',
    minHeight: '70px !important',
  },
  toolbarRootAgent: {
    justifyContent: 'space-between',
    padding: '0 0 0 0',
    minHeight: '70px !important',
  },
  title: {
    flexGrow: 1,
  },
  popover: {
    marginTop: 5,
  },
  list: {
    width: 180,
  },
  notificationPopover: {
    marginTop: theme.spacing(1.25),
  },
  logoWrap: {
    textAlign: 'left',
    cursor: 'pointer',
    '& > img': {
      height: 47,
    },
  },
  signInOut: {
    color: theme.palette.gray.main,
    textTransform: 'capitalize',
    fontSize: 14,
  },
  joinbtn: {
    color: theme.palette.gray.main,
    textTransform: 'capitalize',
    minWidth: 90,
    borderRadius: 20,
    fontSize: 14,
    marginLeft: 20,
    paddingTop: 5,
    paddingBottom: 3,
    '&:hover': {
      backgroundColor: '#35C0CA',
    },

    [theme.breakpoints.down(1280)]: {
      marginLeft: 5,
    },
  },
  middleSearchBar: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& .MuiIconButton-root': {
      padding: '10px !important',
    },
    '& [class^=makeStyles-root]': {
      border: 'solid 1px #9b9b9b !important',
    },
  },

  optionIcon: {
    marginLeft: '0.313em',
    width: '0.625em',
    height: '0.625em',
  },

  searchContainer: {
    display: 'flex',
    maxWidth: '530px',
    minWidth: '340px',
    width: '25vw',
    // maxHeight: '60px',
    [theme.breakpoints.up('1920')]: {
      width: '530px',
    },
  },

  searchBarContainer: {
    display: 'flex',
    width: '530px',
    maxHeight: '60px',
    [theme.breakpoints.down('md')]: {
      width: '450px',
    },
  },

  // Include nearby suburbs filter
  includeNearbyFilterContainer: {
    marginLeft: '15px',
    marginRight: '15px',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down(1280)]: {
      whiteSpace: 'normal',
    },
  },
});

const ButtonLogo = withStyles({
  root: {
    width: '24px',
    minWidth: '39px',
    padding: '0',
  },
})(Button);

const StyledButton = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: '0.7',
    },
  },
})(Button);

const ValidationSearch = styled.div`
  position: absolute;
  bottom: -45%;
  left: 30px;
  border-radius: 3px;
  opacity: 0;
  background-color: rgb(17, 192, 201);
  padding: 10px;
  z-index: 9;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 0.3s ease;
  &.active {
    transform: translateY(0px);
    opacity: 1;
  }
  &:before {
    display: block;
    content: '';
    position: absolute;
    top: -8px;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 8px 7.5px;
    border-color: transparent transparent rgb(17, 192, 201) transparent;
  }
  p {
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
`;

const LeftWrap = styled.div`
  a {
    display: block;
  }
`;

const RightWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  min-height: 50px;
`;

const Logo = styled.img`
  width: 233px;
  height: auto;
`;

const NotificationBell = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  span {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 8px;
    height: 8px;
    border-radius: 10px;
    background-color: #35c0ca;
  }
`;

const AgentWrap = styled.div`
  display: flex;
  align-items: center;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1;
    color: #fff;
  }
  a {
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 10px;
    img {
      width: 100%;
      transition: transform 0.3s ease;
      transform-origin: center;
      &.active {
        transform: rotate(-180deg);
      }
    }
  }
`;
const AgentAvatar = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 42px;
  background-color: #fff;
  overflow: hidden;
  margin-left: 25px;
  img {
    width: 100%;
    object-fit: cover;
  }
`;
const CompanyLogo = styled.div`
  margin-left: 60px;
  width: 70px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
`;
const DropDown = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: absolute;
  left: 0;
  width: 199px;
  padding: 15px 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  top: 59px;
  opacity: 0;
  display: block;
  transition: 0.2s ease;
  transform-origin: center top;
  height: 0px;
  &.active {
    height: 110px;
    opacity: 1;
    transition: 0.3s ease;
    a {
      opacity: 1;
      transition: 0.3s ease 0.3s;
    }
  }
`;
const DropDownItem = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.09px;
  display: flex;
  color: #000000;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  padding: 0 30px;
  text-decoration: none;
  opacity: 0;
  transition: 0.3s ease 0s;
  &:hover {
    background: #e0e0e0;
  }
`;

{
  /* savedSearchContainer: {
    paddingLeft: theme.spacing(3), */
}

const OPTIONS_LOGGED = [
  {
    name: 'Account Settings',
    icon: <AccountCircle />,
    route: '/account-settings',
  },
  /* {
  name: 'Sign Out',
  icon: <ExitToApp />,
  route: ''
} */
];

const OPTIONS_LOGOUT = [
  {
    name: 'Sign In',
    icon: <Person />,
    route: '',
  },
];

const Header = (props) => {
  const {
    classes,
    settings,
    authentication,
    user,
    agency,
    logoutUser,
    toggleSignUp,

    type,
    currentRoute,
    crumbsOption,
    showLightCrumbs,
    isHidden,
    filters,
    clearFiltersState,
    setSearchFilters,
    setPropertySearch,
    setIncludeNearbyFilter,
    searchBuyProperty,
    searchRentProperty,
    searchSoldProperty,
    referenceFilters,
    skipTakeData,
    savePropertySearch,
  } = props;

  const [routerChange] = useRouterChange();

  const [isAgent, setIsAgent] = React.useState(props.isAgent);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmLogout, setConfirmLogout] = React.useState(false);
  const [isDropDownActive, setIsDropDownActive] = React.useState(false);

  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const notifOpen = Boolean(notificationAnchorEl);
  const notifId = notifOpen ? 'notification-popover' : undefined;
  const [hasNotification, setHasNotification] = React.useState(false);
  const [response, setResponse] = useState(null);
  const [invalidSearch, setInvalidSearch] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [showSaveSearch, setShowSaveSearch] = useState(false);

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const hiddenLoginSignout = ['/agency/payment-details/:token'];

  React.useEffect(() => {
    if (filters && filters.suburbs) {
      setInvalidSearch(false);
    }

    let arrayNotif = [];

    /* 
    if (user) {
      const socket = socketIOClient(ENDPOINT);

      
    socket.on(`default ${SOCKET_ADDRESS}${user._id}`, (data) => {
      console.debug(data);
      arrayNotif.push(data);
      setResponse(arrayNotif);
    });
    socket.on(`realtime ${SOCKET_ADDRESS}${user._id}`, (data) => {
      console.debug(data);
      arrayNotif.push(data);
      setResponse(arrayNotif);
    });
    socket.on("error", function () {
      console.error("Sorry, there seems to be an error!");
    });
    socket.on("connection_error", function () {
      console.error(
        "Sorry, there seems to be an issue with the websockets connection!"
      );
    });
    socket.on("server_error", function () {
      console.error("Sorry, there seems to be an issue with the websockets server!");
    });

    
    return () => socket.disconnect();
  } */
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRoutePath = () => {
    return props.currentRoute ? props.currentRoute.split('/')[1] : '';
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function checkTokenStatus(value, signUp) {
    handleClose();
    if (authentication.token) {
      logoutUser();
    } else if (value === false) {
      toggleSignUp(value);
    } else if (value === true && signUp === 1) {
      toggleSignUp(false, signUp);
      routerChange('/signup', {
        origin: props?.history?.location?.pathname ?? window.location.pathname,
      });
    } else {
      toggleSignUp(false);
      routerChange('/login', {
        origin: props?.history?.location?.pathname ?? window.location.pathname,
      });
    }
  }

  function handleConfirmLogout() {
    setConfirmLogout(!confirmLogout);
    checkTokenStatus();
  }

  function changeRoute(route) {
    handleClose();
    routerChange(route);
  }

  const handleDropDown = (e) => {
    e.preventDefault();
    setIsDropDownActive(!isDropDownActive);
  };

  const searchPagesArray = ['buy', 'sold', 'off-market', 'rent', 'hush-hush', 'coming-soon'];

  const addedLightRoutes = ['/login', '/signup'];

  const handleClearFilter = () => {
    setIsClearFilter(true);
    let to = setTimeout(() => {
      setIsClearFilter(false);
      resetFilters();
      // applyFilter('clear');
      clearTimeout(to);
    }, 50);
  };

  function resetFilters() {
    clearFiltersState();
  }

  const validateFilter = (params) => {
    if (params.suburb.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  let st = null;
  async function apiRequestSearchProperty(clear) {
    let path = props.currentRoute.split('/')[1];
    let params = await formatRequest(path, filters, null, null, referenceFilters.cursor);
    const relatedRoutes = ['buy', 'off-market', 'coming-soon', 'hush-hush'];

    if (currentRoute === '/' + path || currentRoute === '/' + path + '/:id') {
      routerChange('/' + path);
    } else {
      // saveFilters(filter)
      if (clear) {
        params.suburb = [];
      }
      if (validateFilter(params)) {
        setInvalidSearch(false);
        // setIsLoading(true);

        if (relatedRoutes.includes(path)) {
          searchBuyProperty(params);
        }
        if (path === 'sold') {
          searchSoldProperty(params);
        }
        if (path === 'rent') {
          searchRentProperty(params);
        }
      } else {
        setInvalidSearch(true);
        st = setTimeout(() => {
          setInvalidSearch(false);
          clearTimeout(st);
          st = null;
        }, 2000);
      }
    }
  }

  /** Show Save Search */
  function showSaveSearchModal(params) {
    setShowSaveSearch(params);
  }

  function saveSearchCheckAuthentication() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(false);
      routerChange('/login');
    } else {
      /** User Logged */
      showSaveSearchModal(true);
    }
  }

  // /** Save Property Search API */
  // async function apiRequestSaveSearch(params) {
  //   let path = getRoutePath()
  //   let filterParameters = await formatRequest(path, filters)
  //   let request = {
  //     name: params.name,
  //     searchType: path,
  //     parameters: filters,
  //   }

  //   savePropertySearch(request)
  // }

  function renderAgentHeader() {
    const name = props.user ? user.name : '';
    const companyLogo = props.agency ? props.agency.logoUrl : null;
    return null;
  }

  function renderDefaultHeader() {
    const handlesArr = [
      '',
      'about-us',
      'account-settings',
      'saved-searches',
      'liked-properties',
      'favourites',
      'privacy-policy',
      'terms-and-conditions',
    ];
    return (
      <Toolbar
        classes={{
          root:
            type === 'LIGHT' ||
            hiddenLoginSignout.includes(props.currentRoute) ||
            handlesArr.includes(props.currentRoute.split('/')[1])
              ? classes.toolbarRoot
              : classes.toolbarRootBordered,
        }}
      >
        <LeftWrap>
          {type === 'DARK' || props.currentRoute.split('/')[1] === 'home' ? (
            <Box marginRight="24px">
              <div className={classes.logoWrap}>
                <img
                  src={LogoInverted}
                  onClick={(e) => {
                    e.preventDefault();
                    routerChange('/');
                  }}
                />
              </div>
            </Box>
          ) : (
            <></>
          )}
          {type === 'LIGHT' && showLightCrumbs && <Crumbs type={type} crumbsOption={crumbsOption} />}
        </LeftWrap>

        {searchPagesArray.includes(props.currentRoute.split('/')[1]) && (
          <div className={classes.middleSearchBar}>
            <div className={classes.searchContainer}>
              <Box height="60px" width="100%">
                <Box display="flex" width="100%" zIndex={3}>
                  <SearchbarFilter triggerFrom={'header'} />
                </Box>
              </Box>
            </div>
            <Box className={classes.includeNearbyFilterContainer}>
              <IncludeNearbySuburbs />
            </Box>

            {props.currentRoute.split('/').length !== 3 && <SavedSearch path={getRoutePath()} />}
          </div>
        )}

        <RightWrap>
          {!hiddenLoginSignout.includes(props.currentRoute) && (
            <>
              <StyledButton
                disableRipple={true}
                className={classes.signInOut}
                style={{
                  color: type === 'LIGHT' ? '#FFFFFF' : '#000000',
                  textTransform: 'initial',
                  borderRadius: 25,
                }}
                color="inherit"
                onClick={() => checkTokenStatus()}
              >
                {authentication.token ? <>Sign&nbsp;Out</> : <>Log In</>}
              </StyledButton>
              {props.auth !== 'logged' && (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  className={classes.joinbtn}
                  onClick={() => checkTokenStatus(true, 1)}
                  style={{
                    color: type === 'LIGHT' ? '#FFFFFF' : '#000000',
                    textTransform: 'initial',
                    borderRadius: 25,
                  }}
                >
                  Sign Up
                </Button>
              )}
            </>
          )}
          {props.auth === 'logged' && (
            <Tooltip title="Account Settings">
              <IconButton aria-label="delete" onClick={handleClick}>
                <AccountCircle color={'primary'} />
              </IconButton>
            </Tooltip>
          )}
          <Popover
            className={classes.popover}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <List className={classes.list} dense={true}>
              {authentication.token &&
                OPTIONS_LOGGED.map((value, index) => {
                  return (
                    <ListItem
                      key={index}
                      button
                      onClick={() => (index === 1 ? handleConfirmLogout() : changeRoute(value.route))}
                    >
                      <ListItemText primary={value.name} />
                      <ListItemSecondaryAction>{value.icon}</ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              {!authentication.token &&
                OPTIONS_LOGOUT.map((value, index) => {
                  return (
                    <ListItem key={index} button onClick={() => checkTokenStatus()}>
                      <ListItemText primary={value.name} />
                      <ListItemSecondaryAction>{value.icon}</ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
            </List>
          </Popover>
        </RightWrap>
      </Toolbar>
    );
  }

  return (
    <AppBar
      position={isAgent || props.currentRoute !== '/agent' ? 'relative' : 'absolute'}
      className={isAgent ? classes.appBarAgent : classes.appBar}
      style={{
        background: type === 'LIGHT' || addedLightRoutes.includes(props.currentRoute) ? 'transparent' : '#FFF',
        zIndex: 99,
        display: isHidden ? 'none' : 'block',
        paddingBottom: props.currentRoute.includes('/buy/') ? '0' : '16px',
      }}
    >
      {isAgent && renderAgentHeader()}
      {!isAgent && renderDefaultHeader()}
      <DialogContainer
        isOpen={confirmLogout}
        toggleSignUp={confirmLogout}
        content={<LogoutNotice confirmLogout={setConfirmLogout} checkTokenStatus={checkTokenStatus} />}
      />
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
    authentication: state.authentication,
    user: state.users.user,
    agency: state.users.agency,
    filters: state.filters,
    referenceFilters: state.referenceFilters,
    skipTakeData: state.skipTakeData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleThemeMode: (checked) => toggleThemeMode(checked),
      toggleSignUp: (checked, targetTab) => toggleSignUp(checked, targetTab),
      logoutUser: () => logoutRequest(),
      clearFiltersState: (params) => clearFilters(params),
      setSearchFilters: (params) => setPropertySearchFilters(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      setIncludeNearbyFilter: (params) => setIncludeNearby(params),
      searchBuyProperty: (params) => requestPropertySearch(params),
      searchSoldProperty: (params) => requestPropertySearchSold(params),
      searchRentProperty: (params) => requestPropertySearchRentals(params),
      savePropertySearch: (params) => requestPropertySaveSearch(params),
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
