export const transformFilterDataService = (filterType, value, globalFilters) => {
  let filterValue = value

  switch (filterType) {
    case 'search_reference':
    case 'property_type':
    case 'hush_hush':
    case 'nearby_filters':
    case 'features':
      {
        if (!Array.isArray(value)) {
          filterValue = globalFilters[filterType].map((filter) => {
            if (filter.id === value.id) {
              filter = value
            }
            return filter
          })
        }
      }
      break
  }

  return { key: filterType, value: filterValue }
}
