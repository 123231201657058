import React, { useState } from 'react';

import BedIcon from '@material-ui/icons/Hotel';
import ParkingIcon from '@material-ui/icons/DriveEta';
import CallIcon from '@material-ui/icons/Call';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

import DynamicIcons from "../DynamicIcons";

import { withStyles, IconButton, Typography, Box, Tooltip } from '@material-ui/core';

const styles = theme => ({
    root: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    iconContainer: {
        marginRight: '0.313em',
        width: '0.625em',
        height: '0.625em',
    },
    icon: {
        color: '#000',
        width: '0.625em',
        height: '0.625em',
        marginRight: '0.213em',
    },
    customIconContainer: {
        marginRight: '0.313em',
        width: '0.525em',
        height: '0.525em',
    },
    customIcon: {
        color: '#000',
        width: '0.525em',
        height: '0.525em',
        marginRight: '0.213em',
    },
    pricing: {
        fontSize: 12,
        fontWeight: 500
    },

    mainTrade: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '0.625em',
        marginBottom: '0.625em'
    },
    callIcon: {
        color: '#000',
        width: '0.825em',
        height: '0.825em',
    },
    companyName: {
        fontSize: 14,
        fontWeight: 500
    },
    inlineicons: {
        verticalAlign: 'middle',
        fontSize: '1.2rem'
    }
});

const PropertySuggestionsInfo = props => {
    const { 
        classes, 
        type,
        properties,
        trades
    } = props; 

    const addressRegex = / *AU *$|AUS *$/gm;
    function trucateText(text){
        return text.length > 45 ? text.replace(addressRegex, '').substring(0, 45) + "..." : text;
    }

    React.useEffect(()=>{
        // console.log('Properties', properties)
    },[]);

    function renderProperties(){ 
        return(
            <div className={classes.root}>
                { properties &&
                <div>
                    <div className={classes.main}>
                        <div>
                            <Tooltip title="Bedrooms">
                                <IconButton className={classes.iconContainer} onClick={()=> ''}>
                                    <BedIcon className={classes.icon}/>
                                    <Typography variant="caption" color="textSecondary" component="p">
                                        {properties.bedrooms ? properties.bedrooms : 0}
                                    </Typography>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Bathrooms">
                                <IconButton className={classes.customIconContainer} onClick={()=> ''}>
                                    <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
                                    <Typography variant="caption" color="textSecondary" component="p">
                                        {properties.bathrooms ? properties.bathrooms : 0}
                                    </Typography>
                                </IconButton>                            
                            </Tooltip>
                            <Tooltip title="Parking">
                                <IconButton className={classes.iconContainer} onClick={()=> ''}>
                                    <ParkingIcon className={classes.icon}/>
                                    <Typography variant="caption" color="textSecondary" component="p">
                                        {properties.totalParking ? properties.totalParking : 0}
                                    </Typography>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <Typography className={classes.pricing} variant="body2" component="p">
                        {properties.priceView}
                    </Typography>                    
                    {
                        properties && properties.addressText && properties.propertyStatus && properties.propertyStatus === 'current' || properties.propertyStatus === 'On-Sale' || properties.propertyStatus === 'sold' || properties.propertyStatus === 'rental' ||  properties.propertyStatus === 'leased'?
                        <Typography variant="caption" color="textSecondary" component="p">
                            {trucateText(properties.addressText)}
                        </Typography>
                        :
                        <>
                            <Typography variant="caption" color="textSecondary" component="p">
                                {properties.addressSuburb}
                            </Typography>
                            <Typography variant="caption" color="textSecondary" component="span">
                            <EmailRoundedIcon className={classes.inlineicons} fontSize="small"/> Email Agent
                            </Typography>
                        </>
                    }   
                </div>
                }
               
                
            </div>
        )
    }

    function renderTrades(){
        return (
            <div className={classes.root}>
                <div className={classes.mainTrade}>
                    <Typography className={classes.companyName} variant="body2" component="p">
                        {trades.registeredTradeName ? trades.registeredTradeName : trades.companyName}
                    </Typography>
                    <IconButton className={classes.iconContainer} href={'tel:123456'} onClick={()=> ''}>
                        <CallIcon className={classes.callIcon} />
                    </IconButton>
                </div>
                <Typography style={{color: '#007CDF', cursor:'pointer'}} gutterBottom variant="caption" component="p" onClick={()=> window.open(trades.website, '_blank')}>
                    {trades.website.replace(/(^\w+:|^)\/\//, '')}
                </Typography>
                <Typography variant="caption" component="p">
                    {trucateText(trades.addressText)}
                </Typography>
            </div>
        );
    }

    return (
        <>
            {
                type === 'properties' &&
                renderProperties()
            }
            {
                type === 'trades' &&
                renderTrades()
            }
        </>
    )
}

export default withStyles(styles)(PropertySuggestionsInfo);
