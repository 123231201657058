import React, { useEffect, useState } from 'react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'

import AgencyVerifyForm from 'components/Web/Agency/AgencyVerifyForm'

import { clearFilters } from 'store/actions'
import theme from 'theme/light'
import { requestVerifyAgency } from 'store/api'

import { Typography, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 106,
    paddingTop: 135,
    paddingRight: theme.spacing(2),
    margin: '0 auto',
    maxWidth: '75vw',
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)',
  },
})

const PageHeading = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px 0',
    zIndex: 10,
    position: 'relative',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
})(Typography)

const AgencyVerification = (props) => {
  const { classes, openSnackbar, verifyAgency, verify_status, verify_error } = props
  const [params, setParams] = useState(null)

  useEffect(() => {
    /** show alert to relogin */
    if (localStorage.getItem('showSnackBar') !== null) {
      openSnackbar('Session Expired.')
      localStorage.removeItem('showSnackBar')
    }

    /** CLEAR FILTERS */
    //clearAllFilters();

    setParams(props.match.params)
  }, [])

  return (
    <div className={classes.root}>
      <PageHeading variant="h2">Verify Registration</PageHeading>
      <Typography variant="body1">
        Please fill-out the details below to complete your account registration.
      </Typography>
      <AgencyVerifyForm
        verifyAgency={verifyAgency}
        params={params}
        verifystatus={verify_status}
        verifyerror={verify_error}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    verify_agency: state.agency.request_verify_agency,
    verify_status: state.agency.request_verify_agency_status,
    verify_error: state.agency.request_verify_agency_error,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      clearAllFilters: () => clearFilters(),
      verifyAgency: (params) => requestVerifyAgency(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(styles)(AgencyVerification)))
