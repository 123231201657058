import React from 'react'
import styled from 'styled-components'

import LogoPlaceholder from '../../../assets/images/logo-placeholder.png'

import { withStyles, Typography, Card, Grid, Button } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

/* const Wrap = styled.div`
  width: 90vw;
  padding-top: 20px;
` */

const styles = (theme) => ({
  root: {
    width: '100%',
  },
  card: {
    width: '100%',
    // maxHeight: '68vw',
    // margin: theme.spacing(1) + 'px ' + theme.spacing(2) + 'px',
    borderTopStyle: 'solid',
    borderTopWidth: theme.spacing(3),
    borderRadius: 0,
    padding: theme.spacing(3),
  },
  logoWrapper: {
    padding: theme.spacing(3) + 'px !important',
  },
  logo: {
    verticalAlign: 'middle',
    width: '100%',
  },
  nameWrapper: {
    padding: theme.spacing(2) + 'px !important',
    display: 'inline-flex',
    alignItems: 'center',
  },
  address: {
    paddingLeft: 0 + 'px !important',
    paddingRight: 0 + 'px !important',
    '& .MuiTypography-body2': {
      fontSize: '0.82rem',
    },
  },
  statWrap: {
    textAlign: 'center',
    '& .MuiPaper-root': {
      padding: theme.spacing(2),
    },
    '& button': {
      borderRadius: 0,
      width: '100%',
    },
  },
  statCardsWrap: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    width: 'calc(100% + ' + theme.spacing(4) + 'px)',
  },
  ctaCardsWrap: {
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
    width: 'calc(100% + ' + theme.spacing(4) + 'px)',
  },
})

const AgencyCard = (props) => {
  const { classes, agency } = props
  const addressRegex = / *AU *$|AUS *$/gm
  const [routerChange] = useRouterChange();

  return (
    <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
      <div
        className={classes.root}
        onClick={() => routerChange('/agency/results/' + agency._id)}
      >
        <Card className={classes.card} style={{ borderColor: '#000000' }}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={6}
              className={classes.logoWrapper}
              style={{ backgroundColor: '#000000' }}
            >
              <img
                className={classes.logo}
                src={LogoPlaceholder}
                alt={'Logo Placeholder'}
              />
            </Grid>
            <Grid item xs={6} className={classes.nameWrapper}>
              {agency && agency.companyName && (
                <Typography variant="subtitle2" component="strong">
                  {agency.registeredTradeName ? agency.registeredTradeName : agency.companyName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} className={classes.address}>
              <Typography variant="body2" fontSize="small">
                {agency.addressText && agency.addressText.replace(addressRegex, '')}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} className={classes.statCardsWrap}>
            <Grid item xs={6} className={classes.statWrap}>
              <Card>
                <Typography variant="h4" component="p">
                  105
                </Typography>
                <Typography variant="body2" component="p">
                  Properties sold in Macedon Ranges Shire
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6} className={classes.statWrap}>
              <Card>
                <Typography variant="h4" component="p">
                  5
                </Typography>
                <Typography variant="body2" component="p">
                  Properties for rent in Macedon Ranges Shire
                </Typography>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.ctaCardsWrap}>
            <Grid item xs={6} className={classes.statWrap}>
              <Button variant="contained" color="primary" disableElevation>
                Get in Touch
              </Button>
            </Grid>
            <Grid item xs={6} className={classes.statWrap}>
              <Button variant="outlined" color="default" disableElevation>
                View Profile
              </Button>
            </Grid>
          </Grid>
        </Card>
      </div>
    </Grid>
  )
}

export default withStyles(styles)(AgencyCard)
