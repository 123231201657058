import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Typography, Grid, withStyles, Button } from "@material-ui/core";

import DynamicIcons from "../../components/Mobile/DynamicIcons";
import Searchbar from '../../components/Web/TradeSearchbar';
import PropertySuggestionCards from '../../components/Mobile/Property/PropertySuggestionCards';
import { PROPERTY_CARDS, TRADES } from '../../components/Mobile/Property/constants';
import TradingBanner from '../../components/Web/Trading/TradingBanner'
import CustomSelect from '../../components/Web/Trading/CustomSelect';
import Crumbs from '../../components/Web/Header/Crumbs';

/** actions */
import {
  setPropertySuburbSearch,
  clearSuburbSearch,
} from '../../store/actions';

/** api redux request */
import {
  requestPropertySearch,
} from "../../store/api";
import { useRouterChange } from "utils/router.utils";

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '20px',
    lineHeight: '1.19',
    padding: '10px',
    zIndex: 10,
    position: 'relative',
    marginTop: '20px',
    marginBottom: '20px'
  },
})(Typography);

const BackButton = withStyles({
  root: {
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);

const styles = theme => ({
  root: {
    backgroundColor: '#FFF',
    paddingLeft: 90,
  },
  content: {
    margin: theme.spacing(2)
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    zIndex: 20,
    backgroundColor: '#FFF',
    position: 'relative'
  },
  topAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
    top: 0,
    transform: 'translateY(-56px)'
  },
  gridWrap: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    '& .MuiCard-root': {
      paddingBottom: theme.spacing(1),
      height: 'auto'
    }
  },
  searchbarWrap: {
    display: 'flex',
  },
  crumbsWrap: {
    paddingLeft: theme.spacing(5) + 'px !important',
    paddingRight: theme.spacing(5) + 'px !important',
    paddingBottom: theme.spacing(0) + 'px !important',
  }
});

const TradesResults = props => {

  const {
    classes
  } = props;
  const [routerChange] = useRouterChange()

  const [category, setCategory] = React.useState(null);

  const handleCategory = (data) => {
    setCategory(data)
  }

  function checkCrumbsValue() {
    return { label: 'Search to find your perfect trade or service', route: '/trades/results' }
  }


  React.useEffect(() => {

  }, [])



  const categoryOptions = [
    {
      id: 0,
      label: 'Select a category',
      value: 'select'
    }, {
      id: 1,
      label: 'All',
      value: 'all'
    },
    {
      id: 2,
      label: 'Landscaper',
      value: 'landscaper'
    },
    {
      id: 3,
      label: 'Plumber',
      value: 'plumber'
    },
    {
      id: 4,
      label: 'Gardener',
      value: 'gardener'
    },
    {
      id: 5,
      label: 'Interior Designer',
      value: 'interior-designer'
    },
    {
      id: 6,
      label: 'Landscape Designer',
      value: 'landscape-designer'
    },
    {
      id: 7,
      label: 'Electrician',
      value: 'electrician'
    },
    {
      id: 8,
      label: 'Carpet Cleaner',
      value: 'carpet-cleaner'
    }
  ]

  return (
    <div className={classes.root}>

      {/* <div className={classes.backButtonContainer}>
        <BackButton 
          variant="text" 
          disableRipple={true} 
          fullWidth={false} 
          onClick={()=> routerChange('/trades')}>
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
      </div>

      <div className={classes.content}>
        <StyledText variant="h2">
            Find a trade or service
        </StyledText>
      </div> */}

      <TradingBanner />



      <div className={classes.cardContent}>
        <Grid container spacing={8} className={classes.gridWrap}>
          <Grid item xs={12} className={classes.crumbsWrap}>
            <Crumbs type={'DARK'} crumbsOption={checkCrumbsValue()} />
          </Grid>
          <Grid item container xs={12} spacing={8} className={classes.searchbarWrap}>
            <Grid item xs={6}>
              <Searchbar
                type={'search'}
                sortType={'trades-results'}
                showSearchBar={true}
                showFilters={false}

                trades={TRADES}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                options={categoryOptions}
                handleCategory={handleCategory}
                isClearFilter={false}
                value={category}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={8} style={{ margin: '0 auto' }}>
            <Grid container spacing={3}>
              {
                TRADES && TRADES.map((value, index) => {
                  return <Grid item xs={6} lg={4}><PropertySuggestionCards key={index} trades={value} type={'trades'} /></Grid>
                })
              }
            </Grid>
          </Grid>

        </Grid>
      </div>

    </div>
  );
};

const mapStateToProps = state => {
  return {
    stepCounter: null
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      searchProperty: params => requestPropertySearch(params),
      setPropertySearch: (params) => setPropertySuburbSearch(params),
      clearPropertySuburb: () => clearSuburbSearch(),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TradesResults));
