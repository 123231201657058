const checkFilterKeyCategory = (filterKey) => {
  let filterKeyCategory = null

  switch (filterKey) {
    case 'state':
    case 'radius_distance':
    case 'nearby_filters':
    case 'property_type':
    case 'features': {
      filterKeyCategory = 'array_obj_id_active'
      break
    }

    case 'suburbs': {
      filterKeyCategory = 'array_obj_id'
      break
    }

    case 'include_nearby_suburbs':
    case 'bedroom':
    case 'bathroom':
    case 'carparking':
    case 'keyword':
    case 'furnished':
    case 'exclude_under_offers': {
      filterKeyCategory = 'key_value'
      break
    }

    case 'price':
    case 'price_rent':
    case 'land_size': {
      filterKeyCategory = 'range'
      break
    }

    case 'available_date': {
      filterKeyCategory = 'date'
      break
    }

    default: {
      filterKeyCategory = null
      break
    }
  }

  return filterKeyCategory
}

export default checkFilterKeyCategory
