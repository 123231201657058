import React from 'react'
import clsx from 'clsx'

import { FOOTER_SOCIAL_LINKS } from './default'

import PMLogo from '../../../assets/images/pm-logo-inverted.png'

import { withStyles, Typography, Link } from '@material-ui/core'
import { useRouterChange } from 'utils/router.utils'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {
    width: '100vw',
    height: '231px',
    backgroundColor: '#E5E5E5',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    width: '100%',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  colContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignContent: 'center',
    width: 'auto',
    height: '20vh',
    paddingTop: 15,
    paddingBottom: 15,
  },
  colContainerMargined: {
    marginRight: '15%',
  },
  bottomContainer: {
    width: '100%',
    height: '16px',
    position: 'absolute',
    bottom: 0,
  },
  coloredContainer: {
    width: '100%',
    height: '16px',
    backgroundColor: '#35C0CA',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    width: '100%',
  },
  imageLogo: {
    width: 230,
    height: 'auto',
  },
  socialContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 15,
  },
  socialLogo: {
    width: 20,
    height: 20,
    position: 'relative',
    top: 0,
    marginRight: 20,
    transition: 'top 0.2s ease',
    cursor: 'pointer',
    '&:hover': {
      top: '-2px',
    },
  },
  normalLinks: {
    color: '#333',
    cursor: 'pointer',
    fontSize: '12px',
    textAlign: 'left',
    padding: '3px 0',
    margin: '0 10px',
    '&:hover': {
      fontWeight: '700',
      textDecoration: 'none',
      color: '#35C0CA !important',
    },
  },
})

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#9B9B9B',
    fontSize: '12px',
    lineHeight: '1.19',
    margin: '8px 0 16px',
  },
})(Typography)

const Footer = (props) => {
  const { classes } = props
  const [routerChange] = useRouterChange()
  const { triggerAnalyticsEvent } = useGoogleEvents()

  const renderSocialIcons = () =>
    FOOTER_SOCIAL_LINKS.map((social) => (
      <Link key={social.name} href={social.route} target="_blank" rel="noopener">
        <img className={classes.socialLogo} src={social.logo} alt="Social Icons" />
      </Link>
    ))

  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
        <div className={clsx(classes.colContainer, classes.colContainerMargined)}>
          <div className={classes.logoContainer}>
            <img className={classes.imageLogo} src={PMLogo} />
          </div>
          <StyledText>
            A modern, intuitive, and exciting new way to discover your perfect property
            and find your sense of place.
          </StyledText>
          <div className={classes.socialContainer}>
            {renderSocialIcons()}
            <Link
              rel="noopener"
              className={classes.normalLinks}
              onClick={(e) => {
                //e.preventDefault()
                triggerAnalyticsEvent({
                  event: 'footer_actions',
                  eventFrom: 'Mobile',
                  actionScope: 'footer_links',
                  actionName: 'Footer Links',
                  actionTarget: 'Clicked /privacy-policy from Footer',
                  actionReference: 'footer',
                })
                routerChange('/privacy-policy')
              }}
              href="/privacy-policy"
            >
              Privacy&nbsp;Policy
            </Link>
            |
            <Link
              rel="noopener"
              className={classes.normalLinks}
              onClick={(e) => {
                triggerAnalyticsEvent({
                  event: 'footer_actions',
                  eventFrom: 'Mobile',
                  actionScope: 'footer_links',
                  actionName: 'Footer Links',
                  actionTarget: 'Clicked /terms-and-conditions from Footer',
                  actionReference: 'footer',
                })
                routerChange('/terms-and-conditions')
              }}
              href="/terms-and-conditions"
            >
              Terms&nbsp;&amp;&nbsp;Conditions
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.bottomContainer}>
        <div className={classes.coloredContainer}></div>
      </div>
    </div>
  )
}

export default withStyles(styles)(Footer)
