import React from "react";
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

const Title = withStyles({
  root: {
    fontWeight:300, 
    fontSize:'18px', 
    marginBottom:'10px',
    letterSpacing:'-0.525px',
    lineHeight:'32px',
    color:'rgba(0, 0, 0, 0.87)'
  }
})(Typography);


const Content = withStyles({
  root: {
    fontWeight:400, 
    fontSize:'14px', 
    letterSpacing:'0.09px',
    lineHeight:'24px',
    color:'#4D4D4D'
  }
})(Typography);

const HeadingContentSmall = props => {
  const { title, content, style } = props;
  return (
    <div style={style}>
        {
          title && 
            <Title variant="h1">
               {title}
            </Title>
        }

        {
          content &&
            <Content dangerouslySetInnerHTML={{ __html: content }} />
        }
    </div>
  )
};


export default HeadingContentSmall