import React from 'react';
import { Link } from "react-router-dom";
import Lottie from 'react-lottie';

import * as logoAnimation from '../../../assets/animations/pmate-logo.json'
import { withStyles } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0
  }
});

const LottieAnimation = props => {
  const { open, classes } = props;
  const [routerChange] = useRouterChange()
  const [isPaused, setPaused] = React.useState(false);
  const [isStopped, setStop] = React.useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: logoAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      height={400}
      width={400}
      isStopped={isStopped}
      isPaused={isPaused}
      eventListeners={[
        {
          eventName: 'complete',
          callback: () => routerChange(),
        }
      ]} />
  );
};

export default withStyles(styles)(LottieAnimation);
