import React from "react";
import { Typography, withStyles } from '@material-ui/core';


const Heading = props => {
  return (
    <div>
      <Typography {...props}>{props.children}</Typography>
    </div>
  );
};

export default Heading;