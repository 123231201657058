import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import SnackbarProvider from 'react-simple-snackbar';

import styled from 'styled-components';
import { connect } from 'react-redux';

import AuthLayout from './layouts/Mobile/AuthLayout';
import MainLayout from './layouts/Mobile/MainLayout';
import EmptyLayout from './layouts/Mobile/EmptyLayout';

/** Import All Routes */
import {
  AgencyRoutes,
  AgentRoutes,
  CommonRoutes,
  HomeRoutes,
  PerfectPropertyRoutes,
  SavedSearchRoutes,
  SearchRoutes,
  TradesRoutes,
  MobileTermsAndPrivacy,
} from './routes/Mobile';

/** Import Themes */
import DARK_THEME from './theme/dark';
import LIGHT_THEME from './theme/light';

import { createBrowserHistory } from 'history';

import { APP_MODE, APP_VERSION } from '../src/utils/constants.utils';

import { SET_API_TOKEN } from '../src/utils/api';

const history = createBrowserHistory();

const NotFound = () => {
  return <div>NotFound</div>;
};

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <AuthLayout>
          <Component {...matchProps} />
        </AuthLayout>
      )}
    />
  );
};

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout>
          <Component {...matchProps} />
        </MainLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <EmptyLayout>
          <Component {...matchProps} />
        </EmptyLayout>
      )}
    />
  );
};

const ScrollContainer = styled.div`
  height: ${(props) => props.vh}px;
  -webkit-overflow-scrolling: touch;
`;

const MobileApp = (props) => {
  const { settings } = props;

  const [innerHeight, setInnerHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    console.debug('*************************');
    console.debug('=== APP MODE (MOBILE) ====> ' + APP_MODE);
    console.debug('=== APP VERSION => ' + APP_VERSION);
    console.debug('*************************');

    /** prevent body scroll */
    document.body.style.overflow = 'hidden';

    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });
    /**
     * SET API TOKEN */
    if (
      localStorage.getItem('persist:root') !== null &&
      JSON.parse(JSON.parse(localStorage.getItem('persist:root')).authentication) &&
      JSON.parse(JSON.parse(localStorage.getItem('persist:root')).authentication).token
    ) {
      SET_API_TOKEN(JSON.parse(JSON.parse(localStorage.getItem('persist:root')).authentication).token.token);
    }
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  function checkIfFilterOpen() {
    const { settings } = props;
    return settings.showFilter === true || settings.mppScroll === true ? 'hidden' : 'scroll';
  }

  return (
    <MuiThemeProvider theme={settings.darkMode ? DARK_THEME : LIGHT_THEME}>
      <CssBaseline />
      <SnackbarProvider>
        <ScrollContainer id="scroll-container" vh={innerHeight} style={{ overflow: checkIfFilterOpen() }}>
          <Router history={history}>
            <Switch>
              {HomeRoutes.map((value, index) => {
                return <MainRoute key={index} path={value.path} component={value.component} exact />;
              })}
              {TradesRoutes.map((value, index) => {
                return <MainRoute key={index} path={value.path} component={value.component} exact />;
              })}
              {AgentRoutes.map((value, index) => {
                return <MainRoute key={index} path={value.path} component={value.component} exact />;
              })}
              {AgencyRoutes.map((value, index) => {
                return <MainRoute key={index} path={value.path} component={value.component} exact />;
              })}
              {CommonRoutes.map((value, index) => {
                return <AuthRoute key={index} path={value.path} component={value.component} exact />;
              })}
              {/* <EmptyRoute component={NotFound} /> */}
              <Redirect to={'/'} />
            </Switch>
          </Router>
        </ScrollContainer>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings,
  };
};

export default connect(mapStateToProps, null)(MobileApp);
