import React from "react";
import PropTypes from "prop-types";
import { Typography, Button, withStyles } from "@material-ui/core";
import PMLogo from "assets/images/pm-logo-inverted.png";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import { useRouterChange } from "utils/router.utils";

const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 20,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: "10vw",
  },
  button: {
    marginLeft: 20,
    borderRadius: 20,
    minWidth: 180,
    textTransform: "capitalize",
  },
});

const AdminsHeader = (props) => {
  const {
    classes,
    type,

    match,
  } = props;
  const [routerChange] = useRouterChange()

  const goTo = () => {
    if (type === "superadmin" && match) {
      routerChange("/superadmin/manage-agencies/" + match?.params?.id + "/add-an-admin");
    }
    if (type === "agency") {
      routerChange("/agency/manage-admins/add-an-admin");
    }
  };

  return (
    <div className={clsx(classes.root)}>
      <Button
        className={classes.button}
        color={"primary"}
        variant="outlined"
        disableElevation
        startIcon={<AddIcon />}
        onClick={goTo}
      >
        {"Add an Admin"}
      </Button>
    </div>
  );
};

AdminsHeader.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AdminsHeader);
