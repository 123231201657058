export const SUPERADMIN_ACTION_TYPES = {
  SUPERADMIN_LOGIN: {
    REQUEST: 'superadmin/SUPERADMIN_LOGIN_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_LOGIN_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_LOGIN_FAILED',
  },
  SUPERADMIN_FORGOT_PASSWORD: {
    REQUEST: 'superadmin/SUPERADMIN_FORGOT_PASSWORD_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_FORGOT_PASSWORD_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_FORGOT_PASSWORD_FAILED',
  },
  SUPERADMIN_RESET_PASSWORD: {
    REQUEST: 'superadmin/SUPERADMIN_RESET_PASSWORD_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_RESET_PASSWORD_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_RESET_PASSWORD_FAILED',
  },
  SUPERADMIN_GET_ALL_AGENCY: {
    REQUEST: 'superadmin/SUPERADMIN_GET_ALL_AGENCY_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_ALL_AGENCY_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_ALL_AGENCY_FAILED',
    CLEAR: 'superadmin/SUPERADMIN_GET_ALL_AGENCY_CLEAR',
  },
  SUPERADMIN_GET_AGENCY_DATA: {
    REQUEST: 'superadmin/SUPERADMIN_GET_AGENCY_DATA_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_AGENCY_DATA_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_AGENCY_DATA_FAILED',
    CLEAR: 'superadmin/SUPERADMIN_GET_AGENCY_DATA_CLEAR',
  },
  SUPERADMIN_UPDATE_ACCOUNT_STATUS: {
    REQUEST: 'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_UPDATE_ACCOUNT_STATUS_FAILED',
  },
  SUPERADMIN_PAUSE_ACCOUNT: {
    REQUEST: 'superadmin/SUPERADMIN_PAUSE_ACCOUNT_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_PAUSE_ACCOUNT_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_PAUSE_ACCOUNT_FAILED',
  },
  SUPERADMIN_DELETE_ACCOUNT: {
    REQUEST: 'superadmin/SUPERADMIN_DELETE_ACCOUNT_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_DELETE_ACCOUNT_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_DELETE_ACCOUNT_FAILED',
  },
  SUPERADMIN_GET_ALL_AGENTS: {
    REQUEST: 'superadmin/SUPERADMIN_GET_ALL_AGENTS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_ALL_AGENTS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_ALL_AGENTS_FAILED',
  },
  SUPERADMIN_SAVE_DRAFT_AGENCY: {
    REQUEST: 'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_SAVE_DRAFT_AGENCY_FAILED',
  },
  SUPERADMIN_SEND_DOCUSIGN_AGENCY: {
    REQUEST: 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_SEND_DOCUSIGN_AGENCY_FAILED',
  },
  SUPERADMIN_RESEND_DOCUSIGN_AGENCY: {
    REQUEST: 'superadmin/SUPERADMIN_RESEND_DOCUSIGN_AGENCY_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_RESEND_DOCUSIGN_AGENCY_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_RESEND_DOCUSIGN_AGENCY_FAILED',
  },
  SUPERADMIN_GET_OFFICE_LISTS: {
    REQUEST: 'superadmin/SUPERADMIN_GET_OFFICE_LISTS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_OFFICE_LISTS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_OFFICE_LISTS_FAILED',
    CLEAR: 'superadmin/SUPERADMIN_GET_OFFICE_LISTS_CLEAR',
  },
  SUPERADMIN_GET_OFFICE_DATA: {
    REQUEST: 'superadmin/SUPERADMIN_GET_OFFICE_DATA_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_OFFICE_DATA_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_OFFICE_DATA_FAILED',
    CLEAR: 'superadmin/SUPERADMIN_GET_OFFICE_DATA_CLEAR',
  },
  SUPERADMIN_SEND_OFFICE_REFERENCE: {
    REQUEST: 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_SEND_OFFICE_REFERENCE_FAILED',
  },

  SUPERADMIN_GET_EMAIL_LOGS: {
    REQUEST: 'superadmin/SUPERADMIN_GET_EMAIL_LOGS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_EMAIL_LOGS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_EMAIL_LOGS_FAILED',
  },
  SUPERADMIN_GET_EMAIL_CONTENT: {
    REQUEST: 'superadmin/SUPERADMIN_GET_EMAIL_CONTENT_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_EMAIL_CONTENT_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_EMAIL_CONTENT_FAILED',
  },
  SUPERADMIN_GET_ALL_CHANGE_REQUESTS: {
    REQUEST: 'superadmin/SUPERADMIN_GET_ALL_CHANGE_REQUESTS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_ALL_CHANGE_REQUESTS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_ALL_CHANGE_REQUESTS_FAILED',
  },
  SUPERADMIN_GET_CHANGE_REQUESTS_DATA: {
    REQUEST: 'superadmin/SUPERADMIN_GET_CHANGE_REQUESTS_DATA_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_CHANGE_REQUESTS_DATA_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_CHANGE_REQUESTS_DATA_FAILED',
    CLEAR: 'superadmin/SUPERADMIN_GET_CHANGE_REQUESTS_DATA_CLEAR',
  },

  SUPERADMIN_UPDATE_CHANGE_REQUESTS: {
    REQUEST: 'superadmin/SUPERADMIN_UPDATE_CHANGE_REQUESTS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_UPDATE_CHANGE_REQUESTS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_UPDATE_CHANGE_REQUESTS_FAILED',
  },

  SUPERADMIN_BULK_UPLOAD: {
    REQUEST: 'superadmin/SUPERADMIN_BULK_UPLOAD_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_BULK_UPLOAD_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_BULK_UPLOAD_FAILED',
  },
  SUPERADMIN_ADD_SINGLE_AGENT: {
    REQUEST: 'superadmin/SUPERADMIN_ADD_SINGLE_AGENT_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_ADD_SINGLE_AGENT_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_ADD_SINGLE_AGENT_FAILED',
  },
  SUPERADMIN_DELETE_AGENT: {
    REQUEST: 'superadmin/SUPERADMIN_DELETE_AGENT_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_DELETE_AGENT_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_DELETE_AGENT_FAILED',
  },
  SUPERADMIN_UPDATE_AGENT: {
    REQUEST: 'superadmin/SUPERADMIN_UPDATE_AGENT_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_UPDATE_AGENT_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_UPDATE_AGENT_FAILED',
  },
  SUPERADMIN_GET_AGENT_DETAILS: {
    REQUEST: 'superadmin/SUPERADMIN_GET_AGENT_DETAILS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_AGENT_DETAILS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_AGENT_DETAILS_FAILED',
    CLEAR: 'superadmin/SUPERADMIN_GET_AGENT_DETAILS_CLEAR',
  },
  SUPERADMIN_RESEND_INVITATION: {
    REQUEST: 'superadmin/SUPERADMIN_RESEND_INVITATION_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_RESEND_INVITATION_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_RESEND_INVITATION_FAILED',
  },
  SUPERADMIN_UPLOAD_AGENT_IMAGE: {
    REQUEST: 'superadmin/SUPERADMIN_UPLOAD_AGENT_IMAGE_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_UPLOAD_AGENT_IMAGE_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_UPLOAD_AGENT_IMAGE_FAILED',
  },
  SUPERADMIN_UPDATE_ACCOUNT_SETTINGS: {
    REQUEST: 'superadmin/SUPERADMIN_UPDATE_ACCOUNT_SETTINGS_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_UPDATE_ACCOUNT_SETTINGS_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_UPDATE_ACCOUNT_SETTINGS_FAILED',
  },
  SUPERADMIN_CHECK_AGENT_EMAIL: {
    REQUEST: 'superadmin/SUPERADMIN_CHECK_AGENT_EMAIL_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_CHECK_AGENT_EMAIL_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_CHECK_AGENT_EMAIL_FAILED',
  },
  SUPERADMIN_VALIDATE_TOKEN: {
    REQUEST: 'superadmin/SUPERADMIN_VALIDATE_TOKEN_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_VALIDATE_TOKEN_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_VALIDATE_TOKEN_FAILED',
  },
  GENERAL_SUPERADMIN: {
    CLEAR_ERROR: 'superadmin/CLEAR_ERROR',
    CLEAR_STATUS: 'superadmin/CLEAR_STATUS',
    LOG_OUT: 'superadmin/LOG_OUT',
    RESET_STATES: 'superadmin/RESET_STATES',
  },
  SUPERADMIN_ADD_AGENCY: {
    REQUEST: 'superadmin/SUPERADMIN_ADD_AGENCY_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_ADD_AGENCY_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_ADD_AGENCY_FAILED',
    CLEAR: 'superadmin/SUPERADMIN_ADD_AGENCY_CLEAR',
  },
  SUPERADMIN_GET_NOTIFICATION_LIST: {
    REQUEST: 'superadmin/SUPERADMIN_GET_NOTIFICATION_LIST_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_NOTIFICATION_LIST_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_NOTIFICATION_LIST_FAILED',
  },
  SUPERADMIN_SEND_ACTIVATION_EMAIL: {
    REQUEST: 'superadmin/SUPERADMIN_SEND_ACTIVATION_EMAIL_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_SEND_ACTIVATION_EMAIL_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_SEND_ACTIVATION_EMAIL_FAILED',
  },
  SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL: {
    REQUEST: 'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_SEND_REQUEST_CREDIT_CARD_EMAIL_FAILED',
  },
  SUPERADMIN_GET_REPORTS_DATA: {
    REQUEST: 'superadmin/SUPERADMIN_GET_REPORTS_DATA_REQUEST',
    SUCCESS: 'superadmin/SUPERADMIN_GET_REPORTS_DATA_SUCCESS',
    FAILED: 'superadmin/SUPERADMIN_GET_REPORTS_DATA_FAILED',
  },
};
