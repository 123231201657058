import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function GoogleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path id="Fill-1" style={{ fill:"#FBBC05"}} d="M7.4,12c0-0.5,0.1-1.1,0.2-1.6L4.9,8.3C4.4,9.4,4.1,10.7,4.1,12c0,1.3,0.3,2.6,0.8,3.7l2.8-2.2C7.5,13.1,7.4,12.5,7.4,12"/>
      <path id="Fill-2" style={{ fill:"#EB4335"}} d="M12.2,7c1.2,0,2.2,0.4,3,1.1l2.4-2.4c-1.5-1.3-3.3-2.1-5.4-2.1c-3.2,0-6,1.9-7.4,4.7l2.8,2.2C8.3,8.5,10.1,7,12.2,7"/>
      <path id="Fill-3" style={{ fill:"#34A853"}} d="M12.2,17c-2.1,0-4-1.4-4.6-3.4l-2.8,2.2c1.3,2.8,4.1,4.7,7.4,4.7c2,0,3.9-0.7,5.3-2.1L15,16.2C14.2,16.7,13.3,17,12.2,17"/>
      <path id="Fill-4" style={{ fill:"#4285F4"}} d="M20.1,12c0-0.5-0.1-1-0.2-1.5h-7.6v3.2h4.4c-0.2,1.1-0.8,2-1.7,2.5l2.6,2.1C19.1,16.9,20.1,14.7,20.1,12"/>
    </SvgIcon>
  );
}

export function GoogleIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GoogleIcon />
      <GoogleIcon color="primary" />
      <GoogleIcon color="secondary" />
      <GoogleIcon color="action" />
      <GoogleIcon color="disabled" />
      <GoogleIcon style={{ color: green[500] }} />
    </div>
  );
}
