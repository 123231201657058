import compareGlobalFilters from 'utils/compareGlobalFilters'
import { typeChecker } from 'utils/compareGlobalFilters'
import checkFilterKeyCategory from './checkFilterKeyCategory'

import { GLOBAL_FILTER_DEFAULT } from 'constants/globalfilters'

// MPP
// state =  array_obj_id_active x
// radius_distance = array-obj-id-active x
// nearby_filters = array-obj-id-active x

// suburbs = array_obj_id x
// include_nearby_suburbs = key_value x
// property_type = array_obj_id_active x
// price = range x
// price_rent = range x
// bedroom = key_value x
// bathroom = key_value x
// carparking = key_value x
// land_size = range x
// features = array_obj_id_active x
// available_date = date x
// keyword = key_value x
// exclude_under_offers = key_value x
// furnished = key_value x

// KEYS
// array_obj_id_active
// array_obj_id
// key_value
// range
// date

const mutateFilterToQueryString = (filterKey, filterValue) => {
  const filterKeyCategory = checkFilterKeyCategory(filterKey)
  let queryString

  switch (filterKeyCategory) {
    case 'array_obj_id_active': {
      const activeFilterArray = []

      filterValue.forEach((filter) => {
        if (filter.active) {
          activeFilterArray.push(filter.id)
        }
      })

      queryString = `${filterKey}=${activeFilterArray.toString().replace(/,/g, '%2C')}`
      break
    }

    case 'array_obj_id': {
      const filterArray = []

      filterValue.forEach((filter) => {
        filterArray.push(filter.id)
      })

      queryString = `${filterKey}=${filterArray.toString().replace(/,/g, '%2C')}`
      break
    }

    case 'key_value': {
      typeChecker(filterValue) === 'object'
        ? (queryString = `${filterKey}=${filterValue.value}${
            filterValue.exact ? `-${filterValue.exact}` : ''
          }`)
        : (queryString = `${filterKey}=${filterValue}`)
      break
    }

    case 'range': {
      queryString = `${filterKey}=${filterValue.min}-${filterValue.max}`
      break
    }

    case 'date': {
      //TODO:
      // queryParameters.set(filterKey, `${filterValue.min}-${filterValue.max}`)
      break
    }

    default: {
      console.debug('error in converting filter into query string')
    }
  }

  return queryString
}

const generateUrlQuery = (path, currentFilters) => {
  const searchRef =
    path === '/perfect-property' ? 'mpp' : path === '/rent' ? 'rent' : 'normal'

  const activeFilters = compareGlobalFilters(
    searchRef,
    currentFilters,
    GLOBAL_FILTER_DEFAULT,
    false,
    true
  )

  const queryStringArray = []

  activeFilters.forEach((filter) => {
    queryStringArray.push(mutateFilterToQueryString(filter.key, filter.value))
  })

  return queryStringArray.toString().replace(/,/g, '&')
}

export default generateUrlQuery
