import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function AppleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path style={{fillRule:"evenodd", clipRule:"evenodd", fill:"#0B0B0A"}} d="M22,17.1c-0.5,1.1-0.8,1.7-1.4,2.7c-0.9,1.4-2.2,3.2-3.9,3.2c-1.4,0-1.8-0.9-3.8-0.9c-2,0-2.4,0.9-3.8,0.9c-1.6,0-2.9-1.6-3.8-3C2.7,16,2.4,11.4,4,8.9c1.1-1.8,2.9-2.8,4.6-2.8c1.7,0,2.8,0.9,4.2,0.9c1.4,0,2.2-0.9,4.2-0.9c1.5,0,3.1,0.8,4.2,2.2C17.6,10.4,18.2,15.7,22,17.1M15.6,4.6c0.7-0.9,1.3-2.2,1.1-3.6c-1.2,0.1-2.6,0.8-3.4,1.8c-0.7,0.9-1.3,2.2-1.1,3.5C13.5,6.3,14.8,5.6,15.6,4.6"/>
    </SvgIcon>
  );
}

export function AppleIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppleIcon />
      <AppleIcon color="primary" />
      <AppleIcon color="secondary" />
      <AppleIcon color="action" />
      <AppleIcon color="disabled" />
      <AppleIcon style={{ color: green[500] }} />
    </div>
  );
}
