import React, { useEffect } from 'react';

import { Box, Grid, MenuItem, Select, withStyles, Typography } from '@material-ui/core';

import FormField from '../Form/FormField';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import guyOnChair from '../../../assets/images/add-property.png';

const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF',
  },
  stepHint: {
    marginBottom: 25,
  },
  imageContainer: {
    display: 'none',
    '@media (min-width: 1280px)': {
      display: 'block',
    },
  },
  rightImage: {
    maxWidth: '100%',
    maxHeight: 315,
    display: 'block',
    height: 'auto',
    objectFit: 'cover',
  },
  agentTitle: {
    '& > p': {
      fontSize: 21,
      fontWeight: 300,
      lineHeight: '32px',
      marginBottom: 10,
      color: '#000',
    },
  },
  agentsSelect: {
    width: '60%',
    fontSize: 16,
    padding: '10px 12px',
    height: '34px',
    marginBottom: '15px',
  },
});

const defaultAgent = {
  firstName: '',
  lastName: '',
  email: '',
  telephone: '',
  image: '',
};

const agentReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        email: action.email,
        telephone: action.telephone,
      };

    default:
      return state;
  }
};

const AddPropertyStep1 = (props) => {
  const {
    classes,
    addPropertyFields,
    setAddPropertyFields,
    handleFieldChange,
    handleCheckField,
    errors,
    setIsFormInvalid,
    agencyAddPropertyAgents,
  } = props;

  const [vendorEmail, setVendorEmail] = React.useState(null);
  const [vendorPhone, setVendorPhone] = React.useState(null);

  const [agentInfo, dispatchAgentInfo] = React.useReducer(agentReducer, defaultAgent);

  const agentMenuHandler = (event) => {
    const agentPicked = agencyAddPropertyAgents?.filter((agent) => agent.email === event.target.value)[0];

    setAddPropertyFields((prev) => ({
      ...prev,
      agentInfo: {
        ...prev.agentInfo,
        firstName: {
          ...prev.agentInfo.firstName,
          value: agentPicked?.firstName || prev.agentInfo.firstName.value,
        },
        lastName: {
          ...prev.agentInfo.lastName,
          value: agentPicked?.lastName || prev.agentInfo.lastName.value,
        },
        email: {
          ...prev.agentInfo.email,
          value: agentPicked?.email || prev.agentInfo.email.value,
        },
        phone: {
          ...prev.agentInfo.phone,
          value: agentPicked?.telephone
            ? agentPicked?.telephone
            : agentPicked?.telephone === ''
            ? 'N/A'
            : prev.agentInfo.phone.value,
        },
        image: {
          ...prev.agentInfo.image,
          value: agentPicked?.image ? agentPicked?.image : agentPicked?.image === '' ? '' : prev.agentInfo.image.value,
        },
      },
    }));
  };

  useEffect(() => {
    const errorBucket = [];
    if (errors) {
      const errorsArr = Object.keys(errors);
      errorsArr.map((keys) => {
        if (errors[keys].status !== false) {
          errorBucket.push(true);
        }
      });
    }

    if (errorBucket.length > 0) {
      setIsFormInvalid(true);
    } else {
      setIsFormInvalid(false);
      if (agencyAddPropertyAgents && !addPropertyFields.agentInfo.email?.value) {
        setIsFormInvalid(true);
      }
      // if (
      //   !addPropertyFields.agentInfo.vendorsEmail?.value ||
      //   !addPropertyFields.agentInfo.vendorsPhone?.value
      // ) {
      //   setIsFormInvalid(true)
      // }
    }
  }, [addPropertyFields, agencyAddPropertyAgents]);

  return (
    <div className={classes.root}>
      <div className={classes.stepHint}>
        <Typography style={{ color: '#4d4d4d' }}>Your information</Typography>
      </div>
      {agencyAddPropertyAgents && (
        <Grid item xs={6} sm={6}>
          <Box width="100%">
            <Select
              className={classes.agentsSelect}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              displayEmpty
              value={addPropertyFields.agentInfo.email.value}
              renderValue={(selected) => {
                if (selected === '' && addPropertyFields.agentInfo.email.value) {
                  return addPropertyFields.agentInfo.email.value;
                }

                if (selected === '') {
                  return 'Pick an agent';
                } else {
                  return selected;
                }
              }}
              onChange={agentMenuHandler}
            >
              {agencyAddPropertyAgents.map((agent) => (
                <MenuItem key={agent.email} value={agent.email}>
                  {agent.email}
                </MenuItem>
              ))}
              {agencyAddPropertyAgents?.length === 0 && (
                <MenuItem key={'no-agent'} value={''}>
                  No agent available
                </MenuItem>
              )}
            </Select>
          </Box>
        </Grid>
      )}

      <div className={classes.agentTitle}>
        <Typography>Agent Information</Typography>
      </div>
      {agencyAddPropertyAgents ? (
        <Grid container spacing={4}>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'text'}
                  name={'firstName'}
                  value={addPropertyFields.agentInfo.firstName.value}
                  placeholder={'First name *'}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'text'}
                  name={'lastName'}
                  value={addPropertyFields.agentInfo.lastName.value}
                  placeholder={'Last name *'}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'text'}
                  name={'phone'}
                  value={addPropertyFields.agentInfo.phone.value}
                  placeholder={'Your Mobile Number *'}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'email'}
                  name={'agencyEmail'}
                  value={addPropertyFields.agentInfo.agencyEmail.value}
                  placeholder={'Agency Email Address *'}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'email'}
                  name={'vendorEmail'}
                  errorMsg={errors.vendorsEmail.message}
                  showError={errors.vendorsEmail.status}
                  value={addPropertyFields.agentInfo.vendorsEmail.value}
                  placeholder={'Vendors Email Address (Optional)'}
                  onChange={(e) => {
                    const value = e.target.value;
                    setVendorEmail(value);
                    handleFieldChange('agentInfo', null, 'vendorsEmail', value);
                    handleCheckField(value, value, 'vendorsEmail');
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'tel'}
                  name={'vendorsPhone'}
                  errorMsg={errors.vendorsPhone.message}
                  showError={errors.vendorsPhone.status}
                  value={addPropertyFields.agentInfo.vendorsPhone.value}
                  placeholder={'Vendors Phone (Optional)'}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    setVendorPhone(value);
                    handleFieldChange('agentInfo', null, 'vendorsPhone', value);

                    handleCheckField(value, value, 'vendorsPhone');
                  }}
                  telformat={'9999999999'}
                  maxlength={14}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} className={classes.imageContainer}>
            <img className={classes.rightImage} src={guyOnChair} alt="" />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12} lg={7}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'text'}
                  name={'firstName'}
                  value={addPropertyFields.agentInfo.firstName.value}
                  placeholder={'First name *'}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'text'}
                  name={'lastName'}
                  value={addPropertyFields.agentInfo.lastName.value}
                  placeholder={'Last name *'}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'email'}
                  name={'email'}
                  value={addPropertyFields.agentInfo.email.value}
                  placeholder={'Your Email Address *'}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'text'}
                  name={'phone'}
                  value={addPropertyFields.agentInfo.phone.value}
                  placeholder={'Your Mobile Number *'}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'email'}
                  name={'agencyEmail'}
                  value={addPropertyFields.agentInfo.agencyEmail.value}
                  placeholder={'Agency Email Address *'}
                  disabled
                />
              </Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'email'}
                  name={'vendorEmail'}
                  errorMsg={errors.vendorsEmail.message}
                  showError={errors.vendorsEmail.status}
                  value={addPropertyFields.agentInfo.vendorsEmail.value}
                  placeholder={'Vendors Email Address (Optional)'}
                  onChange={(e) => {
                    const value = e.target.value;
                    setVendorEmail(value);
                    handleFieldChange('agentInfo', null, 'vendorsEmail', value);
                    handleCheckField(value, value, 'vendorsEmail');
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}></Grid>
              <Grid item xs={6} sm={6}>
                <FormField
                  type={'tel'}
                  name={'vendorsPhone'}
                  errorMsg={errors.vendorsPhone.message}
                  showError={errors.vendorsPhone.status}
                  value={addPropertyFields.agentInfo.vendorsPhone.value}
                  placeholder={'Vendors Phone (Optional)'}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    setVendorPhone(value);
                    handleFieldChange('agentInfo', null, 'vendorsPhone', value);

                    handleCheckField(value, value, 'vendorsPhone');
                  }}
                  telformat={'9999999999'}
                  maxlength={14}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5} className={classes.imageContainer}>
            <img className={classes.rightImage} src={guyOnChair} alt="" />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddPropertyStep1));
