import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuperBreadCrumbs, EmailLogsTable, Snackybar } from 'components/Web/Superadmin';

import AlertEmailDialogBox from 'components/Web/DialogContainer/AlertEmailDialogBox';
import { Loader } from 'components/Common';
/** redux */
import { superadminActions } from 'store/actions';

import { requestPOSTAPISuperadmin, requestGETAPISuperadmin } from 'store/api';
import {
  getStatus,
  getErrors,
  getSuperadminEmailLogs,
  getSuperadminEmailContent,
} from 'store/selectors/superadmin.selectors';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    width: '100%',
    height: '100vh',
    paddingLeft: '10vw',
  },
});

const SuperadminEmailLogs = (props) => {
  const { classes, history } = props;

  const dispatch = useDispatch();

  const STATUS = useSelector(getStatus);
  const ERROR = useSelector(getErrors);
  const EMAIL_LOGS = useSelector(getSuperadminEmailLogs);
  const EMAIL_CONTENT = useSelector(getSuperadminEmailContent);

  // Dialog states
  const [dialogContent, setDialogContent] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState({
    open: false,
    type: 'info',
    message: '',
  });

  const CRUMBS = [
    {
      type: 'secondary',
      label: 'Home',
      path: '/superadmin/dashboard',
    },
    {
      type: 'secondary',
      label: 'Dashboard',
      path: '/superadmin/dashboard',
    },
    {
      type: 'primary',
      label: 'Email Logs',
      path: '/superadmin/email-logs',
    },
  ];

  useEffect(() => {
    handleGetAllEmailLogs();
  }, []);

  useEffect(() => {
    if (
      STATUS === 'superadmin/SUPERADMIN_GET_EMAIL_LOGS_REQUEST' ||
      STATUS === 'superadmin/SUPERADMIN_GET_EMAIL_CONTENT_REQUEST'
    ) {
      setLoading(true);
    }
    if (
      STATUS === 'superadmin/SUPERADMIN_GET_EMAIL_LOGS_FAILED' ||
      STATUS === 'superadmin/SUPERADMIN_GET_EMAIL_CONTENT_FAILED'
    ) {
      setLoading(false);
      setShowMessage({
        open: true,
        type: 'error',
        message: ERROR?.message ? ERROR?.message : ERROR,
      });
      handleClearError();
    }
    if (STATUS === 'superadmin/SUPERADMIN_GET_EMAIL_CONTENT_SUCCESS') {
      setLoading(false);
      showEmailDetails();
    }
    handleClearStatus();
  }, [STATUS, ERROR]);

  const handleMessage = (state) => {
    setShowMessage({
      ...showMessage,
      open: state,
    });
  };

  const showEmailDetails = () => {
    setShowDialog(true);
    setDialogContent(EMAIL_CONTENT?.mailHistory?.mailContent);
  };

  /** API Request */
  const handleGetAllEmailLogs = (data) => {
    let request = {
      query: null, // ? ?take=10&skip=0 for paginate (not working on backend)
      data: { ...data },
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_EMAIL_LOGS'));
  };

  const handleGetEmailContent = (data) => {
    let request = {
      query: '?reference=' + data._id,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_EMAIL_CONTENT'));
  };

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'));
  };

  return (
    <div className={classes.root}>
      <Loader isLoading={loading} />
      <Snackybar
        open={showMessage.open}
        setOpen={handleMessage}
        type={showMessage.type}
        message={showMessage.message}
      />
      <SuperBreadCrumbs section={'Email Logs'} crumbsLinks={CRUMBS} />
      <AlertEmailDialogBox isOpen={showDialog} content={dialogContent} handleClick={setShowDialog} />
      <EmailLogsTable
        tableData={EMAIL_LOGS?.data.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))}
        pagination={{ totalItemCount: 12 }}
        showEmailDetails={handleGetEmailContent}
      />
    </div>
  );
};
export default withStyles(styles)(SuperadminEmailLogs);
