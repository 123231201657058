import React, { useEffect, useState } from 'react';
import {
  Button,
  withStyles,
  TextField,
  Typography,
  Checkbox,
  Grid,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { requestApplyPromoCode } from 'store/api';
import {
  getApplyPromoCodeSuccess,
  getApplyPromoCodeStatus,
  getApplyPromoCodeError,
} from 'store/selectors/agencyv2.selectors';
import { applyPromoCodeReset } from 'store/actions';
import { getUser } from 'store/selectors/superadmin.selectors';
import { getClientSecret } from 'store/selectors/billing.selectors';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  btn: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
    color: '#ffffff',
    '& .MuiButton-label > span': {
      display: 'inline-flex',
    },

    '& .MuiButton-label > span svg': {
      color: '#ffffff !important',
    },
  },
  btnsWrap: {
    justifyContent: 'space-evenly',
  },
});

const PromoCode = (props) => {
  const { onCheckboxSelect, usePromoCode, redirectPath, agencyId } = props;

  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [promoCode, setPromoCode] = useState(null);
  const [isApplyingCode, setIsApplyingCode] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const CLIENTSECRET = useSelector(getClientSecret);
  const PROMO_CODE_DETAILS = useSelector(getApplyPromoCodeSuccess);
  const PROMO_CODE_STATUS = useSelector(getApplyPromoCodeStatus);
  const PROMO_CODE_ERROR = useSelector(getApplyPromoCodeError);
  const AGENCY_DETAILS = useSelector(getUser);

  const [routerChange] = useRouterChange();

  useEffect(() => {
    if (
      PROMO_CODE_STATUS === 'success' &&
      PROMO_CODE_DETAILS &&
      PROMO_CODE_DETAILS.ok === true &&
      PROMO_CODE_DETAILS.data !== null
    ) {
      setIsApplyingCode(false);
    }
  }, [PROMO_CODE_DETAILS]);

  useEffect(() => {
    if (PROMO_CODE_STATUS !== null) {
      switch (PROMO_CODE_STATUS) {
        case 'progress':
          setIsApplyingCode(true);
          setMessage('');
          break;
        case 'success':
          setIsApplyingCode(false);
          setIsDialogOpen(true);
          dispatch(applyPromoCodeReset());
          break;
        default: //"failed"
          setIsApplyingCode(false);
          setMessage(`${PROMO_CODE_ERROR.message}`);
          break;
      }
    }
  }, [PROMO_CODE_STATUS]);

  const closeDialogHandler = () => {
    routerChange(
      `/superadmin/manage-agencies/${
        AGENCY_DETAILS?.currentAddedAgency?.data?.entity?._id
          ? AGENCY_DETAILS?.currentAddedAgency?.data?.entity?._id
          : agencyId
      }`,
    );
    setIsDialogOpen(false);
  };

  const buttonClickHandler = () => {
    if (!promoCode || `${promoCode}`.length === 0) {
      setMessage('Promo code is empty');
      return;
    }
    const request = {
      subscription_id: CLIENTSECRET.data.subscriptionSchedule,
      promotion_code: promoCode,
    };
    dispatch(requestApplyPromoCode(request, 'AGENCY_APPLY_PROMO_CODE'));
  };

  const valueChangeHandler = (event) => {
    setMessage('');
    onCheckboxSelect(event.target.checked);
  };

  const textChangeHandler = (event) => {
    setMessage('');
    setPromoCode(event.target.value);
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Grid direction="row">
        <FormControlLabel
          control={<Checkbox color="primary" checked={usePromoCode} onChange={valueChangeHandler} />}
          label="Use promo code to bypass credit card"
        />
      </Grid>
      {usePromoCode && (
        <>
          <Grid direction="row" alignItems="center" justifyContent="center">
            <TextField
              style={{ backgroundColor: '#FFF' }}
              size="small"
              fullWidth={false}
              id="outlined-basic"
              variant="outlined"
              onChange={textChangeHandler}
              disabled={isApplyingCode}
              value={promoCode}
            />
            {isApplyingCode ? (
              <CircularProgress style={{ marginLeft: 10 }} size={20} color="primary" />
            ) : (
              <Button disabled={isApplyingCode} id="submit" color={'primary'} onClick={buttonClickHandler}>
                Apply Promo Code
              </Button>
            )}
          </Grid>
          {message && message.length > 0 && (
            <Grid direction="row">
              <Typography
                style={{
                  color: 'red',
                }}
                variant={'caption'}
              >
                {message}
              </Typography>
            </Grid>
          )}
        </>
      )}
      <Dialog
        open={isDialogOpen}
        onClose={closeDialogHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Promo code successfully applied. Click OK to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialogHandler} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(PromoCode);
