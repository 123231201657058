import Splash from '../../containers/Web/Splash'

let routes = [
  {
    path: '/splash',
    component: Splash,
  },
]

export default routes
