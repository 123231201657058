import React from "react";
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

const Title = withStyles({
  root: {
    fontWeight:300, 
    fontSize:'21px', 
    marginBottom:'10px',
    letterSpacing:'-0.525px',
    lineHeight:'32px',
    color:'rgba(0, 0, 0, 0.87)'
  }
})(Typography);


const Content = withStyles({
  root: {
    fontWeight:400, 
    fontSize:'16px', 
    letterSpacing:'0.09px',
    lineHeight:'24px',
    color:'#5F5F60'
  }
})(Typography);

const HeadingContent = props => {
  const { title, content } = props;
  return (
    <div>
        {
          title && 
            <Title variant="h1">
               {title}
            </Title>
        }

        {
          content &&
            <Content dangerouslySetInnerHTML={{ __html: content }} />
        }
    </div>
  )
};


export default HeadingContent