import React, { useEffect, useState } from 'react'
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import { SuperForgotForm, AuthImages } from 'components/Web/Superadmin'
import { Loader } from 'components/Common'
/** redux */
import { superadminActions } from 'store/actions'
import { requestPOSTAPISuperadmin } from 'store/api'
import { getStatus, getErrors } from 'store/selectors/superadmin.selectors'
import NotificationDialog from "components/Web/CustomDialogs/NotificationDialog";
import AlertNotification from "components/Web/Form/AlertNotification";
import { useRouterChange } from 'utils/router.utils';

const styles = () => ({
  root: {
    width: '100vw',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#f5f5f5',
    width: '100vw',
    height: '100vh',
    padding: 40,
    paddingLeft: '10vw',
  },
  rightColumn: {
    paddingLeft: '0 !important',
    paddingTop: '54px !important',
    maxWidth: 680,
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const SuperadminForgot = (props) => {
  const { classes } = props
  const [routerChange] = useRouterChange()
  const dispatch = useDispatch()
  const STATUS = useSelector(getStatus)
  const ERROR = useSelector(getErrors)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [dialogContents, setDialogContents] = useState({
    title: '',
    text: ''
  })

  const [loading, setLoading] = useState(false)
  const defaultNotification = { status: false, options: null }
  const [isNotification, setIsNotification] = useState(defaultNotification)

  function goBack() {
    routerChange('back')
  }

  window.onbeforeunload = (e) => {
    return handleClearStates()
  }

  useEffect(() => {
    /**
     * * Default state refresher
     */
    handleClearStates()
    setConfirmDialog(false)

    return () => {
      handleClearStates()
    }
  }, [])

  /** Redux Actions */
  const handleClearStatus = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_STATUS'))
  }

  const handleClearError = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'CLEAR_ERROR'))
  }
  const handleClearStates = () => {
    dispatch(superadminActions(null, 'GENERAL_SUPERADMIN', 'RESET_STATES'))
  }

  const handleConfirmDialog = () => {
    setConfirmDialog(false)
    if (STATUS === 'superadmin/SUPERADMIN_RESET_PASSWORD_SUCCESS' || STATUS === null) {
      handleClearStatus()
      handleClearError()
    }
    setDialogContents({ title: '', text: '' })
    routerChange('/superadmin/login')
  }

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification)
  }
  useEffect(() => {
    if (STATUS === 'superadmin/SUPERADMIN_FORGOT_PASSWORD_REQUEST') {
      setLoading(true)
      const options = { status: true, options: { severity: 'info', message: 'OTP Code request sent.' } }
      setIsNotification(options)
    }

    if (STATUS === 'superadmin/SUPERADMIN_FORGOT_PASSWORD_SUCCESS') {
      setLoading(false)
      const options = { status: true, options: { severity: 'success', message: 'Reset code sent to your email.' } }
      setIsNotification(options)
    }

    if (STATUS === 'superadmin/SUPERADMIN_FORGOT_PASSWORD_FAILED') {
      setLoading(false)
      const options = { status: true, options: { severity: 'error', message: ERROR?.message === 'User does not exist.' ? 'Sorry, this email is not yet assigned to an account' : ERROR?.message ?? "An error occured. Please try again." } }
      setIsNotification(options)
    }

    if (STATUS === 'superadmin/SUPERADMIN_RESET_PASSWORD_REQUEST') {
      setLoading(true)
    }
    if (STATUS === 'superadmin/SUPERADMIN_RESET_PASSWORD_FAILED') {
      setLoading(false)
      setConfirmDialog(true)
      setDialogContents({
        title: 'Error',
        text: ERROR.message
      })
    }
    if (STATUS === 'superadmin/SUPERADMIN_RESET_PASSWORD_SUCCESS') {
      setConfirmDialog(true)
      setDialogContents({
        title: 'Success',
        text: "Password updated successfuly."
      })
      setLoading(false)
    }
  }, [STATUS, ERROR])

  const handleForgotPassword = (data) => {
    let request = {
      query: null,
      data: { email: data.email },
    }
    dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_FORGOT_PASSWORD'))
  }

  const handleResetPassword = (data) => {
    let request = {
      query: null,
      data: { email: data.email, password: data.password, code: data.code },
    }
    dispatch(requestPOSTAPISuperadmin(request, 'SUPERADMIN_RESET_PASSWORD'))
  }

  return (
    <div className={classes.content}>
      <Loader isLoading={loading} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={5} className={classes.leftColumn}>
          <SuperForgotForm

            goBack={goBack}
            error={ERROR}
            handleFormSubmit={handleForgotPassword}
            handleFormSubmitReset={handleResetPassword}
            forgotStatus={STATUS === "superadmin/SUPERADMIN_FORGOT_PASSWORD_SUCCESS" || STATUS === "superadmin/SUPERADMIN_RESET_PASSWORD_FAILED" || STATUS === "superadmin/SUPERADMIN_RESET_PASSWORD_REQUEST"}
            resetStatus={STATUS === "superadmin/SUPERADMIN_RESET_PASSWORD_SUCCESS"}
          />

          {confirmDialog && STATUS !== null &&
            <NotificationDialog
              isOpen={confirmDialog}
              closeCallback={handleConfirmDialog}
              title={dialogContents.title}
              text={dialogContents.text}
              align={'center'}
            />}
          {isNotification.status && ERROR &&
            <AlertNotification
              status={isNotification.status}
              options={isNotification.options}
              closeNotification={handleNotificationClose}
            />
          }



        </Grid>
        <Grid item xs={12} md={6} lg={7} className={classes.rightColumn}>
          <AuthImages type={'forgot-password'} />
        </Grid>
      </Grid>
    </div>
  )
}

export default withStyles(styles)(SuperadminForgot)
