import React, { useState } from 'react'
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import Geocode from 'react-geocode'
import MapCard from '../Map'
import {
  Box,
  Tabs,
  Tab,
  Fade,
  Dialog,
  IconButton,
  Typography,
  DialogActions,
  Button,
  withStyles,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import SectionHeading from '../SectionHeading'
import CloseIcon from '@material-ui/icons/Close'
import GoogleMapView from '../Map/GoogleMapView'
import FullscreenOutlinedIcon from '@material-ui/icons/FullscreenOutlined'
import FullscreenExitOutlinedIcon from '@material-ui/icons/FullscreenExitOutlined'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& .Mui-selected': {
      backgroundColor: '#f5f5f5',
    },
  },
  titleRoot: {
    margin: 0,
    padding: theme.spacing(2),
    '& h6': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '90%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  markerIcon: {
    width: '2.1875em',
    height: '2.5em',
  },
  mapsWrapper: {
    position: 'relative',
    overflow: 'hidden !important',
    width: '100%',
    height: '60vh !important',
    /* '@media (min-width: 1024px)': {
            height: '60vh !important',
        }, */
    /* '@media (min-width: 1440px)': {
            height: '100vh !important',
        }, */
    '& .MuiPaper-root > div': {
      height: '60vh !important',
    },
  },
  mapsWrapperFullscreen: {
    position: 'relative',
    overflow: 'hidden !important',
    width: '100%',
    height: '80vh !important',
    '& .MuiPaper-root > div': {
      height: '80vh !important',
    },
  },
  mapViewToggles: {
    textTransform: 'capitalize',
    '&:disabled': {
      backgroundColor: 'rgba(53, 192, 202, 1)',
      color: '#ffffff',
    },
  },
  dialogActions: {
    justifyContent: 'center',
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.titleRoot} {...other}>
      <Typography variant="h6" title={props.title}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
}))(MuiDialogContent)

const MapPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`map-tabs-${index}`}
      aria-labelledby={`map-tabs-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const a11yProps = (index) => {
  return {
    id: `map-tabs-${index}`,
    'aria-controls': `map-tabs-${index}`,
  }
}

const MapModal = ({ classes, isMapFullScreen, onFullScreenClick, children }) => {
  return (
    <div
      className={isMapFullScreen ? classes.mapsWrapperFullscreen : classes.mapsWrapper}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        top={10}
        right={10}
        position="absolute"
        width="2.5rem"
        height="2.5rem"
        zIndex={2}
        bgcolor="white"
        color="#676767"
        cursor="hand"
        style={{
          cursor: 'pointer',
        }}
        onClick={onFullScreenClick}
        sx={{
          '&:hover': {
            color: 'black',
          },
        }}
      >
        {isMapFullScreen ? (
          <FullscreenExitOutlinedIcon fontSize="large" />
        ) : (
          <FullscreenOutlinedIcon fontSize="large" />
        )}
      </Box>
      {children}
    </div>
  )
}

const MapsSection = (props) => {
  const { classes, property, listingType } = props

  const [mapData, setMapData] = useState({
    map: {
      coordinates: [-37.7963, 144.92512],
      markers: [{ lat: -37.7963, lng: 144.92512 }],
      nearby: [{ lat: -37.793541, lng: 144.931722 }],
      zoom: 15,
    },
  })

  const [mapDialog, setMapDialog] = useState(false)
  const [mapDialogContent, setMapDialogContent] = useState(null)

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [isMapFullScreen, setIsMapFullScreen] = useState(false)
  const mapFullscreenHandler = () => {
    setIsMapFullScreen((prev) => setIsMapFullScreen(!prev))
    if (mapDialog === false) {
      setIsMapFullScreen(false)
    }
  }

  React.useEffect(() => {
    setGoogleAPI()
  }, [property])

  async function setGoogleAPI() {
    Geocode.setApiKey(GOOGLE_MAP_API_KEY)
    Geocode.fromAddress(property.addressText).then(
      (response) => {
        const GEOCODE_DATA = response.results[0]
        setMapData((prevData) => ({
          map: {
            ...prevData.map,
            coordinates: [
              GEOCODE_DATA.geometry.location.lat,
              GEOCODE_DATA.geometry.location.lng,
            ],
            markers: [GEOCODE_DATA.geometry.location],
            nearby: [GEOCODE_DATA.geometry.location],
          },
        }))
      },
      (error) => { }
    )
  }

  return (
    <div className={classes.root}>
      <SectionHeading variant="h6" component="h2">
        Property Location
      </SectionHeading>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="View Maps"
        indicatorColor="primary"
      >
        <Tab label="Map View" {...a11yProps(0)} />
        <Tab label="Street View" {...a11yProps(1)} />
      </Tabs>
      <MapPanel
        value={value}
        index={0}
        onClick={() => {
          setMapDialog(true)
          setMapDialogContent('map')
        }}
      >
        <MapCard
          data={{
            mode: 'map',
            title: 'Map View',
            ...mapData,
            map: {
              ...mapData.map,
              zoom: 13,
            },
            address: property.addressText,
            interactive: false,
          }}
        />
      </MapPanel>
      <MapPanel
        value={value}
        index={1}
        onClick={() => {
          setMapDialog(true)
          setMapDialogContent('street')
        }}
      >
        <MapCard
          data={{
            mode: 'street',
            title: 'Street View',
            ...mapData,
            address: property.addressText,
            interactive: false,
          }}
        />
      </MapPanel>

      <Dialog
        open={mapDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setMapDialog(false)}
        aria-labelledby="map-dialog-preview"
        aria-describedby="map-dialog-preview"
        maxWidth={'md'}
        fullWidth={true}
        fullScreen={isMapFullScreen}
      >
        <DialogTitle onClose={() => setMapDialog(false)} title={property.addressText}>
          {property.addressText}
        </DialogTitle>
        <DialogContent>
          {mapDialogContent === 'map' && (
            <MapModal
              isMapFullScreen={isMapFullScreen}
              onFullScreenClick={mapFullscreenHandler}
              classes={classes}
            >
              <MapCard
                data={{
                  mode: 'map',
                  title: 'Map View',
                  ...mapData,
                  map: {
                    ...mapData.map,
                    zoom: 16,
                  },
                  address: property.addressText,
                  interactive: true,
                }}
              />
            </MapModal>
          )}
          {mapDialogContent === 'street' && (
            <MapModal
              isMapFullScreen={isMapFullScreen}
              onFullScreenClick={mapFullscreenHandler}
              classes={classes}
            >
              <MapCard
                data={{
                  mode: 'street',
                  title: 'Street View',
                  ...mapData,
                  address: property.addressText,
                  interactive: true,
                }}
              />
            </MapModal>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <>
            <Button
              onClick={() => {
                setMapDialogContent('map')
                setValue(0)
                setIsMapFullScreen(false)
              }}
              variant={mapDialogContent === 'map' ? 'contained' : 'outlined'}
              color="primary"
              disabled={mapDialogContent === 'map'}
              className={classes.mapViewToggles}
            >
              Map
            </Button>

            <Button
              onClick={() => {
                setMapDialogContent('street')
                setValue(1)
                setIsMapFullScreen(false)
              }}
              variant={mapDialogContent === 'street' ? 'contained' : 'outlined'}
              color="primary"
              disabled={mapDialogContent === 'street'}
              className={classes.mapViewToggles}
            >
              Street View
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default withStyles(styles)(MapsSection)
