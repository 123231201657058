import React from "react";
import { IconButton, Snackbar, Slide, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EF6631",
    boxShadow: "0.5px 0.5px 1px #9E9E9E",
    minWidth: 350,
    height: "100%",
    borderRadius: 30,
    top: "30vh !important",
    position: "relative"
  },
  buttonContainer: {
    borderRadius: 30,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  closeButton: {
    color: "#000",
    marginTop: 20,
    marginRight: 20,
    padding: 0,
    "& svg": {
      fontSize: "38px"
    }
  },
}));

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const SnackbarNotification = (props) => {
  const classes = useStyles();
  const { open, setOpen, duration, showClose } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={duration}
      // onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <div className={classes.root}>
        <div className={classes.buttonContainer}>
          {showClose && (
            <IconButton
              className={classes.closeButton}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        </div>
        {props.children}
      </div>
    </Snackbar>
  );
};

export default SnackbarNotification;
