import _ from 'lodash'

const handleIsArrayTheSame = (current, reference) => {
  let isArrayEqual = true

  if (current && reference) {
    for (let index = 0; index < current.length; index++) {
      isArrayEqual = _.isEqual(current[index], reference[index])

      if (!isArrayEqual) {
        return isArrayEqual
      }
    }
  }

  return isArrayEqual
}

export const compareArrays = (current, reference, key, objectField) => {
  const currentArr = key ? current?.filter((i) => i[key]) : current
  const referenceArr = key ? reference?.filter((i) => i[key]) : reference
  const haveAll = currentArr
    ?.map((i) => i.id)
    .every((v) => referenceArr?.map((i) => i.id).includes(v.id))
  const isTheSame =
    currentArr
      ?.map((i) => i.id)
      .sort()
      .toString() ===
    referenceArr
      ?.map((i) => i.id)
      .sort()
      .toString()

  let sameSearchRef = true
  let sameListingSortOrder = true

  if (objectField === 'search_reference') {
    sameSearchRef = handleIsArrayTheSame(current, reference)
  }

  if (objectField === 'listing_search_sort') {
    sameListingSortOrder = handleIsArrayTheSame(current, reference)
  }

  const conditions = {
    'different-search-reference': !sameSearchRef,
    'different-listing-order': !sameSearchRef,
    'current-have-more-but-different-from-ref':
      currentArr?.length > referenceArr?.length && !haveAll,
    'no-current-but-ref-have': referenceArr?.length > 0 && currentArr?.length === 0,
    'have-all-within-ref': haveAll && currentArr?.length < referenceArr?.length,
    'not-have-all-but-still-within-ref':
      !haveAll && currentArr?.length < referenceArr?.length,
    'same-length-but-not-same':
      !isTheSame && currentArr?.length === referenceArr?.length,
    'same-length-not-have-any':
      !haveAll && !isTheSame && currentArr?.length === referenceArr?.length,
    'current-have-more': currentArr?.length > referenceArr?.length,
    'current-have-less':
      current?.length < referenceArr?.length && referenceArr?.length > 1,
  }

  if (
    conditions['current-have-more-but-different-from-ref'] ||
    conditions['no-current-but-ref-have'] ||
    conditions['same-length-but-not-same'] ||
    conditions['same-length-not-have-any'] ||
    conditions['different-search-reference'] ||
    conditions['different-listing-order'] ||
    (conditions['current-have-more'] && objectField === 'suburbs') ||
    (conditions['current-have-less'] && objectField === 'suburbs')
  ) {
    return 'widened'
  } else if (
    conditions['have-all-within-reference'] ||
    conditions['not-have-all-but-still-within-ref']
  ) {
    return 'narrowed'
  } else {
    return null
  }
}
