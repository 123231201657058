import React, { useState, useEffect } from 'react'
import Image from 'material-ui-image'
import HeartFullIcon from '@material-ui/icons/Favorite'
import { connect } from 'react-redux'
import _ from 'lodash'

import HeartIcon from '@material-ui/icons/FavoriteBorder'

import DreamPropertiesAgentBanner from './DreamPropertiesAgentBanner'
import DreamPropertiesInfo from './DreamPropertiesInfo'

import IMGComingSoon from '../../../assets/images/coming_soon.svg'
import IMGOffMarket from '../../../assets/images/off_market.svg'

import { cloudinaryCardTransforms, cloudinaryImageHeightSmall } from '../../../constants/constants'

/** Property Utils */
import { propertyConfigSetter } from '../../../utils/property.utils'

import { withStyles, Card, IconButton, Tooltip } from '@material-ui/core'

import { useSelector } from 'react-redux'
import { getMpp } from 'store/selectors/mpp.selectors'
import { useRouterChange } from 'utils/router.utils'
import useGoogleEvents from 'utils/customHooks/useGoogleEvents'

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: 'auto',
    position: 'relative',
  },
  card: {
    width: 372,
    // height: 290,
    margin: theme.spacing(1),
    position: 'relative',
  },
  media: {
    width: '100%',
    height: '6.250em',
    objectFit: 'cover !important',
    cursor: 'pointer',
  },
  favoriteContainer: {
    width: '0.625em',
    height: '0.625em',
    position: 'absolute',
    top: 15,
    right: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
    width: '0.625em',
    height: '0.625em',
  },
  favoriteIconActive: {
    color: '#57bdc7',
    width: '0.625em',
    height: '0.625em',
  },
})

const DreamPropertyCards = (props) => {
  const {
    classes,
    type,
    properties,
    trades,
    authentication,
    toggleSignUp,
    settings,
    apiRequestSaveProperty,
  } = props

  const [galleryImages, setGalleryImages] = useState(null)
  const [routerChange] = useRouterChange()

  const { triggerAnalyticsEvent, getPropertyData } = useGoogleEvents()

  const MPP = useSelector(getMpp)
  let likes = MPP?.favourites?.data?.likes

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      console.debug('toggle')
      // toggleSignUp(!settings.showSignUp)
      routerChange('/login', {
        origin: props?.history?.location?.pathname ?? window.location.pathname,
      })
    } else {
      /** User Logged */
      apiRequestSaveProperty(properties.property)
    }
  }
  useEffect(() => {
    if (properties && properties.images && properties.images.length > 0) {
      let gallery_array = []
      if (properties.cloudinaryImages && properties.cloudinaryImages.length > 0) {
        properties.cloudinaryImages.map((item, idx) => {
          const file = item.path + cloudinaryCardTransforms + ',' + cloudinaryImageHeightSmall + '/' + item.file
          gallery_array.push(file)
        })
      } else {
        gallery_array = _.compact(properties.images)
      }

      setGalleryImages(gallery_array)
    }
  }, [properties])

  function imageType(type, image) {
    switch (type) {
      case 'auction':
        return image
      case 'comingsoon':
        return IMGComingSoon
      case 'offmarket':
        return IMGOffMarket
      default:
        return image
    }
  }

  const getRouteByStatus = (status) => {
    switch (true) {
      case status === 'rent' || status === 'rental' || status === 'leased':
        return '/rent/'
        break

      case status === 'sold':
        return '/sold/'
        break

      case status === 'offmarket':
        return '/off-market/'
        break

      case status === 'comingsoon':
        return '/coming-soon/'
        break

      default:
        return '/buy/'
    }
  }

  const handleRouteChange = (property) => {
    let status = property.propertyStatus
      ? property.propertyStatus.toLowerCase().replace(/[^\w\s]/gi, '')
      : ''
    if (routerChange) {
      routerChange(getRouteByStatus(status) + property.property, {
        previous_screen: '/',
      })
    }
  }

  function renderProperties() {
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <Image
            className={classes.media}
            imageStyle={{
              height:
                properties?.subscriptionType?.toLowerCase() === 'entry'
                  ? '120%'
                  : '100%',
            }}
            src={propertyConfigSetter('image-banner-card', {
              type: properties.propertyStatus,
              image:
                galleryImages && galleryImages[0]
                  ? galleryImages[0]
                  : properties.images && properties.images[0]
                  ? properties.images[0]
                  : null,
            })}
            onClick={() => {
              handleRouteChange(properties)
              let status = properties.propertyStatus
                ? properties.propertyStatus.toLowerCase().replace(/[^\w\s]/gi, '')
                : ''

              triggerAnalyticsEvent({
                event: 'homepage_actions',
                eventFrom: 'Web',
                actionScope: 'dream_carousel',
                actionName: 'Property Card',
                actionTarget: 'Viewed Property',
                actionReference: 'homepage',
                ...getPropertyData(properties),
              })
            }}
            aspectRatio={16 / 9}
            height="40px"
            disableSpinner
          />
          <IconButton
            className={classes.favoriteContainer}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              checkLoginStatus()

              triggerAnalyticsEvent({
                event: 'homepage_actions',
                eventFrom: 'Web',
                actionScope: 'dream_carousel',
                actionName: 'Property Card',
                actionTarget: 'Add to Likes',
                actionReference: 'homepage',
                ...getPropertyData(properties),
              })
            }}
          >
            {authentication &&
            authentication.user_status === 'logged' &&
            likes &&
            likes?.includes(properties?.property) ? (
              <Tooltip title="Remove from dislikes">
                <HeartFullIcon className={classes.favoriteIconActive} />
              </Tooltip>
            ) : (
              <Tooltip title="Add to likes">
                <HeartIcon className={classes.favoriteIcon} />
              </Tooltip>
            )}
          </IconButton>
          <DreamPropertiesAgentBanner
            type={'search'}
            properties={properties}
            {...propertyConfigSetter('agent', properties.subscriptionType)}
          />

          <DreamPropertiesInfo type={type} properties={properties} />
        </Card>
      </div>
    )
  }

  function renderTrades() {
    return (
      <div className={classes.root}>
        <Card className={classes.card}>
          <Image
            className={classes.media}
            src={imageType('', trades.image)}
            onClick={() => ''}
            aspectRatio={16 / 9}
            disableSpinner
          />
          <DreamPropertiesInfo type={type} trades={trades} />
        </Card>
      </div>
    )
  }

  return (
    <>
      {type === 'properties' && renderProperties()}
      {type === 'trades' && renderTrades()}
    </>
  )
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps)(withStyles(styles)(DreamPropertyCards))
