import React, { useEffect, useState } from 'react';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import HouseIcon from '@material-ui/icons/House';
import CloseIcon from '@material-ui/icons/Close';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import HeartIcon from '@material-ui/icons/FavoriteBorder';
import HeartFullIcon from '@material-ui/icons/Favorite';
import videoIcon from '../../../assets/images/video-icon.svg';
import tourIcon from '../../../assets/images/360-icon.svg';

import { withStyles, Badge, IconButton, Button, Tooltip, Dialog, Fade, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getMpp } from 'store/selectors/mpp.selectors';

const styles = (theme) => ({
  root: {
    width: '100%',
    position: 'relative',
  },
  favorite: {
    position: 'absolute',
    top: '47px',
    right: '33px',
    border: 0,
    borderRadius: '50%',
    width: '32px',
    height: '32px',
    backgroundColor: 'rgba(74, 74, 74, 0.39393) !important',
  },
  icons: {
    fontSize: '18px',
  },
  imageCount: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  favoriteContainer: {
    width: 36,
    height: 36,
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50%',
    borderColor: '#ffffff',
    borderWidth: 0.5,
    borderStyle: 'solid',
    padding: 0,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  favoriteIcon: {
    color: '#FFF',
  },
  favoriteIconActive: {
    color: '#57bdc7',
  },
  bannerBarContent: {
    maxWidth: 1157,
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  bannerBar: {
    position: 'absolute',
    width: '100%',
    bottom: theme.spacing(1),
    padding: theme.spacing(1.5, 2),
    backgroundColor: 'rgba(0,0,0, 0.4)',
  },
  bannerCTA: {
    display: 'flex',
    // justifyContent: 'space-between',
    '& .MuiButton-outlined': {
      borderRadius: '10em',
      borderColor: '#ffffff',
      color: '#ffffff',
      marginLeft: theme.spacing(2.5),
      textTransform: 'initial',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  bannerCTAL: {
    display: 'flex',
    justifyContent: 'flex-start',
    '& .MuiButton-outlined': {
      borderRadius: '10em',
      borderColor: '#ffffff',
      color: '#ffffff',
      textTransform: 'initial',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  exploreButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiButton-outlined': {
      borderRadius: '0',
      borderColor: '#ffffff',
      color: '#ffffff',
      textTransform: 'initial',
      margin: theme.spacing(0, 1.1),
      '& img': {
        marginRight: theme.spacing(0.8),
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '&:disabled': {
        opacity: 0.36,
      },
    },
  },
  tourWrapper: {
    backgroundColor: '#000000',
    '& .MuiDialog-scrollPaper': {
      padding: theme.spacing(8, 20),
    },
  },
  closeicon: {
    position: 'fixed',
    top: 0,
    right: 0,
    color: 'gray',
    '&:hover': {
      background: 'transparent',
    },
  },
  tourIframe: {
    height: '100%',
    width: '100%',
    border: 'none',
  },

  requestVirtualInspectionButton: {
    [theme.breakpoints.down(768)]: {
      margin: '10px 0px',
    },
  },
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: -4,
    color: '#ffffff',
    padding: '0 4px',
  },
}))(Badge);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const ImageBannerUI = (props) => {
  const {
    classes,
    authentication,
    settings,
    currentProperty,
    toggleSignUp,
    apiRequestSaveProperty,
    executeScroll,
    data,
  } = props;
  const itemCount = Array.isArray(props.data.images) ? props.data.images.length : 1;

  const [tourOpen, setTourOpen] = useState(false);
  const MPP = useSelector(getMpp);
  let likes = MPP?.favourites?.data?.likes;
  const type_exception = ['sold', 'rental'];

  const handleTourOpen = () => {
    setTourOpen(true);
  };

  const handleTourClose = () => {
    setTourOpen(false);
  };

  function checkLoginStatus() {
    if (authentication && authentication.user_status !== 'logged') {
      toggleSignUp(!settings.showSignUp);
    } else {
      /** User Logged */
      let property_ID = '';
      let propertyIdSearched = likes.find((property) => property.property === currentProperty._id)?._id;
      if (typeof propertyIdSearched !== 'undefined') {
        property_ID = propertyIdSearched;
      } else {
        property_ID = currentProperty._id;
      }
      console.debug('propertyIdSearched', propertyIdSearched);
      console.debug('ImageBanner', property_ID);
      apiRequestSaveProperty(property_ID);
    }
  }

  const handleImageIndex = (i) => {
    let floorplanLength =
      currentProperty?.cloudinaryFloorPlans?.length > 0
        ? currentProperty?.cloudinaryFloorPlans?.length
        : currentProperty?.floorPlan?.length > 0
        ? currentProperty?.floorPlan?.length - 1
        : 0;
    const dataCount = data && data.images && data.images.length ? data.images.length : 0;
    const hasFloorPlan = props.floorPlan ? true : false;
    if (props.setSelectImageIndex && i === 'video') {
      //const target = hasFloorPlan ? dataCount - 2 : dataCount
      const target = hasFloorPlan ? dataCount - currentProperty?.cloudinaryFloorPlans.length : dataCount;
      props.setSelectImageIndex(target - 1);
    }
    if (props.setSelectImageIndex && i === 'floorplan') {
      const target = hasFloorPlan ? dataCount - floorplanLength : dataCount;
      props.setSelectImageIndex(target);
    }
  };
  return (
    <div className={classes.root}>
      {props.children}

      {/* <ToggleButton className={classes.favorite}
              value="check"
              selected={favorite}
              onChange={() => {
                //setFavorite(!favorite);
                checkLoginStatus();
              }}
            >
              {properties.favorite ? <FavoriteIcon color="primary" className={classes.icons}  /> : <FavoriteBorderOutlinedIcon style={{ color: '#ffffff' }} className={classes.icons}  />}
            </ToggleButton> */}
      <div className={classes.bannerBar}>
        <Grid container className={classes.bannerBarContent}>
          {/* <Grid item xs={6} className={classes.bannerCTAL}>
                  {/* <StyledBadge badgeContent={itemCount} color="primary" className={classes.imageCount}>
                    <CameraAltIcon style={{ color: '#ffffff', fontSize: '25px' }}/>
                  </StyledBadge> }
                </Grid> */}
          <Grid item xs={9} className={classes.bannerCTA}>
            {authentication &&
            authentication.user_status === 'logged' &&
            currentProperty &&
            currentProperty._id &&
            likes &&
            likes?.includes(currentProperty?._id) ? (
              <Tooltip title="Remove to favorite">
                <IconButton
                  className={classes.favoriteContainer}
                  onClick={(e) => {
                    e.preventDefault();
                    checkLoginStatus();
                  }}
                >
                  <HeartFullIcon className={classes.favoriteIconActive} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Add to favorite">
                <IconButton
                  className={classes.favoriteContainer}
                  onClick={(e) => {
                    e.preventDefault();
                    checkLoginStatus();
                  }}
                >
                  <HeartIcon className={classes.favoriteIcon} />
                </IconButton>
              </Tooltip>
            )}
            <div>
              {currentProperty?.floorPlan?.[0]?.['@_url'] && (
                <Button variant="outlined" onClick={() => handleImageIndex('floorplan')}>
                  Floorplan
                </Button>
              )}
              {!type_exception.includes(currentProperty.propertyStatus) &&
                (currentProperty?.statementOfIntent || currentProperty?.statementOfInformation) && (
                  <Button
                    className={classes.requestVirtualInspectionButton}
                    variant="outlined"
                    href={
                      currentProperty?.statementOfIntent
                        ? currentProperty.statementOfIntent
                        : currentProperty?.statementOfInformation
                        ? currentProperty.statementOfInformation
                        : null
                    }
                    target="_blank"
                  >
                    View Agent Price Guide
                  </Button>
                )}
            </div>
          </Grid>
          <Grid item md={3} className={classes.exploreButtons}>
            <Button
              variant="outlined"
              onClick={() => handleImageIndex('video')}
              disabled={currentProperty && currentProperty.videoLink ? false : true}
            >
              <img src={videoIcon} />
              Video
            </Button>
            <Button
              variant="outlined"
              onClick={handleTourOpen}
              disabled={props.virtualTours && props.virtualTours.length ? false : true}
            >
              <img src={tourIcon} />
              Virtual Tour
            </Button>
          </Grid>
        </Grid>
      </div>
      {props.virtualTours && props.virtualTours.length && (
        <Dialog
          fullScreen
          open={tourOpen}
          onClose={handleTourClose}
          TransitionComponent={Transition}
          className={classes.tourWrapper}
        >
          <iframe src={props.virtualTours[0]} className={classes.tourIframe} />
          <IconButton
            aria-label="close"
            className={classes.closeicon}
            onClick={handleTourClose}
            disableFocusRipple
            disableRipple
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Dialog>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(withStyles(styles)(ImageBannerUI));
