import React, { useState, useRef } from "react";
import SatelliteIcon from "@material-ui/icons/Satellite";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'
import Geocode from "react-geocode";
import TuneIcon from "@material-ui/icons/Tune";

import PropertySearchMapView from "../../components/Mobile/Map/PropertySearchMapView";
import SearchFilterHeader from "../../components/Mobile/Property/SearchFilterHeader";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Loader from "../../components/Mobile/Loader";
import AlertNotification from "../../components/Mobile/Form/AlertNotification";

import DynamicIcons from "../../components/Mobile/DynamicIcons";
import MapPropertyCards from "../../components/Mobile/Map/MapPropertyCards";

import { scrollToRef } from "../../components/Mobile/ScrollEvents";
import _, { random } from "lodash";

/** api redux request */
import { requestPropertySave } from "../../store/api";

/** actions */
import {
  toggleSignUp,
  toggleFilter,
  setCurrentProperty,
  clearCurrentProperty,
} from "../../store/actions";

import { withStyles, Button, IconButton, Badge } from '@material-ui/core';
import { useRouterChange } from "utils/router.utils";

const styles = (theme) => ({
  topAnchor: {
    position: "relative",
    width: "100%",
    height: 0,
    top: 0,
    transform: "translateY(-56px)",
  },
  mapWrapper: {
    overflow: "hidden",
    position: "relative",
  },
  propertyCards: {
    position: "absolute",
    left: 0,
    bottom: 60,
    width: "100%",
  },
  mapTypeToggle: {
    position: "absolute",
    left: theme.spacing(2),
    bottom: theme.spacing(1),

    "& .MuiButton-contained": {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 12,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
      textTransform: "capitalize",
      fontSize: 12,
    },
  },
  offMarketToggle: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
    "& .MuiButton-contained": {
      fontSize: 13,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: "left",
      borderRadius: theme.spacing(2),
      // backgroundColor: '#ffffff'
    },
  },
  checkWrapper: {
    marginRight: theme.spacing(1),
    display: "inline-flex",
  },
  backButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    zIndex: 20,
    backgroundColor: "#FFF",
    position: "relative",
  },
});

const BackButton = withStyles({
  root: {
    zIndex: 20,
    background: "#ffffff",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const FavoritesMap = (props) => {
  const { classes, property, setCurrentPropertySelected, filters, ...other } = props;
  const [routerChange] = useRouterChange()
  const [properties, setProperties] = React.useState(null);
  const [mapType, setMapType] = React.useState(false);
  const [offMarketToggle, setoffMarketToggle] = React.useState(false);
  const [topOffset, setTopOffset] = React.useState(0);
  const [comingSoonLength, setComingSoonLength] = React.useState(0);
  const [offMarketLength, setOffMarketLength] = React.useState(0);
  const [currentLength, setCurrentLength] = React.useState(0);
  const [soldLength, setSoldLength] = React.useState(0);
  const [rentalLength, setRentalLength] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);

  const [activeProperty, setActiveProperty] = React.useState(null);
  const [visibleOffMarkets, setVisibleOffMarkets] = React.useState(null);
  const [suburbCoordinates, setSuburbCoordinates] = React.useState(null);
  const [previousScreen, setPreviousScreen] = React.useState(null);

  const topContainer = useRef(null);
  const mapWrapperRef = useRef(null);
  const executeTopScroll = (d) => scrollToRef(topContainer, d); //add `false` as second parameter to scroll without transition.
  const BASE_RADIUS = 2000;
  const NEARBY_RADIUS = 8000;

  const getCurrentScreen = () => {
    console.debug(props.match ? props.match.url : "");
    return props.match ? props.match.url : "";
  };

  React.useEffect(() => {
    /**
     * property data setter */
    if (property) {
      if (
        property &&
        property.properties &&
        property.properties.data &&
        property.properties.data.data
      ) {
        setIsLoading(false);
        setProperties(property.properties.data.data.propertiesFilters);

        // setLikedPropertiesData({propertiesFilters: property.properties_liked.data.data})
      }
    }

    /**
     * error hanlder property search */
    if (property && property.properties && isLoading) {
      handleNotification(true, {
        message: "Oops. An error has occured..",
        severity: "error",
      });
      setIsLoading(false);
    }
    /**
     * error hanlder save property */
    if (property && property.properties && isLoading) {
      handleNotification(true, {
        message: "Oops. An error has occured..",
        severity: "error",
      });
      setIsLoading(false);
    }

    if (mapWrapperRef) {
      setTopOffset(mapWrapperRef.current.offsetTop);
    }

    /* document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        setIsLoading(false);
      }
    }; */

    if (filters && filters.suburbs.length > 0) {
      setSuburbCoordinates({
        lat: filters.suburbs[0].lat,
        lng: filters.suburbs[0].long,
      });
    }
  }, [property]);

  React.useEffect(() => {
    executeTopScroll(false);

    /** GET PREVIOUS SCREEN */
    setPreviousScreen(localStorage.getItem("previous_screen"));
  }, []);

  const handleNotification = (status, option) => {
    setIsNotification(status);
    setNotificationOptions(option);
  };

  const closeNotification = () => {
    setIsNotification(false);
  };

  const [mapData, setMapData] = useState({
    map: {
      coordinates: [-37.7963, 144.92512],
      center: { lat: -37.7963, lng: 144.92512 },
      comingsoon: [],
      offmarket: [],
      current: [],
      zoom: 13,
    },
  });

  const getRandomCoordinates = (lat, lng, r) => {
    const METERS_IN_DEGREE = 111_300;
    const u = Math.random(),
      v = Math.random();

    let radius = parseFloat(r) / METERS_IN_DEGREE;

    let w = radius * Math.sqrt(u);
    let t = 2 * Math.PI * v;

    let x = (w * Math.cos(t)) / Math.cos(lat);
    let y = w * Math.sin(t);

    return { lat: y + lat, lng: x + lng }; //returns an object of lat long pair
  };

  function isWithinRadius(checkPoint, centerPoint, km) {
    var ky = 40000 / 360;
    var kx = Math.cos((Math.PI * centerPoint.lat) / 180.0) * ky;
    var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    km = km / 1000;
    return Math.sqrt(dx * dx + dy * dy) <= km;
  }

  function generateRandomCoordinates(center, radius, count) {
    let i;
    let coords = [];

    for (i = 0; i < count; i++) {
      coords.push(getRandomCoordinates(center, radius));
    }

    return coords;
  }

  function iterateItems(obj, r) {
    const items = [];

    for (const [key, details] of Object.entries(obj)) {
      if (r && !details.latitude && !details.longitude) {
        const randomCoordinate = getRandomCoordinates(
          ...mapData.map.coordinates,
          r ? r : BASE_RADIUS
        );
        items.push({ ...randomCoordinate, details });
      } else {
        if (
          details.latitude &&
          details.longitude &&
          details.propertyStatus !== "offmarket"
        ) {
          items.push({
            ...{
              lat: parseFloat(details.latitude),
              lng: parseFloat(details.longitude),
            },
            details,
          });
        } else {
          const randomCoordinate = getRandomCoordinates(
            parseFloat(details.latitude),
            parseFloat(details.longitude),
            r ? r : BASE_RADIUS
          );
          items.push({ ...randomCoordinate, details });
        }
      }
    }

    return items;
  }

  React.useEffect(() => {
    const ALL_COMING_SOON = _.pickBy(properties, (e) =>
      _.includes(["comingsoon"], e.propertyStatus)
    );
    const ALL_OFFMARKET = _.pickBy(properties, (e) =>
      _.includes(["offmarket"], e.propertyStatus)
    );
    const ALL_CURRENT = _.pickBy(properties, (e) =>
      _.includes(["current", "On-Sale", "rental", "sold"], e.propertyStatus)
    );
    //const ALL_SOLD = _.pickBy(properties, e => _.includes(['sold'], e.propertyStatus))
    //const ALL_RENTAL = _.pickBy(properties, e => _.includes(['rental',], e.propertyStatus))
    let i;
    setComingSoonLength(Object.keys(ALL_COMING_SOON).length);
    setOffMarketLength(Object.keys(ALL_OFFMARKET).length);
    setCurrentLength(Object.keys(ALL_CURRENT).length);
    //setRentalLength(Object.keys(ALL_RENTAL).length);
    //setSoldLength(Object.keys(ALL_SOLD).length);

    // console.log(property.properties.data.data.propertiesFilters, 'DATA')

    //START OF DUMMY MARKERS GENERATION

    // console.log(property, "PROPERTY")

    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        //markers: [randomCoordinate ],
        center: suburbCoordinates,
        comingsoon: iterateItems(ALL_COMING_SOON),
        offmarket: iterateItems(ALL_OFFMARKET, 200),
        current: iterateItems(ALL_CURRENT),
        //sold: iterateItems(ALL_SOLD),
        //rental: iterateItems(ALL_RENTAL),
        comingsoonlimit: comingSoonLength,
        offmarketlimit: offMarketLength,
        currentlimit: currentLength,
        //soldlimit: rentalLength,
        //rentallimit: soldLength,
      },
    }));
    //END OF DUMMY MARKERS GENERATION
  }, [property]);

  React.useEffect(() => {
    setMapData((prevData) => ({
      map: {
        ...prevData.map,
        maptype: mapType,
      },
    }));
  }, [mapType]);

  React.useEffect(() => {
    if (suburbCoordinates) {
      setMapData((prevData) => ({
        map: {
          ...prevData.map,
          center: suburbCoordinates,
          coordinates: [suburbCoordinates.lat, suburbCoordinates.lng],
        },
      }));
    }
  }, [suburbCoordinates]);

  const activePropertySetter = (property) => {
    setActiveProperty(property);
  };

  const offMarketToggleSetter = (value) => {
    setoffMarketToggle(value);
  };

  const visibleOffmarketItems = (items) => {
    //if(!visibleOffMarkets.some(v => v["property"] === item.property)) {
    setVisibleOffMarkets((prevData) => items);
    //}
  };

  function getMainPath() {
    if (props.match.url === "/buy/map-view") {
      localStorage.setItem("previous_path", "buy");
      return "buy";
    }
    if (props.match.url === "/rent/map-view") {
      localStorage.setItem("previous_path", "rent");
      return "rent";
    }
    if (props.match.url === "/sold/map-view") {
      localStorage.setItem("previous_path", "sold");
      return "sold";
    }
  }

  return (
    <div className={classes.root}>
      <div ref={topContainer} className={classes.topAnchor}></div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Loader isLoading={isLoading} />
      <div className={classes.backButtonContainer}>
        <BackButton
          variant="text"
          disableRipple={true}
          fullWidth={false}
          onClick={() => routerChange("/")}
        >
          <DynamicIcons icon="ChevronLeft" /> GO BACK
        </BackButton>
        <IconButton onClick={() => routerChange("/" + getMainPath() + "/filters")}>
          <Badge badgeContent={null} color="primary">
            <TuneIcon className={classes.iconTune} />
          </Badge>
        </IconButton>
      </div>
      {/* <SearchFilterHeader {...other}/> */}
      <div
        className={classes.mapWrapper}
        ref={mapWrapperRef}
        style={{ height: window.innerHeight - topOffset }}
      >
        <PropertySearchMapView
          style={{ height: "674px" }}
          data={{
            mode: "map",
            title: "Map View",
            ...mapData,
            address: "",
            radius: BASE_RADIUS,
            filteroffmarket: offMarketToggle,
          }}
          activePropertySetter={activePropertySetter}
          offMarketToggleSetter={offMarketToggleSetter}
          visibleOffmarketItems={visibleOffmarketItems}
        />

        {offMarketLength && (
          <div className={classes.offMarketToggle}>
            <Button
              variant="contained"
              disableElevation
              color={offMarketToggle ? "secondary" : "inherit"}
              style={{ backgroundColor: !offMarketToggle ? "#ffffff" : "" }}
              onClick={() => {
                setoffMarketToggle(!offMarketToggle);
                setActiveProperty(null);
              }}
            >
              {!offMarketToggle && <CheckBoxOutlineBlankIcon fontSize="small" />}
              <span className={classes.checkWrapper}>
                {offMarketToggle && (
                  <CheckBoxIcon
                    fontSize="small"
                    color={offMarketToggle ? "primary" : "inherit"}
                  />
                )}
              </span>

              <span>{offMarketLength} Off Market Properties</span>
            </Button>
          </div>
        )}
        <div className={classes.propertyCards}>
          <div style={{ position: "relative" }}>
            <div className={classes.mapTypeToggle}>
              <Button
                variant="contained"
                disableElevation
                color={mapType ? "primary" : "inherit"}
                onClick={() => {
                  setMapType(!mapType);
                }}
              >
                <SatelliteIcon fontSize="small" />
                <span>Satellite</span>
              </Button>
            </div>
          </div>
          <MapPropertyCards
            pathType={"property"}
            properties={properties}
            activeProperty={activeProperty}
            filterOffmarkets={offMarketToggle}
            visibleOffMarkets={visibleOffMarkets}

            currentScreen={previousScreen}
            currentPath={getMainPath()}
            setCurrentProperty={setCurrentPropertySelected}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    property: state.property,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      toggleSignUp: (checked) => toggleSignUp(checked),
      toggleFilter: (checked) => toggleFilter(checked),
      saveProperty: (params) => requestPropertySave(params),
      setCurrentPropertySelected: (params) => setCurrentProperty(params),
      clearCurrentProperty: () => clearCurrentProperty(),
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FavoritesMap));
