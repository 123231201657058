import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Image from 'material-ui-image'

import { Box, Grid, withStyles, Typography, Button, Icon } from '@material-ui/core';

import DynamicIcons from '../DynamicIcons'
import HeroImage from '../../../assets/images/hero/hero-image-v2.jpg'

const styles = (theme) => ({
  root: {
    position: 'relative',
    display: 'block',
    width: '100%',
    // height: "100vh",
    // maxHeight: 578,
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      // maxHeight: 790,
    },
    '@media (min-width: 2000px)': {
      // maxHeight: 1152
    },
  },
  heroHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 65,
    lineHeight: '60.5px',
    color: theme.palette.gray.main,
    marginBottom: theme.spacing(6),
    letterSpacing: '-0.78px',

    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
    },
    '@media (max-width: 1140px)': {
      fontSize: 50,
      lineHeight: '50px',
      marginBottom: theme.spacing(4),
    },
  },
  heroLeadText: {
    color: theme.palette.gray.main,
    marginBottom: theme.spacing(4),
    '@media (max-width: 1140px)': {
      marginBottom: theme.spacing(3),
    },
  },
  heroLeftColumn: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
    },
    paddingRight: theme.spacing(8) + 'px !important',
  },
  heroRightColumn: {
    '@media (max-width: 1140px)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
})

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .btn-sell {
    svg {
      transform-origin: center;
      transform: rotate(90deg);
      font-size: 16px !important;
    }
  }
  .btn-rent {
    svg {
      transform-origin: center;
      transform: rotate(90deg);
      font-size: 16px !important;
    }
  }
  .btn-property {
    .MuiSvgIcon-root {
      font-size: 14px !important;
    }
  }
  .btn-find-your-perfect-property {
    .MuiSvgIcon-root {
      font-size: 14px !important;
    }
    .MuiButton-label > span:first-child {
      width: 100%;
    }
  }
  .btn-trade {
    .MuiSvgIcon-root {
      font-size: 16px !important;
    }
  }

  .btn-find-hush-hush-properties {
    background-color: #5cc1a3;
    max-width: 300px;
    &:hover {
      background-color: #ffffff;
    }
    small {
      text-align: center !important;
    }

    .MuiButton-label > span:first-child {
      width: 100%;
    }
  }
`

const DEFAULT_POPUP = {
  active: false,
  message: '',
  subject: '',
}

const PopUp = ({ popUp }) => {
  return (
    <Box position="relative">
      <Box
        color="black"
        zIndex={2}
        position="absolute"
        top="-13vh"
        left={'12vw'}
        border="solid 0.1px"
        padding="20px"
        minWidth="400px"
        borderRadius="30px"
        style={{
          background: 'white',
        }}
      >
        <Typography
          variant="subtitle1"
          style={{
            fontFamily: 'Montserrat, sans',
            textAlign: 'center',
            fontWeight: 600,
          }}
        >
          {popUp.message}
        </Typography>
      </Box>
    </Box>
  )
}

const StyledButton = withStyles((theme) => ({
  root: {
    height: '41px',
    width: '100%',
    borderRadius: '19px',
    margin: '0 10px 0 0',
    padding: theme.spacing(2),
    color: theme.palette.textSecondary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiButton-label > span:first-child': {
      width: '100%',
    },
  },
  label: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
  },
}))(Button)

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  padding: 8px 0;
  @media (max-width: 1140px) {
    margin-bottom: 8px;
  }
`

const StyledButtonYellow = withStyles((theme) => ({
  root: {
    height: '41px',
    borderRadius: '19px',
    margin: '0',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primaryYellow.main,
    color: '#000 !important',
    width: '100%',
    maxWidth: 300,
    '&.active': {
      backgroundColor: theme.palette.primaryYellow.main,
      color: '#000 !important',
    },
  },
  label: {
    justifyContent: 'space-between',
    textTransform: 'capitalize',
    textAlign: 'left',
    lineHeight: 1,
    fontWeight: 400,
    letterSpacing: '0.0848923px',
    whiteSpace: 'nowrap',
  },
}))(Button)

const DEFAULT_BUTTONS = [
  {
    name: 'Buy',
    icon: 'AttachMoney',
    path: '/buy',
    active: false,
  },
  {
    name: 'Rent',
    icon: 'VpnKey',
    path: '/rent',
    active: false,
  },
  {
    name: 'Sold',
    icon: 'Gavel',
    path: '/sold',
    active: false,
  },
  {
    name: 'Find Your Perfect Property',
    icon: 'ThumbUpAlt',
    path: '/perfect-property',
    active: false,
  },
  {
    name: 'Find Hush-Hush Properties',
    icon: 'WatchLater',
    path: '/hush-hush',
    active: false,
  },
  /* {
    name: 'Trades & Services',
    icon: 'Build',
    path: '/trades',
    active: false
  } */
]

const Hero = (props) => {
  const { classes } = props

  const [homeButtons, setHomeButtons] = useState(DEFAULT_BUTTONS)
  const [page, setPage] = useState('/buy')
  const [hideSearch, setHideSearch] = useState(false)

  const [popUp, setPopUp] = useState(DEFAULT_POPUP)

  useEffect(() => {
    if (!props.filterWebActive) {
      handleClick(null, null, 0)
    }
  }, [props.filterWebActive])

  const handleClick = (e, obj, index) => {
    if (e) {
      e.preventDefault()
    }
    if (obj) {
      props.toggleFilterWeb({ type: obj.path.replace('/', ''), status: true })
      setHomeButtons(
        homeButtons.map((item) =>
          item.name === obj.name
            ? { ...item, active: true }
            : { ...item, active: false }
        )
      )
      setPage(obj.path)

      if (index === 3) {
        localStorage.setItem('hideState', true)
      } else {
        localStorage.removeItem('hideState')
      }
      props.handlePage(obj.path)
    } else {
      setHomeButtons(DEFAULT_BUTTONS)
    }
  }

  function dynamicStyle(path) {
    let placeholder = 'Address, suburb, postcode, state'
    if (path === '/perfect-property') {
      placeholder = 'Please select the state you want to find the property'
    } else if (path === '/trades') {
      placeholder = 'Suburb'
      /* } else if (path === '/sold') {
          placeholder = 'Search by Suburb, State or Postcode'
        } else if (path === '/rent') {
          placeholder = 'Search by Suburb, State or Postcode' */
    } else {
      placeholder = 'Search by Suburb, State or Postcode'
    }

    return placeholder
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} style={{ paddingLeft: 155 }}>
        <Grid item sm={12} md={5} lg={4} className={classes.heroLeftColumn}>
          <div>
            <Typography component={'h1'} className={classes.heroHeading}>
              <strong>find</strong> where you belong
            </Typography>
            <Typography component={'h6'} className={classes.heroLeadText}>
              Ready to experience something different? Swipe left, swipe right, and let
              our intuition lead you further. Your sense of place awaits.
            </Typography>

            <ButtonWrap>
              <Box width="100%">
                {popUp.active && popUp.subject === 'perfect-property' && (
                  <PopUp popUp={popUp} />
                )}
                <ButtonRow>
                  <StyledButtonYellow
                    className={
                      homeButtons[3].active
                        ? 'active btn-hero btn-' +
                          homeButtons[3].name.replace(/\s+/g, '-').toLowerCase()
                        : 'btn-hero btn-' +
                          homeButtons[3].name.replace(/\s+/g, '-').toLowerCase()
                    }
                    variant="outlined"
                    color="primary"
                    size="small"
                    endIcon={<DynamicIcons icon={homeButtons[3].icon} />}
                    style={{
                      color: '#fff',
                      borderColor: '#EADF74',
                      paddingLeft: '1.250em',
                    }}
                    onClick={(e) => {
                      handleClick(e, homeButtons[3], 3)
                    }}
                    onMouseEnter={() =>
                      setPopUp({
                        active: true,
                        message:
                          'Lead more by lifestyle, less by location to find your perfect home. It’s the modern way to seek and discover.',
                        subject: 'perfect-property',
                      })
                    }
                    onMouseLeave={() =>
                      setPopUp({
                        active: false,
                        message: '',
                        subject: '',
                      })
                    }
                  >
                    <span style={{ textAlign: 'center' }}>{homeButtons[3].name}</span>
                  </StyledButtonYellow>
                </ButtonRow>
              </Box>
              <Box width="100%">
                {popUp.active && popUp.subject === 'hush-hush' && (
                  <PopUp popUp={popUp} />
                )}
                <ButtonRow>
                  <StyledButton
                    className={
                      homeButtons[4].active
                        ? 'active btn-hero btn-' +
                          homeButtons[4].name.replace(/\s+/g, '-').toLowerCase()
                        : 'btn-hero btn-' +
                          homeButtons[4].name.replace(/\s+/g, '-').toLowerCase()
                    }
                    variant="outlined"
                    color="primary"
                    size="small"
                    endIcon={<DynamicIcons icon={homeButtons[4].icon} />}
                    style={{ textAlign: 'center', backgroundColor: '##5CC1A3' }}
                    onClick={(e) => {
                      handleClick(e, homeButtons[4], 4)
                    }}
                    onMouseEnter={() =>
                      setPopUp({
                        active: true,
                        message:
                          'Discover Off Market properties (location discreet) and Coming Soon properties (get excited for what’s to come).',
                        subject: 'hush-hush',
                      })
                    }
                    onMouseLeave={() =>
                      setPopUp({
                        active: false,
                        message: '',
                        subject: '',
                      })
                    }
                  >
                    <span style={{ textAlign: 'center' }}>{homeButtons[4].name}</span>
                  </StyledButton>
                </ButtonRow>
              </Box>
              <ButtonRow>
                <StyledButton
                  className={
                    homeButtons[0].active
                      ? 'active btn-hero btn-' +
                        homeButtons[0].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                        homeButtons[0].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<DynamicIcons icon={homeButtons[0].icon} />}
                  onClick={(e) => {
                    handleClick(e, homeButtons[0], 0)
                  }}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[0].name}</span>
                </StyledButton>
                <StyledButton
                  className={
                    homeButtons[1].active
                      ? 'active btn-hero btn-' +
                        homeButtons[1].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                        homeButtons[1].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<DynamicIcons icon={homeButtons[1].icon} />}
                  onClick={(e) => {
                    handleClick(e, homeButtons[1], 1)
                  }}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[1].name}</span>
                </StyledButton>
                <StyledButton
                  className={
                    homeButtons[2].active
                      ? 'active btn-hero btn-' +
                        homeButtons[2].name.replace(/\s+/g, '-').toLowerCase()
                      : 'btn-hero btn-' +
                        homeButtons[2].name.replace(/\s+/g, '-').toLowerCase()
                  }
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<DynamicIcons icon={homeButtons[2].icon} />}
                  onClick={(e) => {
                    handleClick(e, homeButtons[2], 2)
                  }}
                >
                  <span style={{ textAlign: 'center' }}>{homeButtons[2].name}</span>
                </StyledButton>
              </ButtonRow>
            </ButtonWrap>
          </div>
        </Grid>
        <Grid item sm={12} md={7} lg={8} className={classes.heroRightColumn}>
          <Image
            src={HeroImage}
            aspectRatio={16 / 11}
            imageStyle={{ objectFit: 'cover' }}
          ></Image>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(Hero)
