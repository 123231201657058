import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function BlockUnitIcon(props: SvgIconProps) {
  return (
   
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M33.161 48.901a.2.2 0 01-.2-.2v-7.863c0-.11.09-.2.2-.2.11 0 .2.09.2.2V48.7a.2.2 0 01-.2.2M28.322 48.901a.2.2 0 01-.2-.2v-7.863c0-.11.09-.2.2-.2.11 0 .2.09.2.2V48.7a.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M33.161 41.038h-4.838a.2.2 0 01-.2-.2c0-.111.088-.2.2-.2h4.838a.2.2 0 01.2.2.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0"
        width="6"
        height="2"
        x="28"
        y="47"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28.123 47.867h5.238v1.034h-5.238v-1.034z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M33.161 48.9h-4.838a.2.2 0 01-.2-.2c0-.111.088-.2.2-.2h4.838a.2.2 0 110 .4"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask1"
        width="36"
        height="38"
        x="13"
        y="11"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M13 48.901h35.482v-37.9H13v37.9z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g fill="#000" fillRule="evenodd" clipRule="evenodd">
        <path d="M28.521 37.01h4.438V30.15h-4.438v6.858zm4.64.4h-4.84a.2.2 0 01-.2-.2V29.95c0-.11.09-.2.2-.2h4.84c.11 0 .2.09.2.2v7.258a.2.2 0 01-.2.2z"></path>
        <path d="M30.741 37.41a.2.2 0 01-.2-.2v-7.258a.2.2 0 01.2-.2.2.2 0 01.2.2v7.258a.2.2 0 01-.2.2"></path>
        <path d="M33.161 32.571h-4.838a.2.2 0 01-.2-.2c0-.111.088-.2.2-.2h4.838a.2.2 0 110 .4M33.161 34.99h-4.838a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h4.838a.2.2 0 01.2.2.2.2 0 01-.2.2M28.521 26.123h4.438v-6.857h-4.438v6.857zm4.64.4h-4.84a.2.2 0 01-.2-.2v-7.259c0-.11.09-.2.2-.2h4.84c.11 0 .2.09.2.2v7.259a.2.2 0 01-.2.2z"></path>
        <path d="M30.741 26.523a.2.2 0 01-.2-.2v-7.259c0-.11.09-.2.2-.2.11 0 .2.09.2.2v7.259a.2.2 0 01-.2.2"></path>
        <path d="M33.161 21.684h-4.838a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h4.838a.2.2 0 110 .4M33.161 24.103h-4.838a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h4.838a.2.2 0 01.2.2.2.2 0 01-.2.2M36.185 14.426a.21.21 0 01-.099-.025l-5.443-3.025a.2.2 0 01-.077-.272.198.198 0 01.272-.078l5.445 3.025c.095.053.13.175.075.271a.195.195 0 01-.173.104"></path>
        <path d="M25.3 14.426a.195.195 0 01-.174-.104.199.199 0 01.075-.271l5.443-3.025a.199.199 0 01.272.078.2.2 0 01-.077.272L25.397 14.4a.211.211 0 01-.098.025M36.185 16.845a.21.21 0 01-.099-.025l-5.443-3.025a.2.2 0 01-.077-.272.2.2 0 01.272-.078l5.445 3.025c.095.053.13.175.075.271a.195.195 0 01-.173.104"></path>
        <path d="M25.3 16.846a.195.195 0 01-.174-.104.199.199 0 01.075-.271l5.443-3.025a.199.199 0 01.272.078.2.2 0 01-.077.272l-5.442 3.025a.21.21 0 01-.098.025"></path>
        <path d="M25.298 48.901a.2.2 0 01-.2-.2V14.226a.2.2 0 01.2-.2.2.2 0 01.2.2v34.475a.2.2 0 01-.2.2M36.185 48.901a.2.2 0 01-.2-.2V14.226a.2.2 0 01.2-.2.2.2 0 01.2.2v34.475a.2.2 0 01-.2.2M29.541 44.668a.202.202 0 01-.201-.2c0-.112.086-.2.196-.2h.005a.2.2 0 01.2.2.2.2 0 01-.2.2M44.048 48.901a.2.2 0 01-.2-.2v-7.863c0-.11.09-.2.2-.2.11 0 .2.09.2.2V48.7a.2.2 0 01-.2.2M39.21 48.901a.2.2 0 01-.2-.2v-7.863c0-.11.09-.2.2-.2.11 0 .2.09.2.2V48.7a.2.2 0 01-.2.2"></path>
        <path d="M44.048 41.038H39.21a.2.2 0 01-.2-.2c0-.111.088-.2.2-.2h4.838a.2.2 0 01.2.2.2.2 0 01-.2.2M44.048 48.9H39.21a.2.2 0 01-.2-.2c0-.111.088-.2.2-.2h4.838a.2.2 0 110 .4M39.41 37.01h4.438V30.15H39.41v6.858zm4.638.4h-4.839a.2.2 0 01-.201-.2V29.95c0-.11.09-.2.201-.2h4.839c.11 0 .2.09.2.2v7.258a.2.2 0 01-.2.2z"></path>
        <path d="M41.628 37.41a.2.2 0 01-.2-.2v-7.258a.2.2 0 01.2-.2.2.2 0 01.2.2v7.258a.2.2 0 01-.2.2"></path>
        <path d="M44.048 32.571H39.21a.2.2 0 01-.2-.2c0-.111.088-.2.2-.2h4.838a.2.2 0 110 .4M44.048 34.99H39.21a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h4.838a.2.2 0 01.2.2.2.2 0 01-.2.2M39.41 26.123h4.438v-6.857H39.41v6.857zm4.638.4h-4.839a.201.201 0 01-.201-.2v-7.259c0-.11.09-.2.201-.2h4.839c.11 0 .2.09.2.2v7.259a.2.2 0 01-.2.2z"></path>
        <path d="M41.628 26.523a.2.2 0 01-.2-.2v-7.259c0-.11.09-.2.2-.2.11 0 .2.09.2.2v7.259a.2.2 0 01-.2.2"></path>
        <path d="M44.048 21.684H39.21a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h4.838a.2.2 0 110 .4M44.048 24.103H39.21a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h4.838a.2.2 0 01.2.2.2.2 0 01-.2.2M47.071 14.426a.21.21 0 01-.098-.025l-5.443-3.025a.2.2 0 01-.077-.272.198.198 0 01.272-.078l5.445 3.025c.095.053.13.175.075.271a.195.195 0 01-.174.104"></path>
        <path d="M36.186 14.426a.195.195 0 01-.173-.104.199.199 0 01.075-.271l5.443-3.025a.199.199 0 01.272.078.2.2 0 01-.077.272L36.284 14.4a.21.21 0 01-.098.025M47.071 16.845a.21.21 0 01-.098-.025l-5.443-3.025a.2.2 0 01-.077-.272.2.2 0 01.272-.078l5.445 3.025c.095.053.13.175.075.271a.195.195 0 01-.174.104"></path>
        <path d="M36.186 16.846a.195.195 0 01-.173-.104.199.199 0 01.075-.271l5.443-3.025a.199.199 0 01.272.078.2.2 0 01-.077.272l-5.442 3.025a.21.21 0 01-.098.025M47.072 48.901a.2.2 0 01-.2-.2V14.226a.2.2 0 01.2-.2.2.2 0 01.2.2v34.475a.2.2 0 01-.2.2M40.428 44.668a.202.202 0 01-.202-.2c0-.112.087-.2.197-.2h.005a.2.2 0 01.2.2.2.2 0 01-.2.2M22.275 48.901a.2.2 0 01-.2-.2v-7.863c0-.11.09-.2.2-.2.11 0 .2.09.2.2V48.7a.2.2 0 01-.2.2M17.435 48.901a.2.2 0 01-.2-.2v-7.863c0-.11.09-.2.2-.2.11 0 .2.09.2.2V48.7a.2.2 0 01-.2.2"></path>
        <path d="M22.275 41.038h-4.839a.2.2 0 01-.2-.2c0-.111.089-.2.2-.2h4.839a.2.2 0 01.2.2.2.2 0 01-.2.2M22.275 48.9h-4.839a.2.2 0 01-.2-.2c0-.111.089-.2.2-.2h4.839a.2.2 0 110 .4M17.634 37.01h4.439V30.15h-4.439v6.858zm4.639.4h-4.839a.2.2 0 01-.2-.2V29.95c0-.11.09-.2.2-.2h4.839c.111 0 .201.09.201.2v7.258a.2.2 0 01-.201.2z"></path>
        <path d="M19.853 37.41a.2.2 0 01-.2-.2v-7.258a.2.2 0 01.2-.2.2.2 0 01.2.2v7.258a.2.2 0 01-.2.2"></path>
        <path d="M22.275 32.571h-4.839a.2.2 0 01-.2-.2c0-.111.089-.2.2-.2h4.839a.2.2 0 110 .4M22.275 34.99h-4.839a.2.2 0 01-.2-.2c0-.112.089-.2.2-.2h4.839a.2.2 0 01.2.2.2.2 0 01-.2.2M17.634 26.123h4.439v-6.857h-4.439v6.857zm4.639.4h-4.839a.2.2 0 01-.2-.2v-7.259c0-.11.09-.2.2-.2h4.839c.111 0 .201.09.201.2v7.259c0 .11-.09.2-.201.2z"></path>
        <path d="M19.853 26.523a.2.2 0 01-.2-.2v-7.259c0-.11.09-.2.2-.2.11 0 .2.09.2.2v7.259a.2.2 0 01-.2.2"></path>
        <path d="M22.275 21.684h-4.839a.2.2 0 01-.2-.2c0-.112.089-.2.2-.2h4.839a.2.2 0 110 .4M22.275 24.103h-4.839a.2.2 0 01-.2-.2c0-.112.089-.2.2-.2h4.839a.2.2 0 01.2.2.2.2 0 01-.2.2M25.298 14.426a.211.211 0 01-.098-.025l-5.444-3.025a.2.2 0 01-.076-.272.198.198 0 01.271-.078l5.445 3.025c.095.053.13.175.075.271a.195.195 0 01-.173.104"></path>
        <path d="M14.41 14.426a.195.195 0 01-.173-.104.199.199 0 01.075-.271l5.443-3.025a.199.199 0 01.272.078.2.2 0 01-.077.272L14.51 14.4a.21.21 0 01-.099.025M25.298 16.845a.211.211 0 01-.098-.025l-5.444-3.025a.2.2 0 01-.076-.272.2.2 0 01.271-.078l5.445 3.025c.095.053.13.175.075.271a.195.195 0 01-.173.104"></path>
        <path d="M14.41 16.846a.195.195 0 01-.173-.104.199.199 0 01.075-.271l5.443-3.025a.199.199 0 01.272.078.2.2 0 01-.077.272L14.51 16.82a.21.21 0 01-.099.025"></path>
        <path d="M14.412 48.901a.2.2 0 01-.2-.2V14.226a.2.2 0 01.2-.2.2.2 0 01.2.2v34.475a.2.2 0 01-.2.2M18.654 44.668a.202.202 0 01-.201-.2c0-.112.087-.2.197-.2h.005a.2.2 0 01.2.2.2.2 0 01-.2.2"></path>
        <path d="M48.282 48.9H13.2a.2.2 0 110-.4h35.082a.2.2 0 110 .4"></path>
      </g>
      {/* <rect
        width="59.5"
        height="59.5"
        x="0.25"
        y="0.25"
        stroke="#000"
        strokeWidth="0.5"
        rx="4.75"
      ></rect> */}
    </svg>
  );
}

export function BlockUnitIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BlockUnitIcon />
      <BlockUnitIcon color="primary" />
      <BlockUnitIcon color="secondary" />
      <BlockUnitIcon color="action" />
      <BlockUnitIcon color="disabled" />
      <BlockUnitIcon style={{ color: green[500] }} />
    </div>
  );
}
