import Trades from "../../containers/Mobile/Trades";
import TradesResults from "../../containers/Mobile/TradesResults";

let routes = [{
    path: '/trades',
    component: Trades,
},{
    path: '/trades/results',
    component: TradesResults,
}]

export default routes;
