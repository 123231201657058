import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FiltersActions } from 'store/actions'
import { transformFilterDataService } from 'utils/transformFilterDataService'

export default function useFilters() {
  const GLOBAL_FILTERS = useSelector((state) => state.globalFilters)
  const SELECTOR_CALLBACK = useCallback(
    (key) => {
      return GLOBAL_FILTERS[key]
    },
    [GLOBAL_FILTERS]
  )

  const dispatch = useDispatch()

  function getSingleFilter(key) {
    return SELECTOR_CALLBACK(key)
  }

  function setSingleFilter(args) {
    const { key: filterKey, value: filterValue } = args

    const transformedFilters = transformFilterDataService(
      filterKey,
      filterValue,
      GLOBAL_FILTERS
    )
    const params = { data: transformedFilters }

    dispatch(FiltersActions(params, 'GENERAL_FILTERS', 'SET_SINGLE_FILTER'))
  }
  return [getSingleFilter, setSingleFilter]
}
