import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, CircularProgress, Box, LinearProgress, withStyles } from '@material-ui/core';
import AlertNotification from 'components/Web/Form/AlertNotification';
import Loader from '../Loader';

const styles = (theme) => ({
  btn: {
    borderRadius: 30,
    textTransform: 'initial',
    fontWeight: '400',
    letterSpacing: '0.073125px',
    padding: '9px 15px',
    minWidth: '118px',
    color: '#ffffff',
    '& .MuiButton-label > span': {
      display: 'inline-flex',
    },

    '& .MuiButton-label > span svg': {
      color: '#ffffff !important',
    },
  },
  btnsWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(2),
  },
});

const StripePaymentForm = (props) => {
  const { classes, buttonLabel, redirectPath, intentType, readyCallback } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Prevent the default behavior of the beforeunload event
      event.preventDefault();
      // Chrome requires returnValue to be set to an empty string
      event.returnValue = '';

      // You can provide a custom message here if needed
      // event.returnValue = 'Are you sure you want to leave this page?';
    };

    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    let formError;

    if (intentType && intentType === 'confirmPayment') {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: window.location.origin + redirectPath,
        },
      });

      formError = error;
    } else {
      const { error } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: window.location.origin + redirectPath,
        },
      });
      formError = error;
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (formError.type === 'card_error' || formError.type === 'validation_error') {
      setMessage(formError.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <div id="payment-form">
      {!formLoaded && (
        <Box justifyContent={'center'} alignItems={'center'} style={{ minHeight: 80, display: 'flex' }}>
          <div>
            Loading payment details form...
            <LinearProgress />
          </div>
        </Box>
      )}

      {!formLoaded && <Loader open={!formLoaded} message={'Loading payment details form...'} />}
      <PaymentElement
        id="payment-element"
        onChange={(e) => {
          if (e.complete) {
            setCardComplete(true);
          }
        }}
        onReady={() => {
          setFormLoaded(true);
          if (readyCallback) {
            readyCallback();
          }
        }}
      />
      <div className={classes.btnsWrap}>
        <Button
          disabled={isLoading || !stripe || !elements || !cardComplete}
          id="submit"
          className={classes.btn}
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit}
        >
          <span>
            {isLoading ? <CircularProgress color="secondary" size={'18px'} /> : buttonLabel ?? 'Subscribe Now'}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(StripePaymentForm);
