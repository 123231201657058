import { 
    SET_USER,
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAIL
} from '../constants/user.constants';

export const setUser = (data) => {
    return {
        type: SET_USER,
        params: data
    };
};

export const getUserRequest = (params) => {
    return {
        type: GET_USER_REQUEST,
        params
    };
};

export const getUserRequestSuccess = (payload) => {
    return {
        type: GET_USER_SUCCESS,
        payload
    };
};

export const getUserRequestFail = (error) => {
    return {
        type: GET_USER_FAIL,
        error
    };
};