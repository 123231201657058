import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Image from 'components/Web/Image';

import TextImage01 from 'assets/images/content/guy-on-chair.svg';
import TextImage02 from 'assets/images/content/textimage02.svg';
import TextImage03 from 'assets/images/content/textimage03.svg';

const styles = (theme) => ({
  textImageWrap: {
    paddingLeft: 75,
    '@media (max-width: 960px) ': {
      paddingLeft: '50px',
    },
    '@media (min-width: 1440px)': {
      paddingLeft: 100,
    },
  },
  imageWrap: {
    maxWidth: 468,
    marginBottom: 18,
    '@media (max-width: 960px) ': {
      paddingLeft: '50px',
      margin: '0 auto',
      marginBottom: 60,
    },
  },
  sectionHeading: {
    fontFamily: 'PT Serif, serif',
    fontWeight: 400,
    fontSize: 48,
    lineHeight: '45.5px',
    color: theme.palette.gray.main,
    letterSpacing: '-0.78px',
    marginBottom: '32px',
    '& strong': {
      fontWeight: 700,
      fontFamily: 'Montserrat, sans',
      display: 'block',
      fontSize: 56,
    },
    '@media (max-width: 960px) ': {
      //textAlign: 'center',
      //paddingLeft: '50px',
    },
  },
  sectionSubHeading: {
    fontFamily: 'Montserrat, sans',
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '32px',
    letterSpacing: '-0.52px',
  },
  sectionLeadText: {
    color: theme.palette.gray.main,
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '1.6em',
    '@media (max-width: 960px) ': {
      //paddingLeft: '50px',
      marginBottom: 64,
      //textAlign: 'center',
    },
  },
  textContents: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'flex',
    paddingRight: '0 !important',
    paddingLeft: '50px',
    '@media (max-width: 1280px) ': {
      paddingLeft: 0,
    },
  },
});

const AuthImages = (props) => {
  const { classes, type } = props;

  const _renderLogin = () => (
    <div className={classes.textImageWrap}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={7}>
          <div className={classes.imageWrap} style={{ maxWidth: 398 }}>
            <Image
              src={TextImage02}
              style={{ width: 475 }}
              aspectRatio={4 / 3.6}
              color={'transparent'}
              imageStyle={{ objectFit: 'contain' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.textContents}>
          <Typography component={'h1'} className={classes.sectionHeading}>
            {props.heading ?? (
              <>
                <strong>home</strong>
                feels
                <br />
                better here
              </>
            )}
          </Typography>
          <Typography component={'h6'} className={classes.sectionLeadText}>
            {props.leadtext ?? (
              <span>
                A home and community you love. Somewhere you feel safe, grounded, nurtured, fulfilled. Home feels better
                here.
              </span>
            )}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  const _renderForgot = () => (
    <div className={classes.textImageWrap}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={7}>
          <div className={classes.imageWrap} style={{ maxWidth: 398 }}>
            <Image
              src={TextImage01}
              style={{ width: 475 }}
              aspectRatio={4 / 3.6}
              color={'transparent'}
              imageStyle={{ objectFit: 'contain' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.textContents}>
          <Typography component={'h1'} className={classes.sectionHeading}>
            <strong>buy</strong>
            and sell
            <br />
            property
          </Typography>
          <Typography component={'h6'} className={classes.sectionLeadText}>
            And connect to all the things that matter. Enjoy an effortless and exciting user experience.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );

  const _renderSignup = () => (
    <div className={classes.textImageWrap}>
      <Grid container spacing={0}>
        <Grid item xs={12} lg={7}>
          <div className={classes.imageWrap} style={{ maxWidth: 398 }}>
            <Image
              src={TextImage03}
              style={{ width: 475 }}
              aspectRatio={4 / 3.6}
              color={'transparent'}
              imageStyle={{ objectFit: 'contain' }}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={5} className={classes.textContents}>
          <Typography component={'h1'} className={classes.sectionHeading}>
            <strong>home</strong>
            feels
            <br />
            better here
          </Typography>
          <Typography component={'h6'} className={classes.sectionLeadText}>
            A home and community you love. Somewhere you feel safe, grounded, nurtured, fulfilled. Home feels better
            here.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
  if (type === 'login') {
    return _renderLogin();
  } else if (type === 'signup') {
    return _renderSignup();
  } else {
    return _renderForgot();
  }
};

export default withStyles(styles)(AuthImages);
