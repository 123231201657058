import React from "react";
import { SuperBreadCrumbs } from "components/Web/Superadmin";

import { withStyles } from '@material-ui/core';

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFF",
    width: "100%",
    height: "100vh",
    paddingLeft: "10vw",
  },
});

const SuperadminProperties = (props) => {
  const { classes, history } = props;

  const CRUMBS = [
    {
      type: "secondary",
      label: "Home",
      path: "/superadmin/dashboard",
    },
    {
      type: "primary",
      label: "Manage Properties",
      path: "/superadmin/manage-properties",
    },
  ];

  return (
    <div className={classes.root}>
      <SuperBreadCrumbs
        section={"Manage Properties"}
        crumbsLinks={CRUMBS}

      />
    </div>
  );
};
export default withStyles(styles)(SuperadminProperties);
