import React from 'react';

import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';

import FormField from './FormField'
import OptionModalBottom from "./OptionModalBottom";

import { withStyles, Typography, Paper, IconButton, List, ListItem, ListItemText } from '@material-ui/core';

const styles = theme => ({
    root: {
        //paddingTop: theme.spacing(1),
        backgroundColor: '#FFF',
        position: 'relative',
        '& > svg' : {
            position: 'absolute',
            right: theme.spacing(2),
            top: theme.spacing(3.5),
            opacity: 0.54
        },
        '& .MuiInputBase-input' : {
            paddingRight: theme.spacing(4.5),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'no-wrap'
        }
    },
    optionButton: {
        textTransform: 'none',
        color: '#000',
        fontWeight: 'normal'
    },
    optionIcon: {
        color: '#000000'
    },

    /** Bottomn Option Modal */
    optionBottomContainer: {
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        '&:hover': {
          outline: 'none',
        },
    },
    optionBottomHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        paddingTop: theme.spacing(4),
    },
    optionBottomHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    optionBottomHeaderText: {
        fontWeight: '300'
    },
    optionBottonList: {
        height: '30vh',
        overflowY: 'scroll'
    },
    listitem: {
        textAlign: 'center'
    }
    
    
});

const PopUpDropdownField = props => {
    
    const { 
        classes,
        label,
        placeholder,
        options,
        value,
        selected, 
        onChange,
        onBlur,
        errorMsg,
        showError,
        type        
    } = props;

    const [modalOpen, setModalOpen] = React.useState(false);
    const [selectOptions, setSelectOptions] = React.useState(null); 
    function handleModalOpen(){
        setModalOpen(!modalOpen)
    }

    const handleSelect = (event, newValue) => {        
        if(onChange) {        
            onChange(event, newValue)
        }
        if(onBlur) {        
            onBlur(event, newValue)
        }
        handleModalOpen()
    };

    React.useEffect(()=>{
        setSelectOptions(options)
    },[options])
    
    function renderContent(){        
        return(
            <Paper 
                className={classes.optionBottomContainer} 
                elevation={5}>
                <div className={classes.optionBottomHeaderContainer}>
                    <div className={classes.optionBottomHeader}>
                        <UnfoldMoreIcon />
                        <Typography className={classes.optionBottomHeaderText} variant="h6" component="h2">
                            {label}
                        </Typography>
                    </div>
                    <IconButton onClick={()=> setModalOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.optionBottonList}>
                    <ListOptions options={selectOptions}/>
                </div>
            </Paper>
        )
    }

    const SelectField = () => {        

        return(
            <div className={classes.root} onClick={()=> setModalOpen(true)}>                    
                <FormField 
                    type={type ? type : 'text'}
                    value={value}
                    placeholder={placeholder}
                    onChange={handleSelect}
                    onBlur={handleSelect}
                    errorMsg={errorMsg}
                    showError={showError}                        
                />
                <KeyboardArrowDownSharpIcon fontSize="small"/>
            </div>
        )
    }
    const ListOptions = (props) => {        
        return(        
                <List component="nav" aria-label={placeholder}>
                    {
                   
                        props.options && props.options.map((value, index) => {
                                return(
                                    <ListItem
                                        key={index}
                                        button
                                        value={value}
                                        selected={selected === value ? true : false}                                    
                                        onClick={(event) => handleSelect(event, value) }>
                                        <ListItemText className={classes.listitem} primary={value} />
                                    </ListItem>
                                )
                        })
                    }
                </List>            
        )
    }
    

    return (
        <>
            {                
                <SelectField />
            }
            
             <OptionModalBottom
                isOpen={modalOpen} 
                showOptionBottomModal={handleModalOpen} 
                content={renderContent()} />
        </>
    );
};

export default withStyles(styles)(PopUpDropdownField);
