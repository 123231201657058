import React from 'react'
import TextSmall from '../TextSmall'
import SectionHeading from '../SectionHeading'
import KeyboardArrowUpSharpIcon from '@material-ui/icons/KeyboardArrowUpSharp'

import FILE_PDF from '../../../assets/statementofinformation-3686.pdf'

import { withStyles, Button, Link } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  toTopIconGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#4d4d4d',
    fontSize: '0.75rem',
  },
  toTopGroupIcon: {
    marginRight: theme.spacing(1),
  },
  cta: {
    color: '#000000',
    borderRadius: '10rem',
    textTransform: 'initial',
    padding: theme.spacing(1.5, 1),
    minWidth: 285,
    marginRight: theme.spacing(3),
  },
})

const PriceGuideSection = (props) => {
  const { classes, properties } = props

  const scrollToTop = () => {
    if (window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <div className={classes.root}>
      <Link
        style={{ cursor: 'pointer' }}
        className={classes.toTopIconGroup}
        onClick={() => scrollToTop()}
      >
        <KeyboardArrowUpSharpIcon fontSize="small" className={classes.toTopGroupIcon} />
        <span>Back to Top</span>
      </Link>
      {/* <SectionHeading variant="h6" component="h2">
                Price Guide
            </SectionHeading> */}
      {/* <TextSmall>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Molestias laborum ea quia amet provident reprehenderit, animi pariatur ullam cupiditate asperiores.
            </TextSmall> */}

      <Button
        variant="outlined"
        color="primary"
        className={classes.cta}
        href={
          properties && properties.statementOfIntent
            ? properties.statementOfIntent
            : properties && properties.statementOfInformation
            ? properties.statementOfInformation
            : null
        }
        target="_blank"
      >
        View statement of information
      </Button>
    </div>
  )
}

export default withStyles(styles)(PriceGuideSection)
