import React from 'react'

import { withStyles } from '@material-ui/styles'
import image from 'assets/images/agent-activation-image.png'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Paper,
} from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = (theme) => ({
  textWrap: {
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  actions: {
    justifyContent: 'start',
    padding: '8px 24px',
  },
  actionsBtn: {
    minWidth: 140,
    maxWidth: 140,
    backgroundColor: '#35C0CA',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    textTransform: 'capitalize',
    borderRadius: 20,
    padding: '6px 24px',
    color: '#000',
    '&:hover': {
      backgroundColor: '#2fa8b1',
    }
  },
  content: {
    textAlign: 'right',
    paddingBottom: 35,
  }
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AlertDialogSlide = ({ isOpen, classes, title, message, redirectUrl }) => {
  const [routerChange] = useRouterChange()
  return (
    <>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title ?? "Hello there"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={classes.textWrap}
          >
            {message ?? "Your account is successfully activated!"}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            variant={'contained'}
            size={'medium'}
            disableElevation
            onClick={() => routerChange(redirectUrl ?? '/agent/login')}
            className={classes.actionsBtn}
          >
            Log in
          </Button>
        </DialogActions>
        <DialogContent className={classes.content}>
          <img src={image} alt="Activate Agent" />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default withStyles(styles)(AlertDialogSlide)
