import React from "react";
import PropTypes from 'prop-types';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { 
  toggleThemeMode, 
  toggleSignUp 
} from "../../../store/actions/settings.actions";

import Authentication from './Authentication';

import { withStyles } from '@material-ui/core';

const styles = theme => ({
});

const Form = props => {
  const { type } = props;

  return type === 'Authentication' && <Authentication changeRoute={props.changeRoute}/>
};

Form.propTypes = {
  type: PropTypes.string
};

const mapStateToProps = state => {
  return {
    settings: state.settings
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleThemeMode: checked => toggleThemeMode(checked),
      toggleSignUp: checked => toggleSignUp(checked),
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Form));