export const SET_USER = "user/SETUSER";

export const GET_USER_REQUEST = "user/GET_USER_REQUEST";
export const GET_USER_SUCCESS = "user/GET_USER_SUCCESS";
export const GET_USER_FAIL = "user/GET_USER_FAIL";

export const UPDATE_USER = "user/UPDATE_USER";
export const UPDATE_USER_SUCCESS = "user/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "user/UPDATE_USER_FAIL";
export const UPDATE_USER_CLEAR = "user/UPDATE_USER_CLEAR";
