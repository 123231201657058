import React, { useState } from 'react'

import BedIcon from '@material-ui/icons/Hotel'
import ParkingIcon from '@material-ui/icons/DriveEta'
import CallIcon from '@material-ui/icons/Call'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded'

import DynamicIcons from '../DynamicIcons'

import { withStyles, IconButton, Typography, Box } from '@material-ui/core'
const LINES_TO_SHOW = 2

const styles = (theme) => ({
  root: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  iconContainer: {
    marginRight: '0.313em',
    width: '0.625em',
    height: '0.625em',
  },
  icon: {
    color: '#000',
    width: '0.625em',
    height: '0.625em',
    marginRight: '0.213em',
  },
  customIconContainer: {
    marginRight: '0.313em',
    width: '0.525em',
    height: '0.525em',
  },
  customIcon: {
    color: '#000',
    width: '0.525em',
    height: '0.525em',
    marginRight: '0.213em',
  },
  pricing: {
    fontSize: 12,
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': LINES_TO_SHOW,
    '-webkit-box-orient': 'vertical',
    lineHeight: 1.1,
  },

  mainTrade: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '0.625em',
    marginBottom: '0.625em',
  },
  callIcon: {
    color: '#000',
    width: '0.825em',
    height: '0.825em',
  },
  companyName: {
    fontSize: 14,
    fontWeight: 500,
  },
  inlineicons: {
    verticalAlign: 'middle',
    fontSize: '1.2rem',
  },
})

const MapPropertyCardInfo = (props) => {
  const { classes, type, properties, trades } = props

  const addressRegex = / *AU *$|AUS *$/gm
  function trucateText(text) {
    return text.length > 35
      ? text.replace(addressRegex, '').substring(0, 35) + '...'
      : text
  }

  function renderProperties() {
    return (
      <div className={classes.root}>
        <div className={classes.main}>
          <div>
            <IconButton className={classes.iconContainer} onClick={() => ''}>
              <BedIcon className={classes.icon} />
              <Typography variant="caption" color="textSecondary" component="p">
                {properties.bedrooms}
              </Typography>
            </IconButton>
            <IconButton className={classes.customIconContainer} onClick={() => ''}>
              <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
              <Typography variant="caption" color="textSecondary" component="p">
                {properties.bathrooms}
              </Typography>
            </IconButton>
            <IconButton className={classes.iconContainer} onClick={() => ''}>
              <ParkingIcon className={classes.icon} />
              <Typography variant="caption" color="textSecondary" component="p">
                {properties.totalParking}
              </Typography>
            </IconButton>
          </div>
        </div>
        <Typography className={classes.pricing} variant="body2" component="p">
          {properties?.priceView
            ? properties.priceView
            : properties?.rentalPrice
            ? `$${properties.rentalPrice} weekly`
            : ''}
        </Typography>
        {(properties &&
          properties.addressText &&
          properties.propertyStatus === 'current') ||
        properties.propertyStatus === 'On-Sale' ? (
          <Typography
            style={{ lineHeight: 1.3 }}
            variant="caption"
            color="textSecondary"
            component="p"
          >
            {trucateText(properties.addressText)}
          </Typography>
        ) : (
          <>
            <Typography variant="caption" color="textSecondary" component="p">
              {properties.addressSuburb}
            </Typography>
            <Typography variant="caption" color="textSecondary" component="span">
              <EmailRoundedIcon className={classes.inlineicons} fontSize="small" />{' '}
              Email Agent
            </Typography>
          </>
        )}
      </div>
    )
  }

  return <>{renderProperties()}</>
}

export default withStyles(styles)(MapPropertyCardInfo)
