import React, { useEffect } from 'react'

import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import CloseIcon from '@material-ui/icons/Close'
import ImportExportIcon from '@material-ui/icons/ImportExport'

import { OPTION_MODAL, TRADE_OPTION_MODAL } from './constants'

import ListOptions from './ListOptions'
import OptionModalBottom from './OptionModalBottom'

import Sticky from 'react-sticky-el'

import { withStyles, Button, Typography, Paper, IconButton } from '@material-ui/core'

const styles = (theme) => ({
  root: {
    //paddingTop: theme.spacing(1),
    backgroundColor: '#FFF',
    '& .sticky': {
      top: '66px !important',
      backgroundColor: '#fff',
      zIndex: '100',
    },
  },
  rootLiked: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#FFF',
  },
  rootPerfectProperty: {
    paddingTop: theme.spacing(1),
    backgroundColor: '#FFF',
  },
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionButton: {
    textTransform: 'none',
    color: '#000',
    fontWeight: 'normal',
  },
  optionIcon: {
    color: '#000000',
  },

  /** Bottomn Option Modal */
  optionBottomContainer: {
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    '&:hover': {
      outline: 'none',
    },
  },
  optionBottomHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
  },
  optionBottomHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionBottomHeaderText: {
    fontWeight: '300',
  },
  optionBottonList: {
    height: '30vh',
    overflowY: 'scroll',
  },
  perfectText: {
    fontSize: '1em',
  },

  likedText: {
    fontSize: '1.313em',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  likedContainer: {
    margin: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  tradesText: {
    fontSize: '1.000em',
    fontWeight: '400',
    fontStyle: 'normal',
  },
  tradesContainer: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

const SortSelection = (props) => {
  const {
    classes,
    sortType,
    showModalBottomOptions,
    showOptionBottomModal,
    setSearchSortingFilters,
    filters,
    path,
  } = props
  const OPTIONS = sortType === 'trades-results' ? TRADE_OPTION_MODAL : OPTION_MODAL
  const [sortBy, setSortBy] = React.useState(
    filters ? (filters.sorting ? filters.sorting : OPTIONS) : OPTIONS
  )
  const [suburb, setSuburb] = React.useState(null)

  const handleSortBy = (data) => {
    setSearchSortingFilters(data)
    showOptionBottomModal()
  }

  useEffect(() => {
    if (filters && filters.sorting) {
      setSortBy(filters.sorting)
    }
  }, [filters])

  const getSuburbLabel = () => {
    let label = ''
    if (filters.perfectProperty && filters.perfectProperty.state) {
      filters.perfectProperty.state.map((item, index) => {
        if (item.active) {
          label = getState(item.title)
        }
      })
    }

    return label
  }

  const getState = (label) => {
    let t = label.toLowerCase()
    switch (t) {
      case 'vic':
        return 'Victoria'
      case 'nsw':
        return 'New South Wales'
      case 'wa':
        return 'Western Australia'
      case 'tas':
        return 'Tasmania'
      case 'sa':
        return 'South Australia'
      case 'nt':
        return 'Northern Territory'
      case 'act':
        return 'Australian Capital Territory'
      case 'qld':
        return 'Queensland'
      default:
        return '' // default
    }
  }

  const renderResultsText = () => {
    let propertyText = ' properties'

    if (props.path && props.path === 'off-market') {
      propertyText = ' off-market properties'
    }

    return (
      <Typography variant="caption" component="h2">
        {'Showing ' +
          `${props.current ? props.current : 0}` +
          ' of ' +
          `${props.total ? props.total : 0}` +
          propertyText}
      </Typography>
    )
  }

  function renderContent() {
    return (
      <Paper className={classes.optionBottomContainer} elevation={5}>
        <div className={classes.optionBottomHeaderContainer}>
          <div className={classes.optionBottomHeader}>
            <UnfoldMoreIcon />
            <Typography
              className={classes.optionBottomHeaderText}
              variant="h6"
              component="h2"
            >
              {'Sort by'}
            </Typography>
          </div>
          <IconButton onClick={() => showOptionBottomModal()}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.optionBottonList}>
          <ListOptions type={'sort'} options={sortBy} handleType={handleSortBy} />
        </div>
      </Paper>
    )
  }

  function searchSort() {
    return (
      <div className={classes.root}>
        <Sticky scrollElement="#scroll-container" topOffset={-66}>
          <div className={classes.container}>
            {renderResultsText()}
            {/* <Button 
                            className={classes.optionButton}
                            size={'small'}
                            endIcon={<ImportExportIcon className={classes.optionIcon}/>}
                            onClick={()=> showOptionBottomModal()}>
                            {'Sort'}
                        </Button> */}
          </div>
        </Sticky>
      </div>
    )
  }

  function searchPerfect() {
    let address = filters
      ? filters.perfectProperty
        ? 'in ' + getSuburbLabel()
        : ''
      : ''
    return (
      <div className={classes.rootPerfectProperty}>
        <div className={classes.container}>
          <Typography className={classes.perfectText} variant="caption" component="h2">
            {props.total + ' properties found ' + address}
          </Typography>
        </div>
      </div>
    )
  }

  function searchLiked() {
    return (
      <div className={classes.rootLiked}>
        <div className={classes.likedContainer}>
          <Typography className={classes.likedText} variant="caption" component="h2">
            {path === 'favourites' && 'Favourite Properties'}
            {path === 'liked-properties' && 'Liked Properties'}
          </Typography>
          <Button
            className={classes.optionButton}
            size={'small'}
            endIcon={<ImportExportIcon className={classes.optionIcon} />}
            onClick={() => showOptionBottomModal()}
          >
            {'Sort'}
          </Button>
        </div>
      </div>
    )
  }

  function searchTrades() {
    return (
      <div className={classes.root}>
        <div className={classes.tradesContainer}>
          <Typography className={classes.tradesText} variant="caption" component="h2">
            {'88 trades and services found in Moonee Ponds, Vic, 3039'}
          </Typography>
          {/* <Button 
                        className={classes.optionButton}
                        size={'small'}
                        endIcon={<ImportExportIcon className={classes.optionIcon}/>}
                        onClick={()=> showOptionBottomModal()}>
                        {'Sort'}
                    </Button> */}
        </div>
      </div>
    )
  }

  function searchDisliked() {
    return (
      <div className={classes.rootFavourites}>
        <div className={classes.favouritesContainer}>
          <Button
            className={classes.optionButton}
            size={'small'}
            endIcon={<ImportExportIcon className={classes.optionIcon} />}
            onClick={() => showOptionBottomModal()}
          >
            {'Sort'}
          </Button>
        </div>
      </div>
    )
  }

  function searchFavourites() {
    return (
      <div className={classes.rootFavourites}>
        <div className={classes.favouritesContainer}>
          <Button
            className={classes.optionButton}
            size={'small'}
            endIcon={<ImportExportIcon className={classes.optionIcon} />}
            onClick={() => showOptionBottomModal()}
          >
            {'Sort'}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <>
      {sortType === 'search' && searchSort()}

      {sortType === 'perfect-property' && searchPerfect()}
      {sortType === 'liked-property' && searchLiked()}
      {sortType === 'trades-results' && searchTrades()}
      {sortType === 'disliked-property' && searchDisliked()}
      {sortType === 'user-favourites' && searchFavourites()}
      <OptionModalBottom
        isOpen={showModalBottomOptions}
        showOptionBottomModal={showOptionBottomModal}
        content={renderContent()}
      />
    </>
  )
}

export default withStyles(styles)(SortSelection)
