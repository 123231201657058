import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Drawer, AppBar, CssBaseline, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import DynamicIcons from '../DynamicIcons';
import {
  DEFAULT_NAVIGATION,
  LOGGED_IN_NAVIGATION,
  AGENT_NAVIGATION,
  AGENCY_NAVIGATION,
  SUPERADMIN_NAVIGATION,
} from './constants';
import useFilters from 'utils/customHooks/useFilters';
import useGoogleEvents from 'utils/customHooks/useGoogleEvents';
import useHandleManageAgencyRowCount from 'utils/customHooks/useHandleManageAgencyRowCount';
import useHandleManageAgencyPageCount from 'utils/customHooks/useHandleManageAgencyPageCount';
import useHandleManageAgencyButtons from 'utils/customHooks/useHandleManageAgencyButtons';
import useHandleManageAgencyFilters from 'utils/customHooks/useHandleManageAgencyFilters';
import useHandleManageAgencySort from 'utils/customHooks/useHandleManageAgencySort';
import useHandleManageAgencySearchField from 'utils/customHooks/useHandleManageAgencySearchField';

const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 999,
  },
  rootOpen: {
    width: 175,
  },
  rootClose: {
    width: 90,
  },
  rootAgent: {
    backgroundColor: '#142332 !important',
  },
  rootSuperadmin: {
    backgroundColor: '#2a3a51 !important',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.24)',
  },
  windowsScroll: {
    '&::-webkit-scrollbar-track': {
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0)',
      backgroundColor: 'rgba(255,255,255,0)',
    },
    '&::-webkit-scrollbar': {
      width: '0px',
      backgroundColor: 'rgba(255,255,255,0)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.0)',
    },
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.24)',
  },
  drawerOpen: {
    width: 175,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 90,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    flexDirection: 'column',
    paddingTop: theme.spacing(5),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const NavList = styled.div`
  padding-bottom: 20px;
  &.open {
    a {
      width: 175px;
      span {
        opacity: 1;
        left: 0;
      }
    }
  }
`;

const NavItem = styled.div``;

const NavLink = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.4px;
  color: #4d4d4d;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 18px 33px;
  position: relative;
  width: 90px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    background-color: #35c0ca;
    position: absolute;
    right: -6px;
    opacity: 0;
    transition: all 0.3s ease;
  }

  span {
    display: block;
    width: 54px;
    margin-left: 10px;
    opacity: 0;
    position: relative;
    left: -6px;
    transition: all 0.3s ease;
  }

  &.item-rent {
    svg {
      transform: rotate(90deg);
    }
  }

  &.active,
  &:hover {
    background-color: rgba(218, 218, 218, 0.5);

    &:after {
      opacity: 1;
      right: 0;
    }
  }

  &.page-active {
    background-color: rgba(218, 218, 218, 0.5);

    &:after {
      opacity: 1;
      right: 0;
    }
  }

  ${(props) =>
    props.isAgent &&
    css`
      color: #fff;

      &:after {
        background-color: #35c0ca;
      }
    `}
`;

const NavLinkSuperAdmin = styled.a`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 18px 33px;
  position: relative;
  width: 90px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:after {
    content: '';
    display: block;
    width: 6px;
    height: 100%;
    background-color: #35c0ca;
    position: absolute;
    right: -6px;
    opacity: 0;
    transition: all 0.3s ease;
  }

  span {
    display: block;
    width: 54px;
    margin-left: 10px;
    opacity: 0;
    position: relative;
    left: -6px;
    transition: all 0.3s ease;
  }

  &.item-rent {
    svg {
      transform: rotate(90deg);
    }
  }

  &.active,
  &:hover {
    background-color: rgba(218, 218, 218, 0.5);

    &:after {
      opacity: 1;
      right: 0;
    }
  }

  &.page-active {
    background-color: rgba(218, 218, 218, 0.5);

    &:after {
      opacity: 1;
      right: 0;
    }
  }
`;

const SEARCH_REFERENCE_FILTERS = ['perfect-property', 'hush-hush', 'buy', 'rent', 'sold'];

export default function Sidebar(props) {
  const classes = useStyles();
  const { open, handleToggleDrawer, isAgent, isAgency, isSuperadmin } = props;
  // const [isAgent, setIsAgent] = useState(props.isAgent);
  const [navigation, setNavigation] = useState(null);
  const [getFilter, setFilter] = useFilters();
  const SEARCH_BUTTONS = getFilter('search_reference');

  const [rowCount, setRowCount] = useHandleManageAgencyRowCount();
  const [pageCount, setPageCount] = useHandleManageAgencyPageCount();
  const [manageButtons, setManageButtons] = useHandleManageAgencyButtons();
  const [manageFilters, setManageFilters] = useHandleManageAgencyFilters();
  const [manageSort, setManageSort] = useHandleManageAgencySort();
  const [manageSearchField, setManageSearchField] = useHandleManageAgencySearchField();

  const { triggerAnalyticsEvent } = useGoogleEvents();

  function checkUserAuthentication() {
    let navigation = isSuperadmin
      ? SUPERADMIN_NAVIGATION
      : isAgency
      ? AGENCY_NAVIGATION
      : isAgent
      ? AGENT_NAVIGATION
      : !props.auth
      ? DEFAULT_NAVIGATION
      : LOGGED_IN_NAVIGATION;
    setNavigation(navigation);
  }

  useEffect(() => {
    checkUserAuthentication();
  }, []);

  useEffect(() => {
    checkUserAuthentication();
  }, [props.auth]);

  useEffect(() => {
    if (!props.filterWebActive && navigation) {
      setNavigation(
        navigation.map((item) => {
          return { ...item, active: false };
        }),
      );
    }
  }, [props.filterWebActive]);

  function checkSidebarState() {
    let currentPath = '/' + getRoutePath();
    let currentIndex = _.findIndex(navigation, function (o) {
      return o.path == currentPath;
    });

    if (!navigation[currentIndex.active]) {
      let navigation = isAgent ? AGENT_NAVIGATION : props.auth === null ? DEFAULT_NAVIGATION : LOGGED_IN_NAVIGATION;

      // setNavigation(navigation.map(item =>
      //   item.path === currentPath
      //   ? {...item, active : item.active}
      //   : {...item, active : item.active}
      // ))
    }
  }

  const handleClick = (e, obj, index) => {
    e.preventDefault();
    /*  if(obj.cls === 'hush-hush') {
      localStorage.setItem('isOffMarket', true);
    } else {
      localStorage.removeItem('isOffMarket')
    } */

    triggerAnalyticsEvent({
      event: 'menu_actions',
      eventFrom: 'Web',
      actionScope: 'menu',
      actionName: 'Menu',
      actionTarget: 'Clicked ' + obj?.path + ' from Menu',
      actionReference: 'menu',
    });

    if (SEARCH_REFERENCE_FILTERS.includes(obj.cls)) {
      if (obj.cls.toLowerCase() === 'hush-hush') {
        const homeButtons = SEARCH_BUTTONS.map((item) =>
          item.path.toLowerCase().replace('/', '') === 'coming-soon' ||
          item.path.toLowerCase().replace('/', '') === 'off-market'
            ? { ...item, active: true }
            : { ...item, active: false },
        );
        setFilter({ key: 'search_reference', value: homeButtons });
      } else {
        const homeButtons = SEARCH_BUTTONS.map((item) =>
          item.path.toLowerCase().replace('/', '') === obj.cls.toLowerCase()
            ? { ...item, active: true }
            : { ...item, active: false },
        );
        setFilter({ key: 'search_reference', value: homeButtons });
      }
    }

    setNavigation(
      navigation.map((item) => (item.cls === obj.cls ? { ...item, active: !item.active } : { ...item, active: false })),
    );
    let o = Object.assign([], obj);
    o.active = !obj.active;
    props.handleNavigation(o);
  };

  const getRoutePath = () => {
    return props.page ? props.page : props.match.path.split('/')[1];
  };

  const getCurrentPath = () => {
    let path = props.match.path.split('/')[1];
    if (props.filterWebActive) {
      path = props.page.split('/')[1];
    }
    return path;
  };

  const isActiveRoute = (value) => {
    return props.match.path === value.path;
  };

  const isRouteAuth = () => {
    return (
      props.match.path.includes('/login') ||
      props.match.path.includes('/forgot-password') ||
      props.match.path.includes('/activate') ||
      props.match.path.includes('/confirm-property')
    );
  };

  const _renderSuperadminDrawer = (value, index) => (
    <NavLinkSuperAdmin
      href="#"
      isAgent={isAgent}
      onClick={(e) => {
        if (value.path === '/superadmin/manage-agencies') {
          setRowCount(30);
          setPageCount(0);
          setManageButtons([
            {
              id: 0,
              label: 'Paid',
              active: true,
            },
            {
              id: 1,
              label: 'Free',
              active: false,
            },
          ]);
          setManageFilters({ subscription: 'ALL', docusign: 'ALL', payment: 'ALL', adminEmail: 'ALL', active: 'ALL' });
          setManageSort([
            {
              id: 0,
              key: 'registeredTradeName',
              sort: false,
              sortOrder: null,
            },
            {
              id: 1,
              key: 'address',
              sort: false,
              sortOrder: null,
            },
            {
              id: 2,
              key: 'adminEmail',
              sort: false,
              sortOrder: null,
            },
            {
              id: 3,
              key: 'subscription.billingDate',
              sort: false,
              sortOrder: null,
            },
          ]);
          setManageSearchField('');
        }
        handleClick(e, value, index);
      }}
      className={clsx(isActiveRoute(value) && 'active item-icons item-' + value.cls.replace(/\s+/g, '-').toLowerCase())}
    >
      <DynamicIcons icon={value.icon} />
      <span dangerouslySetInnerHTML={{ __html: value.name }}></span>
    </NavLinkSuperAdmin>
  );

  const _renderDefaultDrawer = (value, index) => (
    <NavLink
      href="#"
      isAgent={isAgent}
      onClick={(e) => {
        handleClick(e, value, index);
      }}
      className={clsx(
        isActiveRoute(value)
          ? 'active item-icons item-' + value.cls.replace(/\s+/g, '-').toLowerCase()
          : 'item-icons item-' + value.cls.replace(/\s+/g, '-').toLowerCase(),
        '/' + getCurrentPath() === value.path ? 'page-active ' + getCurrentPath() : '',
      )}
    >
      <DynamicIcons icon={value.icon} />
      <span dangerouslySetInnerHTML={{ __html: value.name }}></span>
    </NavLink>
  );

  const _renderAdminDrawer = () => (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
        [classes.windowsScroll]: !window.navigator.platform.match(/^Mac/),
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.rootAgent]: isAgent,
          [classes.rootSuperadmin]: isSuperadmin || isAgency,
          [classes.windowsScroll]: !window.navigator.platform.match(/^Mac/),
        }),
      }}
    >
      <div
        className={classes.toolbar}
        onMouseEnter={() => handleToggleDrawer(true)}
        onMouseLeave={() => handleToggleDrawer(false)}
      >
        <DynamicIcons
          style={{
            width: '43px',
            height: '67px',
            margin: '45px auto',
            cursor: 'pointer',
          }}
          icon={'2DCPIconColor'}
        />
        {!isRouteAuth() && (
          <NavList className={open ? 'open' : ''}>
            {navigation &&
              navigation.map((value, index) => (
                <NavItem key={'list' + index}>{_renderSuperadminDrawer(value, index)}</NavItem>
              ))}
          </NavList>
        )}
      </div>
    </Drawer>
  );

  const _renderDrawer = () => (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
        [classes.windowsScroll]: !window.navigator.platform.match(/^Mac/),
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
          [classes.rootAgent]: isAgent,
          [classes.rootSuperadmin]: isSuperadmin,
          [classes.windowsScroll]: !window.navigator.platform.match(/^Mac/),
        }),
      }}
    >
      <div
        className={classes.toolbar}
        onMouseEnter={() => handleToggleDrawer(true)}
        onMouseLeave={() => handleToggleDrawer(false)}
      >
        {!isAgent || !isAgency || !isSuperadmin ? null : (
          <DynamicIcons
            style={{
              width: '43px',
              height: '67px',
              margin: '45px auto',
              cursor: 'pointer',
            }}
            icon={'2DCPIconColor'}
          />
        )}
        <NavList className={open ? 'open' : ''}>
          {navigation &&
            navigation.map((value, index) => (
              <NavItem key={'list' + index}>{_renderDefaultDrawer(value, index)}</NavItem>
            ))}
        </NavList>
      </div>
    </Drawer>
  );

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootOpen]: open,
        [classes.rootClose]: !open,
      })}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      ></AppBar>
      {isSuperadmin || isAgency || isAgent ? _renderAdminDrawer() : _renderDrawer()}
    </div>
  );
}
