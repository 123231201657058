import { STORAGE_ACTION_TYPES } from 'store/constants/storage.constants';

const INITIAL_STATE = {
  version: 0,
  popup_status: true,
  search_scroll_position: 0,
  property_search_ref: null,
  superadminManageAgencyRow: 30,
  superadminManageAgencyPage: 0,
  superadminManageAgencyButtons: [
    {
      id: 0,
      label: 'Paid',
      active: true,
    },
    {
      id: 1,
      label: 'Free',
      active: false,
    },
  ],
  superadminManageAgencyFilters: {
    subscription: 'ALL',
    docusign: 'ALL',
    payment: 'ALL',
    adminEmail: 'ALL',
    active: 'ALL',
  },
  superadminManageAgencySort: [
    {
      id: 0,
      key: 'registeredTradeName',
      sort: false,
      sortOrder: null,
    },
    {
      id: 1,
      key: 'address',
      sort: false,
      sortOrder: null,
    },
    {
      id: 2,
      key: 'adminEmail',
      sort: false,
      sortOrder: null,
    },
    {
      id: 3,
      key: 'subscription.billingDate',
      sort: false,
      sortOrder: null,
    },
  ],
  superadminManageAgencyField: '',
  from_innerpage: null,
};

const StorageReducers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case STORAGE_ACTION_TYPES['GENERAL_STORAGE']['SET_STORAGE_VERSION']:
      return {
        ...state,
        version: action.params,
      };
    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_POPUP_STATUS']:
      return {
        ...state,
        popup_status: action.params,
      };
    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_SCROLL_POSITION']:
      return {
        ...state,
        search_scroll_position: action.params,
      };

    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_PROPERTY_SEARCH_REF']:
      return {
        ...state,
        property_search_ref: action.params,
      };
    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_FROM_INNERPAGE']:
      return {
        ...state,
        from_innerpage: action.params,
      };

    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_SUPERADMIN_MANAGE_AGENCY_ROW_COUNT']:
      return {
        ...state,
        superadminManageAgencyRow: action.params,
      };

    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_SUPERADMIN_MANAGE_AGENCY_PAGE_COUNT']:
      return {
        ...state,
        superadminManageAgencyPage: action.params,
      };

    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_SUPERADMIN_MANAGE_AGENCY_BUTTONS']:
      return {
        ...state,
        superadminManageAgencyButtons: action.params,
      };

    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_SUPERADMIN_MANAGE_AGENCY_FILTERS']:
      return {
        ...state,
        superadminManageAgencyFilters: action.params,
      };

    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_SUPERADMIN_MANAGE_AGENCY_SORTS']:
      return {
        ...state,
        superadminManageAgencySort: action.params,
      };

    case STORAGE_ACTION_TYPES['GENERAL_SETTINGS']['SET_SUPERADMIN_MANAGE_AGENCY_FIELD']:
      return {
        ...state,
        superadminManageAgencyField: action.params,
      };

    default: {
      return state;
    }
  }
};

export default StorageReducers;
