import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import Image from 'material-ui-image'

import HomePageSearchHeading from './HomePageSearchHeading'
import HomePageSearchBar from './HomePageSearchBar'
import HomeFilters from './HomeFilters'

import HOMEPAGE_IMAGE from '../../../assets/images/homepage-section-1.png'

const useStyles = makeStyles((theme) => ({
  sectionOneContainer: {
    display: 'flex',
    padding: '50px 25px 50px 0px',
    color: 'white',
    width: '60%',
  },

  container: {
    maxWidth: '800px',

    [theme.breakpoints.down(1500)]: {
      maxWidth: '600px',
    },

    [theme.breakpoints.down(1300)]: {
      maxWidth: '550px',
    },
  },
}))

const SectionContainer = ({ children, style }) => {
  return (
    <Box paddingY="10px" style={style}>
      {children}
    </Box>
  )
}

const HomePageSearchSection = () => {
  const classes = useStyles()

  return (
    <Box
      paddingLeft="155px"
      width="100%"
      display="flex"
      style={{ backgroundColor: '#6DC5A1' }}
      color="white"
    >
      <Box className={classes.sectionOneContainer}>
        <Box className={classes.container} display="flex" width="100%">
          <Box>
            <SectionContainer>
              <HomePageSearchHeading />
            </SectionContainer>
            <SectionContainer>
              <HomePageSearchBar />
            </SectionContainer>
            <SectionContainer>
              <HomeFilters />
            </SectionContainer>
          </Box>
        </Box>
      </Box>
      <Box width="40%">
        <Image
          src={HOMEPAGE_IMAGE}
          aspectRatio={16 / 11}
          style={{ height: '780px' }}
          imageStyle={{ objectFit: 'cover', height: '100%' }}
        />
      </Box>
    </Box>
  )
}

export default HomePageSearchSection
