import { createStore, applyMiddleware } from "redux";
import { persistStore } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { APP_MODE } from "../utils/constants.utils";

import rootReducer from "./reducers/reducers";

const middleware =
  APP_MODE === "local" || APP_MODE === "staging" ? [thunk, logger] : [thunk];

export const store = createStore(rootReducer, applyMiddleware(...middleware));
export const persistor = persistStore(store);

export default { store, persistor };
