import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function PayPalIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M4.81605 19.3201L5.16605 17.1201H4.38605H0.706055L3.26605 0.860054C3.27288 0.809676 3.29776 0.763485 3.33605 0.730054C3.37625 0.69899 3.42527 0.681483 3.47605 0.680054H9.68605C11.7561 0.680054 13.1761 1.11005 13.9261 1.96005C14.2579 2.31658 14.4891 2.75486 14.5961 3.23005C14.7127 3.80756 14.7127 4.40255 14.5961 4.98005V5.48005L14.9461 5.68005C15.2125 5.81286 15.453 5.99236 15.6561 6.21005C15.9617 6.57905 16.159 7.02561 16.2261 7.50005C16.301 8.12027 16.2739 8.74856 16.1461 9.36005C16.0142 10.1028 15.7536 10.8169 15.3761 11.4701C15.071 11.9937 14.6586 12.4469 14.1661 12.8001C13.6703 13.1381 13.1184 13.3853 12.5361 13.5301C11.8823 13.695 11.2102 13.7756 10.5361 13.7701H10.0461C9.70263 13.7701 9.37005 13.8904 9.10605 14.1101C8.84068 14.3337 8.66637 14.6467 8.61605 14.9901V15.1901L8.00605 19.0701V19.2201C8.0133 19.2462 8.0133 19.2739 8.00605 19.3001H7.94605L4.81605 19.3201Z" fill="#253D80"/>
      <path d="M15.2758 5.07996L15.2158 5.44996C14.3958 9.64996 11.5858 11.11 8.00583 11.11H6.18583C5.74794 11.1094 5.37472 11.4275 5.30583 11.86L4.37583 17.78L4.10583 19.46C4.08557 19.5945 4.12458 19.7312 4.2128 19.8348C4.30103 19.9383 4.4298 19.9986 4.56583 20H7.80583C8.19001 19.9996 8.51673 19.7196 8.57583 19.34V19.18L9.18583 15.31V15.1C9.2404 14.7219 9.56387 14.441 9.94583 14.44H10.4758C13.6058 14.44 16.0658 13.17 16.7758 9.43996C17.16 8.1651 16.923 6.78383 16.1358 5.70996C15.886 5.45377 15.5954 5.24087 15.2758 5.07996V5.07996Z" fill="#189BD7"/>
      <path d="M14.416 4.73997L14.036 4.63997L13.616 4.55997C13.0864 4.48078 12.5514 4.44399 12.016 4.44997H7.13598C7.02199 4.44684 6.90888 4.47083 6.80598 4.51997C6.5741 4.62853 6.41246 4.84654 6.37598 5.09997L5.37598 11.67V11.86C5.44486 11.4275 5.81808 11.1094 6.25598 11.11H8.07598C11.656 11.11 14.466 9.64997 15.286 5.44997L15.346 5.07997C15.1306 4.96866 14.9065 4.87502 14.676 4.79997L14.416 4.73997Z" fill="#242E65"/>
      <path d="M6.37596 5.1C6.41245 4.84658 6.57408 4.62856 6.80596 4.52C6.90887 4.47086 7.02197 4.44687 7.13596 4.45H12.016C12.5514 4.44403 13.0864 4.48081 13.616 4.56L14.036 4.64L14.416 4.74L14.606 4.8C14.8365 4.87505 15.0606 4.96869 15.276 5.08C15.601 3.83063 15.3031 2.50118 14.476 1.51C13.476 0.450001 11.836 6.64913e-07 9.69596 6.64913e-07H3.47596C3.03807 -0.000530767 2.66485 0.317555 2.59596 0.750001L0.00596469 17.16C-0.0172219 17.3148 0.0278605 17.472 0.129538 17.5909C0.231216 17.7098 0.379489 17.7788 0.535965 17.78H4.37596L5.37596 11.67L6.37596 5.1Z" fill="#253D80"/>
    </SvgIcon>
  );
}

export function PayPalIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <PayPalIcon />
      <PayPalIcon color="primary" />
      <PayPalIcon color="secondary" />
      <PayPalIcon color="action" />
      <PayPalIcon color="disabled" />
      <PayPalIcon style={{ color: green[500] }} />
    </div>
  );
}
