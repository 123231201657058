import { POST, FETCH, PUT, DELETE } from 'utils/api'
import { propertyv2Actions } from 'store/actions/propertyv2.actions'
import { apiEndpoints } from 'utils/api/endpoints'

const urlSetter = (params, actionMain) => {
  let URL = apiEndpoints[actionMain]
  if (params.query && params.query !== '') {
    URL = apiEndpoints[actionMain] + params.query
  }
  return URL
}

export function requestPOSTAPIProperty(params, actionMain) {
  let URL = urlSetter(params, actionMain)
  return (dispatch) => {
    dispatch(propertyv2Actions(params, actionMain, 'REQUEST'))
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(propertyv2Actions(response, actionMain, 'SUCCESS'))
        return response
      })
      .catch((error) => {
        dispatch(propertyv2Actions(error.response, actionMain, 'FAILED'))
      })
  }
}

export function requestGETAPIProperty(params, actionMain) {
  let URL = urlSetter(params, actionMain)
  return (dispatch) => {
    dispatch(propertyv2Actions(params, actionMain, 'REQUEST'))
    FETCH(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(propertyv2Actions(response, actionMain, 'SUCCESS'))
        return response
      })
      .catch((error) => {
        dispatch(propertyv2Actions(error.response, actionMain, 'FAILED'))
      })
  }
}

export function requestPUTAPIProperty(params, actionMain) {
  let URL = urlSetter(params, actionMain)
  return (dispatch) => {
    dispatch(propertyv2Actions(params, actionMain, 'REQUEST'))
    PUT(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(propertyv2Actions(response, actionMain, 'SUCCESS'))
        return response
      })
      .catch((error) => {
        dispatch(propertyv2Actions(error.response, actionMain, 'FAILED'))
      })
  }
}

export function requestDELETEAPIProperty(params, actionMain) {
  let URL = urlSetter(params, actionMain)
  return (dispatch) => {
    dispatch(propertyv2Actions(params, actionMain, 'REQUEST'))
    DELETE(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(propertyv2Actions(response, actionMain, 'SUCCESS'))
        return response
      })
      .catch((error) => {
        dispatch(propertyv2Actions(error.response, actionMain, 'FAILED'))
      })
  }
}
