import React, { useState, useEffect } from 'react'

import Close from '@material-ui/icons/Close'
import BedIcon from '@material-ui/icons/Hotel'
import ParkingIcon from '@material-ui/icons/DriveEta'
import { PRICE_RANGE } from '../../../constants/constants'
import DynamicIcons from '../DynamicIcons'

import {
  withStyles,
  Chip,
  Modal,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Box,
} from '@material-ui/core'
import { formatUSD } from 'utils/formatter/priceFormatter'

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    "& .MuiChip-root": {
      borderRadius: 4
    },
    "& .MuiChip-label": {
      padding: '4px',
      fontSize: '12px',
    }
  },
  propertyInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '-8px',
  },
  customIcon: {
    color: '#000',
    width: 18,
    height: 18,
    marginRight: 3,
  },
  icon: {
    color: '#000',
    width: 18,
    height: 18,
    marginRight: 3,
  },
  caption: {
    width: '100%',
    fontSize: '16px',
  },
  addressContainer: {
    width: '100%',
    marginBottom: 8
  },
  break: {
    marginBottom: 12
  },
})

const StyledText = withStyles({
  root: {
    textAlign: 'left',
    color: '#4D4D4D',
    fontSize: '16px',
    lineHeight: '1.19',
  },
})(Typography)

const StyledTextCaption = withStyles({
  root: {
    textAlign: 'left',
    color: '#4D4D4D',
    fontSize: '16px',
    lineHeight: '1.19',
  },
})(Typography)

const SavedCardsInfo = (props) => {
  const { classes, path, value } = props

  function numberWithCommas(num) {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
      return 0
    }
  }

  function uppercase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  function priceData(value) {
    if (value) {
      switch (true) {
        case value.searchType === 'buy':
          let price = value?.filters?.propertyDetails.price
          return price

        case value.searchType === 'rent':
          let priceRent = value?.filters?.propertyDetails.priceRent
          return priceRent

        default:
          return 0
      }
    }
  }

  function propertyData(value, type) {
    if (value) {
      switch (true) {
        case type === 'bedrooms':
          const bed = `${value.bedroom?.value}${value.bedroom?.exact ? '' : '+'}`
          return bed !== '0+' ? bed : '0+'

        case type === 'bathrooms':
          const bath = `${value.bathroom?.value}${value.bathroom?.exact ? '' : '+'}`
          return bath !== '0+' ? bath : '0+'

        case type === 'carparking':
          const parking = `${value.carparking?.value}${
            value.carparking?.exact ? '' : '+'
          }`
          return parking !== '0+' ? parking : '0+'
        default:
          return '0+'
      }
    }
  }

  const priceString = () => {
    if (value.price_rent || value.price) {
      return path === 'rent' || value?.searchType === 'rent'
        ? `${formatUSD(value.price_rent?.min)} - ${formatUSD(value.price_rent?.max)}`
        : `${formatUSD(value.price?.min)} - ${formatUSD(value.price?.max)}`
    }

    return 0
  }

  // const rentalPriceString = (price) => {
  //   switch (true) {
  //     case price && price.min === 250 && price.max === 5000:
  //       return 'Any'

  //     case price && price.min <= 5000 && price.min !== 250 && price.max <= 5000:
  //       return (
  //         'Within $' + numberWithCommas(price.min) + '- $' + numberWithCommas(price.max)
  //       )
  //     case price && price.min === 250 && price.max < 5000:
  //       return 'Below $' + numberWithCommas(price.max)

  //     default:
  //       return 'Above $' + numberWithCommas(price?.max ?? 0)
  //   }
  // }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.addressContainer}>
          {value.suburb && value.suburb.length > 0 && (
            <div>
              <Tooltip
                title={
                  <React.Fragment>
                    { value.suburbs.map((item, i) => {
                      return (
                        <Typography
                          key={i}
                          color="inherit"
                        >
                          {item.suburb} {', '} {item.state}
                        </Typography>
                      );
                    })
                   }
                  </React.Fragment>
                }
              >
                <Grid container>
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <StyledTextCaption variant="caption">
                      {value.suburb[0].suburb +
                        ', ' +
                        value.suburb[0].state}
                    </StyledTextCaption>
                  </Grid>
                  {value.suburbs.length > 1 && (
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Chip label={value.suburbs.length -1 + ' more'} />
                    </Grid>
                  )}
                </Grid>
              </Tooltip>
              {value.suburbs.length <= 1 && (
                <div className={classes.break}></div>
              )}
              <StyledTextCaption variant="caption">
                {value.include_nearby_suburbs ? 'including surrounding suburbs' : ''}
              </StyledTextCaption>
            </div>
          )}

          {value.suburbs && value.suburbs.length > 0 && (
            <div>
              <Tooltip
                title={
                  <React.Fragment>
                    { value.suburbs.map((item, i) => {
                      return (
                        <Typography
                          key={i}
                          color="inherit"
                        >
                          {item.name}
                        </Typography>
                      );
                    })
                    }
                  </React.Fragment>
                }
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <StyledTextCaption variant="caption">
                      {value.suburbs[0].name}
                    </StyledTextCaption>
                  </Grid>
                  {value.suburbs.length > 1 && (
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Chip label={value.suburbs.length -1 + ' more'} />
                    </Grid>
                  )}
                </Grid>
              </Tooltip>
              {value.suburbs.length <= 1 && (
                <div className={classes.break}></div>
              )}
              <StyledTextCaption variant="caption">
                {value.include_nearby_suburbs ? 'including surrounding suburbs' : ''}
              </StyledTextCaption>
            </div>
          )}
        </div>
        <div>
          <div className={classes.caption}>
            <Box variant="body2">
              <Box display="flex" flexDirection="column">
                <Box fontWeight="normal">{`${uppercase(value.searchType)}`}</Box>
                <Box marginTop="5px">{priceString()}</Box>
              </Box>
            </Box>
          </div>
          <div className={classes.propertyInfo}>
            <Tooltip title="Bedrooms">
              <IconButton onClick={() => ''} disableRipple>
                <BedIcon className={classes.icon} />
                <Typography variant="caption" color="textSecondary">
                  {propertyData(value, 'bedrooms')}
                </Typography>
              </IconButton>
            </Tooltip>
            <Tooltip title="Bathrooms">
              <IconButton onClick={() => ''} disableRipple>
                <DynamicIcons className={classes.customIcon} icon="2DCBathIcon" />
                <Typography variant="caption" color="textSecondary">
                  {propertyData(value, 'bathrooms')}
                </Typography>
              </IconButton>
            </Tooltip>
            <Tooltip title="Parking">
              <IconButton onClick={() => ''} disableRipple>
                <ParkingIcon className={classes.icon} />
                <Typography variant="caption" color="textSecondary">
                  {propertyData(value, 'carparking')}
                </Typography>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  )
}

export default withStyles(styles)(SavedCardsInfo)
