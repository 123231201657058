import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import DynamicIcons from '../DynamicIcons'
import { fontFamily, headingFont } from '../../../utils/constants.utils'

import { Typography, withStyles, ButtonBase, IconButton } from '@material-ui/core'

const Container = styled.div`
  width: 100%;
  padding: 10px 0 0;
  margin: 0;
`

const ScrollContainer = styled.div`
  width: auto;
  padding: 10px 0;
`
const ButtonWrap = styled.div``
const Item = styled.div`
  margin-right: 11.5px;
  margin-bottom: 42.5px;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  text-align: center;

  &:nth-child(5n + 5) {
    // margin-right:0;
  }
  path {
    transition: fill 0.3s ease;
  }
  .button-wrap {
    transition: background-color 0.3s ease;
    border: 0.5px solid #000;
    border-radius: 4.75px;
  }
  .btn-icon-all {
    width: 62px;
    height: 62px;
    button {
      height: 100%;
      width: 100%;
      justify-content: center;
      p {
        font-family: ${headingFont};
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  > p {
    font-size: 12px;
    letter-spacing: -0.4px;
    margin-top: 5px;
    color: #6a6a6a;
    line-height: 1.16;
  }

  &.is-active {
    > p {
      color: #000;
    }
    .button-wrap {
      background-color: #35c0ca;
      color: #fff;
    }
    path {
      stroke: #fff !important;
    }
    .btn-icon-all {
      button {
        p {
          color: #fff;
        }
      }
    }
  }
`

const listDataDefault = [
  {
    id: '0',
    title: 'House',
    icon: '2DCHouseIcon',
    active: false,
  },
  {
    id: '1',
    title: 'Townhouse',
    icon: '2DCTownHouseIcon',
    active: false,
  },
  {
    id: '2',
    title: 'Apartment & Unit',
    icon: '2DCApartmentIcon',
    active: false,
  },
  {
    id: '3',
    title: 'Land',
    icon: '2DCLandIcon',
    active: false,
  },
  {
    id: '4',
    title: 'Rural',
    icon: '2DCRuralIcon',
    active: false,
  },
  {
    id: '5',
    title: 'Retirement',
    icon: '2DCRetirementVillageIcon',
    active: false,
  },
  {
    id: '6',
    title: 'Villa',
    icon: '2DCVillaIcon',
    active: false,
  },
]

const PropertyType = (props) => {
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [listData, setListData] = useState(props.propertyType)

  useEffect(() => {
    if (props.isClearFilter) {
      setListData(listDataDefault)
    }
  }, [props.isClearFilter])

  useEffect(() => {
    if (listData) {
      listData.map((item) => {
        if (item.active === false) {
          setIsSelectAll(false)
        }
      })
    }
  }, [listData])

  const handleChange = (obj, index) => {
    let data = listData.map((item) =>
      item.id === obj.id ? { ...item, active: !obj.active } : { ...item, active: false }
    )
    setListData(data)

    if (props.handlePropertyType) {
      props.handlePropertyType(data)
    }
  }

  const handleSelectAll = () => {
    let data = listData.map((item) => {
      return { ...item, active: !isSelectAll }
    })
    setListData(data)
    setIsSelectAll(!isSelectAll)
    if (props.handlePropertyType) {
      props.handlePropertyType(data)
    }
  }

  function renderListItem(value, index) {
    return (
      <Item
        key={index}
        className={value.title === props.selectedPropertyType.value ? 'is-active' : ''}
      >
        <ButtonWrap className="button-wrap" key={index}>
          <ButtonBase
            focusRipple
            key={index}
            className={'Icon-' + value.icon}
            onClick={(e) => {
              //console.log('select ' + value.title)
              handleChange(value, index)
            }}
          >
            <DynamicIcons className={'Icon-' + value.icon} icon={value.icon} />
          </ButtonBase>
        </ButtonWrap>
        <Typography>{value.title}</Typography>
      </Item>
    )
  }

  return (
    <Container>
      <ScrollContainer>
        {listData &&
          listData.map((v, k) => {
            return renderListItem(v, k)
          })}
      </ScrollContainer>
    </Container>
  )
}

export default PropertyType
