import React, {useState} from "react";
import styled, { css } from 'styled-components'


import DynamicIcons from "../DynamicIcons";

import { Typography, withStyles, FormControl, TextField } from '@material-ui/core';

const Container = styled.div`
  padding:10px 0 20px;
`

const AreaWrap = styled.div`
  position: absolute;
  right: 0px;
  top: 1px;
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: #11c0c9;
  color: #fff;
  font-size: 12px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

const StyledFormControl = withStyles({
  root:{
    width:'100%'
  }
})(FormControl);

const StyledTextField = withStyles({
  root:{
    '&.field-area input': {
      paddingRight:'45px'
    }
  }
})(TextField);

const CustomField = props => {

  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Container style={props.containerstyle}>
      <StyledFormControl>
        <StyledTextField
          variant="outlined"
          {...props}
          className={props.isarea ? 'field-area' : ''}
        />
        {
          props.isarea &&
          <AreaWrap>sqm</AreaWrap>
        }
      </StyledFormControl>
    </Container>
  );
};

export default CustomField;