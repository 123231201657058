import React, { useEffect, useState } from 'react'
import { Box, Slider, withStyles } from '@material-ui/core'

import SliderHeader from 'components/Web/Filter/SliderHeader'
import useFilters from 'utils/customHooks/useFilters'
import { priceRangeLabelFormatter } from 'utils/formatter/priceRangeLabelFormatter'

import { PRICE_RANGE_RENT } from 'constants/globalfilters'
import { PRICE_MARKS } from 'constants/globalfilters'
import { RENT_PRICE_MARKS } from 'constants/globalfilters'
import { PRICE_RANGE } from 'constants/globalfilters'

const StyledSlider = withStyles({
  root: {},
  rail: {
    height: '6px',
    borderRadius: '4px',
  },
  track: {
    height: '6px',
    borderRadius: '4px',
  },
  thumb: {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    border: '1px solid #35C0CA',
    marginTop: '-8px',
    marginLeft: '-8px',
  },
  mark: {
    height: '6px',
    backgroundColor: '#fff',
  },
})(Slider)

const scale = (value, priceMarks) => {
  if (value === undefined) {
    return 0
  }
  try {
    const modifier = 25
    const previousMarkIndex = Math.floor(value / modifier)
    const previousMark = priceMarks[previousMarkIndex]
    const remainder = value % modifier
    if (remainder === 0 && previousMark) {
      return previousMark.scaledValue
    }

    const nextMark = priceMarks[previousMarkIndex + 1]
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / modifier

    return remainder * increment + previousMark.scaledValue
  } catch (err) {
    return 0
  }
}

const handleScale = (valueArray, priceMarks) => {
  if (valueArray) {
    return [scale(valueArray[0], priceMarks), scale(valueArray[1], priceMarks)]
  }
}

const PriceSlider = ({ path }) => {
  const [getFilter, setFilter] = useFilters()
  const PRICE = getFilter('price')
  const RENT_PRICE = getFilter('price_rent')

  const priceRangeMinMax =
    path === 'rent'
      ? [RENT_PRICE_MARKS[0].value, RENT_PRICE_MARKS[RENT_PRICE_MARKS.length - 1].value]
      : [PRICE_MARKS[0].value, PRICE_MARKS[PRICE_MARKS.length - 1].value]

  // Put values on state so you will not change redux filters state on slider change
  const [scaledPrice, setScaledPrice] = useState(
    path === 'rent'
      ? handleScale(RENT_PRICE.scaled, RENT_PRICE_MARKS)
      : handleScale(PRICE.scaled, PRICE_MARKS)
  )
  const [priceSliderValue, setPriceSliderValue] = useState(
    path === 'rent' ? RENT_PRICE.scaled : PRICE.scaled
  )
  const [priceLabel, setPriceLabel] = useState()

  // if path changes get local state values from global filters state
  useEffect(() => {
    if (path) {
      if (path === 'rent') {
        setScaledPrice(handleScale(RENT_PRICE.scaled, RENT_PRICE_MARKS))
        setPriceSliderValue(RENT_PRICE.scaled)
      } else {
        setScaledPrice(handleScale(PRICE.scaled, PRICE_MARKS))
        setPriceSliderValue(PRICE.scaled)
      }
    }
  }, [path, RENT_PRICE, PRICE])

  useEffect(() => {
    if (priceSliderValue) {
      path === 'rent'
        ? setPriceLabel(
            priceRangeLabelFormatter(
              scaledPrice[0],
              scaledPrice[1],
              PRICE_RANGE_RENT[0],
              PRICE_RANGE_RENT[1],
              'pw'
            )
          )
        : setPriceLabel(
            priceRangeLabelFormatter(
              scaledPrice[0],
              scaledPrice[1],
              PRICE_RANGE[0],
              PRICE_RANGE[1]
            )
          )
    }
  }, [priceSliderValue, scaledPrice, path])

  return (
    <Box>
      <Box position="relative" width="100%" display="flex" alignItems="center">
        <SliderHeader label="Price" icon="MonetizationOn" />
        <Box
          style={{
            right: '50%',
            top: '50%',
            transform: 'translateY(-50%) translateX(50%)',
          }}
          position="absolute"
          fontSize="13px"
        >
          {priceLabel ? priceLabel : ''}
        </Box>
      </Box>
      <StyledSlider
        value={priceSliderValue}
        aria-labelledby="discrete-slider"
        onChange={(event, newValue) => {
          setPriceSliderValue(newValue)
          setScaledPrice(
            handleScale(newValue, path === 'rent' ? RENT_PRICE_MARKS : PRICE_MARKS)
          )
        }}
        onChangeCommitted={(event, newValue) => {
          setFilter({
            key: path === 'rent' ? 'price_rent' : 'price',
            value: {
              type: 'price',
              min: scaledPrice[0],
              max: scaledPrice[1],
              scaled: newValue,
            },
          })
        }}
        step={5}
        min={priceRangeMinMax[0]}
        max={priceRangeMinMax[1]}
      />
    </Box>
  )
}

export default PriceSlider
