import { POST, FETCH } from 'utils/api'
import { ippReferralActions } from 'store/actions/ippReferral.actions'
import { apiEndpoints } from 'utils/api/endpoints'

const urlSetter = (params, actionMain) => {
  let URL = apiEndpoints[actionMain]
  if (params.query && params.query !== '') {
    URL = apiEndpoints[actionMain] + params.query
  }
  return URL
}

export function requestPOSTAPIAgencyReferral(params, actionMain) {
  let URL = urlSetter(params, actionMain)
  return (dispatch) => {
    dispatch(ippReferralActions(params, actionMain, 'REQUEST'))
    POST(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(ippReferralActions(response, actionMain, 'SUCCESS'))
        return response
      })
      .catch((error) => {
        dispatch(ippReferralActions(error.response, actionMain, 'FAILED'))
      })
  }
}

export function requestGETAPIAgencyReferral(params, actionMain) {
  let URL = urlSetter(params, actionMain)

  return (dispatch) => {
    dispatch(ippReferralActions(params, actionMain, 'REQUEST'))
    FETCH(URL, params.data)
      .then((response) => {
        if (response.error) {
          throw response.error
        }
        dispatch(ippReferralActions(response, actionMain, 'SUCCESS'))
        return response
      })
      .catch((error) => {
        dispatch(ippReferralActions(error.response, actionMain, 'FAILED'))
      })
  }
}
