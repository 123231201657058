import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Paper, Button, Avatar, Typography, withStyles } from '@material-ui/core'
import clsx from 'clsx'
import { SuperStatusChip } from 'components/Web/Superadmin'
import EditIcon from '@material-ui/icons/Edit'
import { useDispatch } from 'react-redux'
import { sendAgentActivationEmail } from 'store/api'
import { useRouterChange } from 'utils/router.utils'

const styles = (theme) => ({
  root: {
    width: '100%',
    height: 260,
    backgroundColor: '#d2d2d2',
    borderRadius: 5,
  },
  content: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF',
    borderRadius: 5,
  },
  header: {
    width: '100%',
    height: 60,
    backgroundColor: '#07091b',
    padding: 5,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    width: '100%',
    height: 140,
    backgroundColor: '#FFF',
    padding: 5,
    paddingLeft: 20,
    paddingRight: 20,
    justifyContent: 'flex-start',
  },
  footer: {
    width: '100%',
    height: 60,
    backgroundColor: '#f5f5f5',
    padding: 5,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonEdit: {
    textTransform: 'capitalize',
    backgroundColor: '#FFF',
  },
  buttonDelete: {
    color: '#FFF',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    '&:hover': {
      background: 'transparent',
      textDecoration: 'underline',
    },
  },
  nameText: {
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'capitalize',
  },
  agentDetailsContent: {
    marginTop: 10,
  },
  infoText: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.525px',
    lineHeight: '20px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  avatarContainer: {
    position: 'relative',
    top: 30,
  },
  avatar: {
    border: '1px solid #FFF',
    width: 100,
    height: 100,
  },
  reInviteButton: {
    textTransform: 'none',
    backgroundColor: '#ffffff',
    border: 'solid #35C0CA 1px',
    borderRadius: '20px',
    padding: '5px 25px 5px 25px',

    '&:hover': {
      outline: 'solid #35C0CA 1px',
      backgroundColor: '#ffffff',
    },
  },
})

const AgentsCard = (props) => {
  const { classes, match, type, handleConfirmDialogOpen, agent } = props
  const [routerChange] = useRouterChange()

  const dispatch = useDispatch()

  const sendActivationEmail = () => {
    if (agent) {
      let request = {
        query: agent._id,
        data: {},
      }
      dispatch(sendAgentActivationEmail(request, 'AGENCY_SEND_AGENT_ACTIVATION_EMAIL'))
    }
  }

  const editAgent = (id) => {
    if (type === 'superadmin') {
      routerChange(
        '/superadmin/manage-agencies/' + match.params.id + '/agents/' + id + '/edit'
      )
    }
    if (type === 'agency') {
      routerChange('/agency/manage-agents/' + id + '/edit')
    }
  }

  const AgentImage = (img) => {
    if (img === 'http://lorempixel.com/200/200/people/9/') {
      return null
    } else {
      if (img) {
        const imgsplit = img.split('image/upload', 2)

        return imgsplit.length === 2
          ? imgsplit[0] + 'image/upload/f_webp,q_auto/c_fill,g_face,h_300,w_300' + imgsplit[1]
          : img
      }
      return img
    }
  }

  const _renderCardContent = () => {
    return (
      <div className={classes.content}>
        <div className={classes.header}>
          <Button
            className={classes.buttonDelete}
            variant="text"
            size="small"
            color="inherit"
            onClick={() =>
              handleConfirmDialogOpen({
                status: true,
                options: {
                  type: 'delete_agent',
                  title: 'Delete Agent',
                  message: `Are you sure you want to delete ${agent.name ?? agent.firstName + ' ' + agent.lastName
                    } from the agency?`,
                  data: agent,
                },
              })
            }
          >
            Delete agent
          </Button>
          <div className={classes.avatarContainer}>
            <Avatar
              className={classes.avatar}
              alt="agent-avatar"
              src={AgentImage(agent.image)}
            />
          </div>
        </div>
        <div className={classes.body}>
          <Typography className={classes.nameText} variant={'h6'}>
            {`${agent.firstName} ${agent.lastName}`}
          </Typography>
          <div className={classes.agentDetailsContent}>
            <Typography className={classes.infoText} variant={'body2'}>
              {agent.email}
            </Typography>
            <Typography className={classes.nameText} variant={'body2'}>
              {agent.telephone}
            </Typography>
            <Typography className={classes.infoText} variant={'body2'}>
              {agent.agencyName}
            </Typography>
          </div>
        </div>
        <div className={classes.footer}>
          <Button
            className={classes.buttonEdit}
            variant="contained"
            size="small"
            color="inherit"
            endIcon={<EditIcon />}
            onClick={() => editAgent(agent._id)}
          >
            Edit
          </Button>
          {agent?.agentState === 'ACTIVATED' ? (
            <SuperStatusChip type="active" />
          ) : (
            <Button
              onClick={() => sendActivationEmail()}
              className={classes.reInviteButton}
            >
              Re-send invitation
            </Button>
          )}
        </div>
      </div>
    )
  }

  return <Paper className={classes.root}>{_renderCardContent()}</Paper>
}

AgentsCard.propTypes = {
  classes: PropTypes.object,
  agentsData: PropTypes.array,
}

export default withStyles(styles)(AgentsCard)
