import React, { useState, useEffect } from 'react'
import { Box, Slider, withStyles } from '@material-ui/core'
import styled, { css } from 'styled-components'
import RangeSlider from '../../Web/Filter/RangeSlider'
import useFilters from 'utils/customHooks/useFilters'
import { DISTANCE_RANGE, DISTANCE_MARKS } from '../../../constants/globalfilters'
import SliderHeader from 'components/Web/Filter/SliderHeader'
import { distanceRangeLabelFormatter } from 'utils/formatter/distanceRangeLabelFormatter'

const StyledSlider = withStyles({
  root: {},
  rail: {
    height: '6px',
    borderRadius: '4px',
  },
  track: {
    height: '6px',
    borderRadius: '4px',
  },
  thumb: {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    backgroundColor: '#fff',
    border: '1px solid #35C0CA',
    marginTop: '-8px',
    marginLeft: '-8px',
  },
  mark: {
    height: '6px',
    backgroundColor: '#fff',
  },
})(Slider)

const marksDefault = [
  {
    value: 0,
    label: '0 km',
  },
  {
    value: 250,
    label: '250 km',
  },
  {
    value: 500,
    label: '500 km',
  },
]

const distanceDefault = [0, 500]

const scale = (value, distanceMarks) => {
  if (value === undefined) {
    return 0
  }
  try {
    const modifier = 25
    const previousMarkIndex = Math.floor(value / modifier)
    const previousMark = distanceMarks[previousMarkIndex]
    const remainder = value % modifier
    if (remainder === 0 && previousMark) {
      return previousMark.scaledValue
    }

    const nextMark = distanceMarks[previousMarkIndex + 1]
    const increment = (nextMark.scaledValue - previousMark.scaledValue) / modifier

    return remainder * increment + previousMark.scaledValue
  } catch (err) {
    return 0
  }
}

const handleScale = (valueArray, distanceMarks) => {
  if (valueArray) {
    return [scale(valueArray[0], distanceMarks), scale(valueArray[1], distanceMarks)]
  }
}

const DistanceContainer = (props) => {
  const [getFilter, setFilter] = useFilters()
  //const listData = getFilter('radius_distance')
  const DISTANCE = getFilter('radius_distance')

  const {
    distanceSliderValue,
    scaledDistanceValue,
    distanceRangeLabel,
    distanceRangeMinMax,
  } = {
    distanceSliderValue: DISTANCE.scaled,
    scaledDistanceValue: handleScale(DISTANCE.scaled, DISTANCE_MARKS),
    distanceRangeLabel: distanceRangeLabelFormatter(
      DISTANCE.min,
      DISTANCE.max,
      DISTANCE_RANGE[0],
      DISTANCE_RANGE[1]
    ),
    distanceRangeMinMax: [
      DISTANCE_MARKS[0].value,
      DISTANCE_MARKS[DISTANCE_MARKS.length - 1].value,
    ],
  }

  return (
    <Box>
      <Box position="relative" width="100%" display="flex" alignItems="center">
        <SliderHeader label="Within kilometers of capital city" icon="Apartment" />
        <Box
          style={{
            right: '10%',
            top: '50%',
            transform: 'translateY(-50%) translateX(50%)',
          }}
          position="absolute"
          fontSize="13px"
        >
          {distanceRangeLabel}
        </Box>
      </Box>
      <StyledSlider
        value={distanceSliderValue}
        aria-labelledby="discrete-slider"
        onChange={(event, newValue) => {
          setFilter({
            key: 'radius_distance',
            value: {
              options: DISTANCE.options,
              min: scaledDistanceValue[0],
              max: scaledDistanceValue[1],
              scaled: newValue,
            },
          })
        }}
        onChangeCommitted={(event, newValue) => {
          setFilter({
            key: 'radius_distance',
            value: {
              options: DISTANCE.options,
              min: scaledDistanceValue[0],
              max: scaledDistanceValue[1],
              scaled: newValue,
            },
          })
        }}
        step={5}
        min={distanceRangeMinMax[0]}
        max={distanceRangeMinMax[1]}
      />
    </Box>
  )
}

export default DistanceContainer
