import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, withStyles } from '@material-ui/core';
import { AdminsCard } from 'components/Web/Superadmin'

const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        // overflowY: "scroll",
        // overflowX: "hidden",
        marginRight: 20,
        marginTop: 10,
        marginBottom: 20,
    },
    cardContainer: {
        padding: 20,
    },
    infoText: {
        width: '100%',
        textAlign: 'center',
        marginTop: '10vh',
    },
})

const AdminsLists = (props) => {
    const { classes, adminData, match, type, handleConfirmDialogOpen } =
        props

    return (
        <div className={classes.root}>
            {adminData?.length > 0 ? (
                <Grid
                    container
                    spacing={2}
                    justifyContent={'flex-start'}
                    alignContent={'flex-start'}
                    alignItems={'center'}
                    className={classes.cardContainer}
                >
                    {adminData &&
                        adminData.map((admin, index) => {
                            return (
                                <Grid key={index} item xs={6} md={6} lg={4} xl={3}>
                                    <AdminsCard
                                        type={type}

                                        match={match}
                                        handleConfirmDialogOpen={handleConfirmDialogOpen}
                                        admin={admin}
                                    />
                                </Grid>
                            )
                        })}
                </Grid>
            ) : (
                <Typography className={classes.infoText} variant={'body2'}>
                    {'No admins listed'}
                </Typography>
            )}
        </div>
    )
}

AdminsLists.propTypes = {
    classes: PropTypes.object,
    agentsData: PropTypes.array,
    pagination: PropTypes.object,
    match: PropTypes.object,
    type: PropTypes.string,
}

export default withStyles(styles)(AdminsLists)
