import * as React from 'react';

import { AppleIcon } from '../../../assets/icons/AppleIcon';
import { BathIcon } from '../../../assets/icons/BathIcon';
import { DropPinIcon } from '../../../assets/icons/DropPinIcon';
import { DropPinIconPPT } from '../../../assets/icons/DropPinIconPPT';
import { FacebookIcon } from '../../../assets/icons/FacebookIcon';
import { FilterIcon } from '../../../assets/icons/FilterIcon';
import { GoogleIcon } from '../../../assets/icons/GoogleIcon';
import { HomeIcon } from '../../../assets/icons/HomeIcon';
import { ListIcon } from '../../../assets/icons/ListIcon';
import { Logo } from '../../../assets/icons/Logo';
import { PropSearchIcon } from '../../../assets/icons/PropSearchIcon';
import { SavedSearchIcon } from '../../../assets/icons/SavedSearchIcon';
import { SearchIcon } from '../../../assets/icons/SearchIcon';
import { ErrorIcon } from '../../../assets/icons/ErrorIcon';
import { PIcon } from '../../../assets/icons/PIcon';
import { PIconColor } from '../../../assets/icons/PIconColor';
import { AcreageIcon } from '../../../assets/icons/AcreageIcon';
import { VillaIcon } from '../../../assets/icons/VillaIcon';
import { TownHouseIcon } from '../../../assets/icons/TownHouseIcon';
import { RuralIcon } from '../../../assets/icons/RuralIcon';
import { RetirementVillageIcon } from '../../../assets/icons/RetirementVillageIcon';
import { ApartmentIcon } from '../../../assets/icons/ApartmentIcon';
import { BlockUnitIcon } from '../../../assets/icons/BlockUnitIcon';
import { HouseIcon } from '../../../assets/icons/HouseIcon';
import { LandIcon } from '../../../assets/icons/LandIcon';
import { SaleDropInIcon } from '../../../assets/icons/SaleDropInIcon';
import { PropertyMapIcon } from '../../../assets/icons/PropertyMapIcon';
import { MapCircleIcon } from '../../../assets/icons/MapCircleIcon';
import { DownloadIcon } from '../../../assets/icons/DownloadIcon';
import { HouseLineIcon } from '../../../assets/icons/HouseLineIcon';
import { HandCrown } from '../../../assets/icons/HandCrown';
import { PayPalIcon } from '../../../assets/icons/PayPalIcon';
import { HousePin } from '../../../assets/icons/HousePin';
import { SortUnFoldMore } from '../../../assets/icons/SortUnFoldMore';

export default function CustomIcon(props) {
  switch (props.icon) {
    case '2DCAppleIcon':
      return <AppleIcon {...props} />;
    case '2DCBathIcon':
      return <BathIcon {...props} />;
    case '2DCDropPinIcon':
      return <DropPinIcon {...props} />;
    case '2DCDropPinIconPPT':
      return <DropPinIconPPT {...props} />;
    case '2DCFacebookIcon':
      return <FacebookIcon {...props} />;
    case '2DCFilterIcon':
      return <FilterIcon {...props} />;
    case '2DCGoogleIcon':
      return <GoogleIcon {...props} />;
    case '2DCHomeIcon':
      return <HomeIcon {...props} />;
    case '2DCListIcon':
      return <ListIcon {...props} />;
    case '2DCLogo':
      return <Logo {...props} />;
    case '2DCPropSearchIcon':
      return <PropSearchIcon {...props} />;
    case '2DCSavedSearchIcon':
      return <SavedSearchIcon {...props} />;
    case '2DCSearchIcon':
      return <SearchIcon {...props} />;
    case '2DCErrorIcon':
      return <ErrorIcon {...props} />;
    case '2DCPIcon':
      return <PIcon {...props} />;
    case '2DCPIconColor':
      return <PIconColor {...props} />;
    case '2DCAcreageIcon':
      return <AcreageIcon {...props} />;
    case '2DCVillaIcon':
      return <VillaIcon {...props} />;
    case '2DCTownHouseIcon':
      return <TownHouseIcon {...props} />;
    case '2DCRuralIcon':
      return <RuralIcon {...props} />;
    case '2DCRetirementVillageIcon':
      return <RetirementVillageIcon {...props} />;
    case '2DCApartmentIcon':
      return <ApartmentIcon {...props} />;
    case '2DCBlockUnitIcon':
      return <BlockUnitIcon {...props} />;
    case '2DCHouseIcon':
      return <HouseIcon {...props} />;
    case '2DCHousePin':
      return <HousePin {...props} />;
    case '2DCLandIcon':
      return <LandIcon {...props} />;
    case '2DCSaleDropInIcon':
      return <SaleDropInIcon {...props} />;
    case '2DCPropertyMapIcon':
      return <PropertyMapIcon {...props} />;
    case '2DCMapCircleIcon':
      return <MapCircleIcon {...props} />;
    case '2DCDownloadIcon':
      return <DownloadIcon {...props} />;
    case '2DCHouseLineIcon':
      return <HouseLineIcon {...props} />;
    case '2DCHandCrown':
      return <HandCrown {...props} />;
    case '2DCPayPalIcon':
      return <PayPalIcon {...props} />;
    case 'SortUnFoldMore':
      return <SortUnFoldMore {...props} />;
    default:
      return;
  }
}
