import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  useMediaQuery,
  Avatar,
  Container,
  withStyles,
  useTheme,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SuperStatusChip, AvatarDropzone } from 'components/Web/Superadmin';
import AlertNotification from 'components/Web/Form/AlertNotification';
import InputMask from 'react-input-mask';

import { agencyv2Actions } from 'store/actions';
import { requestPUTAPIAgency } from 'store/api';

import { getStatus, getAvatarUpload } from 'store/selectors/agencyv2.selectors';
import { requestGETAPISuperadmin } from 'store/api';
import { getAuthentication } from 'store/selectors/authentication.selectors';
import { getAgency } from 'store/selectors/superadmin.selectors';

const FormInput = (props) => (
  <div style={{ marginTop: 10 }}>
    <Typography variant={'caption'} style={{ color: 'gray' }}>
      {props.label}
    </Typography>
    <TextField
      style={{ backgroundColor: '#FFF' }}
      {...props}
      label={null}
      fullWidth
      id="outlined-basic"
      variant="outlined"
    />
  </div>
);

const styles = (theme) => ({
  root: {
    marginTop: 20,
    marginRight: 20,
    paddingBottom: 60,
    width: '89vw',
    '& p.MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-24px',
    },
  },
  title: {
    fontWeight: 300,
    fontSize: '21px',
    letterSpacing: '-0.525px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left',
  },
  subtitle: {
    fontWeight: 300,
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'left',
  },
  formContainer: {
    width: '100%',
    height: 'auto',
    marginRight: 20,
    padding: '2%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 60,
    marginRight: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'capitalize',
    borderRadius: 20,
    marginLeft: 10,
    minWidth: 120,
  },
  icon: {
    fontSize: 25,
  },
  fieldGroup: {
    marginTop: 20,
    borderTop: '1px solid #d3d3d3',
    paddingTop: 20,
    paddingBottom: 20,
  },
  biofield: {
    display: 'flex',
    flexDirection: 'column',
  },
  biocounter: {
    marginTop: 10,
    alignSelf: 'flex-end',
  },
  avatarfield: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatar: {
    border: '1px solid #FFF',
    width: 150,
    height: 150,
  },
  saveButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '50px',
  },
});

const validationSchema = yup.object({
  email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
});

const FormFields = {
  firstName: { name: 'firstName', label: 'First Name' },
  lastName: { name: 'lastName', label: 'Last Name' },
  email: { name: 'email', label: 'Email Address' },
  telephone: { name: 'telephone', label: 'Phone Number' },
  image: { name: 'image', label: 'Profile Image' },
  profileText: { name: 'profileText', label: 'Bio' },
  title: { name: 'title', label: 'Job Title' },
};

const AgentsForm = (props) => {
  const {
    classes,

    goBack,
    match,
    agent,
    handleSubmit,
    submitStatus,
    handleUploadAvatar,
    uploadStatus,
  } = props;

  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      // row 1
      firstName: agent?.firstName ?? '',
      lastName: agent?.lastName ?? '',
      email: agent?.email ?? '',
      telephone: agent?.telephone ?? '',
      image: agent?.image ?? '',
      profileText: agent?.profileText ?? '',
      title: agent?.title ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {},
  });

  const confirmSubmit = () => {
    let params;
    if (uploadedAvatar) {
      params = { ...formik.values, image: uploadedAvatar };
    } else {
      params = formik.values;
    }
    handleSubmit(params);
  };

  const dispatch = useDispatch();
  const [preview, setPreview] = useState([]);
  const STATUS = useSelector(getStatus);
  const AGENCY = useSelector(getAgency);
  const AUTH = useSelector(getAuthentication);
  const UPLOAD_AVATAR = useSelector(getAvatarUpload);

  const defaultNotification = { status: false, options: null };
  const [isNotification, setIsNotification] = useState(defaultNotification);
  const [uploadedAvatar, setUploadedAvatar] = useState(null);

  const handleNotificationClose = () => {
    setIsNotification(defaultNotification);
  };

  const handleGetAgencyData = (data) => {
    let request = {
      query: '/' + match?.params?.id ? match?.params?.id : AUTH?.token?.agency?._id,
      data: {},
    };
    dispatch(requestGETAPISuperadmin(request, 'SUPERADMIN_GET_AGENCY_DATA'));
  };

  useEffect(() => {
    if (!AGENCY) {
      handleGetAgencyData();
    }
  }, []);

  useEffect(() => {
    if (uploadedAvatar) {
      formik.values[FormFields['image'].name] = uploadedAvatar;
    }

    return () => {
      if (UPLOAD_ACTIONS.includes(STATUS)) {
        dispatch(agencyv2Actions(null, 'AGENCY_UPLOAD_IMAGE', 'CLEAR'));
      }
    };
  }, [uploadedAvatar]);

  const UPLOAD_ACTIONS = [
    'agency/AGENCY_AGENT_PHOTO_SUCCESS',
    'agency/AGENCY_AGENT_PHOTO_REQUEST',
    'agency/AGENCY_AGENT_PHOTO_FAILED',
  ];

  useEffect(() => {
    if (UPLOAD_AVATAR && UPLOAD_AVATAR.status === 'agency/AGENCY_AGENT_PHOTO_SUCCESS') {
      const options = {
        status: true,
        options: {
          severity: 'success',
          message: 'Avatar uploaded successfully',
          autoHideDuration: 3000,
          onClose: handleClearNotices,
        },
      };
      setIsNotification(options);

      if (UPLOAD_AVATAR?.response?.uploadedFiles) {
        setUploadedAvatar(UPLOAD_AVATAR.response.uploadedFiles[0]);
      }
    } else if (UPLOAD_AVATAR && UPLOAD_AVATAR.status === 'agency/AGENCY_AGENT_PHOTO_REQUEST') {
      const options = {
        status: true,
        options: {
          severity: 'info',
          message: 'Uploading avatar',
          autoHideDuration: 3000,
        },
      };
      setIsNotification(options);
    } else if (UPLOAD_AVATAR && UPLOAD_AVATAR.status === 'agency/AGENCY_AGENT_PHOTO_FAILED') {
      const options = {
        status: true,
        options: {
          severity: 'error',
          message: 'Error uploading avatar.',
          autoHideDuration: 4000,
        },
      };
      setIsNotification(options);
    }
  }, [UPLOAD_AVATAR]);

  const handleConfirmAvatarUpload = (data) => {
    if (AGENCY && data) {
      var uploadData = new FormData();
      var files = data;
      for (const file of files) {
        uploadData.append('photo', file, file.name);
      }
      uploadData.append('reaAgencyId', AGENCY?.data?.entity?.reaAgencyId);
      requestAPIUploadAvatar(uploadData);
    }
  };

  const requestAPIUploadAvatar = (data) => {
    if (AGENCY) {
      const params = {
        query: formik.values[FormFields['email'].name],
        data: data,
      };

      dispatch(requestPUTAPIAgency(params, 'AGENCY_AGENT_PHOTO'));
    }
  };

  /**
   * ? Redux Localized Actions
   * */
  const handleClearStatus = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_STATUS'));
  };

  const handleClearError = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'CLEAR_ERROR'));
  };

  const handleClearData = () => {
    dispatch(agencyv2Actions(null, 'GENERAL_AGENCY', 'RESET_STATES'));
  };

  const handleClearNotices = () => {
    setIsNotification(defaultNotification);
  };

  const _renderHeader = () => {
    return (
      <div className={classes.header}>
        <div className={classes.buttonContainer}>
          <Typography className={classes.title} variant={'h1'}>
            {props.heading ? props.heading : 'Edit Agent'}
          </Typography>
          {!props.hideCancel && (
            <Button
              className={classes.button}
              disableElevation
              variant="outlined"
              color="default"
              onClick={() => goBack()}
            >
              Cancel
            </Button>
          )}
        </div>
        <div className={classes.buttonContainer}>
          {agent?.agentState === 'PENDING_ACTIVATION' && (
            <Button className={classes.button} disableElevation variant="outlined" color="primary">
              Re-send Invitation
            </Button>
          )}
          {/*           {!submitStatus && (
            <Button
              className={classes.button}
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => confirmSubmit()}
              disabled={
                (formik && !formik.dirty && !uploadedAvatar) ||
                !formik.isValid ||
                submitStatus
              }
            >
              Save
            </Button>
          )} */}
          {submitStatus && (
            <Button
              className={classes.button}
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => goBack()}
            >
              Go Back
            </Button>
          )}
        </div>
      </div>
    );
  };

  const _renderInfo = () => {
    return (
      <div className={classes.formContainer}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container alignItems={'center'} justifyContent={'space-between'}>
            <Grid item>
              <Typography className={classes.subtitle} variant={'body2'}>
                {'Complete agent data below'}
              </Typography>
            </Grid>
            <Grid item>
              {agent?.agentState && (
                <SuperStatusChip
                  type={agent?.agentState === 'ACTIVATED' ? 'active' : agent?.agentState.toLowerCase() ?? ''}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            className={classes.fieldGroup}
            container
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classes.title} variant={'h1'}>
                Job Title
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <FormInput
                id={FormFields['title'].name}
                name={FormFields['title'].name}
                label={FormFields['title'].label}
                variant="outlined"
                value={formik.values[FormFields['title'].name]}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.fieldGroup}
            container
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classes.title} variant={'h1'}>
                {'Name'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormInput
                id={FormFields['firstName'].name}
                name={FormFields['firstName'].name}
                label={FormFields['firstName'].label}
                variant="outlined"
                value={formik.values[FormFields['firstName'].name]}
                onChange={formik.handleChange}
                error={formik.errors && Boolean(formik.errors['firstName'])}
                helperText={formik.errors && formik.errors['firstName']}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <FormInput
                id={FormFields['lastName'].name}
                name={FormFields['lastName'].name}
                label={FormFields['lastName'].label}
                variant="outlined"
                value={formik.values[FormFields['lastName'].name]}
                onChange={formik.handleChange}
                error={formik.errors && Boolean(formik.errors['lastName'])}
                helperText={formik.errors && formik.errors['lastName']}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.fieldGroup}
            container
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classes.title} variant={'h1'}>
                {'Email Address'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <FormInput
                id={FormFields['email'].name}
                name={FormFields['email'].name}
                label={FormFields['email'].label}
                variant="outlined"
                value={formik.values[FormFields['email'].name]}
                onChange={formik.handleChange}
                error={formik.errors && Boolean(formik.errors['email'])}
                helperText={formik.errors && formik.errors['email']}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.fieldGroup}
            container
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classes.title} variant={'h1'}>
                {'Phone Number'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div style={{ marginTop: 10 }}>
                <Typography variant={'caption'} style={{ color: 'gray' }}>
                  {FormFields['telephone'].label}
                </Typography>
                <InputMask
                  mask="9999999999"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values[FormFields['telephone'].name]}
                  maskPlaceholder={''}
                >
                  <TextField
                    type="text"
                    fullWidth
                    variant="outlined"
                    id={FormFields['telephone'].name}
                    name={FormFields['telephone'].name}
                    // label={FormFields["telephone"].label}
                    error={formik.errors && Boolean(formik.errors['telephone'])}
                    helperText={formik.errors && formik.errors['telephone']}
                  />
                </InputMask>
              </div>
            </Grid>
          </Grid>
          <Grid
            className={classes.fieldGroup}
            container
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classes.title} variant={'h1'}>
                {'Profile Image'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <div className={classes.avatarfield}>
                <Avatar
                  className={classes.avatar}
                  alt="agent-avatar"
                  src={
                    preview[0]
                      ? preview[0].preview
                      : formik.values[FormFields['image'].name]
                      ? formik.values[FormFields['image'].name]
                      : null
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <AvatarDropzone setPreview={setPreview} confirmCallback={handleConfirmAvatarUpload} />
            </Grid>
          </Grid>
          <Grid
            className={classes.fieldGroup}
            container
            spacing={2}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography className={classes.title} variant={'h1'}>
                {'Bio'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <div className={classes.biofield}>
                <FormInput
                  id={FormFields['profileText'].name}
                  name={FormFields['profileText'].name}
                  label={FormFields['profileText'].label}
                  variant="outlined"
                  value={formik.values[FormFields['profileText'].name]}
                  onChange={formik.handleChange}
                  multiline
                  rows={4}
                />
                <Typography className={classes.biocounter} variant={'caption'}>
                  {1500 -
                    (formik.values[FormFields['profileText'].name]
                      ? parseInt(formik.values[FormFields['profileText'].name].length)
                      : 0) +
                    ' characters left'}
                </Typography>
              </div>
            </Grid>
            <Grid style={{ margin: '0 auto', display: 'block' }}>
              <div className={classes.saveButton}>
                <Button
                  className={classes.button}
                  disableElevation
                  variant="contained"
                  color="primary"
                  onClick={() => confirmSubmit()}
                  /*                 disabled={
                (formik && !formik.dirty && !uploadedAvatar) ||
                !formik.isValid ||
                submitStatus
              } */
                >
                  Save and Close
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
        {isNotification.status && (
          <AlertNotification
            status={isNotification.status}
            options={isNotification.options}
            closeNotification={handleNotificationClose}
          />
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {_renderHeader()}
      <Container>{_renderInfo()}</Container>
    </div>
  );
};

export default withStyles(styles)(AgentsForm);
