import Home from '../../containers/Web/Home';
import NotificationList from '../../containers/Web/NotificationList';
import AgentActivate from 'containers/Web/Agent/AgentActivate';
import AgencyStandalonePayment from 'containers/Web/Agency/AgencyStandalonePayment';
import AgentForgot from 'containers/Web/Agent/AgentForgot';
import SuperadminForgot from 'containers/Web/Superadmin/SuperadminForgot';
import AdvertisingAgreement from '../../containers/Web/AdvertisingAgreement';
import ConfirmProperty from '../../containers/Web/ConfirmProperty';

let routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/advertising-agreement',
    component: AdvertisingAgreement,
  },
  {
    path: '/notification-list',
    component: NotificationList,
  },
  {
    path: '/agent/activate/:token',
    component: AgentActivate,
  },
  {
    path: '/agency/payment-details/:token',
    component: AgencyStandalonePayment,
  },
  {
    path: '/agency/payment-details',
    component: AgencyStandalonePayment,
  },
  {
    path: '/agent/forgot-password',
    component: AgentForgot,
  },
  {
    path: '/superadmin/forgot-password',
    component: SuperadminForgot,
  },
  {
    path: '/confirm-property/:target/:id',
    component: ConfirmProperty,
  },
];

export default routes;
