import React from 'react';

import AgentBannerIMG from '../../../assets/images/agentbanner.png'
import LogoIMG from '../../../assets/images/logo_placeholder.png'

import { withStyles, Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    position: 'relative',
    backgroundColor: '#FFF'
  },
  image: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
  },
  imageDark: {
    width: '100%',
    height: '35vh',
    objectFit: 'cover',
    zIndex: 0,
    background: 'linear-gradient(0.39deg, rgba(20, 35, 50, 0.93) -0.51%, rgba(20, 35, 50, 0.87) -0.51%, rgba(45, 73, 97, 0.78) 99.84%)'
  },
  logo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 5,
    width: '40%',
  }
});

const StyledText = withStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: '#fff',
    fontSize: '30px',
    lineHeight: '1.19',
    zIndex: 5,
  },
})(Typography);

const AgentBanner = props => {
  const {
    classes,
    category
  } = props;
  return (
    <div className={classes.root}>
      {
        category === 'agent' &&
        <>
          <img
            className={classes.logo}
            src={LogoIMG} />
          <img
            className={classes.image}
            src={AgentBannerIMG} />
        </>
      }
      {
        category === 'agency' &&
        <>
          <StyledText variant="h2">
            Find your perfect agent or agency
          </StyledText>
          <img
            className={classes.imageDark}
            src={AgentBannerIMG} />
        </>
      }
    </div>
  );
}

export default withStyles(styles)(AgentBanner);