import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > svg': {
        margin: theme.spacing(2),
      },
    },
  }),
);

export function VillaIcon(props: SvgIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      {/* <rect
        width="59.5"
        height="59.5"
        x="0.25"
        y="0.25"
        stroke="#000"
        strokeWidth="0.5"
        rx="4.75"
      ></rect> */}
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.856 41.252a.202.202 0 01-.202-.2c0-.112.087-.2.197-.2h.005a.2.2 0 01.2.2.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0"
        width="12"
        height="8"
        x="12"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M12.316 10h11.46v7.968h-11.46V10z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.517 17.968a.2.2 0 01-.112-.365l11.058-7.568a.199.199 0 01.277.052.199.199 0 01-.052.278l-11.06 7.568a.197.197 0 01-.111.035"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask1"
        width="12"
        height="8"
        x="23"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M23.377 10h11.46v7.968h-11.46V10z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M34.638 17.968a.197.197 0 01-.112-.035l-11.062-7.568a.199.199 0 01-.051-.278.198.198 0 01.276-.052l11.06 7.568a.2.2 0 01-.111.365"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M12.517 20.296a.2.2 0 01-.112-.365l11.058-7.568a.2.2 0 01.277.051.199.199 0 01-.052.279l-11.06 7.568a.196.196 0 01-.111.035"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M34.638 20.296a.196.196 0 01-.112-.035l-11.062-7.568a.2.2 0 01.225-.33l11.06 7.568a.2.2 0 01-.111.365"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2"
        width="2"
        height="4"
        x="12"
        y="17"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M12.317 17.567h1.033v2.729h-1.033v-2.729z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M12.517 20.296a.2.2 0 01-.2-.2v-2.329c0-.11.09-.2.2-.2.11 0 .2.09.2.2v2.329a.2.2 0 01-.2.2"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M34.638 20.296a.2.2 0 01-.2-.2v-2.329c0-.11.09-.2.2-.2.11 0 .2.09.2.2v2.329a.2.2 0 01-.2.2M27.07 21.46a.2.2 0 01-.2-.2 3.297 3.297 0 00-3.294-3.293 3.296 3.296 0 00-3.291 3.294.2.2 0 01-.402 0 3.698 3.698 0 013.693-3.694 3.697 3.697 0 013.694 3.694.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M20.083 26.117a.2.2 0 01-.2-.2V21.26c0-.11.09-.2.2-.2.11 0 .2.09.2.2v4.657a.2.2 0 01-.2.2M28.232 30.192H18.92a.2.2 0 01-.2-.2.2.2 0 01.2-.2h9.313a.2.2 0 01.2.2.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M28.232 26.117H18.92a.2.2 0 110-.4h9.313a.2.2 0 110 .4"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M27.07 26.117a.2.2 0 01-.2-.2V21.26c0-.11.09-.2.2-.2.11 0 .2.09.2.2v4.657a.2.2 0 01-.2.2M18.92 30.192a.2.2 0 01-.2-.2v-4.075c0-.111.09-.201.2-.201.11 0 .2.09.2.201v4.075a.2.2 0 01-.2.2M28.234 30.192a.2.2 0 01-.2-.2v-4.075c0-.111.09-.201.2-.201.11 0 .2.09.2.201v4.075a.2.2 0 01-.2.2M21.25 30.192a.2.2 0 01-.2-.2v-4.075c0-.111.09-.201.2-.201.11 0 .2.09.2.201v4.075a.2.2 0 01-.2.2M23.576 30.192a.2.2 0 01-.2-.2v-4.075c0-.111.09-.201.2-.201.11 0 .2.09.2.201v4.075a.2.2 0 01-.2.2M25.906 30.192a.2.2 0 01-.2-.2v-4.075c0-.111.09-.201.2-.201.11 0 .2.09.2.201v4.075a.2.2 0 01-.2.2M13.681 46.491a.2.2 0 01-.2-.2V19.3c0-.11.09-.2.2-.2.11 0 .2.09.2.2V46.29a.2.2 0 01-.2.2M33.472 46.491a.2.2 0 01-.2-.2V19.3c0-.11.09-.2.2-.2.11 0 .2.09.2.2V46.29a.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask3"
        width="36"
        height="2"
        x="13"
        y="45"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M13.48 45.458h35.327v1.034H13.48v-1.034z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g>
        <path
          fill="#000"
          fillRule="evenodd"
          d="M48.607 46.491H13.681a.2.2 0 110-.4h34.926a.2.2 0 110 .4"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M48.607 33.103H13.681a.2.2 0 01-.2-.2.2.2 0 01.2-.2h34.926a.2.2 0 01.2.2.2.2 0 01-.2.2M18.92 36.013h-5.24a.2.2 0 110-.4h5.24a.2.2 0 110 .4"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M18.92 46.492a.2.2 0 01-.2-.2V35.813a.2.2 0 01.2-.2.2.2 0 01.2.2v10.479a.2.2 0 01-.2.2"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask4"
        width="37"
        height="37"
        x="12"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M12.316 46.492h36.492V10H12.316v36.492z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g fill="#000" fillRule="evenodd" clipRule="evenodd">
        <path d="M22.03 42.015h8.332v-6.003h-8.331v6.003zm8.532.402h-8.731a.2.2 0 01-.2-.2v-6.404a.2.2 0 01.2-.2h8.732c.111 0 .2.089.2.2v6.404a.2.2 0 01-.2.2zM48.607 46.492a.2.2 0 01-.2-.2V32.903a.2.2 0 11.4 0v13.389a.2.2 0 01-.2.2"></path>
        <path d="M33.672 32.704h14.735v-1.347H33.672v1.347zm14.934.4H33.471a.2.2 0 01-.2-.2v-1.747a.2.2 0 01.2-.2h15.136a.2.2 0 01.201.2v1.747c0 .11-.09.2-.202.2zM35.802 46.491a.2.2 0 01-.2-.2v-9.315c0-.11.09-.2.2-.2.11 0 .2.09.2.2v9.315a.2.2 0 01-.2.2"></path>
        <path d="M46.278 37.177H35.802a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h10.476a.2.2 0 01.2.2.2.2 0 01-.2.2"></path>
        <path d="M46.279 46.491a.2.2 0 01-.2-.2v-9.315c0-.11.09-.2.2-.2.11 0 .2.09.2.2v9.315a.2.2 0 01-.2.2"></path>
        <path d="M46.278 39.506H35.802a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h10.476a.2.2 0 01.2.2.2.2 0 01-.2.2M46.278 41.835H35.802a.2.2 0 01-.2-.2c0-.112.088-.2.2-.2h10.476a.2.2 0 01.2.2.2.2 0 01-.2.2M46.278 44.162H35.802a.2.2 0 01-.2-.2c0-.111.088-.2.2-.2h10.476a.2.2 0 110 .4"></path>
      </g>
    </svg>
  );
}

export function VillaIconSet() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <VillaIcon />
      <VillaIcon color="primary" />
      <VillaIcon color="secondary" />
      <VillaIcon color="action" />
      <VillaIcon color="disabled" />
      <VillaIcon style={{ color: green[500] }} />
    </div>
  );
}
