import React, { useState, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { StreetView } from 'react-google-map-street-view';

import DynamicIcons from "../DynamicIcons";
import CrumbsBack from "../Header/CrumbsBack";


import GoogleMapView from "./GoogleMapView";

import { GOOGLE_MAP_API_KEY } from 'utils/constants.utils'

import { withStyles, Typography, AppBar, Tabs, Tab } from '@material-ui/core';
import { useRouterChange } from 'utils/router.utils';

const styles = theme => ({
    root: {
        width: '100%'
    },
    appbar: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        position: 'static'
    },
    markerIcon: {
        width: '2.1875em',
        height: '2.5em',
    },
    mapWrapper: {
        overflow: 'hidden',
        width: '100%',
        '@media (min-width: 1024px)': {
            height: '80vh !important',
        },
        '@media (min-width: 1440px)': {
            height: '100vh !important',
        },
    },
    crumbsWrap: {
        paddingTop: 10,
        paddingBottom: 12,
        marginLeft: 80,
        marginRight: 120,
        borderBottom: '1px solid #828282'
    }
});

const streetViewCSS = {
    height: '100%',
    width: '100%',
    maxHeight: '100vh'
}

const a11yProps = key => {
    return {
        id: `mapview-${key}`,
        'aria-controls': `mapview-${key}`,
    }
}

const Marker = ({ content }) => <div>{content}</div>;
const handleApiLoaded = (map, maps) => {
    // use map and maps objects
};


const StreetViewComponent = props => {
    const { classes, data, location, property, listingType } = props;
    const [routerChange] = useRouterChange()
    const [mapMode, setMapMode] = useState(location.pathname);
    const [topOffset, setTopOffset] = React.useState(0);
    const mapWrapperRef = useRef(null)

    const handleModeChange = (event, newValue) => {
        setMapMode(newValue);
        routerChange(newValue)
    };

    React.useEffect(() => {
        setMapMode(location.pathname)
        if (mapWrapperRef) {
            setTopOffset(mapWrapperRef.current.offsetTop)
        }
    }, [])

    const StreetViewMap = (props) => (
        <StreetView mapStyle={streetViewCSS} address={data.address} APIkey={GOOGLE_MAP_API_KEY} streetView zoomLevel={data.map.zoom} />
    )

    const getRoutePath = () => {
        return props.location ? props.location.pathname.split('/') : null;
    }

    function routeBack() {
        if (getRoutePath()) {
            var innerPage = '/' + getRoutePath()[1] + '/' + getRoutePath()[2];
            routerChange(innerPage)
        }
    }

    return (
        <div className={classes.root} >
            <AppBar className={classes.appbar}>
                <div className={classes.crumbsWrap}>
                    <CrumbsBack goBack={routeBack} crumbsOption={{ label: 'Back', route: null }} />
                </div>
                <Tabs
                    value={mapMode}
                    onChange={handleModeChange}
                    aria-label="Map View"
                    indicatorColor="primary"
                    variant="fullWidth"
                >

                    <Tab label={'Map View'} key={0}  {...a11yProps(0)} value={'/' + listingType + '/' + property._id + '/location/map'} />
                    <Tab label={'Street View'} key={1}  {...a11yProps(1)} value={'/' + listingType + '/' + property._id + '/location/street'} />

                </Tabs>
            </AppBar>
            <div className={classes.mapWrapper} ref={mapWrapperRef} style={{ height: (window.innerHeight - topOffset) }}>

                {/* <GoogleMapView/> */}

                {/* {mapMode === 1 && 
                <StreetViewMap />
            } */}

                <StreetViewMap />

            </div>

        </div>
    )
}

export default withStyles(styles)(StreetViewComponent);
