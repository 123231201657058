import _ from "lodash";
import { SEND_CONTACT_SUCCESS, SEND_CONTACT_FAIL, SEND_CONTACT_REQUEST } from "../constants";
import { PURGE } from "redux-persist";

const INITIAL_STATE = {
  emailStatus: {
    ok: false,
    message: null,
  },
};

const contactUsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_CONTACT_REQUEST:
      return {
        ...state,
        emailStatus: {
          ok: false,
          message: 'pending'
        },
      };
    case SEND_CONTACT_SUCCESS:
      return {
        ...state,
        emailStatus: action.payload.data,
      };
    case SEND_CONTACT_FAIL:
      return {
        ...state,
        emailStatus: {
          ok: false,
          message: 'error'
        }
      };
    case PURGE:
      return INITIAL_STATE
    default:
      return state;
  }
};

export default contactUsReducer;
