// download component as image
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const downloadComponentAsImage = async (
  element,
  imageFileName,
  fileFormat,
  isSmall,
  isMedium,
  isLarge
) => {
  const canvas = await html2canvas(element, isLarge ? { scale: 1.3 } : {})
  const image = canvas.toDataURL(`image/png`)

  if (fileFormat === 'pdf') {
    const pdf = new jsPDF({
      unit: 'px',
      format: [300, 300],
    })
    pdf.addImage(image, 'PNG', 0, 0)
    pdf.save(`${imageFileName}.pdf`)
  } else {
    const fakeLink = window.document.createElement('a')
    fakeLink.style = 'display:none;'
    fakeLink.download = `${imageFileName}.${fileFormat}`
    fakeLink.href = image
    document.body.appendChild(fakeLink)
    fakeLink.click()
    document.body.removeChild(fakeLink)
    fakeLink.remove()
  }
}

export default downloadComponentAsImage
