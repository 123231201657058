import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

/** api redux request */
import {
  requestAPIForgotPassword,
  requestAPIChangePassword,
} from "../../../store/api";

import { SOCIAL_MEDIA_BUTTONS, FORGOT_PASSWORD_VALIDATION_CONFIG } from "../../../constants/constants";


import checkField from 'components/Common/Validator/validationService'

import HeadingContent from './HeadingContent'
import FormField from './FormField'
import FormNavigationGroup from './FormNavigationGroup'
import AlertNotification from './AlertNotification'

import { Grid, Typography, Link, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    //padding: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    '&:hover': {
      outline: 'none',
    },
  },
  appBar: {
    backgroundColor: theme.palette.light.main,
  },
  container: {
    padding: '30px 24px 50px',
  },
  avatar: {

  }
});

//const SOCIAL_MEDIA_AUTH = SOCIAL_MEDIA_BUTTONS

const FORGOT_PASSWORD_FIELDS = {
  email: {
    name: 'email',
    id: 'email',
    value: 'email@ema',
    placeholder: 'Email Address',
    label: 'Email Address *',
    type: 'text',
  },
}

const FormLabel = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    marginBottom: '5px'
  }
})(Typography);

const StyledTypography = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#979797'
  }
})(Typography);

const StyledLink = withStyles({
  root: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.09px',
    lineHeight: '24px',
    color: '#000',
    textDecoration: 'underline'
  }
})(Link);

const Notice = withStyles({
  root: {
    fontWeight: 300,
    fontSize: '10px',
    letterSpacing: '0.19724px',
    lineHeight: '12px',
    color: '#919091',
    fontStyle: 'italic',
    marginBottom: '5px'
  }
})(Typography);

const MainForm = styled.form`
  padding-top: 40px;
`

const ForgotPassword = (props) => {
  /** component props */
  const {
    classes,
    type,
    setIsForgotPasswordView
  } = props;

  /** redux props */
  const { authentication } = props

  /** redux api actions */
  const { userForgotPassword, userResetPassword } = props

  const [value, setValue] = React.useState(0);

  //Use `errors` object to immediately show errors on the UI on lost focus or `submittedErrors` to only show errors on form submit
  const [isChangePassword, setChangePasswordView] = React.useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationOptions, setNotificationOptions] = useState(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [code, setCode] = useState('');
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errors, setErrors] = useState({ email: { status: false, message: '' }, password: { status: false, message: '' }, confirmpassword: { status: false, message: '' }, code: { status: false, message: '' } })
  const [isSendAgain, setIsSendAgain] = useState(false);

  const emailCheck = (fields, errors) => {
    const reqFields = fields.email.trim() ? true : false;
    if (reqFields && Object.keys(errors).length > 0) {
      if (!errors.email.status) {
        setIsResetButtonDisabled(false)
      } else {
        setIsResetButtonDisabled(true)
      }
    } else {
      setIsResetButtonDisabled(true)
    }
  }

  const codePassCheck = (fields, errors) => {
    const reqFields = fields.code.trim() && fields.password.trim() && fields.confirmPassword.trim() ? true : false;
    if (reqFields && Object.keys(errors).length > 0) {
      if (!errors.code.status && !errors.password.status && !errors.confirmpassword.status) {
        setIsButtonDisabled(false)
      } else {
        setIsButtonDisabled(true)
      }
    } else {
      setIsButtonDisabled(true)
    }
  }

  /** Listeners */
  useEffect(() => {
    handleAPIErrorResponse();
  }, [authentication])

  useEffect(() => {
    emailCheck({ email }, errors)
  }, [email])

  useEffect(() => {
    codePassCheck({ code, password, confirmPassword }, errors)
  }, [code, password, confirmPassword]);

  /** Forgot Password API */
  function apiRequestForgotPassword() {
    userForgotPassword({
      email: email,
    })
  }

  /** Reset Password API */
  function apiRequestResetPassword() {
    userResetPassword({
      email: email,
      code: code,
      password: password,
    })
  }

  /** API Error Response Handler */
  function handleAPIErrorResponse() {

    /** Forgot Password */
    if (authentication.forgot_password_status === 'success') {
      handleNotification(true, { message: 'Verification code sent.', severity: 'success' });
      if (!isSendAgain) {
        changeView();
      }
      return
    }
    if (authentication.forgot_password_status === 'error') {
      if (authentication.forgot_password_error) {
        handleNotification(true, { message: authentication.forgot_password_error.message, severity: 'error' })
        return;
      }
    }

    /** Change Password */
    if (authentication.change_password_status === 'success') {
      handleNotification(true, { message: 'Password Successfully Updated', severity: 'success' });
      setTimeout(function () { changeView(); }, 1600);
      return;
    }
    if (authentication.change_password_status === 'error') {
      if (authentication.change_password_error) {
        handleNotification(true, { message: authentication.change_password_error.data.message, severity: 'error' })
        return;
      }
    }
  }

  const handleNotification = (status, option) => {
    setIsNotification(status)
    setNotificationOptions(option)
  }

  const closeNotification = () => {
    setIsNotification(false)
  }

  const changeView = () => {
    if (isChangePassword) {
      setIsForgotPasswordView(false)
    }
    setChangePasswordView(!isChangePassword)
  }

  const renderResetPassword = () => {
    return (
      <Grid item xs={12} >
        <HeadingContent
          title={'Reset Password'}
          content={`Forgot your password? <br>It's ok we all do sometimes.`}
        />
        <MainForm>
          <FormLabel>Reset your password using your email</FormLabel>
          <FormField
            type={'email'}
            value={email}
            placeholder={'Email Address *'}
            errorMsg={errors.email.message}
            showError={errors.email.status}
            onChange={(e) => {
              var value = e.target.value;
              setEmail(value);
              if (checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('email').fields.email) === null) {
                setErrors(prevState => ({ ...prevState, email: { ...prevState.email, status: false } }))
              } else {
                setErrors(prevState => ({ ...prevState, email: { ...prevState.email, status: true, message: checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('email').fields.email) } }))
              }
            }}
          />
          <FormNavigationGroup
            type={'reset'}
            disabled={isResetButtonDisabled}
            // disabled={false}
            setIsForgotPasswordView={setIsForgotPasswordView}
            onClick={() => {
              apiRequestForgotPassword();
              //changeView();
            }}
          />
        </MainForm>
      </Grid>
    )
  }

  const renderChangePassword = () => {
    return (
      <Grid item xs={12} >
        <HeadingContent
          title={'Reset Password'}
          content={`Update your password? <br>We have sent a verification code to your email.`}
        />
        <MainForm >
          <FormLabel>Enter the code below</FormLabel>
          <span></span>
          <FormField
            type={'text'}
            value={code}
            placeholder={'Code *'}
            errorMsg={errors.code.message}
            showError={errors.code.status}
            // onKeyUp={(e)=>setCode(e.target.value)}
            // {...getFieldProps('code')}
            onChange={(e) => {
              var value = e.target.value;
              setCode(value);
              if (checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('code').fields.code) === null) {
                setErrors(prevState => ({ ...prevState, code: { ...prevState.code, status: false } }))
              } else {
                setErrors(prevState => ({ ...prevState, code: { ...prevState.code, status: true, message: checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('code').fields.code) } }))
              }
            }}
          />
          <StyledTypography>
            I didn't receive an email. &nbsp;
            <StyledLink href="#" onClick={(e) => {
              e.preventDefault();
              setIsSendAgain(true);
              apiRequestForgotPassword();
            }}>Send again</StyledLink>
          </StyledTypography>
          <FormField
            type={'password'}
            value={password}
            placeholder={'New password *'}
            errorMsg={errors.password.message}
            showError={errors.password.status}
            // onKeyUp={(e)=>setPassword(e.target.value)}
            // {...getFieldProps('password')}
            onChange={(e) => {
              var value = e.target.value;
              setPassword(value);
              if (checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('password').fields.password) === null) {
                setErrors(prevState => ({ ...prevState, password: { ...prevState.password, status: false } }))
              } else {
                setErrors(prevState => ({ ...prevState, password: { ...prevState.password, status: true, message: checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('password').fields.password) } }))
              }
            }}
          />
          <Notice>
            * Your password must contain one capital letter, one special character and a
            number
          </Notice>
          <FormField
            type={'password'}
            value={confirmPassword}
            placeholder={'Re-type new password *'}
            // errorMsg={'Password did not match'}
            errorMsg={errors.confirmpassword.message}
            showError={errors.confirmpassword.status}
            // onKeyUp={(e)=>setConfirmPassword(e.target.value)}
            // {...getFieldProps('confirmpassword')}
            onChange={(e) => {
              var value = e.target.value;
              setConfirmPassword(value);
              if (value) {
                if (value !== password) {
                  if (checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('confirmpassword').fields.confirmpassword) === null) {
                    setErrors(prevState => ({ ...prevState, confirmpassword: { ...prevState.confirmpassword, status: false } }))
                  } else {
                    setErrors(prevState => ({ ...prevState, confirmpassword: { ...prevState.confirmpassword, status: true, message: checkField(value, FORGOT_PASSWORD_VALIDATION_CONFIG('confirmpassword').fields.confirmpassword) } }))
                  }
                } else {
                  setErrors(prevState => ({ ...prevState, confirmpassword: { ...prevState.confirmpassword, status: false } }))
                }
              }
            }}
          />
          <FormNavigationGroup
            type={'change password'}
            disabled={isButtonDisabled}
            setIsForgotPasswordView={setIsForgotPasswordView}
            onClick={() => {
              apiRequestResetPassword();
              // changeView();
            }}
          />
        </MainForm>
      </Grid>
    )
  }

  return (
    <div>
      <AlertNotification
        status={isNotification}
        options={notificationOptions}
        closeNotification={closeNotification}
      />
      <Grid
        className={classes.container}
        container
        wrap="nowrap"
      >
        {
          !isChangePassword ?
            renderResetPassword()
            : renderChangePassword()
        }
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
    settings: state.settings,
    forgotPasswordFields: FORGOT_PASSWORD_FIELDS,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userForgotPassword: (params) => requestAPIForgotPassword(params),
      userResetPassword: (params) => requestAPIChangePassword(params),
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ForgotPassword))
