import React from 'react'
import { Button, Typography, withStyles, Link } from '@material-ui/core'
import { KeyboardArrowDownRounded, KeyboardArrowUpRounded } from '@material-ui/icons'
import DOMPurify from 'dompurify'

const styles = (theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  limitText: {
    position: 'relative',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  },
  toTopIconGroup: {
    paddingTop: theme.spacing(1),
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: '#4d4d4d',
    fontSize: '1em',
    textDecoration: 'none',
  },
  fadeOverlay: {
    position: 'absolute',
    bottom: '-14px',
    width: '100%',
    height: 60,
    background: 'rgb(255,255,255)',
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0.01) 0%,rgba(255,255,255,1) 72%,rgba(255,255,255,1) 100%)',
  },
  buttonReadmore: {
    textTransform: 'capitalize',
    marginTop: theme.spacing(1.25),
    lineHeight: 0,
    fontSize: '0.75rem',
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const ReadMore = (props) => {
  const { classes, description, limit } = props

  const MINIMUM_CHARACTERS_COUNT = 150

  const htmlDecode = (text) => {
    let e = document.createElement('div')
    e.innerHTML = text
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  const baseDescription = htmlDecode(description)

  const [isExpanded, setIsExpanded] = React.useState(false)
  const [descriptionText, setDescriptionText] = React.useState(htmlDecode(description))

  React.useEffect(() => {
    truncate(isExpanded)
  }, [isExpanded])

  function truncate(isFull) {
    if (isFull) {
      return setDescriptionText(baseDescription)
    } else {
      if (baseDescription?.length >= MINIMUM_CHARACTERS_COUNT) {
        return setDescriptionText(
          descriptionText.substring(0, MINIMUM_CHARACTERS_COUNT) + '...'
        )
      } else {
        return setDescriptionText(baseDescription)
      }
    }
  }

  return (
    <>
      <div className={limit && !isExpanded ? classes.limitText : classes.root}>
        <Typography
          variant="caption"
          component="div"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(descriptionText) }}
        />
        {!isExpanded && baseDescription?.length >= MINIMUM_CHARACTERS_COUNT && (
          <div className={classes.fadeOverlay}></div>
        )}
      </div>
      {baseDescription?.length >= MINIMUM_CHARACTERS_COUNT && (
        <Button
          className={classes.buttonReadmore}
          color="primary"
          endIcon={
            isExpanded ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />
          }
          onClick={(e) => {
            e.preventDefault()
            setIsExpanded(!isExpanded)
          }}
        >
          {isExpanded ? <>Read&nbsp;Less</> : <>Read&nbsp;More</>}
        </Button>
      )}
    </>
  )
}

export default withStyles(styles)(ReadMore)
